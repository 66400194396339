import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Grid} from '@material-ui/core';
import {indigo, red } from '@material-ui/core/colors';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import moment from "moment-timezone";
import "moment/locale/es";
import Select from "react-select";
import ModalDialog from "views/Widgets/Common/ModalDialog";

import {TypographyMui as Typography} from "utils/componentsStyle";
moment.locale("es");


export default function ModalSelectSpecific({
  open,
  data,
  handleClose,
  handleSelectSpecificItem,
}) {
  const [listKitSpecific, setlistKitSpecific] = useState([]);
  const [SKU, setSKU] = useState(data.row.id_equipo?.SKU);
  const [selectSkitSpecific, setselectSkitSpecific] = useState(data.row.sku_especifico !== " "? {
    value: data.row.sku_especifico,
    label: `${data.row.sku_especifico} - ${data.row.detalle_especifico}`
  } : {});
  const [dictSelectEquipSpecific, setdictSelectEquipSpecific] = useState({})
  const [forceUpdate, setforceUpdate] = useState(false);

  const dictKitSpecific = useSelector(state => state.currentList.dictKitSpecific);
  const dictEquipGeneric = useSelector(state => state.currentList.dictEquipGeneric);
  const dictKitEquipGen = useSelector(state => state.currentList.dictKitEquipGen);
  const dictEquipGenericSpecific = useSelector(state => state.currentList.dictEquipGenericSpecific);

  useEffect(() => {

    const value = data.row.sku_especifico !== " "? {
      value: data.row.sku_especifico,
      label: `${data.row.detalle_especifico}`
    } : {}
    const tempDict = {
      [SKU]: value
    }
    if (data.row?.items){
      data.row.items.map((item) => {
        tempDict[item.id_equipo.SKU] = {
          value: item.sku_especifico,
          label: item.detalle_especifico,
        }
      });
    }
    setselectSkitSpecific(value);
    setdictSelectEquipSpecific(tempDict);
    setlistKitSpecific(dictKitSpecific[SKU] ? dictKitSpecific[SKU] : []);
  }, []);

  useEffect(() => {
    if (forceUpdate){
      setforceUpdate(false);
    }
  })


  const handleSelectEquipSpecific = (sku, value) => {
    const tempDict = dictSelectEquipSpecific;
    tempDict[sku] = {...value, id: dictEquipGeneric[sku]};
    setdictSelectEquipSpecific(tempDict);
    setforceUpdate(true);
  }

  const  handleSelectKitSpecific = (value) => {
    const tempDict = {
      [SKU]: value,
    };
    setdictSelectEquipSpecific(tempDict);
    setselectSkitSpecific(value);
  }

  const _content_ = <Grid style={{minHeigth: "400px"}}>
    {dictKitEquipGen[SKU] &&
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      item
      xs={12}
    >
      {listKitSpecific.length > 0 ?
      <Typography
        style={{color: indigo[500]}}
        variant='subtitle2'
      >
        {"Selecciona el kit especifico"}
      </Typography>:
      <Typography
      variant='subtitle2'
      >
      {"Este kit genérico no tiene kit específicos"}
      </Typography>
      }
    </Grid>
    }
    {listKitSpecific.length > 0 &&
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      item
      xs={7}
      style={{margin: "23px 0 0 0"}}
    >
      <div style={{width: "100%"}}>
        <Select
          options={listKitSpecific}
          value={selectSkitSpecific}
          onChange={(value) => handleSelectKitSpecific(value)}
        />
      </div>
    </Grid>
    }
    {forceUpdate && <div></div>}
    {(
      dictKitEquipGen[selectSkitSpecific?.value] ||
      (selectSkitSpecific == null && dictKitEquipGen[SKU])
    ) &&
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      item
      xs={12}
      style={{margin: "31px 0 21px 0"}}
    >
      {(dictKitEquipGen[selectSkitSpecific?.value] || dictKitEquipGen[SKU]) ?
      <Typography
        style={{color: indigo[500]}}
        variant='subtitle2'
      >
        {"Selecciona los equipos de este kit"}
      </Typography>:
      <Typography
      variant='subtitle2'
      >
      {"El kit no contiene ningun item"}
      </Typography>
      }
    </Grid>
    }
    {(dictKitEquipGen[selectSkitSpecific?.value] ||
      (selectSkitSpecific == null && dictKitEquipGen[SKU])
    ) &&
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      item
      xs={12}

    >
      {(dictKitEquipGen[selectSkitSpecific?.value] ?
          dictKitEquipGen[selectSkitSpecific?.value] :
          dictKitEquipGen[SKU]
      ).map((row) => (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
          style={{margin: "0 0 14px 0"}}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={6}
          >
            <Typography
              style={{color: indigo[500]}}
              variant='body2'
            >
              {row.label}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={6}
          >
            <div style={{width: "100%"}}>
              <Select
                options={dictEquipGenericSpecific[row.value] ?
                  dictEquipGenericSpecific[row.value] :
                  [{value: "Sin especificos", label: "Sin especificos"}]
                }
                value={dictSelectEquipSpecific[row.value] ? dictSelectEquipSpecific[row.value] : {}}
                onChange={(value) => handleSelectEquipSpecific(row.value, value)}
              />
            </div>
          </Grid>
        </Grid>
      ))
      }
    </Grid>
    }
    {dictEquipGenericSpecific[SKU] ?
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
        style={{margin: "0 0 14px 0"}}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          item
          xs={6}
        >
          <Typography
            style={{color: indigo[500]}}
            variant='body2'
          >
            {`${SKU} - ${data.row.id_equipo?.descripcion}`}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          item
          xs={6}
        >
          <div style={{width: "100%"}}>
            <Select
              options={dictEquipGenericSpecific[SKU] ?
                dictEquipGenericSpecific[SKU] :
                [{value: "Sin especificos", label: "Sin especificos"}]
              }
              value={dictSelectEquipSpecific[SKU] ? dictSelectEquipSpecific[SKU] : {}}
              onChange={(value) => handleSelectEquipSpecific(SKU, value)}
            />
          </div>
        </Grid>
      </Grid> :
      selectSkitSpecific == null && !dictKitEquipGen[SKU] &&
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
        style={{margin: "0 0 14px 0"}}
      >
        <Typography
          variant='subtitle2'
          >
          {"Este item genérico no contiene item específicos"}
        </Typography>

      </Grid>
    }
  </Grid>

  const _actions_ = <>
    <Button
      color="primary"
      variant="contained"
      onClick={handleClose}
    >
      Cerrar
    </Button>
    <Button
      disabled={Object.keys(dictSelectEquipSpecific).length === 0}
      color="primary"
      variant="contained"
      onClick={() => handleSelectSpecificItem(dictSelectEquipSpecific, data.index)}
    >
      Guardar especificos
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={`SELECCIONAR ESPECIFICOS`}
      _content_={_content_}
      _actions_ = {_actions_}
    />

  );
}
