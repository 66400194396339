import React, { useState, useEffect } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import ProductsIncome from "./ProductsIncome";
import DivisionsBlock from "./DivisionsBlock";

const IncomeTasks = () => {
  const [moduleType, setmoduleType] = useState("");

  useEffect(() => {
    const arrayURL = window.location.href.split("/");
    setmoduleType(arrayURL[arrayURL.length - 1]);
  }, []);

  return (
    <GridContainer>
      <ViewTitle
        title={`TAREAS DE ${
          moduleType === "storage" ? "INGRESO" : "INGRESO FARMACIA"
        }`}
        message="En esta sección podrás revisar las tareas de ingreso"
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Typography color="textPrimary">{`Tareas de ${
              moduleType === "storage" ? "Ingreso" : "Ingreso Farmacia"
            }`}</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <GridContainer justify="center" className="m-3 mt-5 mb-5">
            <ProductsIncome moduleType={moduleType} />
            <DivisionsBlock moduleType={moduleType} />
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default IncomeTasks;
