import React from "react";
import Today from "@material-ui/icons/Today";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function Soon() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Today />
              </CardIcon>
              <h1 className={classes.cardIconTitle}>En Construcción</h1>
            </CardHeader>
            <CardBody>
              <br />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
