import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import TextField from '@material-ui/core/TextField';
import api from "utils/API";
import TableExamData from "./TableExamData";
import DialogCancelRequest from "./DialogCancelRequest";
import DialogSendExam from "./DialogSendExam";
// import TableSummary from "./TableSummary";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalExamWaiting = props => {
  const classes = useStyles();
  const { openModal, closeModal, tempRow } = props;

  const [openAnimation, setOpenAnimation] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [openDialogCancelRequest, setOpenDialogCancelRequest] = useState(false);
  const [openDialogSendRequest, setOpenDialogSendRequest] = useState(false);
  const [addExamData, setAddExamData] = useState(false);
  const [examDate, setExamDate] = useState(moment());
  const [comments, setComments] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [indications, setIndications] = useState("");
  const [name, setName] = useState("");
  const [transfer, setTransfer] = useState(null);
  const [transferType, setTransferType] = useState("");
  const [responsable, setResponsable] = useState(null);
  const [priority, setPriority] = useState("");
  const [supplies, setSupplies] = useState([]);
  const [dateRequest, setDateRequest] = useState(moment());

  console.log("tempRow de examen datos entrega", tempRow);

  const handleDateChange = date => {
    setDateRequest(date);
  };

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);
    const getExamDetail = api.get(`/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/examenes/${tempRow.id_solicitud_paciente.id}/`);

    Promise.all([getExamDetail])
      .then(response => {
        console.log("response", response);
        setIndications(response[0].data.examen.indicaciones);
        setName(response[0].data.examen.id_examen_clinica.nombre);
        setExamDate(
          moment(response[0].data.examen.fecha_hora_examen).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        );
        setTransfer(response[0].data.examen.traslado);
        setTransferType(response[0].data.examen.tipo_traslado);
        setResponsable(response[0].data.examen.realiza_cmh);
        setPriority(response[0].data.prioridad);
        setIndications(response[0].data.examen.indicaciones);

        let tempSupplies = [];

        response[0].data.productos_pedidos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto === "Medicamentos") {
            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });
        response[0].data.productos_pedidos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto === "Insumos") {
            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });
        setSupplies(tempSupplies);


      })

  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >

        <ModalTitle id="customized-dialog-title" onClose={closeModal}>
          Datos del examen
        </ModalTitle>
        <DialogContent>
          {addExamData ? (
            <Grid container className={classes.root} justify="center">
              <Grid item container xs={12} justify="center" spacing={1}>
                <Grid item container xs={12} justify="center">
                  <Typography variant="body2" gutterBottom>
                    Ingrese los datos del examen
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} justify="center" spacing={1}>
                {responsable ? (
                  <Grid item container xs={12} justify="center" spacing={1}>
                    <Grid item container xs={6} justify="center">
                      <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <Grid container justify="center">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="DD-MM-YYYY"
                            margin="normal"
                            id="date-picker-inline1"
                            label="Fecha del examen"
                            value={dateRequest}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item container xs={6} justify="center">
                      <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <Grid container justify="center">
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Hora del examen"
                            value={dateRequest}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change time"
                            }}
                            cancelLabel={"Cancelar"}
                            okLabel={"Aceptar"}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container xs={12} justify="center" spacing={1}>
                    <Grid item container xs={4} justify="center">
                      <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <Grid container justify="center">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="DD-MM-YYYY"
                            margin="normal"
                            id="date-picker-inline1"
                            label="Fecha del examen"
                            value={dateRequest}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item container xs={4} justify="center">
                      <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <Grid container justify="center">
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Hora del examen"
                            value={dateRequest}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change time"
                            }}
                            cancelLabel={"Cancelar"}
                            okLabel={"Aceptar"}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={4}
                      justify="center"
                      alignContent="center"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Número de seguimiento"
                        variant="outlined"
                        value={trackingNumber}
                        onChange={e => setTrackingNumber(e.target.value)}
                        error={trackingNumber === "" ? true : false}
                        helperText={
                          trackingNumber === ""
                            ? "Debes ingresar un número de seguimiento"
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                justify="center"
                spacing={1}
                style={{ marginTop: "2em" }}
              >
                <Grid item container xs={10} justify="center">
                  <TextField
                    fullWidth
                    id="filled-multiline-static"
                    label="Comentario"
                    multiline
                    rows={4}
                    variant="outlined"
                    onChange={e => setComments(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid>
              <Grid container className={classes.root} justify="center">
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Nombre examen:
                      <span style={{ fontWeight: "bold" }}>{name}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Fecha examen:
                      <span style={{ fontWeight: "bold" }}>
                        {moment(examDate).format("YYYY-MM-DD")}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Análisis:
                      <span style={{ fontWeight: "bold" }}>
                        {responsable ? "CMH" : "Laboratorio Externo"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Hora examen:
                      <span style={{ fontWeight: "bold" }}>
                        {moment(examDate).format("HH:mm")}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Traslado:
                      <span style={{ fontWeight: "bold" }}>
                        {transfer ? transferType : "No"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Prioridad:
                      <span style={{ fontWeight: "bold" }}>{priority}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Typography variant="body2" gutterBottom>
                      Indicaciones:
                      <span style={{ fontWeight: "bold" }}>{indications}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Grid>
              <Grid item container xs={12} justify="center">
                <TableExamData supplies={supplies} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          {addExamData ? (
            responsable ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#43a047",
                  textTransform: "none",
                  color: "#fff"
                }}
                onClick={() => {
                  setOpenDialogSendRequest(true);
                  //editRequest(true);
                  //setAddExamData(true);
                }}
              >
                Enviar
              </Button>
            ) : trackingNumber != "" ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#43a047",
                  textTransform: "none",
                  color: "#fff"
                }}
                onClick={() => {
                  setOpenDialogSendRequest(true);
                  //editRequest(true);
                  //setAddExamData(true);
                }}
              >
                Enviar
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none"
                }}
                onClick={() => {
                  setOpenDialogSendRequest(true);
                  //editRequest(true);
                  //setAddExamData(true);
                }}
              >
                Enviar
              </Button>
            )
          ) : (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#43a047",
                textTransform: "none",
                color: "#fff"
              }}
              onClick={() => {
                //editRequest(true);
                setAddExamData(true);
              }}
            >
              Ingresar datos del examen
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: "none"
            }}
            onClick={() => {
              closeModal();
              //editRequest(false);
            }}
          >
            Atrás
          </Button>
        </DialogActions>
        {openDialogCancelRequest && (
          <DialogCancelRequest
            openDialog={openDialogCancelRequest}
            closeDialog={() => setOpenDialogCancelRequest(false)}
            closeMainModal={closeModal}
          />
        )}
        {openDialogSendRequest && (
          <DialogSendExam
            openDialog={openDialogSendRequest}
            closeDialog={() => setOpenDialogSendRequest(false)}
            closeMainModal={closeModal}
            dateRequest={dateRequest}
            comments={comments}
            trackingNumber={trackingNumber}
            tempRow={tempRow}
          />
        )}
      </Paper>
    </Modal>
  );
};

export default ModalExamWaiting;

ModalExamWaiting.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRow: PropTypes.object
};
