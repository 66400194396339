import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";
import { useParams } from "react-router-dom";
import ModalEditedRequest from "views/Warehouse/Requests/ModalEditedRequest";
import ModalAceptRequest from "./ModalAcepRequest";
import ModalAcept2Request from "./ModalAcep2Request";
import ModalRejectRequest from "./ModalRejectRequest";
import ModalCancelRequest from "./ModalCancelRequest";
import ModalChangeSku from "./ModalChangeSku";
import ModalChangeQuantity from "./ModalChangeQuantity";

export default function DivisionRequest() {
  const { id } = useParams();
  const [moduleType, setmoduleType] = useState("");
  const [supplies, setSupplies] = useState([]);
  const [group, setGroup] = useState("");
  const [group_final, setGroup_final] = useState("");
  const [sku, setSKU] = useState("");
  const [id_sku, setId_sku] = useState("");
  const [sku_final, setSKU_final] = useState("");
  const [id_sku_final, setId_sku_final] = useState("");
  let list_sku = [];
  let list_sku_final = [];
  const [description, setDescription] = useState("");
  const [description_final, setDescription_final] = useState("");
  const [cantidadFraccionar, setCantidadFraccionar] = useState(0);
  const [cantidad_resultante, setCantidad_resultante] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  const [modalSaved, setModalSaved] = useState(false);
  const [select, setSelect] = useState("");
  const [modalAceptRequest, setModalAceptReqest] = useState(false);
  const [modalAcept2Request, setModalAcept2Request] = useState(false);
  const [modalRejectRequest, setModalRejectRequest] = useState(false);
  const [divisionRequest, setDivisionRequest] = useState([]);
  const [modalCancelRequest, setModalCancelRequest] = useState(false);
  const [compareSkuFinal, setCompareSkuFinal] = useState("");
  const [compareQuantity, setCompareQuantity] = useState("");
  const [modalChangeSku, setModalChangeSku] = useState(false);
  const [modalChangeQuantity, setModalChangeQuantity] = useState(false);
  const [checkSkuFinal, setCheckSkuFinal] = useState("");
  const stockProductoFraccionar = "";
  const [stockProductoFraccionado, setStockProductoFraccionado] = useState("");

  let listMedicamentos = [];
  let listEquipamiento = [];
  let listInsumos = [];
  let listArticulos = [];
  let listActivos = [];
  let listEstetica = [];
  let listAlimentos = [];
  let listVehiculos = [];
  let listOtro = [];

  useEffect(() => {
    const tempArrayURL = window.location.href.split("/");
    const tempModuleType = tempArrayURL[tempArrayURL.length - 2];
    setmoduleType(tempModuleType);
    api.get(`api/solicitudfraccionamiento/${id}`).then((response) => {
      setDivisionRequest(response.data);
      setObservaciones(response.data.observaciones);
      setCantidad_resultante(response.data.cantidad_resultante);
      setCompareQuantity(response.data.cantidad_resultante);
      setCantidadFraccionar(response.data.cantidad_fraccionar);
      setId_sku(response.data.id_producto_fraccionar.id);
      setCompareSkuFinal(response.data.id_producto_fraccionar.id);
      setId_sku_final(response.data.id_producto_resultante.id);

      setSKU({
        value: response.data.id_producto_fraccionar.SKU,
        label: response.data.id_producto_fraccionar.SKU,
      });

      setStockProductoFraccionado(
        response.data.id_producto_fraccionar.stock_producto
      );

      setSKU_final({
        value: response.data.id_producto_resultante.SKU,
        label: response.data.id_producto_resultante.SKU,
      });

      setCheckSkuFinal({
        value: response.data.id_producto_resultante.SKU,
        label: response.data.id_producto_resultante.SKU,
      });

      setStockProductoFraccionado(
        response.data.id_producto_resultante.stock_producto
      );

      if (["Insumos", "Articulos", "Alimentos"].includes(response.data.id_producto_fraccionar.grupo_producto)) {
        const listSupplyInsumos = api.get("api/insumosmedicamento/Insumos");

        Promise.all([listSupplyInsumos])
          .then((response) => {
            setSupplies(response[0].data);
          })

      }
      if (
        response.data.id_producto_fraccionar.grupo_producto === "Medicamentos"
      ) {
        const listSupplyMedicamentos = api.get(
          "api/insumosmedicamento/Medicamentos"
        );

        Promise.all([listSupplyMedicamentos])
          .then((response) => {
            setSupplies(response[0].data);
          })

      }
      if (
        response.data.id_producto_fraccionar.grupo_producto ===
        "MedicamentosVenta"
      ) {
        const listSupplyMedicamentosVenta = api.get(
          "api/insumosmedicamento/Insumos"
        );

        Promise.all([listSupplyMedicamentosVenta])
          .then((response) => {
            setSupplies(response[0].data);
          })

      }
      if (response.data.id_producto_fraccionar.grupo_producto === "Articulos") {
        const listSupplyArticulos = api.get("api/insumosmedicamento/Articulos");

        Promise.all([listSupplyArticulos])
          .then((response) => {
            setSupplies(response[0].data);
          })

      }
      if (response.data.id_producto_fraccionar.grupo_producto === "Activos") {
        const listSupplyActivos = api.get("api/insumosmedicamento/Activos");

        Promise.all([listSupplyActivos])
          .then((response) => {
            setSupplies(response[0].data);
          })

      }
      if (response.data.id_producto_fraccionar.grupo_producto === "Estetica") {
        const listSupplyEstetica = api.get("api/insumosmedicamento/Estetica");

        Promise.all([listSupplyEstetica])
          .then((response) => {
            setSupplies(response[0].data);
          })

      }
      if (response.data.id_producto_fraccionar.grupo_producto === "Alimentos") {
        const listSupplyAlimentos = api.get("api/insumosmedicamento/Alimentos");

        Promise.all([listSupplyAlimentos])
          .then((response) => {
            setSupplies(response[0].data);
          })

      }
      if (response.data.id_producto_fraccionar.grupo_producto === "Vehiculos") {
        const listSupplyVehiculos = api.get("api/insumosmedicamento/Vehiculos");

        Promise.all([listSupplyVehiculos])
          .then((response) => {
            setSupplies(response[0].data);
          })

      }
      if (response.data.id_producto_fraccionar.grupo_producto === "Otro") {
        const listSupplyOtro = api.get("api/insumosmedicamento/Otro");

        Promise.all([listSupplyOtro])
          .then((response) => {
            setSupplies(response[0].data);
          })

      }
    });
  }, []);

  useEffect(() => {
    supplies.map((supply) => {
      list_sku.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies.map((supply) => {
      list_sku_final.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies
      .filter((supply) => supply.SKU === sku.value)
      .map((filtered) => {
        setGroup(filtered.grupo_producto);
        setDescription(filtered.descripcion_producto);
        setId_sku(filtered.id);
      });
    supplies
      .filter((supply) => supply.SKU === sku_final.value)
      .map((filtered) => {
        setGroup_final(filtered.grupo_producto);
        setDescription_final(filtered.descripcion_producto);
        setId_sku_final(filtered.id);
      });
  });

  const handleSKUFinal = (sku_final) => {
    setSKU_final(sku_final);
  };

  useEffect(() => {
    supplies
      .filter((supply) => supply.SKU === sku.value)
      .map((filtered) => {
        if (
          filtered.grupo_producto === "Medicamentos" ||
          filtered.grupo_producto === "MedicamentosVenta"
        ) {
          supplies
            .filter(
              (supply) =>
                (supply.grupo_producto === "Medicamentos" ||
                  supply.grupo_producto === "MedicamentosVenta") &&
                supply.SKU != sku.value
            )
            .map((filtered) => {
              listMedicamentos.push({
                value: filtered.SKU,
                label: filtered.SKU,
              });
            });
          setSelect(filtered.grupo_producto);
        }
        if (["Insumos", "Articulos", "Alimentos"].includes(filtered.grupo_producto)) {
          supplies
            .filter(
              (supply) =>
              ["Insumos", "Articulos", "Alimentos"].includes(supply.grupo_producto) && supply.SKU != sku.value
            )
            .map((filtered) => {
              listInsumos.push({ value: filtered.SKU, label: filtered.SKU });
            });
          setSelect(filtered.grupo_producto);
        }
        if (filtered.grupo_producto === "Equipamiento") {
          supplies
            .filter(
              (supply) =>
                supply.grupo_producto === "Equipamiento" &&
                supply.SKU != sku.value
            )
            .map((filtered) => {
              listEquipamiento.push({
                value: filtered.SKU,
                label: filtered.SKU,
              });
            });
          setSelect(filtered.grupo_producto);
        }
        if (filtered.grupo_producto === "Articulos") {
          supplies
            .filter(
              (supply) =>
                supply.grupo_producto === "Articulos" && supply.SKU != sku.value
            )
            .map((filtered) => {
              listArticulos.push({
                value: filtered.SKU,
                label: filtered.SKU,
              });
            });
          setSelect(filtered.grupo_producto);
        }
        if (filtered.grupo_producto === "Activos") {
          supplies
            .filter(
              (supply) =>
                supply.grupo_producto === "Activos" && supply.SKU != sku.value
            )
            .map((filtered) => {
              listActivos.push({
                value: filtered.SKU,
                label: filtered.SKU,
              });
            });
          setSelect(filtered.grupo_producto);
        }
        if (filtered.grupo_producto === "Estetica") {
          supplies
            .filter(
              (supply) =>
                supply.grupo_producto === "Estetica" && supply.SKU != sku.value
            )
            .map((filtered) => {
              listEstetica.push({
                value: filtered.SKU,
                label: filtered.SKU,
              });
            });
          setSelect(filtered.grupo_producto);
        }
        if (filtered.grupo_producto === "Alimentos") {
          supplies
            .filter(
              (supply) =>
                supply.grupo_producto === "Alimentos" && supply.SKU != sku.value
            )
            .map((filtered) => {
              listAlimentos.push({
                value: filtered.SKU,
                label: filtered.SKU,
              });
            });
          setSelect(filtered.grupo_producto);
        }
        if (filtered.grupo_producto === "Vehiculos") {
          supplies
            .filter(
              (supply) =>
                supply.grupo_producto === "Vehiculos" && supply.SKU != sku.value
            )
            .map((filtered) => {
              listVehiculos.push({
                value: filtered.SKU,
                label: filtered.SKU,
              });
            });
          setSelect(filtered.grupo_producto);
        }
        if (filtered.grupo_producto === "Otro") {
          supplies
            .filter(
              (supply) =>
                supply.grupo_producto === "Otro" && supply.SKU != sku.value
            )
            .map((filtered) => {
              listOtro.push({
                value: filtered.SKU,
                label: filtered.SKU,
              });
            });
          setSelect(filtered.grupo_producto);
        }
      });
  });

  return (
    <GridContainer>
      <ViewTitle
        title="DETALLE FRACCIONAMIENTO"
        message="En esta sección podrás administrar la solicitud del fraccionamiento seleccionado."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/income/tasks/${moduleType}`}>
              {`Tareas de ${
                moduleType === "storage" ? "Ingreso" : "Ingreso Farmacia"
              }`}
            </Link>
            <Link color="inherit" to={`/admin/income/divisions/${moduleType}`}>
              Fraccionamiento y códigos
            </Link>
            <Typography color="textPrimary">Detalle fraccionamiento</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardBody className="mb-3">
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <Grid item xs={4} align="center" />
                <Grid item xs={4} align="center">
                  <h4 className="font-weight-bold mb-3">SKU a fraccionar</h4>
                </Grid>
                <Grid item xs={4} />
                <DetailTextDisabled
                  label="GRUPO:"
                  value={
                    divisionRequest?.id_producto_fraccionar?.grupo_producto
                  }
                />
                <DetailTextDisabled
                  label="SKU:"
                  value={divisionRequest?.id_producto_fraccionar?.SKU}
                />
                <DetailTextDisabled
                  label="DESCRIPCIÓN:"
                  value={
                    divisionRequest?.id_producto_fraccionar
                      ?.descripcion_producto
                  }
                />
                <DetailTextDisabled
                  label="CANTIDAD A FRACCIONAR:"
                  value={divisionRequest.cantidad_fraccionar}
                />
              </Grid>
            </Grid>
            <Divider className="mb-4 mt-3" />
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <Grid item xs={4} align="center" />
                <Grid item xs={4} align="center">
                  <h4 className="font-weight-bold mb-3">SKU resultante</h4>
                </Grid>
                <Grid item xs={4} />
                <DetailTextDisabled label="SKU:" value={sku_final.value} />
                <DetailTextDisabled label="GRUPO:" value={group_final} />
                <DetailTextDisabled
                  label="DESCRIPCIÓN:"
                  value={description_final}
                />
                <DetailTextDisabled
                  label="CANTIDAD RESULTANTE:"
                  value={cantidad_resultante}
                />
              </Grid>
            </Grid>
            {divisionRequest.estado === "Esperar a Ingresos" ? (
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justify="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalChangeSku(true)}
                  >
                    Editar sku resultante
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalChangeQuantity(true)}
                  >
                    Editar cantidad resultante
                  </Button>
                </Grid>
              </Grid>
            ) : null}
            <Divider className="mb-4 mt-3" />
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item xs={8}>
                <div className="col-md-12 mb-3">
                  <label className="text-dark">OBSERVACIONES</label>
                  <textarea
                    className="form-control bg-light font-weight-lighter"
                    style={{ color: "#000000" }}
                    value={observaciones}
                    disabled
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                {divisionRequest.estado === "Finalizado" ? (
                  <Link to={`/admin/income/divisions/${moduleType}`}>
                    <Button variant="contained" color="primary">
                      Atrás
                    </Button>
                  </Link>
                ) : divisionRequest.estado === "Esperar a egresos" ? (
                  <Link to={`/admin/income/divisions/${moduleType}`}>
                    <Button variant="contained" color="primary">
                      Atrás
                    </Button>
                  </Link>
                ) : divisionRequest.estado === "Revisar fraccionamiento" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalAcept2Request(true)}
                  >
                    Aceptar fraccionamiento
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalAceptReqest(true)}
                  >
                    Aceptar fraccionamiento
                  </Button>
                )}
              </Grid>
              <Grid item>
                {divisionRequest.estado ===
                "Finalizado" ? null : divisionRequest.estado ===
                  "Esperar a egresos" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalCancelRequest(true)}
                  >
                    Cancelar envío
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalRejectRequest(true)}
                  >
                    Rechazar fraccionamiento
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      {modalAceptRequest && (
        <ModalAceptRequest
          cantidad_resultante={cantidad_resultante}
          id_producto_resultante={id_sku_final}
          open={modalAceptRequest}
          divisionRequest={divisionRequest}
          closeModal={() => setModalAceptReqest(false)}
          moduleType={moduleType}
        />
      )}
      {modalAcept2Request && (
        <ModalAcept2Request
          cantidad_resultante={cantidad_resultante}
          id_producto_resultante={id_sku_final}
          open={modalAcept2Request}
          divisionRequest={divisionRequest}
          stockProductoFraccionar={stockProductoFraccionar}
          stockProductoFraccionado={stockProductoFraccionado}
          closeModal={() => setModalAcept2Request(false)}
          moduleType={moduleType}
        />
      )}
      {modalRejectRequest && (
        <ModalRejectRequest
          open={modalRejectRequest}
          closeModal={() => setModalRejectRequest(false)}
          divisionRequest={divisionRequest}
          id_order={id}
          moduleType={moduleType}
        />
      )}
      {modalCancelRequest && (
        <ModalCancelRequest
          open={modalCancelRequest}
          divisionRequest={divisionRequest}
          closeModal={() => setModalCancelRequest(false)}
          moduleType={moduleType}
        />
      )}
      {modalSaved && (
        <ModalEditedRequest
          open={modalSaved}
          closeModal={() => setModalSaved(false)}
          moduleType={moduleType}
        />
      )}
      {modalChangeSku && (
        <ModalChangeSku
          value={sku_final}
          list={
            select === "Insumos"
              ? listInsumos
              : select === "Medicamentos" || select === "MedicamentosVenta"
              ? listMedicamentos
              : select === "Equipamiento"
              ? listEquipamiento
              : select === "Articulos"
              ? listArticulos
              : select === "Activos"
              ? listActivos
              : select === "Estetica"
              ? listEstetica
              : select === "Alimentos"
              ? listAlimentos
              : select === "Vehiculos"
              ? listVehiculos
              : select === "Otro"
              ? listOtro
              : null
          }
          listInsumos={listInsumos}
          setSKU_final={setSKU_final}
          onChange={handleSKUFinal}
          checkSkuFinal={checkSkuFinal}
          open={modalChangeSku}
          closeModal={() => setModalChangeSku(false)}
          moduleType={moduleType}
        />
      )}
      {modalChangeQuantity && (
        <ModalChangeQuantity
          value={cantidad_resultante}
          setCantidad_resultante={setCantidad_resultante}
          cantidadFraccionar={cantidadFraccionar}
          compareQuantity={compareQuantity}
          open={modalChangeQuantity}
          closeModal={() => setModalChangeQuantity(false)}
          moduleType={moduleType}
        />
      )}
    </GridContainer>
  );
}
