import React, { useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
// import Button from "components/CustomButtons/Button.js";
import ModalExamData from "./Examenes/ModalExamData";
import ModalTransferClinic from "views/PatientFile/Traslados/Modals/ModalTransferClinic";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

export default function RequestSaveTable({
  dataArray,
  updateArray,
  handleDeleteRow,
  handleSelectRow,
  dataPatient
}) {
  const classes = useStyles();
  const [rowInfo, setRowInfo] = useState({});
  const [openModalExam, setOpenModalExam] = useState(false);
  const [openModalTransfer, setOpenModalTransfer] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const dictPosition = useSelector(state => state.currentList.dictCharges);
  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PACIENTE);

  const array = dataArray.filter(row => (
      (row.estado === "RecienCreada" || row.estado === "Progreso") &&
      (row.Tipo_Solicitud_Paciente === "Cotizacion" || row.id_persona_responder == localStorage.getItem("user_id"))
    )
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Prioridad</TableCell>
              <TableCell align="center">Hecha por</TableCell>
              <TableCell align="center">Fecha</TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updateArray &&
              <TableRow align="center"></TableRow>
            }
            {array
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">
                    {dictTypeRequest[row.Tipo_Solicitud_Paciente]?.label}
                  </TableCell>
                  <TableCell align="center">{row.prioridad}</TableCell>
                  <TableCell align="center">
                    {row.id_cargo_solicitante
                      ? dictPosition[row.id_cargo_solicitante]?.nombre
                      : null}
                  </TableCell>
                  <TableCell align="center">{row.fecha_creacion}</TableCell>
                  <TableCell align="center">
                    {dictTypeRequest[row.Tipo_Solicitud_Paciente]?.label === "Examenes" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: "1% 0",
                          width: "100%",
                          textTransform: "none"
                        }}
                        onClick={() => {
                          setRowInfo(row);
                          setOpenModalExam(true);
                        }}
                      >
                        Editar exámen
                      </Button>
                    ) : dictTypeRequest[row.Tipo_Solicitud_Paciente]?.label === "Traslados" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: "1% 0",
                          width: "100%",
                          textTransform: "none"
                        }}
                        onClick={() => {
                          setRowInfo(row);
                          setOpenModalTransfer(true);
                        }}
                      >
                        Editar traslado
                      </Button>
                    ) : (
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        itemxs={12}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            margin: "1% 0",
                            width: "100%",
                            textTransform: "none"
                          }}
                          onClick={() => handleSelectRow(row)}
                        >
                          Continuar editando
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            margin: "1% 0",
                            width: "100%",
                            textTransform: "none"
                          }}
                          onClick={() => handleDeleteRow(row)}
                        >
                          Eliminar registro
                        </Button>
                      </Grid>
                    )}
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={array.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {openModalExam && (
        <ModalExamData
          openModal={openModalExam}
          closeModal={() => setOpenModalExam(false)}
          tempRow={rowInfo}
          dataPatient={dataPatient}
          dataArray={dataArray}
        />
      )}
      {openModalTransfer && (
        <ModalTransferClinic
          openModal={openModalTransfer}
          closeModal={() => setOpenModalTransfer(false)}
          dataPatient={dataPatient}
          tempRow={rowInfo}
          dataArray={dataArray}
          viewOnly={false}
          edit={true}
        />
      )}
    </Paper>
  );
}

RequestSaveTable.propTypes = {
  dataArray: PropTypes.array
};
