import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ModalSaveQuotation from "./ModalSaveQuotation";
import ModalQuotationSkip from "./ModalQuotationSkip";
import {
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TableComponent from "views/Widgets/Common/TableComponent";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import api from "utils/API";
import {getListRequestPatient} from 'actions/getListAction';
import {setmessageGeneral } from "actions/getGlobalAction";
import {toMoneyFormat} from "utils/formatFunctions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


export default function QuotationPackaging({
  viewQuotation,
  resumeQuotation,
  dataPatient,
  reloadRequest,
  handleReload,
  forecast,
}) {
  const dispatch = useDispatch();
  const [change, setchange] = useState(true);
  const [amount, setamount] = useState(0);
  const [openModal, setopenModal] = useState(false);
  const [openModalSkip, setopenModalSkip] = useState(false);
  const [showPackage, setshowPackage] = useState(false);

  const packageData = showPackage ? resumeQuotation.packageUnbalanced :  resumeQuotation.packageBalanced;

  const typeQuotationDiscount = packageData.tipo_porcentaje;
  const quotationDiscount = packageData.porcentaje_item;

  const totalMonth = packageData.dia_cama*(packageData?.dias_periodo || 30)+ packageData.medicamentos+packageData.insumos

  const dictDiscountQuotation = useSelector(state => state.currentList.dictDiscountQuotation);
  const dictPosition = useSelector(state => state.currentList.dictCharges);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (change) {
      calculateAmount();
    }
  });

  const transient = resumeQuotation?.packageBalanced?.tipo_permanencia == "Transitorio"
  
  const Medical = resumeQuotation.packageBalanced.prestaciones.filter((item) => item.id_insumo_medicamento?.grupo_producto === "Medicamentos");
  const Resourse = resumeQuotation.packageBalanced.prestaciones.filter((item) => ["Insumos", "Articulos", "Alimentos"].includes(item.id_insumo_medicamento?.grupo_producto));
  const Emergency = packageData.prestaciones.filter((item) => item.id_prestaciones?.grupo === "Emergencia");
  const Other = packageData.prestaciones.filter((item) => ["Traslados", "Otros"].includes(item.id_prestaciones?.grupo));
  const Equip = packageData.prestaciones.filter((item) => item.id_equipo?.tipo_producto === "EquiposMedicos");
  const Professional = packageData.prestaciones.filter((item) => item.id_prestaciones?.grupo === "Profesionales");

  const calculateIndividualDiscount = (amount, value, discount, typeDiscount) => {
    if (!value){
      return 0;
    }
    const total = amount*value;
    const totalDiscount = typeDiscount === "Descuento" ? discount*-1 : discount;
    return parseInt(totalDiscount == 0 ? total : total + (total*(totalDiscount/100)));
  };


  const calculateAmount = () => {
    setchange(false);
    let tempamount = 0;
    Medical.map((row) =>{
      if (row.fuera_paquete === true ){
        const tempDiscount = dictDiscountQuotation[`${row.id_insumo_medicamento?.id}_insumos_medicamentos`]
        if(tempDiscount){
          tempamount += calculateIndividualDiscount(
            row.valor,
            row.cantidad,
            tempDiscount.porcentaje_item,
            tempDiscount.tipo_porcentaje
          )
        } else {
          tempamount += row.valor*row.cantidad;
        }
      }
    });
    Resourse.map((row) =>{
      if (row.fuera_paquete === true ){
        const tempDiscount = dictDiscountQuotation[`${row.id_insumo_medicamento?.id}_insumos_medicamentos`]
        if(tempDiscount){
          tempamount += calculateIndividualDiscount(
            row.valor,
            row.cantidad,
            tempDiscount.porcentaje_item,
            tempDiscount.tipo_porcentaje
          )
        } else {
          tempamount += row.valor*row.cantidad;
        }
      }
    });
    Emergency.map((row) =>{
      if (row.fuera_paquete === true ){
        const tempDiscount = dictDiscountQuotation[`${row.id_prestaciones?.id}_prestacion`]
        if(tempDiscount){
          tempamount += calculateIndividualDiscount(
            row.valor,
            row.cantidad,
            tempDiscount.porcentaje_item,
            tempDiscount.tipo_porcentaje
          )
        } else {
          tempamount += row.valor*row.cantidad;
        }
      }
    });
    Other.map((row) =>{
      if (row.fuera_paquete === true ){
        const tempDiscount = dictDiscountQuotation[`${row.id_prestaciones?.id}_prestacion`]
        if(tempDiscount){
          tempamount += calculateIndividualDiscount(
            row.valor,
            row.cantidad,
            tempDiscount.porcentaje_item,
            tempDiscount.tipo_porcentaje
          )
        } else {
          tempamount += row.valor*row.cantidad;
        }
      }
    });
    Equip.map((row) =>{
      if (row.fuera_paquete === true ){
        const tempDiscount = dictDiscountQuotation[`${row.id}_equipo`]
        if(tempDiscount){
          tempamount += calculateIndividualDiscount(
            row.valor,
            row.cantidad,
            tempDiscount.porcentaje_item,
            tempDiscount.tipo_porcentaje
          )
        } else {
          tempamount += row.valor*row.cantidad;
        }
      }
    });
    Professional.map((row) =>{
      if (row.fuera_paquete === true ){
        const tempDiscount = dictDiscountQuotation[`${row.id_prestaciones?.id}_prestacion`]
        if(tempDiscount){
          tempamount += calculateIndividualDiscount(
            row.valor,
            row.cantidad,
            tempDiscount.porcentaje_item,
            tempDiscount.tipo_porcentaje
          )
        } else {
          tempamount += row.valor*row.cantidad;
        }
      }
    });
    setamount(tempamount);
  };

  const calculateGeneralDiscount = (value) => {
    if (quotationDiscount === 0){
      return `Sin descuento`
    }

    const tempValue = quotationDiscount === 0 ? value :
    typeQuotationDiscount === "Descuento" ? value - (value*(quotationDiscount/100)) :
    value + (value*(quotationDiscount/100));
    return `(${typeQuotationDiscount === "Descuento" ? "-" : "+"}${quotationDiscount}%) ${toMoneyFormat(tempValue)}`
  }

  const handleUpload = () => {
    const tempResume = {...resumeQuotation}
    delete tempResume["View"]
    const requestPatient = api.put(`/api/clinica/solicitudes/${viewQuotation.requestPatientInfo.id}/cotizacion/finalizar/`, {});
    Promise.all([
      requestPatient,
    ]).then((request) => {
      setmessageGeneral(dispatch, "Cotización finalizada", "succes", "¡Exito!");
      getListRequestPatient(dataPatient, dispatch)
      handleReload();
    })
  };

  const handleUploadSkip = (date) => {
    const tempResume = {...resumeQuotation}
    delete tempResume["View"]

    const body = {
      fecha_pedidos: moment(date).format("YYYY-MM-DD"),
      actualizar_paquete: packageData.actualizar_paquete,
    }
    api.post(`/api/clinica/solicitudes/${viewQuotation.requestPatientInfo.id}/cotizacion/saltar/habilitacion/`, body).then((response) =>{
      setmessageGeneral(dispatch, "Cotización finalizada", "succes", "¡Exito!");
      getListRequestPatient(dataPatient, dispatch);
      handleReload();
    })
  };

  const printDocument = () => {
    api.get(`api/paciente/solicitud/${viewQuotation.requestPatientInfo.id}/cotizacion/descargar/`, { responseType: "blob" }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // Create a link element and click it to trigger download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Cotizacion.xlsx';
      link.click();
    })
  };

  return (
    <Card>
      <CardBody>
        <GridContainer id="pdfdiv" style={{padding:"15px"}}>
          <GridContainer
            direction="row"
            justify="center"
            alignItems="center"
            item xs={12}
          >
            <Typography variant="body2" gutterBottom>
              {`Se solicitará la cotización del paciente ${dataPatient.nombre} ${dataPatient.apellido_paterno} de la Isapre ${forecast.label} sujeto a:`}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {`sujeto al convenio: ${resumeQuotation.packageBalanced.id_paquetizacion.nombre}, paquete ${resumeQuotation.packageBalanced.zona} ${resumeQuotation.packageBalanced.complejidad}.`}
            </Typography>
            {resumeQuotation.packageUnBalanced &&
            <Typography variant="body2" gutterBottom>
              {`sujeto al convenio (SOS): ${resumeQuotation.packageUnBalanced.id_paquetizacion.nombre}, paquete ${resumeQuotation.packageUnBalanced.zona} ${resumeQuotation.packageUnBalanced.complejidad}.`}
            </Typography>
            }
          </GridContainer>
          <GridItem
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            xs={12}
            style={{margin: "30px 0 0 0"}}
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              item
              xs={6}
            >
              <Typography variant="body2" gutterBottom>
                Dia cama
              </Typography>
              <Typography  variant="body2" gutterBottom>
                Total mensual
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-end"
              item
              xs={6}
            >
              <Typography variant="body2" gutterBottom>
                {`${toMoneyFormat(packageData.dia_cama)}`}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {`${toMoneyFormat(totalMonth)}`}
              </Typography>
            </Grid>
          </GridItem>
          <GridItem
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            xs={12}
            style={{margin: "30px 0 0 0"}}
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              item
              xs={6}
            >
              <Typography variant="body2" gutterBottom>
                Extra a permanencia
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-end"
              item
              xs={6}
            >
              <Typography  variant="body2" gutterBottom>
              {`${toMoneyFormat(amount)}`}
              </Typography>
            </Grid>
          </GridItem>
        </GridContainer>
        {resumeQuotation.packageUnbalanced &&
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          item
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                  checked={showPackage}
                  onChange={() => setshowPackage(!showPackage)}
              />
            }
            label={`Paquete con descompensación ${packageData?.descompensacion ? "activado" : "desactivado"}`}
          />
        </Grid>
        }
        <Grid item xs={12} style={{margin: "30px 0 0 0"}}>
          <Table className="table table-striped">
            <TableHead>
              <TableRow>
                <TableCell className="text-center align-middle">Grupo </TableCell>
                <TableCell className="text-center align-middle">Item </TableCell>
                <TableCell className="text-center align-middle">Tipo cantidad </TableCell>
                <TableCell className="text-center align-middle">Cantidad </TableCell>
                <TableCell className="text-center align-middle">Cada/Duración </TableCell>
                <TableCell className="text-center align-middle">Tiempo </TableCell>
                <TableCell className="text-center align-middle">Area a la que solicitará </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Professional.map((row, index) => (
                <TableRow
                  key={`${index}-professionalRow`}
                >
                  { index===0 && <TableCell rowSpan={Professional.length} className="text-center align-middle">Profesionales </TableCell>}
                  <TableCell className="text-center align-middle">{`${row.id_prestaciones.nombre} ${transient ? row.horario_inhabil === true ?  "Inhabil" :  row.horario_inhabil === false ? "Habil" : "" : ""}`} </TableCell>
                  <TableCell className="text-center align-middle">{row.id_prestaciones.tipo_cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{dictPosition[row.id_prestaciones.id_cargo]?.area} </TableCell>
                </TableRow>
              ))}
              {Medical.map((row, index) => (
                <TableRow
                  key={`${index}-medicalRow`}
                >
                  { index===0 && <TableCell rowSpan={Medical.length} className="text-center align-middle">Medicamentos </TableCell>}
                  <TableCell className="text-center align-middle">{row.id_insumo_medicamento.SKU} </TableCell>
                  <TableCell className="text-center align-middle">{row.id_insumo_medicamento.tipo_cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{"Operaciones - Farmacia"} </TableCell>
                </TableRow>
              ))}
              {Resourse.map((row, index) => (
                <TableRow
                  key={`${index}-resourseRow`}
                >
                  { index===0 && <TableCell rowSpan={Resourse.length} className="text-center align-middle">Insumos </TableCell>}
                  <TableCell className="text-center align-middle">{row.id_insumo_medicamento.SKU} </TableCell>
                  <TableCell className="text-center align-middle">{row.id_insumo_medicamento.tipo_cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{"Operaciones"} </TableCell>
                </TableRow>
              ))}
              {Equip.map((row, index) => (
                <TableRow
                  key={`${index}-equipRow`}
                >
                  { index===0 && <TableCell rowSpan={Equip.length} className="text-center align-middle">Equipamiento </TableCell>}
                  <TableCell className="text-center align-middle">{`(${row.id_equipo.SKU}) ${row.id_equipo.descripcion}`} </TableCell>
                  <TableCell className="text-center align-middle">{"Unidad"} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{"Operaciones"} </TableCell>
                </TableRow>
              ))}
              {Emergency.map((row, index) => (
                <TableRow
                  key={`${index}-technicalRow`}
                >
                  { index===0 && <TableCell rowSpan={Emergency.length} className="text-center align-middle">Emergencia </TableCell>}
                  <TableCell className="text-center align-middle">{row.id_prestaciones.nombre} </TableCell>
                  <TableCell className="text-center align-middle">{row.id_prestaciones.tipo_cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{"Operaciones"} </TableCell>
                </TableRow>
              ))}
               {Other.map((row, index) => (
                <TableRow
                  key={`${index}-technicalRow`}
                >
                  { index===0 && <TableCell rowSpan={Other.length} className="text-center align-middle">Emergencia </TableCell>}
                  <TableCell className="text-center align-middle">{row.id_prestaciones.nombre} </TableCell>
                  <TableCell className="text-center align-middle">{row.id_prestaciones.tipo_cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{"Otros"} </TableCell>
                </TableRow>
              ))}
              <TableRow >
                  <TableCell className="text-center align-middle">Coordinación cliente </TableCell>
                  <TableCell className="text-center align-middle">contrato cliente </TableCell>
                  <TableCell className="text-center align-middle">1 </TableCell>
                  <TableCell className="text-center align-middle"> </TableCell>
                  <TableCell className="text-center align-middle"> </TableCell>
                  <TableCell className="text-center align-middle"> </TableCell>
                  <TableCell className="text-center align-middle">Facturación y Cobranzas </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <GridItem
            container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{margin: "30px 0 0 0"}}
          xs={12}
        >
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={6}
          >
            <Typography variant="body2" gutterBottom>
              Total cotización
            </Typography>
            <Typography variant="body2" gutterBottom>
              Total Descuento/Recargo
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-end"
            item
            xs={6}
          >
            <Typography variant="body2" gutterBottom>
            {`${toMoneyFormat(totalMonth+amount)}`}
            </Typography>
            <Typography variant="body2" gutterBottom>
            {`${calculateGeneralDiscount(totalMonth+amount)}`}
            </Typography>
          </Grid>
        </GridItem>
        <GridContainer>
          <GridItem xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "0 0 20px 0"}}
          >
            {!packageData.actualizar_paquete &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => setopenModal(true)}
              style={{margin: "0 5px"}}
            >
              Finalizar cotización
            </Button>
            }
            {!packageData.actualizar_paquete ?
            <Button
              variant="contained"
              color="primary"
              onClick={() => setopenModalSkip(true)}
              style={{margin: "0 5px"}}
            >
              Habilitar directamente
            </Button> :
              <Button
              variant="contained"
              color="primary"
              onClick={() => setopenModalSkip(true)}
              style={{margin: "0 5px"}}
            >
              Actualizar paquete paciente
            </Button>
            }
            <Button
              variant="contained"
              onClick={printDocument}
              color="primary"
              style={{margin: "0 5px"}}
            >
              Descargar
            </Button>

          </GridItem>
        </GridContainer>
      </CardBody>
      {openModal &&
        <ModalSaveQuotation
          open={openModal}
          handleClose={() => setopenModal(false)}
          handleUpload={handleUpload}
        />
      }
      {openModalSkip &&
        <ModalQuotationSkip
          open={openModalSkip}
          handleClose={() => setopenModalSkip(false)}
          handleUpload={handleUploadSkip}
          updatePackaging={!!packageData.actualizar_paquete}
        />
      }
    </Card>
  );
}
