import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import TableComponent from "views/Widgets/Common/TableComponent";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Edit, Delete } from "@material-ui/icons/";
import { red, indigo } from "@material-ui/core/colors";
import NumberFormat from "react-number-format";
import api from "utils/API";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import ModalDeleteItemTable from "components/PatientFile/ModalDeleteItemTable";
import {toMoneyFormat} from "utils/formatFunctions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const hours_utc = parseInt(moment().tz('America/Santiago').format('z'))*-1

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};



const ModalEditPersonal = props => {
  const dispatch = useDispatch();
  const {
    openModal,
    closeModal,
    tempRow,
    setauxData,
    dataArray,
    finishArray,
    setFinishArray
  } = props;

  const [listProffesional, setlistProffesional] = useState([])
  const [listChanges, setlistChanges] = useState([])
  const [listDeleteItem, setlistDeleteItem] = useState([])
  const [openDeleteModal, setopenDeleteModal] = useState(null);
  const getIndex = dataArray.findIndex(row => row.id === tempRow.id);

  const dictCharges = useSelector(state => state.currentList.dictCharges);

  useEffect(() => {
    initialData()
  }, []);

  const initialData = () => {
    api.get(`api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/editar/profesionaleextras/${tempRow.id_solicitud_paciente.id}/`).then((response) => {
      setlistProffesional(response.data["tareas"])
      setlistChanges(response.data["cambios"])
    })
  }

  const uploadAuthorization = () => {;

    api.patch(`api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/tarea/editar/profesionaleextras/${tempRow.id}/enviar/`, {eliminar_tareas: listDeleteItem}).then(() => {

        let tempArray = dataArray;
        let tempFinish = finishArray;
        tempArray[getIndex].estado = "Finalizado";
        tempFinish.push(tempArray[getIndex]);
        setFinishArray(tempFinish);
        tempArray.splice(getIndex, 1);
        setauxData(tempArray);
        closeModal();
      })

  };

  const handleDeleteRow = () => {
    const tempList = [...listDeleteItem ]
    tempList.push(openDeleteModal.subRow.id)
    setlistDeleteItem(tempList)
    setopenDeleteModal(null)
}

  const headers = [
    {label: "Cargo"},
    {label: "Profesionales aprobados"},
    {label: "Profesionales solicitados"},
    {label: "Horario"},
    {label: "Valor atención"},
    {label: "Total"},
  ];

  const subHeaders = [
    {eval:false, label: "ID"},
    {eval:false, label: "Profesional Asignado"},
    {eval:false, label: "Fecha"},  
    {eval:false, label: "Estado"},  
    {eval:false, label: "Acción"},
  ];

  const data = listChanges.map((row, index) => {
    const selectCharge =  dictCharges[row.id_cargo_asignado_id]
    const tempData = listProffesional.filter((subRow) => (
      row.id_cargo_asignado_id == subRow.id_cargo_asignado && 
      subRow.horario_habil == row.horario_habil &&
      !listDeleteItem.includes(subRow.id)
    )).map((subRow, subIndex) => {
      const _buttonDelete_ =  <Button
      variant="outlined"
      style={{
        color: red[500],
        borderColor: red[500],
      }}
      onClick={() => setopenDeleteModal({subRow, index: subIndex})}
      startIcon={<Delete style={{color: red[500]}}/>}
    >
      Eliminar
    </Button>;

      return [
        {value: subRow.id},
        {value: `${subRow.id_usuarios__first_name || "No"} ${subRow.id_usuarios__last_name || "Asignado"}`},
        {value: moment(subRow.fecha_hora).add(hours_utc, "hours").format("YYYY-MM-DD HH:mm:ss")},
        {value: subRow.estado},
        {value: _buttonDelete_},
      ]
    })

    
    return {
      data: [
        {value: selectCharge["nombre_corto"] },
        {value: row.cantidad},
        {value: tempData.length},
        {value: row.horario_habil ? "Habil": "Inhabil"},
        {value: toMoneyFormat(row.valor_atencion)},
        {value: toMoneyFormat(row.valor_atencion*row.cantidad) },
        {eval: false, value: true, displayRow: row.id}
      ],
      displayRow: row.id,
      subComponent: tempData.length > row.cantidad ? <TableComponent
          headers={subHeaders}
          data={tempData}
      /> : "Cambios completados"
  }
});

  const _content_ =  <div>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      item
      xs={12}
      style={{margin: "0 0 32px 0"}}
    >
      <TableComponent
          headers={headers}
          data={data}
      />
    </Grid>

  </div>

  const _actions_ = <>
    <Button
      disabled={false}
      variant="contained"
      color="primary"
      style={{ textTransform: "none" }}
      onClick={uploadAuthorization}
    >
      Guardar solicitud
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={closeModal}
      style={{ textTransform: "none" }}
    >
      Cerrar
    </Button>
  </>

  return (
    <>

    <ModalDialog
        open={openModal}
        onClose={closeModal}
        title={`Editar personal extra`}
        _content_={_content_}
        _actions_ = {_actions_}
        maxWidth="xl"
    />
     {openDeleteModal && (
        <ModalDeleteItemTable
          open={!!openDeleteModal}
          handleClose={() => setopenDeleteModal(null)}
          handleDelete={handleDeleteRow}
          title={`Eliminar asignacion ${openDeleteModal.subRow.id}`}
          itemType={"asignación"}
        />
      )}
    </>

  );
};

export default ModalEditPersonal;
