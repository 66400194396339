import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import OrderDetailInfo from "./OrderDetail";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core/";
import ModalMessageNotification from "./ModalMessageNotification";
import Grid from "@material-ui/core/Grid";
import ModalMessageSuccess from "./ModalMessageSuccess";
import ModalMessageIncomplete from "./ModalMessageIncomplete";
import ModalError from "./ModalError";
import ModalMessageFiles from "./ModalMessageFiles";
import MaterialTable from "material-table";


export default function RequestDetail() {
  const { id } = useParams();
  const id_order = parseInt(id, 10);
  const [moduleType, setmoduleType] = useState("");
  const [loading, setLoading] = useState(false);

  const [changes, setChanges] = useState(true);
  const [modalMessage, setModalMessage] = useState(false);
  const [file, setFile] = useState(null);
  const [observation, setObservation] = useState("");
  const [openModalMessageSuccess, setOpenModalMessageSuccess] = useState(false);
  const [messageModalMessageSuccess, setMessageModalMessageSuccess] = useState("");
  const [openModalMessageIncomplete, setOpenModalMessageIncomplete] = useState(false);
  const [messageModalMessageIncomplete, setMessageModalMessageIncomplete] = useState("");
  const [productList, setProductList] = useState([]);
  const [incomeRequest, setIncomeRequest] = useState();
  const [openModalError, setOpenModalError] = useState(false);
  const [openModalMessageFiles, setOpenModalMessageFiles] = useState(false);


  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  }, []);

  const initialChargeReception = () => {
    setChanges(false);

    const tempArrayURL = window.location.href.split('/');
    const tempModuleType = tempArrayURL[tempArrayURL.length-2];
    setmoduleType(tempModuleType);
    const requestDetail = api.get(
      `api/ingresos/solicitud/${id_order}/detalle/`
    );
    const requestIncomeProducts = api.get(
      `api/ingresos/solicitud/${id_order}/productos/`
    );
    Promise.all([requestDetail, requestIncomeProducts])
      .then(response => {
        setIncomeRequest(response[0].data);
        setProductList(response[1].data)

      })

    setLoading(true);
  };

  const modalMessageSuccess = (message, open) => {
    setMessageModalMessageSuccess(message);
    setOpenModalMessageSuccess(open);
  };
  const handleChangeObservation = (event) => {
    setObservation(event.target.value);
  };

  const modalMessageIncomplete = (message, open) => {
    setMessageModalMessageIncomplete(message);
    setOpenModalMessageIncomplete(open);
  };

  function sendReception() {

    const formData = new FormData();

    formData.append("nombre", file.name);
    formData.append("tipo_documento", "GuiaIngreso");
    formData.append("archivo", file);
    formData.append("area", "Ingresos");
    formData.append("observacion", observation);
    formData.append("id_solicitud", incomeRequest.id);
    let notification_area = " ";
    if (incomeRequest.id_solicitud_paciente) {
      api.patch(
        `/api/solicitudpaciente/${incomeRequest.id_solicitud_paciente}`,
        {
          estado: "EsperandoIngresos"
        }
      )
    }

    if (
      incomeRequest.tipo_producto === "Equipo" ||
      incomeRequest.tipo_producto === "Medicamento"
    ) {
      notification_area = "NotificarClinica";
    } else {
      notification_area = "NotificarBodega";
    }
    apiform
      .post("api/documentosingresos/", formData)
      .then(response => {
        api
          .patch(`api/solicitudingresos/${incomeRequest.id}`, {
            guia_ingresos: true,
            estado_solicitud: notification_area,
            estado_ingreso: notification_area,
            estado_transporte: "Finalizado",
            estado_bodega: "EsperandoIngresos"
          })
          .then(response => {

            goBack();
          })
          .catch(error => {

            goBack();
          });


      })

  }
  function sendDeliveryGuide() {

    const formData = new FormData();

    formData.append("nombre", file.name);
    formData.append("tipo_documento", "GuiaRecepcion");
    formData.append("archivo", file);
    formData.append("area", "Ingresos");
    formData.append("observacion", observation);
    formData.append("id_solicitud", incomeRequest.id);

    let nuevo_estado_solicitud = "";
    let nuevo_estado_ingreso = "";
    let nuevo_estado_bodega = "";
    if (incomeRequest.guia_bodega === true) {
      nuevo_estado_solicitud = "IngresarBodega";
      nuevo_estado_ingreso = "Finalizado";
      nuevo_estado_bodega = "IngresarBodega";
    } else {
      nuevo_estado_solicitud = "EsperandoGuiaBodega";
      nuevo_estado_ingreso = "EsperandoGuiaBodega";
      nuevo_estado_bodega = "EsperandoGuiaBodega";
    }
    apiform
      .post("api/documentosingresos/", formData)
      .then(response => {
        api
          .patch(`api/solicitudingresos/${incomeRequest.id}`, {
            estado_solicitud: nuevo_estado_solicitud,
            estado_ingreso: nuevo_estado_ingreso,
            estado_bodega: nuevo_estado_bodega
          })
          .then(response => {

            goBack();
          })
          .catch(error => {

            goBack();
          });
      })

  }
  function sendClinicNotification() {

    api
      .post(`api/ingresos/notificar/${incomeRequest.id}/`)
      .then(response => {

        goBack()
      })
      .catch(error => {

        goBack()
      });
  }
  function sendWarehouseNotification() {

    api
      .patch(`api/solicitudingresos/${incomeRequest.id}`, {
        estado_solicitud: 'EsperandoGuiaEntrega',
        estado_ingreso: 'EsperandoGuiaEntrega',
        estado_bodega: 'EsperandoGuiaEntrega'
      })
      .then(response => {
        if (incomeRequest.id_solicitud_paciente) {
          api
            .patch(
              `/api/solicitudpaciente/${incomeRequest.id_solicitud_paciente}`,
              { estado: "EsperandoBodega" }
            )
            .then(response => {

              goBack();
            })
            .catch(error => {

              goBack();
            });
        } else {

          goBack();
        }
      })
      .catch(error => {

        goBack();
      });
  }

  const checkFiles = () => {

    api
      .post(`api/bodega/ingresos/${id}/finalizar/`)
      .then(response => {

        modalMessageSuccess("Se guardaron exitosamente los registros", true);
      })
      .catch(error => {

        setOpenModalMessageFiles(true);
      });
  };

  const goBack = () => {
    window.location.href = `/admin/income/productsincome/${moduleType}`;
  };

  return (
    <GridContainer>
      <ViewTitle
        title="DETALLE COMPRA / DEVOLUCIÓN"
        message="En esta sección podrás revisar el detalle de la compra o devolución seleccionada."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" href={`/admin/income/tasks/${moduleType}`}>
              {`Tareas de ${moduleType === "storage" ? "Ingreso" : "Ingreso Farmacia"}`}
            </Link>
            <Link color="inherit" href={`/admin/income/productsincome/${moduleType}`}>
              Recepción de pedidos
            </Link>
            <Typography color="textPrimary">Detalle solicitud</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {productList ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <OrderDetailInfo
                id={id}
                tipo={incomeRequest?.tipo_origen+' '+incomeRequest?.tipo_producto}
                cantidad={incomeRequest?.cantidad_productos}
                observacion={incomeRequest?.observacion}
                fechaLlegada={incomeRequest?.fecha_tentativa_llegada}
                estado={incomeRequest?.estado_ingreso}
              />
            </GridContainer>
            <CardBody>
              <div>
                {productList && (
                  <MaterialTable
                    title="Productos solicitud ingreso"
                    localization={{
                      pagination: {
                        labelRowsSelect: "Filas",
                        labelDisplayedRows: "{count} de {from}-{to}",
                        firstTooltip: "Primera página",
                        previousTooltip: "Página anterior",
                        nextTooltip: "Próxima página",
                        lastTooltip: "Última página"
                      },
                      toolbar: {
                        nRowsSelected: "{0} Equípo(s) seleccionados",
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                        showColumnsTitle: "Mostrar Columnas"
                      },
                      body: {
                        emptyDataSourceMessage: "No hay datos para mostrar",
                        filterRow: {
                          filterTooltip: "Filtrar"
                        }
                      }
                    }}
                    columns={[
                      {
                        title: "Id",
                        field: "id",
                        filtering: false,
                        sorting: true,
                        align: "justify"
                      },
                      {
                        title: "SKU",
                        field: "sku",
                        filtering: true,
                        sorting: true,
                        align: "justify"
                      },
                      {
                        title: "Descripcion",
                        field: "descripcion",
                        filtering: true,
                        sorting: true,
                        align: "justify"
                      },
                      {
                        title: "Cantidad",
                        field: "cantidad",
                        filtering: true,
                        sorting: true,
                        align: "justify"
                      },
                      {
                        title: "observaciones solicitud",
                        field: "observaciones",
                        sorting: true,
                        filtering: true,
                        align: "justify"
                      },
                      {
                        title: "estado recepcion",
                        field: "estado_recepcion",
                        sorting: true,
                        filtering: true,
                        align: "justify"
                      },
                      {
                        title: "recepcion",
                        field: "recepcion",
                        sorting: true,
                        filtering: true,
                        align: "justify"
                      },
                      {
                        title: "observaciones producto",
                        field: "observacion_producto",
                        sorting: true,
                        filtering: true,
                        align: "justify"
                      }
                    ]}
                    data={productList}
                    options={{
                      selection: false,
                      showTitle: false,
                      filtering: true,
                      search: false,
                      doubleHorizontalScroll: true,
                      maxBodyHeight: "350px",
                      actionsColumnIndex: -1
                    }}
                  />
                )}
                {(incomeRequest &&
                  incomeRequest.estado_ingreso === "AdjuntarGuiaIngreso") ||
                (incomeRequest &&
                  incomeRequest.estado_ingreso === "EsperandoGuiaEntrega") ? (
                  <div>
                    <GridContainer justify="center" className="m-3 mt-3 mb-3">
                      <Grid container xs={12} style={{ textAlign: "center" }}>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          <TextField
                            required
                            id="outlined-name"
                            multiline
                            label="OBSERVACIONES:"
                            placeholder="Observaciones"
                            value={observation}
                            variant="outlined"
                            onChange={handleChangeObservation}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2} />
                      </Grid>
                    </GridContainer>
                    <GridContainer
                      justify="center"
                      style={{ textAlign: "center" }}
                      className="m-3 mt-3 mb-3"
                    >
                      <div className="col-md-4 mb-3">
                        <div className="card">
                          <div className="card-body">
                            <span className="card-title">ARCHIVO:</span>
                            <p className="card-text">
                              Archivo: {file ? file.name : null}
                            </p>
                            <span className="btn btn-cmh-color btn-file">
                              Seleccionar
                              <input
                                type="file"
                                name={file}
                                onChange={e => {
                                  setFile(e.target.files[0]);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </GridContainer>
                  </div>
                ) : null}
                <GridContainer justify="center" className="mt-3 mb-6">
                  {incomeRequest &&
                  incomeRequest.estado_ingreso === "AdjuntarGuiaIngreso" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => sendReception()}
                      className="m-1"
                    >
                      Aceptar recepcion
                    </Button>
                  ) : incomeRequest &&
                    incomeRequest.estado_ingreso === "NotificarClinica" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => sendClinicNotification()}
                      className="m-1"
                    >
                      Notificar area clinica
                    </Button>
                  ) : incomeRequest &&
                    incomeRequest.estado_ingreso === "NotificarBodega" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => sendWarehouseNotification()}
                      className="m-1"
                    >
                      Notificar bodega
                    </Button>
                  ) : incomeRequest &&
                    incomeRequest.estado_ingreso === "EsperandoGuiaEntrega" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => sendDeliveryGuide()}
                      className="m-1"
                    >
                      Adjuntar guia de entrega
                    </Button>
                  ) : null}
                </GridContainer>
              </div>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}

      </GridItem>
      {modalMessage && (
        <ModalMessageNotification
          open={modalMessage}
          closeModal={() => setModalMessage(false)}
          moduleType={moduleType}
        />
      )}
      {openModalMessageSuccess && (
        <ModalMessageSuccess
          open={openModalMessageSuccess}
          message={messageModalMessageSuccess}
          moduleType={moduleType}
        />
      )}
      {openModalMessageIncomplete && (
        <ModalMessageIncomplete
          open={openModalMessageIncomplete}
          message={messageModalMessageIncomplete}
          id={id}
          moduleType={moduleType}
        />
      )}
      {openModalMessageFiles && (
        <ModalMessageFiles
          open={openModalMessageFiles}
          closeModal={() => setOpenModalMessageFiles(false)}
          moduleType={moduleType}
        />
      )}
      {openModalError && <ModalError open={openModalError} moduleType={moduleType}/>}
    </GridContainer>
  );
}
