import React, { useState, useEffect } from "react";
import {
    Button,
    Grid,
} from "@material-ui/core";
import {
    TypographyMui,
} from "utils/componentsStyle"
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from 'moment-timezone';
import 'moment/locale/es' ;
moment.locale("es");

export default function ModalDeleteItem({
    open,
    handleClose,
    handleDelete,
    title="ELIMINAR MEDICAMENTOS",
    itemType="item"
}) {

    const _content_ = <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
    >
        <TypographyMui variant='body2'>
            {`¿Estás seguro que deseas eliminar este ${itemType}?`}
        </TypographyMui>
    </Grid>

    const _actions_ = <>
        <Button
            color="primary"
            variant="contained"
            onClick={handleClose}
        >
            Cerrar
        </Button>
        <Button
            color="primary"
            variant="contained"
            onClick={handleDelete}
        >
            Eliminar
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={title}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xs"
        />
    );
};

