import React, {useState, useEffect} from 'react';
import BreadScrumbs from 'views/Widgets/Common/BreadScrumbs';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import QuotationPackaging from "./QuotationPackaging";

export default function DocumentQuotation({
  dataPatient,
  viewQuotation,
  resumeQuotation,
  setopenModalQuotation,
  setselectPatient,
  setviewQuotation,
  settypeRequest,
  setresumeQuotation,
  handleReloadFile,
  forecast,
}) {

  const handleBack = (row) => {
    row.map((auxRow) => {
      auxRow(null);
    });
  };

  const handleReload = () => {
    handleReloadFile();
    handleBack([
      setopenModalQuotation,
      settypeRequest,
      setviewQuotation,
      setresumeQuotation,
    ]);
  }

  const listLink = [
    {label: "Ficha Paciente", link:{onClick: () =>  handleBack([setselectPatient,setopenModalQuotation,settypeRequest,setviewQuotation,setresumeQuotation, ])}},
    {label: `${dataPatient.nombre} ${dataPatient.apellido_paterno}`, link:{onClick: () =>  handleBack([setopenModalQuotation,settypeRequest,setviewQuotation, setresumeQuotation])}},
    {label: `Solicitudes`, link:{onClick: () =>  handleBack([setviewQuotation, settypeRequest, setresumeQuotation])}},
    {label: `Cotización`, link:{onClick: () =>  handleBack([setviewQuotation, setresumeQuotation])}},
    {label: "Asignación de recursos", link: { onClick: () =>  handleBack([setresumeQuotation])}},
    {label: "Documento"},
  ]

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridItem xs={12}>
          <CardBody className="rounded shadow border border-primary">
            <BreadScrumbs listLink={listLink}/>
          </CardBody>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardBody>
            {resumeQuotation.packageBalanced ?
            <QuotationPackaging
              viewQuotation={viewQuotation}
              resumeQuotation={resumeQuotation}
              dataPatient={dataPatient}
              handleReload={handleReload}
              forecast={forecast}
            />
            :
            <div></div>
            }
            </CardBody>
          </Card>
        </GridItem>
      </GridItem>
    </GridContainer>
  );
}