import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Modal,
    Backdrop,
    Paper,
    Button as ButtonMUI,
    Grid,
} from '@material-ui/core';
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import DetailDate from "views/Widgets/FichaPaciente/detailDate";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import {red, green, indigo} from "@material-ui/core/colors";
import {classStyle} from "../../utils/styleFunction";
import api from "utils/API";
import {
    getListSuppliesMedicines,
  } from "actions/getListAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const groupBadgeStyles = {
borderRadius: '2em',
display: 'inline-block',
fontSize: 12,
fontWeight: 'normal',
lineHeight: '1',
minWidth: 1,
padding: '0.16666666666667em 0.5em',
textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

export default function ModalEditWork({
    open,
    handleClose,
    listassigned,
    index,
    array,
    setArray,
    dataFile,
}) {
    const dispatch = useDispatch();
    const classesStyle = classStyle();
    const [rowWork, setrowWork] = useState(array[index])
    const [type, setType] = useState({});
    const [startDate, setstartDate] = useState(moment(rowWork.fecha_hora).format("YYYY-MM-DD HH:mm:ss"));
    const [assignedPosition, setassignedPosition] = useState({});
    const [assignedPeople, setassignedPeople] = useState({});
    const [listPeople, setlistPeople] = useState([]);
    const [description, setdescription] = useState(rowWork.descripcion);
    const [validation, setvalidation] = useState(false);
    const [medicine, setmedicine] = useState({});
    const [dose, setdose] = useState(1)
    const [typeAmount, settypeAmount] = useState({})
    const [changeButtons, setchangeButtons] = useState(true);
    const [chargeData, setchargeData] = useState(true)

    const listTypeAmountBenefit = useSelector(state => state.currentList.LISTA_TIPO_CANTIDAD_PRESTACION);
    const listTypeWorkFilePatient = useSelector(state => state.currentList.LISTA_TIPO_TAREAS_FICHA_PACIENTE);
    const dictTypeWorkFilePatient = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA_PACIENTE);
    const listMedical = useSelector(state => state.currentList.listMedicines);
    const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

    useEffect(() => {
        if(listMedical.length === 0) {
            getListSuppliesMedicines(dispatch, chargeMedicine)
        }
    }, [])

    useEffect(() => {
        validateForm();
        if (changeButtons) {
            setchangeButtons(false);
        }
        if (chargeData){
            initialInfo()
        }
    });

    const initialInfo = () => {
        setchargeData(false);
        setType(dictTypeWorkFilePatient[rowWork.tipo_tarea] || {});
        const tempAssigned = listassigned[0].options.filter((row) => row.value === rowWork.id_cargo_asignado);
        if (tempAssigned.length > 0){
            setassignedPosition(tempAssigned[0]);
            api.get(`/api/usuario/cargo/${tempAssigned[0].value}`).then((response) => {
                setlistPeople(response.data.map((row) => {
                    return {value: row.id, label: `(${row.rut}) ${row.first_name} ${row.last_name}`};
                }));

                if (rowWork.id_usuarios){
                    const tempUser = response.data.filter((row) => row.id === rowWork.id_usuarios);
                    if (tempUser.length > 0){
                        setassignedPeople({value: tempUser[0].id, label: `(${tempUser[0].rut}) ${tempUser[0].first_name} ${tempUser[0].last_name}`})
                    }
                }
            });
        }
    };

    const validateForm = () => {
        const arrayValidation = [
            (
                (
                    type.value !== undefined &&
                    type.label !== "Medicinas Habituales" &&
                    description !== ""
                ) ||
                (
                    type.label === "Medicinas Habituales" &&
                    (
                        medicine.label !== undefined &&
                        dose > 0 &&
                        typeAmount.value !== undefined
                    )  ||
                    description !== ""
                )
            ),
            (startDate !== "" &&  moment(startDate).diff(moment(),"hour") > 0 ),
            assignedPosition.value !== undefined,
        ];
        if (arrayValidation.every((item) => item === true)) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    };

    const handleOnChange = (value, set) => {
        set(value);
    };
    const handleSelectMedicine = (value) => {
        if(value.value){
            setmedicine(value);
        }
    };

    const updateValue = () => {
        let body = {
            tipo_tarea: type.value,
            fecha_hora: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
            id_cargo_asignado: assignedPosition.value,
            descripcion: `${(
                dose > 0 &&
                typeAmount.value !== undefined &&
                medicine.value !== undefined &&
                type.label === "Medicinas Habituales"
            )  ? `${medicine.label}, ${dose} ${typeAmount.label}. ` : "" } ${description}`,
            estado: "false",
            id_ficha: dataFile.id,
        }
        const tempArray = array;
        api.put(`api/tareaspaciente/${rowWork.id}`, body).then((result) => {
            tempArray[index] = result.data;
            setArray(tempArray)
            handleClose();
        });
    };

    const handleAssignedPosition = (value) => {
        api.get(`/api/usuario/cargo/${value.value}`).then((response) => {
            setlistPeople(response.data.map((row) => {
                return {value: row.id, label: `(${row.rut}) ${row.first_name} ${row.last_name}`};
            }));
        });
        setassignedPosition(value);
    };

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classesStyle.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classesStyle.paper} style={{width: "90%"}}>
            <h3 id="spring-modal-title">{`Nueva Tarea`}</h3>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                item
                xs={12}
            >
                <Grid
                    item
                    xs={4}
                >
                    <DetailSelect
                        label="Tipo"
                        value={type}
                        onchange={(value) => { handleOnChange(value, setType)}}
                        list={listTypeWorkFilePatient}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <DetailText
                        label={`Descripción`}
                        placeholder={`Descripción`}
                        value={description}
                        onchange={(e) => { handleOnChange(e.target.value, setdescription)}}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    {assignedPosition &&
                    <DetailSelect
                        label="Cargo asignado "
                        value={assignedPosition}
                        onchange={(value) => { handleAssignedPosition(value)}}
                        list={listassigned}
                        formatGroupLabel={formatGroupLabel}
                    />
                    }
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <DetailSelect
                        label="Persona asignada"
                        value={assignedPeople}
                        onchange={(value) => { handleOnChange(value, setassignedPeople)}}
                        list={listPeople}
                        formatGroupLabel={formatGroupLabel}
                        validation={false}
                    />
                </Grid>
                { type.label === "Medicinas Habituales" &&
                <Grid
                    item
                    xs={4}
                >
                    <DetailSelect
                        label="Medicamento"
                        value={medicine}
                        onchange={(value) => { handleSelectMedicine(value)}}
                        list={listMedical.length > 0 ? listMedical : [{value: null, label: "Cargando..."}]}
                        validation={description === ""}
                    />
                </Grid>
                }
                { type.label === "Medicinas Habituales" &&
                <Grid
                    item
                    xs={4}
                >
                     <DetailNumber
                        label={`Dosis`}
                        placeholder={`Dosis`}
                        value={dose}
                        onchange={(e) => { handleOnChange(e.target.value, setdose)}}
                        validation={description === ""}
                    />
                </Grid>
                }

                { type.label === "Medicinas Habituales" &&
                <Grid
                    item
                    xs={4}
                >
                     <DetailSelect
                        label="Tipo/Cantidad "
                        value={typeAmount}
                        onchange={(value) => { handleOnChange(value, settypeAmount)}}
                        list={listTypeAmountBenefit}
                        validation={description === ""}
                    />
                </Grid>
                }
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-end"
                    item
                    xs={12}
                >
                    <Grid
                        item
                        xs={4}
                    >
                        <DetailDate
                            label={"Fecha"}
                            placeholder="Fecha"
                            handleOnChange={setstartDate}
                            value={startDate}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 0 0"}}
                >
                    { validation ?
                    <ButtonMUI
                        mr={2}
                        variant="contained"
                        className="text-white"
                        style={{backgroundColor: green[500]}}
                        onClick={updateValue}
                    >
                        Guardar
                    </ButtonMUI>
                    :
                    <ButtonMUI
                        disabled
                        mr={2}
                        variant="contained"
                        className="text-white"
                    >
                        Guardar
                    </ButtonMUI>
                    }
                    <ButtonMUI
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cerrar
                    </ButtonMUI>
                </Grid>
            </Grid>
        </Paper>
      </Modal>
  );
}