import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";



export default function ModalAddRecord(props) {
  const { open, closeModal, idRequest, auxType, auxGroup } = props;


  const [firstCharge, setFirstCharge] = useState(true);
  const [products, setProducts] = useState([]);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const scroll = "paper";

  const initialCharge = () => {
    setFirstCharge(false);

    const getProducts = api.get(
      `api/compras/solicitudescompras/${auxType}/${auxGroup}/`
    );

    Promise.all([getProducts])
      .then(response => {
        let tempProducts = [];
        response[0].data.map(row => {
          tempProducts.push({
            ...row,
            show: true
          });
        });
        setProducts(tempProducts);

      })

  };

  function addRecord(idRecord, index) {

    let tempProducts = products;
    tempProducts[index].show = false;
    api
      .post(
        `api/compras/solicitudcotizacion/productosagrupados/${idRequest}/agregar/${idRecord}`
      )
      .then(() => {
        setProducts(tempProducts);

      })

  }

  const handleCloseModal = () => {
    const add = products.filter(row => row.show === false).length;
    console.log("add", add);
    if (add > 0) {
      window.location.href = `/admin/purchases/inprocess/detail/${idRequest}`;
      console.log("debería recargar página");
    } else {
      closeModal();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Agregar registro</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          <div className="mt-3 mb-3">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="text-center align-middle">Id</th>
                  <th className="text-center align-middle">SKU</th>
                  <th className="text-center align-middle">Descripción</th>
                  <th className="text-center align-middle">Grupo</th>
                  <th className="text-center align-middle">Cantidad</th>
                  <th className="text-center align-middle">Observaciones</th>
                  <th className="text-center align-middle">Seleccionar</th>
                </tr>
              </thead>
              <tbody>
                {products
                  .filter(row => row.show)
                  .map((product, index) => (
                    <tr key={product.id}>
                      <td className="text-center align-middle">{product.id}</td>
                      <td className="text-center align-middle">
                        {product.id_producto_comprado.SKU}
                      </td>
                      <td className="text-center align-middle">
                        {product.id_producto_comprado.descripcion_producto}
                      </td>
                      <td className="text-center align-middle">
                        {product.id_producto_comprado.grupo_producto}
                      </td>
                      <td className="text-center align-middle">
                        {product.cantidad}
                      </td>
                      <td className="text-center align-middle">
                        {product.observaciones}
                      </td>
                      <td className="text-center align-middle">
                        <Button
                          onClick={() => addRecord(product.id, index)}
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none" }}
                        >
                          Agregar
                        </Button>
                      </td>
                    </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseModal}
          color="primary"
          style={{ textTransform: "none" }}
          variant="contained"
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalAddRecord.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  idRequest: PropTypes.string,
  auxType: PropTypes.string,
  auxGroup: PropTypes.string
};
