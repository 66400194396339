import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailText from "views/Widgets/FichaPersonal/EditFichaPersonal/detailText";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";

export default function ModalCreateBenefit(props) {
  const { open, closeModal } = props;

  const [grupo, setGrupo] = useState("");
  const [code, setCode] = useState("");
  const [nombre, setNombre] = useState("");
  const [tipo_cantidad, setTipo_cantidad] = useState("");
  const [cargo, setCargo] = useState("");

  const [list_cargos, setlistcargos] = useState([]);

  const list_cantidades = useSelector(state => state.currentList.LISTA_TIPO_CANTIDAD_PRESTACION);
  const list_grupos = useSelector(state => state.currentList.LISTA_GRUPOS_PRESTACION);


  useEffect(() => {
    const requestListCharges = api.get("api/cargos/?profecionales_visita=True");

    Promise.all([requestListCharges]).then(
      request => {
        const tempListCharge = [];

        request[0].data.map(charges => {
          if (cargo === charges.id){
            setCargo({ value: charges.id, label: charges.nombre_corto });
          }
          tempListCharge.push({
            value: charges.id,
            label: charges.nombre_corto
          });
        });
        setlistcargos(tempListCharge);
    });
  }, []);

  const [scroll, setScroll] = useState("paper");

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function createBenefit() {
    const formData = new FormData();

    if (grupo) {
      formData.append("grupo", grupo.value);
    }
    if (tipo_cantidad) {
      formData.append("tipo_cantidad", tipo_cantidad.value);
    }
    if (cargo){
      formData.append("id_cargo", cargo.value);
    }

    formData.append("nombre", nombre);
    formData.append("codigo_prestacion", code);

    apiform
      .post("api/prestacionesclinica/", formData)
      .then(response => {
        window.location.href = "/admin/benefits/";
      })
      .catch(error =>{} );
  }

  const handleGrupo = grupo => {
    setGrupo(grupo);
  };

  const handleTipoCantidad = tipo_cantidad => {
    setTipo_cantidad(tipo_cantidad);
    if (["Turno12", "Turno24"].includes(tipo_cantidad.value) && grupo.value === "Profesionales"){
      setNombre(`${cargo.label} ${tipo_cantidad.label}`);
    }
  };

  const handleCargo = cargo => {
    setCargo(cargo);
    if (["Turno12", "Turno24"].includes(tipo_cantidad.value)){
      setNombre(`${cargo.label} ${tipo_cantidad.label}`);
    } else {
      setNombre(cargo.label);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullWidth={true}
      PaperProps={{
        style: {
          height: "100%",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">Agregar prestación</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form-row" style={{ marginBottom: "15rem" }}>
          <DetailSelect
            label="Grupo:"
            value={grupo}
            onchange={handleGrupo}
            list={list_grupos}
          />
          {grupo.value === "Profesionales" && (
            <DetailSelect
              label="Cargo asociado:"
              value={cargo}
              onchange={handleCargo}
              list={list_cargos}
            />
          )}
          {grupo.value === "Profesionales" ? (
            <DetailTextDisabled
              label="Nombre descriptivo de prestación:"
              placeholder="Nombre descriptivo de prestación"
              value={nombre}
            />
          ) : (
            <DetailText
              label="Nombre descriptivo de prestación:"
              placeholder="Nombre descriptivo de prestación"
              value={nombre}
              onchange={e => {
                setNombre(e.target.value);
              }}
            />
          )}
          <DetailText
            label="Codigo de prestación:"
            placeholder="Codigo de prestación"
            value={code}
            onchange={e => {
              setCode(e.target.value);
            }}
          />
          <DetailSelect
            label="Unidad de medida:"
            value={tipo_cantidad}
            onchange={handleTipoCantidad}
            list={list_cantidades}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Cancelar
        </Button>
        <Button
          onClick={createBenefit}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCreateBenefit.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool
};
