import axios from "axios";
import apilink from "./APIlink";
import store from "reducers/store"
import {dictError} from "utils/validationFunction"


const setmessageGeneral = (listObj, type, title, action=null, nameAction="") => {
  store.dispatch({
    type: "MENSAJE_GENERAL",
    payload: listObj
  });
  store.dispatch({
    type: "MENSAJE_TITULO",
    payload: title
  });
  store.dispatch({
    type: "TIPO_MENSAJE",
    payload: type
  });
  store.dispatch({
    type: "BOTON_ACCION_MENSAJE",
    payload: action
  });
  store.dispatch({
    type: "NOMBRE_BOTON_ACCION_MENSAJE",
    payload: nameAction
  });
};

const api = axios.create({
  baseURL: apilink,
  headers: {
    "Content-type": "application/json",
    "Authorization": `JWT ${localStorage.getItem("access")}`
  }
});

api.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    console.log("request error",error)
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.data){
      const data = error?.response?.data
      if (typeof data == "object"){
        Object.keys(data).map((key) => (alert(data[key])))
      }
      const message = `${dictError[error?.response?.status]}. "${error?.response?.config?.url}". Para continuar debe apretar el botón "OK/Aceptar"`
      setmessageGeneral(message, "error", "¡Atencion!")
    } else {
      if(error.message === 'Network Error'){
        const message = `
        Está presentando problemas de conexión, intente los siguientes pasos
        - Verifica que tu dispositivo esté conectado a Internet.
        - Si el problema persiste, intenta reiniciar tu dispositivo o tu router.
        `
        setmessageGeneral(message, "warning", "¡Atencion!")
      }
    }
    return Promise.reject(error);
  }
);


export default api;
