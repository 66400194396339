import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Slide,
  Grid
} from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import SvgIcon from "@material-ui/core/SvgIcon";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function OutIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="25" viewBox="2 2 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5874 8.77701L22.5904 12.7796L18.5874 16.7826" stroke="white" strokeWidth="2"/>
        <path d="M8.97253 12.7798L22.5904 12.7798" stroke="white" strokeWidth="2"/>
        <path d="M15.945 15.7684L15.945 17.7643C15.945 18.8689 15.0496 19.7643 13.945 19.7643L4 19.7643C2.89543 19.7643 2 18.8689 2 17.7643L2 7.76494C2 6.66037 2.89543 5.76494 4 5.76494L13.945 5.76494C15.0496 5.76494 15.945 6.66037 15.945 7.76494L15.945 9.78805" stroke="white" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
}
function InIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="25" viewBox="1 3 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6149 8.2478L14.6179 12.2504L10.6149 16.2534" stroke="white" strokeWidth="2"/>
        <path d="M5.64856 9.23164V7.23572C5.64856 6.13115 6.54399 5.23572 7.64856 5.23572H17.5936C18.6981 5.23572 19.5936 6.13115 19.5936 7.23572V17.2351C19.5936 18.3396 18.6981 19.2351 17.5936 19.2351H7.64856C6.54399 19.2351 5.64856 18.3396 5.64856 17.2351V15.2119" stroke="white" strokeWidth="2"/>
        <path d="M1 12.2506L14.6179 12.2506" stroke="white" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
}
export default class ReminderDialogAttendance extends React.Component {
  render() {
    return (
      <Paper mt={4}>
        <Dialog
          open={this.props.openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.props.handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth={false}
          maxWidth={"xs"}
        >
          <DialogTitle id="alert-dialog-slide-title">¡Recuerda!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>{this.props.message}</Grid>
                <Grid item>

                  {/* Dependiendo de si su ultimo registro de asistencia fue una salida o entrada, le recordara al usuario que es lo que debe marcar */}
                  {this.props.attendance === "logout" ? (
                    <Button
                      color="secondary"
                      size="small"
                      aria-label="Notifications"
                      aria-haspopup="true"
                      startIcon={<InIcon />}
                      style={{
                        backgroundColor: "#4CAF50",
                        color:"white",
                        textTransform: "None",
                        fontFamily: "Roboto",
                        fontStyle: "Normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "12px",
                        textAlign: "left",
                        letterSpacing: "0.15000000596046448px",
                        width: "230px"
                      }}
                    >
                      Registrar entrada
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      size="small"
                      aria-label="Notifications"
                      aria-haspopup="true"
                      startIcon={<OutIcon />}
                      style={{
                        backgroundColor: "#F44336",
                        color:"white",
                        textTransform: "None",
                        fontFamily: "Roboto",
                        fontStyle: "Normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "12px",
                        textAlign: "left",
                        letterSpacing: "0.15000000596046448px",
                        width: "230px"
                      }}
                    >
                      Registrar salida
                    </Button>
                )}
                </Grid>
                <Grid item>
                  {
                    "que encontrarás en el menú o en la parte superior de la pantalla."
                  }
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                textTransform: "none",
                backgroundColor: "#3F51B5",
                color: "white"
              }}
              variant="contained"
              onClick={this.props.handleCloseDialog}
              color="primary"
            >
              Entendido
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}
