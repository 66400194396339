import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Grid} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import allActions from "actions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function ModalStartTravel({
    open,
    handleClose,
    startTravel,
    rowsActive,
    setrowsActive,
    setmessage,
}) {
    const dispatch = useDispatch();
    const [tempRow, settempRow] = useState(startTravel[0]);
    const [allRequestPatient, setallRequestPatient] = useState([])

    console.log(rowsActive)

    useEffect(() => {
        if (tempRow.tipo_solicitud !== "Tramite"){
            setallRequestPatient(rowsActive.filter( (row) =>(
                row.estado_recepcion == tempRow.estado_recepcion && 
                moment(row.hora_estimada).format("YYYY-MM-DD") && moment(row.tempRow).format("YYYY-MM-DD") &&
                row.id_paciente === tempRow.id_paciente && 
                row.id_conductor === tempRow.id_conductor
            )))
        }
       
    }, []);

    const handleStartTravel = () => {
        const body = {};
        const body2 = {};
        body["estado_recepcion"] = "EnRuta";
        body2["estado"] = "EnRuta";
        const requestArray = [];
        let messageResult = "Iniciando viaje"

        if (allRequestPatient.length > 0){
            allRequestPatient.map((row) => {
                requestArray.push(api.patch(`/api/agendatransportes/${row.id}`, body));
                if (tempRow.id_envios_pedidos){
                    requestArray.push(api.patch(`/api/enviospedidos/${row.id_envios_pedidos.id}`, body2));
                }
            })
        } else {
            requestArray.push(api.patch(`/api/agendatransportes/${startTravel[0].id}`, body));
            if (tempRow.id_envios_pedidos){
                requestArray.push(api.patch(`/api/enviospedidos/${startTravel[0].id_envios_pedidos.id}`, body2));
            }
            if ((tempRow.estado_recepcion === "Asignado" && tempRow.tipo_solicitud === "Tramite") ){
                body["estado_recepcion"] = "Finalizado";
                body["hora_llegada"] = moment().format("YYYY-MM-DD HH:mm:ss");
                messageResult = "Solicitud Finalizada"
    
            }
        }
       
        Promise.all(requestArray).then((request) => {
            let tempRows = []
            if (tempRow.estado_recepcion === "Asignado" && tempRow.tipo_solicitud === "Tramite"){
                tempRows = rowsActive.filter((row) => (tempRow.id !== row.id))
            } else {
                const list_id = allRequestPatient.map((row) => row.id)
                tempRows = rowsActive.map((row) => {
                    const auxRow = row
                    if(list_id.includes(row.id)){
                        auxRow.estado_recepcion = "EnRuta";
                    }
                    return auxRow;
                });
            }
            setrowsActive(tempRows);
            setmessage(messageResult);
            handleClose();
        });
    };

    const list_message = allRequestPatient.map((row) => {
        const id_solicitud = row?.id_envios_pedidos?.id_solicitud_pedido
        const numero_pedido =  row?.id_envios_pedidos?.numero_pedido
        return `Solicitud ${id_solicitud} - Envio ${numero_pedido}`
    })

    const message = (tempRow.estado_recepcion === "Asignado" && tempRow.tipo_solicitud === "Tramite") ? "¿Desea dar por finalizado el trámite?" :
    "¿Estás seguro que desea iniciar el viaje?"

    const _content_ = <div>
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <h3 id="spring-modal-title">{message}</h3>
        </Grid>
        {list_message.map((row) => (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              item
              xs={12}
          >
              
              <h5 id="spring-modal-title">{row}</h5>
          </Grid>
        ))}
      
    </div>

    const _actions_ = <>
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: green[500], margin: "0 5px"}}
        onClick={handleStartTravel}
    >
        Sí
    </Button>
    <Button
        mr={2}
        variant="contained"
        style={{backgroundColor: red[500], margin: "0 5px"}}
        className="text-white"
        onClick={handleClose}
    >
        No
    </Button></>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Iniciar viaje`}
            _content_={_content_}
            _actions_ = {_actions_}
        />
  );
}