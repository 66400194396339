import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";




export default function ModalCancelRequest(props) {
  const { open, closeModal, id_order, idEnvioPedido, envioPedido } = props;
  const [scroll, setScroll] = useState("paper");
  const descriptionElementRef = React.useRef(null);


  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function updateBenefit() {

    const formData = new FormData();

    formData.append("estado", "Adjuntar guía");
    formData.append("estado_egreso", "Adjuntar guía");
    formData.append("numero_pedido", envioPedido.numero_pedido);

    apiform
      .put(`api/enviospedidos/${idEnvioPedido}`, formData)
      .then(response => {
        console.log(response);
        window.location.href = "/admin/outcome/receptions/";
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Cancelar solicitud</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <p className="text-center font-weight-bold">
          ¿Estás seguro que deseas cancelar la solicitud?
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          No
        </Button>
        <Button onClick={updateBenefit} color="primary">
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCancelRequest.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_order: PropTypes.number,
  idEnvioPedido: PropTypes.number,
  envioPedido: PropTypes.array
};
