import React, {useState} from 'react'
import moment from "moment-timezone";
import "moment/locale/es";
import api from 'utils/API';

import { TypographyMui } from 'utils/componentsStyle';
import ExamDetail from './ExamDetail';

import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Collapse } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import MinimizeIcon from '@material-ui/icons/Minimize';
import examName from '../../../../assets/img/icons/exams/nombre-examen.svg'
import folder from '../../../../assets/img/icons/exams/ver-documento.svg'
import calendar from '../../../../assets/img/icons/exams/fecha-de-toma.svg'
import state from '../../../../assets/img/icons/exams/estado.svg'

const examState = {
  EnEspecialista: { value: 'EnEspecialista', state: 'En especialista', color: '#E57373' },
  AdjuntarResultadoClinica: { value: 'AdjuntarResultadoClinica', state: 'Adjuntar resultados de la clínica', color: '#FDD835' },
  EnviadaCoordinadora: { value: 'EnviadaCoordinadora', state: 'En coordinador de enfermería', color: '#64B5F6' },
  EnRotativaRuta: { value: 'EnRotativaRuta', state: 'En coordinador de rotativa y ruta', color: '#BA68C8' },
  ExamenExterno: { value: 'ExamenExterno', state: 'Examen externo, no aplica', color: 'rgba(0, 0, 0, 0.36)' },
  Finalizado: { value: 'Finalizado', state: 'Finalizado', color: '#7BC67E' },
}

const findExamState = (state) => {
  const stateFinded = examState[state?.id_solicitud_paciente.estado]
  return { state: stateFinded?.state, color: stateFinded?.color }
}

const useStyles = makeStyles({
  listGrid: {
    display: 'grid',
    gridTemplateColumns: '40px 20% repeat(3, 1fr)',
    cursor: 'pointer'
  },
  dateWidth: {
    width: '100%'
  },
  mr8: {
    marginRight: 8
  },
  noAvailableText: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  lessIcon: {
    marginBottom: 15
  },

  '@media (max-width: 800px)': {
    listGrid: {
      gridTemplateColumns: '30px 25% repeat(3, 1fr)'
    },
    dateWidth: {
      width: 125
    }
  },
  dropdownOpen: {
    transform: "rotate(-180deg)",
    transition: "all 0.5s ease 0s",
  },
  dropdownClosed: {
    transform: "rotate(0)",
    transition: "all 0.5s ease 0s",
  }
});

function ExamsList({ examList, pagination, examInfo, openDetailExam }) {
  const classes = useStyles()
  const [orderColumn, setorderColumn] = useState('');
  const [directionColumn, setdirectionColumn] = useState(false)

  const getExamDocument = (currentExam) => {
    api.get(`api/documentospaciente/${currentExam.id_resultado_examen_id}`).then(response => {
      window.open(response.data.archivo)
    })
  }

  const handleOrder = (value) => {
    if (value !== orderColumn){
      setdirectionColumn(true)
    } else {
      setdirectionColumn(!directionColumn)
    }
    setorderColumn(value);
  }

  const handleOrderExam = (a,b) => {
    if (orderColumn) {
      let tempA = "";
      let tempB = "";
      if (orderColumn === "nombre"){
        if (a?.id_examen_clinica?.nombre === undefined && b?.id_examen_clinica?.nombre === undefined){
          return 0;
        } else if (a?.id_examen_clinica?.nombre === undefined){
          return -1;
        } else if (b?.id_examen_clinica?.nombre === undefined){
          return 1;
        }
        tempA = a?.id_examen_clinica?.nombre.toLocaleLowerCase();
        tempB = b?.id_examen_clinica?.nombre.toLocaleLowerCase();
      } else if (orderColumn === "fecha_toma_examen") {
        if (a?.fecha_toma_examen === null && b?.fecha_toma_examen === null){
          return 0;
        } else if (a?.fecha_toma_examen === null){
          return directionColumn ? -1 : 1;
        } else if (b?.fecha_toma_examen === null){
          return directionColumn ? 1 : -1;
        }
        tempA = moment(a?.fecha_toma_examen);
        tempB = moment(b?.fecha_toma_examen);
      } else if (orderColumn === "id_resultado_examen_id"){
        if (a.id_resultado_examen_id == null ){
          return directionColumn ? -1 : 1
        }
        if (b.id_resultado_examen_id == null ){
          return directionColumn ? 1 : -1
        }
        return 0
      } else if (orderColumn === "state") {
        if (a?.id_solicitud_paciente?.estado === undefined && b?.id_solicitud_paciente?.estado === undefined){
          return 0;
        } else if (a?.id_solicitud_paciente?.estado === undefined){
          return directionColumn ? -1 : 1;
        } else if (b?.id_solicitud_paciente?.estado === undefined){
          return directionColumn ? 1 : -1;
        }
        tempA = a?.id_solicitud_paciente?.estado;
        tempB = b?.id_solicitud_paciente?.estado;
      } else {
        tempA = a[orderColumn];
        tempB = b[orderColumn];
      }
      if (directionColumn){
        return tempA > tempB ? 1 : -1
      } else {
        return tempA < tempB ? 1 : -1
      }
    } else return 1
  }

  return (
    <>
      {/* Header */}
      <Box className={classes.listGrid} mt={3}>
        <Box />

        <Box color='primary'>
          <Box display='flex' alignItems='center' onClick={() => handleOrder("nombre")}>
            <Box mr={0.5}>
              <FilterListIcon
                fontSize='small'
                color='primary'
                className={orderColumn === "nombre" && directionColumn ? classes.dropdownOpen : classes.dropdownClosed}
              />
              <img src={examName} alt="examName" width='20px' height='30px' className='ml-2' />
            </Box>
            <TypographyMui variant='subtitle2' color='primary'>NOMBRE EXAMEN</TypographyMui>
          </Box>
        </Box>

        <Box color='primary' onClick={() => handleOrder("id_resultado_examen_id")}>
          <Box display='flex' alignItems='center'>
            <Box mr={0.2}>
              <FilterListIcon
                fontSize='small'
                color='primary'
                className={orderColumn === "id_resultado_examen_id" && directionColumn ? classes.dropdownOpen : classes.dropdownClosed}
              />
              <img src={folder} alt="folder" width='20px' height='30px' className='ml-2' />
            </Box>
            <TypographyMui variant='subtitle2' color='primary'>VER DOCUMENTO</TypographyMui>
          </Box>
        </Box>

        <Box color='primary' onClick={() => handleOrder("fecha_toma_examen")}>
          <Box display='flex' alignItems='center'>
            <Box mr={0.2}>
              <FilterListIcon
                fontSize='small'
                color='primary'
                className={orderColumn === "fecha_toma_examen" && directionColumn ? classes.dropdownOpen : classes.dropdownClosed}
              />
              <img src={calendar} alt="calendar" width='20px' height='30px' className='ml-2' />
            </Box>
            <TypographyMui variant='subtitle2' color='primary'>FECHA DE TOMA EXAMEN</TypographyMui>
          </Box>
        </Box>

        <Box color='primary' onClick={() => handleOrder("state")}>
          <Box display='flex' alignItems='center'>
            <Box mr={0.5}>
              <FilterListIcon
                fontSize='small'
                color='primary'
                className={orderColumn === "state" && directionColumn ? classes.dropdownOpen : classes.dropdownClosed}
              />
              <img src={state} alt="calendar" width='20px' height='30px' className='ml-2' />
            </Box>
            <TypographyMui variant='subtitle2' color='primary'>ESTADO DEL EXAMEN</TypographyMui>
          </Box>
        </Box>
      </Box>
      <hr />

      {/* List */}
      {examList.slice(
        pagination.page * pagination.rowsPerPage,
        pagination.page * pagination.rowsPerPage + pagination.rowsPerPage
      ).sort((a, b) => {
        return handleOrderExam(a,b)
      }).map(exam => {
        return (
          <Box key={exam.id}>
            <Box mt={1.5} className={classes.listGrid}>
              <Box alignSelf='center'>
                {examInfo?.id === exam.id ? <AddIcon color='primary' /> : <MinimizeIcon className={classes.lessIcon} color='primary' />}
              </Box>

              <Box alignSelf='center' px={0.5} onClick={() => openDetailExam(exam)}>
                <TypographyMui variant='body2'>{exam.id_examen_clinica.nombre}</TypographyMui>
              </Box>

              <Box alignSelf='center' px={0.5}>
                {exam.id_resultado_examen_id ?
                  <Button variant='outlined' color='primary' size='smal' fullWidth onClick={() => getExamDocument(exam)}>ver examen</Button>
                  :
                  <TypographyMui className={classes.noAvailableText} variant='body2'>Resultados no disponible</TypographyMui>
                }
              </Box>

              <Box alignSelf='center' px={0.5} onClick={() => openDetailExam(exam)}>
                <TypographyMui className={classes.noAvailableText} variant='body2'>
                  {exam?.fecha_toma_examen ? moment(exam?.fecha_toma_examen).format('DD-MM-YYYY') : 'Fecha no disponible' }
                </TypographyMui>
              </Box>

              <Box display='grid' gridTemplateColumns='20% 1fr' px={0.5} onClick={() => openDetailExam(exam)}>
                <Box width={20} height={20} borderRadius='50%' alignSelf='center' style={{ backgroundColor: findExamState(exam).color }} />
                <Box alignSelf='center'>
                  <TypographyMui
                    variant='body2'
                    style={exam?.id_solicitud_paciente?.estado === "ExamenExterno" ? {color: "rgba(0, 0, 0, 0.38)"} : {}}>
                      {findExamState(exam).state}
                    </TypographyMui>
                </Box>
              </Box>
            </Box>

            <Collapse in={examInfo?.id === exam.id}>
              <ExamDetail exam={exam} />
            </Collapse>
          </Box>
        )
      })}
      <hr />
    </>
  )
}

export default ExamsList
