const setlistTypeWorkFile = (listObj) => {
    return {
        type: "LISTA_TIPO_TAREAS_FICHA",
        payload: listObj
    }
}

const setDictTypeWorkFile = (listObj) => {
    return {
        type: "DICCIONARIO_TIPO_TAREAS_FICHA",
        payload: listObj
    }
}

export default {
    setlistTypeWorkFile,
    setDictTypeWorkFile,
}
