import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import TableProfesionals from "./TableProfesionals";

const Profesionales = props => {
  const {
    professionals,
    setProfessionals,
    professionalSelected,
    setProfessionalSelected,
    viewOnly,
    checkProfessionals,
    addProfessionals,
    setAddProfessionals,
    deleteProfessionals,
    setDeleteProfessionals,
    editProfessionals,
    setEditProfessionals,
    compareProfessionals
  } = props;

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container justify="center" style={{ marginTop: "3em" }}>
        <TableProfesionals
          professionals={professionals}
          setProfessionals={setProfessionals}
          professionalSelected={professionalSelected}
          setProfessionalSelected={setProfessionalSelected}
          viewOnly={viewOnly}
          checkProfessionals={checkProfessionals}
          addProfessionals={addProfessionals}
          setAddProfessionals={setAddProfessionals}
          deleteProfessionals={deleteProfessionals}
          setDeleteProfessionals={setDeleteProfessionals}
          editProfessionals={editProfessionals}
          setEditProfessionals={setEditProfessionals}
          compareProfessionals={compareProfessionals}
        />
        {professionalSelected ? null : viewOnly ? null : (
          <span className="text-danger" style={{ marginTop: "2em" }}>
            Seleccione al menos un profesional
          </span>
        )}
      </Grid>
    </Slide>
  );
};

export default Profesionales;

Profesionales.propTypes = {
  professionals: PropTypes.array,
  setProfessionals: PropTypes.func,
  professionalSelected: PropTypes.bool,
  setProfessionalSelected: PropTypes.func,
  viewOnly: PropTypes.bool,
  checkProfessionals: PropTypes.array,
  addProfessionals: PropTypes.array,
  setAddProfessionals: PropTypes.func,
  deleteProfessionals: PropTypes.array,
  setDeleteProfessionals: PropTypes.func,
  editProfessionals: PropTypes.array,
  setEditProfessionals: PropTypes.func,
  compareProfessionals: PropTypes.array
};
