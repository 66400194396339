import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { Grid, Divider, Typography } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const TitleDivider = ({ title }) => {
  return (
    <Grid item xs={12}>
      <Card
        style={{
          boxShadow: "0 1px 4px 0 rgb(0 0 0 / 0%)",
          background: "#eee",
          width: "130px",
          margin: "5px 0px 0px 0",
          position: "absolute",
        }}
      >
        <CardHeader icon>
          <CardIcon>
            <Typography
              variant="h6"
              style={{
                color: indigo[500],
                textTransform: "none",
              }}
              gutterBottom
            >
              {title}
            </Typography>
          </CardIcon>
        </CardHeader>
      </Card>
      <Divider
        style={{
          margin: "20px 0",
          height: "5px",
          backgroundColor: indigo[500],
        }}
        variant="middle"
      />
    </Grid>
  );
};

export default TitleDivider;
