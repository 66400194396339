import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
    green, indigo,
} from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function ModalMoveBenefit({
    open,
    handleClose,
    row,
    handleMoveRow,
    patientBillings,
}) {
    const classes = useStyles();

    const [selectBilling, setselectBilling] = useState({});



    const handleUpdate = () => {
        handleMoveRow(row, selectBilling);
        handleClose();
    };

    const listBillings = patientBillings?.map((row) => {return {value: row.id, label: `${row.codigo_facturacion} - ${row.tipo_factuacion}`}})

    const _content_ =  <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <Grid
                    item
                    xs={6}
                >
                    <Typography variant="body2" gutterBottom>{`Código: ${row.codigo_prestacion}`}</Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <Typography variant="body2" gutterBottom>{`Prestación efectuada: ${row.prestacion_efectuada}`}</Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12} justify="center" alignContent="flex-start">
                <DetailSelect
                    label="Facturacion:"
                    value={selectBilling}
                    onchange={(value) => setselectBilling(value)}
                    list={listBillings}
                />
            </Grid>
        </Grid>
    </div>

    const _actions_ = <>
        {(selectBilling.value) ?
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: green[500]}}
            onClick={handleUpdate}
        >
            Guardar
        </Button> :
        <Button
            disabled
            mr={2}
            variant="contained"
            className="text-white"
        >
            Guardar
        </Button>
        }
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            color="primary"
            onClick={handleClose}
        >
            Cerrar
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Mover registro a otra factura`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="sm"
        />
    );
}