import React, {useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import NavPills from "components/NavPills/NavPills.js";
import GridItem from "components/Grid/GridItem.js";
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import FormNewWork from "components/PatientFile/FormNewWork";
import InfoPanelTelemedicina from "components/ClinicalRequests/InfoPanelTelemedicina";
import TableRecords from "views/PatientFile/RegistrosVistasYParametros/HojaRegistro/TableRecords";
import Filters from "views/PatientFile/RegistrosVistasYParametros/HojaRegistro/Filters";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import api from "utils/API";
import {classStyle} from "../../utils/styleFunction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


export default function ModalTranscriptionTelemedicina({
    open,
    handleClose,
    dataFile,
    dataPatient,
    listMedical,
    listPeriod,
    listassigned,
    tensAssigned,
    requestDataWork=null,
}) {
    const classesStyle = classStyle();
    const [changeButtons, setchangeButtons] = useState(true);

    const [changes, setChanges] = useState(true);
    const [dateEvent, setdateEvent] = useState(moment());
    const [dataWork, setdataWork] = useState([]);
    const [indicationWork, setindicationWork] = useState([]);
    const [balanceIngresos, setbalanceIngresos] = useState([]);
    const [balanceEgresos, setbalanceEgresos] = useState([]);
    const [startDateBalance, setStartDateBalance] = useState(
        moment().format("YYYY-MM-DD[T]HH:mm:ss")
    );
    const [endDateBalance, setEndDateBalance] = useState(
        moment().format("YYYY-MM-DD[T]HH:mm:ss")
    );
    const [specialtyBalance, setSpecialtyBalance] = useState();

    const [dateStart, setDateStart] = useState(moment().subtract(7, "d"));
    const [dateEnd, setDateEnd] = useState(moment());
    const [position, setPosition] = useState(null);
    const [professional, setProfessional] = useState(null);
    const [records, setRecords] = useState([]);
    const [listProfessionals, setListProfessionals] = useState([]);
    const [listPositions, setListPositions] = useState([]);

    const [selectMedic, setselectMedic] = useState({});
    const [auxUserChargeArray, setauxUserChargeArray] = useState([]);
    const [reservationRequest, setreservarionRequest] = useState({});

    useEffect(() => {
      if (changes) {
        initialCharge();
      }
      if (changeButtons) {
        setchangeButtons(false);
    }
    });

    useEffect(() => {
        initialChargeWork();
    }, []);

    const initialCharge = () => {
      setChanges(false);

      const getFirstLoad = api.get(
        `api/paciente/${
          dataFile.id
        }/hojaregistros/filtros/?fecha_inicio=${moment()
          .subtract(7, "d")
          .format("YYYY-MM-DD")}T00:00:00&fecha_fin=${moment().format(
          "YYYY-MM-DD"
        )}T23:59:59`
      );
      const getFilters = api.get(`api/paciente/${dataFile.id}/conseguirfiltros/`);
      const requestInfoTelemedicina = api.get(`api/integracion/obtener/info/agenda/solicitud/${requestDataWork.id_solicitud_paciente.id}`)

      Promise.all([getFirstLoad, getFilters, requestInfoTelemedicina])
        .then(response => {
          setRecords(response[0].data);
          if (response[1].data.cargos.length > 0) {
            setListPositions(response[1].data.cargos);
          }
          if (response[1].data.profesionales.length > 0) {
            setListProfessionals(response[1].data.profesionales);
          }
          const tempDataRequest = response[2].data["agenda_telemedicina"];
          tempDataRequest["start"] = tempDataRequest["fecha"]
          setselectMedic({
              medico_nombre_completo: tempDataRequest["medico_nombre_completo"],
              medico_especialidad: tempDataRequest["medico_especialidad"],
          });
          setdateEvent(moment(tempDataRequest["fecha"]));
          setreservarionRequest(tempDataRequest);
          setauxUserChargeArray(response[2].data["profesionales"].reduce((accum, obj) => ([
              ...accum,
              {...obj, estado: true },
          ]), []))
          setchangeButtons(true);
        })
    };

    const handleSave = () => {

      const arrayWorkNew = [...dataWork, ...indicationWork].filter((row) => (
          (row.id === undefined || row.id === null) && (row.id_tarea_padre === undefined || row.id_tarea_padre === null)
      )).reduce((accum, obj) => ([
          ...accum,
          {
              ...obj,
              id_tarea_padre: requestDataWork.id,
          }
      ]), []);


      const body = {
        tareas: arrayWorkNew,
      };

      api.patch(`api/integracion/finalizar/transcripcion/tarea/${requestDataWork.id}`, body).then((request) => {
          handleSnakeMessage("Tarea finalizada", "success");
          window.location.reload();
      });
  };

    const initialChargeWork = () => {
        if (requestDataWork){
            setdateEvent(moment(requestDataWork.fecha_hora).format("YYYY-MM-DD HH:mm"))
            // setdataWork(requestDataWork[0].tareas_paciente);
        }
        const arrayRequest = [];
        let getBalanceTypes = null;
        if (dataFile?.id){
            getBalanceTypes = api.get(
                `api/paciente/${dataFile.id}/balancehidrico/tiposmovimientos/`
            );
            arrayRequest.push(getBalanceTypes)
        }
        if (arrayRequest.length > 0) {
            Promise.all(arrayRequest).then(response => {
                const egresos = [];
                const ingresos = [];
                if (dataFile?.id){
                    response[0].data
                    .filter(filtered => filtered.tipo_io === "Ingresos")
                    .map(balance => {
                    const tempRow = {
                        select: false,
                        item: balance.nombre,
                        value: balance.tipo_io,
                        startDate: moment(startDateBalance).format(
                            "YYYY-MM-DD[T]HH:mm:ss"
                        ),
                        endDate: moment(endDateBalance).format("YYYY-MM-DD[T]HH:mm:ss"),
                        speciality: specialtyBalance
                    };
                    ingresos.push(tempRow);
                    });
                    response[0].data
                        .filter(filtered => filtered.tipo_io === "Egresos")
                        .map(balance => {
                        const tempRow = {
                            select: false,
                            item: balance.nombre,
                            value: balance.tipo_io,
                            startDate: moment(startDateBalance).format(
                                "YYYY-MM-DD[T]HH:mm:ss"
                            ),
                            endDate: moment(endDateBalance).format("YYYY-MM-DD[T]HH:mm:ss"),
                            speciality: specialtyBalance
                        };
                        egresos.push(tempRow);
                        });
                    setbalanceIngresos(ingresos);
                    setbalanceEgresos(egresos);
                }
            })
        }
    };

    const handleDateStartChange = date => {
      setDateStart(date);
      if (professional != null && position != null) {
        let fechaInicio = `${moment(date).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position}&profesional=${professional}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      } else if (professional != null) {
        let fechaInicio = `${moment(date).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?profesional=${professional}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      } else if (position != null) {
        let fechaInicio = `${moment(date).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      } else {
        let fechaInicio = `${moment(date).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      }
    };

    const handleDateEndChange = date => {
      setDateEnd(date);
      if (professional != null && position != null) {
        let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(date).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position}&profesional=${professional}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      } else if (professional != null) {
        let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(date).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?profesional=${professional}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      } else if (position != null) {
        let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(date).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      } else {
        let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(date).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      }
    };

    const handlePosition = position => {
      setPosition(position.target.value);
      if (professional != null) {
        let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position.target.value}&profesional=${professional}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      } else {
        let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position.target.value}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      }
    };

    const handleProfessional = professional => {
      setProfessional(professional.target.value);

      if (position != null) {
        let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position}&profesional=${professional.target.value}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      } else {
        let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
        let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
        const getInfo = api.get(
          `api/paciente/${dataFile.id}/hojaregistros/filtros/?profesional=${professional.target.value}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
        );

        Promise.all([getInfo])
          .then(response => {
            setRecords(response[0].data);
          })
      }
    };
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = key => (
        <Button style={{background: "#af4c4c00",  color: "#fff"}} onClick={() => {closeSnackbar(key)}}>
            CERRAR
        </Button>
    );

    const handleSnakeMessage = (message, variant, vertical= "top", hotizontal="center", persist=false) => {
        enqueueSnackbar(
          message,
          {
            variant,
            persist: persist,
            action,
            preventDuplicate: true,
            anchorOrigin: {
              vertical: vertical,
              horizontal: hotizontal,
            },
          }
        );
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesStyle.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper className={classesStyle.paper} style={{maxHeight: "90%", width: "90%", overflow: "auto"}}>
                {changeButtons && <div></div>}
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0"}}
                    >
                        <Typography variant="h5" gutterBottom>
                            {`Transcripción consulta Telemedicina`}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0"}}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={12}
                        >
                          <InfoPanelTelemedicina
                            dateEvent={dateEvent}
                            patient={dataPatient}
                            specialist={selectMedic}
                            worker={auxUserChargeArray}
                          />

                        </Grid>
                    </Grid>
                    <GridItem xs={12}>
                        <NavPills
                            color="primary"
                            alignCenter
                            tabs={[
                                {
                                    tabButton: "Hoja de registro",
                                    tabContent: (
                                        <Grid
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                            item
                                            xs={12}
                                        >
                                            <Filters
                                                dateStart={dateStart}
                                                handleDateStartChange={handleDateStartChange}
                                                dateEnd={dateEnd}
                                                handleDateEndChange={handleDateEndChange}
                                                position={position}
                                                handlePosition={handlePosition}
                                                professional={professional}
                                                setProfessional={setProfessional}
                                                listProfessionals={listProfessionals}
                                                listPositions={listPositions}
                                                handleProfessional={handleProfessional}
                                            />
                                            <TableRecords
                                                records={records}
                                                dataPatient={dataPatient}
                                                dataFile={dataFile}
                                                setChanges={setChanges}
                                                setloading={() => {}}
                                                closeModal={handleClose}
                                                tensAssigned={tensAssigned}
                                                listMedical={listMedical}
                                            />
                                        </Grid>
                                    )},
                                {
                                tabButton: "Tareas asociadas",
                                tabContent: (
                                    <FormNewWork
                                        dataFile={dataFile}
                                        listMedical={listMedical}
                                        listPeriod={listPeriod}
                                        listassigned={listassigned}
                                        balanceIngresos={balanceIngresos}
                                        balanceEgresos={balanceEgresos}
                                        setbalanceIngresos={setbalanceIngresos}
                                        setbalanceEgresos={setbalanceEgresos}
                                        startDateBalance={startDateBalance}
                                        setStartDateBalance={setStartDateBalance}
                                        endDateBalance={endDateBalance}
                                        setEndDateBalance={setEndDateBalance}
                                        specialtyBalance={specialtyBalance}
                                        setSpecialtyBalance={setSpecialtyBalance}
                                        dataWork={dataWork}
                                        setdataWork={setdataWork}
                                        indicationWork={indicationWork}
                                        setindicationWork={setindicationWork}
                                        typeModal="Telemed"
                                    />
                                )},
                            ]}
                        />
                    </GridItem>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 0 0"}}
                >
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: green[500], margin: "0 5px 0 0"}}
                        className="text-white"
                        onClick={handleSave}
                    >
                        Guardar
                    </Button>
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500]}}
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Grid>
            </Paper>
        </Modal>
    );
}