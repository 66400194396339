import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { yellow, orange } from "@material-ui/core/colors";
import { Typography, Grid } from "@material-ui/core";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  tableComponent: {
    padding: "0 1px !important",
    width: "80px"
  },
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

// eslint-disable-next-line react/prop-types
export default function RequestTable({
  dataArray,
  dictPatient,
  selectItemTable,
  handleItemTable,
  getPickingState,
  getIterationNumber,
  getArea,
}) {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getColorSelected = (id, index) => {
    if (selectItemTable.includes(id)) {
      if (index % 2 === 0) {
        return { background: yellow[100] };
      } else {
        return { background: orange[100] };
      }
    } else {
      return {};
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                ID 
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Tipo pedido 
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Área 
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Estado Área
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Paciente
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Fecha a despachar
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Días para enviar
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Estado picking
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Nº Iteración 
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Comuna 
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataArray
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={`${index}-workRows`}
                  style={{
                    ...getColorSelected(row.id, index),
                    cursor: "pointer"
                  }}
                  onClick={() => handleItemTable(row.id)}
                  align="center"
                >
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.id}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {dictTypeRequest[row.tipo_solicitud]?.label}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {getArea(row.id)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.estado_solicitud}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {`
                        ${dictPatient[row.id_paciente_id].nombre} 
                        ${dictPatient[row.id_paciente_id].apellido_paterno} 
                        ${dictPatient[row.id_paciente_id].apellido_materno}
                      `}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {moment(row.fecha_tentativa_llegada).format("YYYY-MM-DD HH:mm")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {moment(row.fecha_tentativa_llegada).diff(
                        moment(),
                        "days"
                      ) > 0
                        ? moment(row.fecha_tentativa_llegada).diff(
                            moment(),
                            "days"
                          )
                        : 0}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {getPickingState(
                        row.estado_picking_farmacia,
                        row.estado_picking
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {getIterationNumber(row.id)}
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {`${dictPatient[row.id_paciente_id].direccion_comuna}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dataArray.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

RequestTable.propTypes = {
  dataArray: PropTypes.array
};
