import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useSnackbar } from 'notistack';
import { Calendar, momentLocalizer } from "react-big-calendar";
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
    TextField,
    FormControlLabel,
    FormGroup,
    Checkbox,
} from '@material-ui/core';
import ModalDefineVisitTelemedicina from "./ModalDefineVisitTelemedicina";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import MedicCard from "views/Widgets/FichaPaciente/MedicCard";
import {red, indigo} from "@material-ui/core/colors";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {classStyle} from "../../utils/styleFunction";
import moment from "moment-timezone";
import "moment/locale/es";
import ChargeWorkerTable from 'views/Widgets/FichaPaciente/IntegrationTelemedicina/ChargeWorkerTable';
import TableReservation from 'views/Widgets/FichaPaciente/IntegrationTelemedicina/TableReservation';
import api from "utils/API";
import apiform from "../../utils/APIForm";
import APITelemedicina from "../../utils/APITelemedicina";
import {
    getListRequestPatient,
  } from 'actions/getListAction';
moment.locale("es");

const localizer = momentLocalizer(moment);

const eventColors = (event) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
};

export default function ModalCreateWorkTelemedicina({
    open,
    handleClose,
    arrayMedic,
    arrayCard,
    arrayUser,
    dataPatient,
}) {

    const classesStyle = classStyle();
    const dispatch = useDispatch();
    const [chargeUserArray, setchargeUserArray] = useState([]);
    const [specialty, setspecialty] = useState("");
    const [selectMedic, setselectMedic] = useState({});
    const [workMedic, setworkMedic] = useState([]);
    const [workMedicMonth, setworkMedicMonth] = useState([]);
    const [workProfesional, setworkProfesional] = useState([]);
    const [workProfesionalMonth, setworkProfesionalMonth] = useState([]);
    const [workPatient, setworkPatient] = useState([]);
    const [workPatientMonth, setworkPatientMonth] = useState([]);
    const [workPatientProfesional, setworkPatientProfesional] = useState([]);
    const [workPatientProfesionalMonth, setworkPatientProfesionalMonth] = useState([]);
    const [listValuesProfesional, setlistValuesProfesional] = useState([]);
    const [selectDate, setselectDate] = useState(moment());
    const [calendarView, setcalendarView] = useState("week");
    const [filter, setfilter] = useState(false);

    const [specialistArray, setspecialistArray] = useState([]);
    const [auxSpecialistArray, setauxSpecialistArray] = useState([]);
    const [auxUserChargeArray, setauxUserChargeArray] = useState(arrayUser);
    const [dictUser, setdictUser] = useState({});
    const [reservationData, setreservationData] = useState({});
    const [motive, setmotive] = useState("");
    const [checkFile, setcheckFile] = useState(false);
    const [exam, setexam] = useState([]);
    const [nameExam, setnameExam] = useState("")

    const [flag, setflag] = useState(1);
    const [openvisitModal, setopenvisitModal] = useState(false);
    const [openBackgrop, setopenBackgrop] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        const dictArrayCard = arrayCard.reduce((accum, obj) => ({
            ...accum,
            [`${obj.nombres} ${obj.apellido_paterno} ${obj.apellido_materno}`] :  obj,
        }), {});
        const tempArrayMedic = arrayMedic.map((row) => {
            const tempRow = {...row}
            const key = `${row.nombres} ${row.apellido_paterno} ${row.apellido_materno}`

            if (dictArrayCard[key]){
                tempRow.foto = dictArrayCard[key].foto;
            }
            return tempRow
        });
        setdictUser(arrayUser.reduce((accum, obj) => ({
            ...accum,
            [obj.id]: obj,
        }), {}))
        setspecialistArray(tempArrayMedic);
        setauxSpecialistArray(tempArrayMedic);
    }, []);

    useEffect(() => {
        if (filter){
            filterSpecialist();
        }
    });

    const filterSpecialist = () => {
        setfilter(false);
        setauxSpecialistArray(specialistArray.filter((row) => (
            `${row.nombres} ${row.apellido_paterno} ${row.apellido_materno}`.includes(specialty) || row.nombre_especialidad.includes(specialty)
        )))
    };

    const action = key => (
        <Button style={{background: "#af4c4c00",  color: "#fff"}} onClick={() => {closeSnackbar(key)}}>
            CERRAR
        </Button>
    );

    const handleSnakeMessage = (message, variant, vertical= "top", hotizontal="center", persist=false) => {
        enqueueSnackbar(
          message,
          {
            variant,
            persist: persist,
            action,
            preventDuplicate: true,
            anchorOrigin: {
              vertical: vertical,
              horizontal: hotizontal,
            },
          }
        );
    };

    const handleSelectSpecialist = (row) => {
        setselectMedic(row);
        setflag(2);
        handleNavigation(selectDate, row);
    };

    const generateTitleMedic = (row, medicRow) => {
        return `${row.nombres} ${row.apellido_paterno} ${row.apellido_materno} - ${row.nombre_especialidad}`;
    }

    const generateProfesionalTitle = (row) => {
        const tempRow = dictUser[row.id_usuarios_id];
        if (tempRow){
            return `${row.tipo_tarea} - ${tempRow["first_name"]} ${tempRow["last_name"]}`;
        } else {
            return `${row.tipo_tarea} - Sin usuario`;
        }
    };

    const handleViewCalendar = (view) => {
        setcalendarView(view);
    };

    const handleNavigation = (date, ArgMedic=null, ArgChargeUserArray=null) => {
        const auxdate = moment(date);
        if (auxdate.format("YYYY-MM") != selectDate.format("YYYY-MM") || ArgMedic || ArgChargeUserArray){
            setopenBackgrop(true);
            const auxMedic = ArgMedic ? ArgMedic : selectMedic;
            const auxChargeUserArray = ArgChargeUserArray ? ArgChargeUserArray : chargeUserArray;
            const dateFormat = auxdate.format("YYYY-MM-DD");

            let paramString = `?fecha=${dateFormat}&paciente=${dataPatient.id}&medico=${auxMedic.id}`;
            auxChargeUserArray.map((row) => {
                if(row["estado"]){
                    paramString = `${paramString}&profesional=${row.id}`;
                }
            });

            api.get(`api/integracion/obtener/tareas/${paramString}`).then((response) => {
                const dictMedicMonthArray = {};
                const dictProfesionalMonthArray = {};
                const dictProfesionalArray = {};
                const profesionalWorkArray = []
                const dictPatientMonthArray = {};
                const dictPatientArray = {};
                const patientWorkArray = [];
                const dictPatientProfesionalSame = {};
                const dictPatientProfesionalSameMonth = {};

                const medicWorkArray = response.data.agenda_medico.map((medicRow) => {
                    const key = moment(medicRow.fecha_hora).format("YYYY-MM-DD");
                    if (dictMedicMonthArray[key]){
                        dictMedicMonthArray[key] += 1;
                    } else {
                        dictMedicMonthArray[key] = 1;
                    }
                    return {
                        title: generateTitleMedic(auxMedic, medicRow),
                        start: moment(medicRow.fecha_hora).toDate(),
                        end: moment(medicRow.fecha_hora).add(medicRow.duracion, "minutes").toDate(),
                        color: "azure",
                        row: medicRow
                    }
                });
                response.data.tareas_profesional.map((profesionalRow) => {
                    dictProfesionalArray[moment(profesionalRow.fecha_hora).format("YYYY-MM-DD HH:mm:ss")] = {
                        title: generateProfesionalTitle(profesionalRow),
                        start: moment(profesionalRow.fecha_hora).toDate(),
                        end: moment(profesionalRow.fecha_final).toDate(),
                        color: "default"
                    }
                });
                response.data.tareas_paciente.map((patientRow) => {
                    dictPatientArray[moment(patientRow.fecha_hora).format("YYYY-MM-DD HH:mm:ss")] = {
                        title: `${patientRow.tipo_tarea}`,
                        start: moment(patientRow.fecha_hora).toDate(),
                        end: moment(patientRow.fecha_final).toDate(),
                        color: "deepOrange",
                    }
                });

                Object.keys(dictPatientArray).map((key) => {
                    const keyDay = moment(dictPatientArray[key].start).format("YYYY-MM-DD");
                    if (dictPatientArray[key] && dictProfesionalArray[key]){

                        if (dictPatientProfesionalSameMonth[keyDay]){
                            dictPatientProfesionalSameMonth[keyDay] += 1;
                        } else {
                            dictPatientProfesionalSameMonth[keyDay] = 1
                        }
                        dictPatientProfesionalSame[key] = {
                            title: dictProfesionalArray[key].title,
                            start: dictProfesionalArray[key].start,
                            end: dictProfesionalArray[key].end,
                            color: "green"
                        }
                    } else{
                        if (dictPatientMonthArray[keyDay]){
                            dictPatientMonthArray[keyDay] += 1;
                        } else {
                            dictPatientMonthArray[keyDay] = 1;
                        }
                        patientWorkArray.push(dictPatientArray[key]);
                    }
                });

                Object.keys(dictProfesionalArray).map((key) => {
                    if (dictPatientProfesionalSame[key] === undefined){
                        const keyday = moment(dictProfesionalArray[key].start).format("YYYY-MM-DD");
                        if (dictProfesionalMonthArray[keyday]){
                            dictProfesionalMonthArray[keyday] += 1;
                        } else {
                            dictProfesionalMonthArray[keyday] = 1;
                        }
                        profesionalWorkArray.push(dictProfesionalArray[key]);
                    }
                });

                const patientProfesionalSameRow = Object.values(dictPatientProfesionalSame);

                const tempMedicRow = Object.keys(dictMedicMonthArray).map((keyRow) => {
                    return {
                        allDay: true,
                        title: `${dictMedicMonthArray[keyRow]} modulos ocupados`,
                        start: moment(keyRow).toDate(),
                        end: moment(keyRow).toDate(),
                        color: "azure"
                    }
                });
                const tempProfesionalRow = Object.keys(dictProfesionalMonthArray).map((keyRow) => {
                    return {
                        allDay: true,
                        title: `${dictProfesionalMonthArray[keyRow]} modulos ocupados`,
                        start: moment(keyRow).toDate(),
                        end: moment(keyRow).toDate(),
                        color: "default"
                    }
                });
                const tempPatientRow = Object.keys(dictPatientMonthArray).map((keyRow) => {
                    return {
                        allDay: true,
                        title: `${dictPatientMonthArray[keyRow]} modulos ocupados`,
                        start: moment(keyRow).toDate(),
                        end: moment(keyRow).toDate(),
                        color: "deepOrange"
                    }
                });
                const tempPatientProfesionalRow = Object.keys(dictPatientProfesionalSameMonth).map((keyRow) => {
                    return {
                        allDay: true,
                        title: `${dictPatientProfesionalSameMonth[keyRow]} modulos disponibles`,
                        start: moment(keyRow).toDate(),
                        end: moment(keyRow).toDate(),
                        color: "green"
                    }
                });

                setworkMedicMonth(tempMedicRow);
                setworkMedic(medicWorkArray);

                setworkProfesionalMonth(tempProfesionalRow);
                setworkProfesional(profesionalWorkArray);

                setworkPatientMonth(tempPatientRow);
                setworkPatient(patientWorkArray)

                setworkPatientProfesional(patientProfesionalSameRow);
                setworkPatientProfesionalMonth(tempPatientProfesionalRow);
                setopenBackgrop(false);
            });
        }

        setselectDate(auxdate);
    };

    const handleSelectSpecialty = (value) => {
        setspecialty(value);
        setfilter(true);
    };

    const handleCheckUser = (value, row) => {
        const index = auxUserChargeArray.findIndex((filterRow) => row.id === filterRow.id);
        const tempChargeUserArray = [...auxUserChargeArray];
        tempChargeUserArray[index]["estado"] = value;
        setchargeUserArray(tempChargeUserArray);
        handleNavigation(selectDate, null, tempChargeUserArray);
    };

    const handleSelectEvent = (value) => {
        const tempArray = auxUserChargeArray.filter((row) => (row["estado"]))
        if (value.color === "azure" && value.row && tempArray.length > 0) {
            setopenvisitModal(value);
        } else if (value.color === "azure" && value.row){
            setflag(3);
            setreservationData(value);
            setlistValuesProfesional([])
        }
    };

    const handleSaveReservation = (list) => {
        setlistValuesProfesional(list)
        setflag(3);
        setreservationData(openvisitModal);
        setopenvisitModal(null);
    };

    const handleReservate = () => {
        setopenBackgrop(true);
        const hours = parseInt(moment().tz('America/Santiago').format('z'))*-1;
        const tempListProfesional = listValuesProfesional.reduce((accum, obj) => ([
            ...accum,
            {
                hora_extra: parseInt(obj.extraTurnValue),
                colacion_turno_extra: parseInt(obj.extraLunchAmount),
                movilizacion_turno_extra: parseInt(obj.shiftMobilizationAmount),
                movilizacion_especial: parseInt(0),
                viaticos_especial: parseInt(obj.specialViaticAmount),
                info_profesional: obj.workerInfo,
            }
        ]), [])
        const body = {
            medico_nombre_completo: `${selectMedic.nombres} ${selectMedic.apellido_paterno} ${selectMedic.apellido_materno}`,
            medico_especialidad: selectMedic.nombre_especialidad,
            duracion: reservationData.row.duracion,
            fecha: reservationData.row.fecha_hora,
            fecha_inicio: moment(reservationData.row.fecha_hora).add(hours, "hours").format("YYYY-MM-DD HH:mm:ss"),
            fecha_termino: moment(reservationData.row.fecha_hora).add(hours, "hours").add(reservationData.row.duracion, "minutes").format("YYYY-MM-DD HH:mm:ss"),
            motivo: motive,
            id_medico_telemedicina: selectMedic.id,
            id_agenda_reserva: reservationData.row.id,
            profesionales: tempListProfesional,
            email: selectMedic.email,
        };
        api.post(`/api/integracion/reservar/agenda/${dataPatient.id}`, body).then((response) => {
            const data = response.data
            if (data["tutor"]){
                createUserTelemedicina(data["tutor"]);
            } else{

                const listRequest = []
                if (exam.length > 0){
                    const formData = new FormData();
                    formData.append("archivo", exam[0]);
                    formData.append("tipo_documento", "ExamenTelemedicina");
                    formData.append("nombre_archivo", nameExam);
                    formData.append("id_paciente", dataPatient.id);
                    listRequest.push(apiform.post(`/api/documentospaciente/`, formData));
                };
                if (listRequest.length > 0){
                    const tempDocId = []
                    Promise.all(listRequest).then((request) => {
                        request.map((row) => {
                            tempDocId.push(row.id);
                        });
                        const body = {
                            id_documento_paciente: tempDocId,
                            id_solicitud_paciente: data["id"],
                        }
                        api.put(`/api/integracion/reservar/agenda/${dataPatient.id}`, body).then((response) => {
                            handleSnakeMessage("Solicitud creada", "success");
                            getListRequestPatient(dataPatient, dispatch);
                            handleClose();
                            setopenBackgrop(false);
                        });
                    })
                }  else {
                    handleSnakeMessage("Solicitud creada", "success");
                    getListRequestPatient(dataPatient, dispatch);
                    handleClose();
                    setopenBackgrop(false);
                }
            }
        }).catch((error) => {
            handleSnakeMessage("Error al generar solicitud", "error");
            setopenBackgrop(false);
        })
    };

    const createUserTelemedicina = (tutor) => {
        const tempRut = rutSoftlandConvertion(tutor.rut)
        const tempPhone = tutor.celular.length > 12 ? tutor.celular.substring(tutor.celular.length-12, 12) : tutor.celular;
        const body = {
            siclin: true,
            usuario: {
                email: tutor.email.toLocaleLowerCase(),
                password: tempRut,
            },
            paciente: {
                cuenta_principal: 1,
                nombres: tutor.nombres,
                apellido_paterno: tutor.apellido_paterno,
                apellido_materno: tutor.apellido_materno,
                rut: tempRut,
                nacimiento: moment().format("YYYY-MM-DD"),
                peso: 0,
                estatura: 0,
                prevision: "-",
                fono: tempPhone,
            },
        };
        APITelemedicina.post(`api/crearnuevacuentapaciente/`, body).then((response) => {
            handleSnakeMessage("Creando cuenta en telemedicina", "success");
            handleReservate();
        })

    }

    const rutSoftlandConvertion = (rut) => {
        const tempRut  = rut.split(".").join("").substr(1, rut.length-1)
        return tempRut
    }

    const selectProf = auxUserChargeArray.filter((row) => (row["estado"])).length;

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesStyle.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper className={classesStyle.paper} style={{maxHeight: "90%", width: "95%", padding:" 10px 22px"}}>
                {flag === 1?
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 0 0"}}
                >
                    <Typography variant="h5" gutterBottom>
                        1.- Seleccione una especialidad
                    </Typography>

                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item xs={12}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={12}
                            spacing={1}

                        >
                            <Typography variant="body2" id="modal-title">
                                ¿Para que especialidad quieres una hora?
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={10}
                            md={6}
                            spacing={1}
                            style={{margin: "10px 0 5px 0px"}}
                        >
                            <TextField
                                label="Especialidad"
                                value={specialty}
                                onChange={(e) => handleSelectSpecialty(e.target.value)}
                                style={{width: "100%"}}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={12}
                        >
                            <Paper className={classesStyle.paper} style={{
                                width: "100%",
                                height: "380px",
                                overflow: "auto",
                                padding: "0"
                                }}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="stretch"
                                item
                                xs={12}
                            >
                            {auxSpecialistArray.map((row, index) => (
                            <Grid
                                key={`${index}-specialist`}
                                item
                                md={6}
                                xs={12}
                                style={{padding: "0 5px 10px 5px"}}

                            >
                                <MedicCard
                                    data={row}
                                    showCalendar={handleSelectSpecialist}
                                />
                            </Grid>
                            ))}
                            </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid> :
                flag === 2 ?
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0"}}
                >
                    <Typography variant="h5" gutterBottom>
                        2.- Agenda fecha y profesionales
                    </Typography>

                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={4}
                        >
                            <Typography style={{color:"#000000"}} variant="body1" >
                                {`${selectProf} seleccionado` }
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={8}
                        >
                            <Grid item xs={12}  style={{padding: "0 19px"}}>
                                <i className={"fas fa-circle"} style={{ color: "#5bc0de" }}
                                />{" "} Médico Disponible{` `}
                                <i className={"fas fa-circle"} style={{ color: "#ff5722" }}
                                />{" "} Paciente Ocupado{` `}
                                <i className={"fas fa-circle"} style={{ color: "#999999" }}
                                />{" "} Profesional Ocupado{` `}
                                <i className={"fas fa-circle"} style={{ color: "#5cb85c" }}
                                />{" "} Profesional en domicilio{` `}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={12}
                    >
                    <Paper className={classesStyle.paper} style={{
                        padding: "0",
                        width: "100%",
                        maxHeight: "700px",
                        overflow: "auto"
                    }}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={12}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={4}

                        >
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                item
                                xs={12}
                            >
                                <ChargeWorkerTable
                                    array={auxUserChargeArray}
                                    handleChange={handleCheckUser}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={8}
                        >
                            <Calendar
                                selectable
                                localizer={localizer}
                                events={calendarView === "month" ? [
                                    ...workMedicMonth, ...workPatientMonth, ...workProfesionalMonth, ...workPatientProfesionalMonth,
                                ] : [
                                    ...workMedicMonth, ...workPatientMonth, ...workProfesionalMonth, ...workPatientProfesionalMonth,
                                    ...workMedic, ...workPatient, ...workProfesional, ...workPatientProfesional,
                                ]}
                                date={selectDate.toDate()}
                                step={30}
                                view={calendarView}
                                startAccessor="start"
                                endAccessor="end"
                                onSelectEvent={event => handleSelectEvent(event)}
                                // onSelectSlot={slotInfo => console.log(slotInfo)}
                                onView={(view) => handleViewCalendar(view)}
                                onNavigate={(date) => handleNavigation(date)}
                                // onSelectEvent={handleSelectEvent}
                                // onSelectSlot={handleSelectSlot}
                                eventPropGetter={eventColors}
                                messages={{
                                next: "Siguiente",
                                previous: "Anterior",
                                today: "Hoy",
                                month: "Mes",
                                week: "Semana",
                                day: "Día",
                                agenda: "Agenda",
                                }}

                                timeslots={1}
                            />

                        </Grid>

                    </Grid>
                    </Paper>
                    </Grid>

                </Grid> :
                flag === 3 &&
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0"}}
                >
                    <Typography variant="h5" gutterBottom>
                        3.- Confirma hora
                    </Typography>
                    <Grid item xs={12}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="flex-start"
                            item
                            xs={12}
                        >
                            <Grid
                                item
                                xs={6}
                                style={{padding: "1%"}}
                            >
                                <TableReservation
                                    patient={dataPatient}
                                    selectHour={reservationData}
                                    specialist={selectMedic}
                                    worker={auxUserChargeArray.filter((row) => (row["estado"]))}
                                />
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="flex-start"
                                item
                                xs={6}
                                style={{padding: "1%"}}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        id="motive"
                                        variant="outlined"
                                        rows="5"
                                        rowsMax="18"
                                        label="Motivo consulta"
                                        defaultValue={motive}
                                        onChange={(event) => setmotive(event.target.value)}
                                        fullWidth my={2}
                                        multiline
                                        style={{textTransform: "none"}}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    item
                                    xs={12}
                                >
                                    <Grid item xs={1} >
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkFile}
                                                        onChange={(event) => setcheckFile(event.target.checked)}
                                                        name="checkFile"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={11} >
                                        <Typography variant="body1">
                                            Deseo adjuntar un archivo para que revise el especialista
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {checkFile &&
                                <Grid
                                    item
                                    xs={12}
                                    style={{padding: "1%"}}
                                >
                                    <TextField
                                        id="motive"
                                        variant="outlined"
                                        label="Descripción"
                                        defaultValue={nameExam}
                                        onChange={(event) => setnameExam(event.target.value)}
                                        fullWidth my={2}
                                        style={{margin: "0 0 2% 0", textTransform: "none"}}
                                    />
                                    <Button
                                        variant="contained"
                                        component="label"
                                        style={{backgroundColor: indigo[500], margin: "0 5px"}}
                                        className="text-white"
                                    >
                                        Subir examen
                                        <TextField
                                            type="file"
                                            id="exam"
                                            onChange={(event) => setexam(event.target.files)}
                                            fullWidth my={2}
                                            style={{display: 'none'}}
                                        />
                                    </Button>
                                    {exam.length > 0 && `${exam[0].name}`}
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                }
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 0 0"}}
                >
                    {(flag === 3 && motive != "" && (checkFile == false || (checkFile == true && exam.length > 0 && nameExam != "") )) ?
                        <Button
                            mr={2}
                            variant="contained"
                            // className={classes.greenButton}
                            style={{backgroundColor: indigo[500], margin: "0 5px"}}
                            className="text-white"
                            onClick={handleReservate}
                        >
                            Agendar
                        </Button>
                        :
                        <Button
                            disabled
                            mr={2}
                            variant="contained"
                        >
                            Agendar
                        </Button>
                    }
                    {flag > 1 &&
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: indigo[500], margin: "0 5px"}}
                        className="text-white"
                        onClick={() => setflag(flag-1)}
                    >
                        Anterior
                    </Button>
                    }
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500]}}
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Grid>
                {openvisitModal &&
                    <ModalDefineVisitTelemedicina
                        open={!!openvisitModal}
                        handleClose={() => setopenvisitModal(null)}
                        visit={openvisitModal}
                        worker={auxUserChargeArray.filter((row) => (row["estado"]))}
                        selectPatient={dataPatient}
                        handleSaveReservation={handleSaveReservation}
                    />

                }
            </Paper>
        </Modal>
    );
}