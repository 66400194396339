import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography as MuiTypography,
    Grid,
    TableFooter,
    TablePagination,
    IconButton,
} from "@material-ui/core";
import {DeleteOutlined, RestorePage} from '@material-ui/icons/';
import { orange, green, red, blue } from "@material-ui/core/colors";
import {
    CustomTableCell
} from "utils/componentsStyle";
import moment from 'moment-timezone';
import 'moment/locale/es' ;
moment.locale("es");


export default function MedicinesTable({
    rows,
    handleDelete,
    recharge,
    typeTable,
    updateRow=true,
    pagination=true,

}) {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{padding: "1%"}}
        >
             {recharge && <div></div>}
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                    <CustomTableCell align="center">Medicamento</CustomTableCell>
                    <CustomTableCell align="center">Dosis</CustomTableCell>
                    <CustomTableCell align="center">Frecuencia (cada cuánto)</CustomTableCell>
                    <CustomTableCell align="center">Periodo tratamiento</CustomTableCell>
                    <CustomTableCell align="center">Cantidad necesaria para el tratamiento</CustomTableCell>
                    <CustomTableCell align="center">Magistral</CustomTableCell>
                    {updateRow &&
                    <CustomTableCell align="center">Acción</CustomTableCell>
                    }
                    </TableRow>

                </TableHead>

                {updateRow ?
                <TableBody>
                    {(rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows
                    ).map((row, index) => (
                    <TableRow
                        key={`${index}-rowsTable`}
                    >
                        <CustomTableCell align="center">
                            {row.delete ?
                                <s>
                                    {row.id_insumo_medicamento_id ? row.medicamento_descripcion : row.medicamento_texto}
                                </s>:
                                row.id_insumo_medicamento_id ? row.medicamento_descripcion : row.medicamento_texto
                            }
                        </CustomTableCell>
                        <CustomTableCell align="center"component="th" scope="row">
                            {row.delete ?
                            <s>
                                {`${row.cantidad} ${row.tipo_cantidad}`}
                            </s>:
                             `${row.cantidad} ${row.tipo_cantidad}`
                            }
                        </CustomTableCell>
                        <CustomTableCell align="center" component="th" scope="row">
                            {row.delete ?
                            <s>
                                {`${row.valor_frecuencia} ${row.frecuencia}`}
                            </s>:
                                `${row.valor_frecuencia} ${row.frecuencia}`
                            }
                        </CustomTableCell>
                        <CustomTableCell align="center" component="th" scope="row">
                            {row.delete ?
                            <s>
                                {`${moment(row.fecha_inicio).format("YYYY-MM-DD hh:mm:ss")} ${moment(row.fecha_fin).format("YYYY-MM-DD hh:mm:ss")}`}
                            </s>:
                                `${moment(row.fecha_inicio).format("YYYY-MM-DD hh:mm:ss")} ${moment(row.fecha_fin).format("YYYY-MM-DD hh:mm:ss")}`
                            }
                        </CustomTableCell>
                        <CustomTableCell align="center" component="th" scope="row">
                            {row.delete ?
                            <s>
                                {`${row.cantidad_total} ${row.tipo_cantidad_total}`}
                            </s>:
                                `${row.cantidad_total} ${row.tipo_cantidad_total}`
                            }
                        </CustomTableCell>
                        <CustomTableCell align="center" component="th" scope="row">
                            {row.delete ?
                            <s>
                                {row.magistral ? "Sí" : "No"}
                            </s>:
                                row.magistral ? "Sí" : "No"
                            }
                        </CustomTableCell>
                        <CustomTableCell align="center" component="th" scope="row">
                        {row.delete ?
                        <IconButton
                            style={{background: green[500], color: green[50]}}
                            onClick={() => handleDelete({
                                typeTable: typeTable,
                                index: index+(page*rowsPerPage)}
                            )}
                            aria-label="delete"
                        >
                            <RestorePage />
                        </IconButton> :
                        <IconButton
                            style={{background: red[500], color: red[50]}}
                            onClick={() => handleDelete({
                                typeTable:typeTable,
                                index: index+(page*rowsPerPage)}
                            )}
                            aria-label="delete"
                        >
                            <DeleteOutlined />
                        </IconButton>
                        }
                        </CustomTableCell>
                    </TableRow>
                    ))}
                </TableBody> :
                <TableBody>
                    { rows.map((row, index) => (
                    <TableRow
                        key={`${index}-rowsTable`}
                    >
                        <CustomTableCell align="center">
                            {row.id_insumo_medicamento_id ? row.medicamento_descripcion : row.medicamento_texto}
                        </CustomTableCell>
                        <CustomTableCell align="center"component="th" scope="row">
                            {`${row.cantidad} ${row.tipo_cantidad}`}
                        </CustomTableCell>
                        <CustomTableCell align="center" component="th" scope="row">
                            {`${row.valor_frecuencia} ${row.frecuencia}`}
                        </CustomTableCell>
                        <CustomTableCell align="center" component="th" scope="row">
                            {`${moment(row.fecha_inicio).format("YYYY-MM-DD")} ${moment(row.fecha_fin).format("YYYY-MM-DD")}`}
                        </CustomTableCell>
                        <CustomTableCell align="center" component="th" scope="row">
                            {`${row.cantidad_total} ${row.tipo_cantidad_total}`}
                        </CustomTableCell>
                        <CustomTableCell align="center" component="th" scope="row">
                            {row.magistral ? "Sí" : "No"}
                        </CustomTableCell>
                    </TableRow>
                    ))}
                </TableBody>
                }
                {pagination &&
                <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: -1 }]}
                        colSpan={12}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'Filas por pagina' },
                            native: false,
                        }}
                        labelRowsPerPage={'Filas por pagina'}
                        nextIconButtonText={'Siguiente'}
                        backIconButtonText={'Atras'}
                        labelDisplayedRows={({ from, to, count }) =>`${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `mas que ${to}`}`}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        style={{textTransform: "none"}}
                    />
                </TableRow>
            </TableFooter>
                }
            </Table>
        </Grid>
    );
}
