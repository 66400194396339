
import {
  change_password,
  request,
  dashboard,
  options,
  download,
  management,
  human_resources_all,
  human_resources_no_remunerations,
  billing_and_collection_all,
  collection_all,
  clinic_all,
  clinic_except_endowments,
  clinic_only_work_file_patient,
  clinic_rotary_file_patient,
  clinic_except_clinicOrders,
  clinic_all_except_rotary,
  clinic_except_clinicOrders_rotary,
  clinic_only_work_file_patient_and_assignments,
  clinic_except_assignments_rotary,
  clinic_except_assinments,
  clinic_coordinators,
  clinic_collocation,
  clinic_bennefit_clinicOrders,
  clinic_bennefit,
  clinicFinance,
  operations_all,
  operations_only_warehouse,
  operations_warehouse,
  operations_outcome,
  operations_income,
  operations_only_transport_boss,
  operations_only_transport_driver,
  operations_only_pharmacy,
  operations_purchases,
  attendance_login,
  attendance_logout,
  password_recovery
} from "components/Routes/AppRoutes";

var dashRoutes = []


if (localStorage.getItem("tipo_perfil") === "GERGerGeneral" || localStorage.getItem("area_perfil") === "Gerencia") {
  var dashRoutes = [
    // request,
    management,
    human_resources_all,
    billing_and_collection_all,
    clinic_all,
    operations_all,
  ];
}

if (["OPGerOpe", "GERJefDptoSSOMA"].includes(localStorage.getItem("tipo_perfil"))) {
  var dashRoutes = [
    // request,
    management,
    billing_and_collection_all,
    clinic_all,
    operations_all,
  ];
}

if (["GERAnaCom", "GERSubGerCom"].includes(localStorage.getItem("tipo_perfil")) ) {
  var dashRoutes = [
    // request,
    management,
    billing_and_collection_all,
    clinic_all,
    operations_all,
  ];
}

if (["OPJefInfo", "OPEncSopAreaInfo", "OPAsisInfo"].includes(localStorage.getItem("tipo_perfil"))) {
  var dashRoutes = [
    // request,
    management,
    collection_all,
    clinic_except_endowments,
    operations_all,
  ];
}

// pueden ver todos los modulos de recursos humanos
if (["ADMFINJefRRHH", "ADMFINAdmin", "ADMFINSubGerRRHH", "ADMFINAdminRRHH", "ADMFINAsisRRHH", "ADMINFINRRHH" ].includes(localStorage.getItem("tipo_perfil"))
){
  var dashRoutes = [
    human_resources_all,
  ]
}

// pueden ver todos los ficha personal y mi dotacion de recursos humanos
if ([
    "ADMFINJefRecSel", "ADMFINAnaRecSel", "ADMFINAdminRecSel", "ADMFINAsisRecSel", "ADMFINAnaCont", "ADMFINAsisCont", "ADMFINJefCon", "ADMFINAuxTes",
    "ADMFINJefCon", "ADMFINEncTes", "ADMFINEncCalImp", "ADMFINSubCon", "ADMINFINFactCob", 
  ].includes(localStorage.getItem("tipo_perfil"))
) {
  var dashRoutes = [
    billing_and_collection_all,
  ];
}
if (["ADMINSubGFin"].includes(localStorage.getItem("tipo_perfil"))) {
  var dashRoutes = [
    billing_and_collection_all,
    human_resources_all
  ];
}

// pueden ver solo  ficha paciente y mis tareas
if (["CLIKineDepEst", "CLITecMed", "CLINutri", "CLIMed", "CLITecEnfBas", "CLIKineMot", "CLIKineResp", "CLIFono", "CLIEnfRuta", "CLIEnfermero(A)Asis", "CLITerOcu"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    clinic_only_work_file_patient,
  ];
};

if (["CLIKinCap"].includes(localStorage.getItem("tipo_perfil")) || ["claudia_castillo", "javiera_leiva", "hector_ruiz tagle", "maria_valdes"].includes(localStorage.getItem("username"))){
  var dashRoutes = [
    clinic_only_work_file_patient_and_assignments,
  ];
}

if (["ADMINFINSubgCont", "CLIAnaLicConve", "CLIRecVen", "CLIEjeCom", "CLIJefCom", "CLIJefExpCli", "CLIPSICGesEXPCli", "CLIPsiGesOIRS"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    clinicFinance,
  ];
}

// clinica de colocacion
if (["CLIAdminRecCol" , "CLIAsisRecCol", "CLIAdminCol", "CLICoordCol", "CLIAdminGesCLin", "CliCoordTurTENS", "CLISubGGesPer"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    clinic_collocation,
  ];
};

// clinica editar, crear prestaciones y ver pedidos programados
if (["CLIEnfGESCAEC", "CLIAnaGes", "CLIAdminGes", "CLISecCenCli"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    clinic_bennefit_clinicOrders,
  ];
};


// clinica editar y crear prestaciones
if (["CliAdminGerEnf", "CliSecreRecepCli", "GERSecreRecep", "FisSupServGen"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    clinic_bennefit,
  ];
};

// pueden ver todo de clinica
if (
  ["leonardo_castro"].includes(localStorage.getItem("username")) ||[
    "CliADirMed", "CliJefEnfCliCentClin", "CLIJefGesCli", "CLICoordUCIPed",  "CLICoordInterPed", "CLICoordAdu", "CLICoordReg", "CLICoordCal", "CLISecreCli", 
    "CLISecreRe", "CLIAnaGesOp", "CLICoorAdmGesCli", "CLIJefCentroClínico", "CLISubGerEnf", "CLIEnfJef", "CLIJefCol", "CLIEnfCoordGESCAEC", "CLIEnfCoord",
    "CLISubGCenCli"
  ].includes(localStorage.getItem("tipo_perfil"))
){
  var dashRoutes = [
    clinic_all,
  ];
};

// no pueden ver rotativa
if (["CLIJefRehab", "CLISubGerRehab", "CLIAnaAClinica"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    clinic_except_clinicOrders_rotary,
  ];
};


// puede ver rotativa y ficha clinica
if (["CLIJefCol"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    clinic_rotary_file_patient,
  ];
};

// puede ver rotativa y ficha clinica
if (["CLIAnaCol", "CliCoordTurnTENS", "CliSupTurnTENS"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    clinic_except_assinments,
  ];
};

if (["CLICoordKineNeuro", "CLICoordKineResp", "CLICoordFonoTO", "CLICoordOperRehab"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    clinic_coordinators,
  ];
};

// puede ver solo bodega
if (["OPJefBod", "OPAsisBod", "OPAuxOpe"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    operations_only_warehouse,
  ];
};

// puede ver solo bodega
if (["OPAsisBod"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    operations_warehouse,
  ];
};

// puede ver egresos
if (["OPAsisEgr", "OPAnaEgr", "OPEncEgr"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    operations_outcome,
  ];
};

// puede ver ingresos
if (["OPEncIng"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    operations_income,
  ];
};

// traslado jefe
if (["OPEncTrans"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    operations_only_transport_boss,
  ];
}

// traslado conductores
if (["OPCondAmbu", "OPCondRep", "Operaciones"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    operations_only_transport_driver,
  ];
}

// ver solo farmcia
if (["OPQFJefLoc", "OPAuxFar"].includes(localStorage.getItem("tipo_perfil")) || ["asdrubal_torres"].includes(localStorage.getItem("username"))){
  var dashRoutes = [
    operations_only_pharmacy,
  ];
}


// puede ver todo operaciones
if (["OPJefOpe", "OPSubGerOpe", "OPSecreOpLog", "OPIngBio", "OPEncProc", "OPJefBod", "OPAnaConInv"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    operations_all,
  ];
}

// puede ver compras
if (["OPAsisComp", "OPEncComp", "OPEncComExt"].includes(localStorage.getItem("tipo_perfil"))){
  var dashRoutes = [
    operations_purchases,
  ];
}

// puede ver todo facturaciones
if (["ADMFINJefFactCobrs", "ADMFINAnaFact", "ADMFINSubGerFactCobrs", "ADMFINAdminFactCobr", "ADMFINAdminCobr"].includes(localStorage.getItem("tipo_perfil"))) {
  var dashRoutes = [
    billing_and_collection_all,
    //clinicFinance,
  ];
};

dashRoutes.push(attendance_login)
dashRoutes.push(password_recovery)
dashRoutes.push(attendance_logout)
dashRoutes.push(dashboard)
dashRoutes.push(change_password)
dashRoutes.push(options)
dashRoutes.push(download)
export default dashRoutes;
