import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "utils/API";
import apiform from "utils/APIForm";
import TableExamData from "./TableExamData";
import DialogCancelRequest from "./DialogCancelRequest";
import EditPageOne from "./EditPageOne";
import EditPageTwo from "./EditPageTwo";
// import TableSummary from "./TableSummary";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalExamDataView = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    tempRow,
    listMedical,
    handleExams,
    loadExams,
    listResourse,
    medicalResourceData
  } = props;
  const [openAnimation, setOpenAnimation] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [openDialogCancelRequest, setOpenDialogCancelRequest] = useState(false);
  const [editPageOne, setEditPageOne] = useState(false);
  const [editPageTwo, setEditPageTwo] = useState(false);
  const [indications, setIndications] = useState("");
  const [name, setName] = useState("");
  const [examDate, setExamDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [responsable, setResponsable] = useState(null);
  const [transfer, setTransfer] = useState(null);
  const [transferType, setTransferType] = useState("");
  const [priority, setPriority] = useState("");
  const [dataMedicalArray, setDataMedicalArray] = useState([]);
  const [dataResourceArray, setDataResourceArray] = useState([]);
  const [supplies, setSupplies] = useState([]);
  const [examRequest, setExamRequest] = useState(null);
  const [isapreAuthorization, setIsapreAuthorization] = useState(null);
  const [uploadAuthorization, setUploadAuthorization] = useState(null);

  const [checkDataMedicalArray, setCheckDataMedicalArray] = useState([]);
  const [addDataMedicalArray, setAddDataMedicalArray] = useState([]);
  const [deleteDataMedicalArray, setDeleteDataMedicalArray] = useState([]);
  const [editDataMedicalArray, setEditDataMedicalArray] = useState([]);

  const [addDataResourceArray, setAddDataResourceArray] = useState([]);
  const [deleteDataResourceArray, setDeleteDataResourceArray] = useState([]);
  const [editDataResourceArray, setEditDataResourceArray] = useState([]);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    const getExamDetail = api.get(
      `/api/paciente/${tempRow.id_paciente}/solicitudes/examenes/${tempRow.id}/`
    );

    Promise.all([getExamDetail])
      .then(response => {
        setIndications(response[0].data.examen.indicaciones);
        setName(response[0].data.examen.id_examen_clinica.nombre);
        setExamDate(
          moment(response[0].data.examen.fecha_hora_examen).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        );
        setTransfer(response[0].data.examen.traslado);
        setTransferType(response[0].data.examen.tipo_traslado);
        setResponsable(response[0].data.examen.realiza_cmh);
        setPriority(response[0].data.prioridad);
        setIndications(response[0].data.examen.indicaciones);

        let tempSupplies = [];

        let tempMedicalArray = [];
        response[0].data.productos_pedidos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto === "Medicamentos") {
            tempMedicalArray.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              id_producto_pedido: row.id
            });

            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });
        let tempResourceArray = [];
        response[0].data.productos_pedidos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto === "Insumos") {
            tempResourceArray.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              id_producto_pedido: row.id
            });

            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });

        if (response[0].data.examen.id_orden_examen != null) {
          setExamRequest(response[0].data.examen.id_orden_examen);
        }
        if (response[0].data.examen.id_autorizacion_isapre != null) {
          setUploadAuthorization(false);
          setIsapreAuthorization(
            response[0].data.examen.id_autorizacion_isapre
          );
        } else {
          setUploadAuthorization(true);
        }

        setDataMedicalArray(tempMedicalArray);
        setDataResourceArray(tempResourceArray);
        setSupplies(tempSupplies);

        setFirstCharge(false);
      })

  };

  const saveEditRequest = () => {
    setOpenAnimation(true);
    let datosEliminar = [];
    deleteDataMedicalArray.map(row => {
      datosEliminar.push({
        id: row.id_producto_pedido
      });
    });
    deleteDataResourceArray.map(row => {
      datosEliminar.push({
        id: row.id_producto_pedido
      });
    });

    let datosAgregar = [];
    addDataMedicalArray.map(row => {
      datosAgregar.push({
        id_insumo_medicamento: row.id,
        cantidad: row.cantidad
      });
    });
    addDataResourceArray.map(row => {
      datosAgregar.push({
        id_insumo_medicamento: row.id,
        cantidad: row.cantidad
      });
    });

    let datosEditar = [];
    editDataMedicalArray.map(row => {
      datosEditar.push({
        id: row.id_producto_pedido,
        cantidad: row.cantidad
      });
    });
    editDataResourceArray.map(row => {
      datosEditar.push({
        id: row.id_producto_pedido,
        cantidad: row.cantidad
      });
    });

    const send = {};
    if (datosEliminar.length > 0) {
      send["datos_eliminar"] = datosEliminar;
    }
    if (datosAgregar.length > 0) {
      send["datos_agregar"] = datosAgregar;
    }
    if (datosEditar.length > 0) {
      send["datos_modificar"] = datosEditar;
    }

    let sendPatch = {
      indicaciones: indications,
      realiza_cmh: responsable,
      fecha_hora_examen: moment(examDate).format("YYYY-MM-DDTHH:mm:ss"),
      traslado: transfer,
      prioridad: priority
    };
    if (transfer) {
      sendPatch["tipo_traslado"] = transferType;
    }
    console.log("sendPatch", sendPatch);
    console.log("send", send);
    api
      .patch(
        `/api/paciente/${tempRow.id_paciente}/solicitudes/examenes/${tempRow.id}/`,
        sendPatch
      )
      .then(response => {
        console.log("response", response);
        api
          .put(
            `api/paciente/${tempRow.id_paciente}/solicitudes/${tempRow.id}/insumos/modificar/`,
            send
          )
          .then(response => {
            console.log("response", response);
            setOpenAnimation(false);
          })

      })

  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        <ModalTitle id="customized-dialog-title" onClose={closeModal}>
          Datos del examen a solicitar
        </ModalTitle>
        <DialogContent>
          {editPageOne ? (
            <Grid container className={classes.root} justify="center">
              <EditPageOne
                handleExams={handleExams}
                loadExams={loadExams}
                examRequest={examRequest}
                setExamRequest={setExamRequest}
                isapreAuthorization={isapreAuthorization}
                setIsapreAuthorization={setIsapreAuthorization}
                indications={indications}
                setIndications={setIndications}
                responsable={responsable}
                setResponsable={setResponsable}
                examDate={examDate}
                setExamDate={setExamDate}
                priority={priority}
                setPriority={setPriority}
                transfer={transfer}
                setTransfer={setTransfer}
                transferType={transferType}
                setTransferType={setTransferType}
              />
            </Grid>
          ) : editPageTwo ? (
            <Grid container className={classes.root} justify="center">
              <EditPageTwo
                listMedical={listMedical}
                listResourse={listResourse}
                medicalResourceData={medicalResourceData}
                dataResourceArray={dataResourceArray}
                setDataResourceArray={setDataResourceArray}
                dataMedicalArray={dataMedicalArray}
                setDataMedicalArray={setDataMedicalArray}
                checkDataMedicalArray={checkDataMedicalArray}
                setCheckDataMedicalArray={setCheckDataMedicalArray}
                addDataMedicalArray={addDataMedicalArray}
                setAddDataMedicalArray={setAddDataMedicalArray}
                deleteDataMedicalArray={deleteDataMedicalArray}
                setDeleteDataMedicalArray={setDeleteDataMedicalArray}
                editDataMedicalArray={editDataMedicalArray}
                setEditDataMedicalArray={setEditDataMedicalArray}
                addDataResourceArray={addDataResourceArray}
                setAddDataResourceArray={setAddDataResourceArray}
                deleteDataResourceArray={deleteDataResourceArray}
                setDeleteDataResourceArray={setDeleteDataResourceArray}
                editDataResourceArray={editDataResourceArray}
                setEditDataResourceArray={setEditDataResourceArray}
              />
            </Grid>
          ) : (
            <Grid>
              <Grid container className={classes.root} justify="center">
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Nombre examen:
                      <span style={{ fontWeight: "bold" }}>{name}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Fecha examen:
                      <span style={{ fontWeight: "bold" }}>
                        {moment(examDate).format("YYYY-MM-DD")}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Análisis:
                      <span style={{ fontWeight: "bold" }}>
                        {responsable ? "CMH" : "Laboratorio Externo"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Hora examen:
                      <span style={{ fontWeight: "bold" }}>
                        {moment(examDate).format("HH:mm")}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Traslado:
                      <span style={{ fontWeight: "bold" }}>
                        {transfer ? transferType : "Sin traslado"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Prioridad:
                      <span style={{ fontWeight: "bold" }}>{priority}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Typography variant="body2" gutterBottom>
                      Indicaciones:
                      <span style={{ fontWeight: "bold" }}>{indications}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Grid>
              <Grid item container xs={12} justify="center">
                <TableExamData supplies={supplies} />
              </Grid>
              {/*
              <Grid
                item
                container
                xs={12}
                justify="center"
                style={{ marginTop: "4em" }}
              >
                {uploadAuthorization ? (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    style={{ marginBottom: "4px" }}
                  >
                    <Typography variant="body2" gutterBottom>
                      <span
                        style={{ fontWeight: "bold" }}
                        className="text-warning"
                      >
                        Falta adjuntar la autorización de la isapre
                      </span>
                    </Typography>
                    {isapreAuthorization === null ? (
                      <Grid item container justify="center">
                        <input
                          id="contained-button-file-isapre"
                          //multiple
                          type="file"
                          onChange={e => setIsapreAuthorization(e.target.files)}
                          hidden
                        />
                        <label htmlFor="contained-button-file-isapre">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{
                              textTransform: "none",
                              marginRight: "3px"
                            }}
                          >
                            Adjuntar autorización de isapre
                          </Button>
                        </label>
                      </Grid>
                    ) : (
                      <Grid item container justify="center">
                        {openAnimation ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{
                              textTransform: "none",
                              marginRight: "3px"
                            }}
                            onClick={() => saveIsapreAuthorization()}
                          >
                            Guardar archivo en la base de datos
                          </Button>
                        )}
                      </Grid>
                    )}
                  </Grid>
                ) : uploadAuthorization === false ? null : null}
              </Grid>
                          */}
            </Grid>
          )}
        </DialogContent>
        {openAnimation ? (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            <CircularProgress />
          </DialogActions>
        ) : (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            {editPageOne ? (
              loadExams ? null : (
                <DialogActions
                  style={{ justifyContent: "center", marginBottom: "1em" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none"
                    }}
                    onClick={() => {
                      setEditPageOne(false);
                      setEditPageTwo(false);
                    }}
                  >
                    Atrás
                  </Button>
                </DialogActions>
              )
            ) : editPageTwo ? (
              <DialogActions
                style={{ justifyContent: "center", marginBottom: "1em" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none"
                  }}
                  onClick={() => {
                    setEditPageOne(false);
                    setEditPageTwo(false);
                    //editRequest(false);
                  }}
                >
                  Atrás
                </Button>
              </DialogActions>
            ) : isapreAuthorization != null && examRequest != null ? (
              <DialogActions
                style={{ justifyContent: "center", marginBottom: "1em" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none"
                  }}
                  onClick={closeModal}
                >
                  Atrás
                </Button>
              </DialogActions>
            ) : (
              <DialogActions
                style={{ justifyContent: "center", marginBottom: "1em" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none"
                  }}
                  onClick={closeModal}
                >
                  Atrás
                </Button>
              </DialogActions>
            )}
          </DialogActions>
        )}
        {openDialogCancelRequest && (
          <DialogCancelRequest
            openDialog={openDialogCancelRequest}
            closeDialog={() => setOpenDialogCancelRequest(false)}
            closeMainModal={closeModal}
            tempRow={tempRow}
          />
        )}
      </Paper>
    </Modal>
  );
};

export default ModalExamDataView;

ModalExamDataView.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  row: PropTypes.object,
  listMedical: PropTypes.array,
  handleExams: PropTypes.func,
  loadExams: PropTypes.bool,
  listResourse: PropTypes.array,
  medicalResourceData: PropTypes.object,
  tempRow: PropTypes.object
};
