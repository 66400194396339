import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {  Grid, Typography, TextField } from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import { Brightness1 } from "@material-ui/icons/";
import { red, green } from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import allActions from "actions";
import moment from "moment-timezone";
import {setmessageGeneral } from "actions/getGlobalAction";
import "moment/locale/es";
moment.locale("es");

const stateColor = {
  Completo: green[500],
  Completa: green[500],
  Completada: green[500],
  Incompleta: red[500],
};

export default function ModalDetailResourcesDriver({
  open,
  closeModal,
  infoRow,
  setinitialCharge,
}) {
  const dispatch = useDispatch();

  const [requestInfo, setrequestInfo] = useState({});
  const [infoItem, setinfoItem] = useState([]);
  const [tempInfoRow, settempInfoRow] = useState(infoRow);
  const [observationDoc, setobservationDoc] = useState("");
  const [documentGuide, setdocumentGuide] = useState([]);
  const [warningRefuse, setwarningRefuse] = useState(false);
  const [observationRefuse, setobservationRefuse] = useState(false);
  const patient = infoRow.id_ficha.id_paciente || {};
  const boxInfo = infoRow?.id_envio_pedido || {};

  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);


  useEffect(() => {
    const getTransportInfo = api.get(`/api/transporte/solicitudes/${boxInfo.id}/detalle/`);
    Promise.all([getTransportInfo])
      .then(response => {
        setrequestInfo(response[0].data[0]?.id_producto_pedido?.id_solicitud_pedido || {});
        setinfoItem(response[0].data);
      })
  }, []);

  const handleAccept = () => {
    const body = {};
    if (tempInfoRow.estado === "Incompleto") {
      body["estado"] = "AdjuntarGuiaDespacho";
    } else if (tempInfoRow.estado === "AdjuntarGuiaDespacho") {
      body["estado"] = "AdjuntarGuiaEntrega";
      const formData = new FormData();
      formData.append("nombre", documentGuide[0].name);
      formData.append("archivo", documentGuide[0]);
      formData.append("tipo_archivo", "GuiaDespacho");
      formData.append("observacion", observationDoc);
      formData.append("area", "Clinica");
      formData.append("id_pedidos", requestInfo.id);
      api.post("/api/documentospedidos/", formData)
    } else if (tempInfoRow.estado === "AdjuntarGuiaEntrega") {
      body["estado"] = "Finalizado";
      const formData = new FormData();
      formData.append("nombre", documentGuide[0].name);
      formData.append("archivo", documentGuide[0]);
      formData.append("tipo_archivo", "GuiaDespacho");
      formData.append("observacion", observationDoc);
      formData.append("area", "Clinica");
      formData.append("id_pedidos", requestInfo.id);
      api.post("/api/documentospedidos/", formData)
    }
    api.patch(`/api/clinica/tareaspaciente/transportar/pedido/${tempInfoRow.id}/`, body).then((request) => {
      let message = ""
      if (tempInfoRow.estado === "Incompleto") {
        message = "Se ha aceptado el pedido."
      } else if (tempInfoRow.estado === "AdjuntarGuiaDespacho") {
        message = "Se ha adjuntado la guía de despacho."
      } else if (tempInfoRow.estado === "AdjuntarGuiaEntrega") {
        message = "Se ha adjuntado la guía de entrega y finalizada la tarea."
      } else if (tempInfoRow.estado === "Finalizado") {
        message = "Se ha finalizado el pedido."
      }

      settempInfoRow({...tempInfoRow, ...request.data["info_tarea"]})
      setmessageGeneral(dispatch, message, "success", "¡Exito!");
      if (body["estado"] !== "AdjuntarGuiaDespacho"){
        setinitialCharge(true);
        closeModal();
      }
    });
  };

  const handleClose = () => {
    closeModal();
    if (tempInfoRow.estado === infoRow.estado) {
      setinitialCharge(true);
    }
  }

  const handleDetailRefuse = () => {
    const body = {
      estado: "Cancelada",
      observacion: observationRefuse,
    };
    api.patch(`/api/clinica/tareaspaciente/transportar/pedido/${tempInfoRow.id}/`, body).then((request) => {
      let message = "Se ha rechazado el pedido."
      settempInfoRow({...tempInfoRow, ...request.data["info_tarea"]})
      setmessageGeneral(dispatch, message, "success", "¡Exito!");
      setinitialCharge(true);
      handleClose();

    });
  }

  const validation = (tempInfoRow.estado === "Incompleto" ||
    (tempInfoRow.estado === "AdjuntarGuiaDespacho" && documentGuide.length > 0 && observationDoc.length > 0) ||
    (tempInfoRow.estado === "AdjuntarGuiaEntrega" && documentGuide.length > 0 && observationDoc.length > 0));

  const _content_ = <Grid container direction="column" justify="center" alignItems="center" item xs={12}>
    <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} style={{ margin: "10px 0 10x 0" }}>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item md={3} sm={6} xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
        {`Paciente: ${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`Dirección: ${patient.direccion_calle} ${patient.direccion_numero} casa/dpto ${patient.direccion_casa_depto}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item md={3} sm={6} xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
          {`Comuna: ${patient.direccion_comuna}`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`Región: ${patient.direccion_region || "No definido"}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item md={3} sm={6} xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
          {`Fecha solicitud:`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`${moment(requestInfo.fecha_solicitud).format("YYYY-MM-DD")}`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>

        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`Fecha estimada:`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`${moment(requestInfo.fecha_tentativa_llegada).format( "YYYY-MM-DD HH:mm")}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item md={3} sm={6} xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
          {`Solicitud pedido: ${requestInfo.id}`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
            {`Caja: ${boxInfo.numero_pedido}`}
          </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`Tipo de solicitud: ${dictTypeRequest[requestInfo.tipo_solicitud]?.label}`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`Medicamento: ${boxInfo.es_farmacia === true ? "Sí" : "No"}`}
        </Typography>

        <Typography variant="caption" display="block" gutterBottom>
          {`Estado picking: ${requestInfo.estado_picking}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
          {`Comentario bodega: ${tempInfoRow.comentarios}`}
        </Typography>
      </Grid>
    </Grid>
    {infoItem.length > 0 && (
      <h3 id="spring-modal-title">{`Contenido del pedido`}</h3>
    )}
    {infoItem.length > 0 && (
      <Grid
        item
        xs={12}
        style={{
          margin: "10px 0 10x 0",
          height: "300px",
          overflow: "auto",
        }}
      >
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-center align-top">SKU </th>
              <th className="text-center align-top">Grupo </th>
              <th className="text-center align-top">
                Descripción
              </th>
              <th className="text-center align-top">Cantidad </th>
              <th className="text-center align-top">
                Observaciones
              </th>
              <th className="text-center align-top">Picking </th>
              <th className="text-center align-top">Estado </th>
              <th className="text-center align-top"></th>
            </tr>
          </thead>
          <tbody>
            {infoItem.map((row, index) => (
              <tr key={`${index}-workRows`}>
                <td className="text-center align-middle">
                  {row?.id_producto_pedido.id_insumo_medicamento.SKU}
                </td>
                <td className="text-center align-middle">
                  {row?.id_producto_pedido.id_insumo_medicamento?.grupo_producto}
                </td>
                <td className="text-center align-middle">
                  {row?.id_producto_pedido.id_insumo_medicamento?.descripcion_producto}
                </td>
                <td className="text-center align-middle">
                  {row.id_producto_pedido.cantidad}
                </td>
                <td className="text-center align-middle">
                  {row.id_producto_pedido.observaciones}
                </td>
                <td className="text-center align-middle">
                  {row.id_producto_pedido.estado}
                </td>
                <td className="text-center align-middle">
                  <Brightness1
                    style={{
                      fill:
                        stateColor[
                          row.id_producto_pedido
                            ? row.id_producto_pedido.estado
                            : "Completo"
                        ]
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    )}
    {(tempInfoRow.estado === "AdjuntarGuiaDespacho" || tempInfoRow.estado ===  "AdjuntarGuiaEntrega") && (
      <Grid container direction="row" justify="center" alignItems="center" item xs={12} style={{margin: "10px 0 10x 0"}}>
      <h5 id="spring-modal-title">{`Adjuntar guía de ${tempInfoRow.estado === "AdjuntarGuiaDespacho" ? "Despacho" : "Entrega"}`}</h5>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
        style={{ margin: "10px 0" }}
      >
        <TextField
            error={observationDoc === ""}
            label={`Observación`}
            helperText={observationDoc === "" ? "Campo requerido" : ""}
            variant="outlined"
            value={observationDoc}
            onChange={(e) => setobservationDoc(e.target.value)}
            placeholder={`Ingresar observación`}
            style={{width: "80%"}}
          />
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-contain"
        item
        xs={8}
        style={{ margin: "10px 0" }}
      >
        <Button
          variant="contained"
          component="label"
          color="primary"
          className="text-white"
          style={{ margin: "0 10px" }}
        >
          Subir Archivo
          <TextField
            type="file"
            id="documentGuide"
            onChange={(event) => setdocumentGuide(event.target.files)}
            fullWidth
            my={2}
            style={{ display: "none" }}
          />
        </Button>
        {documentGuide.length > 0 && `${documentGuide[0].name}`}
      </Grid>
      </Grid>
    )}
  </Grid>

  const _actions_ = <>
    {validation ?
     <Button
      mr={2}
      variant="contained"
      className="text-white"
      color="primary"
      onClick={handleAccept}
    >
      Aceptar
    </Button> :
    <Button
      mr={2}
      variant="contained"
      color="primary"
      disabled
    >
      Aceptar
    </Button>
    }

    <Button
      mr={2}
      variant="contained"
      className="text-white"
      color="primary"
      onClick={() => setwarningRefuse(true)}
    >
      Rechazar
    </Button>
    <Button
      mr={2}
      variant="contained"
      style={{ backgroundColor: red[500], marginRight: "10px" }}
      className="text-white"
      onClick={handleClose}
    >
      Cerrar
    </Button>
  </>

  const handleCancelRefuse = () => {
    setobservationRefuse("")
    setwarningRefuse(false)
  }

  const  validationRefuse = observationRefuse !== ""

  const _contentRefuse_ = <Grid container direction="column" justify="center" alignItems="center" item xs={12}>
    <Grid container direction="row" justify="center" alignItems="center" item xs={12} style={{ margin: "10px 0 10x 0" }}>
      <Typography variant="caption" display="block" gutterBottom>
      {`Indique el motivo de rechazo:`}
      </Typography>
    </Grid>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
      style={{ margin: "10px 0" }}
    >
      <TextField
          error={observationRefuse === ""}
          label={`Observación`}
          helperText={observationRefuse === "" ? "Campo requerido" : ""}
          variant="outlined"
          onChange={(e) => setobservationRefuse(e.target.value)}
          placeholder={`Ingresar observación`}
          style={{width: "100%"}}
          rows={4}
        />
      </Grid>
  </Grid>


  const _actionsRefuse_ = <>
  {validationRefuse ?
    <Button
      mr={2}
      variant="contained"
      className="text-white"
      color="primary"
      onClick={() => handleDetailRefuse()}
    >
      Rechazar
    </Button> :
    <Button
      mr={2}
      variant="contained"
      color="primary"
      disabled
    >
      Rechazar
    </Button>
    }
    <Button
      mr={2}
      variant="contained"
      style={{ backgroundColor: red[500], marginRight: "10px" }}
      className="text-white"
      onClick={() => handleCancelRefuse()}
    >
      Cancelar
    </Button>
  </>

  return (
    <>
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={`Datos del pedido`}
      _content_={_content_}
      _actions_ = {_actions_}
    />
    <ModalDialog
      open={warningRefuse}
      onClose={() => handleCancelRefuse()}
      title={`Recharzar pedido`}
      _content_={_contentRefuse_}
      _actions_ = {_actionsRefuse_}
      maxWidth="sm"
    />
    </>
  );
}
