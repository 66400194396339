import React, { useState, useEffect } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import api from "utils/API";
import SearchRequest from "./MainPage/SearchRequest";
import TabsEndowments from "./MainPage/TabsEndowments";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");



const ClinicEndowments = () => {
  const [changes, setChanges] = useState(true);
  const [files, setFiles] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);
    const getFiles = api.get("api/rrhh/variablesdinamicas/fichas/");

    Promise.all([getFiles])
      .then(response => {
        setFiles(response[0].data);
      })

  };

  let searchFile = files.filter(file => {
    return (
      file.nombres
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      file.apellido_paterno
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      file.apellido_materno
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      (file.variables_contadas.AtencionExtra
        ? file.variables_contadas.AtencionExtra.toString()
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        : null) ||
      (file.variables_contadas.TurnoExtra
        ? file.variables_contadas.TurnoExtra.toString()
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        : null)
    );
  });

  return (
    <GridContainer>
      <ViewTitle
        title="Dotación"
        message="En esta sección podrás revisar, registrar y modificar la información de tu dotación."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Mi dotación</Typography>
            <Typography color="textPrimary">Información dotación</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <GridContainer justify="center" className="m-3 mt-5 mb-5">
            <SearchRequest search={search} setSearch={setSearch} />
            <TabsEndowments files={searchFile} />
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ClinicEndowments;
