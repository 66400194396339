import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { TableFooter, TablePagination } from "@material-ui/core";
import api from "utils/API";
import ModalAddRecord from "./ModalAddRecord";
import ModalAddRecordNurse from "./ModalAddRecordNurse";
import ModalEditRecordNurse from "./ModalEditRecordNurse";
import TablePaginationActions from "./TablePaginationActions";
import FormatPDF from "./FormatPDF";
import FormatPDFSimple from "./FormatPDFSimple";
import {
  getListSuppliesMedicines,
} from "actions/getListAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  table: {
    minWidth: 700
  }
});

const TableRecords = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    records,
    dataPatient,
    dataFile,
    setChanges,
    setOpenBackdrop,
    closeModal,
    tensAssigned,
    clinic,
  } = props;

  const listMedical = useSelector(state => state.currentList.listMedicines);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

  useEffect(() => {
    if(listMedical.length === 0) {
        getListSuppliesMedicines(dispatch, chargeMedicine)
    }
}, [])

  const [openModalAddRecord, setOpenModalAddRecord] = useState(false);
  const [openModalAddRecordNurse, setOpenModalAddRecordNurse] = useState(false);
  const [openModalEditRecordNurse, setOpenModalEditRecordNurse] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [viewQuotation, setviewQuotation] = useState(false);
  const [openSimplePDF, setOpenSimplePDF] = useState(false);
  const [nurseInfo, setNurseInfo] = useState({});
  const [simpleRow, setSimpleRow] = useState({});

  const type_user = localStorage.getItem("tipo_perfil");


  const getNurseInfo = id => {
    const getInfo = api.get(
      `api/paciente/${dataFile.id}/hojaregistros/${id}/enfermera/`
    );

    Promise.all([getInfo])
      .then(response => {
        setNurseInfo(response[0].data);

      })
      .catch(error => {

        console.log("Error promise", error.response)
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sendNurse = recordId => {
    api.post(`/api/clinica/pedidosprogramados/paciente/${dataFile.id}/hojaregistro/${recordId}/enviar/`).then(response => {closeModal();})
  };

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      style={{ marginBottom: "6em" }}
    >
      <Grid item xs={1} />
      <Grid item xs={10} container>
        <Grid item xs={12} container>
          <Grid item xs={8} />
          <Grid
            item
            xs={4}
            container
            justify="flex-end"
            style={{ marginBottom: "1em" }}
          >
            {/* {["Rehabilitación", "Enfermería", "Médico", "Tens", "Gerencia"].includes(localStorage.getItem("area_perfil"))? ( */}
            {(["Enfermería", "Médico",].includes(localStorage.getItem("area_perfil")) || clinic)? (
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={() => setOpenModalAddRecordNurse(true)}
              >
                Agregar registro
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={() => setOpenModalAddRecord(true)}
              >
                Agregar registro
              </Button>
            )}
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Fecha registro</TableCell>
                <TableCell align="center">Cargo</TableCell>
                <TableCell align="center">Profesional</TableCell>
                <TableCell align="center">Anotaciones</TableCell>
                <TableCell align="center">Editar registro</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.length > 0 ? (
                (rowsPerPage > 0
                  ? records.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : records
                ).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {moment(row.fecha_hora).format("DD-MM-YYYY HH:mm")}
                    </TableCell>
                    <TableCell align="center">
                      {row.cargo?.nombre_corto}
                    </TableCell>
                    <TableCell align="center">{`${row.profesional?.first_name} ${row.profesional?.last_name}`}</TableCell>
                    <TableCell align="center" >
                      {row.observaciones === "Hoja de enfermera" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none" }}
                          onClick={() => {
                            getNurseInfo(row.id);
                            setviewQuotation(true);
                          }}
                        >
                          Ver registro
                        </Button>
                      ) : (
                        <Grid>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              textTransform: "none",
                              marginRight: "1rem"
                            }}
                            onClick={() => {
                              setSimpleRow(row);
                              setOpenSimplePDF(true);
                            }}
                          >
                            Ver registro
                          </Button>
                          {type_user !== "CLITecEnfBas" &&
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              textTransform: "none",
                              marginLeft: "1rem"
                            }}
                            onClick={() => {
                              sendNurse(row.id);
                            }}
                          >
                            Enviar a enfermería
                          </Button>
                          }
                        </Grid>
                      )}

                    </TableCell>
                    <TableCell align="center">
                    {(localStorage.getItem("area_perfil") === "Enfermería" && moment().diff(moment(row?.fecha_hora),'minutes')<=30 )? (

                        <Button
                        variant="contained"
                        color="secondary"
                        style={{ textTransform: "none"}}
                        onClick={() => {getNurseInfo(row.id);
                                      setOpenModalEditRecordNurse(true);}}
                        >
                            Editar registro
                        </Button>

                      ) : "Fuera de Tiempo"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center">No hay registros</TableCell>
                </TableRow>
              )}
            </TableBody>
            {records.length > 0 && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "Todas", value: -1 }
                    ]}
                    colSpan={12}
                    count={records.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Filas por pagina" },
                      native: false
                    }}
                    labelRowsPerPage={"Filas por pagina"}
                    nextIconButtonText={"Siguiente"}
                    backIconButtonText={"Atras"}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to === -1 ? count : to} de ${
                        count !== -1 ? count : `mas que ${to}`
                      }`
                    }
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    style={{ textTransform: "none" }}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={1} />
      {openModalAddRecord && (
        <ModalAddRecord
          open={openModalAddRecord}
          closeModal={() => setOpenModalAddRecord(false)}
          dataPatient={dataPatient}
          dataFile={dataFile}
          setChanges={setChanges}

        />
      )}
      {openModalAddRecordNurse && (
        <ModalAddRecordNurse
          open={openModalAddRecordNurse}
          closeModal={() => setOpenModalAddRecordNurse(false)}
          dataPatient={dataPatient}
          dataFile={dataFile}
          setChanges={setChanges}

          tensAssigned={tensAssigned}
          listMedical={listMedical}
        />
      )}
      {openModalEditRecordNurse && Object.keys(nurseInfo).length !== 0 && (
        <ModalEditRecordNurse
          open={openModalEditRecordNurse}
          closeModal={() => setOpenModalEditRecordNurse(false)}
          dataPatient={dataPatient}
          dataFile={dataFile}
          setChanges={setChanges}

          tensAssigned={tensAssigned}
          nurseInfo={nurseInfo}
        />
      )}
      {viewQuotation && Object.keys(nurseInfo).length !== 0 && (
        <FormatPDF
          openModal={viewQuotation}
          closeModal={() => setviewQuotation(false)}
          nurseInfo={nurseInfo}
          dataPatient={dataPatient}
        />
      )}
      {openSimplePDF && (
        <FormatPDFSimple
          openModal={openSimplePDF}
          closeModal={() => setOpenSimplePDF(false)}
          simpleRow={simpleRow}
          dataPatient={dataPatient}
        />
      )}
    </Grid>
  );
};

export default TableRecords;

TableRecords.propTypes = {
  records: PropTypes.array,
  dataPatient: PropTypes.object,
  dataFile: PropTypes.object,
  setChanges: PropTypes.func,
  setOpenBackdrop: PropTypes.func,
  closeModal: PropTypes.func
};
