import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles";
import {Box} from '@material-ui/core';

export default function DialogDefault({ title, fs=false, content, openParameter, setParamenter, fullWidth = 'xs', maxWidth = 'xs', titleStyle = {}, closeButton = true , extra_data=false, showVisibility='visible' } ) {

    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff"
        }
    }));


    return (
        <Dialog fullScreen={fs} onClose={false} aria-labelledby="customized-dialog-title" open={openParameter} style={{visibility:showVisibility}} className="p-3" fullWidth={fullWidth} maxWidth={maxWidth}>
            <Box style={{ display: "inline-flex", justifyContent: "space-between", flexDirection: "row", color: "white", backgroundColor: "#303F9F", height: "52px" }}>
                <DialogTitle style={titleStyle} id="customized-dialog-title" onClose={() => setParamenter(false)}>
                    {title}
                </DialogTitle>
                {closeButton ? <IconButton
                    aria-label="close"
                    onClick={() => setParamenter(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8
                    }}
                    style={{ color: "white" }}
                >
                    <CloseIcon style={{ color: "white" }} />
                </IconButton>
                    : <></>}
            </Box>
            <DialogContent style={{ paddingTop: "12px" }}>
                {content}

            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>

    );
}