import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import SortIcon from "@material-ui/icons/Sort";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment-timezone";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableInProcess = props => {
  const classes = useStyles();
  const {
    searchToOutcome,
    requestSortToOutcome,
    getClassNamesForToOutcome,
    requestTypeToOutcome,
    setRequestTypeToOutcome,
    pickingStatusToOutcome,
    setPickingStatusToOutcome,
    regionToOutcome,
    setRegionToOutcome,
    supplyToOutcome,
    setSupplyToOutcome,
    moduleType,
    months_open,
    days_open
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "50px" }}>
                Id 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortToOutcome("id")}
                  className={getClassNamesForToOutcome("id")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "70px" }}>
                Tipo de pedido 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={requestTypeToOutcome}
                  onChange={e =>
                    setRequestTypeToOutcome(e.target.value.substr(0, 20))
                  }
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"programada"}>Programada</MenuItem>
                  <MenuItem value={"urgente"}>Urgente</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "110px" }}>
                Fecha de solitud 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortToOutcome("fecha_solicitud")}
                  className={getClassNamesForToOutcome("fecha_solicitud")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Estado picking 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={pickingStatusToOutcome}
                  onChange={e =>
                    setPickingStatusToOutcome(e.target.value.substr(0, 20))
                  }
                  label="Age"
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"incompleta"}>Incompleta</MenuItem>
                  <MenuItem value={"completa"}>Completa</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "110px" }}>
                Fecha a despachar 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortToOutcome("fecha_despacho")}
                  className={getClassNamesForToOutcome("fecha_despacho")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Paciente 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortToOutcome("nombre_paciente")}
                  className={getClassNamesForToOutcome("nombre_paciente")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "60px" }}>
                Región 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={regionToOutcome}
                  onChange={e =>
                    setRegionToOutcome(e.target.value.substr(0, 20))
                  }
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"si"}>Si</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Días de atraso
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Estado caja 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortToOutcome("estado_solicitud")}
                  className={getClassNamesForToOutcome("estado_solicitud")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchToOutcome
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  {/*}
                  <TableCell align="center">{row.numero_de_pedidos}</TableCell>
              */}
                  <TableCell align="center">{dictTypeRequest[row.tipo_solicitud]?.label || row.tipo_solicitud}</TableCell>
                  <TableCell align="center">
                    {moment(row.fecha_solicitud).format("YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell align="center">{row.estado_picking}</TableCell>
                  <TableCell align="center">
                    {moment(row.fecha_tentativa_llegada).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                  </TableCell>
                  <TableCell align="center">{row.nombre_paciente}</TableCell>
                  <TableCell align="center">
                    {row.es_region ? "Si" : "No"}
                  </TableCell>
                  {/*}
                  <TableCell align="center">
                    {row.tiene_medicamentos ? "Si" : "No"}
                  </TableCell>
            */}
                  <TableCell align="center">
                    Meses: {months_open(row.fecha_tentativa_llegada)}
                     /  Días:
                    {days_open(row.fecha_tentativa_llegada)}
                  </TableCell>
                  <TableCell align="center">
                    {moduleType === "storage"
                      ? row.estado_solicitud_insumo
                      : row.estado_solicitud_farmacia}
                  </TableCell>
                  <TableCell align="center">
                    {row.estado_solicitud === "Adjuntar guía" ? (
                      <Link
                        to={`/admin/warehouse/order/${moduleType}/${row.id}`}
                      >
                        <Button
                          style={{ textTransform: "none" }}
                          variant="contained"
                          color="primary"
                        >
                          Detalle
                        </Button>
                      </Link>
                    ) : (
                      <Link
                        to={`/admin/warehouse/order/${moduleType}/${row.id}`}
                      >
                        <Button
                          style={{ textTransform: "none" }}
                          variant="contained"
                          color="primary"
                        >
                          Detalle
                        </Button>
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={searchToOutcome.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableInProcess;

TableInProcess.propTypes = {
  searchToOutcome: PropTypes.array,
  requestSortToOutcome: PropTypes.func,
  getClassNamesForToOutcome: PropTypes.func,
  requestTypeToOutcome: PropTypes.func,
  setRequestTypeToOutcome: PropTypes.func,
  pickingStatusToOutcome: PropTypes.string,
  setPickingStatusToOutcome: PropTypes.func,
  regionToOutcome: PropTypes.string,
  setRegionToOutcome: PropTypes.func,
  supplyToOutcome: PropTypes.string,
  setSupplyToOutcome: PropTypes.func,
  moduleType: PropTypes.string,
  months_open: PropTypes.func,
  days_open: PropTypes.func
};
