import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {red, green, orange, indigo} from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import {
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons/";
import {ButtonTextMUI as Button, TypographyMui} from "utils/componentsStyle";
import TableComponent from "views/Widgets/Common/TableComponent";
import EditInTableButton from "views/Widgets/Common/EditInTableButton";
import ModalDeleteItemTable from "components/PatientFile/ModalDeleteItemTable";
import {setmessageGeneral } from "actions/getGlobalAction";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


export default function ModalEditDispatch({
    open,
    closeModal,
    dataDispatch,
    orderData,
    indexSelectOrder,
    handleChangeOrder,
}) {
    const dispatch = useDispatch();
    const [updateDict, setupdateDict] = useState(null);
    const [changeDict, setchangeDict] = useState(null);
    const [editableValueItem, seteditableValueItem] = useState(null);
    const [editableSelectItem, seteditableSelectItem] = useState(null);
    const [dataArray, setdataArray] = useState([...dataDispatch]);
    const [updateAmount, setupdateAmount] = useState({});
    const [updateChanges, setupdateChanges] = useState({});
    const [deleteArray, setdeleteArray] = useState([]);
    const [openDeleteModal, setopenDeleteModal] = useState(null);
    const [openSaveChanges, setopenSaveChanges] = useState(null);
    const [observation, setobservation] = useState("");
    const limitObservation = 400


    const headerTable = [
        {label: "Grupo"},
        {label: "Descripción"},
        {label: "SKU"},
        {label: "Cantidad"},
        {eval:false, label: "Acción"}
    ]

    const listMedical = useSelector(state => state.currentList.listMedicines);
    const listResource = useSelector(state => state.currentList.listSupplies);
    const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);

    const title = `${orderData.fecha_tentativa_llegada ? moment(orderData.fecha_tentativa_llegada).format("DD/MM/YYYY"): ""} ${`${orderData.id ? `id: ${orderData.id}` : ""}`}`



    const handleChangeQuantity = (value, index) => {
      const tempUpdateArray = {...updateAmount};
      const tempData = [...dataArray]
      let row = {}

      const delta = parseInt(value)-tempData[index].cantidad;
      tempData[index].cantidad = parseInt(value);
      tempData[index].cantidad_por_enviar = tempData[index].cantidad_por_enviar+delta;
      tempData[index].estado =  tempData[index].cantidad_por_enviar > 0 ? "Incompleta" : "Completa"
      row = tempData[index];
  
      if (row.id){
        tempUpdateArray[row.id] = {id: row.id, cantidad: row.cantidad, cantidad_por_enviar: row.cantidad_por_enviar, estado: row.estado};
      }
      setupdateAmount(tempUpdateArray);
      setdataArray(tempData);
    };

    const handleChangeItem = (value, index) => {
      const tempUpdateArray = {...updateChanges};
      const tempData = [...dataArray]
      let row = {}
      const id_insumo_medicamento_id = value.value
      const item = medicalResourceData[id_insumo_medicamento_id]
      tempData[index].id_insumo_medicamento__SKU = item.SKU;
      tempData[index].id_insumo_medicamento__descripcion_producto = item.descripcion_producto;
      tempData[index].id_insumo_medicamento_id = id_insumo_medicamento_id;
      tempData[index].id_insumo_medicamento__grupo_producto = item.grupo_producto;
      row = tempData[index];
  
      if (row.id){
        tempUpdateArray[row.id] = {id: row.id, id_insumo_medicamento_id: id_insumo_medicamento_id};
      }
      setupdateChanges(tempUpdateArray);
      setdataArray(tempData);
    };

    const handleUpdateValue = (index, column, value=null) => {
      setupdateDict(updateDict ? null : { index, column });
      if (value){
        seteditableValueItem(value);
      } else {
        handleChangeQuantity(editableValueItem, index);
        seteditableValueItem(null);
      }
    };

    const handleUpdateItem = (index,  value=null) => {
      setchangeDict(changeDict ? null : { index });
      if (value){
        seteditableSelectItem(value);
      } else {
        handleChangeItem(editableSelectItem, index);
        seteditableSelectItem(null);
      }
    };

    const handleDeleteRow = () => {
      const row = openDeleteModal.row;
      const index = openDeleteModal.index;
      const tempDeleteArray = [...deleteArray];
  
      let tempData = [...dataArray]
      if (row.id){
        tempDeleteArray.push(row.id)
        setdeleteArray(tempDeleteArray);
      }
      tempData = tempData.filter((temprow, tempIndex) => index !== tempIndex )
  
      setdataArray(tempData);
      setopenDeleteModal(null);
    };

    // const handleReset = () => {
    //   setdataArray([...dataDispatch]);
    //   setdeleteArray([]);
    //   setupdateAmount({});
    //   console.log("reset")
    // }


    const handleSaveChange = () => {
      const body = {
        item_actualizados: Object.values(updateAmount),
        item_cambiados: Object.values(updateChanges),
        item_eliminados: deleteArray,
        observacion: `${orderData?.observacion || ""}\n${observation}`,
      }
      api.patch(`api/clinica/pedidosprogramados/editar/solicitud/${orderData.id}/`, body).then((response) => {
        setmessageGeneral(dispatch, `Pedido ${orderData.id} modificado`, "success", "¡Exito!")
        closeModal();
        handleChangeOrder(indexSelectOrder)
      });
    }

    const componentUpdateValue = (value, index, column, min="1") => {
        const validateEditable = (updateDict?.index === index && updateDict?.column === column)
        const disabledEdit = (updateDict && (updateDict?.index !== index || updateDict?.column !== column))
        return <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center">
        <Grid item container xs={8} direction="row" justify="center" alignItems="center">
    
        {validateEditable ?
          <DetailNumber
            value={editableValueItem}
            onchange={e => {
              if (/^[0-9\b]+$/.test(e.target.value) && min !== null ? e.target.value >= min : true) {
                seteditableValueItem(e.target.value);
              }
            }}
            min={min}
          />:
          value
        }
        </Grid>
        <Grid item container xs={4} direction="row" justify="flex-end" alignItems="center">
          <EditInTableButton disabledEdit={disabledEdit} selectEditable={validateEditable} onClickSave={() =>handleUpdateValue(index, column, null)} onClickEdit={() =>handleUpdateValue(index,column, value)}/>
        </Grid>
      </Grid>
      };

    const componentUpdateSelect = (row, index) => {
        const validateEditable = changeDict?.index === index
        let disabledEdit = changeDict && (changeDict?.index !== index)
        if (row.cantidad_por_enviar == 0){
          disabledEdit = true;
        }
        const value = { value: row.id_insumo_medicamento_id, label: `(${row.id_insumo_medicamento__SKU}) - (stock: ${row.id_insumo_medicamento__stock_producto}) ${row.id_insumo_medicamento__descripcion_producto} - valor (IVA): $${row.id_insumo_medicamento__valor_con_iva}` }
        return <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center">
        <Grid item container xs={8} direction="row" justify="center" alignItems="center">
        {validateEditable ?
          <DetailSelect
            value={editableSelectItem}
            onchange={e => seteditableSelectItem(e)}
            list={row.id_insumo_medicamento__grupo_producto == "Medicamentos" ? listMedical : listResource }
          />: row.id_insumo_medicamento__SKU
        }
        </Grid>
        <Grid item container xs={4} direction="row" justify="flex-end" alignItems="center">
          <EditInTableButton disabledEdit={disabledEdit} selectEditable={validateEditable} onClickSave={() =>handleUpdateItem(index, null)} onClickEdit={() =>handleUpdateItem(index, value)}/>
        </Grid>
      </Grid>
      };

    const dataEdit = dataArray.map((row, index) => {

        const _quantity_ = componentUpdateValue(
          row.cantidad,
          index,
          "cantidad",
          0
        )

        const _item_ = componentUpdateSelect(
          row,
          index,
        )

        const _buttonDelete_ =  <Button
          variant="outlined"
          style={{
            color: red[500],
            borderColor: red[500],
          }}
          onClick={() => setopenDeleteModal({row, index: index})}
          startIcon={<Delete style={{color: red[500]}}/>}
        >
          Eliminar
        </Button> 

        return {
          data:[
            {eval: true, value: row.id_insumo_medicamento__grupo_producto},
            {eval: true, value: row.id_insumo_medicamento__descripcion_producto},
            {eval: true, value: row.id_insumo_medicamento__SKU, _value_: _item_},
            {eval: true, value: row.cantidad, _value_: _quantity_},
            {eval: true, _value_: _buttonDelete_}
          ],
          show: row.estado !== "Reemplazado"
        }
    })

    const _content_ = <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
          <TableComponent 
            headers={headerTable}
            data={dataEdit}
          />
        </Grid>

    </div>

    const _actions_ = <>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: green[500]}}
            className="text-white"
            onClick={() => setopenSaveChanges(true)}
        >
            Guardar cambios
        </Button>
        {/* <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500]}}
            className="text-white"
            onClick={() => handleReset()}
        >
            Reiniciar cambios
        </Button> */}
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500]}}
            className="text-white"
            onClick={closeModal}
        >
            Cerrar
        </Button>
    </>

    const _contentUpdate_ = <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >

      <TypographyMui variant='body2'>
          {`¿Estás seguro que deseas aplicar los cambios?`}
      </TypographyMui>
      <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
            <TextField
                label={"Observaciones"}
                multiline
                rows={8}
                value={observation}
                variant="outlined"
                onChange={(event) => setobservation(event.target.value)}
                style={{width: "100%"}}
            />
        </Grid>
        <TypographyMui style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
            {`${observation?.length || 0 }/${limitObservation}`}
        </TypographyMui>
      </Grid>

    const _actionsUpdate_ = <>
      <Button
          mr={2}
          variant="contained"
          style={{backgroundColor: green[500]}}
          className="text-white"
          onClick={() => handleSaveChange()}
      >
          Guardar cambios
      </Button>
      <Button
          mr={2}
          variant="contained"
          style={{backgroundColor: red[500]}}
          className="text-white"
          onClick={() => setopenSaveChanges(false)}
      >
          Cerrar
      </Button>
    
    </>

    return (
        <>
        <ModalDialog
            open={open}
            onClose={closeModal}
            title={`Editar pedido - ${title}`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xl"
        />
        {openDeleteModal && (
        <ModalDeleteItemTable
          open={!!openDeleteModal}
          handleClose={() => setopenDeleteModal(null)}
          handleDelete={handleDeleteRow}
          title={`Eliminar ${openDeleteModal.row.id_insumo_medicamento__SKU}`}
          maxWidth="xs"
        />
        )}
          <ModalDialog
            open={openSaveChanges}
            onClose={() => setopenSaveChanges(false)}
            title={`Guardar Cambios`}
            _content_={_contentUpdate_}
            _actions_ = {_actionsUpdate_}
            maxWidth="md"
        />
        </>
      

    );
}