import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";

import apiform from "utils/APIForm";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: 10000,
    color: "#fff"
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function ModalAddAlert(props) {
  const classes = useStyles();
  const {
    open,
    closeModal,
    getListVitalSigns,
    dataFile,
    setChanges,
    setActivateAlert,
    setAlertSigns,
    setAlertas
  } = props;

  const [alertType, setAlertType] = useState("");
  const [lowerLimit, setLowerLimit] = useState(0.0);
  const [upperLimit, setUpperLimit] = useState(0.0);
  const [observation, setObservation] = useState("");

  const handleAlert = event => {
    setAlertType(event.target.value);
  };

  const addAlert = () => {

    const formData = new FormData();

    formData.append("tipo_signos", alertType);
    formData.append("limite_superior", upperLimit);
    formData.append("limite_inferior", lowerLimit);
    formData.append("ultima_modificacion", moment().format());
    formData.append("observacion", observation);
    formData.append("id_ficha", dataFile.id);

    apiform
      .post("api/limitessignos/", formData)
      .then(() => {
        setAlertas({});
        setActivateAlert(false);
        setAlertSigns([]);

        setChanges(true);
        closeModal();
      })
  };

  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
        disableBackdropClick
        scroll="paper"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          align="center"
        >
          Agregar alerta
        </DialogTitle>
        <DialogContent scroll={"paper"}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} container>
              <Grid item xs={1} />
              <Grid item xs={10} container>
                <Grid item xs={12} container spacing={1}>
                  <Grid item xs={12} style={{ marginTop: "5px" }}>

                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Tipo de alerta
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={alertType}
                        onChange={handleAlert}
                        label="Tipo de alerta"
                      >
                        {getListVitalSigns.map(vitalsign => (
                          <MenuItem key={vitalsign.value} value={vitalsign.value}>
                            {vitalsign.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "5px" }}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <TextField
                        id="outlined-number1"
                        label="Límite inferior"
                        inputProps={{ type: "number", step: "0.1" }}
                        variant="outlined"
                        value={lowerLimit}
                        onChange={e => {
                          if (/^[0-9.\b]+$/.test(e.target.value)) {
                            setLowerLimit(parseFloat(e.target.value));
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "5px" }}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <TextField
                        id="outlined-number2"
                        label="Límite superior"
                        inputProps={{ type: "number", step: "0.1" }}
                        variant="outlined"
                        value={upperLimit}
                        onChange={e => {
                          if (/^[0-9.\b]+$/.test(e.target.value)) {
                            setUpperLimit(parseFloat(e.target.value));
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "5px" }}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <TextField
                        id="outlined-basic3"
                        label="Observación"
                        multiline
                        rows={3}
                        inputProps={{
                          maxLength: 500
                        }}
                        variant="outlined"
                        value={observation}
                        onChange={e => setObservation(e.target.value)}
                        error={observation == ""}
                        helperText={
                          observation == "" ? "No puede ser vacío" : ""
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          {alertType != "" && observation != "" ? (
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              onClick={addAlert}
            >
              Guardar cambios
            </Button>
          ) : (
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              disabled
            >
              Guardar cambios
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

ModalAddAlert.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  getListVitalSigns: PropTypes.array,
  dataFile: PropTypes.object,
  setChanges: PropTypes.func,
  setOpenBackdrop: PropTypes.func,
  setActivateAlert: PropTypes.func,
  setAlertSigns: PropTypes.func,
  setAlertas: PropTypes.func
};
