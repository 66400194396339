import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { red, green } from "@material-ui/core/colors";
import {
    Typography,
    Grid,
    Paper,
  } from "@material-ui/core";

export default function RequestTable({
    dataArray,
    handleSelectColumn,
    selectColumn,
    selectItemTable,
}) {
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
          return (
            <React.Fragment>
                <Paper style={{padding: "5px"}}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Typography variant="body1" gutterBottom>{label}</Typography>
                    </Grid>
                    {(selectColumn || selectItemTable.length > 0) ?
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <Typography
                            variant="caption"
                            gutterBottom
                            style={{color: red[100]}}
                        >
                            {`Pendientes: ${payload[0].payload["Pendientes"]}`}
                        </Typography>
                        <Typography
                            variant="caption"
                            gutterBottom
                            style={{color: red[500]}}
                        >
                            {`Pendientes seleccionados: ${payload[0].payload["SelPendientes"]}`}
                        </Typography>
                        <Typography
                            variant="caption"
                            gutterBottom
                            style={{color: green[100]}}
                        >
                            {`Completos: ${payload[0].payload["Completos"]}`}
                        </Typography>
                        <Typography
                            variant="caption"
                            gutterBottom
                            style={{color: green[500]}}
                        >
                            {`Completos seleccionados: ${payload[0].payload["SelCompletos"]}`}
                        </Typography>
                    </Grid>
                    :
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        {payload.map((row) => (
                            <Typography
                                variant="body2"
                                gutterBottom
                                style={{color: row.fill}}
                                key={`tooltip-${row.dataKey}`}
                            >
                                {`${row.dataKey}: ${row.payload[row.dataKey]}`}
                            </Typography>
                        ))}
                    </Grid>
                    }
                </Paper>
            </React.Fragment>
          );
        }
        return null;
    }

    return (
    <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
            <BarChart
                width={500}
                height={300}
                data={dataArray}
                margin={{
                top: 20, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name"/>
                <YAxis />
                <Tooltip content={<CustomTooltip />}/>
                <Legend
                    iconType={"circle"}
                    align={"center"}
                    verticalAlign={"top"}
                    payload={[
                        { value: "Pendientes", type: "circle", color: red[500] },
                        { value: "Completos", type: "circle", color: green[500] }
                    ]}
                    margin={{bottom: 0}}
                />
                {(selectColumn || selectItemTable.length > 0) &&
                <Bar
                    dataKey="SelCompletos"
                    stackId="a"
                    fill={green[500]}
                    onClick={(value) => handleSelectColumn(value, "Completos")}
                />
                }
                {(selectColumn || selectItemTable.length > 0) &&
                <Bar
                    dataKey="SelPendientes"
                    stackId="a"
                    fill={red[500]}
                    onClick={(value) => handleSelectColumn(value, "Pendientes")}
                />
                }
                {(selectColumn || selectItemTable.length > 0) ?
                <Bar
                    dataKey="Pendientes"
                    stackId="a"
                    fill={red[100]}
                    onClick={(value) => handleSelectColumn(value, "Pendientes")}
                />
                :
                <Bar
                    dataKey="Pendientes"
                    stackId="a"
                    fill={red[500]}
                    onClick={(value) => handleSelectColumn(value, "Pendientes")}
                />
                }
                {(selectColumn || selectItemTable.length > 0) ?
                <Bar
                    dataKey="Completos"
                    stackId="a"
                    fill={green[100]}
                    onClick={(value) => handleSelectColumn(value, "Completos")}
                />
                :
                <Bar
                    dataKey="Completos"
                    stackId="a"
                    fill={green[500]}
                    onClick={(value) => handleSelectColumn(value, "Completos")}
                />
                }


            </BarChart>
        </ResponsiveContainer>
    </div>
    );
}
