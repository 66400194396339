import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import DetailTextAreaDisableOptions from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextAreaDisableOptions";
import Divider from "@material-ui/core/Divider";
import ModalSavedPurchase from "./ModalSavedPurchase";
import Select from "react-select";



export default function NewPurchase() {
  const [moduleType, setmoduleType] = useState("");
  const [group, setGroup] = useState("");
  const [sku, setSKU] = useState("");
  const [cantidad, setCantidad] = useState(1);
  const [observaciones, setObservaciones] = useState("");

  const [modalSaved, setModalSaved] = useState(false);
  const [purchaseRequestType, setPurchaseRequestType] = useState("");
  const [firstCharge, setFirstCharge] = useState(true);
  const [listGroup, setListGroup] = useState([]);
  const [listSKU, setListSKU] = useState([]);

  const auxListGroups = useSelector(state => state.currentList.LISTA_TIPO_PRODUCTO);
  const getPurchaseRequestList = useSelector(state => state.currentList.LISTA_TIPO_SOLICITUD_COMPRA);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);

    const tempArrayURL = window.location.href.split("/");
    setmoduleType(tempArrayURL[tempArrayURL.length - 1]);

    setListGroup(auxListGroups.filter(row => row[0] === "Insumos" || row[0] === "Medicamentos"));
  };

  const handleSKU = sku => {
    setSKU(sku);
  };

  const handlePurchaseRequestType = purchaseRequestType => {
    setPurchaseRequestType(purchaseRequestType);
  };

  const handleGroup = group => {

    setGroup(group);
    setSKU("");
    const getSku = api.get(`api/insumosmedicamento/${group.value}`);

    Promise.all([getSku])
      .then(response => {
        let tempSKU = [];
        response[0].data.map(row => {
          tempSKU.push({
            value: row.id,
            label: `(${row.SKU}) ${row.descripcion_producto}`
          });
        });
        setListSKU(tempSKU);

      })

  };

  function createPurchase() {

    const formData = new FormData();

    formData.append("cantidad", cantidad);
    formData.append("observaciones", observaciones);
    formData.append("estado", "EsperarCompra");
    formData.append("id_producto_comprado", sku.value);
    formData.append("area_solicitante", "Bodega");
    formData.append("tipo_solicitud", purchaseRequestType.value);

    apiform
      .post("api/solicitudcompra/", formData)
      .then(() => {

        setModalSaved(true);
      })

  }

  return (
    <GridContainer>
      <ViewTitle
        title="Solicitar compra"
        message="En esta sección podrás solicitar una nueva compra."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link
              color="inherit"
              to={`/admin/warehouse/requests/${moduleType}`}
            >
              Fraccionamiento - Compras
            </Link>
            <Typography color="textPrimary">Solicitar compra</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardBody className="mb-3">
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <div className="col-md-6 mb-3">
                  <label className="text-dark">Grupo:</label>
                  <Select
                    value={group}
                    onChange={handleGroup}
                    options={listGroup}
                    placeholder="Seleccione"
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                  />
                  {group ? null : (
                    <span className="text-danger">Debes ingresar un grupo</span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="text-dark">SKU:</label>
                  <Select
                    value={sku}
                    onChange={handleSKU}
                    options={listSKU}
                    placeholder="Seleccione"
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    noOptionsMessage={() => "Sin registros"}
                    isDisabled={group === "" ? true : false}
                  />
                  {group === "" ? null : sku ? null : (
                    <span className="text-danger">Debes ingresar un sku</span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="text-dark">Cantidad:</label>
                  <input
                    type="number"
                    min="1"
                    className="form-control bg-light font-weight-lighter"
                    style={{ color: "#000000" }}
                    value={cantidad}
                    onChange={e => {
                      if (/^[0-9.\b]+$/.test(e.target.value)) {
                        setCantidad(e.target.value);
                      }
                    }}
                    disabled={group === "" ? true : false}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="text-dark">Tipo solicitud:</label>
                  <Select
                    value={purchaseRequestType}
                    onChange={handlePurchaseRequestType}
                    options={getPurchaseRequestList}
                    placeholder="Seleccione"
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    isDisabled={group === "" ? true : false}
                  />
                  {group === "" ? null : purchaseRequestType === "" ? (
                    <span className="text-danger">
                      Debes ingresar un tipo de solicitud
                    </span>
                  ) : null}
                </div>
              </Grid>
            </Grid>
            <Divider className="mb-4 mt-3" />
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item xs={8}>
                <DetailTextAreaDisableOptions
                  label="Observaciones:"
                  placeholder="Observaciones"
                  value={observaciones}
                  maxlength="500"
                  onchange={e => {
                    setObservaciones(e.target.value);
                  }}
                  disabled={group === "" ? true : false}
                />
                {group === "" ? null : observaciones === "" ? (
                  <span className="text-danger">
                    Debes ingresar una observación
                  </span>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                {observaciones !== "" &&
                sku !== "" &&
                purchaseRequestType !== "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createPurchase}
                    style={{ textTransform: "none" }}
                  >
                    Guardar cambios
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                    style={{ textTransform: "none" }}
                  >
                    Guardar cambios
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Link to={`/admin/warehouse/requests/${moduleType}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none" }}
                  >
                    Cancelar solicitud
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      {modalSaved && (
        <ModalSavedPurchase
          open={modalSaved}
          closeModal={() => setModalSaved(false)}
          moduleType={moduleType}
        />
      )}
    </GridContainer>
  );
}
