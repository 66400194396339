import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import RequestsInformation from "./RequestsInformation";
import { useSortableData } from "./SortableData/SortableData";
import { useSortableDataRetirement } from "./SortableData/SortableDataRetirement";
import TableDivisionOutcome from "./Tables/TableDivisionOutcome";
import TableRecallOutcome from "./Tables/TableRecallOutcome";


export default function OutcomeDivisions() {
  const [moduleType, setmoduleType] = useState("");
  const [changes, setChanges] = useState(true);
  const [divisions, setDivisions] = useState([]);
  const [retirements, setRetirements] = useState([]);

  const { items, requestSort, sortConfig } = useSortableData(divisions);
  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const {
    itemsRetirement,
    requestSortRetirement,
    sortConfigRetirement
  } = useSortableDataRetirement(retirements);

  const getClassNamesForRetirement = name => {
    if (!sortConfigRetirement) {
      return;
    }
    return sortConfigRetirement.key === name
      ? sortConfigRetirement.direction
      : undefined;
  };

  const [search, setSearch] = useState("");
  const [searchRetirements, setSearchRetirements] = useState("");
  const [request_type, setRequest_type] = useState("");

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);

  const [checkTab, setCheckTab] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    if (value === 0) {
      setCheckTab(0);
    }
    if (value === 1) {
      setCheckTab(1);
    }
    if (value === 2) {
      setCheckTab(2);
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);
    setLoading(true);
    const tempArrayURL = window.location.href.split("/");
    setmoduleType(tempArrayURL[tempArrayURL.length - 1]);
    const divisions = api.get("api/egresos/fraccionamiento");
    const retirements = api.get("api/egresos/retiros/");

    Promise.all([divisions, retirements])
      .then(response => {
        setDivisions(response[0].data);
        setRetirements(response[1].data);

      })

  };

  let searchOrders = items.filter(order => {
    return (
      (order.id
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
        order.id_producto_fraccionar.grupo_producto
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        order.id_producto_fraccionar.descripcion_producto
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        order.id_producto_fraccionar.SKU.toLowerCase().indexOf(
          search.toLowerCase()
        ) !== -1 ||
        order.cantidad_fraccionar
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        order.id_producto_resultante.SKU.toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        order.cantidad_resultante
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        order.observaciones.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        order.fecha_solicitud.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        order.estado.toLowerCase().indexOf(search.toLowerCase()) !== -1) &&
      order.id_producto_fraccionar.grupo_producto
        .toLowerCase()
        .indexOf(request_type.toLowerCase()) !== -1
    );
  });

  let searchRetirement = itemsRetirement.filter(order => {
    return (
      (order.id
        .toString()
        .toLowerCase()
        .indexOf(searchRetirements.toLowerCase()) !== -1 ||
        order.id_producto_retirado.grupo_producto
          .toLowerCase()
          .indexOf(searchRetirements.toLowerCase()) !== -1 ||
        order.id_producto_retirado.descripcion_producto
          .toLowerCase()
          .indexOf(searchRetirements.toLowerCase()) !== -1 ||
        order.id_producto_retirado.SKU.toLowerCase().indexOf(
          searchRetirements.toLowerCase()
        ) !== -1 ||
        order.cantidad
          .toString()
          .toLowerCase()
          .indexOf(searchRetirements.toLowerCase()) !== -1 ||
        order.motivo.toLowerCase().indexOf(searchRetirements.toLowerCase()) !==
          -1 ||
        order.fecha_solicitud
          .toLowerCase()
          .indexOf(searchRetirements.toLowerCase()) !== -1 ||
        order.estado.toLowerCase().indexOf(searchRetirements.toLowerCase()) !==
          -1) &&
      order.id_producto_retirado.grupo_producto
        .toLowerCase()
        .indexOf(request_type.toLowerCase()) !== -1
    );
  });

  return (
    <GridContainer>
      <ViewTitle
        title="FRACCIONAMIENTO Y RETIROS"
        message="En esta sección podrás revisar los fraccionamientos y retiros."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/outcome/tasks/${moduleType}`}>
              Tareas de egresos
            </Link>
            <Typography color="textPrimary">
              Fraccionamiento y retiros
            </Typography>
            {checkTab === 0 ? (
              <Typography color="textPrimary">Fraccionamiento</Typography>
            ) : checkTab === 1 ? (
              <Typography color="textPrimary">Retiros</Typography>
            ) : null}
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="mt-3">
              <GridContainer justify="center">
                <RequestsInformation />
              </GridContainer>
              {checkTab === 0 ? (
                <TextField
                  id="outlined-basic"
                  label="Buscar fraccionamiento"
                  variant="outlined"
                  value={search}
                  className="mt-1"
                  onChange={e => setSearch(e.target.value.substr(0, 20))}
                />
              ) : (
                <TextField
                  id="outlined-basic"
                  label="Buscar retiros"
                  variant="outlined"
                  value={searchRetirements}
                  className="mt-1"
                  onChange={e =>
                    setSearchRetirements(e.target.value.substr(0, 20))
                  }
                />
              )}
            </GridContainer>
            <CardBody className="mb-1">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  centered
                >
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Fraccionamiento"
                    {...a11yProps(0)}
                  />
                  <Tab label="Retiros" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <TableDivisionOutcome
                  searchOrders={searchOrders}
                  requestSort={requestSort}
                  getClassNamesFor={getClassNamesFor}
                  moduleType={moduleType}
                  request_type={request_type}
                  setRequest_type={setRequest_type}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableRecallOutcome
                  searchRetirement={searchRetirement}
                  requestSortRetirement={requestSortRetirement}
                  getClassNamesForRetirement={getClassNamesForRetirement}
                  moduleType={moduleType}
                  request_type={request_type}
                  setRequest_type={setRequest_type}
                />
              </TabPanel>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
