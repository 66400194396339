import React from "react";

const DetailTextArea = props => {
  return(
    <div className="col-md-12 mb-3">
      <label className="text-dark" style={{ fontSize: "16px" }}>{props.label}</label>
      <textarea
        className="form-control bg-light font-weight-lighter"
        maxLength={props.maxlength}
        style={{color: "#000000"}}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onchange}
      />
    </div>
  );
}

export default DetailTextArea;