import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableDocuments = props => {
  const classes = useStyles();
  const { documents, deleteFile } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nombre archivo</TableCell>
              <TableCell align="center">Tipo archivo</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.nombre_archivo}</TableCell>
                  <TableCell align="center">{row.tipo_archivo}</TableCell>
                  <TableCell align="center">
                    {row.archivo ? (
                      <Button
                        style={{
                          backgroundColor: "#1F8AFF",
                          textTransform: "none",
                          color: "#fff",
                          marginRight: "5px"
                        }}
                        onClick={() => window.open(`${row.archivo}`, "_blank")}
                      >
                        Ver
                      </Button>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: "#1F8AFF",
                          textTransform: "none",
                          color: "#000",
                          marginRight: "5px"
                        }}
                        onClick={() => window.open(`${row.archivo}`, "_blank")}
                        disabled
                      >
                        Ver
                      </Button>
                    )}
                    <Button
                      style={{
                        backgroundColor: "#1F8AFF",
                        color: "#fff",
                        textTransform: "none",
                        marginLeft: "5px"
                      }}
                      onClick={() =>
                        deleteFile(
                          row.id,
                          row.id_paquetizacion,
                          row.nombre_archivo,
                          row.tipo_archivo
                        )
                      }
                    >
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={documents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableDocuments;

TableDocuments.propTypes = {
  documents: PropTypes.array,
  deleteFile: PropTypes.func
};
