import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import ModalAdd from "./ModalAdd";

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  table: {
    minWidth: 650
  }
});

const MedicalTableComponent = props => {
  const classes = useStyles();
  const {
    dataMedicalArray,
    setDataMedicalArray,
    setPageRefresh,
    margin="30px 0 0 0",
    disabled=false,
    auxlistMedical=null,
  } = props;
  const [openModal, setOpenModal] = useState(null);
  const [loading, setLoading] = useState(false);

  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const listMedical = useSelector(state => state.currentList.listMedicines);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  });

  const handleDeleteRow = (row, index, table) => {
    setLoading(table);
    let tempDeleteArray = dataMedicalArray;
    setDataMedicalArray(tempDeleteArray.filter(array => array.id != row.id));
    setPageRefresh(true);
  };

  const handleChangeQuantity = (value, index, table) => {
    setLoading(table);
    const auxDataArray = dataMedicalArray;
    if(value ===""){
      auxDataArray[index].cantidad = undefined
    }else{
      auxDataArray[index].cantidad = parseInt(value);
    }
    setDataMedicalArray(auxDataArray);
    setPageRefresh(true);
  };

  const handleAddItem = value => {
    let auxArray = dataMedicalArray;
    const tempData = medicalResourceData[value.value];
    const element = {
      item: tempData?.descripcion_producto,
      sku: tempData?.SKU,
      id: tempData?.id,
      cantidad: 1
    };
    auxArray.push(element);
    setDataMedicalArray(auxArray);
    setPageRefresh(true);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          item
          container
          xs={12}
          style={{
            margin: margin,
            maxHeight: "300px",
            overflow: "auto"
          }}
          justify="center"
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: "200px" }}>
                    Item
                  </TableCell>
                  <TableCell align="center" style={{ width: "200px" }}>
                    SKU
                  </TableCell>
                  <TableCell align="center" style={{ width: "200px" }}>
                    Cantidad
                  </TableCell>
                  {!disabled &&
                  <TableCell align="center" style={{ width: "200px" }}>
                    Acción
                  </TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {dataMedicalArray.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.item}
                    </TableCell>
                    <TableCell align="center">{row.sku}</TableCell>
                    <TableCell align="right">
                      <DetailNumber
                        isdisabled={disabled}
                        value={row.cantidad}
                        onchange={e => {
                          //if (/^[0-9\b]+$/.test(e.target.value)) {
                            handleChangeQuantity(e.target.value, index, true);
                          //}
                        }}
                      />
                    </TableCell>
                    {!disabled &&
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          background: red[500],
                          textTransform: "none"
                        }}
                        onClick={() => handleDeleteRow(row, index, true)}
                      >
                        Eliminar
                      </Button>
                    </TableCell>
                    }
                  </TableRow>
                ))}
                {!disabled &&
                <TableRow>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      style={{ textTransform: "none" }}
                    >
                      Agregar Medicamento
                    </Button>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                }
                {!loading && <TableRow></TableRow>}
              </TableBody>

            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
      {openModal !== null && (
        <ModalAdd
          open={openModal !== null}
          table={openModal}
          handleClose={() => setOpenModal(null)}
          listItem={auxlistMedical || listMedical}
          dataInfo={medicalResourceData}
          dataArray={dataMedicalArray}
          setdataArray={setDataMedicalArray}
          handleAddItem={handleAddItem}
        />
      )}
    </Card>
  );
};

export default MedicalTableComponent;

MedicalTableComponent.propTypes = {
  dataMedicalArray: PropTypes.array,
  setDataMedicalArray: PropTypes.func,
  setPageRefresh: PropTypes.func
};
