import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import api from "utils/API";
import { days_open } from "components/Request/ManageRequest/DaysOpen";
import moment from "moment";

const Orders = (props) => {
  const {
    idSolicitud,
    tipoSolicitud,
    paciente,
    fechaSolicitud,
    esRegion,
    estadoPicking,
    tieneMedicamentos,
    estadoSolicitud,
    verNotaVenta=true,
    ArgOrder=null,
  } = props;
  
  const [order, setOrder] = useState({});
  const [group, setGroup] = useState("");
  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);

  useEffect(() => {
      api.get(`api/solicitudpedidos/${props.id}`).then((result) => {
        setOrder(result.data);
      });
      const tempArrayURL = window.location.href.split("/");
      const tempModuleType = tempArrayURL[tempArrayURL.length - 2];
      if (tempModuleType === "storage") {
        setGroup("insumo");
      } else {
        setGroup("farmacia");
      }
  }, []);

  useEffect(() => {
    setOrder(ArgOrder);
  }, [ArgOrder]);

  const months_open = (date) => {
    const today = moment(new Date()).format("DD/MM/YYYY");
    const request_date = moment(date).format("DD/MM/YYYY");
    const diff = moment(today, "DD/MM/YYYY").diff(
      moment(request_date, "DD/MM/YYYY")
    );
    const duration = moment.duration(diff);
    return duration._data.months;
  };

  const handleNoteSold = () => {
    api.get(`api/documentosbodega/notaventa/${idSolicitud ? idSolicitud : props.id}/tipo/${group === "insumo" ? "insumos" : "medicamentos"}/`,
      { responseType: "blob" }
    ).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "nota_venta.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })

  };

  return (
    <div className="col-xl-8 mb-3">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col" />
            <div className="col-6 text-center">Datos del pedido</div>
            <div className="col text-right">
              <span className="font-weight-bold">Estado pedido: </span>
              {estadoSolicitud
                ? estadoSolicitud
                : order[`estado_solicitud_${group}`]}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm text-left ml-5">
              <span className="font-weight-bold">Id: </span>
              {idSolicitud ? idSolicitud : props.id}
              <br />
              <span className="font-weight-bold">Tipo de solicitud: </span>
              {dictTypeRequest[order.tipo_solicitud]?.label || tipoSolicitud}
              <br />
              <span className="font-weight-bold">Paciente: </span>
              {paciente ? paciente : order.nombre_paciente}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Fecha solicitud: </span>
              {fechaSolicitud
                ? moment(fechaSolicitud).format("YYYY-MM-DD HH:mm")
                : moment(order.fecha_solicitud).format("YYYY-MM-DD HH:mm")}
              <br />
              <span className="font-weight-bold">Fecha a despachar: </span>
              {moment(order.fecha_tentativa_llegada).format("YYYY-MM-DD HH:mm")}
              <br />
              <span className="font-weight-bold">Región: </span>
              {esRegion !== undefined
                ? esRegion
                  ? "Si"
                  : "No"
                : order.es_region
                ? "Si"
                : "No"}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Estado picking: </span>
              {estadoPicking ? estadoPicking : order.estado_picking}
              <br />
              <span className="font-weight-bold">Días de atraso: </span>
              Meses:
              {months_open(
                fechaSolicitud ? fechaSolicitud : order.fecha_tentativa_llegada
              )}
               /  Días:
              {days_open(
                fechaSolicitud ? fechaSolicitud : order.fecha_tentativa_llegada
              )}
              <br />
              <span className="font-weight-bold">Medicamento: </span>
              {tieneMedicamentos !== undefined
                ? tieneMedicamentos
                  ? "Si"
                  : "No"
                : order.tiene_medicamentos
                ? "Si"
                : "No"}
            </div>
          </div>
          <div className="row">
          <div className="col-sm text-center" style={{padding: "20px"}}>
            {order.observacion &&
            <TextField
                disabled={true}
                label={"Observaciones"}
                multiline
                rows={5}
                value={order.observacion}
                variant="outlined"
                onChange={(event) => {}}
                style={{width: "100%"}}
            />
            }
            </div>
          </div>
          <div className="row">

            <div className="col-sm text-center">
              <Button
                className="mt-4"
                variant="contained"
                onClick={handleNoteSold}
                color="primary"
              >
                Ver nota de venta
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;

Orders.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.bool,
  id_therapy: PropTypes.number,
  id_paquetizacion: PropTypes.number,
  id: PropTypes.number,
  idSolicitud: PropTypes.number,
  tipoSolicitud: PropTypes.string,
  paciente: PropTypes.string,
  fechaSolicitud: PropTypes.string,
  fechaDespacho: PropTypes.string,
  esRegion: PropTypes.bool,
  estadoPicking: PropTypes.string,
  tieneMedicamentos: PropTypes.bool,
  estadoSolicitud: PropTypes.string,
};
