import React, {useState, useEffect } from 'react';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import {classStyle} from "../../utils/styleFunction";
import moment from "moment-timezone";
import "moment/locale/es";
import TableReservation from 'views/Widgets/FichaPaciente/IntegrationTelemedicina/TableReservation';
import api from "utils/API";
import api_HIDE from "utils/API_HIDE";
import Telemedicinalink from "../../utils/Telemedicinalink";
moment.locale("es");


export default function ModalRequestTelemedicina({
    open,
    closeModal,
    dataPatient,
    requestInfo,
}) {
    const classesStyle = classStyle();
    const [changeButtons, setchangeButtons] = useState(false);

    const [selectMedic, setselectMedic] = useState({});
    const [auxUserChargeArray, setauxUserChargeArray] = useState([]);
    const [reservationData, setreservationData] = useState({});
    const [reservationTelemedicineData, setreservationTelemedicineData] = useState({});
    const [showData, setshowData] = useState(false);
    const [invitationUser, setinvitationUser] = useState(false);


    useEffect(() => {
        const requestInfoTelemedicina = api.get(`api/integracion/obtener/info/agenda/solicitud/${requestInfo.id}`)
        const requestInfoCallTelemedicina = api.get(`api/integracion/obtener/info/llamada/solicitud/${requestInfo.id}`)
        Promise.all([requestInfoTelemedicina, requestInfoCallTelemedicina]).then((request) => {
            setreservationTelemedicineData(request[1].data[0])
            const tempDataRequest = request[0].data["agenda_telemedicina"];
            tempDataRequest["start"] = tempDataRequest["fecha"]
            setreservationData(tempDataRequest);
            setinvitationUser(request[0].data["invitado"])
            setselectMedic({
                medico_nombre_completo: tempDataRequest["medico_nombre_completo"],
                medico_especialidad: tempDataRequest["medico_especialidad"],
            })
            setauxUserChargeArray(request[0].data["profesionales"].reduce((accum, obj) => ([
                ...accum,
                {...obj, estado: true },
            ]), []))
            setchangeButtons(true);
            setshowData(true);
            getInfoTelemedicinaCall();
        });
    }, []);

    useEffect(() => {
        if (changeButtons){
            setchangeButtons(false)
        }
    });

    const handleCheckCall = () => {
        let tempUser = auxUserChargeArray.filter((row) => row.id_usuario == localStorage.getItem("user_id") );
        let rut = tempUser[0]?.rut
        if (!rut){
            rut = dataPatient.rut;
        }
        console.log(tempUser)
        window.open(
            `${Telemedicinalink}VideoCall/${rut}/${reservationData.id_agenda_reserva}/`,
        );
    };

    const getInfoTelemedicinaCall = () => {
        if(reservationTelemedicineData?.sid_sala === "-" || reservationTelemedicineData?.sid_sala === undefined ){
            api_HIDE.get(`api/integracion/obtener/info/llamada/solicitud/${requestInfo.id}`).then((response) => {
                setreservationTelemedicineData(response.data[0])
                setchangeButtons(true);
                if (response.data[0]?.sid_sala === "-"){
                    getInfoTelemedicinaCall()
                }
            });
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesStyle.modal}
            open={open}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper className={classesStyle.paper} style={{maxHeight: "85%", overflow: "auto"}}>
                {changeButtons && <div></div>}
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0"}}
                >
                    <Typography variant="h5" gutterBottom>
                        Cita Telemedicina
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item xs={12}
                        style={{margin: "20px 0"}}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={12}
                            spacing={1}
                            style={{margin: "1%"}}
                        >
                            {showData &&
                            <TableReservation
                                patient={dataPatient}
                                selectHour={reservationData}
                                specialist={selectMedic}
                                worker={auxUserChargeArray.filter((row) => (row["estado"]))}
                                telemedicineData={reservationTelemedicineData}
                                invitationUser={invitationUser}
                                handleCheckCall={handleCheckCall}
                            />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 0 0"}}
                >
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500]}}
                        className="text-white"
                        onClick={closeModal}
                    >
                        Cerrar
                    </Button>
                </Grid>
            </Paper>
        </Modal>
    );
}