import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import api from "utils/API";
import TableExamData from "./TableExamData";
import DialogCancelRequest from "./DialogCancelRequest";
import MedicalTableModify from "views/PatientFile/InsumosYMedicamentos/ModifyRequest/MedicalTableModify";
import ResourceTableModify from "views/PatientFile/InsumosYMedicamentos/ModifyRequest/ResourceTableModify";
import UpdateStockButton from "views/Widgets/UpdateStockButton";
import {
  getListRequestPatient,
  getListSuppliesMedicines,
} from 'actions/getListAction';
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalExamData = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    tempRow,
    dataArray,
    dataPatient,
  } = props;
  const dispatch = useDispatch()
  const [openAnimation, setOpenAnimation] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [openDialogCancelRequest, setOpenDialogCancelRequest] = useState(false);
  const [editPageOne, setEditPageOne] = useState(false);
  const [editPageTwo, setEditPageTwo] = useState(false);
  const [indications, setIndications] = useState("");
  const [name, setName] = useState("");
  const [examDate, setExamDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [responsable, setResponsable] = useState(null);
  const [transfer, setTransfer] = useState(null);
  const [transferType, setTransferType] = useState("");
  const [priority, setPriority] = useState("");
  const [dataMedicalArray, setDataMedicalArray] = useState([]);
  const [dataResourceArray, setDataResourceArray] = useState([]);
  const [supplies, setSupplies] = useState([]);

  const [checkDataMedicalArray, setCheckDataMedicalArray] = useState([]);
  const [addDataMedicalArray, setAddDataMedicalArray] = useState([]);
  const [deleteDataMedicalArray, setDeleteDataMedicalArray] = useState([]);
  const [editDataMedicalArray, setEditDataMedicalArray] = useState([]);

  const [addDataResourceArray, setAddDataResourceArray] = useState([]);
  const [deleteDataResourceArray, setDeleteDataResourceArray] = useState([]);
  const [editDataResourceArray, setEditDataResourceArray] = useState([]);

  const [loadExams, setLoadExams] = useState(null);
  const getIndex = dataArray.findIndex(data => data.id === tempRow.id);
  const [pageRefresh, setPageRefresh] = useState(false);

  const listMedical = useSelector(state => state.currentList.listMedicines);
  const listResource = useSelector(state => state.currentList.listSupplies);
  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

  useEffect(() => {
    if(listMedical.length === 0) {
        getListSuppliesMedicines(dispatch, chargeMedicine)
    }
  }, [])

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);
    const getExamDetail = api.get(
      `/api/paciente/${tempRow.id_paciente}/solicitudes/examenes/${tempRow.id}/`
    );

    Promise.all([getExamDetail])
      .then(response => {
        let tempSupplies = [];

        let tempMedicalArray = [];
        response[0].data.productos_pedidos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto === "Medicamentos") {
            tempMedicalArray.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              id_producto_pedido: row.id
            });

            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });
        let tempResourceArray = [];
        response[0].data.productos_pedidos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto === "Insumos") {
            tempResourceArray.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              id_producto_pedido: row.id
            });

            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });

        setIndications(response[0].data.examen.indicaciones);
        setName(response[0].data.examen.id_examen_clinica.nombre);
        setExamDate(
          moment(response[0].data.examen.fecha_hora_examen).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        );
        setTransfer(response[0].data.examen.traslado);
        setTransferType(response[0].data.examen.tipo_traslado);
        setResponsable(response[0].data.examen.realiza_cmh);
        setPriority(response[0].data.prioridad);
        setIndications(response[0].data.examen.indicaciones);

        setDataMedicalArray(tempMedicalArray);
        setDataResourceArray(tempResourceArray);
        setSupplies(tempSupplies);

        setFirstCharge(false);
      })

  };

  const saveEditRequest = () => {
    setOpenAnimation(true);
    let datosEliminar = [];
    deleteDataMedicalArray.map(row => {
      datosEliminar.push({
        id: row.id_producto_pedido
      });
    });
    deleteDataResourceArray.map(row => {
      datosEliminar.push({
        id: row.id_producto_pedido
      });
    });

    let datosAgregar = [];
    addDataMedicalArray.map(row => {
      datosAgregar.push({
        id_insumo_medicamento: row.id,
        cantidad: row.cantidad
      });
    });
    addDataResourceArray.map(row => {
      datosAgregar.push({
        id_insumo_medicamento: row.id,
        cantidad: row.cantidad
      });
    });

    let datosEditar = [];
    editDataMedicalArray.map(row => {
      datosEditar.push({
        id: row.id_producto_pedido,
        cantidad: row.cantidad
      });
    });
    editDataResourceArray.map(row => {
      datosEditar.push({
        id: row.id_producto_pedido,
        cantidad: row.cantidad
      });
    });

    const send = {};
    if (datosEliminar.length > 0) {
      send["datos_eliminar"] = datosEliminar;
    }
    if (datosAgregar.length > 0) {
      send["datos_agregar"] = datosAgregar;
    }
    if (datosEditar.length > 0) {
      send["datos_modificar"] = datosEditar;
    }

    let sendPatch = {
      indicaciones: indications,
      realiza_cmh: responsable,
      fecha_hora_examen: moment(examDate).format("YYYY-MM-DDTHH:mm:ss"),
      traslado: transfer,
      prioridad: priority
    };
    if (transfer) {
      sendPatch["tipo_traslado"] = transferType;
    }
    api
      .patch(
        `/api/paciente/${tempRow.id_paciente}/solicitudes/examenes/${tempRow.id}/`,
        sendPatch
      )
      .then(response => {
        api
          .put(
            `api/paciente/${tempRow.id_paciente}/solicitudes/${tempRow.id}/insumos/modificar/`,
            send
          )
          .then(response => {
            setOpenAnimation(false);
          })

      })

  };

  const sendRequest = () => {
    setOpenAnimation(true);
    api
      .put(
        `/api/paciente/${tempRow.id_paciente}/solicitudes/examenes/${tempRow.id}/enviar/`
      )
      .then(response => {
        getListRequestPatient(dataPatient, dispatch);
        closeModal();
      })

  };

  const handleResponsable = () => {
    setResponsable(!responsable);
  };

  const handleExamDate = date => {
    setExamDate(date);
  };

  const handlePriority = event => {
    setPriority(event.target.value);
  };

  const handleTransfer = () => {
    setTransfer(!transfer);
  };

  const handleTransferType = event => {
    setTransferType(event.target.value);
  };

  const updateStock = () => {

    api.get("/api/poblarinsumos/").then(() => {
      getListSuppliesMedicines(dispatch, chargeMedicine, true)

    })
  };


  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <div>
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >

        <ModalTitle id="customized-dialog-title" onClose={closeModal}>
          Datos del examen a solicitar
        </ModalTitle>
        <DialogContent>
          {editPageOne ? (
            <Grid container className={classes.root} justify="center">
              <Grid
                item
                container
                xs={12}
                style={{ marginTop: "1em" }}
                justify="center"
              >
                <Grid item xs={1} />
                <Grid item container xs={10}>
                  <Grid item container xs={12} justify="space-between">
                    <Grid item container xs={5} justify="center">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">
                          ¿Quién realiza el análisis?
                        </FormLabel>
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={responsable}
                          onChange={handleResponsable}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio color="primary" />}
                            label="CMH"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label="Laboratorio Externo"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item container xs={5} justify="center">
                      <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <Grid container justify="center">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="DD-MM-YYYY"
                            margin="normal"
                            id="date-picker-inline1"
                            label="Fecha del examen"
                            value={examDate}
                            onChange={handleExamDate}
                            minDate={moment()}
                            minDateMessage={"La fecha de envío no puede ser anterior al día de hoy"}
                            cancelLabel={"Cancelar"}
                            okLabel={"Aceptar"}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <Grid container justify="center">
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Hora del examen"
                            value={examDate}
                            onChange={handleExamDate}
                            KeyboardButtonProps={{
                              "aria-label": "change time"
                            }}
                            cancelLabel={"Cancelar"}
                            okLabel={"Aceptar"}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid item container xs={12} justify="center">
                {transfer ? (
                  <Grid item container xs={12} justify="center" spacing={1}>
                    <Grid item container xs={4} justify="center">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Prioridad
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={priority}
                          onChange={handlePriority}
                          label="Prioridad"
                        >
                          <MenuItem value={"Baja"}>Baja</MenuItem>
                          <MenuItem value={"Media"}>Media</MenuItem>
                          <MenuItem value={"Alta"}>Alta</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item container xs={4} justify="center">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-outlined-label-1">
                          Traslado
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label-1"
                          id="demo-simple-select-outlined-1"
                          value={transfer}
                          onChange={handleTransfer}
                          label="Traslado"
                        >
                          <MenuItem value={true}>Si</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item container xs={4} justify="center">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-outlined-label-2">
                          Tipo de traslado
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label-2"
                          id="demo-simple-select-outlined-2"
                          value={transferType}
                          onChange={handleTransferType}
                          label="Tipo de traslado"
                        >
                          <MenuItem value={"Auto"}>Auto</MenuItem>
                          <MenuItem value={"Basico"}>Básico</MenuItem>
                          <MenuItem value={"Medio"}>Medio</MenuItem>
                          <MenuItem value={"Avanzado"}>Avanzado</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container xs={12} justify="center" spacing={1}>
                    <Grid item container xs={6} justify="center">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Prioridad
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={priority}
                          onChange={handlePriority}
                          label="Prioridad"
                        >
                          <MenuItem value={"Baja"}>Baja</MenuItem>
                          <MenuItem value={"Media"}>Media</MenuItem>
                          <MenuItem value={"Alta"}>Alta</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item container xs={6} justify="center">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Traslado
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={transfer}
                          onChange={handleTransfer}
                          label="Prioridad"
                        >
                          <MenuItem value={true}>Si</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid
                item
                container
                xs={12}
                justify="center"
                style={{ marginTop: "2em" }}
              >
                <Grid item xs={1} />
                <Grid item container xs={10} justify="center">
                  <Grid item container xs={12} justify="center">
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="outlined-basic2"
                      label="Indicaciones"
                      variant="outlined"
                      value={indications}
                      onChange={e => setIndications(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={1} />
              </Grid>

              <Grid
                item
                container
                xs={12}
                justify="center"
                style={{ marginTop: "2em" }}
              >
                <MedicalTableModify
                  dataMedicalArray={dataMedicalArray}
                  setDataMedicalArray={setDataMedicalArray}
                  medicalResourceData={medicalResourceData}
                  listMedical={listMedical.filter(
                    list =>
                      dataMedicalArray.every(data => data.id !== list.value) &&
                      addDataMedicalArray.every(data => data.id !== list.value)
                  )}
                  setPageRefresh={setPageRefresh}
                  checkDataMedicalArray={checkDataMedicalArray}
                  setCheckDataMedicalArray={setCheckDataMedicalArray}
                  addDataMedicalArray={addDataMedicalArray}
                  setAddDataMedicalArray={setAddDataMedicalArray}
                  deleteDataMedicalArray={deleteDataMedicalArray}
                  setDeleteDataMedicalArray={setDeleteDataMedicalArray}
                  editDataMedicalArray={editDataMedicalArray}
                  setEditDataMedicalArray={setEditDataMedicalArray}
                />
              </Grid>
              <Grid item container xs={12} justify="center">
                <ResourceTableModify
                  dataResourceArray={dataResourceArray}
                  setDataResourceArray={setDataResourceArray}
                  medicalResourceData={medicalResourceData}
                  listResource={listResource.filter(
                    list =>
                      dataResourceArray.every(data => data.id !== list.value) &&
                      addDataResourceArray.every(data => data.id !== list.value)
                  )}
                  setPageRefresh={setPageRefresh}
                  addDataResourceArray={addDataResourceArray}
                  setAddDataResourceArray={setAddDataResourceArray}
                  deleteDataResourceArray={deleteDataResourceArray}
                  setDeleteDataResourceArray={setDeleteDataResourceArray}
                  editDataResourceArray={editDataResourceArray}
                  setEditDataResourceArray={setEditDataResourceArray}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid>
              <Grid container className={classes.root} justify="center">
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Nombre examen:
                      <span style={{ fontWeight: "bold" }}>{name}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Fecha examen:
                      <span style={{ fontWeight: "bold" }}>
                        {moment(examDate).format("YYYY-MM-DD")}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Análisis:
                      <span style={{ fontWeight: "bold" }}>
                        {responsable ? "CMH" : "Laboratorio Externo"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Hora examen:
                      <span style={{ fontWeight: "bold" }}>
                        {moment(examDate).format("HH:mm")}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Traslado:
                      <span style={{ fontWeight: "bold" }}>
                        {transfer ? transferType : "Sin traslado"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" gutterBottom>
                      Prioridad:
                      <span style={{ fontWeight: "bold" }}>{priority}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid item container xs={12} justify="center" spacing={1}>
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Typography variant="body2" gutterBottom>
                      Indicaciones:
                      <span style={{ fontWeight: "bold" }}>{indications}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Grid>
              <Grid item container xs={12} justify="center">
                <TableExamData supplies={supplies} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {openAnimation ? (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            <CircularProgress />
          </DialogActions>
        ) : (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            {editPageOne ? (
              loadExams ? null : (
                <DialogActions
                  style={{ justifyContent: "center", marginBottom: "1em" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none"
                    }}
                    onClick={() => {
                      setFirstCharge(true);
                      setEditPageOne(false);
                      setEditPageTwo(false);
                    }}
                  >
                    Atrás
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#43a047",
                      textTransform: "none",
                      color: "#fff"
                    }}
                    onClick={() => {
                      saveEditRequest();
                    }}
                  >
                    Guardar cambios
                  </Button>
                </DialogActions>
              )
            ) : editPageTwo ? (
              <DialogActions
                style={{ justifyContent: "center", marginBottom: "1em" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none"
                  }}
                  onClick={() => {
                    setEditPageOne(false);
                    setEditPageTwo(false);
                    //editRequest(false);
                  }}
                >
                  Atrás
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#43a047",
                    textTransform: "none",
                    color: "#fff"
                  }}
                  onClick={() => {
                    saveEditRequest();
                  }}
                >
                  Guardar cambios
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none"
                  }}
                  onClick={() => {
                    setEditPageOne(true);
                    setEditPageTwo(false);
                  }}
                >
                  Página anterior
                </Button>
              </DialogActions>
            )  : (
              <DialogActions
                style={{ justifyContent: "center", marginBottom: "1em" }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#43a047",
                    textTransform: "none",
                    color: "#fff"
                  }}
                  onClick={() => {
                    setEditPageOne(true);
                    setEditPageTwo(false);
                    //editRequest(true);
                  }}
                >
                  Editar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none"
                  }}
                  onClick={closeModal}
                >
                  Atrás
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    textTransform: "none"
                  }}
                  onClick={() => {
                    setOpenDialogCancelRequest(true);
                  }}
                >
                  Eliminar solicitud
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none"
                  }}
                  onClick={() => {
                    sendRequest();
                  }}
                >
                  Enviar
                </Button>
              </DialogActions>
            )}
          </DialogActions>
        )}
        {openDialogCancelRequest && (
          <DialogCancelRequest
            openDialog={openDialogCancelRequest}
            closeDialog={() => setOpenDialogCancelRequest(false)}
            closeMainModal={closeModal}
            tempRow={tempRow}
            dataArray={dataArray}
            dataPatient={dataPatient}
            getIndex={getIndex}
          />
        )}
      </Paper>
      <UpdateStockButton
          updateStock={updateStock}
      />
      </div>
    </Modal>
  );
};

export default ModalExamData;

ModalExamData.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  row: PropTypes.object,
  handleExams: PropTypes.func,
  loadExams: PropTypes.bool,
  tempRow: PropTypes.object,
  dataArray: PropTypes.array,
};
