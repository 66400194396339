import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const Calendar = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [moduleType, setmoduleType] = useState("");
  let arrayDavid = [];
  let dates = [];
  const [show, setShow] = useState(false);
  const [purchases, setPurchases] = useState([]);
  let checkDates = [];
  let fechas =[];

  useEffect(() => {
    const arrayURL = window.location.href.split('/');
    setmoduleType(arrayURL[arrayURL.length-1])
    api.get("api/solicitudpedidos/").then(result => {
      setLoading(true);
      setOrders(result.data);
    });
    api.get("api/solicitudcotizacioncompras/").then(result => {
      setLoading(true);
      setPurchases(result.data);
    });
    setLoading(false);
  }, []);

  purchases.map(purchase => {
    const found = checkDates.find(
      element => element.fechaSolicitud === purchase.fecha_solicitud
    );

    if (found) {
      checkDates
        .filter(array => array.fechaSolicitud === purchase.fecha_solicitud)
        .map(element => {
          element.count = element.count + 1;
        });
    } else {
      checkDates.push({
        fechaSolicitud: purchase.fecha_solicitud,
        count: 1
      });
    }
  });

  orders.map(solicitud => {
    const found = arrayDavid.find(
      element => element.fechaSolicitud === solicitud.fecha_solicitud
    );

    if (found) {
      switch (solicitud.estado_solicitud) {
        case "Finalizado":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.finalizado = element.finalizado + 1;
            });
          break;
        case "Esperar a egresos":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.esperarEgresos = element.esperarEgresos + 1;
            });
          break;
        case "Esperar aprobación reemplazo":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.aprobacionReemplazo = element.aprobacionReemplazo + 1;
            });
          break;
        case "Hacer picking":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.hacerPicking = element.hacerPicking + 1;
            });
          break;
        case "Notificar a egresos":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.notificarEgresos = element.notificarEgresos + 1;
            });
          break;
        default:
          return null;
      }
    } else {
      switch (solicitud.estado_solicitud) {
        case "Finalizado":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 1,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Esperar a egresos":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            esperarEgresos: 1,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Esperar aprobación reemplazo":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 1,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Reemplazo aprobado":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 1,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Adjuntar guia con egresos":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 1,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Hacer picking":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 1,
            notificarEgresos: 0
          });
          break;
        case "Notificar a egresos":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 1
          });
          break;
        default:
          return null;
      }
    }
  });

  checkDates.map(check => {
    if (check.count > 0) {
      fechas.push({
        title: `${check.count} pedidos`,
        date: check.fechaSolicitud,
        url: "/admin/purchases/requests/"
      });
    }
  });

  arrayDavid.map(array => {
    if (array.adjuntarGuia > 0) {
      dates.push({
        title: `${array.adjuntarGuia} Adjuntar guía`,
        date: array.fechaSolicitud,
        url:  `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.aprobacionReemplazo > 0) {
      dates.push({
        title: `${array.aprobacionReemplazo} Esperar aprobación reemplazo`,
        date: array.fechaSolicitud,
        url:  `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.esperarEgresos > 0) {
      dates.push({
        title: `${array.esperarEgresos} Esperar a egresos`,
        date: array.fechaSolicitud,
        url:  `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.finalizado > 0) {
      dates.push({
        title: `${array.finalizado} Finalizado`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.hacerPicking > 0) {
      dates.push({
        title: `${array.hacerPicking} Hacer picking`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.notificarEgresos > 0) {
      dates.push({
        title: `${array.notificarEgresos} Notificar a egresos`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.reemplazoAprobado > 0) {
      dates.push({
        title: `${array.reemplazoAprobado} Reemplazo aprobado`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
  });

  useEffect(() => {
    if (dates.some(date => date.title) || fechas.some(fecha => fecha.title)) {
      setShow(true);
    }
  });

  return (
    <GridContainer>
      <ViewTitle
        title="Calendario"
        message={`En esta sección podrás revisar los pedidos de ${moduleType === "storage" ? "bodega" : "bodega farmacia"}.`}
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" href={`/admin/warehouse/task/${moduleType}`}>
              {`Tareas ${moduleType === "storage" ? "Bodega" : "Bodega Farmacia"}`}
            </Link>
            <Typography color="textPrimary">Calendario</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading && show ? (
          <Card>
            <CardBody>
              <FullCalendar
                defaultView="dayGridMonth"
                plugins={[dayGridPlugin]}
                weekends={true}
                events={fechas}
              />
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default Calendar;
