import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";
import ReceptionInfoTransport from "./ReceptionInfoTransport";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import Grid from "@material-ui/core/Grid";


export default function OutcomeReceptions() {
  const { id } = useParams();
  const id_order = parseInt(id, 10);
  const [moduleType, setmoduleType] = useState("");
  const [loading, setLoading] = useState(false);

  const [observation, setObservation] = useState("");
  const [order, setOrder] = useState([]);

  const [envioPedido, setEnvioPedido] = useState({});
  const [listProfesional, setListProfesional] = useState([]);
  const [selectProfessional, setSelectProfessional] = useState({});

  const dictCharges = useSelector(state => state.currentList.dictCharges);

  useEffect(() => {
    api.get("api/usuario/cargos/asignaciones/").then((result) => {
      setListProfesional(result.data.filter((item) => dictCharges[item?.id_ficha?.id_cargo_id]).map((item) => {
        const tempCharge = dictCharges[item?.id_ficha?.id_cargo_id];
        return {value: item.id, row: item, label: `${item?.id_ficha?.nombres} ${item?.id_ficha?.apellido_paterno} ${item?.id_ficha?.apellido_materno} - ${tempCharge?.nombre}`}
      }));
    });
  }, [dictCharges]);

  useEffect(() => {
    setLoading(false);
    const tempArrayURL = window.location.href.split("/");
    setmoduleType(tempArrayURL[tempArrayURL.length - 2]);
    api.get(`api/enviospedidos/${id_order}`).then((result) => {
      setEnvioPedido(result.data);
      api
        .get(
          `api/egresos/recepcion/solicitudpedidosdepth/${result.data.id_solicitud_pedido}`
        )
        .then((response) => {
          setOrder(response.data);
          setLoading(true);
        })

    });
  }, [id]);


  function updateAndSend() {

    const formData = new FormData();

    const body = {}

    body["envio_pedido_info"] = {
      "numero_pedido": envioPedido.numero_pedido,
      "observacion": observation,
      "id": envioPedido.id,
    }

    body["tarea_info"] = {
      "id_usuarios_id": selectProfessional.value,
      "id_cargo_asignado_id": selectProfessional.row.id_ficha.id_cargo_id,
      "descripcion": `Entregar ${moduleType === "storage" ? "Insumos" : "Medicamentos"}`,
      "id_envio_pedido_id": envioPedido.id,
    }

    formData.append("numero_pedido", envioPedido.numero_pedido);
    if (observation !== "") {
      formData.append("observacion", observation);
    }
    formData.append("estado", "Esperar a profesional");
    formData.append("estado_egreso", "Esperar a profesional");

    api.post(`/api/clinica/tareaspaciente/transportar/pedido/`, body).then((request) => {
      window.location.href = `/admin/outcome/receptions/${moduleType}`;
    })
  }

  return (
    <GridContainer>
      <ViewTitle
        title="ENVÍO POR TRANSPORTE DE LA EMPRESA"
        message="En esta sección podrás ingresar los datos del envío por transporte de la empresa."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/outcome/tasks/${moduleType}`}>
              Tareas de egresos
            </Link>
            <Typography color="textPrimary">
              Envío con profesional de la empresa
            </Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="mt-3">
              <GridContainer justify="center">
                <ReceptionInfoTransport
                  idEnvioPedido={envioPedido.id}
                  order={order}
                  transport={"Company"}
                  moduleType={moduleType}
                />
              </GridContainer>
            </GridContainer>
            <CardBody className="mb-1">
              <GridContainer justify="center">
                <Grid container item xs={12} style={{ textAlign: "center" }}>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <DetailTextArea
                      label="OBSERVACIONES PARA TRANSPORTE:"
                      placeholder="Observaciones para transporte"
                      value={observation}
                      maxlength="500"
                      onchange={(e) => {
                        setObservation(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                      <DetailSelect
                        label="Seleccionar Profesional:"
                        value={selectProfessional}
                        onchange={(value) => setSelectProfessional(value)}
                        list={listProfesional}
                    />
                  </Grid>
                  <Grid item xs={2} />

                </Grid>
                <GridContainer
                  justify="center"
                  style={{ textAlign: "center" }}
                  className="m-3 mt-3 mb-3"
                >
                  {envioPedido.observacion ? (
                    <div>
                      <p>
                        <span className="font-weight-bold">
                          Observación anterior:
                        </span>
                        {envioPedido.observacion
                          ? envioPedido.observacion
                          : null}
                      </p>
                    </div>
                  ) : observation === "" ? (
                    <span className="text-danger">
                      Debes ingresar una observación
                    </span>
                  ) : null}
                </GridContainer>
                {observation ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={updateAndSend}
                    className="m-1"
                  >
                    Guardar y enviar
                  </Button>
                ) : <Button
                disabled
                variant="contained"
                color="primary"
                className="m-1"
              >
                Guardar y enviar
              </Button>
            }
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
