import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { green, red, indigo } from "@material-ui/core/colors";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import ModalAdd from "./ModalAdd";
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
  TableContainer
} from "@material-ui/core";
import {
  CustomTableCell
} from "utils/componentsStyle";
import ModalEditDiscountItem from "./ModalEditDiscountItem";
import ModalDeleteItemTable from "./ModalDeleteItemTable";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import { Edit, Delete, Done } from "@material-ui/icons/";
import allActions from 'actions';
import {toMoneyFormat} from "utils/formatFunctions";
import {
  getListSuppliesMedicines,
} from "actions/getListAction";
import {setmessageGeneral} from "actions/getGlobalAction";


// eslint-disable-next-line react/prop-types
export default function ResourseTable({
  medicalResourseTotal,
  dataArray,
  setdataArray,
  viewQuotation,
  deleteArray,
  setdeleteArray,
  updateArray,
  setupdateArray,
  editable=true
}) {
  const dispatch = useDispatch();
  const [tempArray, setTempArray] = useState(dataArray);
  const [packageTotal, setpackageTotal] = useState(0);
  const [packageExtraTotal, setpackageExtraTotal] = useState(0);
  const [openModal, setopenModal] = useState(null);
  const [loading, setloading] = useState(null);
  const [change, setchange] = useState(true);
  const [editableDiscount, seteditableDiscount] = useState(null);
  const [openDeleteModal, setopenDeleteModal] = useState(null);
  const [tempQuotation, settempQuotation] = useState(null);
  const [tempTypeQuotation, settempTypeQuotation] = useState(null);
  const [updateDict, setupdateDict] = useState(null);
  const tempType = "_insumos_medicamentos";

  const medicalResourseBase = useSelector(state => state.currentGlobalVar.ammountValuePackage);
  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const listResource = useSelector(state => state.currentList.listSupplies);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);
  const dictDiscountQuotation = useSelector(state => state.currentList.dictDiscountQuotation);
  const quotationDiscount = useSelector(state => state.currentGlobalVar.quotationDiscount);
  const typeQuotationDiscount = useSelector(state => state.currentGlobalVar.typeQuotationDiscount);

  const [pageIn, setPageIn] = useState(0);
  const [pageOut, setPageOut] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage, setpage) => {
      setpage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPageIn(0);
      setPageOut(0);
  };

  useEffect(() => {
    if(listResource.length === 0) {
        getListSuppliesMedicines(dispatch, chargeMedicine)
      }
  }, [])

  useEffect(() => {
    if (loading){
      setloading(false);
    }
    if(change){
      calculateAmount();
    }
    if(tempQuotation !== quotationDiscount || tempTypeQuotation !== typeQuotationDiscount){
      settempQuotation(quotationDiscount);
      settempTypeQuotation(typeQuotationDiscount);
      setloading(true);
    }
  });

  const calculateAmount = () => {
    setchange(false);
    let amountPackage = 0;
    let amountPackageExtra = 0;
    const aux = medicalResourseBase - packageTotal
    const auxMedicalResourseBase = aux > 0 ? aux : 0;
    dispatch(allActions.setGlobalVar.setammountValuePackage(aux))
    dataArray.map((row) => {
      if (row.fuera_paquete){
        const tempDiscount = dictDiscountQuotation[`${row.id_insumo_medicamento?.id}_insumos_medicamentos_true`]
        if(tempDiscount){
          amountPackageExtra += calculateIndividualDiscount(
            row.valor,
            row.cantidad,
            tempDiscount.porcentaje_item,
            tempDiscount.tipo_porcentaje
          )
        } else {
          amountPackageExtra += row.valor*row.cantidad;
        }
      } else {
        const tempDiscount = dictDiscountQuotation[`${row.id_insumo_medicamento?.id}_insumos_medicamentos_false`]
        if(tempDiscount){
          amountPackage += calculateIndividualDiscount(
            row.valor,
            row.cantidad,
            tempDiscount.porcentaje_item,
            tempDiscount.tipo_porcentaje
          )
        } else {
          amountPackage += parseInt(row.cantidad* row.valor);
        }
      }
    });
    dispatch(allActions.setGlobalVar.setsumAmmountValuePackage(amountPackage))
    setpackageTotal(amountPackage);
    setpackageExtraTotal(amountPackageExtra);
    const tempTotal = auxMedicalResourseBase + amountPackage;
    setloading(true);
  };

  const handleDeleteRow = () => {
    const row = openDeleteModal.row;
    const index = openDeleteModal.index;
    const table = openDeleteModal.in;
    const tempDeleteArray = deleteArray;
    if (row.id){
      tempDeleteArray.push(row.id)
      setdeleteArray(tempDeleteArray);
    }
    let tempData1 = dataArray.filter((row) => row.fuera_paquete === false );
    let tempData2 = dataArray.filter((row) => row.fuera_paquete === true );
    if (table){
      tempData2 = tempData2.filter((temprow, tempIndex) => index !== tempIndex )
    } else {
      tempData1 = tempData1.filter((temprow, tempIndex) => index !== tempIndex )
    }
    const auxDataArray = [...tempData1, ...tempData2]
    setdataArray(auxDataArray);
    setTempArray(auxDataArray);
    setopenDeleteModal(null);
    setchange(true);
    setloading(true);
  };

  const handleChangeQuantity = (value, index, atributte, table) => {
    const tempUpdateArray = updateArray;
    const tempData1 = dataArray.filter((row) => row.fuera_paquete === false );
    const tempData2 = dataArray.filter((row) => row.fuera_paquete === true );
    let row = {}
    if (table){
      tempData2[index][atributte] = parseInt(value);
      row = tempData2[index];
    } else {
      tempData1[index][atributte] = parseInt(value);
      row = tempData1[index];
    }
    if (row?.id){
      tempUpdateArray.push(row);
    }
    const auxDataArray = [...tempData1, ...tempData2]
    setupdateArray(tempUpdateArray)
    setdataArray(auxDataArray);
    setTempArray(auxDataArray);
    setchange(true);
    setloading(true);
  };

  const handleAddItem = (value, table) => {
    const auxArray = dataArray;
    const tempData = medicalResourceData[value.value];
    const tempIndex = auxArray.findIndex((row) => (
      row.id_insumo_medicamento?.SKU === tempData.SKU &&
      row.fuera_paquete === table
    ));
    if (tempIndex === -1){
      const element = {
        frecuencia: 1,
        tipo_frecuencia: "Meses",
        cantidad: 1,
        fuera_paquete: table,
        valor: parseInt(tempData.valor_con_iva),
        otros_datos: "-",
        id_prestaciones: null,
        id_insumo_medicamento: tempData,
        id_paquete_cotizacion: viewQuotation.packagingInfo,
        sku_especifico: " ",
        detalle_especifico: " ",
        otros_datos: " ",
        frecuencia_original: 1,
        tipo_frecuencia_original:  "Meses",
        cantidad_original: 1,
      }
      auxArray.push(element);
      setdataArray(auxArray);
      setchange(true);
      setloading(true);
    } else {
      setmessageGeneral(dispatch, "El insumo ya se encuentra en la lista", "warning", "¡Atencion!");
    }
  };

  const checkDiscountItem = (row) => {
    return dictDiscountQuotation[`${row.id_insumo_medicamento?.id}${tempType}_${row.fuera_paquete}`] || false;
  }

  const calculateIndividualDiscount = (amount, value, discount, typeDiscount) => {
    if (!value){
      return 0;
    }
    const total = amount*value;
    const totalDiscount = typeDiscount === "Descuento" ? discount*-1 : discount;
    return parseInt(totalDiscount == 0 ? total : total + (total*(totalDiscount/100)));
  };

  const calculateGeneralDiscount = (amount, value, discount, typeDiscount) => {
    let totalDiscount = 0;
    const total = amount*value;
    if (!value){
      return 0;
    } else if(discount && quotationDiscount){
      const tempDiscount1 =  typeDiscount === "Descuento" ? discount*-1 : discount;
      const tempDiscount2 =  typeQuotationDiscount === "Descuento" ? quotationDiscount*-1 : quotationDiscount;
      totalDiscount = total + (total*(tempDiscount1/100))
      return totalDiscount + (totalDiscount*(tempDiscount2/100))
    } else if(discount){
      totalDiscount = typeDiscount === "Descuento" ? discount*-1 : discount;
      return total + (total*(totalDiscount/100))
    } else if(quotationDiscount){
      totalDiscount = typeQuotationDiscount === "Descuento" ? quotationDiscount*-1 : quotationDiscount;
      return total + (total*(totalDiscount/100))
    } else {
      return amount*value;
    }
    return total;
  };

  const handleSaveDiscount = (typeDiscount, valuePercentage)  => {
    const key = `${editableDiscount.id_insumo_medicamento.id}${tempType}_${editableDiscount.fuera_paquete}`
    const tempDictDiscountQuotation = {...dictDiscountQuotation}
    tempDictDiscountQuotation[key] = {
      tipo_porcentaje: typeDiscount,
      porcentaje_item: valuePercentage,
      fuera_paquete: editableDiscount.fuera_paquete,
      id_paquete_cotizacion: editableDiscount.id_paquete_cotizacion.id,
      id_prestaciones: null,
      id_insumo_medicamento: editableDiscount.id_insumo_medicamento.id,
      id_equipo: null,
    };
    dispatch({
      type: "DICCIONARIO_DESCUENTOS_COTIZACION",
      payload: tempDictDiscountQuotation
    });
    seteditableDiscount(null);
    setchange(true);
    setloading(true);
  };

  const handleUpdateValue = (index, column, table) => {
    setupdateDict(updateDict ? null : { index, column, table })
    setchange(true);
  };

  const componentUpdateValue = (value, handleFunction, index, column, table) => {
    const validateEditable = (updateDict?.index === index && updateDict?.column === column && updateDict?.table === table)
    const disableEdit = (updateDict && (updateDict?.index !== index || updateDict?.column !== column))
    return editable ? <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center">
    <Grid item container xs={9} direction="row" justify="center" alignItems="center">
    {validateEditable ?
      <DetailNumber
        value={value}
        onchange={e => {
          if (/^[0-9\b]+$/.test(e.target.value)) {
            handleFunction(e.target.value, index, column, table);
          }
        }}
      />:
      value
    }
    </Grid>
    <Grid item container xs={3} direction="row" justify="flex-end" alignItems="center">
      {disableEdit ?
        <IconButton size="small"
          disabled
          style={{
            border:`1px ${indigo[50]} solid`
          }}
        >
          <Edit style={{color: indigo[50]}} />
        </IconButton> :
        <IconButton size="small"
        style={{
          border:`1px ${validateEditable ? green[500] :  indigo[500]} solid`,
          margin: "0 0 0 12px"
        }}
        onClick={() => handleUpdateValue(index, column, table)}
      >
        {validateEditable ?
          <Done style={{color: green[500]}}/>:
          <Edit style={{color: indigo[500]}} />
        }
      </IconButton>
      }

    </Grid>
  </Grid> : value
  };

  const componentUpdateDiscount = (row) => {
    return (!checkDiscountItem(row) && !row.editar_descuento && editable) ?
      <Button variant="outlined"
       style={{
         color: indigo[500],
         borderColor: indigo[500],
       }}
       onClick={() => seteditableDiscount(row)}
     >
       Agregar %
     </Button> :
     <Grid
       container
       direction="row"
       justify="center"
       alignItems="center"
       item
       xs={12}
     >
       <Grid
         container
         direction="row"
         justify="flex-end"
         alignItems="center"
         item
         xs={8}
       >
           <Typography variant="body2" style={{color: "#000000ad"}}>
           {`(${checkDiscountItem(row).tipo_porcentaje === "Descuento" ? "-" : "+"}${checkDiscountItem(row).porcentaje_item || 0}%)`}
           </Typography>
           {editable &&
           <IconButton size="small"
             style={{
               border:`1px ${indigo[500]} solid`,
               margin: "0 0 0 12px"
             }}
             onClick={() => seteditableDiscount(row)}
           >
             <Edit style={{color: indigo[500]}} />
           </IconButton>
          }
       </Grid>
       <Grid
         container
         direction="row"
         justify="center"
         alignItems="center"
         item
         xs={4}
       >
           <Typography variant="body2">
             {toMoneyFormat(calculateIndividualDiscount(
               row.cantidad,
               row.valor,
               checkDiscountItem(row).porcentaje_item,
               checkDiscountItem(row).tipo_porcentaje
             ))}
           </Typography>
       </Grid>
     </Grid>
  }

  return (
    <Card>
      <CardBody>
        <GridContainer>
          {loading  && <div></div> }
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
            style={{margin: "0 0 32px 0"}}
          >
            <Typography >
            {`En paquete: ${viewQuotation.packagingInfo?.zona} ${viewQuotation.packagingInfo?.complejidad} ${viewQuotation.packagingInfo?.descripcion ? `- ${viewQuotation.packagingInfo?.descripcion}` : ""}`}
            </Typography>
          </Grid>
          {editable &&
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
            style={{margin: "0 0 32px 0"}}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setopenModal(false)}
            >
              {`Agregar Insumos`}
            </Button>
          </Grid>
          }
          <Grid item xs={12} style={{margin: "30px 0 0 0", maxHeight: "300px", overflow: "auto",}}>
            <TableContainer style={{overflowX: 'auto'}}>
            <Table size={'small'}>
              <TableHead>
              <TableRow>
                  <CustomTableCell align="center" style={{width: "200px"}}>Item </CustomTableCell>
                  <CustomTableCell align="center" style={{width: "200px"}}>SKU </CustomTableCell>
                  <CustomTableCell align="center" style={{width: "120px"}}>Stock </CustomTableCell>
                  <CustomTableCell align="center" style={{width: "200px"}}>Cantidad mensual </CustomTableCell>
                  <CustomTableCell align="center">Total</CustomTableCell>
                   <CustomTableCell align="center" style={{minWidth: "200px"}}>Total con descuento o recargo</CustomTableCell>
                  <CustomTableCell align="center" style={{minWidth: "200px"}}>Total con descuento o recargo general</CustomTableCell>
                  <CustomTableCell align="center">Eliminar </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tempArray.filter((row) => row.fuera_paquete === false ).slice(pageIn * rowsPerPage, pageIn * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow
                    key={`${index}-workRows`}
                  >
                    <CustomTableCell align="center">{row.id_insumo_medicamento.descripcion_producto} </CustomTableCell>
                    <CustomTableCell align="center">{row.id_insumo_medicamento.SKU}</CustomTableCell>
                    <CustomTableCell align="center">{row.id_insumo_medicamento.stock_producto}</CustomTableCell>
                    <CustomTableCell align="center">
                      {componentUpdateValue(
                        row.cantidad,
                        handleChangeQuantity,
                        index+(pageIn * rowsPerPage),
                        "cantidad",
                        false
                      )}
                    </CustomTableCell>
                    <CustomTableCell align="center">{toMoneyFormat(row.cantidad * row.valor)}</CustomTableCell>
                    <CustomTableCell align="center">
                      {componentUpdateDiscount(row)}
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      <Typography variant="body2">
                        {toMoneyFormat(calculateGeneralDiscount(
                          row.cantidad,
                          row.valor,
                          checkDiscountItem(row)?.porcentaje_item,
                          checkDiscountItem(row)?.tipo_porcentaje
                        ))}
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      {editable &&
                      <Button
                        variant="outlined"
                        style={{
                          color: red[500],
                          borderColor: red[500],
                        }}
                        onClick={() => setopenDeleteModal({row, index: index+(pageOut * rowsPerPage), in: false})}
                        startIcon={<Delete style={{color: red[500]}}/>}
                      >
                        Eliminar
                      </Button>
                      }
                    </CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: -1 }]}
                        colSpan={12}
                        count={tempArray.filter((row) => row.fuera_paquete === false ).length}
                        rowsPerPage={rowsPerPage}
                        page={pageIn}
                        SelectProps={{
                            inputProps: { 'aria-label': 'Filas por pagina' },
                            native: false,
                        }}
                        labelRowsPerPage={'Filas por pagina'}
                        nextIconButtonText={'Siguiente'}
                        backIconButtonText={'Atras'}
                        labelDisplayedRows={({ from, to, count }) =>`${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `mas que ${to}`}`}
                        onChangePage={(event, page) => handleChangePage(event, page, setPageIn)}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        style={{textTransform: "none"}}
                    />
                </TableRow>
            </TableFooter>
           </Table>
           </TableContainer >
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            item
            xs={12}
          >
            <Typography >
              {`${toMoneyFormat(calculateGeneralDiscount(
                packageTotal,
                1,
                0,
                "Descuento",
              ))}`}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
            style={{margin: "68px 0 32px 0"}}
          >
            <Typography >
              Extra a permanencia
            </Typography>
          </Grid>
          {editable &&
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
            style={{margin: "0 0 32px 0"}}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setopenModal(true)}
            >
              {`Agregar Insumos`}
            </Button>
          </Grid>
          }
          <Grid item xs={12} style={{margin: "30px 0 0 0", maxHeight: "300px", overflow: "auto",}}>
            <TableContainer style={{overflowX: 'auto'}}>
            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  <CustomTableCell align="center" style={{width: "200px"}}>Item </CustomTableCell>
                  <CustomTableCell align="center" style={{width: "200px"}}>SKU </CustomTableCell>
                  <CustomTableCell align="center" style={{width: "120px"}}>Stock </CustomTableCell>
                  <CustomTableCell align="center" style={{width: "200px"}}>Cantidad mensual </CustomTableCell>
                  <CustomTableCell align="center">Total </CustomTableCell>
                  <CustomTableCell align="center" style={{minWidth: "200px"}}>Total con descuento o recargo</CustomTableCell>
                  <CustomTableCell align="center" style={{minWidth: "200px"}}>Total con descuento o recargo general</CustomTableCell>
                  <CustomTableCell align="center">Eliminar </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tempArray.filter((row) => row.fuera_paquete === true ).slice(pageOut * rowsPerPage, pageOut * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow
                    key={`${index}-workRows`}
                  >
                    <CustomTableCell align="center">{row.id_insumo_medicamento.descripcion_producto} </CustomTableCell>
                    <CustomTableCell align="center">{row.id_insumo_medicamento.SKU}</CustomTableCell>
                    <CustomTableCell align="center">{row.id_insumo_medicamento.stock_producto}</CustomTableCell>
                    <CustomTableCell align="center">
                      {componentUpdateValue(
                        row.cantidad,
                        handleChangeQuantity,
                        index+(pageIn * rowsPerPage),
                        "cantidad",
                        true,
                      )}
                    </CustomTableCell>
                    <CustomTableCell align="center">{toMoneyFormat(row.cantidad * row.valor)}</CustomTableCell>
                    <CustomTableCell align="center">
                      {componentUpdateDiscount(row)}
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      <Typography variant="body2">
                        {toMoneyFormat(calculateGeneralDiscount(
                          row.cantidad,
                          row.valor,
                          checkDiscountItem(row)?.porcentaje_item,
                          checkDiscountItem(row)?.tipo_porcentaje
                        ))}
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      {editable &&
                      <Button
                        variant="outlined"
                        style={{
                          color: red[500],
                          borderColor: red[500],
                        }}
                        onClick={() => setopenDeleteModal({row, index: index+(pageOut * rowsPerPage), in: true})}
                        startIcon={<Delete style={{color: red[500]}}/>}
                      >
                        Eliminar
                      </Button>
                      }
                    </CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: -1 }]}
                        colSpan={12}
                        count={tempArray.filter((row) => row.fuera_paquete === true ).length}
                        rowsPerPage={rowsPerPage}
                        page={pageOut}
                        SelectProps={{
                            inputProps: { 'aria-label': 'Filas por pagina' },
                            native: false,
                        }}
                        labelRowsPerPage={'Filas por pagina'}
                        nextIconButtonText={'Siguiente'}
                        backIconButtonText={'Atras'}
                        labelDisplayedRows={({ from, to, count }) =>`${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `mas que ${to}`}`}
                        onChangePage={(event, page) => handleChangePage(event, page, setPageOut)}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        style={{textTransform: "none"}}
                    />
                </TableRow>
            </TableFooter>
           </Table>
           </TableContainer >
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            item
            xs={12}
          >
            <Typography >
              {`${toMoneyFormat(calculateGeneralDiscount(
                packageExtraTotal,
                1,
                0,
                "Descuento",
              ))}`}
            </Typography>
          </Grid>
        </GridContainer>
      </CardBody>
      {openModal !== null &&
        <ModalAdd
          open={openModal !== null}
          table={openModal}
          handleClose={() => setopenModal(null)}
          listItem={listResource}
          dataArray={dataArray}
          handleAddItem={handleAddItem}
        />
      }
      {editableDiscount && (
        <ModalEditDiscountItem
          open={!!editableDiscount}
          handleClose={() => seteditableDiscount(null)}
          row={editableDiscount}
          handleSaveDiscount={handleSaveDiscount}
        />
      )}
        {openDeleteModal && (
        <ModalDeleteItemTable
          open={!!openDeleteModal}
          handleClose={() => setopenDeleteModal(null)}
          handleDelete={handleDeleteRow}
        />
      )}
    </Card>
  );
}
