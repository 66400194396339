import React from "react";
import Grid from "@material-ui/core/Grid";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const SelectExam = props => {
  const { examSelected, handleExamSelected, examsList } = props;

  return (
    <Grid item container xs={12} justify="center">
      <Grid item xs={1} />
      <Grid item container xs={10} justify="center">
        <Grid item container xs={12} justify="center">
          <Typography variant="body1" gutterBottom justify="center">
            ¿Qué examen desea solicitar?
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={4}
          style={{ marginBottom: "1em" }}
          justify="center"
        >

            <DetailSelect
              value={examSelected}
              onchange={handleExamSelected}
              validation={false}
              list={examsList}
              placeholder="Seleccione Examen"

            />
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default SelectExam;

SelectExam.propTypes = {
  examSelected: PropTypes.string,
  handleExamSelected: PropTypes.func,
  examsList: PropTypes.array
};
