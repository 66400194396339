import React from "react";
import apiform from "../../utils/APIForm";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Slide
} from "@material-ui/core";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const handleLogout = () =>{
  const formData = new FormData();
  formData.append("refresh_token", localStorage.getItem("refresh"));
  apiform
    .post("appauth/token/blacklist/", formData)
    .then(() => {
      window.location.href = `/`;
    })
    .catch(() => {
      window.location.href = `/`;
    });
};
export default class DialogAttendance extends React.Component {
  render() {
    return (
      <Paper mt={4}>
        <Dialog
          open={this.props.openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.props.handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth={false}
          maxWidth={"xs"}
        >
          <DialogTitle id="alert-dialog-slide-title">
            {this.props.title == ""
              ? "¿Desea marcar asistencia?"
              : this.props.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.props.message}
            </DialogContentText>
          </DialogContent>
          {/* Dependiendo de si su ultimo registro de asistencia fue una salida o entrada, le recordara al usuario que es lo que debe marcar */}
          {this.props.attendance === "logout" ? (
            <DialogActions>
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: "#3F51B5",
                  color: "white"
                }}
                variant="contained"
                onClick={this.props.handleCloseDialog}
                href={this.props.url}
              >
                Registrar, entrada
              </Button>
              <Button
                style={{
                  border: "none",
                  outline: "none",
                  textTransform: "none",
                  backgroundColor: "#F44336",
                  color: "white"
                }}
                onClick={handleLogout}
              >
                No, salir de todas formas
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button
                style={{ 
                  textTransform: "none",
                  backgroundColor: "#3F51B5",
                  color: "white"
                }}
                variant="contained"
                onClick={this.props.handleCloseDialog}
                color="primary"
              >
                Cancelar
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: "#F44336",
                  color: "white"
                }}
                variant="contained"
                onClick={this.props.handleCloseDialog}
                href={this.props.url}
              >
                Sí, Registrar salida
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </Paper>
    );
  }
}
