import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import moment from "moment-timezone";
import "moment/locale/es";
import { days_open } from "components/Request/ManageRequest/DaysOpen";
import api from "utils/API";
moment.locale("es");
import allActions from "actions";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const RequestInfo = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    idNotaVenta,
    orderInfo,
  } = props;

  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);

  const months_open = (date) => {
    const today = moment(new Date()).format("DD/MM/YYYY");
    const request_date = moment(date).format("DD/MM/YYYY");
    const diff = moment(today, "DD/MM/YYYY").diff(
      moment(request_date, "DD/MM/YYYY")
    );
    const duration = moment.duration(diff);
    return duration._data.months;
  };

  const getNotaVenta = () => {

    api.get(`api/documentosbodega/${idNotaVenta}`, { responseType: "blob" }).then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Nota_Venta.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      })
  };


  return (

    <Grid container className={classes.root} justify="center">
        <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ marginBottom: "3em" }}
        >
            <div className="card" style={{width: "100%"}}>
                <div className="card-header">
                <div className="row">
                    <div className="col" />
                    <div className="col-6 text-center">Datos del pedido</div>
                    <div className="col text-right">
                    <span className="font-weight-bold">Estado pedido: </span>
                    {orderInfo.estado_solicitud}
                    </div>
                </div>
                </div>
                <div className="card-body">
                <div className="row">
                    <div className="col-sm text-left ml-5">
                        <span className="font-weight-bold">Id: </span>
                        {orderInfo.id}
                        <br />
                        <span className="font-weight-bold">Tipo de solicitud: </span>
                        {dictTypeRequest[orderInfo.tipo_solicitud]?.label || orderInfo.tipo_solicitud}
                        <br />
                        <span className="font-weight-bold">Paciente: </span>
                        {orderInfo.nombre_paciente}
                    </div>
                    <div className="col-sm text-left">
                        <span className="font-weight-bold">Fecha solicitud: </span>
                        {moment(orderInfo.fecha_solicitud).format("YYYY-MM-DD HH:mm")}
                        <br />
                        <span className="font-weight-bold">Fecha a despachar: </span>
                        {moment(orderInfo.fecha_tentativa_llegada).format("YYYY-MM-DD HH:mm")}
                        <br />
                        <span className="font-weight-bold">Región: </span>
                        {orderInfo.es_region ? "Si" : "No"}
                    </div>
                    <div className="col-sm text-left">
                        <span className="font-weight-bold">Estado picking: </span>
                        {orderInfo.estado_picking}
                        <br />
                        <span className="font-weight-bold">Días de atraso: </span>
                        Meses:
                        {months_open(orderInfo.fecha_tentativa_llegada
                        )}
                        /  Días:
                        {days_open(orderInfo.fecha_tentativa_llegada)}
                        <br />
                        <span className="font-weight-bold">Medicamento: </span>
                        {orderInfo.tiene_medicamentos ? "Si" : "No"}
                    </div>
                </div>
                <div className="row">
                <div className="col-sm text-center">
                {orderInfo.observacion &&
                <TextField
                    disabled={true}
                    label={"Observaciones"}
                    multiline
                    rows={5}
                    value={orderInfo.observacion}
                    variant="outlined"
                    onChange={(event) => {}}
                    style={{width: "100%"}}
                />
                }
                </div>
                </div>
                <div className="row">
                <div className="col-sm text-center">
                {idNotaVenta &&
                <Button
                    className="mt-4"
                    variant="contained"
                    onClick={getNotaVenta}
                    color="primary"
                >
                    Ver nota de venta
                </Button>
                }
                </div>
                </div>
                </div>
            </div>
        </Grid>
    </Grid>
  );
};

export default RequestInfo;
