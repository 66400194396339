import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import ModalCreateCovenant from "components/Covenants/ModalCreateCovenant";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { days_open } from "components/Request/ManageRequest/DaysOpen";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { useHistory } from "react-router-dom";
import OrdersInformation from "./OrdersInformation";
import Fade from "@material-ui/core/Fade";
import TableInProcess from "./Tables/TableInProcess";
import TableToOutcome from "./Tables/TableToOutcome";
import TableFinalized from "./Tables/TableFinalized";
import {  setmessageAlert } from "actions/getGlobalAction";


const useSortableDataInProcess = (itemsInProcess, config = null) => {
  const [sortConfigInProcess, setSortConfigInProcess] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...itemsInProcess];
    if (sortConfigInProcess !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfigInProcess.key] < b[sortConfigInProcess.key]) {
          return sortConfigInProcess.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigInProcess.key] > b[sortConfigInProcess.key]) {
          return sortConfigInProcess.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [itemsInProcess, sortConfigInProcess]);

  const requestSortInProcess = key => {
    let direction = "ascending";
    if (
      sortConfigInProcess &&
      sortConfigInProcess.key === key &&
      sortConfigInProcess.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfigInProcess({ key, direction });
  };

  return {
    itemsInProcess: sortedItems,
    requestSortInProcess,
    sortConfigInProcess
  };
};

const useSortableDataToOutcome = (itemsToOutcome, config = null) => {
  const [sortConfigToOutcome, setSortConfigToOutcome] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...itemsToOutcome];
    if (sortConfigToOutcome !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfigToOutcome.key] < b[sortConfigToOutcome.key]) {
          return sortConfigToOutcome.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigToOutcome.key] > b[sortConfigToOutcome.key]) {
          return sortConfigToOutcome.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [itemsToOutcome, sortConfigToOutcome]);

  const requestSortToOutcome = key => {
    let direction = "ascending";
    if (
      sortConfigToOutcome &&
      sortConfigToOutcome.key === key &&
      sortConfigToOutcome.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfigToOutcome({ key, direction });
  };

  return {
    itemsToOutcome: sortedItems,
    requestSortToOutcome,
    sortConfigToOutcome
  };
};

const useSortableDataFinalized = (itemsFinalized, config = null) => {
  const [sortConfigFinalized, setSortConfigFinalized] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...itemsFinalized];
    if (sortConfigFinalized !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfigFinalized.key] < b[sortConfigFinalized.key]) {
          return sortConfigFinalized.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigFinalized.key] > b[sortConfigFinalized.key]) {
          return sortConfigFinalized.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [itemsFinalized, sortConfigFinalized]);

  const requestSortFinalized = key => {
    let direction = "ascending";
    if (
      sortConfigFinalized &&
      sortConfigFinalized.key === key &&
      sortConfigFinalized.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfigFinalized({ key, direction });
  };

  return {
    itemsFinalized: sortedItems,
    requestSortFinalized,
    sortConfigFinalized
  };
};

export default function WareHouseOrders() {
  const dispatch = useDispatch();
  const [moduleType, setmoduleType] = useState("");
  const [actualGroup, setactualGroup] = useState("");
  const [waitToOutcome, setWaitToOutcome] = useState([]);
  const [warehouseInProcess, setWarehouseInProcess] = useState([]);
  const [warehouseFinalized, setWarehouseFinalized] = useState([]);
  const [inputInProcess, setInputInProcess] = useState("");
  const [inputToOutcome, setInputToOutcome] = useState("");
  const [inputFinalized, setInputFinalized] = useState("");

  const {
    itemsInProcess,
    requestSortInProcess,
    sortConfigInProcess
  } = useSortableDataInProcess(warehouseInProcess);

  const getClassNamesForInProcess = name => {
    if (!sortConfigInProcess) {
      return;
    }
    return sortConfigInProcess.key === name
      ? sortConfigInProcess.direction
      : undefined;
  };

  const {
    itemsToOutcome,
    requestSortToOutcome,
    sortConfigToOutcome
  } = useSortableDataToOutcome(waitToOutcome);

  const getClassNamesForToOutcome = name => {
    if (!sortConfigToOutcome) {
      return;
    }
    return sortConfigToOutcome.key === name
      ? sortConfigToOutcome.direction
      : undefined;
  };

  const {
    itemsFinalized,
    requestSortFinalized,
    sortConfigFinalized
  } = useSortableDataFinalized(warehouseFinalized);

  const getClassNamesForFinalized = name => {
    if (!sortConfigFinalized) {
      return;
    }
    return sortConfigFinalized.key === name
      ? sortConfigFinalized.direction
      : undefined;
  };

  const [requestTypeInProcess, setRequestTypeInProcess] = useState("");
  const [requestTypeToOutcome, setRequestTypeToOutcome] = useState("");
  const [requestTypeFinalized, setRequestTypeFinalized] = useState("");
  const [pickingStatusInProcess, setPickingStatusInProcess] = useState("");
  const [pickingStatusToOutcome, setPickingStatusToOutcome] = useState("");
  const [pickingStatusFinalized, setPickingStatusFinalized] = useState("");
  const [regionInProcess, setRegionInProcess] = useState("");
  const [regionToOutcome, setRegionToOutcome] = useState("");
  const [regionFinalized, setRegionFinalized] = useState("");
  const [supplyInProcess, setSupplyInProcess] = useState("");
  const [supplyToOutcome, setSupplyToOutcome] = useState("");
  const [supplyFinalized, setSupplyFinalized] = useState("");

  const [loading, setLoading] = useState(false);
  const [modal_create_covenant, setModal_create_covenant] = useState(false);
  const [value, setValue] = useState(0);

  const [checkTab, setCheckTab] = useState(0);
  const [changes, setChanges] = useState(true);

  const [loadEgresos, setLoadEgresos] = useState(true);
  const [loadFinalized, setLoadFinalized] = useState(true);

  const history = useHistory();

  const routeChange = () => {
    let path = `/admin/warehouse/calendar/${moduleType}`;
    history.push(path);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    if (value === 0) {
      setCheckTab(0);
    }
    if (value === 1) {
      setCheckTab(1);
    }
    if (value === 2) {
      setCheckTab(2);
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
      setInterval(() => setChanges(true), 300000);
  }, [])

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  }, [changes]);

  const initialChargeReception = () => {
    setChanges(false);
    const tempArrayURL = window.location.href.split('/');
    const tempModuleType = tempArrayURL[tempArrayURL.length-1];
    setmoduleType(tempModuleType);
    const group = tempModuleType === "storage" ? "Insumos" : "Medicamentos";
    setactualGroup(tempModuleType === "storage" ? "insumo" : "farmacia");
    const PromiseWarehouseInProcess = api.get(`api/bodega/pedidos/grupo/${group}/estado/Activas/`);
    Promise.all([PromiseWarehouseInProcess]).then(response => {
      const newData = response[0].data
      const oldInprogress = warehouseInProcess.map((row) => {return row.id})
      const newInprogress = newData.filter(newItem => !oldInprogress.includes(newItem.id));
      let  message = ""
      if (oldInprogress.length == 0 && newInprogress.length > 0){
        message = `Tiene ${newInprogress.length} solicitudes que requieren su atención`;
      } else if (newInprogress.length > 0){
        const ids = newInprogress.reduce((accum, obj) => {return `${accum}, ${obj.id}`}, "")
        message = `Tiene ${newInprogress.length} solicitudes nuevas que requieren su atención (${ids})`;
      }
      setmessageAlert(dispatch, message, "success" )


      setWarehouseInProcess(newData)
    })

    setLoading(true);
  };

  const months_open = date => {
    const today = moment(new Date()).format("DD/MM/YYYY");
    const request_date = moment(date).format("DD/MM/YYYY");
    const diff = moment(today, "DD/MM/YYYY").diff(
      moment(request_date, "DD/MM/YYYY")
    );
    const duration = moment.duration(diff);
    return duration._data.months;
  };

  const getInfo = information => {
    const response = information ? "si" : "no";
    return response;
  };

  let searchFinalized = itemsFinalized.filter(order => {
    try {
    return (
      (
        order.id.toString().toLowerCase().indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.tipo_solicitud.toLowerCase().indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.fecha_solicitud.toLowerCase().indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.estado_picking.toLowerCase().indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.fecha_despacho.toLowerCase().indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.nombre_paciente.toLowerCase().indexOf(inputFinalized.toLowerCase()) !== -1 ||
        getInfo(order.es_region).toString().toLowerCase().indexOf(inputFinalized.toLowerCase()) !== -1 ||
        getInfo(order.tiene_medicamentos).toString().toLowerCase().indexOf(inputFinalized.toLowerCase()) !== -1 ||
        months_open(order.fecha_solicitud).toString().toLowerCase().indexOf(inputFinalized.toLowerCase()) !== -1 ||
        days_open(order.fecha_solicitud).toString().toLowerCase().indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.estado_solicitud.toLowerCase().indexOf(inputFinalized.toLocaleLowerCase()) !== -1) &&
        (
          order.tipo_solicitud.toLowerCase().indexOf(requestTypeFinalized.toLowerCase()) !== -1 &&
          order.estado_picking.toLowerCase().indexOf(pickingStatusFinalized.toLowerCase()) !== -1 &&
          getInfo(order.es_region).toString().toLowerCase().indexOf(regionFinalized.toLowerCase()) !== -1 &&
          getInfo(order.tiene_medicamentos).toString().toLowerCase().indexOf(supplyFinalized.toLowerCase()) !== -1
        )
    );
    } catch (error) {
      {};
    }
  });

  let searchToOutcome = itemsToOutcome.filter(order => {
    try {
    return (
      (order.id.toString().toLowerCase().indexOf(inputToOutcome.toLowerCase()) !== -1 ||
        order.tipo_solicitud.toLowerCase().indexOf(inputToOutcome.toLowerCase()) !== -1 ||
        order.fecha_solicitud.toLowerCase().indexOf(inputToOutcome.toLowerCase()) !== -1 ||
        order.estado_picking.toLowerCase().indexOf(inputToOutcome.toLowerCase()) !== -1 ||
        order.fecha_despacho.toLowerCase().indexOf(inputToOutcome.toLowerCase()) !== -1 ||
        order.nombre_paciente.toLowerCase().indexOf(inputToOutcome.toLowerCase()) !== -1 ||
        getInfo(order.es_region).toString().toLowerCase().indexOf(inputToOutcome.toLowerCase()) !== -1 ||
        getInfo(order.tiene_medicamentos).toString().toLowerCase().indexOf(inputToOutcome.toLowerCase()) !== -1 ||
        months_open(order.fecha_solicitud).toString().toLowerCase().indexOf(inputToOutcome.toLowerCase()) !== -1 ||
        days_open(order.fecha_solicitud).toString().toLowerCase().indexOf(inputToOutcome.toLowerCase()) !== -1 ||
        order.estado_solicitud.toLowerCase().indexOf(inputToOutcome.toLocaleLowerCase()) !== -1) &&
      (
        order.tipo_solicitud.toLowerCase().indexOf(requestTypeToOutcome.toLowerCase()) !== -1 &&
        order.estado_picking.toLowerCase().indexOf(pickingStatusToOutcome.toLowerCase()) !== -1 &&
        getInfo(order.es_region).toString().toLowerCase().indexOf(regionToOutcome.toLowerCase()) !== -1 &&
        getInfo(order.tiene_medicamentos).toString().toLowerCase().indexOf(supplyInProcess.toLowerCase()) !== -1
      )
    );
    } catch (error) {
      {};
    }
  });

  let searchInProcess = itemsInProcess.filter(order => {

    try {
      return (
        (order.id
          .toString()
          .toLowerCase()
          .indexOf(inputInProcess.toLowerCase()) !== -1 ||
          order.tipo_solicitud
            .toLowerCase()
            .indexOf(inputInProcess.toLowerCase()) !== -1 ||
          order.fecha_solicitud
            .toLowerCase()
            .indexOf(inputInProcess.toLowerCase()) !== -1 ||
          order.estado_picking
            .toLowerCase()
            .indexOf(inputInProcess.toLowerCase()) !== -1 ||
          order.fecha_despacho
            .toLowerCase()
            .indexOf(inputInProcess.toLowerCase()) !== -1 ||
          order.nombre_paciente
            .toLowerCase()
            .indexOf(inputInProcess.toLowerCase()) !== -1 ||
          getInfo(order.es_region)
            .toString()
            .toLowerCase()
            .indexOf(inputInProcess.toLowerCase()) !== -1 ||
          getInfo(order.tiene_medicamentos)
            .toString()
            .toLowerCase()
            .indexOf(inputInProcess.toLowerCase()) !== -1 ||
          months_open(order.fecha_solicitud)
            .toString()
            .toLowerCase()
            .indexOf(inputInProcess.toLowerCase()) !== -1 ||
          days_open(order.fecha_solicitud)
            .toString()
            .toLowerCase()
            .indexOf(inputInProcess.toLowerCase()) !== -1 ||
          order.estado_solicitud
            .toLowerCase()
            .indexOf(inputInProcess.toLocaleLowerCase()) !== -1) &&
        (order.tipo_solicitud
          .toLowerCase()
          .indexOf(requestTypeInProcess.toLowerCase()) !== -1 &&
          order.estado_picking
            .toLowerCase()
            .indexOf(pickingStatusInProcess.toLowerCase()) !== -1 &&
          getInfo(order.es_region)
            .toString()
            .toLowerCase()
            .indexOf(regionInProcess.toLowerCase()) !== -1 &&
          getInfo(order.tiene_medicamentos)
            .toString()
            .toLowerCase()
            .indexOf(supplyInProcess.toLowerCase()) !== -1)
      );
    } catch (error) {
      {}
    }
  });

  const styles = {
    icon: {
      fontSize: 50
    }
  };

  const loadFunctionEgresos = () => {
    if (loadEgresos) {
      setLoadEgresos(false);
      callEgresos();
    }
  };

  const callEgresos = () => {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    //const waitToOutcome = api.get(`api/bodega/envios/grupo/${group}/estado/EgresosBodega/`);
    //const waitToOutcome = api.get(
      //`api/bodega/pedidos/grupo/${group}/esperando/egreso/`
    //);
    const waitToOutcome = api.get(
      `api/bodega/pedidos/grupo/${group}/estado/Egresos/`
    );
    Promise.all([waitToOutcome])
      .then(response => {
        setWaitToOutcome(response[0].data);

      })

  };

  const loadFunctionFinalized = () => {
    if (loadFinalized) {
      setLoadFinalized(false);
      callFinalized();
    }
  };

  const callFinalized = () => {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    const warehouseFinalized = api.get(
      `api/bodega/pedidos/grupo/${group}/estado/Finalizadas/`
    );

    Promise.all([warehouseFinalized]).then(response => {setWarehouseFinalized(response[0].data);})
  };

  const pickingStatus = moduleType === "storage" ? "estado_solicitud_insumo" : "estado_picking_farmacia";

  const dateToDate = moment().format("YYYY-MM-DD");
  const firstDay = moment().startOf('week').add(1, 'days')
  const lastDay = moment().endOf('week').add(1, 'days')

  const listItemWait = itemsInProcess.filter((row) => row[pickingStatus] != "Completa")
  const listItemToDay= itemsInProcess.filter((row) => moment(row.fecha_despacho).format("YYYY-MM-DD") === dateToDate)
  const listItemWeek = itemsInProcess.filter((row) => moment(row.fecha_despacho) >= firstDay && moment(row.fecha_despacho) <= lastDay )

  return (
    <GridContainer>
      <ViewTitle
        title="PEDIDOS"
        message={`En esta sección podrás revisar los pedidos de ${moduleType === "storage" ? "bodega" : "bodega farmacia"}.`}
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
            {`Tareas ${moduleType === "storage" ? "Bodega" : "Bodega Farmacia"}`}
            </Link>
            <Typography color="textPrimary">Pedidos</Typography>
            {checkTab === 0 ? (
              <Typography color="textPrimary">En proceso</Typography>
            ) : checkTab === 1 ? (
              <Typography color="textPrimary">En espera de egresos</Typography>
            ) : (
              <Typography color="textPrimary">Finalizado</Typography>
            )}
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="mt-3">
              <GridContainer justify="center">
                <OrdersInformation 
                  itemWait={listItemWait.length}
                  itemToDay={listItemToDay.length}
                  itemWeek={listItemWeek.length}
                  total={itemsInProcess.length}
                />
              </GridContainer>
              {checkTab === 1 ? loadFunctionEgresos() : null}
              {checkTab === 2 ? loadFunctionFinalized() : null}
              {checkTab === 0 ? (
                <Fade in>
                  <TextField
                    id="outlined-basic"
                    label="Buscar en proceso"
                    variant="outlined"
                    value={inputInProcess}
                    className="mt-1"
                    onChange={e =>
                      setInputInProcess(e.target.value.substr(0, 20))
                    }
                  />
                </Fade>
              ) : checkTab === 1 ? (
                <Fade in>
                  <TextField
                    id="outlined-basic"
                    label="Buscar egresos"
                    variant="outlined"
                    value={inputToOutcome}
                    className="mt-1"
                    onChange={e =>
                      setInputToOutcome(e.target.value.substr(0, 20))
                    }
                  />
                </Fade>
              ) : (
                <Fade in>
                  <TextField
                    id="outlined-basic"
                    label="Buscar finalizado"
                    variant="outlined"
                    value={inputFinalized}
                    className="mt-1"
                    onChange={e =>
                      setInputFinalized(e.target.value)
                    }
                  />
                </Fade>
              )}
            </GridContainer>
            <GridItem
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <IconButton
                onClick={routeChange}
                color="primary"
                aria-label="add to shopping cart"
              >
                <InsertInvitationIcon style={styles.icon} />
              </IconButton>
            </GridItem>
            <CardBody className="mb-1">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  centered
                >
                  <Tab
                    style={{ textTransform: "none" }}
                    label="En proceso"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="En espera de egresos"
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Finalizado"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <TableInProcess
                  searchInProcess={searchInProcess}
                  requestSortInProcess={requestSortInProcess}
                  getClassNamesForInProcess={getClassNamesForInProcess}
                  requestTypeInProcess={requestTypeInProcess}
                  setRequestTypeInProcess={setRequestTypeInProcess}
                  pickingStatusInProcess={pickingStatusInProcess}
                  setPickingStatusInProcess={setPickingStatusInProcess}
                  regionInProcess={regionInProcess}
                  setRegionInProcess={setRegionInProcess}
                  supplyInProcess={supplyInProcess}
                  setSupplyInProcess={setSupplyInProcess}
                  moduleType={moduleType}
                  months_open={months_open}
                  days_open={days_open}
                  actualGroup={actualGroup}
                />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <TableToOutcome
                  searchToOutcome={searchToOutcome}
                  requestSortToOutcome={requestSortToOutcome}
                  getClassNamesForToOutcome={getClassNamesForToOutcome}
                  requestTypeToOutcome={requestTypeToOutcome}
                  setRequestTypeToOutcome={setRequestTypeToOutcome}
                  pickingStatusToOutcome={pickingStatusToOutcome}
                  setPickingStatusToOutcome={setPickingStatusToOutcome}
                  regionToOutcome={regionToOutcome}
                  setRegionToOutcome={setRegionToOutcome}
                  supplyToOutcome={supplyToOutcome}
                  setSupplyToOutcome={setSupplyToOutcome}
                  moduleType={moduleType}
                  months_open={months_open}
                  days_open={days_open}
                />
              </TabPanel>

              <TabPanel value={value} index={2}>
                <TableFinalized
                  searchFinalized={searchFinalized}
                  requestSortFinalized={requestSortFinalized}
                  getClassNamesForFinalized={getClassNamesForFinalized}
                  requestTypeFinalized={requestTypeFinalized}
                  setRequestTypeFinalized={setRequestTypeFinalized}
                  pickingStatusFinalized={pickingStatusFinalized}
                  setPickingStatusFinalized={setPickingStatusFinalized}
                  regionFinalized={regionFinalized}
                  setRegionFinalized={setRegionFinalized}
                  supplyFinalized={supplyFinalized}
                  setSupplyFinalized={setSupplyFinalized}
                  moduleType={moduleType}
                  months_open={months_open}
                  days_open={days_open}
                  actualGroup={actualGroup}
                />
              </TabPanel>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modal_create_covenant && (
        <ModalCreateCovenant
          open={modal_create_covenant}
          closeModal={() => setModal_create_covenant(false)}
        />
      )}
    </GridContainer>
  );
}
