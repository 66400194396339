import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import ModalAdd from "./ModalAdd";

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  table: {
    minWidth: 650
  }
});

const EquipTableComponent = props => {
  const classes = useStyles();
  const {
    dataEquipArray,
    setDataEquipArray,
    medicalResourceData,
    listEquip,
    margin="30px 0 0 0",
    disabled=false,
  } = props;
  const [openModal, setOpenModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    if (loading2) {
      setLoading2(false);
    }
  });

  const handleDeleteRow = (row, index, table) => {
    setLoading2(table);
    let tempDeleteArray = dataEquipArray;
    tempDeleteArray.splice(index, 1);
    setDataEquipArray(tempDeleteArray);
  };

  const handleChangeQuantity = (value, index, table) => {
    setLoading(table);
    const auxDataArray = dataEquipArray;
    auxDataArray[index].cantidad = parseInt(value);
    setDataEquipArray(auxDataArray);
  };

  const handleAddItem = value => {
    let auxArray = dataEquipArray;
    const tempData = medicalResourceData.filter(
      row => row.id === value.value
    )[0];
    const element = {
      item: tempData.descripcion,
      sku: tempData.SKU,
      id: tempData.id,
      cantidad: 1
    };
    auxArray.push(element);
    setDataEquipArray(auxArray);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          item
          xs={12}
          style={{
            margin: margin,
            maxHeight: "300px",
            overflow: "auto"
          }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: "200px" }}>
                    Item
                  </TableCell>
                  <TableCell align="center" style={{ width: "200px" }}>
                    SKU
                  </TableCell>
                  <TableCell align="center" style={{ width: "200px" }}>
                    Cantidad
                  </TableCell>
                  {!disabled && (
                    <TableCell align="center" style={{ width: "200px" }}>
                      Acción
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataEquipArray.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.item}
                    </TableCell>
                    <TableCell align="center">{row.sku}</TableCell>
                    <TableCell align="right">
                      <DetailNumber
                        isdisabled={disabled}
                        value={row.cantidad}
                        onchange={e => {
                          if (/^[0-9\b]+$/.test(e.target.value)) {
                            handleChangeQuantity(e.target.value, index, true);
                          }
                        }}
                      />
                    </TableCell>
                    {!disabled && (
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            background: red[500],
                            textTransform: "none"
                          }}
                          onClick={() => handleDeleteRow(row, index, true)}
                        >
                          Eliminar
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {!disabled && (
                  <TableRow>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setOpenModal(false);
                        }}
                        style={{ textTransform: "none" }}
                        // habilitar equipo
                        disabled
                      >
                        Agregar Equipo
                      </Button>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {!loading && <TableRow></TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
      {openModal !== null && (
        <ModalAdd
          open={openModal !== null}
          table={openModal}
          handleClose={() => setOpenModal(null)}
          listItem={listEquip}
          dataInfo={medicalResourceData}
          dataArray={dataEquipArray}
          setdataArray={setDataEquipArray}
          handleAddItem={handleAddItem}
        />
      )}
    </Card>
  );
};

export default EquipTableComponent;

EquipTableComponent.propTypes = {
  dataEquipArray: PropTypes.array,
  setDataEquipArray: PropTypes.func,
  medicalResourceData: PropTypes.object,
  listEquip: PropTypes.array
};
