import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableProfesionals = props => {
  const classes = useStyles();
  const {
    professionals,
    setProfessionals,
    setProfessionalSelected,
    viewOnly,
    checkProfessionals,
    addProfessionals,
    setAddProfessionals,
    deleteProfessionals,
    setDeleteProfessionals,
    editProfessionals,
    setEditProfessionals,
    compareProfessionals
  } = props;
  const [page, setPage] = React.useState(0);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [filterProfessionals, setFilterProfessionals] = useState([]);

  useEffect(() => {
    setFilterProfessionals([...compareProfessionals]);
  }, []);

  useEffect(() => {
    if (pageRefresh) {
      setPageRefresh(false);
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSelect = (event, index) => {
    let tempProfessionals = professionals;
    tempProfessionals[index].selected = event.target.checked;
    if (tempProfessionals[index].selected === false) {
      let checkProfessionalDelete = checkProfessionals.filter(
        pro => pro.id === tempProfessionals[index].id
      );

      let checkProfessionalAdd = addProfessionals.filter(
        pro => pro.id_cargo === tempProfessionals[index].id
      );

      let checkInEditArray = editProfessionals.filter(
        pro => pro.id === tempProfessionals[index].id_traslado
      );
      if (checkInEditArray.length > 0) {
        let tempEditArray = editProfessionals;
        const getIndex = editProfessionals.findIndex(
          data => data.id === tempProfessionals[index].id_traslado
        );
        tempEditArray.splice(getIndex, 1);
        setEditProfessionals(tempEditArray);
      }

      if (checkProfessionalDelete.length > 0) {
        let tempDelete = deleteProfessionals;
        tempDelete.push(tempProfessionals[index].id_traslado);
        setDeleteProfessionals(tempDelete);
      } else if (checkProfessionalAdd.length > 0) {
        let tempAdd = addProfessionals;
        const getIndex = addProfessionals.findIndex(
          data => data.id_cargo === tempProfessionals[index].id
        );
        tempAdd.splice(getIndex, 1);
        setAddProfessionals(tempAdd);
      }
    } else if (tempProfessionals[index].selected) {
      let checkProfessional = checkProfessionals.filter(
        pro => pro.id === tempProfessionals[index].id
      );

      if (checkProfessional.length > 0) {
        const checkDeleteProfessional = deleteProfessionals.filter(
          pro => pro.id === tempProfessionals[index].id_traslado
        );

        if (checkDeleteProfessional.length > 0) {

          const getIndexCheck = deleteProfessionals.findIndex(
            data => data.id === tempProfessionals[index].id_traslado
          );
          let tempDeleteArrayProfessional = deleteProfessionals;
          tempDeleteArrayProfessional.splice(getIndexCheck, 1);
          setDeleteProfessionals(tempDeleteArrayProfessional);
        }

        const getIndexCheck = checkProfessionals.findIndex(
          data => data.id === tempProfessionals[index].id
        );
        console.log("getIndexCheck", getIndexCheck);
        console.log("cantidad origina", filterProfessionals[getIndexCheck].cantidadAux);
        if (
          filterProfessionals[getIndexCheck].cantidadAux !=
          tempProfessionals[index].cantidad
        ) {
          let tempEditSend = editProfessionals;
          tempEditSend.push({
            id: tempProfessionals[index].id_traslado,
            cantidad: tempProfessionals[index].cantidad
          });
          //setEditProfessionals(tempEditSend);
        }
        let tempDelete = deleteProfessionals.filter(
          pro => pro === tempProfessionals[index].id_traslado
        );

        if (tempDelete.length > 0) {
          let tempDeleteProfessional = deleteProfessionals;
          const getIndex = deleteProfessionals.findIndex(
            data => data === tempProfessionals[index].id_traslado
          );
          tempDeleteProfessional.splice(getIndex, 1);
          setDeleteProfessionals(tempDeleteProfessional);
        }
      } else {
        let tempAdd = addProfessionals;
        tempAdd.push({
          id_cargo: tempProfessionals[index].id,
          cantidad: tempProfessionals[index].cantidad
        });
        setAddProfessionals(tempAdd);
      }
    }
    setProfessionals([...tempProfessionals]);
    setPageRefresh(true);
    let tempCount = tempProfessionals.filter(row => row.selected);
    if (tempCount.length > 0) {
      setProfessionalSelected(true);
    } else {
      setProfessionalSelected(false);
    }
  };

  const handleChangeCantidad = (event, index) => {
    let tempProfessionals = professionals;
    tempProfessionals[index].cantidad = parseInt(event.target.value, 10);
    let checkRegisterProfessional = checkProfessionals.filter(
      pro => pro.id === tempProfessionals[index].id
    );
    if (checkRegisterProfessional.length > 0) {
      let checkInEdit = editProfessionals.filter(
        pro => pro.id === tempProfessionals[index].id_traslado
      );
      if (checkInEdit.length > 0) {
        let tempEdit = editProfessionals;

        const getIndex = editProfessionals.findIndex(
          data => data.id === tempProfessionals[index].id_traslado
        );

        tempEdit[getIndex].cantidad = tempProfessionals[index].cantidad;
        setEditProfessionals(tempEdit);
      } else {
        let tempEdit = editProfessionals;
        tempEdit.push({
          id: tempProfessionals[index].id_traslado,
          cantidad: tempProfessionals[index].cantidad
        });
        setEditProfessionals(tempEdit);
      }
    }
    let checkInAdd = addProfessionals.filter(
      pro => pro.id_cargo === tempProfessionals[index].id
    );
    if (checkInAdd.length > 0) {
      let tempAdd = addProfessionals;
      const getIndex = addProfessionals.findIndex(
        data => data.id_cargo === tempProfessionals[index].id
      );
      tempAdd[getIndex].cantidad = tempProfessionals[index].cantidad;
    }
    setProfessionals(tempProfessionals);
    setPageRefresh(true);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Profesional</TableCell>
              <TableCell align="center">Selección</TableCell>
              <TableCell align="center">Cantidad</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {professionals
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        disabled={viewOnly ? true : false}
                        color="primary"
                        checked={row.selected}
                        onChange={event => handleChangeSelect(event, index)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {row.selected ? (
                        <TextField
                          disabled={viewOnly ? true : false}
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                          min="1"
                          inputProps={{
                            pattern: "[0-9]*",
                            min: 1
                          }}
                          value={row.cantidad}
                          onChange={event => {
                            handleChangeCantidad(event, index);
                          }}
                        />
                      ) : (
                        <TextField
                          disabled
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                          min="1"
                          inputProps={{
                            pattern: "[0-9]*",
                            min: 1
                          }}
                          value={row.cantidad}
                          onChange={event => {
                            handleChangeCantidad(event, index);
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {pageRefresh && <div></div>}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={professionals.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
      />
    </Paper>
  );
};

export default TableProfesionals;

TableProfesionals.propTypes = {
  professionals: PropTypes.array,
  setProfessionals: PropTypes.func,
  setProfessionalSelected: PropTypes.func,
  viewOnly: PropTypes.bool,
  checkProfessionals: PropTypes.array,
  addProfessionals: PropTypes.array,
  setAddProfessionals: PropTypes.func,
  deleteProfessionals: PropTypes.array,
  setDeleteProfessionals: PropTypes.func,
  editProfessionals: PropTypes.array,
  setEditProfessionals: PropTypes.func,
  compareProfessionals: PropTypes.array
};
