import React from 'react'
import usePagination from 'utils/CustomHooks/usePagination';
import moment from "moment-timezone";
import "moment/locale/es";

import { makeStyles } from '@material-ui/core/styles'
import { TypographyMui } from 'utils/componentsStyle';
import { Box, TablePagination } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';

import pill from '../../../../assets/img/icons/stock/medicamentos.svg'
import label from '../../../../assets/img/icons/stock/insumos.svg'
import pc from '../../../../assets/img/icons/stock/equipos.svg'
import folder from '../../../../assets/img/icons/shared/folder.svg'
import amount from '../../../../assets/img/icons/stock/amount.svg'
import copy from '../../../../assets/img/icons/stock/copy.svg'
import qr from '../../../../assets/img/icons/stock/qr.svg'

const useStyles = makeStyles({
  listGrid: {
    display: 'grid',
    gridTemplateColumns: '12% repeat(4, 1fr)',
    gap: '0.5rem'
  },
  '@media (max-width: 800px)': {},
});

const setTeamStyles = (team) => {
  const res = {
    medicamentos: { text: 'Medicamento', icon: pill, color: ' #F44336' },
    insumos: { text: 'Insumos', icon: label, color: '#2196F3' },
    null: { text: 'Equipos', icon: pc, color: ' #9C27B0' },
  }
  return team.id_equipo ? res[team.id_equipo.descripcion] : res[team.id_equipo]
}

const HomeStockDetail = ({ dialogData }) => {
  const classes = useStyles()
  const [pagination, handleChangePage, handleChangeRowsPerPage] = usePagination(5)

  return (
    <>
      <Box display='grid' gridTemplateColumns='10% 1fr' mt={3}>
        <TypographyMui variant='subtitle2' color='primary'>Tipo de solicitud</TypographyMui>
        <TypographyMui variant='subtitle2'>{dialogData.data.requestType}</TypographyMui>
      </Box>

      <Box display='grid' gridTemplateColumns='10% 1fr' mt={3}>
        <TypographyMui variant='subtitle2' color='primary'>Estado</TypographyMui>
        <Box display='flex'>
          <Box width={15} height={15} mr={1} borderRadius="50%" mt={1}
            style={{ backgroundColor: dialogData.data.requestState && dialogData.pharmacyRequestState ? '#A5D6A7' : '#FFE082' }}
          />
          <TypographyMui variant='subtitle2' color='primary'>
            { dialogData.data.requestState && dialogData.pharmacyRequestState ? 'Finalizado' : 'En proceso' }
          </TypographyMui>
        </Box>
      </Box>

      <Box px={1.5}>
        {/* Header */}
        <Box className={classes.listGrid} mt={3}>
          <Box color='primary'>
            <Box display='flex' alignItems='center' height='100%'>
              <Box mr={0.2}>
                <FilterListIcon color='primary' />
                <img src={qr} alt="qr" width='20px' height='30px' className='mr-2' />
              </Box>
              <TypographyMui variant='subtitle2' color='primary'>SKU</TypographyMui>
            </Box>
          </Box>

          <Box color='primary'>
            <Box display='flex' alignItems='center' height='100%'>
              <Box mr={0.2}>
                <img src={folder} alt="folder" width='20px' height='30px' className='mr-2' />
              </Box>
              <TypographyMui variant='subtitle2' color='primary'>Descripción</TypographyMui>
            </Box>
          </Box>

          <Box color='primary'>
            <Box display='flex' alignItems='center' height='100%'>
              <Box mr={0.2}>
                <FilterListIcon color='primary' />
                <img src={copy} alt="copy" width='20px' height='30px' className='mr-2' />
              </Box>
              <TypographyMui variant='subtitle2' color='primary'>Grupo</TypographyMui>
            </Box>
          </Box>

          <Box color='primary'>
            <Box display='flex' alignItems='center' height='100%'>
              <Box mr={0.2}>
                <img src={amount} alt="amount" width='20px' height='30px' className='mr-2' />
              </Box>
              <TypographyMui variant='subtitle2' color='primary'>Cantidad solicitada</TypographyMui>
            </Box>
          </Box>

          <Box color='primary'>
            <Box display='flex' alignItems='center' height='100%'>
              <Box mr={0.2}>
                <img src={amount} alt="amount" width='20px' height='30px' className='mr-2' />
              </Box>
              <TypographyMui variant='subtitle2' color='primary'>Cantidad por enviar</TypographyMui>
            </Box>
          </Box>
        </Box>
        <hr />

        {dialogData.data.stockDetailList && dialogData.data.stockDetailList.map(item => {
          return (
            <>
              <Box className={classes.listGrid}>
                <Box alignSelf='center'>
                  <TypographyMui variant='body2'>
                    {item.id_insumo_medicamento.SKU}
                  </TypographyMui>
                </Box>

                <Box alignSelf='center'>
                  <TypographyMui variant='body2'>
                    {item.id_insumo_medicamento.SKU}
                  </TypographyMui>
                </Box>

                <Box display='flex' alignItemsCenter='center'>
                  <img src={setTeamStyles(item).icon} alt="pill" width='35px' height='35px' className='mr-3' />
                  <TypographyMui variant='body2' style={{color: setTeamStyles(item).color}}>{setTeamStyles(item).text}</TypographyMui>
                </Box>

                <Box alignSelf='center'>
                  <TypographyMui variant='body2'>
                    {item.cantidad}
                  </TypographyMui>
                </Box>

                <Box alignSelf='center'>
                  <TypographyMui variant='body2'>
                    {item.cantidad_por_enviar}
                  </TypographyMui>
                </Box>
              </Box>
              <hr />
            </>
          )
        })}

        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={dialogData.data.stockDetailList.length}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          SelectProps={{ inputProps: { "aria-label": "Filas por página" }, native: false }}
          labelRowsPerPage={"Filas por página"}
          nextIconButtonText={"Siguiente"}
          backIconButtonText={"Atrás"}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `más que ${to}`}`}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </>
  )
}

export default HomeStockDetail

