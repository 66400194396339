import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DialogDespacho(props) {
  const {
    openDialog,
    closeDialog,
    setInternalTransport,
    setOpenModalDespacho
  } = props;

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"¿Por dónde se realizará el despacho?"}
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            setInternalTransport(true);
            setOpenModalDespacho(true);
          }}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Transporte interno
        </Button>
        <Button
          onClick={() => {
            setInternalTransport(false);
            setOpenModalDespacho(true);
          }}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Transporte del proveedor
        </Button>
        <Button
          onClick={closeDialog}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
