import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
    CircularProgress,
    FormControlLabel,
    Radio,
    Checkbox,
    IconButton
} from '@material-ui/core';
import {
    RadioButtonChecked,
    CheckCircle,
    RadioButtonUnchecked,
} from "@material-ui/icons/";
import {
    ArrowBackIos,
    ArrowForwardIos,
} from '@material-ui/icons/';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import {classStyle} from "utils/styleFunction";
import {dictMonth} from "utils/validationFunction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function ModalDefineVisitTelemedicina({
    open,
    handleClose,
    visit,
    worker,
    selectPatient,
    handleSaveReservation,
}) {
    const classes = useStyles();
    const classesStyle = classStyle();
    const [initialCharge, setinitialCharge] = useState(true);
    const [indexWorker, setindexWorker] = useState(0);
    const [loading, setloading] = useState(false);
    const [start, setstart] = useState({});
    const [end, setend] = useState({});
    const [listHour, setlistHour] = useState([]);
    const [listValues, setlistValues] = useState([])
    const [messageWarning, setmessageWarning] = useState("");
    const [changes, setChanges] = useState(true);
    const [openAnimation, setOpenAnimation] = useState(true);
    const [vetoes, setVetoes] = useState([]);
    const [alertVetoe, setAlertVetoe] = useState(false);

    useEffect(() => {
        if (initialCharge){
            initialData();
        }
        if (changes) {
            initialVetoes();
        }

    });

    const initialVetoes = () => {
        setChanges(false);
        const rowWorker = worker[indexWorker];
        if (rowWorker){
            const getVetoes = api.get(
            `api/rrhh/variablesdinamicas/personal/${worker.id_ficha_id}/tipo/vetos/`
            );

            Promise.all([getVetoes])
            .then(response => {
                const checkVetoe = response[0].data.valores.filter(
                filtered =>
                    filtered.info_veto.id_ficha_paciente_id.id_paciente_id.id ===
                    selectPatient.id
                );
                if (checkVetoe.length > 0) {
                setAlertVetoe(true);
                } else {
                setAlertVetoe(false);
                }
                setVetoes(response[0].data.valores);
                setOpenAnimation(false);
            })
            .catch(error => {
                setOpenAnimation(false);
                setAlertVetoe(false);
            });
        } else {
            setOpenAnimation(false);
            setAlertVetoe(true);
        }
    };

    const initialData = () => {
        setinitialCharge(false);
        const tempStart = moment(visit.start).format("HH:mm");
        const tempEnd = moment(visit.end).format("HH:mm");
        setstart({value: tempStart, label: tempStart});
        setend({value: tempEnd, label: tempEnd});
        const tempListValues = [...listValues]
        tempListValues.push({
            extraLunchAmount: "0",
            shiftMobilizationAmount: "0",
            specialViaticAmount: "0",
            extraTurnValue: "0",
            comment: "",
            hourType: "normal",
            extraLunch: false,
            shiftMobilization: false,
            specialViatic: false,
            workerInfo: worker[0],
        });
        setlistValues(tempListValues)
    };

    const handleHourType = event => {
        const tempListValues = [...listValues]
        tempListValues[indexWorker]["hourType"] = event.target.value
        tempListValues[indexWorker]["extraTurnValue"] = 0
        setlistValues(tempListValues)
    };

    const handleAddItems = (valueCheck, amount) => {
        const tempListValues = [...listValues];
        tempListValues[indexWorker][valueCheck] =  !tempListValues[indexWorker][valueCheck];
        tempListValues[indexWorker][amount] = 0;
        setlistValues(tempListValues);
    };

    const handleIndexWorker = (value) => {
        setindexWorker(value);
        if (listValues[value] === undefined){
            const tempListValues = [...listValues]
            tempListValues.push({
                extraLunchAmount: "0",
                shiftMobilizationAmount: "0",
                specialViaticAmount: "0",
                extraTurnValue: "0",
                comment: "",
                hourType: "normal",
                extraLunch: false,
                shiftMobilization: false,
                specialViatic: false,
                workerInfo: worker[value],
            });
            setlistValues(tempListValues);
            setChanges(true)
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesStyle.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >

            <Paper  className={classesStyle.paper}  style={{maxHeight: "95%", overflow: "auto"}}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0"}}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={2}
                        >
                            {indexWorker > 0 ?
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={() => handleIndexWorker(indexWorker-1)}
                            >
                                <ArrowBackIos style={{color: indigo[500]}}/>
                            </IconButton> :
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}

                            >
                                <ArrowBackIos/>
                            </IconButton>
                            }
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={8}
                        >
                            <Typography variant="h5" align={"center"} gutterBottom>{`Visita Telemedicina: Profesional ${indexWorker+1} de ${worker.length} `}</Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={2}
                        >
                        { (indexWorker + 1) < (worker.length) ?
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={() => handleIndexWorker(indexWorker+1)}
                            >
                                <ArrowForwardIos style={{color: indigo[500]}}/>
                            </IconButton> :
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}

                            >
                                <ArrowForwardIos/>
                            </IconButton>
                        }

                        </Grid>
                    </Grid>


                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <Typography variant="body2" gutterBottom>
                            {`${moment(visit.start).format("DD")} de ${dictMonth[parseInt(moment(visit.start).format("MM"))]}`}
                        </Typography>
                        {worker[indexWorker] &&
                        <Typography variant="body2" gutterBottom>
                            {`${worker[indexWorker].first_name} ${worker[indexWorker].last_name}`}
                        </Typography>
                        }
                        { worker[indexWorker] &&
                        <Typography variant="body2" gutterBottom>
                            {`${worker[indexWorker].nombre_cargo}`}
                        </Typography>
                        }
                        <Typography variant="body2" gutterBottom>
                            {`Paciente: ${selectPatient.nombre} ${selectPatient.apellido_paterno} ${selectPatient.apellido_materno}`}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <DetailSelect
                            isDisabled={true}
                            label="Horario inicio:"
                            value={start}
                            list={listHour.slice(0, listHour.length - 1)}
                        />
                        <DetailSelect
                            isDisabled={true}
                            label="Horario fin:"
                            value={end}
                            list={listHour.slice(1, listHour.length)}
                        />
                        {messageWarning != "" &&
                        <SnackbarContent
                            message={messageWarning}
                            color="danger"
                        />
                        }
                        {alertVetoe && (
                            <SnackbarContent
                                message={"Existe un veto por parte de este paciete hacia este profesional."}
                                color="danger"
                            />
                        )}

                    </Grid>
                    {listValues[indexWorker] &&
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={10}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={listValues[indexWorker]["hourType"] === "normal"}
                                    onChange={handleHourType}
                                    value="normal"
                                    name="radio button enabled"
                                    aria-label="normal"
                                    icon={
                                        <RadioButtonUnchecked style={{fill: indigo[500]}} />
                                    }
                                    checkedIcon={
                                        <RadioButtonChecked style={{fill: indigo[500]}}/>
                                    }
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot
                                }}
                                label="Considerar como atención normal"
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={listValues[indexWorker]["hourType"] === "extra"}
                                    onChange={handleHourType}
                                    value="extra"
                                    name="radio button enabled"
                                    aria-label="extra"
                                    icon={
                                        <RadioButtonUnchecked style={{fill: indigo[500]}} />
                                    }
                                    checkedIcon={
                                        <RadioButtonChecked style={{fill: indigo[500]}} />
                                    }

                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot
                                }}
                                label="Considerar como atención extra"
                        />
                        { listValues[indexWorker]["hourType"] === "extra" &&
                            <DetailNumber
                                label={`Valor atención extra`}
                                value={listValues[indexWorker]["extraTurnValue"]}
                                onchange={e => listValues[indexWorker]["extraTurnValue"] = parseInt(e.target.value.split('$').join('').split(',').join(''))}

                                prefix={"$"}
                                thousandSeparator={true}
                            />
                        }
                    </Grid>
                    }
                    {listValues[indexWorker] &&
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <Typography variant="body2" gutterBottom>
                           {`¿Cuál de los siguientes items desea agregar?`}
                        </Typography>

                    </Grid>
                    }
                    {listValues[indexWorker] &&
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={10}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    value={listValues[indexWorker]["extraLunch"]}
                                    onClick={() => handleAddItems("extraLunch", "extraLunchAmount")}
                                    checkedIcon={
                                        <CheckCircle style={{fill: indigo[500]}} />
                                    }
                                    icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label="Colación atención extra"
                        />
                        { listValues[indexWorker]["extraLunch"] &&
                            <DetailNumber
                                label={`Valor colación atención extra`}
                                value={listValues[indexWorker]["extraLunchAmount"]}
                                onchange={e => listValues[indexWorker]["extraLunchAmount"] = parseInt(e.target.value.split('$').join('').split(',').join(''))}
                                prefix={"$"}
                                thousandSeparator={true}
                            />
                        }
                        <FormControlLabel
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    value={listValues[indexWorker]["shiftMobilization"]}
                                    onClick={() => handleAddItems("shiftMobilization", "shiftMobilizationAmount")}
                                    checkedIcon={
                                        <CheckCircle style={{fill: indigo[500]}} />
                                    }
                                    icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label="Movilizacíon atención extra"
                        />
                        { listValues[indexWorker]["shiftMobilization"] &&
                            <DetailNumber
                                label={`Valor movilizacíon atención extra`}
                                value={listValues[indexWorker]["shiftMobilizationAmount"]}
                                onchange={e => listValues[indexWorker]["shiftMobilizationAmount"] = parseInt(e.target.value.split('$').join('').split(',').join(''))}
                                prefix={"$"}
                                thousandSeparator={true}
                            />
                        }
                        <FormControlLabel
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    value={listValues[indexWorker]["specialViatic"]}
                                    onClick={() => handleAddItems("specialViatic", "specialViaticAmount")}
                                    checkedIcon={
                                        <CheckCircle style={{fill: indigo[500]}} />
                                    }
                                    icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label="Viatico especial"
                        />
                        { listValues[indexWorker]["specialViatic"] &&
                            <DetailNumber
                                label={`Valor viatico especial`}
                                value={listValues[indexWorker]["specialViaticAmount"]}
                                onchange={e => listValues[indexWorker]["specialViaticAmount"] = parseInt(e.target.value.split('$').join('').split(',').join(''))}
                                prefix={"$"}
                                thousandSeparator={true}
                            />
                        }
                    </Grid>
                    }
                    {listValues[indexWorker] &&
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={10}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <DetailText
                            label={`Comentario`}
                            placeholder={``}
                            validation={false}
                            value={listValues[indexWorker]["comment"]}
                            onchange={e => listValues[indexWorker]["comment"] = e.target.value}

                        />
                    </Grid>
                    }
                    <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 0 0"}}
                    >
                        {openAnimation ? (
                            <CircularProgress />
                        ) : (
                            <span>
                                {alertVetoe ? (
                                    <span>
                                        <Button
                                            disabled
                                            mr={2}
                                            variant="contained"
                                            className="text-white"
                                            style={{ marginRight: "4px"}}
                                        >
                                            Siguiente
                                        </Button>
                                    </span>
                                ) : (
                                    <span>
                                        <Button
                                            mr={2}
                                            variant="contained"
                                            className="text-white"
                                            style={{backgroundColor: green[500], marginRight: "4px"}}
                                            onClick={() => handleSaveReservation(
                                                listValues,
                                            )}
                                        >
                                            Siguiente
                                        </Button>
                                    </span>
                                )}
                                <Button
                                    mr={2}
                                    variant="contained"
                                    style={{backgroundColor: red[500], marginLeft: "4px"}}
                                    className="text-white"
                                    onClick={handleClose}
                                >
                                    Cerrar
                                </Button>
                            </span>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
}