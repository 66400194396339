import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Typography, Grid } from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

class ComponentToPrint extends React.Component {
  render() {
    const { simpleRow, dataPatient } = this.props;
    return (
      <Grid container style={{ flexGrow: 1, marginTop: "5em" }} spacing={2}>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} container justify="center">
            <Grid item xs={4} container justify="center">
              <TextField
                id="outlined-read-only-input3"
                label="Nombre del paciente"
                value={`${dataPatient.nombre} ${dataPatient.apellido_paterno}`}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4} container justify="center">
              <TextField
                id="outlined-read-only-input2"
                label="Edad del paciente"
                value={`${moment().diff(dataPatient.nacimiento, "years")} años`}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4} container justify="center">
              <TextField
                id="outlined-read-only-input1"
                label="Fecha y hora del registro"
                value={
                  `${moment(simpleRow?.fecha_hora).format("l")} ${moment(
                    simpleRow?.fecha_hora
                  ).format("HH:mm")} ` || " "
                }
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              justify="center"
              style={{ marginTop: "3em" }}
            >
              <FormControl
                fullWidth
                style={{ margin: "1px" }}
                variant="outlined"
              >
                <TextField
                  id="outlined-multiline-static1"
                  label="Información"
                  multiline
                  rows={4}
                  value={simpleRow?.observaciones || " "}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
    );
  }
}

ComponentToPrint.propTypes = {
  simpleRow: PropTypes.object,
  dataPatient: PropTypes.object
};

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const FormatPDFSimple = props => {
  const { openModal, closeModal, simpleRow, dataPatient } = props;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="customized-dialog-title" onClose={closeModal} />
        <DialogContent>
          <ComponentToPrint ref={componentRef} simpleRow={simpleRow} dataPatient={dataPatient} />
          {/* <ComponentToPrint ref={componentRef} {...props} nurseInfo={nurseInfo} /> */}
          {/* <button onClick={handlePrint}>Descargar</button> */}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          <Button variant="contained" color="primary" onClick={handlePrint}>
            Descargar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormatPDFSimple;

FormatPDFSimple.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  simpleRow: PropTypes.object,
  dataPatient: PropTypes.object
};
