import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import {CircularProgress, Typography, Grid} from "@material-ui/core/";
import { useEffect, useState } from "react";
import AlertMessage from "components/Attendance/AlertMessage";
import AlertDialog from "components/Attendance/AlertDialog";
import { setmessageGeneral } from "actions/getGlobalAction";

export default function BlockScreen() {
  const dispatch = useDispatch();
  const [refresh, setrefresh] = useState(false);


  useEffect(() => {
    setInterval(() => {
      setrefresh(true)
    }, 1000);
  }, [])

  useEffect(() => {
      setrefresh(false)
  }, [refresh])


  const listApiCharge = useSelector(state => state.currentList.listApiCharge);
  const generalMessage = useSelector(state => state.currentGlobalVar.generalMessage);
  const typeMessage = useSelector(state => state.currentGlobalVar.typeMessage);
  const titleMessage = useSelector(state => state.currentGlobalVar.titleMessage);
  const actionGlobalMessage = useSelector(state => state.currentGlobalVar.actionGlobalMessage);
  const nameActionGlobalMessage = useSelector(state => state.currentGlobalVar.nameActionGlobalMessage);



  let tempAPICharge = listApiCharge.reduce((obj, accum) => {
    let tempAccum = accum.split("/")
    tempAccum = tempAccum.filter((row, index) => row && index > 1)
    tempAccum = tempAccum.join("/")
    return [...obj, tempAccum]
  }, [])

  tempAPICharge = [...new Set(tempAPICharge)].filter((row) => row);

  return (<>
    <AlertDialog
      openDialog={(generalMessage !== "" && titleMessage !== "" && typeMessage !="")}
      handleClose={() => setmessageGeneral(dispatch, "", "", "")}
      message={generalMessage}
      title={titleMessage}
      severity={typeMessage}
      actionGlobalMessage={actionGlobalMessage}
      nameActionGlobalMessage={nameActionGlobalMessage}
    />
    <AlertMessage />
    <Backdrop className={"backdropStyle"} open={listApiCharge.length > 0}>
      <Grid
        container
          direction="column"
          justify="center"
          alignItems="center"
          item
          xs={12}
        >
          <Grid item>
              <CircularProgress color="inherit" />
          </Grid>
          {tempAPICharge.map((row) => (
            <Grid item>
              <Typography variant="caption" color="inherit">
                {`${row}...`}
              </Typography>
            </Grid>
          ))}
        </Grid>
    </Backdrop>
    </>
  )
}