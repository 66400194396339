import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Select from "react-select";
import {
  Typography,
  Checkbox,
  Grid,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
  red, brown, yellow, blue, green, grey, orange, indigo
} from "@material-ui/core/colors";
import {
    CheckCircle,
    RadioButtonUnchecked,
    RowingTwoTone,
} from "@material-ui/icons/";
import InfoBox from "components/ClinicalRequests/InfoBox";
import ModalContract from "components/ClinicalRequests/ModalContract";
import api from "utils/API";
import ContractParameters from "./ContractParameters";
import ModalEditPatient from "./EditPatientInfo/ModalEditPatient";
import ModalEditTutor from "./EditPatientInfo/ModalEditTutor";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import moment from "moment";
moment.locale("es-es");


const useStyles = makeStyles(styles);

const list_gerente = [
    { value: 'wildo', label: 'Wildo'},
    { value: 'maria', label: 'María'},
    { value: 'chung', label: 'Chung' }
];

const SelectedContract = ({
    selectContracts,
    setselectContracts,
    handleUpdateRowState,
    setselectRowDetails,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [initialCharge, setinitialCharge] = useState(true);
    const [tutorPatient, settutoPatient] = useState(null);
    const [patient, setpatient] = useState(null);
    const [updateView, setupdateView] = useState(false);
    const [contratCMH, setcontratCMH] = useState(false);
    const [contratMHS, setcontratMHS] = useState(false);
    const [contratcomodato, setcontratcomodato] = useState(false);
    const [conditionCMH, setconditionCMH] = useState(false);
    const [isapreInfo, setisapreInfo] = useState({})
    
    const [mandateCMH, setmandateCMH] = useState(false);
    const [mandateMHS, setmandateMHS] = useState(false);
    const [transientCMH, settransientCMH] = useState(false);
    const [transientMHS, settransientMHS] = useState(false);
    const [clinicMHS, setclinicMHS] = useState(false);
    const [promissory, setpromissory] = useState(false);
    const [selectCMH, setselectCMH] = useState({});
    const [selectMHS, setselectMHS] = useState({});
    const [validation, setvalidation] = useState(false);
    const [checkValidation, setcheckValidation] = useState(false);
    const [openWarningModal, setopenWarningModal] = useState(false);
    const [contractView, setcontractView] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalEditTutor, setModalEditTutor] = useState(false);

    const litItemDoc = [
        {other: false, type:"MHS", value:  "ContratoMHS", label: "Contrato MHS", state: contratMHS, set: setcontratMHS},
        {other: false, type:"MHS", value:  "MandatoMHS", label: "Mandato MHS", state: mandateMHS, set: setmandateMHS}, 
        {other: false, type:"MHS", value:  "TransitorioMHS", label: "Transitorio MHS", state: transientMHS, set: settransientMHS}, 
        {other: false, type:"MHS", value:  "ClinicMHS", label: "MANDATO ESPECIAL PARA PAGARÉ N°1", state: clinicMHS, set: setclinicMHS}, 
        {other: false, type:"MHS", value:  "Promissory", label: "PAGARÉ N°1", state: promissory, set: setpromissory}, 
        {other: false, type:"CMH", value:  "ContratoCMH", label: "Contrato CMH", state: contratCMH, set: setcontratCMH},
        {other: false, type:"CMH", value:  "MandatoCMH", label: "Mandato CMH", state: mandateCMH, set: setmandateCMH}, 
        {other: false, type:"CMH", value:  "TransitorioCMH", label: "Transitorio CMH", state: transientCMH, set: settransientCMH}, 
        {other: true, type:"CMH", value:  "Contratocomodato", label: "Contrato COMODATO", state: contratcomodato, set: setcontratcomodato},
        {other: true, type:"CMH", value:  "CondicionesCMH", label: "Condiciones CMH", state: conditionCMH, set: setconditionCMH}, 
    ]


    let listItemMHS = litItemDoc.filter((row) => row.other === false && row.type == "MHS")
    let listItemCMH = litItemDoc.filter((row) => row.other === false && row.type == "CMH")
    if (isapreInfo?.nombre?.includes("Dipreca")){
        listItemMHS = []
        listItemCMH = litItemDoc.filter((row) => row.other === true && row.type == "CMH")
    }  

    useEffect(() => {
        if (initialCharge){
            initialData();
        }

        if (updateView){
            setupdateView(false);
        }

        if (checkValidation){
            validateContract();
        }
    });

    const initialData = () => {
        setinitialCharge(false);
        setpatient(selectContracts.id_ficha.id_paciente);
        if (selectContracts.estado === "PorRealizar"){

            const requestTutorPatient = api.get(`api/paciente/${selectContracts.id_ficha.id_paciente.id}/tutor/`)
            const requestIsapre = api.get(`api/convenioisapre/?id_paciente=${selectContracts.id_ficha.id_paciente.id}`)
            Promise.all([requestTutorPatient,requestIsapre]).then((request) => {
                settutoPatient(request[0].data);
                setisapreInfo(request[1].data[0]);
            });
        } else if ( selectContracts.estado === "GestionarContratos" || selectContracts.estado === "SubirContratos") {
            setcontractView(true);
        }
    };

    const validateContract = () => {
        setcheckValidation(false);
        const arrayValidation = [
            (
                (selectCMH.value !== undefined && (contratCMH || mandateCMH || transientCMH || contratcomodato || conditionCMH)) ||
                (selectMHS.value !== undefined && (contratMHS || mandateMHS || transientMHS || clinicMHS || promissory))
            ),
        ];
        setvalidation(arrayValidation.every((item) => item === true));
    }

  const handleEditPatient = () => {
  };

    const handleEditTutor = () => {

    };

    const handleAddItems = (value, set, type) => {
        set(!value);
        setupdateView(true);
        setcheckValidation(true);
    };

    const handleSelect = (value, set) => {
        set(value);
        setcheckValidation(true);
    };

    const handleCreateContract = () => {
        setopenWarningModal(false);
        const body = {};
        const lista_contratos = [];

        litItemDoc.forEach((row) => {
            if (row.state){
                lista_contratos.push(row.value);
            }
        })

        if (selectCMH.value){
            body["gerente_cmh"] = selectCMH.value;
        }
        if (selectMHS.value){
            body["gerente_mhs"] = selectMHS.value;
        }
        body["lista_contratos"] = lista_contratos;

        api.post(`api/facturacion/paciente/${selectContracts.id_ficha.id_paciente.id}/habilitacion/tarea/${selectContracts.id}/contratos/contratosback/`, body).then((request) => {
            settutoPatient(request.data);

            handleUpdateRowState(selectContracts, "GestionarContratos");
            setcontractView(true);
        });
    };

    return (
        <Card>
            {contractView ?
            <ContractParameters
                selectContracts={selectContracts}
                setselectContracts={setselectContracts}
                contratCMH={contratCMH}
                contratMHS={contratMHS}
                mandateCMH={mandateCMH}
                mandateMHS={mandateMHS}
                transientCMH={transientCMH}
                transientMHS={transientMHS}
                clinicMHS={clinicMHS}
                promissory={promissory}
                contratcomodato={contratcomodato}
                conditionCMH={conditionCMH}
                selectCMH={selectCMH}
                selectMHS={selectMHS}
                litItemDoc={litItemDoc}
                handleUpdateRowState={handleUpdateRowState}
                setselectRowDetails={setselectRowDetails}
            /> :
            <CardBody>
                {updateView &&
                    <div></div>
                }
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                        item xs={12}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item xs={6}
                            style={{padding: "5px"}}
                        >

                            {tutorPatient &&
                            <InfoBox
                                title={"Información del paciente"}
                                name={`${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`}
                                address={`${patient.direccion_calle} ${patient.direccion_casa_depto} ${patient.direccion_block}, ${patient.direccion_comuna}, ${patient.direccion_region}`}
                                rut={patient.rut}
                                diagnosis={"-"}
                                age={`${moment().diff(patient.nacimiento, "years")}`}
                                handleEdit={handleEditPatient}
                                setModalEdit={setModalEdit}
                            />
                            }
                        </Grid>
                        <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item xs={6}
                        style={{padding: "5px"}}
                    >
                        {tutorPatient &&
                        <InfoBox
                            title={"Información del apoderado"}
                            name={`${tutorPatient.nombres} ${tutorPatient.apellido_paterno} ${tutorPatient.apellido_materno}`}
                            address={`-`}
                            rut={tutorPatient.rut}
                            email={tutorPatient.email}
                            handleEdit={handleEditTutor}
                            setModalEdit={setModalEditTutor}
                        />
                        }
                    </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item xs={12}
                    >
                        <Typography style={{textTransform: "none"}} variant="subtitle1" gutterBottom>{`¿Qué tipo de contrato desea realizar?`}</Typography>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item xs={6}
                    >
                        <FormControl component="fieldset" className={classes.formControl}>
                        {listItemMHS.map((row) => (
                            <FormControlLabel
                               control={
                                   <Checkbox
                                       tabIndex={-1}
                                       checked={row.state}
                                       onClick={() => handleAddItems(row.state, row.set, row.value)}
                                       checkedIcon={
                                           <CheckCircle style={{fill: indigo[500]}} />
                                       }
                                       icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                   />
                               }
                               classes={{
                                   label: classes.label,
                                   root: classes.labelRoot
                               }}
                               label={row.label}
                           />

                        ))}
                      
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item xs={6}
                    >
                        <FormControl component="fieldset" className={classes.formControl}>
                        {listItemCMH.map((row) => (
                            <FormControlLabel
                               control={
                                   <Checkbox
                                       tabIndex={-1}
                                       checked={row.state}
                                       onClick={() => handleAddItems(row.state, row.set, row.value)}
                                       checkedIcon={
                                           <CheckCircle style={{fill: indigo[500]}} />
                                       }
                                       icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                   />
                               }
                               classes={{
                                   label: classes.label,
                                   root: classes.labelRoot
                               }}
                               label={row.label}
                           />

                        ))}
                      
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item xs={12}
                    >
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            item xs={6}
                        >
                            <Typography style={{textTransform: "none"}} variant="subtitle1" gutterBottom>{`¿Quién es el representate de MHS?`}</Typography>
                            <div className="col-md-4 mb-3">
                                {(contratMHS || mandateMHS || transientMHS || clinicMHS || promissory)  ?
                                    <Select
                                        value={selectMHS}
                                        onChange={(value) => handleSelect(value, setselectMHS)}
                                        options={list_gerente}
                                        placeholder=" "
                                        styles={{
                                        // Fixes the overlapping problem of the component
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    /> :
                                    <Select
                                        value={{}}
                                        onChange={(value) => handleSelect(value, setselectMHS)}
                                        options={list_gerente}
                                        placeholder=" "
                                        styles={{
                                        // Fixes the overlapping problem of the component
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                        isDisabled
                                    />
                                }
                            </div>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            item xs={6}
                        >
                            <Typography style={{textTransform: "none"}} variant="subtitle1" gutterBottom>{`¿Quién es el representate de CMH?`}</Typography>
                            <div className="col-md-4 mb-3">

                            {(contratCMH || mandateCMH || transientCMH || contratcomodato || conditionCMH) ?
                                    <Select
                                        value={selectCMH}
                                        onChange={(value) => handleSelect(value, setselectCMH)}
                                        options={list_gerente}
                                        placeholder=" "
                                        styles={{
                                        // Fixes the overlapping problem of the component
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    /> :
                                    <Select
                                        value={{}}
                                        onChange={(value) => handleSelect(value, setselectCMH)}
                                        options={list_gerente}
                                        placeholder=" "
                                        styles={{
                                        // Fixes the overlapping problem of the component
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                        isDisabled
                                    />
                                    }

                            </div>
                       </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-end"
                        item xs={12}
                    >
                        {validation ?
                        <Button
                            variant="contained"
                            className="text-white"
                            color="primary"
                            onClick={() => setopenWarningModal(true)}
                        >
                            Aceptar
                        </Button> :
                        <Button
                            disabled
                            variant="contained"
                            className="text-white"
                            color="primary"
                        >
                            Aceptar
                        </Button>
                        }
                    </Grid>
                </Grid>
            </CardBody>
            }
            { openWarningModal &&
                <ModalContract
                    open={openWarningModal}
                    handleClose={() => setopenWarningModal(false)}
                    handleCreateContract={handleCreateContract}
                />
            }
      {
        modalEdit && (
            <ModalEditPatient
                openModal={modalEdit}
                closeModal={() => setModalEdit(false)}
                patient={patient}
                setpatient={setpatient}
            />
        )
      }
      {
          modalEditTutor && (
              <ModalEditTutor
                openModal={modalEditTutor}
                closeModal={() => setModalEditTutor(false)}
                tutorPatient={tutorPatient}
                settutoPatient={settutoPatient}
              />
          )
      }
        </Card>
    );
};

export default SelectedContract;
