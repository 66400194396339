import React from "react";
import Button from "@material-ui/core/Button";

export const checkIndications = (row, openModal, saveRow, index, saveIndex) =>
  row.estado === "Completado" ? (
    <Button
      disabled
      variant="contained"
      color="primary"
      style={{ textTransform: "none" }}
    >
      Agregar {row.descripcion}
    </Button>
  ) : (
    <Button
      variant="contained"
      color="primary"
      style={{ textTransform: "none" }}
      onClick={() => {
        openModal(true);
        saveRow(row);
        saveIndex(index);
      }}
    >
      Agregar {row.descripcion}
    </Button>
  );
