import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableWaitToWarehouseTaskIncome = props => {
  const classes = useStyles();
  const { moduleType, requestIncomeWaiting } = props;

  return (
    <Paper className={classes.root}>
      <MaterialTable
        title="Action Overriding Preview"
        localization={{
          pagination: {
            labelRowsSelect: "Filas",
            labelDisplayedRows: "{count} de {from}-{to}",
            firstTooltip: "Primera página",
            previousTooltip: "Página anterior",
            nextTooltip: "Próxima página",
            lastTooltip: "Última página"
          },
          toolbar: {
            nRowsSelected: "{0} Equípo(s) seleccionados",
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar",
            showColumnsTitle: "Mostrar Columnas"
          },
          body: {
            emptyDataSourceMessage: "No hay datos para mostrar",
            filterRow: {
              filterTooltip: "Filtrar"
            }
          }
        }}
        columns={[
          {
            title: "id",
            field: "id",
            filtering: false,
            sorting: true,
            align: "justify"
          },
          {
            title: "Tipo de solicitud",
            field: "tipo_origen_producto",
            filtering: true,
            sorting: true,
            align: "justify"
          },
          {
            title: "Cantidad",
            field: "cantidad_productos",
            filtering: true,
            sorting: true,
            align: "justify"
          },
          {
            title: "Observacion",
            field: "observacion",
            filtering: true,
            sorting: true,
            align: "justify"
          },
          {
            title: "Fecha de llegada",
            field: "fecha_tentativa_llegada",
            sorting: true,
            filtering: true,
            align: "justify"
          },
          {
            title: "Estado",
            field: "estado_ingreso",
            sorting: true,
            filtering: true,
            align: "justify"
          }
        ]}
        data={requestIncomeWaiting}
        actions={[
          {
            icon: "save",
            tooltip: "Save User",
            onClick: (event, rowData) => alert("You saved " + rowData.name)
          }
        ]}
        options={{
          selection: false,
          showTitle: false,
          filtering: true,
          search: false,
          doubleHorizontalScroll: true,
          maxBodyHeight: "350px",
          actionsColumnIndex: -1
        }}
        components={{
          Action: props => (
            <Link to={`/admin/income/detail/${moduleType}/${props?.data?.id}`}>
              <Button
                style={{ textTransform: "none" }}
                variant="contained"
                color="primary"
              >
                Detalle
              </Button>
            </Link>
          )
        }}
    />
    </Paper>
  );
};

export default TableWaitToWarehouseTaskIncome;

TableWaitToWarehouseTaskIncome.propTypes = {
  requestIncomeWaiting: PropTypes.array,
  moduleType: PropTypes.string
};
