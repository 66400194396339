import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ModalEditedPurchases from "./ModalEditedPurchases";


export default function EditPurchase() {
  const { id } = useParams();
  const [moduleType, setmoduleType] = useState("");
  const [group, setGroup] = useState({});
  const [purchaseRequestType, setPurchaseRequestType] = useState({});
  const [sku, setSKU] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [cantidad, setCantidad] = useState(1);
  const [modalSaved, setModalSaved] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);

  const [listSKU, setListSKU] = useState([]);
  const [loading, setLoading] = useState(true);

  const dictTypeProuct = useSelector(state => state.currentList.DICCIONARIO_TIPO_PRODUCTO);
  const dictTypeRequestBuy = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_COMPRA);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);

    const tempArrayURL = window.location.href.split("/");
    setmoduleType(tempArrayURL[tempArrayURL.length - 2]);

    const getRequestInfo = api.get(`api/solicitudcompra/${id}`);
    Promise.all([getRequestInfo])
      .then(responseFirst => {
        setCantidad(responseFirst[0].data.cantidad);
        setObservaciones(responseFirst[0].data.observaciones);
        setPurchaseRequestType(dictTypeRequestBuy[responseFirst[0].data.tipo_solicitud] || {});

        const getSupplyInfo = api.get(
          `api/insumosmedicamento/${responseFirst[0].data.id_producto_comprado}`
        );

        Promise.all([getSupplyInfo]).then(response => {
            let tempGroup = dictTypeProuct[response[0].data.grupo_producto] || {}
            setGroup(tempGroup);
            setSKU({
              value: response[0].data.id,
              label: `(${response[0].data.SKU}) ${response[0].data.descripcion_producto}`
            });

            const getSku = api.get(`api/insumosmedicamento/${tempGroup.value}`);
            Promise.all([getSku])
              .then(response => {
                let tempSKU = [];
                response[0].data.map(row => {
                  tempSKU.push({
                    value: row.id,
                    label: `(${row.SKU}) ${row.descripcion_producto}`
                  });
                });
                setListSKU(tempSKU);
                setLoading(false);

              })

          })

      })

  };

  const handleSKU = sku => {
    setSKU(sku);
  };

  function updatePurchase() {

    const formData = new FormData();

    formData.append("cantidad", cantidad);
    formData.append("observaciones", observaciones);
    formData.append("estado", "EsperarCompra");
    formData.append("id_producto_comprado", sku.value);
    formData.append("tipo_solicitud", purchaseRequestType.value);

    apiform
      .put(`api/solicitudcompra/${id}`, formData)
      .then(() => {

        setModalSaved(true);
      })

  }

  return (
    <GridContainer>
      <ViewTitle
        title="Editar compra"
        message="En esta sección podrás editar la compra seleccionada."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link
              color="inherit"
              to={`/admin/warehouse/requests/${moduleType}`}
            >
              Fraccionamiento - Compras
            </Link>
            <Typography color="textPrimary">Editar compra</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardBody className="mb-3">
            <Grid container direction="row" justify="center">
              {loading ? (
                <Grid container item direction="row" xs={8}>
                  <div className="col-md-6 mb-3">
                    <div>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={210}
                        height={15}
                        style={{ borderRadius: "4px" }}
                      />
                    </div>
                    <div style={{ marginTop: "1em" }}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={300}
                        height={30}
                        style={{ borderRadius: "4px" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={210}
                        height={15}
                        style={{ borderRadius: "4px" }}
                      />
                    </div>
                    <div style={{ marginTop: "1em" }}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={300}
                        height={30}
                        style={{ borderRadius: "4px" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={210}
                        height={15}
                        style={{ borderRadius: "4px" }}
                      />
                    </div>
                    <div style={{ marginTop: "1em" }}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={300}
                        height={30}
                        style={{ borderRadius: "4px" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={210}
                        height={15}
                        style={{ borderRadius: "4px" }}
                      />
                    </div>
                    <div style={{ marginTop: "1em" }}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={300}
                        height={30}
                        style={{ borderRadius: "4px" }}
                      />
                    </div>
                  </div>
                </Grid>
              ) : (
                <Grid container item direction="row" xs={8}>
                  <div className="col-md-6 mb-3">
                    <label className="text-dark">Grupo:</label>
                    <Select
                      value={group}
                      placeholder="Seleccione"
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-dark">SKU:</label>
                    <Select
                      value={sku}
                      onChange={handleSKU}
                      options={listSKU}
                      placeholder="Seleccione"
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                      noOptionsMessage={() => "Sin registros"}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-dark">Cantidad:</label>
                    <input
                      type="number"
                      min="1"
                      className="form-control bg-light font-weight-lighter"
                      style={{ color: "#000000" }}
                      value={cantidad}
                      onChange={e => {
                        if (/^[0-9.\b]+$/.test(e.target.value)) {
                          setCantidad(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-dark">Tipo solicitud:</label>
                    <Select
                      value={purchaseRequestType}
                      placeholder="Seleccione"
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                      isDisabled={true}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            <Divider className="mb-4 mt-3" />
            {loading ? (
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justify="center"
                spacing={2}
              >
                <Grid item xs={8}>
                  <div>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width={210}
                      height={15}
                      style={{ borderRadius: "4px" }}
                    />
                  </div>
                  <div style={{ marginTop: "1em" }}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      //width={1000}
                      height={80}
                      style={{ borderRadius: "4px" }}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justify="center"
                spacing={2}
              >
                <Grid item xs={8}>
                  <DetailTextArea
                    label="OBSERVACIONES:"
                    placeholder="Observaciones"
                    value={observaciones}
                    maxlength="500"
                    onchange={e => {
                      setObservaciones(e.target.value);
                    }}
                  />
                  {observaciones === "" ? (
                    <span className="text-danger">
                      Debes ingresar una observación
                    </span>
                  ) : null}
                </Grid>
              </Grid>
            )}
            {loading ? (
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justify="center"
                spacing={2}
                style={{ marginTop: "1em" }}
              >
                <Grid item>
                  <div>
                    <Skeleton
                      //animation="wave"
                      variant="rect"
                      width={150}
                      height={40}
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#303f9f"
                      }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div>
                    <Skeleton
                      //animation="wave"
                      variant="rect"
                      width={150}
                      height={40}
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#303f9f"
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justify="center"
                spacing={2}
              >
                <Grid item>
                  {observaciones !== "" && sku ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={updatePurchase}
                      style={{ textTransform: "none" }}
                    >
                      Guardar cambios
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ textTransform: "none" }}
                      disabled
                    >
                      Guardar cambios
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Link to={`/admin/warehouse/requests/${moduleType}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ textTransform: "none" }}
                    >
                      Cancelar Solicitud
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            )}
          </CardBody>
        </Card>
      </GridItem>
      {modalSaved && (
        <ModalEditedPurchases
          open={modalSaved}
          closeModal={() => setModalSaved(false)}
          moduleType={moduleType}
        />
      )}
    </GridContainer>
  );
}
