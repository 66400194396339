import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    Typography,
} from '@material-ui/core';
import PedidosProgramadosViewQuotation from "views/ClinicOrders/OrdersDashboard/ModalPedidosProgramados/PedidosProgramadosViewQuotation";
import {red} from "@material-ui/core/colors";
import api from "utils/API";
import {
    getListSuppliesMedicines,
} from "actions/getListAction";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
}));

export default function ModalEnableAddressWarehouse({
    open,
    handleClose,
    selectEnableAddress,
    handleUpdateRowState,
}) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [patientInfo, setpatientInfo] = useState(selectEnableAddress.id_ficha.id_paciente);

    const [firstCharge, setFirstCharge] = useState(true);
    const [viewQuotation, setViewQuotation] = useState({});
    const medicalResourseTotal = viewQuotation.packagingInfo
        ? viewQuotation.packagingInfo.insumos +
        viewQuotation.packagingInfo.medicamentos
        : 0;
    const [medicalResourseBase, setmedicalResourseBase] = useState(0);
    const [benefitsPackage, setbenefitsPackage] = useState([]);
    const [listEmergency, setlistEmergency] = useState([]);
    const [listEquip, setlistEquip] = useState([]);
    const [emergencyData, setemergencyData] = useState({});
    const [equipData, setequipData] = useState({});
    const [reloadRequest, setreloadRequest] = useState({});
    const [load, setload] = useState(false)

    const dictTypeWork = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA);
    const listMedical = useSelector(state => state.currentList.listMedicines);
    const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
    const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

    useEffect(() => {
        if(listMedical.length === 0 && !chargeMedicine) {
            getListSuppliesMedicines(dispatch, chargeMedicine)
        }
    }, [])

    useEffect(() => {
        if (firstCharge) {
            initialCharge();
        }
    });

    const initialCharge = () => {
        setFirstCharge(false);
        const getViewQuotation = api.get(
            `/api/clinica/pedidosprogramados/paquetepaciente/paciente/${patientInfo.id}`
        );
        const getMedicalResourceBase = api.get(
            `/api/paciente/pedidosprogramados/prestacionespaquete/paciente/${patientInfo.id}`
        );
        const requestCharges = api.get("api/prestacionesclinica/");
        const requestEquip = api.get(`api/equipos/tipos/`);

        Promise.all([
            getViewQuotation,
            getMedicalResourceBase,
            requestCharges,
            requestEquip,
        ])
        .then(response => {
            let tempViewQuotation = {
                forecast: response[0].data.id_convenio_isapre,
                typePatient: response[0].data.tipo_paciente,
                packagingInfo: {
                    id: response[0].data.id,
                    zona: response[0].data.zona,
                    complejidad: response[0].data.complejidad,
                    dia_cama: response[0].data.dia_cama,
                    id_paquete_basado: response[0].data.id_paquete_basado,
                    insumos: response[0].data.insumos,
                    medicamentos: response[0].data.medicamentos,
                    tipo_paciente: response[0].data.tipo_paciente,
                    total_afecto: response[0].data.total_afecto,
                    total_dia: response[0].data.total_dia,
                    total_excento: response[0].data.total_excento,
                    total_mes: response[0].data.total_mes
                }
            };
            setViewQuotation(tempViewQuotation);

            const packageCheck = response[1].data;
            let tempTotal = 0;
            packageCheck.map(row => {
            if (["Insumos", "Articulos", "Alimentos", "Medicamentos"].includes(row.id_insumo_medicamento?.grupo_producto)) {
                tempTotal += row.cantidad * row.frecuencia;
            }
            });
            setmedicalResourseBase(tempTotal);
            setbenefitsPackage([...packageCheck]);

            const auxEmergencyList = [];
            const auxEquipList = [];
            const auxEmergencyData = {};
            const auxEquipData = {};
            const auxWorkersData = {};
            const auxProfessionalList = [];
            const auxCharges = response[2].data;

            auxCharges.map(row => {
            if (
                row.grupo === "Profesionales" &&
                !row.nombre.includes("Técnico en Enfermería") &&
                !row.nombre.includes("TENS")
            ) {
                auxProfessionalList.push({
                value: row.id,
                label: `${row.nombre}`
                });
                auxWorkersData[row.id] = row;
            }
            if (row.grupo === "Emergencias") {
                auxEmergencyList.push({
                value: row.id,
                label: `${row.nombre}`
                });
                auxEmergencyData[row.id] = row;
            }
            });
            response[3].data.map((row) => {
            auxEquipList.push({value: row.id, label: `(${row.SKU}) ${row.descripcion}`});
            auxEquipData[row.id] = row;
            });
            setlistEmergency(auxEmergencyList);
            setlistEquip(auxEquipList)
            setemergencyData(auxEmergencyData);
            setequipData(auxEquipData);
            setreloadRequest(response[1].data);
            setload(true);
        })
    };

    const handleReloadFile = () => {
        firstCharge(true);
    };

    const handleAssing = () => {
        const body = {
            estado: "Finalizado",
        };
        const requestWorks = api.patch(`api/tareaspaciente/${selectEnableAddress.id}`, body);
        const requestWarehouse = api.post(`api/clinica/solicitudes/pacientes/${selectEnableAddress.id_ficha.id_paciente.id}/habilitacion/${selectEnableAddress.id_solicitud_paciente.id}/pedidobodega/`, {});
        Promise.all([
            requestWorks,
            requestWarehouse,
        ]).then((request) => {
            handleUpdateRowState(selectEnableAddress, "Finalizado");
            handleClose();
        });
    };

    const _content_ = <div>
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    item
                    xs={12}
                    style={{padding: "5px"}}
                >
                    <Grid item xs={6}>
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Tipo de solicitud: ${dictTypeWork[selectEnableAddress.tipo_tarea]?.label}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Dirección: ${patientInfo.direccion_calle} ${patientInfo.direccion_numero} ${patientInfo.direccion_casa_depto} ${patientInfo.direccion_block} ${patientInfo.direccion_comuna} ${patientInfo.direccion_region}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Fecha de Habilitación: ${moment(selectEnableAddress.fecha_hora).format("DD-MM-YYYY HH:mm:ss")}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.root} justify="center">
                    {load &&
                    <PedidosProgramadosViewQuotation
                        viewQuotation={viewQuotation}
                        reloadRequest={reloadRequest}
                        medicalResourceData={medicalResourceData}
                        handleReloadFile={handleReloadFile}
                        medicalResourseTotal={medicalResourseTotal}
                        medicalResourseBase={medicalResourseBase}
                        setmedicalResourseBase={setmedicalResourseBase}
                        listEquip={listEquip}
                        listEmergency={listEmergency}
                        equipData={equipData}
                        emergencyData={emergencyData}
                        benefitsPackage={benefitsPackage}
                        tempPatientIdInfo={patientInfo?.id}
                        getPatientInfo={() => {}}
                        closeModal={() => {}}
                    />
                    }
                </Grid>
            </Grid>

        </Grid>
    </div>

    const _action_ = <>
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            color="primary"
            onClick={handleAssing}
        >
            Asignar
        </Button>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500]}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Habilitación de domicilio: Operaciones`}
            _content_={_content_}
            _actions_ = {_action_}
            maxWidth={"xl"}
        />
  );
}