import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Paper,
  Button,
} from "@material-ui/core";
import {
  red, brown, yellow, blue, green, grey, orange, indigo
} from "@material-ui/core/colors";
import api from "utils/API";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import moment from "moment";
moment.locale("es-es", {
	week: {
		dow: 0 //Monday is the first day of the week.
	}
});


const useStyles = makeStyles(styles);
const classStyle = makeStyles((theme) => ({
  text: {
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    '&:hover': {
        color: indigo[400],
    },
  },
}));


const InfoBox = ({
    title,
    name=null,
    rut=null,
    email=null,
    address=null,
    diagnosis=null,
    age=null,
    handleEdit=null,
    setModalEdit
}) => {
    const classes = useStyles();
    const classesStyle = classStyle();

    return (
        <Paper style={{padding: "5px 10px", height: "100%"}}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                item xs={12}
                style={{height: "100%"}}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    item xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item xs={12}
                    >
                        <Typography style={{textTransform: "none"}} variant="subtitle1" gutterBottom>{title}</Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={6}
                    >
                        <Typography variant="body2" gutterBottom>{`Nombre: ${name}`}</Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={6}
                    >
                        <Typography variant="body2" gutterBottom>{`Dirección: ${address}`}</Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={6}
                    >
                        <Typography variant="body2" gutterBottom>{`RUT: ${rut}`}</Typography>
                    </Grid>
                    {diagnosis &&
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={6}
                    >
                        <Typography variant="body2" gutterBottom>{`Diagnostico: ${diagnosis}`}</Typography>
                    </Grid>
                    }
                    {email &&
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={6}
                    >
                        <Typography variant="body2" gutterBottom>{`Mail: ${email}`}</Typography>
                    </Grid>
                    }
                    {age &&
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={6}
                    >
                        <Typography variant="body2" gutterBottom>{`Edad: ${age}`}</Typography>
                    </Grid>
                    }
                    {age &&
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={6}
                    >
                    </Grid>
                    }
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-end"
                    item xs={12}
                >
                    <Button
                        variant="contained"
                        className="text-white"
                        color="primary"
                        onClick={() => setModalEdit(true)}
                    >
                        Editar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default InfoBox;
