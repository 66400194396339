import React, {useState, useEffect} from "react";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

export default function DetailImageAvatar(props) {
  const [file, setFile] = useState(null);
  const [initialCharge, setinitialCharge] = useState(true);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    props.avatar ? defaultAvatar : defaultImage
  );

    let fileInput = React.createRef();

    useEffect(() => {
        if (initialCharge){
            initialData();
        }
    });

    const initialData = () => {
        setinitialCharge(false);
        // let file = props.file;
        // setFile(file);
        if (props.image) {
          setImagePreviewUrl(props.image)
        };
    };

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      props.chargeAvatar(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };

  let { avatar, addButtonProps, changeButtonProps } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {file === null ? (
          <Button {...addButtonProps} onClick={() => handleClick()} style={{ textTransform: "none" }}>
            {avatar ? "Agregar foto" : "Seleccionar imagen"}
          </Button>
        ) : (
          <span>
            <Button
              style={{ textTransform: "none" }}
              {...changeButtonProps}
              onClick={() => handleClick()}
            >
              Cambiar foto
            </Button>
            {avatar ? <br /> : null}
          </span>
        )}
      </div>
    </div>
  );
}