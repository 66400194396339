import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import ModalAddRecord from "./AddRequest/ModalAddRecord";
import Sucess from "./Sucess";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "5em"
  }
});

const SearchRequest = props => {
  const classes = useStyles();
  const {
    search,
    setSearch,
    searchFinalized,
    setSearchFinalized,
    tabValue
  } = props;
  const [openModalAddRequest, setOpenModalAddRequest] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");

  return (
    <Grid container className={classes.root}>
      <Grid item container xs={12} justify="center">
        <Grid item xs={4} />
        <Grid item container xs={4} justify="center">
          <FormControl fullWidth className={classes.margin} variant="outlined">
            {tabValue === 0 ? (
              <TextField
                id="outlined-disabled"
                label="Buscar en proceso"
                variant="outlined"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            ) : (
              <TextField
                id="outlined-disabled"
                label="Buscar finalizadas"
                variant="outlined"
                value={searchFinalized}
                onChange={e => setSearchFinalized(e.target.value)}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item container xs={4} justify="center">
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={() => setOpenModalAddRequest(true)}
          >
            Nueva solicitud administrativa
          </Button>
        </Grid>
      </Grid>
      {openModalAddRequest && (
        <ModalAddRecord
          openModal={openModalAddRequest}
          closeModal={() => setOpenModalAddRequest(false)}
          setOpenDialog={setOpenDialog}
          setMessage={setMessage}
        />
      )}
      {openDialog && (
        <Sucess
          openDialog={openDialog}
          closeDialog={() => setOpenDialog(false)}
          message={message}
        />
      )}
    </Grid>
  );
};

export default SearchRequest;

SearchRequest.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  searchFinalized: PropTypes.string,
  setSearchFinalized: PropTypes.func,
  tabValue: PropTypes.number
};
