import React, { useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
} from "@material-ui/core";
import { red} from "@material-ui/core/colors";
import {
  TypographyMui as Typography,
} from "utils/componentsStyle"
import { Warning, } from "@material-ui/icons/";
import Danger from "components/Typography/Danger.js";
import Info from "components/Typography/Info.js";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import EditInTableButton from "views/Widgets/Common/EditInTableButton";
import {toMoneyFormat} from "utils/formatFunctions";

// eslint-disable-next-line react/prop-types
export default function CardStatistics({ amountTotal, amountBase=null, title, subTitle, subTitleError=null, IconSub, disabledEdit, editable, selectEditable, onClickEdit, onClickCancel, onClickSave }) {

  const [value, setvalue] = useState(null)

  const quotationDiscount = useSelector(state => state.currentGlobalVar.quotationDiscount);
  const typeQuotationDiscount = useSelector(state => state.currentGlobalVar.typeQuotationDiscount);
  const tempValuePercentage = (100 + (typeQuotationDiscount ==="Descuento" ? -1 : 1)*quotationDiscount)/100
  const tempAmountTotal = amountTotal*tempValuePercentage;
  const validation = (tempAmountTotal-amountBase*tempValuePercentage) >= 0

  const handleFunction = (value, min) => {
    if (value > min || value === "") setvalue(value)
  }

  const handleEdit = () => {
    setvalue(amountTotal);
    onClickEdit();
  }

  const handleSave = () => {
    onClickSave(value);
    setvalue(0);
  }

  const handleCancel = () => {
    onClickCancel();
    setvalue(0);
  }

  return (
    <Card style={{width: "100%"}}>
      <CardHeader subheader={title} />
      <CardContent
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-end"
          item
          xs={12}
        >

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            item
            xs={12}
            style={{margin: "0 0 5px 0"}}
          >
            {subTitle &&
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              item
              xs={6}
            >
              <Typography variant="body2" style={{color: "#000000ad"}}>
                Total inicial
              </Typography>
            </Grid>
            }
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              item
              xs={6}
            >
              <Typography variant="body1" style={{color: "#000000de"}}>
                { amountBase === null ?
                  `${toMoneyFormat(amountTotal)}` :
                  `${toMoneyFormat(amountTotal-amountBase)}`
                }
              </Typography>
            </Grid>
          </Grid>
          {(typeQuotationDiscount && quotationDiscount > 0 )&&
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
            item
            xs={12}
            style={{margin: "0 0 5px 0"}}
          >
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              item
              xs={6}
            >
              <Typography variant="body2" style={{color: "#000000ad"}}>
                {`Total con ${typeQuotationDiscount} (${typeQuotationDiscount === "Descuento" ? "-" : "+"}${quotationDiscount}%)`}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              item
              xs={6}
            >
              <Typography variant="body1" style={{color: "#000000de"}}>
              { amountBase === null ?
                `${toMoneyFormat(tempAmountTotal)}` :
                `${toMoneyFormat(tempAmountTotal-amountBase*tempValuePercentage)}`
              }
              </Typography>
            </Grid>
          </Grid>
          }
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          item
          xs={editable ? 8 : 12}
        >
          {selectEditable ?
            <DetailNumber
              value={value}
              onchange={e => {
                if (/^[0-9\b]+$/.test(e.target.value)) {
                  handleFunction(e.target.value, 0);
                }
              }}
              min={0}

            /> :
            <>
            {(validation || amountBase === null) ?

            <Info>
              <IconSub />
            </Info>
            :
            <Danger>
              <Warning />
            </Danger>
            }
            <Typography variant="body1" style={{color: (validation  || amountBase === null) ? "#000000de": red[500]}}>
              {(validation || amountBase === null) ? subTitle : subTitleError}
            </Typography>
            </>
          }
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          item
          xs={4}
        >
          {editable &&
            <EditInTableButton selectEditable={selectEditable} disabledEdit={disabledEdit} onClickEdit={handleEdit} onClickCancel={handleCancel} onClickSave={handleSave} />
          }

        </Grid>
      </CardActions>
    </Card>
  );
}
