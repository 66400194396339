import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff"
  }
}))(LinearProgress);

const RequestsInformation = () => {
  return (
    <div className="col-md-5 mb-3">
      <div className="card">
        <div className="card-header text-center">
          Estado fraccionamiento y retiros
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm text-center">
              <h1>12</h1>
              <div className="col-sm text-center">Fraccionamiento</div>
              <div>
                <BorderLinearProgress variant="determinate" value={50} />
              </div>
            </div>
            <div className="col-sm text-center">
              <h1>6</h1>
              <div className="col-sm text-center">Retiros</div>
              <div>
                <BorderLinearProgress variant="determinate" value={50} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestsInformation;

RequestsInformation.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.bool,
  id_therapy: PropTypes.number,
  id_paquetizacion: PropTypes.number
};
