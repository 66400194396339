import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Button,
  TextField
} from '@material-ui/core';
import api from "utils/API";
import TableComponent from "views/Widgets/Common/TableComponent";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import NumberFormat from "react-number-format";
import {firstChartUpperString, toMoneyFormat} from "utils/formatFunctions"
// import TableSummary from "./TableSummary";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};


const detectType = (value, key) => {
  if (key.toLowerCase().includes("fecha")) {
      return moment(value).format("YYYY-MM-DD")
  } else if (key.toLowerCase().includes("valor")) {
      return toMoneyFormat(value)
  } else if (typeof(value) === "number"){
      return value
  } else if ( typeof(value) === "boolean"){
      return value ? "Verdadero" : "Falseo"
  }
  return firstChartUpperString(value)
}

function CardObject({obj, title}){
  const classes = useStyles();

  const listKey = Object.keys(obj).map((key) => {
      let tempKeyName = key.split("_").join(" ")
      return {"key": key, "label": firstChartUpperString(tempKeyName)}
  })

  return (
  <Grid item container direction="row" justify="center" alignItems="center" xs={12} style={{margin: "10px 0"}}>
      <Card  className={classes.root} >
          <CardHeader title={`${title} - Información`} />
          <CardContent>
          <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={12}>
              {listKey.map((key) => (
                  <Grid item container direction="row" justify="flex-start" alignItems="flex-start"  xs={6}>
                      <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={6}>
                          <Typography component="body2" variant="body2"  style={{fontWeight: 'bold'}}>
                              {`${key["label"]}:`}
                          </Typography>
                      </Grid>
                      <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={6}>
                          <Typography component="body2" variant="body2">
                              {detectType(obj[key["key"]] || "-", key["key"])}
                          </Typography>

                      </Grid>
                  </Grid>
              ))}
          </Grid>
          </CardContent>
      </Card>
  </Grid>
  )
}

const ModalManagerRequestAntibiotic = props => {
  const classes = useStyles();
  const { openModal, closeModal, tempRow } = props;
  const [firstCharge, setFirstCharge] = useState(true);

  const [antibioticData, setantibioticData] = useState({});
  const [supplies, setSupplies] = useState([]);
  const [listProfesional, setlistProfesional] = useState([]);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);
    const getRequestAntibiotic = api.get(
      `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/tratamiento/antibiotico/${tempRow.id_solicitud_paciente.id}/`
    );
    Promise.all([getRequestAntibiotic]).then(response => {
        setantibioticData(response[0].data.tratamiento_antibiotico)
        setlistProfesional(response[0].data.lista_cargos)
        setSupplies(response[0].data.lista_insumos);
      })

  };

  const handleSend = () => {
    const body =  {tareas_paciente: listProfesional}
    api.put(`/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/tratamiento/antibiotico/${tempRow.id_solicitud_paciente.id}/tarea/${tempRow.id}/`, body).then(responseFile => {
      window.location.reload();
    })

  };

  const handleUpdateAmount = (value, index) => {
    const tempList = [...listProfesional];
    tempList[index].valor_atencion = value;
    setlistProfesional(tempList);
  }

  const headersSupplies = [
    {label: "Tipo"},
    {label: "SKU"},
    {label: "Nombre"},
    {label: "Cantidad"},
  ];

  const headersProff = [
    {label: "Nombre"},
    {label: "Tipo"},
    {label: "Fecha/hora"},
    {label: "Valor"},
  ];

  const dataSupplies = supplies.map((row, index) => {
    return [
      {value: row.id_insumo_medicamento__grupo_producto},
      {value: row.id_insumo_medicamento__SKU },
      {value: row.id_insumo_medicamento__descripcion_producto },
      {value: row.cantidad_necesaria},

    ]
  });

  const dataProff = listProfesional.map((row, index) => {
    const _amount_ =  <TextField
      label=""
      value={row.valor_atencion || 0}

      onChange={e => handleUpdateAmount(parseInt(e.target.value), index)}
      name="numberformat"
      id="formatted-numberformat-input"
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
    />
    return [
      {value: row.id_cargo__nombre_corto},
      {value: row.tipo},
      {value: row.tipo == "Repetido" ? moment.utc(row.fecha_hora).format("hh:mm A") : moment.utc(row.fecha_hora).format("YYYY-MM-DD hh:mm")},
      {_value_: _amount_},
    ]
  })


  const _content_ =  <Grid item container xs={12} justify="center">
      <Grid container className={classes.root} justify="center">
        <Grid
          item
          container
          xs={12}
          direction="column"
          justify="center"
          alignContent="center"
          spacing={1}
        >
          <CardObject obj={antibioticData} title={"TTO"}/>
        </Grid>
      </Grid>
      <Grid item container xs={12} justify="center">
        <TableComponent
            headers={headersSupplies}
            data={dataSupplies}
        />
      </Grid>
      <Grid item container xs={12} justify="center">
        <TableComponent
            headers={headersProff}
            data={dataProff}
        />
      </Grid>
    </Grid>

  const _actions_ = <>
      {listProfesional.length == 0 || listProfesional.every((row) => row.valor_atencion > 0)?

      <Button
        variant="contained"
        style={{
          backgroundColor: "#43a047",
          textTransform: "none",
          color: "#fff"
        }}
        onClick={() => handleSend()}
      >
        Aceptar solicitud
      </Button> :
      <Button
        variant="contained"
        disabled
      >
        Aceptar solicitud
      </Button>
      }
      <Button
        variant="contained"
        color="primary"
        style={{
          textTransform: "none"
        }}
        onClick={closeModal}
      >
        Atrás
      </Button>
  </>

  return (
    <ModalDialog
        open={openModal}
        onClose={closeModal}
        title={`Información Tratamiento Antibiótico`}
        _content_={_content_}
        _actions_ = {_actions_}
        maxWidth="xl"
    />
  );
};

export default ModalManagerRequestAntibiotic;

ModalManagerRequestAntibiotic.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRow: PropTypes.object
};
