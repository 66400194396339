import api from "utils/API";

export const getGlobalVarRemunerations = (date, dispatch) => {
    api.get(`api/remuneracion/variablesgeneralesplataforma/fecha/${date}`).then((response) => {
        dispatch({
            type: "UTM_VALOR",
            payload: response.data["UTM"]
        });
        dispatch({
            type: "UF_VALOR",
            payload: response.data["UF"]
        });
        dispatch({
            type: "UF_SEGURO_VALOR",
            payload: response.data["UF_seguro_complementario"]
        });
        dispatch({
            type: "TUF_VALOR",
            payload: response.data["TUF"]
        });
        dispatch({
            type: "TUF_SEGURO_VALOR",
            payload: response.data["TUF_seguro_cesantia"]
        });
        dispatch({
            type: "SALARIO_MINIMO_SEGURO_VALOR",
            payload: response.data["sueldo_minimo"]
        });
        dispatch({
            type: "IMPUESTO_SEGUNDA_CATEGORIA",
            payload: response.data["impuesto_segunda_categoria"]
        });
    }).catch((error) => {
        dispatch({
            type: "UTM_VALOR",
            payload: 0
        });
        dispatch({
            type: "UF_VALOR",
            payload: 0
        });
        dispatch({
            type: "UF_SEGURO_VALOR",
            payload: 0
        });
        dispatch({
            type: "TUF_VALOR",
            payload: 0
        });
        dispatch({
            type: "TUF_SEGURO_VALOR",
            payload: 0
        });
        dispatch({
            type: "SALARIO_MINIMO_SEGURO_VALOR",
            payload: 0
        });
        dispatch({
            type: "IMPUESTO_SEGUNDA_CATEGORIA",
            payload: 0
        });
    });
}

export const getAsistencia = (dispatch) => {
  const usuario_id = localStorage.getItem("user_id");
  const usuario_tipo = localStorage.getItem("tipo_usuario");
  const usuario_cargo_id = localStorage.getItem("id_cargo");
  api.post("api/registroasistencia/pacientes/salida", {
      user_id: usuario_id,
      cargo_id: usuario_cargo_id,
      user_type: usuario_tipo
    }).then(response => {
      var lista_pacientes = response.data.map(item => ({
        value: item.id,
        direccion: item.direccion,
        label: item.nombre_completo,
        asistencia: item.asistencia
      }));
      if (lista_pacientes.length > 0) {
        if (
          lista_pacientes[0]["asistencia"] === "Login" ||
          lista_pacientes[0]["asistencia"] === "login"
        ) {
          dispatch({
            type: "TITULO_DIALOGO_DASHBOARD",
            payload: "Registrar Salida"
          });
          dispatch({
            type: "ASISTENCIA_TIPO",
            payload: "login"
          });
          dispatch({
            type: "ASISTENCIA_URL",
            payload: "/attendance/logout"
          });
          dispatch({
            type: "TITULO_DIALOGO_NAVBAR",
            payload: "Registrar Salida"
          });
          dispatch({
            type: "MENSAJE_DIALOGO_NAVBAR",
            payload:
              "¡No has registrado tu salida! ¿Deseas hacerlo al salir de la plataforma?"
          });
          dispatch({
            type: "MENSAJE_DIALOGO_DASHBOARD",
            payload: "Al registrar tu salida también cerrarás tú sesión"
          });
          dispatch({
            type: "ABRIR_DIALOGO_DASHBOARD",
            payload: false
          });
          dispatch({
            type: "ABRIR_DIALOGO_NAVBAR",
            payload: false
          });

          dispatch({
            type: "DESHABILITAR_ASISTENCIA_ENTRADA",
            payload: true
          });
          dispatch({
            type: "DESHABILITAR_ASISTENCIA_SALIDA",
            payload: false
          });
        } else {
          dispatch({
            type: "ASISTENCIA_TIPO",
            payload: "logout"
          });
          dispatch({
            type: "ASISTENCIA_URL",
            payload: "/attendance/login"
          });
          dispatch({
            type: "ABRIR_DIALOGO_BIENVENIDA_DASHBOARD",
            payload: true
          });
          dispatch({
            type: "TITULO_DIALOGO_DASHBOARD",
            payload: "Registrar entrada"
          });
          dispatch({
            type: "TITULO_DIALOGO_NAVBAR",
            payload: "Salir"
          });
          dispatch({
            type: "MENSAJE_DIALOGO_NAVBAR",
            payload:
              "¡Al ingresar a la plataforma no registraste tu entrada! Esto quiere decir que al salir no quedará registro del trabajo que desarrollaste en ella. ¿Estás seguro que deseas salir?"
          });
          dispatch({
            type: "DESHABILITAR_ASISTENCIA_ENTRADA",
            payload: false
          });
          dispatch({
            type: "DESHABILITAR_ASISTENCIA_SALIDA",
            payload: true
          });
          dispatch({
            type: "MENSAJE_DIALOGO_DASHBOARD",
            payload: "¡No has registrado asistencia!, ¿Desea marcar tú entrada?"
          });
        }
      } else {
        dispatch({
          type: "ASISTENCIA_TIPO",
          payload: "logout"
        });
        dispatch({
          type: "ASISTENCIA_URL",
          payload: "/attendance/login"
        });
        dispatch({
          type: "ABRIR_DIALOGO_BIENVENIDA_DASHBOARD",
          payload: true
        });
        dispatch({
          type: "TITULO_DIALOGO_DASHBOARD",
          payload: "Registrar entrada"
        });
        dispatch({
          type: "TITULO_DIALOGO_NAVBAR",
          payload: "Salir"
        });
        dispatch({
          type: "MENSAJE_DIALOGO_DASHBOARD",
          payload: "¡No has registrado asistencia!, ¿Desea marcar tú entrada?"
        });
        dispatch({
          type: "MENSAJE_DIALOGO_NAVBAR",
          payload:
            "¡Al ingresar a la plataforma no registraste tu entrada! Esto quiere decir que al salir no quedará registro del trabajo que desarrollaste en ella. ¿Estás seguro que deseas salir?"
        });
        dispatch({
          type: "DESHABILITAR_ASISTENCIA_ENTRADA",
          payload: false
        });
        dispatch({
          type: "DESHABILITAR_ASISTENCIA_SALIDA",
          payload: true
        });
      }
    })
    .catch(error => {});
};

export const setmessageGeneral = (dispatch, listObj, type, title, action=null, nameAction="") => {
  dispatch({
    type: "MENSAJE_GENERAL",
    payload: listObj
  });
  dispatch({
    type: "MENSAJE_TITULO",
    payload: title
  });
  dispatch({
    type: "TIPO_MENSAJE",
    payload: type
  });
  dispatch({
    type: "BOTON_ACCION_MENSAJE",
    payload: action
  });
  dispatch({
    type: "NOMBRE_BOTON_ACCION_MENSAJE",
    payload: nameAction
  });

};


export const setmessageAlert= (dispatch, listObj, type, position={}) => {
  dispatch({
    type: "POSICION_ALERTA",
    payload: position
  });
  dispatch({
    type: "MENSAJE_ALERTA",
    payload: listObj
  });
  dispatch({
    type: "TIPO_ALERTA",
    payload: type
  });
};

export const setdictEquipRelSpecific = (dispatch) => {
  api.get(`/api/equipos/obtener/kit/`).then((request) => {
    dispatch({
      type: "DICCIONARIO_KIT_ESPECIFICO",
      payload: request.data.dict_opciones_kit_especifico
    });
    dispatch({
      type: "DICCIONARIO_KIT_GENERICO",
      payload: request.data.diccionario_kit_equipo_generico
    });
    dispatch({
      type: "DICCIONARIO_EQUIPOS_GENERICOS_ESPECIFICOS",
      payload: request.data.diccionario_opciones_equipos_genericos
    });
    dispatch({
      type: "DICCIONARIO_EQUIPOS_GENERICOS",
      payload: request.data.diccionario_equipos_generico
    });
  })
};

export const setDiscountQuotation = (discount, type,dispatch) => {
  dispatch({
    type: "DESCUENTO_COTIZACION",
    payload: discount
  });
  dispatch({
    type: "TIPO_DESCUENTO_COTIZACION",
    payload: type
  });
}
