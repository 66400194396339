import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import api from "utils/API";
import TabBossShoping from "./TabBossShopping";
import CardBody from "components/Card/CardBody.js";
import { Breadcrumbs, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    width: "100%",
  },
}));

const Tasks = () => {
  const classes = useStyles();
  const [firstCharge, setFirstCharge] = useState(true);
  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);
    const getPurchases = api.get("api/compras/solicitudcotizacion/activas/");

    Promise.all([getPurchases])
      .then((response) => {
        setPurchases(
          response[0].data.filter(
            (row) =>
              row.estado === "JefeSeleccionaCotizacion" ||
              row.estado === "RevisarOC"
          )
        );
      })

  };

  return (
    <Grid container className={classes.root} justify="center">
      <Grid item container xs={12} style={{ marginBottom: "1em" }}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" style={{ cursor: "pointer" }}>
              Mis tareas
            </Link>
          </Breadcrumbs>
        </CardBody>
      </Grid>
      <Paper className={classes.paper}>
        <Grid item container xs={12} justify="center">
          <Grid item container xs={1} />
          <Grid
            item
            container
            xs={10}
            justify="center"
            style={{ marginTop: "5em" }}
          >
            <TabBossShoping purchases={purchases} />
          </Grid>
          <Grid item container xs={1} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Tasks;
