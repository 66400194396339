import React from "react";

import {
  DialogContentText,
} from "@material-ui/core";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import {ButtonTextMUI as Button} from "utils/componentsStyle";


export default class WelcomeDialogAttendance extends React.Component {

  render() {
    const title = this.props.title || "¿Desea marcar asistencia?";

    const _content_ =  <DialogContentText id="alert-dialog-slide-description">
      {this.props.message}
    </DialogContentText>

    const _actions_ = <>
      <Button
        style={{
          textTransform: "none",
          backgroundColor: "#3F51B5",
          color: "white"
        }}
        variant="contained"
        onClick={this.props.handleCloseDialog}
        color="primary"
      >
        Cancelar
      </Button>
      <Button
        style={{
          textTransform: "none",
          backgroundColor: "#4CAF50",
          color: "white"
        }}
        variant="contained"
        onClick={this.props.handleCloseDialog}
        href={this.props.url}
      >
        Sí, Registrar Entrada
      </Button>
    </>

    return (
      <ModalDialog
        open={this.props.openDialog}
        onClose={this.props.handleCloseDialog}
        title={title}
        _content_={_content_}
        _actions_ = {_actions_}
        maxWidth={"xs"}
      />
    );
  }
}
