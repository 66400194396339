import React from "react";
import {
  Grid,
  Typography,
  CardContent,
  Card,
} from "@material-ui/core/";
import {useSelector, useDispatch} from 'react-redux';


export default function CardDetailInfo(props) {
  const globalOpenBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);
  return (
    <Card style={props.styleCard || {}}>
      <CardContent>
        {props.title &&
          <Typography component="h5" variant="h5" className={props.classTitle || props.subTitle ? "" : "cardTitle"}>
            {props.title}
          </Typography>
        }
        {props.subTitle &&
        <Grid container direction="column" justify="flex-start" alignContent="flex-start" item xs={12}>
          <Typography component="h5" variant="h5">
            {props.title}
          </Typography>
        </Grid>
        }
        {(!globalOpenBackdrop && props.dataList) &&
        <Grid container>
        {props.dataList && props.dataList.map(data => (
          data._value_ ? data._value_ :
          <Grid container direction="row" justify="flex-start" alignContent="flex-start" item xs={12}>
            <Grid container direction="row" justify="flex-start" alignContent="flex-start" item xs={6}>
              <Typography>
                <span className="font-weight-bold">{`${data.label}:`}</span>
              </Typography>
            </Grid>
            <Grid container direction="row" justify="flex-start" alignContent="flex-start" item xs={6}>
              <Typography>
                {data.value}
              </Typography>
            </Grid>
          </Grid>
        ))}
        </Grid>
      }
      {props.contentCard &&
      <Grid container>
        {props.contentCard}
      </Grid>
      }
      </CardContent>
    </Card>
  );
}
