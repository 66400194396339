import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import PropTypes from "prop-types";
import api from "utils/API";
import EquipTableComponent from "./EquipTableComponent";
import DialogDeleteRequest from "./DialogDeleteRequest";
import ModalRequestEquip from "./ModalRequestEquip";
import moment from "moment-timezone";
import "moment/locale/es";
import {
  getListEquipment,
} from 'actions/getListAction';
import UpdateStockButton from "views/Widgets/UpdateStockButton";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "5em",
  },
}));

const Equipos = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    dataPatient,
    dataFile,
  } = props;

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [dataEquipArray, setDataEquipArray] = useState([]);
  const [pageRefresh, setPageRefresh] = useState(false);

  const [rentEquipList, setRentEquipList] = useState([]);
  const [listStoreEquip, setlistStoreEquip] = useState([]);
  const [openModalTable, setopenModalTable] = useState(false);
  const [changes, setChanges] = useState(true);
  const [updateView, setUpdateView] = useState(false);

  const listEquip = useSelector(state => state.currentList.listEquip);
  const dictEquip = useSelector(state => state.currentList.dictEquip);
  const chargeEquip = useSelector(state => state.currentGlobalVar.chargeEquip);

  
  useEffect(() => {
    if (changes) {
      initialCharge();
    }
    if (updateView) {
      setUpdateView(false)
    }
    if (pageRefresh) {
      setPageRefresh(false);
    }
  });

  useEffect(() => {
    const auxListStoreEquip = listEquip.map((row) => {
      const auxitem = dictEquip[row.value]
      return {
        id_equipo: auxitem.id,
        equipo: auxitem.descripcion,
        sku: auxitem.SKU,
        marca: null,
        modelo_equipo: null,
        numero_serie: null,
        selected: false,
      }
    })
    setlistStoreEquip(auxListStoreEquip)
    setUpdateView(true)

  }, [listEquip]);

  const initialCharge = () => {
    setChanges(false);
    api.get(`api/paciente/equipos/arrendados/${dataFile.id}`).then((request) => {
      setRentEquipList(request.data.data)
     
    });
  };

  const updateStock = () => {
    api.get("/api/cargarequipos/").then(() => {
      getListEquipment(dispatch, chargeEquip);
    })
  };

  return (
    <Grid container className={classes.root} justify="center" spacing={5}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setopenModalTable(true);
          }}
          style={{ textTransform: "none" }}
        >
          Solicitud Equipo
        </Button>
      </Grid>
      {pageRefresh && <div></div>}
      <Grid item xs={12}>
        {!updateView &&
          <EquipTableComponent
            dataEquipArray={dataEquipArray}
            dataPatient={dataPatient}
            setDataEquipArray={setDataEquipArray}
            listEquip={listEquip}
            setPageRefresh={setPageRefresh}
            rentEquipList={rentEquipList}
          />
        }
      </Grid>
    

      { openModalTable &&
        <ModalRequestEquip
          open={openModalTable}
          closeModal={() => setopenModalTable(false)}
          listEquip={listEquip}
          dictEquip={dictEquip}
          dataPatient={dataPatient}
        />
      }

      {openDialogDelete && (
        <DialogDeleteRequest
          openDialog={openDialogDelete}
          closeDialog={() => setOpenDialogDelete(false)}
        />
      )}
      {<UpdateStockButton
        updateStock={updateStock}
      />}
    </Grid>
  );
};

export default Equipos;

Equipos.propTypes = {
  settypeRequest: PropTypes.func,
  viewQuotation: PropTypes.object,
  dataPatient: PropTypes.object,
  setdataRequest: PropTypes.func,
};
