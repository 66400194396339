import React,  {createRef, useState, useEffect } from "react";

import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet"
import 'leaflet/dist/leaflet.css'

const iconURL = `${window.location.origin}/pin.svg`

const IconLocation = L.icon({
  iconUrl: iconURL,
  iconRetinaUrl: iconURL,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35,35],
  className: "leaflet-venue-icon",
})

export default function  MapComponent ({markCoord, handleClick})  {

    const [localCoord, setlocalCoord] = useState({});
    
    useEffect(() => {
        if ("geolocation" in navigator) {
            // El navegador admite geolocalización
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const latitud = position.coords.latitude;
                const longitud = position.coords.longitude;
                setlocalCoord({
                  lat: latitud,
                  lng: longitud,
                })
               
           
                // Puedes utilizar estas coordenadas en tu aplicación
              },
              (error) => {
                alert(`Error al obtener la ubicación: ${error.message}`);
              }
            );
          } else {
            alert("La geolocalización no está disponible en este navegador.");
          }
    }, []);

  
  const handleMarkerClick = (e) => {
    const tempCoord = {...e.latlng}
    handleClick(tempCoord)
  };

  return (
    <>
    {(localCoord?.lat) &&
    <Map center={localCoord} zoom={13} onClick={handleMarkerClick}>
        <TileLayer 
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        
        />
        {(markCoord?.lat && markCoord?.lng && markCoord?.lat !== "-" && markCoord?.lng !== "-") &&
          <Marker position={markCoord} icon={IconLocation} />
        }
        
    </Map>
    }
     
    </>
   
  );
};