import React, { useState, useEffect } from "react";
import {
    TextField,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    Grid,
    FormControlLabel ,
} from "@material-ui/core";
import { CustomRadio, TypographyMui, ButtonTextMUI as Button } from "utils/componentsStyle";
import {
    Close,
    CheckCircle,
    Cancel,
} from '@material-ui/icons/';
import { green, indigo } from "@material-ui/core/colors";
import {classStyle} from "utils/styleFunction";
import {toMoneyFormat} from "utils/formatFunctions";
import moment from 'moment-timezone';
import 'moment/locale/es' ;
moment.locale("es");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function ModalAddMedicine({
    open,
    handleClose,
    row,
    handleSaveDiscount,
    style={}
}) {
    const classes = classStyle();

    const [typeDiscount, settypeDiscount] = useState("Descuento");
    const [valuePercentage, setvaluePercentage] = useState(0);
    const [flag, setflag] = useState(false);

    useEffect(() => {
        if (flag){
            setflag(false);
        }
    }, [])

    const calculateIndividualDiscount = () => {
        if (!row.valor){
          return 0;
        }
        const total = row.valor*row.cantidad;
        const totalDiscount = typeDiscount === "Descuento" ? valuePercentage*-1 : valuePercentage;
        return parseInt(valuePercentage === 0 ? total : total + (total*(totalDiscount/100)));
    };

    return (
        <div>
            <Dialog
                fullScreen={false}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={"xs"}
                style={{...style}}
            >
                <DialogTitle className={classes.dialogHeader} style={{padding: "0px 24px"}}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        <TypographyMui className={classes.colorWhite} variant='body2'>{"AGREGAR DESCUENTO O RECARGO"}</TypographyMui>
                        <IconButton type='reset' onClick={handleClose}>
                            <Close className={classes.colorWhite} />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent} style={{padding: "24px"}}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    item
                    xs={12}
                    style={{padding: "28px 0 "}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item xs={12}
                    >
                        <FormControlLabel
                            control={
                                <CustomRadio
                                    value="Descuento"
                                    checked={typeDiscount === "Descuento"}
                                    onChange={(event) => settypeDiscount(event.target.value)}
                                />
                            }
                            label="Descuento" style={{textTransform: "none", color: "#000000"}}
                        />
                        <FormControlLabel
                            control={
                                <CustomRadio
                                    value="Recargo"
                                    checked={typeDiscount === "Recargo"}
                                    onChange={(event) => settypeDiscount(event.target.value)}

                                />
                            }
                            label="Recargo" style={{textTransform: "none", color: "#000000"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={12}
                            style={{margin: "25px 0 11px 0 "}}
                        >
                            <TypographyMui
                                style={{color: indigo[500]}}
                                variant='body1'
                            >
                                {"Porcentaje"}
                            </TypographyMui>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={5}
                        >
                            <TextField
                                size="small"
                                type="number"
                                variant="outlined"
                                value={valuePercentage}
                                onChange={(event) => setvaluePercentage(event.target.value)}
                                style={{width: "100%"}}
                            />
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={12}
                            style={{margin: "28px 0 5px 0 "}}
                        >
                            <TypographyMui
                                style={{color: indigo[500]}}
                                variant='body1'
                            >
                                {"Total original"}
                            </TypographyMui>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={12}
                        >
                            <TypographyMui
                                variant='body1'
                            >
                                {toMoneyFormat(row.valor*row.cantidad)}
                            </TypographyMui>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={12}
                            style={{margin: "16px 0 5px 0 "}}
                        >
                            <TypographyMui
                                style={{color: indigo[500]}}
                                variant='body1'
                            >
                                {`Total con ${typeDiscount === "Descuento" ? "descuento" : "recargo"}`}
                            </TypographyMui>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={12}
                        >
                            {flag && <div></div>}
                            <TypographyMui
                                variant='body1'
                            >
                                {toMoneyFormat(calculateIndividualDiscount())}
                            </TypographyMui>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "18px 0 0 0"}}
                    >
                        <Button
                            variant="outlined"
                            style={{
                                color: indigo[500],
                                borderColor: indigo[500],
                                margin: "0 16px 0 0"
                            }}
                            onClick={handleClose}
                            startIcon={<Cancel style={{color: indigo[500]}}/>}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="outlined"
                            style={{
                                borderColor: green[500],
                                color: green[500],
                            }}
                            onClick={() => handleSaveDiscount(
                                typeDiscount,
                                valuePercentage
                            )}
                            startIcon={<CheckCircle style={{color: green[500]}}/>}

                        >
                            Aplicar
                        </Button>
                    </Grid>
                </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

