import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import {
  Typography,
  Grid,
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";

import FormContrat from "components/ClinicalRequests/FormContrat";
import ModalContractUpload from "components/ClinicalRequests/ModalContractUpload";
import api from "utils/API";
import moment from "moment";
moment.locale("es-es");

const ContractParameters = ({
    selectContracts,
    setselectContracts,
    contratCMH,
    contratMHS,
    mandateCMH,
    mandateMHS,
    transientCMH,
    transientMHS,
    clinicMHS,
    promissory,
    contratcomodato, 
    conditionCMH,
    selectCMH,
    selectMHS,
    handleUpdateRowState,
    setselectRowDetails,
}) => {
  const dispatch = useDispatch();
  const [initialCharge, setinitialCharge] = useState(true);
  const [updateView, setupdateView] = useState(false);
  const [validation, setvalidation] = useState(false);
  const [checkValidation, setcheckValidation] = useState(false);
  const [bodycontratCMH, setbodycontratCMH] = useState(null);
  const [bodycontratMHS, setbodycontratMHS] = useState(null);
  const [bodymandateCMH, setbodymandateCMH] = useState(null);
  const [bodymandateMHS, setbodymandateMHS] = useState(null);
  const [bodytransientCMH, settransientCMH] = useState(null);
  const [bodytransientMHS, settransientMHS] = useState(null);
  const [bodyclinicMHS, setclinicMHS] = useState(null);
  const [bodypromissoryMHS, setpromissory] = useState(null);
  const [bodycontratcomodato, setcontratcomodato] = useState(null);
  const [bodyconditionCMH, setconditionCMH] = useState(null);
  const [arrayTabs, setArrayTabs] = useState([])
  const [openModal, setopenModal] = useState(false);
  const [contractsValidation, setContractsValidation] = useState([]);

  const litItemDoc = [
    {select: selectMHS, message: "Contrato MHS: Contrato de prestación de servicios de hospitalización domiciliaria.",
       bodySet: setbodycontratMHS, bodyValue: bodycontratMHS, key: "estado_contrato_mhs", other: false, type:"MHS", value:  "ContratoMHS", label: "Contrato MHS", state: contratMHS},
    {select: selectMHS, message: "Mandato MHS", 
      bodySet: setbodymandateMHS, bodyValue: bodymandateMHS, key: "estado_mandato_mhs", other: false, type:"MHS", value:  "MandatoMHS", label: "Mandato MHS", state: mandateMHS}, 
    {select: selectMHS, message: "Transitorio MHS: Contrato de prestación de servicios de hospitalización domiciliaria pacientes transitorios.", 
      bodySet: settransientMHS, bodyValue: bodytransientMHS, key: "estado_transitorio_mhs", other: false, type:"MHS", value:  "TransitorioMHS", label: "Transitorio MHS", state: transientMHS}, 
    {select: selectMHS, message: "MANDATO ESPECIAL PARA PAGARÉ N°1: Mandato especial para pagaré nº1.", 
      bodySet: setclinicMHS, bodyValue: bodyclinicMHS, key: "estado_clinica_mhs", other: false, type:"MHS", value:  "ClinicMHS", label: "MANDATO ESPECIAL PARA PAGARÉ N°1", state: clinicMHS}, 
    {select: selectMHS, message: "PAGARÉ N°1", 
      bodySet: setpromissory, bodyValue: bodypromissoryMHS, key: "estado_pagare_mhs", other: false, type:"MHS", value:  "Promissory", label: " PAGARÉ N°1", state: promissory}, 
    {select: selectCMH, message: "Contrato CMH: Contrato de arriendo y/o venta de insumos y equipos médicos.", 
      bodySet: setbodycontratCMH, bodyValue: bodycontratCMH, key: "estado_contrato_cmh", other: false, type:"CMH", value:  "ContratoCMH", label: "Contrato CMH", state: contratCMH},
    {select: selectCMH, message: "Mandato CMH", 
      bodySet: setbodymandateCMH, bodyValue: bodymandateCMH, key: "estado_mandato_cmh", other: false, type:"CMH", value:  "MandatoCMH", label: "Mandato CMH", state: mandateCMH}, 
    {select: selectCMH, message: "Transitorio CMH: Contrato de arriendo y/o venta de insumos y equipos médicos pacientes transitorios.",
      bodySet: settransientCMH, bodyValue: bodytransientCMH, key: "estado_transitorio_cmh", other: false, type:"CMH", value:  "TransitorioCMH", label: "Transitorio CMH", state: transientCMH}, 
    {select: selectCMH, message: "Contrato COMODATO", 
      bodySet: setcontratcomodato, bodyValue: bodycontratcomodato, key: "estado_contrato_comodato", other: true, type:"CMH", value:  "Contratocomodato", label: "Contrato COMODATO", state: contratcomodato},
    {select: selectCMH, message: "Condiciones CMH", 
      bodySet: setconditionCMH, bodyValue: bodyconditionCMH, key: "estado_condiciones_cmh", other: true, type:"CMH", value:  "CondicionesCMH", label: "Condiciones CMH", state: conditionCMH}, 
]

  useEffect(() => {
        if (initialCharge){
            initialData();
        }

        if (updateView){
            setupdateView(false);
        }

        if (checkValidation){
            validateContract();
        }
    });

  const initialData = () => {
    setinitialCharge(false);
    const tempArrayTabs = [];

    const getHabilitacion = api.get(
      `api/paciente/solicitudes/${selectContracts.id_solicitud_paciente.id}/habilitacion/`
    );
    const getIsapre = api.get(
      `/api/clinica/pedidosprogramados/paquetepaciente/paciente/${selectContracts.id_ficha.id_paciente.id}`
    );

    Promise.all([getHabilitacion, getIsapre]).then(response => {
        const tempData = response[0].data;
        const contracts = tempData.id_contratos;
        let tempContracts = [];
        contracts.map(row => {
          tempContracts.push(row.tipo_contrato);
        });
        setContractsValidation(tempContracts);
        litItemDoc.forEach((row) => {
          if (tempData[row.key] != "NoGenerarContrato") {
            const contract_cmh = contracts.filter((rowContract) => (rowContract.tipo_contrato === row.value));
            if (selectContracts.estado === "SubirContratos") {
              row.bodySet(contract_cmh[0]);
            }
  
            tempArrayTabs.push({
              tabButton: row.label,
              tabContent: (
                <FormContrat
                  contractsValidation={contractsValidation}
                  setContractsValidation={setContractsValidation}
                  setBody={row.bodySet}
                  management={selectCMH}
                  type={row.type}
                  handleUpdateContract={handleUpdateContract}
                  quotation={tempData.id_cotizacion_paciente}
                  typeForm={row.value}
                  data={contract_cmh[0]}
                  stateWork={selectContracts.estado}
                  stateFile={tempData.estado_contrato_arriendos}
                  selectContracts={selectContracts}
                  setopenModal={setopenModal}
                  isapre={response[1].data.id_convenio_isapre[0].nombre}
                  message={row.message}
                />
              )
            });
          }
          
        });
        setArrayTabs(tempArrayTabs);
      })
    };


    const handleUpdateContract = (value, set, type, id) => {
        const body = {
            dias_aviso: value.dias_aviso,
            fecha_aviso: moment(value.fecha_aviso).format("YYYY-MM-DD"),
            fecha_fin: moment(value.fecha_fin).format("YYYY-MM-DD"),
            fecha_inicio:  moment(value.fecha_inicio).format("YYYY-MM-DD"),
            vigente: true,
        };

        let tempContract = null
        litItemDoc.forEach((row) => {
          if (type === row.value){
            tempContract = {...row.bodyValue, ...body}
            set(tempContract);
          }
        })
        api.patch(`api/contratospaciente/${id}`, body).then((request) => {
            setcheckValidation(true);
            setinitialCharge(true);
        });
    };

  const validateContract = () => {
    setcheckValidation(false);
    const arrayValidation = litItemDoc.map((row) => {
      return  (row.bodyValue != null && row.state) || !row.state
    })
    setvalidation(arrayValidation.every((item) => item === true));
  };

  const handleGenerateContract = () => {
    api.post(`api/facturacion/paciente/${selectContracts.id_ficha.id_paciente.id}/habilitacion/tarea/${selectContracts.id}/contratos/generarcontratos/`, {}).then((request) => {
        handleUpdateRowState(selectContracts, "SubirContratos");
        setinitialCharge(true);
      });
  };

  const handleCloseModal = () => {
    handleUpdateRowState(selectContracts, "Finalizado");
    setopenModal(false);
    setselectRowDetails(null);
    setselectContracts(null);
  };

  return (
    <CardBody>
      {updateView && <div></div>}
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
        item
        xs={12}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
        >
          <Typography
            style={{ textTransform: "none" }}
            variant="h6"
            gutterBottom
          >{`Ingrese los parámetros para cada uno de los contratos`}</Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
        >
          <NavPills color="primary" alignCenter tabs={arrayTabs} />
        </Grid>
        {selectContracts.estado !== "SubirContratos" && (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            item
            xs={12}
          >
            {validation ? (
              <Button
                variant="contained"
                className="text-white"
                color="primary"
                onClick={handleGenerateContract}
              >
                Generar contratos
              </Button>
            ) : (
              <Button variant="contained" disabled>
                Generar contratos
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      {openModal && (
        <ModalContractUpload open={openModal} handleClose={handleCloseModal} />
      )}
    </CardBody>
  );
};

export default ContractParameters;
