import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const ProfessionalFilter = props => {
  const { professional, listProfessionals, handleProfessional } = props;
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth>
      <InputLabel id="demo-simple-select-outlined-label">
        Profesional
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={professional || ""}
        onChange={handleProfessional}
        label="Profesional"
      >
        {listProfessionals.map(row => (
          <MenuItem key={row.id} value={row.id}>
            {`${row.first_name} ${row.last_name}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProfessionalFilter;

ProfessionalFilter.propTypes = {
  professional: PropTypes.number,
  setProfessional: PropTypes.func,
  listProfessionals: PropTypes.array,
  handleProfessional: PropTypes.func
};
