import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  Link,
  Breadcrumbs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {orange, green} from "@material-ui/core/colors";
import api from "utils/API";
import {
} from 'actions/getListAction';


const color_dict = {
  PorRealizar: {
    background: orange[800],
    color: orange[50]
  },
  Incompleto: {
    background: orange[800],
    color: orange[50]
  },
  Finalizado: {
    background: green[800],
    color: green[50]
  },
  Realizada: {
    background: green[800],
    color: green[50]
  }
}

export default function DetailEnableAddress({
  dataPatient,
  dataFile,
  setdataFile,
  reloadRequest,
  detailEnablementAddress,
  setselectPatient,
  setopenModalQuotation,
  settypeRequest,
  setdetailEnablementAddress,
}) {
  const [initialCharge, setinitialCharge] = useState(true);
  const [benefit, setbenefit] = useState([]);
  const [disableState, setdisableState] = useState(false);
  const [contractState, setcontractState] = useState("");

  const dictStateWork = useSelector(state => state.currentList.DICCIONARIO_ESTADO_TAREAS_FICHA);
  const listStateWork = useSelector(state => state.currentList.LISTA_ESTADO_TAREAS_FICHA);

  useEffect(() => {
    if (initialCharge && listStateWork){
      initialData();
    }
  });

  const initialData = () => {
    setinitialCharge(false);
    const tempBenefit = detailEnablementAddress.id_prestaciones;
    setcontractState(detailEnablementAddress.estado_contrato_insumo);
    setbenefit(tempBenefit);
    const arrayState = tempBenefit.map((row) => {
      return dictStateWork[row.id_prestaciones[0]?.tarea?.estado]?.value || dictStateWork["PorRealizar"]?.value;
    });

    const tempDisableState = (arrayState.includes("Finalizado") || arrayState.includes("Realizada"))
    setdisableState(tempDisableState);
  };

  const handleEnable = () => {
    api.post(`api/clinica/solicitudes/pacientes/${dataPatient.id}/habilitacion/${reloadRequest.id}/finalizar/`).then((response) => {
      const tempFile = {...dataFile}
      tempFile.estado_ficha = "Habilitado"
      setdataFile({...tempFile})
      handleBack([setopenModalQuotation, settypeRequest, setdetailEnablementAddress]);

    });
  };

  const handleBack = (row) => {
    row.map((auxRow) => {
      auxRow(null);
    });
  };

  const handleReload = () => {
    handleBack([
      setopenModalQuotation,
      settypeRequest,
    ]);
  };

  const transient = detailEnablementAddress.tipo_permanencia === "Transitorio";
  
  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridItem xs={12}>
          <CardBody className="rounded shadow border border-primary">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => handleBack([setselectPatient, setopenModalQuotation, settypeRequest, setdetailEnablementAddress])}
                style={{cursor: "pointer"}}
              >
                Ficha Paciente
              </Link>
              <Link
                color="inherit"
                onClick={() => handleBack([setopenModalQuotation, settypeRequest, setdetailEnablementAddress])}
                style={{cursor: "pointer"}}
              >
                {`${dataPatient.nombre} ${dataPatient.apellido_paterno}`}
              </Link>
              <Link
                color="inherit"
                onClick={() => handleBack([settypeRequest, setdetailEnablementAddress])}
                style={{cursor: "pointer"}}
              >
                {`Solicitudes`}
              </Link>
              <Typography color="textPrimary">{`Habilitación de domicilio / Detalle`}</Typography>
            </Breadcrumbs>
          </CardBody>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table className="table table-striped">
              <TableHead>
                <TableRow>
                  <TableCell className="text-center align-middle">Grupo </TableCell>
                  <TableCell className="text-center align-middle">Item </TableCell>
                  <TableCell className="text-center align-middle">Tipo cantidad </TableCell>
                  <TableCell className="text-center align-middle">Cantidad </TableCell>
                  <TableCell className="text-center align-middle">Cada </TableCell>
                  <TableCell className="text-center align-middle">Tiempo </TableCell>
                  <TableCell className="text-center align-middle">Area a la que solicitará </TableCell>
                  <TableCell className="text-center align-middle">Estado </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {benefit.map((row, index) => (
                  <TableRow key={`${index}-professionalRow`}>
                    { index===0 && <TableCell rowSpan={benefit.length} className="text-center align-middle">Profesionales </TableCell>}
                    <TableCell className="text-center align-middle">{`${row.id_prestaciones[0].nombre} ${transient ? row.horario_inhabil === true ?  "Inhabil" : row.horario_inhabil === false ? "Habil" : "" : ""}`} </TableCell>
                    <TableCell className="text-center align-middle">{row.id_prestaciones[0].tipo_cantidad} </TableCell>
                    <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                    <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                    <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                    <TableCell className="text-center align-middle">{row.id_prestaciones[0].id_cargo.area} </TableCell>
                    <TableCell
                      className="text-center align-middle"
                      style={{...color_dict[row.id_prestaciones[0]?.tarea?.estado || "PorRealizar"]}}
                    >
                      {dictStateWork[row.id_prestaciones[0]?.tarea?.estado]?.label || dictStateWork["PorRealizar"]?.label}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell className="text-center align-middle">Equipos </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell
                    className="text-center align-middle"
                    style={{...color_dict["PorRealizar"]}}
                  >
                    {dictStateWork["PorRealizar"]?.label}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-center align-middle">Emergencias </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell className="text-center align-middle">{} </TableCell>
                  <TableCell className="text-center align-middle">{"Operaciones"} </TableCell>
                  <TableCell
                    className="text-center align-middle"
                    style={{...color_dict["PorRealizar"]}}
                  >
                    {dictStateWork["PorRealizar"]?.label}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
              <GridItem
                container
                direction="row"
                justify="center"
                alignItems="center"
                xs={12}
              >
                {["GenerarContrato", "NoGenerarContrato", "RecienIniciadaSolicitud"].includes(contractState) ?
                <Button
                  mr={2}
                  variant="contained"
                  color="primary"
                  className="text-white"
                  onClick={() => setdetailEnablementAddress(null)}
                  style={{margin: "0 10px 0 0"}}
                >
                  Cancelar
                </Button>:
                 <Button
                 disabled
                 mr={2}
                 variant="contained"
                 style={{margin: "0 10px 0 0"}}
                >
                  Cancelar
                </Button>
                }
                {!disableState?
                  <Button
                    onClick={handleEnable}
                    mr={2}
                    variant="contained"
                    color="primary"
                    className="text-white"
                  >
                    Habilitado
                  </Button> :
                  <Button
                    disabled
                    mr={2}
                    variant="contained"
                  >
                    Pendientes
                  </Button>
                }

              </GridItem>
            </CardBody>
          </Card>
        </GridItem>

      </GridItem>
    </GridContainer>
  );
}