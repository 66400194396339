import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import FormEditTutor from "./FormEditTutor";
import Snackbar from "components/Snackbar/Snackbar.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },

}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const ModalEditPatient = props => {
  const classes = useStyles();
  const { openModal, closeModal, tutorPatient, settutoPatient } = props;
  const [openAnimation, setOpenAnimation] = useState(false);
  const [message, setmessage] = useState(null);

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto", width: "60%" }}
      >
        {openAnimation ? (
          <ModalTitle id="customized-dialog-title">
            Datos del tutor a editar
          </ModalTitle>
        ) : (
          <ModalTitle id="customized-dialog-title" onClose={closeModal}>
            Datos del tutor a editar
          </ModalTitle>
        )}
        <DialogContent>
          <Grid container className={classes.root} justify="center">
            <FormEditTutor
              data={tutorPatient}
              setmessage={setmessage}
              openAnimation={openAnimation}
              setOpenAnimation={setOpenAnimation}
              closeModal={closeModal}
              settutor={settutoPatient}
            />
          </Grid>
        </DialogContent>
        {message && (
          <Snackbar
            place="tc"
            close
            color="success"
            message={message}
            open={!!message}
            closeNotification={() => setmessage(null)}
          />
        )}
      </Paper>
    </Modal>
  );
};

export default ModalEditPatient;

ModalEditPatient.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tutorPatient: PropTypes.object,
  settutoPatient: PropTypes.func
};
