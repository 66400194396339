import React from "react";

const DetailTextDisabled = props => {
  return(
    <div className="col-md-4 mb-3">
      <label className="text-dark" style={{ fontSize: "16px" }}>{props.label}</label>
      <input
        type="text"
        className="form-control bg-light font-weight-lighter"
        style={{color: "#000000"}}
        value={props.value}
        disabled
      />
    </div>
  );
}

export default DetailTextDisabled;