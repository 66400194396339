import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Avatar, Fab } from '@material-ui/core'
import { TypographyMui } from 'utils/componentsStyle';
import TablePagination from "@material-ui/core/TablePagination";
import usePagination from 'utils/CustomHooks/usePagination';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import coordinatorImg from '../../../../assets/img/icons/workTeam/coordinador.svg'
import tutorImg from '../../../../assets/img/icons/workTeam/tutor.svg'

const useStyles = makeStyles({
  listGrid: {
    display: 'grid',
    gridTemplateColumns: '9.5rem repeat(4, 1fr)',
    gridTemplateRows: '3.5rem',
    marginTop: '.5rem',
  },
  alignSelf: {
    alignSelf: 'center',
  },
  justifySelf: {
    justifySelf: 'center'
  },
  fab: {
    height: 0,
    width: 35,
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  fabIcon: {
    height: 18,
    width: 21
  },
  '@media (max-width: 800px)': {
    listGrid: {
      gridTemplateColumns: '4rem repeat(2, 1fr) 7rem 1fr',
    }
  },
});

function WorkTeamList({ open, coordinators, wordToFilter, workTeamData, setWordToFilter, chargeColor, setDeleteProfessionalData }) {
  const classes = useStyles()
  const [pagination, handleChangePage, handleChangeRowsPerPage] = usePagination(5)
  const [professionalSortedList, setProfessionalSortedList] = useState([])
  const [professionalSortedListToRender, setProfessionalSortedListToRender] = useState([])


  const sortByCharge = () => {
    if (!workTeamData.ficha_personal.length) {
      return
    }
    const chargesSorted = chargeColor.map(charge => {
      let newList = []
      workTeamData.ficha_personal.forEach(professional => {
        const isInTraslate = charge.traslate.some(chargeIter => chargeIter === professional.id_cargo__codigo)
        newList = isInTraslate ? [...newList, professional] : newList
      })
      return newList
    })
    const professionalListSorted = chargesSorted.reduce((acum, list) => {
      list.forEach(professional => acum = [...acum, professional])
      return acum
    }, [])
    setProfessionalSortedList(professionalListSorted)
    setProfessionalSortedListToRender(professionalListSorted)
  }

  const findCoordinatorColor = (coordinator) => {
    if (!coordinator?.id_cargo__tipo_perfil) {
      return
    }
    const findColor = chargeColor.find(charge => {
      const chargeList = charge.traslate.find((item => item === coordinator.id_cargo__tipo_perfil))
      return chargeList
    })
    return findColor.color
  }

  const filterListByNameCharge = () => {
    if (!wordToFilter.length) {
      setProfessionalSortedListToRender(professionalSortedList)
      setWordToFilter('')
      return 
    }
    const listToRender = professionalSortedList.filter((iter) => {
      const query = wordToFilter.toLowerCase().replace(' ', '');
      const filterByName = iter.nombres.toLowerCase().replace(' ', '').indexOf(query) >= 0
      const filterByCharge = iter.id_cargo__nombre_corto.toLowerCase().replace(' ', '').indexOf(query) >= 0
      return filterByName || filterByCharge
    });
    return setProfessionalSortedListToRender(listToRender)
  };

  useEffect(() => {
    sortByCharge()
  }, [workTeamData.ficha_personal])

  useEffect(() => {
    filterListByNameCharge()
  }, [wordToFilter]);

  return (
    <Box Box display={open === 0 ? 'block' : 'none'} mt={3}>
      <Box className={classes.listGrid}>
        <span></span>
        <TypographyMui variant='subtitle2' className={classes.alignSelf}>NOMBRE</TypographyMui>
        <TypographyMui variant='subtitle2' className={classes.alignSelf}>CARGO</TypographyMui>
        <Box>
          <Box display="flex" flexDirection="row">
            <img src={coordinatorImg} width='18px' height='18px' alt="coordinador" className='mr-1' />
            <TypographyMui variant='subtitle2'>COORDINADOR(A)</TypographyMui>
          </Box>
          <Box display="flex" flexDirection="row">
            <img src={tutorImg} width='18px' height='18px' alt="tutor" className='mr-1' />
            <TypographyMui variant='subtitle2'>TUTOR(A)</TypographyMui>
          </Box>
        </Box>
        <TypographyMui variant='subtitle2' className={classes.alignSelf}>CONTACTO</TypographyMui>
      </Box>

      {coordinators && coordinators.map(coordinator => {
        return (
          <Box key={coordinator.id} className={classes.listGrid} style={{ backgroundColor: findCoordinatorColor(coordinator) }}>
            {/* <Avatar className={`${classes.alignSelf} ${classes.justifySelf}`} alt="Remy Sharp" src="https://placeimg.com/640/480/people" /> */}
            <span></span>
            <Box className={classes.alignSelf}>
              <TypographyMui variant='body2'>{coordinator?.nombres ? coordinator?.nombres : 'Coordinador no asignado'}</TypographyMui>
              <TypographyMui variant='body2'>{coordinator?.apellido_paterno}</TypographyMui>
            </Box>
            <TypographyMui variant='body2' className={classes.alignSelf}>{coordinator?.id_cargo__nombre_corto}</TypographyMui>
            <Box display='flex' justifyContent='center' alignItems='center'>
              {coordinator.tipo_coordinador === "coordinadorpaciente"
                ? <img src={coordinatorImg} width='36px' height='36px' alt="coordinador" />
                : <img src={tutorImg} width='36px' height='36px' alt="tutor" />
              }
              <Fab onClick={() => setDeleteProfessionalData(coordinator)} className={`ml-3 ${classes.fab}`} color="primary">
                {<DeleteForeverIcon className={classes.fabIcon} />}
              </Fab>
            </Box>
            <TypographyMui variant='body2' className={classes.alignSelf}>{coordinator?.telefono_1}</TypographyMui>
          </Box>
        )
      })}

      {professionalSortedListToRender
        .slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage)
        .map(professional => {
          const backgroundColor = chargeColor.find(charge => charge.traslate.find(iter => iter === professional.id_cargo__codigo) ? true : false)?.color
          return (
            <Box key={professional.id_usuario} className={classes.listGrid} style={{ backgroundColor }}>
              {/* <Avatar className={`${classes.alignSelf} ${classes.justifySelf}`} alt="Remy Sharp" src="https://placeimg.com/640/480/people" /> */}
              <span></span>
              <Box className={classes.alignSelf}>
                <TypographyMui variant='body2'>{`${professional.nombres}`}</TypographyMui>
                <TypographyMui variant='body2'>{`${professional.apellido_paterno}`}</TypographyMui>
              </Box>
              <TypographyMui variant='body2' className={classes.alignSelf}>{professional.id_cargo__nombre_corto}</TypographyMui>
              <Box></Box>
              <TypographyMui variant='body2' className={classes.alignSelf}>{professional.telefono_1 ? professional.telefono_1 : 'No ingresado'}</TypographyMui>
            </Box>
          )
        })
      }

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={workTeamData.ficha_personal.length}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        SelectProps={{ inputProps: { "aria-label": "Filas por página" }, native: false }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `más que ${to}`}`}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  )
}

export default WorkTeamList
