import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";
import { useParams } from "react-router-dom";
import ModalEditedCommodity from "./ModalEditedCommodity";


export default function EditCommodity() {
  const { id } = useParams();
  const [moduleType, setmoduleType] = useState("");
  const [supplies, setSupplies] = useState([]);
  const [group, setGroup] = useState("");
  const [sku, setSKU] = useState("");
  const [id_sku, setId_sku] = useState("");
  let list_sku = [];
  let list_sku_final = [];
  const [description, setDescription] = useState("");


  const [cantidad, setCantidad] = useState(0);
  const [motivo, setMotivo] = useState("");
  const [modalSaved, setModalSaved] = useState(false);

  const listReason = useSelector(state => state.currentList.LISTA_MOTIVO_RETIRO);
  const dictReason = useSelector(state => state.currentList.DICCIONARIO_MOTIVO_RETIRO);

  useEffect(() => {

    const tempArrayURL = window.location.href.split('/');
    setmoduleType(tempArrayURL[tempArrayURL.length-1]);
    api.get(`api/solicitudretiro/${id}`).then(response => {
      setCantidad(response.data.cantidad);

      api.get("/api/insumosmedicamento/").then(response2 => {
        setSupplies(response2.data);
        response2.data.map(supply => {
          if (response.data.id_producto_retirado === supply.id) {
            setSKU({ value: supply.SKU, label: supply.SKU });
          }
        });

      });
      setMotivo(dictReason[response.data.motivo] || {})
    });
  }, []);

  useEffect(() => {
    supplies.map(supply => {
      list_sku.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies.map(supply => {
      list_sku_final.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies
      .filter(supply => supply.SKU === sku.value)
      .map(filtered => {
        setGroup(filtered.grupo_producto);
        setDescription(filtered.descripcion_producto);
        setId_sku(filtered.id);
      });
  });

  const handleSKU = sku => {
    setSKU(sku);
  };

  const handleReason = motivo => {
    setMotivo(motivo);
  };

  function updatePurchase() {

    const formData = new FormData();

    formData.append("cantidad", cantidad);
    formData.append("estado", "Estado1");
    formData.append("motivo", motivo.value);
    formData.append("id_producto_retirado", id_sku);

    apiform
      .put(`api/solicitudretiro/${id}`, formData)
      .then(response => {
        console.log(response);

        setModalSaved(true);
      })

  }

  return (
    <GridContainer>
      <ViewTitle
        title="EDITAR COMPRA"
        message="En esta sección podrás editar la compra seleccionada."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link color="inherit" to={`/admin/warehouse/requests/${moduleType}`}>
              Fraccionamiento - Compras
            </Link>
            <Typography color="textPrimary">
              Editar compra
            </Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardBody className="mb-3">
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <DetailSelect
                  label="SKU:"
                  value={sku}
                  onchange={handleSKU}
                  list={list_sku}
                />
                <DetailTextDisabled label="GRUPO:" value={group} />
                <DetailTextDisabled label="DESCRIPCIÓN:" value={description} />
                <div className="col-md-4 mb-3">
                  <label className="text-dark">CANTIDAD</label>
                  <input
                    type="number"
                    min="1"
                    className="form-control bg-light font-weight-lighter"
                    style={{color: "#000000"}}
                    value={cantidad}
                    onChange={e => {
                      if (/^[0-9.\b]+$/.test(e.target.value)) {
                        setCantidad(e.target.value);
                      }
                    }}
                  />
                </div>
                <DetailSelect
                  label="MOTIVO:"
                  value={motivo}
                  onchange={handleReason}
                  list={listReason}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item xs={8}>
                {sku ? null : (
                  <span className="text-danger">
                    Debes ingresar un sku
                  </span>
                )}
                {motivo ? null : (
                  <span className="text-danger">
                    Debes ingresar un motivo
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                {sku && motivo ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={updatePurchase}
                  >
                    Guardar cambios
                  </Button>
                ) : null}

              </Grid>
              <Grid item>
                <Link to={`/admin/warehouse/requests/${moduleType}`}>
                  <Button variant="contained" color="primary">
                    Cancelar Solicitud
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      {modalSaved && (
        <ModalEditedCommodity open={modalSaved} closeModal={() => setModalSaved(false)} moduleType={moduleType} />
      )}
    </GridContainer>
  );
}
