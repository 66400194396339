import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
    TextField,
    Select,
    FormControl,
    InputLabel,
    InputAdornment,
} from '@material-ui/core';
import {AttachMoney} from '@material-ui/icons/';
import {ButtonTextMUI as Button, CustomDatePicker, CustomSelectAutoComplete} from "utils/componentsStyle";
import DocumentTable from "./DocumentTable";
import ModalUploadFile from "components/PatientFile/ModalUploadFile";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import {red, green} from "@material-ui/core/colors";
import {onlyNumbers} from "utils/formatFunctions"
import moment from "moment-timezone";
import api from "utils/API";
import "moment/locale/es";
moment.locale("es");

export default function FormMedicalPreventiveData({
    dataFile,
    dataPatient,
    listPercentage,
    setdataFile,
    setdataPatient,
    selectForecast,
    selectPackage,
    setmessage,
}) {
    const [centro_hospitalario_referencia, setcentro_hospitalario] = useState(
        dataFile.centro_hospitalario_referencia ? dataFile.centro_hospitalario_referencia : ""
    );

    const [porcentaje_cobertura, setporcentaje_cobertura] = useState(
        dataPatient.porcentaje_cobertura ? {value: dataPatient.porcentaje_cobertura, label: dataPatient.porcentaje_cobertura} : {}
    );
    const [empresa, setempresa] = useState(dataFile.empresa);

    const [tope, settope] = useState(
        dataPatient.tope ? dataPatient.tope : 0
    );
    const [fecha_renovar, setfecha_renovar] = useState(
        dataPatient.fecha_renovar ? dataPatient.fecha_renovar : ""
    );
    const [validation, setvalidation] = useState(false);
    const [change, setChange] = useState(true);
    const [documentPatient, setdocumentPatient] = useState([]);
    const [initialCharge, setinitialCharge] = useState(true);
    const [loading, setloading] = useState(false);
    const [modalNewDocument, setmodalNewDocument] = useState(false);

    const lista_empresa = useSelector(state => state.currentList.LISTA_EMPRESA);
    const diccionario_empresa = useSelector(state => state.currentList.DICCIONARIO_EMPRESA);

    const paquete = selectPackage || {}
    const prevision = selectForecast || {}
    
    useEffect(() => {
        setempresa(diccionario_empresa[empresa])
    }, []);

    useEffect(() => {
        if (change) {
            validateForm();
        }
        if (initialCharge){
            initialData();
        }
    });

    const initialData = () => {
        setinitialCharge(false);
        setloading(false);

        const requestFile = api.get(`api/paciente/${dataPatient.id}/solicitudes/documentos/`);
        const requestDocumentPatient = api.get(`api/paciente/${dataPatient.id}/documentos/`);
        Promise.all([
            requestFile,
            requestDocumentPatient,
        ]).then((request) => {
            const tempFiles = [];

            request[0].data.map((row) => {
                const tempName =  `${row.Tipo_Solicitud_Paciente}_${dataPatient.nombre}_${dataPatient.apellido_paterno}.xlsx`
                tempFiles.push({
                    nombre_archivo: tempName,
                    tipo_documento: row.Tipo_Solicitud_Paciente,
                    id: row.id,
                    fecha: row.fecha_creacion,
                });
            });
            request[1].data.map((row) => {
                tempFiles.push({
                    archivo: row.archivo,
                    nombre_archivo: row.nombre_archivo,
                    tipo_documento: row.tipo_documento,
                    fecha: row.fecha,
                });
            });
            setdocumentPatient(tempFiles);
            setloading(true);
        });
    };

    const handleAddFile = (row) => {
        const tempArrayFile = documentPatient;
        tempArrayFile.push({
            archivo: row.archivo,
            nombre_archivo: row.nombre_archivo,
            tipo_documento: row.tipo_documento,
        })
        setdocumentPatient(tempArrayFile);
    };

    const validateForm = () => {
        const arrayValidation = [
        ];
        if (arrayValidation.every((item) => item === true)) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    };

    const updateValue = () => {
        const bodyFile = {};
        const bodyPatient = {};
        if (centro_hospitalario_referencia !== ""){
            bodyFile["centro_hospitalario_referencia"] = centro_hospitalario_referencia;
        }
        if (empresa?.value){
            bodyFile["empresa"] = empresa?.value;
        }
        if (porcentaje_cobertura?.value){
            bodyPatient["porcentaje_cobertura"] = porcentaje_cobertura?.value;
        }
        if (tope !== ""){
            bodyPatient["tope"] = typeof tope === 'number' ? tope : parseInt(tope.split('$').join('').split(',').join(''));
        }
        if (fecha_renovar !== ""){
            bodyPatient["fecha_renovar"] = fecha_renovar
        }

        const requestFilePatient = api.patch(`api/fichapaciente/${dataFile.id}`, bodyFile);
        const requestPatient = api.patch(`api/paciente/${dataPatient.id}`, bodyPatient);
        Promise.all([
            requestPatient,
            requestFilePatient,
        ]).then((request) => {
            setdataFile({...dataFile, ...request[0].data});
            setdataPatient({...dataPatient});
            setdataFile({...dataPatient, ...request[1].data});
            setmessage("Datos actualizados");
        })
    };

    const restart = () => {
        setcentro_hospitalario(dataFile.centro_hospitalario_referencia ? dataFile.centro_hospitalario_referencia: "");
        setporcentaje_cobertura(dataPatient.porcentaje_cobertura ? {value: dataPatient.porcentaje_cobertura, label: dataPatient.porcentaje_cobertura} : "");
        settope(dataPatient.tope ? dataPatient.tope : "");
        setfecha_renovar(dataPatient.fecha_renovar ? dataPatient.fecha_renovar : "");
    };

    const type_user = localStorage.getItem("tipo_perfil");

    const listForm = [
        {
            disabled: type_user == "CLITecEnfBas",
            label:"Centro hospitalario de referencia",
            value: centro_hospitalario_referencia,
            onChange: (e) => setcentro_hospitalario(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: true,
            label:"Previsión",
            value: prevision.label,
            gridStyle: {padding: "15px"}
        },
        {
            disabled: true,
            label:"Paquete",
            value: paquete.label,
            gridStyle: {padding: "15px"}
        },
        {
            _content_:  <CustomSelectAutoComplete
            disabled={type_user === "CLITecEnfBas"}
            value={porcentaje_cobertura}
            onChange={(e) => setporcentaje_cobertura(e)}
            label="Porcentaje cobertura"
            listItem={listPercentage}
        />,
            gridStyle: {padding: "15px"}
        },
        {
            _content_:  <CustomSelectAutoComplete
            disabled={type_user === "CLITecEnfBas"}
            value={empresa}
            onChange={(e) => setempresa(e)}
            label="Empresa"
            listItem={lista_empresa}
        />,
            gridStyle: {padding: "15px"}
        },
        {
            label:"Tope isapre",
            value: tope,
            onChange: (e) => settope(onlyNumbers(e.target.value)),
            InputProps:{
                startAdornment: (
                <InputAdornment position="start">
                <AttachMoney />
                </InputAdornment>
                ),
                min: 1,
            },
            gridStyle: {padding: "15px"}
        },
        {
            _content_: <CustomDatePicker
                disabled={type_user === "CLITecEnfBas"}
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                label={"Fecha de renovación"}
                placeholder="Fecha de renovación"
                onChange={(e) => setfecha_renovar(e.format("YYYY-MM-DD"))}
                value={fecha_renovar ? moment(fecha_renovar): null}
                inputVariant={"outlined"}
            />,
            gridStyle: {padding: "15px"}
        },

    ]

    return (
        <Grid item xs={12}>
            {loading ?
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                item
                xs={12}
            >
                {listForm.map((row) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    style={row.gridStyle || {}}
                >
                    {row._content_ ||
                    <TextField
                        variant="outlined"
                        type="text"
                        error={row.value == ""}
                        helperText={row.value == "" ? "No puede ser vacio" : ""}
                        style={{ width: '100%' }}
                        {...row}

                    />}
                </Grid>
                ))}

                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                >
                    { (validation &&  type_user !== "CLITecEnfBas") ?
                    <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        style={{backgroundColor: green[500]}}
                        onClick={updateValue}
                    >
                        Guardar
                    </Button>
                    :
                    <Button
                        disabled
                        mr={2}
                        variant="contained"
                    >
                        Guardar
                    </Button>
                    }
                    {type_user === "CLITecEnfBas" ?
                    <Button
                        mr={2}
                        variant="contained"
                        disabled
                    >
                        Reiniciar
                    </Button>:
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={restart}
                    >
                        Reiniciar
                    </Button>
                    }
                </Grid>

                {type_user !== "CLITecEnfBas" &&
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    item
                    style={{margin: "30px 0 0 0"}}
                    xs={12}
                >
                    <Button
                        variant="contained" color="primary"
                        style={{zIndex: 4, textTransform: "none"}}
                        onClick={() => setmodalNewDocument(true)}
                    >
                        Agregar Documento
                    </Button>
                </Grid>
                }
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    item
                    xs={12}

                >
                    <DocumentTable
                        files={documentPatient}
                        setfiles={setdocumentPatient}
                        dataPatient={dataPatient}

                    />
                </Grid>
                {modalNewDocument &&
                <ModalUploadFile
                    open={modalNewDocument}
                    handleClose={() => setmodalNewDocument(false)}
                    dataPatient={dataPatient}
                    setmessage={setmessage}
                    handleAddFile={handleAddFile}
                    />
                }
            </Grid>
            :
            <LoadInfo/>
            }
       </Grid>
    );
}