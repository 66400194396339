import Soon from "components/Soon/Soon";
import WareHouseTask from "views/Warehouse/Tasks/WareHouseTask";
import WareHouseManagement from "views/Warehouse/Management/WareHouseManagement";
import WareHouseOrders from "views/Warehouse/Orders/WareHouseOrders";
import WareHouseRequests from "views/Warehouse/Requests/WareHouseRequest";
import WareHouseStock from "views/Warehouse/Stock/WareHouseStock";
import WareHouseReceptions from "views/Warehouse/Receptions/WareHouseReceptions";
import OrderDetail from "views/Warehouse/Orders/OrderDetail";
import OrderEquipDetail from "views/Warehouse/Orders/OrderEquipDetail.js";
import OrderDetailWaitOutcome from "views/Warehouse/Orders/OrderDetailWaitOutcome";
import Calendar from "views/Warehouse/Orders/Calendar";
import AddFile from "views/Warehouse/Orders/AddFile";
import NewRequest from "views/Warehouse/Requests/NewRequest";
import EditRequest from "views/Warehouse/Requests/EditRequest";
import NewPurchase from "views/Warehouse/Requests/NewPurchase";
import EditPurchase from "views/Warehouse/Requests/EditPurchase";
import NewCommodity from "views/Warehouse/Requests/NewCommodity";
import EditCommodity from "views/Warehouse/Requests/EditCommodity";
import AddFileReception from "views/Warehouse/Receptions/AddFile";
import AddFileReception2 from "views/Warehouse/Receptions/AddFile2";
import NewReport from "views/Warehouse/Requests/NewReport";
import EditReport from "views/Warehouse/Requests/EditReport";
import OutcomeTasks from "views/Outcome/Tasks/OutcomeTasks";
import OutcomeReceptions from "views/Outcome/Receptions/OutcomeReceptions";
import OutcomeDivisions from "views/Outcome/Divisions/OutcomeDivisions";
import ReceptionDetail from "views/Outcome/Receptions/ReceptionDetail";
import AddReceptionFile from "views/Outcome/Receptions/AddFile/AddReceptionFile";
import SendCompanyTransport from "views/Outcome/Receptions/SendCompanyTransport";
import SendCompanyProfesional from "views/Outcome/Receptions/SendCompanyProfesional";
import SendExternalTransport from "views/Outcome/Receptions/SendExternalTransport";
import DivisionRequest from "views/Outcome/Divisions/Division/DivisionRequest";
import CommodityRequest from "views/Outcome/Divisions/Commodity/CommodityRequest";
import IncomeTasks from "views/Income/Tasks/IncomeTaks";
import ProductsIncome from "views/Income/ProductsIncome/ProductsIncome";
import IncomeDivisions from "views/Income/Divisions/IncomeDivisions";
import IncomeDetail from "views/Income/ProductsIncome/RequestDetail";
import DivisionRequestIncome from "views/Income/Divisions/DivisionRequest";
import ProductCodeRequest from "views/Income/ProductsIncome/ProductCodeRequest";
import PurchasesTasks from "views/Purchases/Tasks/PurchasesTasks";
import PurchaseRequest from "views/Purchases/PurchaseRequest/PurchaseRequest";
import CalendarPurchases from "views/Purchases/PurchaseRequest/CalendarPurchases";
import InProcessDetail from "views/Purchases/PurchaseRequest/InProcess/InProcessDetail";
import ToComeDetail from "views/Purchases/PurchaseRequest/ToCome/ToComeDetail";
import FinalizedDetail from "views/Purchases/PurchaseRequest/Finalized/FinalizedDetail";
import ProvidersRequest from "views/Purchases/Providers/ProvidersRequest";
import Transport from "views/Transport/Transport";
import TransportDriver from "views/Transport/TransportDriver";
import DashboardBoss from "views/DashboardBoss/DashboardBoss";
import Tasks from "views/BossShopping/Tasks";
import AddIncomeFile from "views/Income/ProductsIncome/AddIncomeFile";
import PurchaseDetail from "views/BossShopping/PurchaseDetail";

export const soon_operations = {
  path: "/soon/",
  name: "Por Definir",
  rtlName: "نظام الشبكة",
  mini: ".",
  rtlMini: "زو",
  component: Soon,
  layout: "/admin"
};

export const warehouse_taks_storage = {
  path: "/warehouse/task/storage",
  name: "Tareas de bodega",
  mini: ".",
  component: WareHouseTask,
  layout: "/admin"
};

export const warehouse_equip_maintaining = {
  path: "/warehouse/task/mantaining ",
  name: "Gestion de equipos",
  mini: ".",
  component: WareHouseManagement,
  layout: "/admin"
};

export const warehouse_taks_pharmacy = {
  path: "/warehouse/task/pharmacy",
  name: "Tareas de farmacia",
  mini: ".",
  component: WareHouseTask,
  layout: "/admin"
};

export const warehouse_orders_storage = {
  path: "/warehouse/orders/storage",
  component: WareHouseOrders,
  layout: "/admin",
  invisible: true
};

export const warehouse_orders_pharmacy = {
  path: "/warehouse/orders/pharmacy",
  component: WareHouseOrders,
  layout: "/admin",
  invisible: true
};

export const warehouse_requests_storage = {
  path: "/warehouse/requests/storage",
  // name: "Solicitudes administrativas",
  // mini: ".",
  component: WareHouseRequests,
  layout: "/admin",
  invisible: true
};

export const warehouse_requests_pharmacy = {
  path: "/warehouse/requests/pharmacy",
  // name: "Solicitudes administrativas",
  // mini: ".",
  component: WareHouseRequests,
  layout: "/admin",
  invisible: true
};

export const warehouse_stock_storage = {
  path: "/warehouse/stock/storage",
  name: "Consultar stock",
  mini: ".",
  component: WareHouseStock,
  layout: "/admin"
};

export const warehouse_stock_pharmacy = {
  path: "/warehouse/stock/pharmacy",
  name: "Consultar stock",
  mini: ".",
  component: WareHouseStock,
  layout: "/admin"
};

export const warehouse_receptions_storage = {
  path: "/warehouse/receptions/storage",
  component: WareHouseReceptions,
  layout: "/admin",
  invisible: true
};

export const warehouse_receptions_pharmacy = {
  path: "/warehouse/receptions/pharmacy",
  component: WareHouseReceptions,
  layout: "/admin",
  invisible: true
};

export const warehouse_order_detail_storage = {
  path: "/warehouse/order/storage/:id",
  component: OrderDetail,
  layout: "/admin",
  invisible: true
};

export const warehouse_order_equip_detail_storage = {
  path: "/warehouse/order/equipments/:id",
  component: OrderEquipDetail,
  layout: "/admin",
  invisible: true
};

export const warehouse_order_detail_pharmacy = {
  path: "/warehouse/order/pharmacy/:id",
  component: OrderDetail,
  layout: "/admin",
  invisible: true
};

export const warehouse_order_wait_outcome_storage = {
  path: "/warehouse/outcome/storage/:id/",
  component: OrderDetailWaitOutcome,
  layout: "/admin",
  invisible: true
};

export const warehouse_order_wait_outcome_pharmacy = {
  path: "/warehouse/outcome/pharmacy/:id/",
  component: OrderDetailWaitOutcome,
  layout: "/admin",
  invisible: true
};

export const warehouse_order_calendar_storage = {
  path: "/warehouse/calendar/storage",
  component: Calendar,
  layout: "/admin",
  invisible: true
};

export const warehouse_order_calendar_pharmacy = {
  path: "/warehouse/calendar/pharmacy",
  component: Calendar,
  layout: "/admin",
  invisible: true
};

export const warehouse_add_file_storage = {
  path: "/warehouse/addfile/storage/:id",
  component: AddFile,
  layout: "/admin",
  invisible: true
};

export const warehouse_add_file_pharmacy = {
  path: "/warehouse/addfile/pharmacy/:id",
  component: AddFile,
  layout: "/admin",
  invisible: true
};

export const division_storage = {
  path: "/warehouse/request/add/storage",
  component: NewRequest,
  layout: "/admin",
  invisible: true
};

export const division_pharmacy = {
  path: "/warehouse/request/add/pharmacy",
  component: NewRequest,
  layout: "/admin",
  invisible: true
};

export const edit_division_storage = {
  path: "/warehouse/request/edit/storage/:id",
  component: EditRequest,
  layout: "/admin",
  invisible: true
};

export const edit_division_pharmacy = {
  path: "/warehouse/request/edit/pharmacy/:id",
  component: EditRequest,
  layout: "/admin",
  invisible: true
};

export const add_purchase_storage = {
  path: "/warehouse/purchases/add/storage",
  component: NewPurchase,
  layout: "/admin",
  invisible: true
};

export const add_purchase_pharmacy = {
  path: "/warehouse/purchases/add/pharmacy",
  component: NewPurchase,
  layout: "/admin",
  invisible: true
};

export const edit_purchase_storage = {
  path: "/warehouse/purchases/edit/storage/:id",
  component: EditPurchase,
  layout: "/admin",
  invisible: true
};

export const edit_purchase_pharmacy = {
  path: "/warehouse/purchases/edit/pharmacy/:id",
  component: EditPurchase,
  layout: "/admin",
  invisible: true
};

export const add_commodity_storage = {
  path: "/warehouse/commodity/add/storage",
  component: NewCommodity,
  layout: "/admin",
  invisible: true
};

export const add_commodity_pharmacy = {
  path: "/warehouse/commodity/add/pharmacy",
  component: NewCommodity,
  layout: "/admin",
  invisible: true
};

export const add_report_storage = {
  path: "/warehouse/reports/add/storage",
  component: NewReport,
  layout: "/admin",
  invisible: true
};

export const add_report_pharmacy = {
  path: "/warehouse/reports/add/pharmacy",
  component: NewReport,
  layout: "/admin",
  invisible: true
};

export const edit_report_storage = {
  path: "/warehouse/report/edit/storage/:id",
  component: EditReport,
  layout: "/admin",
  invisible: true
};

export const edit_report_pharmacy = {
  path: "/warehouse/report/edit/pharmacy/:id",
  component: EditReport,
  layout: "/admin",
  invisible: true
};

export const edit_commodity_storage = {
  path: "/warehouse/commodity/edit/storage/:id",
  component: EditCommodity,
  layout: "/admin",
  invisible: true
};

export const edit_commodity_pharmacy = {
  path: "/warehouse/commodity/edit/pharmacy/:id",
  component: EditCommodity,
  layout: "/admin",
  invisible: true
};

export const reception_add_file_storage = {
  path: "/warehouse/reception/addfile/storage/:id",
  component: AddFileReception,
  layout: "/admin",
  invisible: true
};

export const reception_add_file_pharmacy = {
  path: "/warehouse/reception/addfile/pharmacy/:id",
  component: AddFileReception,
  layout: "/admin",
  invisible: true
};

export const reception_add_file_2_storage = {
  path: "/warehouse/entry/addfile/storage/:id",
  component: AddFileReception2,
  layout: "/admin",
  invisible: true
};

export const reception_add_file_2_pharmacy = {
  path: "/warehouse/entry/addfile/pharmacy/:id",
  component: AddFileReception2,
  layout: "/admin",
  invisible: true
};

export const warehouseStorage = {
  collapse: true,
  path: "/warehouse/storage",
  name: "Bodega",
  mini: ".",
  layout: "/admin",
  state: "warehouseStorageCollapse",
  views: [
    warehouse_equip_maintaining,
    warehouse_taks_storage,
    warehouse_orders_storage,
    warehouse_requests_storage,
    warehouse_stock_storage,
    warehouse_receptions_storage,
    warehouse_order_detail_storage,
    warehouse_order_equip_detail_storage,
    warehouse_order_wait_outcome_storage,
    warehouse_order_calendar_storage,
    warehouse_add_file_storage,
    division_storage,
    edit_division_storage,
    add_purchase_storage,
    edit_purchase_storage,
    add_commodity_storage,
    edit_commodity_storage,
    add_report_storage,
    edit_report_storage,
    reception_add_file_storage,
    reception_add_file_2_storage
  ]
};

export const warehousePharmacy = {
  collapse: true,
  path: "/warehouse/pharmacy",
  name: "Bodega farmacia",
  mini: ".",
  layout: "/admin",
  state: "warehousePharmacyCollapse",
  views: [
    warehouse_taks_pharmacy,
    warehouse_orders_pharmacy,
    warehouse_requests_pharmacy,
    warehouse_stock_pharmacy,
    warehouse_receptions_pharmacy,
    warehouse_order_detail_pharmacy,
    warehouse_order_wait_outcome_pharmacy,
    warehouse_order_calendar_pharmacy,
    warehouse_add_file_pharmacy,
    division_pharmacy,
    edit_division_pharmacy,
    add_purchase_pharmacy,
    edit_purchase_pharmacy,
    add_commodity_pharmacy,
    edit_commodity_pharmacy,
    add_report_pharmacy,
    edit_report_pharmacy,
    reception_add_file_pharmacy,
    reception_add_file_2_pharmacy
  ]
};

export const outcome_tasks_storage = {
  path: "/outcome/tasks/storage",
  name: "Tareas de egreso",
  mini: ".",
  component: OutcomeTasks,
  layout: "/admin"
};

export const outcome_tasks_pharmacy = {
  path: "/outcome/tasks/pharmacy",
  name: "Tareas de egreso",
  mini: ".",
  component: OutcomeTasks,
  layout: "/admin"
};

export const outcome_receptions_storage = {
  path: "/outcome/receptions/storage",
  component: OutcomeReceptions,
  layout: "/admin",
  invisible: true
};

export const outcome_receptions_pharmacy = {
  path: "/outcome/receptions/pharmacy",
  component: OutcomeReceptions,
  layout: "/admin",
  invisible: true
};

export const outcome_divisions_storage = {
  path: "/outcome/divisions/storage",
  component: OutcomeDivisions,
  layout: "/admin",
  invisible: true
};

export const outcome_divisions_pharmacy = {
  path: "/outcome/divisions/pharmacy",
  component: OutcomeDivisions,
  layout: "/admin",
  invisible: true
};

export const outcome_division_storage = {
  path: "/outcome/reception/storage/:id",
  component: ReceptionDetail,
  layout: "/admin",
  invisible: true
};

export const outcome_division_pharmacy = {
  path: "/outcome/reception/pharmacy/:id",
  component: ReceptionDetail,
  layout: "/admin",
  invisible: true
};

export const outcome_reception_add_file_storage = {
  path: "/outcome/addfile/storage/:id",
  component: AddReceptionFile,
  layout: "/admin",
  invisible: true
};

export const outcome_reception_add_file_pharmacy = {
  path: "/outcome/addfile/pharmacy/:id",
  component: AddReceptionFile,
  layout: "/admin",
  invisible: true
};

export const outcome_company_transport_storage = {
  path: "/outcome/companytransport/storage/:id",
  component: SendCompanyTransport,
  layout: "/admin",
  invisible: true
};

export const outcome_company_transport_pharmacy = {
  path: "/outcome/companytransport/pharmacy/:id",
  component: SendCompanyTransport,
  layout: "/admin",
  invisible: true
};

export const outcome_professional_transport_storage = {
  path: "/outcome/profesionaltransport/storage/:id",
  component: SendCompanyProfesional,
  layout: "/admin",
  invisible: true
};

export const outcome_professional_transport_pharmacy = {
  path: "/outcome/profesionaltransport/pharmacy/:id",
  component: SendCompanyProfesional,
  layout: "/admin",
  invisible: true
};

export const outcome_external_transport_storage = {
  path: "/outcome/externaltransport/storage/:id",
  component: SendExternalTransport,
  layout: "/admin",
  invisible: true
};

export const outcome_external_transport_pharmacy = {
  path: "/outcome/externaltransport/pharmacy/:id",
  component: SendExternalTransport,
  layout: "/admin",
  invisible: true
};

export const outcome_division_request_storage = {
  path: "/outcome/division/storage/:id",
  component: DivisionRequest,
  layout: "/admin",
  invisible: true
};

export const outcome_division_request_pharmacy = {
  path: "/outcome/division/pharmacy/:id",
  component: DivisionRequest,
  layout: "/admin",
  invisible: true
};

export const outcome_commodity_request_storage = {
  path: "/outcome/commodity/storage/:id",
  component: CommodityRequest,
  layout: "/admin",
  invisible: true
};

export const outcome_commodity_request_pharmacy = {
  path: "/outcome/commodity/pharmacy/:id",
  component: CommodityRequest,
  layout: "/admin",
  invisible: true
};

export const outcome_order_detail_storage = {
  path: "/outcome/order/storage/:id",
  component: OrderDetail,
  layout: "/admin",
  invisible: true
};

export const outcome_order_detail_pharmacy = {
  path: "/outcome/order/pharmacy/:id",
  component: OrderDetail,
  layout: "/admin",
  invisible: true
};


export const outcomeStorage = {
  collapse: true,
  path: "/outcome/storage",
  name: "Egresos",
  mini: ".",
  layout: "/admin",
  state: "outcomeCollapse",
  views: [
    outcome_tasks_storage,
    outcome_receptions_storage,
    outcome_divisions_storage,
    outcome_division_storage,
    outcome_reception_add_file_storage,
    outcome_company_transport_storage,
    outcome_external_transport_storage,
    outcome_division_request_storage,
    outcome_professional_transport_storage,
    outcome_commodity_request_storage,
    outcome_order_detail_storage,
    outcome_order_detail_pharmacy
  ]
};

export const outcomePharmacy = {
  collapse: true,
  path: "/outcome/pharmacy",
  name: "Egresos Farmacia",
  mini: ".",
  layout: "/admin",
  state: "outcomeCollapsePharmacy",
  views: [
    outcome_tasks_pharmacy,
    outcome_receptions_pharmacy,
    outcome_divisions_pharmacy,
    outcome_division_pharmacy,
    outcome_reception_add_file_pharmacy,
    outcome_company_transport_pharmacy,
    outcome_professional_transport_storage,
    outcome_professional_transport_pharmacy,
    outcome_external_transport_pharmacy,
    outcome_division_request_pharmacy,
    outcome_commodity_request_pharmacy,
    outcome_order_detail_storage,
    outcome_order_detail_pharmacy
  ]
};

export const income_taks_storage = {
  path: "/income/tasks/storage",
  name: "Tareas de ingreso",
  mini: ".",
  component: IncomeTasks,
  layout: "/admin"
};

export const income_taks_pharmacy = {
  path: "/income/tasks/pharmacy",
  name: "Tareas de farmacia",
  mini: ".",
  component: IncomeTasks,
  layout: "/admin"
};

export const income_products_income_storage = {
  path: "/income/productsincome/storage",
  component: ProductsIncome,
  layout: "/admin",
  invisible: true
};
export const income_products_income_pharmacy = {
  path: "/income/productsincome/pharmacy",
  component: ProductsIncome,
  layout: "/admin",
  invisible: true
};

export const income_divisions_storage = {
  path: "/income/divisions/storage",
  component: IncomeDivisions,
  layout: "/admin",
  invisible: true
};

export const income_divisions_pharmacy = {
  path: "/income/divisions/pharmacy",
  component: IncomeDivisions,
  layout: "/admin",
  invisible: true
};

export const income_division_request_storage = {
  path: "/income/division/storage/:id",
  component: DivisionRequestIncome,
  layout: "/admin",
  invisible: true
};
export const income_division_request_pharmacy = {
  path: "/income/division/pharmacy/:id",
  component: DivisionRequestIncome,
  layout: "/admin",
  invisible: true
};

export const income_product_code_storage = {
  path: "/income/productcode/storage/:id",
  component: ProductCodeRequest,
  layout: "/admin",
  invisible: true
};

export const income_product_code_pharmacy = {
  path: "/income/productcode/pharmacy/:id",
  component: ProductCodeRequest,
  layout: "/admin",
  invisible: true
};

export const income_detail_storage = {
  path: "/income/detail/storage/:id",
  component: IncomeDetail,
  layout: "/admin",
  invisible: true
};

export const income_detail_pharmacy = {
  path: "/income/detail/pharmacy/:id",
  component: IncomeDetail,
  layout: "/admin",
  invisible: true
};

export const income_add_file_storage = {
  path: "/income/addfile/storage/:id",
  component: AddIncomeFile,
  layout: "/admin",
  invisible: true
};

export const income_add_file_pharmacy = {
  path: "/income/addfile/pharmacy/:id",
  component: AddIncomeFile,
  layout: "/admin",
  invisible: true
};

export const incomeStorage = {
  collapse: true,
  path: "/income/storage",
  name: "Ingresos",
  mini: ".",
  layout: "/admin",
  state: "incomeCollapse",
  views: [
    income_taks_storage,
    income_products_income_storage,
    income_divisions_storage,
    income_division_request_storage,
    income_product_code_storage,
    income_detail_storage,
    income_add_file_storage
  ]
};
export const incomePharmacy = {
  collapse: true,
  path: "/income/pharmacy",
  name: "Ingresos farmacia",
  mini: ".",
  layout: "/admin",
  state: "incomeCollapsePharmacy",
  views: [
    income_taks_pharmacy,
    income_products_income_pharmacy,
    income_divisions_pharmacy,
    income_division_request_pharmacy,
    income_product_code_pharmacy,
    income_detail_pharmacy,
    income_add_file_pharmacy
  ]
};

export const purchases_tasks = {
  path: "/purchases/tasks/",
  name: "Tareas de compras",
  mini: ".",
  component: PurchasesTasks,
  layout: "/admin"
};

export const purchases_requests = {
  path: "/purchases/requests/",
  component: PurchaseRequest,
  layout: "/admin",
  invisible: true
};

export const purchases_calendar = {
  path: "/purchases/calendar/",
  component: CalendarPurchases,
  layout: "/admin",
  invisible: true
};

export const purchase_inprocess_detail = {
  path: "/purchases/inprocess/detail/:id",
  component: InProcessDetail,
  layout: "/admin",
  invisible: true
};

export const purchase_tocome_detail = {
  path: "/purchase/tocome/detail/:id",
  component: ToComeDetail,
  layout: "/admin",
  invisible: true
};

export const purchase_finalized_detail = {
  path: "/purchases/finalized/detail/:id",
  component: FinalizedDetail,
  layout: "/admin",
  invisible: true
};

export const purchases_providers = {
  path: "/purchases/providers/",
  component: ProvidersRequest,
  layout: "/admin",
  invisible: true
};

export const purchases = {
  collapse: true,
  path: "/purchases/",
  name: "Compras",
  mini: ".",
  layout: "/admin",
  state: "purchaseIncome",
  views: [
    purchases_tasks,
    purchases_requests,
    purchases_calendar,
    purchase_inprocess_detail,
    purchase_tocome_detail,
    purchase_finalized_detail,
    purchases_providers
  ]
};

export const transport_tasks = {
  path: "/transport/tasks/",
  name: "Tareas de transporte",
  mini: ".",
  component: Transport,
  layout: "/admin"
};

export const transport_driver = {
  path: "/transport/driver/",
  name: "Tareas conductor",
  mini: ".",
  component: TransportDriver,
  layout: "/admin"
};

export const transport_all = {
  collapse: true,
  path: "/transport",
  name: "Transporte",
  mini: ".",
  layout: "/admin",
  state: "transportCollapse",
  views: [
    transport_tasks,
    transport_driver,
  ]
};

export const transport_only_boss = {
  collapse: true,
  path: "/transport",
  name: "Transporte",
  mini: ".",
  layout: "/admin",
  state: "transportCollapse",
  views: [
    transport_tasks,
  ]
};

export const transport_only_driver = {
  collapse: true,
  path: "/transport",
  name: "Transporte",
  mini: ".",
  layout: "/admin",
  state: "transportCollapse",
  views: [
    transport_driver,
  ]
};

export const boss_dashboard = {
  path: "/boss/dashboard/",
  name: "Dashboard",
  mini: ".",
  component: DashboardBoss,
  layout: "/admin"
};

export const bossShopping = {
  path: "/boss/shopping/",
  name: "Tareas",
  mini: ".",
  component: Tasks,
  layout: "/admin"
};

export const bossShoppingDetail = {
  path: "/boss/detail/shopping/:id/",
  invisible: true,
  component: PurchaseDetail,
  layout: "/admin"
};

export const boss = {
  collapse: true,
  path: "/Boss",
  name: "Jefe",
  mini: ".",
  layout: "/admin",
  state: "bossCollapse",
  views: [
    boss_dashboard,
    bossShopping,
    bossShoppingDetail
  ]
};
