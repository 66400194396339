import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import { red, green } from "@material-ui/core/colors";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStylesTemplate = makeStyles(styles);


export default function ModalEventTravel({
    open,
    handleClose,
    eventTravel,
    rowsActive,
    setrowsActive,
    setmessage,
}) {
    const classesTemplate = useStylesTemplate();
    const [tempRow, settempRow] = useState(eventTravel[0]);
    const [reception, setreception] = useState("");
    const [comment, setcomment] = useState("");
    const [state, setstate] = useState("");
    const [allRequestPatient, setallRequestPatient] = useState([])
    const stateFilter = [
        "Entregado",
        "Retrasado",
        "Fallido",
        "Retirado"
      ];

    useEffect(() => {
        setallRequestPatient(rowsActive.filter( (row) =>(
            row.estado_recepcion == tempRow.estado_recepcion && 
            moment(row.hora_estimada).format("YYYY-MM-DD") && moment(row.tempRow).format("YYYY-MM-DD") &&
            row.id_paciente === tempRow.id_paciente && 
            row.id_conductor === tempRow.id_conductor
        )))
    }, []);

    const handleSave = () => {
        const body = {};
        const body2 = {};
        if (state !== "Fallido" ){
            body["estado_recepcion"] = `GuiaEntrega${state}`;
            body2["estado"] = `GuiaEntrega${state}`
            body["hora_llegada"] = moment().format("YYYY-MM-DD HH:mm:ss");
        } else {
            body["estado_recepcion"] = state;
            body["estado"] = state;
            body["hora_llegada"] = null;
        }

        body["quien_recibio"] = reception;
        body["observacion_recepcion"] = comment;
        const requestArray = [];
        const list_id = allRequestPatient.map((row) => {
            requestArray.push(api.patch(`/api/agendatransportes/${row.id}`, body));
            if (row.id_envios_pedidos){
                requestArray.push(api.patch(`/api/enviospedidos/${row.id_envios_pedidos.id}`, body2));
            }
            if(state === "Retirado" && row.tipo_solicitud==="SolicitudRetiroEquipo"){
                requestArray.push(api.post(`api/transporte/equipoarrendado/actualizar/${row.id_parada_traslado_ingreso.id_solicitud_ingreso.id}/`));
            }
            return row.id
        })

        Promise.all(requestArray).then((request) => {
            const tempRows = rowsActive.map((row) => {
                const auxRow = row
                console.log(list_id.includes(row.id), row.id)
                if(list_id.includes(row.id)){
                    auxRow.estado_recepcion = body.estado_recepcion;
                    auxRow.quien_recibio = body.quien_recibio;
                    auxRow.observacion_recepcion = body.observacion_recepcion;
                    auxRow.hora_llegada = body.hora_llegada;
                }
                return auxRow;
            });

            setrowsActive(tempRows);
            setmessage("Pedido entregado. Adjuntar guía de entrega para finalizar el proceso");
            handleClose();
        });
    };

    const _content_ = <div>
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <h3 id="spring-modal-title">{`¿Qué ha ocurrido con el viaje?`}</h3>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Grid item xs={12}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <div className={classesTemplate.cardContentRight}>
                        <div className={classesTemplate.buttonGroup}>
                            {stateFilter.map((row, index) =>
                                <Button
                                    key={row}
                                    color={state === row ? "primary" : "default"}
                                    variant="contained"
                                    className="text-white"
                                    onClick={() => setstate(row)}
                                    style={{margin: "0 3px"}}
                                >
                                    {row}
                                </Button>
                            )}
                        </div>
                    </div>
                </Grid>
        </Grid>
            <h3 id="spring-modal-title">{`¿Quién recibio el paquete?`}</h3>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <DetailText
                    label={``}
                    value={reception}
                    onchange={(e) => { setreception(e.target.value)}}
                    validation={state !== "Fallido"}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <DetailText
                    label={`Comentarios`}
                    value={comment}
                    onchange={(e) => { setcomment(e.target.value)}}
                    validation={state !== "Fallido"}
                />
            </Grid>
        </Grid>
    </div>

    const _actions_ = <>
        {((state !== "Fallido" && state !== "" && reception != "" && comment != "") || (state == "Fallido")) ?
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            color="primary"
            onClick={handleSave}
        >
            Guardar
        </Button>
        :
        <Button
            mr={2}
            variant="contained"
            disabled
        >
            Guardar
        </Button>
        }
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500], marginRight: "10px"}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`En ruta`}
            _content_={_content_}
            _actions_ = {_actions_}
        />
  );
}