import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    Typography
} from '@material-ui/core';
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function InfoPanelTelemedicina({
  dateEvent,
  patient,
  specialist,
  worker,
}) {
  const classes = useStyles();

  return (
    <Paper
      style={{
        width: "100%",
        margin: "1px 1px 15px 1px"
      }}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          item
          xs={6}
          style={{ padding: "10px 15px" }}
        >
          <Typography variant="body2" align="left" gutterBottom>
            {`Paciente: ${patient?.nombre} ${patient?.apellido_paterno} ${patient?.apellido_materno}`}
          </Typography>
          <Typography variant="body2" align="left" gutterBottom>
            {`Medico: ${specialist.medico_nombre_completo}`}
          </Typography>
          <Typography variant="body2" align="left" gutterBottom>
            {`Especialidad: ${specialist.medico_especialidad}`}
          </Typography>
          <Typography variant="body2" align="left" gutterBottom>
            {`Fecha: ${moment(dateEvent).format("YYYY-MM-DD HH:mm")}`}
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          item
          xs={6}
          style={{ padding: "10px 15px" }}
        >
          {worker.map((row) => ( 
            <Typography variant="body2" align="left" gutterBottom>
              {`${row.nombre_cargo}: ${row.first_name} ${row.last_name}`}
            </Typography>))
          }
       
        </Grid>
      
       
     </Grid>
    </Paper>
  );
}
