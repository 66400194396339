import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import SortIcon from "@material-ui/icons/Sort";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment-timezone";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableInProcessOutcome = props => {
  const classes = useStyles();
  const {
    searchInProcess,
    requestSortInProcess,
    getClassNamesForInProcess,
    requestTypeInProcess,
    setRequestTypeInProcess,
    pickingStatusInProcess,
    setPickingStatusInProcess,
    regionInProcess,
    setRegionInProcess,
    supplyInProcess,
    setSupplyInProcess,
    moduleType,
    months_open,
    days_open,
    setOpenModalMessage,
    handleOpenModalTransport
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "50px" }}>
                Id 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortInProcess("id")}
                  className={getClassNamesForInProcess("id")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "50px" }}>
                Envío 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortInProcess("numero_pedido")}
                  className={getClassNamesForInProcess("numero_pedido")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "80px" }}>
                Tipo de solicitud 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={requestTypeInProcess}
                  onChange={e =>
                    setRequestTypeInProcess(e.target.value.substr(0, 20))
                  }
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"programado"}>Programado</MenuItem>
                  <MenuItem value={"urgente"}>Urgente</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "110px" }}>
                Fecha a despachar 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortInProcess("fecha_despacho")}
                  className={getClassNamesForInProcess("fecha_despacho")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Paciente 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortInProcess("nombre_paciente")}
                  className={getClassNamesForInProcess("nombre_paciente")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "50px" }}>
                Región 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={regionInProcess}
                  onChange={e =>
                    setRegionInProcess(e.target.value.substr(0, 20))
                  }
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"si"}>Si</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Medicamento 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={supplyInProcess}
                  onChange={e =>
                    setSupplyInProcess(e.target.value.substr(0, 20))
                  }
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"si"}>Si</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Días de atraso
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Estado caja 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={pickingStatusInProcess}
                  onChange={e =>
                    setPickingStatusInProcess(e.target.value.substr(0, 20))
                  }
                  label="Age"
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"Pendiente"}>Pendiente</MenuItem>
                  <MenuItem value={"Completa"}>Completa</MenuItem>
                  <MenuItem value={"Incompleta"}>Incompleta</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchInProcess
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">
                    {row.id_solicitud_pedido.id}
                  </TableCell>
                  <TableCell align="center">{row.numero_pedido}</TableCell>
                  <TableCell align="center">
                    {row.id_solicitud_pedido.tipo_solicitud}
                  </TableCell>
                  <TableCell align="center">
                    {moment(
                      row.id_solicitud_pedido.fecha_tentativa_llegada
                    ).format("YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell align="center">
                    {row.id_solicitud_pedido.nombre_paciente}
                  </TableCell>
                  <TableCell align="center">
                    {row.id_solicitud_pedido.es_region ? "Si" : "No"}
                  </TableCell>
                  <TableCell align="center">
                    {row.id_solicitud_pedido.tiene_medicamentos ? "Si" : "No"}
                  </TableCell>
                  <TableCell align="center">
                    Meses: {months_open(row.fecha_tentativa_llegada)}
                     /  Días:
                    {days_open(row.fecha_tentativa_llegada)}
                  </TableCell>
                  <TableCell align="center">
                    {row.estado_egreso === "Esperar a egresos"
                      ? "Ir a retirar caja"
                      : row.estado_egreso}
                  </TableCell>
                  <TableCell align="center">
                    {row.estado_egreso === "Adjuntar guía" ? (
                      <Link
                        to={`/admin/outcome/addfile/${moduleType}/${row.id}`}
                      >
                        <Button
                          style={{ textTransform: "none" }}
                          variant="contained"
                          color="primary"
                        >
                          Detalle
                        </Button>
                      </Link>
                    ) : row.estado_egreso ===
                      "Aprobado por supervisora. Adjuntar guía de bodega" ? (
                      <Link
                        to={`/admin/outcome/addfile/${moduleType}/${row.id}`}
                      >
                        <Button
                          style={{ textTransform: "none" }}
                          variant="contained"
                          color="primary"
                        >
                          Detalle
                        </Button>
                      </Link>
                    ) : row.estado_egreso === "Notificar a transporte" &&
                      row.estado_egreso === "HabilitacionDomicilio" ? (
                      row.estado_bodega === "Finalizado" ? (
                        <Link
                          to={`/admin/outcome/companytransport/${moduleType}/${row.id}`}
                        >
                          <Button
                            style={{ textTransform: "none" }}
                            variant="contained"
                            color="primary"
                          >
                            Detalle
                          </Button>
                        </Link>
                      ) : (
                        <Button
                          style={{ textTransform: "none" }}
                          onClick={() => setOpenModalMessage(true)}
                          variant="contained"
                          color="primary"
                        >
                          Detalle
                        </Button>
                      )
                    ) : row.estado_egreso === "Notificar a transporte" ? (
                      row.estado_bodega === "Finalizado" ? (
                        <Button
                          style={{ textTransform: "none" }}
                          onClick={() => handleOpenModalTransport(true, row.id)}
                          variant="contained"
                          color="primary"
                        >
                          Detalle
                        </Button>
                      ) : (
                        <Button
                          style={{ textTransform: "none" }}
                          onClick={() => setOpenModalMessage(true)}
                          variant="contained"
                          color="primary"
                        >
                          Detalle
                        </Button>
                      )
                    ) : row.estado_egreso === "Gestionar transporte" &&
                      row.estado_egreso === "HabilitacionDomicilio" ? (
                      <Link
                        to={`/admin/outcome/companytransport/${moduleType}/${row.id}`}
                      >
                        <Button
                          style={{ textTransform: "none" }}
                          variant="contained"
                          color="primary"
                        >
                          Detalle
                        </Button>
                      </Link>
                    ) : row.estado_egreso === "Gestionar transporte" ? (
                      <Button
                        style={{ textTransform: "none" }}
                        onClick={() => openModalTransport(true, row.id)}
                        variant="contained"
                        color="primary"
                      >
                        Detalle
                      </Button>
                    ) : (
                      <Link
                        to={`/admin/outcome/reception/${moduleType}/${row.id}`}
                      >
                        <Button
                          style={{ textTransform: "none" }}
                          variant="contained"
                          color="primary"
                        >
                          Detalle
                        </Button>
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={searchInProcess.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableInProcessOutcome;

TableInProcessOutcome.propTypes = {
  searchInProcess: PropTypes.array,
  requestSortInProcess: PropTypes.func,
  getClassNamesForInProcess: PropTypes.func,
  requestTypeInProcess: PropTypes.string,
  setRequestTypeInProcess: PropTypes.func,
  pickingStatusInProcess: PropTypes.string,
  setPickingStatusInProcess: PropTypes.func,
  regionInProcess: PropTypes.string,
  setRegionInProcess: PropTypes.func,
  supplyInProcess: PropTypes.string,
  setSupplyInProcess: PropTypes.func,
  moduleType: PropTypes.string,
  months_open: PropTypes.func,
  days_open: PropTypes.func,
  setOpenModalMessage: PropTypes.func,
  handleOpenModalTransport: PropTypes.func
};
