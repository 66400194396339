import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
} from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import {ArrowForward,} from "@material-ui/icons/";
import {classStyle} from "utils/styleFunction";
import moment from 'moment-timezone';
import ModalDialog from "views/Widgets/Common/ModalDialog";
import 'moment/locale/es' ;
moment.locale("es");

export default function ModalEditPackageItemAmount({
    open,
    handleClose,
    infoRowPackage,
    handleSave
}) {
    const classes = classStyle();
    const [tempRowPackage, settempRowPackage] = useState(infoRowPackage);

    const handleOnChange = (value, index) => {
        tempRowPackage[index].tempValue = value
        settempRowPackage([...tempRowPackage]);
    }

    const _content_ = <div className="form-row">
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            {infoRowPackage.map((row, index) => (
            <>
                <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      item
                      xs={12}
                >
                    <Typography variant="body1">
                        {row.label}
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={5}
                        style={{margin: "10px 0"}}
                    >
                        <DetailNumber
                            isdisabled={true}
                            label={`Valor original`}
                            value={row.value}
                            prefix={"$"}
                        />

                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={2}
                        style={{margin: "10px 0"}}
                    >
                        <Button
                            justIcon
                            round
                            color="primary"
                        >
                            <ArrowForward className={classes.icons} />
                        </Button>

                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={5}
                        style={{margin: "10px 0"}}
                    >
                        <DetailNumber
                            label={`Valor modificado`}
                            value={row.tempValue}
                            onchange={e => handleOnChange(
                                parseInt(e.target.value.split('$').join('').split(',').join('')),
                                index)
                            }
                            prefix={"$"}
                            thousandSeparator={true}
                        />
                    </Grid>
                </Grid>
            </>
          ))}
        </Grid>
  </div>

    const _actions_ = <>
        <Button
            color="primary"
            variant="contained"
            onClick={handleClose}
        >
            Cerrar
        </Button>
        <Button
            color="primary"
            variant="contained"
            onClick={() => handleSave(tempRowPackage)}
        >
            Agregar
        </Button>
    </>


    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Editar monto paquete`}
            _content_={_content_}
            _actions_ = {_actions_}
        />
    );
};

