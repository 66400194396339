import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";



export default function ModalCancelReplace(props) {

  const { open, closeModal, id_producto, id_detalle, moduleType, infoProduct } = props;
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {


  }, []);

  const scroll = "paper";

  function updateProduct() {

    apiform.delete(`api/bodega/reemplazo/producto/${id_producto}/cancelar/`).then((response) => {
      window.location.href = `/admin/warehouse/order/${moduleType}/${id_detalle}`;
    })
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Descripción solicitud</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "10rem" }}>
          Cancelar la solicitud de reemplazo? <br />
          <div className="mt-3 mb-3">
              <ul className="list-group">
                <li className="list-group-item">
                  <span className="font-weight-bold">SKU: </span>
                  {infoProduct?.id_insumo_medicamento?.SKU}
                </li>
                <li className="list-group-item">
                  <span className="font-weight-bold">Grupo: </span>
                  {infoProduct?.id_insumo_medicamento?.grupo_producto}
                </li>
                <li className="list-group-item">
                  <span className="font-weight-bold">Descripción: </span>
                  {infoProduct?.id_insumo_medicamento?.descripcion_producto}
                </li>
              </ul>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Ok
        </Button>
        <Button onClick={updateProduct} color="primary">
          Cancelar solicitud
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCancelReplace.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number,
  order: PropTypes.array,
  getProductsOther: PropTypes.array,
};
