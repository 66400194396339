import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid,
    Typography,
    TextField,
    Button,
} from '@material-ui/core';
import api from "utils/API";
import NewItemTableComponent from "../../../Widgets/OrdeerDashboard/NewItemTableComponent";
import Indicator from "views/Widgets/OrdeerDashboard/Indicator";
import { green, red } from "@material-ui/core/colors";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { setmessageGeneral } from "actions/getGlobalAction";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import allActions from "actions";

//import EditInsumos from "./EditInsumos";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const DialogQuickNewOrder = props => {
    const {
        closeModal,
        patientId,
        openModal,
        getPatientInfo,
        setOpenPatientRegistration,
        selectOrderPatient,
        listaPrestaciones,
    } = props;
    const [firstCharge, setFirstCharge] = useState(true);
    const dispatch = useDispatch()

    const [dataMedicalArray, setDataMedicalArray] = useState([]);
    const [dataResourceArray, setDataResourceArray] = useState([]);
    const [dictItemPackage, setdictItemPackage] = useState({});
    const [pageRefresh, setPageRefresh] = useState(false);
    const [listMedical, setlistMedical] = useState([]);
    const [listResource, setlistResource] = useState([]);
    const [flagCalculate, setFlagCalculate] = useState(false);
    const [flagButton, setFlagButton] = useState(false);
    const [openModalWarning, setopenModalWarning] = useState(false);
    const [datePay, setDatePay] = useState(null);
    const [dateSend, setDateSend] = useState(moment());
    const [openModalDateSend, setopenModalDateSend] = useState(false);
    const [observation, setobservation] = useState("");
    const limitObservation = 400


    const valueUpdate = useSelector(state => state.currentGlobalVar.ValueUpdate)
    const medicalResourseBase = useSelector(state => state.currentGlobalVar.TotalValueMonthConsume)
    const listFrecuency = useSelector(state => state.currentList.LISTA_TIPO_FRECUENCIA_PRESTACION);
    const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
    const listMedicalBeta = useSelector(state => state.currentList.listMedicines);
    const listResourceBeta = useSelector(state => state.currentList.listSupplies);
    const viewQuotation = useSelector(state => state.currentGlobalVar.patientPackage);
    const dictDiscountQuotation = useSelector(state => state.currentList.dictDiscountQuotation);
    const quotationDiscount = useSelector(state => state.currentGlobalVar.quotationDiscount);
    const typeQuotationDiscount = useSelector(state => state.currentGlobalVar.typeQuotationDiscount);
    const tempType = "_insumos_medicamentos";

    const checkOrder = selectOrderPatient?.id

    const validateAmountMedicalResourse = (
        ["ParticularServicios", "ParticularHD"].includes(viewQuotation?.tipo_paciente) ||
        ["ParticularServicios", "ParticularHD"].includes(viewQuotation?.typePatient) ||
        viewQuotation?.tipo_permanencia == "Transitorio" ||
        viewQuotation?.typeStay == "Transitorio" ||
        // viewQuotation?.id_convenio_isapre?.nombre == "Fondo Nacional de Salud" ||
        // viewQuotation?.forecast[0]?.nombre == "Fondo Nacional de Salud" ||
        (viewQuotation?.packagingInfo?.insumos == 0 && viewQuotation?.packagingInfo?.medicamentos == 0)
    )

    useEffect(() => {
        if (checkOrder){
            handleChangeDate(selectOrderPatient.fecha_cobro);
            chargeData();
        } else {
            handleChangeDate(moment());
        }
    }, [])

    useEffect(() => {
        if (listFrecuency.length > 0 && listMedicalBeta.length && Object.keys(medicalResourceData).length){
            getItemList()
        }


    }, [listMedicalBeta, listResourceBeta, medicalResourceData])

    useEffect(() => {
        if (!DialogContent) {

        }
        if (firstCharge && listFrecuency.length > 0 && listMedicalBeta.length && Object.keys(medicalResourceData).length) {
            initialCharge();
        }
        if (pageRefresh) {
            setPageRefresh(false);
        }
        if(flagCalculate){
            calculate();
        }
        const tempFlag = validateAmountMedicalResourse ? parseInt(medicalResourseBase) + parseInt(valueUpdate) > 0 : medicalResourseTotal - (parseInt(medicalResourseBase) + parseInt(valueUpdate)) > 0
        setFlagButton(tempFlag)
    });

    const chargeData = () => {
        const tempDataMedicine = [];
        const tempDataResource = [];
        const tempDictItemPackage = listaPrestaciones.reduce((acc, item) => ({ ...acc, [item.id]: {...item}}), {})
        setdictItemPackage(tempDictItemPackage)
        Object.values(selectOrderPatient.items).filter((item) => medicalResourceData[item.id_insumo_medicamento_id] ).map((item) => {
            const itemInfo = medicalResourceData[item.id_insumo_medicamento_id]
            const tempItem = {
                cantidad: item.cantidad_envio,
                item: itemInfo.descripcion_producto,
                sku: itemInfo.SKU,
                valor: itemInfo?.valor_con_iva || 0,
                id: item.id_insumo_medicamento_id,
                id_prestacion_paciente_id: item.id_prestacion_paciente_id,
                fuera_paquete: tempDictItemPackage[item.id_prestacion_paciente_id].fuera_paquete
            }
            if (itemInfo?.grupo_producto == "Medicamentos") {
                tempDataMedicine.push(tempItem)
            } else {
                tempDataResource.push(tempItem)
            }
        });
        setDataMedicalArray(tempDataMedicine);
        setDataResourceArray(tempDataResource);
        setDateSend(moment(selectOrderPatient.fecha_envio));
        setFlagCalculate(true);
    }

    const calculateIndividualDiscount = (amount, value, discount, typeDiscount) => {
        if (!value){
          return 0;
        }
        const total = amount*value;
        const totalDiscount = typeDiscount === "Descuento" ? discount*-1 : discount;
        return parseInt(totalDiscount == 0 ? total : total + (total*(totalDiscount/100)));
      };

    const calculate = () => {
        setFlagCalculate(false);
        let termValue = 0
        if(dataMedicalArray){
            dataMedicalArray.map(item =>{
                if (dictItemPackage[item.id_prestacion_paciente_id]?.fuera_paquete !== true){
                    const tempDiscount = dictDiscountQuotation[`${item.id_insumo_medicamento}_insumos_medicamentos_false`]
                    if(tempDiscount){
                        termValue += calculateIndividualDiscount(
                        parseInt(item.cantidad || 0),
                        item.valor,
                        tempDiscount.porcentaje_item,
                        tempDiscount.tipo_porcentaje
                    )
                    } else {
                        termValue += item.valor * item.cantidad
                    }
                }
            })
        }
        if(dataResourceArray){
            dataResourceArray.map(item =>{
                if (dictItemPackage[item.id_prestacion_paciente_id]?.fuera_paquete !== true){
                    const tempDiscount = dictDiscountQuotation[`${item.id_insumo_medicamento}_insumos_medicamentos_false`]
                    if(tempDiscount){
                        termValue += calculateIndividualDiscount(
                        parseInt(item.cantidad || 0),
                        item.valor,
                        tempDiscount.porcentaje_item,
                        tempDiscount.tipo_porcentaje
                    )
                    } else {
                        termValue += item.valor * item.cantidad
                    }
                }
            })
        }
        dispatch({ payload: termValue, type: 'VALOR_ACTUALIZADO' })

    }

    const getItemList = () => {
        const auxMedicalList = [];
        const auxResourseList = [];
        const auxDictItem = [];
        listMedicalBeta.map((row) => {
            const medicalRow = medicalResourceData[row.value]
            if (medicalRow.valor_con_iva > 0) {
                auxMedicalList.push({
                    value: row.value,
                    label: row.label,
                    unitValue: parseInt(medicalRow.valor_con_iva)
                });
                auxDictItem[medicalRow.id] = medicalRow;
            }
        });
        listResourceBeta.map((row) => {
            const resourceRow = medicalResourceData[row.value]
            if (resourceRow.valor_con_iva > 0) {
                auxResourseList.push({
                    value: row.value,
                    label: row.label,
                    unitValue: parseInt(resourceRow.valor_con_iva)
                });
                auxDictItem[resourceRow.id] = resourceRow;
            }
        });
        setlistMedical(auxMedicalList);
        setlistResource(auxResourseList);

    };

    const initialCharge = () => {
        setFirstCharge(false)
        dispatch({ payload: 0, type: 'VALOR_ACTUALIZADO' })
        getItemList();
        console.log("check")
    };

    const handleChangeDate = (value) => {
        setDatePay(value);

        api.get(
            `/api/paciente/pedidosprogramados/prestacionespaquete/total/paciente/${patientId}/?fecha_pedido=${moment(value).format("YYYY-MM-DD")}`
        ).then(response => {
            const total_value = response.data.valor_solicitudes
            const total_package = response.data.valor_solicitudes
            dispatch({ payload: total_value, type: 'VALOR_CONSUMIDO_MES' })
            dispatch({ payload: total_package, type: 'VALOR_CONSUMIDO_PAQUETE' })
            const message = "Los valores del paciente han sido actualizados al periodo seleccionado"
            setmessageGeneral(dispatch, message, "success", "Actualización periodo completada");

        });
    }

    const medicalResourseTotal = viewQuotation
        ? viewQuotation.packagingInfo.insumos + viewQuotation.packagingInfo.medicamentos
        : 0;

    const getItems = () => {

        let bodyBulk = {
            "id": patientId,
            "date_send": moment(dateSend).format("YYYY-MM-DD"),
            "date_pay": moment(datePay).format("YYYY-MM-DD"),
            "medical": dataMedicalArray,
            "resource": dataResourceArray,
            "tipo_pedido": checkOrder ? "Programado" : "ProgramadoRapido",
            "fecha_pedido_repetido": (checkOrder) ? moment(dateSend).add(1, "month").format("YYYY-MM-DD") : null,
            "id_pedido_programado": selectOrderPatient?.id || null,
            "observacion": observation,

        }
        api.post(`/api/paciente/pedidosprogramadosrapido/bulk/`, bodyBulk).then((response) => {
            const idOrder = response.data.id
            const message = checkOrder ? `El pedido programado fue enviado a bodega con la id: ${idOrder}` : `El pedido rápido fue creado con éxito con la id: ${idOrder}`
            const title = checkOrder ? "Pedido programado" : "Pedido rápido creado"

            setmessageGeneral(dispatch, message, "success", title)
            setOpenPatientRegistration(false);
            handleClose();
            getPatientInfo(patientId);
            setopenModalWarning(false);
        })
    }

    const handleClose = () => {
        dispatch({ payload: 0, type: 'VALOR_ACTUALIZADO' })
        closeModal();
    }

    const DialogContent = <Grid item xs={12}>
        <Grid item container xs={12} justify="left">
            <Grid item container xs={4}>
                <Typography variant={'h6'} style={{ paddingTop: '15px' }}>
                    {!checkOrder ? "Selecciona fecha de cobro" : "Periodo de cobro"}
                </Typography>
            </Grid>
            <Grid item container xs={3} >
                {!checkOrder ?
                <MuiPickersUtilsProvider locale="es" utils={MomentUtils} >
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        margin="normal"
                        id="date-picker-inline1-1"
                        value={datePay}
                        openTo="year"
                        views={["year", "month"]}
                        onChange={(e) => handleChangeDate(e)}
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                        autoOk={true}
                    />
                </MuiPickersUtilsProvider>
                : <Typography variant={'h6'} style={{ paddingTop: '15px' }}>
                    {moment(datePay).format("MMMM YYYY")}
                </Typography>
                }
            </Grid>
            <Grid item container xs={5}></Grid>
        </Grid>
        {checkOrder &&
        <Grid item container xs={12} justify="left">
            <Grid item container xs={4}>
                <Typography variant={'h6'}>
                   Fecha de envío
                </Typography>
            </Grid>
            <Grid item container xs={3} >
                <Typography variant={'h6'}>
                    {moment(dateSend).format("YYYY-MM-DD")}
                </Typography>
            </Grid>
            <Grid item container xs={5}></Grid>
        </Grid>
        }
        <Grid item container xs={12} >
            <Indicator />
        </Grid>
        <Grid item container xs={12}>
            <Grid container justify="center">
                {pageRefresh && <div></div>}
                <Grid item xs={12}>
                    <NewItemTableComponent
                        dataItemArray={dataMedicalArray}
                        setDataItemArray={setDataMedicalArray}
                        ItemData={medicalResourceData}
                        listItem={listMedical}
                        setPageRefresh={setPageRefresh}
                        setFlagCalculate={setFlagCalculate}
                        dictDiscountQuotation={dictDiscountQuotation}
                        quotationDiscount={quotationDiscount}
                        typeQuotationDiscount={typeQuotationDiscount}
                        tempType={tempType}
                        typeTable={"MEDICAMENTO"}
                        order={checkOrder}
                    />
                </Grid>
                <Grid item xs={12}>
                    <NewItemTableComponent
                        dataItemArray={dataResourceArray}
                        setDataItemArray={setDataResourceArray}
                        ItemData={medicalResourceData}
                        listItem={listResource}
                        setPageRefresh={setPageRefresh}
                        setFlagCalculate={setFlagCalculate}
                        dictDiscountQuotation={dictDiscountQuotation}
                        quotationDiscount={quotationDiscount}
                        typeQuotationDiscount={typeQuotationDiscount}
                        tempType={tempType}
                        typeTable={"INSUMO"}
                        order={checkOrder}
                    />
                </Grid>

            </Grid>
        </Grid>
    </Grid >

    const _dialogAction_  = <>
        <Button onClick={handleClose} style={{ width: '224px', height: '42px', background: red[500], color: '#FFFFFF' }}>
            CANCELAR
        </Button>
        { ((dataMedicalArray.length > 0 || dataResourceArray.length > 0) && flagButton) ?
        selectOrderPatient?.estado === "EnBodega" ?
        <Button
            disabled
            variant={"contained"}
            style={{
                width: "224px",
                height: "42px",
            }}>
            Ya fue enviado el pedido
        </Button> :
        <Button variant="contained"
            style={{
                width: "224px",
                height: "42px",
                background: green[500],
                color: '#FFFFFF'
            }}
            onClick={() => {
                setopenModalWarning(true)
            }}
        >
            { checkOrder ? "ENVIAR PEDIDO": `CREAR PEDIDO`}
        </Button> :
        <Button disabled variant="contained" color="primary" style={{ width: "224px", height: "42px", background: '#dddddd', color: '#FFFFFF' }}>
            CREAR PEDIDO
        </Button>
        }
    </>

    const _contentWarning_ =  <Grid item container xs={12}>
        <Typography>
            ¿Está seguro que el pedido/cotización está correcto?
        </Typography>
    </Grid>


    const _actionWarning_ = <>
        <Button variant={"outlined"} style={{ color: green[900], width: "150px", heigth: "36px" }} onClick={() => {
            setopenModalWarning(false);
            setopenModalDateSend(true);
        }}>
            ENVIAR PEDIDO
        </Button>
        <Button variant={"outlined"} style={{ color: red[900], width: "125px", heigth: "36px" }} onClick={() => {setopenModalWarning(false)}}>
            CANCELAR
        </Button>
    </>

    const _sendBodegaContext_ =  <Grid item container xs={12}>
        <Grid item container xs={5}>
            <Typography variant={"body2"} style={{ marginTop: "22px" }}>
                Seleccionar fecha de despacho
            </Typography>
        </Grid>
        <Grid item container xs={1}></Grid>
        <Grid item container xs={6}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    id="date-picker-inline1-1"
                    value={dateSend}
                    minDate={moment().subtract(1, "day")}
                    minDateMessage={"La fecha de envío no puede ser anterior al día de hoy"}
                    cancelLabel={"Cancelar"}
                    okLabel={"Aceptar"}
                    onChange={(e) => setDateSend(e)}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    autoOk={true}
                />
            </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
            <TextField
                label={"Observaciones"}
                multiline
                rows={8}
                value={observation}
                variant="outlined"
                onChange={(event) => setobservation(event.target.value)}
                style={{width: "100%"}}
            />
        </Grid>
            <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
                {`${observation?.length || 0 }/${limitObservation}`}
            </Typography>
        </Grid>

        const _sendBodegaAction_ = <>
        <Button aria-label="close" variant={"outlined"} style={{ color: green[500], width: "150px", heigth: "36px" }} onClick={getItems}>ENVIAR PEDIDO </Button>
        <Button aria-label="close" variant={"outlined"} style={{ color: red[900], width: "125px", heigth: "36px" }} onClick={() => setopenModalDateSend(false)}>CANCELAR </Button>
        </>

    return (
        <>
            <ModalDialog
                open={openModal}
                onClose={handleClose}
                title={checkOrder ? "ENVIAR PEDIDO A BODEGA" :  "CREAR PEDIDO RAPIDO"}
                _content_={DialogContent}
                _actions_ = {_dialogAction_}
                maxWidth={"xl"}
            />
            <ModalDialog
                open={openModalWarning}
                onClose={() => setopenModalWarning(false)}
                title={"ADVERTENCIA"}
                _content_={_contentWarning_}
                _actions_ = {_actionWarning_}
                maxWidth={"xs"}
            />
            <ModalDialog
                open={openModalDateSend}
                onClose={() => setopenModalDateSend(false)}
                title={"ENVIAR PEDIDO A BODEGA"}
                _content_={_sendBodegaContext_}
                _actions_ = {_sendBodegaAction_}
                maxWidth={"xs"}
            />
        </>
        );
};

export default DialogQuickNewOrder;
