import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Backdrop,
  Paper,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import { red, green } from "@material-ui/core/colors";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
}));

export default function ModalRetirementGuide({
  open,
  handleClose,
  retirementGuide,
  rowsActive,
  setrowsActive,
  rowsFinished,
  serowsFinished,
  setmessage,
  infoRequest,
  patientDict,
}) {
  const classes = useStyles();
  const [tempRow, settempRow] = useState(retirementGuide[0]);
  const [exam, setexam] = useState(
    tempRow["archivo_recepcion"] ? tempRow["archivo_recepcion"] : []
  );
  const [observation, setobservation] = useState(
    tempRow["observacion_recepcion"] ? tempRow["observacion_recepcion"] : ""
  );
  const [patient, setpatient] = useState(patientDict[tempRow.id_paciente]);

  const dictSTValueLabel = useSelector(state => state.currentList.DICCIONARIO_ESTADOS_TRANSPORTES);

  const handleSaveSend = () => {
    const body = {};
    if (tempRow.estado_recepcion === "GuiaEntregaRetrasado") {
      body["estado_recepcion"] = "Retrasado";
    } else {
      body["estado_recepcion"] = "Retirado";
    }
    const requestArray = [];
    requestArray.push(
      api.patch(`/api/agendatransportes/${retirementGuide[0].id}`, body)
    );
    if (retirementGuide[0].id_envios_pedidos) {
      const body2 = {
        caja: retirementGuide[0].id_envios_pedidos.id,
      };
      const formData = new FormData();
      formData.append("nombre", exam[0].name);
      formData.append("archivo", exam[0]);
      formData.append("tipo_archivo", "GuiaDespacho");
      formData.append("observacion", observation);
      formData.append("area", "Transporte");
      formData.append("id_pedidos", retirementGuide[0].id_envios_pedidos.id_solicitud_pedido);
      requestArray.push(
        api.post(`/api/transporte/solicitudes/finalizar/`, body2)
      );
      requestArray.push(
        api.post('/api/documentospedidos/', formData),
      );
    }
    Promise.all(requestArray).then((request) => {
      const tempRows = rowsActive.filter(
        (row) => retirementGuide[0].id !== row.id
      );
      const tempRowsEnds = rowsFinished;
      const temprow = retirementGuide[0];
      temprow["estado_recepcion"] = body["estado_recepcion"];
      tempRowsEnds.push(temprow);
      setrowsActive(tempRows);
      serowsFinished(tempRowsEnds);
      setmessage("Guia de entrega adjuntada");
      handleClose();
    });
  };

  const handleSave = () => {
    const auxRow = tempRow;
    const auxRows = rowsActive;
    auxRow["archivo_recepcion"] = exam;
    auxRow["observacion_recepcion"] = observation;
    auxRows[retirementGuide[1]] = auxRow;
    setrowsActive(auxRows);
    setmessage("Datos guardados");
    handleClose();
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      style={{
        borderRadius: "4px",
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper
        className={classes.paper}
        style={{
          maxHeight: "900px",
          overflow: "auto",
        }}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          item
          xs={12}
        >
          <h3 id="spring-modal-title">{`Adjuntar guía de retiro`}</h3>

          <h5 id="spring-modal-title">{`Datos del retiro`}</h5>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            item
            xs={12}
            style={{ margin: "10px 0 10x 0" }}
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              item
              xs={3}
              style={{ padding: "5px" }}
            >
              <Typography variant="caption" display="block" gutterBottom>
                {tempRow.id_parada_traslado_ingreso && ( tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudRetiroEquipo" || tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudEntregaRetiroEquipo" )  ? `Empresa: ${tempRow.id_parada_traslado_ingreso.nombre_empresa}`
                  : tempRow.id_parada_traslado_ingreso && tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudRetiroCompra" ?
                    `Paciente: ${tempRow.id_parada_traslado_ingreso.nombre_empresa}` :`Paciente: ${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {tempRow.id_parada_traslado_ingreso
                  ? tempRow.id_parada_traslado_ingreso.tipo_parada === "Inicio"
                    ? `Dirección: ${tempRow.id_parada_traslado_ingreso.direccion_calle} ${tempRow.id_parada_traslado_ingreso.direccion_numero} ${tempRow.id_parada_traslado_ingreso.direccion_oficina}`
                    : tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso
                        .tipo_producto === "Insumo" ||
                      tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso
                        .tipo_producto === "Equipo"
                    ? `Dirección: Bodega Ingreso`
                    : `Dirección: Bodega Farmacia`
                  : `Dirección: ${patient.direccion_calle} ${patient.direccion_numero} casa/dpto ${patient.direccion_casa_depto}`}
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              item
              xs={3}
              style={{ padding: "5px" }}
            >
              <Typography variant="caption" display="block" gutterBottom>
                {tempRow.id_parada_traslado_ingreso
                  ? `Comuna: ${tempRow.id_parada_traslado_ingreso.direccion_comuna}`
                  : `Comuna: ${patient.direccion_comuna}`}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {tempRow.id_parada_traslado_ingreso
                  ? `Región: ${tempRow.id_parada_traslado_ingreso.direccion_region}`
                  : `Región: ${patient.direccion_region}`}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {tempRow.id_parada_traslado_ingreso
                  ? `Zona: -`
                  : `Zona: ${tempRow.zona}`}
              </Typography>
            </Grid>

            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              item
              xs={3}
              style={{ padding: "5px" }}
            >
              <Typography variant="caption" display="block" gutterBottom>
                {`Fecha solicitud:`}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {`${
                  tempRow.fecha_solicitud
                    ? moment(tempRow.fecha_solicitud).format("YYYY-MM-DD HH:mm")
                    : moment(tempRow.id_parada_traslado.fecha_hora).format(
                        "YYYY-MM-DD HH:mm"
                      )
                }`}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {tempRow.hora_estimada ?
                `Fecha a traslado:`:
                `Fecha a despachar:`
                }
                {}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {`${ tempRow.id_envios_pedidos ?
                  moment(tempRow.id_envios_pedidos.fecha_tentativa_llegada).format(
                    "YYYY-MM-DD HH:mm"
                  ) : tempRow.fecha_tentativa_llegada?
                    moment(tempRow.fecha_tentativa_llegada).format(
                    "YYYY-MM-DD HH:mm"
                    ) :  moment(tempRow.hora_estimada).format(
                    "YYYY-MM-DD HH:mm"
                    )

                }`}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {`Fecha estimada:`}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {`${
                  tempRow.hora_estimada
                    ? moment(tempRow.hora_estimada).format("DD-MM-YYYY HH:mm")
                    : "-"
                }`}
              </Typography>
              {(tempRow.estado_recepcion === "Entregado" ||
                tempRow.estado_recepcion === "Retrasado") && (
                <Typography variant="caption" display="block" gutterBottom>
                  {`Fecha entrega:`}
                </Typography>
              )}
              {(tempRow.estado_recepcion === "Entregado" ||
                tempRow.estado_recepcion === "Retrasado") && (
                <Typography variant="caption" display="block" gutterBottom>
                  {`${
                    tempRow.hora_llegada
                      ? moment(tempRow.hora_llegada).format("YYYY-MM-DD HH:mm")
                      : "-"
                  }`}
                </Typography>
              )}
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              item
              xs={3}
              style={{ padding: "5px" }}
            >
              <Typography variant="caption" display="block" gutterBottom>
                {tempRow.id_envios_pedidos
                  ? `Solicitud pedido: ${tempRow.id_envios_pedidos.id_solicitud_pedido}`
                  : tempRow.id_parada_traslado
                  ? `Solicitud paciente: ${tempRow.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}`
                  : tempRow.id_parada_traslado_ingreso
                  ? `Solicitud ingreso: ${tempRow?.id_parada_traslado_ingreso?.id_solicitud_ingreso.id}`
                  : " "}
              </Typography>
              {tempRow.id_envios_pedidos && (
                <Typography variant="caption" display="block" gutterBottom>
                  {tempRow.id_parada_traslado_ingreso
                    ? `Caja: 1`
                    : `Caja: ${
                        tempRow.id_envios_pedidos
                          ? tempRow.id_envios_pedidos.numero_pedido
                          : "-"}`}
                </Typography>
              )}
              <Typography variant="caption" display="block" gutterBottom>
                {`Tipo de solicitud: ${tempRow.tipo_solicitud}`}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {`Estado pedido: ${dictSTValueLabel[tempRow.estado_recepcion]}`}
              </Typography>
              {tempRow.id_envios_pedidos && (
                <Typography variant="caption" display="block" gutterBottom>
                  {`Medicamento: ${
                    tempRow?.id_parada_traslado_ingreso?.id_solicitud_ingreso
                      .tipo_producto === "Medicamento" ||
                    tempRow?.id_parada_traslado_ingreso?.id_solicitud_ingreso
                      .tipo_producto === "InsumoMedicamento" ||
                    tempRow?.id_envios_pedidos?.es_farmacia === true
                      ? "Sí"
                      : "No"
                  }`}
                </Typography>
              )}
              {infoRequest.length > 0 && (
                <Typography variant="caption" display="block" gutterBottom>
                  {`Estado picking: ${
                    infoRequest[0].id_envio_pedido
                      ? infoRequest[0].id_envio_pedido.id_solicitud_pedido
                          .estado_picking
                      : "-"
                  }`}
                </Typography>
              )}
            </Grid>
          </Grid>
          <h5 id="spring-modal-title">{`Adjuntar guía de retiro`}</h5>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{ margin: "10px 0 10x 0" }}
          >
            <DetailText
              label={`Observación`}
              value={observation}
              onchange={(e) => {
                setobservation(e.target.value);
              }}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={8}
            style={{ margin: "10px 0 30px 0" }}
          >
            <Button
              variant="contained"
              component="label"
              color="primary"
              className="text-white"
              style={{ margin: "0 10px" }}
            >
              Subir Archivo
              <TextField
                type="file"
                id="exam"
                onChange={(event) => setexam(event.target.files)}
                fullWidth
                my={2}
                style={{ display: "none" }}
              />
            </Button>
            {exam.length > 0 && `${exam[0].name}`}
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
            item
            xs={12}
            style={{ margin: "10px 0 0 0" }}
          >
            {exam.length > 0 && observation ? (
              <Button
                mr={2}
                variant="contained"
                className="text-white"
                color="primary"
                onClick={handleSaveSend}
              >
                Guardar y enviar
              </Button>
            ) : (
              <Button mr={2} variant="contained" disabled>
                Guardar y enviar
              </Button>
            )}

            {exam.length > 0 && observation ? (
              <Button
                mr={2}
                variant="contained"
                className="text-white"
                color="primary"
                onClick={handleSave}
              >
                Guardar
              </Button>
            ) : (
              <Button mr={2} variant="contained" disabled>
                Guardar
              </Button>
            )}
            <Button
              mr={2}
              variant="contained"
              style={{ backgroundColor: red[500], marginRight: "10px" }}
              className="text-white"
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
