import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import Select from "react-select";
import Link from "@material-ui/core/Link";
import api from "utils/API";
import apiform from "utils/APIForm";
import AlertDelete from "./AlertDelete";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    minHeight: "70%",
    maxHeight: "70%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    //minWidth: 120
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    position: "absolute",
    minHeight: "150%",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      //thousandSeparator={true}
      thousandSeparator={"."}
      decimalSeparator={false}
      allowNegative={false}
      isNumericString
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ModalQuotes = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    addSupplier,
    setAddSupplier,
    vendors,
    setVendors,
    setOpenBackdrop,
    auxAddSupplier,
    infoRequest,
    tempRow,
    checkSupplier,
    setSupplierValidation
  } = props;
  const [supplierSelected, setSupplierSelected] = useState(
    auxAddSupplier
      ? null
      : {
          value: tempRow.id_proveedor_id,
          label: tempRow.nombre
        }
  );
  const [listSuppliers, setListSuppliers] = useState([]);
  const [amount, setAmount] = useState(auxAddSupplier ? 0 : tempRow.monto);
  const [comment, setCommet] = useState(
    auxAddSupplier ? "" : tempRow.observacion
  );
  const [file, setFile] = useState(null);
  const fileRow = auxAddSupplier
    ? null
    : tempRow.archivo
    ? tempRow.archivo
    : null;
  const getIndex = auxAddSupplier
    ? null
    : vendors.findIndex(row => row.id === tempRow.id);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  console.log("openDialogDelete", openDialogDelete);

  useEffect(() => {
    if (addSupplier) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setAddSupplier(false);

    const getSuppliers = api.get("api/proveedores/");

    Promise.all([getSuppliers])
      .then(response => {
        let tempListSuppliers = [];
        response[0].data
          .filter(list => vendors.every(data => data.id_proveedor !== list.id))
          .map(row => {
            tempListSuppliers.push({
              value: row.id,
              label: row.nombre
            });
          });
        setListSuppliers(tempListSuppliers);

      })

  };

  const handleSupplier = supplier => {
    setSupplierSelected(supplier);
  };

  const addQuote = () => {

    const formData = new FormData();
    formData.append("nombre", supplierSelected.label);
    formData.append("observacion", comment);
    formData.append("monto", amount);
    formData.append("id_solicitud_cotizacion", infoRequest.id);
    formData.append("id_proveedor", supplierSelected.value);

    if (file != null) {
      formData.append("archivo", file[0]);
    }

    const tempArray = vendors;

    apiform
      .post("api/cotizacionescompra/", formData)
      .then(response => {
        tempArray.push(response.data);
        checkSupplier(tempArray, setSupplierValidation);
        setVendors(tempArray);
        closeModal();

      })

  };

  const editQuote = () => {

    const formData = new FormData();
    formData.append("nombre", supplierSelected.label);
    formData.append("observacion", comment);
    formData.append("monto", amount);
    formData.append("id_solicitud_cotizacion", infoRequest.id);
    if (file != null) {
      formData.append("archivo", file[0]);
    }

    const tempArray = vendors;
    tempArray.splice(getIndex, 1);

    apiform
      .put(`api/cotizacionescompra/${tempRow.id}`, formData)
      .then(response => {
        tempArray.push(response.data);
        checkSupplier(tempArray, setSupplierValidation);
        setVendors(tempArray);
        closeModal();

      })

  };

  const deleteQuote = () => {

    const tempArray = vendors;
    api
      .delete(`api/cotizacionescompra/${tempRow.id}`)
      .then(response => {
        console.log("response", response);
        tempArray.splice(getIndex, 1);
        checkSupplier(tempArray, setSupplierValidation);
        setVendors(tempArray);
        closeModal();

      })

  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper className={classes.paper}>
        <ModalTitle id="customized-dialog-title" onClose={closeModal}>
          Adjuntar cotizaciones
        </ModalTitle>
        <DialogContent>
          <Grid
            container
            className={classes.root}
            justify="center"
            style={{ marginBottom: "3em" }}
          >
            <Grid item container xs={12} justify="center">
              {auxAddSupplier ? (
                <Grid item container xs={4} justify="center">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <Select
                      value={supplierSelected}
                      onChange={handleSupplier}
                      options={listSuppliers}
                      placeholder={
                        supplierSelected != null ? (
                          "Seleccione proveedor"
                        ) : (
                          <span className="text-danger">Requerido</span>
                        )
                      }
                      noOptionsMessage={() => "Sin opciones"}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        control: provided => ({
                          ...provided,
                          minHeight: "56px",
                          color: "red"
                        })
                      }}
                    />
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item container xs={auxAddSupplier ? 4 : 6} justify="center">
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                >
                  <TextField
                    variant="outlined"
                    label="Monto cotización"
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    name="numberformat"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumberFormatCustom
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item container xs={auxAddSupplier ? 4 : 6} justify="center">
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  style={{ textTransform: "none" }}
                >
                  Adjuntar archivo
                  <TextField
                    type="file"
                    id="exam"
                    onChange={event => setFile(event.target.files)}
                    fullWidth
                    my={2}
                    style={{ display: "none" }}
                    accept={("pdf", "jpg", "jpeg", "png")}
                  />
                </Button>
                <Grid item xs={12} justify="center">
                  {auxAddSupplier ? (
                    <span style={{ textAlign: "center" }}>
                      {file === null ? "" : `Nuevo archivo: ${file[0].name}`}
                    </span>
                  ) : (
                    <Grid item xs={12} justify="center">
                      <span style={{ textAlign: "center" }}>
                        {fileRow === null ? (
                          ""
                        ) : (
                          <Link target="_blank" href={`${fileRow}`}>
                            Ver archivo anterior
                          </Link>
                        )}
                      </span>
                      <span style={{ textAlign: "center" }}>
                        {file === null
                          ? ""
                          : ` | Nuevo archivo: ${file[0].name}`}

                      </span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justify="center"
              style={{ marginTop: "1em" }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  id="outlined-multiline-static"
                  label="Comentario"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={comment}
                  onChange={e => setCommet(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {auxAddSupplier ? null : (
            <Button
              variant="contained"
              color="secondary"
              style={{ textTransform: "none" }}
              onClick={() => setOpenDialogDelete(true)}
            >
              Eliminar
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={auxAddSupplier ? addQuote : editQuote}
            disabled={
              auxAddSupplier
                ? supplierSelected === null
                  ? true
                  : false
                : false
            }
          >
            {auxAddSupplier ? "Agregar" : "Guardar"}
          </Button>
        </DialogActions>
        {openDialogDelete && (
          <AlertDelete
            openDialog={openDialogDelete}
            closeDialog={() => setOpenDialogDelete(false)}
            deleteQuote={deleteQuote}
          />
        )}
      </Paper>
    </Modal>
  );
};

export default ModalQuotes;

ModalQuotes.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  addSupplier: PropTypes.bool,
  setAddSupplier: PropTypes.func,
  vendors: PropTypes.array,
  setVendors: PropTypes.func,
  setOpenBackdrop: PropTypes.func,
  auxAddSupplier: PropTypes.bool,
  infoRequest: PropTypes.object,
  tempRow: PropTypes.object,
  checkSupplier: PropTypes.func,
  setSupplierValidation: PropTypes.func
};
