import React, { useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import apiform from "utils/APIForm";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: "#fff"
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function ModalEditAlert(props) {
  const classes = useStyles();
  const {
    open,
    closeModal,
    idAlert,
    idFicha,
    lowerLimit,
    setLowerLimit,
    upperLimit,
    setUpperLimit,
    observation,
    setObservation,
    alertType,
    setChanges,
    setOpenBackdrop,
    setActivateAlert,
    setAlertSigns,
    setAlertas
  } = props;

  const [openBackdropAlert, setOpenBackdropAlert] = useState(false);
  const dictSigns = useSelector(state => state.currentList.DICCIONARIO_SIGNOS_VITALES);


  const editAlert = () => {
    setOpenBackdropAlert(true);

    const formData = new FormData();

    formData.append("tipo_signos", alertType);
    formData.append("limite_superior", upperLimit);
    formData.append("limite_inferior", lowerLimit);
    formData.append("ultima_modificacion", moment().format());
    formData.append("observacion", observation);
    formData.append("id_ficha", idFicha);

    apiform
      .put(`api/limitessignos/${idAlert}`, formData)
      .then(() => {
        setAlertas({});
        setActivateAlert(false);
        setAlertSigns([]);

        setChanges(true);
        closeModal();
      })
      .catch(error => {
        console.log("Error put api/limitessignos/", error.response);
      });
  };

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="lg"
      disableBackdropClick
      scroll="paper"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={closeModal}
        align="center"
      >
        Editar alerta
      </DialogTitle>
      <DialogContent scroll={"paper"}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={1} />
            <Grid item xs={10} container>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} style={{ marginTop: "5px" }}>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <TextField
                      disabled
                      id="outlined-number2"
                      label="Tipo de alerta"
                      variant="outlined"
                      value={dictSigns[alertType]?.label || ""}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "5px" }}>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <TextField
                      id="outlined-number1"
                      label="Límite inferior"
                      inputProps={{ type: "number", step: "0.1" }}
                      variant="outlined"
                      value={lowerLimit}
                      onChange={e => {
                        if (/^[0-9.\b]+$/.test(e.target.value)) {
                          setLowerLimit(parseFloat(e.target.value));
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "5px" }}>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <TextField
                      id="outlined-number2"
                      label="Límite superior"
                      inputProps={{ type: "number", step: "0.1" }}
                      variant="outlined"
                      value={upperLimit}
                      onChange={e => {
                        if (/^[0-9.\b]+$/.test(e.target.value)) {
                          setUpperLimit(parseFloat(e.target.value));
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "5px" }}>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <TextField
                      id="outlined-basic3"
                      label="Observación"
                      multiline
                      rows={3}
                      inputProps={{
                        maxLength: 500
                      }}
                      variant="outlined"
                      value={observation}
                      onChange={e => setObservation(e.target.value)}
                      error={observation == ""}
                      helperText={observation == "" ? "No puede ser vacío" : ""}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "1em" }}>
        {openBackdropAlert ? (
          <CircularProgress />
        ) : alertType != "" && observation != "" ? (
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            color="primary"
            onClick={editAlert}
          >
            Guardar cambios
          </Button>
        ) : (
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            color="primary"
            disabled
          >
            Guardar cambios
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalEditAlert.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  setChanges: PropTypes.func,
  setOpenBackdrop: PropTypes.func,
  idAlert: PropTypes.number,
  idFicha: PropTypes.number,
  lowerLimit: PropTypes.number,
  setLowerLimit: PropTypes.func,
  upperLimit: PropTypes.number,
  setUpperLimit: PropTypes.func,
  observation: PropTypes.string,
  setObservation: PropTypes.func,
  alertType: PropTypes.string,
  setActivateAlert: PropTypes.func,
  setAlertSigns: PropTypes.func,
  setAlertas: PropTypes.func
};
