//TODO Componetizar formulario de asignación
import React, { useState } from 'react'
import api from 'utils/API'
import moment from "moment-timezone";
import "moment/locale/es";
import { useForm, Controller } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import { TextField, Box, Button, Tabs, Tab, Select, MenuItem } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search'
import ListIcon from '@material-ui/icons/List'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { TypographyMui } from 'utils/componentsStyle'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const useStyles = makeStyles({
  menu: {
    position: 'absolute',
    right: 16,
    backgroundColor: 'white',
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
    padding: '25px 19px',
    marginTop: 8,
    width: 365,
    zIndex: 1000
  },
  alignSelf: {
    alignSelf: 'center'
  },
  alert: {
    color: '#C77700',
    border: '1px solid #C77700',
    padding: '1rem, 0px',
    lineHeight: '12px'
  },
  tabs: {
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
});

function WorkTeamActions({ workTeamData, wordToFilter, typeProfessionalSelected, chargeColor, tab, setWordToFilter, setTab, updateProfessionalList, setSpiner }) {
  const classes = useStyles()
  const { errors, handleSubmit, control, reset, setValue } = useForm()
  const [toogleMenu, setToogleMenu] = useState(false)
  const [alert, setAlert] = useState({ success: false, error: false })
  const [areaSelected, setAreaSelected] = useState([])
  const [alertMessage, setAlertMessage] = useState({ name: undefined, assignation: undefined, actionButtomName: 'Asignar' })

  const changeTab = (event, newValue) => {
    setTab(newValue)
    setToogleMenu(false)
  }

  const isProfessionalAssigned = (professional) => {
    if (typeProfessionalSelected[professional.id_usuario_id]?.nombres) {
      setAlertMessage({
        name: typeProfessionalSelected[professional.id_usuario_id].nombres,
        assignation: typeProfessionalSelected[professional.id_usuario_id].tipo_coordinador === 'coordinadorpaciente' ? 'Coordinador(a)' : 'Tutor(a)',
        actionButtomName: 'Cambiar asignación'
      })
      setValue('rol', typeProfessionalSelected[professional.id_usuario_id].tipo_coordinador === 'coordinadorpaciente' ? 'coordinadorpaciente' : 'tutorpaciente')
      return
    }
    setAlertMessage({ name: undefined, assignation: undefined, actionButtomName: 'Asignar' })
    setValue('rol', '')
  }

  const openCoordinatorMenu = () => {
    setToogleMenu(prev => !prev)
    reset()
    setAreaSelected([])
    setAlertMessage({ name: undefined, assignation: undefined, actionButtomName: 'Asignar' })
  }

  const onSubmit = (data) => {
    setSpiner()
    const coordinatorData = {
      tipo_coordinador: data.rol,
      fecha_inicio: moment().format('YYYY-MM-DD HH:mm:ss'),
      id_coordinador: data.professional.id_usuario_id,
      id_ficha_paciente: workTeamData.file.id
    }
    api.post("api/cordinadores/asignar/paciente/", coordinatorData).then((resp) => {
      updateProfessionalList(resp.data, data.professional.id_usuario_id)
      setAlert({ ...alert, success: true })
      setAlertMessage({ name: undefined, assignation: undefined, actionButtomName: 'Asignar' })
      reset()
      setTimeout(() => {
        setAlert({ ...alert, success: false })
        setToogleMenu(false)
      }, 3000);
      setSpiner()
    },
      (err) => {
        setAlert({ ...alert, error: true })
        setSpiner()
      })
  }

  return (
    <>
      <Box width='100%' mt={1} mb={2} display='grid' gridTemplateColumns='repeat(4, 1fr)'>
        {chargeColor.map(charge => {
          return (
            <Box display='flex' alignItems='center' mt={3} key={charge.name}>
              <Box width={15} height={15} borderRadius="50%" mr={1} style={{ backgroundColor: charge.color_row }} />
              <span>{charge.name}</span>
            </Box>
          )
        })}
      </Box>

      <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary" centered>
        <Tab className={classes.tabs} label="Listado" icon={<ListIcon />} />
        <Tab className={classes.tabs} label="Calendario" icon={<DateRangeIcon />} />
      </Tabs>

      <Box display='grid' gridTemplateColumns='40px 1fr 45%' mt={2}>
        <Box pb={0.5} alignSelf='flex-end'>
          <SearchIcon />
        </Box>

        <Box display='grid' gridTemplateColumns='1fr 50px'>
          <TextField
            className={classes.alignSelf}
            onChange={e => setWordToFilter(e.target.value)}
            label='Filtrar por nombre o cargo'
            value={wordToFilter}
          />
        </Box>

        <Box justifySelf='flex-end' alignSelf='flex-end'>
          <Button className='px-3' onClick={openCoordinatorMenu} variant='outlined' color='primary' >
            Asignar Coordinador o tutor
          </Button>
          {/* Menu de asignar tutor/coordinado */}
          <Box onSubmit={handleSubmit(onSubmit)} display={toogleMenu ? 'block' : 'none'} className={classes.menu} component='form'>
            <Box display='grid' gridTemplateColumns='30% 1fr' gridGap='0.5rem'>
              <TypographyMui variant='body2' color='primary' className={classes.alignSelf}>Area</TypographyMui>
              <Box>
                <Box>
                  <Controller className='w-100 mt-4'
                    as={
                      <Select className='w-100'>
                        <MenuItem value=''>
                          <em>Selecciona un cargo</em>
                        </MenuItem>
                        {chargeColor.map((option, i) => (
                          <MenuItem key={option.name} value={option} onClick={() => { setAreaSelected(option.professional) }}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    defaultValue=""
                    name='area'
                    rules={{ required: "Campo Obligatorio" }}
                    control={control}
                  />
                </Box>
                <span>{errors?.area?.message}</span>
              </Box>
            </Box>

            <Box display='grid' gridTemplateColumns='30% 1fr' gridGap='0.5rem'>
              <TypographyMui variant='body2' color='primary' className={classes.alignSelf}>Nombre profesional</TypographyMui>
              <Box>
                <Box>
                  <Controller className='w-100 mt-4' style={{ height: 40 }}
                    as={
                      <Select>
                        <MenuItem value=''>
                          <em>Selecciona el nombre del listado</em>
                        </MenuItem>
                        {areaSelected.map((option, i) => (
                          <MenuItem key={option.nombres} value={option} onClick={() => isProfessionalAssigned(option)}>
                            {option.nombres} {option.apellido_paterno}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    defaultValue=""
                    name='professional'
                    rules={{ required: "Campo Obligatorio" }}
                    control={control}
                  />
                </Box>
                <span>{errors?.professional?.message}</span>
              </Box>
            </Box>

            <Box display='grid' gridTemplateColumns='30% 1fr' gridGap='0.5rem'>
              <TypographyMui variant='body2' color='primary' className={classes.alignSelf}>Asignación</TypographyMui>
              <Box>
                <Box>
                  <Controller className='w-100 mt-4' style={{ height: 40 }}
                    as={
                      <Select>
                        <MenuItem value=''>
                          <em>Selecciona coordinador o tutor</em>
                        </MenuItem>
                        <MenuItem value='coordinadorpaciente'>
                          COORDINADOR(a)
                        </MenuItem>
                        <MenuItem value='tutorpaciente'>
                          TUTOR(a)
                        </MenuItem>
                      </Select>
                    }
                    defaultValue=""
                    name='rol'
                    rules={{ required: "Campo Obligatorio" }}
                    control={control}
                  />
                </Box>
                <span>{errors?.rol?.message}</span>
              </Box>
            </Box>

            {alertMessage.name &&
              <Box px={1.5} py={1} mt={1.5} mx={2} display='grid' gridTemplateColumns='30% 1fr' className={classes.alert}>
              <ReportProblemIcon fontSize='large' style={{ color: '#C77700' }} className={`align-self-center mx-auto d-block`} />
                <TypographyMui variant='button' class>
                El profesional seleccionado actualmente es {alertMessage.assignation} por lo que solo se puede cambiar su asignación a {alertMessage.assignation === 'Coordinador(a)' ? 'Tutor(a)' : 'Coordinador(a)'}.
                </TypographyMui>
              </Box>
            }

            <Box display='flex' justifyContent='center' mt={3}>
              <Button className={classes.assignButtom} variant='outlined' color='primary' type='submit'>
                {alertMessage.actionButtomName}
              </Button>
            </Box>

            <Box display={alert.success ? 'block' : 'none'} mt={1.5}>
              <Alert severity="success">Los cambios se realizaron exitosamente</Alert>
            </Box>
            <Box display={alert.error ? 'block' : 'none'} mt={1.5}>
              <Alert severity="error">Hubo un error. Intentalo nuevamente</Alert>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default WorkTeamActions
