import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import medIcon from "assets/img/icons/indicator/med.svg";
import supIcon from "assets/img/icons/indicator/sup.svg";
import equipIcon from "assets/img/icons/indicator/equip.svg";
import emergencyIcon from "assets/img/icons/indicator/emergency.svg";

export function MedIcon(props) {
  return (
      <img src={medIcon} alt="insumos" />
  );
}

export function SupIcon(props) {
  return (
    <img src={supIcon} alt="insumos" />
  );
}

export function EquipIcon(props) {
  return (
    <img src={equipIcon} alt="insumos" />
  );
}

export function EmergencyIcon(props) {
  return (
    <img src={emergencyIcon} alt="insumos" />
  );
}