import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import { Link } from "react-router-dom";

export default function ModalTransport(props) {
  const { open, closeModal, idTransport, moduleType } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const _content_ = <div>
      <p className="text-center font-weight-bold">
        ¿Por cuál forma deseas despachar el pedido?
      </p>
    </div>

    const _actions_ = <>
      <Button onClick={closeModal} color="primary">
        Cancelar
      </Button>
      <Link to={`/admin/outcome/companytransport/${moduleType}/${idTransport}`}>
        <Button color="primary">Transporte de la empresa</Button>
      </Link>
      <Link to={`/admin/outcome/profesionaltransport/${moduleType}/${idTransport}`}>
        <Button color="primary">Transporte con profesional interno</Button>
      </Link>
      <Link to={`/admin/outcome/externaltransport/${moduleType}/${idTransport}`}>
        <Button color="primary">Transporte externo</Button>
      </Link>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={closeModal}
            title={`Forma de transporte`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="md"
        />
  );
}

ModalTransport.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_order: PropTypes.number
};
