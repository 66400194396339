import React from "react";
import Button from "@material-ui/core/Button";

const Summary = ({setopenDetail, packagePending, packageToDayPending, packageWeekPending}) => {
  return (
    <div className="mb-3 m-1">
      <div className="card">
        <div className="card-header text-center">Estados pedidos</div>
        <div className="card-body">
          <div className="row">
            <div className="text-center">
              <h3>{packagePending}</h3>
              <div className="col-sm text-center">Pendientes</div>
            </div>
            <div className="text-center">
              <h3>{packageToDayPending}</h3>
              <div className="col-sm text-center">A realizar hoy</div>
            </div>
            <div className="text-center">
              <h3>{packageWeekPending}</h3>
              <div className="col-sm text-center">A realizar esta semana</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm text-center mt-3 mb-1">
                <Button variant="contained" color="primary" onClick={() => setopenDetail(true)}>
                  Ver detalle
                </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;

