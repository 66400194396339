import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";
import { useParams } from "react-router-dom";
import CommodityInfo from "./CommodityInfo";
import ModalAceptRequest from "./ModalAcepRequest";
import ModalRejectRequest from "./ModalRejectRequest";



export default function CommodityRequest() {
  const { id } = useParams();
  const [moduleType, setmoduleType] = useState("");
  const [supplies, setSupplies] = useState([]);
  const [group, setGroup] = useState("");
  const [sku, setSKU] = useState("");
  let list_sku = [];
  let list_sku_final = [];
  const [description, setDescription] = useState("");


  const [cantidad, setCantidad] = useState(0);
  const [motivo, setMotivo] = useState("");

  const [modalAceptRequest, setModalAceptRequest] = useState(false);
  const [modalRejectRequest, setModalRejectRequest] = useState(false);

  const dictReason = useSelector(state => state.currentList.DICCIONARIO_MOTIVO_RETIRO);

  useEffect(() => {

    const tempArrayURL = window.location.href.split('/');
    setmoduleType(tempArrayURL[tempArrayURL.length-2]);
    api.get(`api/solicitudretiro/${id}`).then(response => {
      setCantidad(response.data.cantidad);
      setMotivo(dictReason[response.data.motivo] || {});

      api.get("/api/insumosmedicamento/").then(response2 => {
        setSupplies(response2.data);
        response2.data.map(supply => {
          if (response.data.id_producto_retirado === supply.id) {
            setSKU({ value: supply.SKU, label: supply.SKU });
          }
        });
      });


    });
  }, []);

  useEffect(() => {
    supplies.map(supply => {
      list_sku.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies.map(supply => {
      list_sku_final.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies
      .filter(supply => supply.SKU === sku.value)
      .map(filtered => {
        setGroup(filtered.grupo_producto);
        setDescription(filtered.descripcion_producto);
      });
  });


  return (
    <GridContainer>
      <ViewTitle
        title="DETALLE RETIRO"
        message="En esta sección podrás administrar el retiro seleccionado."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/outcome/tasks/${moduleType}`}>
              Tareas de egresos
            </Link>
            <Link color="inherit" to={`/admin/outcome/divisions/${moduleType}`}>
              Fraccionamiento - retiros
            </Link>
            <Typography color="textPrimary">Detalle retiro</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardBody className="mb-3">
            <GridContainer justify="center">
              <CommodityInfo id={id} />
            </GridContainer>
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <DetailTextDisabled label="SKU:" value={sku.value} />
                <DetailTextDisabled label="GRUPO:" value={group} />
                <DetailTextDisabled label="DESCRIPCIÓN:" value={description} />
                <DetailTextDisabled label="CANTIDAD:" value={cantidad} />
                <DetailTextDisabled label="MOTIVO:" value={motivo.value} />
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setModalAceptRequest(true)}
                >
                  Aceptar retiro
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setModalRejectRequest(true)}
                >
                  Rechazar retiro
                </Button>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      {modalAceptRequest && (
        <ModalAceptRequest
          open={modalAceptRequest}
          closeModal={() => setModalAceptRequest(false)}
          id={id}
          moduleType={moduleType}
        />
      )}
      {modalRejectRequest && (
        <ModalRejectRequest
          open={modalRejectRequest}
          closeModal={() => setModalRejectRequest(false)}
          id_order={id}
          moduleType={moduleType}
        />
      )}
    </GridContainer>
  );
}
