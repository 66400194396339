import currentList from './List'
import currentGlobalVar from './GlobalVar'

import {combineReducers} from 'redux'

const rootReducer = combineReducers({
    currentList,
    currentGlobalVar
})

export default rootReducer