import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  buttonMargin: {
    marginTop: "3px",
    marginBottom: "3px"
  },
  formControl: {
    margin: theme.spacing(1)
  }
}));

const ButtonsFilter = props => {
  const classes = useStyles();
  const {
    size,
    weight,
    temperature,
    heartRate,
    breathingFrecuency,
    oxygenSaturation,
    bloodPressure,
    bloodPressureSystolic,
    bloodPressureDiastolic,
    pain,
    handleChangeFilters,
    checkFilter,
    alertSigns
  } = props;

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ marginTop: "2em" }}
    >
      <Grid item lg={12}>
        <Typography
          variant="body1"
          gutterBottom
          align="center"
          style={{ textTransform: "none", marginBottom: "3px" }}
        >
          Medición a mostrar
        </Typography>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {checkFilter === false ? (
              alertSigns.filter(alert => alert.sizePatient).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={size}
                        onChange={handleChangeFilters}
                        name="size"
                        color="primary"
                      />
                    }
                    label="Talla"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={size}
                      onChange={handleChangeFilters}
                      name="size"
                      color="primary"
                    />
                  }
                  label="Talla"
                />
              )
            ) : checkFilter && size ? (
              alertSigns.filter(alert => alert.sizePatient).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={size}
                        onChange={handleChangeFilters}
                        name="size"
                        color="primary"
                      />
                    }
                    label="Talla"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={size}
                      onChange={handleChangeFilters}
                      name="size"
                      color="primary"
                    />
                  }
                  label="Talla"
                />
              )
            ) : alertSigns.filter(alert => alert.sizePatient).length > 0 ? (
              <span>
                <NotificationImportantIcon color="secondary" />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={size}
                      onChange={handleChangeFilters}
                      name="size"
                      color="primary"
                    />
                  }
                  label="Talla"
                />
              </span>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={size}
                    onChange={handleChangeFilters}
                    name="size"
                    color="primary"
                  />
                }
                label="Talla"
              />
            )}
            {checkFilter === false ? (
              alertSigns.filter(alert => alert.weight).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={weight}
                        onChange={handleChangeFilters}
                        name="weight"
                        color="primary"
                      />
                    }
                    label="Peso"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={weight}
                      onChange={handleChangeFilters}
                      name="weight"
                      color="primary"
                    />
                  }
                  label="Peso"
                />
              )
            ) : checkFilter && weight ? (
              alertSigns.filter(alert => alert.weight).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={weight}
                        onChange={handleChangeFilters}
                        name="weight"
                        color="primary"
                      />
                    }
                    label="Peso"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={weight}
                      onChange={handleChangeFilters}
                      name="weight"
                      color="primary"
                    />
                  }
                  label="Peso"
                />
              )
            ) : alertSigns.filter(alert => alert.weight).length > 0 ? (
              <span>
                <NotificationImportantIcon color="secondary" />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={weight}
                      onChange={handleChangeFilters}
                      name="weight"
                      color="primary"
                    />
                  }
                  label="Peso"
                />
              </span>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={weight}
                    onChange={handleChangeFilters}
                    name="weight"
                    color="primary"
                  />
                }
                label="Peso"
              />
            )}
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {checkFilter === false ? (
              alertSigns.filter(alert => alert.temperature).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={temperature}
                        onChange={handleChangeFilters}
                        name="temperature"
                        color="primary"
                      />
                    }
                    label="Temperatura"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={temperature}
                      onChange={handleChangeFilters}
                      name="temperature"
                      color="primary"
                    />
                  }
                  label="Temperatura"
                />
              )
            ) : checkFilter && temperature ? (
              alertSigns.filter(alert => alert.temperature).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={temperature}
                        onChange={handleChangeFilters}
                        name="temperature"
                        color="primary"
                      />
                    }
                    label="Temperatura"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={temperature}
                      onChange={handleChangeFilters}
                      name="temperature"
                      color="primary"
                    />
                  }
                  label="Temperatura"
                />
              )
            ) : alertSigns.filter(alert => alert.temperature).length > 0 ? (
              <span>
                <NotificationImportantIcon color="secondary" />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={temperature}
                      onChange={handleChangeFilters}
                      name="temperature"
                      color="primary"
                    />
                  }
                  label="Temperatura"
                />
              </span>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={temperature}
                    onChange={handleChangeFilters}
                    name="temperature"
                    color="primary"
                  />
                }
                label="Temperatura"
              />
            )}
            {checkFilter === false ? (
              alertSigns.filter(alert => alert.heartRate).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={heartRate}
                        onChange={handleChangeFilters}
                        name="heartRate"
                        color="primary"
                      />
                    }
                    label="Frecuencia cardiaca"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={heartRate}
                      onChange={handleChangeFilters}
                      name="heartRate"
                      color="primary"
                    />
                  }
                  label="Frecuencia cardiaca"
                />
              )
            ) : checkFilter && heartRate ? (
              alertSigns.filter(alert => alert.heartRate).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={heartRate}
                        onChange={handleChangeFilters}
                        name="heartRate"
                        color="primary"
                      />
                    }
                    label="Frecuencia cardiaca"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={heartRate}
                      onChange={handleChangeFilters}
                      name="heartRate"
                      color="primary"
                    />
                  }
                  label="Frecuencia cardiaca"
                />
              )
            ) : alertSigns.filter(alert => alert.heartRate).length > 0 ? (
              <span>
                <NotificationImportantIcon color="secondary" />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={heartRate}
                      onChange={handleChangeFilters}
                      name="heartRate"
                      color="primary"
                    />
                  }
                  label="Frecuencia cardiaca"
                />
              </span>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={heartRate}
                    onChange={handleChangeFilters}
                    name="heartRate"
                    color="primary"
                  />
                }
                label="Frecuencia cardiaca"
              />
            )}
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {checkFilter === false ? (
              alertSigns.filter(alert => alert.breathingFrecuency).length >
              0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={breathingFrecuency}
                        onChange={handleChangeFilters}
                        name="breathingFrecuency"
                        color="primary"
                      />
                    }
                    label="Frecuencia respiratoria"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={breathingFrecuency}
                      onChange={handleChangeFilters}
                      name="breathingFrecuency"
                      color="primary"
                    />
                  }
                  label="Frecuencia respiratoria"
                />
              )
            ) : checkFilter && breathingFrecuency ? (
              alertSigns.filter(alert => alert.breathingFrecuency).length >
              0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={breathingFrecuency}
                        onChange={handleChangeFilters}
                        name="breathingFrecuency"
                        color="primary"
                      />
                    }
                    label="Frecuencia respiratoria"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={breathingFrecuency}
                      onChange={handleChangeFilters}
                      name="breathingFrecuency"
                      color="primary"
                    />
                  }
                  label="Frecuencia respiratoria"
                />
              )
            ) : alertSigns.filter(alert => alert.breathingFrecuency).length >
              0 ? (
              <span>
                <NotificationImportantIcon color="secondary" />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={breathingFrecuency}
                      onChange={handleChangeFilters}
                      name="breathingFrecuency"
                      color="primary"
                    />
                  }
                  label="Frecuencia respiratoria"
                />
              </span>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={breathingFrecuency}
                    onChange={handleChangeFilters}
                    name="breathingFrecuency"
                    color="primary"
                  />
                }
                label="Frecuencia respiratoria"
              />
            )}
            {checkFilter === false ? (
              alertSigns.filter(alert => alert.oxygenSaturation).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={oxygenSaturation}
                        onChange={handleChangeFilters}
                        name="oxygenSaturation"
                        color="primary"
                      />
                    }
                    label="Saturación de oxígeno"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={oxygenSaturation}
                      onChange={handleChangeFilters}
                      name="oxygenSaturation"
                      color="primary"
                    />
                  }
                  label="Saturación de oxígeno"
                />
              )
            ) : checkFilter && oxygenSaturation ? (
              alertSigns.filter(alert => alert.oxygenSaturation).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={oxygenSaturation}
                        onChange={handleChangeFilters}
                        name="oxygenSaturation"
                        color="primary"
                      />
                    }
                    label="Saturación de oxígeno"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={oxygenSaturation}
                      onChange={handleChangeFilters}
                      name="oxygenSaturation"
                      color="primary"
                    />
                  }
                  label="Saturación de oxígeno"
                />
              )
            ) : alertSigns.filter(alert => alert.oxygenSaturation).length >
              0 ? (
              <span>
                <NotificationImportantIcon color="secondary" />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={oxygenSaturation}
                      onChange={handleChangeFilters}
                      name="oxygenSaturation"
                      color="primary"
                    />
                  }
                  label="Saturación de oxígeno"
                />
              </span>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={oxygenSaturation}
                    onChange={handleChangeFilters}
                    name="oxygenSaturation"
                    color="primary"
                  />
                }
                label="Saturación de oxígeno"
              />
            )}
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            
            {checkFilter === false ? (
              alertSigns.filter(alert => alert.bloodPressure).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bloodPressure}
                        onChange={handleChangeFilters}
                        name="bloodPressure"
                        color="primary"
                      />
                    }
                    label="Presión arterial media"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bloodPressure}
                      onChange={handleChangeFilters}
                      name="bloodPressure"
                      color="primary"
                    />
                  }
                  label="Presión arterial media"
                />
              )
            ) : checkFilter && bloodPressure ? (
              alertSigns.filter(alert => alert.bloodPressure).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bloodPressure}
                        onChange={handleChangeFilters}
                        name="bloodPressure"
                        color="primary"
                      />
                    }
                    label="Presión arterial media"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bloodPressure}
                      onChange={handleChangeFilters}
                      name="bloodPressure"
                      color="primary"
                    />
                  }
                  label="Presión arterial media"
                />
              )
            ) : alertSigns.filter(alert => alert.bloodPressure).length > 0 ? (
              <span>
                <NotificationImportantIcon color="secondary" />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={bloodPressure}
                      onChange={handleChangeFilters}
                      name="bloodPressure"
                      color="primary"
                    />
                  }
                  label="Presión arterial media"
                />
              </span>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={bloodPressure}
                    onChange={handleChangeFilters}
                    name="bloodPressure"
                    color="primary"
                  />
                }
                label="Presión arterial media"
              />
            )}
            
            {checkFilter === false ? (
              alertSigns.filter(alert => alert.pain).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pain}
                        onChange={handleChangeFilters}
                        name="pain"
                        color="primary"
                      />
                    }
                    label="Dolor"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pain}
                      onChange={handleChangeFilters}
                      name="pain"
                      color="primary"
                    />
                  }
                  label="Dolor"
                />
              )
            ) : checkFilter && pain ? (
              alertSigns.filter(alert => alert.pain).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pain}
                        onChange={handleChangeFilters}
                        name="pain"
                        color="primary"
                      />
                    }
                    label="Dolor"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pain}
                      onChange={handleChangeFilters}
                      name="pain"
                      color="primary"
                    />
                  }
                  label="Dolor"
                />
              )
            ) : alertSigns.filter(alert => alert.pain).length > 0 ? (
              <span>
                <NotificationImportantIcon color="secondary" />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={pain}
                      onChange={handleChangeFilters}
                      name="pain"
                      color="primary"
                    />
                  }
                  label="Dolor"
                />
              </span>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={pain}
                    onChange={handleChangeFilters}
                    name="pain"
                    color="primary"
                  />
                }
                label="Dolor"
              />
            )}
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {checkFilter === false ? (
              alertSigns.filter(alert => alert.bloodPressureSystolic).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bloodPressureSystolic}
                        onChange={handleChangeFilters}
                        name="bloodPressureSystolic"
                        color="primary"
                      />
                    }
                    label="Presión arterial sistolica"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bloodPressureSystolic}
                      onChange={handleChangeFilters}
                      name="bloodPressureSystolic"
                      color="primary"
                    />
                  }
                  label="Presión arterial sistolica"
                />
              )
            ) : checkFilter && bloodPressureSystolic ? (
              alertSigns.filter(alert => alert.bloodPressureSystolic).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bloodPressureSystolic}
                        onChange={handleChangeFilters}
                        name="bloodPressureSystolic"
                        color="primary"
                      />
                    }
                    label="Presión arterial sistolica"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bloodPressureSystolic}
                      onChange={handleChangeFilters}
                      name="bloodPressureSystolic"
                      color="primary"
                    />
                  }
                  label="Presión arterial sistolica"
                />
              )
            ) : alertSigns.filter(alert => alert.bloodPressureSystolic).length > 0 ? (
              <span>
                <NotificationImportantIcon color="secondary" />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={bloodPressureSystolic}
                      onChange={handleChangeFilters}
                      name="bloodPressureSystolic"
                      color="primary"
                    />
                  }
                  label="Presión arterial sistolica"
                />
              </span>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={bloodPressureSystolic}
                    onChange={handleChangeFilters}
                    name="bloodPressureSystolic"
                    color="primary"
                  />
                }
                label="Presión arterial sistolica"
              />
            )}
            {checkFilter === false ? (
              alertSigns.filter(alert => alert.bloodPressureDiastolic).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bloodPressureDiastolic}
                        onChange={handleChangeFilters}
                        name="bloodPressureDiastolic"
                        color="primary"
                      />
                    }
                    label="Presión arterial diastolica"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bloodPressureDiastolic}
                      onChange={handleChangeFilters}
                      name="bloodPressureDiastolic"
                      color="primary"
                    />
                  }
                  label="Presión arterial diastolica"
                />
              )
            ) : checkFilter && bloodPressureDiastolic ? (
              alertSigns.filter(alert => alert.bloodPressureDiastolic).length > 0 ? (
                <span>
                  <NotificationImportantIcon color="secondary" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bloodPressureDiastolic}
                        onChange={handleChangeFilters}
                        name="bloodPressureDiastolic"
                        color="primary"
                      />
                    }
                    label="Presión arterial diastolica"
                  />
                </span>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bloodPressureDiastolic}
                      onChange={handleChangeFilters}
                      name="bloodPressureDiastolic"
                      color="primary"
                    />
                  }
                  label="Presión arterial diastolica"
                />
              )
            ) : alertSigns.filter(alert => alert.bloodPressureDiastolic).length > 0 ? (
              <span>
                <NotificationImportantIcon color="secondary" />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={bloodPressureDiastolic}
                      onChange={handleChangeFilters}
                      name="bloodPressureDiastolic"
                      color="primary"
                    />
                  }
                  label="Presión arterial diastolica"
                />
              </span>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={bloodPressureDiastolic}
                    onChange={handleChangeFilters}
                    name="bloodPressureDiatolic"
                    color="primary"
                  />
                }
                label="Presión arterial diastolica"
              />
            )}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ButtonsFilter;

ButtonsFilter.propTypes = {
  size: PropTypes.bool,
  weight: PropTypes.bool,
  temperature: PropTypes.bool,
  heartRate: PropTypes.bool,
  breathingFrecuency: PropTypes.bool,
  oxygenSaturation: PropTypes.bool,
  bloodPressure: PropTypes.bool,
  bloodPressureSystolic:PropTypes.bool,
  bloodPressureDiastolic:PropTypes.bool,
  pain: PropTypes.bool,
  handleChangeFilters: PropTypes.func,
  checkFilter: PropTypes.bool,
  alertSigns: PropTypes.array
};
