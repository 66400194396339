import React, { useState, useEffect } from "react";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import api from "utils/API";
import logo from "../../layouts/logo-CMH.svg";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    backgroundColor: "#FFFFFF"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  image: {
    //backgroundImage: "url(https://venezolanoenchile.com/wp-content/uploads/2016/09/medicos-extranjeros-en-chile.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    height: "600px",
    width: "600px",
    left: "1093px",
    top: "724px",
    borderRadius: "0px"
  },
  title: {
    height: "32px",
    width: "371px",
    left: "115px",
    top: "103px",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "32px",
    letterSpacing: "0.15000000596046448px",
    textAlign: "center"
  },
  body: {
    height: "60px",
    width: "371px",
    left: "115px",
    top: "154px",
    fontFamily: "Roboto",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0.15000000596046448px",
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  attendance: {
    color: "#fff",
    contrastText: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
      "@media (hover: none)": {
        backgroundColor: green[500]
      }
    }
  },
  attendanceBox: {
    paddingTop: "10px"
  },
  forgotPasswordButton: {
    color: "#3F51B5",
    border: "1px solid #3F51B5",
    backgroundColor: "rgba(63, 81, 181, 0.08)"
  }
}));

export default function Recovery() {
  const { id } = useParams();

  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordLetterNumber, setPasswordLetterNumber] = useState(false);
  const [passwordMayMin, setPasswordMayMin] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [userName, setUserName] = useState(null);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    handleObtainUser();
  }, []);

  const handleCheckPassword = value => {
    setNewPassword(value);
    if (value.length < 8) {
      setPasswordLength(false);
    } else {
      setPasswordLength(true);
    }
    if (/\d/.test(value) && value.match(/[aA-zZ]/)) {
      setPasswordLetterNumber(true);
    } else {
      setPasswordLetterNumber(false);
    }
    if (value.match(/[a-z]/) && value.match(/[A-Z]/)) {
      setPasswordMayMin(true);
    } else {
      setPasswordMayMin(false);
    }
    if (value === confirmPassword) {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  };
  const handleValidatePassword = value => {
    setConfirPassword(value);
    if (newPassword === value) {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  };
  const handleObtainUser = () => {

    const arrayURL = window.location.href.split("/");
    let body = {
      token: arrayURL.slice(5, arrayURL.length).join()
    };
    api
      .post(`appauth/token/usuario/`, body)
      .then(response => {
        setUserName(response.data.username);

      })
      .catch(error => {
        console.log("response", error);
        window.location.href = "/";

      });
  };
  const handleChangePassword = () => {

    let body = {
      password: confirmPassword,
      username: userName
    };
    api
      .post(`appauth/user/ChangePassword/`, body)
      .then(response => {

        setChangePasswordSuccess(true);
      })
      .catch(error => {
        console.log("response", error);

        setChangePasswordSuccess(false);
      });
  };
  const handleChangePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };
  return (
    <Container fixed className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={5}
      >
        <Grid item xs={12} md={12}>
          <img alt="Qries" src={logo} width="280" height="100" />
        </Grid>
        <Grid item xs={12} md={12}>
          {!changePasswordSuccess ? (
            <Typography className={classes.title}>
              Reestablecer contraseña
            </Typography>
          ) : (
            <Typography className={classes.title}>
              Contraseña modificada
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          {!changePasswordSuccess ? null : (
            <div>
              <Typography variant="body1" gutterBottom style={{ width: "350px" }}>
                Tu contraseña fue modificada con éxito.
              </Typography>
              <Typography variant="body1" gutterBottom style={{ width: "350px" }}>
                Ahora puedes iniciar sesión con tu nombre de usuario y nueva
                contraseña.
              </Typography>
            </div>
          )}
        </Grid>
        {!changePasswordSuccess ? (
          <div>
            <Grid item xs={12} md={12}>
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ color: "#303F9F", width: "350px" }}
              >
                Ingresa la nueva contraseña
              </Typography>
              <TextField
                style={{ width: "350px" }}
                id="outlined-start-adornment"
                fullWidth
                label="contraseña"
                variant="outlined"
                type={passwordVisibility ? "text" : "password"}
                onChange={event => handleCheckPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleChangePasswordVisibility}
                        edge="end"
                      >
                        {passwordVisibility ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {!passwordLength && <Typography variant="caption" display="block" gutterBottom style={{color:"#E31B0C", width: "350px"}}>
                Debe tener un mínimo de 8 caracteres
              </Typography>}
              {!passwordMayMin  && <Typography variant="caption" display="block" gutterBottom style={{color:"#E31B0C", width: "350px"}}>
                Debe tener al menos una mayúscula y una minúscula
              </Typography>}
              {!passwordLetterNumber && <Typography variant="caption" display="block" gutterBottom style={{color:"#E31B0C", width: "350px"}}>
                Debe tener al menos una letra y un número
              </Typography>}
            </Grid>
            <Grid item xs={12} md={12} style={{paddingTop:"40px"}}>
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ color: "#303F9F", width: "350px" }}
              >
                Confirma la nueva contraseña
              </Typography>
              <TextField
                style={{ width: "350px"}}
                id="outlined-start-adornment"
                fullWidth
                label="contraseña"
                variant="outlined"
                disabled={
                  passwordLength && passwordLetterNumber && passwordMayMin
                    ? false
                    : true
                }
                type={passwordVisibility ? "text" : "password"}
                onChange={event => handleValidatePassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleChangePasswordVisibility}
                        edge="end"
                      >
                        {passwordVisibility ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {!passwordValidation && (
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ color: "#E31B0C", width: "350px", padding: "2px" }}
                >
                  Las contraseñas no coinciden.
                </Typography>
              )}
            </Grid>
          </div>
        ) : null}
        <Grid item xs={12} md={12}>
          {!changePasswordSuccess ? (
            <Button
              color="primary"
              variant="contained"
              onClick={handleChangePassword}
              startIcon={<DoneOutlineIcon style={{ color: "white" }} />}
              style={{ width: "350px" }}
              disabled={!passwordValidation}
            >
              Cambiar contraseña
            </Button>
          ) : (
            <Button color= "primary" variant="contained" href="/login" startIcon={<AccountCircleIcon style={{color:"white"}}/>} style={{ width: "350px"}}>
              Iniciar sesión
            </Button>
          )}
        </Grid>

      </Grid>
    </Container>
  );
}
