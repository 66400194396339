import React from "react";
import Contract from "./ManageLink/Contract";

export const manage = (type, id, id_request, id_solicitud) => {
  switch (type) {
    case 2: //prod: 1 staging: 2
      return (
        <Contract id={id} id_request={id_request} id_solicitud={id_solicitud} />
      );
    default:
      return null;
  }
};
