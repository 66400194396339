import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";
import moment from "moment-timezone";
import api from "utils/API";
import "moment/locale/es";
import {
    getListRequestPatient,
  } from 'actions/getListAction';
moment.locale("es");

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: "all 500ms linear",
    },
    paper: {
        transition: "all 500ms linear",
        padding: "2% 4% 3%",
    },
}));

export default function ModalRemoveRequest({
    open,
    handleClose,
    row,
    dataPatient,
}) {
    const dispatch = useDispatch()
    const classes = useStyles();
    const deleteValue = () => {
        api.delete(`api/solicitudpaciente/${row.id}`).then((response) => {;
            getListRequestPatient(dataPatient, dispatch);
            handleClose();
        });
    }

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px',
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.paper}>
            <h3 id="spring-modal-title">{`Eliminar Solicitud`}</h3>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                item
                xs={12}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item xs={12}
                >
                   <Typography variant="caption" gutterBottom>
                        {`Desea eliminar la solicitud de "${row.Tipo_Solicitud_Paciente}"?`}
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        style={{backgroundColor: green[500]}}
                        onClick={deleteValue}
                    >
                        Eliminar
                    </Button>

                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    </Modal>
    );
}