import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "utils/API";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogRejectRequest = (props) => {
  const {
    openDialog,
    closeDialog,
    rowInfo,
    closeModal,
    dataArray,
    setauxData,
    getIndex,
    closeMainModal,
  } = props;
  const [openAnimation, setOpenAnimation] = useState(false);

  const rejectRequest = () => {
    setOpenAnimation(true);
    api
      .post(
        `api/paciente/${rowInfo.id_paciente}/solicitudes/insumosextra/${rowInfo.id}/anularbodega/`
      )
      .then((response) => {
        console.log("response", response);
        let tempArray = dataArray;
        tempArray[getIndex].estado = "RecienCreada";
        setauxData(tempArray);
        closeMainModal();
        closeModal();
      })

  };

  return (
    <Dialog
      onClose={closeDialog}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      disableBackdropClick
    >
      {openAnimation ? (
        <DialogTitle id="customized-dialog-title" />
      ) : (
        <DialogTitle id="customized-dialog-title" onClose={closeDialog} />
      )}
      <DialogContent style={{ marginTop: "1em" }}>
        <Typography gutterBottom>
          ¿Estás seguro que deseas cancelar en envío?
        </Typography>
        <Typography gutterBottom>
          Tendrás que enviar el pedido nuevamente a bodega.
        </Typography>
      </DialogContent>
      {openAnimation ? (
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          <CircularProgress />
        </DialogActions>
      ) : (
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={closeDialog}
            style={{ textTransform: "none" }}
          >
            No, volver atrás
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={rejectRequest}
          >
            Si, cancelar envío
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogRejectRequest;

DialogRejectRequest.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  rowInfo: PropTypes.object,
  closeModal: PropTypes.func,
  dataArray: PropTypes.array,
  setauxData: PropTypes.func,
  getIndex: PropTypes.number,
  closeMainModal: PropTypes.func,
};
