import React, { useState } from "react";
import {
  IconButton
} from "@material-ui/core";
import {green, indigo, red} from "@material-ui/core/colors";
import {Edit, Done, Close } from "@material-ui/icons/";

export default function EditInTableButton({selectEditable, disabledEdit, onClickEdit, onClickCancel, onClickSave }) {
  return (
    <>
      {selectEditable ?
        <>
          {onClickCancel &&
          <IconButton size="small"
            style={{
              border:`1px ${red[500]} solid`,
              margin: "0 0 0 12px"
            }}
            onClick={onClickCancel}

          >
            <Close style={{color: red[500]}}/>
          </IconButton>
          }
          <IconButton size="small"
            style={{
              border:`1px ${green[500]} solid`,
              margin: "0 0 0 12px"
            }}
            onClick={onClickSave}
          >
            <Done style={{color: green[500]}}/>
          </IconButton>
        </>:
        disabledEdit ?
        <IconButton size="small"
          style={{
            border:`1px ${indigo[50]} solid`
          }}
        >
          <Edit style={{color: indigo[50]}} />
        </IconButton> :
        <IconButton size="small"
          style={{
            border:`1px ${indigo[500]} solid`
          }}
          onClick={onClickEdit}
        >
          <Edit style={{color: indigo[500]}} />
        </IconButton>
      }
    </>
  );
}
