import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import { red, green } from "@material-ui/core/colors";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
;


export default function ModalDispatchGuide({
  open,
  handleClose,
  dispatchGuide,
  rowsActive,
  setrowsActive,
  setmessage,
  infoRequest,
  patientDict,
}) {
  const [tempRow, settempRow] = useState(dispatchGuide[0]);
  const [exam, setexam] = useState(
    tempRow["archivo"] ? tempRow["archivo"] : []
  );
  const [observation, setobservation] = useState(
    tempRow["observacion"] ? tempRow["observacion"] : ""
  );

  const dictSTValueLabel = useSelector(state => state.currentList.DICCIONARIO_ESTADOS_TRANSPORTES);
  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);

  const patient = patientDict[tempRow.id_paciente]

  const handleSaveSend = () => {
    const body = {};
    const body2 = {};
    body["estado_recepcion"] = "IniciarViaje";
    body2["estado"] = "IniciarViaje";

    const requestArray = [
      api.patch(`/api/agendatransportes/${dispatchGuide[0].id}`, body)
    ];
    if (tempRow.id_envios_pedidos) {
      const formData = new FormData();
      formData.append("nombre", exam[0].name);
      formData.append("archivo", exam[0]);
      formData.append("tipo_archivo", "GuiaDespacho");
      formData.append("observacion", observation);
      formData.append("area", "Transporte");
      formData.append("id_pedidos", dispatchGuide[0].id_envios_pedidos.id_solicitud_pedido);
      requestArray.push(
        api.patch(`/api/enviospedidos/${dispatchGuide[0].id_envios_pedidos.id}`, body2)
      );
      requestArray.push(api.post("/api/documentospedidos/", formData));
    }
    Promise.all(requestArray).then((result) => {
      const tempRows = rowsActive.map((row) => {
        const tempRow = row;
        if (dispatchGuide[0].id === row.id) {
          tempRow.estado_recepcion = "IniciarViaje";
        }
        return tempRow;
      });
      setrowsActive(tempRows);
      setmessage("Guia de despacho adjuntada");
      handleClose();
    });
  };

  const handleSave = () => {
    const auxRow = tempRow;
    const auxRows = rowsActive;
    auxRow["archivo"] = exam;
    auxRow["observacion"] = observation;
    auxRows[dispatchGuide[1]] = auxRow;
    if (tempRow.id_envios_pedidos) {
      const formData = new FormData();
      formData.append("nombre", exam[0].name);
      formData.append("archivo", exam[0]);
      formData.append("tipo_archivo", "GuiaDespacho");
      formData.append("observacion", observation);
      formData.append("area", "Transporte");
      formData.append("id_pedidos", dispatchGuide[0].id_envios_pedidos.id_solicitud_pedido);

      api.post("/api/documentospedidos/", formData).then((request) => {
        setmessage("Guia de despacho adjuntada");
      })
    }
    setrowsActive(auxRows);
    setmessage("Datos guardados");
    handleClose();
  };

  const _content_ = <div>
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
      <h5 id="spring-modal-title">{`Datos del pedido`}</h5>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        item
        xs={12}
        style={{ margin: "10px 0 10x 0" }}
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          item
          md={3}
          sm={6}
          xs={12}
          style={{ padding: "5px" }}
        >
          <Typography variant="caption" display="block" gutterBottom>
            {tempRow.id_parada_traslado_ingreso && ( tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudRetiroEquipo" || tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudEntregaRetiroEquipo" )   ? `Empresa: ${tempRow.id_parada_traslado_ingreso.nombre_empresa}`
              : tempRow.id_parada_traslado_ingreso && tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudRetiroCompra" ?
                `Paciente: ${tempRow.id_parada_traslado_ingreso.nombre_empresa}` :`Paciente: ${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {tempRow.id_parada_traslado_ingreso
              ? tempRow.id_parada_traslado_ingreso.tipo_parada === "Inicio"
                ? `Dirección: ${tempRow.id_parada_traslado_ingreso.direccion_calle} ${tempRow.id_parada_traslado_ingreso.direccion_numero} ${tempRow.id_parada_traslado_ingreso.direccion_oficina}`
                : tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso
                    .tipo_producto === "Insumo" ||
                  tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso
                    .tipo_producto === "Equipo"
                ? `Dirección: Bodega Ingreso`
                : `Dirección: Bodega Farmacia`
              : `Dirección: ${patient.direccion_calle} ${patient.direccion_numero} casa/dpto ${patient.direccion_casa_depto}`}
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          item
          md={3}
          sm={6}
          xs={12}
          style={{ padding: "5px" }}
        >
          <Typography variant="caption" display="block" gutterBottom>
            {tempRow.id_parada_traslado_ingreso
              ? `Comuna: ${tempRow.id_parada_traslado_ingreso.direccion_comuna}`
              : `Comuna: ${patient.direccion_comuna}`}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {tempRow.id_parada_traslado_ingreso
              ? `Región: ${tempRow.id_parada_traslado_ingreso.direccion_region}`
              : `Región: ${patient.direccion_region}`}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {tempRow.id_parada_traslado_ingreso
              ? `Zona: -`
              : `Zona: ${tempRow.zona}`}
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          item
          md={3}
          sm={6}
          xs={12}
          style={{ padding: "5px" }}
        >
          <Typography variant="caption" display="block" gutterBottom>
            {`Fecha solicitud:`}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {`${
              tempRow.fecha_solicitud
                ? tempRow.fecha_solicitud
                : moment(tempRow.id_parada_traslado.fecha_hora).format(
                    "YYYY-MM-DD HH:mm"
                  )
            }`}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {tempRow.hora_estimada ?
            `Fecha a traslado:`:
            `Fecha a despachar:`
            }
            {}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {`${ tempRow.id_envios_pedidos ?
              moment(tempRow.id_envios_pedidos.fecha_tentativa_llegada).format(
                "YYYY-MM-DD HH:mm"
              ) : tempRow.fecha_tentativa_llegada?
                moment(tempRow.fecha_tentativa_llegada).format(
                "YYYY-MM-DD HH:mm"
                ) :  moment(tempRow.hora_estimada).format(
                "YYYY-MM-DD HH:mm"
                )

            }`}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {`Fecha estimada:`}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {`${
              tempRow.hora_estimada
                ? moment(tempRow.hora_estimada).format("DD-MM-YYYY HH:mm")
                : "-"
            }`}
          </Typography>
          {(tempRow.estado_recepcion === "Entregado" ||
            tempRow.estado_recepcion === "Retrasado") && (
            <Typography variant="caption" display="block" gutterBottom>
              {`Fecha entrega:`}
            </Typography>
          )}
          {(tempRow.estado_recepcion === "Entregado" ||
            tempRow.estado_recepcion === "Retrasado") && (
            <Typography variant="caption" display="block" gutterBottom>
              {`${moment(tempRow.hora_llegada).format("YYYY-MM-DD HH:mm")}`}
            </Typography>
          )}
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          item
          md={3}
          sm={6}
          xs={12}
          style={{ padding: "5px" }}
        >
          <Typography variant="caption" display="block" gutterBottom>
            {tempRow.id_envios_pedidos
              ? `Solicitud pedido: ${tempRow.id_envios_pedidos.id_solicitud_pedido}`
              : tempRow.id_parada_traslado
              ? `Solicitud paciente: ${tempRow.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}`
              : " "}
          </Typography>
          {tempRow.id_envios_pedidos && (
            <Typography variant="caption" display="block" gutterBottom>
              {tempRow.id_parada_traslado_ingreso
                ? `Caja: 1`
                : `Caja: ${
                    tempRow.id_envios_pedidos
                      ? tempRow.id_envios_pedidos.numero_pedido
                      : "-"}`}
            </Typography>
          )}
          <Typography variant="caption" display="block" gutterBottom>
            {`Tipo de solicitud: ${dictTypeRequest[tempRow.tipo_solicitud]?.label}`}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {`Estado pedido: ${dictSTValueLabel[tempRow.estado_recepcion]}`}
          </Typography>
          {tempRow.id_envios_pedidos && (
            <Typography variant="caption" display="block" gutterBottom>
              {`Medicamento: ${
                tempRow?.id_parada_traslado_ingreso?.id_solicitud_ingreso
                  .tipo_producto === "Medicamento" ||
                tempRow?.id_parada_traslado_ingreso?.id_solicitud_ingreso
                  .tipo_producto === "InsumoMedicamento" ||
                tempRow?.id_envios_pedidos?.es_farmacia === true
                  ? "Sí"
                  : "No"
              }`}
            </Typography>
          )}
          {infoRequest.length > 0 && (
            <Typography variant="caption" display="block" gutterBottom>
              {`Estado picking: ${
                infoRequest[0].id_envio_pedido
                  ? infoRequest[0].id_envio_pedido.id_solicitud_pedido
                      .estado_picking
                  : "-"
              }`}
            </Typography>
          )}
        </Grid>
      </Grid>
      <h5 id="spring-modal-title">{`Adjuntar guía de despacho`}</h5>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
        style={{ margin: "10px 0" }}
      >
        <DetailText
          label={`Observación`}
          value={observation}
          onchange={(e) => {
            setobservation(e.target.value);
          }}
        />
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-contain"
        item
        xs={8}
        style={{ margin: "10px 0" }}
      >
        <Button
          variant="contained"
          component="label"
          color="primary"
          className="text-white"
          style={{ margin: "0 10px" }}
        >
          Subir Archivo
          <TextField
            type="file"
            id="exam"
            onChange={(event) => setexam(event.target.files)}
            fullWidth
            my={2}
            style={{ display: "none" }}
          />
        </Button>
        {exam.length > 0 && `${exam[0].name}`}
      </Grid>
    </Grid>
  </div>

  const _actions_ = <>
    {exam.length > 0 && observation ? (
      <Button
        mr={2}
        variant="contained"
        className="text-white"
        color="primary"
        onClick={handleSaveSend}
      >
        Guardar y enviar
      </Button>
    ) : (
      <Button mr={2} variant="contained" disabled>
        Guardar y enviar
      </Button>
    )}

    {exam.length > 0 && observation ? (
      <Button
        mr={2}
        variant="contained"
        className="text-white"
        color="primary"
        onClick={handleSave}
      >
        Guardar
      </Button>
    ) : (
      <Button mr={2} variant="contained" disabled>
        Guardar
      </Button>
    )}
    <Button
      mr={2}
      variant="contained"
      style={{ backgroundColor: red[500], marginRight: "10px" }}
      className="text-white"
      onClick={handleClose}
    >
      Cerrar
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={`Adjuntar guía de despacho`}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}
