import React, { useState, useEffect, useRef } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import { green, indigo, orange, red  } from "@material-ui/core/colors";
import { Delete, Close} from "@material-ui/icons/";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import ModalDeleteItemTable from "./ModalDeleteItemTable";
import ExpansionPanelCommon from "views/Widgets/Common/ExpansionPanelCommon";
import TableComponent from "views/Widgets/Common/TableComponent";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailNumber2 from "views/Widgets/FichaPaciente/detailNumber2";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
    KeyboardDatePicker
} from "@material-ui/pickers";
import { getListRequestPatient } from 'actions/getListAction';
import {setmessageGeneral} from "actions/getGlobalAction";
import api from "utils/API";
import {dictMonth, daysList as days} from "utils/validationFunction";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import MomentUtils from "@date-io/moment";
import moment from 'moment-timezone';

import 'moment/locale/es' ;
moment.locale("es");

const useStyles = makeStyles(styles);


export default function ModalClinicWorkers({
    open,
    closeModal,
    dataPatient,
}) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const listChargesRoute = [
        {label: "Kinesiologo(a) Motor", value: "CLIKineMot"},
        {label: "Kinesiologo(a) Respiratorio", value: "CLIKineResp"},
        {label: "Kinesiologo(a) integral", value: "CLIKineIntegral"},
        {label: "Fonoaudiologo(a)", value: "CLIFono"},
        {label: "Enfermero(a)", value: "CLIEnfRuta"},
        {label: "Terapeuta Ocupacional", value: "CLITerOcu"},
        {label: "Tecnico Enfermería Basico", value: "CLITecEnfBas"},
        {label: "Médico", value: "CLIMed"},
    ]
    const limitChar = 4000;

    const [recharge, setrecharge] = useState(false)
    const [addCharge, setaddCharge] = useState({})
    const [listProffesional, setlistProffesional] = useState([])
    const [start, setstart] = useState({});
    const [end, setend] = useState({});
    const [startDate, setstartDate] = useState(moment());
    const [endDate, setendDate] = useState(moment().add(1, "days"));
    const [expandedPanel, setexpandedPanel] = useState(false);
    const [selectFrequency, setselectFrequency] = useState({});
    const [numberDays, setnumberDays] = useState(1);
    const [numberDayMonth, setnumberDayMonth] = useState(1);
    const [consecutiveDays, setconsecutiveDays] = useState(1);
    const [pauseDays, setpauseDays] = useState(0);
    const [selectDate, setselectDate] = useState(moment());
    const [openDeleteModal, setopenDeleteModal] = useState(null);
    const [openAddModal, setopenAddModal] = useState(null);
    const [changeHour, setchangeHour] = useState(true);
    const [listHour, setlistHour] = useState([]);
    const [selectDays, setselectDays] = useState([0, 1, 2, 3, 4, 5, 6]);

    const listFrequency = [
        {value: "diario", label: "Diario"},
        {value: "semanal", label: "Semanal"},
        {value: "mensual", label: "Mensual"},
        {value: "otra", label: "Otra"}
    ];

    const dictCharges = useSelector(state => state.currentList.dictCharges);

    useEffect(() => {
        if (changeHour){
            getTimeList();
        }
    });

    useEffect(() => {
        if(recharge){
            setrecharge(false)
        }
    })

    const handleCloseAdd = () => {
        setopenAddModal(false)
        setaddCharge({})
        setstart({})
        setend({})
    }

    const handleSave = () => {
        const tempDictCharges = Object.values(dictCharges).reduce((accum, obj) => ({
            ...accum,
            [obj.tipo_perfil]: obj
        }), {})

        const tempList = listProffesional.map((row) => {
            const tempCharge = tempDictCharges[row.charge.value]
            return {id_cargo_asignado_id: tempCharge.id, fecha_hora: row.dateInit, fecha_final: row.dateEnd}
        })

        const body = {tareas_extras: tempList }

        api.post(`api/paciente/solicitudes/extraprofesionales/${dataPatient.id}`, body).then((response) => {
            setmessageGeneral(dispatch, "Se ha enviado la solicitud de profesionales extras", "success", "¡Exito!")
            getListRequestPatient(dataPatient, dispatch)
            closeModal()
        })
    }

    const handleDeleteRow = () => {
        const index = openDeleteModal.index;
        let tempData = listProffesional
        tempData = tempData.filter((temprow, tempIndex) => index !== tempIndex )
        setlistProffesional(tempData);
        setopenDeleteModal(null);
    };

    const handleUpdateCharge = (value, index) => {
        const tempList = [...listProffesional];
        tempList[index].charge = value;
        setlistProffesional(tempList);
    }



    const headers = [
        {label: "Cargo"},
        {label: "Fecha inicio"},
        {label: "Fecha fin"},
        {eval: false, label: "Acción"},
    ];

    const data = listProffesional.map((row, index) => {
        const _selectCharge_ =  <DetailSelect
            value={row?.charge || {}}
            onchange={(value) => handleUpdateCharge(value, index)}
            list={listChargesRoute}
        />
        const _buttonDelete_ = <Button
            variant="outlined"
            style={{
                color: red[500],
                borderColor: red[500],
            }}
            onClick={() => setopenDeleteModal({row, index: index})}
            startIcon={<Delete style={{color: red[500]}}/>}
        >
            Eliminar
        </Button>

        return [
            {_value_: _selectCharge_ },
            {_value_: moment(row.dateInit).format("YYYY-MM-DD hh:mm")},
            {_value_: moment(row.dateEnd).format("YYYY-MM-DD hh:mm")},
            {_value_: _buttonDelete_},
        ]
    });


    const _content_ = <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
            style={{margin: "0 0 32px 0"}}
        >
            <Button
                variant="contained"
                color="primary"
                onClick={() => setopenAddModal(true)}
            >
                {`Agregar Profesional Extra`}
            </Button>
        </Grid>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
            style={{margin: "0 0 32px 0"}}
        >
            <TableComponent
                headers={headers}
                data={data}
            />
        </Grid>

        </Grid>
    </div>

    const _actions_ = <>
        {(listProffesional.length > 0 && listProffesional.every((row) => row?.charge?.value))?
        <Button
            style={{
                background: green[500],
                color: green[50],
                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
            }}
            onClick={() => handleSave()}
        >
            Solicitar
        </Button> :
        <Button
            disabled
        >
            Solicitar
        </Button>}
        <Button
            style={{
                background: red[500],
                color: red[50],
                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
            }}
            onClick={() => closeModal()}
        >
            Cerrar
        </Button>
    </>

    const getTimeList = () => {
        setchangeHour(false);
        const tempListHour = [];

        let tempDate =  moment(selectDate).format("YYYY-MM-DD");
        let countDate = moment(tempDate);
        let tempDateEnd = moment(tempDate).add(24, "hours")

        while (moment(tempDateEnd).diff(countDate, "minutes") >= 0){
            const tempHour = moment(countDate).format("HH:mm");
            tempListHour.push({value: tempHour, label: tempHour});
            countDate = moment(countDate).add(30, "minutes");
        }
        setlistHour(tempListHour);
    };

    const handleSelectHour= (value, argDate, set) => {
        const tempDate =  moment(selectDate).format("YYYY-MM-DD");
        let auxDate = moment(`${tempDate} ${value.value}:0`);
        let compareDate = null;
        if (argDate === "start"){
            compareDate = moment(`${tempDate} ${end.value}:0`);
            if (moment(compareDate).diff(auxDate, "minutes") < 0 ){
                let auxEndTime = moment(auxDate).add(30, "minutes");
                auxEndTime = moment(auxEndTime).format("HH:mm");
                setend({value:auxEndTime, label: auxEndTime});
            }
        } else {
            compareDate = moment(`${tempDate} ${start.value}:0`);
            if (moment(auxDate).diff(compareDate, "minutes") < 0){
                let auxStartTime = moment(auxDate).subtract(30, "minutes");
                auxStartTime = moment(auxStartTime).format("HH:mm");
                setstart({value:auxStartTime, label: auxStartTime});
            }
        }
        const valueFormat = moment(auxDate).format("HH:mm")
        set({value:valueFormat, label: valueFormat});
    };

    const handleFrequency = (value) => {
        setselectFrequency(value)
        if (value.value === "diario"){
            setendDate(moment(startDate).add(1, "days"));
        } else if (value.value === "semanal"){
            setendDate(moment().add(1, "weeks"));
        } else if (value.value === "mensual"){
            setendDate(moment(startDate).add(1, "months"));
        }
    };

    const handleSelectDay = (button) => {
        let tempArray = selectDays;
        if (selectDays.includes(button)) {
          tempArray = selectDays.filter((row) => row != button);
        } else {
          tempArray.push(button);
        }
        setselectDays(tempArray)
    };

    const handleAdd = () => {
        let tempDateArray = [];
        if (expandedPanel){
            const tempEndDate = moment(endDate);
            let dateCondition = moment(startDate);
            if (selectFrequency.value === "diario"){
                let countDay = 0;
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    if (countDay% parseInt(numberDays) === 0){
                        tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    }
                    countDay += 1;
                    dateCondition = dateCondition.add(1, "days");
                }
            } else if (selectFrequency.value === "semanal"){
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    if (selectDays.includes(dateCondition.day())){
                        tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    }
                    dateCondition = dateCondition.add(1, "days");
                }
            } else if (selectFrequency.value === "mensual"){
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    if (parseInt(moment(dateCondition).format("DD")) === parseInt(numberDayMonth)){
                        tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    }
                    dateCondition = dateCondition.add(1, "days");
                }
            } else if (selectFrequency.value === "otra"){
                let checkConsecutiveDay = 0;
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    checkConsecutiveDay += 1;
                    dateCondition = dateCondition.add(1, "days");
                    if (checkConsecutiveDay >= parseInt(consecutiveDays)){
                        checkConsecutiveDay = 0
                        dateCondition = dateCondition.add(pauseDays, "days");
                    }
                }
            }
        } else {
            tempDateArray.push(moment(selectDate).format("YYYY-MM-DD"));
        }

        const auxDateArray = tempDateArray.map((row) => {
            const tempInit = `${row} ${start.value}:00`;
            const tempEnd = `${row} ${end.value}:00`;
            return {
                charge: addCharge,
                dateInit: tempInit,
                dateEnd: tempEnd,
            }
        })
        setlistProffesional([...listProffesional, ...auxDateArray]);
        handleCloseAdd();
    };

    const _expandContent_ = <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        item
    >
        <DetailSelect
            label="Frecuencia:"
            value={selectFrequency}
            onchange={(value) => handleFrequency(value)}
            list={listFrequency}
        />
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha Inicio"}
                placeholder="Fecha Inicio"
                onChange={setstartDate}
                maxDate={moment(endDate)}
                value={startDate}
                inputVariant={"outlined"}
            />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha Término"}
                placeholder="Fecha Término"
                minDate={moment(startDate)}
                onChange={setendDate}
                value={endDate}
                inputVariant={"outlined"}
            />
        </MuiPickersUtilsProvider>
        {selectFrequency.value === "diario" &&
        <DetailNumber2
            label={"Cada cuantos días"}
            value={numberDays}
            onchange={e => {
            if (/^[0-9\b]+$/.test(e.target.value)) {
                setnumberDays(e.target.value);
            }
            }}
        />
        }
        {selectFrequency.value === "semanal" &&
        <Grid item xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            {days.map((row, index) => (
                <Button
                    key={`${index}-daybuttons`}
                    justIcon
                    round
                    size="sm"
                    style={{
                        background: selectDays.includes(row.value) ?  indigo[500] :  indigo[50],
                        color: selectDays.includes(row.value) ?  indigo[50] :  indigo[500],
                    }}
                    onClick={() => handleSelectDay(row.value)}
                >
                    {row.label}
                </Button>
            ))}
            {selectDays.length === 0 &&
                <Typography variant="caption" style={{color: red[500]}} gutterBottom>
                    A lo menos 1 día seleccionado
                </Typography>
            }
        </Grid>
        }
        {selectFrequency.value === "mensual" &&
        <DetailNumber2
            label={"Dia de cada mes"}
            value={numberDayMonth}
            max={"31"}
            onchange={e => {
            if (/^[0-9\b]+$/.test(e.target.value)) {
                setnumberDayMonth(e.target.value);
            }
            }}
        />
        }
        {selectFrequency.value === "otra" &&
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0"}}
        >
            <Grid item xs={5} style={{margin: "10px 0"}} >
                <DetailNumber2
                    label={"Días seguidos"}
                    min={"1"}
                    value={consecutiveDays}
                    onchange={e => {
                        if (/^[0-9\b]+$/.test(e.target.value)) {
                            setconsecutiveDays(e.target.value);
                        }
                    }}
                />
            </Grid>
            <Grid  item xs={2} style={{margin: "10px 0"}} >
                <Button justIcon round color="primary">
                    <Close className={classes.icons} />
                </Button>
            </Grid>
            <Grid item xs={5} style={{margin: "10px 0"}}
            >
                <DetailNumber2
                    label={"Días de descanso"}
                    min={"1"}
                    value={pauseDays}
                    onchange={e => {
                        if (/^[0-9\b]+$/.test(e.target.value)) {
                            setpauseDays(e.target.value);
                        }
                    }}
                />
            </Grid>
        </Grid>
        }
    </Grid>

    const _contentAdd_ =  <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
    >
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0 10x 0"}}
        >

            <DetailSelect
                value={addCharge || {}}
                onchange={(value) => setaddCharge(value)}
                list={listChargesRoute}
                label="Personal:"
            />
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    label={"Fecha hora"}
                    placeholder="Fecha hora"
                    onChange={setselectDate}
                    value={selectDate}
                    inputVariant={"outlined"}
                />
            </MuiPickersUtilsProvider>       
            <DetailSelect
                label="Horario inicio:"
                value={start}
                onchange={(value) => handleSelectHour(value, "start", setstart)}
                list={listHour.slice(0, listHour.length - 1)}
            />
            <DetailSelect
                label="Horario fin:"
                value={end}
                onchange={(value) => handleSelectHour(value, "end", setend)}
                list={listHour.slice(1, listHour.length)}
            />
        </Grid>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0 0 0"}}
        >
            <ExpansionPanelCommon
                title= "Definir recurrencia"
                content={_expandContent_}
                handleChange={(expand) => setexpandedPanel(expand)}
            />
        </Grid>
    </Grid>

    const _actionAdd_ = <>
        {(start?.value && end?.value && addCharge?.value) ?
        <Button
            style={{
                background: green[500],
                color: green[50],
                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
            }}
            onClick={() => handleAdd()}
        >
            Agregar
        </Button> : 
        <Button
            disabled={true}
         
        >
            Agregar
        </Button>  
        }
        <Button
            style={{
                background: red[500],
                color: red[50],
                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
            }}
            onClick={() => handleCloseAdd()}
        >
            Cerrar
        </Button>
    </>

    return (
        <>
        <ModalDialog
            open={open}
            onClose={closeModal}
            title={`Personal Clinico`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xl"
        />
        {openDeleteModal && (
            <ModalDeleteItemTable
                open={!!openDeleteModal}
                handleClose={() => setopenDeleteModal(null)}
                handleDelete={handleDeleteRow}
                title={`Eliminar reserva ${openDeleteModal.charge}`}
            />
        )}
        {openAddModal && (
            <ModalDialog
                open={openAddModal}
                onClose={() => setopenAddModal(false)}
                title={`Agregar personal clinico`}
                _content_={_contentAdd_}
                _actions_ = {_actionAdd_}
                maxWidth="xs"
            />
        )}

        </>
    );
};

