import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {  setmessageAlert } from "actions/getGlobalAction";
import { useSnackbar } from 'notistack';
import {ButtonTextMUI as Button} from "utils/componentsStyle";



export default function AlertMessage (props){
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const generalMessage = useSelector(state => state.currentGlobalVar.messageAlert);
  const typeMessage = useSelector(state => state.currentGlobalVar.messageType);
  const titleMessage = useSelector(state => state.currentGlobalVar.messageTitle);
  const placeMessage = useSelector(state => state.currentGlobalVar.messagePlace);


  const auxPlaceMessage = (placeMessage.vertical && placeMessage.horizontal) ? placeMessage : { vertical: 'bottom', horizontal: 'left' }

  const handleClose = (key) => {
    closeSnackbar(key);
    setmessageAlert(dispatch, "", "", "")
  }

  const action = key => (
    <Button style={{background: "#af4c4c00",  color: "#fff"}} onClick={() => {handleClose(key)}}>
        CERRAR
    </Button>
);

  useEffect(() => {
    if (generalMessage != "" & typeMessage !== "" ){
      enqueueSnackbar(
        generalMessage,
        {
          variant: typeMessage,
          persist: true,
          action,
          preventDuplicate: true,
          anchorOrigin: auxPlaceMessage,
        }
      )
    }
  }, [generalMessage, typeMessage, placeMessage]);

  return (
    <>
    </>
  );
}