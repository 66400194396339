import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import ModalCreateCovenant from "components/Covenants/ModalCreateCovenant";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import ReceptionsInformation from "./ReceptionsInformation";
import TableReturns from "./Tables/TableReturns";
import TableRevenues from "./Tables/TableRevenues";


const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = key => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const useSortableDataIncome = (itemsIncome, config = null) => {
  const [sortConfigIncome, setSortConfigIncome] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...itemsIncome];
    if (sortConfigIncome !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfigIncome.key] < b[sortConfigIncome.key]) {
          return sortConfigIncome.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigIncome.key] > b[sortConfigIncome.key]) {
          return sortConfigIncome.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [itemsIncome, sortConfigIncome]);

  const requestSortIncome = key => {
    let direction = "ascending";
    if (
      sortConfigIncome &&
      sortConfigIncome.key === key &&
      sortConfigIncome.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfigIncome({ key, direction });
  };

  return { itemsIncome: sortedItems, requestSortIncome, sortConfigIncome };
};

export default function WareHouseReceptions() {

  const [moduleType, setmoduleType] = useState("");
  const [orders, setOrders] = useState([]);
  const [motivo, setMotivo] = useState("");
  const [estado, setEstado] = useState("");
  const [toCome, setToCome] = useState([]);
  const [finish, setFinish] = useState([]);

  const { items, requestSort, sortConfig } = useSortableData(orders);
  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const { itemsIncome, requestSortIncome, sortConfigIncome } = useSortableDataIncome(toCome);
  const getClassNamesForIncome = name => {
    if (!sortConfigIncome) {
      return;
    }
    return sortConfigIncome.key === name
      ? sortConfigIncome.direction
      : undefined;
  };

  const [search, setSearch] = useState("");
  const [searchIncome, setSearchIncome] = useState("");

  const [loading, setLoading] = useState(true);
  const [modal_create_covenant, setModal_create_covenant] = useState(false);
  const [value, setValue] = useState(0);
  const [changes, setChanges] = useState(true);
  const [checkTab, setCheckTab] = useState("");

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    setCheckTab(value);

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);
    const tempArrayURL = window.location.href.split('/');
    setmoduleType(tempArrayURL[tempArrayURL.length-1]);
    const returns = api.get("api/bodega/ingresos/Devolucion/Activo/");
    const income = api.get("api/bodega/ingresos/Compra/Activo/");
    const finish = api.get("api/bodega/ingresos/Todos/Finalizado/");

    Promise.all([returns, income, finish])
      .then(response => {
        setOrders(response[0].data);
        setToCome(response[1].data);
        setFinish(response[2].data);

      })

  };

  let searchOrders = items.filter(order => {
    return (
      order.id
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      order.cantidad_productos
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      order.id_solicitud_ingreso.tipo_origen.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      order.fecha_tentativa_llegada.toLowerCase().indexOf(search.toLowerCase()) !==
        -1 ||
      order.estado_solicitud.toLowerCase().indexOf(search.toLowerCase()) !==
        -1 ||
      order.id_solicitud_ingreso.tipo_producto.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      order.producto.descripcion.toLowerCase().indexOf(search.toLowerCase()) !==
        -1 ||
      order.producto.SKU.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  let searchIncomes = itemsIncome.filter(order => {
    return (
      order.id
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      order.cantidad_productos
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      order.id_solicitud_ingreso.tipo_origen.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      order.fecha_tentativa_llegada.toLowerCase().indexOf(search.toLowerCase()) !==
        -1 ||
      order.estado_solicitud.toLowerCase().indexOf(search.toLowerCase()) !==
        -1 ||
      order.id_solicitud_ingreso.tipo_producto.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      order.producto.descripcion.toLowerCase().indexOf(search.toLowerCase()) !==
        -1 ||
      order.producto.SKU.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  let searchFinish = finish.filter(order => {
    return (
      order.id
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      order.cantidad_productos
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      order.id_solicitud_ingreso.tipo_origen.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      order.fecha_tentativa_llegada.toLowerCase().indexOf(search.toLowerCase()) !==
        -1 ||
      order.estado_solicitud.toLowerCase().indexOf(search.toLowerCase()) !==
        -1 ||
      order.id_solicitud_ingreso.tipo_producto.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      order.producto.descripcion.toLowerCase().indexOf(search.toLowerCase()) !==
        -1 ||
      order.producto.SKU.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  return (
    <GridContainer>
      <ViewTitle
        title="RECEPCIÓN DE INGRESOS Y DEVOLUCIONES"
        message="En esta sección podrás revisar las recepciones de ingresos y devoluciones."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Typography color="textPrimary">
              Recepción de ingresos y devoluciones
            </Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="mt-5">
              <GridContainer justify="center">
                <ReceptionsInformation />
              </GridContainer>
              {checkTab === 0 ? (
                <TextField
                  id="outlined-basic"
                  label="Buscar devoluciones"
                  variant="outlined"
                  value={search}
                  className="mt-5"
                  onChange={e => setSearch(e.target.value.substr(0, 20))}
                />
              ) : checkTab === 1 ? (
                <TextField
                  id="outlined-basic"
                  label="Buscar ingresos"
                  variant="outlined"
                  value={searchIncome}
                  className="mt-5"
                  onChange={e => setSearchIncome(e.target.value.substr(0, 20))}
                />
              ) : (
                <TextField
                  id="outlined-basic"
                  label="Buscar finalizadas"
                  variant="outlined"
                  value={searchFinish}
                  className="mt-5"
                  onChange={e => setSearchIncome(e.target.value.substr(0, 20))}
                />
              )}
            </GridContainer>
            <CardBody className="mb-3">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  centered
                >
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Devoluciones"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Ingresos"
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Finalizados"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <TableReturns
                  searchOrders={searchOrders}
                  requestSort={requestSort}
                  getClassNamesFor={getClassNamesFor}
                  motivo={motivo}
                  setMotivo={setMotivo}
                  estado={estado}
                  setEstado={setEstado}
                  moduleType={moduleType}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableRevenues
                  searchIncomes={searchIncomes}
                  requestSortIncome={requestSortIncome}
                  getClassNamesForIncome={getClassNamesForIncome}
                  moduleType={moduleType}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TableRevenues
                  searchIncomes={searchFinish}
                  requestSortIncome={requestSortIncome}
                  getClassNamesForIncome={getClassNamesForIncome}
                  moduleType={moduleType}
                />
              </TabPanel>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modal_create_covenant && (
        <ModalCreateCovenant
          open={modal_create_covenant}
          closeModal={() => setModal_create_covenant(false)}
        />
      )}
    </GridContainer>
  );
}
