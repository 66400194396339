import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Grid
  } from "@material-ui/core";
import {
  red,
  green,
  indigo,
} from "@material-ui/core/colors";
import { ExpandMore } from "@material-ui/icons";
import {TypographyMui as Typography} from "utils/componentsStyle";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import api from "utils/API";
import moment from "moment-timezone";
import {toMoneyFormat} from "utils/formatFunctions";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);
const classStyle = makeStyles((theme) => ({
  text: {
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    "&:hover": {
      color: indigo[400],
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Variables = ({
    validationAFPTax,
    setvalidationAFPTax,
    listAFPTax,
    setlistAFPTax,
    monthDate,
    changeButtons,
    setchangeButtons,
    setopenBackdrop,
}) => {
    const classes = useStyles();
    const classesStyle = classStyle();
    const [expandedPanel, setexpandedPanel] = useState(null);
    const [openEditAFPTax, setopenEditAFPTax] = useState(null);
    const UTM = useSelector(state => state.currentGlobalVar.UTM);
    const UF = useSelector(state => state.currentGlobalVar.UF);
    const UF2 = useSelector(state => state.currentGlobalVar.UF2);
    const TUF = useSelector(state => state.currentGlobalVar.TUF);
    const TUF2 = useSelector(state => state.currentGlobalVar.TUF2);
    const minSalary = useSelector(state => state.currentGlobalVar.minSalary);
    const listQuotationAFP = useSelector(state => state.currentGlobalVar.listQuotationAFP);

    const validationSoftland = [
        UTM !== 0,
        UF !== 0,
        UF2 !== 0,
        TUF !== 0,
        TUF2 !== 0,
        minSalary !== 0,
        listQuotationAFP ? listQuotationAFP.length > 0 : false,
    ].every((item) => item === true)

    useEffect(() => {
        initialData();
    }, [])

    const initialData = () => {
    };

    const handleEditAFPTax = (value, index, key) => {
        let tempValue = parseFloat(value)
        const tempDataArray = [...listAFPTax];
        if (tempDataArray[index]["tasa_cotizacion_afp"]){
            tempDataArray[index]["tasa_cotizacion_afp"][key] = tempValue
        } else {
            tempDataArray[index]["tasa_cotizacion_afp"] = {[key]: tempValue}
        }
        setlistAFPTax(tempDataArray);
    }

    const handleUpdateAFPTax = () => {

        const body = {
            sis: openEditAFPTax.tasa_cotizacion_afp.sis,
            tasa_dependientes: openEditAFPTax.tasa_cotizacion_afp.tasa_dependientes,
            tasa_independientes: openEditAFPTax.tasa_cotizacion_afp.tasa_independientes,
            id_afp: openEditAFPTax.id,
        };

        api.post(`api/remuneracion/tazacotizacionafp/fecha/${moment(monthDate).format("YYYY-MM-DD")}`, body).then((response) => {
            setvalidationAFPTax(listAFPTax.every((item) => item["tasa_cotizacion_afp"]));
            setopenEditAFPTax(null);
            setchangeButtons(true);

          });
    };

    return (
        <div>
        {changeButtons === false && <div></div>}
        <Grid item xs={12}>
            <ExpansionPanel
                square
                expanded={expandedPanel === "panel3"}
                onChange={() => setexpandedPanel(expandedPanel === "panel3" ? "" : "panel3")}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={classesStyle.title}
                >
                    <Typography
                        style={validationAFPTax ? {} : {color: red[500]} }
                        className={classesStyle.text}
                        variant="body1"
                        gutterBottom
                    >
                        {`Tasa cotización AFP${validationAFPTax ? "" : "!!!! No ingresado"}`}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                   <Grid item
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        xs={12}
                        style={{margin: "10px 0"}}
                    >
                        <TableContainer style={{height: "440px"}}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="text-center align-top">
                                            AFP
                                        </TableCell>
                                        <TableCell className="text-center align-top">
                                            Tasa dependientes
                                        </TableCell>
                                        <TableCell className="text-center align-top">
                                            SIS
                                        </TableCell>
                                        <TableCell className="text-center align-top">
                                            Tasa independientes
                                        </TableCell>
                                        <TableCell className="text-center align-top">
                                            Acciones
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {listAFPTax.map((row, index) => (
                                    <TableRow
                                        key={`${index}-workRows`}
                                    >
                                        <TableCell className="text-center align-middle">
                                            {row.nombre}
                                        </TableCell>
                                        <TableCell className="text-center align-middle">
                                            {openEditAFPTax?.id === row.id ?
                                            <DetailNumber
                                                isdisabled={false}
                                                value={row.tasa_cotizacion_afp?.tasa_dependientes || 0}
                                                onchange={e => handleEditAFPTax(e.target.value, index, "tasa_dependientes")}
                                            />
                                            :
                                            <DetailNumber
                                                isdisabled={true}
                                                value={row.tasa_cotizacion_afp?.tasa_dependientes || 0}
                                                onchange={e => handleEditAFPTax(e.target.value, index, "tasa_dependientes")}
                                            />
                                            }
                                        </TableCell>
                                        <TableCell className="text-center align-middle">
                                        {openEditAFPTax?.id === row.id ?
                                            <DetailNumber
                                                isdisabled={false}
                                                value={row.tasa_cotizacion_afp?.sis || 0}
                                                onchange={e => handleEditAFPTax(e.target.value, index, "sis")}
                                            />
                                            :
                                            <DetailNumber
                                                isdisabled={true}
                                                value={row.tasa_cotizacion_afp?.sis || 0}
                                                onchange={e => handleEditAFPTax(e.target.value, index, "sis")}
                                            />
                                        }
                                        </TableCell>
                                        <TableCell className="text-center align-middle">
                                        {openEditAFPTax?.id === row.id ?
                                            <DetailNumber
                                                isdisabled={false}
                                                value={row.tasa_cotizacion_afp?.tasa_independientes || 0}
                                                onchange={e => handleEditAFPTax(e.target.value, index, "tasa_independientes")}
                                            />
                                            :
                                            <DetailNumber
                                                isdisabled={true}
                                                value={row.tasa_cotizacion_afp?.tasa_independientes || 0}
                                                onchange={e => handleEditAFPTax(e.target.value, index, "tasa_independientes")}
                                            />
                                        }
                                        </TableCell>
                                        <TableCell className="text-center align-middle">
                                            {openEditAFPTax?.id === row.id ?
                                            <Button
                                                mr={2}
                                                variant="contained"
                                                className="text-white"
                                                onClick={() => handleUpdateAFPTax(row)}
                                                style={{margin: "0 5px", background: green[500]}}
                                                >
                                                Guardar
                                            </Button> :
                                            openEditAFPTax ?
                                            <Button
                                                disabled
                                                mr={2}
                                                variant="contained"
                                                >
                                                Editar
                                            </Button> :
                                            <Button
                                                mr={2}
                                                variant="contained"
                                                className="text-white"
                                                onClick={() => setopenEditAFPTax(row)}
                                                style={{margin: "0 5px", background: green[500]}}
                                                >
                                                Editar
                                            </Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {changeButtons && <TableRow></TableRow>}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
            <ExpansionPanel
                square
                expanded={expandedPanel === "panel1"}
                onChange={() => setexpandedPanel(expandedPanel === "panel1" ? "" : "panel1")}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={classesStyle.title}
                >
                    <Typography
                        style={validationSoftland ? {} : {color: red[500]} }
                        className={classesStyle.text}
                        variant="body1"
                        gutterBottom
                    >
                        {`Variables Softland ${validationSoftland ? "" : "!!!! No ingresado"}`}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid
                        item
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        xs={12}
                    >
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>UF:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(UF) }`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>UF Seguro:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(UF2) }`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>Tope UF:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(TUF)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>Tope UF seguro:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(TUF2)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>UTM:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(UTM)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>Salario Mínimo:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(minSalary)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            xs={12}
                            style={{margin: "10px 0"}}
                        >
                            <TableContainer style={{height: "440px"}}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="text-center align-top">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    <b>Tramo</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-top">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    <b>Desde</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-top">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    <b>Hasta</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-top">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    <b>Tasa</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-top">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    <b>Rebaja Impuesto</b>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {listQuotationAFP && listQuotationAFP.map((row, index) => (
                                        <TableRow
                                            key={`${index}-workRows`}
                                        >
                                            <TableCell className="text-center align-middle">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                {row.Tramo}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-middle">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    {row.TopeMin}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-middle">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    {row.TopeMax}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-middle">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    {row.Porcentaje}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-middle">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    {row.CantRebajar}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>
        </div>
    );
};

export default Variables;
