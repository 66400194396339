import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import {
  TextField,
  Grid
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TableComponent from "views/Widgets/Common/TableComponent";
import {ButtonTextMUI} from "utils/componentsStyle";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import { red, green, indigo, orange } from "@material-ui/core/colors";
import {toMoneyFormat} from "utils/formatFunctions";
import {dictMonth} from "utils/validationFunction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);


const PatientBillings = ({
  patientRow,
  changeButtons,
  setchangeButtons,
  handleBilling,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [patientDetail, setpatientDetail] = useState([]);
  const [billingsType, setbillingsType] = useState([]);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [changeFilter, setchangeFilter] = useState(true);
  const [diagnostic, setdiagnostic] = useState("");
  const [editDiagnostic, seteditDiagnostic] = useState(true);

  useEffect(() => {
    if (changeFilter) {
      updateFilter();
    }
  });

  useEffect(() => {
    api.get(`api/diagnostico/?ficha_paciente=${patientRow.id_ficha_paciente_id}&tipo_diagnostico=Facturacion`).then((response) => {
      const auxList = response.data
      const lastElement = auxList[auxList.length - 1];
      if (lastElement){
        setdiagnostic(lastElement.diagnostico)
      }

    })
  }, []);


  const handleBillingsType = (button) => {
    let tempBillingsType = [...billingsType];
    if (billingsType.includes(button)) {
      tempBillingsType = billingsType.filter((row) => row != button);
    } else {
      tempBillingsType.push(button);
    }
    setbillingsType(tempBillingsType);
    setchangeFilter(true);
  }

  const handleDate = (value, set) => {
    set(value);
    setchangeFilter(true);
  };

  const updateFilter = () => {
    setchangeFilter(false);

    let paramString = `?ficha_paciente=${patientRow.id_ficha_paciente_id}`;

    if (billingsType.length > 0){
      billingsType.map((row) => {
        if (paramString=== "?"){
          paramString = `${paramString}tipo_factuacion=${row}`
        } else {
          paramString = `${paramString}&tipo_factuacion=${row}`;
        }
      });
    }
    if (startDate){
      const tempStartDate = moment(startDate).format("YYYY-MM-DDTHH:mm:ss");
      if (paramString=== "?"){
        paramString = `${paramString}fecha_inicio=${tempStartDate}`
      } else {
        paramString = `${paramString}&fecha_inicio=${tempStartDate}`;
      }
    }
    if (endDate){
      const tempEndDate = moment(endDate).format("YYYY-MM-DDTHH:mm:ss");
      if (paramString==="?"){
        paramString = `${paramString}fecha_fin=${tempEndDate}`;
      } else {
        paramString = `${paramString}&fecha_fin=${tempEndDate}`;
      }
    };
    api.get(`api/facturaciones/${paramString}`).then((response) => {
      setpatientDetail(response.data);
      setchangeButtons(true);
    });
  };

  const updateDiagnosticPatient = () => {
    const body = {
      diagnostico: diagnostic,
      id_ficha: patientRow.id_ficha_paciente_id,
      tipo_diagnostico: "Facturacion",
    }
    api.post(`api/diagnostico/`, body).then((result) => {
      setdiagnostic(result.data.diagnostico)
    });
    seteditDiagnostic(true);
  }

  const dateFormat = (row) => {
    const endDate = moment(row.fin_periodo);
    const startDate = moment(row.inicio_periodo);
    const startFormat = `${startDate.format("DD")} de ${dictMonth[parseInt(startDate.format("MM"))]} ${startDate.format("YYYY")}`;
    const endFormat = `${endDate.format("DD")} de ${dictMonth[parseInt(endDate.format("MM"))]} ${endDate.format("YYYY")}`;
    return `${startFormat} al ${endFormat}`;
  };

  const headerTable = [
    {label: "Nombre paciente"},
    {label: "Tipo de paciente"},
    {label: "Isapre"},
    {label: "Tipo de facturación"},
    {label: "Monto facturado"},
    {label: "Modificado"},
    {label: "Generado"},
    {eval:false, label: "Acción"},
  ]

  const data = patientDetail.map((row) => {

    const _detailButton_ =  <ButtonTextMUI
      style={{ background: indigo[500] }}
      className="text-white"
      onClick={() => handleBilling(row)}
    >
      Detalle
    </ButtonTextMUI>

    return [
      {value: row.nombre_paciente},
      {value: row.tipo_paciente },
      {value: row.nombre_isapre},
      {value: row.tipo_factuacion},
      {value: `${toMoneyFormat(row.monto_facturado_modificado+row.paquete_insumos+row.paquete_medicamentos)}`},
      {value: dateFormat(row)},
      {value: row.modificado ? "Sí" : "No"},
      {value: row.generado ? "Sí" : "No"},
      {_value_: _detailButton_}
    ]
  })

  return (

    <Card>
      <CardBody>
        <GridItem
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid container direction="row" justify="center" alignItems="center" md={12} lg={6}>
            <Grid xs={12} md={8} lg={9} style={{padding: "0px 10px"}}>
              <TextField
                variant="outlined"
                type="text"
                style={{ width: '100%' }}
                value={diagnostic}
                label={"Diagnostico Facturación"}
                onChange={(e) => setdiagnostic(e.target.value)}
                disabled={editDiagnostic}
              />
            </Grid>
            <Grid xs={12} md={4} lg={3}>
              {(editDiagnostic)?
              <ButtonTextMUI
                style={{ background: orange[500] }}
                className="text-white"
                onClick={() => seteditDiagnostic(false)}
              >
                Editar
            </ButtonTextMUI> :
            diagnostic.length == 0 ?
              <ButtonTextMUI
                  className="text-white"
                >
                  Guardar
              </ButtonTextMUI> :
              <ButtonTextMUI
                style={{ background: green[500] }}
                className="text-white"

                onClick={() => updateDiagnosticPatient()}
              >
                Guardar
            </ButtonTextMUI>
            }
            </Grid>
          </Grid>
          <Grid xs={6} md={4} lg={2}>
            <div className={classes.cardContentRight}>
              <div className={classes.buttonGroup}>
                <Button
                  color={
                    billingsType.includes("CMH")
                      ? "rose"
                      : "info"
                  }
                  className={classes.firstButton}
                  onClick={() =>
                    handleBillingsType("CMH")
                  }
                >
                  CMH
                </Button>
                <Button
                  color={
                    billingsType.includes("MHS")
                      ? "rose"
                      : "info"
                  }
                  className={classes.firstButton}
                  onClick={() =>
                    handleBillingsType("MHS")
                  }
                >
                  MHS
                </Button>
              </div>
            </div>
          </Grid>
          <Grid xs={6} md={4} lg={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha Inicio"}
                placeholder="Fecha Inicio"
                onChange={(e) => handleDate(e, setstartDate)}
                value={startDate ? moment(startDate) : null}
                inputVariant={"outlined"}
              />
            </MuiPickersUtilsProvider>

          </Grid>
          <Grid xs={6} md={4} lg={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha fin"}
                placeholder="Fecha fin"
                onChange={(e) => handleDate(e, setendDate)}
                value={endDate ? moment(endDate) : null}
                inputVariant={"outlined"}
              />
            </MuiPickersUtilsProvider>
          </Grid>

        </GridItem>
        {changeButtons === false && <div></div>}
        <GridItem xs={12}>
          <TableComponent
            headers={headerTable}
            data={data}
          />
        </GridItem>
      </CardBody>
    </Card>

  );
};

export default PatientBillings;
