import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Typography, Grid } from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

class ComponentToPrint extends React.Component {
  render() {
    const { nurseInfo, dataPatient } = this.props;
    return (
      <Grid container style={{ flexGrow: 1 }}>
        <Grid item xs={12} container style={{ marginTop: "3em" }}>
          <Grid item xs={1} />
          <Grid item xs={10} container justify="center">
            <Grid
              item
              xs={12}
              container
              spacing={1}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={8}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-disabled"
                    label="NombrePaciente"
                    value={`${dataPatient.nombre} ${dataPatient.apellido_paterno} ${dataPatient.apellido_materno}`}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-disabled"
                    label="Fecha"
                    variant="outlined"
                    value={moment(nurseInfo?.fecha_inicio).format("YYYY-MM-DD")}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={3}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-disabled"
                    label="InicioVisita"
                    variant="outlined"
                    value={moment(nurseInfo?.fecha_inicio).format("HH:mm:ss")}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-disabled"
                    label="TérminoVisita"
                    variant="outlined"
                    value={moment(nurseInfo?.fecha_fin).format("HH:mm:ss")}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-basic"
                    label="PresenciaFamiliar"
                    variant="outlined"
                    value={nurseInfo?.presencia_familiar || " "}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-disabled"
                    label="PersonalenTurno"
                    variant="outlined"
                    value={(nurseInfo['profesional']?.first_name+" "+nurseInfo['profesional']?.last_name+" "+nurseInfo['profesional']?.second_last_name ) || " "}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-disabled"
                    label="PersonalenTurno"
                    variant="outlined"
                    value={(nurseInfo['profesional_tens']?.first_name+" "+nurseInfo['profesional_tens']?.last_name+" "+nurseInfo['profesional_tens']?.second_last_name )|| " "}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-multiline-static"
                    label="AntecedentesRelevantes"
                    multiline
                    rows={4}
                    value={nurseInfo?.antecedentes_relevantes || " "}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={3} container>
                <Card style={{ flexGrow: 1 }} variant="outlined">
                  <CardContent>
                    <Typography
                      style={{ fontSize: 14 }}
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    >
                      SignosVitales
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Grid item xs={12} container>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-number"
                            label="Saturación"
                            variant="outlined"
                            value={nurseInfo?.saturacion || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            style={{ paddingRight: "1em" }}
                            id="outlined-basic"
                            label="O2"
                            inputProps={{ type: "number", step: "1" }}
                            variant="outlined"
                            value={nurseInfo?.o2 || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            style={{ paddingRight: "1em" }}
                            id="outlined-basic"
                            label="Comentario O2"
                            variant="outlined"
                            value={nurseInfo?.comentario_o2 || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="FC"
                            variant="outlined"
                            value={nurseInfo?.frecuencia_cardiaca || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="FR"
                            variant="outlined"
                            value={nurseInfo?.frecuencia_respiratoria || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Presión sistólica"
                            variant="outlined"
                            value={nurseInfo?.presion_sistolica || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Presión diastólica"
                            variant="outlined"
                            value={nurseInfo?.presion_diastolica || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Presión arterial media"
                            variant="outlined"
                            value={nurseInfo?.presion_arterial_media || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="T°"
                            variant="outlined"
                            value={nurseInfo?.temperatura || " "}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={3} container>
                <Card style={{ flexGrow: 1 }} variant="outlined">
                  <CardContent>
                    <Typography
                      style={{ fontSize: 14 }}
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    >
                      ÚltimoBalanceHídrico
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Grid item xs={12} container>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Ingresos"
                            variant="outlined"
                            value={nurseInfo?.hidrico_ingreso || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Egresos"
                            variant="outlined"
                            value={nurseInfo?.hidrico_egreso || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-disabled"
                            label="Total"
                            variant="outlined"
                            value={ parseInt(nurseInfo?.hidrico_egreso) - parseInt(nurseInfo?.hidrico_ingreso) || " " }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={3} container>
                <Card style={{ flexGrow: 1 }} variant="outlined">
                  <CardContent>
                    <Typography
                      style={{ fontSize: 14 }}
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    >
                      Observaciones
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Grid item xs={12} container>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Peso"
                            variant="outlined"
                            value={nurseInfo?.peso || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Talla"
                            variant="outlined"
                            value={nurseInfo?.talla || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="FechaÚlt.V.Médica."
                            variant="outlined"
                            value={
                              moment(nurseInfo?.ultima_visita_medica).format(
                                "YYYY-MM-DD"
                              ) || " "
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="FechaProx.V.Méd."
                            variant="outlined"
                            value={
                              moment(nurseInfo?.proxima_visita_medica).format(
                                "YYYY-MM-DD"
                              ) || " "
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Exámenes"
                            variant="outlined"
                            value={nurseInfo?.examenes || " "}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={3} container>
                <Card style={{ flexGrow: 1 }} variant="outlined">
                  <CardContent>
                    <Typography
                      style={{ fontSize: 14 }}
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    >
                      NºDías
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Grid item xs={12} container>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="GTT"
                            variant="outlined"
                            value={nurseInfo?.gtt || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="TQT"
                            variant="outlined"
                            value={nurseInfo?.tqt || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="VVP"
                            variant="outlined"
                            value={nurseInfo?.vpp || " "}
                          />
                        </FormControl>
                      </Grid>
                      
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-multiline-static"
                    label="ExámenFísico"
                    multiline
                    rows={7}
                    value={nurseInfo?.examen_fisico || " "}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={12}>
                <Typography
                  style={{ fontSize: 14 }}
                  color="textSecondary"
                  gutterBottom
                  align="center"
                >
                  Indicaciones Médicas Actuales
                </Typography>
              </Grid>
              {nurseInfo?.medicamentos?.map(medicamento => (
                <Grid key={medicamento.id} item xs={12} container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      style={{ margin: "1px" }}
                      variant="outlined"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Medicamento"
                        variant="outlined"
                        value={medicamento.medicamento || " "}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} container>
                    <Grid item xs={12} container spacing={1}>
                      <Grid item xs={4}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="VIA"
                            variant="outlined"
                            value={medicamento.via || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Administración"
                            variant="outlined"
                            value={medicamento.administracion || " "}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl
                          fullWidth
                          style={{ margin: "1px" }}
                          variant="outlined"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Horario"
                            variant="outlined"
                            value={medicamento.horario || " "}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-multiline-static"
                    label="Unidad del Paciente"
                    multiline
                    rows={3}
                    value={nurseInfo?.unidad_paciente || " "}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <FormControl
                  fullWidth
                  style={{ margin: "1px" }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-multiline-static"
                    label="Indicaciones de Enfermería"
                    multiline
                    rows={7}
                    value={nurseInfo?.indicaciones_enfermeria || " "}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
    );
  }
}

ComponentToPrint.propTypes = {
  nurseInfo: PropTypes.object,
  dataPatient: PropTypes.object
};

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const FormatPDF = props => {
  const { openModal, closeModal, nurseInfo, dataPatient } = props;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="customized-dialog-title" onClose={closeModal} />
        <DialogContent>
          <ComponentToPrint
            ref={componentRef}
            nurseInfo={nurseInfo}
            dataPatient={dataPatient}
          />
          {/* <ComponentToPrint ref={componentRef} {...props} /> */}
          {/* <button onClick={handlePrint}>Descargar</button> */}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          <Button variant="contained" color="primary" onClick={handlePrint}>
            Descargar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormatPDF;

FormatPDF.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  nurseInfo: PropTypes.object,
  dataPatient: PropTypes.object
};
