import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  buttonMargin: {
    marginTop: "3px",
    marginBottom: "3px"
  },
  formControl: {
    margin: theme.spacing(3)
  }
}));

const ButtonsFilter = props => {
  const classes = useStyles();
  const {
    Ingresos,
    Egresos,
    Balance,
    handleChangeFilters,
    checkFilter
  } = props;

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ marginTop: "2em" }}
    >
      <Grid item xs={12}>
        <Typography
          variant="body1"
          gutterBottom
          align="center"
          style={{ textTransform: "none", marginBottom: "3px" }}
        >
          Medición a mostrar
        </Typography>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {checkFilter === false ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Ingresos}
                    onChange={handleChangeFilters}
                    name="Ingresos"
                    color="primary"
                  />
                }
                label="Ingresos"
              />
            ) : checkFilter && Ingresos ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Ingresos}
                    onChange={handleChangeFilters}
                    name="Ingresos"
                    color="primary"
                  />
                }
                label="Ingresos"
              />
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={Ingresos}
                    onChange={handleChangeFilters}
                    name="Ingresos"
                    color="primary"
                  />
                }
                label="Ingresos"
              />
            )}
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {checkFilter === false ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Egresos}
                    onChange={handleChangeFilters}
                    name="Egresos"
                    color="primary"
                  />
                }
                label="Egresos"
              />
            ) : checkFilter && Egresos ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Egresos}
                    onChange={handleChangeFilters}
                    name="Egresos"
                    color="primary"
                  />
                }
                label="Egresos"
              />
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={Egresos}
                    onChange={handleChangeFilters}
                    name="Egresos"
                    color="primary"
                  />
                }
                label="Egresos"
              />
            )}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ButtonsFilter;

ButtonsFilter.propTypes = {
  Ingresos: PropTypes.bool,
  Egresos: PropTypes.bool,
  Balance: PropTypes.bool,
  handleChangeFilters: PropTypes.func,
  checkFilter: PropTypes.bool
};
