import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { red, green, indigo } from "@material-ui/core/colors";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import NewModalAdd from "./NewModalAdd";
import {toMoneyFormat} from "utils/formatFunctions";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DialogDefault from "./DialogDefault";
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { ButtonTextMUI as ButtonCustom } from "utils/componentsStyle";
import CreateIcon from '@material-ui/icons/Create';
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TableComponent from "views/Widgets/Common/TableComponent";

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  table: {
    minWidth: 650
  }
});

const NewItemTableComponent = props => {
  const classes = useStyles();
  const {
    dataItemArray,
    setDataItemArray,
    ItemData,
    listItem,
    setPageRefresh,
    margin = "30px 0 0 0",
    disabled = false,
    setFlagCalculate,
    dictDiscountQuotation,
    quotationDiscount,
    typeQuotationDiscount,
    tempType,
    typeTable,
    order=false,
  } = props;
  const [openModal, setOpenModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);
  const [indexSelected, setIndexSelected] = useState(null);
  const [updateTable, setUpdateTable] = useState(false);


  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    if (updateTable) {
      setUpdateTable(false)
    }
  });

  const calculateGeneralDiscount = (amount, value, discount, typeDiscount) => {
    let totalDiscount = 0;
    const total = amount*value;
    if (!value){
      return 0;
    } else if(discount && quotationDiscount){
      const tempDiscount1 =  typeDiscount === "Descuento" ? discount*-1 : discount;
      const tempDiscount2 =  typeQuotationDiscount === "Descuento" ? quotationDiscount*-1 : quotationDiscount;
      totalDiscount = total + (total*(tempDiscount1/100))
      return totalDiscount + (totalDiscount*(tempDiscount2/100));
    } else if(discount){
      totalDiscount = typeDiscount === "Descuento" ? discount*-1 : discount;
      return total + (total*(totalDiscount/100));
    } else if(quotationDiscount){
      totalDiscount = typeQuotationDiscount === "Descuento" ? quotationDiscount*-1 : quotationDiscount;
      return total + (total*(totalDiscount/100))
    } else {
      return amount*value;
    }
  };

  const checkDiscountItem = (row) => {
    return dictDiscountQuotation[`${row.id}${tempType}_false`] || false;
  };

  const handleDeleteRow = (row, index, table) => {
    setLoading(table);
    let tempDeleteArray = dataItemArray;
    setDataItemArray(tempDeleteArray.filter(array => array.id != row.id));
    setPageRefresh(true);
    setOpenModalDelete(false)
    setFlagCalculate(true)
  };

  const handleChangeQuantity = (value, index, table) => {
    setLoading(table);
    const auxDataArray = dataItemArray;
    if (value && parseInt(value) > 0) {
      auxDataArray[index].cantidad = parseInt(value);
    } else {
      auxDataArray[index].cantidad = 1
    }
    setDataItemArray(auxDataArray);
    setPageRefresh(true);
    setFlagCalculate(true)

  };

  const enableEdit = (enable, index) => {
    const auxDataArray = dataItemArray;
    const tempRow = auxDataArray[index]
    tempRow.editable = enable
    auxDataArray[index] = tempRow
    setDataItemArray(auxDataArray)
    setUpdateTable(true)
    setFlagCalculate(true)
  }

  const handleAddItem = (value, quantity) => {
    let auxArray = dataItemArray;
    const tempData = ItemData[value.value];
    const element = {
      item: tempData.descripcion_producto,
      sku: tempData.SKU,
      id: tempData.id,
      valor: parseInt(tempData.valor_con_iva),
      cantidad: parseInt(quantity),
    };
    auxArray.unshift(element);

    setDataItemArray(auxArray);
    setPageRefresh(true);
    setFlagCalculate(true)
  };

  const DialogDeleteItemItem =
    <Grid item container xs={12}>
      <Grid item container xs={12}>
        <Grid item container xs={2}></Grid>
        <Grid item container xs={10}>
          ¿Estás seguro que deseas eliminar este insumo del despacho rápido?
        </Grid>
        <Grid item container xs={2}></Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item container xs={6} style={{ width: "150px", heigth: "36px" }}>
          <ButtonCustom variant={"outlined"} style={{ color: red[900] }} onClick={() => setOpenModalDelete(false)}>
            <CancelIcon />CANCELAR
          </ButtonCustom>
        </Grid>
        <Grid item container xs={6} style={{ width: "150px", heigth: "36px" }}>
          <ButtonCustom mr={2} variant={"outlined"} style={{ color: green[500] }} onClick={() => handleDeleteRow(rowSelected, indexSelected, true)}>
            <AddCircleIcon /> SI, ELIMINAR
          </ButtonCustom>
        </Grid>
      </Grid>
    </Grid>


  const headerTable = [
    { eval: true, label: "Item",},
    { eval: true, label: "SKU",},
    { onlySort: true, label: "Cantidad",},
    { onlySort: true, label: "Total",},
    { onlySort: true, label: "Total con descuentos",},
    { onlySort: true, label: "Extra", hidding: disabled},
    { eval: false, label: "Acción", hidding: disabled},
  ]

  const dataTable = dataItemArray.map((row, index) => {
    const _amount_ = <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center">
      <Grid item container xs={6} style={{ width: "60px" }} >
        {row.editable ?
          <DetailNumber
            value={row.cantidad}
            id="outlined-number"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onchange={e => {
              handleChangeQuantity(e.target.value, index, true);
            }}
          />
          : <Typography justify="center">{row.cantidad}</Typography>
        }
      </Grid>
      {!order &&
      <Grid item container xs={4} style={{ width: "60px" }}>
        <IconButton variant={"outlined"} style={{ color: indigo[500] }} onClick={() => enableEdit(!row.editable, index)} >
          {row.editable ? <CheckCircleOutlineIcon variant={"outlined"} style={{ color: green[500] }} /> :
            <CreateIcon variant={"outlined"} style={{ color: indigo[500] }} />
          }
        </IconButton>
      </Grid>
    }
    </Grid>;

    const total = toMoneyFormat(row.cantidad * row.valor);
    const totalCalculate = toMoneyFormat(calculateGeneralDiscount(
      row.cantidad,
      row.valor,
      checkDiscountItem(row).porcentaje_item,
      checkDiscountItem(row).tipo_porcentaje
    ));

    const _removeButton_ = !order ? <Button aria-label="close" variant={"outlined"} style={{ color: red[900], width: "125px", heigth: "36px" }}
      onClick={() => {
        setIndexSelected(index);
        setRowSelected(row);
        setOpenModalDelete(true);
      }}
    >
      <RemoveCircleIcon /> Eliminar
    </Button> : null

    return [
      {value: row.item},
      {value: row.sku},
      {_value_: _amount_},
      {value: total},
      {value: totalCalculate},
      {hidding: disabled, value: row.fuera_paquete ? "SI" : "NO"},
      {hidding: disabled, _value_: _removeButton_},
    ]
  })

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          item
          container
          xs={12}
          style={{
            margin: margin,
            maxHeight: "500px",
            overflow: "auto"
          }}
          justify="left"
        >
          {!order &&
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenModal(false);
            }}
            style={{ textTransform: "none", width: "224px", height: "36px", background: green[500] }}
          >
            {`AGREGAR ${typeTable}`}
          </Button>
          }
          <TableComponent
            headers={headerTable}
            data={dataTable}
            pageSize={100}
          />
        </Grid>
      </CardContent>
      {openModalDelete &&
      <DialogDefault title={`ELIMINAR ${typeTable}`} titleStyle={{ textAlignLast: "center" }} content={DialogDeleteItemItem} openParameter={openModalDelete} setParamenter={setOpenModalDelete} fullWidth={'xs'} maxWidth={'xs'} />
      }
      {openModal !== null && (
          <NewModalAdd
            open={openModal !== null}
            table={openModal}
            nameType={typeTable}
            handleClose={() => setOpenModal(null)}
            listItem={listItem}
            dataInfo={ItemData}
            dataArray={dataItemArray}
            setdataArray={setDataItemArray}
            handleAddItem={handleAddItem}
            setFlagCalculate={setFlagCalculate}
          />
        )
      }
    </Card >
  );
};

export default NewItemTableComponent;