import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailText from "views/Widgets/FichaPersonal/EditFichaPersonal/detailText";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";



export default function ModalAddProvider(props) {
  const { open, closeModal } = props;


  const [provider, setProvider] = useState("");
  const [adress, setAdress] = useState("");
  const [contact, setContact] = useState("");
  const [phone, setPhone] = useState("");
  const [products, setProducts] = useState("");
  const [comments, setComments] = useState("");
  const [value, setValue] = useState(0);
  const scroll = "paper";
  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function createProvider() {

    const formData = new FormData();

    formData.append("nombre", provider);
    formData.append("direccion", adress);
    formData.append("contacto", contact);
    formData.append("telefono", phone);
    formData.append("producto_especialidad", products);
    formData.append("comentarios", comments);
    formData.append("evaluacion", value);

    apiform
      .post("api/proveedores/", formData)
      .then(() => {
        window.location.href = "/admin/purchases/providers/";
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Agregar proveedor</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          <DetailText
            label="Nombre proveedor:"
            placeholder="Proveedor"
            value={provider}
            onchange={e => {
              setProvider(e.target.value);
            }}
          />
          <DetailText
            label="Dirección:"
            placeholder="Dirección"
            value={adress}
            onchange={e => {
              setAdress(e.target.value);
            }}
          />
          <DetailText
            label="Contacto:"
            placeholder="Contacto"
            value={contact}
            onchange={e => {
              setContact(e.target.value);
            }}
          />
          <DetailText
            label="Teléfono:"
            placeholder="Teléfono"
            value={phone}
            onchange={e => {
              setPhone(e.target.value);
            }}
          />
          <DetailTextArea
            label="Productos que venden:"
            placeholder="Productos que venden"
            value={products}
            maxlength="500"
            onchange={e => {
              setProducts(e.target.value);
            }}
          />
          <DetailTextArea
            label="Comentarios:"
            placeholder="Comentarios"
            value={comments}
            maxlength="500"
            onchange={e => {
              setComments(e.target.value);
            }}
          />
          <div className="ml-4">
            <Box component="fieldset" mb={3} borderColor="transparent">
              <Typography component="legend">Evaluación</Typography>
              <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
            </Box>
          </div>

          <p className="text-center mt-1">
            ¿Estás seguro que deseas agregar este proveedor?
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          No
        </Button>
        <Button onClick={createProvider} color="primary">
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalAddProvider.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool
};
