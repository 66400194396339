import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SortIcon from "@material-ui/icons/Sort";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { useSortableData } from "./SortableData/SortableData";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const InProcessTable = props => {
  const classes = useStyles();
  const { setOpenDialogDespacho,setQuotationId } = props;
  const { items, requestSort, sortConfig } = useSortableData(props.inProcess);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "10px" }}>
                Id 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("id")}
                  className={getClassNamesFor("id")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Tipo 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("type")}
                  className={getClassNamesFor("type")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Cantidad SKU 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("cantidad_productos")}
                  className={getClassNamesFor("cantidad_productos")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Observación 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("observacion")}
                  className={getClassNamesFor("observacion")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Fecha de compra 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("fecha_compra")}
                  className={getClassNamesFor("fecha_compra")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Estado 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("status")}
                  className={getClassNamesFor("status")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.type}</TableCell>
                  <TableCell align="center">{row.cantidad_productos}</TableCell>
                  <TableCell align="center">{row.observacion}</TableCell>
                  <TableCell align="center">{row.fecha_compra}</TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center">
                    {row.status === "Ingresar despacho" ? (
                      <Button
                        style={{ textTransform: "none" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setOpenDialogDespacho(true);
                          setQuotationId(row.id);
                        }}
                      >
                        Detalle
                      </Button>
                    ) : (
                      <Link to={`/admin/purchases/inprocess/detail/${row.id}`}>
                        <Button
                          style={{ textTransform: "none" }}
                          variant="contained"
                          color="primary"
                        >
                          Detalle
                        </Button>
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.inProcess.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default InProcessTable;

InProcessTable.propTypes = {
};
