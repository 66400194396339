import React from "react";
import "../../../../styles/Styles.css";

const DetailFile = props => {
  return(
    <div className="col-md-4 mb-3">
      <div className="card">
        <div className="card-body">
          <span className="card-title">{props.label}</span>
          <p className="card-text">Archivo: {props.value?.name}</p>
          <span className="btn btn-cmh-color btn-file">
            Seleccionar <input type="file" name={props.value} onChange={props.onchange} />
          </span>
        </div>
      </div>
    </div>
  );
}

export default DetailFile;
