import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import deLocale from "date-fns/locale/es";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "50%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function ModalSetPurchase(props) {
  const { open, closeModal } = props;
  const classes = useStyles();
  const [observation, setObservation] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [inputFields, setInputFields] = useState([{ id: "" }]);

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {

    api.get("api/proveedores/").then(response => {
      setSuppliers(response.data);

    });
  }, []);

  const [scroll, setScroll] = useState("paper");

  function createPurchase() {


    const tempSuppliers = [];

    inputFields
      .filter(filtered => filtered.id)
      .map(input => {
        tempSuppliers.push(input.id);
      });
    // const formData = new FormData();

    // formData.append("solicitudes_compras", props.solicitudesCompras);
    // formData.append("fecha", moment(selectedDate).format("DD/MM/YYYY"));
    // formData.append("proveedores", tempSuppliers);
    // formData.append("observacion", observation);

    api
      .post("api/compras/armarcompra/", {
        solicitudes_compras: props.solicitudesCompras,
        fecha: moment(selectedDate).format("DD/MM/YYYY"),
        proveedores: tempSuppliers,
        observacion: observation
      })
      .then(response => {
        console.log(response);
        window.location.href = "/admin/purchases/requests/";
      })

  }

  const handleSelect = (event, index) => {
    const values = [...inputFields];
    values[index].id = event.target.value;
    setInputFields(values);
  };

  const handleAddSelect = () => {
    setInputFields([...inputFields, { id: "" }]);
  };

  const handleRemoveSelect = index => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Resumen del pedido</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          Los productos seleccionados son los siguientes: <br />
          <div className="mt-3 mb-3">
            <ul className="list-group">
              {props.purchasesToSend.map(purchase => (
                <li key={purchase.id} className="list-group-item">
                  {purchase.id_producto_comprado.SKU} /
                  {purchase.id_producto_comprado.grupo_producto} /
                  {purchase.id_producto_comprado.descripcion_producto} (
                  {purchase.cantidad}{" "}
                  {purchase.cantidad === 1 ? "Unidad" : "Unidades"})
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p className="text-center">
              ¿En qué fecha quiere realizar la compra de este pedido?
            </p>
            <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Fecha"
                  format="dd/MM/yyyy"
                  value={selectedDate ? moment(selectedDate) : null}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  helperText={
                    selectedDate === null ? "Debes ingresar una fecha" : null
                  }
                  error={selectedDate === null ? true : false}
                  okLabel="Aceptar"
                  cancelLabel="Cancelar"
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <p className="text-center mt-1">
              ¿A cuál proveedor comprará estos productos?
            </p>
            <div className="text-center mt-2 mb-2">
              {inputFields.map((inputField, index) => (
                <Paper
                  key={index}
                  style={{ marginLeft: "70px" }}
                  component="form"
                  className={classes.root}
                >
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Proveedor
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={inputField.id}
                      onChange={event => {
                        handleSelect(event, index);
                      }}
                      label="Proveedor"
                    >
                      {suppliers.map(supply => (
                        <MenuItem key={supply.id} value={supply.id}>
                          {supply.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {index === 0 ? (
                    <IconButton disabled>
                      <RemoveIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleRemoveSelect(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <Divider className={classes.divider} orientation="vertical" />
                  <IconButton onClick={() => handleAddSelect()}>
                    <AddIcon />
                  </IconButton>
                </Paper>
              ))}
              {inputFields[0].id != "" ? null : (
                <span className="text-danger">
                  Debes ingresar al menos un proveedor
                </span>
              )}
            </div>
            <DetailTextArea
              label="Observaciones:"
              placeholder="Observaciones"
              value={observation}
              maxlength="500"
              onchange={e => {
                setObservation(e.target.value);
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={closeModal}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Cancelar
        </Button>
        {selectedDate != null && inputFields[0].id != "" ? (
          <Button
            variant="contained"
            onClick={createPurchase}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Guardar
          </Button>
        ) : (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
          >
            Guardar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalSetPurchase.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number,
  productos_restantes: PropTypes.array,
  purchasesToSend: PropTypes.array,
  solicitudes_compras: PropTypes.array
};
