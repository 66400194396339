import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import { makeStyles } from "@material-ui/core/styles";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Divider from "@material-ui/core/Divider";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";
import ModalSavedDivision from "./ModalSavedDivision";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export default function NewRequest() {
  const [moduleType, setmoduleType] = useState("");
  const [supplies, setSupplies] = useState([]);
  const [group, setGroup] = useState("");
  const [group_final, setGroup_final] = useState("");
  const [sku, setSKU] = useState("");
  const [id_sku, setId_sku] = useState("");
  const [sku_final, setSKU_final] = useState("");
  const [id_sku_final, setId_sku_final] = useState("");
  let list_sku = [];
  let list_sku_final = [];
  const [description, setDescription] = useState("");
  const [description_final, setDescription_final] = useState("");
  const [cantidadFraccionar, setCantidadFraccionar] = useState(1);
  const [cantidad_resultante, setCantidad_resultante] = useState(1);
  const [observaciones, setObservaciones] = useState("");
  const [modalSaved, setModalSaved] = useState(false);
  const [value, setValue] = useState(sku.value);
  const [groupValue, setGroupValue] = useState(group.value);

  const [changes, setChanges] = useState(true);
  const [listSupplyInsumos, setListSupplyInsumos] = useState([]);
  const [listSupplyMedicamentos, setListSupplyMedicamentos] = useState([]);
  const [
    listSupplyMedicamentosVenta,
    setListSupplyMedicamentosVenta
  ] = useState([]);
  const [listSupplyArticulos, setListSupplyArticulos] = useState([]);
  const [listSupplyActivos, setListSupplyActivos] = useState([]);
  const [listSupplyEstetica, setListSupplyEstetica] = useState([]);
  const [listSupplyAlimentos, setListSupplyAlimentos] = useState([]);
  const [listSupplyVehiculos, setListSupplyVehiculos] = useState([]);
  const [listSupplyOtro, setListSupplyOtro] = useState([]);

  let supplyListInsumos = [];
  let supplyListMedicamentos = [];
  let supplyListMedicamentosVenta = [];
  let supplyListArticulos = [];
  let supplyListActivos = [];
  let supplyListEstetica = [];
  let supplyListAlimentos = [];
  let supplyListVehiculos = [];
  let supplyListOtro = [];

  let listMedicamentos = [];
  let listMedicamentosVentas = [];
  let listInsumos = [];
  let listArticulos = [];
  let listActivos = [];
  let listEstetica = [];
  let listAlimentos = [];
  let listVehiculos = [];
  let listOtro = [];

  const listGroups = useSelector(state => state.currentList.LISTA_TIPO_PRODUCTO);

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);
    const tempArrayURL = window.location.href.split('/');
    setmoduleType(tempArrayURL[tempArrayURL.length-1]);
  };

  useEffect(() => {
    listSupplyInsumos.map(insumo => {
      supplyListInsumos.push({ value: insumo.SKU, label: insumo.SKU });
    });
    listSupplyMedicamentos.map(medicamento => {
      supplyListMedicamentos.push({
        value: medicamento.SKU,
        label: medicamento.SKU
      });
    });
    listSupplyMedicamentosVenta.map(medicamentoventa => {
      supplyListMedicamentosVenta.push({
        value: medicamentoventa.SKU,
        label: medicamentoventa.SKU
      });
    });
    listSupplyArticulos.map(articulo => {
      supplyListArticulos.push({ value: articulo.SKU, label: articulo.SKU });
    });
    listSupplyActivos.map(activo => {
      supplyListActivos.push({ value: activo.SKU, label: activo.SKU });
    });
    listSupplyEstetica.map(estetica => {
      supplyListEstetica.push({ value: estetica.SKU, label: estetica.SKU });
    });
    listSupplyAlimentos.map(alimento => {
      supplyListAlimentos.push({ value: alimento.SKU, label: alimento.SKU });
    });
    listSupplyVehiculos.map(vehiculo => {
      supplyListVehiculos.push({ value: vehiculo.SKU, label: vehiculo.SKU });
    });
    listSupplyOtro.map(otro => {
      supplyListOtro.push({ value: otro.SKU, label: otro.SKU });
    });

    supplies.map(supply => {
      list_sku.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies.map(supply => {
      list_sku_final.push({ value: supply.SKU, label: supply.SKU });
    });

    if (group.value === "Insumos" && sku.value) {
      listSupplyInsumos
        .filter(supply => supply.SKU === sku.value)
        .map(filtered => {
          setDescription(filtered.descripcion_producto);
          setId_sku(filtered.id);
        });
      listSupplyInsumos
        .filter(supply => supply.SKU != sku.value)
        .map(filtered => {
          listInsumos.push({
            value: filtered.SKU,
            label: filtered.SKU
          });
        });
    }
    if (group.value === "Medicamentos" && sku.value) {
      listSupplyMedicamentos
        .filter(supply => supply.SKU === sku.value)
        .map(filtered => {
          setDescription(filtered.descripcion_producto);
          setId_sku(filtered.id);
        });
      listSupplyMedicamentos
        .filter(supply => supply.SKU != sku.value)
        .map(filtered => {
          listMedicamentos.push({
            value: filtered.SKU,
            label: filtered.SKU
          });
        });
    }
    if (group.value === "MedicamentosVenta" && sku.value) {
      listSupplyMedicamentosVenta
        .filter(supply => supply.SKU === sku.value)
        .map(filtered => {
          setDescription(filtered.descripcion_producto);
          setId_sku(filtered.id);
        });
      listSupplyMedicamentosVenta
        .filter(supply => supply.SKU != sku.value)
        .map(filtered => {
          listMedicamentosVentas.push({
            value: filtered.SKU,
            label: filtered.SKU
          });
        });
    }
    if (group.value === "Articulos" && sku.value) {
      listSupplyArticulos
        .filter(supply => supply.SKU === sku.value)
        .map(filtered => {
          setDescription(filtered.descripcion_producto);
          setId_sku(filtered.id);
        });
      listSupplyArticulos
        .filter(supply => supply.SKU != sku.value)
        .map(filtered => {
          listArticulos.push({
            value: filtered.SKU,
            label: filtered.SKU
          });
        });
    }
    if (group.value === "Activos" && sku.value) {
      listSupplyActivos
        .filter(supply => supply.SKU === sku.value)
        .map(filtered => {
          setDescription(filtered.descripcion_producto);
          setId_sku(filtered.id);
        });
      listSupplyActivos
        .filter(supply => supply.SKU != sku.value)
        .map(filtered => {
          listActivos.push({
            value: filtered.SKU,
            label: filtered.SKU
          });
        });
    }
    if (group.value === "Estetica" && sku.value) {
      listSupplyEstetica
        .filter(supply => supply.SKU === sku.value)
        .map(filtered => {
          setDescription(filtered.descripcion_producto);
          setId_sku(filtered.id);
        });
      listSupplyEstetica
        .filter(supply => supply.SKU != sku.value)
        .map(filtered => {
          listEstetica.push({
            value: filtered.SKU,
            label: filtered.SKU
          });
        });
    }
    if (group.value === "Alimentos" && sku.value) {
      listSupplyAlimentos
        .filter(supply => supply.SKU === sku.value)
        .map(filtered => {
          setDescription(filtered.descripcion_producto);
          setId_sku(filtered.id);
        });
      listSupplyAlimentos
        .filter(supply => supply.SKU != sku.value)
        .map(filtered => {
          listAlimentos.push({
            value: filtered.SKU,
            label: filtered.SKU
          });
        });
    }
    if (group.value === "Vehiculos" && sku.value) {
      listSupplyVehiculos
        .filter(supply => supply.SKU === sku.value)
        .map(filtered => {
          setDescription(filtered.descripcion_producto);
          setId_sku(filtered.id);
        });
      listSupplyVehiculos
        .filter(supply => supply.SKU != sku.value)
        .map(filtered => {
          listVehiculos.push({
            value: filtered.SKU,
            label: filtered.SKU
          });
        });
    }
    if (group.value === "Otro" && sku.value) {
      listSupplyOtro
        .filter(supply => supply.SKU === sku.value)
        .map(filtered => {
          setDescription(filtered.descripcion_producto);
          setId_sku(filtered.id);
        });
      listSupplyOtro
        .filter(supply => supply.SKU != sku.value)
        .map(filtered => {
          listOtro.push({
            value: filtered.SKU,
            label: filtered.SKU
          });
        });
    }

    if (group.value === "Insumos" && sku_final.value) {
      setGroup_final("Insumos");
      listSupplyInsumos
        .filter(supply => supply.SKU === sku_final.value)
        .map(filtered => {
          setDescription_final(filtered.descripcion_producto);
          setId_sku_final(filtered.id);
        });
    }
    if (group.value === "Medicamentos" && sku_final.value) {
      setGroup_final("Medicamentos");
      listSupplyMedicamentos
        .filter(supply => supply.SKU === sku_final.value)
        .map(filtered => {
          setDescription_final(filtered.descripcion_producto);
          setId_sku_final(filtered.id);
        });
    }
    if (group.value === "MedicamentosVenta" && sku_final.value) {
      setGroup_final("Medicamentos Venta");
      listSupplyMedicamentosVenta
        .filter(supply => supply.SKU === sku_final.value)
        .map(filtered => {
          setDescription_final(filtered.descripcion_producto);
          setId_sku_final(filtered.id);
        });
    }
    if (group.value === "Articulos" && sku_final.value) {
      setGroup_final("Artículos");
      listSupplyArticulos
        .filter(supply => supply.SKU === sku_final.value)
        .map(filtered => {
          setDescription_final(filtered.descripcion_producto);
          setId_sku_final(filtered.id);
        });
    }
    if (group.value === "Activos" && sku_final.value) {
      setGroup_final("Activos");
      listSupplyActivos
        .filter(supply => supply.SKU === sku_final.value)
        .map(filtered => {
          setDescription_final(filtered.descripcion_producto);
          setId_sku_final(filtered.id);
        });
    }
    if (group.value === "Estetica" && sku_final.value) {
      setGroup_final("Estética");
      listSupplyEstetica
        .filter(supply => supply.SKU === sku_final.value)
        .map(filtered => {
          setDescription_final(filtered.descripcion_producto);
          setId_sku_final(filtered.id);
        });
    }
    if (group.value === "Alimentos" && sku_final.value) {
      setGroup_final("Alimentos");
      listSupplyAlimentos
        .filter(supply => supply.SKU === sku_final.value)
        .map(filtered => {
          setDescription_final(filtered.descripcion_producto);
          setId_sku_final(filtered.id);
        });
    }
    if (group.value === "Vehiculos" && sku_final.value) {
      setGroup_final("Vehículos");
      listSupplyVehiculos
        .filter(supply => supply.SKU === sku_final.value)
        .map(filtered => {
          setDescription_final(filtered.descripcion_producto);
          setId_sku_final(filtered.id);
        });
    }
    if (group.value === "Otro" && sku_final.value) {
      setGroup_final("Otro");
      listSupplyOtro
        .filter(supply => supply.SKU === sku_final.value)
        .map(filtered => {
          setDescription_final(filtered.descripcion_producto);
          setId_sku_final(filtered.id);
        });
    }
  });

  const handleSKU = sku => {
    setSKU(sku);
  };

  const handleGroup = group => {
    setGroup(group);
    if (group.value === "Insumos" && listSupplyInsumos.length === 0) {

      const listSupplyInsumos = api.get("api/insumosmedicamento/Insumos");

      Promise.all([listSupplyInsumos])
        .then(response => {
          setListSupplyInsumos(response[0].data);

        })

    }
    if (group.value === "Medicamentos" && listSupplyMedicamentos.length === 0) {

      const listSupplyMedicamentos = api.get(
        "api/insumosmedicamento/Medicamentos"
      );

      Promise.all([listSupplyMedicamentos])
        .then(response => {
          setListSupplyMedicamentos(response[0].data);

        })

    }
    if (
      group.value === "MedicamentosVenta" &&
      listSupplyMedicamentosVenta.length === 0
    ) {

      const listSupplyMedicamentosVenta = api.get(
        "api/insumosmedicamento/MedicamentosVenta"
      );

      Promise.all([listSupplyMedicamentosVenta])
        .then(response => {
          setListSupplyMedicamentosVenta(response[0].data);

        })

    }
    if (group.value === "Articulos" && listSupplyArticulos.length === 0) {

      const listSupplyArticulos = api.get("api/insumosmedicamento/Articulos");

      Promise.all([listSupplyArticulos])
        .then(response => {
          setListSupplyArticulos(response[0].data);

        })

    }
    if (group.value === "Activos" && listSupplyActivos.length === 0) {

      const listSupplyActivos = api.get("api/insumosmedicamento/Activos");

      Promise.all([listSupplyActivos])
        .then(response => {
          setListSupplyActivos(response[0].data);

        })

    }
    if (group.value === "Estetica" && listSupplyEstetica.length === 0) {

      const listSupplyEstetica = api.get("api/insumosmedicamento/Estetica");

      Promise.all([listSupplyEstetica])
        .then(response => {
          setListSupplyEstetica(response[0].data);

        })

    }
    if (group.value === "Alimentos" && listSupplyAlimentos.length === 0) {

      const listSupplyAlimentos = api.get("api/insumosmedicamento/Alimentos");

      Promise.all([listSupplyAlimentos])
        .then(response => {
          setListSupplyAlimentos(response[0].data);

        })

    }
    if (group.value === "Vehiculos" && listSupplyVehiculos.length === 0) {

      const listSupplyVehiculos = api.get("api/insumosmedicamento/Vehiculos");

      Promise.all([listSupplyVehiculos])
        .then(response => {
          setListSupplyVehiculos(response[0].data);

        })

    }
    if (group.value === "Otro" && listSupplyOtro.length === 0) {

      const listSupplyOtro = api.get("api/insumosmedicamento/Otro");

      Promise.all([listSupplyOtro])
        .then(response => {
          setListSupplyOtro(response[0].data);

        })

    }
  };

  const handleSKUFinal = sku_final => {
    setSKU_final(sku_final);
  };

  function createDivision() {

    const formData = new FormData();

    formData.append("cantidad_fraccionar", cantidadFraccionar);
    formData.append("observaciones", observaciones);
    formData.append("cantidad_resultante", cantidad_resultante);
    formData.append("estado", "Esperar a Ingresos");
    formData.append("id_producto_fraccionar", id_sku);
    formData.append("id_producto_resultante", id_sku_final);
    formData.append("area", "Ingresos");

    apiform
      .post("api/solicitudfraccionamiento/", formData)
      .then(response => {
        console.log(response);

        setModalSaved(true);
      })

  }

  useEffect(() => {
    if (groupValue != group.value) {
      setGroupValue(group.value);
      setSKU("");
      setDescription("");
      setSKU_final("");
      setGroup_final("");
      setDescription_final("");
    }
    if (value != sku.value) {
      setValue(sku.value);
      setSKU_final("");
      setGroup_final("");
      setDescription_final("");
    }
  });

  return (
    <GridContainer>
      <ViewTitle
        title="SOLICITAR FRACCIONAMIENTO"
        message="En esta sección podrás solicitar un nuevo fraccionamiento."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link color="inherit" to={`/admin/warehouse/requests/${moduleType}`}>
              Fraccionamiento - Compras
            </Link>
            <Typography color="textPrimary">
              Solicitar fraccionamiento
            </Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardBody className="mb-3">
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <Grid item xs={4} align="center" />
                <Grid item xs={4} align="center">
                  <h4 className="font-weight-bold mb-3">SKU a fraccionar</h4>
                </Grid>
                <Grid item xs={4} />
                <DetailSelect
                  label="GRUPO:"
                  value={group}
                  onchange={handleGroup}
                  list={listGroups}
                />
                {group.value === "Insumos" ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku}
                    onchange={handleSKU}
                    list={supplyListInsumos}
                  />
                ) : group.value === "Medicamentos" ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku}
                    onchange={handleSKU}
                    list={supplyListMedicamentos}
                  />
                ) : group.value === "MedicamentosVenta" ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku}
                    onchange={handleSKU}
                    list={supplyListMedicamentosVenta}
                  />
                ) : group.value === "Articulos" ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku}
                    onchange={handleSKU}
                    list={supplyListArticulos}
                  />
                ) : group.value === "Activos" ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku}
                    onchange={handleSKU}
                    list={supplyListActivos}
                  />
                ) : group.value === "Estetica" ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku}
                    onchange={handleSKU}
                    list={supplyListEstetica}
                  />
                ) : group.value === "Alimentos" ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku}
                    onchange={handleSKU}
                    list={supplyListAlimentos}
                  />
                ) : group.value === "Vehiculos" ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku}
                    onchange={handleSKU}
                    list={supplyListVehiculos}
                  />
                ) : group.value === "Otro" ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku}
                    onchange={handleSKU}
                    list={supplyListOtro}
                  />
                ) : (
                  <DetailTextDisabled label="SKU:" value={sku.value} />
                )}



                {/* <DetailSelect
                  label="SKU:"
                  value={sku}
                  onchange={handleSKU}
                  list={list_sku}
                /> */}
                {/* {console.log("sku", sku)} */}
                {/* <DetailTextDisabled label="GRUPO:" value={group} /> */}
                <DetailTextDisabled label="DESCRIPCIÓN:" value={description} />
                <div className="col-md-4 mb-3">
                  <label className="text-dark">CANTIDAD A FRACCIONAR:</label>
                  <input
                    type="number"
                    min="1"
                    className="form-control bg-light font-weight-lighter"
                    style={{ color: "#000000" }}
                    value={cantidadFraccionar}
                    onChange={e => {
                      if (/^[0-9.\b]+$/.test(e.target.value)) {
                        setCantidadFraccionar(parseInt(e.target.value, 10));
                      }
                    }}
                  />
                </div>
                {/* <DetailNumber
                  label="CANTIDAD A FRACCIONAR:"
                  value={cantidadFraccionar}
                  onchange={e => {
                    if (/^[0-9.\b]+$/.test(e.target.value)) {
                      setCantidadFraccionar(e.target.value);
                    }
                  }}
                /> */}
              </Grid>
            </Grid>
            <Divider className="mb-4 mt-3" />
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <Grid item xs={4} align="center" />
                <Grid item xs={4} align="center">
                  <h4 className="font-weight-bold mb-3">SKU resultante</h4>
                </Grid>
                {/* {console.log("list medicamentos", listMedicamentos)}
                {console.log("list insumos", listInsumos)}
                {console.log("list equipamiento", listEquipamiento)} */}
                <Grid item xs={4} />
                {group.value === "Insumos" && sku.value ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku_final}
                    onchange={handleSKUFinal}
                    list={listInsumos}
                  />
                ) : group.value === "Medicamentos" && sku.value ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku_final}
                    onchange={handleSKUFinal}
                    list={listMedicamentos}
                  />
                ) : group.value === "MedicamentosVenta" && sku.value ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku_final}
                    onchange={handleSKUFinal}
                    list={listMedicamentosVentas}
                  />
                ) : group.value === "Articulos" && sku.value ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku_final}
                    onchange={handleSKUFinal}
                    list={listArticulos}
                  />
                ) : group.value === "Activos" && sku.value ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku_final}
                    onchange={handleSKUFinal}
                    list={listActivos}
                  />
                ) : group.value === "Estetica" && sku.value ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku_final}
                    onchange={handleSKUFinal}
                    list={listEstetica}
                  />
                ) : group.value === "Alimentos" && sku.value ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku_final}
                    onchange={handleSKUFinal}
                    list={listAlimentos}
                  />
                ) : group.value === "Vehiculos" && sku.value ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku_final}
                    onchange={handleSKUFinal}
                    list={listVehiculos}
                  />
                ) : group.value === "Otro" && sku.value ? (
                  <DetailSelect
                    label="SKU:"
                    value={sku_final}
                    onchange={handleSKUFinal}
                    list={listOtro}
                  />
                ) : (
                  <DetailTextDisabled label="SKU:" value={sku_final.value} />
                )}
                <DetailTextDisabled label="GRUPO:" value={group_final} />
                <DetailTextDisabled
                  label="DESCRIPCIÓN:"
                  value={description_final}
                />
                <div className="col-md-4 mb-3">
                  <label className="text-dark">
                    CANTIDAD RESULTANTE:
                    {parseInt(cantidad_resultante, 10) <=
                    parseInt(cantidadFraccionar, 10) ? (
                      <span className="text-danger">
                        debe ser mayor a cantidad inicial
                      </span>
                    ) : null}
                  </label>
                  <input
                    type="number"
                    min="1"
                    className="form-control bg-light font-weight-lighter"
                    style={{ color: "#000000" }}
                    value={cantidad_resultante}
                    onChange={e => {
                      if (/^[0-9.\b]+$/.test(e.target.value)) {
                        setCantidad_resultante(parseInt(e.target.value, 10));
                      }
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Divider className="mb-4 mt-3" />
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item xs={8}>
                <DetailTextArea
                  label="OBSERVACIONES:"
                  placeholder="Observaciones"
                  value={observaciones}
                  maxlength="500"
                  onchange={e => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item xs={8}>
                {sku ? null : (
                  <span className="text-danger">
                    Debes ingresar un sku
                  </span>
                )}
                {sku_final ? null : (
                  <span className="text-danger">
                    Debes ingresar un sku resultante
                  </span>
                )}
                {observaciones === "" ? (
                  <span className="text-danger">
                    Debes ingresar una observación
                  </span>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                {cantidad_resultante > cantidadFraccionar &&
                sku &&
                sku_final &&
                observaciones != "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createDivision}
                  >
                    Guardar cambios
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Link to={`/admin/warehouse/requests/${moduleType}`}>
                  <Button variant="contained" color="primary">
                    Cancelar Solicitud
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      {modalSaved && (
        <ModalSavedDivision open={modalSaved} closeModal={() => setModalSaved(false)} moduleType={moduleType} />
      )}
    </GridContainer>
  );
}
