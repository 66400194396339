import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Select from "react-select";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "2em"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const AtencionExtra = props => {
  const classes = useStyles();
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    valorTurno,
    setValorTurno,
    valorColacion,
    setValorColacion,
    valorMovilizacion,
    setValorMovilizacion,
    valorViatico,
    setValorViatico,
    NumberFormatCustom,
    checkColacion,
    handleCheckColacion,
    checkMovilizacion,
    handleCheckMovilizacion,
    checkViatico,
    handleCheckViatico,
    patients,
    patient,
    handlePatient,
    alertVetoe
  } = props;

  const handleChangeStartDate = event => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = event => {
    setEndDate(event.target.value);
  };

  return (
    <Grid container justify="center">
      <Grid item container xs={7} justify="center">
        <Grid item container xs={10} justify="space-around">
          <form className={classes.container} noValidate>
            <TextField
              id="datetime-local1"
              label="Fecha y hora de inicio"
              type="datetime-local"
              value={moment(startDate).format("YYYY-MM-DDTHH:mm")}
              onChange={handleChangeStartDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
            />
          </form>
        </Grid>
      </Grid>
      <Grid item container xs={7} justify="center">
        <Grid item container xs={10} justify="space-around">
          {moment(endDate) < moment(startDate) ? (
            <form className={classes.container} noValidate>
              <TextField
                id="datetime-local2"
                label="Fecha y hora de fin"
                type="datetime-local"
                value={moment(endDate).format("YYYY-MM-DDTHH:mm")}
                onChange={handleChangeEndDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  min: moment(startDate).format("YYYY-MM-DDTHH:mm")
                }}
                error
                helperText="La fecha y hora de término no debe ser menor a la fecha y hora de inicio."
              />
            </form>
          ) : (
            <form className={classes.container} noValidate>
              <TextField
                id="datetime-local3"
                label="Fecha y hora de fin"
                type="datetime-local"
                value={moment(endDate).format("YYYY-MM-DDTHH:mm")}
                onChange={handleChangeEndDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  min: moment(startDate).format("YYYY-MM-DDTHH:mm")
                }}
              />
            </form>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={7} justify="center" style={{ marginTop: "1em" }}>
        <Grid item xs={7}>
          <label className="text-dark">Paciente</label>
          <Select
            value={Object.keys(patient).length > 0 ? patient : " "}
            onChange={handlePatient}
            options={patients}
            placeholder={
              Object.keys(patient).length > 0 ? (
                "Seleccione"
              ) : (
                <span className="text-danger">Requerido</span>
              )
            }
            styles={{
              // Fixes the overlapping problem of the component
              menu: provided => ({ ...provided, zIndex: 9999 })
            }}
            noOptionsMessage={() => "Sin resultados"}
          />
        </Grid>
      </Grid>
      {alertVetoe ? (
        <Alert severity="error" style={{ marginTop: "10px" }}>
          Existe un veto por parte del paciente para este profesional.
        </Alert>
      ) : null}
      <Grid item container xs={7} justify="center" style={{ marginTop: "3em" }}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Indique que incluye el atención extra
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <CheckIcon />
                  </TableCell>
                  <TableCell align="center">Item</TableCell>
                  <TableCell align="center">Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell align="center">Turno</TableCell>
                  <TableCell align="right">
                    <TextField
                      variant="outlined"
                      label="Valor turno"
                      value={valorTurno}
                      onChange={e => setValorTurno(parseInt(e.target.value))}
                      name="numberformat"
                      id="formatted-numberformat-input2"
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                      error={valorTurno > 0 ? false : true}
                      helperText={
                        valorTurno > 0 ? null : "Debe ser mayor que 0"
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Checkbox
                      checked={checkColacion}
                      onChange={handleCheckColacion}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">Colación</TableCell>
                  <TableCell align="right">
                    {checkColacion ? (
                      <TextField
                        variant="outlined"
                        label="Valor colación"
                        value={valorColacion}
                        onChange={e =>
                          setValorColacion(parseInt(e.target.value))
                        }
                        name="numberformat"
                        id="formatted-numberformat-input3"
                        InputProps={{
                          inputComponent: NumberFormatCustom
                        }}
                      />
                    ) : (
                      <TextField
                        disabled
                        value={valorColacion}
                        name="numberformat"
                        id="formatted-numberformat-input4"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          disableUnderline: true
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Checkbox
                      checked={checkMovilizacion}
                      onChange={handleCheckMovilizacion}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">Movilización</TableCell>
                  <TableCell align="right">
                    {checkMovilizacion ? (
                      <TextField
                        variant="outlined"
                        label="Valor movilización"
                        value={valorMovilizacion}
                        onChange={e =>
                          setValorMovilizacion(parseInt(e.target.value))
                        }
                        name="numberformat"
                        id="formatted-numberformat-input5"
                        InputProps={{
                          inputComponent: NumberFormatCustom
                        }}
                      />
                    ) : (
                      <TextField
                        disabled
                        value={valorMovilizacion}
                        name="numberformat"
                        id="formatted-numberformat-input6"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          disableUnderline: true
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Checkbox
                      checked={checkViatico}
                      onChange={handleCheckViatico}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">Viático especial</TableCell>
                  <TableCell align="right">
                    {checkViatico ? (
                      <TextField
                        variant="outlined"
                        label="Valor viático especial"
                        value={valorViatico}
                        onChange={e =>
                          setValorViatico(parseInt(e.target.value))
                        }
                        name="numberformat"
                        id="formatted-numberformat-input7"
                        InputProps={{
                          inputComponent: NumberFormatCustom
                        }}
                      />
                    ) : (
                      <TextField
                        disabled
                        value={valorViatico}
                        name="numberformat"
                        id="formatted-numberformat-input8"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          disableUnderline: true
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AtencionExtra;

AtencionExtra.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.object,
  startDate: PropTypes.object,
  setStartDate: PropTypes.func,
  endDate: PropTypes.object,
  setEndDate: PropTypes.func,
  valorTurno: PropTypes.number,
  setValorTurno: PropTypes.func,
  valorColacion: PropTypes.number,
  setValorColacion: PropTypes.func,
  valorMovilizacion: PropTypes.number,
  setValorMovilizacion: PropTypes.func,
  valorViatico: PropTypes.number,
  setValorViatico: PropTypes.func,
  NumberFormatCustom: PropTypes.func,
  checkColacion: PropTypes.bool,
  handleCheckColacion: PropTypes.func,
  checkMovilizacion: PropTypes.bool,
  handleCheckMovilizacion: PropTypes.func,
  checkViatico: PropTypes.bool,
  handleCheckViatico: PropTypes.func,
  patients: PropTypes.array,
  patient: PropTypes.object,
  handlePatient: PropTypes.func,
  alertVetoe: PropTypes.bool
};
