import Widgets from "views/Widgets/Widgets";
import Create from "views/Widgets/CreateFichaPersonal";
import Rotary from "views/Rotary/Rotary";
import ClinicalRequest from "views/ClinicalRequests/ClinicalRequest";

export const hiring_request = {
  path: "/Solicitudcontratacion",
  name: "Solicitud de Contratación",
  mini: ".",
  component: Widgets,
  layout: "/admin"
};

export const people_taks = {
  path: "/gestion/tareas/",
  name: "Mis tareas",
  mini: ".",
  component: ClinicalRequest,
  layout: "/admin"
};

export const personal_file = {
  path: "/fichapersonal",
  name: "Ficha Personal",
  mini: ".",
  component: Create,
  layout: "/admin"
};

export const rotary = {
  path: "/rotary/",
  name: "Rotativa",
  component: Rotary,
  layout: "/admin",
  mini: "."
};
