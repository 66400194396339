import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import OrderDetailInfo from "./OrderDetail";
import Button from "@material-ui/core/Button";
import apiform from "utils/APIForm";
import ModalAddRecord from "./ModalAddRecord";
import ModalDeleteRecord from "./ModalDeleteRecord";
import SupplierTable from "./SupplierTable/SupplierTable";
import SupplierTableFile from "./SupplierTable/SupplierTableFile";
import ModalQuotes from "./ModalQuotes/ModalQuotes";
import ModalPurchaseOrder from "./ModalQuotes/ModalPurchaseOrder";



export default function InProcessDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [orders, setOrders] = useState([]);


  const [modalAddRecord, setModalAddRecord] = useState(false);
  const [idRequest, setIdRequest] = useState("");
  const [idGroup, setIdGroup] = useState("");
  const [modalDeleteRecord, setModalDeleteRecord] = useState(false);
  const [changes, setChanges] = useState(true);
  const [infoRequest, setInfoRequest] = useState(null);
  const [auxType, setAuxType] = useState(null);
  const [auxGroup, setAuxGroup] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [openModalQuotes, setOpenModalQuotes] = useState(false);
  const [openModalPurchaseOrder, setOpenModalPurchaseOrder] = useState(false);
  const [addSupplier, setAddSupplier] = useState(null);
  const [auxAddSupplier, setAuxAddSupplier] = useState(null);
  const [tempRow, setTempRow] = useState(null);
  const [supplierValidation, setSupplierValidation] = useState(false);

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const checkSupplier = (array, set) => {
    let check = array.filter(row => row.monto > 0 && row.archivo);
    if (check.length > 0) {
      set(true);
    } else {
      set(false);
    }
  };

  const initialChargeReception = () => {
    setChanges(false);
    const getProducts = api.get(
      `api/compras/solicitudcotizacion/detalleproductos/${id}/`
    );
    const infoRequest = api.get(`api/solicitudcotizacioncompras/${id}`);
    const getVendors = api.get(
      `api/compras/solicitudcotizacion/proveedores/${id}/`
    );
    Promise.all([getProducts, infoRequest, getVendors])
      .then(response => {
        let tempOrders = [];
        response[0].data.map(row => {
          tempOrders.push({
            ...row,
            show: true
          });
        });
        setOrders(tempOrders);
        setInfoRequest(response[1].data);
        setAuxType(
          response[1].data.tipo_solicitud_cotizar === "Aseo" ? "aseo" : "normal"
        );
        setAuxGroup(
          ["Insumos", "Articulos", "Alimentos"].includes(response[0].data[0].id_insumo_medicamento.grupo_producto)
            ? "insumos"
            : "medicamentos"
        );
        response[1].data.estado === "AdjuntarOrdenCompra"
          ? setVendors(response[2].data.filter(row => row.elegida))
          : setVendors(response[2].data);
        checkSupplier(response[2].data, setSupplierValidation);

      })

    setLoading(true);
  };

  const openModalDeleteRecord = (idRequest, idGroup, open) => {
    setIdRequest(idRequest);
    setIdGroup(idGroup);
    setModalDeleteRecord(open);
  };

  function deleteRequest() {

    apiform
      .post(`api/compras/eliminarcompra/${id}`)
      .then(() => {
        window.location.href = "/admin/purchases/requests/";
      })

  }

  function aceptRequest() {


    const sendInfo = {
      tipo_solicitud_cotizar: infoRequest.tipo_solicitud_cotizar,
      cantidad_productos: infoRequest.cantidad_productos,
      fecha_solicitud: infoRequest.fecha_solicitud,
      estado: "JefeSeleccionaCotizacion"
    };
    if (infoRequest.estado === "AdjuntarOrdenCompra") {
      sendInfo["estado"] = "RevisarOC";
    }

    api
      .put(`api/solicitudcotizacioncompras/${id}`, sendInfo)
      .then(() => {
        window.location.href = "/admin/purchases/requests/";
      })

  }

  return (
    <GridContainer>
      <ViewTitle
        title="Detalle compra"
        message="En esta sección podrás revisar el detalle de la compra seleccionada."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Compras</Typography>
            <Link color="inherit" href="/admin/purchases/tasks/">
              Tareas de compras
            </Link>
            <Link color="inherit" href="/admin/purchases/requests/">
              Solicitudes de compras
            </Link>
            <Typography color="textPrimary">Detalle solicitud</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <OrderDetailInfo id={id} />
            </GridContainer>
            <CardBody>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center align-middle">SKU</th>
                    <th className="text-center align-middle">Descripción</th>
                    <th className="text-center align-middle">Cantidad</th>
                    <th className="text-center align-middle">Observaciones</th>
                    <th className="text-center align-middle">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {orders
                    .filter(row => row.show)
                    .map(order => (
                      <tr key={order.id}>
                        <td className="text-center align-middle">
                          {order.id_insumo_medicamento.SKU}
                        </td>
                        <td className="text-center align-middle">
                          {order.id_insumo_medicamento.descripcion_producto}
                        </td>
                        <td className="text-center align-middle">
                          {order.cantidad}
                        </td>
                        <td className="text-center align-middle">
                          {order.tiene_observaciones ? "Si" : "No"}
                        </td>
                        <td className="text-center align-middle">
                          {order.id_solicitud_cotizacion.estado ===
                            "JefeSeleccionaCotizacion" ||
                          order.id_solicitud_cotizacion.estado ===
                            "AdjuntarOrdenCompra" ||
                          order.id_solicitud_cotizacion.estado ===
                            "RevisarOC" ? (
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                              className="m-1"
                              style={{ textTransform: "none" }}
                            >
                              Detalle
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                openModalDeleteRecord(id, order.id, true);
                              }}
                              className="m-1"
                              style={{ textTransform: "none" }}
                            >
                              Detalle
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-right">
                    <Button
                      disabled={
                        infoRequest?.estado === "JefeSeleccionaCotizacion" ||
                        infoRequest?.estado === "AdjuntarOrdenCompra" ||
                        infoRequest?.estado === "RevisarOC"
                          ? true
                          : false
                      }
                      variant="contained"
                      color="primary"
                      onClick={() => setModalAddRecord(true)}
                      className="m-1"
                    >
                      +
                    </Button>
                  </td>
                </tbody>
              </table>
              <GridContainer justify="center">
                <Grid item container xs={10} justify="center">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="center"
                    style={{ textTransform: "none" }}
                  >
                    Proveedores
                  </Typography>
                  {infoRequest?.estado === "AdjuntarOrdenCompra" ? (
                    <SupplierTableFile
                      suppliers={vendors}
                      setAddSupplier={setAddSupplier}
                      setAuxAddSupplier={setAuxAddSupplier}
                      setOpenModalQuotes={setOpenModalPurchaseOrder}
                      setTempRow={setTempRow}
                      infoRequest={infoRequest}
                    />
                  ) : (
                    <SupplierTable
                      suppliers={vendors}
                      setAddSupplier={setAddSupplier}
                      setAuxAddSupplier={setAuxAddSupplier}
                      setOpenModalQuotes={setOpenModalQuotes}
                      setTempRow={setTempRow}
                      infoRequest={infoRequest}
                    />
                  )}
                </Grid>
              </GridContainer>
              <GridContainer justify="center" className="mt-3 mb-6">
                {infoRequest?.estado === "JefeSeleccionaCotizacion" ||
                infoRequest?.estado ===
                  "RevisarOC" ? null : infoRequest?.estado ===
                  "AdjuntarOrdenCompra" ? (
                  <Button
                    disabled={
                      vendors[0]?.monto_orden_compra > 0 &&
                      vendors[0]?.orden_compra != null
                        ? false
                        : true
                    }
                    variant="contained"
                    color="primary"
                    onClick={aceptRequest}
                    className="m-1"
                    style={{ textTransform: "none" }}
                  >
                    Enviar
                  </Button>
                ) : (
                  <div>
                    <Button
                      onClick={deleteRequest}
                      variant="contained"
                      color="secondary"
                      className="m-1"
                      style={{ textTransform: "none" }}
                    >
                      Eliminar pedido
                    </Button>
                    <Button
                      onClick={() => {

                        setAddSupplier(true);
                        setAuxAddSupplier(true);
                        setOpenModalQuotes(true);
                      }}
                      variant="contained"
                      color="primary"
                      className="m-1"
                      style={{ textTransform: "none" }}
                    >
                      Añadir proveedor
                    </Button>
                    <Button
                      disabled={supplierValidation ? false : true}
                      variant="contained"
                      color="primary"
                      onClick={aceptRequest}
                      className="m-1"
                      style={{ textTransform: "none" }}
                    >
                      Enviar
                    </Button>
                  </div>
                )}
              </GridContainer>
              <div className="mt-3 mb-5"></div>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modalAddRecord && (
        <ModalAddRecord
          idRequest={id}
          open={modalAddRecord}
          closeModal={() => {
            setModalAddRecord(false);
            setChanges(true);
          }}
          auxType={auxType}
          auxGroup={auxGroup}
        />
      )}
      {modalDeleteRecord && (
        <ModalDeleteRecord
          idRequest={idRequest}
          idGroup={idGroup}
          open={modalDeleteRecord}
          closeModal={() => setModalDeleteRecord(false)}
          orders={orders}
          setOrders={setOrders}
        />
      )}
      {openModalQuotes && (
        <ModalQuotes
          openModal={openModalQuotes}
          closeModal={() => setOpenModalQuotes(false)}
          addSupplier={addSupplier}
          setAddSupplier={setAddSupplier}
          vendors={vendors}
          setVendors={setVendors}

          auxAddSupplier={auxAddSupplier}
          infoRequest={infoRequest}
          tempRow={tempRow}
          checkSupplier={checkSupplier}
          setSupplierValidation={setSupplierValidation}
        />
      )}
      {openModalPurchaseOrder && (
        <ModalPurchaseOrder
          openModal={openModalPurchaseOrder}
          closeModal={() => setOpenModalPurchaseOrder(false)}
          addSupplier={addSupplier}
          setAddSupplier={setAddSupplier}
          vendors={vendors}
          setVendors={setVendors}

          auxAddSupplier={auxAddSupplier}
          infoRequest={infoRequest}
          tempRow={tempRow}
          checkSupplier={checkSupplier}
          setSupplierValidation={setSupplierValidation}
        />
      )}

    </GridContainer>
  );
}
