import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import Snackbar from "components/Snackbar/Snackbar.js";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import {ButtonTextMUI} from "utils/componentsStyle";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import PreBilling from "./PreBilling"
import PatientBillings from "./PatientBillings"
import BillingsPatientTable from "views/Widgets/Billings/BillingsPatientTable";
import ModalBillingsDownload from "components/Billing/ModalBillingsDownload";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../styles/Styles.css"

const useStyles = makeStyles(styles);


const Billing = () => {
  const classes = useStyles();

  const [open, setopen] = useState(null);
  const [loading, setloading] = useState(false);
  const [patientType, setpatientType] = useState([]);
  const [isapreDict, setisapreDict] = useState([]);
  const [listIsapre, setlistIsapre] = useState([]);
  const [selectedIsapre, setselectedIsapre] = useState([]);
  const [patientDict, setpatientDict] = useState({});
  const [listPatient, setlistPatient] = useState([]);
  const [selectedPatient, setselectedPatient] = useState([]);
  const [initialCharge, setinitialCharge] = useState(true);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [changeButtons, setchangeButtons] = useState(true);
  const [changeFilter, setchangeFilter] = useState(true);
  const [dataArray, setdataArray] = useState([]);
  const [viewDetail, setviewDetail] = useState(null);
  const [patientRow,  setpatientRow] = useState(null);
  const [benefitsData, setbenefitsData] = useState([]);
  const [dictChecked, setdictChecked] = useState({});
  const [arrayCheckedBillings, setarrayCheckedBillings] = useState([]);
  const [openModalDownload, setopenModalDownload] = useState(false);
  const [message, setmessage] = useState(null);
  const [processDownload, setprocessDownload] = useState(false);
  const [dictReadyDownload, setdictReadyDownload] = useState({});

  useEffect(() => {
    if (changeButtons) {
      setchangeButtons(false);
    }
    if (changeFilter) {
      updateFilter();
    }
    if (initialCharge) {
      initialData();
    }
  });

  const initialData = () => {
    setinitialCharge(false);
    const requestPatient = api.get("api/paciente");
    const requestIsapres = api.get("api/convenioisapre/")
    Promise.allSettled([
      requestPatient,
      requestIsapres,
    ]).then((request) => {
      const tempListIsapre = [];
      const tempListPatient = [];
      const tempDictPatient = {};
      const tempDictIsapre = {};
      request[0].value.data.map((row) => {
        tempDictPatient[row.id] = row;
        tempListPatient.push({
          value: row.id,
          label: `(${row.rut}) ${row.nombre} ${row.apellido_paterno}`
        });
      });

      request[1].value.data.map((row) => {
        tempDictIsapre[row.id] = row;
        tempListIsapre.push({
          value: row.id,
          label: row.nombre
        });
      });

      setpatientDict(tempDictPatient);
      setlistPatient(tempListPatient);
      setisapreDict(tempDictIsapre);
      setlistIsapre(tempListIsapre)
      setchangeFilter(true);
      setloading(true);
    });
  };

  const updateFilter = () => {
    setchangeFilter(false);
    let paramString = "?";
    if (patientType.length > 0){
      patientType.map((row) => {
        if (paramString=== "?"){
          paramString = `${paramString}tipo_paciente=${row}`
        } else {
          paramString = `${paramString}&tipo_paciente=${row}`;
        }
      });
    }
    if (selectedIsapre.length > 0){
      selectedIsapre.map((row) => {
        if (paramString=== "?"){
          paramString = `${paramString}isapre=${row.value}`
        } else {
          paramString = `${paramString}&isapre=${row.value}`;
        }
      });
    }
    if (selectedPatient.length > 0){
      selectedPatient.map((row) => {
        if (paramString=== "?"){
          paramString = `${paramString}paciente=${row.value}`
        } else {
          paramString = `${paramString}&paciente=${row.value}`;
        }
      });
    }
    if (startDate){
      const tempStartDate = moment(startDate).format("YYYY-MM-DDTHH:mm:ss");
      if (paramString=== "?"){
        paramString = `${paramString}fecha_inicio=${tempStartDate}`
      } else {
        paramString = `${paramString}&fecha_inicio=${tempStartDate}`;
      }
    }
    if (endDate){
      const tempEndDate = moment(endDate).format("YYYY-MM-DDTHH:mm:ss");
      if (paramString==="?"){
        paramString = `${paramString}fecha_fin=${tempEndDate}`;
      } else {
        paramString = `${paramString}&fecha_fin=${tempEndDate}`;
      }
    };
    api.get(`api/facturacion/paciente/agrupado/${paramString}`).then((response) => {
      setdataArray(response.data);
      setdictChecked({});

      setchangeButtons(true);
    });
  };

  const handlePatientType = (button) => {
    let tempPatientType = [...patientType];
    if (patientType.includes(button)) {
      tempPatientType = patientType.filter((row) => row != button);
    } else {
      tempPatientType.push(button);
    }
    setpatientType(tempPatientType);
    setchangeFilter(true);
  }

  const handleSelectedIsapre = (value) => {
    setselectedIsapre(value ? value : []);
    setchangeFilter(true);
  };

  const handleSelectedPatient = (value) => {
    setselectedPatient(value ? value : []);
    setchangeFilter(true);
  };

  const handleDate = (value, set) => {
    set(value);
    setchangeFilter(true);
  };

  const handleBilling = (row) => {
    api.get(`api/prestacionesfacturacion/?facturacion=${row.id}&eliminado=0`).then((response) => {
      setbenefitsData(response.data);
      setviewDetail(row);
    })
  };

  const handleUpdateRow = (row, amount, value, hide) => {
    const body = {
      cantidad: amount,
      valor: value,
      ocultar: hide,
    };
    api.put(`api/facturacion/prestacion/actualizar/${row.id}`, body).then((response) => {
      const benefitRow = response.data.prestacion;
      const indexBenefit = benefitsData.findIndex((subRow) => benefitRow.id === subRow.id);
      const tempBenefitsDate = benefitsData
      tempBenefitsDate[indexBenefit] = benefitRow
      setbenefitsData(tempBenefitsDate);
      setchangeButtons(true);
    });
  };

  const handleMoveRow = (row, billingsSend) => {
    const body = {
      id_facturacion_final: billingsSend.value,
    };
    api.patch(`api/facturacion/prestacion/mover/${row.id}`, body).then((response) => {
      const benefitRow = response.data.prestacion;
      const indexBenefit = benefitsData.findIndex((subRow) => benefitRow.id === subRow.id);
      const tempBenefitsData = benefitsData;
      delete tempBenefitsData[indexBenefit];
      setbenefitsData(tempBenefitsData);
      setchangeButtons(true);
    });
  };

  const handleAddRow = (row) => {
    api.post(`api/facturacion/${viewDetail.id}/prestacion/agregar/`, row).then((response) => {
      const tempBenefitsData = benefitsData;
      tempBenefitsData.push(response.data.prestacion)
      setbenefitsData(tempBenefitsData);
      setchangeButtons(true);
    });
  };

  const handleDeleteRow = (row) => {
    api.delete(`api/facturacion/prestacion/actualizar/${row.id}`).then((response) => {
      const indexBenefit = benefitsData.findIndex((subRow) => row.id === subRow.id);
      const tempBenefitsData = benefitsData;
      delete tempBenefitsData[indexBenefit];
      setbenefitsData(tempBenefitsData);
      setchangeButtons(true);
    });
  };

  const handleReverse = () => {
    api.post(`api/facturacion/prestacion/revertir/${viewDetail.id}`).then((response) => {
      handleBilling(viewDetail)
    });
  };

  const handleChange = (checked, row) => {
    const tempDictChecked = dictChecked;
    if (checked){
      tempDictChecked[row.id_ficha_paciente_id] = true;
    } else {
      delete tempDictChecked[row.id_ficha_paciente_id];
    }
    setdictChecked(tempDictChecked);
    setchangeButtons(true);
  };

  const handleCheckAll = () => {
    const tempDictChecked = {};
    dataArray.map((row) => {
      tempDictChecked[row.id_ficha_paciente_id] = true;
    });
    setdictChecked(tempDictChecked);
    setchangeButtons(true);
  };

  const handleCheckedBillings = () => {

    let paramString = `?`;
    Object.keys(dictChecked).map((key) => {
      if (paramString=== "?"){
        paramString = `${paramString}ficha_paciente=${key}`
      } else {
        paramString = `${paramString}&ficha_paciente=${key}`;
      }
    });

    if (patientType.length > 0){
      patientType.map((row) => {
        if (paramString=== "?"){
          paramString = `${paramString}tipo_paciente=${row}`
        } else {
          paramString = `${paramString}&tipo_paciente=${row}`;
        }
      });
    }
    if (selectedIsapre.length > 0){
      selectedIsapre.map((row) => {
        if (paramString=== "?"){
          paramString = `${paramString}isapre=${row.value}`
        } else {
          paramString = `${paramString}&isapre=${row.value}`;
        }
      });
    }
    if (startDate){
      const tempStartDate = moment(startDate).format("YYYY-MM-DDTHH:mm:ss");
      if (paramString=== "?"){
        paramString = `${paramString}fecha_inicio=${tempStartDate}`
      } else {
        paramString = `${paramString}&fecha_inicio=${tempStartDate}`;
      }
    }
    if (endDate){
      const tempEndDate = moment(endDate).format("YYYY-MM-DDTHH:mm:ss");
      if (paramString==="?"){
        paramString = `${paramString}fecha_fin=${tempEndDate}`;
      } else {
        paramString = `${paramString}&fecha_fin=${tempEndDate}`;
      }
    };
    api.get(`api/facturaciones/${paramString}`).then((response) => {
      setarrayCheckedBillings(response.data);
      setopenModalDownload(true)
    });
  };

  const handleProcessDownload = (indexDownload=0, argDictReadyDownload={}) => {

    const tempBillingDownload = arrayCheckedBillings[indexDownload];

    setprocessDownload(true);
    if (tempBillingDownload){
      api.get(`api/facturacion/facturar/pacientes/${tempBillingDownload.id}/descargar`, { responseType: "blob" }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // Create a link element and click it to trigger download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'pre-factura.xlsx';
        link.click();
        handleProcessDownload(indexDownload+1, argDictReadyDownload);
      })
    } else {
      setprocessDownload(false);
    }
  };

  return (
    <div>
      {open ? (
        <div></div>
      ) : (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody className="rounded shadow border border-primary">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => {
                      setviewDetail(null);
                      setbenefitsData(null);
                      setpatientRow(null);
                    }}
                    style={{cursor: "pointer"}}
                  >
                    {`Facturación`}
                  </Link>
                  {patientRow &&
                  <Link
                    color="inherit"
                    onClick={() => {
                      setviewDetail(null);
                      setbenefitsData(null);
                    }}
                    style={{cursor: "pointer"}}
                  >
                    {`Paciente: ${patientRow.nombre_paciente}`}
                  </Link>
                  }
                  {viewDetail &&
                  <Link
                    color="inherit"
                    onClick={() => {}}
                    style={{cursor: "pointer"}}
                  >
                    {`Pre Facturación: ${viewDetail.id}`}
                  </Link>
                  }
                </Breadcrumbs>
              </CardBody>
            </Card>
          </GridItem>
          {loading ? (
          <GridItem xs={12}>
            {viewDetail ?
            <PreBilling
              viewDetail={viewDetail}
              isapre={isapreDict[viewDetail.id_isapre]}
              benefitsData={benefitsData}
              patientRow={patientRow}
              setchangeButtons={setchangeButtons}
              handleUpdateRow={handleUpdateRow}
              handleAddRow={handleAddRow}
              handleDeleteRow={handleDeleteRow}
              handleReverse={handleReverse}
              handleMoveRow={handleMoveRow}

            /> :
            patientRow ?
            <PatientBillings
              patientRow={patientRow}
              changeButtons={changeButtons}
              setchangeButtons={setchangeButtons}
              handleBilling={handleBilling}
            /> :
            <Card>
              <CardBody>
                <GridItem
                  xs={12}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <GridItem xs={4}>
                    <div className={classes.cardContentRight}>
                      <div className={classes.buttonGroup}>
                        <Button
                          color={
                            patientType.includes("Particular")
                              ? "rose"
                              : "info"
                          }
                          className={classes.firstButton}
                          onClick={() =>
                            handlePatientType("Particular")
                          }
                        >
                          Particular
                        </Button>
                        <Button
                          color={
                            patientType.includes("Isapre")
                              ? "rose"
                              : "info"
                          }
                          className={classes.firstButton}
                          onClick={() =>
                            handlePatientType("Isapre")
                          }
                        >
                          Isapre
                        </Button>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={8}>
                    <DetailSelect
                      isMulti={true}
                      validation={false}
                      label="Isapres"
                      value={selectedIsapre}
                      onchange={(value) => {handleSelectedIsapre(value)}}
                      list={listIsapre}
                  />

                  </GridItem>
                </GridItem>
                <GridItem
                  xs={12}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <GridItem xs={3}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="DD/MM/YYYY"
                        margin="normal"
                        label={"Fecha Inicio"}
                        placeholder="Fecha Inicio"
                        onChange={(e) => handleDate(e, setstartDate)}
                        value={startDate ? moment(startDate) : null}
                        inputVariant={"outlined"}
                      />
                    </MuiPickersUtilsProvider>

                  </GridItem>
                  <GridItem xs={3}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="DD/MM/YYYY"
                        margin="normal"
                        label={"Fecha fin"}
                        placeholder="Fecha fin"
                        onChange={(e) => handleDate(e, setendDate)}
                        value={endDate ? moment(endDate) : null}
                        inputVariant={"outlined"}
                      />
                    </MuiPickersUtilsProvider>
                  </GridItem>
                  <GridItem xs={6}>
                    <DetailSelect
                      isMulti={true}
                      validation={false}
                      label="Pacientes"
                      value={selectedPatient}
                      onchange={(value) => {handleSelectedPatient(value)}}
                      list={listPatient}
                    />
                  </GridItem>
                </GridItem>
                {changeButtons === false && <div></div>}
                <GridItem
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  style={{margin: "10px 0"}}
                >
                  <ButtonTextMUI
                    mr={2}
                    variant="contained"
                    className="text-white"
                    color="primary"
                    onClick={() => handleCheckAll()}
                    style={{margin: "0 5px"}}
                  >
                    Seleccionar todo
                  </ButtonTextMUI>
                </GridItem>
                <GridItem xs={12}>
                  <BillingsPatientTable
                    dataArray={dataArray}
                    dictChecked={dictChecked}
                    setdata={setdataArray}
                    handleDetail={setpatientRow}
                    handleChange={handleChange}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{margin: "10px 0"}}
                >
                  {Object.keys(dictChecked).length > 0 ?
                  <ButtonTextMUI
                    mr={2}
                    variant="contained"
                    className="text-white"
                    color="primary"
                    onClick={() => handleCheckedBillings()}
                    style={{margin: "0 5px"}}
                  >
                    Generar prefacturas
                  </ButtonTextMUI>:
                  <ButtonTextMUI
                    disabled
                    mr={2}
                    variant="contained"
                    style={{margin: "0 5px"}}
                  >
                   Generar prefacturas
                 </ButtonTextMUI>
                  }
                </GridItem>
              </CardBody>
            </Card>
            }
          </GridItem>

          ) : (
            <LoadInfo title={"Cargando información ..."} />
          )}
          {message && (
            <Snackbar
              place="tc"
              close
              color="success"
              message={message}
              open={!!message}
              closeNotification={() => setmessage(null)}
            />
          )}
        </GridContainer>
      )}
      { openModalDownload &&
        <ModalBillingsDownload
          open={openModalDownload}
          handleClose={() => {!processDownload && setopenModalDownload(false)}}
          dataArray={arrayCheckedBillings}
          processDownload={processDownload}
          changeButtons={changeButtons}
          dictReadyDownload={dictReadyDownload}
          handleProcessDownload={handleProcessDownload}
        />
      }
    </div>
  );
};

export default Billing;
