import React from "react";
import CardHeader from "../../../components/Card/CardHeader";
import CardText from "../../../components/Card/CardText.js";

const CardTitle = props => {
  return(
    <CardHeader>
      <CardText className="text-white" style={{backgroundColor: "#1B9CFC"}}>
        <h4 className={props.class}>{props.title}</h4>
      </CardText>
    </CardHeader>
  );
}

export default CardTitle;