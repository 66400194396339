import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import SvgIcon from "@material-ui/core/SvgIcon";
// core components
import Button from "components/CustomButtons/Button.js";
import AlertDialog from "components/Attendance/AlertDialog";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import DialogAttendance from "components/Dashboard/DialogAttendance";
import moment from "moment-timezone";
import { getAsistencia, setmessageGeneral, setmessageAlert } from "actions/getGlobalAction";
import {
  getAllAuxList,
  getlistCharges,
  getlistAFP,
} from "actions/getListAction";
import allActions from "actions";
import ReminderDialogAttendance from "components/Dashboard/ReminderDialogAttendance";

const useStyles = makeStyles(styles);


function OutIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="25"
        viewBox="2 2 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.5874 8.77701L22.5904 12.7796L18.5874 16.7826" stroke="white" strokeWidth="2"/>
        <path d="M8.97253 12.7798L22.5904 12.7798" stroke="white" strokeWidth="2" />
        <path d="M15.945 15.7684L15.945 17.7643C15.945 18.8689 15.0496 19.7643 13.945 19.7643L4 19.7643C2.89543 19.7643 2 18.8689 2 17.7643L2 7.76494C2 6.66037 2.89543 5.76494 4 5.76494L13.945 5.76494C15.0496 5.76494 15.945 6.66037 15.945 7.76494L15.945 9.78805" stroke="white" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
}
function InIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="1 3 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.6149 8.2478L14.6179 12.2504L10.6149 16.2534" stroke="white" strokeWidth="2"/>
        <path d="M5.64856 9.23164V7.23572C5.64856 6.13115 6.54399 5.23572 7.64856 5.23572H17.5936C18.6981 5.23572 19.5936 6.13115 19.5936 7.23572V17.2351C19.5936 18.3396 18.6981 19.2351 17.5936 19.2351H7.64856C6.54399 19.2351 5.64856 18.3396 5.64856 17.2351V15.2119" stroke="white" strokeWidth="2"/>
        <path d="M1 12.2506L14.6179 12.2506" stroke="white" strokeWidth="2" />
      </svg>
    </SvgIcon>
  );
}
export default function HeaderLinks(props) {
  const dispatch = useDispatch();


  const [openNotification, setOpenNotification] = React.useState(null);
  const [openImportantNotification, setOpenImportantNotification] = React.useState(null);
  const [abrirAlerta, setAbrirAlerta] = React.useState(false);
  const [titulo, setTitulo] = React.useState("");
  const [mensaje, setMensaje] = React.useState("");
  const [taskCount, setTaskCount] = React.useState("");
  const [importantTaskCount, setImportantTaskCount] = React.useState("");
  const [taskList, setTaskList] = React.useState([]);
  const [importantTaskList, setImportantTaskList] = React.useState([]);
  const [taskListRender, setTaskListRender] = React.useState([]);
  const [importantTaskListRender, setImportantTaskListRender] = React.useState([]);
  const [updateView, setUpdateView] = React.useState(false);
  const [tipoAlerta, setTipoAlerta] = React.useState("");
  const [urlAttendance, setUrlAttendance] = React.useState("/attendance/logout");


  const dialogTitleNavbar = useSelector(state => state.currentGlobalVar.TITULO_DIALOGO_NAVBAR);
  const dialogMessageNavbar = useSelector(state => state.currentGlobalVar.MENSAJE_DIALOGO_NAVBAR);
  const disableAttendance = useSelector(state => state.currentGlobalVar.DESHABILITAR_ASISTENCIA_ENTRADA);
  const disableAttendanceExit = useSelector(state => state.currentGlobalVar.DESHABILITAR_ASISTENCIA_SALIDA);
  const openDialogAttendanceNavbar = useSelector(state => state.currentGlobalVar.ABRIR_DIALOGO_NAVBAR);
  const attendanceType = useSelector(state => state.currentGlobalVar.ASISTENCIA_TIPO);
  const attendanceUrl = useSelector(state => state.currentGlobalVar.ASISTENCIA_URL);
  const openDialogReminder = useSelector(state => state.currentGlobalVar.ABRIR_DIALOGO_RECORDATORIO);
  const timeDialogReminder = useSelector(state => state.currentGlobalVar.FECHA_DIALOGO_RECORDATORIO);


  const versionAPP = useSelector(state => state.currentGlobalVar.versionAPP);


  React.useEffect(() => {
    const error_ficha = localStorage.getItem("error_ficha");
    if (error_ficha) {
      alert(error_ficha);
    }
    getTasksList();
    checkToken();
    getAsistencia(dispatch);
    getAllAuxList(dispatch);
    getlistCharges(dispatch);
    getlistAFP(dispatch);
  }, []);

  React.useEffect(() => {
    if (updateView) {
      setUpdateView(false);
    }
    // En esta seccion es donde se gatilla la renderizacion del dialogo de recordatorio, verificando primero que no se encuentre en el dashboard y que no se haya abierto anteriormente
    // ademas, tambien se verifica que la variable (attendanceType) la cual nos indica el tipo de la ultima asistencia registrada por el usaurio exista, para que ocurra eso la funcion
    // getAsistencia que se llama en getGlobalAction en redux, debe haber respondido con la consulta al back, posteriormente teniendo esa informacion el recordatorio se renderizara cada
    // 15 o 60 minutos
    const arrayURL = window.location.href.split("/");
    const ubicacion = arrayURL[arrayURL.length - 2];
    let timeDelta = new Date().getTime() - timeDialogReminder;
    if (
      ubicacion !== "dashboard" &&
      openDialogReminder === false &&
      attendanceType !== ""
    ) {
      if (attendanceType === "logout" && Math.floor((timeDelta % (1000 * 60 * 60)) / (1000 * 60)) > 15) {
        dispatch(allActions.setGlobalVar.setOpenReminder(true));
      } if (attendanceType === "login" && Math.floor((timeDelta % (1000 * 60 * 60)) / (1000 * 60)) > 60) {
        dispatch(allActions.setGlobalVar.setOpenReminder(true));
      }
    }
  });

  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleClickExitAttendance = () => {
    // se hace necesario actualizar los mensaje de salida que se renderizaran en el componente de Dialogo
    // dependiendo si realizo click en el boton de salir del navbar o en el boton de registrar salida
    dispatch(allActions.setGlobalVar.setOpenDialogNavbar(true));
    dispatch(allActions.setGlobalVar.setDialogTitleNavbar("Registrar Salida"));
    dispatch(allActions.setGlobalVar.setDialogMessageNavbar("Al registrar tu salida también cerrarás tú sesión"));
  };

  const handleClickImportantNotification = event => {
    if (
      openImportantNotification &&
      openImportantNotification.contains(event.target)
    ) {
      setOpenImportantNotification(null);
    } else {
      setOpenImportantNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    let tempRenderTaskList = taskList.filter((item) => item.tipo_tarea !=='DescompIndicacionesMedicas' && item.tipo_tarea !=='DescompRespiratorio' && item.tipo_tarea !=='DescompMedHab' && item.tipo_tarea !=='DescompAlimentacion' && item.tipo_tarea !=='DescompensacionNoTens')
    if (taskList.length < 10) {
      setTaskListRender(tempRenderTaskList);
    } else {
      setTaskListRender(tempRenderTaskList.slice(0, 10));
    }
    setOpenNotification(null);
  };

  const handleCloseImportantNotification = () => {
    let tempImportantRenderTaskList = importantTaskList.filter((item) => item.tipo_tarea ==='DescompIndicacionesMedicas' || item.tipo_tarea ==='DescompRespiratorio' || item.tipo_tarea ==='DescompMedHab' && item.tipo_tarea ==='DescompAlimentacion' || item.tipo_tarea ==='DescompensacionNoTens')
    if (importantTaskList.length < 10) {
      setImportantTaskListRender(tempImportantRenderTaskList);
    } else {
      setImportantTaskListRender(tempImportantRenderTaskList.slice(0, 10));
    }

    setOpenImportantNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleChangePassword = () => {
    window.location.href = "/admin/changepassword/";
  };

  const handleCloseDialog = () => {
    dispatch(allActions.setGlobalVar.setOpenDialogNavbar(false));

  };

  const handleCloseReminderDialogAttendance = () => {
    {/* Cuando el mensaje se despliega (cumpliendo con las condiciones de tiempo dependiendo el tipo de asistencia registrado), se cierra por medio de variables globales de reux,
      reseteando tambien el tiempo desde donde debe empezar a contabilizar la alarma*/}
    var dt = new Date();
    dispatch(allActions.setGlobalVar.setOpenDateReminder(dt.getTime()));
    dispatch(allActions.setGlobalVar.setOpenReminder(false));
  };

  const handleLogout = () => {
    {/* Cuando el usuario reliza click en el boton de salir ubicado en el navbar, el mensaje del Dialogo de alerta cambia, modificandio las variables de redux,
      en caso que el usuario tenga una entrada registrada lo invitara a seguir un flujo de registro de salida, en caso de no tener registrada una entrada,
      el flujo le permitira salir de la plataforma sin pasar por un registro de asistencia*/}
    if (attendanceType==="login") {
      dispatch(allActions.setGlobalVar.setDialogTitleNavbar("Registrar Salida"));
      dispatch(allActions.setGlobalVar.setDialogMessageNavbar("¡No has registrado tu salida! ¿Deseas hacerlo al salir de la plataforma?"));
    }
    dispatch(allActions.setGlobalVar.setOpenDialogNavbar(true));
  };

  const handleCerrarAlerta = () => {
    setAbrirAlerta(false);
  };

  const handleActionCache = () => {
    window.location.reload()
  }

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    setmessageGeneral(
      dispatch,
      "Se ha detectado una actualización en la APP, se ha procedido a limpiar cache de forma automática. Recomendamos recargar la página para evitar algún tipo de mal funcionamiento",
      "warning",
      "¡Atencion!",
      () => {handleActionCache ()},
      "Recargar pagina"
      );
  };

  const checkToken = () => {
    const body = {
      refresh: localStorage.getItem("refresh")
    };
    api
      .get(`/appauth/validarToken/`)
      .then((response) => {
        if (response.data?.version !== versionAPP){
          clearCacheData();
        }
      })
      .catch(() => {
        api
          .post(`/appauth/token/refresh/`, body)
          .then(response => {

            localStorage.setItem("access", response.data.access);
            localStorage.setItem("refresh", response.data.refresh);
            window.location.reload();
          })
          .catch(() => {
            localStorage.clear();
            window.location.href = `/`;
          });
      });
  };

  const getTasksList = () => {
    const oldInprogress = taskList.map((row) => {return row.id})
    
    api.get(`/api/clinica/tareaspaciente/logeado/`).then((response) => {
      let tempRenderTaskList = response.data.filter((item) => item.tipo_tarea !=='DescompIndicacionesMedicas' && item.tipo_tarea !=='DescompRespiratorio' && item.tipo_tarea !=='DescompMedHab' && item.tipo_tarea !=='DescompAlimentacion' && item.tipo_tarea !=='DescompensacionNoTens')
      let tempRenderImportantTaskList = response.data.filter((item) => item.tipo_tarea ==='DescompIndicacionesMedicas' || item.tipo_tarea ==='DescompRespiratorio' || item.tipo_tarea ==='DescompMedHab' || item.tipo_tarea ==='DescompAlimentacion' || item.tipo_tarea ==='DescompensacionNoTens')
      setTaskCount(tempRenderTaskList.length);
      setImportantTaskCount(tempRenderImportantTaskList.length);
      setTaskList(tempRenderTaskList);
      setImportantTaskList(tempRenderImportantTaskList);
      if (tempRenderImportantTaskList.length > 0) {
        setTipoAlerta("warning")
        setTitulo("¡Atencion!")
        setMensaje("Tiene una tarea de descompensacion por realizar")
        setUrlAttendance("");
        setAbrirAlerta(true);
      }
      if (tempRenderTaskList.length <= 10) {
        setTaskListRender(tempRenderTaskList);
        setImportantTaskListRender(tempRenderImportantTaskList);
      } else {
        setTaskListRender(tempRenderTaskList.slice(0, 10));
      }
      if (tempRenderTaskList.length > 0){
        const newInprogress = tempRenderTaskList.filter(newItem => !oldInprogress.includes(newItem.id));
        let  message = ""
        if (oldInprogress.length == 0 && newInprogress.length > 0){
          message = `Tiene ${newInprogress.length} tareas que requieren su atención`;
        } else if (newInprogress.length > 0){
          message = `Tiene ${newInprogress.length} tareas nuevas que requieren su atención`;
        }
        if (message !== ""){
          setmessageAlert(dispatch, message, "warning", { vertical: 'top', horizontal: 'center' })
        }
      
      }

      if (tempRenderImportantTaskList.length <= 10) {
        setImportantTaskListRender(tempRenderImportantTaskList);
      } else {
        setImportantTaskListRender(tempRenderImportantTaskList.slice(0, 10));
      }
     
    });
  };

  const hanldeAddRender = () => {
    let newCountRenderTaskList = parseInt(taskListRender.length) + 10;
    let tempRenderTaskList = taskList.filter((item) => item.tipo_tarea !=='DescompIndicacionesMedicas' && item.tipo_tarea !=='DescompRespiratorio' && item.tipo_tarea !=='DescompMedHab' && item.tipo_tarea !=='DescompAlimentacion' && item.tipo_tarea !=='DescompensacionNoTens')
    if (parseInt(taskList.length) <= parseInt(newCountRenderTaskList)) {
      setTaskListRender(tempRenderTaskList);
    } else {
      setTaskListRender(tempRenderTaskList.slice(0, newCountRenderTaskList));
    }
  };

  const hanldeImportantAddRender = () => {
    let newCountRenderTaskList = parseInt(importantTaskListRender.length)+10;
    let tempRenderImportantTaskList = importantTaskList.filter((item) => item.tipo_tarea ==='DescompIndicacionesMedicas' || item.tipo_tarea ==='DescompRespiratorio' || item.tipo_tarea ==='DescompMedHab' || item.tipo_tarea ==='DescompAlimentacion' || item.tipo_tarea ==='DescompensacionNoTens')
    if (parseInt(importantTaskListRender.length) <= parseInt(newCountRenderTaskList)){
      setTaskListRender(tempRenderImportantTaskList);
    } else {
      setTaskListRender(tempRenderImportantTaskList.slice(0, newCountRenderTaskList));
    }
  };

  const classes = useStyles();
  const { rtlActive } = props;
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });



  return (
    <div className={wrapper}>
      {/* Este componente renderiza el mensaje recordatorio que debe registrar asistencia, dependiendo de si tiene registrada una entrada o salida por medio de la variable (attendanceType),
        renderizara el mensaje correspondiente, la variable (attendanceType) se asigna al llamar la funcion getAsistencia en el archivo getGlobalAction de redux*/}
      <ReminderDialogAttendance
        message={
          attendanceType === "login"
            ? "Antes de salir de la plataforma debes registrar tu salida con el botón"
            : "Debes registrar tu entrada antes de comenzar tu trabajo, con el botón"
        }
        openDialog={openDialogReminder}
        title={"Recuerda"}
        handleCloseDialog={handleCloseReminderDialogAttendance}
        url={""}
        attendance={attendanceType}
      />
      <AlertDialog
        openDialog={abrirAlerta}
        handleClose={handleCerrarAlerta}
        url={urlAttendance}
        message={mensaje}
        title={titulo}
        severity={tipoAlerta}
      />

      {/* Este componente renderiza las opciones y los mensajes al registrar salida, dentro de el se encuentran los flujos de registro de salida.
        Tener considerado que este componente se gatilla al hacer click al boton de registrar salida, ubicado en el dashboard/navbar*/}
      <DialogAttendance
        message={dialogMessageNavbar}
        openDialog={openDialogAttendanceNavbar}
        title={dialogTitleNavbar}
        handleCloseDialog={handleCloseDialog}
        url={attendanceUrl}
        attendance={attendanceType}
      />
      {/* Dependiendo de la primera llamada al back obteniendo el ultimo registro de asistencia del usuario, se renderizara de forma condicional el boton de registrar entrada o salida
          las variables encargadas de estas condiciones (disableAttendance, disableAttendanceExit) de renderizacion se asignan al llamar la funcion getAsistencia en el archivo getGlobalAction de redux */}
      {!disableAttendance && (
        <div className={managerClasses}>
          <Button
            color="secondary"
            size="sm"
            aria-label="Notifications"
            aria-haspopup="true"
            href={"/attendance/login"}
            style={{
              backgroundColor: "#4CAF50",
              textTransform: "None",
              fontFamily: "Roboto",
              fontStyle: "Normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "12px",
              textAlign: "left",
              marginLeft: "10px",
              letterSpacing: "0.15000000596046448px",
              width: "230px"
            }}
            startIcon={<InIcon />}
          >
            Registrar entrada
          </Button>
        </div>
      )}
      {!disableAttendanceExit && (
        <div className={managerClasses}>
          <Button
            color="primary"
            size="small"
            aria-label="Notifications"
            aria-haspopup="true"
            startIcon={<OutIcon />}
            style={{
              backgroundColor: "#F44336",
              textTransform: "None",
              fontFamily: "Roboto",
              fontStyle: "Normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "12px",
              textAlign: "left",
              marginLeft: "10px",
              letterSpacing: "0.15000000596046448px",
              width: "230px"
            }}
            onClick={handleClickExitAttendance}
          >
            Registrar salida
          </Button>
        </div>
      )}
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        >
          <Notifications
            viewBox="0 0 35 33"
            style={{ width: "50px", height: "50px" }}
          />
          <span
            className={classes.notifications}
            style={{ width: "17px", height: "17px" }}
          >
            {taskCount <= 10 ? taskCount : "10+"}
          </span>
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {rtlActive ? "إعلام" : "Notification"}
            </span>
          </Hidden>
        </Button>

        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {!updateView && <div></div>}
                    {taskListRender &&
                      taskListRender.map((row, index) => (
                        <MenuItem
                          key={index}
                          className={dropdownItem}
                          onClick={() =>
                            window.open(
                              `/admin/clinicRequest/`
                            )
                          }
                        >
                          Tarea Id:{row.id} -{" "}
                          {moment(row.fecha_hora).format("YYYY/MM/DD HH:mm")} -{" "}
                          {row.descripcion}
                        </MenuItem>
                      ))}
                    {taskList.length > 10 && (
                      <MenuItem
                        key={"ver-mas"}
                        className={dropdownItem}
                        onClick={hanldeAddRender}
                      >
                        ver mas +
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Important Notifications"
          aria-owns={
            openImportantNotification
              ? "important-notification-menu-list"
              : null
          }
          aria-haspopup="true"
          onClick={handleClickImportantNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <NotificationImportantIcon
            viewBox="0 0 35 33"
            style={{ width: "50px", height: "50px" }}
          />
          <span
            className={classes.notifications}
            style={{ width: "17px", height: "17px" }}
          >
            {importantTaskCount <= 10 ? importantTaskCount : "10+"}
          </span>
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickImportantNotification}
              className={classes.linkText}
            >
              {rtlActive ? "إعلام" : "Important Notifications"}
            </span>
          </Hidden>
        </Button>

        <Popper
          open={Boolean(openImportantNotification)}
          anchorEl={openImportantNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openImportantNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="important-notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener
                  onClickAway={handleCloseImportantNotification}
                >
                  <MenuList role="menu">
                    {!updateView && <div></div>}
                    {importantTaskListRender &&
                      importantTaskListRender.map((row, index) => (
                        <MenuItem
                          key={index}
                          className={dropdownItem}
                          onClick={() =>
                            window.open(
                              `/admin/patientDetail/${row.id_ficha.id_paciente.id}`
                            )
                          }
                        >
                          Tarea Id:{row.id} -{" "}
                          {moment(row.fecha_hora).format("YYYY/MM/DD HH:mm")} -{" "}
                          {row.descripcion}
                        </MenuItem>
                      ))}

                    {importantTaskList.length > 10 && (
                      <MenuItem
                        key={"ver-mas"}
                        className={dropdownItem}
                        onClick={hanldeImportantAddRender}
                      >
                        ver mas +
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Person
            viewBox="0 0 35 33"
            style={{ width: "50px", height: "50px" }}
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Perfil"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleChangePassword}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : "Cambiar contraseña"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الإعدادات" : "Ajustes"}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الخروج" : "Salir"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
