import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import api from "utils/API";
import { Box } from "@material-ui/core";
import useToogle from "utils/CustomHooks/useToogle";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import Spiner from "views/Widgets/Spiner";
import AppDialog from "views/Widgets/AppDialog";
import HomeStockActions from "./components/HomeStockActions";
import HomeStockCalendar from "./components/HomeStockCalendar";
import HomeStockList from "./components/HomeStockList";
import CreateStockForm from "./components/CreateStockForm";
import {
  Grid,
} from '@material-ui/core';
import {red, green, orange, indigo} from "@material-ui/core/colors";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";



function HomeStock({ stockData, dataFile}) {
  const dispatch = useDispatch()
  const [tab, setTab] = useState(0);
  const [spiner, setSpiner] = useToogle()
  const [isDialogOpen, setIsDialogOpen] = useToogle()
  const [openDownloadStock, setopenDownloadStock] = useToogle()
  const [stockList, setStockList] = useState({list: [], isfiltering: false})
  const [startDate, setstartDate] = useState(moment())
  const [endDate, setendDate] = useState(moment())
  const [stockCalendarList, setStockCalendarList] = useState([]);

  useEffect(() => {
    if (stockData.stockList) {
      const newList = stockData.stockList.map(item => {
        return { ...item, isUpdate: false, showMessage: false }
      })
      setStockList({ list: newList, isfiltering: false })
    }
    if (stockData.stockCalendarList) {
      setStockCalendarList(stockData.stockCalendarList)
    }
  }, [stockData.stockList, stockData.stockCalendarList, tab])

  const handleDownloadStock = () => {
    api.get(`api/bodega/descargarmasiva/stock/${stockData.file.id_paciente}/?fecha_inicio=${moment(startDate).format("YYYY-MM-DD")}&fecha_fin=${moment(endDate).format("YYYY-MM-DD")}`, { responseType: "blob" }).then(response => {
      var blob = new Blob([response.data], { type: response.headers["content-type"] });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = `stock_paciiente.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
  }

  const handleOpenModal = () => {
    let tempStart =  moment().startOf('month')
    let tempEnd = moment().endOf('month')
    setstartDate(tempStart)
    setendDate(tempEnd)
    setopenDownloadStock(false)

  }

  const _content_ = <div>
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      item
    >
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha Inicio"}
                placeholder="Fecha Inicio"
                onChange={setstartDate}
                maxDate={moment(endDate)}
                value={startDate}
                inputVariant={"outlined"}
            />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha Término"}
                placeholder="Fecha Término"
                minDate={moment(startDate)}
                onChange={setendDate}
                value={endDate}
                inputVariant={"outlined"}
            />
        </MuiPickersUtilsProvider>
    </Grid>
    
  </div>

  const _actions_ = <>

    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: green[500], marginRight: "3px"}}
        onClick={handleDownloadStock}
    >
        Descargar
    </Button>
    <Button
        mr={2}
        variant="contained"
        style={{backgroundColor: red[500], marginRight: "10px", marginLeft: "3px"}}
        className="text-white"
        onClick={() => setopenDownloadStock(false)}
    >
        Cerrar
    </Button>
  </>

  


  return (
    <Box width='100%'>
      <Spiner open={spiner} />
      <HomeStockActions open={tab} stockData={stockData} stockList={stockList} setIsDialogOpen={setIsDialogOpen} setopenDownloadStock={handleOpenModal} setTab={setTab} setStockList={setStockList} setStockCalendarList={setStockCalendarList} />
      <HomeStockList open={tab} stockData={stockData} stockList={stockList} setStockList={setStockList} setSpiner={setSpiner} />
      <HomeStockCalendar open={tab} stockCalendarList={stockCalendarList} setSpiner={setSpiner} />
      {stockList &&
      <AppDialog title='Agregar a Stock' open={isDialogOpen} onClose={setIsDialogOpen} style={{width: "450px", left: "35%"}}>
        <CreateStockForm
          stockList={stockList}
          setStockList={setStockList}
          dataFile={dataFile}
          setSpiner={setSpiner}
        />
      </AppDialog>
      }
      {openDownloadStock &&
      <ModalDialog
          open={openDownloadStock}
          onClose={() => setopenDownloadStock(false)}
          title={`Descargar Stock`}
          _content_={_content_}
          _actions_ = {_actions_}
          maxWidth="xs"
      />
      }
    </Box>
  )
}

export default HomeStock;
