import React from "react";
import { indigo } from "@material-ui/core/colors";
import IconButton from '@material-ui/core/IconButton';

export default function Icon({ icon, color, action, size="medium", style={}}) {



    return (
        <IconButton size={size} aria-label="close" style={{ background: indigo[500], color: color, width: "40px", height: "40px", ...style }} onClick={action}>
            {icon}
        </IconButton>
    );
}
