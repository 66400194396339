import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import "moment/locale/es";
import apiform from "utils/APIForm";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function ModalAddRecord(props) {
  const {
    open,
    closeModal,
    dataFile,
    dataPatient,
    setChanges,
    setOpenBackdrop
  } = props;
  const classes = useStyles();
  const [observations, setObservations] = useState("");
  const maxLengthInformation = 1000;
  const [openAnimation, setOpenAnimation] = useState(false);

  const addRecord = () => {
    setOpenAnimation(true);
    const formData = new FormData();

    formData.append("observaciones", observations);
    formData.append("fecha_hora", moment().format());
    formData.append("id_ficha", dataFile.id);
    formData.append("id_cargo", parseInt(localStorage.getItem("id_cargo")));
    formData.append(
      "id_profesional",
      parseInt(localStorage.getItem("user_id"))
    );

    apiform.post("api/hojaregistro/", formData).then(response => {
        setOpenAnimation(false);
        setChanges(true);
        closeModal();
      }).catch(error => {
        console.log("ERROR post api/hojaregistro/", error)
        console.log("ERROR post api/hojaregistro/", error.response)
      });
  };

  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
        disableBackdropClick
        scroll="paper"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          align="center"
          style={{ textTransform: "none" }}
        >
          Agregar registro
        </DialogTitle>
        <DialogContent scroll={"paper"}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} container>
              <Grid item xs={1} />
              <Grid item xs={10} container justify="center">
                <Grid item xs={4} container justify="center">
                  <TextField
                    disabled
                    id="outlined-read-only-input3"
                    label="Nombre del paciente"
                    value={`${dataPatient.nombre} ${dataPatient.apellido_paterno}`}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} container justify="center">
                  <TextField
                    disabled
                    id="outlined-read-only-input2"
                    label="Edad del paciente"
                    value={`${moment().diff(
                      dataPatient.nacimiento,
                      "years"
                    )} años`}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} container justify="center">
                  <TextField
                    disabled
                    id="outlined-read-only-input1"
                    label="Fecha y hora del registro"
                    value={moment().format("YYYY-MM-DD HH:mm")}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justify="center"
                  style={{ marginTop: "3em" }}
                >
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <TextField
                      id="outlined-multiline-static1"
                      label="Información"
                      multiline
                      rows={4}
                      inputProps={{
                        maxLength: maxLengthInformation
                      }}
                      value={observations}
                      onChange={e => setObservations(e.target.value)}
                      variant="outlined"
                      error={observations == ""}
                      helperText={
                        observations == "" ? "No puede ser vacío" : ""
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justify="flex-end"
                >
                  <Typography style={{margin: "0 15px 0 0"}} variant="body2" gutterBottom>
                      {`${observations.length}/${maxLengthInformation}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={1} />
             
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          {openAnimation ? (
            <CircularProgress />
          ) : observations != "" ? (
            <Button variant="contained" color="primary" onClick={addRecord}>
              Guardar
            </Button>
          ) : (
            <Button variant="contained" color="primary" disabled>
              Guardar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

ModalAddRecord.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  dataFile: PropTypes.object,
  dataPatient: PropTypes.object,
  setChanges: PropTypes.func,
  setOpenBackdrop: PropTypes.func
};
