import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailNumber from "views/Widgets/FichaPersonal/EditFichaPersonal/detailNumber";

export default function ModalAddPicking(props) {
  const { open, closeModal, id_producto, id_detalle } = props;
  const [picking, setPicking] = useState("");
  const [pedido, setPedido] = useState("");

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    api.get(`api/productospedidos/${id_producto}`).then(response => {
      setPedido(response.data);
      setPicking(response.data.picking);
    });
  }, []);

  const scroll = "paper";

  function updateBenefit() {
    const formData = new FormData();

    formData.append("observaciones", pedido.observaciones);
    formData.append("estado", pedido.estado);
    formData.append("cantidad", pedido.cantidad);
    formData.append("tipo_cantidad", pedido.tipo_cantidad);
    formData.append("picking", picking);
    formData.append("id_insumo_medicamento", pedido.id_insumo_medicamento);
    formData.append("id_solicitud_pedido", pedido.id_solicitud_pedido);

    apiform
      .put(`api/productospedidos/${id_producto}`, formData)
      .then(response => {
        window.location.href = `/admin/warehouse/order/${id_detalle}`;
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">
        Modificar Picking / Estado
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form-row" style={{ marginBottom: "10rem" }}>
          <DetailNumber
            label="PICKING:"
            value={picking}
            onchange={e => {
              if (/^[0-9\b]+$/.test(e.target.value)) {
                setPicking(e.target.value);
              }
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Cancelar
        </Button>
        <Button oClick={updateBenefit} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalAddPicking.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number
};
