import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { Calendar, momentLocalizer } from "react-big-calendar";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import {
  Typography,
  Breadcrumbs,
  Link,
  FormControl,
  Button as ButtonMUI,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Search, Today, Toc, Event, Room } from "@material-ui/icons";
import FilterBox from "views/Widgets/Transport/FilterBox";
import TransportTable from "views/Widgets/Transport/TransportTable";
import ModalAllocateResources from "components/Transport/ModalAllocateResources";
import ModalAdministrativeProcess from "components/Transport/ModalAdministrativeProcess";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import ModalDetails from "components/Transport/ModalDetails";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);

const TransportDetail = ({
  setopenDetail,
  activeRow,
  finishedRow,
  driverRow,
  driverDict,
  vehicleRow,
  vehicleDict,
  patientDict,
  setChanges,
}) => {
  const classes = useStyles();
  const [open, setopen] = useState(null);
  const [filter, setfilter] = useState("");
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [rowsActive, setrowsActive] = useState([...activeRow]);
  const [rowsFinished, serowsFinished] = useState([...finishedRow]);
  const [arrayFilterStateButtons, setarrayFilterStateButtons] = useState([]);
  const [arrayFilterZoneButtons, setarrayFilterZoneButtons] = useState([]);
  const [changeButtons, setchangeButtons] = useState(true);
  const [rowChecked, setrowChecked] = useState(0);
  const [openAdministrativeProcess, setopenAdministrativeProcess] = useState(false);
  const [openModalAllocateResources, setopenModalAllocateResources] = useState(false);
  
  const [rowDetail, setrowDetail] = useState(null);
  const [infoRequest, setinfoRequest] = useState([]);
  const [message, setmessage] = useState(null);

  const [getProducts, setGetProducts] = useState([]);
  const [timeLine, setTimeLine] = useState([]);
  const [getProductsComplete, setGetProductsComplete] = useState([]);
  const [getProductsOther, setGetProductsOther] = useState([]);
  const [boxNumber, setBoxNumber] = useState(0);
  const [viewInfo, setviewInfo] = useState("Table");
  const [events, setevents] = useState([]);
  const [selectDate, setselectDate] = useState(moment());
  const stateFilter = ["No Asignado", "En Ruta", "Asignado", "Fallido", "Retraso", "Entregado"];

  const vehicleArray = [...vehicleRow];
  const driverArray = [...driverRow];

  const dictSTValueLabel = useSelector(state => state.currentList.DICCIONARIO_ESTADOS_TRANSPORTES);

  const eventColors = event => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  };

  useEffect(() => {
    if (changeButtons) {
      setchangeButtons(false);
    }
  });

  useEffect(() => {
    setrowsActive([...activeRow]);
  }, [activeRow]);

  const generateTitle = (patientInfo, chargeInfo, state) => {
    const patientName =  patientInfo ? `${patientInfo.nombre} ${patientInfo.apellido_paterno} ${patientInfo.apellido_materno}`: "No disponible";
    const driverName = chargeInfo ? `${chargeInfo.first_name} ${chargeInfo.last_name}` : "No disponible";
    return `
      Paciente: ${patientName},
      Chofer: ${driverName},
      Estado: ${state}.
    `;
  };

  const handleFilterStateButtons = (button) => {
    let tempArray = arrayFilterStateButtons;
    if (arrayFilterStateButtons.includes(button)) {
      tempArray = arrayFilterStateButtons.filter((row) => row != button);
    } else {
      tempArray.push(button);
    }
    setarrayFilterStateButtons(tempArray);
    setchangeButtons(true);
  };

  const handleFilterZoneButtons = (button) => {
    let tempArray = arrayFilterZoneButtons;
    if (arrayFilterZoneButtons.includes(button)) {
      tempArray = arrayFilterZoneButtons.filter((row) => row != button);
    } else {
      tempArray.push(button);
    }
    setarrayFilterZoneButtons(tempArray);
    setchangeButtons(true);
  };

  const handleDate = (value, set) => {
    set(value);
  };

  const handleFilter = (event) => {
    setfilter(event.target.value);
  };

  const handleChange = (checked, row) => {
    const tempauxRows = [...rowsActive];
    const index = rowsActive.findIndex((auxRow) => auxRow.id === row.id)
    let countCheck = 0;
    tempauxRows[index].check = checked;
    countCheck = tempauxRows.filter((row) => row.check).length
    setrowChecked(countCheck);
    setrowsActive(tempauxRows);
  };

  const handleDetail = (value, set) => {
    setBoxNumber(value[0].id_envios_pedidos?.numero_pedido);

    setmessage("Cargando solicitud");
    if (value[0].id_envios_pedidos) {
      const getTransportInfo = api.get(
        `/api/transporte/solicitudes/${value[0].id_envios_pedidos.id}/detalle/`
      );

      const getRequest = api.get(
        `api/bodega/pedidos/grupo/Todos/detalle/${value[0].id_envios_pedidos.id_solicitud_pedido}`
      );

      const getTimeLine = api.get(
        `api/bodega/pedidos/grupo/Todos/detalle/${value[0].id_envios_pedidos.id_solicitud_pedido}/timeline/`
      );

      Promise.all([getTransportInfo, getRequest, getTimeLine])
        .then(response => {
          const requestFilter = response[1].data.filter(
            filtered =>
              (filtered.estado === "Incompleta" &&
                filtered.reemplazado === false) ||
              (filtered.estado === "Completa" && filtered.picking > 0)
          );

          setGetProducts([...requestFilter]);
          setGetProductsComplete(
            response[1].data.filter(
              filtered =>
                filtered.estado === "Completa" && filtered.picking === 0
            )
          );
          setGetProductsOther(
            response[1].data.filter(
              filtered =>
                filtered.estado != "Incompleta" && filtered.estado != "Completa"
            )
          );

          setTimeLine(response[2].data);
          setinfoRequest(response[0].data);
          set(value);

          setmessage(null);
        })
    } else if (value[0].id_parada_traslado_ingreso) {
      api
        .get(
          `api/ingresos/solicitud/${value[0].id_parada_traslado_ingreso.id_solicitud_ingreso.id}/productos/`
        )
        .then(response => {
          setTimeLine(response.data);
          set(value);

          setmessage(null);
        })
    } else {
      set(value);
      setTimeLine([]);
      setBoxNumber(0);

      setmessage(null);
    }
  };

  const functionFilter = (row) => {
    const patientInfo = patientDict[row.id_paciente]
    const driverInfo = driverDict[row.id_conductor]
    const vehicleInfo = vehicleDict[row.id_vehiculo]
    const stateValueInfo = dictSTValueLabel[row.estado_recepcion]
    let filterStringResult = true
    let filterButtonState = true
    let filterButtonZone = true
    let filterStartDate = true
    let filterEndDate = true
    let filterRangeDate = true
    if (filter != ""){
      filterStringResult =(
        row["comuna"].toLowerCase().includes(filter.toLowerCase()) ||
        row["tipo_solicitud"].toLowerCase().includes(filter.toLowerCase()) ||
        patientInfo?.nombre.toLowerCase().includes(filter.toLowerCase()) ||
        patientInfo?.apellido_paterno.toLowerCase().includes(filter.toLowerCase()) ||
        patientInfo?.apellido_materno.toLowerCase().includes(filter.toLowerCase()) ||
        driverInfo?.first_name.toLowerCase().includes(filter.toLowerCase()) ||
        driverInfo?.last_name.toLowerCase().includes(filter.toLowerCase()) ||
        vehicleInfo?.patente.toLowerCase().includes(filter.toLowerCase())
      )
    }

    if (arrayFilterStateButtons.length > 0){
      filterButtonState = arrayFilterStateButtons.includes(stateValueInfo?.label || "")
    } 
    if (arrayFilterZoneButtons.length > 0){
      filterButtonZone = arrayFilterZoneButtons.includes(stateValueInfo?.label || "")
    }  
    if (startDate){
      filterStartDate = moment(startDate).diff(moment(row.hora_estimada || row.fecha_solicitud), "days") <= 0
    }
    if (endDate){
      filterEndDate =  moment(endDate).diff(moment(row.hora_estimada || row.fecha_solicitud ), "days") >= 0
    }
    
    filterRangeDate =  moment(startDate).diff(moment(row.hora_estimada || row.fecha_solicitud), "days") <= 0 && moment(endDate).diff(moment(row.hora_estimada || row.fecha_solicitud), "days") >= 0
    const dateFilter =  (startDate && endDate) ? filterRangeDate : (filterStartDate && filterEndDate)

    return filterStringResult && filterButtonState && filterButtonZone && dateFilter
  }

  const auxRowsActive = rowsActive.filter((row) => functionFilter(row))
  const auxRowsFinished = rowsFinished.filter((row) => functionFilter(row))


  return (
    <div>
      {open ? (
        <div></div>
      ) : (
        <GridContainer>
          <GridItem xs={12}>
            <CardBody className="rounded shadow border border-primary">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  color="inherit"
                  onClick={() => setopenDetail(null)}
                  style={{ cursor: "pointer" }}
                >
                  Tarea de Transporte
                </Link>
                <Typography color="textPrimary">{`Recepción de pedidos`}</Typography>
              </Breadcrumbs>
            </CardBody>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                {/* <GridItem xs={12}>
                  <SummaryProgress />
                </GridItem> */}
                <GridItem
                  xs={12}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography color="textPrimary">{`Filtros`}</Typography>
                </GridItem>
                <GridItem
                  xs={12}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <GridItem xs={4}>
                    <FilterBox
                      filterText={filter}
                      handleFilter={handleFilter}
                      IconSpan={Search}
                      stylePaper={{ width: "80%" }}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <Card>
                      <CardHeader color="info" icon>
                        <CardIcon color="info">
                          <Today />
                        </CardIcon>
                      </CardHeader>
                      <CardBody>
                        <FormControl fullWidth>
                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                          <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha Inicio"}
                            placeholder="Fecha Inicio"
                            onChange={(e) => handleDate(e, setstartDate)}
                            value={startDate ? moment(startDate) : null}
                            inputVariant={"outlined"}
                          />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={4}>
                    <Card>
                      <CardHeader color="info" icon>
                        <CardIcon color="info">
                          <Today />
                        </CardIcon>
                      </CardHeader>
                      <CardBody>
                        <FormControl fullWidth>
                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                          <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha fin"}
                            placeholder="Fecha fin"
                            onChange={(e) => handleDate(e, setendDate)}
                            value={endDate ? moment(endDate) : null}
                            inputVariant={"outlined"}
                          />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridItem>
                {changeButtons === false && <div></div>}
                <GridItem
                  xs={12}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <GridItem
                    xs={6}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <div className={classes.cardContentRight}>
                      <div className={classes.buttonGroup}>
                        {[...Array(8)].map((e, index) => (
                          <Button
                            key={`Zona ${index + 1}`}
                            color={
                              arrayFilterZoneButtons.includes(
                                `Zona ${index + 1}`
                              )
                                ? "rose"
                                : "info"
                            }
                            className={classes.firstButton}
                            onClick={() =>
                              handleFilterZoneButtons(`Zona ${index + 1}`)
                            }
                          >
                            {`Zona ${index + 1}`}
                          </Button>
                        ))}
                      </div>
                    </div>
                  </GridItem>
                  <GridItem
                    xs={6}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <div className={classes.cardContentRight}>
                      <div className={classes.buttonGroup}>
                        {stateFilter.map((row, index) => (
                          <Button
                            key={row}
                            color={
                              arrayFilterStateButtons.includes(row)
                                ? "rose"
                                : "info"
                            }
                            className={classes.firstButton}
                            onClick={() => handleFilterStateButtons(row)}
                          >
                            {row}
                          </Button>
                        ))}
                      </div>
                    </div>
                  </GridItem>
                </GridItem>
                <GridItem
                  xs={12}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ margin: "20px 0 -70px 0" }}
                >
                  <GridItem
                    xs={6}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Button
                      justIcon
                      round
                      style={{zIndex: 10}}
                      color={viewInfo === "Table" ? "rose" : "info"}
                      className={classes.marginRight}
                      onClick={() => setviewInfo("Table")}
                    >
                      <Toc className={classes.icons} />
                    </Button>
                    <Button
                      justIcon
                      round
                      style={{zIndex: 10}}
                      color={viewInfo === "Calendar" ? "rose" : "info"}
                      className={classes.marginRight}
                      onClick={() => setviewInfo("Calendar")}
                    >
                      <Event className={classes.icons} />
                    </Button>
                    <Button
                      justIcon
                      round
                      className={classes.marginRight}
                      color={viewInfo === "Map" ? "rose" : "info"}
                      onClick={() => setviewInfo("Map")}
                    >
                      <Room className={classes.icons} />
                    </Button>
                  </GridItem>
                  <GridItem
                    xs={6}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                      <ButtonMUI
                        variant="contained"
                        color="primary"
                        style={{ zIndex: 10, margin: "0 1em 0 0" }}
                        onClick={() => setopenAdministrativeProcess(true)}
                      >
                        Tramite Administrativo
                      </ButtonMUI>
                    {rowChecked > 0 ? (
                      <ButtonMUI
                        variant="contained"
                        color="primary"
                        style={{ zIndex: 10 }}
                        onClick={() => setopenModalAllocateResources(true)}
                      >
                        Asignar recursos
                      </ButtonMUI>
                    ) : (
                      <ButtonMUI
                        disabled
                        variant="contained"
                        style={{ zIndex: 10 }}
                      >
                        Asignar recursos
                      </ButtonMUI>
                    )}
                  </GridItem>
                </GridItem>
                {viewInfo === "Table" ?
                <GridItem xs={12}>
                  <NavPills
                    color="primary"
                    alignCenter
                    tabs={[
                      {
                        tabButton: "En curso",
                        tabContent: (
                          <TransportTable
                            dataArray={auxRowsActive}
                            checkEnd={false}
                            handleChange={handleChange}
                            handleDetail={handleDetail}
                            setrowDetail={setrowDetail}
                            dictSTValueLabel={dictSTValueLabel}
                            driverDict={driverDict}
                            vehicleDict={vehicleDict}
                            patientDict={patientDict}
                          />
                        ),
                      },
                      {
                        tabButton: "Finalizado",
                        tabContent: (
                          <TransportTable
                            dataArray={auxRowsFinished}
                            checkEnd={true}
                            handleChange={handleChange}
                            handleDetail={handleDetail}
                            setrowDetail={setrowDetail}
                            driverDict={driverDict}
                            vehicleDict={vehicleDict}
                            patientDict={patientDict}
                          />
                        ),
                      },
                    ]}
                  />
                </GridItem>:
                viewInfo === "Calendar"  ?
                  <CardBody calendar style={{margin: "50px 0 0 0"}}>
                    <Calendar
                      selectable
                      localizer={localizer}
                      events={events}
                      defaultView="month"
                      date={selectDate.toDate()}
                      onSelectEvent={event => handleDetail([event.row, 0], setrowDetail)}
                      // onSelectSlot={slotInfo => addNewEventAlert(slotInfo)}
                      onSelectAgendaEvent={(event) => handleDetail([event.row, 0], setrowDetail)}
                      eventPropGetter={eventColors}
                      onNavigate={date => setselectDate(moment(date))}
                      step={30}
                      timeslots={1}
                      messages={{
                        next: "Siguiente",
                        previous: "Anterior",
                        today: "Hoy",
                        month: "Mes",
                        week: "Semana",
                        day: "Día"
                      }}
                    />
                  </CardBody>
                  :
                  <div></div>
                }
              </CardBody>
            </Card>
          </GridItem>
          {openModalAllocateResources && (
            <ModalAllocateResources
              open={openModalAllocateResources}
              handleClose={() => setopenModalAllocateResources(false)}
              array={rowsActive}
              setArray={setrowsActive}
              setchangeButtons={setchangeButtons}
              vehicleArray={vehicleArray}
              driverArray={driverArray}
              rowChecked={rowChecked}
              setrowChecked={setrowChecked}
              driverDict={driverDict}
              vehicleDict={vehicleDict}
              patientDict={patientDict}
            />
          )}
          {openAdministrativeProcess && (
            <ModalAdministrativeProcess
              open={openAdministrativeProcess}
              handleClose={() => setopenAdministrativeProcess(false)}
              setChanges={setChanges}
            />
          )}
          {rowDetail && (
            <ModalDetails
              open={!!rowDetail}
              handleClose={() => setrowDetail(null)}
              rowDetail={rowDetail}
              infoRequest={infoRequest}
              patientDict={patientDict}
              timeLine={timeLine}
              getProducts={getProducts}
              getProductsComplete={getProductsComplete}
              getProductsOther={getProductsOther}
              boxNumber={boxNumber}
            />
          )}
          {message && (
            <Snackbar
              place="tc"
              close
              color="success"
              message={message}
              open={!!message}
              closeNotification={() => setmessage(null)}
            />
          )}
        </GridContainer>
      )}
    </div>
  );
};

export default TransportDetail;
