import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";

const ProductsTable = props => {
  const { products } = props;

  return (
    <TableContainer component={Paper}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="text-center align-middle">SKU</th>
            <th className="text-center align-middle">Descripción</th>
            <th className="text-center align-middle">Cantidad solicitud</th>
            <th className="text-center align-middle">Cantidad recepción</th>
            <th className="text-center align-middle">Cantidad restante</th>
            <th className="text-center align-middle">Comentarios</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <tr key={product.id}>
              <td className="text-center align-middle">{product.sku}</td>
              <td className="text-center align-middle">{product.descripcion}</td>
              <td className="text-center align-middle">{product.cantidad}</td>
              <td className="text-center align-middle">{product.cantidad}</td>
              <td className="text-center align-middle">0</td>
              <td className="text-center align-middle">{product.observacion_producto}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default ProductsTable;

ProductsTable.propTypes = {
  products: PropTypes.array
};
