import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, ResponsiveContainer
} from 'recharts';

export default function LittleChart({
    row,
    color,
    stackOffset,
    max,
    min=0,
    width=50,
    height=30,
}) {
    return (
        <BarChart
            layout="vertical"
            width={width}
            height={height}
            data={[{
                name: "data", 
                value: row,
            }]}
            stackOffset={stackOffset}
            // margin={{
            //     top: 10, right: 0, left: -100, bottom: 0,
            // }}
        >
            <XAxis hide={true} type="number" domain={[min, max]} />
            <YAxis hide={true} dataKey="name" type="category" />
            <Bar 
                dataKey="value" 
                fill={color} 
            />
        </BarChart>
    );
}
