import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import PropTypes from "prop-types";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const Chart = props => {
  const { dataChart } = props;

  return (
    <ResponsiveContainer>
      <LineChart
        width={500}
        height={300}
        data={dataChart}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <XAxis dataKey="date" tick={<CustomizedAxisTick />} height={60} />
        <YAxis
          type="number"
          label={{ value: "Pedidos", angle: -90, position: "insideLeft" }}
          domain={[1, 'auto']} 
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="count"
          stroke="#82ca9d"
          name={"Cantidad"}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;

Chart.propTypes = {
  dataChart: PropTypes.array
};
