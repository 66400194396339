import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import { red, green } from "@material-ui/core/colors";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import apiRepeat from "utils/API_REPEAT";
import allActions from "actions";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
}));

export default function ModalDeliveryGuide({
  open,
  handleClose,
  deliveryGuide,
  rowsActive,
  setrowsActive,
  rowsFinished,
  serowsFinished,
  setmessage,
  infoRequest,
  patientDict,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);
  const dictSTValueLabel = useSelector(state => state.currentList.DICCIONARIO_ESTADOS_TRANSPORTES);

  const [tempRow, settempRow] = useState(deliveryGuide[0]);
  const [exam, setexam] = useState(tempRow["archivo_recepcion"] ? tempRow["archivo_recepcion"] : []);
  const [observation, setobservation] = useState(tempRow["observacion_recepcion"] ? tempRow["observacion_recepcion"] : "");
  const [patient, setpatient] = useState(patientDict[tempRow.id_paciente]);
  const [allRequestPatient, setallRequestPatient] = useState([])
  const [listFileUpload, setlistFileUpload] = useState([])

  console.log(rowsActive)
  useEffect(() => {
    const tempListActive = rowsActive.filter((row) =>(
      row.estado_recepcion == tempRow.estado_recepcion && 
      moment(row.hora_estimada).format("YYYY-MM-DD") && moment(row.tempRow).format("YYYY-MM-DD") &&
      row.id_paciente === tempRow.id_paciente && 
      row.id_conductor === tempRow.id_conductor
    ))
    const tempFile = []
    tempListActive.map((row) => {
      tempFile.push(null)
    })
    setallRequestPatient(tempListActive)
    setlistFileUpload(tempFile)
  }, []);

  const handleSaveSend = () => {
    let estadoTransporte = "";
    const requestArray = [];
    let status = ""
    const list_id = allRequestPatient.map((row, index) => {
      const body = {};
      if (row.estado_recepcion === "GuiaEntregaRetrasado") {
        body["estado_recepcion"] = "Retrasado";
        status = "Retrasado";
        estadoTransporte = "EsperandoTransportes";
      } else {
        body["estado_recepcion"] = "Entregado";
        estadoTransporte = "Finalizado";
        status = "Finalizado";
      }

      requestArray.push(api.patch(`/api/agendatransportes/${row.id}`, body));
      if (row.id_envios_pedidos) {
        const body2 = {
          caja: row.id_envios_pedidos.id,
        };
        const formData = new FormData();
        formData.append("nombre", listFileUpload[index].name);
        formData.append("archivo",  listFileUpload[index]);
        formData.append("tipo_archivo", "GuiaDespacho");
        formData.append("observacion", observation);
        formData.append("area", "Transporte");
        formData.append("id_pedidos", row.id_envios_pedidos.id_solicitud_pedido);
        requestArray.push(apiRepeat.post(`/api/transporte/solicitudes/finalizar/`, body2));
        requestArray.push(apiRepeat.post("/api/documentospedidos/", formData));
      } else if (row.id_parada_traslado_ingreso) {
        let body3 = {};
        if (row.id_parada_traslado_ingreso.id_solicitud_ingreso.tipo_producto === "Insumo") {
          body3 = {
            estado_solicitud: "AdjuntarGuiaIngreso",
            estado_ingreso: "AdjuntarGuiaIngreso",
            estado_bodega: "EsperandoIngresos",
            estado_transporte: estadoTransporte
          };
        } else {
          body3 = {
            estado_solicitud: "AdjuntarGuiaIngreso",
            estado_ingreso: "AdjuntarGuiaIngreso",
            estado_bodega: "EsperandoIngresos",
            estado_transporte: estadoTransporte
          };
        }
        requestArray.push(
          api.patch(`/api/solicitudingresos/${row.id_parada_traslado_ingreso.id_solicitud_ingreso.id}`,body3)
        );
      }
      return row.id
    })
    console.log(list_id)
    Promise.all(requestArray).then((request) => {
     
      const tempRows = rowsActive.filter(row => !list_id.includes(row.id));
      const tempRowsEnds = [...rowsFinished, ...allRequestPatient.map((row) => {
        const tempRow = {...row}
        tempRow["estado_recepcion"] = status;
        return tempRow
      })];
      console.log(tempRows)
      setrowsActive(tempRows);
      serowsFinished(tempRowsEnds);
      setmessage("Guia de entrega adjuntada");
      handleClose();
    });
  };

  const handleSelecetFile = (file, index) => {
    const tempFiles = [...listFileUpload]
    tempFiles[index] = file[0]
    setlistFileUpload(tempFiles)
  }

  const listProps = [
    (tempRow.id_parada_traslado_ingreso && (tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudRetiroEquipo" || tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudEntregaRetiroEquipo" ))  ? 
    `Empresa: ${tempRow.id_parada_traslado_ingreso.nombre_empresa}`: 
    (tempRow.id_parada_traslado_ingreso && tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudRetiroCompra") ?
     `Paciente: ${tempRow.id_parada_traslado_ingreso.nombre_empresa}` :`Paciente: ${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`,
    tempRow.id_parada_traslado_ingreso ? tempRow.id_parada_traslado_ingreso.tipo_parada === "Inicio" ? 
    `Dirección: ${tempRow.id_parada_traslado_ingreso.direccion_calle} ${tempRow.id_parada_traslado_ingreso.direccion_numero} ${tempRow.id_parada_traslado_ingreso.direccion_oficina}` :
    tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso.tipo_producto === "Insumo" || tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso .tipo_producto === "Equipo" ?
    `Dirección: Bodega Ingreso` : `Dirección: Bodega Farmacia` : `Dirección: ${patient.direccion_calle} #${patient.direccion_casa_depto}`,
    tempRow.id_parada_traslado_ingreso ? `Comuna: ${tempRow.id_parada_traslado_ingreso.direccion_comuna}` : `Comuna: ${patient.direccion_comuna}`,
    tempRow.id_parada_traslado_ingreso ? `Región: ${tempRow.id_parada_traslado_ingreso.direccion_region}` : `Región: ${patient.direccion_region}`,
    tempRow.id_parada_traslado_ingreso ? `Zona: -` : `Zona: ${tempRow.zona}`,
    `Fecha solicitud: ${tempRow.fecha_solicitud? moment(tempRow.fecha_solicitud).format("YYYY-MM-DD HH:mm") : moment(tempRow.id_parada_traslado.fecha_hora).format("YYYY-MM-DD HH:mm")}`,
    tempRow.hora_estimada ? `Fecha a traslado:`: `Fecha a despachar: ${tempRow.id_envios_pedidos ?
      moment(tempRow.id_envios_pedidos.fecha_tentativa_llegada).format("YYYY-MM-DD HH:mm") : tempRow.fecha_tentativa_llegada ?
      moment(tempRow.fecha_tentativa_llegada).format("YYYY-MM-DD HH:mm") :  moment(tempRow.hora_estimada).format("YYYY-MM-DD HH:mm")}`,
      `Fecha estimada: ${tempRow.hora_estimada ? moment(tempRow.hora_estimada).format("DD-MM-YYYY HH:mm") : "-"}`,
    (tempRow.estado_recepcion === "Entregado" || tempRow.estado_recepcion === "Retrasado") && `Fecha entrega: ${tempRow.hora_llegada ? moment(tempRow.hora_llegada).format("YYYY-MM-DD HH:mm") : "-"}`,
    tempRow.id_envios_pedidos && `Medicamento: ${tempRow.id_envios_pedidos.es_farmacia ? "Sí" : "No"}`,
  ]

  const generateButtonName = (row) => {
    const auxRequest = row.id_envios_pedidos ? `Solicitud pedido: ${row.id_envios_pedidos.id_solicitud_pedido}` : row.id_parada_traslado ? `Solicitud paciente: ${row.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}` : " "
    const auxPackage = row.id_envios_pedidos && `Envio: ${tempRow.id_envios_pedidos ? row.id_envios_pedidos.numero_pedido : "-"}`
    return `${auxRequest} - ${auxPackage} - ${`Tipo de solicitud: ${dictTypeRequest[row.tipo_solicitud]?.label}`}`
  }

  const _content_ = <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        item
        xs={12}
      >
        <h5 id="spring-modal-title">{`Datos del pedido`}</h5>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          item
          xs={12}
        >
          {listProps.filter((row) => row).map((row) => (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              item
              md={3}
              sm={6}
              xs={12}
              style={{ padding: "5px" }}
            >
            <Typography variant="caption" display="block" gutterBottom>
              {row}
            </Typography>
            </Grid>
          ))}
          </Grid>
        <h5 id="spring-modal-title">{`Adjuntar guía de entrega`}</h5>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
          style={{ margin: "10px 0 10x 0" }}
        >
          <DetailText
            label={`Observación`}
            value={observation}
            onchange={(e) => {
              setobservation(e.target.value);
            }}
          />
        </Grid>
        {allRequestPatient.map((row, index) => (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={8}
          style={{ margin: "10px 0 30px 0" }}
        >
           <Button
             variant="contained"
             component="label"
             color="primary"
             className="text-white"
             style={{ margin: "0 10px" }}
           >
             {`Subir Archivo - ${generateButtonName(row)}`} 
             <TextField
               type="file"
               id="exam"
               onChange={(event) => handleSelecetFile(event.target.files, index)}
               fullWidth
               my={2}
               style={{ display: "none" }}
             />
           </Button>
           {listFileUpload[index]?.name && `${listFileUpload[index]?.name}`}
         </Grid>
        ))}
       
      </Grid>
    </div>

  const _actions_ = <>
      {listFileUpload.every((row) => row !== null) && observation ? (
      <Button
        mr={2}
        variant="contained"
        className="text-white"
        color="primary"
        onClick={handleSaveSend}
      >
        Guardar y enviar
      </Button>
    ) : (
      <Button mr={2} variant="contained" disabled>
        Guardar y enviar
      </Button>
    )}
    <Button
      mr={2}
      variant="contained"
      style={{ backgroundColor: red[500], marginRight: "10px" }}
      className="text-white"
      onClick={handleClose}
    >
      Cerrar
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={`Adjuntar guía de entrega`}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}
