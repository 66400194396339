import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableVetoes = props => {
  const classes = useStyles();
  const {
    vetoesInfo,
    setTempMotivo,
    setTempIdFichaPaciente,
    setTempIdFichaPersonal,
    setOpenModalEditPatient,
    setTempPatient,
    setTempPatientCompare,
    setTempVetoe
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const setInformation = row => {
    setTempVetoe(row);
    setTempMotivo(row.info_veto.motivo);
    setTempIdFichaPaciente(row.info_veto.id_ficha_paciente_id.id);
    setTempIdFichaPersonal(row.id_ficha_personal_id);
    setTempPatient({
      value: row.info_veto.id_ficha_paciente_id.id,
      label: `${row.info_veto.id_ficha_paciente_id.id_paciente_id.nombre} ${row.info_veto.id_ficha_paciente_id.id_paciente_id.apellido_paterno} ${row.info_veto.id_ficha_paciente_id.id_paciente_id.apellido_materno}`
    });
    setTempPatientCompare({
      value: row.info_veto.id_ficha_paciente_id.id,
      label: `${row.info_veto.id_ficha_paciente_id.id_paciente_id.nombre} ${row.info_veto.id_ficha_paciente_id.id_paciente_id.apellido_paterno} ${row.info_veto.id_ficha_paciente_id.id_paciente_id.apellido_materno}`
    });
    setOpenModalEditPatient(true);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Paciente</TableCell>
              <TableCell align="center">Motivo</TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vetoesInfo
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      {row.info_veto.id_ficha_paciente_id.id_paciente_id.nombre}
                      
                      {
                        row.info_veto.id_ficha_paciente_id.id_paciente_id
                          .apellido_paterno
                      }
                    </TableCell>
                    <TableCell align="center">{row.info_veto.motivo}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none" }}
                        onClick={() => setInformation(row)}
                      >
                        Editar
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "Todas", value: -1 }]}
        colSpan={12}
        component="div"
        count={vetoesInfo.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por pagina" },
          native: false
        }}
        labelRowsPerPage={"Filas por pagina"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atras"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `mas que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableVetoes;

TableVetoes.propTypes = {
  vetoesInfo: PropTypes.array,
  setTempMotivo: PropTypes.func,
  setTempIdFichaPaciente: PropTypes.func,
  setTempIdFichaPersonal: PropTypes.func,
  setOpenModalEditPatient: PropTypes.func,
  setTempPatient: PropTypes.func,
  setTempPatientCompare: PropTypes.func,
  setTempVetoe: PropTypes.func
};
