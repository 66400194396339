import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const Motivo = props => {
  const classes = useStyles();
  const {
    reason,
    setReason,
    reasons,
    setReasons,
    indications,
    setIndications,
    viewOnly
  } = props;

  const transferTypes = useSelector(state => state.currentList.LISTA_TIPO_TRASLADOS);

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container justify="center" style={{ marginTop: "3em" }}>
        <Grid item container xs={5} justify="center">
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            error={reason === "" ? true : false}
            disabled={viewOnly ? true : false}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Motivo del traslado
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={reason}
              onChange={e => setReason(e.target.value)}
              label="Motivo del traslado"
            >
              {transferTypes.map(row => (
                <MenuItem key={row.value} value={row.value}>
                  {row.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {reason === "" ? "Ingrese un motivo" : null}
            </FormHelperText>
          </FormControl>
        </Grid>
        {reason === "Otros" ? (
          <Grid
            item
            container
            xs={10}
            justify="center"
            style={{ marginTop: "2em", marginBottom: "2em" }}
          >
            <TextField
              disabled={viewOnly ? true : false}
              fullWidth
              id="outlined-multiline-static"
              label="Motivo del traslado"
              multiline
              rows={4}
              value={reasons}
              onChange={e => setReasons(e.target.value)}
              variant="outlined"
            />
          </Grid>
        ) : null}
        <Grid item container xs={10} justify="center">
          <TextField
            disabled={viewOnly ? true : false}
            error={indications === "" ? true : false}
            helperText={
              indications === "" ? "Ingrese indicaciones generales" : null
            }
            fullWidth
            id="outlined-multiline-static"
            label="Indicaciones generales"
            multiline
            rows={4}
            value={indications}
            onChange={e => setIndications(e.target.value)}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default Motivo;

Motivo.propTypes = {
  reason: PropTypes.string,
  setReason: PropTypes.func,
  reasons: PropTypes.string,
  setReasons: PropTypes.func,
  indications: PropTypes.string,
  setIndications: PropTypes.func,
  viewOnly: PropTypes.bool
};
