import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {Button, Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import TableComponent from "views/Widgets/Common/TableComponent";
import {setmessageGeneral } from "actions/getGlobalAction";


export default function ModalCloneBenefit(props) {
  const { open, closeModal, id_paquete, listPackage, setFirstCharge} = props;
  const dispatch = useDispatch();
  const [selectedPackage, setselectedPackage] = useState({});

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleCloneBenefit = (event) => {
    api.get(`api/clinica/clonarpaquete/origen/${selectedPackage.value}/llegada/${id_paquete}/`, {}).then((response) => {
        setFirstCharge(true);
        closeModal();
        setmessageGeneral(dispatch, response.data.message, "succes", "¡Exito!");
      })
      .catch((error) => {
        if(error?.response?.data?.error) {
          setmessageGeneral(dispatch, error?.response?.data?.error, "error", "¡Atencion!");
        }
      });
  }

  const handleSelectPackage = (event) => {
    api.get(`api/prestacionespaquete/?paquete=${event.value}`).then(response => {
      setselectedPackage({...event, benefit: response.data});
    })
  }

  const headers = [
    {label: "Grupo"},
    {label: "Prestación"},
    {label: "Cantidad"},
    {label: "Tipo Cantidad"},
    {label: "Frecuencia/Duración"},
    {label: "Tipo frecuencia"},
    {label: "Última modificación"},
    {label: "Estado"},
  ];

  const dataBenefit = selectedPackage?.benefit?.map((row) => {
    //eval is not use in table only condition to filter
    return [
      {value: row.id_prestaciones ? row.id_prestaciones.grupo : row.id_equipo ? row.id_equipo.tipo_producto : "-",},
      {value: row.id_prestaciones ? row.id_prestaciones.nombre : row.id_equipo ? `(${row.id_equipo.SKU}) ${row.id_equipo.descripcion}` : "-"},
      {value: row.cantidad},
      {value: row.id_prestaciones ? row.id_prestaciones.tipo_cantidad : row.id_equipo ? "Unidad" : "-",},
      {value: row.frecuencia === 0 ? "-" : row.frecuencia,},
      {value: row.tipo_frecuencia},
      {value: row.fecha_ultima_modificacion},
      {value: row.estado ? "Activa" : "Inactiva"},
    ]
  }) || [];

  const _content_ =  <div className="form-row">
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
    >
      <DetailSelect
        label="Seleccionar Paquete:"
        value={selectedPackage}
        onchange={(event) =>  handleSelectPackage(event)}
        list={listPackage}
      />

      <TableComponent
        headers={headers}
        data={dataBenefit}
        styleTableContainer={{height: "500px"}}
      />

    </Grid>

  </div>

  const _actions_ = <>
      <Button
          onClick={closeModal}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Cancelar
        </Button>
        {selectedPackage.value ? <Button
          onClick={handleCloneBenefit}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Clonar
        </Button> :
        <Button
          disabled
          color="primary"
          style={{ textTransform: "none" }}
        >
          Clonar
        </Button>
        }
  </>
  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      maxWidth="lg"
      title={"Clonar prestaciones paquete"}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}

ModalCloneBenefit.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
};
