import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const Providers = () => {
  return (
    <div className="mb-3 m-1">
      <div className="card">
        <div className="card-header text-center">Proveedores</div>
        <div className="card-body">
          <div className="row">
            {/* <div className="text-center">
              <h3>12</h3>
              <div className="col-sm text-center">Fraccionamientos</div>
            </div> */}
            <div className="text-center">
              <h3>7</h3>
              <div className="col-sm text-center">Proveedores disponibles</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm text-center mt-3 mb-1">
              <Link to={`/admin/purchases/providers/`}>
                <Button variant="contained" color="primary">
                  Ver detalle
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Providers;
