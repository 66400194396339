import React, {useState, useEffect } from 'react';

import {ProfessionalRemuneration} from "components/Remunerations/ProfessionalRemuneration";




export default class RemunerationDoc extends React.Component {
    render() {
        const {personalRow, dataArray, monthDate, doc} = this.props;
        return (
            <div >
                {doc ?
                <ProfessionalRemuneration
                    personalRow={personalRow}
                    dataArray={dataArray}
                    monthDate={monthDate}
                /> : <div>Nada</div>
                }
            </div>
        );
    }
}