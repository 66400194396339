import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {Button, Grid} from "@material-ui/core/";
import {red, green} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import {setmessageGeneral } from "actions/getGlobalAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function ModalDisabledPatient(props) {
  const dispatch = useDispatch();
  const { open, closeModal, dataPatient, setdataPatient} = props;


  const handleDisablePatient = () => {

    api.post(`api/paciente/inhabilitar/${dataPatient.id}/`).then(response => {
      let message = "Paciente dado de baja, esperando facturacion"
      if (!dataPatient.activo){
        message = "Paciente reactivado"
      }
      setdataPatient({...dataPatient, activo: !dataPatient.activo})
      setmessageGeneral(dispatch, message, "success", "¡Exito!");
      closeModal()
    })
  }

  const _content_ = <div>
    <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
      <Typography component="body2" variant="body2"  style={{fontWeight: 'bold'}} >{`¿Está seguro de que desea ${dataPatient.activo ? "inhabiilitar": "habilitar"} al paciente?`}</Typography>
    </Grid>
  </div>

  const _actions_ = <>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: green[500]}}
            className="text-white"
            onClick={handleDisablePatient}
        >
          {dataPatient.activo ? "Inhabilitar" : "Habilitar"}
        </Button>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500]}}
            className="text-white"
            onClick={() => closeModal()}
        >
            Cerrar
        </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={`${dataPatient.activo ? "Inhabilitar" : "Habilitar"}  paciente`}
      _content_={_content_}
      _actions_ = {_actions_}
      maxWidth="xs"
    />
  );
}