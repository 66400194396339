import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import {
  Typography,
  Grid,
  Fab,
  Link,
} from "@material-ui/core";
import {
  green, orange, red, indigo
} from "@material-ui/core/colors";
import WorkOffIcon from '@material-ui/icons/WorkOff';
import WorkIcon from '@material-ui/icons/Work';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PublishIcon from '@material-ui/icons/Publish';
import {ButtonTextTemplate as Button, CustomCardItem, ButtonTextMUI} from "utils/componentsStyle";
import ModalEditPatientTENS from "components/Rotary/ModalEditPatientTENS";
import ModalCreateTENS from "components/Rotary/ModalCreateTENS";
import ModalShowViewTensPatient from "components/Rotary/ModalShowViewTensPatient";
import DefineVisit from "components/Rotary/DefineVisit";
import EditVisit from "components/Rotary/EditVisit";
import EditVisitWork from "components/Rotary/EditVisitWork";
import DefineVisitWarning from "components/Rotary/DefineVisitWarning";
import PatientTable from "views/Widgets/Assignments/PatientTable";
import WorkerTable from "views/Widgets/Assignments/WorkerTable";
import MonthTable from "views/Widgets/Rotary/MonthTable";
import TableComponent from "views/Widgets/Common/TableComponent";
import TableStateTENS from "views/Widgets/Rotary/TableStateTENS";
import {dictMonth, daysWeekShort as daysWeek} from "utils/validationFunction";
import {setmessageGeneral } from "actions/getGlobalAction";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import apiformxlsx from "utils/APIForm"
import api from "utils/API";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver'; 

import moment from "moment-timezone";

import "moment/locale/es";

moment.locale("es");

const useStyles = makeStyles(styles);

const Rotary = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [changeButtons, setchangeButtons] = useState(true);
  const [updateArrayPatient, setupdateArrayPatient] = useState(false);
  const [updateArrayProfessional, setupdateArrayProfessional] = useState(false);
  const [changeFilter, setchangeFilter] = useState(false);
  const [initialCharge, setinitialCharge] = useState(true);
  const [chargeWorker, setchargeWorker] = useState(false);
  const [updateFilterPatient, setupdateFilterPatient] = useState(true);
  const [openModalEditTENS, setopenModalEditTENS] = useState(false);
  const [openModalCreateTENS, setopenModalCreateTENS] = useState(false);
  const [updateCalendar, setupdateCalendar] = useState(true);
  const [showTabs, setshowTabs] = useState("Visitas")

  const [selectPatient, setselectPatient] = useState(null);
  const [selectEditWeek, setselectEditWeek] = useState(null);
  const [scheduleVisit, setscheduleVisit] = useState(null);
  const [editScheduleVisit, seteditScheduleVisit] = useState(null);
  const [editWork, seteditWork] = useState(null);
  const [scheduleVisitOther, setscheduleVisitOther] = useState(null);
  const [orderProfessional, setorderProfessional] = useState(null);
  const [directionProfessional, setdirectionProfessional] = useState(null);
  const [orderPatient, setorderPatient] = useState(null);
  const [directionPatient, setdirectionPatient] = useState(null);

  const [startDatePeriod, setstartDatePeriod] = useState(moment().startOf("months"));
  const [endDatePeriod, setendDatePeriod] = useState(moment().endOf("months"));
  const [selectDate, setselectDate] = useState(moment());

  const [arrayWorker, setarrayWorker] = useState([]);
  const [arrayPatient, setarrayPatient] = useState([]);
  const [arrayProfessional, setarrayProfessional] = useState([]);
  const [auxArrayPatient, setauxArrayPatient] = useState([]);
  const [auxArrayProfessional, setauxArrayProfessional] = useState([]);
  const [filterArrayPatient, setfilterArrayPatient] = useState([]);
  const [dailyArray, setdailyArray] = useState([]);
  const [dailyDict, setdailyDict] = useState({});
  const [dictPatientFile, setdictPatientFile] = useState({});
  const [dictReservation, setdictReservation] = useState({});
  const [dictPatientFileWorker, setdictPatientFileWorker] = useState({});
  const [dictPatientWorker, setdictPatientWorker] = useState({});
  const [dictPatientschedule, setdictPatientschedule] = useState({});
  const [dictRegisterVar, setdictRegisterVar] = useState({});
  const [dictExtraWorksFile, setdictExtraWorksFile] = useState({});
  const [dictWorkOther, setdictWorkOther] = useState({});
  const [dictWorkerId, setdictWorkerId] = useState({});
  const [dictWorkerFile, setdictWorkerFile] = useState({});
  const [filterProfessionalTable, setfilterProfessionalTable] = useState("");
  const [filterPatientTable, setfilterPatientTable] = useState("");
  const [listPatient, setlistPatient] = useState([]);
  const [showPanelTable, setshowPanelTable] = useState("PANEL");
  const [dictAvailability, setdictAvailability] = useState({});
  const [listSelectedRemove, setlistSelectedRemove] = useState([]);
  const [listMonthTensPatient, setlistMonthTensPatient] = useState(null);
  const [selecterdMonthTens, setselecterdMonthTens] = useState(null);
  const [multipleSelectMode, setmultipleSelectMode] = useState(false);
  const [fileMultiUser, setfileMultiUser] = useState(null)

  const dictChargeId = useSelector(state => state.currentList.dictCharges);
  const listType = useSelector(state => state.currentList.LISTA_TIPO_TURNOS_TENS);
  const listTurnTensWork = useSelector(state => state.currentList.LISTA_TURNOS_TENS_TAREAS);
  const listCharges = useSelector(state => state.currentList.listCharges);


  const transformFrecuency = {
    Horas: "hours",
    Dias: "days",
    Semanas: "weeks",
    Meses: "months",
    Permanente: "days",
  }


  const listAbsence = [
    {value: 'inasistencias', label: 'Inasistencias'},
    {value: 'vacaciones', label: 'Vacaciones'}
  ]

  const statisticsColumnHeader = [
    {value: "proyecto", label: "Proyectado"},
    {value: "real", label: "Real"},
    {value: "domingos", label: "Domingos"},
    ...(listType || [])
  ]

  const listWorkFilter = (listTurnTensWork || []).map((row) => {return row.value})

  let dictBaseStatistics = (listType || []).reduce((accum, obj) => {return {...accum, [obj.value]: 0}}, {})

  dictBaseStatistics = {
    proyecto: 0,
    real: 0,
    feriado: 0,
    domingos: 0,
    ...dictBaseStatistics
  }



  useEffect(() => {
    if (chargeWorker && selectPatient) {
      getDictsWorkers();
    }
    if (updateArrayPatient){
      filterPatients();
    }
    if (updateArrayProfessional){
      filterProfessional();
    }
    if (changeButtons) {
      setchangeButtons(false);
    }
    if (changeFilter) {
      updateFilter();
    }
    if (updateFilterPatient){
      updateDataPatient();
    }
    if (updateCalendar){
      getArrayDatePeople();
    }
    if (initialCharge){
      initialData();
    }
  });

  const initialData = () => {
    setinitialCharge(false);

    const requestPatient = api.get("api/clinica/pacientes/TENS");
    const requestCharges = api.get("api/usuario/cargos/asignaciones/TENS");
    Promise.all([
      requestPatient,
      requestCharges,
    ]).then((request) => {
        const tempDictPatientFile = {};
        let tempPatientBenefit = [];
        const accumBenefit = {};
        const tempWorker = request[1].data;

        const tempDictWorkerId = tempWorker.reduce((accum, item) => {
          accum[item.id] = item;
          return accum;
        }, {});

        const tempDictWorkerFile = tempWorker.reduce((accum, item) => {
          accum[item?.id_ficha?.id] = item;
          return accum;
        }, {});

        request[0].data.map((row) => {
          const tempProcess = row.proceso == "Habilitacion" ? true : false;
          tempDictPatientFile[row.id_ficha_paciente.id] = row;
          if (row.id_paquete){
            row.id_paquete.id_prestacion.map((benefit) => {
              const tempRow = {
                ...row,
                id_prestacion: {...benefit, ingresados: 0},
              };
              delete tempRow["id_paquete"];
              const savekey = `${tempRow.id_prestacion.id_prestaciones.nombre}_${row.id}_${tempRow.id_prestacion.tipo_frecuencia}`
              if (accumBenefit[savekey]){
                accumBenefit[savekey].peticiones.push({
                  frecuencia: tempProcess ? 1 : tempRow.id_prestacion.frecuencia,
                  cantidad: tempProcess ? 1 : tempRow.id_prestacion.cantidad,
                  fecha: tempRow.id_prestacion.ultima_modificacion
                });
              } else {
                accumBenefit[savekey] = tempRow;
                accumBenefit[savekey].peticiones = [{
                  frecuencia: tempProcess ? 1 : tempRow.id_prestacion.frecuencia,
                  cantidad: tempProcess ? 1 : tempRow.id_prestacion.cantidad,
                  fecha: tempRow.id_prestacion.ultima_modificacion
                }];
                delete accumBenefit[savekey].id_prestacion.frecuencia;
                delete accumBenefit[savekey].id_prestacion.cantidad;
              }
            });
          }
        });

        tempPatientBenefit = Object.values(accumBenefit);
        setdictPatientFile(tempDictPatientFile);
        setarrayPatient(tempPatientBenefit);
        setarrayWorker(tempWorker);
        setdictWorkerId(tempDictWorkerId);
        setdictWorkerFile(tempDictWorkerFile);
        handleNavigation(selectDate);
    });
  };

  const getDictsWorkers = () => {
    setchargeWorker(false);
    const tempDicReservation = {};
    const tempDictPatientFileWorker = {};
    Object.values(dictPatientWorker).map((row) => {
      const tempData = row;
      const tempStartDate = moment(tempData.fecha_hora).format("YYYY-MM-DD");
      if (tempDictPatientFileWorker[tempData.id_ficha]){
        tempDictPatientFileWorker[tempData.id_ficha].push(tempData);
      } else {
        tempDictPatientFileWorker[tempData.id_ficha] = [tempData];
      }

      if (tempDicReservation[tempStartDate]){
        if (selectPatient.id_ficha_paciente.id === tempData.id_ficha){
          if (tempDicReservation[tempStartDate][tempData.id_usuarios]){
            tempDicReservation[tempStartDate][tempData.id_usuarios].push(tempData);
          } else {
            tempDicReservation[tempStartDate][tempData.id_usuarios] = [tempData];
          }
        }
      } else {
        tempDicReservation[tempStartDate] = {
          [tempData.id_usuarios]: [tempData]
        }
      }
    });

    Object.values(dictPatientschedule).map((row) => {
      const tempData = row;
      const tempStartDate = moment(tempData.fecha_hora_inicio).format("YYYY-MM-DD");
      if (tempDictPatientFileWorker[tempData.id_ficha]){
        tempDictPatientFileWorker[tempData.id_ficha].push(tempData);
      } else {
        tempDictPatientFileWorker[tempData.id_ficha] = [tempData];
      }
      if (tempDicReservation[tempStartDate]){
        if (selectPatient.id_ficha_paciente.id === tempData.id_ficha){
          if (tempDicReservation[tempStartDate][tempData.id_usuario]){
            tempDicReservation[tempStartDate][tempData.id_usuario].push(tempData);
          } else {
            tempDicReservation[tempStartDate][tempData.id_usuario] = [tempData];
          }
        }
      } else {
        tempDicReservation[tempStartDate] = {
          [tempData.id_usuario]: [tempData]
        }
      }
    });

    setdictReservation(tempDicReservation);
    setdictPatientFileWorker(tempDictPatientFileWorker);
    setupdateArrayProfessional(true);
    setupdateFilterPatient(true);
  };

  const getArrayDatePeople = () => {
    setupdateCalendar(false);
    const diffDays = moment(endDatePeriod).diff(moment(startDatePeriod), "days");
    const tempdaily={}
    const tempArray = [...Array(diffDays+1).keys()].map((row) => {
      const tempDay = moment(startDatePeriod).add(row, "days");
      const tempKey = moment(tempDay).format("YYYY-MM-DD");
      const tempValue =  {
        label: `${moment(tempDay).format("DD")} ${daysWeek[moment(tempDay).day()]}`,
        value: tempKey,
        colorHeader: red[100],
      }
      tempdaily[tempKey] = {...tempValue}
      return {...tempValue}
    });
    setdailyDict(tempdaily);
    setdailyArray(tempArray);
  };

  const checkDay = (patientRow, arrayWorks, type=null) => {
    const typeFrecuency = patientRow.id_prestacion.tipo_frecuencia;
    const tempdailyDict = dailyDict;
    const tempDateDictCount = {};
    arrayWorks.map((row) => {
      const tempDate = moment(row.fecha_hora_inicio).format("YYYY-MM-DD");
      if (tempDateDictCount[tempDate] ){
        tempDateDictCount[tempDate] += (type && (row.tipo_turno=="D" || row.tipo_turno=="N"))  ? 0.5 : 1;
      } else {
        tempDateDictCount[tempDate] = (type && (row.tipo_turno=="D" || row.tipo_turno=="N"))  ? 0.5 : 1;
      }
    });
    Object.keys(tempDateDictCount).map((dateRow) => {
      let tempQuantity = 0
      patientRow.peticiones.map((subRow) => {
        const quantity = subRow.cantidad;
        const frequency = subRow.frecuencia;
        const diffDate = moment(dateRow).diff(moment(startDatePeriod), transformFrecuency[typeFrecuency]);
        if (diffDate % frequency === 0){
            tempQuantity += quantity;
        }
      });
      if (tempQuantity > 0) {
        if (tempDateDictCount[dateRow] > 3) {
          tempdailyDict[dateRow].colorHeader = red[100];
        }
        if (tempQuantity > tempDateDictCount[dateRow]){
          tempdailyDict[dateRow].colorHeader = orange[100]
        } else {
          tempdailyDict[dateRow].colorHeader = green[100]
        }
      } else {
        tempdailyDict[dateRow].colorHeader = green[100];
      }
    });
    return {...tempdailyDict}
  };

  const updateFilter = () => {
    setchangeFilter(false);
    setchangeButtons(true);
  };

  const updateDataPatient = () => {
    setupdateFilterPatient(false);
    let tempArray = [];
    const tempListPatient = [];
    let tempdailyDict = {}
    const idSelectedFile = selectPatient ? selectPatient.id_ficha_paciente.id : null;
    if (showTabs === "Traslado" ){
      const tempFileIdWorks =  Object.keys(dictExtraWorksFile);
      tempFileIdWorks.map((row) => {
        const auxRow = {...dictPatientFile[row]};
        const tempPatientFile = dictPatientFileWorker[row];
        if (tempPatientFile){
          if (parseInt(row) === idSelectedFile && idSelectedFile){
            const tempDict = tempPatientFile.filter((tempRow) => tempRow.id_usuarios === null).reduce((accum, obj) => ({
              ...accum,
              [moment(obj.fecha_hora).format("YYYY-MM-DD")]: {
                ...obj,
              }
            }), {})
            setdictWorkOther(tempDict)
          }
        }
        delete auxRow.id_paquete;
        const tempExtraAmount = dictExtraWorksFile[auxRow.id_ficha_paciente.id] ?
        dictExtraWorksFile[auxRow.id_ficha_paciente.id].filter((tempRow) => (
            tempRow.id_usuarios
        )).length : 0;
        const tempTotalExtraAmount = dictExtraWorksFile[auxRow.id_ficha_paciente.id] ?
        dictExtraWorksFile[auxRow.id_ficha_paciente.id].length : 0;
        tempArray.push({
          ...auxRow,
          beneficio_actual: 0,
          beneficio_total: 0,
          descompensacion_actual: tempExtraAmount,
          descompensacion_total: tempTotalExtraAmount,
          proceso: "Traslado"
        });
        tempListPatient.push({value: auxRow.id, label: `${auxRow.rut} ${auxRow.nombre} ${auxRow.apellido_paterno}`});
      });
    } else {
      auxArrayPatient.filter((row) => (
        filterPatientTable == "" ||
        (filterPatientTable != "" && `${row.nombre} ${row.apellido_paterno}`.toLowerCase().includes(filterPatientTable.toLowerCase()))
      )).map((row) => {
        const idFile = row.id_ficha_paciente.id
        const tempPatientFile = dictPatientFileWorker[row.id_ficha_paciente.id] ? dictPatientFileWorker[row.id_ficha_paciente.id] : [];
        const tempType = row.id_prestacion.id_prestaciones.tipo_cantidad;
        let auxArray = [];
        let tempAmount = 0;
        auxArray = tempPatientFile;
        if (row.proceso === "Habilitacion"){
          auxArray = auxArray.filter((tempRow) => (idFile === tempRow.id_ficha && tempRow.tipo_tarea === "HabilitacionDomicilio"));
          setdictWorkOther(auxArray.filter((tempRow) => tempRow.id_usuarios === null).reduce((accum, obj) => ({
            ...accum,
            [moment(obj.fecha_hora).format("YYYY-MM-DD")]: {
              ...obj,
            }
          }), {}))
          tempAmount = auxArray.length;
        } else {
          auxArray = auxArray.filter((tempRow) => (idFile === tempRow.id_ficha && tempRow.tipo_tarea !== "HabilitacionDomicilio" && tempRow.tipo_tarea !== "VisitaInicial"));
          if (tempType==="Turno12"){
            auxArray = auxArray.filter((tempRow) => (listWorkFilter.includes(tempRow.tipo_turno)))
            tempAmount = auxArray.length;
            if (idSelectedFile === idFile){
              tempdailyDict = checkDay(row, auxArray);
            }
          } else if (tempType==="Turno24") {
            const auxArrayMid = auxArray.filter((tempRow) => (listWorkFilter.includes(tempRow.tipo_turno)))
            auxArray = auxArray.filter((tempRow) => (["T", "EXT", "TR24"].includes(tempRow.tipo_turno)))
            tempAmount = auxArray.length + auxArrayMid.length/2;
            if (idSelectedFile === idFile){
              tempdailyDict = checkDay(row, [...auxArray, ...auxArrayMid], "Turno24");
            }
          } else {
            tempAmount = tempArray.length;
          }
        }
        if ((row.proceso === "Habilitacion" && tempAmount > 0 )|| (row.proceso !== "Habilitacion")) {
          tempArray.push({...row, beneficio_actual: tempAmount});
          tempListPatient.push({value: row.id, label: `${row.rut} ${row.nombre} ${row.apellido_paterno}`})
        }

      });

    }
    if (orderPatient){
      if (orderPatient === "Paciente"){
        if (directionPatient){
            tempArray = tempArray.sort((a, b) => `${a.nombre} ${a.apellido_paterno}` < `${b.nombre} ${b.apellido_paterno}` ? 1 : -1);
        } else {
            tempArray = tempArray.sort((a, b) =>  `${a.nombre} ${a.apellido_paterno}` > `${b.nombre} ${b.apellido_paterno}` ? 1 : -1);
        }
      } else if (orderPatient === "Estado"){
        if (directionPatient){
            tempArray = tempArray.sort((a, b) => a.beneficio_actual/a.beneficio_total < b.beneficio_actual/b.beneficio_total ? 1 : -1);
        } else {
            tempArray = tempArray.sort((a, b) =>  a.beneficio_actual/a.beneficio_total > b.beneficio_actual/b.beneficio_total ? 1 : -1);
        }
      }
    }
    const tempdailyArray = Object.values(tempdailyDict)
    setlistPatient(tempListPatient);
    setfilterArrayPatient(tempArray);
    if (tempdailyArray.length > 0){
      setdailyDict(tempdailyDict);
      setdailyArray(Object.values(tempdailyArray));
    }
    setchangeButtons(true);
  };



  const getTotalVisitNow = (row, idSelectedFile, idFile) => {
    const tempdailyDict = dailyDict;
    let tempQuantity = 0;
    const typeFrecuency = row.id_prestacion.tipo_frecuencia;
    dailyArray.map((date) => {
      let auxQuantity = 0
      const diffDate = moment(date.value).diff(moment(startDatePeriod), transformFrecuency[typeFrecuency]);
      row.peticiones.map((subRow) => {
        const quantity = subRow.cantidad;
        let frequency = subRow.frecuencia;
        if (diffDate % frequency === 0){
            tempQuantity += quantity;
            auxQuantity += quantity;
        }
      });
      if (idSelectedFile === idFile && auxQuantity === 0 && tempdailyDict[moment(date).format("YYYY-MM-DD")]){
        tempdailyDict[moment(date).format("YYYY-MM-DD")].colorHeader = green[100];
      }
    });
    if (idSelectedFile === idFile ){
      setdailyDict(tempdailyDict);
      setdailyArray(Object.values(tempdailyDict));
    }
    return tempQuantity;
  };

  const filterPatients = () => {
    setupdateArrayPatient(false);
    const idSelectedFile = selectPatient ? selectPatient.id_ficha_paciente.id : null;
    const tempArrayPatient = [];
    let tempPatient = selectPatient || null
    arrayPatient.filter((row) => (
      (
        (showTabs === "Visitas" && row.proceso === undefined) ||
        (showTabs === "Traslado" && row.proceso === "Traslado") ||
        (showTabs === "Habilitacion" && row.proceso === "Habilitacion")
      ) &&
      (
        (row.proceso !== "Habilitacion" && moment(endDatePeriod).diff(moment(row.id_prestacion.ultima_modificacion), "days") >= 0) ||
        (moment(endDatePeriod).diff(moment(row.id_prestacion.ultima_modificacion), "days") >= 0 && moment(row.id_prestacion.ultima_modificacion).diff(startDatePeriod, "days") >= 0 )
      )
      )).map((row) => {
      const idFile = row.id_ficha_paciente.id
      const tempRow = {...row};
      const tempChargeId = row.id_prestacion.id_prestaciones.id_cargo_id;
      const totalBenefit = row.proceso === "Habilitacion" ? 1 : getTotalVisitNow(row, idSelectedFile, idFile);
      tempRow["beneficio_total"] = totalBenefit;
      if (tempRow["beneficio_total"] > 0) {
        let tempCharge = dictChargeId[tempChargeId];
        if (tempCharge){
          tempArrayPatient.push({...tempRow});
        }
        if (!tempPatient){
          tempPatient = tempRow
        }
      }
    });
    if (tempPatient){
      handleSelectPatient(tempPatient);
    } else {
      setupdateCalendar(true);
      setchargeWorker(true);
    }
    setauxArrayPatient(tempArrayPatient);
  };

  const filterProfessional = () => {
    setupdateArrayProfessional(false);
    const tempArrayProfessional = [];
    let tempSelectProfessional = null;
    arrayProfessional.map((row) => {
        tempArrayProfessional.push(row);
        if (tempSelectProfessional === null ){
          tempSelectProfessional = row;
        }
    });

    setauxArrayProfessional(tempArrayProfessional);

  };

  const handleFilterProfessional = (event) => {
    setfilterProfessionalTable(event.target.value);
  };

  const handleFilterPatient = (event) => {
    setfilterPatientTable(event.target.value);
    setupdateFilterPatient(true);
  };

  const handleNavigation = (date) => {

    const tempStartDate = moment(date).startOf("months").format("YYYY-MM-DD");
    const tempEndDate = moment(date).endOf("months").format("YYYY-MM-DD");
    const requestTurns = api.get(`api/clinica/pacientes/turnos/tens/?fecha_inicio=${tempStartDate}T00:00:00&&fecha_fin=${tempEndDate}T23:59:59`);
    const requestPatientWorks = api.get(`api/clinica/tareaspaciente/asignacion/TENS/?fecha_inicio=${tempStartDate}T00:00:00&&fecha_fin=${tempEndDate}T23:59:59`);
    const requestRegister = api.get(`api/registrovariablesdinamicas/?fecha_inicio=${tempStartDate}T00:00:00&&fecha_fin=${tempEndDate}T23:59:59&&${selectPatient?.id ? `paciente=${selectPatient?.id}`: ""}`);
    Promise.all([requestTurns, requestPatientWorks, requestRegister]).then((request) => {
      const tempDictExtraWorksFile = {};
      setdictPatientschedule(request[0].data.reduce((accum, obj) => ({
        ...accum,
        [obj.id]: {
          ...obj,
          fecha_hora_inicio: moment(obj.fecha_hora_inicio).format("YYYY-MM-DD HH:mm:ss"),
          fecha_hora_fin: moment(obj.fecha_hora_fin).format("YYYY-MM-DD HH:mm:ss"),
        }
      }), {}));
      const dictWorks = {}
      request[1].data.map((row) => {
        const tempRow = {
          ...row,
          fecha_hora: moment(row.fecha_hora).format("YYYY-MM-DD HH:mm:ss"),
          fecha_final: moment(row.fecha_final).format("YYYY-MM-DD HH:mm:ss"),
        }
        if (tempDictExtraWorksFile[row.id_ficha] === undefined){
          tempDictExtraWorksFile[row.id_ficha] = []
        }
        tempDictExtraWorksFile[row.id_ficha].push({...tempRow});
        dictWorks[tempRow.id] = tempRow;
      });
      setdictRegisterVar(request[2].data.reduce((accum, obj) => ({
        ...accum,
        [obj.id]: {...obj}
      }), {}));
      setdictExtraWorksFile(tempDictExtraWorksFile);
      setdictPatientWorker(dictWorks);
      setstartDatePeriod(tempStartDate);
      setendDatePeriod(tempEndDate);
      setselectDate(moment(date));
      setupdateCalendar(true);
      setupdateArrayPatient(true);
    });
  };

  const handleDownloadData = () => {
    const dictProff = auxArrayProfessional.reduce((accum, obj) => {
      return {...accum, [obj.id]: obj}

    }, {})
    let data = Object.values(dictPatientWorker).map((row) => {
      const item = {
        id: row.id,
        tipo_tarea: row.tipo_tarea,
        fecha_inicio: row.fecha_hora,
        fecha_fin: row.fecha_final,
        estado: row.estado,
        descripcion: row.descripcion,
        comentario: row.comentario,
      }
      const userData = dictProff[row.id_usuarios]
      let userName = `${userData?.first_name || ""} ${userData?.last_name || ""}`
      item["profesional"] = userName 
      return item
    })

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Almacena el archivo Excel generado en un búfer
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    }); 

      // Crea un objeto Blob y descárgalo como "data.xlsx"
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    saveAs(blob, `${selectPatient?.rut}_${moment(selectDate).format("YYYY-MM")}.xlsx`);
  };

  const handleSelectDateWorker = (row, rowWorker, rowWork) => {
    if (listPatient.length > 0 && rowWork){
      seteditWork([rowWork, rowWorker])
    } else {
        setscheduleVisit([row, rowWorker, true]);
    }
  };

  const handleSelectPatient = (row) => {

    if (row){
      const requestPatientTens = api.get(`api/clinica/tens/paciente/${row.id_ficha_paciente.id}`)
      Promise.all([
        requestPatientTens,
      ]).then((request) => {
        const tempDictAvailability = {};

        const tempAsignTens = request[0].data.map((obj) => {
          if (obj.registros){
            obj.registros.map((registro) => {
              if (tempDictAvailability[obj.id] === undefined){
                tempDictAvailability[obj.id] = []
              }
              tempDictAvailability[obj.id].push(registro)
            });
          }
          return obj.id
        });
        setdictAvailability(tempDictAvailability)
        setarrayProfessional(arrayWorker.filter((tempRow) => tempAsignTens.includes(tempRow.id)))
        setselectPatient(row);
        setupdateCalendar(true);
        setchargeWorker(true);
      });
    } else {
      setarrayProfessional([]);
      setselectPatient(row);
      setdictReservation([]);
      setdictPatientFileWorker([]);
      setupdateArrayProfessional(true);
      setupdateFilterPatient(true);
    }
  };

  const handleEditDateWorker = (row, RowWorker) => {
    seteditScheduleVisit([row, RowWorker]);
  };

  const handleSelectProfessional = (row) => {
    api.get("api/usuario/cargos/asignaciones/TENS")

  };

  const handleOrderProfessional = (headerName) => {
    let tempDirection = directionProfessional;
    if (headerName === orderProfessional){
      tempDirection = !directionProfessional;
    } else {
      tempDirection = false;
    }

    setorderProfessional(headerName);
    setdirectionProfessional(tempDirection);
  };

  const handleOrderPatient = (headerName) => {
    let tempDirection = directionPatient;
    if (headerName === orderPatient){
      tempDirection = !directionPatient;
    } else {
      tempDirection = false;
    }

    setorderPatient(headerName);
    setdirectionPatient(tempDirection);
    setupdateFilterPatient(true);
  };

  const handleSelectWorkerOther = (row, rowWorker, rowWork=null) => {
    setscheduleVisitOther([row, rowWorker, rowWork]);
  };

  const handleShowTabs = (value) => {
    setshowTabs(value);
    setupdateCalendar(true);
    setupdateArrayPatient(true);

  };

  const handleSaveReservation = (
    type,
    start,
    end,
    extraLunchAmount,
    shiftMobilizationAmount,
    specialViaticAmount,
    extraTurnValue,
    comment,
    value,
    dateArray,
  ) => {
    const tempWorker = scheduleVisit[1];
    const dateWorker = scheduleVisit[0];
    let tempStart = moment(`${moment(dateWorker.value).format("YYYY-MM-DD")} ${start.value}`);
    let tempEnd = moment(`${moment(dateWorker.value).format("YYYY-MM-DD")} ${end.value}`);
    let tempTime = 0;
    if (moment(tempEnd).diff(tempStart) <=0 ){
      tempEnd = moment(tempEnd).add(1, "days")
    }

    const tempDateArray = dateArray.map((row) => moment(`${row} ${start.value}`).format("YYYY-MM-DD"))
    tempTime = tempEnd.diff(moment(tempStart), "minutes");

    const bodyWork = {
      tipo_turno: type.value,
      id_ficha_personal: tempWorker.id_ficha.id,
      id_ficha: selectPatient.id_ficha_paciente.id,
      id_usuario: tempWorker.id,
      duracion_minutos: tempTime,
      comentarios: comment,
    };

    const extraBody = {
      valor_hora: parseInt(value),
      colacion_turno_extra: parseInt(extraLunchAmount),
      movilizacion_turno_extra: parseInt(shiftMobilizationAmount),
      viaticos_especial: parseInt(specialViaticAmount),
      hora_extra: parseInt(extraTurnValue),
      hora_inicio: tempStart.format("HH:mm"),
      hora_termino: tempEnd.format("HH:mm"),
    };

    const body = {
      tarea: bodyWork,
      extra: extraBody,
      fechas: tempDateArray,
    };

    const tempDictPatientschedule = {...dictPatientschedule};
    const tempDictPatientWorker = {...dictPatientWorker};
    setscheduleVisit(null);

    api.post("api/clinica/pacientes/asignar/turno/tens/", body).then((response) => {
      response.data["tareas"].map((row) => {
        tempDictPatientWorker[row.id] = row
      })
      response.data["asigancion"].map((row) => {
        const tempRow = {
          ...row,
          fecha_hora_inicio: moment(row.fecha_hora_inicio).format("YYYY-MM-DD HH:mm:ss"),
          fecha_hora_fin: moment(row.fecha_hora_fin).format("YYYY-MM-DD HH:mm:ss"),
        }
        tempDictPatientschedule[row.id] = {...tempRow};
      });
      setdictPatientschedule(tempDictPatientschedule);
      setdictPatientWorker(tempDictPatientWorker)
      setupdateCalendar(true);
      setupdateArrayPatient(true);
      
      setmessageGeneral(dispatch, "Visita ingresada", "success", "¡Exito!");
    });
  };

  const handleEditReservation = (
    type,
    start,
    end,
    extraLunchAmount,
    shiftMobilizationAmount,
    specialViaticAmount,
    extraTurnValue,
    value,
    comment,
    date,
  ) => {

    let tempData = null;
    let tempWorker = null

    tempData = editScheduleVisit[0];
    tempWorker = editScheduleVisit[1];

    let tempStartDate = moment(`${moment(date).format("YYYY-MM-DD")} ${start.value}:00`).format("YYYY-MM-DD HH:mm:ss");
    let tempEndDate = moment(`${moment(date).format("YYYY-MM-DD")} ${end.value}:00`).format("YYYY-MM-DD HH:mm:ss");

    if (moment(tempEndDate).diff(tempStartDate) <=0 ){
      tempEndDate = moment(tempEndDate).add(1, "days");
    }
    const bodyWork = {...tempData}
    bodyWork["tipo_turno"] = type.value;
    bodyWork["fecha_hora_inicio"] = tempStartDate;
    bodyWork["fecha_hora_fin"] = tempEndDate;
    bodyWork["comentarios"] = comment;

    const tempTime = moment(tempEndDate).diff(moment(tempStartDate), "minutes");
    bodyWork["duracion_minutos"] = tempTime

    const extraBody = {
      valor_hora: parseInt(value),
      colacion_turno_extra: parseInt(extraLunchAmount),
      movilizacion_turno_extra: parseInt(shiftMobilizationAmount),
      viaticos_especial: parseInt(specialViaticAmount),
      hora_extra: parseInt(extraTurnValue),
      id_ficha_personal: tempWorker.id_ficha.id,
    };

    const body = {
      tarea: bodyWork,
      extra: extraBody,
    };

    const tempDictPatientschedule = {...dictPatientschedule};
    seteditScheduleVisit(null);
    setselectEditWeek(null);

    api.put(`api/clinica/pacientes/asignar/turno/tens/${tempData.id}`, body).then((response) => {
      const tempData = response.data;
      const tempDataRequest = {
        ...tempData,
        fecha_hora_inicio: moment(tempData.fecha_hora_inicio).format("YYYY-MM-DD HH:mm:ss"),
        fecha_hora_fin: moment(tempData.fecha_hora_fin).format("YYYY-MM-DD HH:mm:ss"),
      };
      if (moment(date).format("MM") === moment(selectDate).format("MM")){
        tempDictPatientschedule[tempDataRequest.id] = tempDataRequest;
      } else {
        delete tempDictPatientschedule[tempDataRequest.id];
      }
      setdictPatientschedule(tempDictPatientschedule);
      setupdateCalendar(true);
      setupdateArrayPatient(true);
      setmessageGeneral(dispatch, "Visita Editada", "success", "¡Exito!");
    });
  };

  const handleEditWork = (
    extraLunchAmount,
    shiftMobilizationAmount,
    specialViaticAmount,
    extraTurnValue,
    comment,
  ) => {

    let tempData = null;
    let tempWorker = null

    tempData = editWork[0];
    tempWorker = editWork[1];

    const bodyWork = {...tempData}

    bodyWork["comentarios"] = comment;
    bodyWork["id_usuarios"] = tempWorker.id;

    const extraBody = {
      hora_extra: parseInt(extraTurnValue),
      colacion_turno_extra: parseInt(extraLunchAmount),
      movilizacion_turno_extra: parseInt(shiftMobilizationAmount),
      movilizacion_especial: 0,
      viaticos_especial: parseInt(specialViaticAmount),
      id_ficha_personal: tempWorker.id_ficha.id,
    };

    const body = {
      tarea: bodyWork,
      exta: extraBody,
    };

    const tempDictPatientWorker = {...dictPatientWorker};
    seteditWork(null);
    api.put(`api/clinica/asignar/tarea/${tempData.id}`, body).then((response) => {
      const tempDataRequest = response.data;
      tempDictPatientWorker[tempDataRequest.id] = tempDataRequest;
      setdictPatientWorker(tempDictPatientWorker);
      setupdateCalendar(true);
      setupdateArrayPatient(true);
      setmessageGeneral(dispatch, "Visita Editada", "success", "¡Exito!");
    });
  };

  const handleRemoveReservation = () => {

    let tempData = null;
    if (selectEditWeek) {
      tempData = selectEditWeek;
    } else {
      tempData = editScheduleVisit[0];
    }
    const tempDictPatientWorker = { ...dictPatientWorker };
    const tempDictPatientSchedule = { ...dictPatientschedule };

    seteditScheduleVisit(null);
    setselectEditWeek(null);
    
    api.delete(`api/clinica/asignar/tarea/${tempData.id}`).then((response) => {
      delete tempDictPatientWorker[tempData.id];
      Object.values(tempDictPatientSchedule).forEach((row) => {
        console.log(row)
        if (row.id_asignacion_tens && !tempDictPatientWorker[row.id_asignacion_tens] ){
          delete tempDictPatientSchedule[row.id_asignacion_tens]
        }
      })
      setdictPatientWorker(tempDictPatientWorker);
      setdictPatientschedule(tempDictPatientSchedule);
      setupdateCalendar(true);
      setupdateArrayPatient(true);
      setmessageGeneral(dispatch, "Visita eliminada", "success", "¡Exito!");
    });
  };

  console.log("dictPatientWorker", dictPatientWorker)
  console.log("dictPatientschedule", dictPatientschedule)

  const deleteVisita = () => {

    seteditScheduleVisit(null);

    let tempData = null;
    tempData = editScheduleVisit[0];

    const tempDictPatientWorker = { ...dictPatientWorker };
    const tempDictPatientSchedule = { ...dictPatientschedule };
    api.delete(`api/clinica/pacientes/asignar/turno/tens/${tempData.id}`).then(() => {
      delete tempDictPatientSchedule[tempData.id];
      Object.values(tempDictPatientWorker).forEach((row) => {
        if (row.id_asignacion_tens && !tempDictPatientSchedule[row.id_asignacion_tens] ){
          delete tempDictPatientWorker[row.id]
        }
      })
      setdictPatientWorker(tempDictPatientWorker);
      setdictPatientschedule(tempDictPatientSchedule);
      setupdateCalendar(true);
      setupdateArrayPatient(true);
      setmessageGeneral(dispatch, "Visita eliminada", "success", "¡Exito!");
    });
  };

  const handleSaveAbsence = (updateDateStart, updateDateEnd, lastDayWork, dayReinstatement, type, licence=null, admonition=null, discount=null ) => {
    const tempWorker = scheduleVisit[1];
    const body = {
        fecha_hora_inicio: moment(updateDateStart).format("YYYY-MM-DDTHH:mm:ss"),
        fecha_hora_fin: moment(updateDateEnd).format("YYYY-MM-DDTHH:mm:ss"),
        ultimo_dia_trabajado: moment(lastDayWork).format("YYYY-MM-DDTHH:mm:ss"),
        primer_dia_trabajar: moment(dayReinstatement).format("YYYY-MM-DDTHH:mm:ss"),
        total_dias: String(moment(updateDateEnd).diff(moment(updateDateStart), "days") +1),
        id_ficha_personal: tempWorker?.id_ficha?.id,
    }
    if (type.value === "vacaciones"){
        body["estado_solicitud"] = "Aprobadas jefatura";
    } else if(type.value === "inasistencias"){
        body["licencia"] = licence;
        body["amonestacion"] = admonition;
        body["descuento"] = discount;
    }
    setscheduleVisit(null);

    api.post(`/api/rrhh/variablesdinamicas/registros/${type.value}/`,body ).then((response) => {
      setupdateCalendar(true);
      setupdateArrayPatient(true);
      setmessageGeneral(dispatch, "Disponibilidad ingresada", "success", "¡Exito!");
    })
  }

  const handleEditAbsence = (updateDateStart, updateDateEnd, lastDayWork, dayReinstatement, type, licence=null, admonition=null, discount=null ) => {
      const tempEventInfo = editScheduleVisit[0]
      const tempWorker = editScheduleVisit[1];
      const body = {
        fecha_hora_inicio: moment(updateDateStart).format("YYYY-MM-DDTHH:mm:ss"),
        fecha_hora_fin: moment(updateDateEnd).format("YYYY-MM-DDTHH:mm:ss"),
        ultimo_dia_trabajado: moment(lastDayWork).format("YYYY-MM-DDTHH:mm:ss"),
        primer_dia_trabajar: moment(dayReinstatement).format("YYYY-MM-DDTHH:mm:ss"),
        total_dias: String(moment(updateDateEnd).diff(moment(updateDateStart), "days") +1),
        id_ficha_personal: tempWorker?.id_ficha?.id,
    }
    if (type.value === "vacaciones"){
        body["estado_solicitud"] = "Aprobadas jefatura";
    } else if(type.value === "inasistencias"){
        body["licencia"] = licence;
        body["amonestacion"] = admonition;
        body["descuento"] = discount;
    }

    seteditScheduleVisit(null);
    api.patch(`api/rrhh/variablesdinamicas/registros/${type.value}/${tempEventInfo.id}/`, body).then(response => {
        setupdateCalendar(true);
        setupdateArrayPatient(true);
        setmessageGeneral(dispatch, "Disponibilidad Editada", "success", "¡Exito!");
      })
  };

  const handleDeleteAbsence = (type) => {
    const tempEventInfo = editScheduleVisit[0]

    seteditScheduleVisit(null);
    api.delete(`api/rrhh/variablesdinamicas/registros/${type.value}/${tempEventInfo.id}/`).then(() => {
      setupdateCalendar(true);
      setupdateArrayPatient(true);
      setmessageGeneral(dispatch, "Disponibilidad removida", "success", "¡Exito!");
    });
  }

  const checkExistSelectedItem = (item) => {
    return listSelectedRemove.filter((row) => (row.id === item.id && row?.valores_extras?.length === item?.valores_extras?.length))
  }

  const handleSelectDeletedItem = (selectMode, item=null) => {
    if (!selectMode && !item){
      setlistSelectedRemove([])
    } else if (selectMode && item) {
      let templistSelectedRemove = [...listSelectedRemove];
      if (checkExistSelectedItem(item).length === 0 ) {
        templistSelectedRemove.push(item)
      } else {
        templistSelectedRemove = listSelectedRemove.filter((row) => !(
          row.id === item.id &&
          row?.valores_extras?.length === item?.valores_extras?.length
        ))
      }
      setlistSelectedRemove(templistSelectedRemove)
    }
    setmultipleSelectMode(selectMode);
  }

  const headerWorksDelete = [
    {label: "Tipo"},
    {label: "Fecha"},
    {label: "Profesional"},
    {eval: false, label: "Acción"},
  ]

  const  dataWorksDelete = listSelectedRemove.map((row) => {
    const _removeButton_ = <Button
        style={{ background: indigo[500] }}
        onClick={() => {
          handleSelectDeletedItem(true, row);
        }}
      >
        Quitar
      </Button>
    let tempNameWorker = "Sin nombre"
    if (dictWorkerFile[row.id_ficha_personal_id]){
      const tempFile = dictWorkerFile[row.id_ficha_personal_id]
      tempNameWorker = `${tempFile.first_name} ${tempFile.last_name}`
    } else if (dictWorkerId[row.id_usuario]){
      const tempFile = dictWorkerId[row.id_usuario]
      tempNameWorker = `${tempFile.first_name} ${tempFile.last_name}`
    }

    const initial_date = moment(row.fecha_hora_inicio).format("YYYY-MM-DD")
    const final_date = moment(row.fecha_hora_fin).format("YYYY-MM-DD")
    let date_range = `${initial_date} a ${final_date}`
    if (initial_date === final_date){
      date_range = initial_date
    }
    //eval is not use in table only condition to filter
    return [
      {eval:true, value: row.tipo_registro || row.tipo_turno},
      {eval:true, value: date_range},
      {eval:true, value: tempNameWorker},
      {eval:false, _value_: _removeButton_},
    ]
  });

  const handleDeleteMultiple = () => {
    const registers = listSelectedRemove.filter((row) => !!row.valores_extras).map((row) => {return row["id"]});
    const schedules = listSelectedRemove.filter((row) => !row.valores_extras).map((row) => {return row["id"]});
    const body = {
      registros: registers,
      agenda: schedules,
    }

    api.post(`api/clinica/gestion/masiva/rotativa/`, body).then(() => {
      handleSelectDeletedItem(false);
      const tempDictPatientschedule = {...dictPatientschedule};
      schedules.map((schedule) => {
        delete tempDictPatientschedule[schedule]
      })
      handleNavigation(moment(selectDate));
      setmessageGeneral(dispatch, "Item eliminados", "success", "¡Exito!");
    });
  }

  const dictProfessionalStatistics = {};

  const dictAsigtTensWorker = Object.values(dictPatientWorker).filter((row) => row.id_asignacion_tens).reduce((accum, obj) => {
    return {...accum, [obj.id_asignacion_tens]: obj}
  }, {})

  const auxfilterArrayProfessional = auxArrayProfessional.map((row) => {
    const name = `${row.first_name} ${row.last_name}`;
    const tempHours = row.id_ficha.variable_personal ? row.id_ficha.variable_personal.valor * 4.3 : 0;
    const hours = tempHours > parseInt(tempHours) ? parseInt(tempHours) + 1 : parseInt(tempHours);
    let countReal = 0;
    let countProyect = 0;
    let countSunDay = 0

    if (!dictProfessionalStatistics[row.id]){
      dictProfessionalStatistics[row.id] = {...dictBaseStatistics}
    }

    Object.keys(dictReservation).filter((tempRow) => (
      moment(tempRow).isBetween(startDatePeriod, endDatePeriod) &&
      (filterProfessionalTable === "" || name.toLowerCase().includes(filterProfessionalTable.toLowerCase()))
    )).filter((tempRow) =>dictReservation[tempRow][row.id] ).map(tempRow => {
        const tempWork = dictReservation[tempRow][row.id];
        tempWork?.filter((subRow) => subRow.fecha_hora_inicio).map((subRow) => {
          let tempCount = 0
          if (moment(subRow.fecha_hora_inicio).day() === 0){
            countSunDay+= 1
          }
          dictProfessionalStatistics[row.id][subRow.tipo_turno] += subRow.duracion_minutos / 60;
          tempCount += subRow.duracion_minutos;
          if (dictAsigtTensWorker[subRow.id]){
            if (dictAsigtTensWorker[subRow.id]["estado"] =="Finalizado"){
              countReal += tempCount
            } else {
              countProyect += tempCount
            }
          }
          
        })
    });
    if (dictProfessionalStatistics[row.id]){
      dictProfessionalStatistics[row.id]["real"] += countReal / 60;
      dictProfessionalStatistics[row.id]["proyecto"] += countProyect / 60;
      dictProfessionalStatistics[row.id]["domingos"] += countSunDay;
    }
    return {
      ...row,
      visitas: hours,
      visitas_realizadas: countReal+countProyect / 60
    }
  }).sort((a,b) => {
      if (orderProfessional === "Profesional"){
        const aName = `${a.first_name} ${a.last_name}`;
        const bName = `${b.first_name} ${b.last_name}`;
        if (directionProfessional){
          return aName < bName ? 1 : -1;
        } else {
          return aName > bName ? 1 : -1;
        }
      } else if (orderProfessional === "Estado"){
        const aState = a.visitas_realizadas/a.visitas;
        const bState = b.visitas_realizadas/b.visitas;
        if (directionProfessional){
          return aState < bState ? 1 : -1;
        } else {
          return aState > bState ? 1 : -1;
        }
      } else {return 0}
  });

  const handleCheckPatientWorker = (row) => {

      setselecterdMonthTens(row)
      const tempStartDate = moment(selectDate).startOf("months").format("YYYY-MM-DD");
      const tempEndDate = moment(selectDate).endOf("months").format("YYYY-MM-DD");
      api.get(`api/usuario/obtener/paciente/?fecha_inicio=${tempStartDate}T00:00:00&&fecha_fin=${tempEndDate}T23:59:59&usuario=${row.id}`).then((response) => {
        setlistMonthTensPatient(response.data)

      })
  }

  const handleMultipleUser = () => {
    const formData = new FormData();
    formData.append("archivo", fileMultiUser);
    apiformxlsx.post("api/paciente/cargamasiva/rotativa/", formData).then(response => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download = `resultado_carga_masiva_${moment().format("YYYY-MM-DD")}.csv`;
        document.body.appendChild(link);
        link.click();
        setfileMultiUser(null)
      }).catch(error => {
        setfileMultiUser(null)
        {}
      });
  }


  const handleDownloadBaseFile = () => {
    api.get("api/paciente/descargarbasemasiva/rotativa/", { responseType: "blob" }).then(response => {
        var blob = new Blob([response.data], { type: response.headers["content-type"] });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "carga_masiva_turnos.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
  }


  return (
    <GridContainer>
      {changeButtons &&
        <div></div>
      }

      <Grid container item xs={12}>
        <GridItem xs={(multipleSelectMode || showPanelTable != "") ? 8 : 12} style={{transitionDuration: "1000ms" }}>
          <Grid item xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
              <Card>
              <CardBody stats className={classes.cardFooter}>
                { selectPatient &&
                <Grid item xs={12}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography color="textPrimary">{`Paciente: ${selectPatient.nombre} ${selectPatient.apellido_paterno}`}</Typography>
                </Grid>
                 }
                <Grid item xs={12}
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={6}>
                    <h6 className={classes.legendTitle}>Estados diarios</h6>
                    <i className={"fas fa-circle"} style={{color: "#ffcdd2"}} /> Sin asignar{` `}
                    <i className={"fas fa-circle"} style={{color: "#ffe0b2"}} /> Asignado con pendientes{` `}
                    <i className={"fas fa-circle"} style={{color: "#c8e6c9"}}/> Asignado{` `}
                  </Grid>
                  <Grid item xs={6}>
                    <h6 className={classes.legendTitle}>Tareas por asignar</h6>
                    <i className={"fas fa-circle"} style={{color: "#5bc0de"}} /> Habilitación{` `}
                    <i className={"fas fa-circle"} style={{color: "#ff5722"}}/> Examen{` `}
                    <i className={"fas fa-circle"} style={{color: "#5c6bc0"}}/> Traslado{` `}
                  </Grid>
                  <Grid item xs={6}>
                    <h6 className={classes.legendTitle}>Disponibilidad</h6>
                    <i className={"fas fa-circle"} style={{color: orange[500]}} /> Vacaciones{` `}
                    <i className={"fas fa-circle"} style={{color: red[500]}}/> Inasistencias{` `}
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <MonthTable
              dailyArray={dailyArray}
              arrayWorkers={auxfilterArrayProfessional}
              dictAvailability={dictAvailability}
              selectDate={selectDate}
              selectPatient={selectPatient}
              listSelectedRemove={listSelectedRemove}
              multipleSelectMode={multipleSelectMode}
              checkExistSelectedItem={checkExistSelectedItem}
              handleNavigation={handleNavigation}
              handleDownloadData={handleDownloadData}
              handleSelectDateWorker={handleSelectDateWorker}
              handleEditDateWorker={handleEditDateWorker}
              handleSelectWorkerOther={handleSelectWorkerOther}
              handleSelectDeletedItem={handleSelectDeletedItem}
              seteditWork={seteditWork}
              showTabs={showTabs}
              listType={listType}
              listAbsence={listAbsence}
              dictPatientFile={dictPatientFile}
              dictReservation={dictReservation}
              dictWorkOther={dictWorkOther}
              dictRegisterVar={dictRegisterVar}
              changeButtons={changeButtons}
              handleCheckPatientWorker={handleCheckPatientWorker}
            />
          </Grid>
          <Grid item xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <TableStateTENS
              arrayWorkers={auxfilterArrayProfessional}
              columnHeader={statisticsColumnHeader}
              dictColumnHeader={dictProfessionalStatistics}
              changeButtons={changeButtons}
            />
          </Grid>
        </GridItem>
        <GridItem item xs={4} style={{transitionDuration: "1000ms", display: (multipleSelectMode) ? "block" : "none" }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{ margin: "10px 0 10x 0" }}
          >
            <h5>{`Selección multiple`}</h5>
          </Grid>
          <Grid item xs={12}>
            <TableComponent
              headers={headerWorksDelete}
              data={dataWorksDelete}
              styleTableContainer={{maxHeight: "100%"}}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
          >
            <Button
              mr={2}
              variant="contained"
              style={{
                  backgroundColor: indigo[500],
                  color: indigo[50],
                  margin: "10px 0 0 0",
              }}
              onClick={() => handleDeleteMultiple()}
            >
              Eliminar Seleccionados
            </Button>
          </Grid>
        </GridItem>
        <GridItem item xs={4} style={{transitionDuration: "1000ms", display: (!multipleSelectMode && showPanelTable != "") ? "block" : "none" }}>
          <Grid item xs={12} style={{transitionDuration: "1000ms", display: (showPanelTable == "PANEL") ? "block" : "none" }}>
            <PatientTable
              arrayPatient={filterArrayPatient}
              period={"Meses"}
              calendarView={"months"}
              filter={filterPatientTable}
              showTableSwich={true}
              selectPatient={selectPatient}
              handlePeriod={() => {return false}}
              handleFilter={handleFilterPatient}
              handleSelectPatient={handleSelectPatient}
              handleOrder={handleOrderPatient}
              handleShowTabs={handleShowTabs}
              order={orderPatient}
              direction={directionPatient}
              changePeriod={false}
              showPeriod={false}
              showTabs={showTabs}
              changeButtons={changeButtons}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{transitionDuration: "1000ms", display: (showPanelTable == "PANEL") ? "block" : "none"}}
          >
            <Button
              mr={2}
              variant="contained"
              style={{
                  backgroundColor: indigo[500],
                  color: indigo[50],
                  margin: "10px 0 0 0",
                  width: "100%"
              }}
              onClick={() => setopenModalCreateTENS(true)}
            >
              Crear TENS
            </Button>
          </Grid>
          <Grid item xs={12} style={{margin: "10px 0 0 0", transitionDuration: "1000ms", display: (showPanelTable == "PANEL") ? "block" : "none" }}>
            <WorkerTable
              arrayWorker={auxfilterArrayProfessional}
              period={"Meses"}
              filter={filterProfessionalTable}
              handleFilter={handleFilterProfessional}
              showTableSwich={true}
              selectProfessional={null}
              handleSelectProfessional={handleSelectProfessional}
              handleOrder={handleOrderProfessional}
              order={orderProfessional}
              direction={directionProfessional}
              changeButtons={changeButtons}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{transitionDuration: "1000ms", display: (showPanelTable == "PANEL") ? "block" : "none" }}
          >
            {(listPatient.length > 0 && selectPatient) ?
            <Button
              mr={2}
              variant="contained"
              style={{
                  backgroundColor: indigo[500],
                  color: indigo[50],
                  margin: "10px 0 0 0",
                  width: "100%",
              }}
              onClick={() => setopenModalEditTENS(true)}
            >
              Editar TENS del paciente
            </Button> :
            <Button
              disabled
              mr={2}
              variant="contained"
            >
              Editar TENS del paciente
            </Button>
          }

          </Grid>
          <Grid item container xs={12} direction="row" alignContent="flex-start" justify="center" style={{transitionDuration: "1000ms", display: (showPanelTable == "CARGA") ? "block" : "none" }}>
            <CustomCardItem
              xs={12}
              sm={12}
              md={12}
              title={"Carga masiva"}
              content={"Permite cargar masivamente los turnos de TENS."}
              textAction={fileMultiUser ? "Carga masiva turnos": ""}
              action={() => handleMultipleUser()}
              subComponent={<Link href="#" onClick={() => handleDownloadBaseFile()}>
                Descargar archivo base
              </Link>}
              component={ fileMultiUser ? null : <ButtonTextMUI
                variant='contained'
                color='primary'
              >
              <span className="btn-file">
                Adjuntar archivo carga masiva TENS
              <input
                type="file"
                accept="image/xlsx"
                name="archivo"
                onChange={e => setfileMultiUser(e.target.files[0])}
              />
              </span>
              </ButtonTextMUI>}
            />
          </Grid>


        </GridItem>

      </Grid>
      {!multipleSelectMode &&
      <Fab
        className={"fabGridExtend"}
        color="primary"
        style={{zIndex: "9"}}
        onClick={() => setshowPanelTable(showPanelTable == "PANEL" ? "" : "PANEL")}
        >
        {showPanelTable == "PANEL" ? <WorkOffIcon /> :  <WorkIcon />}
      </Fab>
      }
      {!multipleSelectMode &&
      <Fab
        className={"fabGridExtend"}
        color="primary"
        style={{margin: "0px 0px 70px 0", zIndex: "9"}}
        onClick={() => setshowPanelTable(showPanelTable == "CARGA" ? "" : "CARGA")}
        >
        {showPanelTable == "CARGA" ? <PublishIcon /> :  <CloudUploadIcon />}
      </Fab>
      }

      {(openModalEditTENS && listPatient.length > 0 && selectPatient) &&
        <ModalEditPatientTENS
          open={openModalEditTENS}
          handleClose={() => setopenModalEditTENS(false)}
          listPatient={listPatient}
          filterArrayPatient={filterArrayPatient}
          selectPatient={selectPatient}
          arrayProfessional={arrayProfessional}
          handleSelectPatientRotary={handleSelectPatient}
          arrayWorker={arrayWorker}
          handleCheckPatientWorker={handleCheckPatientWorker}
        />
      }
      {(openModalCreateTENS) &&
        <ModalCreateTENS
          open={openModalCreateTENS}
          handleClose={() => setopenModalCreateTENS(false)}
          setarrayWorker={setarrayWorker}
          arrayWorker={arrayWorker}
        />
      }
      {listMonthTensPatient &&
        <ModalShowViewTensPatient
          open={!!listMonthTensPatient}
          handleClose={() => {
            setselecterdMonthTens(null)
            setlistMonthTensPatient(null)
            }
          }
          arrayWorker={listMonthTensPatient}
          selecterdMonthTens={selecterdMonthTens}
          selectDate={selectDate}
        />
      }
       { (scheduleVisit && listPatient.length > 0 ) &&
        <DefineVisit
          open={!!scheduleVisit}
          handleClose={() => setscheduleVisit(null)}
          scheduleVisit={scheduleVisit}
          listType={listType}
          listAbsence={listAbsence}
          dictReservation={dictReservation}
          handleSaveReservation={handleSaveReservation}
          handleSaveAbsence={handleSaveAbsence}
          dictProfessionalStatistics={dictProfessionalStatistics}
          showTabs={showTabs}
        />
      }
      { (editScheduleVisit && listPatient.length > 0 ) &&
        <EditVisit
          open={!!editScheduleVisit}
          handleClose={() => seteditScheduleVisit(null)}
          editScheduleVisit={editScheduleVisit}
          listType={listType}
          listAbsence={listAbsence}
          dictReservation={dictReservation}
          handleEditReservation={handleEditReservation}
          handleEditAbsence={handleEditAbsence}
          handleRemoveReservation={handleRemoveReservation}
          deleteVisita={deleteVisita}
          handleDeleteAbsence={handleDeleteAbsence}
          dictProfessionalStatistics={dictProfessionalStatistics}
          showTabs={showTabs}
          dictAsigtTensWorker={dictAsigtTensWorker}
        />
      }
      { (editWork && listPatient.length > 0 ) &&
        <EditVisitWork
          open={!!editWork}
          handleClose={() => seteditWork(null)}
          editScheduleVisit={editWork}
          selectPatient={selectPatient}
          dictChargeId={dictChargeId}
          dictReservation={dictReservation}
          dictPatientFile={dictPatientFile}
          dictPatientFileWorker={dictPatientFileWorker}
          handleEditWork={handleEditWork}
          handleRemoveReservation={handleRemoveReservation}
          showTabs={showTabs}
        />
      }
      { (scheduleVisitOther && listPatient.length > 0 ) &&
        <DefineVisitWarning
          open={!!scheduleVisitOther}
          handleClose={() => setscheduleVisitOther(null)}
          scheduleVisitOther={scheduleVisitOther}
          handleSelectDateWorker={handleSelectDateWorker}
          dictPatientFile={dictPatientFile}
        />
      }
  </GridContainer>
  );
};

export default Rotary;