import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import PropTypes from "prop-types";

const ShowData = props => {
  const { graphicData, activos } = props;

  return (
    <LineChart
      width={800}
      height={500}
      data={graphicData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      {activos.length === 0 ? null : (
        <YAxis
          yAxisId="left"
          label={{ value: `${activos[0].name}`, angle: -90, position: "left" }}
        />
      )}
      {activos.length === 2 ? (
        <YAxis
          yAxisId="right"
          orientation="right"
          label={{ value: `${activos[1].name}`, angle: -90, position: "right" }}
        />
      ) : null}
      <Tooltip />
      <Legend />
      {activos.length === 0 ? null : (
        <Line
          yAxisId="left"
          type="monotone"
          dataKey={activos[0].name}
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      )}
      {activos.length === 2 ? (
        <Line
          yAxisId="right"
          type="monotone"
          dataKey={activos[1].name}
          stroke="#82ca9d"
        />
      ) : null}
    </LineChart>
  );
};

export default ShowData;

ShowData.propTypes = {
  graphicData: PropTypes.array,
  activos: PropTypes.array
};
