import React, {useState} from 'react'
import { Controller } from 'react-hook-form'

import { TypographyMui } from 'utils/componentsStyle';

import { makeStyles } from '@material-ui/core/styles'
import {CustomSelectAutoComplete} from "utils/componentsStyle";
import { Box, Button, TextField, Tooltip, MenuItem, Select } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import BackupIcon from '@material-ui/icons/Backup';
import examName from '../../../../assets/img/icons/exams/nombre-examen.svg'
import lab from '../../../../assets/img/icons/exams/laboratorio.svg'
import examDate from '../../../../assets/img/icons/exams/fecha-examen.svg'
import document from '../../../../assets/img/icons/exams/ver-documento.svg'

const useStyles = makeStyles({
  microText: {
    fontSize: 10,
    color: '#BDBDBD'
  },
  answerIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '1rem',
  },
  disabledColor: {
    color: '#BDBDBD'
  },

  '@media (max-width: 800px)': {
  },
});

function ExamForm({ typeExamenList, register, errors, control, file, alert, setFile }) {
  const classes = useStyles()
  const [typeExam, settypeExam] = useState({});
  const [labName, setlabName] = useState("");
  const [dateLab, setdateLab] = useState("");
  
  return (
    <>
      <Box display='grid' gridTemplateColumns='10% 30% 1fr' gridGap='0.5rem'>
        <img src={examName} alt="examName" width='35px' height='35px' className='mr-3' />
        <TypographyMui variant='body2' color='primary' className='align-self-center' >Nombre o tipo de examen</TypographyMui>
        <Box>
          <Box display='flex' alignItems='center'>
            <Tooltip title="Si requieres un examen que no aparece en la lista, debes ir a la sección Examen y crearlo." arrow placement="top">
              <HelpOutlineIcon className={classes.answerIcon} fontSize='small' />
            </Tooltip>
            <Controller className='w-100' style={{ height: 40 }}
              as={
                <CustomSelectAutoComplete 
                value={typeExam}
                onChange={(e) => settypeExam(e)}
                label=""
                listItem={typeExamenList}
                />
               
              }
              defaultValue=""
              name='examType'
              rules={{ required: "Campo Obligatorio" }}
              control={control}
            />
          </Box>
          <span>{errors?.examType?.message}</span>
        </Box>
      </Box>

      <Box display='grid' gridTemplateColumns='10% 30% 1fr' gridGap='0.5rem' mt={3.25}>
        <img src={lab} alt="lab" width='35px' height='35px' className='mr-3' />
        <TypographyMui variant='body2' color='primary' className='align-self-center' >Laboratorio donde se realizó</TypographyMui>
        <Box>
          <TextField placeholder='Laboratorio' size="small" variant='outlined' className='w-100' name='originLab'
            onChange={(event) => setlabName(event.target.value)}
            helperText="Campo Obligatorio"
            error={labName === ""}
            inputRef={
              register({
                required: "Campo Obligatorio",
              })
            }
          />
          <span>
            {errors?.originLab?.message}
          </span>
        </Box>
      </Box>

      <Box display='grid' gridTemplateColumns='10% 30% 1fr' gridGap='0.5rem' mt={3.25}>
        <img src={examDate} alt="examDate" width='35px' height='35px' className='mr-3' />
        <TypographyMui variant='body2' color='primary' className='align-self-center' >Fecha de toma del examen</TypographyMui>
        <Box>
          <TextField type='date' size="small" variant='outlined' className='w-100' name='examDate'
              onChange={(event) => setdateLab(event.target.value)}
              helperText="Campo Obligatorio"
              error={dateLab === ""}
              inputRef={
                register({
                  required: "Campo Obligatorio",
                })
              }
            />
          <span>
            {errors?.examDate?.message}
          </span>
        </Box>
      </Box>

      <Box display='grid' gridTemplateColumns='10% 30% 1fr' gridGap='0.5rem' mt={3.25}>
        <img src={document} alt="document" width='35px' height='35px' className='mr-3' />
        <TypographyMui variant='body2' className='align-self-center'  color='primary'>Subir archivo</TypographyMui>
        {file.length ?
          <TypographyMui variant='body2' className={`$'align-self-center'  ${classes.disabledColor}`}>
            {file[0].name} <DeleteOutlineIcon color='disabled' onClick={() => setFile([])} />
          </TypographyMui>
          :
          <Box>
            <Button
              variant="outlined"
              component="label"
              color="primary"
              size='small'
              fullWidth
              type='button'
              style={{ backgroundColor: 'rgba(63, 81, 181, 0.08)' }}
            >
              <BackupIcon className='mr-2' color='primary' /> Buscar archivo
              <TextField
                type="file"
                id="exam"
                onChange={(event) => { setFile(event.target.files) }}
                fullWidth
                my={2}
                style={{ display: "none" }}
                accept={("pdf", "jpg", "jpeg", "png")}
                name='upLoadFile'
                inputRef={
                  register({
                    required: "Campo Obligatorio",
                  })
                }
              />
            </Button>
            <span className={classes.microText}>PDF o JPG</span>
            <span>{errors?.upLoadFile?.message}</span>
          </Box>
        }
      </Box>

      <Box display={alert.success ? 'block' : 'none'} mt={1.5}>
        <Alert severity="success">Examen guardado exitosamente</Alert>
      </Box>
      <Box display={alert.error ? 'block' : 'none'} mt={1.5}>
        <Alert severity="error">Hubo un error. Intentalo nuevamente</Alert>
      </Box>

      <Box display='grid' gridTemplateColumns='10% 30% 1fr' gridGap='0.5rem' mt={4.5}>
        <Box />
        <Box />
        { (typeExam && labName && dateLab && file.length ) ?
        <Button type='submit' variant='outlined' fullWidth size='small' style={{ backgroundColor: 'rgba(76, 175, 80, 0.08)', color: '#4CAF50' }}>
          <CheckCircleOutlineIcon style={{ color: '#4CAF50' }} className='mr-3' />
          Guardar
        </Button> :
        <Button disabled variant='outlined' fullWidth size='small'>
          Guardar
        </Button>
        }
      </Box>
    </>
  )
}

export default ExamForm
