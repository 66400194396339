import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    CardHeader,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import {firstChartUpperString, toMoneyFormat} from "utils/formatFunctions"
import TableComponent from "views/Widgets/Common/TableComponent";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        width: "100%"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    // avatar: {
    //   backgroundColor: red[500],
    // },
}));

const detectType = (value, key) => {
    if (key.toLowerCase().includes("fecha")) {
        return moment(value).format("YYYY-MM-DD")
    } else if (key.toLowerCase().includes("valor")) {
        return toMoneyFormat(value)
    } else if (typeof(value) === "number"){
        return value
    } else if ( typeof(value) === "boolean"){
        return value ? "Verdadero" : "Falseo"
    }
    return firstChartUpperString(value)
}

function CardObject({obj, title}){
    const classes = useStyles();

    const listKey = Object.keys(obj).map((key) => {
        let tempKeyName = key.split("_").join(" ")
        return {"key": key, "label": firstChartUpperString(tempKeyName)}
    })

    return (
    <Grid item container direction="row" justify="center" alignItems="center" xs={12} style={{margin: "10px 0"}}>
        <Card  className={classes.root} >
            <CardHeader title={`${title} - Información`} />
            <CardContent>
            <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={12}>
                {listKey.map((key) => (
                    <Grid item container direction="row" justify="flex-start" alignItems="flex-start"  xs={6}>
                        <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={6}>
                            <Typography component="body2" variant="body2"  style={{fontWeight: 'bold'}}>
                                {`${key["label"]}:`}
                            </Typography>
                        </Grid>
                        <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={6}>
                            <Typography component="body2" variant="body2">
                                {detectType(obj[key["key"]] || "-", key["key"])}
                            </Typography>

                        </Grid>
                    </Grid>
                ))}
            </Grid>
            </CardContent>
        </Card>
    </Grid>
    )
}

function TableList({list, title}){
    const classes = useStyles();

    const headers = Object.keys(list[0]).map((key) => {
        if ( key == "id") return {label: "ID"}
        let tempKeyName = key
        if (key.includes("id_")){
            tempKeyName = key.split("id_").join(" ")
        }
        tempKeyName = tempKeyName.split("_").join(" ")

        return {label: firstChartUpperString(tempKeyName)}
    })

    const data = list.map((row) => {
        const listData = Object.keys(row).map((key) => {
            const tempRow =  {value: row[key] || "-"}
           
            return tempRow
        } )

        return listData
    })


    return (
        <Grid item container direction="row" justify="center" alignItems="center" xs={12}  style={{margin: "10px 0"}}>
            <Card  className={classes.root} >
            <CardHeader title={`${title} - Detalle`} />
            <CardContent>
            <Grid item container direction="row" justify="flex-start" alignItems="flex-start" xs={12}>
            <TableComponent
                headers={headers}
                data={data}
            />
            </Grid>
            </CardContent>
        </Card>

        </Grid>
    )
}



export default function ModalShowRequest({
    open,
    closeModal,
    idRequest,
}) {

    const [listItem, setlistItem] = useState([]);

    useEffect(() => {
        api.get(`api/clinica/pedidosprogramados/productospedido/solicitud/${idRequest}/`).then((response) => {
            let tempItems = response.data.item
            tempItems = tempItems.filter((row) => (row?.objeto?.length > 0 || row?.sin_objeto === true))
            tempItems?.map((row) => {
                row.objeto.map((subrow) => {
                   
                })
            })
            setlistItem(tempItems)
        })
    }, []);



    const _content_ = <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            {listItem.map((row) => (
                <div style={{width: "100%"}}>
                    {row.objeto.map((obj) => (
                        <CardObject obj={obj} title={row.titulo}/>
                    ))
                    }
                    {row.lista_insumo.length > 0 &&
                        <TableList
                            list={row.lista_insumo}
                            title={row.titulo}
                        />
                    }
                </div>
            ))}
        </Grid>

    </div>

    const _actions_ = <>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: indigo[500]}}
            className="text-white"
            onClick={closeModal}
        >
            Cerrar
        </Button>
    </>

    return (
        <>
        <ModalDialog
            open={open}
            onClose={closeModal}
            title={`Resumen Solicitud`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xl"
        />
        </>

    );
}