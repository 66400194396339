import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModalMessage from "./ModalMessage";


export default function ModalCancelRequest(props) {
  const { open, closeModal, id_order, moduleType } = props;
  const [order, setOrder] = useState([]);
  const [modalMessage, setModalMessage] = useState(false);


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    api.get(`api/solicitudpedidos/${id_order}`).then((response) => {
      setOrder(response.data);
    });
  }, []);

  const scroll = "paper";

  function cancelRequest() {

    const formData = new FormData();
    const group = moduleType === "storage";
    formData.append("tipo_solicitud", order.tipo_solicitud);
    formData.append("fecha_solicitud", order.fecha_solicitud);
    formData.append("estado_picking", order.estado_picking);
    formData.append("fecha_despacho", order.fecha_despacho);
    formData.append("nombre_paciente", order.nombre_paciente);
    formData.append("es_region", order.es_region);
    formData.append("tiene_medicamentos", order.tiene_medicamentos);
    formData.append("estado_guia_despacho", order.estado_guia_despacho);
    formData.append("estado_solicitud", "Notificar a egresos");
    formData.append("traslado_clinica", order.traslado_clinica);
    formData.append("motivo_rechazo", order.motivo_rechazo);
    formData.append("id_paciente", order.id_paciente);
    if (group) {
      formData.append("estado_solicitud_insumo", "Notificar a egresos");
    } else {
      formData.append("estado_solicitud_farmacia", "Notificar a egresos");
    }

    apiform
      .put(`api/solicitudpedidos/${id_order}`, formData)
      .then((response) => {
        window.location.href = `/admin/warehouse/orders/${moduleType}`;
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Cancelar envío</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          <p className="text-center">
            El pedido será devuelto al estado "Notificar a egresos" y tendrás
            que enviarlo nuevamente para notificar a egresos.
          </p>
        </div>
        <div>
          <span className="font-weight-bold">
            ¿Estás seguro que deseas cancelar el envío del pedido?
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          No
        </Button>
        <Button onClick={cancelRequest} color="primary">
          Si
        </Button>
      </DialogActions>
      {modalMessage && (
        <ModalMessage
          open={modalMessage}
          closeModal={() => setModalMessage(false)}
        />
      )}
    </Dialog>
  );
}

ModalCancelRequest.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number,
  id_order: PropTypes.number,
  moduleType: PropTypes.string,
};
