import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import {useSelector} from 'react-redux';
import {Sort} from '@material-ui/icons/';
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import {
  Typography,
  Grid,
  Tooltip,
  IconButton
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {indigo, grey} from "@material-ui/core/colors";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 500
  }
});

const TableComponent = props => {
  const [activeFilter, setactiveFilter] = useState(props?.filter !== undefined ? props?.filter : true)
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.pageSize || 100);
  const [oderBy, setOderBy] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [listFilter, setListFilter] = useState([]);
  const [displayItem, setDisplayItem] = useState([]);

  useEffect(() => {
    const tempArray = Array.from(Array(props.headers.filter((row) => !row.hidding ).length).keys()).map(() => {return ""});
    setListFilter(tempArray)
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOrderBy = (newOrderBy, headerEval) => {
    if (headerEval !== false){
      setOderBy(newOrderBy);
      if (newOrderBy === oderBy) {
        setOrderType(orderType === "asc" ? "desc" : "asc");
      } else {
        setOrderType("asc");
      }
      setPage(0);
    }
  };

  const handleOnChange = (value, index) => {
    const tempArray = [...listFilter];
    tempArray[index] = value;
    setPage(0)
    setListFilter(tempArray);
  }

  const globalOpenBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);

  const filterData = (props.data || []).filter((row) =>
    (row.data || row)?.filter((item) => !item.hidding ).every((item, index) =>  {
      const tempValue = (item.value === undefined || item.value === null) ? "" : item.value;
      if (item.eval === false|| listFilter[index] === "" || listFilter[index] === undefined) return true;
      return tempValue.toString().toUpperCase().includes(listFilter[index].toString().toUpperCase())
    })
  ).sort((a, b) => {
    const A = a.data || a;
    const B = b.data || b;
    if (oderBy !== null && A[oderBy]?.value && B[oderBy]?.value) {
      let isnum = /^\d+$/.test(A[oderBy].value.toString().replace('$', '')) && /^\d+$/.test(B[oderBy].value.toString().replace('$', ''));
      const tempA = isnum ? parseInt(A[oderBy].value) : A[oderBy].value;
      const tempB = isnum ? parseInt(B[oderBy].value) : B[oderBy].value;
      if (orderType === "asc") {
        return tempA > tempB ? 1 : -1;
      } else {
        return tempA < tempB ? 1 : -1;
      }
    }  else if (B[oderBy]?.value === undefined && A[oderBy]?.value === undefined) {
      return 0
    }  else if (B[oderBy]?.value === undefined ) {
      return orderType === "asc" ? 1 : -1
    } else if (A[oderBy]?.value === undefined) {
      return orderType === "asc" ? -1 : 1
    }
    return 0
  })

  const handleShowSubData = (displayRow) => {
    if (displayItem.includes(displayRow)){
      setDisplayItem(displayItem.filter(item => item !== displayRow))
    } else {
      setDisplayItem([...displayItem, displayRow])
    }
  }

  const openSubData = (displayRow) => {
    return displayRow ? <IconButton size={"small"} aria-label="close" style={{ background: indigo[500], color: grey[50], width: "40px", height: "40px"}} onClick={() => handleShowSubData(displayRow)}>
        <ArrowDropDownIcon style={{transform: `rotate(${displayItem.includes(displayRow) ? "180" : "0"}deg)`}} />
      </IconButton> : null
  }

  const filterDataHidding = filterData.filter((row) => row.show === undefined ? true : row.show)

  const dataPagination = props.pagination !== false ? filterDataHidding.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filterDataHidding

  const colSpanSubComponent = props.headers.length

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container} style={props.styleTableContainer || {}}>
        <Table stickyHeader aria-label="sticky table" size={props.size || 'medium'}>
          <TableHead>
            {props.headerExtra &&
            <TableRow>
              {props.headerExtra.map(item => {
                  return <TableCell colspan={item.colspan || "1"} style={{...item.stylerow}} align="center">
                    <Grid style={{ display: "flex" }} justify="center">{item.icon}
                      <Typography variant="subtitle2" gutterBottom style={{ textTransform: "none", color: indigo[700] }}>{item.label}</Typography>
                      </Grid>
                    </TableCell>
              })}
            </TableRow>
            }
            <TableRow>
              {props.headers.filter((row) => !row.hidding ).map((header, index) => (
                header.eval !== false  ?
                <Tooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography variant="body2" gutterBottom>{`Ordernar por ${header.label}`}</Typography>
                      </Grid>
                    </React.Fragment>
                  }
                >
                  <TableCell
                    key={`header-${index}`}
                    align={header.aling || "flex-start"}
                    style={{...header.style || {}, cursor: "pointer"}}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      item xs={12}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={10}
                        onClick={() => handleOrderBy(index, header.eval)}
                      >
                        <Typography variant="subtitle2" gutterBottom style={{ textTransform: "none", color: indigo[700] }}>
                          {header.label}
                        </Typography>
                      </Grid>
                      {oderBy === index  &&
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item xs={2}
                        onClick={() => handleOrderBy(index, header.eval)}
                      >
                        <Sort style={{color: indigo[500], transform: `rotate(${orderType === "asc" ? 180 : 0}deg)`, transition: "all 0.2s"}}/>
                      </Grid>
                      }
                    </Grid>
                  </TableCell>
                </Tooltip> :
                <TableCell
                    key={`header-${index}`}
                    align={header.aling || "flex-start"}
                    style={{...header.style || {}}}
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                  >
                    <Typography variant="subtitle2" gutterBottom style={{ textTransform: "none", color: indigo[700] }}>
                      {header.label}
                    </Typography>
                 </Grid></TableCell>
              ))}
            </TableRow>
            <TableRow>
              {props.headers.filter((row) => !row.hidding ).map((header, index) => (
                (header.eval !== false && header.onlySort !== true && activeFilter ) ?
                <Tooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography variant="body2" gutterBottom>{`Filtrar por ${header.label}`}</Typography>
                      </Grid>
                    </React.Fragment>
                  }
                >
                  <TableCell
                    key={`header-${index}`}
                    align={header.aling || "flex-start"}
                    style={{...header.style || {}, cursor: "pointer"}}
                  >
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item xs={12}
                      >
                        <DetailText
                          value={listFilter[index]}
                          onchange={(e) => {handleOnChange(e.target.value, index)}}
                          validation={false}
                          style={{padding: "0"}}
                        />
                      </Grid>
                  </TableCell>
                </Tooltip> :
                <TableCell
                    key={`header-${index}`}
                    align={header.aling || "flex-start"}
                    style={{...header.style || {}}}
                >
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!globalOpenBackdrop ?
            <TableBody>
              {dataPagination.map((row, index) => (
                <>
                <TableRow key={`${index}-row`}>
                  {row.data ? row.data.filter((item) => !item.hidding ).map((item, indexItem) => (
                    <TableCell align={item.aling || "center"} key={`${indexItem}-${index}-cell`}>{openSubData(item.displayRow) || item._value_ || item.value }</TableCell>)) :
                    row.filter((item) => !item.hidding ).map((item, indexItem) => (
                      <TableCell align={item.aling || "center"} key={`${indexItem}-${index}-cell`}>{item._value_ || item.value}</TableCell>))
                  }
                </TableRow>
                {row.subData && row.subData.filter((itemRow) => displayItem.includes(itemRow.displayRow)).map((itemRow, indexitemRow) => (
                  <TableRow key={`${index}-subrow`}>
                    {itemRow.data && itemRow.data.filter((subItemRow) => !subItemRow.hidding ).map((subItemRow, indexsubItemRow) => (
                      <TableCell align={subItemRow.aling || "center"} key={`${index}-${indexsubItemRow}-subcell`}>{subItemRow._value_ || subItemRow.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
                {(row.subComponent && displayItem.includes(row.displayRow)) &&
                  <TableRow key={`${index}-sucomponent`}>
                    <TableCell align="center" colspan={colSpanSubComponent}>
                      {row.subComponent}
                    </TableCell>
                  </TableRow>
                }
                </>
              ))}


            </TableBody> : <TableBody><TableRow >...Recargando</TableRow></TableBody>
            }
        </Table>
      </TableContainer>
      {props.pagination !== false &&
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filterDataHidding.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    }
    </Paper>
  );
};

export default TableComponent;
