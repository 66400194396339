import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
  red,
} from "@material-ui/core/colors";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailNumber2 from "views/Widgets/FichaPaciente/detailNumber2";
import ModalApplyQuotation  from "components/PatientFile/ModalApplyQuotation";
import { Warning, Info } from "@material-ui/icons";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import moment from "moment-timezone";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
import api from "utils/API";
import {
  getListRequestPatient,
} from 'actions/getListAction';
import ModalDialog from "views/Widgets/Common/ModalDialog";
import ModalQuotationPatient from "views/ClinicOrders/OrdersDashboard/ModalQuotationPatient";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function ModalRequestQuotation({
  open,
  closeModal,
  dataFile,
  setviewQuotation,
  settypeRequest,
  selectForecast,
  selectPackage,
  selectpackageUnbalancedPatient,
  dataPatient,
  actualPackage,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [typePatient, settypePatient] = useState({});
  const [typePermanence, settypePermanence] = useState({});
  const [tempListPackage, settempListPackage ] = useState([]);
  const [forecast, setforecast] = useState(selectForecast);
  const [packagePatient, setpackagePatient] = useState(selectPackage);
  const [packagePatientUnbalanced, setpackagePatientUnbalanced] = useState(selectpackageUnbalancedPatient);
  const [priority, setpriority] = useState({value: "Media", label: "Media"});
  const [listAssignedPosition, setlistAssignedPosition] = useState([]);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [days, setdays] = useState(null);
  const [initialCharge, setinitialCharge] = useState(true);
  const [updatePatientPackage, setupdatePatientPackage] = useState(false);
  const [useAncientQuotation, setuseAncientQuotation] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [listQuotationPatient, setlistQuotationPatient] = useState([]);
  const [selectedQuotationPatient, setselectedQuotationPatient] = useState({});
  const [openModalShowQuotation, setopenModalShowQuotation] = useState(false);
  const [quotationPatientInfo, setquotationPatientInfo] = useState([]);

  const listTypePatient = useSelector(state => state.currentList.LISTA_TIPO_PACIENTE);
  const listTypePermanence = useSelector(state => state.currentList.LISTA_TIPO_PERMANENCIA);
  const listPriority = useSelector(state => state.currentList.LISTA_PRIORIDADES_SOLICITUD);
  const listForecast = useSelector(state => state.currentList.listForecast);
  const listPackage = useSelector(state => state.currentList.listPackage);
  const listPackaging = useSelector(state => state.currentList.listPackaging);
  const dictPackage = useSelector(state => state.currentList.dictPackage);

  const validationInit = actualPackage.id && listForecast.length > 0 && listPackage.length > 0 && listPackaging.length > 0 && listTypePatient.length > 0 && listTypePermanence.length > 0 && listPriority.length > 0 && Object.keys(dictPackage).length > 0;
  useEffect(() => {
    if ( initialCharge && validationInit){
      initialData();
    }
  });

  useEffect(() => {
    handleForecastChange();
  }, [forecast])

  useEffect(() => {
    if(startDate != null && endDate != null){
      const tempsDays = moment(endDate).diff(startDate, "days")
      setdays(parseInt(tempsDays+1))
    }
  }, [startDate, endDate])


  const initialData = () => {
    setinitialCharge(false);
    if (actualPackage?.tipo_permanencia){
      settypePermanence({label: actualPackage.tipo_permanencia, value: actualPackage.tipo_permanencia})
    }
    if (actualPackage?.tipo_paciente){
      const tempFilterList = listTypePatient.filter((row) => (row.value === actualPackage.tipo_paciente))
      if (tempFilterList.length > 0){
        settypePatient(tempFilterList[0])
      }
    }
    api.get(`api/clinica/cotizacion/simple/?paciente=${dataPatient.id}`).then((response) => {
      const tempQuotationPatient = response.data.map((row) => {
        return  {item: row, value: row.id, label: `SOLICITUD: ${row.id} - FECHA CREACIÓN: ${row.fecha_creacion} - ESTADO: ${row.estado}`}
      });
      setlistQuotationPatient(tempQuotationPatient);
    })
    const requestCharge = api.get(`api/cargos/usuario/activo/`);
    Promise.all([
      requestCharge,
    ]).then((request) => {
      setlistAssignedPosition(request[0].data.map((row) => {
        return {value: row.id, label: row.nombre};
      }));
    });
  };

  let validation = false;
  const arrayValidation = [
    !!typePatient.value,
    !!typePermanence.value,
    !!forecast.value,
    !!priority.value,
  ];

  if (typePatient !== ""){
    arrayValidation.push(["ParticularServicios", "ParticularHD"].includes(typePatient?.value) ? true : !!packagePatient.value);
  }

  if (useAncientQuotation){
    arrayValidation.push(!!selectedQuotationPatient?.value);
  }
  if (arrayValidation.every((item) => item === true)) {
    validation = true;
  }

  const handleOnChange = (value, set) => {
    set(value);
  };

  const validateAmountMedicalResourse = ["ParticularServicios", "ParticularHD"].includes(typePatient?.value) || typePermanence.value == "Transitorio"

  const getPackageFormat = (tempPackage) => {
    if (tempPackage){
      const tempData = {
        ...tempPackage,
        tipo_paciente: typePatient.value,
        tipo_permanencia: typePermanence.value,
        id_paciente_id: dataPatient.id,
        id_paquete_basado_id: tempPackage?.id || null,
        id_convenio_isapre_id: forecast.value,
        actualizar_paquete: updatePatientPackage,
      };

      delete tempData["id_paquetizacion"];
      delete tempData["fecha_ultima_modificacion"];
      delete tempData["id"];
      delete tempData["estado"];
      delete tempData["id_paciente_id"];
      if (validateAmountMedicalResourse){
        tempData["medicamentos"] = 0
        tempData["insumos"] = 0
      }

      return tempData;
    }
    return {
      codigo_paquete: "",
      complejidad: "",
      descripcion: "",
      dia_cama: 0,
      insumos: 0,
      medicamentos: 0,
      total_afecto: 0,
      total_dia: 0,
      total_excento: 0,
      total_mes: 0,
      zona: "",
      tipo_paciente: typePatient.value,
      tipo_permanencia: typePermanence.value,
      id_paquete_basado_id: tempPackage?.id || null,
      id_convenio_isapre_id: forecast.value,
      actualizar_paquete: updatePatientPackage
    };

  };

  const handleChargeQuotation = (idCharge=null, idUser=null) => {
    const idPositionLocal = parseInt(localStorage.getItem("id_cargo"));
    const idUserLocal = parseInt(localStorage.getItem("user_id"));
    const tempPackage = dictPackage[packagePatient.value]
    const tempPackageUnbalanced = dictPackage[packagePatientUnbalanced?.value]

    const bodyRequest = {
      Tipo_Solicitud_Paciente: "Cotizacion",
      prioridad: priority.value,
      id_cargo_solicitante_id: idPositionLocal,
      id_persona_solicitante_id: idUserLocal,
      id_paciente_id: dataPatient.id,
      id_cargo_responder_id: idCharge ? idCharge : idPositionLocal,
      id_persona_responder_id: idUser ? idUser: idUserLocal,
    };

    if(idCharge, idUser){
      bodyRequest["Tipo_Solicitud_Paciente"] = "SolicitarCotizacion";
    }


    const bodyWork = {
      tipo_tarea: "SolicitudCotizacion",
      fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
      descripcion: "Cotización paciente",
      estado: "Incompleto",
      id_ficha_id: dataFile.id,
      id_cargo_asignado_id: idCharge ? idCharge : idPositionLocal,
      id_usuarios_id: idUser ? idUser: idUserLocal,
    };

    const bodyPackageQuotation = getPackageFormat(tempPackage);
    const bodyPackageQuotationUnbalanced = tempPackageUnbalanced ? getPackageFormat(tempPackageUnbalanced) : null;

    if (endDate){
      bodyPackageQuotation["fin_periodo"] = moment(endDate).format("YYYY-MM-DD")
      if (bodyPackageQuotationUnbalanced){
        bodyPackageQuotationUnbalanced["fin_periodo"] = moment(endDate).format("YYYY-MM-DD")
      }
    }
    if (startDate){
      bodyPackageQuotation["inicio_periodo"] = moment(endDate).format("YYYY-MM-DD")
      if (bodyPackageQuotationUnbalanced){
        bodyPackageQuotationUnbalanced["inicio_periodo"] = moment(endDate).format("YYYY-MM-DD")
      }
    }
    if (days){
      bodyPackageQuotation["dias_periodo"] = days
      if (bodyPackageQuotationUnbalanced){
        bodyPackageQuotationUnbalanced["dias_periodo"] = days
      }
    }

    bodyPackageQuotation

    const bodyQuotation = {
      solicitud: bodyRequest,
      tarea: bodyWork,
      paquete_cotizacion: bodyPackageQuotation,
      paquete_cotizacion_descompensacion: bodyPackageQuotationUnbalanced,
      id_solicitud_clonar: selectedQuotationPatient?.value,
    }

    api.post(`/api/clinica/iniciar/cotizacion/paciente/`, bodyQuotation).then((request) => {
      const tempQuotation = request.data?.solicitud_info;
      setviewQuotation({requestPatientInfo: tempQuotation});
      if (idCharge && idUser){
        getListRequestPatient(dataPatient, dispatch);
      }
      setopenModal(false);
      closeModal();
    })
  };

  const handleForecastChange = () => {

    if (forecast.value){
        const tempPackaging = listPackaging.filter((row) => (forecast.value === row.id_convenio_isapre.id)).reduce((accum, obj) => ([...accum, obj.value]), []);
        const tempPackage = listPackage.filter((row) => row.estado === "Activo" && tempPackaging.includes(row.id_paquetizacion));
        const idPackage = tempPackage.map((row) => {return row.value})
        if (!idPackage.includes(packagePatient?.value)){
          setpackagePatient({});
        }
        if (!idPackage.includes(packagePatientUnbalanced?.value)){
          setpackagePatientUnbalanced({});
        }
        
        
        settempListPackage(tempPackage);
    }
  };

  const handleSelectQuotation = (value) => {
    if (value.value == null){
      return null
    } else {
      const tempItem = value.item.item.filter((row) => !row.descompensacion)[0];
      const tempItemUnbased =  value.item.item.filter((row) => row.descompensacion)[0];
      const temptypePatient = listTypePatient.filter((row) => (row.value === tempItem?.tipo_paciente))[0]
      const temptypepermanence= listTypePermanence.filter((row) => (row.value === tempItem?.tipo_permanencia))[0]
      const tempforecast = listForecast.filter((row) => (row.value === tempItem?.id_convenio_isapre))[0]
      const temppackage = listPackage.filter((row) => (row.value === tempItem?.id_paquete_basado_id))[0]
      const tempPriority = listPriority.filter((row) => (row.value === tempItem?.id_cotizacion_paciente?.id_solicitud_paciente?.prioridad))[0]
      const temppackageUnbased = listPackage.filter((row) => (row.value === tempItemUnbased?.id_paquete_basado_id))[0]

      if (temptypePatient){
        settypePatient(temptypePatient)
      }
      if (temptypepermanence){
        settypePermanence(temptypepermanence)
      }
      if (tempforecast){
        setforecast(tempforecast)
      }
      if (temppackage){
        setpackagePatient(temppackage)
      }
      if (temppackageUnbased){
        setpackagePatientUnbalanced(temppackageUnbased)
      } else {
        setpackagePatientUnbalanced({})
      }
      if (tempPriority){
        setpriority(tempPriority)
      }
      setupdatePatientPackage(false);
      setselectedQuotationPatient(value);
    }
  }

  const handleShowQuotationData = () => {
    const tempItem = selectedQuotationPatient.item
    api.get(`/api/clinica/cotizacion/completa/?solicitud=${tempItem.id}`).then(response => {
        const balanced = response.data.filter((row) => !row.descompensacion)[0]
        const unbalanced = response.data.filter((row) => row.descompensacion)[0]
        setquotationPatientInfo({
          balanced: balanced,
          unbalanced: unbalanced,
        })
        setopenModalShowQuotation(true)
    }).catch(error => {
    });
  }

  const handleDate = (value, set) => {
    set(value);
  };

  const handleSelectOldQuotation = () => {
    setuseAncientQuotation(!useAncientQuotation);
    setselectedQuotationPatient({});
  }

  const _content_ = <>
    <SnackbarContent
      message={"Debes ingresar los siguientes datos para cotizar"}
      color="warning"
      icon={Warning}
    />
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
      <Grid
        xs={12}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <FormControlLabel
          control={
              <Checkbox
                  tabIndex={-1}
                  onClick={handleSelectOldQuotation}
              />
          }
          classes={{
              label: classes.label,
              root: classes.labelRoot
          }}
          label="Usar cotización antigua del paciente"
        />
      </Grid>
      {useAncientQuotation &&
      <Grid
        xs={7}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <DetailSelect
          validation={false}
          label="Cotizaciones antiguas"
          value={selectedQuotationPatient}
          onchange={(value) => handleSelectQuotation(value)}
          list={listQuotationPatient.length > 0 ? listQuotationPatient : [{value: null, label: selectedQuotationPatient === null ? "No se encontraron cotizaciones" : "...Cargando..."}]}
        />
      </Grid>}
      <Grid
        container
        item
        xs={7}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <DetailSelect
          isDisabled={useAncientQuotation}
          label="*Tipo de paciente"
          value={typePatient}
          onchange={(value) => { handleOnChange(value, settypePatient)}}
          list={listTypePatient}
        />
        <DetailSelect
          isDisabled={useAncientQuotation}
          label="*Tipo de permanencia"
          value={typePermanence}
          onchange={(value) => { handleOnChange(value, settypePermanence)}}
          list={listTypePermanence}
        />
        <DetailSelect
          isDisabled={useAncientQuotation}
          label="*Previsión"
          value={forecast}
          onchange={(value) => { handleOnChange(value, setforecast)}}
          list={listForecast}
        />
        <DetailSelect
          isDisabled={useAncientQuotation}
          label={`${(typePatient.value !== "ParticularHD" && typePatient.value !== "ParticularServicios" )? "*" : ""}Paquete (Zona complejidad)`}
          value={packagePatient}
          onchange={(value) => { handleOnChange(value, setpackagePatient)}}
          list={tempListPackage}
          validation={(typePatient.value === "ParticularHD" || typePatient.value === "ParticularServicios")}
        />
        <DetailSelect
          isDisabled={useAncientQuotation}
          label={`Paquete paciente descompensado (Zona complejidad)`}
          value={packagePatientUnbalanced}
          onchange={(value) => { handleOnChange(value, setpackagePatientUnbalanced)}}
          list={[{value: null, label: " "}, ...tempListPackage]}
          validation={(typePatient.value === "ParticularHD" || typePatient.value === "ParticularServicios")}
        />
        <DetailSelect
          isDisabled={useAncientQuotation}
          label={`*Prioridad`}
          value={priority}
          onchange={(value) => { handleOnChange(value, setpriority)}}
          list={listPriority}
        />
      </Grid>
      {typePermanence.label === "Transitorio" &&
      <Grid
        xs={7}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid xs={6} style={{padding: "0 5px"}}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            {endDate ?
            <KeyboardDatePicker
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              label={"Fecha Inicio"}
              placeholder="Fecha Inicio"
              onChange={(e) => handleDate(e, setstartDate)}
              value={startDate ? moment(startDate) : null}
              maxDate={moment(endDate)}
              inputVariant={"outlined"}
            /> :
            <KeyboardDatePicker
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              label={"Fecha Inicio"}
              placeholder="Fecha Inicio"
              onChange={(e) => handleDate(e, setstartDate)}
              value={startDate ? moment(startDate) : null}
              inputVariant={"outlined"}
            />
          }
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid xs={6} style={{padding: "0 5px"}}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            {startDate ?
            <KeyboardDatePicker
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              label={"Fecha fin"}
              placeholder="Fecha fin"
              onChange={(e) => handleDate(e, setendDate)}
              value={endDate ? moment(endDate) : null}
              minDate={moment(startDate)}
              inputVariant={"outlined"}
            /> :
            <KeyboardDatePicker
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              label={"Fecha fin"}
              placeholder="Fecha fin"
              onChange={(e) => handleDate(e, setendDate)}
              value={endDate ? moment(endDate) : null}
              inputVariant={"outlined"}
            />
          }
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid xs={6} style={{padding: "0 5px"}}>
          <DetailNumber2
              isdisabled={startDate !== null && endDate !== null}
              label={"Días tratamiento"}
              min={"0"}
              value={days}
              onchange={e => {
                  if (/^[0-9\b]+$/.test(e.target.value)) {
                    setdays(e.target.value);
                  }
              }}
          />
        </Grid>
      </Grid>
      }
      {(actualPackage?.id && !useAncientQuotation &&
        (
          ["CLICoordUCIPed",  "CLICoordInterPed", "CLICoordAdu", "CLICoordReg", "CLICoordCal", "CLIJefGesCli","CLIEnfGESCAEC", "CliJefEnfCliCentClin", "CLIAnaGes", "CLIAdminGes", "CLISecreCli", "CLICoorAdmGesCli"].includes(localStorage.getItem("tipo_perfil")) ||
          ["Gerencia"].includes(localStorage.getItem("area_perfil"))
        )
      ) &&
      <Grid
        xs={12}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <FormControlLabel
          control={
              <Checkbox
                  tabIndex={-1}
                  onClick={() => setupdatePatientPackage(!updatePatientPackage)}
              />
          }
          classes={{
              label: classes.label,
              root: classes.labelRoot
          }}
          label="Actualizar paquete paciente"
        />
      </Grid>
      }
    </Grid>
    <SnackbarContent
      message={"(*): Indica los elementos obligatorios a ingresar"}
      color="info"
    />
  </>

  const _actions_ = <>
    {useAncientQuotation &&
    <Button
      disabled={!selectedQuotationPatient?.value}
      variant="contained"
      color="primary"
      onClick={handleShowQuotationData}
    >
      Ver datos cotización
    </Button>}
    {validation ?
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleChargeQuotation()}
    >
      Cotizar
    </Button>
    :
    <Button
      variant="contained"
      disabled
    >
      Cotizar
    </Button>
    }
    {validation ?
    <Button
      variant="contained"
      color="primary"
      onClick={() => setopenModal(true)}
    >
      Solicitar
    </Button>
    :
    <Button
      variant="contained"
      disabled
    >
      Solicitar
    </Button>
    }
    <Button
      variant="contained"
      className="text-white"
      style={{backgroundColor: red[500]}}
      onClick={() => settypeRequest(null)}
    >
      Cancelar
    </Button>
  </>

  return (
      <>
        <ModalDialog
          open={open}
          onClose={closeModal}
          title={"Cotización"}
          _content_={_content_}
          _actions_ = {_actions_}
        />
        {openModalShowQuotation &&
          <ModalQuotationPatient
              open={openModalShowQuotation}
              closeModal={() => setopenModalShowQuotation(false)}
              quotationDataPatient={quotationPatientInfo}
          />
        }
        {openModal &&
        <ModalApplyQuotation
          open={openModal}
          handleClose={() => setopenModal(false)}
          listAssignedPosition={listAssignedPosition}
          handleChargeQuotation={handleChargeQuotation}
        />
        }
      </>
  );
}