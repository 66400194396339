import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import allActions from "actions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
}));

export default function ModalApplyQuotation({
  open,
  handleClose,
  listAssignedPosition,
  handleChargeQuotation,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [assignedPosition, setassignedPosition] = useState({});
  const [assignedPeople, setassignedPeople] = useState({});
  const [listPeople, setlistPeople] = useState([]);
  const [validation, setvalidation] = useState(false);
  const [changeButtons, setchangeButtons] = useState(true);

  useEffect(() => {
    validateForm();
    if (changeButtons) {
      setchangeButtons(false);
    }
  });

  const validateForm = () => {
    const arrayValidation = [
      assignedPosition.value !== undefined,
      assignedPosition.value !== undefined,
    ];
    if (arrayValidation.every((item) => item === true)) {
      setvalidation(true);
    } else {
      setvalidation(false);
    }
  };

  const handleOnChange = (value, set) => {
    set(value);
  };

  const handleAssignedPosition = (value) => {
    setassignedPeople({});
    api.get(`/api/usuario/cargo/${value.value}`).then((response) => {
      setlistPeople(
        response.data.map((row) => {
          return {
            value: row.id,
            label: `(${row.rut}) ${row.first_name} ${row.last_name}`,
          };
        })
      );
    });
    setassignedPosition(value);
  };

  const updateValue = () => {
    handleChargeQuotation(assignedPosition.value, assignedPeople.value);
  };

  const _content_ = <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-end"
      item
      xs={12}

    >
      <Grid item xs={10}>
        <DetailSelect
          label="Cargo asignado "
          value={assignedPosition}
          onchange={(value) => {
            handleAssignedPosition(value);
          }}
          list={listAssignedPosition}
        />
      </Grid>
      <Grid item xs={10} style={{height: "380px"}}>
        <DetailSelect
          label="Persona asignada"
          value={assignedPeople}
          onchange={(value) => {
            handleOnChange(value, setassignedPeople);
          }}
          list={listPeople}
        />
      </Grid>
    </Grid>

  const _actions_ = <>
    <Button
    color="primary"
    variant="contained"
      onClick={handleClose}
    >
      Cerrar
    </Button>
    {validation ? (
      <Button
        color="primary"
        variant="contained"
        onClick={updateValue}
      >
        Guardar
      </Button>
    ) : (
      <Button
        disabled
        color="primary"
        variant="contained"
      >
        Guardar
      </Button>
    )}

  </>
  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={`Solicitar cotización`}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}
