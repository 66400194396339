import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SortIcon from "@material-ui/icons/Sort";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableProviders = props => {
  const classes = useStyles();
  const {
    searchProviders,
    requestSort,
    getClassNamesFor,
    openModalEditProvider
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Id 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("id")}
                  className={getClassNamesFor("id")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "250px" }}>
                Nombre Proveedor 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("nombre")}
                  className={getClassNamesFor("nombre")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Dirección 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("direccion")}
                  className={getClassNamesFor("direccion")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Contacto 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("contacto")}
                  className={getClassNamesFor("contacto")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Teléfono 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("telefono")}
                  className={getClassNamesFor("telefono")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "250px" }}>
                Productos que vende 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("producto_especiadlidad")}
                  className={getClassNamesFor("producto_especialidad")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Comentarios 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("comentarios")}
                  className={getClassNamesFor("comentarios")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Evaluación 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("evaluacion")}
                  className={getClassNamesFor("evaluacion")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchProviders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.nombre}</TableCell>
                  <TableCell align="center">{row.direccion}</TableCell>
                  <TableCell align="center">{row.contacto}</TableCell>
                  <TableCell align="center">{row.telefono}</TableCell>
                  <TableCell align="center">
                    {row.producto_especialidad}
                  </TableCell>
                  <TableCell align="center">{row.comentarios}</TableCell>
                  <TableCell align="center">
                    <Box component="fieldset" mb={3} borderColor="transparent">
                      <Rating
                        name="read-only"
                        value={row.evaluacion}
                        readOnly
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      style={{ textTransform: "none" }}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        openModalEditProvider(
                          true,
                          row.id,
                          row.nombre,
                          row.direccion,
                          row.contacto,
                          row.telefono,
                          row.producto_especialidad,
                          row.comentarios,
                          row.evaluaction
                        )
                      }
                    >
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={searchProviders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableProviders;

TableProviders.propTypes = {
  searchProviders: PropTypes.array,
  requestSort: PropTypes.func,
  getClassNamesFor: PropTypes.func,
  openModalEditProvider: PropTypes.func
};
