import React, {useState, useEffect } from 'react';
import {
    Grid,
    Typography
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";

import "moment/locale/es";
moment.locale("es");

export default function ModalQuotationSkip({
    open,
    handleClose,
    handleUpload,
    updatePackaging,
}) {
    const [requestDate, setrequestDate] = useState(moment());

    const updateValue = () => {
        handleUpload(requestDate);
        handleClose();
    };

    const _content_ =  <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                {updatePackaging ?
                    <Typography >
                        Actualizar fecha de envio pedidos
                    </Typography>
                :
                <Typography >
                ¿Está seguro que desea habilitar al paciente?
                </Typography>
                }

            </Grid>
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                        variant="inline"
                        format="DD/MM/YYYY"
                        margin="normal"
                        label={"Inicio de actividades"}
                        placeholder="Inicio de actividades"
                        onChange={(value) => setrequestDate(value)}
                        value={requestDate}
                        inputVariant={"outlined"}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
    </div>

    const _actions_ = <>
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: green[500]}}
            onClick={updateValue}
        >
            Aceptar
        </Button>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500]}}
            className="text-white"
            onClick={handleClose}
        >
            Cancelar
        </Button>
    </>;

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Habilitar directamente`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xs"
        />
    );
}