import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import { makeStyles } from "@material-ui/core/styles";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";
import ModalSaved from "./ModalSaved";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export default function NewCommodity() {
  const [moduleType, setmoduleType] = useState("");
  const [supplies, setSupplies] = useState([]);
  const [group, setGroup] = useState("");
  const [sku, setSKU] = useState("");
  const [id_sku, setId_sku] = useState("");
  let list_sku = [];
  let list_sku_final = [];
  const [description, setDescription] = useState("");
  const [cantidad, setCantidad] = useState(1);

  const [reason, setReason] = useState("");
  const [modalSaved, setModalSaved] = useState(false);


  const listReason = useSelector(state => state.currentList.LISTA_MOTIVO_RETIRO);

  useEffect(() => {
    const tempArrayURL = window.location.href.split('/');
    setmoduleType(tempArrayURL[tempArrayURL.length-1]);
    api.get("api/insumosmedicamento").then(response => {
      setSupplies(response.data);

    });
  }, []);

  useEffect(() => {
    supplies.map(supply => {
      list_sku.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies.map(supply => {
      list_sku_final.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies
      .filter(supply => supply.SKU === sku.value)
      .map(filtered => {
        setGroup(filtered.grupo_producto);
        setDescription(filtered.descripcion_producto);
        setId_sku(filtered.id);
      });
  });

  const handleSKU = sku => {
    setSKU(sku);
  };

  const handleReason = reason => {
    setReason(reason);
  };

  function createRetirement() {

    const formData = new FormData();

    formData.append("cantidad", cantidad);
    formData.append("motivo", reason.value);
    formData.append("estado", "Estado1");
    formData.append("id_producto_retirado", id_sku);

    apiform
      .post("api/solicitudretiro/", formData)
      .then(response => {
        console.log(response);

        setModalSaved(true);
      })

  }

  return (
    <GridContainer>
      <ViewTitle
        title="SOLICITAR RETIRO"
        message="En esta sección podrás solicitar un nuevo retiro de mercadería."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link color="inherit" to={`/admin/warehouse/requests/${moduleType}`}>
              Fraccionamiento - Compras
            </Link>
            <Typography color="textPrimary">Solicitar retiro</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardBody className="mb-3">
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                {/* <Grid item xs={4} align="center" />
                <Grid item xs={4} align="center">
                  <h4 className="font-weight-bold mb-3">SKU a fraccionar</h4>
                </Grid>
                <Grid item xs={4} /> */}
                <DetailSelect
                  label="SKU:"
                  value={sku}
                  onchange={handleSKU}
                  list={list_sku}
                />
                <DetailTextDisabled label="GRUPO:" value={group} />
                <DetailTextDisabled label="DESCRIPCIÓN:" value={description} />
                <div className="col-md-4 mb-3">
                  <label className="text-dark">CANTIDAD</label>
                  <input
                    type="number"
                    min="1"
                    className="form-control bg-light font-weight-lighter"
                    style={{color: "#000000"}}
                    value={cantidad}
                    onChange={e => {
                      if (/^[0-9.\b]+$/.test(e.target.value)) {
                        setCantidad(e.target.value);
                      }
                    }}
                  />
                </div>
                {/* <DetailNumber
                  label="CANTIDAD:"
                  value={cantidad}
                  min="1"
                  onchange={e => {
                    if (/^[0-9.\b]+$/.test(e.target.value)) {
                      setCantidad(e.target.value);
                    }
                  }}
                /> */}
                <DetailSelect
                  label="MOTIVO:"
                  value={reason}
                  onchange={handleReason}
                  list={listReason}
                />
              </Grid>
            </Grid>

            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item xs={8}>
                {sku ? null : (
                  <span className="text-danger">
                    Debes ingresar un sku
                  </span>
                )}
                {reason ? null : (
                  <span className="text-danger">
                    Debes ingresar un motivo
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                {sku && reason ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createRetirement}
                  >
                    Guardar cambios
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Link to={`/admin/warehouse/requests/${moduleType}`}>
                  <Button variant="contained" color="primary">
                    Cancelar Solicitud
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      {modalSaved && (
        <ModalSaved   open={modalSaved} closeModal={() => setModalSaved(false)} moduleType={moduleType} />
      )}
    </GridContainer>
  );
}
