import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SelectReact from "react-select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import api from "utils/API";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const Origen = props => {
  const {
    dataPatient,
    regiones,
    comunas,
    setComunas,
    origin,
    setOrigin,
    originDate,
    setOriginDate,
    originStreet,
    setOriginStreet,
    originNumber,
    setOriginNumber,
    originDepto,
    setOriginDepto,
    originBlock,
    setOriginBlock,
    originCity,
    setOriginCity,
    originComuna,
    setOriginComuna,
    originRegion,
    setOriginRegion,
    //originDescription,
    //setOriginDescription,
    originComments,
    setOriginComments,
    waitPatient,
    setwaitPatient,
    destination,
    setDestination,
    destinationDate,
    setDestinationDate,
    destinationStreet,
    setDestinationStreet,
    destinationNumber,
    setDestinationNumber,
    destinationDepto,
    setDestinationDepto,
    destinationBlock,
    setDestinationBlock,
    destinationCity,
    setDestinationCity,
    destinationComuna,
    setDestinationComuna,
    destinationRegion,
    setDestinationRegion,
    //destinationDescription,
    //setDestinationDescription,
    destinationComments,
    setDestinationComments,
    viewOnly
  } = props;

  const [destinationComunas, setDestinationComunas] = useState([]);
  const classes = useStyles();

  const handleOrigin = event => {
    setOrigin(event.target.value);
    if (event.target.value === "Domicilio") {
      setOriginStreet(dataPatient.direccion_calle);
      setOriginNumber(dataPatient.direccion_numero);
      setOriginDepto(dataPatient.direccion_casa_depto);
      setOriginRegion({
        value: dataPatient.id_region,
        label: dataPatient.direccion_region
      });
      setOriginBlock(dataPatient.direccion_block);
      setOriginComuna({
        value: dataPatient.id_comuna,
        label: dataPatient.direccion_comuna
      });
      setOriginCity(dataPatient.direccion_ciudad);
    } else {
      setOriginStreet("");
      setOriginNumber("");
      setOriginDepto("");
      setOriginRegion("");
      setOriginBlock("");
      setOriginComuna("");
      setOriginCity("");
    }
  };

  const handleDestination = event => {
    setDestination(event.target.value);
    if (event.target.value === "Domicilio") {
      setDestinationStreet(dataPatient.direccion_calle);
      setDestinationNumber(dataPatient.direccion_numero);
      setDestinationDepto(dataPatient.direccion_casa_depto);
      setDestinationRegion({
        value: dataPatient.id_region,
        label: dataPatient.direccion_region
      });
      setDestinationBlock(dataPatient.direccion_block);
      setDestinationComuna({
        value: dataPatient.id_comuna,
        label: dataPatient.direccion_comuna
      });
      setDestinationCity(dataPatient.direccion_ciudad);
    } else {
      setDestinationStreet("");
      setDestinationNumber("");
      setDestinationDepto("");
      setDestinationRegion("");
      setDestinationBlock("");
      setDestinationComuna("");
      setDestinationCity("");
    }
  };

  const handleOriginRegion = region => {
    setOriginRegion(region);
    setOriginComuna("");
    getComuna(region.value);
  };

  const handleOriginComuna = comuna => {
    setOriginComuna(comuna);
  };

  const getComuna = id => {

    const getComuna = api.get(`/api/locaciones/regiones/${id}/comunas/`);
    Promise.all([getComuna])
      .then(response => {
        let tempComunas = [];
        response[0].data.map(row => {
          tempComunas.push({
            value: row.id,
            label: row.nombre
          });
        });
        setComunas(tempComunas);

      })

  };

  const handleDestinationRegion = region => {
    setDestinationRegion(region);
    setDestinationComuna("");
    getDestinationComuna(region.value);
  };

  const handleDestinationComuna = comuna => {
    setDestinationComuna(comuna);
  };

  const getDestinationComuna = id => {

    const getComuna = api.get(`/api/locaciones/regiones/${id}/comunas/`);
    Promise.all([getComuna])
      .then(response => {
        let tempComunas = [];
        response[0].data.map(row => {
          tempComunas.push({
            value: row.id,
            label: row.nombre
          });
        });
        setDestinationComunas(tempComunas);

      })

  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container justify="center" style={{ marginTop: "3em" }}>
        <Grid item container xs={12} justify="center">
          <Grid item container xs={7} justify="center">
            <Grid item xs={10}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                disabled={viewOnly ? true : false}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Origen
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined-1"
                  value={origin}
                  onChange={handleOrigin}
                  label="Origen"
                >
                  <MenuItem value={"Domicilio"}>
                    Domicilio del paciente
                  </MenuItem>
                  <MenuItem value={"Otra"}>Otra</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container xs={6} justify="center">
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  format="DD/MM/YYYY HH:mm"
                  margin="normal"
                  label="Fecha traslado"
                  value={originDate}
                  onChange={setOriginDate}
                  minDate={moment()}
                  minDateMessage={"La fecha del traslado no puede ser anterior al día de hoy"}
                  cancelLabel={"Cancelar"}
                  okLabel={"Aceptar"}
                  minutesStep={30}
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item container xs={10} justify="center">
            <Grid
              item
              container
              xs={12}
              justify="center"
              spacing={1}
              style={{ marginTop: "2em" }}
            >
              <Grid item xs={4}>
                <TextField
                  disabled={viewOnly ? true : false}
                  error={originStreet === "" ? true : false}
                  helperText={originStreet === "" ? "Ingrese calle" : null}
                  fullWidth
                  id="outlined-basic-2"
                  label="Calle"
                  variant="outlined"
                  value={originStreet}
                  onChange={e => setOriginStreet(e.target.value)}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  disabled={viewOnly ? true : false}
                  error={originNumber === "" ? true : false}
                  helperText={originNumber === "" ? "Ingrese número" : null}
                  fullWidth
                  id="outlined-basic-3"
                  label="Número"
                  variant="outlined"
                  value={originNumber}
                  onChange={e => setOriginNumber(e.target.value)}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  disabled={viewOnly ? true : false}
                  fullWidth
                  id="outlined-basic-4"
                  label="Dpto / casa"
                  variant="outlined"
                  value={originDepto}
                  onChange={e => setOriginDepto(e.target.value)}
                />
              </Grid>

              <Grid item xs={4}>
                <SelectReact
                  isDisabled={viewOnly ? true : false}
                  value={originRegion}
                  onChange={handleOriginRegion}
                  options={regiones}
                  placeholder="Seleccione región"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    control: provided => ({ ...provided, minHeight: "56px" })
                  }}
                />
                <FormHelperText>
                  {originRegion === "" ? (
                    <span className="text-danger">Ingrese región</span>
                  ) : null}
                </FormHelperText>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              justify="center"
              spacing={1}
              style={{ marginTop: "2em" }}
            >
              <Grid item xs={4}>
                <TextField
                  disabled={viewOnly ? true : false}
                  fullWidth
                  id="outlined-basic-5"
                  label="Block"
                  variant="outlined"
                  value={originBlock}
                  onChange={e => setOriginBlock(e.target.value)}
                />
              </Grid>

              <Grid item xs={4}>
                <SelectReact
                  isDisabled={viewOnly ? true : false}
                  value={originComuna}
                  onChange={handleOriginComuna}
                  options={comunas}
                  placeholder="Seleccione comuna"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    control: provided => ({ ...provided, minHeight: "56px" })
                  }}
                />
                <FormHelperText>
                  {originComuna === "" ? (
                    <span className="text-danger">Ingrese comuna</span>
                  ) : null}
                </FormHelperText>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  disabled={viewOnly ? true : false}
                  error={originCity === "" ? true : false}
                  helperText={originCity === "" ? "Ingrese ciudad" : null}
                  fullWidth
                  id="outlined-basic-6"
                  label="Ciudad"
                  variant="outlined"
                  value={originCity}
                  onChange={e => setOriginCity(e.target.value)}
                />
              </Grid>
              {/*

              <Grid item xs={12} style={{ marginTop: "2em" }}>
                <TextField
                  disabled={viewOnly ? true : false}
                  fullWidth
                  id="outlined-basic"
                  label="Descripción"
                  variant="outlined"
                  value={originDescription}
                  onChange={e => setOriginDescription(e.target.value)}
                />
              </Grid>
              */}
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            justify="center"
            spacing={1}
            style={{ marginTop: "2em" }}
          >
            <Grid item xs={10}>
              <TextField
                disabled={viewOnly ? true : false}
                fullWidth
                id="outlined-multiline-static-7"
                label="Comentarios"
                multiline
                rows={4}
                variant="outlined"
                value={originComments}
                onChange={e => setOriginComments(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          <hr style={{ borderTop: "5px solid #bbb ", borderRadius: "3px" }} />
        </Grid>

        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{ marginTop: "2em" }}
        >
             <Grid item sm={6} style={{padding: 5}}>
              {/* <Typography variant="caption" gutterBottom>
                  Posecion de Tablet en domicilio del paciente?
              </Typography> */}
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                disabled={viewOnly ? true : false}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  ¿Debe esperar al paciente?
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined-1"
                  value={waitPatient}
                  onChange={(event) => setwaitPatient(event.target.value)}
                  label="Origen"
                >
                  <MenuItem value={true}>Sí</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          <Grid item container xs={7} justify="center">
            <Grid item xs={10}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                disabled={viewOnly ? true : false}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Destino
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined-8"
                  value={destination}
                  onChange={handleDestination}
                  label="Destino"
                >
                  <MenuItem value={"Domicilio"}>
                    Domicilio del paciente
                  </MenuItem>
                  <MenuItem value={"Otra"}>Otra</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container xs={6} justify="center">
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  format="DD/MM/YYYY HH:mm"
                  margin="normal"
                  label="Fecha traslado"
                  value={destinationDate}
                  onChange={setDestinationDate}
                  minDate={moment()}
                  minDateMessage={"La fecha del traslado no puede ser anterior al día de hoy"}
                  cancelLabel={"Cancelar"}
                  okLabel={"Aceptar"}
                  minutesStep={30}
                />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item container xs={10} justify="center">
            <Grid
              item
              container
              xs={12}
              justify="center"
              spacing={1}
              style={{ marginTop: "2em" }}
            >
              <Grid item xs={4}>
                <TextField
                  disabled={viewOnly ? true : false}
                  error={destinationStreet === "" ? true : false}
                  helperText={destinationStreet === "" ? "Ingrese calle" : null}
                  fullWidth
                  id="outlined-basic-11"
                  label="Calle"
                  variant="outlined"
                  value={destinationStreet}
                  onChange={e => setDestinationStreet(e.target.value)}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  disabled={viewOnly ? true : false}
                  error={destinationNumber === "" ? true : false}
                  helperText={
                    destinationNumber === "" ? "Ingrese númbero" : null
                  }
                  fullWidth
                  id="outlined-basic-12"
                  label="Número"
                  variant="outlined"
                  value={destinationNumber}
                  onChange={e => setDestinationNumber(e.target.value)}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  disabled={viewOnly ? true : false}
                  fullWidth
                  id="outlined-basic-13"
                  label="Dpto / casa"
                  variant="outlined"
                  value={destinationDepto}
                  onChange={e => setDestinationDepto(e.target.value)}
                />
              </Grid>

              <Grid item xs={4}>
                <SelectReact
                  isDisabled={viewOnly ? true : false}
                  value={destinationRegion}
                  onChange={handleDestinationRegion}
                  options={regiones}
                  placeholder="Seleccione región"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    control: provided => ({ ...provided, minHeight: "56px" })
                  }}
                />
                <FormHelperText>
                  {destinationRegion === "" ? (
                    <span className="text-danger">Ingrese región</span>
                  ) : null}
                </FormHelperText>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              justify="center"
              spacing={1}
              style={{ marginTop: "2em" }}
            >
              <Grid item xs={4}>
                <TextField
                  disabled={viewOnly ? true : false}
                  fullWidth
                  id="outlined-basic-14"
                  label="Block"
                  variant="outlined"
                  value={destinationBlock}
                  onChange={e => setDestinationBlock(e.target.value)}
                />
              </Grid>

              <Grid item xs={4}>
                <SelectReact
                  isDisabled={viewOnly ? true : false}
                  value={destinationComuna}
                  onChange={handleDestinationComuna}
                  options={destinationComunas}
                  placeholder="Seleccione comuna"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    control: provided => ({ ...provided, minHeight: "56px" })
                  }}
                />
                <FormHelperText>
                  {destinationComuna === "" ? (
                    <span className="text-danger">Ingrese comuna</span>
                  ) : null}
                </FormHelperText>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  disabled={viewOnly ? true : false}
                  error={destinationCity === "" ? true : false}
                  helperText={destinationCity === "" ? "Ingrese ciudad" : null}
                  fullWidth
                  id="outlined-basic-15"
                  label="Ciudad"
                  variant="outlined"
                  value={destinationCity}
                  onChange={e => setDestinationCity(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            justify="center"
            spacing={1}
            style={{ marginTop: "2em" }}
          >
            <Grid item xs={10}>
              <TextField
                disabled={viewOnly ? true : false}
                fullWidth
                id="outlined-multiline-static-16"
                label="Comentarios"
                multiline
                rows={4}
                variant="outlined"
                value={destinationComments}
                onChange={e => setDestinationComments(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default Origen;

Origen.propTypes = {
  dataPatient: PropTypes.object,
  regiones: PropTypes.array,
  comunas: PropTypes.array,
  setComunas: PropTypes.func,
  origin: PropTypes.string,
  setOrigin: PropTypes.func,
  originDate: PropTypes.object,
  setOriginDate: PropTypes.func,

  originStreet: PropTypes.string,
  setOriginStreet: PropTypes.func,
  originNumber: PropTypes.string,
  setOriginNumber: PropTypes.func,
  originDepto: PropTypes.string,
  setOriginDepto: PropTypes.func,
  originBlock: PropTypes.string,
  setOriginBlock: PropTypes.func,
  originCity: PropTypes.string,
  setOriginCity: PropTypes.func,
  originComuna: PropTypes.string,
  setOriginComuna: PropTypes.func,
  originRegion: PropTypes.string,
  setOriginRegion: PropTypes.func,
  waitPatient: PropTypes.string,
  setwaitPatient: PropTypes.func,
  //originDescription: PropTypes.string,
  //setOriginDescription: PropTypes.func,
  originComments: PropTypes.string,
  setOriginComments: PropTypes.func,

  destination: PropTypes.string,
  setDestination: PropTypes.func,
  destinationDate: PropTypes.string,
  setDestinationDate: PropTypes.func,
  destinationStreet: PropTypes.string,
  setDestinationStreet: PropTypes.func,
  destinationNumber: PropTypes.string,
  setDestinationNumber: PropTypes.func,
  destinationDepto: PropTypes.string,
  setDestinationDepto: PropTypes.func,
  destinationBlock: PropTypes.string,
  setDestinationBlock: PropTypes.func,
  destinationCity: PropTypes.string,
  setDestinationCity: PropTypes.func,
  destinationComuna: PropTypes.string,
  setDestinationComuna: PropTypes.func,
  destinationRegion: PropTypes.string,
  setDestinationRegion: PropTypes.func,
  //destinationDescription: PropTypes.string,
  //setDestinationDescription: PropTypes.func,
  destinationComments: PropTypes.string,
  setDestinationComments: PropTypes.func,
  viewOnly: PropTypes.bool
};
