import React from "react";

const DetailText = props => {
  return(
    <div className="col-md-12 mb-3" style={{...props.style ? props.style : {}}}>
      <label className="text-dark">
        {props?.label}
        {(props?.value === "" && props.validation !== false) ? (
          <span className="text-danger"> no puede ser vacío</span>
        ) : (props?.error && props.validation !== false) ? 
          <span className="text-danger"> {props?.error} </span> : null}
      </label>
      <input
        disabled={props.isdisabled ? props.isdisabled : false}
        type="text"
        maxLength={props?.maxlength}
        className={
          (props?.value === "" && props.validation !== false)
            ? "form-control bg-danger font-weight-lighter text-white"
            : "form-control bg-light font-weight-lighter text-dark"
        }
        style={{
          color: (props?.value === "" && props.validation !== false) ?  "#FFFFFF" : "#000000",
          ...props.styleInput ? props.styleInput : {}
        }}
        multiline={props.multiline}
        value={props?.value}
        placeholder={props.placeholder}
        onChange={props?.onchange}
      />
    </div>
  );
}

export default DetailText;