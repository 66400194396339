import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";
import api from "utils/API";
import TurnoExtra from "./TurnoExtra";
import AtencionExtra from "./AtencionExtra";
import Vacaciones from "./Vacaciones";
import PermisoSinSueldo from "./PermisoSinSueldo";
import Inasistencia from "./Inasistencia";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

var momentBusinessDays = require("moment-business-days");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "absolute",
    width: "60%",
    maxHeight: "70%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
  },
}));

const ModalTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      decimalSeparator={false}
      allowNegative={false}
      isNumericString
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ModalViewRecord = (props) => {
  const classes = useStyles();
  const { openModal, closeModal, tempEventInfo } = props;
  const [openAnimation, setOpenAnimation] = useState(false);
  const [changes, setChanges] = useState(true);
  const [startDate, setStarDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [idFichaPersonal, setIdFichaPersonal] = useState("");
  const [idRegistro, setIdRegistro] = useState("");

  const editInfo = false;
  const deleteInfo = false;
  // turno extra
  const [valorTurno, setValorTurno] = useState(0);
  const [valorColacion, setValorColacion] = useState(undefined);
  const [valorMovilizacion, setValorMovilizacion] = useState(undefined);
  const [valorViatico, setValorViatico] = useState(undefined);
  const [checkColacion, setCheckColacion] = useState(false);
  const [checkMovilizacion, setCheckMovilizacion] = useState(false);
  const [checkViatico, setCheckViatico] = useState(false);
  // end turno extra
  // atención extra
  const patients = [];
  const [patient, setPatient] = useState({});
  // end atención extra

  // vacaciones
  const [estadoVacaciones, setEstadoVacaciones] = useState("");
  const [ultimoDiaTrabajado, setUltimoDiaTrabajado] = useState(null);
  const [primerDiaTrabajado, setPrimerDiaTrabajado] = useState(null);
  const [totalDias, setTotalDias] = useState("");
  // end vacaciones
  // inasistencia
  const [licencia, setLicencia] = useState("");
  const [amonestacion, setAmonestacion] = useState("");
  const [descuento, setDescuento] = useState("");
  // end inasistencia

  const handleLicencia = (event) => {
    setLicencia(event.target.value);
  };

  const handleAmonestacion = (event) => {
    setAmonestacion(event.target.value);
  };

  const handleDescuento = (event) => {
    setDescuento(event.target.value);
  };

  const handleUltimoDiaTrabajado = (date) => {
    setUltimoDiaTrabajado(date);
  };

  const handlePrimerDiaTrabajado = (date) => {
    setPrimerDiaTrabajado(date);
  };

  const handleEstadoVacaciones = (event) => {
    setEstadoVacaciones(event.target.value);
  };

  const handlePatient = (patient) => {
    setPatient(patient);
  };

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);
    if (tempEventInfo.title === "Vacación") {
      const requestVacations = api.get(
        `api/rrhh/variablesdinamicas/registros/vacaciones/${tempEventInfo.id}/`
      );

      Promise.all([requestVacations])
        .then((response) => {
          setStarDate(moment(response[0].data.fecha_hora_inicio));
          setEndDate(moment(response[0].data.fecha_hora_fin).add(1, "hours"));
          setIdFichaPersonal(response[0].data.id_ficha_personal);
          setIdRegistro(response[0].data.id);
          response[0].data.valores.map((value) => {
            setVariableValueVacaciones(value.codigo_tipo_valor, value.valor);
          });
        })

    }
    if (tempEventInfo.title === "Permiso sin sueldo") {
      const requestPermission = api.get(
        `api/rrhh/variablesdinamicas/registros/permisosinsueldo/${tempEventInfo.id}/`
      );

      Promise.all([requestPermission])
        .then((response) => {
          setStarDate(moment(response[0].data.fecha_hora_inicio));
          setEndDate(moment(response[0].data.fecha_hora_fin).add(1, "hours"));
          setIdFichaPersonal(response[0].data.id_ficha_personal);
          setIdRegistro(response[0].data.id);
          response[0].data.valores.map((value) => {
            setVariableValueVacaciones(value.codigo_tipo_valor, value.valor);
          });
        })

    }
    if (tempEventInfo.title === "Inasistencia") {
      const requestAbsence = api.get(
        `api/rrhh/variablesdinamicas/registros/inasistencias/${tempEventInfo.id}`
      );

      Promise.all([requestAbsence])
        .then((response) => {
          setStarDate(moment(response[0].data.fecha_hora_inicio));
          setEndDate(moment(response[0].data.fecha_hora_fin).add(1, "hours"));
          setIdFichaPersonal(response[0].data.id_ficha_personal);
          setIdRegistro(response[0].data.id);
          response[0].data.valores.map((value) => {
            setVariableValueInasistencia(value.codigo_tipo_valor, value.valor);
          });
        })
    }
  };

  const setVariableValueVacaciones = (name, value) => {
    setChanges(false);
    switch (name) {
      case "UltimoDiaTrabajado":
        setUltimoDiaTrabajado(moment(value));
        break;
      case "PrimerDiaTrabajar":
        setPrimerDiaTrabajado(moment(value));
        break;
      case "DiasPedidos":
        setTotalDias(value);
        break;
      case "EstadoSolicitud":
        setEstadoVacaciones(value);
        break;
      default:
        break;
    }
  };

  const setVariableValueInasistencia = (name, value) => {
    setChanges(false);
    switch (name) {
      case "UltimoDiaTrabajado":
        setUltimoDiaTrabajado(moment(value));
        break;
      case "PrimerDiaTrabajar":
        setPrimerDiaTrabajado(moment(value));
        break;
      case "Licencia":
        setLicencia(value);
        break;
      case "Amonestacion":
        setAmonestacion(value);
        break;
      case "Descuento":
        setDescuento(value);
    }
  };

  const handleCheckColacion = (event) => {
    setCheckColacion(event.target.checked);
  };

  const handleCheckMovilizacion = (event) => {
    setCheckMovilizacion(event.target.checked);
  };

  const handleCheckViatico = (event) => {
    setCheckViatico(event.target.checked);
  };

  const handleStartDate = (date) => {
    setStarDate(date);
    setTotalDias(
      momentBusinessDays(moment(endDate).add(2, "seconds")).businessDiff(
        moment(date).add(1, "seconds")
      )
    );
  };

  const handleEndDate = (date) => {
    setEndDate(moment(date).add(2, "seconds"));
    setTotalDias(
      momentBusinessDays(moment(date).add(2, "seconds")).businessDiff(
        moment(startDate).add(1, "seconds")
      )
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        {openAnimation ? (
          <ModalTitle id="customized-dialog-title">
            Tipo de registro: {tempEventInfo.title}
          </ModalTitle>
        ) : (
          <ModalTitle id="customized-dialog-title" onClose={closeModal}>
            Tipo de registro: {tempEventInfo.title}
          </ModalTitle>
        )}
        <DialogContent>
          <Grid container className={classes.root} justify="center">
            <Grid item container xs={12} justify="center">
              {tempEventInfo.title === "Turno extra" ? (
                <TurnoExtra
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  valorTurno={valorTurno}
                  setValorTurno={setValorTurno}
                  valorColacion={valorColacion}
                  setValorColacion={setValorColacion}
                  valorMovilizacion={valorMovilizacion}
                  setValorMovilizacion={setValorMovilizacion}
                  valorViatico={valorViatico}
                  setValorViatico={setValorViatico}
                  NumberFormatCustom={NumberFormatCustom}
                  checkColacion={checkColacion}
                  handleCheckColacion={handleCheckColacion}
                  checkMovilizacion={checkMovilizacion}
                  handleCheckMovilizacion={handleCheckMovilizacion}
                  checkViatico={checkViatico}
                  handleCheckViatico={handleCheckViatico}
                  editInfo={editInfo}
                  tempEventInfo={tempEventInfo}
                />
              ) : tempEventInfo.title === "Atención extra" ? (
                <AtencionExtra
                  startDate={startDate}
                  setStartDate={setStarDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  valorTurno={valorTurno}
                  setValorTurno={setValorTurno}
                  valorColacion={valorColacion}
                  setValorColacion={setValorColacion}
                  valorMovilizacion={valorMovilizacion}
                  setValorMovilizacion={setValorMovilizacion}
                  valorViatico={valorViatico}
                  setValorViatico={setValorViatico}
                  NumberFormatCustom={NumberFormatCustom}
                  checkColacion={checkColacion}
                  handleCheckColacion={handleCheckColacion}
                  checkMovilizacion={checkMovilizacion}
                  handleCheckMovilizacion={handleCheckMovilizacion}
                  checkViatico={checkViatico}
                  handleCheckViatico={handleCheckViatico}
                  editInfo={editInfo}
                  tempEventInfo={tempEventInfo}
                  patient={patient}
                  patients={patients}
                  handlePatient={handlePatient}
                />
              ) : tempEventInfo.title === "Vacación" ? (
                <Vacaciones
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  ultimoDiaTrabajado={ultimoDiaTrabajado}
                  handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
                  primerDiaTrabajado={primerDiaTrabajado}
                  handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
                  totalDias={totalDias}
                  estadoVacaciones={estadoVacaciones}
                  handleEstadoVacaciones={handleEstadoVacaciones}
                  editInfo={editInfo}
                />
              ) : tempEventInfo.title === "Permiso sin sueldo" ? (
                <PermisoSinSueldo
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  ultimoDiaTrabajado={ultimoDiaTrabajado}
                  handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
                  primerDiaTrabajado={primerDiaTrabajado}
                  handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
                  totalDias={totalDias}
                  estadoVacaciones={estadoVacaciones}
                  handleEstadoVacaciones={handleEstadoVacaciones}
                  editInfo={editInfo}
                />
              ) : tempEventInfo.title === "Inasistencia" ? (
                <Inasistencia
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  ultimoDiaTrabajado={ultimoDiaTrabajado}
                  handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
                  primerDiaTrabajado={primerDiaTrabajado}
                  handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
                  totalDias={totalDias}
                  estadoVacaciones={estadoVacaciones}
                  handleEstadoVacaciones={handleEstadoVacaciones}
                  editInfo={editInfo}
                  licencia={licencia}
                  handleLicencia={handleLicencia}
                  amonestacion={amonestacion}
                  handleAmonestacion={handleAmonestacion}
                  descuento={descuento}
                  handleDescuento={handleDescuento}
                />
              ) : null}
            </Grid>
            <Grid container justify="center">
              {deleteInfo ? (
                <Typography
                  variant="h6"
                  style={{ textTransform: "none", marginTop: "2em" }}
                >
                  ¿Está seguro que desea eliminar este registro?
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {openAnimation ? <CircularProgress /> : null}
        </DialogActions>
      </Paper>
    </Modal>
  );
};

export default ModalViewRecord;

ModalViewRecord.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.string,
  tempEventInfo: PropTypes.object,
  setOpenDialogSucess: PropTypes.func,
  setMessage: PropTypes.func,
  setPageRefresh: PropTypes.func,
  variableCounter: PropTypes.object,
  setVariableCounter: PropTypes.func,
  eventsVariables: PropTypes.array,
  setEvents: PropTypes.func,
};
