import Covenants from "views/Covenants/Covenants";
import Benefits from "views/Benefits/Benefits";
import Contracts from "views/Contracts/Contracts";
import CovenantsDetail from "views/Covenants/CovenantsDetail";
import Documents from "views/Covenants/Documents/Documents";
import Packages from "views/Covenants/Packages/Packages";
import PatientFileMenu from "views/PatientFile/PatientFileMenu";
import ClinicalRequest from "views/ClinicalRequests/ClinicalRequest";
import Assignments from "views/Assignments/Assignments";
import Billing from "views/Billing/Billing";
import ClinicEndowments from "views/Clinic/Endowments/ClinicEndownments";
import DetailPage from "views/Clinic/Endowments/Detail/DetailPage";
import Requests from "views/Clinic/Requests/Requests";
import OrdersDashboard from "views/ClinicOrders/OrdersDashboard/OrdersDashboard";
import Attendance from "views/Attendance/Attendance";

export const clinicEndowments = {
  path: "/clinicendowments/",
  name: "Mi dotación",
  component: ClinicEndowments,
  layout: "/admin",
  mini: "."
};

export const clinicOrders = {
  path: "/clinicorders/",
  name: "Pedidos programados",
  component: OrdersDashboard,
  layout: "/admin",
  mini: "."
};

export const clinicRequests = {
  path: "/clinicrequests/",
  name: "Solicitudes",
  component: Requests,
  layout: "/admin",
  mini: "."
};

export const clinicEndowmentsDetail = {
  path: "/clinicendowmentsdetail/:id",
  name: "Mi dotación",
  component: DetailPage,
  layout: "/admin",
  mini: ".",
  invisible: true
};

export const covenants = {
  path: "/convenants/",
  name: "Convenios",
  mini: ".",
  component: Covenants,
  layout: "/admin"
};

export const benefits = {
  path: "/benefits/",
  name: "Prestaciones",
  mini: ".",
  component: Benefits,
  layout: "/admin"
};

export const patientFile = {
  path: "/patient/",
  name: "Ficha clinica",
  component: PatientFileMenu,
  layout: "/admin",
  mini: "."
};

export const patientFileDetails = {
  path: "/patientDetail/:id",
  name: "Ficha clinica",
  component: PatientFileMenu,
  layout: "/admin",
  mini: ".",
  invisible: true
};

export const contracts = {
  path: "/contracts/",
  name: "Contratos",
  mini: ".",
  component: Contracts,
  layout: "/admin"
};

export const billing = {
  path: "/billing/",
  name: "Facturación",
  mini: ".",
  component: Billing,
  layout: "/admin"
};

export const billing_tasks = {
  path: "/facturacion/tareas/",
  name: "Mis tareas",
  component: ClinicalRequest,
  layout: "/admin",
  mini: "."
};

export const clinicRequest = {
  path: "/clinicRequest/",
  name: "Mis tareas",
  component: ClinicalRequest,
  layout: "/admin",
  mini: "."
};

export const assignments = {
  path: "/Assignments/",
  name: "Ruta",
  component: Assignments,
  layout: "/admin",
  mini: "."
};

export const covenantsdetail = {
  path: "/covenants/:id",
  component: CovenantsDetail,
  layout: "/admin",
  invisible: true
};

export const documents = {
  path: "/documents/:id",
  component: Documents,
  layout: "/admin",
  invisible: true
};

export const packages = {
  path: "/packages/:id",
  component: Packages,
  layout: "/admin",
  invisible: true
};
