import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Box } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  redButton: {
    backgroundColor: 'rgba(244, 67, 54, 0.08)',
    color: '#E31B0C',
    marginRight: 61,
    border: '1px solid #E31B0C'
  },
  greenButton: {
    backgroundColor: 'rgba(76, 175, 80, 0.08)',
    color: '#4CAF50',
    border: '1px solid #4CAF50'
  },
}));

const ActionButtons = ({
  actionButtonText = 'Guardar',
  actionButtonType = 'button',
  justifyActionButtons = 'space-between',
  disabledAction,
  cancelAction,
  actionButtonMethod
}) => {
  const classes = useStyles()
  return (
    <Box display='flex' justifyContent={justifyActionButtons} mt={2} width='100%'>
      <Button variant='outlined' onClick={cancelAction} className={classes.redButton}>
        <CheckCircleOutlineIcon style={{ color: '#E31B0C' }} className='mr-3' />
        Cancelar
      </Button>
      <Button onClick={actionButtonMethod} variant='outlined' className={classes.greenButton} disabled={disabledAction} actionButtonType={actionButtonType}>
        <CheckCircleOutlineIcon style={{ color: '#4CAF50' }} className='mr-3' />
        {actionButtonText}
      </Button>
    </Box>
  )
};

export default ActionButtons
