import React, { useEffect, useState, useRef } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { useReactToPrint } from "react-to-print";
import { indigo } from "@material-ui/core/colors";
import Button from "components/CustomButtons/Button.js";
import { CloudDownload } from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import api from "utils/API";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import TableComponent from "views/Widgets/Common/TableComponent";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

const useStylesTable = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

// eslint-disable-next-line react/prop-types
export default function DocumentTable({
  files,
  dataPatient,
}) {
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [initialFilter, setinitialFilter] = useState(true);

  const componentRef = useRef();

  const dictTypeFile = useSelector(state => state.currentList.DICCIONARIO_TIPO_ARCHIVO_PACIENTE);


  const headerDataDocument = [
    {label: "Documento"},
    {label: "Tipo"},
    {label: "Fecha"},
    {eval: false, label: "Acciones"},
  ];

  useEffect(() => {
    if (initialFilter){
      updateList();
    }
  });

  const updateList = () => {
    setinitialFilter(false);
    const tempType = [];
      files.map((row) => {
      tempType.push(row.tipo_documento);
    });
    const auxType = Array.from(new Set(tempType)).map((row) => {
      return {value: dictTypeFile[row]?.label || row, label: dictTypeFile[row]?.label || row};
    });
    auxType.push({value: null, label: "Sin Filtro"});
  };

  const handleDownload = (file) => {
    window.open(file);
  };

  const handleGenFile = (row) => {
    api.get(`api/paciente/solicitud/${row.id}/cotizacion/descargar/`, { responseType: "blob" }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // Create a link element and click it to trigger download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = row.nombre_archivo;
      link.click();
    })
  };

  const handleGenFileOrderMedic = (row) => {
    api.get(`api/paciente/${dataPatient.id}/solicitudes/${row.id}/orden/medica/`).then((response) => {
      handlePrint();
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const dataDocumentFilter = files.map((row) => {
    const _actionButton_ = row.archivo ? (
      <Button
        justIcon
        round
        size="sm"
        style={{ background: indigo[500] }}
        onClick={() => {
          handleDownload(row.archivo);
        }}
      >
        <CloudDownload className={classes.icons} />
      </Button>
    ) : row.tipo_documento === "OrdenMedica" ?
    (
      <Button
        justIcon
        round
        size="sm"
        style={{ background: indigo[500] }}
        onClick={() => {
          handleGenFileOrderMedic(row);
        }}
      >
        <CloudDownload className={classes.icons} />
      </Button>
    ) :
    (
      <Button
        justIcon
        round
        size="sm"
        style={{ background: indigo[500] }}
        onClick={() => {
          handleGenFile(row);
        }}
      >
        <CloudDownload className={classes.icons} />
      </Button>
    )

    //eval is not use in table only condition to filter
    return [
      {eval:true, value: row.nombre_archivo},
      {eval:true, value: row.archivo ? dictTypeFile[row.tipo_documento]?.label ||  row.tipo_documento: row.tipo_documento},
      {eval:true, value: row.fecha},
      {eval:true, _value_: _actionButton_},
    ]

  });


  return (
    <Paper className={classesTable.root}>
      <TableComponent
        headers={headerDataDocument}
        data={dataDocumentFilter}
      />
    </Paper>
  );
}
