import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


export default function ModalCancelReplace(props) {
  const { open, closeModal, id_producto, id_detalle } = props;
  const [pedido, setPedido] = useState("");
  const [supplies, setSupplies] = useState([]);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {

    api.get(`api/productospedidos/${id_producto}`).then(response => {
      setPedido(response.data);
    });
    api.get("api/insumosmedicamento/").then(result => {
      setSupplies(result.data);

    });
  }, []);

  const scroll = "paper";

  const setSKU = (id_supply, supplies) => {
    const response = supplies
      .filter(supply => supply.id === id_supply)
      .map(filtered => {
        return filtered.SKU;
      });
    return response;
  };

  const setGrupo = (id_supply, supplies) => {
    const response = supplies
      .filter(supply => supply.id === id_supply)
      .map(filtered => {
        return filtered.grupo_producto;
      });
    return response;
  };

  const setDescripcion = (id_supply, supplies) => {
    const response = supplies
      .filter(supply => supply.id === id_supply)
      .map(filtered => {
        return filtered.descripcion_producto;
      });
    return response;
  };

  function updateProduct() {

    const formData = new FormData();

    formData.append("observaciones", pedido.observaciones);
    formData.append("estado", pedido.estado);
    formData.append("cantidad", pedido.cantidad);
    formData.append("tipo_cantidad", pedido.tipo_cantidad);
    formData.append("picking", pedido.picking);
    formData.append("reemplazado", false);
    formData.append("id_insumo_medicamento", pedido.id_insumo_medicamento);
    formData.append("id_solicitud_pedido", pedido.id_solicitud_pedido);
    formData.append("estado_reemplazo", "No solicitado");
    formData.append("cantidad_por_enviar", pedido.cantidad_por_enviar);
    formData.append("id_medicamento_reemplazo", "");

    apiform
      .put(`api/productospedidos/${id_producto}`, formData)
      .then(response => {
        console.log(response);
        window.location.href = `/admin/warehouse/order/${id_detalle}`;
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Descripción solicitud</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "10rem" }}>
          La solicitud de reemplazo fue enviada: <br />
          <div className="mt-3 mb-3">
            {open_backgrop ? null : (
              <ul className="list-group">
                <li className="list-group-item">
                  <span className="font-weight-bold">SKU: </span>
                  {setSKU(pedido.id_insumo_medicamento, supplies)}
                </li>
                <li className="list-group-item">
                  <span className="font-weight-bold">Grupo: </span>
                  {setGrupo(pedido.id_insumo_medicamento, supplies)}
                </li>
                <li className="list-group-item">
                  <span className="font-weight-bold">Descripción: </span>
                  {setDescripcion(pedido.id_insumo_medicamento, supplies)}
                </li>
              </ul>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Ok
        </Button>
        <Button onClick={updateProduct} color="primary">
          Cancelar solicitud
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCancelReplace.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number
};
