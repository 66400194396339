import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import api from "utils/API";
import ModalCreateExam from "./Modals/ModalCreateExam";
import ModalRequestExam from "./Modals/ModalRequestExam";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: "5em"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    color: "#fff"
  }
}));

const Examenes = props => {
  const classes = useStyles();
  const {
    setTypeRequest,
    dataPatient,
    clinic,
  } = props;

  const [changes, setChanges] = useState(true);
  const [examsList, setExamsList] = useState([]);
  const [examSelected, setExamSelected] = useState("");
  const [selectedListExam, setselectedListExam] = useState([]);
  const [tempExam, setTempExam] = useState("");
  const [dataMedicalArray, setDataMedicalArray] = useState([]);
  const [dataResourceArray, setDataResourceArray] = useState([]);
  const [listEquip, setListEquip] = useState([]);
  const [equipData, setEquipData] = useState({});
  const [indications, setIndications] = useState("");
  const [examName, setExamName] = useState("");
  const [examCode, setExamCode] = useState("");
  const [responsable, setResponsable] = useState(true);
  const [openModalCreateExam, setOpenModalCreateExam] = useState(false);
  const [openModalRequestExam, setOpenModalRequestExam] = useState(false);


  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);
    const getExams = api.get(`/api/clinica/examenes/?paciente=${dataPatient.id}`);
    const getEquip = api.get(`api/equipos/tipos/`);
    Promise.allSettled ([getExams, getEquip])
      .then(response => {
        let tempExamList = [];
        const auxEquipList = [];
        const auxEquipData = {}
        response[0].value.data.map(row => {
          tempExamList.push({
            value: row.id,
            label: `${row.codigo_examen === null ? "" : row.codigo_examen} ${
              row.nombre
            }`
          });
        });
        response[1].value.data.map(row => {
          auxEquipList.push({
            value: row.id,
            label: `(${row.SKU}) ${row.descripcion}`
          });
          auxEquipData[row.id] = row;
        });

        setExamsList(tempExamList);
        setListEquip(auxEquipList);
        setEquipData(auxEquipData);
        //setExamsList(response[0].data);

      })
      .catch(error => console.log("errror", error.response));
  };



  const handleExamSelected = event => {
    const tempList = [...selectedListExam]
    setExamSelected("");
    // getExamDetail(event.value);
    setTempExam(true);
    tempList.push(event)
    setselectedListExam(tempList)
    //setTempExam(examsList.filter(exam => exam.name === event.target.value)[0]);
  };

  const handleExamDeleted = (exam) => {
    const tempList = selectedListExam.filter((row) => row.value != exam)
    setselectedListExam(tempList)
  }

  const handleCloseSelectExam = () => {
    setExamSelected("");
    setTempExam("");
    setDataMedicalArray([]);
    setDataResourceArray([]);
    setEquipData([]);
    setIndications("");
    setExamName("");
    setExamCode("");
    setResponsable(true);
    setselectedListExam([]);
    setOpenModalRequestExam(false);
  };

  const handleCloseCreateExam = () => {
    setExamSelected("");
    setTempExam("");
    setDataMedicalArray([]);
    setDataResourceArray([]);
    setEquipData([]);
    setIndications("");
    setExamName("");
    setExamCode("");
    setResponsable(true);
    setselectedListExam([])
    setOpenModalCreateExam(false);
  };

  return (
    <Grid container className={classes.root} justify="center">
      <Grid item container xs={12} justify="center">
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "none", marginRight: "4px" }}
          onClick={() => {
            setOpenModalCreateExam(true);
          }}
        >
          Crear nuevo tipo de examen
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "none", marginLeft: "4px" }}
          onClick={() => {
            setOpenModalRequestExam(true);
          }}
        >
          Solicitar examen
        </Button>
      </Grid>
      {openModalCreateExam && (
        <ModalCreateExam
          openModal={openModalCreateExam}
          closeModal={handleCloseCreateExam}
          listEquip={listEquip}
          equipData={equipData}
          examsList={examsList}
          setExamsList={setExamsList}
          dataMedicalArray={dataMedicalArray}
          setDataMedicalArray={setDataMedicalArray}
          dataResourceArray={dataResourceArray}
          setDataResourceArray={setDataResourceArray}
          indications={indications}
          setIndications={setIndications}
          examName={examName}
          setExamName={setExamName}
          examCode={examCode}
          setExamCode={setExamCode}
          responsable={responsable}
          setResponsable={setResponsable}

        />
      )}
      {openModalRequestExam && (
        <ModalRequestExam
          openModal={openModalRequestExam}
          closeModal={handleCloseSelectExam}
          examSelected={examSelected}
          selectedListExam={selectedListExam}
          handleExamSelected={handleExamSelected}
          handleExamDeleted={handleExamDeleted}
          examsList={examsList}
          tempExam={tempExam}
          indications={indications}
          setIndications={setIndications}
          responsable={responsable}
          setResponsable={setResponsable}
          listEquip={listEquip}
          equipData={equipData}
          setExamsList={setExamsList}
          setTempExam={setTempExam}
          setTypeRequest={setTypeRequest}
          dataPatient={dataPatient}
          dataMedicalArray={dataMedicalArray}
          setDataMedicalArray={setDataMedicalArray}
          dataResourceArray={dataResourceArray}
          setDataResourceArray={setDataResourceArray}
          clinic={clinic}

        />
      )}

    </Grid>
  );
};

export default Examenes;
