import React, {useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { DriveEta, AccountCircle } from "@material-ui/icons/";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import { red, green, orange, indigo } from "@material-ui/core/colors";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");



export default function ModalAllocateResources({
    open,
    handleClose,
    array,
    setArray,
    setchangeButtons,
    vehicleArray,
    driverArray,
    rowChecked,
    setrowChecked,
    driverDict,
    vehicleDict,
    patientDict,
}) {

    const [flag, setflag] = useState(1);
    const [tempArray, settempArray] = useState(array);
    const [tempVehicleArray, settempVehicleArray] = useState([]);
    const [tempdriverArray, settempdriverArray] = useState([]);
    const [selectVehicle, setselectVehicle] = useState(null);
    const [selectDriver, setselectDriver] = useState(null);
    const [selectCoDriver, setselectCoDriver] = useState(null);
    const [packageVehicle, setpackageVehicle] = useState({});
    const [packageDriver, setpackageDriver] = useState({});
    const [updateRow, setupdateRow] = useState(0);
    const [initialCharge, setinitialCharge] = useState(true);
    const [dictVehicleDriver, setdictVehicleDriver] = useState({});
    const [dictVehicleCoDriver, setdictVehicleCoDriver] = useState({});
    const [dictDriverVehicle, setdictDriverVehicle] = useState({});
    const [dictCoDriverVehicle, setdictCoDriverVehicle] = useState({});
    const [loading, setloading] = useState(true);

    const boxSuccesSelect = {background: `linear-gradient(60deg, ${green[600]}, ${green[900]} )`};
    const boxdangerSelect = {background: `linear-gradient(60deg, ${red[500]}, ${red[900]} )`};

    useEffect(() => {
        if (initialCharge){
          initialData();
        }
    });

    const initialData = () => {
        setinitialCharge(false);
        let tempCheck = 0;
        const tempPackageDriver = [];
        const tempPackageVehicle = [];
        const tempDictVehicleDriver = {};
        const tempDictVehicleCoDriver = {};
        const tempDictDriverVehicle = {};
        const tempDictCoDriverVehicle = {};
        tempArray.map((row) => {
            if (row.estado_recepcion  !== "Fallido" && row.estado_recepcion  !== "Entregado" && row.estado_recepcion  !== "Retrasado"){
                if (row.id_vehiculo){
                    if (tempPackageVehicle[row.id_vehiculo]){
                        tempPackageVehicle[row.id_vehiculo].push(row);
                    } else {
                        tempPackageVehicle[row.id_vehiculo] = [row];
                    }
                    tempDictVehicleDriver[row.id_vehiculo] = row.id_conductor;
                    tempDictVehicleCoDriver[row.id_vehiculo] = row.id_copiloto;
                    if (row.id_conductor){
                        if (tempPackageDriver[row.id_conductor]){
                            tempPackageDriver[row.id_conductor].push(row);
                        } else {
                            tempPackageDriver[row.id_conductor] = [row];
                        }
                        tempDictDriverVehicle[row.id_conductor] = row.id_vehiculo;
                    }
                    if (row.id_copiloto){
                        if (tempPackageDriver[row.id_copiloto]){
                            tempPackageDriver[row.id_copiloto].push(row);
                        } else {
                            tempPackageDriver[row.id_copiloto] = [row];
                        }
                        tempDictCoDriverVehicle[row.id_copiloto] = row.id_vehiculo;
                    }
                }
            }
            if (row.hora_estimada === null){
                tempCheck += 1
            }

        });
        setupdateRow(tempCheck);
        setpackageVehicle(tempPackageVehicle);
        setpackageDriver(tempPackageDriver);
        setdictVehicleDriver(tempDictVehicleDriver);
        setdictVehicleCoDriver(tempDictVehicleCoDriver);
        setdictDriverVehicle(tempDictDriverVehicle);
        setdictCoDriverVehicle(tempDictCoDriverVehicle);
        settempVehicleArray(vehicleArray);
        settempdriverArray(driverArray);
        setloading(false);
    };

    const handleDate = (value, index) => {
        const tempCheck = updateRow + 1;
        setupdateRow(tempCheck);
        const temphour = moment(value).format("YYYY-MM-DD HH:mm:ss");
        const auxArray = tempArray;
        auxArray[index].hora_estimada = temphour;
        settempArray(auxArray);
    };

    const handleSave = () => {
        setloading(true);
        const requestArray = []
        const auxArray = tempArray.map((row) => {
            const tempRow = {...row};
            if (tempRow.check){
                tempRow.estado_recepcion = "Asignado";
                tempRow.id_conductor = selectDriver[0].id;
                tempRow.id_vehiculo = selectVehicle[0].id;
                tempRow.id_copiloto = selectCoDriver ? selectCoDriver[0].id : null;
                tempRow.check = false;
                const body = {...tempRow}
                if (row.id_envios_pedidos) {
                    body.id_envios_pedidos = row.id_envios_pedidos.id
                }
                requestArray.push(api.put(`/api/agendatransportes/${row.id}`, body));
            }
            tempRow.check = false;
            return tempRow;
        });
        Promise.all(requestArray).then((request) => {
            setloading(false);
            setArray(auxArray);
            setchangeButtons(true);
            setrowChecked([]);
            handleClose();
        });
    };

    const handleCoDriver = (value) => {
        if (selectCoDriver){
            if (value[1] === selectCoDriver[1]){
                setselectCoDriver(null);
            } else {
                setselectCoDriver(value);
            }
        } else {
            setselectCoDriver(value);
        }
    };

    const handleSelectColor = (select, id, index, dict) => {
        if (select){
            if (select[1] === index ){
                if (dict[id]) {
                    return boxdangerSelect;
                } else {
                    return boxSuccesSelect;
                }
            }
        }
        return {}
    }

    const titleModal =   flag === 1 ? `Asignar vehiculo` :
        flag === 2 ? `Asignar conductor`:
        flag === 3 ? `Asignar copiloto`: 
        `Asignar hora estimada`

    
    const _content1_ = flag === 1 ? <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    style={{maxWidth: "100ev", flexWrap: 'nowrap',}}
>
    <h5><strong>Paso 1:</strong> Asigna un vehículo para trasladar estos pedidos.</h5>
    <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{maxWidth: "100%", overflow: "auto", flexWrap: 'nowrap',}}
    >
        <Grid
            style={{padding: "1em"}}
        >
            <Card  style={{width: "50px"}} >
            </Card>
        </Grid>
        {tempVehicleArray.map((row, index) => (
        <Grid
            key={`${index}-carCard`}
            style={{padding: "1em"}}
        >
            <Card
                onClick={() => setselectVehicle([row,index])}
                style={{
                    width: "200px",
                    boxShadow: selectVehicle ?
                        selectVehicle[1] === index ? "#948203b3  -3px 3px 10px 1px" : "" :
                        ""
                    }}
            >
                <CardHeader
                    color={dictVehicleDriver[row.id] ? "danger" : "success"} icon>
                    <CardIcon
                        color={dictVehicleDriver[row.id] ? "danger" : "success"}
                        style={handleSelectColor(
                                selectVehicle,
                                row.id,
                                index,
                                dictVehicleDriver
                            )}
                    >
                        <DriveEta />
                    </CardIcon>
                </CardHeader>
                <CardBody style={{maxHeight: "210px", overflow: "auto"}}>

                    <Typography variant="body2" display="block" gutterBottom>
                        {`SKU: ${row.tipo_vehiculo}`}
                    </Typography>
                    <Typography variant="body2" display="block" gutterBottom>
                        {`Patente: ${row.patente}`}
                    </Typography>
                    <Typography variant="body2" display="block" gutterBottom>
                        {row.modelo}
                    </Typography>
                    <Typography variant="body2" display="block" gutterBottom>
                        {`${dictVehicleDriver[row.id] ?  `${driverDict[dictVehicleDriver[row.id]]?.first_name} ${driverDict[dictVehicleDriver[row.id]]?.last_name}` : "Sin asignar"}`}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                        Pedidos:
                    </Typography>
                    {packageVehicle[row.id] && packageVehicle[row.id].map((subRow, subindex) => (
                        <Typography
                            key={`pedido-${index}-${subindex}`}
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            {`1 viaje a ${subRow.comuna} a las ${moment(subRow.hora_estimada).format("DD-MM-YYYY HH:mm")}`}
                        </Typography>
                    ))}
                </CardBody>
            </Card>
        </Grid>
        ))}
    </Grid>
</Grid> : null

    const _content2_ = flag === 2 ? <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    style={{maxWidth: "100ev", flexWrap: 'nowrap',}}
>
    <h5><strong>Paso 2:</strong> Asigna un conductor al vehículo</h5>
    <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{maxWidth: "100%", overflow: "auto", flexWrap: 'nowrap',}}
    >
        <Grid
            style={{padding: "1em"}}
        >
            <Card  style={{width: "50px"}} >
            </Card>
        </Grid>
        {tempdriverArray.map((row, index) => (
        <Grid
            key={`${index}-carCard`}
            style={{padding: "1em"}}
        >
            <Card
                style={{
                    width: "200px",
                    boxShadow: selectDriver ?
                    selectDriver[1] === index ? "#948203b3  -3px 3px 10px 1px" : "" :
                    ""
                }}
                onClick={() => setselectDriver([row,index])}
            >
                <CardHeader
                    color={(dictDriverVehicle[row.id] || dictCoDriverVehicle[row.id]) ? "danger" : "success"}
                    icon
                >
                    <CardIcon
                        color={(dictDriverVehicle[row.id] || dictCoDriverVehicle[row.id]) ? "danger" : "success"}
                        style={handleSelectColor(
                            selectDriver,
                            row.id,
                            index,
                            dictDriverVehicle[row.id] ? dictDriverVehicle : dictCoDriverVehicle
                        )}
                    >
                        <AccountCircle />
                    </CardIcon>
                </CardHeader>
                <CardBody style={{maxHeight: "210px", overflow: "auto"}}>
                    <Typography variant="body2" display="block" gutterBottom>
                        {`${row.first_name} ${row.last_name}`}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                        Pedidos:
                    </Typography>
                    {packageDriver[row.id] && packageDriver[row.id].map((subRow, subindex) => (
                        <Typography
                            key={`pedido-${index}-${subindex}`}
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            {`1 viaje a ${subRow.comuna} a las ${moment(subRow.hora_estimada).format("DD-MM-YYYY HH:mm")}`}
                        </Typography>
                    ))}
                </CardBody>
            </Card>
        </Grid>
        ))}
    </Grid>
</Grid> : null

    const _content3_ = flag === 3 ?  <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    style={{maxWidth: "100ev", flexWrap: 'nowrap',}}
>
    <h5><strong>Paso 3:</strong> Asigna un copiloto al vehículo</h5>
    <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{maxWidth: "100%", overflow: "auto", flexWrap: 'nowrap',}}
    >
        <Grid
            style={{padding: "1em"}}
        >
            <Card  style={{width: "50px"}} >
            </Card>
        </Grid>
        {tempdriverArray.map((row, index) => (
        selectDriver[1] !== index &&
        <Grid
            key={`${index}-carCard`}
            style={{padding: "1em"}}
        >
            <Card
                onClick={() => handleCoDriver([row,index])}
                style={{
                    width: "200px",
                    boxShadow: selectCoDriver ?
                    selectCoDriver[1] === index ? "#948203b3  -3px 3px 10px 1px" : "" :
                        ""
                }}
            >
                <CardHeader color={(dictDriverVehicle[row.id] || dictCoDriverVehicle[row.id]) ? "danger" : "success"} icon >
                    <CardIcon
                        color={(dictDriverVehicle[row.id] || dictCoDriverVehicle[row.id]) ? "danger" : "success"}
                        style={handleSelectColor(
                            selectCoDriver,
                            row.id,
                            index,
                            dictDriverVehicle[row.id] ? dictDriverVehicle : dictCoDriverVehicle
                        )}
                    >
                        <AccountCircle />
                    </CardIcon>
                </CardHeader>
                <CardBody style={{maxHeight: "210px", overflow: "auto"}}>
                    <Typography variant="body2" display="block" gutterBottom>
                        {`${row.first_name} ${row.last_name}`}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                        Pedidos:
                    </Typography>
                    {packageDriver[row.id] && packageDriver[row.id].map((subRow, subindex) => (
                        <Typography
                            key={`pedido-${index}-${subindex}`}
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            {`1 viaje a ${subRow.comuna} a las ${moment(subRow.hora_estimada).format("DD-MM-YYYY HH:mm")}`}
                        </Typography>
                    ))}
                </CardBody>
            </Card>
        </Grid>
        ))}
    </Grid>
</Grid> : null

    const _content4_ = flag ===  4? <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
>
    <h5><strong>Paso 4:</strong> Indica la hora estimada de llegada de cada pedido</h5>
    <Grid item xs={12} >
    <table className="table table-striped">
    <thead>
        <tr>
        <th className="text-center align-top">solicitud </th>
        <th className="text-center align-top">envio </th>
        <th className="text-center align-top">Zona </th>
        <th className="text-center align-top">Comuna </th>
        <th className="text-center align-top">Tipo de solicitud </th>
        <th className="text-center align-top">Fecha solicitud </th>
        <th className="text-center align-top">Paciente </th>
        <th className="text-center align-top">Fecha estimada de llegada </th>
        </tr>
    </thead>
    <tbody>
        {array.map((row, index) => (
            row.check &&
            <tr key={`${index}-workRows`}>
                <td className="text-center align-middle">
                    {
                        row.id_envios_pedidos ? `solicitud pedido: ${row.id_envios_pedidos.id_solicitud_pedido}`: 
                        row.id_parada_traslado ? `solicitud paciente: ${row.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}` :
                        row.id_parada_traslado_ingreso ? `solicitud ingreso: ${row?.id_parada_traslado_ingreso?.id_solicitud_ingreso.id}` : 
                        `Tramite - ${row.titulo} ${row.id}`
                    }
                </td>
                <td className="text-center align-middle">{
                    row.id_envios_pedidos ?  row.id_envios_pedidos.numero_pedido : "-"
                }</td>
                <td className="text-center align-middle">{row.zona}</td>
                <td className="text-center align-middle">{row.comuna}</td>
                <td className="text-center align-middle">{row.tipo_solicitud}</td>
                <td className="text-center align-middle">
                    {row.fecha_solicitud
                        ? moment(row.fecha_solicitud).format(
                            "YYYY-MM-DD HH:mm"
                        )
                        : row.id_parada_traslado
                        ? moment(row.id_parada_traslado.fecha_hora).format(
                            "YYYY-MM-DD HH:mm"
                        )
                        : "-"}
                </td>
                <td className="text-center align-middle">{
                    row.id_paciente ?
                        `${patientDict[row.id_paciente].nombre} ${patientDict[row.id_paciente].apellido_paterno} ${patientDict[row.id_paciente].apellido_materno}` :
                        ""
                    }
                </td>
                <th className="text-center align-top">
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    {row.hora_estimada === null ?
                        <KeyboardDateTimePicker
                            variant="inline"
                            format="DD/MM/YYYY HH:mm"
                            margin="normal"
                            label={"Fecha estimada"}
                            placeholder="Fecha estimada"
                            onChange={(e) => handleDate(e, index)}
                            value={moment()}
                            inputVariant={"outlined"}
                        />
                        :
                        <KeyboardDateTimePicker
                            variant="inline"
                            format="DD/MM/YYYY HH:mm"
                            margin="normal"
                            label={"Fecha estimada"}
                            placeholder="Fecha estimada"
                            onChange={(e) => handleDate(e, index)}
                            value={moment(row.hora_estimada)}
                            inputVariant={"outlined"}
                        />
                        }
                    </MuiPickersUtilsProvider>

                </th>
            </tr>
        ))}
    </tbody>
    </table>
</Grid>
</Grid> : null

    
        
    const _content_ = <div>
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            {flag !== 4 &&
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                item
                xs={12}
                style={{margin: "1em 0"}}
            >
                <h5>{`Los pedidos seleccionados son los siguientes:`}</h5>
            </Grid>
            }
            {flag !== 4 &&
            <Grid item xs={12} >
                <TableContainer style={{maxHeight: "400px"}}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    <TableCell className="text-center align-top">Solicitud </TableCell>
                    <TableCell className="text-center align-top">Envio </TableCell>
                    <TableCell className="text-center align-top">Zona </TableCell>
                    <TableCell className="text-center align-top">Comuna </TableCell>
                    <TableCell className="text-center align-top">Tipo de solicitud </TableCell>
                    <TableCell className="text-center align-top">Fecha solicitud </TableCell>
                    <TableCell className="text-center align-top">Paciente </TableCell>
                    <TableCell className="text-center align-top">Observaciones </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {array.map((row, index) => (
                        row.check &&
                        <TableRow key={`${index}-workRows`}>
                            <TableCell className="text-center align-middle">
                            {
                                row.id_envios_pedidos ? `solicitud pedido: ${row.id_envios_pedidos.id_solicitud_pedido}`: 
                                row.id_parada_traslado ? `solicitud paciente: ${row.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}` :
                                row.id_parada_traslado_ingreso ? `solicitud ingreso: ${row?.id_parada_traslado_ingreso?.id_solicitud_ingreso.id}` : 
                                `Tramite - ${row.titulo} ${row.id}`
                            }

                            </TableCell>
                            <TableCell className="text-center align-middle">{
                                row.id_envios_pedidos ?  row.id_envios_pedidos.numero_pedido : "-"
                            }
                            </TableCell>
                            <TableCell className="text-center align-middle">{row.zona}</TableCell>
                            <TableCell className="text-center align-middle">{row.comuna}</TableCell>
                            <TableCell className="text-center align-middle">{row.tipo_solicitud}</TableCell>
                            <TableCell className="text-center align-middle">
                                {
                                    row.fecha_solicitud ? moment(row.fecha_solicitud).format("YYYY-MM-DD HH:mm") : 
                                    row.id_parada_traslado ? moment(row.id_parada_traslado.fecha_hora).format("YYYY-MM-DD HH:mm") :
                                    moment(row.hora_estimada).format("YYYY-MM-DD HH:mm")
                                }
                            </TableCell>
                            <TableCell className="text-center align-middle">{
                                row.id_paciente ?
                                    `${patientDict[row.id_paciente].nombre} ${patientDict[row.id_paciente].apellido_paterno} ${patientDict[row.id_paciente].apellido_materno}` :
                                    ""
                                }
                            </TableCell>
                            <TableCell className="text-center align-middle">
                                {row.observacion_recepcion}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                </Table>
                </TableContainer>
            </Grid>
            }
            {_content1_}
            {_content2_}
            {_content3_}
            {_content4_}

        </Grid>
    </div>

    const _actions_ = <>
       { (flag === 1 && selectVehicle) ?
                <Button
                    color="primary"
                    mr={2}
                    variant="contained"
                    className="text-white"
                    style={{backgroundColor: green[500]}}
                    onClick={() => setflag(2)}
                >
                    Ir al paso 2
                </Button>
                : (flag === 2 && selectDriver) ?
                <Button
                    color="primary"
                    mr={2}
                    variant="contained"
                    className="text-white"
                    style={{backgroundColor: green[500]}}
                    onClick={() => setflag(3)}
                >
                    IR al paso 3
                </Button>
                :(flag === 3) ?
                <Button
                    color="primary"
                    mr={2}
                    variant="contained"
                    className="text-white"
                    style={{backgroundColor: green[500]}}
                    onClick={() => setflag(4)}
                >
                    IR al paso 4
                </Button>
                :(flag === 4 && updateRow >= rowChecked) ?
                <Button
                    mr={2}
                    color="primary"
                    variant="contained"
                    className="text-white"
                    onClick={handleSave}
                >
                    Guardar
                </Button>
                :
                <Button
                    disabled
                    mr={2}
                    variant="contained"
                >
                    Guardar
                </Button>
                }
                {flag > 1 ?
                <Button
                    mr={2}
                    variant="contained"
                    style={{backgroundColor: orange[800]}}
                    className="text-white"
                    onClick={() => setflag(flag-1)}
                >
                    atrás
                </Button>
                :
                <Button
                    mr={2}
                    variant="contained"
                    disabled
                >
                    atrás
                </Button>
                }
                <Button
                    mr={2}
                    variant="contained"
                    style={{backgroundColor: red[500]}}
                    className="text-white"
                    onClick={handleClose}
                >
                    Cerrar
                </Button>
    </>



    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={titleModal}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xl"
            
        />

  );
}