import React from "react";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

const Contract = props => {
  function save_id_request(id, type) {
    localStorage.setItem("request_id", id);
    localStorage.setItem("request_type", type);
  }
  return (
    <Link
      to={{
        pathname: `/admin/personalfile/manage/${props.id}`,
        state: {
          fromRequest: true
        }
      }}
    >
      <Button
        style={{ backgroundColor: "#1F8AFF", textTransform: "none" }}
        onClick={() => save_id_request(props.id_request, props.id_solicitud)}
      >
        Gestionar
      </Button>
    </Link>
  );
};

export default Contract;
