import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";



export default function ModalSummary(props) {
  const {
    open,
    closeModal,
    productos_restantes,
    id_order,
    enviosPedidos,
    moduleType,
  } = props;

  const [order, setOrder] = useState([]);

  const [arrayToSend, setArrayToSend] = useState(props.uploadArrayEnviados);

  const resetPicking = [];

  arrayToSend.map((array) => {
    const tempRow = { ...array };
    tempRow.sendPicking = array.picking;
    tempRow.picking = 0;

    resetPicking.push(tempRow);
  });

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    api.get(`api/solicitudpedidos/${id_order}`).then((response) => {
      setOrder(response.data);

    });
  }, []);

  const [scroll, setScroll] = useState("paper");

  function updateBenefit() {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    const formData = new FormData();
    formData.append("tipo_solicitud", order.tipo_solicitud);
    formData.append("fecha_solicitud", order.fecha_solicitud);
    formData.append("estado_picking", "Pendiente");
    formData.append("fecha_despacho", order.fecha_despacho);
    formData.append("nombre_paciente", order.nombre_paciente);
    formData.append("es_region", order.es_region ? 1 : 0);
    formData.append("tiene_medicamentos", order.tiene_medicamentos ? 1 : 0);
    formData.append("estado_guia_despacho", order.estado_guia_despacho);
    formData.append("estado_solicitud", order.estado_solicitud);
    formData.append("traslado_clinica", order.traslado_clinica ? 1 : 0);
    formData.append("motivo_rechazo", order.motivo_rechazo);
    formData.append("id_paciente", order.id_paciente);
    formData.append("numero_de_pedidos", order.numero_de_pedidos + 1);

    let arrayAux = [];
    apiform
      .put(
        `api/bodega/finalizar/picking/grupo/${group}/solicitud/${id_order}`,
        formData
      )
      .then((response) => {
        arrayToSend.map((array) => {
          const data = response.data;
          arrayAux.push({
            numero_pedido: data.numero_pedido,
            cantidad_enviada: array.picking,
            id_producto_pedido: array.id,
            id_envio_pedido: data.id,
          });
        });
        api
          .put("api/ProductosEnviados/bulk", arrayAux)
          .then((responseBulk) => {
            window.location.href = `/admin/warehouse/orders/${props.moduleType}`;
          })
          .catch((error) => {
            console.log(error.response);
          });
      })
      .catch((error) => alert(`Error ${error.response.data}`));
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Resumen del pedido</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          El pedido quedo con los siguiente pendientes: <br />
          <div className="mt-3 mb-3">
            <ul className="list-group">
              {productos_restantes.map((producto) => (
                <li key={producto.id} className="list-group-item">
                  {producto.cantidad_por_enviar}
                  {producto.id_insumo_medicamento.tipo_cantidad}
                   de
                  {producto.id_insumo_medicamento.descripcion_producto}
                </li>
              ))}
            </ul>
          </div>
          <div>
            El estado del pedido será:
            <span className="font-weight-bold">Pendiente</span>
          </div>
        </div>
        <div>
          <span className="font-weight-bold">
            ¿Estás seguro que deseas enviar el pedido a egresos?
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          No
        </Button>
        <Button onClick={updateBenefit} color="primary">
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalSummary.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_order: PropTypes.number,
  productos_restantes: PropTypes.array,
  enviosPedidos: PropTypes.array,
  uploadArrayEnviados: PropTypes.array,
  moduleType: PropTypes.string,
};
