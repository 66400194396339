import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import SortIcon from "@material-ui/icons/Sort";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableRevenues = props => {
  const classes = useStyles();
  const {
    searchIncomes,
    requestSortIncome,
    getClassNamesForIncome,
    moduleType
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Id 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortIncome("id")}
                  className={getClassNamesForIncome("id")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                SKU 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortIncome("id_insumo_medicamento.sku")}
                  className={getClassNamesForIncome("id_insumo_medicamento.sku")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Tipo producto
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortIncome("tipo_origen")}
                  className={getClassNamesForIncome("tipo_origen")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Cantidad 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortIncome("cantidad_productos")}
                  className={getClassNamesForIncome("cantidad_productos")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Fecha 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortIncome("fecha_tentativa_llegada")}
                  className={getClassNamesForIncome("fecha_tentativa_llegada")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Estado 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortIncome("estado_solicitud")}
                  className={getClassNamesForIncome("estado_solicitud")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Observación 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortIncome("observacion")}
                  className={getClassNamesForIncome("observacion")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchIncomes
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.producto.sku ? row.producto.sku : row.producto.SKU}</TableCell>
                  <TableCell align="center">{row.id_solicitud_ingreso.tipo_producto}</TableCell>
                  <TableCell align="center">{row.id_solicitud_ingreso.cantidad_productos}</TableCell>
                  <TableCell align="center">{row.id_solicitud_ingreso.fecha_tentativa_llegada}</TableCell>
                  <TableCell align="center">{row.id_solicitud_ingreso.estado_solicitud}</TableCell>
                  <TableCell align="center">{row.observacion}</TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/admin/warehouse/entry/addfile/${moduleType}/${row.id_solicitud_ingreso.id}`}
                    >
                      <Button
                        style={{ textTransform: "none" }}
                        variant="contained"
                        color="primary"
                      >
                        Detalle
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={searchIncomes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableRevenues;

TableRevenues.propTypes = {
  searchIncomes: PropTypes.array,
  requestSortIncome: PropTypes.func,
  getClassNamesForIncome: PropTypes.func,
  moduleType: PropTypes.string
};
