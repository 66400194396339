import ViewsContrato from "views/Widgets/ViewsContrato";
import ViewPersonalFile from "views/PersonalFile/ViewPersonalFile";
import Edit from "views/Widgets/EditContrato";
import DeleteContrato from "views/Widgets/DeleteContrato";
import TablesSolicitudesContratos from "views/Tables/TablesSolicitudesContratos.js";
import Remunerations from "views/Remunerations/Remunerations";
import Endownment from "views/Endowments/Endowments";
import RequestsHumanResources from "views/HumanResources/Requests/RequestsHumanResources";

export const view_personal_file = {
  path: "/personalfile/view/:id",
  component: ViewPersonalFile,
  layout: "/admin",
  invisible: true
};

export const manage_personal_file = {
  path: "/personalfile/manage/:id",
  component: ViewsContrato,
  layout: "/admin",
  invisible: true
};

export const edit_personal_file = {
  path: "/edit/fichapersonal/:id",
  component: Edit,
  layout: "/admin",
  invisible: true
};

export const delete_personal_file = {
  path: "/delete/fichapersonal/:id",
  component: DeleteContrato,
  layout: "/admin",
  invisible: true
};

export const requestHumanResources = {
  path: "/humanresources/requests/",
  name: "Solicitudes",
  mini: " . ",
  component: RequestsHumanResources,
  layout: "/admin"
};

export const remunerations = {
  //collapse: true,
  path: "/remuneraciones",
  name: "Remuneraciones",
  rtlName: "رد فعل الطاولة",
  mini: ".",
  rtlMini: "در",
  component: Remunerations,
  layout: "/admin"
};

export const endowments = {
  path: "/endowment/",
  name: "Dotación",
  mini: ".",
  component: Endownment,
  layout: "/admin"
};
