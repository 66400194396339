import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

import {classStyle} from "../../utils/styleFunction";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
    green,
  } from "@material-ui/core/colors";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function ModalAddBenefit({
    open,
    handleClose,
    handleAddRow,
}) {
    const classesStyle = classStyle();
    const [item, setitem] = useState("");
    const [name, setname] = useState("");
    const [value, setvalue] = useState(0);

    const handleOnChange = (value, set) => {
        let tempValue =  isNaN(value) ? 0 : value;
        set(tempValue);
    };

    const handleUpdate = () => {
        const row = {
            item: item,
            nombre: name,
            valor_inicial: value,
            valor_modificado: value,
            tipo: "Bono",
            color: "#ffef62",
            tipo_valor: "$",
            eliminable: true,
            editable: true,
        }
        handleAddRow(row);
        handleClose();
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesStyle.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper  className={classesStyle.paper}  style={{height: "85%", width: "90%", overflow: "auto"}}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Typography variant="h5" gutterBottom>{`Agregar registro`}</Typography>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0"}}
                    >
                        <Grid
                            item
                            xs={4}
                            style={{padding: "0 10px"}}
                        >
                            <DetailText
                                label={`Item`}
                                placeholder={`Item`}
                                value={item}
                                onchange={(e) => { setitem(e.target.value)}}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            style={{padding: "0 10px"}}
                        >
                            <DetailText
                                label={`Nombre`}
                                placeholder={`Nombre`}
                                value={name}
                                onchange={(e) => { setname(e.target.value)}}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            style={{padding: "0 10px"}}
                        >
                            <DetailNumber
                                label={`Valor`}
                                value={value}
                                onchange={e => handleOnChange(
                                    parseInt(e.target.value.split('$').join('').split(',').join('')),
                                    setvalue)
                                }
                                prefix={"$"}
                                thousandSeparator={true}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            style={{padding: "0 10px"}}
                        >
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 0 0"}}
                    >
                        {
                        (value && item && name) ?
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            style={{backgroundColor: green[500]}}
                            onClick={handleUpdate}
                        >
                            Guardar
                        </Button> :
                        <Button
                            disabled
                            mr={2}
                            variant="contained"
                            className="text-white"
                        >
                            Guardar
                        </Button>
                        }
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            color="primary"
                            onClick={handleClose}
                        >
                            Cerrar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
}