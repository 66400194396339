import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
    FormControlLabel,
    Radio,
    Checkbox,
} from '@material-ui/core';
import {
    RadioButtonChecked,
    CheckCircle,
    RadioButtonUnchecked,
} from "@material-ui/icons/";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import {classStyle} from "utils/styleFunction";
import {dictMonth} from "utils/validationFunction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function EditVisit({
    open,
    handleClose,
    editScheduleVisit,
    dictReservation,
    dictPatientFile,
    handleEditWork,
    handleRemoveReservation,
    showTabs,
}) {
    const classes = useStyles();
    const classesStyle = classStyle();
    const [initialCharge, setinitialCharge] = useState(true);
    const [data, setDate] = useState(editScheduleVisit[0]);
    const [start, setstart] = useState({});
    const [end, setend] = useState({});
    const [listHour, setlistHour] = useState([]);
    const [worker, setworker] = useState(editScheduleVisit[1]);
    const [hourType, sethourType] = useState("normal");
    const [changeHour, setchangeHour] = useState(false);
    const [extraLunch, setextraLunch] = useState(false);
    const [shiftMobilization, setshiftMobilization] = useState(false);
    const [specialViatic, setspecialViatic] = useState(false);
    const [extraLunchAmount, setextraLunchAmount] = useState(0);
    const [shiftMobilizationAmount, setshiftMobilizationAmount] = useState(0);
    const [specialViaticAmount, setspecialViaticAmount] = useState(0);
    const [extraTurnValue, setextraTurnValue] = useState(0);
    const [comment, setcomment] = useState(data.comentarios ? data.comentarios : "");

    useEffect(() => {
        if (initialCharge){
            initialData();
        }
        if (changeHour){
            getTimeList()
        }
    });

    const initialData = () => {
        setinitialCharge(false);
        const tempStart = moment(data.fecha_hora).format("HH:mm");
        const tempEnd = moment(data.fecha_final).format("HH:mm");
        const tempUser = dictPatientFile[data.id_ficha] ;
        setstart({value: tempStart, label: tempStart});
        setend({value: tempEnd, label: tempEnd});
        api.get(`/api/clinica/asignar/tarea/${data.id}`).then((response) => {
            const extra_values = response.data.valores_extras.reduce((accum, obj) => ({
                ...accum,
                [obj.codigo_tipo_valor]: obj
              }), {});
            sethourType(extra_values["ValorAtencionExtra"] ? "extra" : "normal")
            setextraLunch(extra_values["ColacionExtra"] ? true : false)
            setshiftMobilization(extra_values["MovilizacionExtra"] ? true : false)
            setspecialViatic(extra_values["ViaticoEspecial"] ? true : false)
            setextraLunchAmount(extra_values["ColacionExtra"] ? extra_values["ColacionExtra"].valor : 0)
            setshiftMobilizationAmount(extra_values["MovilizacionExtra"] ? extra_values["MovilizacionExtra"].valor : 0)
            setspecialViaticAmount(extra_values["ViaticoEspecial"] ? extra_values["ViaticoEspecial"].valor : 0)
            setextraTurnValue(extra_values["ValorAtencionExtra"] ? extra_values["ValorAtencionExtra"].valor : 0);
            setchangeHour(true);
        }).catch((error) => {
            setchangeHour(true);
        });

    };

    const getTimeList = () => {
        setchangeHour(false);
        const tempListHours = [];
        let tempDate =  moment(data.fecha_hora).format("YYYY-MM-DD");
        const tempStartVisit =  moment(data.fecha_hora).format("YYYY-MM-DD HH:mm:ss");
        let countDate = moment(tempDate);
        let tempDateEnd = moment(tempDate).add(1, "days");

        let startDateLimit = moment(countDate);
        let endDateLimit = moment(tempDateEnd);

        Object.keys(dictReservation).map((key) => {
            if (dictReservation[key][worker.id] && key !== tempStartVisit){
                const tempValue = dictReservation[key][worker.id];
                let tempStart = null;
                let tempEnd = null;

                if (moment(data.fecha_hora).diff(tempValue.fecha_final, "minutes") >= 0 ){
                    tempStart = moment(tempValue.fecha_final);
                } else if (moment(data.fecha_hora).diff(tempValue.fecha_hora, "minutes") >= 0 ){
                    tempStart = moment(tempValue.fecha_hora);
                }
                if (tempStart){
                    if (moment(tempStart).diff(startDateLimit, "minutes") >= 0 ){
                        startDateLimit = moment(tempStart);
                    }
                }

                if (moment(data.fecha_final).diff(tempValue.fecha_hora, "minutes") <= 0 ){
                    tempEnd = moment(tempValue.fecha_hora);
                } else if (moment(data.fecha_final).diff(tempValue.fecha_final, "minutes") <= 0 ){
                    tempEnd = moment(tempValue.fecha_final);
                }
                if (tempEnd){
                    if (moment(tempEnd).diff(endDateLimit, "minutes") <= 0 ){
                        endDateLimit = moment(tempEnd);
                    }
                }
            }
        });
        while (moment(tempDateEnd).diff(countDate, "minutes") >= 0){
            const tempHour = moment(countDate).format("HH:mm");
            tempListHours.push({value: tempHour, label: tempHour});
            countDate = moment(countDate).add(30, "minutes");
        }
        setlistHour(tempListHours);
    };

    const handleSelectHour= (value, argDate, set) => {
        const tempDate =  moment(data.fecha_hora).format("YYYY-MM-DD");
        let auxDate = moment(`${tempDate} ${value.value}:0`);
        let compareDate = null;
        let auxValue = value
        if (argDate === "start"){
            compareDate = moment(`${tempDate} ${end.value}:0`);
            auxValue = moment(compareDate).diff(auxDate, "minutes") > 0 ?
                auxDate : moment(compareDate).subtract(30, "minutes");
        } else {
            compareDate = moment(`${tempDate} ${start.value}:0`);
            auxValue = moment(auxDate).diff(compareDate, "minutes") > 0 ?
                auxDate : moment(compareDate).add(30, "minutes");
        }
        const valueFormat = moment(auxValue).format("HH:mm")
        set({value:valueFormat, label: valueFormat});
    };


    const handleHourType = event => {
        sethourType(event.target.value);
        setextraTurnValue(0);
    };

    const handleAddItems = (value, set, setvalue) => {
        set(!value);
        setvalue(0);
    };

    const handleOnChange = (value, set) => {
        let tempValue =  isNaN(value) ? 0 : value;
        set(tempValue);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesStyle.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper  className={classesStyle.paper}  style={{maxHeight: "85%", overflow: "auto"}}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <Typography variant="h5" gutterBottom>{`Editar visita`}</Typography>
                        <Typography variant="body2" gutterBottom>
                            {`${moment(data.fecha_hora).format("DD")} de ${dictMonth[parseInt(moment(data.fecha_hora).format("MM"))]}`}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {`TENS: ${worker.first_name} ${worker.last_name}`}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <DetailSelect
                            isDisabled={true}
                            label="Horario inicio:"
                            value={start}
                            onchange={(value) => handleSelectHour(value, "start", setstart)}
                            list={listHour.slice(0, listHour.length - 1)}
                        />
                        <DetailSelect
                            isDisabled={true}
                            label="Horario fin:"
                            value={end}
                            onchange={(value) => handleSelectHour(value, "end", setend)}
                            list={listHour.slice(1, listHour.length)}
                        />
                    </Grid>
                    {showTabs === "Traslado" &&
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={10}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={hourType === "normal"}
                                    onChange={handleHourType}
                                    value="normal"
                                    name="radio button enabled"
                                    aria-label="normal"
                                    icon={
                                        <RadioButtonUnchecked style={{fill: indigo[500]}} />
                                    }
                                    checkedIcon={
                                        <RadioButtonChecked style={{fill: indigo[500]}}/>
                                    }
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot
                                }}
                                label="Considerar como atención normal"
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={hourType === "extra"}
                                    onChange={handleHourType}
                                    value="extra"
                                    name="radio button enabled"
                                    aria-label="extra"
                                    icon={
                                        <RadioButtonUnchecked style={{fill: indigo[500]}} />
                                    }
                                    checkedIcon={
                                        <RadioButtonChecked style={{fill: indigo[500]}} />
                                    }

                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot
                                }}
                                label="Considerar como atención extra"
                        />
                        {(hourType === "extra" &&  showTabs === "Traslado" ) &&
                            <DetailNumber
                                label={`Valor atención extra`}
                                value={extraTurnValue}
                                onchange={e => handleOnChange(
                                    parseInt(e.target.value.split('$').join('').split(',').join('')),
                                    setextraTurnValue)
                                }
                                prefix={"$"}
                                thousandSeparator={true}
                            />
                        }
                    </Grid>
                    }
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <Typography variant="body2" gutterBottom>
                           {`¿Cuál de los siguientes items desea agregar?`}
                        </Typography>

                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={10}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    checked={extraLunch}
                                    onClick={() => handleAddItems(extraLunch, setextraLunch, setextraLunchAmount)}
                                    checkedIcon={
                                        <CheckCircle style={{fill: indigo[500]}} />
                                    }
                                    icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label="Colación atención extra"
                        />
                        { extraLunch &&
                            <DetailNumber
                                label={`Valor colación atención extra`}
                                value={extraLunchAmount}
                                onchange={e => handleOnChange(
                                    parseInt(e.target.value.split('$').join('').split(',').join('')),
                                    setextraLunchAmount)
                                }
                                prefix={"$"}
                                thousandSeparator={true}
                            />
                        }
                        <FormControlLabel
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    checked={shiftMobilization}
                                    onClick={() => handleAddItems(shiftMobilization, setshiftMobilization, setshiftMobilizationAmount)}
                                    checkedIcon={
                                        <CheckCircle style={{fill: indigo[500]}} />
                                    }
                                    icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label="Movilizacíon atención extra"
                        />
                        { shiftMobilization &&
                            <DetailNumber
                                label={`Valor movilizacíon atención extra`}
                                value={shiftMobilizationAmount}
                                onchange={e => handleOnChange(
                                    parseInt(e.target.value.split('$').join('').split(',').join('')),
                                    setshiftMobilizationAmount)
                                }
                                prefix={"$"}
                                thousandSeparator={true}
                            />
                        }
                        <FormControlLabel
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    checked={specialViatic}
                                    onClick={() => handleAddItems(specialViatic, setspecialViatic, setspecialViaticAmount)}
                                    checkedIcon={
                                        <CheckCircle style={{fill: indigo[500]}} />
                                    }
                                    icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label="Viatico especial"
                        />
                        { specialViatic &&
                            <DetailNumber
                                label={`Valor viatico especial`}
                                value={specialViaticAmount}
                                onchange={e => handleOnChange(
                                    parseInt(e.target.value.split('$').join('').split(',').join('')),
                                    setspecialViaticAmount)
                                }
                                prefix={"$"}
                                thousandSeparator={true}
                            />
                        }
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={10}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <DetailText
                            label={`Comentario`}
                            placeholder={``}
                            value={comment}
                            validation={false}
                            onchange={(e) => { handleOnChange(e.target.value, setcomment)}}
                        />
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 0 0"}}
                    >
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            style={{backgroundColor: green[500], marginRight: "4px", textTransform: "none"}}
                            onClick={() => handleEditWork(
                                extraLunchAmount,
                                shiftMobilizationAmount,
                                specialViaticAmount,
                                extraTurnValue,
                                comment,
                            )}
                        >
                            Guardar
                        </Button>
                        <Button
                            mr={2}
                            variant="contained"
                            style={{backgroundColor: indigo[500], marginRight: "10px", textTransform: "none"}}
                            className="text-white"
                            onClick={handleClose}
                        >
                            Cerrar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
}