import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import SortIcon from "@material-ui/icons/Sort";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment-timezone";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableInProcess = props => {
  const classes = useStyles();
  const {
    searchInProcess,
    requestSortInProcess,
    getClassNamesForInProcess,
    requestTypeInProcess,
    setRequestTypeInProcess,
    pickingStatusInProcess,
    setPickingStatusInProcess,
    regionInProcess,
    setRegionInProcess,
    supplyInProcess,
    setSupplyInProcess,
    moduleType,
    months_open,
    days_open,
    actualGroup
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "50px" }}>
                Id
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortInProcess("id")}
                  className={getClassNamesForInProcess("id")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "70px" }}>
                Tipo de pedido
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={requestTypeInProcess}
                  onChange={e =>
                    setRequestTypeInProcess(e.target.value.substr(0, 20))
                  }
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"programada"}>Programada</MenuItem>
                  <MenuItem value={"urgente"}>Urgente</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "110px" }}>
                Fecha de solitud
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortInProcess("fecha_solicitud")}
                  className={getClassNamesForInProcess("fecha_solicitud")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Estado picking
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={pickingStatusInProcess}
                  onChange={e =>
                    setPickingStatusInProcess(e.target.value.substr(0, 20))
                  }
                  label="Age"
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"incompleta"}>Incompleta</MenuItem>
                  <MenuItem value={"completa"}>Completa</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "110px" }}>
                Fecha a despachar
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortInProcess("fecha_despacho")}
                  className={getClassNamesForInProcess("fecha_despacho")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Paciente
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortInProcess("nombre_paciente")}
                  className={getClassNamesForInProcess("nombre_paciente")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "60px" }}>
                Región
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={regionInProcess}
                  onChange={e =>
                    setRegionInProcess(e.target.value.substr(0, 20))
                  }
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"si"}>Si</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Días de atraso
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Estado pedido
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortInProcess("estado_solicitud")}
                  className={getClassNamesForInProcess("estado_solicitud")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchInProcess.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{dictTypeRequest[row.tipo_solicitud]?.label || row.tipo_solicitud}</TableCell>
                  <TableCell align="center">
                    {moment(row.fecha_solicitud).format("YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell align="center">
                    {
                      row[
                        `estado_picking${
                          moduleType === "pharmacy" ? "_farmacia" : ""
                        }`
                      ]
                    }
                  </TableCell>
                  <TableCell align="center">
                    {moment(row.fecha_tentativa_llegada).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                  </TableCell>
                  <TableCell align="center">{row.nombre_paciente}</TableCell>
                  <TableCell align="center">
                    {row.es_region ? "Si" : "No"}
                  </TableCell>
                  {/*}
                  <TableCell align="center">
                    {row.tiene_medicamentos ? "Si" : "No"}
                  </TableCell>
                  */}
                  <TableCell align="center">
                    Meses: {months_open(row.fecha_tentativa_llegada)}
                     /  Días:
                    {days_open(row.fecha_tentativa_llegada)}
                  </TableCell>
                  <TableCell align="center">
                    {row[`estado_solicitud_${actualGroup}`]
                      ? row[`estado_solicitud_${actualGroup}`]
                      : row.estado_solicitud}
                  </TableCell>
                  <TableCell align="center">
                    {(row[`estado_solicitud_${actualGroup}`] ? (
                      row[`estado_solicitud_${actualGroup}`] ===
                      "Adjuntar guía de bodega"
                    ) : (
                      row.estado_solicitud === "Adjuntar guía de bodega"
                    )) ? (
                      <Link
                        to={`/admin/warehouse/order/${moduleType}/${row.id}`}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none" }}
                        >
                          Detalle
                        </Button>
                      </Link>
                    ) : (
                      <Link
                        to={`/admin/warehouse/order/${moduleType}/${row.id}`}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none" }}
                        >
                          Detalle
                        </Button>
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={searchInProcess.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableInProcess;

TableInProcess.propTypes = {
  searchInProcess: PropTypes.array,
  requestSortInProcess: PropTypes.func,
  getClassNamesForInProcess: PropTypes.func,
  requestTypeInProcess: PropTypes.string,
  setRequestTypeInProcess: PropTypes.func,
  pickingStatusInProcess: PropTypes.string,
  setPickingStatusInProcess: PropTypes.func,
  regionInProcess: PropTypes.string,
  setRegionInProcess: PropTypes.func,
  supplyInProcess: PropTypes.string,
  setSupplyInProcess: PropTypes.func,
  moduleType: PropTypes.string,
  months_open: PropTypes.func,
  days_open: PropTypes.func,
  actualGroup: PropTypes.string
};
