import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import ModalEditBenefits from "components/Covenants/Packages/Benefits/ModalEditBenefit";
import ModalCreatePackageBenefit from "components/Covenants/Packages/Benefits/ModalCreatePackageBenefit";
import ModalCloneBenefit from "components/Covenants/Packages/Benefits/ModalCloneBenefit";
import TableComponent from "views/Widgets/Common/TableComponent";
import { useParams } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import SearchPrestaciones from "./SearchPrestaciones";

import allActions from "actions";

import { useSelector, useDispatch } from "react-redux";


export default function Packages(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const id_paquete = parseInt(id, 10);
  const [listPackage, setlistPackage] = useState([]);

  const [benefits, setBenefits] = useState([]);
  const [modal_edit_benefits, setModal_edit_benefits] = useState(false);
  const [id_benefit, setId_benefit] = useState("");
  const [
    modal_create_package_benefit,
    setModal_create_package_benefit
  ] = useState(false);
  const [modalClonePackageBenifit, setModalClonePackageBenifit] = useState(false);
  const { fromConvenant } = props.location.state;
  const { nombre_paquetizacion } = props.location.state;
  const { nombre_isapre } = props.location.state;
  const { complexity } = props.location.state;

  const [grupoBenefit, setGrupoBenefit] = useState("");
  const [nombreBenefit, setNombreBenefit] = useState("");
  const [tipoCantidadBenefit, setTipoCantidadBenefit] = useState("");
  const [cantidadBenefit, setCantidadBenefit] = useState("");
  const [frecuenciaBenefit, setFrecuenciaBenefit] = useState("");
  const [tipoFrecuenciaBenefit, setTipoFrecuenciaBenefit] = useState("");
  const [estadoBenefit, setEstadoBenefit] = useState("");
  const [firstCharge, setFirstCharge] = useState(true);
  const [search, setSearch] = useState("");

  const setmodal = (
    id_benefit,
    modal_edit,
    grupo,
    nombre,
    tipoCantidad,
    cantidad,
    frecuencia,
    tipoFrecuencia,
    estado
  ) => {
    setId_benefit(id_benefit);
    setModal_edit_benefits(modal_edit);
    setGrupoBenefit(grupo);
    setNombreBenefit(nombre);
    setTipoCantidadBenefit(tipoCantidad);
    setCantidadBenefit(cantidad);
    setFrecuenciaBenefit(frecuencia);
    setTipoFrecuenciaBenefit(tipoFrecuencia);
    setEstadoBenefit(estado);
  };

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);

    const getPrestaciones = api.get(`api/prestacionespaquete/?paquete=${id_paquete}`);

    Promise.all([getPrestaciones])
      .then(response => {
        let tempPrestaciones = [];
        response[0].data.map(row => {
            tempPrestaciones.push({
              id: row.id,
              grupo: row.id_prestaciones ?
              row.id_prestaciones.grupo :
              row.id_equipo ? row.id_equipo.tipo_producto : "-",
              prestacion: row.id_prestaciones
                ? row.id_prestaciones.nombre
                : row.id_equipo ? `(${row.id_equipo.SKU}) ${row.id_equipo.descripcion}` : "-",

              frecuencia: row.frecuencia === 0 ? "-" : row.frecuencia,
              tipoFrecuencia: row.tipo_frecuencia,
              cantidad: row.cantidad,
              tipoCantidad: row.id_prestaciones
                ? row.id_prestaciones.tipo_cantidad
                : row.id_equipo ? "Unidad" : "-",
              valor: row.valor,
              ultimaModificacion: row.fecha_ultima_modificacion,
              estado: row.estado ? "Activa" : "Inactiva",
              estadoBool: row.estado
            });
          });
        setBenefits(tempPrestaciones);

      })

  };

  const handleOpenCopyPackageModal = () => {

    const requestPackage = api.get(`api/paquetes/`)
    const requestPackaging = api.get(`api/paquetizaciones/`)
    Promise.all([requestPackage, requestPackaging]).then(response => {
      const dictPackaging = response[1].data.reduce((accum, obj) => ({
        ...accum,
        [obj.id]: obj.id_convenio_isapre?.nombre
      }), {})
      const tempPackage = response[0].data.reduce((accum, obj) => ([
        ...accum,
        {
          value: obj.id,
          label: `${dictPackaging[obj.id_paquetizacion] || "Sin isapre"} - ${obj.codigo_paquete ? `(${obj.codigo_paquete})` : ""}) ${obj.zona} ${obj.complejidad} ${obj.descripcion ? `(${obj.descripcion})` : ""}`,
        }
      ]), [])

      setlistPackage(tempPackage);

      setModalClonePackageBenifit(true);

    }).catch((error) => {
      {};

    });
  }

  const searchPrestaciones = benefits.map((row) => {
    const _tempButton_ = <Button
      variant="contained"
      style={{ backgroundColor: "#1F8AFF", color: "white" }}
      onClick={() =>
        setmodal(
          row.id,
          true,
          row.grupo,
          row.prestacion,
          row.tipoCantidad,
          row.cantidad,
          row.frecuencia,
          row.tipoFrecuencia,
          row.estadoBool
        )
      }
    >
    <EditIcon />
  </Button>

  //eval is not use in table only condition to filter
  return [
    {eval:true, value: row.grupo},
    {eval:true, value: row.prestacion},
    {eval:true, value: row.cantidad},
    {eval:true, value: row.tipoCantidad},
    {eval:true, value: row.frecuencia},
    {eval:true, value: row.tipoFrecuencia},
    {eval:true, value: row.ultimaModificacion},
    {eval:true, value: row.estado},
    {eval:false, _value_: _tempButton_},
  ]
  }).filter(row => row.some(item => (item.eval && item.value?.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 )))

  const headers = [
    {label: "Grupo"},
    {label: "Prestación"},
    {label: "Cantidad"},
    {label: "Tipo Cantidad"},
    {label: "Frecuencia/Duración"},
    {label: "Tipo frecuencia"},
    {label: "Última modificación"},
    {label: "Estado"},
    {eval: false, label: "Editar"},
  ]

  return (
    <GridContainer>
      <ViewTitle
        title="PRESTACIONES"
        message="En esta sección podrás revisar las prestaciones."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/admin/convenants/">
              Convenios
            </Link>
            <Link color="inherit" href={`/admin/covenants/${fromConvenant}`}>
              Paquetizaciones {nombre_isapre} {nombre_paquetizacion}
            </Link>
            <Typography color="textPrimary">
              Prestaciones {complexity}
            </Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <GridContainer justify="center">
            <Button
              className="buttonHeaderStyle"
              onClick={() => setModal_create_package_benefit(true)}
            >
              Agregar prestación
            </Button>
            <Button
              className="buttonHeaderStyle"
              onClick={handleOpenCopyPackageModal}
            >
              Clonar otro paquete
            </Button>
          </GridContainer>
          <GridContainer justify="center">
            <SearchPrestaciones search={search} setSearch={setSearch} />
          </GridContainer>
          <CardBody>
            <TableComponent
              headers={headers}
              data={searchPrestaciones}
            />
          </CardBody>
        </Card>
      </GridItem>
      {modal_edit_benefits && (
        <ModalEditBenefits
          open={modal_edit_benefits}
          closeModal={() => setModal_edit_benefits(false)}
          id_benefit={id_benefit}
          id_paquete={id_paquete}
          grupoBenefit={grupoBenefit}
          nombreBenefit={nombreBenefit}
          tipoCantidadBenefit={tipoCantidadBenefit}
          cantidadBenefit={cantidadBenefit}
          frecuenciaBenefit={frecuenciaBenefit}
          tipoFrecuenciaBenefit={tipoFrecuenciaBenefit}
          estadoBenefit={estadoBenefit}
          setFirstCharge={setFirstCharge}
        />
      )}
      {modal_create_package_benefit && (
        <ModalCreatePackageBenefit
          open={modal_create_package_benefit}
          closeModal={() => setModal_create_package_benefit(false)}
          id_benefit={id_benefit}
          id_paquete={id_paquete}
          setFirstCharge={setFirstCharge}
        />
      )}
      {modalClonePackageBenifit && (
        <ModalCloneBenefit
            open={modalClonePackageBenifit}
            closeModal={() => setModalClonePackageBenifit(false)}
            id_paquete={id_paquete}
            listPackage={listPackage}
            setFirstCharge={setFirstCharge}
          />
      )}
    </GridContainer>
  );
}
