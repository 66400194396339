import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { grey, indigo, orange, green, red } from "@material-ui/core/colors";
import allActions from "actions";

import api from "utils/API";
import moment from "moment-timezone";

export default function Downloads() {
  const dispatch = useDispatch();

  const handleDownload = (e) => {

    api.get("api/descargarPrestaciones/", { responseType: "blob" }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", "prestaciones.xlsx");
      document.body.appendChild(fileLink);

      fileLink.click();

    }).catch((error) => {
      {};
    })
  }

  return (
    <Grid item container xs={12} style={{ marginTop: "2em", backgroundColor: "#fff", borderRadius: "5px" }}>
    <Grid item container xs={12} style={{ padding: "10px" }}>
        <Grid item container xs={3}  direction="row" alignContent="flex-start">
            <Grid item container xs={12} style={{margin: "0 0 20px 0"}}>
                <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}>
                    Descargar Prestaciones
                </Typography>
            </Grid>
            <Grid item container xs={12}>
                <Typography gutterBottom style={{ textTransform: "none", margin: "0 0 32px 0" }}>
                    Permite descargar las prestaciones cargadas en SICLIN.
                    Estas se encuentran asociadas a paquetetes, convenios e isapres
                </Typography>
                <Button variant="contained" color="primary" style={{ width: "313px", height: "36px"}} onClick={handleDownload}>DESCARGAR</Button>
            </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

}
