import React, {useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Fab,
    CircularProgress,
    Paper,
} from "@material-ui/core";
import {Check, CloudDownload} from '@material-ui/icons/';
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {classStyle} from "../../utils/styleFunction";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {green} from "@material-ui/core/colors";
import {toMoneyFormat} from "utils/formatFunctions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

const useStylesLoad = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
}));

export default function ModalBillingsDownload({
    open,
    handleClose,
    dataArray,
    processDownload,
    changeButtons,
    dictReadyDownload,
    handleProcessDownload
}) {
    const classes = useStyles();
    const classesStyle = classStyle();
    const classesLoad = useStylesLoad();
    const buttonClassname = clsx({
        [classes.buttonSuccess]: processDownload,
    });

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesStyle.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper  className={classesStyle.paper}  style={{maxHeight: "85%", width: "90%", overflow: "auto"}}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item xs={12}
                >
                    <Grid item xs={12}>
                        <TableContainer style={{height: "440px"}}>
                            <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                <TableCell className="text-center align-middle">
                                    Nombre paciente
                                </TableCell>
                                <TableCell className="text-center align-middle">
                                    Tipo de paciente
                                </TableCell>
                                <TableCell className="text-center align-middle">
                                    Isapre
                                </TableCell>
                                <TableCell className="text-center align-middle">
                                    Monto facturado total
                                </TableCell>
                                <TableCell className="text-center align-top">
                                    Proceso
                                </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataArray.map((row, index) => (
                                    <TableRow
                                    key={`${index}-workRows`}
                                    >
                                    <TableCell className="text-center align-middle">
                                        {row.nombre_paciente}
                                    </TableCell>
                                    <TableCell className="text-center align-middle">
                                        {row.tipo_paciente}
                                    </TableCell>
                                    <TableCell className="text-center align-middle">
                                        {row.nombre_isapre}
                                    </TableCell>
                                    <TableCell className="text-center align-middle">
                                        {`${toMoneyFormat(row.monto_facturado_modificado/1.19+row.paquete_insumos+row.paquete_medicamentos)}`}
                                    </TableCell>
                                    <TableCell className="text-center align-middle">
                                        <div className={classesLoad.root}>
                                            <div className={classesLoad.wrapper}>
                                                <Fab
                                                    aria-label="save"
                                                    color="primary"
                                                    className={buttonClassname}
                                                >
                                                {dictReadyDownload[row.id] ? <Check /> : <CloudDownload />}
                                                </Fab>
                                                {(!dictReadyDownload[row.id] && processDownload) && <CircularProgress size={68} className={classesLoad.fabProgress} />}
                                            </div>

                                        </div>
                                    </TableCell>
                                    </TableRow>
                                ))}
                                 {changeButtons === false && <TableRow></TableRow>}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item xs={12}
                        style={{margin: "10px 0"}}
                    >
                        { !processDownload ?
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            color="primary"
                            onClick={() => handleProcessDownload(0)}
                            style={{margin: "0 5px"}}
                        >
                            Descargar Archivos
                        </Button>:
                        <Button
                            disabled
                            mr={2}
                            variant="contained"
                            style={{margin: "0 5px"}}
                        >
                            Descargar Archivos
                        </Button>
                        }
                        { !processDownload ?
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            color="primary"
                            onClick={handleClose}
                            style={{margin: "0 5px"}}
                        >
                            Cerrar
                        </Button>:
                        <Button
                            disabled
                            mr={2}
                            variant="contained"
                            style={{margin: "0 5px"}}
                        >
                            Cerrar
                        </Button>
                        }
                    </Grid >
                </Grid >
            </Paper>
        </Modal>
    );
}