import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import {
    RadioButtonChecked,
    CheckCircle,
    RadioButtonUnchecked,
} from "@material-ui/icons/";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import MomentUtils from "@date-io/moment";
import api from "utils/API";
import moment from "moment-timezone";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);


export default function ModalcheckOut({open, handleClose, dataPatient, dataFile}) {
    const classes = useStyles();
    const [arrayCheckOut, setarrayCheckOut] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [startDate, setstartDate] = useState(null);
    const [endDate, setendDate] = useState(null);
    const [motive, setmotive] = useState("");
    const [state, setstate] = useState("");
    const [editRow, seteditRow] = useState(null);
    const [hospitalization, sethospitalization] = useState(false)

    useEffect(() => {
        api.get(`api/internacionexterna/?paciente=${dataPatient.id}`).then((response) => {
            setarrayCheckOut(response.data);
        });
    }, []);

    const handleAddCheckOut = () => {
        const body = {
            fecha_ida: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
            fecha_vuelta: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : null,
            motivo_salida: motive,
            id_ficha: dataFile.id,
            clinica_cmh: hospitalization
        };
        api.post(`api/internacionexterna/`, body).then((response) => {
            const tempArrayCheckOut = [...arrayCheckOut]
            tempArrayCheckOut.push(response.data);
            setstartDate(null);
            setendDate(null);
            setmotive("null");
            setstate("");
            setarrayCheckOut(tempArrayCheckOut);
        });
    };

    const handleEditCheckOut = () => {
        const body = {
            fecha_ida: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
            fecha_vuelta: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : null,
            motivo_salida: motive,
        };

        api.patch(`api/internacionexterna/${editRow.id}`, body).then((response) => {
            const tempArrayCheckOut = [...arrayCheckOut];
            const indexArray = tempArrayCheckOut.findIndex((row) => (row.id === editRow.id));
            const tempRow = tempArrayCheckOut[indexArray];
            tempRow.fecha_ida = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
            tempRow.fecha_vuelta = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : null;
            tempRow.motivo_salida = motive;
            tempArrayCheckOut[indexArray] = tempRow
            setstartDate(null);
            setendDate(null);
            setmotive("null");
            setstate("");
            setarrayCheckOut(tempArrayCheckOut);
        });
    };

    const handleDeleteCheckOut = () => {
        api.delete(`api/internacionexterna/${editRow.id}`).then((response) => {
            const tempArrayCheckOut = arrayCheckOut.filter((row) => (row.id !== editRow.id));
            setstartDate(null);
            setendDate(null);
            setmotive("null");
            setstate("");
            setarrayCheckOut(tempArrayCheckOut);
        });
    }

    const handleDate = (value, set) => {
        set(value);
    };

    const handleAddRegister = () => {
        setstate("Insertar");
        setstartDate(null);
        setendDate(null);
        setmotive("");
    }

    const handleEditRegister = (row) => {
        seteditRow(row)
        setstate("Editar");
        setstartDate(row.fecha_ida);
        setendDate(row.fecha_vuelta);
        setmotive(row.motivo_salida);
    }

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };

    const dataTable = arrayCheckOut.sort((a, b) => {
        if (moment(a.fecha_registro).diff(moment(b.fecha_registro), "minutes") > 0 ){
            return 1;
        } else {
            return -1;
        }
    })

    const _content_ = <div>
    <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-end"
        item
        xs={12}
    >
        {state === "" ?
        <Grid
            item
            xs={12}
        >
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell className="text-center align-top" style={{minWidth: "150px"}}>
                            Fecha de registro
                        </TableCell>
                        <TableCell className="text-center align-top" style={{minWidth: "150px"}}>
                            Inicio de periodo
                        </TableCell>
                        <TableCell className="text-center align-top" style={{minWidth: "150px"}}>
                            Término de periodo
                        </TableCell>
                        <TableCell className="text-center align-top" style={{minWidth: "150px"}}>
                            Motivo
                        </TableCell>
                        <TableCell className="text-center align-top" style={{minWidth: "150px"}}>
                            Clinica San Gerardo
                        </TableCell>
                        <TableCell className="text-center align-top">
                            Acción
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                        <TableRow
                            key={`${index}-checkout`}
                        >
                       <TableCell className="text-center align-top">
                            {moment(row.fecha_registro).format("YYYY-MM-DD HH:mm")}
                        </TableCell>
                        <TableCell className="text-center align-top">
                            {moment(row.fecha_ida).format("YYYY-MM-DD HH:mm")}
                        </TableCell>
                        <TableCell className="text-center align-top">
                            {moment(row.fecha_vuelta).format("YYYY-MM-DD HH:mm")}
                        </TableCell>
                        <TableCell className="text-center align-top">
                            {row.motivo_salida}
                        </TableCell>
                        <TableCell className="text-center align-top">
                            {row.clinica_cmh ? "Sí" : "No"}
                        </TableCell>
                        <TableCell className="text-center align-top">
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            size={"small"}
                            style={{backgroundColor: green[500]}}
                            onClick={() => handleEditRegister(row)}
                        >
                            Editar
                        </Button>
                        </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={arrayCheckOut.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                inputProps: { "aria-label": "Filas por página" },
                native: false
                }}
                labelRowsPerPage={"Filas por página"}
                nextIconButtonText={"Siguiente"}
                backIconButtonText={"Atrás"}
                labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} de ${
                    count !== -1 ? count : `más que ${to}`
                }`
                }
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Grid> :
        (state === "Insertar" || state === "Editar") ?
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={6}
                style={{padding: "0 5px"}}
            >
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            {endDate ? (
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha de inicio"}
                placeholder="Fecha de inicio"
                onChange={e => handleDate(e, setstartDate)}
                maxDate={moment(endDate)}
                value={startDate}
                inputVariant={"outlined"}
                />
            ) : (
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha de inicio"}
                placeholder="Fecha de inicio"
                onChange={e => handleDate(e, setstartDate)}
                value={startDate}
                inputVariant={"outlined"}
                />
            )}
            </MuiPickersUtilsProvider>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={6}
                style={{padding: "0 5px"}}
            >
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            {startDate ? (
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha de termino"}
                placeholder="Fecha de termino"
                onChange={e => handleDate(e, setendDate)}
                minDate={moment(startDate)}
                value={endDate}
                inputVariant={"outlined"}
                />
            ) : (
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha de termino"}
                placeholder="Fecha de termino"
                onChange={e => handleDate(e, setendDate)}
                value={endDate}
                inputVariant={"outlined"}
                />
            )}
            </MuiPickersUtilsProvider>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{padding: "5px"}}
            >
            <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checked={hospitalization}
                            onClick={() => sethospitalization(!hospitalization)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Clinica San Gerardo"
                />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{padding: "5px"}}
            >
                <TextField
                    id="motive_id"
                    label={`Motivo`}
                    multiline
                    rows={8}
                    value={motive}
                    variant="outlined"
                    onChange={(event) => setmotive(event.target.value)}
                    style={{width: "100%"}}
                />
            </Grid>
        </Grid> :
        state === "Eliminar" &&
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "0 0 10px 0"}}
        >
            <Typography>
                  {`¿Estas seguro que deseas eliminar el registro?`}
            </Typography>
        </Grid>
        }
    </Grid>
</div>

const _actions_ = <>
    {state === "" ?
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: green[500]}}
        onClick={handleAddRegister}
    >
        Agregar registro
    </Button> :
    (state === "Insertar" && startDate !== null ) ?
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: green[500]}}
        onClick={handleAddCheckOut}
    >
        Guardar Registro
    </Button> :
    state === "Insertar" ?
    <Button
        mr={2}
        variant="contained"
        disabled
    >
        Guardar Registro
    </Button> :
    (state === "Editar" && startDate !== null) ?
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: green[500]}}
        onClick={handleEditCheckOut}
    >
        Editar Registro
    </Button> :
    state === "Editar" &&
    <Button
        mr={2}
        variant="contained"
        disabled
    >
        Editar Registro
    </Button>
    }
    {state === "Editar" &&
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: red[500], marginRight: "10px"}}
        onClick={() => setstate("Eliminar")}
    >
        Eliminar
    </Button>
    }
    {state === "Eliminar" &&
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: red[500], marginRight: "10px"}}
        onClick={handleDeleteCheckOut}
    >
        Si, eliminar
    </Button>
    }
    {state !== "" &&
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        color="primary"
        onClick={() => setstate(`${state === "Eliminar" ? "Editar" : ""}`)}
    >
        {`${state === "Eliminar" ? "No, volver atrás" : "Atrás"}`}
    </Button>
    }

    <Button
        mr={2}
        variant="contained"
        color="primary"
        className="text-white"
        onClick={handleClose}
    >
        Cerrar
    </Button>
</>


return (
    <ModalDialog
        open={open}
        onClose={handleClose}
        title= {state === "" ? `Registro de salidas/hospitalización `: state === "Insertar" ? `Agregar registro` : state === "Editar" ? `Editar registro` : state === "Eliminar" && `Eliminar registro`}
        _content_={_content_}
        _actions_ = {_actions_}
    />
    
    );
}