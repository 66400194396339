import React, { useState, useEffect } from "react";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import api from "utils/API";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { MenuItem } from "@material-ui/core/";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";



export default function ModalCheckEquipment({
  open,
  closeModal,
  info,
  infoRow
}) {
  const [comentario, setComentario] = React.useState("");
  const [estado, setEstado] = React.useState("");


  const handleComentario = (event) => {
    setComentario(event.target.value);
  };
  const handleEstado = (event) => {
    setEstado(event.target.value);
  };

  const scroll = "paper";

  function sendNotification() {
    api.patch(`api/tareaspaciente/${infoRow.id}`, {
      estado: "Finalizado"
    }).then(response => {
        api.patch(`api/solicitudingresos/${infoRow.id_solicitud_ingreso_id}`, {
          estado_solicitud: "NotificarBodega",
          estado_ingreso: "NotificarBodega"
        }).then(response => {
            api
              .patch(`api/productosingresos/${info.id}`, {
                observacion: comentario,
                estado_recepcion: estado
              })
              .then(response => {
                closeModal();
              })
              .catch(error => {
                closeModal();
              });
          })
      })
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Revision de Equipo</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "10rem" }}>
          <div className="mt-3 mb-3">
            <ul className="list-group">
              <li className="list-group-item">
                <span className="font-weight-bold">Producto: </span>
                {info.descripcion}
              </li>
              <li className="list-group-item">
                <span className="font-weight-bold">SKU: </span>
                {info.sku}
              </li>
              <li className="list-group-item">
                <span className="font-weight-bold">Grupo: </span>
                Equipo
              </li>
              <li className="list-group-item">
                <span className="font-weight-bold">Observaciones: </span>
                {info.observaciones}
              </li>
              <li className="list-group-item">
                <TextField
                  required
                  id="outlined-name"
                  label="Comentarios estado recepcion"
                  value={comentario}
                  onChange={handleComentario}
                  fullWidth
                />
              </li>
              <li className="list-group-item">
                <TextField
                  id="outlined-select-currency"
                  select
                  fullWidth
                  label="Estado"
                  value={estado}
                  onChange={handleEstado}
                  helperText="Seleccione el estado de revision"
                >
                  <MenuItem key={"Aprobado"} value={"Aprobado"}>
                    Aprobado
                  </MenuItem>
                  <MenuItem key={"Reprobado"} value={"Reprobado"}>
                    Reprobado
                  </MenuItem>
                </TextField>
              </li>
            </ul>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Cancelar
        </Button>
        <Button onClick={sendNotification} color="primary">
          Notificar revision a ingresos
        </Button>
      </DialogActions>
    </Dialog>
  );
}
