import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import api from 'utils/API';
import moment from "moment-timezone";
import "moment/locale/es";

import ExamsList from './components/ExamList';
import ExamForm from './components/ExamForm';

import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TablePagination from "@material-ui/core/TablePagination";
import Spiner from 'views/Widgets/Spiner';
import useToogle from 'utils/CustomHooks/useToogle';
import usePagination from 'utils/CustomHooks/usePagination';
import AppDialog from 'views/Widgets/AppDialog';

const useStyles = makeStyles({
  mr8: {
    marginRight: 8
  },
  dialogHeader: {
    backgroundColor: '#303F9F',
    padding: '0px 24px'
  },
  dialogContent: {
    padding: 24
  },
  colorWhite: {
    color: 'white'
  },

  '@media (max-width: 800px)': {
  },
});

function Exams({ examsData }) {
  const classes = useStyles()
  const { register, errors, handleSubmit, control, reset } = useForm()
  const [spiner, setSpiner] = useToogle()
  const [pagination, handleChangePage, handleChangeRowsPerPage] = usePagination(5)
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [examList, setExamList] = useState([])
  const [typeExamenList, setTypeExamenList] = useState([])
  const [examInfo, setExamInfo] = useState(undefined)
  const [file, setFile] = useState([])
  const [alert, setAlert] = useState({ success: false, error: false })


  const openDialog = () => {
    api.get("api/examenes/clinica/").then((response) => {
      setTypeExamenList(response.data.map((row) => {return{value: row.id, label: row.nombre}}))
    })
  
    setIsOpenDialog(true)
  }

  const closeDialog = () => {
    setIsOpenDialog(false)
    setFile([])
    setAlert({ ...alert, error: false, success: false })
  }

  const openDetailExam = (exam) => {
    if (examInfo?.id === exam.id) {
      return setExamInfo(undefined)
    }
    setExamInfo(exam)
  }

  const onSubmit = (data) => {
    setSpiner()
    const newExam = new FormData()
    newExam.append('id_examen_clinica', data.examType.value)
    newExam.append('laboratorio', data.originLab)
    newExam.append('fecha_toma_examen', moment(data.examDate).format('YYYY-MM-DD HH:mm:ss'))
    newExam.append('id_paciente', examsData.file.id_paciente)

    api.post("api/examenes/guardar/", newExam).then((response) => {
      const uploadFile = new FormData()
      uploadFile.append('archivo', file[0])
      uploadFile.append('tipo_documento', 'ResultadosExamenes')
      uploadFile.append('nombre_archivo', file[0].name)
      uploadFile.append('id_paciente', examsData.file.id_paciente)

      api.post(
        `api/paciente/${examsData.file.id_paciente}/solicitudes/examenes/${response.data.id_solicitud_paciente_id}/documentos/ficha_paciente_examen/subir/`
        , uploadFile
      ).then((subResponse) => {
        const newResponse = { ...response.data, id_resultado_examen_id: subResponse.data.id }
        setExamList([...examList, newResponse])
        setAlert({ ...alert, error: false, success: true })
        setTimeout(() => {
          setAlert({ ...alert, success: false })
          reset()
          setSpiner()
          closeDialog()
        }, 2500);
      }, error => {
        setAlert({ ...alert, error: true })
        setSpiner()
      })
    }, (error) => {
      setAlert({ ...alert, error: true })
      setSpiner()
    })
  }

  useEffect(() => {
    setExamList(examsData.examList)
  }, [examsData.examList])

  return (
    <>
      <Spiner open={spiner} />

      <Button variant='outlined' color='primary' onClick={() => openDialog()} style={{ backgroundColor: 'rgba(63, 81, 181, 0.08)' }}>
        <CloudUploadIcon color='primary' className={classes.mr8} />
        Subir examen
      </Button>

      <ExamsList examList={examList} pagination={pagination} examInfo={examInfo} openDetailExam={openDetailExam} />

      <TablePagination
        rowsPerPageOptions={[1, 5, 10, 15]}
        component="div"
        count={examList.length}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        SelectProps={{ inputProps: { "aria-label": "Filas por página" }, native: false }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `más que ${to}`}`}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <AppDialog title='SUBIR EXAMEN' open={isOpenDialog} onClose={closeDialog}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ExamForm
            typeExamenList={typeExamenList}
            register={register}
            errors={errors}
            control={control}
            file={file}
            alert={alert}
            setFile={setFile}
          />
        </form>
      </AppDialog>
    </>
  )
}

export default Exams
