import React from "react";

export const useSortableDataRetirement = (itemsRetirement, config = null) => {
  const [sortConfigRetirement, setSortConfigRetirement] = React.useState(
    config
  );

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...itemsRetirement];
    if (sortConfigRetirement !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfigRetirement.key] < b[sortConfigRetirement.key]) {
          return sortConfigRetirement.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigRetirement.key] > b[sortConfigRetirement.key]) {
          return sortConfigRetirement.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [itemsRetirement, sortConfigRetirement]);

  const requestSortRetirement = key => {
    let direction = "ascending";
    if (
      sortConfigRetirement &&
      sortConfigRetirement.key === key &&
      sortConfigRetirement.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfigRetirement({ key, direction });
  };

  return {
    itemsRetirement: sortedItems,
    requestSortRetirement,
    sortConfigRetirement
  };
};
