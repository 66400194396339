import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


export default function ModalAceptReceptionComplete(props) {
  const { open, closeModal, id_order, moduleType } = props;
  const [order, setOrder] = useState([]);

  const { getEnviosPedidos } = props;
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    api.get(`api/solicitudpedidos/${id_order}`).then(response => {
      setOrder(response.data);
    });
  }, []);

  const [scroll, setScroll] = useState("paper");

  function updateBenefit() {
    const formData = new FormData();

    formData.append("estado", "Adjuntar guía");
    formData.append("estado_egreso", "Adjuntar guía");

    const requestSend = apiform.patch(`api/enviospedidos/${getEnviosPedidos.id}`, formData)
    Promise.all([requestSend]).then(response => {
        window.location.href = `/admin/outcome/receptions/${moduleType}`;
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Resumen del pedido</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          <div>
            El pedido está: <span className="font-weight-bold">Completo</span>
          </div>
        </div>
        <div>
          <span className="font-weight-bold text-center">
            ¿Estás seguro que deseas recepcionar el pedido?
          </span>
          <br />
          <p className="text-center">
            Se enviará un correo automáticamente a los contactos del paciente.
          </p>
          <p className="text-center">
            Los productos del pedido se descontarán del stock automáticamente.
          </p>
          <p className="text-center">
            Se descargará la guía de despacho automáticamente para que sea
            adjuntada posteriormente.
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          No
        </Button>
        <Button onClick={updateBenefit} color="primary">
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalAceptReceptionComplete.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_order: PropTypes.number
};
