import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    TextField
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import {red, green} from "@material-ui/core/colors";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: "all 500ms linear",
    },
    paper: {
        transition: "all 500ms linear",
        padding: "2% 4% 3%",
    }
}));

export default function ModalUploadFile({
    open,
    handleClose,
    dataPatient,
    setmessage,
    handleAddFile,
}) {
    const classes = useStyles();

    const [selectfile, setselectfile] = useState(null);
    const [typeFile, settypeFile] = useState({value:"informe", label:"Informes clínicos previos"});

    const listTypeFile = useSelector(state => state.currentList.LISTA_TIPO_ARCHIVO_PACIENTE);

    const uploadFile = () => {
        const fileInfo = selectfile[0];
        const body = new FormData();
        body.append('archivo', fileInfo);
        body.append('tipo_documento', typeFile.value);
        body.append('nombre_archivo', fileInfo.name);
        body.append('id_paciente',  dataPatient.id);

        api.post(`api/documentospaciente/`, body).then((response) => {
            handleAddFile(response.data)
            setmessage("Archivo Cargado");
            handleClose();
        });
    };

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px',
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.paper}>
            <h3 id="spring-modal-title">{`Subir archivo`}</h3>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                item
                xs={12}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-end"
                        item
                        xs={12}
                    >
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            item
                            xs={6}
                        >
                            {selectfile? selectfile[0].name: ""}
                            <Button
                                variant="contained"
                                component="label"
                                color="primary"
                                style={{margin: "0 0 18px 0"}}
                            >
                                Cargar archivo
                                <TextField
                                    type="file"
                                    id="exam"
                                    onChange={(event) =>  setselectfile(event.target.files)}
                                    fullWidth my={2}
                                    style={{display: 'none'}}
                                    accept={"pdf", "jpg", "jpeg", "png"}
                                />
                            </Button>

                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={6}
                        >
                            <DetailSelect
                                label="Tipo de archivo"
                                value={typeFile}
                                onchange={(value) => { settypeFile(value) }}
                                list={listTypeFile}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                >
                     {(selectfile && typeFile) ?
                    <Button
                        variant="contained"
                        component="label"
                        className="text-white"
                        style={{backgroundColor: green[500]}}
                        onClick={uploadFile}
                    >
                        Subir archivo
                    </Button>
                    :
                    <Button
                        disabled
                        variant="contained"
                        component="label"
                    >
                    Subir archivo
                    </Button>

                    }
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    </Modal>
    );
}