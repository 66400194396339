import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export const Buttons = (checkTab, moduleType = "") => {
  switch (checkTab) {
    case 0:
      return (
        <Link to={`/admin/warehouse/request/add/${moduleType}`}>
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            color="primary"
          >
            Nueva Solicitud fraccionamiento
          </Button>
        </Link>
      );
    case 1:
      return (
        <Link to={`/admin/warehouse/purchases/add/${moduleType}`}>
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            color="primary"
          >
            Nueva Solicitud compras
          </Button>
        </Link>
      );
    case 2:
      return (
        <Link to={`/admin/warehouse/commodity/add/${moduleType}`}>
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            color="primary"
          >
            Nueva Solicitud retiro
          </Button>
        </Link>
      );
    case 3:
      return (
        <Link to={`/admin/warehouse/reports/add/${moduleType}`}>
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            color="primary"
          >
            Nueva Solicitud reportes
          </Button>
        </Link>
      );
    default:
      return null;
  }
};
