import React from 'react';
import {
    TextField as TextFieldMUI,
    InputAdornment,
} from '@material-ui/core';

export default function TextField(props) {

    const handleChange = (e) => {
        if (props.onlyNumber){
            const tempValue = e.target.value.replace( /^\D+/g, '')
            return props.onChange(tempValue);
        } else {
            return props.onChange(e.target.value)
        }
    }

    const inputprops = props.adornment ? {InputProps: {startAdornment: <InputAdornment position={props.adornment.position || "start"}>{props.adornment.label}</InputAdornment>}} : {};

    return (
        <TextFieldMUI
            {...props}
            {...inputprops}
            onChange={(e) => handleChange(e)}
        />
    );
}