import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
} from '@material-ui/core';
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
    green,
} from "@material-ui/core/colors";
import moment from "moment-timezone";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import allActions from "actions";
import api from "utils/API";
import "moment/locale/es";
moment.locale("es");


export default function ModalAddBenefit({
    open,
    viewDetail,
    handleClose,
    handleAddRow,
}) {
    const dispatch = useDispatch();
    const [type, settype] = useState({});
    const [code, setcode] = useState("");
    const [name, setname] = useState("");
    const [amount, setamount] = useState(0);
    const [value, setvalue] = useState(0);
    const [listTTO, setlistTTO] = useState([]);
    const [selectedTTO, setselectedTTO] = useState(null);

    const listType = useSelector(state => state.currentList.LISTA_TIPO_PRESTACION_FACTURACION);

    useEffect(() => {
        initialData();
    }, []);

    const initialData = () => {
        settype(listType[0] || {});
    };

    const handleGetBenefitHeader = (typeTTO) => {
        api.get(`api/prestacionesfacturacion/?facturacion=${viewDetail.id}&eliminado=0&header=True&tipo_prestacion=${typeTTO}`).then((response) => {
            setlistTTO(response.data.reduce((accum, obj) => {return [...accum, {value: obj.id, label: obj.prestacion_efectuada}]}, []))
        })
    };

    const handleOnChange = (value, set) => {
        let tempValue =  isNaN(value) ? 0 : value;
        set(tempValue);
    };

    const handleUpdate = () => {
        const row = {
            codigo_prestacion: code,
            prestacion_efectuada: name,
            cantidad_efectuada_inicial: amount,
            valor_unitario_inicial: value,
            total_prestacion_inicial: value*amount,
            cantidad_efectuada_modificado: amount,
            valor_unitario_modificado: value,
            total_prestacion_modificado: value*amount,
            tipo_prestacion: type.value,
            id_factura: viewDetail.id,
        }
        if (selectedTTO){
            row["id_prestacion_facturacion_padre"] = selectedTTO.value
        }
        handleAddRow(row);
        handleClose();
    };

    const handleSelectType = (selectType) => {
        settype(selectType);
        if ( ["InsumoTTO", "ServiciosTTO"].includes(selectType.value) ){
            handleGetBenefitHeader(selectType.value)
        } else {
            setselectedTTO(null)
        }
    }

    const _content_ = <div>
    <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
    >
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0"}}
        >
            <Grid
                item
                xs={4}
                style={{padding: "0 10px"}}
            >
                <DetailText
                    label={`Codigo Prestación`}
                    placeholder={`Codigo Prestación`}
                    value={code}
                    onchange={(e) => { setcode(e.target.value)}}
                />
            </Grid>
            <Grid
                item
                xs={4}
                style={{padding: "0 10px"}}
            >
                <DetailText
                    label={`Nombre Prestación`}
                    placeholder={`Nombre Prestación`}
                    value={name}
                    onchange={(e) => { setname(e.target.value)}}
                />
            </Grid>
            <Grid
                item
                xs={4}
                style={{padding: "0 10px"}}
            >
                <DetailSelect
                    label="Tipo Prestación:"
                    value={type}
                    onchange={(value) => handleSelectType(value)}
                    list={listType}
                />
            </Grid>
            <Grid
                item
                xs={4}
                style={{padding: "0 10px"}}
            >
                <DetailNumber
                    label={`Cantidad modificada`}
                    value={amount}
                    onchange={e => handleOnChange(
                        parseInt(e.target.value.split('$').join('').split(',').join('')),
                        setamount)
                    }
                    thousandSeparator={true}
                />
            </Grid>
            <Grid
                item
                xs={4}
                style={{padding: "0 10px"}}
            >
                <DetailNumber
                    label={`Valor modificada`}
                    value={value}
                    onchange={e => handleOnChange(
                        parseInt(e.target.value.split('$').join('').split(',').join('')),
                        setvalue)
                    }
                    prefix={"$"}
                    thousandSeparator={true}
                />
            </Grid>
            <Grid
                item
                xs={4}
                style={{padding: "0 10px"}}
            >
                {listTTO.length > 0 &&
                <DetailSelect
                    label="Ingresar TTO correspondiente:"
                    value={selectedTTO}
                    onchange={(value) => setselectedTTO(value)}
                    list={listTTO}
                />
                }
            </Grid>
        </Grid>
    </Grid>
</div>

    const _actions_ = <>
            {(amount && value && type.value && code && name && ((listTTO.length > 0 && selectedTTO) || listTTO.length == 0 ) ) ?
            <Button
                mr={2}
                variant="contained"
                className="text-white"
                style={{backgroundColor: green[500]}}
                onClick={handleUpdate}
            >
                Guardar
            </Button> :
            <Button
                disabled
                mr={2}
                variant="contained"
                className="text-white"
            >
                Guardar
            </Button>
            }
            <Button
                mr={2}
                variant="contained"
                className="text-white"
                color="primary"
                onClick={handleClose}
            >
                Cerrar
            </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Agregar registro`}
            _content_={_content_}
            _actions_ = {_actions_}
        />


    );
}