import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ModalHomeAuthorization from "./ModalHomeAuthorization"
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {getListRequestPatient} from 'actions/getListAction';
import {setmessageGeneral} from "actions/getGlobalAction";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

// eslint-disable-next-line react/prop-types

export default function HomeAuthorization({
  resumeQuotation,
  dataPatient,
  viewQuotation,
  forecast,
  setresumeQuotation,
  handleReloadFile,
  handleBack,
  dataFile,
  setdataFile,
}) {
  const dispatch = useDispatch()
  const [comment, setcomment] = useState("");
  const [openModal, setopenModal] = useState(false);
  const [dateRequest, setdateRequest] = useState(moment().format("YYYY-MM-DD"));
  const [showPackage, setshowPackage] = useState(false);

  const dictPosition = useSelector(state => state.currentList.dictCharges);

  const packageData = showPackage ? resumeQuotation.packageUnbalanced : resumeQuotation.packageBalanced;

  const Medical = resumeQuotation.packageBalanced.prestaciones.filter((item) => item.id_insumo_medicamento?.grupo_producto === "Medicamentos");
  const Resourse = resumeQuotation.packageBalanced.prestaciones.filter((item) => ["Insumos", "Articulos", "Alimentos"].includes(item.id_insumo_medicamento?.grupo_producto));
  const Emergency = packageData.prestaciones.filter((item) => item.id_prestaciones?.grupo === "Emergencia");
  const Other = packageData.prestaciones.filter((item) => ["Traslados", "Otros"].includes(item.id_prestaciones?.grupo));
  const Equip = packageData.prestaciones.filter((item) => item.id_equipo?.tipo_producto === "EquiposMedicos");
  const Professional = packageData.prestaciones.filter((item) => item.id_prestaciones?.grupo === "Profesionales");

  const handleUpload = () => {
    api.post(`api/clinica/solicitudes/pacientes/habilitacion/cotizacionapaquete/${viewQuotation.requestPatientInfo.id}`).then((response) => {
      setopenModal(true);
    });

  };

  const handleUploadPatienPackage = () => {
    setopenModal(false);
    const hours = parseInt(moment().tz('America/Santiago').format('z'))*-1;
    const auxDataRequest = moment(dateRequest).format("YYYY-MM-DD")
    const bodyRequest = {
      fecha_hora_habilitacion: auxDataRequest,
      fecha_fin: moment(auxDataRequest).add(hours, "hours").format("YYYY-MM-DDTHH:MM:SS"),
      comentarios: comment,
      id_paquete_cotizacion: resumeQuotation.packageBalanced.id,
    };

    const requestAuthorization = api.post(`api/clinica/solicitudes/${viewQuotation.requestPatientInfo.id}/pacientes/${dataPatient.id}/habilitacion/solicitar/`, bodyRequest);
    Promise.all([
      requestAuthorization
    ]).then((request) => {
      setmessageGeneral(dispatch, "Habilitación finalizada", "succes", "¡Exito!");
      const tempFile = {...dataFile}
      tempFile.estado_ficha = "Habilitado"
      setdataFile({...tempFile})
      getListRequestPatient(dataPatient, dispatch)
      handleReloadFile();
      handleBack();
    });
  };

  const transient = packageData.tipo_permanencia == "Transitorio"

  return (
    <Card>
      <CardBody>
        <GridContainer
          direction="row"
          justify="center"
          alignItems="center"
          item xs={12}
        >
          <Typography variant="body2" gutterBottom>
            {`Se solicitará la habilitación del paciente ${dataPatient.nombre} ${dataPatient.apellido_paterno} de la Isapre ${forecast.label} sujeto a:`}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {`sujeto al convenio: ${resumeQuotation.packageBalanced.id_paquetizacion.nombre}, paquete ${resumeQuotation.packageBalanced.zona} ${resumeQuotation.packageBalanced.complejidad}.`}
          </Typography>
          {resumeQuotation.packageUnBalanced &&
          <Typography variant="body2" gutterBottom>
            {`sujeto al convenio (SOS): ${resumeQuotation.packageUnBalanced.id_paquetizacion.nombre}, paquete ${resumeQuotation.packageUnBalanced.zona} ${resumeQuotation.packageUnBalanced.complejidad}.`}
          </Typography>
          }
        </GridContainer>
        {resumeQuotation.packageUnbalanced &&
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          item
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                  checked={showPackage}
                  onChange={() => setshowPackage(!showPackage)}
              />
            }
            label={`Paquete con descompensación ${packageData?.descompensacion ? "activado" : "desactivado"}`}
          />
        </Grid>
        }
        <Grid item xs={12} style={{margin: "30px 0 0 0"}}>
          <Table className="table table-striped">
            <TableHead>
              <TableRow>
                <TableCell className="text-center align-middle">Grupo </TableCell>
                <TableCell className="text-center align-middle">Item </TableCell>
                <TableCell className="text-center align-middle">Tipo cantidad </TableCell>
                <TableCell className="text-center align-middle">Cantidad </TableCell>
                <TableCell className="text-center align-middle">Cada/Duración </TableCell>
                <TableCell className="text-center align-middle">Tiempo </TableCell>
                <TableCell className="text-center align-middle">Area a la que solicitará </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Professional.map((row, index) => (
                <TableRow
                  key={`${index}-professionalRow`}
                >
                  { index===0 && <TableCell rowSpan={Professional.length} className="text-center align-middle">Profesionales </TableCell>}
                  <TableCell className="text-center align-middle">{`${row.id_prestaciones.nombre} ${transient ? row.horario_inhabil === true ?  "Inhabil" : row.horario_inhabil === false ? "Habil" : "" : ""}`} </TableCell>
                  <TableCell className="text-center align-middle">{row.id_prestaciones.tipo_cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{dictPosition[row.id_prestaciones.id_cargo]?.area} </TableCell>
                </TableRow>
              ))}
              {Medical.map((row, index) => (
                <TableRow
                  key={`${index}-medicalRow`}
                >
                  { index===0 && <TableCell rowSpan={Medical.length} className="text-center align-middle">Medicamentos </TableCell>}
                  <TableCell className="text-center align-middle">{row.id_insumo_medicamento.SKU} </TableCell>
                  <TableCell className="text-center align-middle">{row.id_insumo_medicamento.tipo_cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{"Operaciones - Farmacia"} </TableCell>
                </TableRow>
              ))}
              {Resourse.map((row, index) => (
                <TableRow
                  key={`${index}-resourseRow`}
                >
                  { index===0 && <TableCell rowSpan={Resourse.length} className="text-center align-middle">Insumos </TableCell>}
                  <TableCell className="text-center align-middle">{row.id_insumo_medicamento.SKU} </TableCell>
                  <TableCell className="text-center align-middle">{row.id_insumo_medicamento.tipo_cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{"Operaciones"} </TableCell>
                </TableRow>
              ))}
              {Equip.map((row, index) => (
                <TableRow
                  key={`${index}-equipRow`}
                >
                  { index===0 && <TableCell rowSpan={Equip.length} className="text-center align-middle">Equipamiento </TableCell>}
                  <TableCell className="text-center align-middle">{`(${row.id_equipo.SKU}) ${row.id_equipo.descripcion}`} </TableCell>
                  <TableCell className="text-center align-middle">{"Unidad"} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{"Operaciones"} </TableCell>
                </TableRow>
              ))}
              {Emergency.map((row, index) => (
                <TableRow
                  key={`${index}-technicalRow`}
                >
                  { index===0 && <TableCell rowSpan={Emergency.length} className="text-center align-middle">Emergencia </TableCell>}
                  <TableCell className="text-center align-middle">{row.id_prestaciones.nombre} </TableCell>
                  <TableCell className="text-center align-middle">{row.id_prestaciones.tipo_cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{"Operaciones"} </TableCell>
                </TableRow>
              ))}
               {Other.map((row, index) => (
                <TableRow
                  key={`${index}-technicalRow`}
                >
                  { index===0 && <TableCell rowSpan={Other.length} className="text-center align-middle">Emergencia </TableCell>}
                  <TableCell className="text-center align-middle">{row.id_prestaciones.nombre} </TableCell>
                  <TableCell className="text-center align-middle">{row.id_prestaciones.tipo_cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.cantidad} </TableCell>
                  <TableCell className="text-center align-middle">{row.frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{row.tipo_frecuencia} </TableCell>
                  <TableCell className="text-center align-middle">{"Otros"} </TableCell>
                </TableRow>
              ))}
              <TableRow >
                  <TableCell className="text-center align-middle">Coordinación cliente </TableCell>
                  <TableCell className="text-center align-middle">contrato cliente </TableCell>
                  <TableCell className="text-center align-middle">1 </TableCell>
                  <TableCell className="text-center align-middle"> </TableCell>
                  <TableCell className="text-center align-middle"> </TableCell>
                  <TableCell className="text-center align-middle"> </TableCell>
                  <TableCell className="text-center align-middle">Facturación y Cobranzas </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
          style={{ margin: "20px 0"}}
        >
          <TextField
              label={`Comentarios`}
              multiline
              value={comment}
              rows={4}
              onChange={(event) => setcomment(event.target.value)}
              variant="outlined"
              style={{width: "60%"}}
          />
          </Grid>
        <GridContainer>
          <GridItem xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "0 0 20px 0"}}
          >
            <Grid item xs={2} >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setresumeQuotation(null)}
              >
                volver
              </Button>
            </Grid>
            <Grid item xs={2} >
              <Button
                  variant="contained"
                  onClick={handleUpload}
                  color="primary"
                >
                  Solicitar
                </Button>
            </Grid>
          </GridItem>
        </GridContainer>
      </CardBody>
      {openModal &&
      <ModalHomeAuthorization
        open={openModal}
        handleClose={() => setopenModal(false)}
        dateRequest={dateRequest}
        setdateRequest={setdateRequest}
        handleUpload={handleUploadPatienPackage}
      />
      }
    </Card>
  );
}
