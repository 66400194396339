import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid,
    Typography,
    Button,
    TextField
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import TableComponent from "views/Widgets/Common/TableComponent";
import Icon from "views/Widgets/OrdeerDashboard/Icon";
import ModalShowRequest from "./ModalShowRequest";
import moment from "moment-timezone";
import "moment/locale/es";
import { grey, indigo, orange, green, red } from "@material-ui/core/colors";
import api from "utils/API";


moment.locale("es");

const DispatchPatientSearch = ({patientId}) => {
    const [value, setvalue] = useState("");
    const [idRequest, setidRequest] = useState(null);
    const [listItem, setlistItem] = useState([]);

    useEffect(() => {
        setvalue("")
        setidRequest(null)
        setlistItem([])
    }, [patientId]);

    const handleSelect = () => {
        const listFilter = value.split(" ")
        const wordFilter = listFilter.reduce((accum, obj) => {
            const tempObj= `descripcion_item=${obj}`
            if (accum === ""){
                return `?${tempObj}`
            } else {
                return `${accum}&${tempObj}`
            }
        }, "")
        api.get(`/api/clinica/pedidosprogramados/productospedido/paciente/${patientId}/${wordFilter}`).then((response) => {
            setlistItem(response.data)

        })
    }

    const handleShowRequest = (id) => {
        setidRequest(id)
    }

    const headerDetail = [
        { eval: true, label: 'Solicitud'},
        { eval: true, label: 'Fecha' },
        { eval: true, label: 'SKU' },
        { eval: true, label: 'Nombre producto' },
        { eval: true, label: 'Cantidad enviada/pedida' },
        { eval: true, label: "Reemplazo",  },
        { eval: true, label: "Acción",  },
    ]

    const dataDetail = listItem.map((row) => {

        let calculate = parseInt((row.cantidad-row.cantidad_por_enviar) * 100 / row.cantidad)
        let color = red[500]
        if (calculate > 49 & calculate < 100) {
            color = orange[500]
        }
        else if (calculate > 99) {
            color = green[500]
        }
        const _amountItem_ = <Typography><p style={{ color: color, textAlignLast: "center" }}>{row.cantidad - row.cantidad_por_enviar} / {row.cantidad} -  {calculate}%</p></Typography>

        const _button_ = <Button
            variant="contained" color="primary"
            style={{background: indigo[500]}}
            onClick={() => handleShowRequest(row.id_solicitud_pedido_id)}
        >
            Ver solicitud
        </Button>

        return {
            data: [
                {value: row.id_solicitud_pedido_id},
                {value: moment(row.id_solicitud_pedido__fecha_despacho).format("YYYY-MM-DD")},
                {value: row.id_insumo_medicamento__SKU},
                {value: row.id_insumo_medicamento__descripcion_producto},
                {value: row.cantidad, _value_:_amountItem_},
                {value: row.id_producto_reemplazo__id_insumo_medicamento__SKU || "Sin remplazo"},
                {eval: false, _value_: _button_},
               
            ]
        }
    })
    

    return (
        <Grid item container xs={12} style={{ marginTop: "2em", backgroundColor: "#fff", borderRadius: "5px" }}>
            <Grid item container xs={12} style={{ padding: "0 2% 0 2%" }}>
                <Grid item container xs={3}  direction="row" alignContent="flex-start">
                    <Grid item container xs={12} style={{margin: "0 0 20px 0"}}>
                        <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}>
                            Busqueda de pedidos por Item
                        </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Typography gutterBottom style={{ textTransform: "none", margin: "0 0 32px 0" }}>
                            Permite realizar busquedas de pedidos en bodega por la descripción y/o nombre de algun item
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={1}>
                </Grid>
                <Grid item container xs={8}>
                    <Grid item container xs={12}>
                        <Grid item container xs={8} alignItems="center">
                            <TextField 
                                defaultValue="" 
                                value={value} 
                                helperText="busca por descripción" 
                                style={{width: "100%"}}
                                onChange={(e) => setvalue(e.target.value)} 
                            />
                        </Grid>
                        <Grid item container xs={1} alignItems="center" justify="center">
                            <Icon icon={<SearchIcon />} action={handleSelect} color={grey[50]} />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <TableComponent
                            headers={headerDetail}
                            data={dataDetail}
                            size={'small'}
                            pageSize={5}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {idRequest && 
                <ModalShowRequest
                open={idRequest != null}
                closeModal={() => setidRequest(null)}
                idRequest={idRequest}
                />
            }
        </Grid>
    )
}

export default DispatchPatientSearch;
