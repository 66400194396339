import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {Button} from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailNumber from "views/Widgets/FichaPersonal/EditFichaPersonal/detailNumber";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import ModalDialog from "views/Widgets/Common/ModalDialog";


export default function ModalCreatePackageBenefit(props) {
  const { open, closeModal, id_paquete,
    setFirstCharge,
  } = props;
  const [grupo, setGrupo] = useState("");
  const [nombre, setNombre] = useState("");
  const [frecuencia, setFrecuencia] = useState(0);
  const [tipo_frecuencia, setTipo_frecuencia] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [tipo_cantidad, setTipo_cantidad] = useState("");
  const [estado, setEstado] = useState("");
  let list_valida = [
    { value: false, label: "Inactiva" },
    { value: true, label: "Activa" },
  ];
  const [value, setValue] = useState(grupo.value);


  const [textoLibre, setTextoLibre] = useState([]);
  const [idPrestaciones, setIdPrestaciones] = useState("");

  const [flag, setFlag] = useState(false);
  const [listPrestacionEquipamiento, setlistPrestacionEquipamiento] = useState(
    []
  );
  const [
    listPrestacionProfesionales,
    setlistPrestacionProfesionales,
  ] = useState([]);
  const [listPrestacionEmergencias, setlistPrestacionEmergencias] = useState(
    []
  );
  const [listPrestacionTraslados, setlistPrestacionTraslados] = useState([]);
  const [
    listPrestacionBolsoEmergencia,
    setlistPrestacionBolsoEmergencia,
  ] = useState([]);
  const [listPrestacionOtros, setlistPrestacionOtros] = useState([]);
  const [dictPrestaciones, setdictPrestaciones] = useState({});

  const list_frecuencias = useSelector(state => state.currentList.LISTA_TIPO_FRECUENCIA_PRESTACION);
  const list_grupos = useSelector(state => state.currentList.LISTA_GRUPOS_PRESTACION);

  useEffect(() => {
    const templistPrestacionEquipamiento = [];
    const templistPrestacionEmergencias = [];
    const templistPrestacionProfesionales = [];
    const templistPrestacionTraslados = [];
    const templistPrestacionBolsoEmergencia = [];
    const templistPrestacionOtros = [];

    const requestEquipoType = api.get("api/equipos/tipos/");
    const requestBenefit = api.get("api/prestacionesclinica/");

    Promise.all([requestEquipoType, requestBenefit]).then((request) => {

      request[0].data.map((row) => {
        templistPrestacionEquipamiento.push({
          value: row.id,
          label: `(${row.SKU}) ${row.descripcion}`,
        });
      });

      setdictPrestaciones(request[1].data.reduce((accum, obj) => ({
        ...accum,
        [obj.id]: obj
      }), {}));
      request[1].data.map((row) => {
        if (row.grupo === "Profesionales") {
          templistPrestacionProfesionales.push({
            value: row.id,
            label: row.nombre,
          });
        }
        if (row.grupo === "Emergencias") {
          templistPrestacionEmergencias.push({
            value: row.id,
            label: row.nombre,
          });
        }
        if (row.grupo === "Traslados") {
          templistPrestacionTraslados.push({
            value: row.id,
            label: row.nombre,
          });
        }
        if (row.grupo === "BolsoEmergencia") {
          templistPrestacionBolsoEmergencia.push({
            value: row.id,
            label: row.nombre,
          });
        }
        if (row.grupo === "Otros") {
          templistPrestacionOtros.push({
            value: row.id,
            label: row.nombre,
          });
        }
      });
      setlistPrestacionEmergencias(templistPrestacionEmergencias);
      setlistPrestacionProfesionales(templistPrestacionProfesionales);
      setlistPrestacionTraslados(templistPrestacionTraslados);
      setlistPrestacionBolsoEmergencia(templistPrestacionBolsoEmergencia);
      setlistPrestacionOtros(templistPrestacionOtros);
      setlistPrestacionEquipamiento(templistPrestacionEquipamiento);
    });
  }, []);

  list_valida.map((list) => {
    if (estado === list.value) {
      setEstado({ value: list.value, label: list.label });
    }
  });

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function createBenefit() {
    const formData = new FormData();

    if (tipo_frecuencia) {
      formData.append("tipo_frecuencia", tipo_frecuencia.value);
    }
    if (tipo_frecuencia.value === "Permanente") {
      formData.append("frecuencia", 0);
    } else {
      formData.append("frecuencia", frecuencia);
    }
    formData.append("otros_datos", textoLibre);
    if (estado) {
      formData.append("estado", estado.value);
    }
    formData.append("id_paquete", id_paquete);
    formData.append("cantidad", cantidad);

    if (grupo.value === "Equipamiento") {
      formData.append("id_equipo", nombre.value);
    } else {
      formData.append("id_prestaciones", idPrestaciones);
    }
    apiform
      .post("api/prestacionespaquete/", formData)
      .then((response) => {
        setFirstCharge(true);
        closeModal();
      })
  }

  const handleGrupo = (grupo) => {
    setGrupo(grupo);
    setFlag(true);
  };

  const handleNombre = (nombre) => {
    if (dictPrestaciones[nombre.value]){
      setTipo_cantidad(dictPrestaciones[nombre.value].tipo_cantidad)
      setIdPrestaciones(nombre.value)
    }
    setNombre(nombre);
    setFlag(true);
  };

  const handleTipoFrecuencia = (tipo_frecuencia) => {
    setTipo_frecuencia(tipo_frecuencia);
  };

  const handleEstado = (estado) => {
    setEstado(estado);
  };

  useEffect(() => {
    if (value != grupo.value) {
      setValue(grupo.value);
      setNombre("");
    }
  });

  useEffect(() => {
    if (flag) {
      setFlag(false);
      if (grupo.value === "Equipamiento") {
        setTipo_cantidad("Unidad");
        setIdPrestaciones(nombre.id);
      }
    }
  });

  const _content_ = <div className="form-row">
  <DetailSelect
    label="Grupo:"
    value={grupo}
    onchange={handleGrupo}
    list={list_grupos}
  />

  {grupo.value === "Profesionales" ? (
    <DetailSelect
      label="Prestación:"
      value={nombre}
      onchange={handleNombre}
      list={listPrestacionProfesionales}
    />
  ) : grupo.value === "Equipamiento" ? (
    <DetailSelect
      label="Prestación:"
      value={nombre}
      onchange={handleNombre}
      list={listPrestacionEquipamiento}
    />
  ) : grupo.value === "Emergencias" ? (
    <DetailSelect
      label="Prestación:"
      value={nombre}
      onchange={handleNombre}
      list={listPrestacionEmergencias}
    />
  ) : grupo.value === "Traslados" ? (
    <DetailSelect
      label="Prestación:"
      value={nombre}
      onchange={handleNombre}
      list={listPrestacionTraslados}
    />
  ) : grupo.value === "BolsoEmergencia" ? (
    <DetailSelect
      label="Prestación:"
      value={nombre}
      onchange={handleNombre}
      list={listPrestacionBolsoEmergencia}
    />
  ) : grupo.value === "Otros" ? (
    <DetailSelect
      label="Prestación:"
      value={nombre}
      onchange={handleNombre}
      list={listPrestacionOtros}
    />
  ) : null}
  <DetailTextDisabled
    label="Tipo cantidad:"
    value={tipo_cantidad}
  />
  <DetailNumber
    label="Cantidad:"
    value={cantidad}
    onchange={(e) => {
      if (/^[0-9\b]+$/.test(e.target.value)) {
        setCantidad(e.target.value);
      }
    }}
  />
  {tipo_frecuencia.value != "Permanente" ? (
    <DetailNumber
      label={grupo.value === "Equipamiento" ? "Duración:" : "Cada:"}
      value={frecuencia}
      onchange={(e) => {
        if (/^[0-9\b]+$/.test(e.target.value)) {
          setFrecuencia(e.target.value);
        }
      }}
    />
  ) : null}
  <DetailSelect
    label="Tiempo:"
    value={tipo_frecuencia}
    onchange={handleTipoFrecuencia}
    list={list_frecuencias}
  />
  <DetailSelect
    label="Estado:"
    value={estado}
    onchange={handleEstado}
    list={list_valida}
  />

  {grupo.value === "Otros" ? (
    <DetailTextArea
      label="Texto libre:"
      value={textoLibre}
      onchange={(e) => setTextoLibre(e.target.value)}
    />
  ) : null}

</div>

const _actions_ = <>
  <Button
    onClick={closeModal}
    color="primary"
    variant="contained"
  >
    Cancelar
  </Button>
  <Button
    onClick={createBenefit}
    color="primary"
    variant="contained"
  >
    Crear
  </Button>
</>

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={"Agregar prestación"}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}

ModalCreatePackageBenefit.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_paquete: PropTypes.number,
};
