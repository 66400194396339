import React from 'react';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmacionSolicitudEquipo = (props) => {
    const {
        openAlert,
        closeAlert,
        handleConfirmRequest,
    } = props;
  return (
    <div>
     
      <Dialog
        open={openAlert}
        onClose={closeAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¡Atencion!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            La solicitud será notificada. ¿Desea aceptar? 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmRequest} color="primary">
            Sí
          </Button>
          <Button onClick={closeAlert} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmacionSolicitudEquipo;