import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";

import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function ModalAdd({
  open,
  handleClose,
  table,
  listItem,
  dataArray,
  handleAddItem,
}) {
  const [value, setvalue] = useState({});

  const handleOnChange = (value, set) => {
    if(value.value !== null){
      set(value);
    }
  };

  const updateValue = () => {
    handleAddItem(value, table);
    handleClose();
  };

  const chargeList = [{value: null, label: "Cargando..."}]

  const auxDataId = dataArray.filter((row) => row.fuera_paquete === table ).reduce((accum, obj) => ([
    ...accum,
    obj.id_equipo?.id || obj.id_insumo_medicamento?.id || obj.id_prestaciones.id
  ]), []);

  const tempList = listItem.filter(row => !auxDataId.includes(row.value))

  const _content_ = <Grid
    container
    direction="row"
    justify="flex-start"
    alignItems="flex-end"
    item
    xs={12}
  >
    <Grid item xs={12} style={{height: "380px"}}>
      <DetailSelect
        label="Buscar"
        value={value}
        onchange={(value) => { handleOnChange(value, setvalue)}}
        list={tempList.length > 0 ? tempList : chargeList}
      />
    </Grid>
  </Grid>

  const _actions_ = <>
    <Button
      color="primary"
      variant="contained"
      onClick={handleClose}
    >
      Cerrar
    </Button>
    <Button
      disabled={!value}
      color="primary"
      variant="contained"
      onClick={updateValue}
    >
      Agregar
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={`Agregar Item test`}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}
