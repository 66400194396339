import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { green } from '@material-ui/core/colors';
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import moment from "moment-timezone";
import "moment/locale/es";
import api from "utils/API";
import apiform from "utils/APIForm";
import PublishIcon from '@material-ui/icons/Publish';
import AddIcon from '@material-ui/icons/Add';
import { Container } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';

moment.locale("es");

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 230
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));
Bienestar.propTypes = {
    idFicha: PropTypes.string,
    startDate: PropTypes.object,
    handleStartDate: PropTypes.func,
};

export default function Bienestar (props) {

    const classes = useStyles();
    const {
        startDate,
        montoBienestar,
        handleMontoBienestar,
        handleFechaBienestar,
        onFileChange,
        files,
        setUpdateModal,
        setFiles
    } = props;
    const eliminarDocumentoTemporal = ( filename ) => {
        let tempFiles = files;
        const nombre_archivo=filename
        tempFiles.find(function(archivo, index) {
            if(archivo!=undefined){
            if (archivo['name'] == nombre_archivo) {
                tempFiles.splice(index, 1);
            }
            }
        });
        setFiles(tempFiles);
        setUpdateModal(true)
    };
    return (
        <Container>
            <Grid container direction="row" justify="center" alignItems="flex-end" style={{ marginTop: "5px" }}>
                <Grid item container xs={3}>
                    <Typography variant="subtitle1" gutterBottom style={{ textTransform: "none" }} >
                        Fecha Registro Bienestar:
                    </Typography>
                </Grid>
                <Grid item container xs={2} spacing={1}>
                    <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <Grid container>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="DD-MM-YYYY"
                            margin="normal"
                            id="date-picker-inline1"
                            label="Fecha de inicio"
                            value={startDate}
                            onChange={handleFechaBienestar}
                            KeyboardButtonProps={{
                            "aria-label": "change date"
                            }}
                        />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>

            <Grid container direction="row" justify="center" alignItems="baseline" style={{ marginTop: "5px" }}>
                <Grid item container xs={3}>
                    <Typography variant="subtitle1" gutterBottom style={{ textTransform: "none" }} >
                        Monto Bienestar:
                    </Typography>
                </Grid>
                <Grid item container xs={2} spacing={1} >
                    <NumberFormat
                        placeholder="Monto Bienestar"
                        getInputRef={montoBienestar}
                        value={montoBienestar}
                        onChange={event=>handleMontoBienestar(event)}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        isNumericString={true}
                        customInput={TextField}
                        prefix={"$"}
                    />
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="baseline" style={{ marginTop: "5px" }}>
                <Grid item container xs={3}>
                    <div style={{ textTransform: "none",verticalAlign: 'middle', display:'flex',position: 'relative'}}>

                        <input
                            accept=".pdf"
                            hidden
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={onFileChange}
                        />

                        <label htmlFor="contained-button-file">
                            <Fab size="small" color="primary"  component="span" aria-label="add" style={{ bottom: '3px'}}>
                                <AddIcon />
                            </Fab>
                        </label>

                        <Typography variant="h6" gutterBottom style={{ textTransform: "none",paddingLeft:'5px' }} >
                                Documentos :
                        </Typography>
                    </div>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="baseline" spacing={5} style={{ marginTop: "5px" }}>
                    {files.map((file,i) =>
                        <Grid item container sm={2} id={i}>
                            <div style={{ horizontalAlign: 'center',textAlign: 'center',textOverflow: 'ellipsis',direction: 'rtl', width: '100%'}}>
                                <DescriptionOutlinedIcon type="button" color="primary" style={{ fontSize: 50 }}/>
                                <Typography  variant="body2" noWrap  style={{textOverflow: 'ellipsis',direction: 'rtl'}} >
                                        {file['name']}
                                </Typography>
                                <HighlightOffIcon type="button" onClick={() => eliminarDocumentoTemporal(file['name'])} color="secondary" fontSize="small"/>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Container>
  );
};



