import React, { useState, useEffect, useRef } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { useReactToPrint } from "react-to-print";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import {
  Modal,
  Backdrop,
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import DetailDate from "views/Widgets/FichaPaciente/detailDate";
import { red } from "@material-ui/core/colors";
import Logo from "../../layouts/Logo.jpeg";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
}));

class ComponentToPrint extends React.Component {
  render() {
    const {
      auxRequestArray,
      dictTypeWork,
      startDate,
      endDate,
      userData,
    } = this.props;
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          item
          xs={12}
          style={{ margin: "10px 0", padding: "10px" }}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            item
            xs={3}
          >
            <img alt="Qries" src={Logo} width="150" />
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            item
            xs={9}
            style={{ padding: "5px" }}
          >
            <Paper style={{ width: "100%", padding: "5px 15px" }}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={12}
              >
                <Typography variant="body2" gutterBottom>
                  {`${userData.first_name} ${userData.last_name}`}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`Cargo: ${userData.cargo}`}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`Inicio de filtro: ${moment(startDate).format(
                    "DD-MM-YYYY"
                  )}`}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`Fin de filtro: ${moment(endDate).format("DD-MM-YYYY")}`}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`Fecha de descarga: ${moment().format("DD-MM-YYYY HH:mm")}`}
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <SnackbarContent
          message={`Las tareas descargadas se encuentran actualizadas hasta la fecha: ${moment().format(
            "DD-MM-YYYY HH:mm:ss"
          )}`}
          color="warning"
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          item
          xs={12}
          style={{ margin: "10px 0", padding: "10px" }}
        >
          <Table className="table table-striped">
            <TableHead>
              <TableRow>
                <TableCell className="text-center align-top">
                  {" "}
                  ID 
                </TableCell>
                <TableCell className="text-center align-top">
                  {" "}
                  Tipo de Tarea 
                </TableCell>
                <TableCell className="text-center align-top">
                  {" "}
                  Paciente 
                </TableCell>
                <TableCell className="text-center align-top">
                  {" "}
                  Region 
                </TableCell>
                <TableCell className="text-center align-top">
                  {" "}
                  Comuna 
                </TableCell>
                <TableCell className="text-center align-top">
                  {" "}
                  Dirección 
                </TableCell>
                <TableCell className="text-center align-top">
                  {" "}
                  Fecha de inicio 
                </TableCell>
                <TableCell className="text-center align-top">
                  {" "}
                  Fecha de término 
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auxRequestArray.map((row, index) => (
                <TableRow key={`${index}-workRows`}>
                  <TableCell className="text-center align-middle">
                    {row.id}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {dictTypeWork[row.tipo_tarea][1]}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.id_ficha.id_paciente
                      ? `${row.id_ficha.id_paciente.nombre} ${row.id_ficha.id_paciente.apellido_paterno} ${row.id_ficha.id_paciente.apellido_materno}`
                      : `No disponible`}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.id_ficha.id_paciente
                      ? `${row.id_ficha.id_paciente.direccion_region}`
                      : `No disponible`}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.id_ficha.id_paciente
                      ? `${row.id_ficha.id_paciente.direccion_comuna}`
                      : `No disponible`}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.id_ficha.id_paciente
                      ? `${row.id_ficha.id_paciente.direccion_calle} ${row.id_ficha.id_paciente.direccion_numero} ${row.id_ficha.id_paciente.direccion_casa_depto} ${row.id_ficha.id_paciente.direccion_block}`
                      : `No disponible`}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {moment(row.fecha_hora).format("YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.fecha_final
                      ? moment(row.fecha_final).format("YYYY-MM-DD HH:mm")
                      : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </div>
    );
  }
}

export default function ModalRoute({
  open,
  handleClose,
  requestArray,
  userData,
}) {
  const classes = useStyles();
  const [startDate, setstartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setendDate] = useState(moment().format("YYYY-MM-DD"));
  const [changeFilter, setchangeFilter] = useState(true);
  const [auxRequestArray, setauxRequestArray] = useState(requestArray);
  const componentRef = useRef();

  const dictTypeWork = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA);

  useEffect(() => {
    if (changeFilter) {
      updateFilter();
    }
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const updateFilter = () => {
    setchangeFilter(false);
    const tempEndDate = moment(endDate).add(1, "days");
    setauxRequestArray(
      requestArray
        .filter(
          (row) =>
            moment(row.fecha_hora).diff(moment(startDate), "minutes") >= 0 &&
            moment(tempEndDate).diff(moment(row.fecha_hora), "minutes") >= 0
        )
        .sort((a, b) => {
          if (moment(a.fecha_hora).diff(moment(b.fecha_hora), "minutes") > 0) {
            return 1;
          } else if (
            moment(a.fecha_hora).diff(moment(b.fecha_hora), "minutes") < 0
          ) {
            return -1;
          }
          return 0;
        })
    );
  };

  const handleDate = (value, set) => {
    set(moment(value).format("YYYY-MM-DD"));
    setchangeFilter(true);
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      style={{
        borderRadius: "4px",
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.paper}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          item
          xs={12}
        >
          <h3 id="spring-modal-title">{`Determina el periodo de tiempo a descargar`}</h3>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item xs={4}>
              <DetailDate
                label={"Fecha Inicio"}
                placeholder="Fecha Inicio"
                handleOnChange={(value) => handleDate(value, setstartDate)}
                value={startDate}
                timeFormat={false}
              />
            </Grid>
            <Grid item xs={4}>
              <DetailDate
                label={"Fecha fin"}
                placeholder="Fecha fin"
                handleOnChange={(value) => handleDate(value, setendDate)}
                value={endDate}
                timeFormat={false}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer style={{ maxHeight: "340px" }}>
              <ComponentToPrint
                ref={componentRef}
                auxRequestArray={auxRequestArray}
                dictTypeWork={dictTypeWork}
                startDate={startDate}
                endDate={endDate}
                userData={userData}
              />
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button variant="contained" color="primary" onClick={handlePrint}>
              Descargar
            </Button>
            <Button
              variant="contained"
              style={{
                margin: "0 0 0 10px",
                background: red[500],
                color: red[50],
              }}
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
