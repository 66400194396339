import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
    TextField,
} from '@material-ui/core';
import {ButtonTextMUI as Button, CustomDatePicker, CustomSelectAutoComplete} from "utils/componentsStyle";
import DetailImageAvatar from "views/Widgets/FichaPaciente/detailImageAvatar";
import {red, green} from "@material-ui/core/colors";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import { checkRut } from "../../utils/validationFunction";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function FormPersonalData({
    data,
    setdata,
    setmessage,
}) {
    const [nombre, setnombre] = useState(data.nombre);
    const [apellido_paterno, setapellido_paterno] = useState(data.apellido_paterno);
    const [apellido_materno, setapellido_materno] = useState(data.apellido_materno);
    const [rut, setrut] = useState(data.rut);
    const [rutError, setrutError] = useState("");
    const [nacimiento, setnacimiento] = useState(data.nacimiento);
    const [sexo, setsexo] = useState(data.sexo);
    const [direccion_calle, setdireccion_calle] = useState(data.direccion_calle);
    const [direccion_numero, setdireccion_numero] = useState(data.direccion_numero);
    const [direccion_block, setdireccion_block] = useState(data.direccion_block);
    const [direccion_casa_depto, setdireccion_casa_depto] = useState(data.direccion_casa_depto);
    const [direccion_ciudad, setdireccion_ciudad] = useState(data.direccion_ciudad);
    const [direccion_comuna, setdireccion_comuna] = useState({});
    const [direccion_region, setdireccion_region] = useState({});
    const [id_zona, setzona] = useState(data.id_zona)
    const [listComuna, setlistComuna] = useState([]);
    const [listRegion, setlistRegion] = useState([]);
    const [dictComuna, setdictComuna] = useState({});
    const [dictRegion, setdictRegion] = useState({})
    const [initialCharge, setinitialCharge] = useState(true);
    const [validation, setvalidation] = useState(false);
    const [foto_paciente, setfoto_paciente] = useState();
    const [loading, setloading] = useState(false);


    const type_user = localStorage.getItem("tipo_perfil");

    const listSex = useSelector(state => state.currentList.LISTA_SEXO);
    const dictSex = useSelector(state => state.currentList.DICCIONARIO_SEXO);

    useEffect(() => {
        setsexo(dictSex[sexo])
    }, []);

    useEffect(() => {
        validateForm();
        if (initialCharge){
            initialData();
        }
    });

    const initialData = () => {
        setinitialCharge(false);
        setloading(false);
        api.get(`api/locaciones/regiones/`).then((request) => {
            const tempdictLabelregion = {}
            setlistRegion(request.data.map((row) => {
                tempdictLabelregion[row.id] = {value: row.id , label: row.nombre}
                return {value: row.id , label: row.nombre}
            }));

            setdictRegion(tempdictLabelregion)
            setdireccion_region(tempdictLabelregion[data.id_region])

            if (data.id_region){
                api.get(`api/locaciones/regiones/${data.id_region}/comunas/`).then((response) => {
                    const tempDictComuna = {}
                    setlistComuna(response.data.map((row) => {
                        tempDictComuna[row.id] = {value: row.id, label: row.nombre, id_zona: row.id_zona};
                        return {value: row.id, label: row.nombre};
                    }));
                    setdictComuna(tempDictComuna);
                    setdireccion_comuna(tempDictComuna[data.id_comuna])
                    setloading(true);
                });
            } else {
                setloading(true);
            }

        });
    };

    const validateForm = () => {
        const arrayValidation = [
            nombre !== "",
            apellido_paterno !== "",
            apellido_materno !== "",
            rut != "",
            rutError === "",
            nacimiento !== "",
            !!sexo,
            direccion_calle !== "",
            direccion_numero !== "",
            direccion_block !== "",
            direccion_casa_depto !== "",
            direccion_ciudad !== "",
            !!direccion_comuna,
            !!direccion_region,
        ];
        if (arrayValidation.every((item) => item === true)) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    };

    const updateValue = () => {
        const body = {...data}
        body.nombre = nombre;
        body.apellido_paterno = apellido_paterno;
        body.apellido_materno = apellido_materno;
        body.rut = rut;
        body.nacimiento = nacimiento;
        body.sexo = sexo.value;
        body.direccion_calle = direccion_calle;
        body.direccion_numero = direccion_numero;
        body.direccion_block = direccion_block;
        body.direccion_casa_depto = direccion_casa_depto;
        body.direccion_ciudad = direccion_ciudad;
        body.direccion_comuna = direccion_comuna?.label || "";
        body.direccion_region = direccion_region?.label || "";
        body.id_comuna = direccion_comuna?.value || null;
        body.id_region = direccion_region?.value || null;
        body.id_zona = id_zona;
        delete body["foto_paciente"];
        let formData = new FormData();
        formData.append("foto_paciente", foto_paciente);
        setdata(body);
        setmessage("Actualizando datos");
        const requestArray = []
        requestArray.push(api.patch(`api/paciente/${data.id}`, body))
        if (foto_paciente){
            requestArray.push(api.patch(`api/paciente/${data.id}`, formData));
        }
        Promise.all(requestArray).then((request) => {
            if (foto_paciente){
                body.foto_paciente = request[1].data.foto_paciente;
            }
            localStorage.setItem("patientFile",JSON.stringify(request[0].data))
            setdata(body);
            setmessage("Datos actualizados");
        });
    };

    const handleRegion = (value) => {
        if (value?.value){
            setdireccion_region(value);
            setdireccion_comuna(null);
            api.get(`api/locaciones/regiones/${value?.value}/comunas/`).then((response) => {
                const tempDictComuna = {}
                const tempList = response.data.map((row) => {
                    tempDictComuna[row.id] = {value: row.id, label: row.nombre, id_zona: row.id_zona};
                    return {value: row.id, label: row.nombre};
                })
                setdireccion_comuna(tempList[0].value);
                setlistComuna(tempList);
                setdictComuna(tempDictComuna)
            });
        } else if (!value?.value){
            setdireccion_region(null);
            handleComuna(null)
        }
    };

    const handleComuna = (value) => {
        setdireccion_comuna(value);
        const comuna = dictComuna[value?.value || value];
        setzona(comuna?.id_zona || null);
    };

    const handleOnChangeRut = (value, set) => {
        setrutError(checkRut(value))
        set(value);
    };

    const restart = () => {
        setnombre(data.nombre);
        setapellido_paterno(data.apellido_paterno);
        setapellido_materno(data.apellido_materno);
        setrut(data.rut);
        setnacimiento(data.nacimiento);
        setsexo(data.sexo);
        setdireccion_calle(data.direccion_calle);
        setdireccion_numero(data.direccion_numero);
        setdireccion_block(data.direccion_block);
        setdireccion_casa_depto(data.direccion_casa_depto);
        setdireccion_region(data.direccion_region);
    };

    const listForm = [
        {
            disabled: type_user == "CLITecEnfBas",
            label:"Nombres",
            value: nombre,
            onChange: (e) => setnombre(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label:"Apellido Paterno",
            value: apellido_paterno,
            onChange: (e) => setapellido_paterno(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label:"Apellido Materno",
            value: apellido_materno,
            onChange: (e) => setapellido_materno(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label:"RUT",
            placeholder:`Ej: 12345678-9`,
            value: rut,
            onChange: (e) => handleOnChangeRut(e.target.value, setrut),
            gridStyle: {padding: "15px"}
        },
        {
            _content_:  <CustomDatePicker
                disabled={type_user === "CLITecEnfBas"}
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                label={"Fecha de nacimiento"}
                placeholder="Fecha de nacimiento"
                onChange={(e) => setnacimiento(e.format("YYYY-MM-DD"))}
                value={nacimiento ? moment(nacimiento): null}
                inputVariant={"outlined"}
            />,
            gridStyle: {padding: "15px"}
        },
        {
            _content_: <CustomSelectAutoComplete
                disabled={type_user === "CLITecEnfBas"}
                value={sexo}
                onChange={(e) => setsexo(e)}
                label="Sexo"
                listItem={listSex}
            />,
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label: "Dirección calle",
            placeholder:`Dirección calle`,
            value: direccion_calle,
            onChange: (e) => setdireccion_calle(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label: "Dirección número",
            placeholder:`Dirección número`,
            value: direccion_numero,
            onChange: (e) => setdireccion_numero(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label: "Departamento/Casa",
            placeholder:`Departamento/Casa`,
            value: direccion_casa_depto,
            onChange: (e) => setdireccion_casa_depto(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label: "Block",
            placeholder:`Block`,
            value: direccion_block,
            onChange: (e) => setdireccion_block(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label: "Ciudad",
            placeholder:`Ciudad`,
            value: direccion_ciudad,
            onChange: (e) => setdireccion_ciudad(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label: "Ciudad",
            placeholder:`Ciudad`,
            value: direccion_ciudad,
            onChange: (e) => setdireccion_ciudad(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            _content_: <CustomSelectAutoComplete
                disabled={type_user === "CLITecEnfBas"}
                value={direccion_region}
                onChange={(e) => handleRegion(e)}
                label="Región"
                listItem={listRegion}
            />,
            gridStyle: {padding: "15px"}

        },
        {
            _content_: <CustomSelectAutoComplete
                disabled={type_user === "CLITecEnfBas"}
                value={direccion_comuna}
                onChange={(e) => handleComuna(e)}
                label="Comuna"
                listItem={listComuna}
            />,
            gridStyle: {padding: "15px"}
        },
    ]

    return (
        <Grid
            item
            xs={12}
        >
            {loading ?
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                item
                xs={12}
            >
                {listForm.map((row) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    style={row.gridStyle || {}}
                >
                    {row._content_ ||
                    <TextField
                        variant="outlined"
                        type="text"
                        error={row.value == ""}
                        helperText={row.value == "" ? "No puede ser vacio" : ""}
                        style={{ width: '100%' }}
                        {...row}

                    />}
                </Grid>
                ))}
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-end"
                    item
                    xs={12}
                >
                    <DetailImageAvatar
                        avatar
                        file={foto_paciente}
                        image={data.foto_paciente}
                        chargeAvatar={setfoto_paciente}
                        addButtonProps={{
                            color: "info",
                            round: true
                        }}
                        changeButtonProps={{
                            color: "info",
                            round: true
                        }}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                >
                    { (validation && type_user !== "CLITecEnfBas") ?
                    <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        style={{backgroundColor: green[500]}}
                        onClick={updateValue}
                    >
                        Guardar
                    </Button>
                    :
                    <Button
                        disabled
                        mr={2}
                        variant="contained"
                    >
                        Guardar
                    </Button>
                    }
                    {type_user === "CLITecEnfBas" ?
                    <Button
                        mr={2}
                        variant="contained"
                    >
                        Reiniciar
                    </Button> :
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={restart}
                    >
                        Reiniciar
                    </Button>
                    }
                </Grid>
            </Grid>
            :
            <LoadInfo/>
            }

        </Grid>
    );
}