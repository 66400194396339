import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import apiform from "utils/APIForm";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalAddEgresos = props => {
  const {
    openModal,
    closeModal,
    dataFile,
    setChanges,
    balanceEgresos,
    setBalanceEgresos,
    startDateBalance,
    endDateBalance,
    specialtyBalance,
  } = props;
  const [name, setName] = useState("");

  const addRecord = () => {
    const formData = new FormData();
    formData.append("nombre", name);
    formData.append("tipo_io", "Egresos");
    formData.append("id_ficha", dataFile.id);

    apiform
      .post("api/tipobalancehidrico/", formData)
      .then(response => {
        const tempBalanceEgresos = balanceEgresos;
        const data = response.data;
        const tempRow = {
          select: false,
          item: data.nombre,
          value: data.tipo_io,
          startDate: moment(startDateBalance).format(
              "YYYY-MM-DD[T]HH:mm:ss"
          ),
          endDate: moment(endDateBalance).format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: specialtyBalance
        };
        tempBalanceEgresos.push(tempRow);
        setBalanceEgresos(tempBalanceEgresos);
        setChanges(true);
        closeModal();
      })
      .catch(error =>
        console.log("ERROR post api/tipobalancehidrico/", error.response)
      );
  };

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      titleStyle={{ textAlign: "center" }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeModal}>
        Agregar Tipo de Ingreso
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} container justify="center">
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Nombre"
              variant="outlined"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "1em" }}>
        <Button variant="contained" color="primary" onClick={addRecord}>
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddEgresos;

ModalAddEgresos.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  dataFile: PropTypes.object,
  setChanges: PropTypes.func,
};
