import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import api from "utils/API";

const Orders = props => {
  const [order, setOrder] = useState([]);

  useEffect(() => {
    api.get(`api/solicitudcotizacioncompras/${props.id}`).then(result => {
      setOrder(result.data);
    });
  }, []);

  return (
    <div className="col-xl-8 mb-3">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col text-center">Datos de la compra</div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm text-left ml-5">
              <span className="font-weight-bold">Id: </span>
              {props.id}
              <br />
              <span className="font-weight-bold">Tipo: </span>
              {order.tipo_solicitud_cotizar}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Cantidad SKU: </span>
              {order.cantidad_productos}
              <br />
              <span className="font-weight-bold">Observación: </span>
              {order.observacion}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Fecha de solicitud: </span>
              {order.fecha_solicitud}
              <br />
              <span className="font-weight-bold">Estado: </span>
              {order.estado}
            </div>
          </div>
          <div className="row">
            <div className="col-sm text-center">
              <Link>
                <Button className="mt-4" variant="contained" color="primary">
                  Ver orden de compra
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;

Orders.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.bool,
  id_therapy: PropTypes.number,
  id_paquetizacion: PropTypes.number,
  id: PropTypes.number
};
