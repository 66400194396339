import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { yellow, indigo, red } from "@material-ui/core/colors";
import { Typography } from "@material-ui/core";
import LittleChart from "components/Boss/LittleChart";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  tableComponent: {
    padding: "0 1px !important",
    width: "80px",
  },
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 200,
  },
});

// eslint-disable-next-line react/prop-types
export default function TableDemandStock({
  demandStock,
  total,
  handleItemTableDemandStock,
  selectItemTableDemandStock,
  demand,
  stock,
  diff,
  diffMin,
}) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getColorSelected = (id, index) => {
    if (selectItemTableDemandStock) {
      if (selectItemTableDemandStock.id === id) {
        return { background: yellow[100] };
      }
    }
    return {};
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Grupo </TableCell>
              <TableCell align="center">SKU </TableCell>
              <TableCell align="center" style={{minWidth: "100px"}}>Demanda </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Stock </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center" style={{minWidth: "120px"}} >Diferencia </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center" style={{minWidth: "90px"}}>Pedidos </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {demandStock
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={`${index}-workRows`}
                  align="center"
                  style={{
                    ...getColorSelected(row.id, index),
                    cursor: "pointer",
                  }}
                  onClick={() => handleItemTableDemandStock(row)}
                >
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.grupo}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.SKU}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <LittleChart
                      row={row.demanda}
                      color={row.demanda > 0 ? indigo[500] : red[500]}
                      max={demand}
                      stackOffset="none"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.demanda}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <LittleChart
                      row={row.stock}
                      color={row.stock > 0 ? indigo[500] : red[500]}
                      max={stock}
                      stackOffset="none"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.stock}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <LittleChart
                      row={row.difrencia}
                      color={row.difrencia > 0 ? indigo[500] : red[500]}
                      max={diff}
                      min={diffMin}
                      stackOffset="sign"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.difrencia}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption" display="block" gutterBottom>
                      {`${total === 0 ? 0 : parseInt((row.pedido.length * 100) / total)} %`}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={demandStock.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false,
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

TableDemandStock.propTypes = {
  demandStock: PropTypes.array,
};
