import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import OrderDetailInfo from "components/WareHouse/Orders/OrderDetail";
import api from "utils/API";
import ModalCancelRequest from "./ModalCancelRequest";
import TableWaitToOutcome from "./TableWaitToOutcome";



export default function OrderDetailWaitOutcome() {
  const { id } = useParams();
  const [moduleType, setmoduleType] = useState("");
  const id_order = parseInt(id, 10);
  const [loading, setLoading] = useState(false);
  const [modalCancelRequest, setModalCancelRequest] = useState(false);

  const [changes, setChanges] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);
    const tempArrayURL = window.location.href.split('/')
    const tempModuleType = tempArrayURL[tempArrayURL.length-2];
    setmoduleType(tempModuleType);
    const group = tempModuleType === "storage" ? "Insumos" : "Medicamentos";
    const getProducts = api.get(`api/v2/bodega/pedidos/egresos/grupo/${group}/envio/${id}/detalle/`);
    Promise.all([getProducts])
      .then(response => {
        setProducts(response[0].data);

      })
      .catch(error => {
        console.log(error.response);
      });
    setLoading(true);
  };

  return (
    <GridContainer>
      <ViewTitle
        title="PEDIDOS"
        message={`En esta sección podrás revisar los pedidos de ${moduleType === "storage" ? "bodega" : "bodega farmacia"}.`}
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" href={`/admin/warehouse/task/${moduleType}`}>
            {`Tareas ${moduleType === "storage" ? "Bodega" : "Bodega Farmacia"}`}
            </Link>
            <Link color="inherit" href={`/admin/warehouse/orders/${moduleType}`}>
              Pedidos
            </Link>
            <Typography color="textPrimary">Detalle del pedido</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <OrderDetailInfo
                getApi={false}
                idSolicitud={
                  products[0]?.id_producto_pedido.id_solicitud_pedido.id
                }
                tipoSolicitud={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .tipo_solicitud
                }
                paciente={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .nombre_paciente
                }
                fechaSolicitud={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .fecha_solicitud
                }
                fechaDespacho={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .fecha_despacho
                }
                esRegion={
                  products[0]?.id_producto_pedido.id_solicitud_pedido.es_region
                }
                estadoPicking={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .estado_picking
                }
                tieneMedicamentos={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .tiene_medicamentos
                }
                estadoSolicitud={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .estado_solicitud
                }
              />
            </GridContainer>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <TableWaitToOutcome products={products} />
            </GridContainer>
            <CardBody>
              <GridContainer justify="center" className="mt-3 mb-6">
                <Link color="inherit" href={`/admin/warehouse/orders/${moduleType}`}>
                  <Button variant="contained" color="primary" className="m-1">
                    Atrás
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setModalCancelRequest(true)}
                  className="m-1"
                  disabled
                >
                  Cancelar envío
                </Button>
              </GridContainer>
              <div className="mt-3 mb-5"></div>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>

      {modalCancelRequest && (
        <ModalCancelRequest
          open={modalCancelRequest}
          closeModal={() => setModalCancelRequest(false)}
          id_order={id_order}
          moduleType={moduleType}
        />
      )}
    </GridContainer>
  );
}
