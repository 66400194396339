import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Radio from "@material-ui/core/Radio";
import {Checkbox} from "@material-ui/core/";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {RadioButtonUnchecked, CheckCircle } from "@material-ui/icons/";
import {indigo} from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

const Tipo = props => {
  const classes = useStyles();
  const { transferType, setTransferType, viewOnly,
    originGoing, setoriginGoing, originReturn, setoriginReturn
  } = props;


  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container justify="center" style={{ marginTop: "3em" }}>
        <Grid item container xs={6} justify="center">
          <FormControl component="fieldset" disabled={viewOnly ? true : false}>
            <FormLabel component="legend">
              Seleccione el tipo de traslado requerido
            </FormLabel>
            <RadioGroup
              aria-label="type"
              name="type1"
              value={transferType}
              onChange={(e) => setTransferType(e.target.value)}
            >
              <FormControlLabel
                value="Auto"
                control={<Radio color="primary" />}
                label="Vehículo propio"
              />
              <FormControlLabel
                value="Básico"
                control={<Radio color="primary" />}
                label="Complejidad baja"
              />
              <FormControlLabel
                value="Medio"
                control={<Radio color="primary" />}
                label="Complejidad media"
              />
              <FormControlLabel
                value="Avanzado"
                control={<Radio color="primary" />}
                label="Complejidad alta"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item container xs={6} justify="center">
          <Grid item container xs={12} justify="center">
            <FormControl>
              <FormLabel component="legend">
                Indicar si el viaje es ida, vuelta o ambas
              </FormLabel>
            </FormControl>
          </Grid>
          <Grid item container xs={12} justify="center" alignContent="flex-start">
            <FormControlLabel
              control={
                  <Checkbox
                      tabIndex={-1}
                      checked={originGoing}
                      onClick={() => setoriginGoing(!originGoing)}
                      checkedIcon={
                          <CheckCircle style={{fill: indigo[500]}} />
                      }
                      icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                  />
              }
              classes={{
                  label: classes.label,
                  root: classes.labelRoot
              }}
              label="Ida"
            />
            <FormControlLabel
              control={
                  <Checkbox
                      tabIndex={-1}
                      checked={originReturn}
                      onClick={() => setoriginReturn(!originReturn)}
                      checkedIcon={
                          <CheckCircle style={{fill: indigo[500]}} />
                      }
                      icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                  />
              }
              classes={{
                  label: classes.label,
                  root: classes.labelRoot
              }}
              label="Vuelta"
          />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default Tipo;

Tipo.propTypes = {
  transferType: PropTypes.string,
  setTransferType: PropTypes.func,
  viewOnly: PropTypes.bool
};
