import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ButtonTemplate from "components/CustomButtons/Button.js";
import {
    Grid,
    Typography,
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
  } from "@material-ui/pickers";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import {red, blue, green, orange, indigo} from "@material-ui/core/colors";
import TabsTasks from "./MedicalIndications/TabsTasks";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

const periodDict = {
    Horario: "hours",
    Diario: "days",
    Semanal: "weeks",
    Mensual: "months",
    Anual: "years",
};

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const groupBadgeStyles = {
borderRadius: '2em',
display: 'inline-block',
fontSize: 12,
fontWeight: 'normal',
lineHeight: '1',
minWidth: 1,
padding: '0.16666666666667em 0.5em',
textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

export default function FormNewWork({
    dataFile,
    listPeriod,
    dataWork,
    indicationWork,
    setdataWork,
    setindicationWork,
    balanceIngresos,
    balanceEgresos,
    setbalanceIngresos,
    setbalanceEgresos,
    startDateBalance,
    setStartDateBalance,
    endDateBalance,
    setEndDateBalance,
    specialtyBalance,
    setSpecialtyBalance,
    listassigned,
    typeModal="Descomp"
}) {
    const classes = useStyles();
    const [changeButtons, setchangeButtons] = useState(true);
    const [validationWork, setvalidationWork] = useState(false);
    const [type, setType] = useState({});
    const [startDate, setstartDate] = useState(moment(moment().add(2, "hours").format("YYYY-MM-DD HH"), "YYYY-MM-DD HH"));
    const [endDate, setendDate] = useState(moment(moment().add(3, "hours").format("YYYY-MM-DD HH"), "YYYY-MM-DD HH"));
    const [period, setperiod] = useState({})
    const [repeatable, setrepeatable] = useState(false)
    const [assignedPosition, setassignedPosition] = useState({});
    const [assignedPeople, setassignedPeople] = useState({});
    const [listPeople, setlistPeople] = useState([]);
    const [description, setdescription] = useState ("");
    const [hours, sethours] = useState (1);
    const [medicine, setmedicine] = useState({});
    const [dose, setdose] = useState(1)
    const [typeAmount, settypeAmount] = useState({})
    const [selectDays, setselectDays] = useState([0, 1, 2, 3, 4, 5, 6]);
    const [pageRefresh, setPageRefresh] = useState(false);
    const [changes, setChanges] = useState(true);
    const [vitalSigns, setVitalSigns] = useState([
        {
          select: false,
          item: "Talla",
          value: "sizePatient",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""],
          hora1: "",
          hora2: "",
          hora3: "",
          hora4: "",
          hora5: "",
          hora6: "",
          hora7: "",
          hora8: ""
        },
        {
          select: false,
          item: "Peso",
          value: "weight",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""],
          hora1: "",
          hora2: "",
          hora3: "",
          hora4: "",
          hora5: "",
          hora6: "",
          hora7: "",
          hora8: ""
        },
        {
          select: false,
          item: "Temperatura",
          value: "temperature",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""],
          hora1: "",
          hora2: "",
          hora3: "",
          hora4: "",
          hora5: "",
          hora6: "",
          hora7: "",
          hora8: ""
        },
        {
          select: false,
          item: "Frecuencia cardiaca",
          value: "heartRate",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""],
          hora1: "",
          hora2: "",
          hora3: "",
          hora4: "",
          hora5: "",
          hora6: "",
          hora7: "",
          hora8: ""
        },
        {
          select: false,
          item: "Frecuencia respiratoria",
          value: "breathingFrequency",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""],
          hora1: "",
          hora2: "",
          hora3: "",
          hora4: "",
          hora5: "",
          hora6: "",
          hora7: "",
          hora8: ""
        },
        {
          select: false,
          item: "Saturacion de oxígeno",
          value: "oxygenSaturation",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""],
          hora1: "",
          hora2: "",
          hora3: "",
          hora4: "",
          hora5: "",
          hora6: "",
          hora7: "",
          hora8: ""
        },
        {
          select: false,
          item: "Presión arterial",
          value: "bloodPressure",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""],
          hora1: "",
          hora2: "",
          hora3: "",
          hora4: "",
          hora5: "",
          hora6: "",
          hora7: "",
          hora8: ""
        },
        {
          select: false,
          item: "Dolor",
          value: "pain",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""],
          hora1: "",
          hora2: "",
          hora3: "",
          hora4: "",
          hora5: "",
          hora6: "",
          hora7: "",
          hora8: ""
        }
    ]);
    const [general, setGeneral] = useState([
        {
          select: false,
          item: "Aseo Cama",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Aseo Genital",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Lubricación piel",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Cuidados de Sonda",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Cambios de posición",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Cuidados de VVP",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Curación de TQT",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Curación de TQT",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Curación de GTT",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Sondeo Vesical",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Control Peso",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Medición CC",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Recolección de Orina",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        },
        {
          select: false,
          item: "Cambio bajada alimentación",
          value: "General",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          horas: ["", "", "", "", "", "", "", ""]
        }
    ]);
    const [others, setOthers] = useState([
        {
          select: false,
          item: "Ingesta de liquidos",
          value: "Estimulaciones",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          observation: ""
        },
        {
          select: false,
          item: "Deambulación",
          value: "Estimulaciones",
          startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
          speciality: "",
          observation: ""
        }
    ]);
    const days = [
        {
            label: "L",
            value: 1,
        },
        {
            label: "M",
            value: 2,
        },
        {
            label: "M",
            value: 3,
        },
        {
            label: "J",
            value: 4,
        },
        {
            label: "V",
            value: 5,
        },
        {
            label: "S",
            value: 6,
        },
        {
            label: "D",
            value: 0,
        },
    ];

    const dictTypeWork = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA);
    const listTypeAmountBenefit = useSelector(state => state.currentList.LISTA_TIPO_TAREAS_FICHA);
    const listTypeWorkFilePatient = useSelector(state => state.currentList.LISTA_TIPO_TAREAS_FICHA_PACIENTE);
    const dictCharges = useSelector(state => state.currentList.dictCharges);
    const listMedical = useSelector(state => state.currentList.listMedicines);


    const hoursTz = parseInt(moment().tz('America/Santiago').format('z'))*-1;

    useEffect(() => {
        validateFormWork();
        if (changeButtons) {
            setchangeButtons(false);
        }
        if (pageRefresh) {
            setPageRefresh(false);
          }
    });

    const validateFormWork = () => {
        const arrayValidation = [
            (
                (type.value !== undefined && type.label !== "Medicinas Habituales" && type.label !== "SOS") ||
                (
                    (type.label === "Medicinas Habituales" || type.label === "SOS")  &&
                    medicine.label !== undefined &&
                    dose > 0 &&
                    typeAmount.value !== undefined
                )
            ),
            assignedPosition.value !== undefined,
            description !== "",
            type.value !== undefined,
            (repeatable === false ||
                (
                    period.value !== undefined &&
                    endDate !== "" &&
                    (
                        (period.value === "Horario" && hours > 0) ||
                        (period.value === "Semanal" && selectDays.length > 0) ||
                        (period.value !== "Semanal" && period.value !== "Horario" )
                    )
                )
            ),
        ];
        if (arrayValidation.every((item) => item === true)) {
            setvalidationWork(true);
        } else {
            setvalidationWork(false);
        }
    };

    const handleOnChange = (value, set) => {
        set(value);
    };
    const handleSelectMedicine = (value) => {
        if (value.value){
            setmedicine(value);
        }

    };

    const updateValue = () => {
        const tempDataWork = dataWork;

        if (repeatable){
            const tempPeriod = periodDict[period.value];
            if (period.value === "Horario"){
                let dateCondition = moment(startDate, "DD-MM-YYYY HH:mm:ss");
                const dateEndIteration = moment(endDate, "DD-MM-YYYY HH:mm:ss");
                while (dateEndIteration.diff(moment(dateCondition, "DD-MM-YYYY HH:mm:ss"), "minute") >= 0){
                    tempDataWork.push({
                        tipo_tarea: `${typeModal}${type.value}`,
                        fecha_hora: moment(dateCondition).add(hoursTz).format("YYYY-MM-DD HH:mm:ss"),
                        fecha_final: null,
                        id_cargo_asignado: assignedPosition.value,
                        id_usuarios: assignedPeople.value,
                        descripcion: `${(
                            dose > 0 &&
                            typeAmount.value !== undefined &&
                            medicine.value  !== undefined &&
                            (type.label === "Medicinas Habituales" || type.label === "SOS")
                        )  ? `${medicine.label}, ${dose} ${typeAmount.label}. ` : "" } ${description}`,
                        estado: "Incompleto",
                        id_ficha: dataFile.id,
                        motivo_rechazo: "",
                        comentarios: "",
                    });
                    dateCondition = dateCondition.add(hours, tempPeriod)
                }
            } else if (period.value === "Semanal"){
                let dateCondition = moment(startDate, "DD-MM-YYYY HH:mm:ss");
                const dateEndIteration = moment(endDate, "DD-MM-YYYY HH:mm:ss");
                while (dateEndIteration.diff(moment(dateCondition, "DD-MM-YYYY HH:mm:ss"), "days") >= 0){
                    if (selectDays.includes(dateCondition.day())){
                        tempDataWork.push({
                            tipo_tarea: `${typeModal}${type.value}`,
                            fecha_hora: moment(dateCondition).add(hoursTz).format("YYYY-MM-DD HH:mm:ss"),
                            fecha_final: null,
                            id_cargo_asignado: assignedPosition.value,
                            id_usuarios: assignedPeople.value,
                            descripcion: `${(
                                dose > 0 &&
                                typeAmount.value !== undefined &&
                                medicine.value !== undefined &&
                                (type.label === "Medicinas Habituales" || type.label === "SOS")
                            ) ? `${medicine.label}, ${dose} ${typeAmount.label}. ` : "" } ${description}`,
                            estado: "Incompleto",
                            id_ficha: dataFile.id,
                            motivo_rechazo: "",
                            comentarios: "",
                        });
                    }
                    dateCondition = dateCondition.add(1, "days");
                }
            } else {
                const diff = moment(endDate, "DD-MM-YYYY HH:mm:ss").diff(moment(startDate, "DD-MM-YYYY HH:mm:ss"), tempPeriod)+1;
                Array.from(Array(diff), (e, i) => {
                    tempDataWork.push({
                        tipo_tarea: `${typeModal}${type.value}`,
                        fecha_hora: moment(startDate, "DD-MM-YYYY HH:mm:ss").add(i, tempPeriod).add(hoursTz).format("YYYY-MM-DD HH:mm:ss"),
                        fecha_final: null,
                        id_cargo_asignado: assignedPosition.value,
                        id_usuarios: assignedPeople.value,
                        descripcion: `${(
                            dose > 0 &&
                            typeAmount.value !== undefined &&
                            medicine.value !== undefined &&
                            (type.label === "Medicinas Habituales" || type.label === "SOS")
                        ) ? `${medicine.label}, ${dose} ${typeAmount.label}. ` : "" } ${description}`,
                        estado: "Incompleto",
                        id_ficha: dataFile.id,
                        motivo_rechazo: "",
                        comentarios: ""
                    });
                });
            };
        } else {
            tempDataWork.push({
                tipo_tarea: `${typeModal}${type.value}`,
                fecha_hora: moment(startDate).add(hoursTz).format("YYYY-MM-DD HH:mm:ss"),
                fecha_final: null,
                id_cargo_asignado: assignedPosition.value,
                id_usuarios: assignedPeople.value,
                descripcion: `${(
                    dose > 0 &&
                    typeAmount.value !== undefined &&
                    medicine.value !== undefined &&
                    (type.label === "Medicinas Habituales" || type.label === "SOS")
                ) ? `${medicine.label}, ${dose} ${typeAmount.label}. ` : "" } ${description}`,
                estado: "Incompleto",
                id_ficha: dataFile.id,
                motivo_rechazo: "",
                comentarios: ""
            });
        };
        setdataWork(tempDataWork);
        setchangeButtons(true);
    };

    const handleSelectDay = (button) => {
        let tempArray = selectDays;
        if (selectDays.includes(button)) {
          tempArray = selectDays.filter((row) => row != button);
        } else {
          tempArray.push(button);
        }
        setselectDays(tempArray)
        setchangeButtons(true);
    };

    const handleRepeatable = (checked) => {
        if (checked){
            setperiod({value: "Horario", label: "Horario"});
            setstartDate(moment(moment().add(2, "hours").format("YYYY-MM-DD HH"), "YYYY-MM-DD HH"));
            setendDate(moment(moment().add(3, "hours").format("YYYY-MM-DD HH"), "YYYY-MM-DD HH"));
        }
        setrepeatable(checked);
    };

    const handlePeriod = (value) => {
        const temPeriod = value.value;
        const tempDateStart = moment(moment().add(2, "hours").format("YYYY-MM-DD HH"), "YYYY-MM-DD HH");
        if (temPeriod=="Horario"){
            setstartDate(tempDateStart);
            setendDate(moment(moment(tempDateStart).add(1, "hours").format("YYYY-MM-DD HH"), "YYYY-MM-DD HH"));
        } else if (temPeriod=="Diario"){
            setstartDate(tempDateStart);
            setendDate(moment(moment(tempDateStart).add(1, "days").format("YYYY-MM-DD HH"), "YYYY-MM-DD HH"));
        } else if (temPeriod=="Semanal"){
            setstartDate(tempDateStart);
            setendDate(moment(moment(tempDateStart).add(1, "weeks").format("YYYY-MM-DD HH"), "YYYY-MM-DD HH"));
        } else if (temPeriod=="Mensual"){
            setstartDate(tempDateStart);
            setendDate(moment(moment(tempDateStart).add(1, "months").format("YYYY-MM-DD HH"), "YYYY-MM-DD HH"));
        } else if (temPeriod=="Anual"){
            setstartDate(tempDateStart);
            setendDate(moment(moment(tempDateStart).add(1, "years").format("YYYY-MM-DD HH"), "YYYY-MM-DD HH"));
        }
        setperiod(value);
    };

    const handleAssignedPosition = (value) => {
        api.get(`/api/usuario/cargo/${value.value}`).then((response) => {
            setlistPeople(response.data.map((row) => {
                return {value: row.id, label: `(${row.rut}) ${row.first_name} ${row.last_name}`};
            }));
        });
        setassignedPosition(value);
    };

    const addAll = () => {
        // setOpenProcess(true);
        const hours = parseInt(moment().tz('America/Santiago').format('z'))*-1;
        const sendArray = [];
        // others
        others
          .filter(filtered => filtered.select)
          .map(sign => {
            sendArray.push({
                tipo_tarea: `${typeModal}IndicacionesMedicas`,
                fecha_hora: moment(sign.startDate).add(hours).format("YYYY-MM-DD HH:mm:ss"),
                fecha_final: moment(sign.endDate).add(hours).format("YYYY-MM-DD HH:mm:ss"),
                descripcion: sign.item,
                estado: "Incompleto",
                comentarios: sign.value,
                motivo_rechazo: "Estimulaciones",
                id_ficha: dataFile.id,
                id_cargo_asignado: sign.speciality,
                id_usuarios: null,
            });
          });
        // end others
        // general
        let countGeneral = 0;
        general
          .filter(filtered => filtered.select)
          .map(sign => {
            countGeneral = countGeneral + 1;
            let fechaComparar = moment(sign.startDate);
            let fechaFin = moment(sign.endDate).add(1, "days");
            while (fechaFin.diff(moment(fechaComparar)) > 0) {
              sign.horas
                .filter(hora => hora !== "")
                .map(hora => {
                  const tempFecha = `${moment(fechaComparar).format(
                    "YYYY-MM-DD"
                  )}T${moment(hora).format("HH:mm:ss")}`;
                  sendArray.push({
                    tipo_tarea: `${typeModal}IndicacionesMedicas`,
                    fecha_hora: moment(tempFecha).add(hours).format("YYYY-MM-DD HH:mm:ss"),
                    fecha_final: null,
                    descripcion: sign.item,
                    id_cargo_asignado: sign.speciality,
                    id_usuarios: null,
                    estado: "Incompleto",
                    comentarios: sign.value,
                    motivo_rechazo: "General",
                    id_ficha: dataFile.id

                  });
                });
              fechaComparar = moment(fechaComparar).add(1, "days");
            }
          });
        // end general
        // vital signs
        vitalSigns
          .filter(filtered => filtered.select)
          .map(sign => {
            let fechaComparar = moment(sign.startDate);
            let fechaFin = moment(sign.endDate).add(1, "days");
            while (fechaFin.diff(moment(fechaComparar)) > 0) {
              sign.horas
                .filter(hora => hora !== "")
                .map(hora => {
                  const tempFecha = `${moment(fechaComparar).format(
                    "YYYY-MM-DD"
                  )}T${moment(hora).format("HH:mm:ss")}`;
                  sendArray.push({
                    tipo_tarea: `${typeModal}IndicacionesMedicas`,
                    fecha_hora: moment(tempFecha).add(hours).format("YYYY-MM-DD HH:mm:ss"),
                    fecha_final: null,
                    descripcion: sign.item,
                    id_cargo_asignado: sign.speciality,
                    id_usuarios: null,
                    estado: "Incompleto",
                    comentarios: sign.value,
                    motivo_rechazo: "SignosVitales",
                    id_ficha: dataFile.id
                  });
                });
              fechaComparar = moment(fechaComparar).add(1, "days");
            }
          });
        // end vital signs
        // balance
        let totalBalance = 0;
        balanceIngresos
          .filter(filtered => filtered.select)
          .map(sign => {
            totalBalance = totalBalance + 1;
            let fechaComparar = moment(sign.startDate);
            let fechaFin = moment(sign.endDate).add(1, "days");
            while (fechaFin.diff(moment(fechaComparar)) > 0) {
              const tempFecha = `${moment(fechaComparar).format(
                "YYYY-MM-DDTHH:mm:ss"
              )}`;
              sendArray.push({
                tipo_tarea: `${typeModal}IndicacionesMedicas`,
                fecha_hora: moment(tempFecha).add(hours).format("YYYY-MM-DD HH:mm:ss"),
                fecha_final: null,
                descripcion: sign.item,
                id_cargo_asignado: sign.speciality,
                id_usuarios: null,
                estado: "Incompleto",
                comentarios: sign.value,
                motivo_rechazo: "BalanceHidrico",
                id_ficha: dataFile.id
              });
              fechaComparar = moment(fechaComparar).add(1, "days");
            }
          });

        balanceEgresos
          .filter(filtered => filtered.select)
          .map(sign => {
            totalBalance = totalBalance + 1;
            let fechaComparar = moment(sign.startDate);
            let fechaFin = moment(sign.endDate).add(1, "days");
            while (fechaFin.diff(moment(fechaComparar)) > 0) {
              const tempFecha = `${moment(fechaComparar).format(
                "YYYY-MM-DDTHH:mm:ss"
              )}`;
              sendArray.push({
                tipo_tarea: `${typeModal}IndicacionesMedicas`,
                fecha_hora: moment(tempFecha).add(hours).format("YYYY-MM-DD HH:mm:ss"),
                fecha_final: null,
                descripcion: sign.item,
                id_cargo_asignado: sign.speciality,
                id_usuarios: null,
                estado: "Incompleto",
                comentarios: sign.value,
                motivo_rechazo: "BalanceHidrico",
                id_ficha: dataFile.id
              });
              fechaComparar = moment(fechaComparar).add(1, "days");
            }
          });
        // end balance
        setindicationWork([...sendArray]);
        setchangeButtons(true);
    };

    return (
        <Grid container style={{height: "315px", overflow: "auto"}}>
            { (type.value === undefined || type.value !== "IndicacionesMedicas") ?
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                item
                xs={12}
            >
                <Grid
                    item
                    xs={4}
                >
                    <DetailSelect
                        label="Tipo"
                        value={type}
                        onchange={(value) => { handleOnChange(value, setType)}}
                        list={listTypeWorkFilePatient}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <DetailText
                        label={`Descripción`}
                        placeholder={`Descripción`}
                        value={description}
                        onchange={(e) => { handleOnChange(e.target.value, setdescription)}}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <DetailSelect
                        label="Cargo asignado "
                        value={assignedPosition}
                        onchange={(value) => {handleAssignedPosition(value)}}
                        list={listassigned}
                        formatGroupLabel={formatGroupLabel}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <DetailSelect
                        label="Persona asignada"
                        value={assignedPeople}
                        onchange={(value) => { handleOnChange(value, setassignedPeople)}}
                        list={listPeople}
                        formatGroupLabel={formatGroupLabel}
                        validation={false}
                    />
                </Grid>
                { (type.label === "Medicinas Habituales" || type.label === "SOS")  &&
                <Grid
                    item
                    xs={4}
                >
                    <DetailSelect
                        label="Medicamento"
                        value={medicine}
                        onchange={(value) => {handleSelectMedicine(value)}}
                        list={listMedical.length > 0 ? listMedical : [{value: null, label: "Cargando..."}]}
                    />
                </Grid>
                }
                { (type.label === "Medicinas Habituales" || type.label === "SOS")  &&
                <Grid
                    item
                    xs={4}
                >
                    <DetailNumber
                        label={`Dosis`}
                        placeholder={`Dosis`}
                        value={dose}
                        onchange={(e) => { handleOnChange(e.target.value, setdose)}}
                    />
                </Grid>
                }
                { (type.label === "Medicinas Habituales" || type.label === "SOS")  &&
                <Grid
                    item
                    xs={4}
                >
                    <DetailSelect
                        label="Tipo/Cantidad "
                        value={typeAmount}
                        onchange={(value) => { handleOnChange(value, settypeAmount)}}
                        list={listTypeAmountBenefit}
                    />
                </Grid>
                }
                <Grid
                    item
                    xs={12}
                    style={{padding: "0 15px"}}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={repeatable}
                                onChange={(event) => handleRepeatable(event.target.checked)}
                                name="Repetible"
                                inputProps={{ 'aria-label': 'periodSelect' }}
                            />
                        }
                        label="Repetible"
                    />

                </Grid>
                {repeatable ?
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-end"
                    item
                    xs={12}
                >
                    <Grid
                        item
                        xs={4}
                    >
                        <DetailSelect
                            label="Frecuencia"
                            value={period}
                            onchange={(value) => { handlePeriod(value)}}
                            list={listPeriod}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDateTimePicker
                            variant="inline"
                            format="DD/MM/YYYY HH:mm"
                            margin="normal"
                            label={"Fecha Inicio"}
                            placeholder="Fecha Inicio"
                            onChange={setstartDate}
                            maxDate={moment(endDate)}
                            
                            value={startDate}
                            inputVariant={"outlined"}
                        />
                    </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                format="DD/MM/YYYY HH:mm"
                                margin="normal"
                                label={"Fecha Término"}
                                placeholder="Fecha Término"
                                onChange={setendDate}
                                minDate={moment(startDate)}
                                value={endDate}
                                inputVariant={"outlined"}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {period.value === "Horario" &&
                    <Grid
                        item
                        xs={4}
                    >
                        <DetailNumber
                            label={`Tiempo de repetición (Horas)`}
                            placeholder={`Horas`}
                            value={hours}
                            onchange={(e) => { handleOnChange(e.target.value, sethours)}}
                        />
                    </Grid>
                    }
                    {period.value === "Semanal" &&
                    <Grid item xs={5}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        {days.map((row, index) => (
                            <ButtonTemplate
                                key={`${index}-daybuttons`}
                                justIcon
                                round
                                color={selectDays.includes(row.value) ?  "facebook" :  "default"}
                                className={classes.marginRight}
                                onClick={() => handleSelectDay(row.value)}
                            >
                                {row.label}
                            </ButtonTemplate>
                        ))}
                        {selectDays.length === 0 &&
                            <Typography variant="caption" style={{color: red[500]}} gutterBottom>
                                A lo menos 1 día seleccionado
                            </Typography>
                        }
                    </Grid>
                    }
                </Grid>
                :
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-end"
                    item
                    xs={12}
                >
                    <Grid
                        item
                        xs={4}
                    >
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                format="DD/MM/YYYY HH:mm"
                                margin="normal"
                                label={"Fecha"}
                                placeholder="Fecha"
                                onChange={setstartDate}
                                value={startDate}
                                inputVariant={"outlined"}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                }
            </Grid> :
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                item
                xs={12}
            >
                <Grid
                    item
                    xs={4}
                >
                    <DetailSelect
                        label="Tipo"
                        value={type}
                        onchange={(value) => { handleOnChange(value, setType)}}
                        list={listTypeWorkFilePatient}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <TabsTasks
                        vitalSigns={vitalSigns}
                        setVitalSigns={setVitalSigns}
                        general={general}
                        setGeneral={setGeneral}
                        setPageRefresh={setPageRefresh}
                        others={others}
                        setOthers={setOthers}
                        balanceIngresos={balanceIngresos}
                        setBalanceIngresos={setbalanceIngresos}
                        balanceEgresos={balanceEgresos}
                        setBalanceEgresos={setbalanceEgresos}
                        startDateBalance={startDateBalance}
                        setStartDateBalance={setStartDateBalance}
                        endDateBalance={endDateBalance}
                        setEndDateBalance={setEndDateBalance}
                        specialtyBalance={specialtyBalance}
                        setSpecialtyBalance={setSpecialtyBalance}
                        dataFile={dataFile}
                        setChanges={setChanges}
                        pageRefresh={pageRefresh}
                    />
                    {pageRefresh && <div></div>}
                </Grid>
            </Grid>
            }
            <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0"}}
                >
                    {(validationWork && type.value !== "IndicacionesMedicas") ?
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            style={{backgroundColor: green[500]}}
                            onClick={updateValue}
                        >
                            Incluir Tareas
                        </Button>
                    : type.value !== "IndicacionesMedicas" ?
                    <Button
                        disabled
                        mr={2}
                        variant="contained"
                        className="text-white"
                    >
                        Incluir Tareas
                    </Button> :
                    <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        style={{backgroundColor: green[500]}}
                        onClick={addAll}
                    >
                        Incluir indicaciones médicas
                    </Button>
                    }
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Table className="table table-striped" >
                        <TableHead>
                        <TableRow>
                            <TableCell  className="text-center align-top">Tipo </TableCell>
                            <TableCell  className="text-center align-top">Fecha </TableCell>
                            <TableCell  className="text-center align-top">Asignada a </TableCell>
                            <TableCell  className="text-center align-top">Descripción </TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {[...dataWork, ...indicationWork].map((row, index) => (
                            <TableRow
                                key={`${index}-workRows`}
                            >
                            <TableCell className="text-center align-middle">{dictTypeWork[row.tipo_tarea]?.label} </TableCell>
                            <TableCell className="text-center align-middle">{row.id ?
                                moment(row.fecha_hora, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm") :
                                moment(row.fecha_hora, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm")
                            }</TableCell>
                            <TableCell className="text-center align-middle">
                                {dictCharges[row.id_cargo_asignado]?.nombre_corto || " "}
                                </TableCell>
                            <TableCell className="text-center align-middle">
                                    {row.descripcion}
                            </TableCell>
                            </TableRow>
                        ))}
                        {changeButtons && <TableRow></TableRow>}
                        </TableBody>
                    </Table>
                </Grid>
        </Grid>
    );
}