import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";


const ProductRequestInfo = props => {


  return (
    <div className="col-xl-8 mb-3">
      <div className="card">
        <div className="card-header">
          <p className="text-center">Datos del pedido</p>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm text-left ml-5">
              <span className="font-weight-bold">Id: </span>
              {props.id}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Grupo: </span>
              {props.group}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Descripción: </span>
              {props.description}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Fecha de ingreso: </span>
              {props.date}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductRequestInfo;

ProductRequestInfo.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.bool,
  id_therapy: PropTypes.number,
  id_paquetizacion: PropTypes.number,
  id: PropTypes.number
};
