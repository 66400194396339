import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Divider from "@material-ui/core/Divider";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";
import ModalSavedReport from "./ModalSavedReport";


export default function NewReport() {
  const [moduleType, setmoduleType] = useState("");
  const [supplies, setSupplies] = useState([]);
  const [group, setGroup] = useState("");
  const [group_final, setGroup_final] = useState("");
  const [sku, setSKU] = useState("");
  const [id_sku, setId_sku] = useState("");
  const [sku_final, setSKU_final] = useState("");
  const [id_sku_final, setId_sku_final] = useState("");
  let list_sku = [];
  let list_sku_final = [];
  const [description, setDescription] = useState("");
  const [description_final, setDescription_final] = useState("");
  const [cantidad, setCantidad] = useState(1);
  const [observaciones, setObservaciones] = useState("");

  const [modalSaved, setModalSaved] = useState(false);
  const [stock, setStock] = useState("");


  useEffect(() => {
    const tempArrayURL = window.location.href.split('/');
    setmoduleType(tempArrayURL[tempArrayURL.length-1]);
    api.get("api/insumosmedicamento").then(response => {
      setSupplies(response.data);

    });
  }, []);

  useEffect(() => {
    supplies.map(supply => {
      list_sku.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies.map(supply => {
      list_sku_final.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies
      .filter(supply => supply.SKU === sku.value)
      .map(filtered => {
        setGroup(filtered.grupo_producto);
        setDescription(filtered.descripcion_producto);
        setId_sku(filtered.id);
        setStock(filtered.stock_producto);
      });
    supplies
      .filter(supply => supply.SKU === sku_final.value)
      .map(filtered => {
        setGroup_final(filtered.grupo_producto);
        setDescription_final(filtered.descripcion_producto);
        setId_sku_final(filtered.id);
      });
  });

  const handleSKU = sku => {
    setSKU(sku);
  };

  function createPurchase() {

    const formData = new FormData();

    formData.append("cantidad_encontrada", cantidad);
    formData.append("observaciones", observaciones);
    formData.append("estado", "Pendiente");
    formData.append("id_insumo_medicamento", id_sku);

    apiform
      .post("api/solicitudreportes/", formData)
      .then(response => {
        console.log(response);

        setModalSaved(true);
      })

  }

  return (
    <GridContainer>
      <ViewTitle
        title="SOLICITAR REPORTE"
        message="En esta sección podrás solicitar un nuevo reporte."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link color="inherit" to={`/admin/warehouse/requests/${moduleType}`}>
              Fraccionamiento - Compras
            </Link>
            <Typography color="textPrimary">Solicitar reporte</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardBody className="mb-3">
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <DetailSelect
                  label="SKU:"
                  value={sku}
                  onchange={handleSKU}
                  list={list_sku}
                />
                <DetailTextDisabled label="GRUPO:" value={group} />
                <DetailTextDisabled label="DESCRIPCIÓN:" value={description} />
                <DetailTextDisabled label="CANTIDAD STOCK:" value={stock} />
                <div className="col-md-4 mb-3">
                  <label className="text-dark">CANTIDAD BODEGA:</label>
                  <input
                    type="number"
                    min="0"
                    className="form-control bg-light font-weight-lighter"
                    style={{color: "#000000"}}
                    value={cantidad}
                    onChange={e => {
                      if (/^[0-9.\b]+$/.test(e.target.value)) {
                        setCantidad(e.target.value);
                      }
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Divider className="mb-4 mt-3" />
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item xs={8}>
                <DetailTextArea
                  label="OBSERVACIONES:"
                  placeholder="Observaciones"
                  value={observaciones}
                  maxlength="500"
                  onchange={e => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item xs={8}>
                {sku ? null : (
                  <span className="text-danger">
                    Debes ingresar un sku
                  </span>
                )}
                {observaciones === "" ? (
                  <span className="text-danger">
                    Debes ingresar una observación
                  </span>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                {sku && observaciones != "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createPurchase}
                  >
                    Guardar cambios
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Link to={`/admin/warehouse/requests/${moduleType}`}>
                  <Button variant="contained" color="primary">
                    Cancelar Solicitud
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      {modalSaved && (
        <ModalSavedReport open={modalSaved} closeModal={() => setModalSaved(false)} moduleType={moduleType}/>
      )}
    </GridContainer>
  );
}
