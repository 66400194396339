import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    IconButton,
    Box,
    CircularProgress,
    Backdrop,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';

export default function ModalDialog(props) {

const scroll = props.scroll || "paper"
const globalOpenBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);

return (
    <Dialog
        open={props.open}
        onClose={props.onClose}
        //scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={props.maxWidth || "md"}
        fullWidth={props.fullWidth || true}
    >
        <Box class={"titleBox"}>
            <DialogTitle className="titleDialog" onClose={props.onClose}>
                {props.title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8
                }}
                style={{ color: "white" }}
            >
                <CloseIcon style={{ color: "white" }} />
            </IconButton>
        </Box>
        <DialogContent dividers={scroll === "paper"}>
            {props._content_ && props._content_}
        </DialogContent>
        {props._actions_ &&
        <DialogActions>
            {props._actions_}
        </DialogActions>
        }
        <Backdrop className={"backdropStyle"} open={globalOpenBackdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </Dialog>
  );
}


