import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModalMessage from "./ModalMessage";
import DialogNotDelete from "./DialogNotDelete";
import DialogDelete from "./DialogDelete";


export default function ModalAddRecord(props) {
  const { open, closeModal, idRequest, idGroup, orders, setOrders } = props;
  const [modalMessage, setModalMessage] = useState(false);

  const [firstCharge, setFirstCharge] = useState(true);
  const indexOrders = orders.findIndex(row => row.id === idGroup);
  const [products, setProducts] = useState([]);
  const [openDialogNotDelete, setOpenDialogNotDelete] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [tempIdRequest, setTempIdRequest] = useState(null);
  const [tempIdRecord, setTempIdRecord] = useState(null);
  const [tempIndex, setTempIndex] = useState(null);
  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const scroll = "paper";

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);

    const getProducts = api.get(
      `api/compras/solicitudcotizacion/productosagrupados/${idGroup}`
    );

    Promise.all([getProducts])
      .then(response => {
        let tempProducts = [];
        response[0].data.map(row => {
          tempProducts.push({
            ...row,
            recover: false
          });
        });
        setProducts(tempProducts);

      })

  };

  function deleteRecord(idRecord, index) {

    if (orders.filter(row => row.show).length === 1 && products.length === 1) {
      setOpenDialogNotDelete(true);

    } else {
      if (products.length === 1) {
        setTempIdRequest(idRequest);
        setTempIdRecord(idRecord);
        setTempIndex(indexOrders);
        setOpenDialogDelete(true);
      } else {
        const filteredProducts = products.filter(row => row.recover === false)
          .length;
        if (
          filteredProducts === 1 &&
          orders.filter(row => row.show).length === 1
        ) {
          setOpenDialogNotDelete(true);

        } else if (filteredProducts === 1) {
          let tempProducts = products;
          tempProducts[index].recover = true;
          const deleteRecord = api.post(
            `api/compras/solicitudcotizacion/productosagrupados/${idRequest}/eliminar/${idRecord}`
          );
          Promise.all([deleteRecord])
            .then(() => {
              let tempDelete = orders;
              tempDelete[indexOrders].show = false;
              setProducts(tempProducts);
              setOrders(tempDelete);

            })

        } else {
          let tempProducts = products;
          tempProducts[index].recover = true;
          const deleteRecord = api.post(
            `api/compras/solicitudcotizacion/productosagrupados/${idRequest}/eliminar/${idRecord}`
          );

          Promise.all([deleteRecord])
            .then(() => {
              setProducts(tempProducts);

            })

        }
      }
    }
  }

  function addRecord(idRecord, index) {

    let tempProducts = products;
    tempProducts[index].recover = false;

    const recoverRecord = api.post(
      `api/compras/solicitudcotizacion/productosagrupados/${idRequest}/agregar/${idRecord}`
    );

    Promise.all([recoverRecord])
      .then(() => {
        let tempRecover = orders;
        tempRecover[indexOrders].show = true;
        setProducts(tempProducts);
        setOrders(tempRecover);

      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">
        Confirmación eliminación registro
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          <div className="mt-3 mb-3">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="text-center align-middle">SKU</th>
                  <th className="text-center align-middle">Descripción</th>
                  <th className="text-center align-middle">Cantidad</th>
                  <th className="text-center align-middle">Observaciones</th>
                  <th className="text-center align-middle">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr key={product.id}>
                    <td className="text-center align-middle">
                      {product.id_producto_comprado.SKU}
                    </td>
                    <td className="text-center align-middle">
                      {product.id_producto_comprado.descripcion_producto}
                    </td>
                    <td className="text-center align-middle">
                      {product.cantidad}
                    </td>
                    <td className="text-center align-middle">
                      {product.observaciones}
                    </td>
                    <td className="text-center align-middle">
                      {product.recover ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addRecord(product.id, index)}
                          style={{ textTransform: "none" }}
                        >
                          Recuperar
                        </Button>
                      ) : (
                        <Button
                          onClick={() => deleteRecord(product.id, index)}
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none" }}
                        >
                          Eliminar
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          color="primary"
          style={{ textTransform: "none" }}
          variant="contained"
        >
          Cerrar
        </Button>
      </DialogActions>
      {modalMessage && (
        <ModalMessage
          open={modalMessage}
          closeModal={() => setModalMessage(false)}
        />
      )}
      {openDialogNotDelete && (
        <DialogNotDelete
          openDialog={openDialogNotDelete}
          closeDialog={() => setOpenDialogNotDelete(false)}
          closeModal={closeModal}
        />
      )}
      {openDialogDelete && (
        <DialogDelete
          openDialog={openDialogDelete}
          closeDialog={() => setOpenDialogDelete(false)}
          closeModal={closeModal}
          orders={orders}
          setOrders={setOrders}
          tempIdRequest={tempIdRequest}
          tempIdRecord={tempIdRecord}
          tempIndex={tempIndex}
        />
      )}
    </Dialog>
  );
}

ModalAddRecord.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  idRequest: PropTypes.number,
  idGroup: PropTypes.number,
  orders: PropTypes.array,
  setOrders: PropTypes.func
};
