import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableExamData = props => {
  const classes = useStyles();
  const { supplies } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root} style={{ marginTop: "2em" }}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">SKU</TableCell>
              <TableCell align="center">Nombre</TableCell>
              <TableCell align="center">Cantidad</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplies
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index )=> {
                return (
                  <TableRow index={`${index}-exam-data`} hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align="center">{row.tipo}</TableCell>
                    <TableCell align="center">{row.sku}</TableCell>
                    <TableCell align="center">{row.item}</TableCell>
                    <TableCell align="center">{row.cantidad}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "Todas", value: -1 }]}
        colSpan={12}
        component="div"
        count={supplies.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por pagina" },
          native: false
        }}
        labelRowsPerPage={"Filas por pagina"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atras"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `mas que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableExamData;

TableExamData.propTypes = {
  supplies: PropTypes.array
};
