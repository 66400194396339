import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const Vacaciones = props => {
  const {
    startDate,
    handleStartDate,
    endDate,
    handleEndDate,
    totalDias,
    primerDiaTrabajado,
    handlePrimerDiaTrabajado,
    ultimoDiaTrabajado,
    handleUltimoDiaTrabajado,
    editInfo,
    estadoVacaciones
  } = props;

  return (
    <Grid container justify="flex-start" style={{ marginTop: "2em" }}>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Días disponible:
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none", fontWeight: "bold" }}
          >
            
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        {editInfo ? (
          <Grid item container xs={9}>
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline1"
                  label="Fecha de inicio"
                  value={startDate}
                  onChange={handleStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        ) : (
          <Grid item container xs={9}>
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline1"
                  label="Fecha de inicio"
                  value={startDate}
                  onChange={handleStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  readOnly
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        )}
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          {editInfo ? (
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline2"
                  label="Fecha de término"
                  value={endDate}
                  onChange={handleEndDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  minDate={startDate}
                  minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          ) : (
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline2"
                  label="Fecha de término"
                  value={endDate}
                  onChange={handleEndDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  minDate={startDate}
                  minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
                  readOnly
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          {editInfo ? (
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline3"
                  label="Fecha último día trabajado"
                  value={ultimoDiaTrabajado}
                  onChange={handleUltimoDiaTrabajado}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  // minDate={startDate}
                  // minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          ) : (
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline3"
                  label="Fecha último día trabajado"
                  value={ultimoDiaTrabajado}
                  onChange={handleUltimoDiaTrabajado}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  readOnly
                  // minDate={startDate}
                  // minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          {editInfo ? (
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline4"
                  label="Fecha primer día trabajado"
                  value={primerDiaTrabajado}
                  onChange={handlePrimerDiaTrabajado}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  // minDate={startDate}
                  // minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          ) : (
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline4"
                  label="Fecha primer día trabajado"
                  value={primerDiaTrabajado}
                  onChange={handlePrimerDiaTrabajado}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  readOnly
                  // minDate={startDate}
                  // minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
        style={{ marginTop: "1em" }}
      >
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Días hábiles totales solicitados:
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none", fontWeight: "bold" }}
          >
            {totalDias === 0 ? 1 : totalDias}
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
        style={{ marginTop: "1em" }}
      >
        <Grid item xs={2} />
        <Grid item container xs={9}>
          {estadoVacaciones === "Aprobado" ? (
            <div>
              <Typography
                variant="body2"
                gutterBottom
                style={{ textTransform: "none" }}
              >
                Estado solicitud:
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ textTransform: "none", fontWeight: "bold" }}
              >
                {estadoVacaciones}
              </Typography>
            </div>
          ) : null}
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  );
};

export default Vacaciones;

Vacaciones.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.object,
  startDate: PropTypes.object,
  handleStartDate: PropTypes.func,
  endDate: PropTypes.object,
  handleEndDate: PropTypes.func,
  totalDias: PropTypes.number,
  primerDiaTrabajado: PropTypes.object,
  handlePrimerDiaTrabajado: PropTypes.func,
  ultimoDiaTrabajado: PropTypes.object,
  handleUltimoDiaTrabajado: PropTypes.func,
  editInfo: PropTypes.bool,
  estadoVacaciones: PropTypes.string
};
