import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import ViewTitle from "./FichaPersonal/viewTitle";
import CardTitle from "./FichaPersonal/cardTitle";
import DetailText from "./FichaPersonal/ViewFichaPersonal/detailText";
import DetailMoney from "./FichaPersonal/ViewFichaPersonal/detailMoney";
import DetailBoolean from "./FichaPersonal/ViewFichaPersonal/detailBoolean";
import DetailTextArea from "./FichaPersonal/ViewFichaPersonal/detailTextArea";
import DetailFile from "./FichaPersonal/ViewFichaPersonal/detailFile";
import DetailFileContrato from "./FichaPersonal/ViewFichaPersonal/detailFileContrato";
import DetailSelect2 from "./FichaPaciente/detailSelect";
import LoadingSpinner from "./FichaPersonal/loadingSpinner";
import Grid from "@material-ui/core/Grid";
import api from "utils/API";
import "bootstrap/dist/css/bootstrap.min.css";

const useStyles = makeStyles(styles);

const DeleteContrato = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const { id } = useParams();
  const [contrato, setContrato] = useState();
  const [loading, setLoading] = useState(false);

  const [sexo, setSexo] = useState("");
  const [get_sexo, setGet_sexo] = useState("");

  const [estado_civil, setEstado_civil] = useState("");
  const [get_estado_civil, setGet_estado_civil] = useState("");

  const [direccion_region, setDireccion_region] = useState("");
  const [get_region, setGet_region] = useState("");

  const [nacionalidad, setNacionalidad] = useState("");
  const [get_nacionalidad, setGet_nacionalidad] = useState("");

  const [banco, setBanco] = useState("");
  const [get_banco, setGet_banco] = useState("");

  const [tipo_cuenta_banco, setTipo_cuenta_banco] = useState("");
  const [get_tipo_cuenta_banco, setGet_tipo_cuenta_banco] = useState("");

  const [nivel_estudios, setNivel_estudios] = useState("");
  const [get_nivel_estudios, setGet_nivel_estudios] = useState("");

  const [estado_estudios, setEstado_estudios] = useState("");
  const [get_estado_estudios, setGet_estado_estudios] = useState("");

  const [tipo_contrato, setTipo_contrato] = useState("");
  const [get_tipo_contrato, setGet_tipo_contrato] = useState("");

  const [empresa, setEmpresa] = useState("");
  const [get_empresa, setGet_empresa] = useState("");

  const [id_cargo, setId_cargo] = useState();

  const [nombre_isapre, setNombre_isapre] = useState("");
  const [get_nombre_isapre, setGet_nombre_isapre] = useState("");

  const [id_lista_afp, setId_lista_afp] = useState();
  const [get_afp, setGet_afp] = useState("");

  const [get_contratos_anexos, setGet_contratos_anexos] = useState();
  const [area, setArea] = useState();

  const [selectedBoss, setselectedBoss] = useState([])

  const get_cargo = useSelector(state => state.currentList.listCharges);

  useEffect(() => {
    api.get(`api/personal/jefatura/ficha/${id}`).then((response) => {
      setselectedBoss(response.data.reduce((accum, obj) => ([
        ...accum,
        {value: obj.id, label: `${obj.first_name} ${obj.last_name}`}
      ]), []));
    });

    api.get("api/auxlistas/lista_sexo").then(result => {
      setGet_sexo(result.data);
    });

    api.get("api/auxlistas/lista_estado_civil").then(result => {
      setGet_estado_civil(result.data);
    });

    api.get("api/auxlistas/lista_region").then(result => {
      setGet_region(result.data);
    });

    api.get("api/auxlistas/lista_nacionalidad").then(result => {
      setGet_nacionalidad(result.data);
    });

    api.get("api/auxlistas/lista_banco").then(result => {
      setGet_banco(result.data);
    });

    api.get("api/auxlistas/lista_cuenta_banco").then(result => {
      setGet_tipo_cuenta_banco(result.data);
    });

    api.get("api/auxlistas/lista_tipo_estudios").then(result => {
      setGet_nivel_estudios(result.data);
    });

    api.get("api/auxlistas/lista_estado_estudio").then(result => {
      setGet_estado_estudios(result.data);
    });

    api.get("api/auxlistas/lista_tipo_contrato").then(result => {
      setGet_tipo_contrato(result.data);
    });

    api.get("api/auxlistas/lista_empresa").then(result => {
      setGet_empresa(result.data);
    });

    api.get("api/auxlistas/lista_isapres").then(result => {
      setGet_nombre_isapre(result.data);
    });

    api.get("api/listaafp/").then(result => {
      setGet_afp(result.data);
    });

    api.get("api/contratosanexosficha/").then(result => {
      setGet_contratos_anexos(result.data);
    });
  }, []);




  useEffect(() => {
    api.get(`api/fichapersonal/${id}`, {
        headers: { Authorization: `JWT ${localStorage.getItem("access")}` },
      })
      .then((result) => {
        setLoading(true);

        setSexo(result.data.sexo);
        setEstado_civil(result.data.estado_civil);
        setDireccion_region(result.data.direccion_region);
        setNacionalidad(result.data.nacionalidad);
        setBanco(result.data.banco);
        setTipo_cuenta_banco(result.data.tipo_cuenta_banco);
        setNivel_estudios(result.data.nivel_estudios);
        setEstado_estudios(result.data.estado_estudios);
        setTipo_contrato(result.data.tipo_contrato);
        setEmpresa(result.data.empresa);
        setId_cargo(result.data.id_cargo);
        setNombre_isapre(result.data.nombre_isapre);
        setId_lista_afp(result.data.id_lista_afp);
        setContrato(result.data);

      });
      setLoading(false);
  }, [id]);

  useEffect(() => {

    for(let i = 0; i < get_sexo.length; i++){
      if(sexo === get_sexo[i][0]) {
        setSexo(get_sexo[i][1]);
      }
    }

    for(let i = 0; i < get_estado_civil.length; i++){
      if(estado_civil === get_estado_civil[i][0]) {
        setEstado_civil(get_estado_civil[i][1])
      }
    }

    for(let i = 0; i < get_region.length; i++){
      if(direccion_region === get_region[i][0]) {
        setDireccion_region(get_region[i][1])
      }
    }

    for(let i = 0; i < get_nacionalidad.length; i++){
      if(nacionalidad === get_nacionalidad[i][0]) {
        setNacionalidad(get_nacionalidad[i][1]);
      }
    }

    for(let i = 0; i < get_banco.length; i++){
      if(banco === get_banco[i][0]) {
        setBanco(get_banco[i][1]);
      }
    }

    for(let i = 0; i < get_tipo_cuenta_banco.length; i++){
      if(tipo_cuenta_banco === get_tipo_cuenta_banco[i][0]) {
        setTipo_cuenta_banco(get_tipo_cuenta_banco[i][1])
      }
    }

    for(let i = 0; i < get_nivel_estudios.length; i++){
      if(nivel_estudios === get_nivel_estudios[i][0]) {
        setNivel_estudios(get_nivel_estudios[i][1]);
      }
    }

    for(let i = 0; i < get_estado_estudios.length; i++){
      if(estado_estudios === get_estado_estudios[i][0]){
        setEstado_estudios(get_estado_estudios[i][1]);
      }
    }

    for(let i = 0; i < get_tipo_contrato.length; i++){
      if(tipo_contrato === get_tipo_contrato[i][0]) {
        setTipo_contrato(get_tipo_contrato[i][1]);
      }
    }

    for(let i = 0; i < get_empresa.length; i++){
      if(empresa === get_empresa[i][0]) {
        setEmpresa(get_empresa[i][1]);
      }
    }

    for(let i = 0; i < get_cargo.length; i++){
      if(id_cargo === get_cargo[i].id) {
        setId_cargo(get_cargo[i].nombre);
        setId_cargo(get_cargo[i].nombre);
        setArea(get_cargo[i].gerencia);
      }
    }

    for(let i = 0; i < get_nombre_isapre.length; i++){
      if(nombre_isapre === get_nombre_isapre[i][0]) {
        setNombre_isapre(get_nombre_isapre[i][1]);
      }
    }

    for(let i = 0; i < get_afp.length; i++){
      if(id_lista_afp === get_afp[i].id ) {
        setId_lista_afp(get_afp[i].nombre);
      }
    }
  });


  const handleRemove = () => {
    api.delete(`api/fichapersonal/${id}`, {
        headers: { Authorization: `JWT ${localStorage.getItem("access")}` },
      })
      .then((result) => {
        props.history.push("/admin/dashboard");
        alert("Eliminación exitosa");
        api
        .put(`api/solicitudesrealizadas/${localStorage.getItem("request_id")}`, {
          id_cuenta_gestionando: localStorage.getItem("user_id"),
          id_tipo_solicitud: localStorage.getItem("request_type")
        })
        .then(resp => {
          console.log(resp);
        })
        .catch(err => {
          console.log(err.response);
        })
      });
  };

  const id_ficha = parseInt(id, 10);



  return (
    <GridContainer>
      <ViewTitle title="ELIMINAR FICHA PERSONAL" message="A continuación podrás eliminar esta ficha personal" />
      { loading ?
          <GridItem>
            <GridItem container direction="row" justify="flex-end" alignItems="center" >
              <Button onClick={handleRemove} color="danger">Eliminar</Button>
              <Button onClick={() => props.history.goBack()} style={{backgroundColor: '#1B9CFC'}}>
                <svg class="bi bi-arrow-return-left" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M5.854 5.646a.5.5 0 010 .708L3.207 9l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z" clip-rule="evenodd"/>
                  <path fill-rule="evenodd" d="M13.5 2.5a.5.5 0 01.5.5v4a2.5 2.5 0 01-2.5 2.5H3a.5.5 0 010-1h8.5A1.5 1.5 0 0013 7V3a.5.5 0 01.5-.5z" clip-rule="evenodd"/>
                </svg>
                Volver
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6} md={12}>
              <Card>
                <CardTitle title="DATOS PERSONALES" class={classes.cardTitle} />
                <GridItem container direction="row" justify="flex-end" alignItems="center" >
                  <span>Estado de ficha personal: </span> <span className="text-uppercase"><mark><strong>{contrato?.status}</strong></mark></span>
                </GridItem>
                <CardBody>
                  <div className="form-row">
                    <DetailText name="APELLIDO PATERNO:" field={contrato?.apellido_paterno} />
                    <DetailText name="APELLIDO MATERNO:" field={contrato?.apellido_materno} />
                    <DetailText name="NOMBRES:" field={contrato?.nombres} />
                    <DetailText name="RUT:" field={contrato?.rut} />
                    <DetailText name="SEXO:" field={sexo} />
                    <DetailText name="ESTADO CIVIL:" field={estado_civil} />
                    <DetailText name="DIRECCIÓN:" field={contrato?.direccion_calle} />
                    <DetailText name="NÚMERO:" field={contrato?.direccion_numero} />
                    <DetailText name="BLOCK:" field={contrato?.direccion_block} />
                    <DetailText name="DEPARTAMENTO/CASA:" field={contrato?.direccion_departamento} />
                    <DetailText name="CIUDAD:" field={contrato?.ciudad} />
                    <DetailText name="COMUNA:" field={contrato?.direccion_comuna} />
                    <DetailText name="REGIÓN:" field={direccion_region} />
                    <DetailText name="CÓDIGO POSTAL:" field={contrato?.codigo_postal} />
                    <DetailText name="NACIONALIDAD:" field={nacionalidad} />
                    <DetailText name="FECHA DE NACIMIENTO:" field={contrato?.nacimiento} />
                    <DetailText name="TELÉFONO FIJO:" field={contrato?.telefono_fijo} />
                    <DetailText name="TELÉFONO CELULAR:" field={contrato?.telefono_celular} />
                    <DetailText name="EMAIL:" field={contrato?.email} />
                    <DetailText name="EMAIL:" field={contrato?.email_institucional} />
                    <DetailText name="BANCO:" field={banco} />
                    <DetailText name="NÚMERO DE CUENTA:" field={contrato?.cuenta_banco} />
                    <DetailText name="TIPO DE CUENTA:" field={tipo_cuenta_banco} />
                    <DetailText name="CCAF ANTERIOR:" field={contrato?.ccaf_anterior} />
                    <DetailText name="NÚMERO DE ASIGNACIÓN FAMILIAR:" field={contrato?.n_asignacion_familiar} />
                    <DetailText name="ESTUDIOS:" field={nivel_estudios} />
                    <DetailText name="ESTADO DE ESTUDIOS:" field={estado_estudios} />
                  </div>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} md={12}>
              <Card>
                <CardTitle title="CONDICIONES DE INGRESO" class={classes.cardTitle} />
                <CardBody>
                  <div className="form-row">
                    <DetailText name="FECHA DE INICIO CONTRATO:" field={contrato?.fecha_inicio_contrato} />
                    <DetailText name="FECHA DE FIN CONTRATO:" field={contrato?.fecha_fin_contrato} />
                    <DetailText name="TIPO DE CONTRATO:" field={tipo_contrato} />
                    <DetailText name="EMPRESA:" field={empresa} />
                    <DetailText name="CARGO:" field={id_cargo} />
                    <DetailText name="GERENCIA:" field={area} />
                    <Grid Item xs={12}>
                      <DetailSelect2
                        label="Jefatura"
                        isMulti={true}
                        value={selectedBoss}
                        list={[]}
                        validation={false}
                        onchange={() => {}}
                        isDisabled={true}
                      />
                    </Grid>
                    <DetailText name="JORNADA LABORAL:" field={contrato?.jornada_laboral} />
                    <DetailMoney name="SUELDO BASE:" field={contrato?.sueldo_base} />
                    <DetailText name="TEXTO SUELDO BASE:" field={contrato?.texto_sueldo_base} />
                    <DetailMoney name="GRATIFICACIÓN:" field={contrato?.gratificacion} />
                    <DetailText name="TEXTO GRATIFICACIÓN:" field={contrato?.texto_gratificacion} />
                    <DetailMoney name="BONO DE RESPONSABILIDAD:" field={contrato?.bono_responsabilidad} />
                    <DetailText name="TEXTO BONO RESPONSABILIDAD:" field={contrato?.texto_responsabilidad} />
                    <DetailMoney name="BONO COLACIÓN:" field={contrato?.bono_colacion} />
                    <DetailText name="TEXTO BONO COLACIÓN:" field={contrato?.texto_colacion} />
                    <DetailMoney name="BONO MOVILIZACIÓN:" field={contrato?.bono_movilizacion} />
                    <DetailText name="TEXTO BONO MOVILIZACIÓN:" field={contrato?.texto_movilizacion} />
                    <DetailMoney name="ASIGNACIÓN CAJA" field={contrato?.asignacion_caja} />
                    <DetailText name="TEXTO ASIGNACIÓN CAJA:" field={contrato?.texto_caja} />
                    <DetailMoney name="BONO RESPONSABILIDAD INVENTARIO:" field={contrato?.bono_responsabilidad_inventario} />
                    <DetailText name="TEXTO BONO RESPONSABILIDAD INVENTARIO:" field={contrato?.texto_responsabilidad_inventario} />
                    <DetailMoney name="VIÁTICOS:" field={contrato?.viaticos} />
                    <DetailText name="TEXTO VIÁTICOS:" field={contrato?.texto_viaticos} />
                    <DetailText name="SEGURO COMPLEMENTARIO:" field={contrato?.seguro_complementario} />
                    <DetailText name="SEGURO DE CESANTÍA:" field={contrato?.seguro_cesantia} />
                    <DetailMoney name="BONO SALA CUNA:" field={contrato?.bono_sala_cuna} />
                    <DetailText name="TEXTO BONO SALA CUNA:" field={contrato?.texto_sala_cuna} />
                    <DetailMoney name="DESGASTE DE HERRAMIENTAS:" field={contrato?.desgaste_herramientas} />
                    <DetailText name="TEXTO DESGASTE DE HERRAMIENTAS:" field={contrato?.texto_herramientas} />
                    <DetailBoolean name="ASIGNACIÓN TELÉFONO:" field={contrato?.asignacion_telefono} />
                    <DetailBoolean name="ASIGNACIÓN UNIFORME:" field={contrato?.asignacion_uniforme} />
                    <DetailBoolean name="ASIGNACIÓN EQUIPO:" field={contrato?.asignacion_equipo} />
                    <DetailText name="NOMBRE ISAPRE:" field={contrato?.nombre_isapre} />
                    <DetailText name="VALOR PACTADO ISAPRE:" field={contrato?.valor_pactado_isapre} />
                    <DetailMoney name="VALOR TURNO TENS:" field={contrato?.valor_turno_tens} />
                    <DetailText name="TEXTO VALOR TURNO TENS:" field={contrato?.texto_turno_tens} />
                    <DetailMoney name="VALOR ORIENTACIÓN:" field={contrato?.valor_orientacion} />
                    <DetailText name="TEXTO VALOR ORIENTACIÓN:" field={contrato?.texto_orientacion} />
                    <DetailMoney name="TOTAL IMPONIBLE:" field={contrato?.total_imponible} />
                    <DetailText name="TEXTO TOTAL IMPONIBLE:" field={contrato?.texto_imponible} />
                    <DetailMoney name="TOTAL HABERES:" field={contrato?.total_haberes} />
                    <DetailText name="TEXTO TOTAL HABERES:" field={contrato?.texto_haberes} />
                    <DetailMoney name="TOTAL LÍQUIDO:" field={contrato?.total_liquido} />
                    <DetailText name="TEXTO TOTAL LÍQUIDO:" field={contrato?.texto_liquido} />
                    <DetailTextArea name="FUNCIONES:" field={contrato?.funciones} />
                    <DetailText name="AFP:" field={id_lista_afp} />
                  </div>
                </CardBody>
              </Card>
            </GridItem>

        <GridItem xs={12} sm={6} md={12}>
          <Card>
            <CardTitle title="ARCHIVOS" class={classes.cardTitle} />
            <CardBody>
              <GridContainer>
                <DetailFile name="CERTIFICADO ANTECEDENTES:" link={contrato?.certificado_antecedentes} />
                <DetailFile name="FOTOCOPIA CARNET IDENTIDAD:" link={contrato?.fotocopia_ci} />
                <DetailFile name="HOJA DE VIDA CONDUCTOR" link={contrato?.hoja_vida_conductor} />
                <DetailFile name="CERTIFICADO FONASA/ISAPRE:" link={contrato?.certificado_isapre_fonasa} />
                <DetailFile name="CERTIFICADO AFP:" link={contrato?.certificado_afp} />
                <DetailFile name="CURRÍCULUM VITAE:" link={contrato?.curriculum} />
                <DetailFile name="INSCRIP. SUPERINTENDENCIA SALUD:" link={contrato?.inscripcion_super_salud} />
                <DetailFile name="VACUNA HEPATITIS:" link={contrato?.vacuna_hepatitis} />

                {
                  get_contratos_anexos ?
                    get_contratos_anexos
                      .filter(contrato => contrato.id_ficha_personal === id_ficha)
                      .map(contrato =>
                        <DetailFileContrato name={contrato.nombre_archivo} type={contrato.tipo} link={contrato.archivo} />
                      )
                      :
                    null
                }
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridItem>
      :
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardTitle title="Cargando información ..." class={classes.cardTitle}/>
          <CardBody className="d-flex justify-content-center">
            <LoadingSpinner />
          </CardBody>
        </Card>
      </GridItem>
      }
    </GridContainer>
  );
};

export default DeleteContrato;
