import React from "react";
import NumberFormat from 'react-number-format';

const DetailNumber = props => {

  const writtenNumber = require('written-number');
  writtenNumber.defaults.lang = 'es';

  return(
    <div className="col-md-12 mb-3" style={props.styleDiv ? props.styleDiv : {}}>
       <label className="text-dark">
        {props?.label}
        {((props?.value === "" || props?.defaultValue === "") && props.validation !== false) ? (
          <span className="text-danger"> no puede ser vacío</span>
        ) : (props?.error && props.validation !== false) ? 
          <span className="text-danger"> {props?.error} </span> : null}
      </label>
      <NumberFormat 
        disabled={props.isdisabled}
        allowNegative={false}
        thousandSeparator={props.thousandSeparator ? props.thousandSeparator : false} 
        placeholder={props.placeholder ? props.placeholder : ""}
        format={props.format ? props.format : false}
        mask={props.mask ? props.mask : ""}
        value={props.value} 
        onChange={props?.onchange}
        prefix={props.prefix ? props.prefix : "" } 
        isNumericString={props.isNumericString ? props.isNumericString : false}
        className={
          ((props?.value === "" || props?.defaultValue === "") && props.validation !== false)
            ? "form-control bg-danger font-weight-lighter text-white"
            : "form-control bg-light font-weight-lighter text-dark"
        }
        style={
          ((props?.value === "" || props?.defaultValue === "") && props.validation !== false) ? { color: "#FFFFFF" } : { color: "#000000" }
        }
      />
      
    </div>
  );
}

export default DetailNumber;