import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Calendar, momentLocalizer } from "react-big-calendar";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  Typography,
  Breadcrumbs,
  Link,
  FormControl,
  Grid,
  withWidth,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import {
  indigo,
} from "@material-ui/core/colors";
import { Search, Today, Toc, Event, Room, ExpandMore } from "@material-ui/icons";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import FilterBox from "views/Widgets/Transport/FilterBox";
import ModalDetailResourcesDriver from "components/Transport/ModalDetailResourcesDriver";
import ModalDetailRefuseDriver from "components/Transport/ModalDetailRefuseDriver";
import ModalDetails from "components/Transport/ModalDetails";
import ModalDispatchGuide from "components/Transport/ModalDispatchGuide";
import ModalStartTravel from "components/Transport/ModalStartTravel";
import ModalEventTravel from "components/Transport/ModalEventTravel";
import ModalDeliveryGuide from "components/Transport/ModalDeliveryGuide";
import ModalRetirementGuide from "components/Transport/ModalRetirementGuide";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import TableResponsive from "views/Widgets/Common/TableResponsive";
import { setmessageAlert } from "actions/getGlobalAction";

import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../styles/Styles.css"

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);
const classStyle = makeStyles((theme) => ({
  text: {
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    "&:hover": {
      color: indigo[400],
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const TransportDriver = ({ setopenDetail, width }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const classesStyle = classStyle();
  const [open, setopen] = useState(null);
  const [loading, setloading] = useState(false);
  const [initialCharge, setinitialCharge] = useState(true);
  const [filter, setfilter] = useState("");
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [rowsActive, setrowsActive] = useState([]);
  const [rowsFinished, serowsFinished] = useState([]);
  const [arrayFilterStateButtons, setarrayFilterStateButtons] = useState([]);
  const [
    arrayFilterStateButtonsFinished,
    setarrayFilterStateButtonsFinished,
  ] = useState([]);
  const [arrayFilterZoneButtons, setarrayFilterZoneButtons] = useState([]);
  const [changeButtons, setchangeButtons] = useState(true);
  const [driverDict, setdriverDict] = useState({});
  const [vehicleDict, setvehicleDict] = useState({});
  const [patientDict, setpatientDict] = useState({});
  const [typeVehicleDict, settypeVehicleDict] = useState({});
  const [rowDetail, setrowDetail] = useState(null);
  const [withdrawOrder, setwithdrawOrder] = useState(null);
  const [rowRefuse, setrowRefuse] = useState(null);
  const [dispatchGuide, setdispatchGuide] = useState(null);
  const [startTravel, setstartTravel] = useState(null);
  const [eventTravel, seteventTravel] = useState(null);
  const [retirementGuide, setretirementGuide] = useState(null);
  const [deliveryGuide, setdeliveryGuide] = useState(null);
  const [viewInfo, setviewInfo] = useState("Table");
  const [infoRequest, setinfoRequest] = useState([]);
  const [message, setmessage] = useState(null);
  const [events, setevents] = useState([]);

  const [selectDate, setselectDate] = useState(moment());

  const stateFilter = [
    "Ir a retirar pedido",
    "Adjuntar guia de despacho",
    "Iniciar viaje",
    "en ruta",
    "Adjuntar guia de entrega",
    "Adjuntar guia de retiro",
  ];

  const [getProducts, setGetProducts] = useState([]);
  const [timeLine, setTimeLine] = useState([]);
  const [getProductsComplete, setGetProductsComplete] = useState([]);
  const [getProductsOther, setGetProductsOther] = useState([]);
  const [boxNumber, setBoxNumber] = useState(0);
  const [expanded, setexpanded] = useState(null);
  const [changes, setChanges] = useState(true);

  const stateFilterFinished = ["Entregado", "Retrasado", "Finalizado"];

  useEffect(() => {
    if (changeButtons) {
      setchangeButtons(false);
    }
    if (initialCharge) {
      initialData();
    }
  });

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  }, [changes]);

  useEffect(() => {
    setInterval(() => setChanges(true), 300000);
  }, []);

  const initialChargeReception = () => {
    setChanges(false);
    api.get("api/transporte/solicitudes/login/Activas").then(response => {
      let newData = response.data
      const oldInprogress = rowsActive.map((row) => {return row.id})
      const newInprogress = newData.filter(newItem => !oldInprogress.includes(newItem.id));
      let  message = ""
      if (oldInprogress.length == 0 && newInprogress.length > 0){
        message = `Tiene ${newInprogress.length} solicitudes que requieren su atención`;
      } else if (newInprogress.length > 0){
        const ids = newInprogress.reduce((accum, obj) => {
          const id = obj?.id_parada_traslado?.id_traslado_paciente?.id_solicitud_paciente || obj?.id_envios_pedidos?.id_solicitud_pedido
          return `${accum}, ${id}`
        }, "")
        message = `Tiene ${newInprogress.length} solicitudes nuevas que requieren su atención (${ids})`;
      }
      setmessageAlert(dispatch, message, "success" )
      setrowsActive(newData);
      setloading(true);
    });
  };


  const dictSTValueLabel = useSelector(state => state.currentList.DICCIONARIO_ESTADOS_TRANSPORTES);

  const initialData = () => {
    setinitialCharge(false);
    const requestFinished = api.get("api/transporte/solicitudes/login/Finalizadas");
    const requestDrivers = api.get("api/transporte/solicitudes/despacho/conductores/");
    const requestVehicle = api.get("api/vehiculos/");
    const requestTypeVehicle = api.get("api/insumosmedicamento/Vehiculos");
    const requestPatient = api.get("api/paciente");
    Promise.all([
      requestFinished,
      requestDrivers,
      requestVehicle,
      requestPatient,
      requestTypeVehicle,
    ]).then((request) => {
      const tempValueLabel = {};
      const tempfinishedRow = request[0].data;
      // cambiar fecha_solicitud por fecha_llegada o algo por el estilo cuando se habilite
      request[4].data.map((row) => {
        tempValueLabel[row[0]] = row[1];
      });
      
      serowsFinished(tempfinishedRow);
      setdriverDict(
        request[1].data.reduce(
          (accum, obj) => ({
            ...accum,
            [obj.id]: obj,
          }),
          {}
        )
      );
      setvehicleDict(
        request[2].data.reduce(
          (accum, obj) => ({
            ...accum,
            [obj.id]: obj,
          }),
          {}
        )
      );
      setpatientDict(
        request[3].data.reduce(
          (accum, obj) => ({
            ...accum,
            [obj.id]: obj,
          }),
          {}
        )
      );
      settypeVehicleDict(
        request[4].data.reduce(
          (accum, obj) => ({
            ...accum,
            [obj.id]: obj,
          }),
          {}
        )
      );
      setloading(true);
    });
  };


  const generateTitle = (patientInfo, chargeInfo, state) => {
    const patientName = patientInfo
      ? `${patientInfo.nombre} ${patientInfo.apellido_paterno} ${patientInfo.apellido_materno}`
      : "No disponible";
    const driverName = chargeInfo
      ? `${chargeInfo.first_name} ${chargeInfo.last_name}`
      : "No disponible";
    return `
      Paciente: ${patientName},
      Chofer: ${driverName},
      Estado: ${state}.
    `;
  };

  const handleFilterStateButtons = button => {
    let tempArray = arrayFilterStateButtons;
    if (arrayFilterStateButtons.includes(button)) {
      tempArray = arrayFilterStateButtons.filter((row) => row != button);
    } else {
      tempArray.push(button);
    }
    setarrayFilterStateButtons(tempArray);
    setchangeButtons(true);
  };

  const handleFilterStateButtonsFinished = (button) => {
    let tempArray = arrayFilterStateButtonsFinished;
    if (arrayFilterStateButtonsFinished.includes(button)) {
      tempArray = arrayFilterStateButtonsFinished.filter(row => row != button);
    } else {
      tempArray.push(button);
    }
    setarrayFilterStateButtonsFinished(tempArray);
    setchangeButtons(true);
  };

  const handleFilterZoneButtons = button => {
    let tempArray = arrayFilterZoneButtons;
    if (arrayFilterZoneButtons.includes(button)) {
      tempArray = arrayFilterZoneButtons.filter(row => row != button);
    } else {
      tempArray.push(button);
    }
    setarrayFilterZoneButtons(tempArray);
    setchangeButtons(true);
  };


  const handleDetailRefuse = () => {
    if (withdrawOrder) {
      setrowRefuse(withdrawOrder);
      setwithdrawOrder(null);
    } else {
      setwithdrawOrder(rowRefuse);
      setrowRefuse(null);
    }
  };

  const handleDetail = (value, set) => {
    if (value[0].id_envios_pedidos) {
      setBoxNumber(value[0].id_envios_pedidos.numero_pedido);
    }

    setmessage("Cargando solicitud");
    if (value[0].id_envios_pedidos) {
      const getTransportInfo = api.get(`/api/transporte/solicitudes/${value[0].id_envios_pedidos.id}/detalle/`);
      const getRequest = api.get(`api/bodega/pedidos/grupo/Todos/detalle/${value[0].id_envios_pedidos.id_solicitud_pedido}`);
      const getTimeLine = api.get(`api/bodega/pedidos/grupo/Todos/detalle/${value[0].id_envios_pedidos.id_solicitud_pedido}/timeline/`);

      Promise.all([getTransportInfo, getRequest, getTimeLine]).then(response => {
          const requestFilter = response[1].data.filter(filtered =>
            (filtered.estado === "Incompleta" && filtered.reemplazado === false) || (filtered.estado === "Completa" && filtered.picking > 0)
          );

          setGetProducts([...requestFilter]);
          setGetProductsComplete(
            response[1].data.filter(
              filtered =>
                filtered.estado === "Completa" && filtered.picking === 0
            )
          );
          setGetProductsOther(
            response[1].data.filter(
              filtered =>
                filtered.estado != "Incompleta" && filtered.estado != "Completa"
            )
          );

          setTimeLine(response[2].data);
          setinfoRequest(response[0].data);
          set(value);

          setmessage(null);
        })
        .catch(error => console.log("Error ", error.response));
    } else if (value[0].id_parada_traslado_ingreso) {
      api.get(`api/ingresos/solicitud/${value[0].id_parada_traslado_ingreso.id_solicitud_ingreso.id}/productos/`).then(response => {
          setinfoRequest(response.data);
          setTimeLine(response.data);
          set(value);

          setmessage(null);
        }).catch(error => console.log("Error ", error.response));
    } else {
      set(value);
      setTimeLine([]);
      setBoxNumber(0);

      setmessage(null);
    }
  };

  const eventColors = event => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };

  const handleDetailAction = (row, index) => {
    if (row.estado_recepcion === "IniciarViaje" || (row.estado_recepcion === "Asignado" && row.tipo_solicitud === "Tramite")) {
      handleDetail([row, index], setstartTravel);
    } else if (row.estado_recepcion === "RetirarPedido" || row.estado_recepcion === "Asignado") {
      handleDetail([row, index], setwithdrawOrder);
    } else if (row.estado_recepcion === "GuiaDespacho") {
      handleDetail([row, index], setdispatchGuide);
    } else if (row.estado_recepcion === "EnRuta") {
      handleDetail([row, index], seteventTravel);
    } else if (row.estado_recepcion === "GuiaEntregaRetrasado" || row.estado_recepcion === "GuiaEntregaEntregado") {
      handleDetail([row, index], setdeliveryGuide);
    } else if (row.estado_recepcion === "GuiaEntregaRetirado") {
      handleDetail([row, index], setretirementGuide);
    }
  };

  const functionFilter = (row) => {
    const patientInfo = patientDict[row.id_paciente]
    const driverInfo = driverDict[row.id_conductor]
    const vehicleInfo = vehicleDict[row.id_vehiculo]
    const stateValueInfo = dictSTValueLabel[row.estado_recepcion]
    let filterStringResult = true
    let filterButtonState = true
    let filterButtonZone = true
    let filterStartDate = true
    let filterEndDate = true
    let filterRangeDate = true
    if (filter != ""){
      filterStringResult =(
        row["comuna"].toLowerCase().includes(filter.toLowerCase()) ||
        row["tipo_solicitud"].toLowerCase().includes(filter.toLowerCase()) ||
        patientInfo?.nombre.toLowerCase().includes(filter.toLowerCase()) ||
        patientInfo?.apellido_paterno.toLowerCase().includes(filter.toLowerCase()) ||
        patientInfo?.apellido_materno.toLowerCase().includes(filter.toLowerCase()) ||
        driverInfo?.first_name.toLowerCase().includes(filter.toLowerCase()) ||
        driverInfo?.last_name.toLowerCase().includes(filter.toLowerCase()) ||
        vehicleInfo?.patente.toLowerCase().includes(filter.toLowerCase())
      )
    }

    if (arrayFilterStateButtons.length > 0){
      filterButtonState = arrayFilterStateButtons.includes(stateValueInfo?.label || "")
    } 
    if (arrayFilterZoneButtons.length > 0){
      filterButtonZone = arrayFilterZoneButtons.includes(stateValueInfo?.label || "")
    }  
    if (startDate){
      filterStartDate = moment(startDate).diff(moment(row.hora_estimada || row.fecha_solicitud), "days") <= 0
    }
    if (endDate){
      filterEndDate =  moment(endDate).diff(moment(row.hora_estimada || row.fecha_solicitud ), "days") >= 0
    }
    
    filterRangeDate =  moment(startDate).diff(moment(row.hora_estimada || row.fecha_solicitud), "days") <= 0 && moment(endDate).diff(moment(row.hora_estimada || row.fecha_solicitud), "days") >= 0
    const dateFilter =  (startDate && endDate) ? filterRangeDate : (filterStartDate && filterEndDate)

    return filterStringResult && filterButtonState && filterButtonZone && dateFilter
  }

  const hiddingCol = width == "xs" || width == "sm" || width == "md"

  const headerInProgres = [
    {hidding:hiddingCol, label: "Solicitud", style:{minWidth: "90px"}},
    {hidding:hiddingCol, label: "Envio", style:{minWidth: "85px"}},
    {hidding:hiddingCol, label: "Zona", style:{minWidth: "85px"}},
    {hidding:hiddingCol, label: "Comuna", style:{minWidth: "85px"}},
    {label: "Tipo de solicitud", style:{minWidth: "150px"}},
    {label: "Paciente", style:{minWidth: "200px"}},
    {hidding:hiddingCol, label: "Conductor", style:{minWidth: "200px"}},
    {hidding:hiddingCol, label: "Copiloto", style:{minWidth: "200px"}},
    {hidding:hiddingCol, label: "Vehículo", style:{minWidth: "90px"}},
    {hidding:hiddingCol, label: "Fecha a despachar", style:{minWidth: "150px"}},
    {hidding:hiddingCol, label: "Fecha llegada", style:{minWidth: "150px"}},
    {hidding:hiddingCol, label: "Farmacia", style:{minWidth: "90px"}},
    {label: "Acción"},
    {eval: false, label: "Info"},
  ]

  const headerEnd = [
    {hidding:hiddingCol, label: "Solicitud", style:{minWidth: "90px"}},
    {hidding:hiddingCol, label: "Envio", style:{minWidth: "85px"}},
    {hidding:hiddingCol, label: "Zona", style:{minWidth: "85px"}},
    {hidding:hiddingCol,label: "Comuna", style:{minWidth: "85px"}},
    {label: "Tipo de solicitud", style:{minWidth: "160px"}},
    {label: "Paciente", style:{minWidth: "200px"}},
    {hidding:hiddingCol, label: "Conductor", style:{minWidth: "200px"}},
    {hidding:hiddingCol, label: "Copiloto", style:{minWidth: "200px"}},
    {hidding:hiddingCol, label: "Vehículo", style:{minWidth: "90px"}},
    {hidding:hiddingCol, label: "Fecha llegada", style:{minWidth: "150px"}},
    {hidding:hiddingCol, label: "Fecha llegada", style:{minWidth: "150px"}},
    {hidding:hiddingCol, label: "Farmacia", style:{minWidth: "90px"}},
    {label: "Estado recepción", style:{minWidth: "160px"}},
    {eval: false, label: "Acción"},
  ]
  
  const inProgresItem = rowsActive.filter((row) => functionFilter(row)).map((row, index) => {

    const idRequest = row.id_envios_pedidos ? `solicitud pedido: ${row.id_envios_pedidos.id_solicitud_pedido}`: 
      row.id_parada_traslado ? `solicitud paciente: ${row.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}` :
      row.id_parada_traslado_ingreso ? `solicitud ingreso: ${row?.id_parada_traslado_ingreso?.id_solicitud_ingreso.id}` : 
      `Tramite ${row.titulo} ${row.id}`
    const numberPackage =  row.id_envios_pedidos
      ? row.id_envios_pedidos.numero_pedido
      : "-";

    const dateRequest = row.fecha_solicitud ? moment(row.fecha_solicitud).format("YYYY-MM-DD HH:mm"):
      row.id_parada_traslado ? moment(row.id_parada_traslado.fecha_hora).format("YYYY-MM-DD HH:mm") : null;
    const patientName = row.id_paciente
      ? `${patientDict[row.id_paciente]?.nombre} ${patientDict[row.id_paciente]?.apellido_paterno} ${patientDict[row.id_paciente]?.apellido_materno}`
      : "";

    const driverName = row.id_conductor
      ? `${driverDict[row.id_conductor]?.first_name} ${driverDict[row.id_conductor]?.last_name}`
      : "-";

    const copilotName = driverDict[row.id_copiloto] ? `${driverDict[row.id_copiloto]?.first_name} ${driverDict[row.id_copiloto]?.last_name}` : "-";
    const idVehicle= vehicleDict[row.id_vehiculo]?.patente || "-";
    const tentativeDate =  row.fecha_tentativa_llegada ? moment(row.fecha_tentativa_llegada).format("YYYY-MM-DD HH:mm"): "-"

    const isFarmace = row.id_envios_pedidos ? row.id_envios_pedidos.es_farmacia
        ? "Sí"
        : "No"
      : row.es_farmacia != null ? row.es_farmacia === true
        ? "Sí"
        : "No"
      : "-"

    const stateRequest =   (row.estado_recepcion === "Asignado" && row.tipo_solicitud === "Tramite") ? "Finalizar" :
      (row.estado_recepcion === "Asignado" && row.tipo_solicitud !== "SolicitudEntregaRetiroEquipo") ? "Ir a retirar pedido" :
      (row.estado_recepcion === "Asignado" && row.tipo_solicitud === "SolicitudEntregaRetiroEquipo") ? "Ir a entregar pedido":
      dictSTValueLabel[row.estado_recepcion]?.label;

    const _buttonAction_ = <Button
      style={{ textTransform: "none" }}
      variant="contained"
      color="primary"
      onClick={() => handleDetailAction(row, index)}
    >
      {stateRequest}
    </Button>

    const _buttonInformation_ = <Button
        style={{ textTransform: "none" }}
        variant="contained"
        color="primary"
        onClick={() =>
          handleDetail([row, index], setrowDetail)
        }
      >
        Información
      </Button>
    //eval is not use in table only condition to filter
    return [
      {hidding:hiddingCol, eval:true, value: idRequest},
      {hidding:hiddingCol, eval:true, value: numberPackage },
      {hidding:hiddingCol, eval:true, value: row.zona},
      {hidding:hiddingCol, eval:true, value: row.comuna},
      {eval:true, value: row.tipo_solicitud},
      {eval:true, value: patientName},
      {hidding:hiddingCol, eval:true, value: driverName},
      {hidding:hiddingCol, eval:true, value: copilotName},
      {hidding:hiddingCol, eval:true, value: idVehicle},
      {hidding:hiddingCol, eval:true, value: dateRequest},
      {hidding:hiddingCol, eval:true, value: tentativeDate},
      {hidding:hiddingCol, eval:true, value: isFarmace},
      {eval:true, value: stateRequest, _value_: _buttonAction_},
      {eval:false, _value_: _buttonInformation_},
    ]
  })

  const endProgresItem = rowsFinished.filter((row) => functionFilter(row)).map((row, index) => {

    const idRequest = row.id_envios_pedidos
      ? `solicitud pedido: ${row.id_envios_pedidos.id_solicitud_pedido}`
      : row.id_parada_traslado
      ? `solicitud paciente: ${row.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}`
      : row.id_parada_traslado_ingreso ? `Solicitud ingreso: ${row?.id_parada_traslado_ingreso?.id_solicitud_ingreso.id}` : " ";

    const numberPackage =  row.id_envios_pedidos
      ? row.id_envios_pedidos.numero_pedido
      : "-";

    const dateRequest = row.fecha_solicitud ? moment(row.fecha_solicitud).format("YYYY-MM-DD HH:mm") :
      row.id_parada_traslado ? moment(row.id_parada_traslado.fecha_hora).format("YYYY-MM-DD HH:mm") : null;

    const patientName = row.id_paciente
      ? `${patientDict[row.id_paciente]?.nombre} ${patientDict[row.id_paciente]?.apellido_paterno} ${patientDict[row.id_paciente]?.apellido_materno}`
      : "";

    const driverName = driverDict[row.id_conductor] ? `${driverDict[row.id_conductor]?.first_name} ${driverDict[row.id_conductor]?.last_name}` : "-";

    const copilotName = driverDict[row.id_copiloto] ? `${driverDict[row.id_copiloto]?.first_name} ${driverDict[row.id_copiloto]?.last_name}` : "-";
    const idVehicle= vehicleDict[row.id_vehiculo]?.patente || "-";
    const tentativeDate =  row.fecha_tentativa_llegada ? moment(row.fecha_tentativa_llegada).format("YYYY-MM-DD HH:mm"): "-"

    const isFarmace = row.id_envios_pedidos ? row.id_envios_pedidos.es_farmacia
        ? "Sí"
        : "No"
      : row.es_farmacia != null ? row.es_farmacia === true
        ? "Sí"
        : "No"
      : "-"

    const stateRequest =  row.estado_recepcion === "Asignado" && row.tipo_solicitud !== "SolicitudEntregaRetiroEquipo"
      ? "Ir a retirar pedido"
      : row.estado_recepcion === "Asignado" && row.tipo_solicitud === "SolicitudEntregaRetiroEquipo"
      ? "Ir a entregar pedido"
      : dictSTValueLabel[row.estado_recepcion]?.label;

    const _buttonInformation_ = <Button
        style={{ textTransform: "none" }}
        variant="contained"
        color="primary"
        onClick={() =>
          handleDetail([row, index], setrowDetail)
        }
      >
        Información
      </Button>
    //eval is not use in table only condition to filter
    return [
      {hidding:hiddingCol, eval:true, value: idRequest},
      {hidding:hiddingCol, eval:true, value: numberPackage},
      {hidding:hiddingCol, eval:true, value: row.zona},
      {hidding:hiddingCol, eval:true, value: row.comuna},
      {eval:true, value: row.tipo_solicitud},
      {eval:true, value: patientName},
      {hidding:hiddingCol, eval:true, value: driverName},
      {hidding:hiddingCol, eval:true, value: copilotName},
      {hidding:hiddingCol, eval:true, value: idVehicle},
      {hidding:hiddingCol, eval:true, value: dateRequest},
      {hidding:hiddingCol, eval:true, value: tentativeDate},
      {hidding:hiddingCol, eval:true, value: isFarmace},
      {eval:true, value: stateRequest},
      {eval:false, _value_: _buttonInformation_},
    ]
  })

  return (
    <div>
      {open ? (
        <div></div>
      ) : (
        <GridContainer>
          {loading ? (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <CardBody className="rounded shadow border border-primary">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      color="inherit"
                      onClick={() => setopenDetail(null)}
                      style={{ cursor: "pointer" }}
                    >
                      Tarea de Conductor
                    </Link>
                    <Typography color="textPrimary">{`Recepción de pedidos`}</Typography>
                  </Breadcrumbs>
                </CardBody>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardBody>
                    <Grid item xs={12} style={{ margin: "15px 0" }}>
                      <ExpansionPanel
                        square
                        expanded={expanded === "panel1"}
                        onChange={() =>
                          setexpanded(expanded === "panel1" ? "" : "panel1")
                        }
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className={classesStyle.title}
                        >
                          <Typography
                            className={classesStyle.text}
                            variant="body1"
                            gutterBottom
                          >
                            Filtros
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            <Grid
                              item
                              md={4}
                              sm={12}
                              style={
                                width === "xs"
                                  ? { margin: "10px 0" }
                                  : { padding: "0 10px" }
                              }
                            >
                              <FilterBox
                                filterText={filter}
                                handleFilter={(event) => setfilter(event.target.value)}
                                IconSpan={Search}
                                stylePaper={{ width: "100%" }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              style={
                                width === "xs"
                                  ? { margin: "10px 0" }
                                  : { padding: "0 10px" }
                              }
                            >
                              <Card>
                                <CardHeader color="info" icon>
                                  <CardIcon color="info">
                                    <Today />
                                  </CardIcon>
                                </CardHeader>
                                <CardBody>
                                  <FormControl fullWidth>
                                    <MuiPickersUtilsProvider
                                      libInstance={moment}
                                      utils={MomentUtils}
                                    >
                                      <KeyboardDatePicker
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        margin="normal"
                                        label={"Fecha Inicio"}
                                        placeholder="Fecha Inicio"
                                        onChange={e =>setstartDate(e)}
                                        value={startDate ? moment(startDate) : null}
                                        inputVariant={"outlined"}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </FormControl>
                                </CardBody>
                              </Card>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              style={
                                width === "xs"
                                  ? { margin: "10px 0" }
                                  : { padding: "0 10px" }
                              }
                            >
                              <Card>
                                <CardHeader color="info" icon>
                                  <CardIcon color="info">
                                    <Today />
                                  </CardIcon>
                                </CardHeader>
                                <CardBody>
                                  <FormControl fullWidth>
                                    <MuiPickersUtilsProvider
                                      libInstance={moment}
                                      utils={MomentUtils}
                                    >
                                      <KeyboardDatePicker
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        margin="normal"
                                        label={"Fecha fin"}
                                        placeholder="Fecha fin"
                                        onChange={e =>setendDate(e)}
                                        value={endDate ? moment(endDate) : null}
                                        inputVariant={"outlined"}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </FormControl>
                                </CardBody>
                              </Card>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start"
                              style={{ margin: "10px 0" }}
                            >
                              <Typography color="textPrimary">{`Filtro por zonas`}</Typography>
                              <Grid
                                item
                                md={12}
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                              >
                                <div className={classes.cardContentRight}>
                                  <div className={classes.buttonGroup}>
                                    {[...Array(8)].map((e, index) => (
                                      <Button
                                        key={`Zona ${index + 1}`}
                                        color={
                                          arrayFilterZoneButtons.includes(
                                            `Zona ${index + 1}`
                                          )
                                            ? "rose"
                                            : "info"
                                        }
                                        className={classes.firstButton}
                                        onClick={() =>
                                          handleFilterZoneButtons(
                                            `Zona ${index + 1}`
                                          )
                                        }
                                      >
                                        {`Zona ${index + 1}`}
                                      </Button>
                                    ))}
                                  </div>
                                </div>
                              </Grid>
                              <Typography color="textPrimary">{`Filtro por estados`}</Typography>
                              <Grid
                                item
                                md={12}
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                              >
                                <div className={classes.cardContentRight}>
                                  <div className={classes.buttonGroup}>
                                    {stateFilter.map((row, index) => (
                                      <Button
                                        key={row}
                                        color={
                                          arrayFilterStateButtons.includes(row)
                                            ? "rose"
                                            : "info"
                                        }
                                        className={classes.firstButton}
                                        onClick={() =>
                                          handleFilterStateButtons(row)
                                        }
                                      >
                                        {row}
                                      </Button>
                                    ))}
                                    {stateFilterFinished.map((row, index) => (
                                      <Button
                                        key={row}
                                        color={
                                          arrayFilterStateButtonsFinished.includes(row)
                                            ? "rose"
                                            : "info"
                                        }
                                        className={classes.firstButton}
                                        onClick={() =>
                                          handleFilterStateButtonsFinished(row)
                                        }
                                      >
                                        {row}
                                      </Button>
                                    ))}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                    {changeButtons === false && <div></div>}
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={
                        width === "xs"
                          ? { margin: "20px 0 0 0" }
                          : { margin: "20px 0 -70px 0" }
                      }
                    >
                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        <Button
                          justIcon
                          round
                          color={viewInfo === "Table" ? "rose" : "info"}
                          style={{ zIndex: 10 }}
                          className={classes.marginRight}
                          onClick={() => setviewInfo("Table")}
                        >
                          <Toc className={classes.icons} />
                        </Button>
                        <Button
                          justIcon
                          round
                          color={viewInfo === "Calendar" ? "rose" : "info"}
                          style={{ zIndex: 10 }}
                          className={classes.marginRight}
                          onClick={() => setviewInfo("Calendar")}
                        >
                          <Event className={classes.icons} />
                        </Button>
                        <Button
                          justIcon
                          round
                          color={viewInfo === "Map" ? "rose" : "info"}
                          className={classes.marginRight}
                          style={{ zIndex: 10 }}
                          onClick={() => setviewInfo("Map")}
                        >
                          <Room className={classes.icons} />
                        </Button>
                      </Grid>
                    </Grid>
                    {viewInfo === "Table" ? (
                      <NavPills
                        color="primary"
                        alignCenter
                        tabs={[
                          {
                            tabButton: "En curso",
                            tabContent: (
                              <TableResponsive
                                headers={headerInProgres}
                                data={inProgresItem}
                              />
                            )
                          },
                          {
                            tabButton: "Finalizado",
                            tabContent: (
                              <TableResponsive
                                headers={headerEnd}
                                data={endProgresItem}
                              />
                            )
                          },
                        ]}
                      />) : viewInfo === "Calendar" ? (
                      <CardBody calendar style={{ margin: "50px 0 0 0" }}>
                        <Calendar
                          selectable
                          localizer={localizer}
                          events={events}
                          defaultView="month"
                          date={selectDate.toDate()}
                          onSelectEvent={event => handleDetail([event.row, 0], setrowDetail)}
                          // onSelectSlot={slotInfo => addNewEventAlert(slotInfo)}
                          onSelectAgendaEvent={(event) => handleDetail([event.row, 0], setrowDetail)}
                          eventPropGetter={eventColors}
                          onNavigate={date => setselectDate(moment(date))}
                          step={30}
                          timeslots={1}
                          messages={{
                            next: "Siguiente",
                            previous: "Anterior",
                            today: "Hoy",
                            month: "Mes",
                            week: "Semana",
                            day: "Día"
                          }}
                        />
                      </CardBody>
                    ) : (
                      <div></div>
                    )}
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <LoadInfo title={"Cargando información ..."} />
          )}
          {rowDetail && (
            <ModalDetails
              open={!!rowDetail}
              handleClose={() => setrowDetail(null)}
              rowDetail={rowDetail}
              infoRequest={infoRequest}
              patientDict={patientDict}
              timeLine={timeLine}
              getProducts={getProducts}
              getProductsComplete={getProductsComplete}
              getProductsOther={getProductsOther}
              boxNumber={boxNumber}
            />
          )}
          {withdrawOrder && (
            <ModalDetailResourcesDriver
              open={!!withdrawOrder}
              handleClose={() => setwithdrawOrder(null)}
              handleDetailRefuse={handleDetailRefuse}
              withdrawOrder={withdrawOrder}
              rowsActive={rowsActive}
              setrowsActive={setrowsActive}
              infoRequest={infoRequest}
              setmessage={setmessage}
              patientDict={patientDict}
            />
          )}
          {rowRefuse && (
            <ModalDetailRefuseDriver
              open={!!rowRefuse}
              handleClose={() => setrowRefuse(null)}
              handleDetailRefuse={handleDetailRefuse}
              rowRefuse={rowRefuse}
              rowsActive={rowsActive}
              setrowsActive={setrowsActive}
              setchangeButtons={setchangeButtons}
              setmessage={setmessage}
              infoRequest={infoRequest}
            />
          )}
          {dispatchGuide && (
            <ModalDispatchGuide
              open={!!dispatchGuide}
              handleClose={() => setdispatchGuide(null)}
              dispatchGuide={dispatchGuide}
              rowsActive={rowsActive}
              setrowsActive={setrowsActive}
              infoRequest={infoRequest}
              setmessage={setmessage}
              patientDict={patientDict}
            />
          )}
          {startTravel && (
            <ModalStartTravel
              open={!!startTravel}
              handleClose={() => setstartTravel(null)}
              startTravel={startTravel}
              rowsActive={rowsActive}
              setrowsActive={setrowsActive}
              setmessage={setmessage}
            />
          )}
          {eventTravel && (
            <ModalEventTravel
              open={!!eventTravel}
              handleClose={() => seteventTravel(null)}
              eventTravel={eventTravel}
              rowsActive={rowsActive}
              setrowsActive={setrowsActive}
              setmessage={setmessage}
            />
          )}
          {deliveryGuide && (
            <ModalDeliveryGuide
              open={!!deliveryGuide}
              handleClose={() => setdeliveryGuide(null)}
              deliveryGuide={deliveryGuide}
              rowsActive={rowsActive}
              setrowsActive={setrowsActive}
              rowsFinished={rowsFinished}
              serowsFinished={serowsFinished}
              infoRequest={infoRequest}
              setmessage={setmessage}
              patientDict={patientDict}
            />
          )}
          {retirementGuide && (
            <ModalRetirementGuide
              open={!!retirementGuide}
              handleClose={() => setretirementGuide(null)}
              retirementGuide={retirementGuide}
              rowsActive={rowsActive}
              setrowsActive={setrowsActive}
              rowsFinished={rowsFinished}
              serowsFinished={serowsFinished}
              infoRequest={infoRequest}
              setmessage={setmessage}
              patientDict={patientDict}
            />
          )}
          {message && (
            <Snackbar
              place="tc"
              close
              color="success"
              message={message}
              open={!!message}
              closeNotification={() => setmessage(null)}
            />
          )}
        </GridContainer>
      )}
    </div>
  );
};

export default withWidth()(TransportDriver);
