import React from "react";

export const useSortableDataInProcess = (itemsInProcess, config = null) => {
  const [sortConfigInProcess, setSortConfigInProcess] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...itemsInProcess];
    if (sortConfigInProcess !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfigInProcess.key] < b[sortConfigInProcess.key]) {
          return sortConfigInProcess.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigInProcess.key] > b[sortConfigInProcess.key]) {
          return sortConfigInProcess.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [itemsInProcess, sortConfigInProcess]);

  const requestSortInProcess = key => {
    let direction = "ascending";
    if (
      sortConfigInProcess &&
      sortConfigInProcess.key === key &&
      sortConfigInProcess.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfigInProcess({ key, direction });
  };

  return {
    itemsInProcess: sortedItems,
    requestSortInProcess,
    sortConfigInProcess
  };
};
