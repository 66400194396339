import React, { useState } from "react";
import TableComponent from "views/Widgets/Common/TableComponent";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import "moment/locale/es";
import api from "utils/API";
import AlertDialog from "components/Attendance/AlertDialog";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import ModalAdd from "views/ClinicOrders/InsumosYMedicamentos/ModalAdd";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";



const ModalEquipRequest = props => {
  const {
    open,
    closeModal,
    listEquip,
    dictEquip,
    dataPatient,
  } = props;
  const [localPacientEquipList, setLocalPacientEquipList] = useState([])
  const [updateView, setUpdateView] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [abrirAlerta, setAbrirAlerta] = React.useState(false);
  const [titulo, setTitulo] = React.useState("");
  const [mensaje, setMensaje] = React.useState("");
  const [tipoAlerta, setTipoAlerta] = React.useState("");

  const handleCerrarAlerta = event => {
    closeModal();
    setAbrirAlerta(false);
    
  };

  const saveRequest = status => {

    let bodyes_list = {};
    let body_solicitud_paciente = {
      Tipo_Solicitud_Paciente: "SolicitarEquipo",
      prioridad: "Media",
      id_paciente_id: dataPatient.id
    };
    var body_productos_pedido = localPacientEquipList.map(equip => ({
      cantidad: 1,
      id_equipo_id: equip.id_equipo,
      // id_equipo_bodega_id: equip.id_bodega,
      fecha_inicio: moment(equip.fecha_inicio).format("YYYY-MM-DD"),
      fecha_fin: equip?.fecha_fin,
      tipo: "Solicitar"
    }));
    bodyes_list["solicitud_paciente"] = body_solicitud_paciente;
    bodyes_list["productos_pedidos"] = body_productos_pedido;
    api.post(`api/paciente/solicitudes/equipos`, bodyes_list)
      .then(response => {
        setTitulo("Exito");
        setMensaje(response?.data?.Exito);
        setTipoAlerta("success");
        setAbrirAlerta(true);
      })
  };


  const handleDateIniciotSelectEquipment = (date, index) => {
    let tempPacientEquipList = localPacientEquipList;

    tempPacientEquipList[index].fecha_inicio = date;
    setLocalPacientEquipList(tempPacientEquipList);
    setUpdateView(true);
  };

  const handleDateFintSelectEquipment = (date, index) => {
    let tempPacientEquipList = localPacientEquipList;

    tempPacientEquipList[index].fecha_fin = moment(date)
    
    setLocalPacientEquipList(tempPacientEquipList)
  
    setUpdateView(true);
  };

  const handleAddItem = (value) => {
    let auxArray = localPacientEquipList;
    const tempData = dictEquip[value.value];
    const element = {
      fecha_inicio : moment(),
      fecha_fin: null,
      sku: tempData.SKU,
      equipo: tempData.descripcion,
      selected: true,
      id_equipo: tempData.id,
    };
    auxArray.push(element);
    setLocalPacientEquipList(auxArray);
    setUpdateView(true);
  }

  const handleDeleteItem = (index) => {
    let auxArray = localPacientEquipList.filter((row, rowIndex) => rowIndex !== index);
    setLocalPacientEquipList(auxArray);
    setUpdateView(true);
  }

  React.useEffect(() => {
    if (updateView) {
      setUpdateView(false);
    }
  });

  
  const validation  = localPacientEquipList.length > 0


  const headerTable = [
    {label: "Equipo"},
    {label: "Sku"},
    {label: "Fecha Inicio"},
    {label: "Fecha Fin"},
    {label: "Acción"},
  ]

  const dataEquip = localPacientEquipList.map((row, index) => {
    const _startDate_ = <MuiPickersUtilsProvider
      locale="es"
      utils={MomentUtils}
    >
      <KeyboardDatePicker
        label="Fecha Inicial"
        clearable
        key={index + "inicio"}
        format="DD-MM-YYYY"
        variant="inline"
        inputVariant="outlined"
        value={row.fecha_inicio }
        onChange={date => handleDateIniciotSelectEquipment(date, index)}
        minDate={moment()}
        minDateMessage={"La fecha de envío no puede ser anterior al día de hoy"}
        cancelLabel={"Cancelar"}
        okLabel={"Aceptar"}
      />
    </MuiPickersUtilsProvider>

    const _endDate_ = <MuiPickersUtilsProvider
      locale="es"
      utils={MomentUtils}
    >
      <KeyboardDatePicker
        label="Indefinido"
        clearable
        format="DD-MM-YYYY"
        key={index + "fin"}
        minDate={moment(row.fecha_inicio)}
        variant="inline"
        inputVariant="outlined"
        value={row?.fecha_fin ? row?.fecha_fin : null}
        onChange={(date) => handleDateFintSelectEquipment(date, index)}
      />
    </MuiPickersUtilsProvider>

    const _delete_ =  <Button
      variant="contained"
      color="primary"
      style={{backgroundColor: red[500]}}
      onClick={() => handleDeleteItem(index)}
    >
      Quitar
    </Button>

    return [
      {value: row.equipo},
      {value: row.sku},
      {eval: false, _value_: _startDate_},
      {eval: false, _value_: _endDate_},
      {eval: false, _value_: _delete_},
    ]
  }) || [];

  const _content_ =  <div>

  <Grid item xs={12}>
    <Button
      variant="contained"
      color="primary"
      onClick={() => setOpenModal(true)}
      style={{ textTransform: "none" }}
    >
      Agregar Equipo
    </Button>
  </Grid>
  <Grid
    item
    container
    xs={12}
    justify="center"
    style={{ padding: "1em" }}
  >
    <TableComponent
      headers={headerTable}
      data={dataEquip}
    />
  </Grid>
  <AlertDialog
    openDialog={abrirAlerta}
    handleClose={handleCerrarAlerta}
    message={mensaje}
    title={titulo}
    severity={tipoAlerta}
  />

</div>

const _actions_ = <>
    <Button
      disabled={!validation}
      variant="contained"
      color="primary"
    
      onClick={() => saveRequest(true)}
    >
      Solicitar
    </Button>
    <Button
      variant="contained"
      color="primary"
      style={{backgroundColor: red[500]}}
      onClick={() => closeModal()}
    >
      Cerrar
    </Button>
</>


  return (
    <>
     <ModalDialog
        open={open}
        onClose={closeModal}
        title={`Solicitar nuevos equipos`}
        _content_={_content_}
        _actions_ = {_actions_}
        maxWidth="xl"
    />
    {openModal && (
      <ModalAdd
        open={openModal}
        handleClose={() => setOpenModal(null)}
        listItem={listEquip}
        dataInfo={dictEquip}
        dataArray={localPacientEquipList}
        setdataArray={setLocalPacientEquipList}
        handleAddItem={handleAddItem}
      />
    )}
    </>
   

    

  );
};

export default ModalEquipRequest;