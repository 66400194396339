import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Fab from "@material-ui/core/Fab";
import api from "utils/API";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import Box from "@material-ui/core/Box";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import AlertDialog from "components/Attendance/AlertDialog";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getlistWarehouseManagers } from "actions/getListAction";
import { useSelector, useDispatch } from "react-redux";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { ButtonTextTemplate as ButtonTemplate,  CustomCardItem, ButtonTextMUI } from "utils/componentsStyle";
import TableComponent from "views/Widgets/Common/TableComponent";
import apiformxlsx from "utils/APIForm"
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
moment.locale("es");

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  root: {
    width: "100%"
  },
  container: {
    minHeight: 100,
    paddingTop: "50px"
  },
  table: {
    height: "1200.5px",
    left: "30.5px",
    top: "192px",
    borderRadius: "10px",
    padding: "40px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    float: "center",
    alignItems: "center"
  },
  box: {
    // height: "365px",
    height: "330px",
    width: "710px",
    borderRadius: "0px",
    paddingTop: "30px"
  },
  boxLeft: {
    height: "114px",
    width: "600px",
    borderRadius: "0px"
  },
  boxLeft2: {
    height: "210px",
    width: "600px",
    borderRadius: "0px"
  },
  headerRight1: {
    height: "42px",
    left: "0px",
    top: "0px",
    bordeRadius: "0px",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
    background: "#64B6F7",
    fontSize: "14",
    color: "white",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500"
  },
  headerRight2: {
    height: "42px",
    left: "0px",
    top: "0px",
    bordeRadius: "0px",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
    fontSize: "14",
    color: "#3F51B5",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500"
  },
  headerBodyRight1: {
    height: "20px",
    left: "0px",
    top: "0px",
    bordeRadius: "0px",
    padding: "0px",
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
    fontSize: "14",
    color: "#3F51B5",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500"
  },
  bodyRight2: {
    height: "140px",
    width: "600px",
    left: "0px",
    top: "0px",
    bordeRadius: "0px",
    padding: "10px",
    fontFamily: "Roboto",
    fontSize: "14"
  },
  bodyRight1: {
    height: "20px",
    width: "250px",
    left: "0px",
    marginRight: "2px",
    top: "0px",
    bordeRadius: "0px",
    padding: "0",
    fontFamily: "Roboto",
    fontSize: "14"
  },
  bodyLeft1: {
    height: "20px",
    width: "250px",
    left: "0px",
    marginRight: "2px",
    top: "0px",
    bordeRadius: "0px",
    padding: "0",
    fontFamily: "Roboto",
    fontSize: "14",
    textAlign: "left"
  },
  bodyLeft2: {
    height: "20px",
    width: "300px",
    left: "0px",
    marginRight: "2px",
    top: "0px",
    bordeRadius: "0px",
    padding: "0",
    fontFamily: "Roboto",
    fontSize: "14",
    textAlign: "left"
  }
}));


const WareHouseManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const [fileMultiUser, setfileMultiUser] = useState(null)
  const [abrirAlerta, setAbrirAlerta] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [tipoAlerta, setTipoAlerta] = useState("");
  const [showPanelTable, setshowPanelTable] = useState("");

  const [loading, setLoading] = useState(false);
  const [dataFrame, setDataFrame] = useState([]);
  const [updateView, setUpdateView] = useState(null);

  const encargados = useSelector(state => state.currentList.LISTA_ENCARGADOS_BODEGA);

  const handleRevisadoPor = (event, rowData) => {
    let tempDataFrame = dataFrame;
    tempDataFrame[rowData.tableData.id].detail.revisadoPor = event.target.value;
    setDataFrame(tempDataFrame);
    setUpdateView(true);
  };

  const handleProfResponsable = (event, rowData) => {
    let tempDataFrame = dataFrame;
    tempDataFrame[rowData.tableData.id].detail.profResponsable = event.target.value;
    setDataFrame(tempDataFrame);
    setUpdateView(true);
  };

  const handleEstadoOperacional = (event, rowData) => {
    let tempDataFrame = dataFrame;
    tempDataFrame[rowData.tableData.id].estado = event.target.value;
    setDataFrame(tempDataFrame);
    setUpdateView(true);
  };

  const handleEstadoSoporteAtril = (event, rowData) => {
    let tempDataFrame = dataFrame;
    tempDataFrame[rowData.tableData.id].detail.estado_soporte_atril = event.target.value;
    setDataFrame(tempDataFrame);
    setUpdateView(true);
  };

  const handleEstadoBolsaTransporte = (event, rowData) => {
    let tempDataFrame = dataFrame;
    tempDataFrame[rowData.tableData.id].detail.estado_bolsa_transporte = event.target.value;
    setDataFrame(tempDataFrame);
    setUpdateView(true);
  };

  const handleEstadoCablePoder = (event, rowData) => {
    let tempDataFrame = dataFrame;
    tempDataFrame[rowData.tableData.id].detail.estado_cable_poder = event.target.value;
    setDataFrame(tempDataFrame);
    setUpdateView(true);
  };

  const handleEstadoMordazaFijacion = (event, rowData) => {
    let tempDataFrame = dataFrame;
    tempDataFrame[rowData.tableData.id].detail.estado_mordaza_fijacion = event.target.value;
    setDataFrame(tempDataFrame);
    setUpdateView(true);
  };

  const handleObservaciones = (event, rowData) => {
    let tempDataFrame = dataFrame;
    tempDataFrame[rowData.tableData.id].detail.observaciones = event.target.value;
    setDataFrame(tempDataFrame);
  };

  const handleEstadoAutonomia = (value, rowData) => {
    let tempDataFrame = dataFrame;
    tempDataFrame[rowData.tableData.id].detail.estado_autonomia = value;
    setDataFrame(tempDataFrame);
    setUpdateView(true);
  };

  const ObtenerEquiposBodega = () => {

    setLoading(true);
    api
      .get("api/bodega/equipos/mantenedor/")
      .then(response => {
        setDataFrame(response.data);

        setLoading(false);
        setUpdateView(true);
      })
      .catch(error => {

        setLoading(false);
        console.log("response error", error);
      });
  };

  const ActualizarEquipoBodega = (value, field, rowData) => {

    const formData = new FormData();
    formData.append(field, value);

    api.patch(`api/equipos/bodega/${rowData.id}/`, formData).then(() => {

        setTitulo("¡Exito!");
        setTipoAlerta("success");
        setMensaje("¡Se ha actualizado de forma exitosa!");
        setAbrirAlerta(true);
        setUpdateView(true);
      }).catch(() => {

        setTitulo("¡Atención!");
        setTipoAlerta("error");
        setMensaje("¡Ha ocurrido un problema al actualizar los datos!");
        setAbrirAlerta(true);
      });
  };

  const ActualizarEquipoBodegaCompleto = rowData => {

    const formData = new FormData();
    if (rowData.detail.revisadoPor !== null) {
      formData.append("id_revisor", rowData.detail.revisadoPor);
    }
    if (rowData.detail.profResponsable !== null) {
      formData.append("id_responsable", rowData.detail.profResponsable);
    }

    formData.append("estado_equipo", rowData.estado);
    formData.append("estado_autonomia", rowData.detail.estado_autonomia);
    formData.append("estado_soporte_atril", rowData.detail.estado_soporte_atril);
    formData.append("estado_bolsa_transporte", rowData.detail.estado_bolsa_transporte);
    formData.append("estado_cable_poder", rowData.detail.estado_cable_poder);
    formData.append("estado_mordaza_fijacion", rowData.detail.estado_mordaza_fijacion);
    formData.append("observaciones", rowData.detail.observaciones);
    api.patch(`api/equipos/bodega/${rowData.id}/`, formData)
      .then(() => {

        setTitulo("¡Exito!");
        setTipoAlerta("success");
        setMensaje("¡Se ha actualizado de forma exitosa!");
        setAbrirAlerta(true);
        setUpdateView(true);
      })
      .catch(() => {

        setTitulo("¡Atención!");
        setTipoAlerta("error");
        setMensaje("¡Ha ocurrido un problema al actualizar los datos!");
        setAbrirAlerta(true);
      });
  };

  const handleCerrarAlerta = () => {
    setAbrirAlerta(false);
  };

  const handleObtaiLocation = rowData => {
    if (
      rowData.detail.ultima_ubicacion === undefined ||
      rowData.detail.ultima_ubicacion === undefined
    ) {
      api
        .get(`api/bodega/equipos/ubicacion/${rowData.id}/`)
        .then(response => {
          let tempDataFrame = dataFrame;
          tempDataFrame[rowData.tableData.id].detail.ultima_ubicacion = response.data.ultima_ubicacion;
          tempDataFrame[rowData.tableData.id].detail.actual_ubicacion = response.data.actual_ubicacion;
          tempDataFrame[rowData.tableData.id].detail.motivo_devolucion = response.data.motivo_devolucion;
          setDataFrame(tempDataFrame);
          setUpdateView(true);
        })
    }
  };

  useEffect(() => {
    if (updateView) {
      setUpdateView(false);
    }
  });

  useEffect(() => {
    ObtenerEquiposBodega();
    getlistWarehouseManagers(dispatch);
  }, []);

  const handleDownloadBaseFile = () => {
    api.get(`api/bodega/descargarbasemasiva/equipos/`, { responseType: "blob" }).then(response => {
        var blob = new Blob([response.data], { type: response.headers["content-type"] });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "carga_item_bodega.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
  }

  const handleMultipleUser  = () => {
    const formData = new FormData();
    formData.append("archivo", fileMultiUser);

    apiformxlsx.post(`api/equiposbodega/`, formData).then(response => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download = `resultado_carga_masiva_${moment().format("YYYY-MM-DD")}.csv`;
        document.body.appendChild(link);
        link.click();
        setfileMultiUser(null)
      })
      .catch(error => {
        setfileMultiUser(null)
        {}
      });
  }

  const headersEquip = [
    {label: "SKU"},
    {label: "EQUIPO"},
    {label: "MARCA"},
    {label: "COLOR"},
    {label: "MODELO"},
    {label: "Nº DE SERIE"},
    {label: "EN BODEGA"},
  ]

  // const dataEquip = dataFrame.map((row) => {
  //   return  [
  //     // {value: row.id_insumo_medicamento__grupo_producto},
  //     // {value: row.id_insumo_medicamento__SKU },
  //     // {value: row.id_insumo_medicamento__descripcion_producto },
  //     // {value: row.cantidad_necesaria},
  //   ]
  // })

  return (
    <GridContainer>
      <ViewTitle
        title="MANTENEDOR EQUIPOS"
        message={`En esta sección podrás revisar el listado de equipos y sus detalles. Podrás editar algunos aspectos con respecto a ellos.`}
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Typography color="textPrimary">Gestión equipos</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={showPanelTable ? 1 : 12} style={{transitionDuration: "5000ms", display: !showPanelTable ? "block" : "none" }}>
        <Card>
          <GridContainer justify="center" className={classes.table}>
            <AlertDialog
              openDialog={abrirAlerta}
              handleClose={handleCerrarAlerta}
              message={mensaje}
              title={titulo}
              severity={tipoAlerta}
            />
            {/* <TableComponent
              headers={headersEquip}
              data={dataSupplies}
            /> */}
            {!updateView && (
              <MaterialTable
                localization={{
                  pagination: {
                    labelRowsSelect: "Filas",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página"
                  },
                  toolbar: {
                    nRowsSelected: "{0} Equípo(s) seleccionados",
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                    showColumnsTitle: "Mostrar Columnas"
                  },

                  header: {
                    actions: "Acciones"
                  },
                  body: {
                    emptyDataSourceMessage: loading ? (
                      <Box p={2} display="flex" justifyContent="space-around">
                        <LoadingSpinner />
                      </Box>
                    ) : (
                      "No hay datos para mostrar"
                    ),
                    filterRow: {
                      filterTooltip: "Filtrar"
                    }
                  }
                }}
                columns={[
                  {
                    title: "SKU",
                    field: "sku",
                    filterCellStyle: {
                      "& div": {
                        color: "#3F51B5"
                      },
                      color: "#3F51B5"
                    }
                  },
                  { title: "EQUÍPO", field: "equipo" },
                  { title: "MARCA", field: "marca" },
                  { title: "COLOR", field: "color" },
                  { title: "MODELO", field: "modelo" },
                  { title: "N° DE SERIE", field: "serie" },
                  {
                    title: "EN BODEGA",
                    field: "stock",
                    cellStyle: (e, rowData) => {
                      if (rowData.stock === "No") {
                        return { color: "#E31B0C", textAlign: "center" };
                      } else {
                        return { color: "#4CAF50", textAlign: "center" };
                      }
                    },
                    render: rowData =>
                      <span style={{
                        border: "solid 1px",
                        padding:rowData.stock === "No" ? " 5px 5px" : "6px 9px",
                        borderRadius: "50% ",
                        borderBlockColor: rowData.stock === "No" ? "#E31B0C" : "#4CAF50"
                        }}
                      >
                        {rowData.stock}
                      </span>
                  }
                ]}
                data={dataFrame}
                options={{
                  selection: false,
                  showTitle: false,
                  filtering: true,
                  pageSize: 10,
                  doubleHorizontalScroll: true,
                  selectionProps: rowData => ({
                    disabled:
                      rowData.stock === "No" ||
                      rowData.estado === "No Operativo" ||
                      rowData.estado === "No operativo",
                    color: "primary"
                  }),
                  headerStyle: {
                    position: "sticky",
                    top: "0",
                    color: "#3F51B5"
                  },
                  filterCellStyle: {
                    color: "#3F51B5"
                  },
                  actionsCellStyle: {
                    color: "#3F51B5"
                  },
                  maxBodyHeight: "950px",
                  minBodyHeight: "950px",
                  actionsColumnIndex: -1
                }}
                onRowClick={rowData => handleObtaiLocation(rowData)}
                detailPanel={[
                  {
                    icon: () => <AddIcon color="primary"></AddIcon>,
                    tooltip: "Informacion extra",
                    // eslint-disable-next-line react/display-name
                    render: (rowData)=> {
                      handleObtaiLocation(rowData);
                      return (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="flex-start"
                        >
                          <Grid item xs={6} align="left">
                            <Grid
                              container
                              direction="column"
                              alignItems="stretch"
                              justifyContent="flex-start"
                              spacing={1}
                            >
                              <Grid item align="center">
                                <Box className={classes.boxLeft}>
                                  <Card style={{boxShadow: "none"}}>
                                    <CardContent style={{padding: "5px"}}>
                                      <Typography className={classes.headerRight1} gutterBottom>
                                        Ubicación
                                      </Typography>
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        justify="space-between"
                                      >
                                        <Grid item align="left">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Ubicación actual
                                              </Typography>
                                              <Typography className={classes.bodyLeft1} gutterBottom>
                                                {rowData.detail.actual_ubicacion ? rowData.detail.actual_ubicacion: "Cargando..."}
                                              </Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                        <Grid item align="left">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Ubicación anterior
                                              </Typography>
                                              <Typography className={classes.bodyLeft1} gutterBottom>
                                                {rowData.detail.ultima_ubicacion ? rowData.detail.ultima_ubicacion: "Cargando..."}
                                              </Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Grid>
                              <Grid item align="center">
                                <Box className={classes.boxLeft2}>
                                  <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                    <CardContent style={{padding: "5px"}}>
                                      <Typography className={classes.headerRight1} gutterBottom>
                                        Paso por bodega
                                      </Typography>
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        justify="space-between"
                                      >
                                        <Grid item align="left">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Última entrada a bodega
                                              </Typography>
                                              <Typography className={classes.bodyLeft1} gutterBottom>
                                                {rowData.detail.fecha_entrada}
                                              </Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                        <Grid item align="flex-start">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Última salida de bodega
                                              </Typography>
                                              <Typography className={classes.bodyLeft1} gutterBottom>
                                                {rowData.detail.fecha_salida}
                                              </Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        justify="space-between"
                                      >
                                        <Grid item align="left">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Motivo de devolución
                                              </Typography>
                                              <Typography className={classes.bodyLeft1} gutterBottom>
                                                {rowData.detail.motivo_devolucion ? rowData.detail.motivo_devolucion: "Cargando..."}
                                              </Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Grid>
                              <Grid item align="center">
                                <Box className={classes.boxLeft}>
                                  <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                    <CardContent style={{padding: "5px"}}>
                                      <Typography className={classes.headerRight1} gutterBottom>
                                        Profesionales relacionados
                                      </Typography>
                                      <Grid
                                        container
                                        direction="column"
                                        alignItems="stretch"
                                        justify="flex-start"
                                      >
                                        <Grid item align="left">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Revisado por
                                              </Typography>
                                              <Box style={{ textAlign: "left" }}>
                                                <Select
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={
                                                    rowData.detail.revisadoPor
                                                  }
                                                  onChange={event => {
                                                    handleRevisadoPor(
                                                      event,
                                                      rowData
                                                    );
                                                  }}
                                                  label="Age"
                                                  className={classes.bodyLeft2}
                                                >

                                                  {encargados.length > 0 ? (encargados.map(item => (
                                                    <MenuItem value={item.value}>
                                                      {item.label}
                                                    </MenuItem>
                                                  ))):
                                                    (<MenuItem value={""}>
                                                      "Cargando Datos..."
                                                    </MenuItem>)}
                                                </Select>
                                                {/* <Tooltip title="Click aquí para guardar cambios">
                                                  <IconButton onClick = { () => ActualizarEquipoBodega(rowData.detail.revisadoPor, "id_revisor", rowData)}>
                                                    <SaveIcon color="primary" />
                                                  </IconButton>
                                                </Tooltip> */}
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                        <Grid item align="flex-start">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Profesional responsable
                                              </Typography>
                                              <Box style={{textAlign: "left"}}>
                                                <Select
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={
                                                    rowData.detail.profResponsable
                                                  }
                                                  onChange={event =>
                                                    handleProfResponsable(
                                                      event,
                                                      rowData
                                                    )
                                                  }
                                                  label="Age"
                                                  className={classes.bodyLeft2}
                                                >

                                                  {encargados.length > 0 ? (encargados.map(item => (
                                                    <MenuItem value={item.value}>
                                                      {item.label}
                                                    </MenuItem>
                                                  ))):
                                                    (<MenuItem value={""}>
                                                      "Cargando Datos..."
                                                    </MenuItem>)}
                                                </Select>
                                                {/* <Tooltip title="Click aquí para guardar cambios">
                                                  <IconButton onClick = { () => ActualizarEquipoBodega(rowData.detail.profResponsable, "id_responsable", rowData)}>
                                                    <SaveIcon color="primary" />
                                                  </IconButton>
                                                </Tooltip> */}
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} align="left">
                            <Grid
                              container
                              direction="column"
                              alignItems="stretch"
                              justifyContent="flex-start"
                              spacing={1}
                            >
                              <Grid item align="left">
                                <Box className={classes.box}>
                                  <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                    <CardContent style={{padding: "5px"}}>
                                      <Typography className={classes.headerRight1} gutterBottom>
                                        Estado de componentes
                                      </Typography>
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        justify="space-between"
                                        spacing={4}
                                      >
                                        <Grid item align="left">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Estado operacional
                                              </Typography>
                                              <Box>
                                                <Select
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={rowData.estado}
                                                  onChange={event =>
                                                    handleEstadoOperacional(
                                                      event,
                                                      rowData
                                                    )
                                                  }
                                                  label="Age"
                                                  className={classes.bodyRight1}
                                                >

                                                  <MenuItem value={"No Declarado"}>No Declarado</MenuItem>
                                                  <MenuItem value={"Operativo"}>Operativo</MenuItem>
                                                  <MenuItem value={"Dañado Opertivo"}>Dañado Operativo</MenuItem>
                                                  <MenuItem value={"No Operativo"}>No Operativo</MenuItem>
                                                  <MenuItem value={"Mantencion"}>Mantencion</MenuItem>
                                                  <MenuItem value={"Dañado"}>Dañado</MenuItem>
                                                </Select>
                                                {/* <Tooltip title="Click aquí para guardar cambios">
                                                  <IconButton onClick = { () => ActualizarEquipoBodega(rowData.estado, "estado_equipo", rowData)}>
                                                    <SaveIcon color="primary" />
                                                  </IconButton>
                                                </Tooltip> */}
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                        <Grid item align="flex-start">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Soporte para atril
                                              </Typography>
                                              <Box>
                                                <Select
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={rowData.detail.estado_soporte_atril}
                                                  onChange={event =>
                                                    handleEstadoSoporteAtril(
                                                      event,
                                                      rowData
                                                    )
                                                  }
                                                  label="Age"
                                                  className={classes.bodyRight1}
                                                >

                                                  <MenuItem value={"No"}>No</MenuItem>
                                                  <MenuItem value={"Sí"}>Sí</MenuItem>
                                                  <MenuItem value={"Dañado"}>Dañado</MenuItem>
                                                  <MenuItem value={"No Necesita"}>No Necesita</MenuItem>
                                                  <MenuItem value={"Mantencion"}>Mantencion</MenuItem>
                                                  <MenuItem value={"No Declarado"}>No Declarado</MenuItem>
                                                </Select>
                                                {/* <Tooltip title="Click aquí para guardar cambios">
                                                  <IconButton onClick = { () => ActualizarEquipoBodega(rowData.detail.estado_soporte_atril, "estado_soporte_atril", rowData)}>
                                                    <SaveIcon color="primary" />
                                                  </IconButton>
                                                </Tooltip> */}
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        justify="space-between"
                                        spacing={4}
                                      >
                                        <Grid item align="left">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Autonomía (Horas)
                                              </Typography>
                                              <Box>
                                                <MuiPickersUtilsProvider locale="es" utils={MomentUtils} >
                                                  <TimePicker
                                                    clearable
                                                    ampm={false}
                                                    format="HH:mm"
                                                    minutesStep={30}
                                                    className={classes.bodyRight1}
                                                    value={rowData.detail.estado_autonomia === "00:00" ? moment(new Date("2021-12-12 00:00:29"),"YYYY-MM-DD HH:mm:ss") : rowData.detail.estado_autonomia}
                                                    onChange={event =>
                                                      handleEstadoAutonomia(
                                                        event,
                                                        rowData
                                                      )
                                                    }
                                                  />
                                                </MuiPickersUtilsProvider>
                                                {/* <Tooltip title="Click aquí para guardar cambios">
                                                  <IconButton onClick = { () => ActualizarEquipoBodega(moment(rowData.detail.estado_autonomia).format("YYYY-MM-DD HH:mm:ss"), "estado_autonomia", rowData)}>
                                                    <SaveIcon color="primary" />
                                                  </IconButton>
                                                </Tooltip> */}
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                        <Grid item align="flex-start">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Bolsa de transporte
                                              </Typography>
                                              <Box>
                                                <Select
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={rowData.detail.estado_bolsa_transporte}
                                                  onChange={event =>
                                                    handleEstadoBolsaTransporte(
                                                      event,
                                                      rowData
                                                    )
                                                  }
                                                  label="Age"
                                                  className={classes.bodyRight1}
                                                >
                                                  <MenuItem value={"No"}>No</MenuItem>
                                                  <MenuItem value={"Sí"}>Sí</MenuItem>
                                                  <MenuItem value={"Dañado"}>Dañado</MenuItem>
                                                  <MenuItem value={"No Necesita"}>No Necesita</MenuItem>
                                                  <MenuItem value={"Mantencion"}>Mantencion</MenuItem>
                                                  <MenuItem value={"No Declarado"}>No Declarado</MenuItem>
                                                </Select>
                                                {/* <Tooltip title="Click aquí para guardar cambios">
                                                  <IconButton onClick = { () => ActualizarEquipoBodega(rowData.detail.estado_bolsa_transporte, "estado_bolsa_transporte", rowData)}>
                                                    <SaveIcon color="primary" />
                                                  </IconButton>
                                                </Tooltip> */}
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        justify="space-between"
                                        spacing={4}
                                      >
                                        <Grid item align="left">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Cable de poder
                                              </Typography>
                                              <Box>
                                                <Select
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={rowData.detail.estado_cable_poder}
                                                  onChange={event =>
                                                    handleEstadoCablePoder(
                                                      event,
                                                      rowData
                                                    )
                                                  }
                                                  label="Age"
                                                  className={classes.bodyRight1}
                                                >

                                                  <MenuItem value={"No"}>No</MenuItem>
                                                  <MenuItem value={"Sí"}>Sí</MenuItem>
                                                  <MenuItem value={"Dañado"}>Dañado</MenuItem>
                                                  <MenuItem value={"No Necesita"}>No Necesita</MenuItem>
                                                  <MenuItem value={"Mantencion"}>Mantencion</MenuItem>
                                                  <MenuItem value={"No Declarado"}>No Declarado</MenuItem>
                                                </Select>
                                                {/* <Tooltip title="Click aquí para guardar cambios">
                                                  <IconButton onClick = { () => ActualizarEquipoBodega(rowData.detail.estado_cable_poder, "estado_cable_poder", rowData)}>
                                                    <SaveIcon color="primary" />
                                                  </IconButton>
                                                </Tooltip> */}
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                        <Grid item align="flex-start">
                                          <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                            <CardContent style={{padding: "5px"}}>
                                              <Typography className={classes.headerBodyRight1} gutterBottom>
                                                Mordaza para fijación
                                              </Typography>
                                              <Box>
                                                <Select
                                                  labelId="demo-simple-select-outlined-label"
                                                  id="demo-simple-select-outlined"
                                                  value={rowData.detail.estado_mordaza_fijacion}
                                                  onChange={event =>
                                                    handleEstadoMordazaFijacion(
                                                      event,
                                                      rowData
                                                    )
                                                  }
                                                  label="Age"
                                                  className={classes.bodyRight1}
                                                >

                                                  <MenuItem value={"No"}>No</MenuItem>
                                                  <MenuItem value={"Sí"}>Sí</MenuItem>
                                                  <MenuItem value={"Dañado"}>Dañado</MenuItem>
                                                  <MenuItem value={"No Necesita"}>No Necesita</MenuItem>
                                                  <MenuItem value={"Mantencion"}>Mantencion</MenuItem>
                                                  <MenuItem value={"No Declarado"}>No Declarado</MenuItem>
                                                </Select>
                                                {/* <Tooltip title="Click aquí para guardar cambios">
                                                  <IconButton onClick = { () => ActualizarEquipoBodega(rowData.detail.estado_mordaza_fijacion, "estado_mordaza_fijacion", rowData)}>
                                                    <SaveIcon color="primary" />
                                                  </IconButton>
                                                </Tooltip> */}
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Grid>
                              <Grid item align="left">
                                <Box className={classes.box}>
                                  <Card style={{boxShadow: "none", marginBottom:"0px", marginTop:"0px"}}>
                                    <CardContent style={{padding: "5px"}}>
                                      <Typography className={classes.headerRight2} gutterBottom>
                                        Observaciones
                                      </Typography>
                                      <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={6}
                                        onChange={event =>
                                          handleObservaciones(
                                            event,
                                            rowData
                                          )
                                        }
                                        placeholder="Aquí ingresa y anota las observaciones que tenga el equipo"
                                        defaultValue={rowData.detail.observaciones == "" ? "Aquí ingresa y anota las observaciones que tenga el equipo":rowData.detail.observaciones}
                                        className={classes.bodyRight2}
                                      />
                                    </CardContent>
                                    <CardActions>
                                      <Button
                                        size="medium"
                                        variant="outlined"
                                        style={{
                                          color: "#3F51B5",
                                          borderColor: "#3F51B5",
                                          marginLeft: "10px"
                                        }}
                                        // onClick = { () => ActualizarEquipoBodega(rowData.detail.observaciones, "observaciones", rowData)}
                                        onClick = { () => ActualizarEquipoBodegaCompleto(rowData)}
                                      >
                                        Guardar Cambios
                                      </Button>
                                    </CardActions>
                                  </Card>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }
                  }
                ]}
              />
            )}
          </GridContainer>
        </Card>
      </GridItem>
      <Grid item xs={12}  style={{transitionDuration: "1000ms", display: showPanelTable ? "block" : "none" }}>
          <Grid item container xs={12} direction="row" alignContent="flex-start" justify="center" style={{transitionDuration: "1000ms", display: (showPanelTable == "CARGA") ? "block" : "none" }}>
            <CustomCardItem
              xs={12}
              title={`Carga masiva bodega`}
              content={`Permite cargar el stock inicial de equipos en bodega.`}
              textAction={fileMultiUser ? `Carga masiva`: ""}
              action={() => handleMultipleUser()}
              subComponent={<Link href="#" onClick={() => handleDownloadBaseFile()}>
                Descargar archivo base
              </Link>}
              component={ fileMultiUser ? null : <Button
                variant='contained'
                color='primary'
              >
              <span className="btn-file">
                {`Adjuntar archivo carga masiva de equipos nuevos en bodega`}
              <input
                type="file"
                accept="image/xlsx"
                name="archivo"
                onChange={e => setfileMultiUser(e.target.files[0])}
              />
              </span>
              </Button>}
            />
          </Grid>
      </Grid>
      <Fab
        className={"fabGridExtend"}
        color="primary"
        style={{zIndex: "9"}}
        onClick={() => setshowPanelTable(showPanelTable == "CARGA" ? "" : "CARGA")}
        >
        {showPanelTable == "CARGA" ? <PublishIcon /> :  <CloudUploadIcon />}
      </Fab>
    </GridContainer>
  );
};

export default WareHouseManagement;
