import axios from "axios";

const api = axios.create({
  // baseURL: "https://cmh-tele-back-staging-docker.azurewebsites.net/",
  baseURL: "https://cmh-tele-back-prod.azurewebsites.net/",
  // baseURL: "http://localhost:8001/",
  headers: {
    "Content-type": "application/json",
    "Authorization": `JWT ${localStorage.getItem("access")}`
  }
});


export default api;