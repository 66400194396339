import React, { useState, useEffect } from "react";
import useToogle from "utils/CustomHooks/useToogle";
import api from "utils/API";

import ActionButtons from "views/Widgets/ActionButtons";
import WorkTeamActions from "./components/WorkTeamActions";
import WorkTeamList from "./components/WorkTeamList";
import WorkTeamCalendar from "./components/WorkTeamCalendar";
import AppDialog from "views/Widgets/AppDialog";
import { TypographyMui } from "utils/componentsStyle";

import { Box } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Spiner from "views/Widgets/Spiner";

function WorkTeam(props) {
  const [wordToFilter, setWordToFilter] = useState('');
  const [coordinators, setCoordinators] = useState([]);
  const [typeProfessionalSelected, setTypeProfessionalSelected] = useState({});
  const [tab, setTab] = useState(0);
  const [professionalToDelete, setProfessionalToDelete] = useState({ isDialogOpen: false, data: undefined })
  const [alert, setAlert] = useState({ success: false, error: false })
  const [spiner, setSpiner] = useToogle()

  const closeDialog = () => {
    setProfessionalToDelete({ ...professionalToDelete, isDialogOpen: false, data: undefined })
    setAlert({ success: false, error: false })
  }

  const updateProfessionalList = (professionalToAdd, professionalToDelete) => {
    const deleteProfessional = coordinators.filter(iter => iter.id_usuario !== professionalToDelete)
    setCoordinators([...deleteProfessional, professionalToAdd])
  }

  const setDeleteProfessionalData = (data) => {
    setProfessionalToDelete({ ...professionalToDelete, isDialogOpen: true, data })
  }

  const deleteProfessional = () => {
    setSpiner()
    api.delete(`api/cordinadores/quitar/${professionalToDelete.data.id_usuario}/paciente/${props.workTeamData.file.id}`).then(response => {
      const removeProfessionalFromState = coordinators.filter(iter => iter.id_usuario !== professionalToDelete.data.id_usuario)
      setCoordinators(removeProfessionalFromState)
      setAlert({ ...alert, success: true })
      setTimeout(() => {
        setAlert({ ...alert, success: false })
        setProfessionalToDelete({ ...professionalToDelete, isDialogOpen: false, data: undefined })
      }, 3000);
      setSpiner()
    },
      (err) => {
        setAlert({ ...alert, error: true })
        setSpiner()
      })
  }

  const professionalSelectedvalidator = () => {
    const formatedProfessionalList = coordinators.reduce((acum, iter) => {
      const professiona = { ...acum, [iter.id_usuario]: iter }
      return professiona
    }, {})
    setTypeProfessionalSelected(formatedProfessionalList)
  }

  useEffect(() => {
    props.workTeamData.coordinador && setCoordinators(props.workTeamData.coordinador);
  }, [props.workTeamData.coordinador]);

  useEffect(() => {
    coordinators.length && professionalSelectedvalidator()
  }, [coordinators]);

  return (
    <>
      <Spiner open={spiner} />

      <WorkTeamActions
        tab={tab}
        typeProfessionalSelected={typeProfessionalSelected}
        wordToFilter={wordToFilter}
        setWordToFilter={setWordToFilter}
        setTab={setTab}
        updateProfessionalList={updateProfessionalList}
        setSpiner={setSpiner}
        {...props}
      />
      <WorkTeamList
        open={tab}
        coordinators={coordinators}
        wordToFilter={wordToFilter}
        setWordToFilter={setWordToFilter}
        setDeleteProfessionalData={setDeleteProfessionalData}
        {...props}
      />
      <WorkTeamCalendar open={tab} {...props} />

      <AppDialog title='Eliminar asignación' open={professionalToDelete.isDialogOpen} onClose={closeDialog} maxWidth='xs'>
        <TypographyMui variant='body2' color='primary'>
          ¿Estás seguro que deseas eliminar a {professionalToDelete.data?.nombres} como
          {professionalToDelete.data?.tipo_coordinador === 'coordinadorpaciente' ? ' coordinnador' : ' tutor'}?
        </TypographyMui>

        <Box display={alert.success ? 'block' : 'none'} mt={1.5}>
          <Alert severity="success">Profesional borrado exitosamente</Alert>
        </Box>
        <Box display={alert.error ? 'block' : 'none'} mt={1.5}>
          <Alert severity="error">Hubo un error. Intentalo nuevamente</Alert>
        </Box>

        <ActionButtons actionButtonText='Eliminar' cancelAction={closeDialog} actionButtonMethod={deleteProfessional} />
      </AppDialog>
    </>
  );
}

export default WorkTeam;
