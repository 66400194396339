import React, { useState, useEffect } from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";

const LoadInfo = (props) => {
  const [initialCharge, setinitialCharge] = useState(true);

  useEffect(() => {
    if (initialCharge){
      window.scrollTo(0, 0);
      setinitialCharge(false);
   }
  });
  
  
  return (
    <Card>
        {props.title &&
            <CardTitle title={props.title} />
        }
        
        <CardBody className="d-flex justify-content-center">
            <LoadingSpinner />
        </CardBody>
    </Card>
  );
};

export default LoadInfo;
