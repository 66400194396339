import React, { useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ModalEditAlert from "./ModalEditAlert";
import ModalDeleteAlert from "./ModalDeleteAlert";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  table: {
    minWidth: 650
  }
});

const TableAlerts = props => {
  const classes = useStyles();
  const {
    getAlerts,
    setChanges,
    setOpenBackdrop,
    getListVitalSigns,
    setActivateAlert,
    setAlertSigns,
    setAlertas
  } = props;
  const [idAlert, setIdAlert] = useState("");
  const [idFicha, setIdFicha] = useState("");
  const [lowerLimit, setLowerLimit] = useState(0.0);
  const [upperLimit, setUpperLimit] = useState(0.0);
  const [observation, setObservation] = useState("");
  const [alertType, setAlertType] = useState("");
  const [openModalEditAlert, setOpenModalEditAlert] = useState(false);
  const [openModalDeleteAlert, setOpenModalDeleteAlert] = useState(false);

  const type_user = localStorage.getItem("tipo_perfil");
  const dictSigns = useSelector(state => state.currentList.DICCIONARIO_SIGNOS_VITALES);

  const setModalEditAlert = alert => {
    setIdAlert(alert.id);
    setIdFicha(alert.id_ficha);
    setLowerLimit(alert.limite_inferior);
    setUpperLimit(alert.limite_superior);
    setObservation(alert.observacion);
    setAlertType(alert.tipo_signos);
    setOpenModalEditAlert(true);
  };

  const setModalDeleteAlert = alert => {
    setIdAlert(alert.id);
    setOpenModalDeleteAlert(true);
  };

  return (
    <Grid container className={classes.root} justify="center">
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          style={{ textTransform: "none" }}
        >
          Alertas
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Tipo de alerta</TableCell>
                <TableCell align="center">Límite inferior</TableCell>
                <TableCell align="center">Límite superior</TableCell>
                <TableCell align="center">Observación</TableCell>
                <TableCell align="center">
                  Última fecha de modificación
                </TableCell>
                {type_user !== "CLITecEnfBas" &&
                <TableCell align="center">Editar</TableCell>
                }
                {type_user !== "CLITecEnfBas" &&
                <TableCell align="center">Eliminar</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {getAlerts.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {dictSigns[row.tipo_signos]?.label}
                  </TableCell>
                  <TableCell align="center">{row.limite_inferior}</TableCell>
                  <TableCell align="center">{row.limite_superior}</TableCell>
                  <TableCell align="center">{row.observacion}</TableCell>
                  <TableCell align="center">
                    {moment(row.ultima_modificacion).format("LLLL")}
                  </TableCell>
                  {type_user !== "CLITecEnfBas" &&
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setModalEditAlert(row)}
                    >
                      Editar
                    </Button>
                  </TableCell>
                  }
                  {type_user !== "CLITecEnfBas" &&
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setModalDeleteAlert(row)}
                    >
                      Eliminar
                    </Button>
                  </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={1} />
      {openModalEditAlert && (
        <ModalEditAlert
          open={openModalEditAlert}
          closeModal={() => setOpenModalEditAlert(false)}
          getListVitalSigns={getListVitalSigns}
          idAlert={idAlert}
          idFicha={idFicha}
          lowerLimit={lowerLimit}
          setLowerLimit={setLowerLimit}
          upperLimit={upperLimit}
          setUpperLimit={setUpperLimit}
          observation={observation}
          setObservation={setObservation}
          alertType={alertType}
          setAlertType={setAlertType}
          setChanges={setChanges}

          setActivateAlert={setActivateAlert}
          setAlertSigns={setAlertSigns}
          setAlertas={setAlertas}
        />
      )}
      {openModalDeleteAlert && (
        <ModalDeleteAlert
          open={openModalDeleteAlert}
          closeModal={() => setOpenModalDeleteAlert(false)}
          idAlert={idAlert}
          setChanges={setChanges}

          setActivateAlert={setActivateAlert}
          setAlertSigns={setAlertSigns}
          setAlertas={setAlertas}
        />
      )}
    </Grid>
  );
};

export default TableAlerts;

TableAlerts.propTypes = {
  getAlerts: PropTypes.array,
  setChanges: PropTypes.func,
  setOpenBackdrop: PropTypes.func,
  getListVitalSigns: PropTypes.array,
  setActivateAlert: PropTypes.func,
  setAlertSigns: PropTypes.func,
  setAlertas: PropTypes.func
};
