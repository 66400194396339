import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Grid, Typography, TextField, IconButton } from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import { red, green, indigo, orange } from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import TimeLineIngreso from "./TimeLine/TimeLineIngreso";
import moment from "moment-timezone";
import api from "utils/API";
import "moment/locale/es";
import RoomIcon from '@material-ui/icons/Room';
moment.locale("es");


export default function ModalDetails({
  open,
  handleClose,
  rowDetail,
  infoRequest,
  patientDict,
  timeLine,
  getProducts,
  getProductsComplete,
  getProductsOther,
  boxNumber,
}) {
  const tempRow = rowDetail[0];
  const patient = patientDict[tempRow.id_paciente];
  const tempInfoRequest = infoRequest[0];

  const dictSTValueLabel = useSelector(state => state.currentList.DICCIONARIO_ESTADOS_TRANSPORTES);
  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);

  const title = tempRow.tipo_solicitud === "Traslado Paciente" ? `Datos del traslado` :
    (tempRow.tipo_solicitud === "SolicitudRetiroCompra" ||
    tempRow.tipo_solicitud === "SolicitudRetiroEquipo" ||
    tempRow.tipo_solicitud === "SolicitudEntregaRetiroEquipo" ) ? `Datos del retiro` :
    `Datos del pedido`

  const opengooglemap = () => {
      const lat = patient ? patient?.direccion_latitud : tempRow.direccion_latitud
      const lng = patient ? patient?.direccion_longitud :  tempRow.direccion_longitud
      const url = `https://www.google.com/maps/place/${lat},${lng}`;
      window.open(url, '_blank');
  }

  const handleNoteSold = (idSolicitud) => {
    api.get(`api/documentosbodega/notaventa/${idSolicitud ? idSolicitud : props.id}/tipo/Todo/`,
      { responseType: "blob" }
    ).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "nota_venta.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })

  };

  console.log(tempRow.id_envios_pedidos)
  

  const _content_ = <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
      {tempRow.tipo_solicitud === "Traslado Paciente" ? (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          item
          xs={12}
          style={{ margin: "10px 0 10x 0" }}
        >
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={10}
            style={{ padding: "5px" }}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {`Motivo traslado: ${tempRow.id_parada_traslado.id_traslado_paciente.tipo_motivo_traslado}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Indicaciones generales: ${tempRow.id_parada_traslado.id_traslado_paciente.indicaciones_generales}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Tipo de traslado: ${tempRow.id_parada_traslado.id_traslado_paciente.tipo_traslado}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Tipo de parada: ${tempRow.id_parada_traslado.tipo_parada}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Dirección block: ${tempRow.id_parada_traslado.direccion_block}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Dirección calle: ${tempRow.id_parada_traslado.direccion_calle}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Dirección ciudad: ${tempRow.id_parada_traslado.direccion_ciudad}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Dirección comuna: ${tempRow.id_parada_traslado.direccion_comuna}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Dirección departamento: ${tempRow.id_parada_traslado.direccion_depto_casa}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Dirección región: ${tempRow.id_parada_traslado.direccion_region}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Fecha: ${moment(
                tempRow.id_parada_traslado.fecha_hora
              ).format("YYYY-MM-DD HH:mm")}`}
            </Typography>
          </Grid>
        </Grid>
      ) : (tempRow.tipo_solicitud === "SolicitudRetiroCompra" ||
        tempRow.tipo_solicitud === "SolicitudRetiroEquipo" ||
        tempRow.tipo_solicitud === "SolicitudEntregaRetiroEquipo") ? (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          item
          xs={12}
          style={{ margin: "10px 0 10x 0" }}
        >
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            md={3}
            sm={6}
            xs={12}
            style={{ padding: "5px" }}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {tempRow.tipo_solicitud === "SolicitudRetiroCompra"
                ? `Empresa: ${tempRow?.id_parada_traslado_ingreso?.nombre_empresa} `
                : `Paciente: ${tempRow?.id_parada_traslado_ingreso?.nombre_empresa} `}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {tempRow.id_parada_traslado_ingreso
                ? tempRow.id_parada_traslado_ingreso.tipo_parada === "Inicio"
                  ? `Dirección: ${tempRow.id_parada_traslado_ingreso.direccion_calle} ${tempRow.id_parada_traslado_ingreso.direccion_numero} ${tempRow.id_parada_traslado_ingreso.direccion_oficina}`
                  : tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso
                      .tipo_producto === "Insumo" ||
                    tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso
                      .tipo_producto === "Equipo"
                  ? `Dirección: Bodega Ingreso`
                  : `Dirección: Bodega Farmacia`
                : `Dirección: ${patient?.nombre} ${patient?.apellido_paterno} ${patient?.apellido_materno}`}
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            md={3}
            sm={6}
            xs={12}
            style={{ padding: "5px" }}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {`Comuna:${tempRow.id_parada_traslado_ingreso.direccion_comuna}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Región:${tempRow.id_parada_traslado_ingreso.direccion_region} `}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Zona:`}
            </Typography>
          </Grid>

          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            md={3}
            sm={6}
            xs={12}
            style={{ padding: "5px" }}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {`Fecha solicitud:`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`${
                tempRow.fecha_solicitud
                  ? moment(tempRow.fecha_solicitud).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "-"
              }`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {tempRow.hora_estimada ?
              `Fecha a traslado:`:
              `Fecha a despachar:`
              }
              {}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`${ tempRow.id_envios_pedidos ?
                moment(tempRow.id_envios_pedidos.fecha_tentativa_llegada).format(
                  "YYYY-MM-DD HH:mm"
                ) : tempRow.fecha_tentativa_llegada?
                  moment(tempRow.fecha_tentativa_llegada).format(
                  "YYYY-MM-DD HH:mm"
                  ) :  moment(tempRow.hora_estimada).format(
                  "YYYY-MM-DD HH:mm"
                  )

              }`}
            </Typography>
            {tempRow.hora_estimada && (
              <Typography variant="caption" display="block" gutterBottom>
                {`Fecha estimada:`}
              </Typography>
            )}
            {tempRow.hora_estimada && (
              <Typography variant="caption" display="block" gutterBottom>
                {`${
                  tempRow.hora_estimada
                    ? moment(tempRow.hora_estimada).format("DD-MM-YYYY HH:mm")
                    : "-"
                }`}
              </Typography>
            )}
            {(tempRow.estado_recepcion === "Entregado" ||
              tempRow.estado_recepcion === "Retrasado") && (
              <Typography variant="caption" display="block" gutterBottom>
                {`Fecha entrega:`}
              </Typography>
            )}
            {(tempRow.estado_recepcion === "Entregado" ||
              tempRow.estado_recepcion === "Retrasado") && (
              <Typography variant="caption" display="block" gutterBottom>
                {tempRow.hora_llegada
                  ? `${moment(tempRow.hora_llegada).format(
                      "YYYY-MM-DD HH:mm"
                    )}`
                  : `Sin entregar`}
              </Typography>
            )}
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            md={3}
            sm={6}
            xs={12}
            style={{ padding: "5px" }}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {tempRow.id_envios_pedidos
                ? `Solicitud pedido: ${tempRow.id_envios_pedidos.id_solicitud_pedido}`
                : tempRow.id_parada_traslado
                ? `Solicitud paciente: ${tempRow.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}`
                : tempRow.id_parada_traslado_ingreso
                ? `Solicitud ingreso: ${tempRow?.id_parada_traslado_ingreso?.id_solicitud_ingreso.id}`
                : " "}
            </Typography>
            {tempRow.id_parada_traslado_ingreso && (
              <Typography variant="caption" display="block" gutterBottom>
                {`Caja: ${tempRow.id_parada_traslado_ingreso ? 1 : "-"}`}
              </Typography>
            )}
            <Typography variant="caption" display="block" gutterBottom>
              {`Tipo de solicitud: ${dictTypeRequest[tempRow.tipo_solicitud]?.label}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Estado pedido: ${
                dictSTValueLabel[tempRow.estado_recepcion]?.label
              }`}
            </Typography>
            {tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso && (
              <Typography variant="caption" display="block" gutterBottom>
                {`Medicamento: ${
                  tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso
                    .tipo_producto === "Medicamento" ||
                  tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso
                    .tipo_producto === "InsumoMedicamento"
                    ? "Sí"
                    : "No"
                }`}
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          item
          xs={12}
          style={{ margin: "10px 0 10x 0" }}
        >
          {patient &&
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            md={3}
            sm={6}
            xs={12}
            style={{ padding: "5px" }}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {`Paciente: ${patient?.nombre} ${patient?.apellido_paterno} ${patient?.apellido_materno}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Dirección: ${patient?.direccion_calle} ${patient?.direccion_numero} ${patient?.direccion_casa_depto} ${patient?.direccion_block}`}
            </Typography>
          </Grid>
          }
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            md={3}
            sm={6}
            xs={12}
            style={{ padding: "5px" }}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {`Comuna: ${patient?.direccion_comuna || tempRow.comuna }`}
            </Typography>
            { patient && 
            <Typography variant="caption" display="block" gutterBottom>
              {`Región: ${patient?.direccion_region}`}
            </Typography>
            }
            { patient && 
            <Typography variant="caption" display="block" gutterBottom>
              {`Zona: ${tempRow.zona}`}
            </Typography>
            }
          </Grid>

          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            md={3}
            sm={6}
            xs={12}
            style={{ padding: "5px" }}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {`Fecha solicitud:`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`${
                tempRow.fecha_solicitud
                  ? moment(tempRow.fecha_solicitud).format("YYYY-MM-DD HH:mm")
                  : "-"
              }`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {tempRow.hora_estimada ?
              `Fecha a traslado:`:
              `Fecha a despachar:`
              }
              {}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`${ tempRow.id_envios_pedidos ?
                moment(tempRow.id_envios_pedidos.fecha_tentativa_llegada).format(
                  "YYYY-MM-DD HH:mm"
                ) : tempRow.fecha_tentativa_llegada?
                  moment(tempRow.fecha_tentativa_llegada).format(
                  "YYYY-MM-DD HH:mm"
                  ) :  moment(tempRow.hora_estimada).format(
                  "YYYY-MM-DD HH:mm"
                  )

              }`}
            </Typography>
            {tempRow.hora_estimada && (
              <Typography variant="caption" display="block" gutterBottom>
                {`Fecha estimada:`}
              </Typography>
            )}
            {tempRow.hora_estimada && (
              <Typography variant="caption" display="block" gutterBottom>
                {`${
                  tempRow.hora_estimada
                    ? moment(tempRow.hora_estimada).format("DD-MM-YYYY HH:mm")
                    : "-"
                }`}
              </Typography>
            )}
            {(tempRow.estado_recepcion === "Entregado" ||
              tempRow.estado_recepcion === "Retrasado") && (
              <Typography variant="caption" display="block" gutterBottom>
                {`Fecha entrega:`}
              </Typography>
            )}
            {(tempRow.estado_recepcion === "Entregado" ||
              tempRow.estado_recepcion === "Retrasado") && (
              <Typography variant="caption" display="block" gutterBottom>
                {tempRow.hora_llegada
                  ? `${moment(tempRow.hora_llegada).format(
                      "YYYY-MM-DD HH:mm"
                    )}`
                  : `Sin entregar`}
              </Typography>
            )}
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            md={3}
            sm={6}
            xs={12}
            style={{ padding: "5px" }}
          >
            <Typography variant="caption" display="block" gutterBottom>
                {
                  tempRow.id_envios_pedidos ? `Solicitud pedido: ${tempRow.id_envios_pedidos.id_solicitud_pedido}`: 
                  tempRow.id_parada_traslado ? `Solicitud paciente: ${tempRow.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}` :
                  tempRow.id_parada_traslado_ingreso ? `Solicitud ingreso: ${tempRow?.id_parada_traslado_ingreso?.id_solicitud_ingreso.id}` : 
                  `Tramite - ${tempRow.titulo} ${tempRow.id}`
                }
            </Typography>
            {tempRow.id_envios_pedidos && (
              <Typography variant="caption" display="block" gutterBottom>
                {`Caja: ${
                  tempRow.id_envios_pedidos
                    ? tempRow.id_envios_pedidos.numero_pedido
                    : "-"
                }`}
              </Typography>
            )}
            <Typography variant="caption" display="block" gutterBottom>
              {`Tipo de solicitud: ${dictTypeRequest[tempRow.tipo_solicitud]?.label || `Tramite - ${tempRow.titulo}`}`}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {`Estado pedido: ${dictSTValueLabel[tempRow.estado_recepcion]?.label}`}
            </Typography>
            {tempRow.id_envios_pedidos && (
              <Typography variant="caption" display="block" gutterBottom>
                {`Medicamento: ${
                  tempRow.id_envios_pedidos.es_farmacia ? "Sí" : "No"
                }`}
              </Typography>
            )}
            {tempInfoRequest?.id_envio_pedido > 0 && (
              <Typography variant="caption" display="block" gutterBottom>
                {`Estado picking: ${
                  tempInfoRequest.id_envio_pedido ? tempInfoRequest.id_envio_pedido.id_solicitud_pedido.estado_picking : "-"
                }`}
              </Typography>
            )}
          </Grid>
          {(tempInfoRequest?.id_envio_pedido?.id_solicitud_pedido || tempRow.observacion_recepcion) &&
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{ padding: "5px" }}
          >
            <Button
                className="mt-4"
                variant="contained"
                onClick={() => handleNoteSold(tempInfoRequest?.id_envio_pedido?.id_solicitud_pedido?.id)}
                color="primary"
            >
                Ver nota de venta
            </Button>
            </Grid>
          }
          {(tempInfoRequest?.id_envio_pedido?.id_solicitud_pedido || tempRow.observacion_recepcion) &&
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{ padding: "5px" }}
          >
            <TextField
                disabled={true}
                label={"Observaciones"}
                multiline
                rows={5}
                value={tempInfoRequest?.id_envio_pedido?.id_solicitud_pedido?.observacion || tempRow.observacion_recepcion}
                variant="outlined"
                onChange={(event) => {}}
                style={{width: "100%"}}
            />
            </Grid>
          }
        </Grid>
      )}
      <Grid item container xs={12}>
        {tempRow.tipo_solicitud === "SolicitudRetiroCompra" ||
        tempRow.tipo_solicitud === "SolicitudRetiroEquipo" ||
        tempRow.tipo_solicitud === "SolicitudEntregaRetiroEquipo" ? (
          <TimeLineIngreso
            getProducts={getProducts}
            timeLine={timeLine}
            getProductsComplete={getProductsComplete}
            getProductsOther={getProductsOther}
            caja={boxNumber}
          />
        ):null}
      </Grid>
    </Grid>


const _actions_ = <>
    {(patient?.direccion_latitud || tempRow?.direccion_latitud) && 
      <IconButton variant={"outlined"} style={{ background: orange[500], padding: "5px"}} onClick={() => opengooglemap()} >
        <RoomIcon style={{color: '#FFFFFF'}} />
      </IconButton>
    }
    
    <Button
      mr={2}
      variant="contained"
      style={{ backgroundColor: red[500], marginRight: "10px" }}
      className="text-white"
      onClick={handleClose}
    >
      Cerrar
    </Button>
  </>

  return (
    <ModalDialog
        open={open}
        onClose={handleClose}
        title={title}
        _content_={_content_}
        _actions_ = {_actions_}
      />
  );
}
