import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import api from "utils/API";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/es";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    minHeight: "70%",
    maxHeight: "70%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    //minWidth: 120
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    position: "absolute",
    minHeight: "150%",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalDespacho = props => {
  const classes = useStyles();
  const { openModal, closeModal, internalTransport, quotationId} = props;
  const [selectedDate, setSelectedDate] = useState(moment());
  const [enterprise, setEnterprise] = useState("");
  const [adress, setAdress] = useState("");
  const [number, setNumber] = useState("");
  const [commune, setCommune] = useState("");
  const [region, setRegion] = useState("");
  const [office, setOffice] = useState("");
  const [comments, setComments] = useState("");
  const [ciudad, setCiudad] = useState("");

  const handleDateChange = date => {
    setSelectedDate(date);
  };
  const handleEnterpriseChange = event => {
    setEnterprise(event.target.value);
  };
  const handleAdressChange = event => {
    setAdress(event.target.value);
  };
  const handleNumberChange = event => {
    setNumber(event.target.value);
  };
  const handleCommuneChange = event => {
    setCommune(event.target.value);
  };
  const handleRegionChange = event => {
    setRegion(event.target.value);
  };
  const handleOfficeChange = event => {
    setOffice(event.target.value);
  };
  const handleCommentsChange = event => {
    setComments(event.target.value);
  };
  const handleCiudadChange = event => {
    setCiudad(event.target.value);
  };

  const sendNotification = () => {
    let url = "";
    if (internalTransport === true) {
      url = "api/compras/notificar/interno";
    } else {
      url = "api/compras/notificar/externo";
    }
    const formData = new FormData();
    formData.append("empresa", enterprise);
    formData.append("direccion", adress);
    formData.append("numero", number);
    formData.append("comuna", commune);
    formData.append("region", region);
    formData.append("oficina", office);
    formData.append("comentarios", comments);
    formData.append("ciudad", ciudad);
    formData.append("id_solicitud_cotizacion", quotationId);
    formData.append(
      "fecha",
      moment(selectedDate)
        .format("YYYY-MM-DD")
        .toString()
    );
    api
      .post(url, formData)
      .then(response => {
        console.log("response", response);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper className={classes.paper}>
        <ModalTitle id="customized-dialog-title" onClose={closeModal}>
          {internalTransport
            ? "Notificar a ingresos y transporte"
            : "Notificar a ingresos"}
        </ModalTitle>
        <DialogContent>
          <Grid
            container
            className={classes.root}
            justify="center"
            style={{ marginBottom: "3em" }}
          >
            <Grid item container xs={12} justify="center" spacing={2}>
              {internalTransport ? (
                <div>
                  <Grid item container md={12} justify="center" spacing={3}>
                    <Grid item md={6} justify="center">
                      <MuiPickersUtilsProvider
                        locale={deLocale}
                        utils={DateFnsUtils}
                      >
                        <Grid container justify="space-around">
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Fecha"
                            format="dd/MM/yyyy"
                            value={selectedDate ? moment(selectedDate) : null}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                            okLabel="Aceptar"
                            cancelLabel="Cancelar"
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid item container md={12} justify="center" spacing={3}>
                    <Grid item md={4} justify="center">
                      <TextField
                        id="outlined-basic"
                        label="Empresa"
                        variant="outlined"
                        fullWidth
                        onChange={event => handleEnterpriseChange(event)}
                      />
                    </Grid>
                    <Grid item md={4} justify="center">
                      <TextField
                        id="outlined-basic4"
                        label="Oficina"
                        variant="outlined"
                        fullWidth
                        onChange={event => handleOfficeChange(event)}
                      />
                    </Grid>
                    <Grid item md={4} justify="center" >
                      <TextField
                        id="outlined-basic4"
                        label="Calle"
                        variant="outlined"
                        fullWidth
                        onChange={event => handleAdressChange(event)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container md={12} justify="center" spacing={3}>
                    <Grid item md={3} justify="center">
                      <TextField
                        id="outlined-basic1"
                        label="Numero"
                        variant="outlined"
                        onChange={event => handleNumberChange(event)}
                      />
                    </Grid>
                    <Grid item md={3} justify="center">
                      <TextField
                        id="outlined-basic2"
                        label="Comuna"
                        variant="outlined"
                        onChange={event => handleCommuneChange(event)}
                      />
                    </Grid>
                    <Grid item md={3} justify="center">
                      <TextField
                        id="outlined-basic3"
                        label="Ciudad"
                        variant="outlined"
                        onChange={event => handleCiudadChange(event)}
                      />
                    </Grid>
                    <Grid item md={3} justify="center">
                      <TextField
                        id="outlined-basic3"
                        label="Region"
                        variant="outlined"
                        onChange={event => handleRegionChange(event)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container md={12} justify="center" spacing={3}>
                    <Grid item md={12} justify="center">
                      <TextField
                        id="outlined-basic1"
                        label="Comentarios"
                        variant="outlined"
                        fullWidth
                        onChange={event => handleCommentsChange(event)}
                      />
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <Grid item container xs={12} justify="center">
                  <Grid item container xs={6} justify="center">
                    <TextField
                      id="outlined-basic"
                      label="Empresa"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    justify="center"
                    style={{ marginTop: "-1em" }}
                  >
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="Fecha"
                          format="dd/MM/yyyy"
                          value={selectedDate ? moment(selectedDate) : null}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                          okLabel="Aceptar"
                          cancelLabel="Cancelar"
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={sendNotification}
          >
            {internalTransport
              ? "Notificar a ingresos y transporte"
              : "Notificar a ingresos"}
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
};

export default ModalDespacho;

ModalDespacho.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  internalTransport: PropTypes.bool
};
