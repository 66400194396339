import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ModalMessageFiles(props) {
  const { open, id, closeModal, moduleType } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const scroll = "paper";

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Guías faltantes</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form-row" style={{ marginBottom: "5rem" }}>
          Aún no se completa el ingreso de guías. Podrás revisar el ingreso de
          guías presionando el botón revisar guías.
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalMessageFiles.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  message: PropTypes.string,
  id: PropTypes.string
};
