import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
} from "@material-ui/core";
import {
    Call
} from '@material-ui/icons/';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {blue} from "@material-ui/core/colors";
import { spacing } from "@material-ui/system";

import api from "utils/API";
import moment from 'moment-timezone';
import 'moment/locale/es' ;
moment.locale("es");

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

export default function TableReservation({
        patient,
        selectHour,
        specialist,
        worker,
        telemedicineData=null,
        invitationUser=false,
        handleCheckCall
    }) {

    const [consultData, setconsultData] = useState([
        [`${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`  , "Paciente"],
        [`${moment(selectHour.start).format("YYYY-MM-DD")}`, "Fecha"],
        [`${moment(selectHour.start).format("HH:mm")}`, "Hora"],
        [specialist.medico_nombre_completo ? specialist.medico_nombre_completo : `${specialist.nombres} ${specialist.apellido_paterno} ${specialist.apellido_materno}`  , "Medico"],
        [specialist.medico_especialidad ? specialist.medico_especialidad : specialist.nombre_especialidad , "Medico Especialidad"],
    ]);

    return (
        <div>
            <Paper>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell className="text-center align-top">Campo</TableCell>
                            <TableCell className="text-center align-top">Dato</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {consultData.map((row, index)=> (
                            <TableRow key={`${index}-personal-row`}>
                                    <TableCell className="text-center align-middle">
                                    {row[1]}
                                </TableCell >
                                <TableCell className="text-center align-middle">
                                    {row[0]}
                                </TableCell>
                            </TableRow>
                            ))}
                            {worker.map((row, index)=> (
                                <TableRow key={`${index}-personal-row`}>
                                    <TableCell className="text-center align-middle">
                                    {`${row.nombre_cargo}`}
                                </TableCell >
                                <TableCell className="text-center align-middle">
                                    {`${row.first_name} ${row.last_name}`}
                                </TableCell>
                                </TableRow>
                            ))}
                            {telemedicineData &&
                             <TableRow>
                                <TableCell className="text-center align-middle">
                                    Acceso a la reunión
                                </TableCell >
                                <TableCell className="text-center align-middle">
                                {   telemedicineData?.estado == "Realizada" ?
                                        `Llamada finalizada` :
                                    invitationUser ?
                                    telemedicineData?.sid_sala === "-" ?
                                        <Button
                                            disabled
                                            variant="contained"
                                            size="large"
                                            startIcon={<Call />}
                                        >
                                            llamada no iniciada
                                        </Button> :
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            startIcon={<Call />}
                                            onClick={handleCheckCall}
                                        >
                                            Medico llamando
                                        </Button> :
                                    `Sin acceso a la llamada`
                                }
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Paper>
        </div>
    );
}
