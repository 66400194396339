import { useState } from "react"

function usePagination(rows) {
  const [pagination, setpagination] = useState({ page: 0, rowsPerPage: rows })

  const handleChangePage = (event, newPage) => {
    setpagination({ ...pagination, page: newPage })
  }

  const handleChangeRowsPerPage = (event) => {
    setpagination({ ...pagination, page: 0, rowsPerPage: +event.target.value })
  }

  return ([pagination, handleChangePage, handleChangeRowsPerPage])
}

export default usePagination
