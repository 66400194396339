import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import api from "utils/API";
import PropTypes from "prop-types";
import TableRecords from "./TableRecords";
import Filters from "./Filters";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");



const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function ModalHojaRegistro(props) {
  const { open, closeModal, dataPatient, dataFile, tensAssigned, clinic} = props;
  const [dateStart, setDateStart] = useState(moment().subtract(7, "d"));
  const [dateEnd, setDateEnd] = useState(moment());
  const [position, setPosition] = useState(null);
  const [professional, setProfessional] = useState(null);

  const [changes, setChanges] = useState(true);
  const [records, setRecords] = useState([]);
  const [listProfessionals, setListProfessionals] = useState([]);
  const [listPositions, setListPositions] = useState([]);

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);

    const getFirstLoad = api.get(
      `api/paciente/${
        dataFile.id
      }/hojaregistros/filtros/?fecha_inicio=${moment()
        .subtract(7, "d")
        .format("YYYY-MM-DD")}T00:00:00&fecha_fin=${moment().format(
        "YYYY-MM-DD"
      )}T23:59:59`
    );

    const getFilters = api.get(`api/paciente/${dataFile.id}/conseguirfiltros/`);

    Promise.all([getFirstLoad, getFilters])
      .then(response => {
        setRecords(response[0].data);
        if (response[1].data.cargos.length > 0) {
          setListPositions(response[1].data.cargos);
        }
        if (response[1].data.profesionales.length > 0) {
          setListProfessionals(response[1].data.profesionales);
        }

      })
      .catch(error => console.log("Error promise", error.response));
  };

  const handleDateStartChange = date => {

    setDateStart(date);
    if (professional != null && position != null) {
      let fechaInicio = `${moment(date).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position}&profesional=${professional}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    } else if (professional != null) {
      let fechaInicio = `${moment(date).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?profesional=${professional}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    } else if (position != null) {
      let fechaInicio = `${moment(date).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    } else {
      let fechaInicio = `${moment(date).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    }
  };

  const handleDateEndChange = date => {

    setDateEnd(date);
    if (professional != null && position != null) {
      let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(date).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position}&profesional=${professional}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    } else if (professional != null) {
      let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(date).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?profesional=${professional}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    } else if (position != null) {
      let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(date).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    } else {
      let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(date).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    }
  };

  const handlePosition = position => {

    setPosition(position.target.value);
    if (professional != null) {
      let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position.target.value}&profesional=${professional}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    } else {
      let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position.target.value}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    }
  };

  const handleProfessional = professional => {

    setProfessional(professional.target.value);
    console.log("se cambió profesional");

    if (position != null) {
      let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?cargo=${position}&profesional=${professional.target.value}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    } else {
      let fechaInicio = `${moment(dateStart).format("YYYY-MM-DD")}T00:00:00`;
      let fechaFin = `${moment(dateEnd).format("YYYY-MM-DD")}T23:59:59`;
      const getInfo = api.get(
        `api/paciente/${dataFile.id}/hojaregistros/filtros/?profesional=${professional.target.value}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
      );

      Promise.all([getInfo])
        .then(response => {
          setRecords(response[0].data);

        })
        .catch(error => console.log("Error promise", error.response));
    }
  };

  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
        disableBackdropClick
        scroll="paper"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          align="center"
        >
          Hoja de registro
        </DialogTitle>
        <DialogContent scroll={"paper"}>
          <Filters
            dateStart={dateStart}
            handleDateStartChange={handleDateStartChange}
            dateEnd={dateEnd}
            handleDateEndChange={handleDateEndChange}
            position={position}
            handlePosition={handlePosition}
            professional={professional}
            setProfessional={setProfessional}
            listProfessionals={listProfessionals}
            listPositions={listPositions}
            handleProfessional={handleProfessional}
          />
          <TableRecords
            records={records}
            dataPatient={dataPatient}
            dataFile={dataFile}
            setChanges={setChanges}

            closeModal={closeModal}
            tensAssigned={tensAssigned}
            clinic={clinic}
          />
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={closeModal}
            style={{ textTransform: "none" }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ModalHojaRegistro.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  dataPatient: PropTypes.object,
  dataFile: PropTypes.object
};
