import React, {useEffect, useState} from "react";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {useSelector} from 'react-redux';
import {Sort} from '@material-ui/icons/';
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import {
  Typography,
  Grid,
  Tooltip,
  Paper,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
});

const TableResponsive = props => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.pageSize || 50);
  const [oderBy, setOderBy] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [listFilter, setListFilter] = useState([]);

  useEffect(() => {
    const tempArray = Array.from(Array(props.headers.filter((row) => !row.hidding ).length).keys()).map(() => {return ""});
    setListFilter(tempArray)
  }, []);

  const handleOrderBy = (newOrderBy, headerEval) => {
    if (headerEval !== false){
      setOderBy(newOrderBy);
      if (newOrderBy === oderBy) {
        setOrderType(orderType === "asc" ? "desc" : "asc");
      } else {
        setOrderType("asc");
      }
      setPage(0);
    }
  };

  const handleOnChange = (value, index) => {
    const tempArray = [...listFilter];
    tempArray[index] = value;
    setListFilter(tempArray);
    setPage(0);
  }

  const globalOpenBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);

  const filterData = (props.data || []).filter((row) =>
    row?.filter((item) => !item.hidding ).every((item, index) =>  {
      const tempValue = (item.value === undefined || item.value === null) ? "" : item.value;
      if (item.eval === false|| listFilter[index] === "" || listFilter[index] === undefined) return true;
      return tempValue.toString().toUpperCase().includes(listFilter[index].toString().toUpperCase())
    })
  ).sort((a, b) => {
    if (oderBy !== null && a[oderBy]?.value && b[oderBy]?.value) {
      let isnum = /^\d+$/.test(a[oderBy].value.toString().replace('$', '')) && /^\d+$/.test(b[oderBy].value.toString().replace('$', ''));
      const tempA = isnum ? parseInt(a[oderBy].value) : a[oderBy].value;
      const tempB = isnum ? parseInt(b[oderBy].value) : b[oderBy].value;
      if (orderType === "asc") {
        return tempA > tempB ? 1 : -1;
      } else {
        return tempA < tempB ? 1 : -1;
      }
    }  else if (b[oderBy]?.value === undefined && a[oderBy]?.value === undefined) {
      return 0
    }  else if (b[oderBy]?.value === undefined ) {
      return orderType === "asc" ? 1 : -1
    } else if (a[oderBy]?.value === undefined) {
      return orderType === "asc" ? -1 : 1
    }
    return 0
  })

  const dataPagination = filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const totalPage = parseInt(filterData.length/rowsPerPage)+ 1;

  const numberArray = Array.from({ length: totalPage }, (_, index) => index + 1);


  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >    
        <nav aria-label="...">
          <ul class="pagination">
            {page !== 0 &&
              <li class="page-item">
              <a class="page-link" onClick={() => setPage(page -1 )} disabled >Anterior</a>
            </li>
            }
            {numberArray.map((row, index) => (
              (page === index ?
              <li class="page-item active"><a class="page-link">{index + 1}</a></li>
              :
              <li class="page-item"><a class="page-link" onClick={() => setPage(index)}>{index + 1}</a></li>)
            ))}
          
            {page !== totalPage -1 &&
              <li class="page-item">
              <a class="page-link" onClick={() => setPage(page + 1 )} disabled >Siguiente</a>
            </li>
            }
          </ul>
        </nav>
      </Grid>
      <table className="table table-striped table-hover table-responsive table-bordered">
        <thead>
        <tr>
            {props.headers.filter((row) => !row.hidding ).map((header, index) => (
              header.eval !== false ?
              <Tooltip
                key={`header-${index}`}
                placement="top"
                title={
                  <React.Fragment>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <Typography variant="body2" gutterBottom>{`Ordernar por ${header.label}`}</Typography>
                    </Grid>
                  </React.Fragment>
                }
              >
                <th


                  style={{...header.style || {}, cursor: "pointer", padding: "0 10px"}}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    item xs={12}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      item xs={10}
                      onClick={() => handleOrderBy(index, header.eval)}
                    >
                      {header.label}
                    </Grid>
                    {oderBy === index  &&
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      item xs={2}
                      onClick={() => handleOrderBy(index, header.eval)}
                      style={{minWidth: "10px"}}
                    >
                      <Sort style={{transform: `rotate(${orderType === "asc" ? 180 : 0}deg)`, transition: "all 0.2s"}}/>
                    </Grid>
                    }
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      item xs={12}
                    >
                      <DetailText
                        value={listFilter[index]}
                        onchange={(e) => {handleOnChange(e.target.value, index)}}
                        validation={false}
                        style={{padding: "0"}}
                      />
                    </Grid>
                  </Grid>
                </th>
              </Tooltip> :
                <th
                    key={`header-${index}`}
                    style={{...header.style || {}}}
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                  >
                    {header.label}
                  </Grid></th>
              ))}
        </tr>
        </thead>
        {!globalOpenBackdrop ?
          <tbody>
            {dataPagination.map((row, index) => (
                <tr key={`${index}-row`}>
                  {row.filter((item) => !item.hidding ).map((item, indexItem) => (
                    <td className={"tdItem"} key={`${indexItem}-${index}-cell`}>{item._value_ || item.value }</td>
                  ))}
                </tr>
              ))}
          </tbody> : <tbody><tr >...Recargando</tr></tbody>
          }
      </table>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >    
        <nav aria-label="...">
          <ul class="pagination">
            {page !== 0 &&
              <li class="page-item">
              <a class="page-link" onClick={() => setPage(page -1 )} disabled >Anterior</a>
            </li>
            }
            {numberArray.map((row, index) => (
              (page === index ?
              <li class="page-item active"><a class="page-link">{index + 1}</a></li>
              :
              <li class="page-item"><a class="page-link" onClick={() => setPage(index)}>{index + 1}</a></li>)
            ))}
          
            {page !== totalPage -1 &&
              <li class="page-item">
              <a class="page-link" onClick={() => setPage(page + 1 )} disabled >Siguiente</a>
            </li>
            }
          </ul>
        </nav>
      </Grid>
    </Paper>
  );
};

export default TableResponsive;

TableResponsive.propTypes = {
  benefits: PropTypes.array,
  setmodal: PropTypes.func
};
