import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import Link from "@material-ui/core/Link";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      /*}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
    */
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      allowNegative={false}
      displayType="text"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function SupplierTable(props) {
  const classes = useStyles();
  const {
    suppliers,
    setAddSupplier,
    setOpenModalQuotes,
    setAuxAddSupplier,
    setTempRow,
    infoRequest,
    rowSelected,
    setRowSelected
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChange = event => {
    setRowSelected(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Proveedor</TableCell>
              <TableCell align="center">Monto</TableCell>
              <TableCell align="center" style={{ minWidth: "250px" }}>
                Comentario
              </TableCell>
              <TableCell align="center">Archivo</TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align="center">{row.nombre}</TableCell>
                    <TableCell align="right">
                      <NumberFormatCustom
                        value={row.monto}
                        thousandSeparator="."
                        displayType={"text"}
                        prefix={"$ "}
                      />
                    </TableCell>
                    <TableCell align="center">{row.observacion}</TableCell>
                    <TableCell align="center">
                      {row.archivo != null ? (
                        <Link
                          href={`${row.archivo}`}
                          underline="none"
                          target="_blank"
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ textTransform: "none" }}
                          >
                            Ver
                          </Button>
                        </Link>
                      ) : (
                        " - "
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <RadioGroup value={rowSelected} onChange={handleChange}>
                        <FormControlLabel
                          value={`${index}`}
                          control={<Radio color="primary" />}
                        />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={suppliers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
      />
    </Paper>
  );
}

SupplierTable.propTypes = {
  suppliers: PropTypes.array,
  setAddSupplier: PropTypes.func,
  setOpenModalQuotes: PropTypes.func,
  setAuxAddSupplier: PropTypes.func,
  setTempRow: PropTypes.func,
  infoRequest: PropTypes.object,
  rowSelected: PropTypes.string,
  setRowSelected: PropTypes.func
};
