import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ModalEditProvider from "./ModalEditProvider";
import ModalAddProvider from "./ModalAddProvider";
import { useSortableData } from "./SortableData/SortableData";
import TableProviders from "./Tables/TableProviders";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export default function ProvidersRequest() {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [modalEditProvider, setModalEditProvider] = useState(false);
  const [modalAddProvider, setModalAddProvider] = useState(false);

  const [changes, setChanges] = useState(true);
  const [providers, setProviders] = useState([]);
  const [idEditProvider, setIdEditProvider] = useState("");
  const { items, requestSort, sortConfig } = useSortableData(providers);
  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  const [providerName, setProviderName] = useState("");
  const [providerAdress, setProviderAdress] = useState("");
  const [providerContact, setProviderContact] = useState("");
  const [providerPhone, setProviderPhone] = useState("");
  const [providerProducts, setProviderProducts] = useState("");
  const [providerComments, setProviderComments] = useState("");
  const [providerEvaluation, setProviderEvaluation] = useState("");

  const openModalEditProvider = (
    open,
    id,
    name,
    adress,
    contact,
    phone,
    products,
    comments,
    evaluation
  ) => {
    setModalEditProvider(open);
    setIdEditProvider(id);
    setProviderName(name);
    setProviderAdress(adress);
    setProviderContact(contact);
    setProviderPhone(phone);
    setProviderProducts(products);
    setProviderComments(comments);
    setProviderEvaluation(evaluation);
  };

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);

    const requestGetProviders = api.get("api/proveedores/");

    Promise.all([requestGetProviders])
      .then(response => {
        setProviders(response[0].data);

      })

  };

  let searchProviders = items.filter(provider => {
    return (
      provider.id
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      provider.nombre.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      provider.direccion.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      provider.contacto.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      provider.telefono
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      provider.producto_especialidad
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      provider.comentarios.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      provider.evaluacion
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    );
  });

  return (
    <GridContainer>
      <ViewTitle
        title="PROVEEDORES"
        message="En esta sección podrás revisar los proveedores."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to="/admin/purchases/tasks/">
              Tareas de compras
            </Link>
            <Typography color="textPrimary">Proveedores</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="mt-5">
              <TextField
                id="outlined-basic"
                label="Buscar"
                variant="outlined"
                value={search}
                className="mt-1 mb-4"
                onChange={e => setSearch(e.target.value.substr(0, 20))}
              />
              <GridContainer justify="center">
                <Button
                  style={{ textTransform: "none" }}
                  variant="contained"
                  color="primary"
                  onClick={() => setModalAddProvider(true)}
                >
                  Agregar proveedor
                </Button>
              </GridContainer>
            </GridContainer>
            <CardBody className="mb-3">
              <TableProviders
                searchProviders={searchProviders}
                requestSort={requestSort}
                getClassNamesFor={getClassNamesFor}
                openModalEditProvider={openModalEditProvider}
              />
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modalEditProvider && (
        <ModalEditProvider
          providerId={idEditProvider}
          providerName={providerName}
          providerAdress={providerAdress}
          providerContact={providerContact}
          providerPhone={providerPhone}
          providerProducts={providerProducts}
          providerComments={providerComments}
          providerEvaluation={providerEvaluation}
          open={modalEditProvider}
          closeModal={() => setModalEditProvider(false)}
        />
      )}
      {modalAddProvider && (
        <ModalAddProvider
          open={modalAddProvider}
          closeModal={() => setModalAddProvider(false)}
        />
      )}
    </GridContainer>
  );
}
