import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Select from "react-select";
import CardTitle from "./FichaPersonal/cardTitle";
import DetailFile from "./FichaPersonal/ViewFichaPersonal/detailFile";
import DetailSelect2 from "./FichaPaciente/detailSelect";
import DetailFileContrato from "./FichaPersonal/ViewFichaPersonal/detailFileContrato";
import ViewTitle from "./FichaPersonal/viewTitle";
import LoadingSpinner from "./FichaPersonal/loadingSpinner";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import api from "utils/API";
import apiform from "utils/APIForm";
import apilink from "utils/APIlink";
import "styles/Styles.css";
import DialogAlert from "components/Dialog/DialogAlert";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import {Tabs, Tab, Box, TextField, InputAdornment} from "@material-ui/core/";
import {AttachMoney} from '@material-ui/icons/';
import { green } from '@material-ui/core/colors';
import DetailPage from "views/Clinic/Endowments/Detail/DetailPage";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AlertDialog from "components/Attendance/AlertDialog";
import WarningIcon from '@material-ui/icons/Warning';
import ModalDialog from "views/Widgets/Common/ModalDialog";
import {CustomCheckBox, CustomSelectAutoComplete} from "utils/componentsStyle";
import moment from "moment-timezone";
import "moment/locale/es";

moment.locale("es");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fondo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
}));

const list_gerente = [
  { value: "wildo", label: "Wildo" },
  { value: "maria", label: "María" },
  { value: "chung", label: "Chung" },
];

const ViewsContrato = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const FileDownload = require("js-file-download");

  const { id } = useParams();
  const [contrato, setContrato] = useState();
  const [gerente, setGerente] = useState("");
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [sexo, setSexo] = useState("");
  const [estado_civil, setEstado_civil] = useState("");
  const [direccion_region, setDireccion_region] = useState("");
  const [nacionalidad, setNacionalidad] = useState("");
  const [banco, setBanco] = useState("");
  const [tipo_cuenta_banco, setTipo_cuenta_banco] = useState("");
  const [nivel_estudios, setNivel_estudios] = useState("");
  const [estado_estudios, setEstado_estudios] = useState("");
  const [tipo_contrato, setTipo_contrato] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [id_cargo, setId_cargo] = useState();
  const [REG, setREG] = useState("");
  const [RCM, setRCM] = useState("");
  const [nombre_isapre, setNombre_isapre] = useState("");
  const [id_lista_afp, setId_lista_afp] = useState();

  const [hoja_contrato, setHoja_contrato] = useState();
  const [hoja_anexo, setHoja_anexo] = useState();
  const [firma, setfirma] = useState();

  const [fotos_perfil, setFotos_perfil] = useState(null);

  const [abrirAlerta,setAbrirAlerta] = React.useState(false);
  const [titulo,setTitulo] = React.useState('');
  const [mensaje,setMensaje] = React.useState('');
  const [tipoAlerta,setTipoAlerta] = React.useState('');

  const [get_contratos_anexos, setGet_contratos_anexos] = useState();
  const [get_azure_modelo_face, setGet_azure_modelo_face] = useState();
  const [get_azure_modelo_fecha, setGet_azure_modelo_fecha] = useState();

  const [vigencia, setvigencia] = useState({value: false, label: "Inactivo"});

  const [area, setArea] = useState();

  const [texto_anexo, setTexto_anexo] = useState();

  const request = [];

  const [modal_dialog_alert, setModal_dialog_alert] = useState(false);

  const [getCentrosCostos, setGetCentrosCostos] = useState([]);
  const [idCentroCosto, setIdCentroCosto] = useState("");
  const [centroCostos, setCentroCosto] = useState("");
  const [getVariables, setGetVariables] = useState([]);
  const [getDocumentos, setGetDocumentos] = useState([]);
  const [fileCharge, setfileCharge] = useState(false);

  const [updateView, setUpdateView] = useState(true);

  const [selectedBoss, setselectedBoss] = useState([])


  const dictTypeGratification = useSelector(state => state.currentList.DICCIONARIO_TIPO_GRATIFICACION);
  const list_cargo = useSelector(state => state.currentList.listCharges);
  const dict_cargo = useSelector(state => state.currentList.dictCharges);
  const list_afp = useSelector(state => state.currentList.listAFP)
  const list_sexo = useSelector(state => state.currentList.LISTA_SEXO)
  const list_estado_civil = useSelector(state => state.currentList.LISTA_ESTADO_CIVIL)
  const list_nacionalidad = useSelector(state => state.currentList.LISTA_NACIONALIDAD)
  const list_banco = useSelector(state => state.currentList.LISTA_BANCO)
  const list_tipo_cuenta_banco = useSelector(state => state.currentList.LISTA_CUENTA_BANCO)
  const list_nivel_estudios = useSelector(state => state.currentList.LISTA_TIPO_ESTUDIOS)
  const list_estado_estudios = useSelector(state => state.currentList.LISTA_ESTADO_ESTUDIO)
  const list_tipo_contrato = useSelector(state => state.currentList.LISTA_TIPO_CONTRATO)
  const list_empresa = useSelector(state => state.currentList.LISTA_EMPRESA)
  const list_isapres = useSelector(state => state.currentList.LISTA_ISAPRES)
  const list_variables_personal = useSelector(state => state.currentList.LISTA_VARIABLES_PERSONAL)
  const lista_key = useSelector(state => state.currentList.LISTA_KEY_AUXILIARES)


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const checkList = (lists) => {
    return lists.every((list) =>{
      return list && list.length >0
    } )
  }

  useEffect(() => {
    setLoading(false);
    if (checkList([lista_key, list_cargo, list_afp])){
      api.get(`api/personal/jefatura/ficha/${id}`).then((response) => {
        setselectedBoss(response.data.reduce((accum, obj) => ([
          ...accum,
          {value: obj.id, label: `${obj.first_name} ${obj.last_name}`}
        ]), []));
      });
      api.get(`api/fichapersonal/${id}`).then((result) => {
        const fileInfo = result.data
        console.log(result.data)
        setDireccion_region(fileInfo.direccion_region);

        if (fileInfo.vigencia){
          setvigencia({value: true, label: "Activo"});
        }
        setREG(fileInfo.REG)
        setRCM(fileInfo.RCM)
        setIdCentroCosto(fileInfo.id_centro_costo);
        setGet_azure_modelo_face(fileInfo.azure_face_id)
        setGet_azure_modelo_fecha(fileInfo.fecha_actualizacion_modelo)
        setContrato(fileInfo);
        list_sexo.filter((filtered) => filtered.value === fileInfo.sexo).map((maped) => {setSexo(maped)});
        list_estado_civil.filter((filtered) => filtered.value === fileInfo.estado_civil).map((maped) => {setEstado_civil(maped.label)});
        list_nacionalidad.filter((filtered) => filtered.value === fileInfo.nacionalidad).map((maped) => {setNacionalidad(maped.label)});
        list_banco.filter((filtered) => filtered.value === fileInfo.banco).map((maped) => {setBanco(maped.label)});
        list_tipo_cuenta_banco.filter((filtered) => filtered.value === fileInfo.tipo_cuenta_banco).map((maped) => {setTipo_cuenta_banco(maped.label)});
        list_nivel_estudios.filter((filtered) => filtered.value === fileInfo.nivel_estudios).map((maped) => {setNivel_estudios(maped.label)});
        list_estado_estudios.filter((filtered) => filtered.value === fileInfo.estado_estudios).map((maped) => {setEstado_estudios(maped.label)});
        list_tipo_contrato.filter((filtered) => filtered.value === fileInfo.tipo_contrato).map((maped) => {setTipo_contrato(maped.label)});
        list_empresa.filter((filtered) => filtered.value === fileInfo.empresa).map((maped) => {setEmpresa(maped.label)});
        list_isapres.filter((filtered) => filtered.value === fileInfo.nombre_isapre).map((maped) => {setNombre_isapre(maped.label)});
        list_afp.filter((filtered) => filtered.value === fileInfo.id_lista_afp).map((maped) => {setId_lista_afp(maped.label)});
        const temp_cargo = dict_cargo[fileInfo.id_cargo]

        setId_cargo(temp_cargo.nombre_corto)
        setArea(temp_cargo.gerencia)

        setLoading(true);
        setfileCharge(true)
      })
      api.get(`api/personal/ficha/${id}/variables/`).then((response) => {
        setGetVariables(response.data);
      })
      api.get(`api/personal/ficha/${id}/documentos/`).then((response) => {
        setGetDocumentos(response.data);
      })
      api.get("api/contratosanexosficha/").then((result) => {
        setGet_contratos_anexos(result.data);
      });
      api.get("api/centrocosto/").then((response) => {
        setGetCentrosCostos(response.data);
      });
    }
  }, [id, lista_key, list_cargo, list_afp]);


  useEffect(() => {
    if (updateView){
      setUpdateView(false)
      api.get(`api/personal/ficha/${id}/documentos/`)
        .then((response) => {
          setGetDocumentos(response.data)
          setLoading(true);
        })
        .catch((error) => {
          {}
        });
    }


    getCentrosCostos
      .filter((filtered) => filtered.id === idCentroCosto)
      .map((maped) => {
        setCentroCosto(maped.nombre);
      });
  });

  const handleGerente = (gerente) => {
    setGerente(gerente);
  };

  const removePersonalFile = (request) => {
    if (request) {
      api
        .put(`api/fichapersonal/${id}`, { vigencia: false })
        .then((response) => console.log(response))


      api
        .put(
          `api/solicitudesrealizadas/${localStorage.getItem("request_id")}`,
          {
            estado: "Listo",
            id_cuenta_gestionando: localStorage.getItem("user_id"),
            id_tipo_solicitud: localStorage.getItem("request_type"),
          }
        )
        .then((resp) => {
          console.log(resp);
          alert("Eliminación exitosa");
          window.location.href = "/admin/request/";
        })

    } else {
      api
        .put(`api/fichapersonal/${id}`, { vigencia: false })
        .then((response) => {
          console.log(response);
          alert("Eliminación exitosa");
          window.location.href = "/admin/endowment/";
        })

    }
  };

  const handleGenerarContrato = (request) => {
    if (request) {
      window.open(
        `${apilink}api/personal/ficha/${id}/contrato/${gerente.value}`,
        "_blank"
      );
      api
        .put(`api/fichapersonal/${id}`, { status: "Esperando Firma" })
        .then((response) => console.log(response))

      api
        .put(
          `api/solicitudesrealizadas/${localStorage.getItem("request_id")}`,
          {
            estado: "Esperando Firma",
            id_cuenta_gestionando: localStorage.getItem("user_id"),
            id_tipo_solicitud: localStorage.getItem("request_type"),
          }
        )
        .then((resp) => console.log(resp))
        .catch((err) => {});
    } else {
      window.open(
        `${apilink}api/personal/ficha/${id}/contrato/${gerente.value}`,
        "_blank"
      );
      api
        .put(`api/fichapersonal/${id}`, { status: "Esperando Firma" })
        .then((response) => console.log(response))

    }
  };

  const uploadFotos = () =>{

    setLoading(false)


    const fotos_perfil_arreglo = Object.values(fotos_perfil)
    for (let i = 0; i < fotos_perfil_arreglo.length; i++) {
      const formData = new FormData()
      const archivo=fotos_perfil_arreglo[i]
      formData.append("archivo",archivo )
      formData.append("id_ficha_personal", id)
      formData.append("nombre_archivo", archivo.name)
      formData.append("tipo_archivo", "Foto_Perfil")
      apiform.post(`api/personal/ficha/${id}/documentos/`, formData).
      then((response) => {
        setLoading(true)

        setTitulo("¡Exito!")
        setTipoAlerta("success")
        setMensaje("Se ha subido la foto de forma exitosa.")
        setFotos_perfil(null)
        setAbrirAlerta(true)
        setUpdateView(true)
      }).catch((error) => {
        setLoading(true)

        setTitulo("¡Atencion!")
        setTipoAlerta("error")
        setMensaje("Ha ocurrido un problema al subir la foto.")
        setFotos_perfil(null)
        setAbrirAlerta(true)
      });
    }

  }

  const uploadFirma = () =>{

    setLoading(false)

    const arrayName = firma.name.split(".")
    const name = `${contrato?.rut}-firma.${arrayName[arrayName.length-1]}`
    const formData = new FormData()
    formData.append("archivo",firma )
    formData.append("id_ficha_personal", id)
    formData.append("nombre_archivo", name)
    formData.append("tipo_archivo", "Firma")
    apiform.post(`api/personal/ficha/${id}/documentos/`, formData).
    then((response) => {
      setLoading(true)

      setTitulo("¡Exito!")
      setTipoAlerta("success")
      setMensaje("Se ha subido la firma de forma exitosa.")
      setFotos_perfil(null)
      setAbrirAlerta(true)
      setUpdateView(true)
    }).catch((error) => {
      setLoading(true)

      setTitulo("¡Atencion!")
      setTipoAlerta("error")
      setMensaje("Ha ocurrido un problema al subir la Firma.")
      setFotos_perfil(null)
      setAbrirAlerta(true)
    });


  }

  const handleAdjuntarFotos = lista_fotos_perfil => {
    const fotos_perfil_arreglo = Object.values(lista_fotos_perfil)
    let status = true;
    if (lista_fotos_perfil.length > 0) {
      for (let i = 0; i < fotos_perfil_arreglo.length; i++) {
        const archivo = fotos_perfil_arreglo[i];
        if (
          archivo.type === "image/jpg" ||
          archivo.type === "image/jpeg" ||
          archivo.type === "image/png"
        ) {
          continue;
        } else {
          status = false;
          setTitulo("¡Atencion!");
          setTipoAlerta("error");
          setMensaje(
            "Verifique formato de las imagenes, solo se admiten los formatos jpeg, jpg y png."
          );
          setFotos_perfil(null);
          setAbrirAlerta(true);
        }
      }
      if (status) {
        setFotos_perfil(lista_fotos_perfil);
      } else {
        setFotos_perfil(null);
      }
    } else {
      setFotos_perfil(null);
    }
  };

  const trainFotos = () => {

    setLoading(false)
    apiform.post(`api/registroasistencia/entrenar/${id}`).
      then((response) => {

        if(response.data){
          if(response.data.Exito){

            setTitulo("¡Exito!")
            setTipoAlerta("success")
            setMensaje(response.data.Exito)
            setAbrirAlerta(true)

          }
        }
        setLoading(true)

      }).catch((error) => {

        setTipoAlerta("error")
        setTitulo("¡Atencion!")
        setTipoAlerta("error")
        setMensaje(error.response.data.Error)
        setAbrirAlerta(true)
        setLoading(true)


      });

  }
  const uploadContrato = (request) => {

    setLoading(false)

    const formData = new FormData();

    formData.append("archivo", hoja_contrato);
    formData.append("id_ficha_personal", id);
    formData.append("nombre_archivo", hoja_contrato.name);
    formData.append("tipo_archivo", "Contrato");

    if (request) {
      apiform
        .post(`api/personal/ficha/${id}/documentos/`, formData)
        .then((response) => {
          console.log(response);
          api
            .put(`api/fichapersonal/${id}`, { status: "Listo", vigencia: true })
            .then((response) => console.log(response))

          api
            .put(
              `api/solicitudesrealizadas/${localStorage.getItem("request_id")}`,
              {
                estado: "Listo",
                id_cuenta_gestionando: localStorage.getItem("user_id"),
                id_tipo_solicitud: localStorage.getItem("request_type"),
              }
            )
            .then((resp) => {
              console.log(resp);
              alert("Contrato subido exitosamente");
              window.location.href = `/admin/request/`;
            })
            .catch((err) => {});
        })
        .catch((error) => {
          console.log("Error documentos", error.response);
          alert(`ERROR: ${error.response.data.Error}`);
        });
    } else {
      apiform
        .post(`api/personal/ficha/${id}/documentos/`, formData)
        .then((response) => {

          api
            .put(`api/fichapersonal/${id}`, {
              status: "Listo",
              apellido_materno: contrato.apellido_materno,
              apellido_paterno: contrato.apellido_paterno,
              direccion_calle: contrato.direccion_calle,
              direccion_numero: contrato.direccion_numero,
              empresa: contrato.empresa,
              estado_civil: contrato.estado_civil,
              fecha_fin_contrato: contrato.fecha_fin_contrato,
              fecha_inicio_contrato: contrato.fecha_inicio_contrato,
              nacimiento: contrato.nacimiento,
              nacionalidad: contrato.nacionalidad,
              nombres: contrato.nombres,
              rut: contrato.rut,
              tipo_contrato: contrato.tipo_contrato,
            })
            .then((response) => {

              alert("Contrato subido exitosamente");
              window.location.href = `/admin/personalfile/manage/${id}`;
            })
            .catch((error) =>
              console.log("Error put ficha personal", error.response)
            );
        })
        .catch((error) => {

          alert(`ERROR: ${error.response.data.Error}`);
        });
    }
  };

  const generateAnexo = (request) => {
    const formData = new FormData();
    formData.append("texto", texto_anexo);

    if (request) {
      apiform
        .post(`api/personal/ficha/${id}/anexo/${gerente.value}/`, formData)
        .then((response) => {
          FileDownload(response.data, "anexo.pdf");
        })
        .catch((error) => {
          console.log("error response", error.response);
          alert(`ERROR: ${error.response.data}`);
        });
      api
        .put(
          `api/solicitudesrealizadas/${localStorage.getItem("request_id")}`,
          {
            id_cuenta_gestionando: localStorage.getItem("user_id"),
            id_tipo_solicitud: localStorage.getItem("request_type"),
          }
        )
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => console.log(err.response));
    } else {
      apiform
        .post(`api/personal/ficha/${id}/anexo/${gerente.value}/`, formData)
        .then((response) => {
          FileDownload(response.data, "anexo.pdf");
        })
        .catch((error) => {
          console.log("error response", error.response);
          alert(`ERROR: ${error.response.data}`);
        });
    }
  };

  const uploadAnexo = (e, request) => {
    setLoading(false)
    const formData = new FormData();

    formData.append("archivo", hoja_anexo);
    formData.append("id_ficha_personal", id);
    formData.append("nombre_archivo", hoja_anexo.name);
    formData.append("tipo_archivo", "Anexo");

    if (request) {
      apiform
        .post(`api/personal/ficha/${id}/documentos/`, formData)
        .then((response) => console.log(response))
        .catch((error) => {
          console.log("error response", error.response);
          alert(`ERROR: ${error.response.data.Error}`);
        });

      api
        .put(
          `api/solicitudesrealizadas/${localStorage.getItem("request_id")}`,
          {
            id_cuenta_gestionando: localStorage.getItem("user_id"),
            id_tipo_solicitud: localStorage.getItem("request_type"),
          }
        )
        .then((resp) => {
          alert("Anexo subido exitosamente");
          window.location.href = `/admin/vistacontrato/${id}`;
        })
        .catch((err) => {});
    } else {
      apiform
        .post(`api/personal/ficha/${id}/documentos/`, formData)
        .then((response) => {
          alert("Anexo subido exitosamente");
          window.location.href = `/admin/personalfile/manage/${id}`;
        })

    }
  };

  const id_ficha = parseInt(id, 10);
  const handleCerrarAlerta = (event) => {
    setAbrirAlerta(false);
  }


  const _contentContrat_ = <div>
    <>Selecciona gerente para firmar contrato.</>
    <div className="col-md-10 mb-3">
      <Select
        value={gerente}
        onChange={handleGerente}
        options={list_gerente}
        placeholder="Gerente"
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
    </div>
  </div>

  const _actionsContrat_ = <>
    {gerente ? (
      <button
        onClick={() => handleGenerarContrato(props.location.state)}
        className="btn btn-cmh-color"
      >
        Generar contrato
      </button>
    ) : (
      <button className="btn btn-cmh-color" disabled>
        Generar contrato
      </button>
    )}
  </>

  const _contentAnex_ = <div>
    <>Escriba el anexo y seleccione un gerente para firmar contrato.</>
    <div className="col-md-10 mb-3">
      <Select
        value={gerente}
        onChange={handleGerente}
        options={list_gerente}
        placeholder="Gerente"
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
    </div>
    <div className="col-md-12 mb-3">
      <label className="text-dark">Anexo</label>
      <textarea
        className="form-control bg-light font-weight-lighter"
        maxLength="500"
        style={{ color: "#000000" }}
        value={texto_anexo}
        placeholder="Anexo"
        onChange={(e) => {
          setTexto_anexo(e.target.value);
        }}
      />
    </div>
    <div className="col-md-4 mb-3">
      <Select
        value={gerente}
        onChange={handleGerente}
        options={list_gerente}
        placeholder="Gerente"
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
    </div>
  </div>

  const _actionAnex_ = <>
    {texto_anexo && gerente ? (
      <button
        onClick={() => generateAnexo(props.location.state)}
        className="btn btn-cmh-color"
      >
        Generar anexo
      </button>
    ) : (
      <button className="btn btn-cmh-color" disabled>
        Generar anexo
      </button>
    )}
  </>

  const listForm = [
    {
        disabled: true,
        label:"Vigencia",
        value: vigencia.label,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Apellido paterno",
        value: contrato?.apellido_paterno,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Apellido materno",
        value: contrato?.apellido_materno,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Nombres",
        value: contrato?.nombres,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"RUT",
        value: contrato?.rut,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Sexo",
        value: sexo?.label,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Estado civil",
        value: estado_civil,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Dirección",
        value: contrato?.direccion_calle,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Número",
        value: contrato?.direccion_numero,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Block",
        value: contrato?.direccion_block,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Departamento/casa",
        value: contrato?.direccion_departamento,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Ciudad",
        value: contrato?.ciudad,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Región",
        value: direccion_region,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Comuna",
        value: contrato?.direccion_comuna,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Nacionalidad",
        value: nacionalidad,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Fecha de nacimiento",
        value: contrato?.nacimiento,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Teléfono 1",
        value: contrato?.telefono_1,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Teléfono 2",
        value: contrato?.telefono_2,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Teléfono 3",
        value: contrato?.telefono_3,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Email",
        value: contrato?.email,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Email Institucional",
        value: contrato?.email_institucional,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Tipo pago",
        value: contrato?.tipo_pago,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label:"Banco",
        value: banco,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Número de cuenta",
        value: contrato?.cuenta_banco,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Tipo de cuenta",
        value: tipo_cuenta_banco,
        gridStyle: {padding: "15px"}
    },
    {
      disabled: true,
      label: "Estudios",
      value: nivel_estudios,
      gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Estado de estudios",
        value: estado_estudios,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "AFP",
        value: id_lista_afp,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Convenio Isapre",
        value: contrato?.id_convenio_isapre,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Nombre Isapre",
        value: contrato?.nombre_isapre,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Valor pactado Isapre",
        value: contrato?.valor_pactado_isapre,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Fecha de inicio contrato",
        value: contrato?.fecha_inicio_contrato ,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Fecha de fin contrato",
        value: contrato?.fecha_fin_contrato,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Tipo de contrato",
        value: tipo_contrato,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Empresa",
        value: empresa,
        gridStyle: {padding: "15px"}
    },
    {
        disabled: true,
        label: "Gerencia",
        value: area,
        gridStyle: {padding: "15px"}
    },
    {
      _content_:  <DetailSelect2
        label="Jefatura"
        isMulti={true}
        value={selectedBoss}
        list={[]}
        validation={false}
        onchange={() => {}}
        isDisabled={true}
      />,
      gridStyle: {padding: "15px"},
      gridSize: 12
    },
    {
      disabled: true,
      label: "REG",
      value: REG,
      gridStyle: {padding: "15px"}
    },
    {
      disabled: true,
      label: "RCM",
      value: RCM,
      gridStyle: {padding: "15px"}
    },
    {
      disabled: true,
      label: "Título",
      value: contrato?.titulo,
      gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomCheckBox
        disabled={true}
        label="Asignación teléfono"
        value={contrato?.asignacion_telefono}
      />,
      gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomCheckBox
        disabled={true}
        label="Asignación uniforme"
        value={contrato?.asignacion_uniforme}
      />,
      gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomCheckBox
        disabled={true}
        label="Asignación equipo"
        value={contrato?.asignacion_equipo}
      />,
      gridStyle: {padding: "15px"}
    },
    {
      label: "Centro costo",
      value: centroCostos,
      gridStyle: {padding: "15px"}
    },
    {
      disabled: true,
      label: "Funciones",
      value: contrato?.funciones,
      maxRows: 4,
      gridStyle: {padding: "15px"},
      gridSize: 12,
    },
    {
      _content_:  <CustomSelectAutoComplete
        label="Tipo Gratificación"
        value={ dictTypeGratification ? dictTypeGratification[contrato?.tipo_gratificacion] : {value: "Sin seleccionar", label: "Sin selecccionar"}}
        listItem={[]}
        onChange={() => {}}
        disabled={true}
      />,
      gridStyle: {padding: "15px"}
    },
  ]

  const inputProps = {
      startAdornment: (
        <InputAdornment position="start">
        <AttachMoney />
        </InputAdornment>
      ),
    }

  const listFormPersonal = (list_variables_personal || []).map((row) => {
    const input = {
      disabled: true,
      label: row.label,
      value: parseInt(getVariables.filter(filtered => filtered.codigo_variable === row.value)[0]?.valor, 10) || 0,
      gridStyle: {padding: "15px"},
    }

    if (row.value !== "P057"){
      input["InputProps"] = inputProps
    }

    return input
  })



  return (
    <GridContainer>
      <ModalDialog
        open={open}
        onClose={handleClose}
        title={`Generar contrato`}
        _content_={_contentContrat_}
        _actions_ = {_actionsContrat_}
        maxWidth="xs"
      />
      <ModalDialog
        open={open2}
        onClose={handleClose2}
        title={`Generar anexo`}
        _content_={_contentAnex_}
        _actions_ = {_actionAnex_}
        maxWidth="sm"
      />

      <ViewTitle
        title="Ver ficha personal"
        message="A continuación podrás ver en detalle esta ficha personal"
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Recursos humanos</Typography>
            <Typography color="textPrimary">
              <Link color="inherit" to="/admin/endowment/">
                Dotación
              </Link>
            </Typography>
            <Typography color="textPrimary">Detalle dotación</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>

      <GridItem
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <AlertDialog openDialog={abrirAlerta} handleClose={handleCerrarAlerta} message={mensaje} title={titulo} severity={tipoAlerta}/>
        {props.location.state ? (
          <Link
            to={{
              pathname: `/admin/edit/fichapersonal/${id}`,
              state: {
                fromRequest: true
              }
            }}
          >
            <button className="btn btn-cmh-warning">Editar ficha</button>
          </Link>
        ) : (
          <Link to={`/admin/edit/fichapersonal/${id}`}>
            <button className="btn btn-cmh-warning">Editar ficha</button>
          </Link>
        )}
        <Button
          style={{
            backgroundColor: "#f44336",
            color: "white",
            marginTop: "5em",
            marginBottom: "5em",
            height: "38px",
            textTransform: "none"
          }}
          onClick={() => setModal_dialog_alert(true)}
        >
          Eliminar ficha
        </Button>

        <button className="btn btn-cmh-color" onClick={handleClickOpen}>
          Generar contrato
        </button>

        {hoja_contrato ? (
          <button
            className="btn text-white"
            style={{ background: "#35465c" }}
            onClick={() => uploadContrato(props.location.state)}
          >
            Subir contrato
          </button>
        ) : (
          <span className="btn btn-cmh-color btn-file">
            Adjuntar contrato
            <input
              type="file"
              name="archivo"
              onChange={(e) => setHoja_contrato(e.target.files[0])}
            />
          </span>
        )}

        <button className="btn btn-cmh-color" onClick={handleClickOpen2}>
          Generar anexo
        </button>

        {hoja_anexo ? (
          <button
            className="btn text-white"
            style={{ background: "#35465c" }}
            onClick={() => uploadAnexo(props.location.state)}
          >
            Subir anexo
          </button>
        ) : (
          <span className="btn btn-cmh-color btn-file">
            Adjuntar anexo
            <input
              type="file"
              name="archivo"
              onChange={e => setHoja_anexo(e.target.files[0])}
            />
          </span>
        )}
        <Tooltip
          title={"Formato de la firma debe ser de 300x300 pixeles"}
          aria-label="add"
        >
        {firma ? (
          <button
            className="btn text-white"
            style={{ background: "#35465c" }}
            onClick={() => uploadFirma()}
          >
            Subir firma
          </button>
        ) : (
          <span className="btn btn-cmh-color btn-file">
            Adjuntar firma
            <input
              type="file"
              accept="image/x-png,image/jpg,image/jpeg,image/png"
              name="archivo"
              onChange={e => setfirma(e.target.files[0])}
            />
          </span>
        )}
        </Tooltip>
        {fotos_perfil !== null ? (
          <button
            className="btn text-white"
            style={{ background: green[500] }}
            onClick={() => uploadFotos(props.location.state)}
          >
            Click para subir
          </button>
        ) : (
          <span className="btn btn-cmh-color btn-file">
            Adjuntar fotos
            <input
              type="file"
              accept="image/x-png,image/jpg,image/jpeg,image/png"
              name="archivo"
              onChange={e => handleAdjuntarFotos(e.target.files)}
              multiple
            />
          </span>
        )}

        <Tooltip
          title={ get_azure_modelo_fecha ? "El reconocimiento facial fue actualizado el "+moment(get_azure_modelo_fecha,"YYYY-MM-DD").format("DD/MM/YYYY"):"El reconocimiento no ha sido actualizado"}
          aria-label="add"
        >
          {/* <Button
            style={{
              backgroundColor:
                get_azure_modelo_face === "No Ingresado"
                  ? "#dc3545"
                  : "#1B9CFC",
              height: "38px",
              marginTop: "5em",
              marginBottom: "5em"
            }}
            className="btn text-white btn-cmh-color"
            onClick={() => trainFotos(props.location.state)}
            disabled={!loading}
            startIcon={
              get_azure_modelo_face === "No Ingresado" ? <WarningIcon /> : ""
            }
          >
            {get_azure_modelo_face === "No Ingresado"
              ? "Debe entrenar reconocimiento"
              : "Actualizar Reconocimiento"}
          </Button> */}
          <Button
            style={{
              height: "38px",
              marginTop: "5em",
              marginBottom: "5em"
            }}
            className="btn text-white btn-cmh-color"
            disabled={true}
            startIcon={
              get_azure_modelo_face === "No Ingresado" ? <WarningIcon /> : ""
            }
          >
            "No disponible, entrenamiento"
          </Button>
        </Tooltip>
      </GridItem>
      {(loading && fileCharge)? (
        <div>
          <GridItem xs={12} sm={6} md={12}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                centered
              >
                <Tab
                  label="Datos personales"
                  {...a11yProps(0)}
                  style={{ textTransform: "none" }}
                />
                <Tab
                  label="Variables personales"
                  {...a11yProps(1)}
                  style={{ textTransform: "none" }}
                />
                <Tab
                  label="Documentos personales"
                  {...a11yProps(2)}
                  style={{ textTransform: "none" }}
                />
                <Tab
                  label="Variables Dinámicas"
                  {...a11yProps(3)}
                  style={{ textTransform: "none" }}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Card>
                <CardTitle title="Datos personales" class={classes.cardTitle} />
                <GridItem
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <span>Estado de ficha personal: </span>
                  <span className="text-uppercase">
                    <mark>
                      <strong>{contrato?.status}</strong>
                    </mark>
                  </span>
                </GridItem>
                <CardBody>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    item
                    xs={12}
                  >
                    {listForm.map((row) => (
                    <Grid
                      item
                      xs={row.gridSize || 12}
                      sm={row.gridSize || 6}
                      md={row.gridSize || 4}
                      style={row.gridStyle || {}}
                    >
                      {row._content_ ||
                      <TextField
                          variant="outlined"
                          type="text"
                          // error={row.value == ""}
                          // helperText={row.value == "" ? "No puede ser vacio" : ""}
                          style={{ width: '100%' }}
                          {...row}
                          value={row.value || "-"}

                      />}
                    </Grid>
                    ))}
                  </Grid>
                </CardBody>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card>
                <CardTitle
                  title="Variables personales"
                  class={classes.cardTitle}
                />
                <CardBody>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    item
                    xs={12}
                  >
                    {listFormPersonal.map((row) => (
                    <Grid
                      item
                      xs={row.gridSize || 12}
                      sm={row.gridSize || 6}
                      md={row.gridSize || 4}
                      style={row.gridStyle || {}}
                    >
                      {row._content_ ||
                      <TextField
                          variant="outlined"
                          type="text"
                          // error={row.value == ""}
                          // helperText={row.value == "" ? "No puede ser vacio" : ""}
                          style={{ width: '100%' }}
                          {...row}

                      />}
                    </Grid>
                    ))}
                  </Grid>
                </CardBody>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Card>
                    <CardTitle title="Archivos" class={classes.cardTitle} />
                    <CardBody>
                      <GridContainer>
                        {!updateView && getDocumentos.map(document => (
                          <DetailFile
                            id={document.id}
                            name={document.nombre_archivo}
                            link={document.archivo}
                            setUpdateView = {setUpdateView}
                            setLoading = {setLoading}
                          />
                        ))}
                        {get_contratos_anexos
                          ? get_contratos_anexos
                              .filter(
                                contrato =>
                                  contrato.id_ficha_personal === id_ficha
                              )
                              .map(contrato => (
                                <DetailFileContrato
                                  key={contrato.id}
                                  name={contrato.nombre_archivo}
                                  type={contrato.tipo}
                                  link={contrato.archivo}
                                />
                              ))
                          : null}
                        <Grid container>
                          <Grid item xs={1} />
                          <Grid item xs={10}>
                            <div style={{ width: "80vw" }}></div>
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
            <Grid container>
                <Grid item xs={12}>
                  <Card>
                    <CardTitle title="Variables Dinámicas" class={classes.cardTitle} />
                    <CardBody>
                      <GridContainer>
                        <DetailPage/>
                        <Grid container>
                          <Grid item xs={1} />
                          <Grid item xs={10}>
                            <div style={{ width: "80vw" }}></div>
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>
            </TabPanel>
          </GridItem>
        </div>
      ) : (
        <GridItem xs={12} sm={6} md={12}>
          <Card>
            <CardTitle
              title="Cargando información ..."
              class={classes.cardTitle}
            />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        </GridItem>
      )}
      {modal_dialog_alert && (
        <DialogAlert
          open={modal_dialog_alert}
          setOpen={() => setModal_dialog_alert(false)}
          update={() => removePersonalFile(request)}
        />
      )}
    </GridContainer>
  );
};

export default ViewsContrato;
