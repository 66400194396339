import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper as MuiPaper,
  InputBase,
  IconButton,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

const Paper = styled(MuiPaper)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    margin: "20px 0 0 0",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function FilterBox({
  filterText,
  handleFilter,
  IconSpan,
  stylePaper = {},
}) {
  const classes = useStyles();
  return (
    <Paper
      component="form"
      className={classes.root}
      style={{ boxShadow: "0 0 14px 0 rgba(0, 0, 0, 0.38)", ...stylePaper }}
    >
      {IconSpan && (
        <IconButton className={classes.iconButton} style={{ cursor: "auto" }}>
          <IconSpan />
        </IconButton>
      )}
      <InputBase
        className={classes.input}
        placeholder={"Filtro de búsqueda"}
        defaultValue={filterText}
        onChange={handleFilter}
      />
    </Paper>
  );
}
