import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import OrderDetailInfo from "views/Purchases/PurchaseRequest/InProcess/OrderDetail";
import Button from "@material-ui/core/Button";
import apiform from "utils/APIForm";
import SuppliersTable from "./SuppliersTable";
import PurchaseOrderTable from "./PurchaseOrderTable";


export default function PurchaseDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [orders, setOrders] = useState([]);

  const [idRequest, setIdRequest] = useState("");
  const [idGroup, setIdGroup] = useState("");
  const [modalDeleteRecord, setModalDeleteRecord] = useState(false);
  const [changes, setChanges] = useState(true);
  const [infoRequest, setInfoRequest] = useState(null);
  const [auxType, setAuxType] = useState(null);
  const [auxGroup, setAuxGroup] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [openModalQuotes, setOpenModalQuotes] = useState(false);
  const [addSupplier, setAddSupplier] = useState(null);
  const [auxAddSupplier, setAuxAddSupplier] = useState(null);
  const [tempRow, setTempRow] = useState(null);
  const [supplierValidation, setSupplierValidation] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const checkSupplier = (array, set) => {
    let check = array.filter(row => row.monto > 0 && row.archivo);
    if (check.length > 0) {
      set(true);
    } else {
      set(false);
    }
  };

  const initialChargeReception = () => {
    setChanges(false);
    const getProducts = api.get(
      `api/compras/solicitudcotizacion/detalleproductos/${id}/`
    );
    const infoRequest = api.get(`api/solicitudcotizacioncompras/${id}`);
    const getVendors = api.get(
      `api/compras/solicitudcotizacion/proveedores/${id}/`
    );
    Promise.all([getProducts, infoRequest, getVendors])
      .then(response => {
        let tempOrders = [];
        response[0].data.map(row => {
          tempOrders.push({
            ...row,
            show: true
          });
        });
        setOrders(tempOrders);
        setInfoRequest(response[1].data);
        setAuxType(
          response[1].data.tipo_solicitud_cotizar === "Aseo" ? "aseo" : "normal"
        );
        setAuxGroup(
          ["Insumos", "Articulos", "Alimentos"].includes(response[0].data[0].id_insumo_medicamento.grupo_producto)
            ? "insumos"
            : "medicamentos"
        );
        response[1].data.estado === "RevisarOC"
          ? setVendors(response[2].data.filter(row => row.elegida))
          : setVendors(response[2].data);
        checkSupplier(response[2].data, setSupplierValidation);
      })

    setLoading(true);
  };

  const openModalDeleteRecord = (idRequest, idGroup, open) => {
    setIdRequest(idRequest);
    setIdGroup(idGroup);
    setModalDeleteRecord(open);
  };

  function deleteRequest() {
    apiform
      .post(`api/compras/eliminarcompra/${id}`)
      .then(() => {
        window.location.href = "/admin/purchases/requests/";
      })

  }

  function aceptRequest() {

    let tempRow = vendors[parseInt(rowSelected)];

    const formData = new FormData();
    formData.append("nombre", tempRow.nombre);
    formData.append("elegida", true);

    if (infoRequest.estado === "RevisarOC") {
      const sendInfo = {
        tipo_solicitud_cotizar: infoRequest.tipo_solicitud_cotizar,
        cantidad_productos: infoRequest.cantidad_productos,
        fecha_solicitud: infoRequest.fecha_solicitud,
        estado: "IngresarDespacho"
      };

      api
        .put(`api/solicitudcotizacioncompras/${id}`, sendInfo)
        .then(() => {
          window.location.href = "/admin/boss/shopping/";
        })
    } else {
      apiform
        .put(`api/cotizacionescompra/${tempRow.id}`, formData)
        .then(() => {
          const sendInfo = {
            tipo_solicitud_cotizar: infoRequest.tipo_solicitud_cotizar,
            cantidad_productos: infoRequest.cantidad_productos,
            fecha_solicitud: infoRequest.fecha_solicitud,
            estado: "AdjuntarOrdenCompra"
          };

          api
            .put(`api/solicitudcotizacioncompras/${id}`, sendInfo)
            .then(() => {
              window.location.href = "/admin/boss/shopping/";
            })

        })

    }
  }

  return (
    <GridContainer>
      <ViewTitle
        title="Detalle compra"
        message="En esta sección podrás revisar el detalle de la compra seleccionada."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Jefe</Typography>
            <Link color="inherit" href="/admin/boss/shopping/">
              Tareas de compras
            </Link>
            <Typography color="textPrimary">Detalle solicitud</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <OrderDetailInfo id={id} />
            </GridContainer>
            <CardBody>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center align-middle">SKU</th>
                    <th className="text-center align-middle">Descripción</th>
                    <th className="text-center align-middle">Cantidad</th>
                    <th className="text-center align-middle">Observaciones</th>
                    {/*}
                    <th className="text-center align-middle">Acciones</th>
      */}
                  </tr>
                </thead>
                <tbody>
                  {orders
                    .filter(row => row.show)
                    .map(order => (
                      <tr key={order.id}>
                        <td className="text-center align-middle">
                          {order.id_insumo_medicamento.SKU}
                        </td>
                        <td className="text-center align-middle">
                          {order.id_insumo_medicamento.descripcion_producto}
                        </td>
                        <td className="text-center align-middle">
                          {order.cantidad}
                        </td>
                        <td className="text-center align-middle">
                          {order.tiene_observaciones ? "Si" : "No"}
                        </td>
                        {/*}
                        <td className="text-center align-middle">
                          {order.id_solicitud_cotizacion.estado === "JefeSeleccionaCotizacion" ? (
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                              className="m-1"
                              style={{ textTransform: "none" }}
                            >
                              Detalle
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                openModalDeleteRecord(id, order.id, true);
                              }}
                              className="m-1"
                              style={{ textTransform: "none" }}
                            >
                              Detalle
                            </Button>
                          )}
                        </td>
                            */}
                      </tr>
                    ))}
                    {/*}
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-right">
                    <Button
                      disabled={
                        infoRequest?.estado === "JefeSeleccionaCotizacion"
                          ? true
                          : false
                      }
                      variant="contained"
                      color="primary"
                      onClick={() => setModalAddRecord(true)}
                      className="m-1"
                    >
                      +
                    </Button>
                  </td>
                    */}
                </tbody>
              </table>
              <GridContainer justify="center">
                <Grid item container xs={10} justify="center">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="center"
                    style={{ textTransform: "none" }}
                  >
                    Proveedores
                  </Typography>
                  {infoRequest?.estado === "RevisarOC" ? (
                    <PurchaseOrderTable
                      suppliers={vendors}
                      setAddSupplier={setAddSupplier}
                      setAuxAddSupplier={setAuxAddSupplier}
                      setOpenModalQuotes={setOpenModalQuotes}
                      setTempRow={setTempRow}
                      infoRequest={infoRequest}
                      rowSelected={rowSelected}
                      setRowSelected={setRowSelected}
                    />
                  ) : (
                    <SuppliersTable
                      suppliers={vendors}
                      setAddSupplier={setAddSupplier}
                      setAuxAddSupplier={setAuxAddSupplier}
                      setOpenModalQuotes={setOpenModalQuotes}
                      setTempRow={setTempRow}
                      infoRequest={infoRequest}
                      rowSelected={rowSelected}
                      setRowSelected={setRowSelected}
                    />
                  )}
                </Grid>
              </GridContainer>
              <GridContainer justify="center" className="mt-3 mb-6">
                {infoRequest?.estado === "RevisarOC" ? (
                  <Button
                    disabled={rowSelected != null ? false : true}
                    variant="contained"
                    color="primary"
                    onClick={aceptRequest}
                    className="m-1"
                    style={{ textTransform: "none" }}
                  >
                    Aceptar
                  </Button>
                ) : (
                  <div>
                    <Button
                      disabled={rowSelected != null ? false : true}
                      variant="contained"
                      color="primary"
                      onClick={aceptRequest}
                      className="m-1"
                      style={{ textTransform: "none" }}
                    >
                      Enviar
                    </Button>
                    <Button
                      disabled
                      variant="contained"
                      color="secondary"
                      className="m-1"
                      style={{ textTransform: "none" }}
                    >
                      Rechazar cotizaciones
                    </Button>
                  </div>
                )}
              </GridContainer>
              <div className="mt-3 mb-5"></div>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
