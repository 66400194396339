import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import TableComponent from "views/Widgets/Common/TableComponent";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import apiform from "utils/APIForm";
import api from "utils/API";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import {toMoneyFormat} from "utils/formatFunctions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ModalAddAuthorizationPersonal = props => {
  const dispatch = useDispatch();
  const {
    openModal,
    closeModal,
    tempRow,
    setauxData,
    dataArray,
    finishArray,
    setFinishArray
  } = props;

  const [approvalFile, setApprovalFile] = useState(null);
  const [listProffesional, setlistProffesional] = useState([])
  const getIndex = dataArray.findIndex(row => row.id === tempRow.id);

  const dictCharges = useSelector(state => state.currentList.dictCharges);

  useEffect(() => {
    initialData()
  }, []);

  const initialData = () => {
    api.get(`api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/profesionaleextras/${tempRow.id_solicitud_paciente.id}/`).then((response) => {
      setlistProffesional(response.data["agrupados"])
    })
  }

  const uploadAuthorization = (check=true) => {
    const formData = new FormData();
    if (check){
      formData.append("archivo", approvalFile[0]);
      formData.append("tipo_documento", "AutorizacionPersonalExtra");
      formData.append("nombre_archivo", approvalFile[0].name);
      formData.append("id_paciente", parseInt(tempRow.id_ficha.id_paciente.id));
    }
    
    const tempListProffesional = listProffesional.filter((row) => row.id_solicitud_paciente_id)

    api.patch(`api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/profesionaleextras/${tempRow.id_solicitud_paciente.id}/`, {actualizar_tareas: tempListProffesional, autorizacion:check}).then(() => {
      if (check){
        apiform.post(
          `/api/paciente/${tempRow.id_ficha.id_paciente.id}/solicitudes/profesionaleextras/${tempRow.id_solicitud_paciente.id}/documentos/AutorizacionPersonalExtra/subir/`,formData
        ).then(() => {
          let tempArray = dataArray;
          let tempFinish = finishArray;
          tempArray[getIndex].estado = "Finalizado";
          tempFinish.push(tempArray[getIndex]);
          setFinishArray(tempFinish);
          tempArray.splice(getIndex, 1);
          setauxData(tempArray);
          closeModal();
        })
      } else {
        let tempArray = dataArray;
        let tempFinish = finishArray;
        tempArray[getIndex].estado = "Finalizado";
        tempFinish.push(tempArray[getIndex]);
        setFinishArray(tempFinish);
        tempArray.splice(getIndex, 1);
        setauxData(tempArray);
        closeModal();
      }
     
      })

  };

  const headers = [
    {label: "Cargo"},
    {label: "Cantidad"},
    {label: "Horario"},
    {label: "Valor atención"},
    {label: "Total"},
  ];

  const handleUpdateValue = (value, index) => {
    const tempList = [...listProffesional];
    tempList[index].valor_atencion = value;
    tempList[index].id_solicitud_paciente_id = tempRow.id_solicitud_paciente.id;
    setlistProffesional(tempList);
  }

  const handleUpdateAmount = (value, index) => {
    if (value >= 0){
    const tempList = [...listProffesional];
    tempList[index].cantidad = value;
    tempList[index].id_solicitud_paciente_id = tempRow.id_solicitud_paciente.id;
    setlistProffesional(tempList);
    }
  }

  const data = listProffesional.map((row, index) => {
    const selectCharge =  dictCharges[row.id_cargo_asignado_id]
    const _value_ =  <TextField
      label=""
      value={row.valor_atencion || 0}

      onChange={e => handleUpdateValue(parseInt(e.target.value), index)}
      name="numberformat"
      id="formatted-numberformat-input"
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
    />
    const _amount_ =  <TextField
      label=""
      value={row.cantidad || 0}
      onChange={e => handleUpdateAmount(parseInt(e.target.value), index)}
      name="numberformat"
      id="formatted-numberformat-input"
    />

    return [
      {value: selectCharge["nombre_corto"] },
      {value: row.cantidad, _value_: _amount_},
      {value: row.horario_habil ? "Habil": "Inhabil"},
      {value: row.valor_atencion, _value_: _value_ },
      {value: toMoneyFormat(row.valor_atencion*row.cantidad) },
    ]
});

  const _content_ =  <div>
    <Grid
      item
      container
      xs={12}
      justify="center"
      style={{ marginTop: "1em" }}
    >
      <Grid item container xs={12} justify="center">
        {approvalFile != null ? (
          approvalFile[0].name
        ) : (
          <span></span>
        )}
      </Grid>
      <input
        id="contained-button-file-isapre"
        type="file"
        onChange={e => setApprovalFile(e.target.files)}
        hidden
      />
      <label htmlFor="contained-button-file-isapre">
        <Button
          variant="contained"
          color="primary"
          component="span"
          style={{ textTransform: "none" }}
        >
          Adjuntar autorización de isapre
        </Button>
      </label>
    </Grid>

    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      item
      xs={12}
      style={{margin: "0 0 32px 0"}}
    >
      <TableComponent
          headers={headers}
          data={data}
      />
    </Grid>

  </div>

  const _actions_ = <>
    <Button
      disabled={(approvalFile === null ||  listProffesional.every((row) => parseInt(row.valor_atencion) <= 0))? true : false}
      variant="contained"
      color="primary"
      style={{ textTransform: "none" }}
      onClick={uploadAuthorization}
    >
      Aceptar con autorización
    </Button>
    <Button
      variant="contained"
      color="primary"
      style={{ textTransform: "none" }}
      onClick={() => uploadAuthorization(false)}
    >
      Aceptar sin autorización
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={closeModal}
      style={{ textTransform: "none" }}
    >
      Cerrar
    </Button>
  </>

  return (
    <ModalDialog
        open={openModal}
        onClose={closeModal}
        title={`Adjuntar autorización de personal extra`}
        _content_={_content_}
        _actions_ = {_actions_}
        maxWidth="xl"
    />

  );
};

export default ModalAddAuthorizationPersonal;
