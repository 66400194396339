import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import ModalCreateDocument from "./ModalCreateDocument";
import TableDocuments from "./TableDocuments";
import SearchDocuments from "./SearchDocuments";

export default function Documents() {
  const { id } = useParams();
  const id_paquetizacion = parseInt(id, 10);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal_create_document, setModal_create_document] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    api.get(`api/clinica/paquetizaciones/${id}/documentos/`).then(result => {
      setLoading(true);
      setDocuments(result.data);
    });
    setLoading(false);
  }, []);

  const deleteFile = (id, paquetizacion, nombre, tipo) => {
    api
      .put(`api/documentospaquetizacion/${id}`, {
        estado: false,
        id_paquetizacion: paquetizacion,
        nombre_archivo: nombre,
        tipo_archivo: tipo
      })
      .then(() => {
        window.location.href = `/admin/documents/${id_paquetizacion}`;
      })

  };

  const searchDocuments = documents.filter(request => {
    return (
      request.nombre_archivo
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.tipo_archivo
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    );
  });

  return (
    <GridContainer>
      <ViewTitle
        title="DOCUMENTOS"
        message="En esta sección podrás revisar los documentos de la paquetización seleccionada"
      />
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center">
              <Button
                style={{
                  backgroundColor: "#1F8AFF",
                  marginTop: "5em",
                  marginBottom: "2em",
                  textTransform: "none"
                }}
                onClick={() => setModal_create_document(true)}
              >
                Agregar archivo
              </Button>
            </GridContainer>
            <GridContainer justify="center">
              <SearchDocuments search={search} setSearch={setSearch} />
            </GridContainer>
            <CardBody>
              <TableDocuments
                documents={searchDocuments}
                deleteFile={deleteFile}
              />
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modal_create_document && (
        <ModalCreateDocument
          open={modal_create_document}
          closeModal={() => setModal_create_document(false)}
          id_paquetizacion={id_paquetizacion}
        />
      )}
    </GridContainer>
  );
}
