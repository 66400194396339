import React, { useState, useEffect } from 'react'

import MemorandumActions from './components/MemorandumActions'
import TablePagination from "@material-ui/core/TablePagination";
import MemorandumList from './components/MemorandumList';
import MemorandumForm from './components/MemorandumForm';
import Spiner from '../../../views/Widgets/Spiner'

import api from 'utils/API';
import useToogle from 'utils/CustomHooks/useToogle';
import usePagination from 'utils/CustomHooks/usePagination';
import AppDialog from 'views/Widgets/AppDialog';

const memoInitialState = {
  data: {
    memoId: undefined,
    memoMembers: [],
    memoDescription: ''
  },
  isEdit: false,
  showFormFields: true,
}

const Memorandum = ({ memorandumsData }) => {
  const [memorandumsList, setMemorandumsList] = useState({ list: [], isFiltering: false })
  const [pagination, handleChangePage, handleChangeRowsPerPage] = usePagination(5)
  const [openDialog, setOpenDialog] = useState(false)
  const [memoData, setMemoData] = useState(memoInitialState)
  const [membersSelected, setmembersSelected] = useState([])
  const [possibleMember, setPossibleMember] = useState([])
  const [errorAlert, setNoResultAlert] = useState(false)
  const [spiner, setSpiner] = useToogle()

  const toogleDialog = () => {
    setMemoData(memoInitialState)
    if (openDialog) {
      setOpenDialog(false);
    } else {
      setSpiner()
      api.get(`api/minutas/obtener/participantes/`).then(response => {
        setPossibleMember(response.data)
        setOpenDialog(true);
        setSpiner()
      })
    }
  }

  const setMemoToEditData = (memo) => {
    const requestPossibleMembers = api.get(`api/minutas/obtener/participantes/`)
    const requestMemoData = api.get(`api/minutas/${memo.id_minuta}`)
    const requestMemoMembers = api.get(`api/minutas/participantes/${memo.id_minuta}`)
    setSpiner()
    Promise.all([requestPossibleMembers, requestMemoData, requestMemoMembers]).then(response => {
      setPossibleMember(response[0].data)
      setMemoData({
        data: {
          memoId: response[1].data?.id,
          memoMembers: response[2].data,
          memoDescription: response[1].data?.minuta,
          createdAt: response[1].data?.fecha_creacion
        },
        isEdit: true,
        showFormFlieds: false
      })
      setmembersSelected(response[2].data)
      setOpenDialog(true)
      setSpiner()
    }, error => {
      setSpiner()
    })
  }

  const resetMemoData = () => {
    setMemoData(memoInitialState)
  }

  const getFilteredMemorandums = (word) => {
    setSpiner()
    api.get(`api/minutas/paciente/${memorandumsData.file.id_paciente}?palabra=${word}`).then(response => {
      setMemorandumsList({ list: response.data, isFiltering: true })
      if (response.data.length) {
        setNoResultAlert(false)
      } else {
        setNoResultAlert(true)
      }
      setSpiner()
    }, error => {
      setNoResultAlert(true)
      setSpiner()
    })
  }

  const updateMemorandums = (memo, isEditing) => {
    if (isEditing) {
      const list = memorandumsList.list.map(item => item.id_minuta === memo.id ? memo : item)
      setMemorandumsList({ list, isFiltering: false })
    } else {
      setMemorandumsList({ list: [memo, ...memorandumsList.list], isFiltering: false })
    }
  }

  const resetMemorandumsList = () => {
    setNoResultAlert(false)
    setMemorandumsList({ list: memorandumsData.memorandumsList, isFiltering: false })
  }

  useEffect(() => {
    resetMemorandumsList()
  }, [memorandumsData.memorandumsList])

  return (
    <>
      <Spiner open={spiner} />

      <MemorandumActions memorandumsList={memorandumsList} getFilteredMemorandums={getFilteredMemorandums} toogleDialog={toogleDialog} resetMemorandumsList={resetMemorandumsList} />

      <MemorandumList memorandumsList={memorandumsList} errorAlert={errorAlert} pagination={pagination} setMemoToEditData={setMemoToEditData} />

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={memorandumsList.list.length}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        SelectProps={{ inputProps: { "aria-label": "Filas por página" }, native: false }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `más que ${to}`}`}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {openDialog &&
        <AppDialog fullScreen title='CREA UNA MINUTA' open={true} onClose={toogleDialog}>
          <MemorandumForm
            memoData={memoData}
            memorandumsData={memorandumsData}
            possibleMember={possibleMember}
            membersSelected={membersSelected}
            setmembersSelected={setmembersSelected}
            setMemoToEditData={setMemoToEditData}
            setMemoData={setMemoData}
            resetMemoData={resetMemoData}
            toogleDialog={toogleDialog}
            updateMemorandums={updateMemorandums}
            setSpiner={setSpiner}
          />
        </AppDialog>
      }
    </>
  )
}

export default Memorandum
