import React from "react";
import Datetime from "react-datetime";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    border: 0,
  },
  inputHeaderTable: {
    border: 0,
    width: "90px"
  },
  
}));


const DetailDate = props => {
  const classes = useStyles();

  const handleValidDate = () => {
    console.log(props.isValidDate === undefined ? true : props.isValidDate)
    return props.isValidDate === undefined ? true : props.isValidDate
  };

  const styleValidation = (props?.value === "" && props.validation !== false) ? 
    { color: "#FFFFFF" } :
    { color: "#000000" };
  const inputStyleTimeFormat =  props.timeFormat !== false ? {} : {width: "90px"};

  return(
    <div className="col-md-12 mb-3" style={{...props.style ? props.style : {}}}>
      <label className="text-dark">
        {props?.label}
        {(props.value === "" &&  props.validation !== false) ? (
          <span className="text-danger"> no puede ser vacío</span>
        ) : (props?.error && props.validation !== false) ? 
          <span className="text-danger"> {props?.error} </span>
          : (props.isValidDate === false ) ?
          <span className="text-danger"> no es válida </span> : null }
      </label>
      <Datetime
          value={props.value}
          inputProps={{ 
            placeholder: props.placeholder, 
            className: props.header ? classes.inputHeaderTable : classes.input 
          }}
          onChange={(e) => props.handleOnChange(e)}
          className={
            (props?.value === "" && props.validation !== false)
              ? "form-control bg-danger font-weight-lighter text-white"
              : "form-control bg-light font-weight-lighter text-dark"
          }
          style={{
            ...styleValidation,
            ...inputStyleTimeFormat
          }}
          timeFormat={props.timeFormat !== false ? true : false}
      />
    </div>
  );
}

export default DetailDate;