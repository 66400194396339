import { makeStyles } from '@material-ui/core/styles';
import {
    indigo
  } from "@material-ui/core/colors";

export const classStyle = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
      fontWeight: "bold",
    },
    title: {
      fontWeight: "bold",
      '&:hover': {
          color: indigo[400],
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff"
    },
    paper: {
        transition: "all 500ms linear",
        padding: "10px 45px",
        width: "500px",
    },
    paperTable: {
        padding: "10px 5px",
        width: "100%",
    },
    labelBox: {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "0.75rem",
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: "400",
      lineHeight: "1",
      margin: "0px 0px -9px 11px",
      zIndex: "1",
      background: "white",
      padding: "0 4px",
    },
    cardBox: {
      height: "150px", 
      overflow: "auto",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "inherit",
      borderColor: "#0000003b",
    },
    dialogHeader: {
      backgroundColor: '#303F9F',
      padding: '0px 24px'
    },
    dialogContent: {
      padding: 24
    },
    colorWhite: {
      color: 'white'
    },
    rootRadio: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
      },
      'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
}));
