import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tab from "../Components/Tab";
import Motivo from "../Components/Motivo";
import Tipo from "../Components/Tipo";
import Origen from "../Components/Origen";
import Profesionales from "../Components/Profesionales";
import {
  getListRequestPatient,
} from 'actions/getListAction';
import Adjuntar from "../Components/Adjuntar";
import api from "utils/API";

import DialogCancelRequest from "./DialogCancelRequest";
import DialogDeleteRequest from "./DialogDeleteRequest";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "80%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalTransferClinic = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    dataPatient,
    tempRow,
    dataArray,
    viewOnly,
    edit
  } = props;
  const dispatch = useDispatch()
  const [firstCharge, setFirstCharge] = useState(true);
  const [tabSection, setTabSection] = useState("motivo");
  const sendApproval = false;
  const [reason, setReason] = useState("");
  const [reasons, setReasons] = useState("");
  const [indications, setIndications] = useState("");
  const [transferType, setTransferType] = useState("Auto");
  const [regiones, setRegiones] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [origin, setOrigin] = useState("");
  const [originDate, setOriginDate] = useState(moment());
  const [originStreet, setOriginStreet] = useState("");
  const [originNumber, setOriginNumber] = useState("");
  const [originDepto, setOriginDepto] = useState("");
  const [originBlock, setOriginBlock] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [originComuna, setOriginComuna] = useState("");
  const [originRegion, setOriginRegion] = useState("");
  const [originDescription, setOriginDescription] = useState("");
  const [originComments, setOriginComments] = useState("");
  const [destination, setDestination] = useState("");
  const [destinationDate, setDestinationDate] = useState(moment());
  const [destinationStreet, setDestinationStreet] = useState("");
  const [destinationNumber, setDestinationNumber] = useState("");
  const [destinationDepto, setDestinationDepto] = useState("");
  const [destinationBlock, setDestinationBlock] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [destinationComuna, setDestinationComuna] = useState("");
  const [destinationRegion, setDestinationRegion] = useState("");
  const [destinationDescription, setDestinationDescription] = useState("");
  const [destinationComments, setDestinationComments] = useState("");
  const [professionals, setProfessionals] = useState([]);
  const [professionalSelected, setProfessionalSelected] = useState(false);
  const [transferPrice, setTransferPrice] = useState(0);
  const [approvalFile, setApprovalFile] = useState(null);

  const [openModalDeleteRequest, setOpenModalDeleteRequest] = useState(false);
  const [openModalCancelRequest, setOpenModalCancelRequest] = useState(false);

  const [compareProfessionals, setCompareProfessionals] = useState([]);
  const [checkProfessionals, setCheckProfessionals] = useState([]);
  const [deleteProfessionals, setDeleteProfessionals] = useState([]);
  const [addProfessionals, setAddProfessionals] = useState([]);
  const [editProfessionals, setEditProfessionals] = useState([]);

  const transferTypes = useSelector(state => state.currentList.LISTA_TIPO_TRASLADOS);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);
    const getRegiones = api.get("api/locaciones/regiones/");
    const getProfessionals = api.get(
      `api/paciente/${dataPatient.id}/solicitudes/traslados/datosbase/`
    );
    const getRequest = api.get(
      `/api/paciente/${tempRow.id_paciente}/solicitudes/traslados/${tempRow.id}/`
    );

    Promise.all([getRegiones, getProfessionals, getRequest])
      .then(response => {
        let tempRegiones = [];
        response[0].data.map(row => {
          tempRegiones.push({
            value: row.id,
            label: row.nombre
          });
        });
        const tempProfessionals = [];
        response[1].data.cargos.map(row => {
          tempProfessionals.push({
            id: row.id,
            id_traslado: 0,
            name: row.nombre_corto,
            selected: false,
            cantidad: 1,
            cantidadAux: 1
          });
        });
        if (response[2].data.profesionales.length > 0) {
          setProfessionalSelected(true);
        }
        response[2].data.profesionales.map(row => {
          tempProfessionals
            .filter(temp => temp.id === row.id_cargo_id)
            .map(pro => {
              pro.selected = true;
              pro.cantidad = row.cantidad;
              pro.cantidadAux = row.cantidad;
              pro.id_traslado = row.id;
            });
        });
        setCheckProfessionals([
          ...tempProfessionals.filter(pro => pro.selected)
        ]);
        setCompareProfessionals([
          ...tempProfessionals.filter(pro => pro.selected)
        ]);
        setProfessionals(tempProfessionals);
        setRegiones(tempRegiones);

        setReason(response[2].data.traslado.tipo_motivo_traslado);
        setReasons(response[2].data.traslado.texto_motivo_traslado);
        setIndications(response[2].data.traslado.indicaciones_generales);
        setTransferType(response[2].data.traslado.tipo_traslado);
        setOriginDate(moment(response[2].data.traslado.paradas[0].fecha_hora));
        setOriginStreet(response[2].data.traslado.paradas[0].direccion_calle);
        setOriginNumber(response[2].data.traslado.paradas[0].direccion_numero);
        setOriginDepto(
          response[2].data.traslado.paradas[0].direccion_depto_casa
        );
        let tempComuna = response[2].data.traslado.paradas[0].direccion_comuna;

        response[0].data
          .filter(
            row =>
              row.nombre ===
              response[2].data.traslado.paradas[0].direccion_region
          )
          .map(region => {
            setOriginRegion({
              value: region.id,
              label: region.nombre
            });

            const getComunas = api.get(
              `/api/locaciones/regiones/${region.id}/comunas/`
            );
            Promise.all([getComunas])
              .then(response => {
                setComunas(response[0].data);
                response[0].data
                  .filter(row => row.nombre === tempComuna)
                  .map(comuna => {
                    setOriginComuna({
                      value: comuna.id,
                      label: comuna.nombre
                    });
                  });
              })

          });
        setOriginBlock(response[2].data.traslado.paradas[0].direccion_block);
        setOriginCity(response[2].data.traslado.paradas[0].direccion_ciudad);
        setOriginComments(response[2].data.traslado.paradas[0].comentarios);

        setDestinationDate(
          moment(response[2].data.traslado.paradas[1].fecha_hora)
        );
        setDestinationStreet(
          response[2].data.traslado.paradas[1].direccion_calle
        );
        setDestinationNumber(
          response[2].data.traslado.paradas[1].direccion_numero
        );
        setDestinationDepto(
          response[2].data.traslado.paradas[1].direccion_depto_casa
        );

        let destinationTempComuna =
          response[2].data.traslado.paradas[1].direccion_comuna;

        response[0].data
          .filter(
            row =>
              row.nombre ===
              response[2].data.traslado.paradas[1].direccion_region
          )
          .map(region => {
            setDestinationRegion({
              value: region.id,
              label: region.nombre
            });

            const getComunas = api.get(
              `/api/locaciones/regiones/${region.id}/comunas/`
            );
            Promise.all([getComunas])
              .then(response => {
                response[0].data
                  .filter(row => row.nombre === destinationTempComuna)
                  .map(comuna => {
                    setDestinationComuna({
                      value: comuna.id,
                      label: comuna.nombre
                    });
                  });
              })

          });
        setDestinationBlock(
          response[2].data.traslado.paradas[1].direccion_block
        );
        setDestinationCity(
          response[2].data.traslado.paradas[1].direccion_ciudad
        );
        setDestinationComments(
          response[2].data.traslado.paradas[1].comentarios
        );

      })

  };

  const sendRequest = save => {


    let tempProfessionals = [];
    professionals
      .filter(professional => professional.selected)
      .map(row => {
        tempProfessionals.push({
          cantidad: row.cantidad,
          id_cargo: row.id
        });
      });

    let send = {
      tipo_motivo_traslado: reason,
      indicaciones_generales: indications,
      tipo_traslado: transferType,
      fecha_hora_inicio: moment(originDate).format("YYYY-MM-DDTHH:mm:ss"),
      calle_inicio: originStreet,
      numero_inicio: originNumber,
      ciudad_inicio: originCity,
      comuna_inicio: originComuna.label,
      region_inicio: originRegion.label,
      fecha_hora_fin: moment(destinationDate).format("YYYY-MM-DDTHH:mm:ss"),
      calle_fin: destinationStreet,
      numero_fin: destinationNumber,
      ciudad_fin: destinationCity,
      comuna_fin: destinationComuna.label,
      region_fin: destinationRegion.label,
      //profesionales_solicitados: tempProfessionals,
      texto_motivo_traslado: reasons,
      //valor_traslado: transferPrice,
      depto_casa_inicio: originDepto,
      block_inicio: originBlock,
      comentarios_inicio: originComments,
      depto_casa_fin: destinationDepto,
      block_fin: destinationBlock,
      comentarios_fin: destinationComments,
      comentarios: "david",
      fecha_hora: "2020-10-11T10:11:00",
      direccion_calle: "david"
    };

    if (editProfessionals.length > 0) {
      send["profesionales_modificar"] = editProfessionals;
    }

    if (addProfessionals.length > 0) {
      send["profesionales_agregar"] = addProfessionals;
    }

    if (deleteProfessionals.length > 0) {
      send["profesionales_eliminar"] = deleteProfessionals;
    }

    if (save) {
      api
        .patch(
          `/api/paciente/${dataPatient.id}/solicitudes/traslados/${tempRow.id}/`,
          send
        )
        .then(response => {
          console.log("response", response);
          api
            .put(
              `/api/paciente/${dataPatient.id}/solicitudes/traslados/${tempRow.id}/enviar/`,
              send
            )
            .then(responsePut => {
              getListRequestPatient(dataPatient, dispatch);
              closeModal();

            })

        })

    } else {
      api
        .patch(
          `/api/paciente/${dataPatient.id}/solicitudes/traslados/${tempRow.id}/`,
          send
        )
        .then(response => {
          console.log("response", response);
          closeModal();

        })

    }

    console.log("send", send);
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        <ModalTitle id="customized-dialog-title" onClose={closeModal}>
          Datos del traslado
        </ModalTitle>
        <DialogContent>
          <Grid container justify="center">
            <Tab sendApproval={sendApproval} setTabSection={setTabSection} />
            {tabSection === "motivo" ? (
              <Motivo
                reason={reason}
                setReason={setReason}
                reasons={reasons}
                setReasons={setReasons}
                indications={indications}
                setIndications={setIndications}
                viewOnly={viewOnly}
              />
            ) : tabSection === "tipo" ? (
              <Tipo
                transferType={transferType}
                setTransferType={setTransferType}
                viewOnly={viewOnly}
              />
            ) : tabSection === "origen" ? (
              <Origen
                dataPatient={dataPatient}
                regiones={regiones}
                comunas={comunas}
                setComunas={setComunas}
                origin={origin}
                setOrigin={setOrigin}
                originDate={originDate}
                setOriginDate={setOriginDate}
                originStreet={originStreet}
                setOriginStreet={setOriginStreet}
                originNumber={originNumber}
                setOriginNumber={setOriginNumber}
                originDepto={originDepto}
                setOriginDepto={setOriginDepto}
                originBlock={originBlock}
                setOriginBlock={setOriginBlock}
                originCity={originCity}
                setOriginCity={setOriginCity}
                originComuna={originComuna}
                setOriginComuna={setOriginComuna}
                originRegion={originRegion}
                setOriginRegion={setOriginRegion}
                originDescription={originDescription}
                setOriginDescription={setOriginDescription}
                originComments={originComments}
                setOriginComments={setOriginComments}
                destination={destination}
                setDestination={setDestination}
                destinationDate={destinationDate}
                setDestinationDate={setDestinationDate}
                destinationStreet={destinationStreet}
                setDestinationStreet={setDestinationStreet}
                destinationNumber={destinationNumber}
                setDestinationNumber={setDestinationNumber}
                destinationDepto={destinationDepto}
                setDestinationDepto={setDestinationDepto}
                destinationBlock={destinationBlock}
                setDestinationBlock={setDestinationBlock}
                destinationCity={destinationCity}
                setDestinationCity={setDestinationCity}
                destinationComuna={destinationComuna}
                setDestinationComuna={setDestinationComuna}
                destinationRegion={destinationRegion}
                setDestinationRegion={setDestinationRegion}
                destinationDescription={destinationDescription}
                setDestinationDescription={setDestinationDescription}
                destinationComments={destinationComments}
                setDestinationComments={setDestinationComments}
                viewOnly={viewOnly}
              />
            ) : tabSection === "profesionales" ? (
              <Profesionales
                professionals={professionals}
                setProfessionals={setProfessionals}
                professionalSelected={professionalSelected}
                setProfessionalSelected={setProfessionalSelected}
                viewOnly={viewOnly}
                compareProfessionals={[...compareProfessionals]}
                checkProfessionals={checkProfessionals}
                addProfessionals={addProfessionals}
                setAddProfessionals={setAddProfessionals}
                deleteProfessionals={deleteProfessionals}
                setDeleteProfessionals={setDeleteProfessionals}
                editProfessionals={editProfessionals}
                setEditProfessionals={setEditProfessionals}
              />
            ) : tabSection === "adjuntar" ? (
              <Adjuntar
                transferPrice={transferPrice}
                setTransferPrice={setTransferPrice}
                approvalFile={approvalFile}
                setApprovalFile={setApprovalFile}
                viewOnly={viewOnly}
              />
            ) : null}
          </Grid>
        </DialogContent>

        {edit ? (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={closeModal}
              style={{ textTransform: "none" }}
            >
              Atrás
            </Button>
            {reason !== "" &&
            indications !== "" &&
            transferType !== "" &&
            originStreet !== "" &&
            originNumber !== "" &&
            originRegion !== "" &&
            originComuna !== "" &&
            originCity !== "" &&
            destinationStreet !== "" &&
            destinationNumber !== "" &&
            destinationRegion !== "" &&
            destinationComuna !== "" &&
            professionalSelected &&
            moment(originDate).format("YYYY-MM-DD") >=
              moment().format("YYYY-MM-DD") &&
            moment(destinationDate).format("YYYY-MM-DD") >=
              moment().format("YYYY-MM-DD") ? (
              <Grid>
                {}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => sendRequest(false)}
                  style={{ marginRight: "5px", textTransform: "none" }}
                >
                  Guardar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => sendRequest(true)}
                  style={{ marginLeft: "5px", textTransform: "none" }}
                >
                  Enviar
                </Button>
              </Grid>
            ) : (
              <Grid>
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  onClick={closeModal}
                  style={{ marginRight: "5px", textTransform: "none" }}
                >
                  Guardar
                </Button>
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  onClick={closeModal}
                  style={{ marginLeft: "5px", textTransform: "none" }}
                >
                  Enviar
                </Button>
              </Grid>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenModalDeleteRequest(true)}
              style={{ textTransform: "none" }}
            >
              Eliminar solicitud
            </Button>
          </DialogActions>
        ) : (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={closeModal}
              style={{ textTransform: "none" }}
            >
              Atrás
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenModalCancelRequest(true)}
              style={{ textTransform: "none" }}
            >
              Cancelar solicitud
            </Button>
          </DialogActions>
        )}

        {openModalDeleteRequest && (
          <DialogDeleteRequest
            openDialog={openModalDeleteRequest}
            closeDialog={() => setOpenModalDeleteRequest(false)}
            tempRow={tempRow}
            closeMainModal={closeModal}
            dataPatient={dataPatient}
          />
        )}
        {openModalCancelRequest && (
          <DialogCancelRequest
            openDialog={openModalCancelRequest}
            closeDialog={() => setOpenModalCancelRequest(false)}
            tempRow={tempRow}
            closeMainModal={closeModal}
            dataPatient={dataPatient}
          />
        )}
      </Paper>
    </Modal>
  );
};

export default ModalTransferClinic;

ModalTransferClinic.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  dataPatient: PropTypes.object,
  tempRow: PropTypes.object,
  dataArray: PropTypes.array,
  setauxData: PropTypes.func,
  viewOnly: PropTypes.bool,
  edit: PropTypes.bool
};
