import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import api from "utils/API";
import SearchRequest from "./SearchRequest";
import TabRequest from "./TabRequests";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
}));

const Requests = () => {
  const classes = useStyles();
  const [changes, setChanges] = useState(true);
  const pageRefresh = false;
  const [search, setSearch] = useState("");
  const [searchFinalized, setSearchFinalized] = useState("");
  const [requests, setRequests] = useState([]);
  const [requestsFinalized, setRequestFinalized] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);
    const getRequest = api.get("api/plataforma/solicitudes/clinica/");
    Promise.all([getRequest])
      .then(response => {
        setRequests(
          response[0].data.filter(
            request =>
              request.estado != "Finalizado" && request.estado != "Rechazado"
          )
        );
        setRequestFinalized(
          response[0].data.filter(
            request =>
              request.estado === "Finalizado" || request.estado === "Rechazado"
          )
        );
      })
  };

  let searchRequestsInProcess = requests.filter(request => {
    return (
      request.id
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.id_tipo_solicitud.nombre_solicitud
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      `${request.id_cargo_solicitante?.nombre_corto} - ${request.id_cuenta_solicitante?.first_name} ${request.id_cuenta_solicitante?.last_name}`
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.etapa
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      (request.id_cargo_receptor
        ? request.id_cargo_receptor
            .toString()
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        : null) ||
      request.fecha_solicitud
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    );
  });

  let searchRequestFinalized = requestsFinalized.filter(request => {
    return (
      request.id
        .toString()
        .toLowerCase()
        .indexOf(searchFinalized.toLowerCase()) !== -1 ||
      request.id_tipo_solicitud.nombre_solicitud
        .toString()
        .toLowerCase()
        .indexOf(searchFinalized.toLowerCase()) !== -1 ||
      `${request.id_cargo_solicitante?.nombre_corto} - ${request.id_cuenta_solicitante?.first_name} ${request.id_cuenta_solicitante?.last_name}`
        .toString()
        .toLowerCase()
        .indexOf(searchFinalized.toLowerCase()) !== -1 ||
      request.etapa
        .toString()
        .toLowerCase()
        .indexOf(searchFinalized.toLowerCase()) !== -1 ||
      (request.id_cargo_receptor
        ? request.id_cargo_receptor
            .toString()
            .toLowerCase()
            .indexOf(searchFinalized.toLowerCase()) !== -1
        : null) ||
      request.fecha_solicitud
        .toString()
        .toLowerCase()
        .indexOf(searchFinalized.toLowerCase()) !== -1
    );
  });

  return (
    <Grid container className={classes.root}>
      <Card style={{ backgroundColor: "#1B9CFC", width: "100%" }}>
        <CardContent>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{
              color: "#FFFFFF",
              textTransform: "none",
              fontWeight: "bold"
            }}
          >
            Solicitudes dotación
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{
              color: "#FFFFFF",
              textTransform: "none"
            }}
          >
            En esta sección podrás revisar las solicitudes de dotación
          </Typography>
        </CardContent>
      </Card>
      <Grid item xs={12} sm={6} md={12} style={{ marginTop: "2em" }}>
        <CardContent
          className="rounded shadow border border-primary"
          style={{ justifyContent: "center" }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Solicitudes</Typography>
            <Typography color="textPrimary">Información dotación</Typography>
          </Breadcrumbs>
        </CardContent>
      </Grid>
      <Grid item xs={12} sm={6} md={12} style={{ marginTop: "2em" }}>
        <Card>
          <Grid container justify="center" className="m-3 mt-5 mb-5">
            <SearchRequest
              search={search}
              setSearch={setSearch}
              searchFinalized={searchFinalized}
              setSearchFinalized={setSearchFinalized}
              tabValue={tabValue}
            />
            <TabRequest
              requests={searchRequestsInProcess}
              requestsFinalized={searchRequestFinalized}
              setTabValue={setTabValue}
            />
          </Grid>
        </Card>
      </Grid>
      {pageRefresh && <div></div>}
    </Grid>
  );
};

export default Requests;
