import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Box, TextField, Fab } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  alignSelfCenter: {
    alignSelf: 'center'
  },

  '@media (max-width: 800px)': {},
});

const MemorandumActions = ({ memorandumsList, getFilteredMemorandums, toogleDialog, resetMemorandumsList }) => {
  const classes = useStyles()
  const [filterWord, setFilterWord] = useState('')

  const handleChange = (e) => {
    setFilterWord(e.target.value)
    resetMemorandumsList()
  }

  const setFilter = () => {
    if (filterWord && !memorandumsList.isFiltering) {
      getFilteredMemorandums(filterWord)
    } else {
      setFilterWord('')
      resetMemorandumsList()
    }
  }

  return (
    <Box display='grid' gridTemplateColumns='30% 25% 1fr'>
      <Box alignSelf='center'>
        <Button variant='outlined' color='primary' onClick={toogleDialog} style={{ height: 36, backgroundColor: 'rgba(63, 81, 181, 0.08)' }}>
          <AddToPhotosIcon color='primary' className='mr-2' />
          Crear Minuta
        </Button>
      </Box>
      <Box />
      <Box display='grid' gridTemplateColumns='1fr 50px'>
        <TextField
          className={classes.alignSelfCenter}
          onChange={handleChange}
          label='Busca por asistente o palabra'
          value={filterWord}
        />
        <Fab size="small" color="primary" onClick={setFilter}>
          {memorandumsList.isFiltering ? <CloseIcon /> : <SearchIcon />}
        </Fab>
      </Box>
    </Box>
  )
}

export default MemorandumActions
