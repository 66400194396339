import axios from "axios";
import apilink from "./APIlink";
import {getUrl} from "./formatFunctions"
import store from "reducers/store"
import {dictError} from "utils/validationFunction"

const updateListCharge = (item) => {
  store.dispatch({ type: 'LISTA_API_CARGA', payload: item });
}

const updateOpenBackDrop = (item) => {
  store.dispatch({ type: 'BLOQUEAR_PANTALLA', payload: item });
}

const setmessageGeneral = (listObj, type, title, action=null, nameAction="") => {
  store.dispatch({
    type: "MENSAJE_GENERAL",
    payload: listObj
  });
  store.dispatch({
    type: "MENSAJE_TITULO",
    payload: title
  });
  store.dispatch({
    type: "TIPO_MENSAJE",
    payload: type
  });
  store.dispatch({
    type: "BOTON_ACCION_MENSAJE",
    payload: action
  });
  store.dispatch({
    type: "NOMBRE_BOTON_ACCION_MENSAJE",
    payload: nameAction
  });
};

const url_repeat = ["get./appauth/validarToken/"]

const api = axios.create({
  baseURL: apilink,
  headers: {
    "Content-type": "application/json",
    "Authorization": `JWT ${localStorage.getItem("access")}`
  }
});



api.interceptors.request.use(
  config => {
    const url = getUrl(config)
    console.log(url)
    const state = store.getState();
    let tempListCharge = state?.currentList?.listApiCharge || []
    if (tempListCharge.includes(url) && !url_repeat.includes(url)){
      return Promise.reject(`Ya se encuentra procesando espere un momento porfavor ${url}`);
    } else {
      tempListCharge.push(url)
      let openBackdrop = state?.currentGlobalVar?.openBackdrop || false
      if (tempListCharge.lenght > 0){
        openBackdrop = true
      }
      updateListCharge(tempListCharge)
      updateOpenBackDrop(openBackdrop)
      return config;
    }


  },
  error => {
    console.log("request error",error)
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    const state = store.getState();
    const url = getUrl(response.config)
    let tempListCharge = state?.currentList?.listApiCharge || []
    tempListCharge =  tempListCharge.filter((auxurl) => url != auxurl)
    let openBackdrop = state?.currentGlobalVar?.openBackdrop || false
    if (tempListCharge.lenght > 0){
      openBackdrop = true
    }
    updateListCharge(tempListCharge)
    return response;
  },
  error => {
    let cleanCharge = false
    const url = getUrl(error?.response?.config)
    if (error?.response?.data){
      const data = error?.response?.data
      if (typeof data == "object"){
        Object.keys(data).map((key) => (alert(data[key])))
      }
      const message = `${dictError[error?.response?.status]}. "${url}". Para continuar debe apretar el botón "OK/Aceptar"`
      setmessageGeneral(message, "error", "¡Atencion!")
    } else {
      if(error.message === 'Network Error'){
        const message = `
        Está presentando problemas de conexión, intente los siguientes pasos
        - Verifica que tu dispositivo esté conectado a Internet.
        - Si el problema persiste, intenta reiniciar tu dispositivo o tu router.
        `
        setmessageGeneral(message, "warning", "¡Atencion!")
        cleanCharge = true
      }
    }
    if (cleanCharge){
      updateListCharge([])
      updateOpenBackDrop(false)
    } else {
      const state = store.getState();
      

      let tempListCharge = state?.currentList?.listApiCharge || []
      tempListCharge =  tempListCharge.filter((auxurl) => url !=  auxurl)
      let openBackdrop = state?.currentGlobalVar?.openBackdrop || false
      if (tempListCharge.lenght > 0){
        openBackdrop = true
      }
      updateListCharge(tempListCharge)
      updateOpenBackDrop(openBackdrop)
    }

    return Promise.reject(error);
  }
);


export default api;
