import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import ModalViewDetail from "./ViewDetail/ModalViewDetail";
import Sucess from "../../Clinic/Endowments/Detail/Dialogs/Sucess";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableFinalized = props => {
  const classes = useStyles();
  const { requestsFinalized } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openModalInformation, setOpenModalInformation] = useState(false);
  const tempEventInfo = {};
  const tempRowInfo = {};
  const [openDialogSucess, setOpenDialogSucess] = useState(false);
  const [message, setMessage] = useState("");
  const [pageRefresh, setPageRefresh] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Tipo de solicitud
              </TableCell>
              <TableCell align="center">Solicitante</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Etapa (Área donde está)</TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Fecha de la solicitud
              </TableCell>
              {/* <TableCell align="center">Acción</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {requestsFinalized
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow key={row.id} align="center">
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">
                      {row.id_tipo_solicitud.nombre_solicitud}
                    </TableCell>
                    <TableCell align="center">
                      {row.id_cargo_solicitante?.nombre_corto}-
                      {row.id_cuenta_solicitante?.first_name}
                      {row.id_cuenta_solicitante?.last_name}
                    </TableCell>
                    <TableCell align="center">{row.estado}</TableCell>
                    <TableCell align="center">
                      {row.id_cargo_solicitante?.nombre_corto}
                    </TableCell>
                    <TableCell align="center">{row.fecha_solicitud}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          {pageRefresh && <div></div>}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={requestsFinalized.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {openModalInformation && (
        <ModalViewDetail
          openModal={openModalInformation}
          closeModal={() => setOpenModalInformation(false)}
          tempEventInfo={tempEventInfo}
          setMessage={setMessage}
          setOpenDialogSucess={setOpenDialogSucess}
          tempRowInfo={tempRowInfo}
        />
      )}
      {openDialogSucess && (
        <Sucess
          openDialog={openDialogSucess}
          closeDialog={() => setOpenDialogSucess(false)}
          message={message}
          setPageRefresh={setPageRefresh}
          setOpenModalAddRecord={setOpenModalInformation}
          setOpenModalViewRecord={setOpenModalInformation}
        />
      )}
    </Paper>
  );
};

export default TableFinalized;

TableFinalized.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  requestsFinalized: PropTypes.array
};
