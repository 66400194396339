import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const EditPageOne = props => {
  const {
    handleExams,
    loadExams,
    examRequest,
    setExamRequest,
    isapreAuthorization,
    setIsapreAuthorization,
    indications,
    setIndications,
    responsable,
    setResponsable,
    examDate,
    setExamDate,
    priority,
    setPriority,
    transfer,
    setTransfer,
    transferType,
    setTransferType
  } = props;
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      //handleExams();
    }
    if (loadExams === false) {
      setFirstLoad(false);
    }
  });

  const handleTransferType = event => {
    setTransferType(event.target.value);
  };

  const handleTransfer = () => {
    setTransfer(!transfer);
  };

  const handlePriority = event => {
    setPriority(event.target.value);
  };

  const handleExamDate = date => {
    setExamDate(date);
  };

  const handleResponsable = () => {
    setResponsable(!responsable);
  };

  return (
    <Grid container justify="center">
      {loadExams ? (
        <CircularProgress />
      ) : (
        <Grid container justify="center">
          
          
          <Grid
            item
            container
            xs={8}
            style={{ marginBottom: "4em" }}
            justify="center"
          >
            <Grid item container xs={12} justify="center">
              <TextField
                fullWidth
                multiline
                rows={4}
                id="outlined-basic2"
                label="Indicaciones"
                variant="outlined"
                value={indications}
                onChange={e => setIndications(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={6}
            style={{ marginBottom: "2em" }}
            justify="center"
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">
                ¿Quién realiza el análisis?
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={responsable}
                onChange={handleResponsable}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="CMH"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label="Laboratorio Externo"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            xs={6}
            justify="center"
            style={{ marginBottom: "2em" }}
          >
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container justify="center">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline1"
                  label="Fecha del examen"
                  minDate={moment()}
                  minDateMessage={"La fecha de envío no puede ser anterior al día de hoy"}
                  cancelLabel={"Cancelar"}
                  okLabel={"Aceptar"}
                  value={examDate}
                  onChange={handleExamDate}
                  
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container justify="center">
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Hora del examen"
                  value={examDate}
                  onChange={handleExamDate}
                  KeyboardButtonProps={{
                    "aria-label": "change time"
                  }}
                  cancelLabel={"Cancelar"}
                  okLabel={"Aceptar"}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            item
            container
            xs={4}
            style={{ marginBottom: "2em", marginTop: "2em" }}
            justify="center"
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Prioridad</FormLabel>
              <RadioGroup
                aria-label="gender2"
                name="gender2"
                value={priority}
                onChange={handlePriority}
              >
                <FormControlLabel
                  value="Baja"
                  control={<Radio color="primary" />}
                  label="Baja"
                />
                <FormControlLabel
                  value="Media"
                  control={<Radio color="primary" />}
                  label="Media"
                />
                <FormControlLabel
                  value="Alta"
                  control={<Radio color="primary" />}
                  label="Alta"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            xs={4}
            style={{ marginBottom: "2em", marginTop: "2em" }}
            justify="center"
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Traslado</FormLabel>
              <RadioGroup
                aria-label="gender3"
                name="gender3"
                value={transfer}
                onChange={handleTransfer}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Si"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {transfer ? (
            <Grid
              item
              container
              xs={4}
              style={{ marginBottom: "2em", marginTop: "2em" }}
              justify="center"
            >
              <FormControl component="fieldset">
                <FormLabel component="legend">Tipo de traslado</FormLabel>
                <RadioGroup
                  aria-label="gender4"
                  name="gender4"
                  value={transferType}
                  onChange={handleTransferType}
                >
                  <FormControlLabel
                    value="Auto"
                    control={<Radio color="primary" />}
                    label="Auto"
                  />
                  <FormControlLabel
                    value="Básico"
                    control={<Radio color="primary" />}
                    label="Básico"
                  />
                  <FormControlLabel
                    value="Medio"
                    control={<Radio color="primary" />}
                    label="Medio"
                  />
                  <FormControlLabel
                    value="Avanzado"
                    control={<Radio color="primary" />}
                    label="Avanzado"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          ) : null}
        </Grid>
      )}
    </Grid>
  );
};

export default EditPageOne;

EditPageOne.propTypes = {
  handleExams: PropTypes.func,
  loadExams: PropTypes.bool,
  examRequest: PropTypes.object,
  setExamRequest: PropTypes.func,
  isapreAuthorization: PropTypes.object,
  setIsapreAuthorization: PropTypes.func,
  indications: PropTypes.string,
  setIndications: PropTypes.func,
  responsable: PropTypes.bool,
  setResponsable: PropTypes.func,
  examDate: PropTypes.object,
  setExamDate: PropTypes.func,
  priority: PropTypes.string,
  setPriority: PropTypes.func,
  transfer: PropTypes.bool,
  setTransfer: PropTypes.func,
  transferType: PropTypes.string,
  setTransferType: PropTypes.func
};
