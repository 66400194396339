import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import DateFilterStart from "./Filters/DateFilterStart";
import DateFilterEnd from "./Filters/DateFilterEnd";
import PositionFilter from "./Filters/PositionFilter";
import ProfessionalFilter from "./Filters/ProfessionalFilter";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  table: {
    minWidth: 650
  }
});

const Filters = props => {
  const classes = useStyles();
  const {
    dateStart,
    handleDateStartChange,
    dateEnd,
    handleDateEndChange,
    position,
    handlePosition,
    professional,
    setProfessional,
    listPositions,
    listProfessionals,
    handleProfessional
  } = props;

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      style={{ marginBottom: "3em" }}
    >
      <Grid item xs={1} />
      <Grid item xs={10} container>
        <Grid item xs={12} container spacing={1} justify="space-between">
          <Grid item xs={3} container justify="center">
            <DateFilterStart
              dateStart={dateStart}
              handleDateStartChange={handleDateStartChange}
            />
          </Grid>
          <Grid item xs={3} container justify="center">
            <DateFilterEnd
              dateEnd={dateEnd}
              handleDateEndChange={handleDateEndChange}
            />
          </Grid>
          <Grid item xs={3} container justify="center">
            <PositionFilter
              position={position}
              listPositions={listPositions}
              handlePosition={handlePosition}
            />
          </Grid>
          <Grid item xs={3} container justify="center">
            <ProfessionalFilter
              professional={professional}
              setProfessional={setProfessional}
              listProfessionals={listProfessionals}
              handleProfessional={handleProfessional}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default Filters;

Filters.propTypes = {
  dateStart: PropTypes.object,
  handleDateStartChange: PropTypes.func,
  dateEnd: PropTypes.object,
  handleDateEndChange: PropTypes.func,
  position: PropTypes.number,
  handlePosition: PropTypes.func,
  professional: PropTypes.number,
  setProfessional: PropTypes.func,
  listPositions: PropTypes.array,
  listProfessionals: PropTypes.array,
  handleProfessional: PropTypes.func
};
