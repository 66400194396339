import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import api from "utils/API";
import TableRequestInProcess from "./TableRequestInProcess";
import DialogDeleteRequest from "./DialogDeleteRequest";
import DialogRejectRequest from "./DialogRejectRequest";
// import TableSummary from "./TableSummary";
import moment from "moment-timezone";
import "moment/locale/es";
import DialogAceptRequest from "./DialogAceptRequest";
import RequestInfo from "views/Widgets/Common/RequestInfo";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },

}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalCheckRequest = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    rowInfo,
    setauxData,
    dataArray,
    setOpenModalEditInProcess,
    type
  } = props;

  const [charge, setCharge] = useState(true);

  const [pageRefresh, setPageRefresh] = useState(false);
  const getIndex = dataArray.findIndex(row => row.id == rowInfo.id);
  const [arrayInfo, setArrayInfo] = useState([]);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogAceptRequest, setOpenDialogAceptRequest] = useState(false);
  const [idNotaVenta, setIdNotaVenta] = useState(null);
  const [orderInfo, setorderInfo] = useState({});
  const [showNV, setShowNV] = useState(false);
  const [openDialogRejectRequest, setOpenDialogRejectRequest] = useState(false);


  useEffect(() => {
    if (charge) {
      initialCharge();
    }
    if (pageRefresh) {
      setPageRefresh(false);
    }
  });

  const initialCharge = () => {
    setCharge(false);
    const getInformation = api.get(
      `/api/paciente/${rowInfo.id_paciente}/solicitudes/insumosextra/${rowInfo.id}/`
    );
    // rowInfo.id
    Promise.all([getInformation])
      .then(response => {
        setIdNotaVenta(response[0].data.productos_pedidos.id_solicitud_pedido);
        let tempArray = [];
        response[0].data.productos_pedidos.medicamentos.map(medicamento =>
          tempArray.push({
            tipo: "Medicamento",
            item: medicamento.id_insumo_medicamento.descripcion_producto,
            sku: medicamento.id_insumo_medicamento.sku,
            cantidad: medicamento.cantidad
          })
        );
        response[0].data.productos_pedidos.insumos.map(insumo =>
          tempArray.push({
            tipo: "Insumos",
            cantidad: insumo.cantidad,
            item: insumo.id_insumo_medicamento.descripcion_producto,
            sku: insumo.id_insumo_medicamento.sku,
          })
        );
        response[0].data.productos_pedidos.equipos.map(equipo =>
          tempArray.push({
            tipo: "Equipos",
            cantidad: equipo.cantidad,
            item: equipo.id_equipo.descripcion_producto,
            sku: equipo.id_equipo.sku,
          })
        );
        if (response[0]?.data?.productos_pedidos?.insumos.length > 0 || response[0].data?.productos_pedidos?.medicamentos?.lenght > 0) {
          setShowNV(true);
        }
        setorderInfo(response[0]?.data?.productos_pedidos?.solicitud_pedido)
        setArrayInfo(tempArray);

      })

  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        <ModalTitle id="customized-dialog-title" onClose={closeModal}>
          Resumen solicitud
        </ModalTitle>
        <DialogContent>
          {pageRefresh && <div></div>}
          <RequestInfo
            orderInfo={orderInfo}
            idNotaVenta={showNV ? idNotaVenta : null}
          />
          <Grid container className={classes.root} justify="center">
            <Grid item container xs={12}>
              <TableRequestInProcess arrayInfo={arrayInfo} />
            </Grid>
          </Grid>
        </DialogContent>
        {type === "1" ? (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#43a047",
                textTransform: "none",
                color: "#fff"
              }}
              onClick={() => {
                setOpenModalEditInProcess(true);
              }}
            >
              Agregar Items
            </Button>
          </DialogActions>
        ) : (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: "none"
              }}
              onClick={() => {
                closeModal();
              }}
            >
              Atrás
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{
                textTransform: "none"
              }}
              onClick={() => {
                setOpenDialogRejectRequest(true);
                // rejectRequest();
              }}
            >
              Cancelar envío
            </Button>
          </DialogActions>
        )}
        {openDialogDelete && (
          <DialogDeleteRequest
            openDialog={openDialogDelete}
            closeDialog={() => setOpenDialogDelete(false)}
            rowInfo={rowInfo}
            closeModal={closeModal}
            dataArray={dataArray}
            setauxData={setauxData}
            getIndex={getIndex}
          />
        )}
        {openDialogAceptRequest && (
          <DialogAceptRequest
            openDialog={openDialogAceptRequest}
            closeDialog={() => setOpenDialogAceptRequest(false)}
            closeMainModal={closeModal}
          />
        )}
        {openDialogRejectRequest && (
          <DialogRejectRequest
            openDialog={openDialogRejectRequest}
            closeDialog={() => setOpenDialogRejectRequest(false)}
            closeMainModal={closeModal}
            rowInfo={rowInfo}
            closeModal={closeModal}
            dataArray={dataArray}
            setauxData={setauxData}
            getIndex={getIndex}
          />
        )}
      </Paper>
    </Modal>
  );
};

export default ModalCheckRequest;

ModalCheckRequest.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.object,
  idFicha: PropTypes.string,
  setOpenDialogSucess: PropTypes.func,
  setOpenModalAddRecord: PropTypes.func,
  setTempMessage: PropTypes.func,
  variableCounter: PropTypes.object,
  setVariableCounter: PropTypes.func,
  setPageRefresh: PropTypes.func,
  eventsVariables: PropTypes.array,
  setEvents: PropTypes.func,
  extraShift: PropTypes.bool,
  extraAttention: PropTypes.bool,
  holiday: PropTypes.bool,
  unpaidLeave: PropTypes.bool,
  absence: PropTypes.bool,
  settypeRequest: PropTypes.func,
  setOpenDialogDelete: PropTypes.func,
  dataMedicalArray: PropTypes.array,
  dataResourceArray: PropTypes.array,
  dataEquipArray: PropTypes.array,
  state: PropTypes.string,
  dataPatient: PropTypes.object,
  rowInfo: PropTypes.object,
  setauxData: PropTypes.func,
  dataArray: PropTypes.array,
  setOpenModalEditInProcess: PropTypes.func,
  type: PropTypes.string
};
