import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import api from "utils/API";
import TableAlerts from "./TableAlerts";
import TableRecords from "./TableRecords";
import GraphicComponent from "./Graphic/GraphicComponent";
import ModalAddAlert from "./ModalAddAlert";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function ModalHistorialSV(props) {
  const {
    open,
    closeModal,
    dataFile,
    alertSigns,
    sizePatientProp,
    weightProp,
    temperatureProp,
    heartRateProp,
    breathingFrequencyProp,
    oxygenSaturationProp,
    bloodPressureSystolicProp,
    bloodPressureDiastolicProp,
    bloodPressureMeanProp,
    painProp,
    setActivateAlert,
    setAlertSigns
  } = props;
  const [openModalAddAlert, setOpenModalAddAlert] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD[T]HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD[T]HH:mm:ss")
  );
  const [getFilterOne, setGetFilterOne] = useState([]);
  const [getFilterTwo, setGetFilterTwo] = useState([]);
  const [getAlerts, setGetAlerts] = useState([]);

  const [changes, setChanges] = useState(true);
  const [graphicData, setGraphicData] = useState([]);
  const [activos, setActivos] = useState([]);
  const [getListVitalSigns, setGetVitalSigns] = useState([]);
  const [changeUpdateDate, setChangeUpdateDate] = useState(false);

  const [alertas, setAlertas] = useState({});

  const type_user = localStorage.getItem("tipo_perfil");

  const signosProps = [
    {
      tipo_signos: "sizePatient",
      medicion: sizePatientProp.value
    },
    {
      tipo_signos: "temperature",
      medicion: temperatureProp.value
    },
    {
      tipo_signos: "weight",
      medicion: weightProp.value
    },
    {
      tipo_signos: "heartRate",
      medicion: heartRateProp.value
    },
    {
      tipo_signos: "breathingFrequency",
      medicion: breathingFrequencyProp.value
    },
    {
      tipo_signos: "oxygenSaturation",
      medicion: oxygenSaturationProp.value
    },
    {
      tipo_signos: "bloodPressureSystolic",
      medicion:bloodPressureSystolicProp.value
    },
    {
      tipo_signos: "bloodPressureDiastolic",
      medicion:bloodPressureDiastolicProp.value
    },
    {
      tipo_signos: "bloodPressureMean",
      medicion:bloodPressureMeanProp.value
    },
    {
      tipo_signos: "pain",
      medicion: painProp.value
    }
  ];

  const [filters, setFilters] = useState({
    size: false,
    weight: false,
    temperature: true,
    heartRate: true,
    breathingFrecuency: false,
    oxygenSaturation: false,
    bloodPressure: false,
    bloodPressureSystolic:false,
    bloodPressureDiastolic:false,
    pain: false
  });

  const handleDateStartChange = date => {
    setStartDate(date);
    setChangeUpdateDate(true);
  };

  useEffect(() => {
    if (changeUpdateDate) {
      updateFilters(activos);
    }
  });

  const handleDateEndChange = date => {
    setEndDate(date);
    setChangeUpdateDate(true);
  };

  const handleChangeFilters = event => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
    const tempSomething = {
      ...filters,
      [event.target.name]: event.target.checked
    };
    const {
      size,
      weight,
      temperature,
      heartRate,
      breathingFrecuency,
      oxygenSaturation,
      bloodPressure,
      bloodPressureSystolic,
      bloodPressureDiastolic,
      pain
    } = tempSomething;

    const filtersInformationTemp = [
      {
        name: "Talla",
        label: "sizePatient",
        value: size
      },
      {
        name: "Peso",
        label: "weight",
        value: weight
      },
      {
        name: "Temperatura",
        label: "temperature",
        value: temperature
      },
      {
        name: "FrecuenciaCardiaca",
        label: "heartRate",
        value: heartRate
      },
      {
        name: "FrecuenciaRespiratoria",
        label: "breathingFrecuency",
        value: breathingFrecuency
      },
      {
        name: "SaturacionOxigeno",
        label: "oxygenSaturation",
        value: oxygenSaturation
      },
      {
        name: "PresionArterial",
        label: "bloodPressure",
        value: bloodPressure
      },
      {
        name: "PresionArterialSistolica",
        label: "bloodPressureSystolic",
        value: bloodPressureSystolic
      },
      {
        name: "PresionArterialDiastolica",
        label: "bloodPressureDiastolic",
        value: bloodPressureDiastolic
      },
      {
        name: "Dolor",
        label: "pain",
        value: pain
      }
    ];

    const activosTemp = filtersInformationTemp.filter(
      filtered => filtered.value
    );
    setActivos(activosTemp);
    updateFilters(activosTemp);
  };

  const {
    size,
    weight,
    temperature,
    heartRate,
    breathingFrecuency,
    oxygenSaturation,
    bloodPressure,
    bloodPressureSystolic,
    bloodPressureDiastolic,
    pain
  } = filters;

  const countFilters = [
    size,
    weight,
    temperature,
    heartRate,
    breathingFrecuency,
    oxygenSaturation,
    bloodPressure,
    bloodPressureSystolic,
    bloodPressureDiastolic,
    pain
  ];

  let checkFilter =
    countFilters.filter(filtered => filtered).length <= 2 &&
    countFilters.filter(filtered => filtered).length === 2;

  const listSigns = useSelector(state => state.currentList.LISTA_SIGNOS_VITALES);

  const filteredVitalSigns = listSigns.filter(list => !["diagnosis", "sizePatient", "allergy"].includes(list.value));


  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);
    const firstFilterOne = api.get(
      `api/paciente/${dataFile.id}/signos/tipo/temperature/fecha/${moment(
        startDate
      ).format("YYYY-MM-DD")}T00:00:00/${moment(endDate).format(
        "YYYY-MM-DD"
      )}T23:59:59/`
    );
    const secondFilterTwo = api.get(
      `api/paciente/${dataFile.id}/signos/tipo/heartRate/fecha/${moment(
        startDate
      ).format("YYYY-MM-DD")}T00:00:00/${moment(endDate).format(
        "YYYY-MM-DD"
      )}T23:59:59/`
    );

    const getAlerts = api.get(`api/paciente/${dataFile.id}/signos/limites/`);

    Promise.all([firstFilterOne, secondFilterTwo, getAlerts])
      .then(response => {
        response[2].data.map(alerta => {
          let tempRow = alertas;
          tempRow[alerta.tipo_signos] = {
            limite_inferior: alerta.limite_inferior,
            limite_superior: alerta.limite_superior
          };
          setAlertas(tempRow);
        });
        signosProps.map(row => {
          if (alertas[`${row.tipo_signos}`] != undefined) {
            if (
              parseFloat(row.medicion) <
                alertas[`${row.tipo_signos}`].limite_inferior ||
              parseFloat(row.medicion) >
                alertas[`${row.tipo_signos}`].limite_superior
            ) {
              setActivateAlert(true);
              const tempArray = alertSigns;
              tempArray.push({
                [row.tipo_signos]: true
              });
              setAlertSigns(tempArray);
            }
          }
        });
        if (response[0].data.length > response[1].data.length) {
          setGetFilterOne(response[0].data);
          setGetFilterTwo(response[1].data);
          let dataTimeLine = [];
          response[0].data.map(row => {
            const tempRow = row;
            dataTimeLine.push({
              name: moment(tempRow.fecha_hora).format("HH:mm"),
              //Temperatura: parseInt(tempRow.medicion)
              Temperatura: tempRow.medicion
            });
          });
          response[1].data.map((row, index) => {
            const tempRow = row;
            dataTimeLine[index].name = moment(tempRow.fecha_hora).format("HH:mm");
            //dataTimeLine[index].FrecuenciaCardiaca = parseInt(tempRow.medicion);
            dataTimeLine[index].FrecuenciaCardiaca = tempRow.medicion;
          });
          setActivos([
            {
              label: "temperature",
              name: "Temperatura",
              value: true
            },
            {
              label: "heartRate",
              name: "FrecuenciaCardiaca",
              value: true
            }
          ]);
          setGraphicData(dataTimeLine);
        } else {
          setGetFilterTwo(response[0].data);
          setGetFilterOne(response[1].data);
          let dataTimeLine = [];
          response[1].data.map(row => {
            const tempRow = row;
            dataTimeLine.push({
              name: moment(tempRow.fecha_hora).format("HH:mm"),
              //FrecuenciaCardiaca: parseInt(tempRow.medicion)
              FrecuenciaCardiaca: tempRow.medicion
            });
          });
          response[0].data.map((row, index) => {
            const tempRow = row;
            dataTimeLine[index].name = moment(tempRow.fecha_hora).format("HH:mm");
            //dataTimeLine[index].Temperatura = parseInt(tempRow.medicion);
            dataTimeLine[index].Temperatura = tempRow.medicion;
          });
          setActivos([
            {
              label: "heartRate",
              name: "FrecuenciaCardiaca",
              value: true
            },
            {
              label: "temperature",
              name: "Temperatura",
              value: true
            }
          ]);
          setGraphicData(dataTimeLine);
        }
        setGetAlerts(response[2].data);


      })

  };

  const updateFilters = activosTemp => {
    setChangeUpdateDate(false);
    if (activosTemp.length === 0) {

      setGetFilterOne([]);
      setGetFilterTwo([]);
      setGraphicData([]);

    }
    if (activosTemp.length === 1) {

      const filterOne = api.get(
        `api/paciente/${dataFile.id}/signos/tipo/${
          activosTemp[0].label
        }/fecha/${moment(startDate).format("YYYY-MM-DD[T]HH:mm:ss")}/${moment(
          endDate
        ).format("YYYY-MM-DD[T]HH:mm:ss")}/`
      );
      Promise.all([filterOne])
        .then(response => {
          setGetFilterOne(response[0].data);
          setGetFilterTwo([]);
          const dataTimeLine = [];
          response[0].data.map(row => {
            const tempRow = row;
            dataTimeLine.push({
              name: moment(tempRow.fecha_hora).format("YYYY-MM-DD HH:mm"),
              //[activosTemp[0].name]: parseInt(tempRow.medicion)
              [activosTemp[0].name]: tempRow.medicion
            });
          });
          setGraphicData(dataTimeLine);

        })

    }
    if (activosTemp.length === 2) {

      const filterOne = api.get(
        `api/paciente/${dataFile.id}/signos/tipo/${
          activosTemp[0].label
        }/fecha/${moment(startDate).format("YYYY-MM-DD[T]HH:mm:ss")}/${moment(
          endDate
        ).format("YYYY-MM-DD[T]HH:mm:ss")}/`
      );
      const filterTwo = api.get(
        `api/paciente/${dataFile.id}/signos/tipo/${
          activosTemp[1].label
        }/fecha/${moment(startDate).format("YYYY-MM-DD[T]HH:mm:ss")}/${moment(
          endDate
        ).format("YYYY-MM-DD[T]HH:mm:ss")}/`
      );
      Promise.all([filterOne, filterTwo])
        .then(response => {
          if (response[0].data.length > response[1].data.length) {
            setGetFilterOne(response[0].data);
            setGetFilterTwo(response[1].data);
            let dataTimeLine = [];
            response[0].data.map(row => {
              const tempRow = row;
              dataTimeLine.push({
                name: moment(tempRow.fecha_hora).format("YYYY-MM-DD HH:mm"),
                //[activosTemp[0].name]: parseInt(tempRow.medicion)
                [activosTemp[0].name]: tempRow.medicion
              });
            });
            response[1].data.map((row, index) => {
              const tempRow = row;
              dataTimeLine[index].name = moment(tempRow.fecha_hora).format(
                "YYYY-MM-DD HH:mm"
              );
              //dataTimeLine[index][activosTemp[1].name] = parseInt(
              //tempRow.medicion
              dataTimeLine[index][activosTemp[1].name] = tempRow.medicion;
            });
            setGraphicData(dataTimeLine);
          } else {
            setGetFilterTwo(response[0].data);
            setGetFilterOne(response[1].data);
            let dataTimeLine = [];
            response[1].data.map(row => {
              const tempRow = row;
              dataTimeLine.push({
                name: moment(tempRow.fecha_hora).format("YYYY-MM-DD HH:mm"),
                //[activosTemp[1].name]: parseInt(tempRow.medicion)
                [activosTemp[1].name]: tempRow.medicion
              });
            });
            response[0].data.map((row, index) => {
              const tempRow = row;
              dataTimeLine[index].name = moment(tempRow.fecha_hora).format(
                "YYYY-MM-DD HH:mm"
              );
              //dataTimeLine[index][activosTemp[0].name] = parseInt(
              //tempRow.medicion
              dataTimeLine[index][activosTemp[0].name] = tempRow.medicion;
            });
            setGraphicData(dataTimeLine);
          }

        })

    }
  };

  const filtersRecords = [...getFilterOne, ...getFilterTwo];

  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
        disableBackdropClick
        scroll="paper"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          align="center"
        >
          Signos vitales
        </DialogTitle>
        <DialogContent scroll={"paper"}>
          <GraphicComponent
            activos={activos}
            graphicData={graphicData}
            startDate={startDate}
            endDate={endDate}
            handleDateStartChange={handleDateStartChange}
            handleDateEndChange={handleDateEndChange}
            size={size}
            weight={weight}
            temperature={temperature}
            heartRate={heartRate}
            breathingFrecuency={breathingFrecuency}
            oxygenSaturation={oxygenSaturation}
            bloodPressure={bloodPressure}
            bloodPressureSystolic={bloodPressureSystolic}
            bloodPressureDiastolic={bloodPressureDiastolic}
            pain={pain}
            handleChangeFilters={handleChangeFilters}
            checkFilter={checkFilter}
            getFilterOne={getFilterOne}
            getFilterTwo={getFilterTwo}
            alertSigns={alertSigns}
          />
          <TableRecords
            filtersRecords={filtersRecords}
            getFilterOne={getFilterOne}
            getFilterTwo={getFilterTwo}
          />
          <TableAlerts
            getAlerts={getAlerts}
            getListVitalSigns={getListVitalSigns}
            setChanges={setChanges}

            setActivateAlert={setActivateAlert}
            setAlertSigns={setAlertSigns}
            setAlertas={setAlertas}
          />
        </DialogContent>
        {type_user !== "CLITecEnfBas" &&
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            color="primary"
            onClick={() => setOpenModalAddAlert(true)}
          >
            Agregar alerta
          </Button>
        </DialogActions>
        }
      </Dialog>
      {openModalAddAlert && (
        <ModalAddAlert
          open={openModalAddAlert}
          closeModal={() => setOpenModalAddAlert(false)}
          getListVitalSigns={filteredVitalSigns}
          dataFile={dataFile}
          setChanges={setChanges}

          getAlerts={getAlerts}
          setActivateAlert={setActivateAlert}
          setAlertSigns={setAlertSigns}
          setAlertas={setAlertas}
        />
      )}
    </div>
  );
}

ModalHistorialSV.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  dataFile: PropTypes.object,
  alertSigns: PropTypes.array,
  sizePatientProp: PropTypes.object,
  weightProp: PropTypes.object,
  temperatureProp: PropTypes.object,
  heartRateProp: PropTypes.object,
  breathingFrequencyProp: PropTypes.object,
  oxygenSaturationProp: PropTypes.object,
  bloodPressureSystolicProp: PropTypes.object,
  bloodPressureDiastolicProp: PropTypes.object,
  bloodPressureMeanProp: PropTypes.object,
  painProp: PropTypes.object,
  setActivateAlert: PropTypes.func,
  setAlertSigns: PropTypes.func
};
