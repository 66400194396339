import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { grey, indigo, orange, green, red } from "@material-ui/core/colors";
import { setmessageGeneral } from "actions/getGlobalAction";
import api from "utils/API";

export default function Dashboard() {
  const dispatch = useDispatch();
  const listCharges = useSelector(state => state.currentList.listCharges);

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    alert('Complete Cache Cleared')
    window.location.reload()
  };

  const runCron = () => {
    api.get("api/run_cron/").then((response) => {
      setmessageGeneral(dispatch, "Cron ejecutado correctamente", "success", '¡Éxito!');
    }).catch((error) => {
      {};
    })
  }

  const permission = JSON.parse(localStorage.getItem("permisos"))

  const CardItem = ({title, content, textAction, action}) => <Grid item container xs={4}
  style={{ padding: "1em",  marginTop: "2em 0 0 1em", backgroundColor: "#fff", borderRadius: "5px"}}>
  <Grid item container xs={12}  direction="row" alignContent="flex-start">
      <Grid item container xs={12} style={{margin: "0 0 20px 0"}}>
          <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}>
           {title}
          </Typography>
      </Grid>
      <Grid item container xs={12}>
          <Typography gutterBottom style={{ textTransform: "none", margin: "0 0 32px 0" }}>
              {content}
          </Typography>
          <Button variant="contained" color="primary" style={{ width: "313px", height: "36px"}} onClick={action}>{textAction}</Button>
      </Grid>
  </Grid>
</Grid>

  return (
    <Grid item container xs={12} direction="row" alignContent="flex-start" justify="space-evenly">
      <CardItem title={"Limpiar cache manual"}
        content={"Permite limpiar el cache manualmente del sistema, esto es util cuando se realizan cambios en el sistema y no se ven reflejados en el navegador."}
        textAction={"Limpiar cache"} action={() => clearCacheData()}
      />
      {(permission && permission.includes("CronSiclin")) &&
      <CardItem title={"Correr cron"}
        content={"Permite dejar corriendo los procesos automaticos en segundo plano del sistema"}
        textAction={"Activar Cron"} action={() => runCron()}
      />
      }
    </Grid>

  );

}
