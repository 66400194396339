import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styled from "styled-components";
import { orange, red, green } from "@material-ui/core/colors";
import {
    Grid,
    Typography as MuiTypography,
    Card,
    CardContent,
    CardMedia,
    Button,
} from "@material-ui/core";
import moment from 'moment-timezone';
import 'moment/locale/es' 
import useMediaQuery from '@material-ui/core/useMediaQuery';
moment.locale("es");

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 300,
        maxWidth: 300,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    orangeButton: {
        margin: "1%",
        textTransform: "none",
        color: orange[50],
        background: orange[800],
        '&:hover': {
            background: orange[900],
        },
    },
}));

const Typography = styled(MuiTypography)`
    text-transform: none;
`;

export default function MedicCard({data, showCalendar}) {
    const classes = useStyles();
    const theme = useTheme();
    const notfoundImg = `https://www.ing.uc.cl/transporte-y-logistica/wp-content/uploads/2018/04/foto-incognito.jpg`;

    const matches300 = useMediaQuery('(max-width: 300px)');
    const matches330 = useMediaQuery('(max-width: 330px)');
    const matches370 = useMediaQuery('(max-width: 370px)');
    const matches385 = useMediaQuery('(max-width: 385px)');
    const matches424 = useMediaQuery('(max-width: 424px)');
    
    return (
        <div>
            {matches300 ? (
                <Grid item justify="center" style={{maxWidth: 240, overflow: "auto"}}>
                    <Card style={{boxShadow: "0 0 14px 0 rgba(0, 0, 0, 0.38)"}}>
                        <div className={classes.details}>
                            <Grid item >
                                <CardContent >  
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        item xs={12}
                                    >
                                        <Grid item xs={12}>
                                            <img
                                                style={{ width: "100%", maxWidth: "800px", height: "auto"}}
                                                src={data.foto ? data.foto : notfoundImg}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography component="h5" variant="h5">
                                                {`${data.nombres} ${data.apellido_paterno} ${data.apellido_materno}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {data.nombre_especialidad}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {`Descripción: ${data.descripcion}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {`Próxima  hora disponible: ${
                                                    data.proxima_consulta ? 
                                                        moment(data.proxima_consulta["fecha_hora"]).format("dddd DD MMMM, HH:mm"): "Sin hora"
                                                    } - ${data.proxima_consulta ?
                                                            moment(data. proxima_consulta["fecha_hora"]).add(data. proxima_consulta["duracion"], "minutes").format("HH:mm") : ""
                                                    }`
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button 
                                                // size="small" 
                                                onClick={() => showCalendar(data)} 
                                                className={classes.orangeButton} 
                                            >
                                                Ver horario 
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            ) : matches330 ? (
                <Grid item justify="center" style={{maxWidth: 280, overflow: "auto"}}>
                    <Card style={{boxShadow: "0 0 14px 0 rgba(0, 0, 0, 0.38)"}}>
                        <div className={classes.details}>
                            <Grid item >
                                <CardContent >  
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        item xs={12}
                                    >
                                        <Grid item xs={12}>
                                            <img
                                                style={{ width: "100%", maxWidth: "800px", height: "auto"}}
                                                src={data.foto ? data.foto : notfoundImg}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography component="h5" variant="h5">
                                                {`${data.nombres} ${data.apellido_paterno} ${data.apellido_materno}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {data.nombre_especialidad}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {`Descripción: ${data.descripcion}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {`Próxima  hora disponible: ${
                                                    data.proxima_consulta ? 
                                                        moment(data.proxima_consulta["fecha_hora"]).format("dddd DD MMMM, HH:mm"): "Sin hora"
                                                    } - ${data.proxima_consulta ?
                                                            moment(data.proxima_consulta["fecha_hora"]).add(data. proxima_consulta["duracion"], "minutes").format("HH:mm") : ""
                                                    }`
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button 
                                                size="small" 
                                                onClick={() => showCalendar(data)} 
                                                className={classes.orangeButton} 
                                            >
                                                Ver horario 
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            ) : matches370 ? (
                <Grid item justify="center" style={{maxWidth: 320, overflow: "auto"}}>
                    <Card style={{boxShadow: "0 0 14px 0 rgba(0, 0, 0, 0.38)"}}>
                        <div className={classes.details}>
                            <Grid item >
                                <CardContent >  
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        item xs={12}
                                    >
                                        <Grid item xs={12}>
                                            <img
                                                style={{ width: "100%", maxWidth: "800px", height: "auto"}}
                                                src={data.foto ? data.foto : notfoundImg}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography component="h5" variant="h5">
                                                {`${data.nombres} ${data.apellido_paterno} ${data.apellido_materno}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {data.nombre_especialidad}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {`Descripción: ${data.descripcion}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {`Próxima  hora disponible: ${
                                                    data.proxima_consulta ? 
                                                        moment(data.proxima_consulta["fecha_hora"]).format("dddd DD MMMM, HH:mm"): "Sin hora"
                                                    } - ${data.proxima_consulta ?
                                                            moment(data. proxima_consulta["fecha_hora"]).add(data. proxima_consulta["duracion"], "minutes").format("HH:mm") : ""
                                                    }`
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button 
                                                size="small" 
                                                onClick={() => showCalendar(data)} 
                                                className={classes.orangeButton} 
                                            >
                                                Ver horario 
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            ) : matches385 ? (
                <Grid item justify="center" style={{maxWidth: 320, overflow: "auto"}}>
                    <Card style={{boxShadow: "0 0 14px 0 rgba(0, 0, 0, 0.38)"}}>
                        <div className={classes.details}>
                            <Grid item >
                                <CardContent >  
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        item xs={12}
                                    >
                                        <Grid item xs={12}>
                                            <img
                                                style={{ width: "100%", maxWidth: "800px", height: "auto"}}
                                                src={data.foto ? data.foto : notfoundImg}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography component="h5" variant="h5">
                                                {`${data.nombres} ${data.apellido_paterno} ${data.apellido_materno}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {data.nombre_especialidad}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {`Descripción: ${data.descripcion}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {`Próxima  hora disponible: ${
                                                    data.proxima_consulta ? 
                                                        moment(data.proxima_consulta["fecha_hora"]).format("dddd DD MMMM, HH:mm"): "Sin hora"
                                                    } - ${data.proxima_consulta ?
                                                            moment(data. proxima_consulta["fecha_hora"]).add(data. proxima_consulta["duracion"], "minutes").format("HH:mm") : ""
                                                    }`
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button 
                                                size="small" 
                                                onClick={() => showCalendar(data)} 
                                                className={classes.orangeButton} 
                                            >
                                                Ver horario 
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            ) : matches424 ? (
                <Grid item justify="center" style={{maxWidth: 350, overflow: "auto"}}>
                    <Card style={{boxShadow: "0 0 14px 0 rgba(0, 0, 0, 0.38)"}}>
                        <div className={classes.details}>
                            <Grid item >
                                <CardContent >  
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        item xs={12}
                                    >
                                        <Grid item xs={12}>
                                            <img
                                                style={{ width: "100%", maxWidth: "800px", height: "auto"}}
                                                src={data.foto ? data.foto : notfoundImg}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography component="h5" variant="h5">
                                                {`${data.nombres} ${data.apellido_paterno} ${data.apellido_materno}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {data.nombre_especialidad}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {`Descripción: ${data.descripcion}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {`Próxima  hora disponible: ${
                                                    data.proxima_consulta ? 
                                                        moment(data.proxima_consulta["fecha_hora"]).format("dddd DD MMMM, HH:mm"): "Sin hora"
                                                    } - ${data.proxima_consulta ?
                                                            moment(data. proxima_consulta["fecha_hora"]).add(data. proxima_consulta["duracion"], "minutes").format("HH:mm") : ""
                                                    }`
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button 
                                                size="small" 
                                                onClick={() => showCalendar(data)} 
                                                className={classes.orangeButton} 
                                            >
                                                Ver horario 
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            ) : (
                <Card className={classes.card} style={{boxShadow: "0 0 14px 0 rgba(0, 0, 0, 0.38)"}}>
                    <CardMedia
                        className={classes.cover}
                        image={data.foto ? data.foto : notfoundImg}
                        title= {`${data.nombres} ${data.apellido_paterno} ${data.apellido_materno}`}
                        
                    />
                    <div className={classes.details}>
                        <Grid item >
                            <CardContent >  
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    item xs={12}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Typography component="h5" variant="h5">
                                            {`${data.nombres} ${data.apellido_paterno} ${data.apellido_materno}`}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {data.nombre_especialidad}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {`Descripción: ${data.descripcion}`}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {`Próxima  hora disponible: ${
                                                data.proxima_consulta ? 
                                                    moment(data.proxima_consulta["fecha_hora"]).format("dddd DD MMMM, HH:mm"): "Sin hora"
                                                } - ${data.proxima_consulta ?
                                                        moment(data. proxima_consulta["fecha_hora"]).add(data. proxima_consulta["duracion"], "minutes").format("HH:mm") : ""
                                                }`
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        item xs={12}
                                    >
                                        <Button 
                                            size="small" 
                                            onClick={() => showCalendar(data)} 
                                            className={classes.orangeButton} 
                                        >
                                            Ver horario 
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Grid>
                    </div>
                </Card>
            )}
        </div>
    );
}

