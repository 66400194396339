import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";
import api from "utils/API";
import apiform from "utils/APIForm";
import Vacaciones from "./Vacaciones";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

var momentBusinessDays = require("moment-business-days");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator={true}
      decimalSeparator={false}
      allowNegative={false}
      isNumericString
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ModalAddRecord = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    tempDateInfo,
    idFicha,
    setOpenDialogSucess,
    setTempMessage,
    variableCounter,
    setVariableCounter,
    setPageRefresh,
    eventsVariables,
    setEvents,
    extraShift,
    extraAttention,
    holiday,
    unpaidLeave,
    absence,
    setMessage,
    setOpenDialog,
  } = props;
  const [openAnimation, setOpenAnimation] = useState(false);
  const [startDate, setStartDate] = useState(moment(tempDateInfo));
  const [endDate, setEndDate] = useState(moment(tempDateInfo));
  const [selectTypeRecord, setSelectTypeRecord] = useState("");
  const [changes, setChanges] = useState(true);
  const [professionals, setProfessionals] = useState([]);
  const [professional, setProfessional] = useState({});

  // end turno extra
  // atención extra
  const [patients, setPatients] = useState([]);

  // end atención extra
  // Vacaciones
  const [estadoVacaciones, setEstadoVacaciones] = useState(
    "Aprobadas jefatura"
  );
  const [ultimoDiaTrabajado, setUltimoDiaTrabajado] = useState(
    moment(tempDateInfo)
  );
  const [primerDiaTrabajado, setPrimerDiaTrabajado] = useState(
    moment(tempDateInfo)
  );
  const [totalDias, setTotalDias] = useState(
    momentBusinessDays(moment(endDate).add(2, "seconds")).businessDiff(
      moment(startDate).add(1, "seconds")
    )
  );
  // end vacaciones
  // permiso sin goce de sueldo
  const estadoPermisoSinSueldo = "Aprobado por Jefatura";
  // end permiso sin goce de sueldo
  // inasistencia
  const [licencia, setLicencia] = useState("");
  const [amonestacion, setAmonestacion] = useState("");
  const [descuento, setDescuento] = useState("");
  // end inasistencia

  const listRecordType = useSelector(state => state.currentList.LISTA_TIPO_REGISTRO_VARIABLE);

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);

    const getPatients = api.get("api/paciente/activos/");
    const getProfessionals = api.get("api/personal/ficha/activas/");

    Promise.all([getPatients, getProfessionals])
      .then(response => {
        let tempPatients = [];
        let tempProfessionals = [];
        response[0].data.map(patient => {
          tempPatients.push({
            value: patient.id,
            label: `${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`
          });
        });
        response[1].data.map(professional => {
          tempProfessionals.push({
            value: professional.id,
            label: `${professional.nombres} ${professional.apellido_paterno} ${professional.apellido_materno}`
          });
        });
        setProfessionals(tempProfessionals);
        setPatients(tempPatients);
      })
  };

  const handleUltimoDiaTrabajado = date => {
    setUltimoDiaTrabajado(date);
  };

  const handlePrimerDiaTrabajado = date => {
    setPrimerDiaTrabajado(date);
  };

  const handleEstadoVacaciones = event => {
    setEstadoVacaciones(event.target.value);
  };

  const handleSelectTypeRecord = event => {
    setSelectTypeRecord(event.target.value);
  };

  const handleStartDate = date => {
    setStartDate(date);
    setTotalDias(
      momentBusinessDays(moment(endDate).add(2, "seconds")).businessDiff(
        moment(date).add(1, "seconds")
      )
    );
  };

  const handleEndDate = date => {
    setEndDate(date);
    setTotalDias(
      momentBusinessDays(moment(date).add(2, "seconds")).businessDiff(
        moment(startDate).add(1, "seconds")
      )
    );
  };

  const addVacaciones = () => {
    setOpenAnimation(true);
    const formData = new FormData();
    formData.append(
      "fecha_hora_inicio",
      moment(startDate).format("YYYY-MM-DDTHH:mm:01")
    );
    formData.append(
      "fecha_hora_fin",
      moment(endDate).format("YYYY-MM-DDTHH:mm:02")
    );
    formData.append(
      "ultimo_dia_trabajado",
      moment(ultimoDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    formData.append(
      "primer_dia_trabajar",
      moment(primerDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    if (totalDias === 0) {
      formData.append("total_dias", 1);
    } else {
      formData.append("total_dias", totalDias);
    }
    formData.append("estado_solicitud", estadoVacaciones);
    formData.append("id_ficha_personal", professional.value);

    apiform
      .post("api/rrhh/variablesdinamicas/registros/vacaciones/", formData)
      .then(response => {
        console.log("response", response);
        setMessage("Registro guardado con éxito");
        setOpenAnimation(false);
        setOpenDialog(true);
      })
  };

  const addPermisoSinSueldo = () => {
    setOpenAnimation(true);
    let tempVariableCounter = { ...variableCounter };
    let tempEvents = [...eventsVariables];
    const formData = new FormData();
    formData.append(
      "fecha_hora_inicio",
      moment(startDate).format("YYYY-MM-DDTHH:mm:01")
    );
    formData.append(
      "fecha_hora_fin",
      moment(endDate).format("YYYY-MM-DDTHH:mm:02")
    );
    formData.append(
      "ultimo_dia_trabajado",
      moment(ultimoDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    formData.append(
      "primer_dia_trabajar",
      moment(primerDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    if (totalDias === 0) {
      formData.append("total_dias", 1);
    } else {
      formData.append("total_dias", totalDias);
    }
    formData.append("estado_solicitud", estadoPermisoSinSueldo);
    formData.append("id_ficha_personal", idFicha);

    apiform
      .post("api/rrhh/variablesdinamicas/registros/permisosinsueldo/", formData)
      .then(response => {
        tempVariableCounter.cantidad_permisos_sin_goce_sueldo =
          variableCounter.cantidad_permisos_sin_goce_sueldo + 1;
        setVariableCounter(tempVariableCounter);
        if (unpaidLeave) {
          tempEvents.push({
            title: "Permiso sin sueldo",
            allDay: true,
            start: moment(startDate)
              .add(1, "seconds")
              .toDate(),
            end: moment(endDate)
              .add(6, "hours")
              .toDate(),
            row: response.data
          });
          setEvents(tempEvents);
        }
        setTempMessage("Registro guardado con éxito");
        setPageRefresh(true);
        setOpenAnimation(false);
        setOpenDialogSucess(true);
      })
  };

  const addInasistencia = () => {
    setOpenAnimation(true);
    let tempVariableCounter = { ...variableCounter };
    let tempEvents = [...eventsVariables];
    const formData = new FormData();
    formData.append(
      "fecha_hora_inicio",
      moment(startDate).format("YYYY-MM-DDTHH:mm:01")
    );
    formData.append(
      "fecha_hora_fin",
      moment(endDate).format("YYYY-MM-DDTHH:mm:02")
    );
    formData.append(
      "ultimo_dia_trabajado",
      moment(ultimoDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    formData.append(
      "primer_dia_trabajar",
      moment(primerDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    if (totalDias === 0) {
      formData.append("total_dias", 1);
    } else {
      formData.append("total_dias", totalDias);
    }
    formData.append("id_ficha_personal", idFicha);
    formData.append("licencia", licencia);
    formData.append("amonestacion", amonestacion);
    formData.append("descuento", descuento);

    apiform
      .post("api/rrhh/variablesdinamicas/registros/inasistencias/", formData)
      .then(response => {
        tempVariableCounter.cantidad_inasistencias =
          variableCounter.cantidad_inasistencias + 1;
        setVariableCounter(tempVariableCounter);
        if (absence) {
          tempEvents.push({
            title: "Inasistencia",
            allDay: true,
            start: moment(startDate)
              .add(1, "seconds")
              .toDate(),
            end: moment(endDate)
              .add(6, "hours")
              .toDate(),
            row: response.data
          });
          setEvents(tempEvents);
        }
        setTempMessage("Registro guardado con éxito");
        setPageRefresh(true);
        setOpenAnimation(false);
        setOpenDialogSucess(true);
      })
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        {openAnimation ? (
          <ModalTitle id="customized-dialog-title">Agregar registro</ModalTitle>
        ) : (
          <ModalTitle id="customized-dialog-title" onClose={closeModal}>
            Agregar registro
          </ModalTitle>
        )}
        <DialogContent>
          <Grid container className={classes.root} justify="center">
            <Grid item container xs={12} justify="center">
              <Grid item container xs={7} justify="center">
                <Grid item xs={8}>
                  {selectTypeRecord !== "" ? (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Tipo de registro
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectTypeRecord}
                        onChange={handleSelectTypeRecord}
                        label="Tipo de registro"
                      >
                        {listRecordType.map(list => (
                          <MenuItem key={list.value} value={list.value}>
                            {list.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                      error
                    >
                      <InputLabel id="demo-simple-select-error-label">
                        Tipo de registro
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-error-label"
                        id="demo-simple-select-error"
                        value={selectTypeRecord}
                        onChange={handleSelectTypeRecord}
                      >
                        {listRecordType.map(list => (
                          <MenuItem key={list.value} value={list.value}>
                            {list.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        Debes seleccionar un tipo de registro
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
              {selectTypeRecord === "Vacaciones" ? (
                <Vacaciones
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  estadoVacaciones={estadoVacaciones}
                  handleEstadoVacaciones={handleEstadoVacaciones}
                  totalDias={totalDias}
                  ultimoDiaTrabajado={ultimoDiaTrabajado}
                  handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
                  primerDiaTrabajado={primerDiaTrabajado}
                  handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
                  professionals={professionals}
                  professional={professional}
                  setProfessional={setProfessional}
                />
              ) : null
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          {openAnimation ? (
            <CircularProgress />
          ) : (
            <div>
              {selectTypeRecord === "Vacaciones" ? (
                estadoVacaciones != "" &&
                moment(endDate) >= moment(startDate) &&
                Object.keys(professional).length > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addVacaciones}
                    style={{ textTransform: "none", marginRight: "3px" }}
                  >
                    Guardar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                    style={{ textTransform: "none", marginRight: "3px" }}
                  >
                    Guardar
                  </Button>
                )
              ) : selectTypeRecord === "PermisoSinSueldo" ? (
                moment(endDate) >= moment(startDate) ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addPermisoSinSueldo}
                    style={{ textTransform: "none", marginRight: "3px" }}
                  >
                    Guardar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                    style={{ textTransform: "none", marginRight: "3px" }}
                  >
                    Guardar
                  </Button>
                )
              ) : selectTypeRecord === "Inasistencias" ? (
                moment(endDate) >= moment(startDate) &&
                licencia != "" &&
                amonestacion != "" &&
                descuento != "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addInasistencia}
                    style={{ textTransform: "none", marginRight: "3px" }}
                  >
                    Guardar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                    style={{ textTransform: "none", marginRight: "3px" }}
                  >
                    Guardar
                  </Button>
                )
              ) : null}
              <Button
                variant="contained"
                color="primary"
                onClick={closeModal}
                style={{ textTransform: "none", marginLeft: "3px" }}
              >
                Cerrar
              </Button>
            </div>
          )}
        </DialogActions>
      </Paper>
    </Modal>
  );
};

export default ModalAddRecord;

ModalAddRecord.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.object,
  idFicha: PropTypes.string,
  setOpenDialogSucess: PropTypes.func,
  setOpenModalAddRecord: PropTypes.func,
  setTempMessage: PropTypes.func,
  variableCounter: PropTypes.object,
  setVariableCounter: PropTypes.func,
  setPageRefresh: PropTypes.func,
  eventsVariables: PropTypes.array,
  setEvents: PropTypes.func,
  extraShift: PropTypes.bool,
  extraAttention: PropTypes.bool,
  holiday: PropTypes.bool,
  unpaidLeave: PropTypes.bool,
  absence: PropTypes.bool,
  setMessage: PropTypes.func,
  setOpenDialog: PropTypes.func
};
