import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import api from "utils/API";
import { days_open } from "components/Request/ManageRequest/DaysOpen";
import moment from "moment";

const ReceptionInfo = props => {
  const {
    order,
    moduleType
  } = props;

  

  const months_open = date => {
    const today = moment(new Date()).format("DD/MM/YYYY");
    const request_date = moment(date).format("DD/MM/YYYY");
    const diff = moment(today, "DD/MM/YYYY").diff(
      moment(request_date, "DD/MM/YYYY")
    );
    const duration = moment.duration(diff);
    return duration._data.months;
  };

  const handleNoteSold = () => {
    api
      .get(
        `api/documentosbodega/notaventa/${props.id}/tipo/${
          moduleType === "storage" ? "insumos" : "medicamentos"
        }/`,
        { responseType: "blob" }
      )
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "nota_venta.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  return (
    <div className="col-xl-8 mb-3">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col" />
            <div className="col-6 text-center">Datos del pedido</div>
            <div className="col text-right">
              <span className="font-weight-bold">Estado pedido: </span>
              {order.estado_solicitud === "Esperar a egresos"
                ? "Ir a retirar pedido"
                : order.estado_solicitud}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm text-left ml-5">
              <span className="font-weight-bold">Id: </span>
              {props.id}
              <br />
              <span className="font-weight-bold">Tipo de solicitud: </span>
              {order.tipo_solicitud}
              <br />
              <span className="font-weight-bold">Paciente: </span>
              {order.nombre_paciente}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Fecha solicitud: </span>
              {moment(order.nombre_paciente).format("YYYY-MM-DD HH:mm")}
              <br />
              <span className="font-weight-bold">Fecha a despachar: </span>
              {moment(order.fecha_despacho).format("YYYY-MM-DD HH:mm")}
              <br />
              <span className="font-weight-bold">Región: </span>
              {order.es_region ? "Si" : "No"}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Estado picking: </span>
              {order.estado_picking}
              <br />
              <span className="font-weight-bold">Días de atraso: </span>
              Meses: {months_open(order.nombre_paciente)}
               /  Días:
              {days_open(order.nombre_paciente)}
              <br />
              <span className="font-weight-bold">Medicamento: </span>
              {order.tiene_medicamentos ? "Si" : "No"}
            </div>
          </div>
          <div className="row">
          <div className="col-sm text-center" style={{padding: "20px"}}>
            {order.observacion &&
            <TextField
                disabled={true}
                label={"Observaciones"}
                multiline
                rows={5}
                value={order.observacion}
                variant="outlined"
                onChange={(event) => {}}
                style={{width: "100%"}}
            />
            }
            </div>
          </div>
          <div className="row">
            <div className="col-sm text-center">
              <Link>
                <Button
                  className="mt-4"
                  variant="contained"
                  color="primary"
                  onClick={handleNoteSold}
                >
                  Ver nota de venta
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceptionInfo;
