import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { red, green, orange, indigo } from "@material-ui/core/colors";
import { Grid, TextField, Typography } from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import ModalDialog from "views/Widgets/Common/ModalDialog"; 
import MapComponent from 'views/Widgets/Common/MapComponent';
import api from "utils/API";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
  } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";


moment.locale("es");



export default function ModalAdministrativeProcess({
    open,
    handleClose,
    setChanges,
}) {

    const [markCoord, setmarkCoord] = useState({})
    const [lat, setlat] = useState("");
    const [lng, setlng] = useState("");
    const [comment, setComment] = useState("");
    const [dateSend, setdateSend] = useState(moment());
    const [title, settitle] = useState("");
    const [openModalSave, setopenModalSave] = useState("");

    
    useEffect(() => {

        if(lat && markCoord?.lat !== lat){
           const tempCoord = {...markCoord}
           tempCoord.lat = lat
           setmarkCoord(tempCoord)
        }
        if(lng && markCoord?.lng !== lng){
           const tempCoord = {...markCoord}
           tempCoord.lng = lng
           setmarkCoord(tempCoord)
        }
    }, [lat, lng]);

    useEffect(() => {
        if(markCoord.lat !== lat){
            setlat(markCoord.lat)
        }
        if(markCoord.lng !== lng){
            setlng(markCoord.lng)
        }
    }, [markCoord]);

    const handleInputChangeCoord = (value, setValue) => {
        const tempValue = value.replace(/[^-0-9.]/g, '');
        setValue(tempValue);
    };

    const handleSave = () => {
        
        const body = {
            estado_recepcion: "NoAsignado",
            tipo_solicitud: "Tramite",
            zona: "Sin Zona",
            hora_estimada: dateSend.format("YYYY-MM-DD HH:mm:ss"),
            fecha_solicitud: moment().format("YYYY-MM-DD HH:mm:ss"),
            observacion_recepcion: comment, 
            direccion_latitud: lat,
            direccion_longitud: lng, 
            comuna: "Santiago",
            titulo: title,
        }
        
        api.post('api/agendatransportes/', body).then((response) => {
            setChanges(true)
            handleClose()
        })

    }

    


    const _content_ = <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{ padding: "1em" }}
            >
                <TextField
                    label={"Titulo"}
                    input
                    variant="outlined"
                    defaultValue={title}
                    value={lat}
                    onChange={(e) => settitle(e.target.value)}
                    inputProps={{
                        maxLength: 20,
                    }}
                    style={{width: "100%"}}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={4}
                style={{ padding: "1em" }}
            >
                <TextField
                    label={"Latitud"}
                    input
                    variant="outlined"
                    defaultValue={lat}
                    value={lat}
                    onChange={(e) => handleInputChangeCoord(e.target.value, setlat)}
                    inputProps={{
                        maxLength: 20,
                    }}
                    style={{width: "100%"}}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={4}
                style={{ padding: "1em" }}
            >
                <TextField
                    label={"Longitud"}
                    variant="outlined"
                    defaultValue={lng}
                    value={lng}
                    onChange={(e) => handleInputChangeCoord(e.target.value, setlng)}
                    inputProps={{
                        maxLength: 20,
                    }}
                    style={{width: "100%"}}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={4}
                style={{ padding: "1em" }}
            >
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDateTimePicker
                        variant="inline"
                        format="DD/MM/YYYY HH:mm"
                        margin="normal"
                        label={"Fecha Tramite"}
                        placeholder="Fecha Tramite"
                        onChange={setdateSend}
                        minDate={moment()}
                        
                        value={dateSend}
                        inputVariant={"outlined"}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{ padding: "1em" }}
            >
                <TextField
                    label={"Ingresar Observaciones"}
                    multiline
                    rows={5}
                    value={comment}
                    variant="outlined"
                    onChange={(e) => setComment(e.target.value)}
                    style={{width: "100%"}}
                />
            </Grid>
        </Grid>
        <div style={{maxHeight: "400px", overflow: "hidden"}}>
            <MapComponent 
                markCoord={markCoord}
                handleClick={setmarkCoord}
            />
        </div>
    </div>

    const _actions_ = <>
        {(lat && lng && comment && title) ?
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: green[500], marginLeft: "1em"}}
            className="text-white"
            onClick={() => setopenModalSave(true)}
        >
            Guardar
        </Button> : 
        <Button
            disabled
            mr={2}
            variant="contained"
            className="text-white"
        >
            Guardar
        </Button>}
       
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500], marginLeft: "1em"}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </Button>
        </>


    const _contentInfo_ = <div>
         <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{ padding: "5px" }}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{ padding: "5px" }}
            >
                <Typography variant="body" gutterBottom>{`¿Esta seguro que desea, guardar el siguiente tramite?`}</Typography>
            </Grid>

      </Grid>
      
      
    </div>

    const _actionsInfo_ = <>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: green[500], marginLeft: "1em"}}
            className="text-white"
            onClick={() => handleSave()}
        >
            Guardar
        </Button>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500], marginLeft: "1em"}}
            className="text-white"
            onClick={() => setopenModalSave(false)}
        >
            Cancelar
        </Button>
    </>

    return (
        <>
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={"Tramite Administrativo"}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="lg"
        />
        {openModalSave &&
        <ModalDialog
            open={openModalSave}
            onClose={() => setopenModalSave(false)}
            title={"Guardar Tramite Administrativo"}
            _content_={_contentInfo_}
            _actions_ = {_actionsInfo_}
            maxWidth="sm"
        />
        }
        </>
       

  );
}