import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";
import api from "utils/API";
import apiform from "utils/APIForm";
import TurnoExtra from "./TurnoExtra";
import AtencionExtra from "./AtencionExtra";
import Vacaciones from "./Vacaciones";
import PermisoSinSueldo from "./PermisoSinSueldo";
import Inasistencia from "./Inasistencia";
import Renuncia from "./Renuncia";
import Imponible from "./Imponible";
import Colacion from "./Colacion";
import Movilizacion from "./Movilizacion";
import Guarderia from "./Guarderia";
import Bienestar from "./Bienestar";
import Anticipo from "./Anticipo";
import Despido from "./Despido";
import moment from "moment-timezone";
import AlertDialog from "components/Attendance/AlertDialog";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import "moment/locale/es";
moment.locale("es");

var momentBusinessDays = require("moment-business-days");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },

}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator={true}
      decimalSeparator={false}
      allowNegative={false}
      isNumericString
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ModalAddRecord = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    tempDateInfo,
    idFicha,
    setOpenDialogSucess,
    setTempMessage,
    variableCounter,
    setVariableCounter,
    setPageRefresh,
    eventsVariables,
    setEvents,
    extraShift,
    extraAttention,
    holiday,
    unpaidLeave,
    resignation,
    absence
  } = props;
  const dispatch = useDispatch();
  const [openAnimation, setOpenAnimation] = useState(false);
  const [startDate, setStartDate] = useState(moment(tempDateInfo));
  const [endDate, setEndDate] = useState(moment(tempDateInfo));
  const [selectTypeRecord, setSelectTypeRecord] = useState("");
  const [changes, setChanges] = useState(true);
  const [listRecordType, setListRecordType] = useState([]);
  const [abrirAlerta,setAbrirAlerta] = React.useState(false);
  const [titulo,setTitulo] = React.useState('');
  const [mensaje,setMensaje] = React.useState('');
  const [tipoAlerta,setTipoAlerta] = React.useState('');
  const [updateModal,setUpdateModal] = React.useState(false);
  // turno extra
  const [valorTurno, setValorTurno] = useState(0);
  const [valorColacion, setValorColacion] = useState(0);
  const [valorMovilizacion, setValorMovilizacion] = useState(0);
  const [valorViatico, setValorViatico] = useState(0);
  const [checkColacion, setCheckColacion] = useState(false);
  const [checkMovilizacion, setCheckMovilizacion] = useState(false);
  const [checkViatico, setCheckViatico] = useState(false);
  // end turno extra
  // atención extra
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  const [callVetos, setCallVetos] = useState(false);
  const [alertVetoe, setAlertVetoe] = useState(false);
  const [vetoes, setVetoes] = useState([]);
  // end atención extra
  // Vacaciones
  const [estadoVacaciones, setEstadoVacaciones] = useState(
    "Aprobadas jefatura"
  );
  const [ultimoDiaTrabajado, setUltimoDiaTrabajado] = useState(
    moment(tempDateInfo)
  );
  const [primerDiaTrabajado, setPrimerDiaTrabajado] = useState(
    moment(tempDateInfo)
  );
  const [totalDias, setTotalDias] = useState(
    momentBusinessDays(moment(endDate).add(2, "seconds")).businessDiff(
      moment(startDate).add(1, "seconds")
    )
  );
  // end vacaciones
  // permiso sin goce de sueldo
  const estadoPermisoSinSueldo = "Aprobado por Jefatura";
  // end permiso sin goce de sueldo
  // inasistencia
  const [licencia, setLicencia] = useState("");
  const [amonestacion, setAmonestacion] = useState("");
  const [descuento, setDescuento] = useState("");
  // end inasistencia
  // Despido

  // end Despido
  // Renuncia
  const [diasTrabajados, setDiasTrabajados] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [fechaTermino, setFechaTermino] = React.useState("");
  //end Renuncia
  // Otros Imponibles
  const [periodoCorreccionImponible, setPeriodoCorrecionImponible] = React.useState(moment(new Date()));
  const [montoImponible, setMontoImponible] = React.useState(0);
  // end imponibles
  // Otros Colacion
  const [periodoCorreccionColacion, setPeriodoCorrecionColacion] = React.useState(moment(new Date()));
  const [montoColacion, setMontoColacion] = React.useState(0);
  // end Colacion
  // Otros Movilizacion
  const [periodoCorreccionMovilizacion, setPeriodoCorrecionMovilizacion] = React.useState(moment(new Date()));
  const [montoMovilizacion, setMontoMovilizacion] = React.useState(0);
  // end Movilizacion
  // Otros Guarderia

  const [montoGuarderia, setMontoGuarderia] = React.useState(0);
  // end Guarderia
  // Otros Bienestar

  const [montoBienestar, setMontoBienestar] = React.useState(0);
  // end Bienestar
  // Otros Anticipo

  const [montoAnticipo, setMontoAnticipo] = React.useState(0);

  // end Anticipo
  const handleCerrarAlerta = (event) => {
    setAbrirAlerta(false);
  }

  const auxlistRecordType = useSelector(state => state.currentList.LISTA_TIPO_REGISTRO_VARIABLE);


  useEffect(() => {
    if (changes && auxlistRecordType) {
      initialCharge();
      getFichaPersonal();
    }
    if (callVetos) {
      initialVetos();
    }
    if(updateModal){
      setUpdateModal(false)
    }
  });

  const initialCharge = () => {
    setChanges(false);
    const arrayURL = window.location.href.split('/');
    const origen =arrayURL[4]

    const getPatients = api.get("api/paciente/activos/");

    Promise.all([getPatients])
      .then(response => {
        let tempPatients = [];
        console.log(origen)
        if(origen==='personalfile'){
          setListRecordType(auxlistRecordType);
        }else{
          const tempListType = [
            'DiasTrabajados',
            'HorasMasMenos',
            'TurnoExtra',
            'AtencionExtra',
            'Inasistencias',
            'Vacaciones',
            'PermisoSinSueldo',
          ]
          setListRecordType(auxlistRecordType.filter(row => tempListType.includes(row.value)));
        }

          response[0].data.map(patient => {
          tempPatients.push({
            value: patient.id,
            label: `${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`
          });
        });
        setPatients(tempPatients);
      })

  };


  const initialVetos = () => {
    setOpenAnimation(true);
    setCallVetos(false);

    const getVetoes = api.get(
      `api/rrhh/variablesdinamicas/personal/${idFicha}/tipo/vetos/`
    );

    Promise.all([getVetoes])
      .then(response => {
        const checkVetoe = response[0].data.valores.filter(
          filtered =>
            filtered.info_veto.id_ficha_paciente_id.id_paciente_id.id ===
            patient.value
        );
        if (checkVetoe.length > 0) {
          setAlertVetoe(true);
        } else {
          setAlertVetoe(false);
        }
        setVetoes(response[0].data.valores);
        setOpenAnimation(false);
      })

  };


  const onupload = (idRegitro) => {
    setOpenAnimation(true);
    let id_registro_variable = idRegitro
    if (files.length > 0) {
      let countArray = files.length;
      let count = 0;
      files.map(file => {
        const formData = new FormData();
        formData.append("nombre_archivo", file.name);
        formData.append("descripcion_archivo", "Licencia");
        formData.append("archivo", file);
        formData.append("estado", true);
        formData.append("id_registro_variable", id_registro_variable);
        apiform
          .post("api/documentosregistrosvariables/", formData)
          .then(() => {
            count = count + 1;
            if (count === countArray) {
              setFiles([])
            }

          })

      });
    } else {
      setFiles([])
    }

  }

  const getFichaPersonal = () => {
    const url = `/api/fichapersonal/${idFicha}`
    api.get(url
    ).then(response => {

        api.post('/api/diasnohabiles/',{
            'fecha_inicio': moment().clone().startOf('month').format('YYYY-MM-DD').toString(),
            'fecha_termino':moment(startDate).format('YYYY-MM-DD').toString()
        }).then(response => {

            var fecha_inicio = moment().clone().startOf('month')
            var fecha_termino = moment(startDate)

            var diferenciaDias =  fecha_termino.diff(fecha_inicio, 'days')
            var diasTrabajados = diferenciaDias - response.data.data
            setDiasTrabajados(diasTrabajados)

        })

    })
}

  const handleFechaInicioRenuncia = date => {
    setStartDate(date);
    setEndDate(date);
    setDiasTrabajados('')
    setChanges(true)
  };

  const handleFechaInicioDespido = date => {
    setStartDate(date);
    setEndDate(date);
    setDiasTrabajados('')
    setChanges(true)
  };

  const handleFechaImponible = date => {
    setStartDate(date);
    setEndDate(date);
    setChanges(true)
  };

  const handlePeriodoCorreccionImponible = date => {
    setPeriodoCorrecionImponible(date);
  };

  const handleFechaColacion = date => {
    setStartDate(date);
    setEndDate(date);
    setChanges(true)
  };

  const handlePeriodoCorreccionColacion = date => {
    setPeriodoCorrecionColacion(date);
  };

  const handleMontoImponible = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoImponible(new_value);
  };

  const handleMontoMovilizacion = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoMovilizacion(new_value);
  };

  const handleMontoColacion = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoColacion(new_value);
  };

  const handleMontoGuarderia = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoGuarderia(new_value);
  };

  const handleMontoBienestar = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoBienestar(new_value);
  };

  const handleMontoAnticipo = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoAnticipo(new_value);
  };

  const handleFechaMovilizacion = date => {
    setStartDate(date);
    setEndDate(date);
    setChanges(true)
  };

  const handlePeriodoCorreccionMovilizacion = date => {
    setPeriodoCorrecionMovilizacion(date);
  };

  const handleFechaGuarderia = date => {
    setStartDate(date);
    setEndDate(date);
    setChanges(true)
  };

  const handleFechaBienestar = date => {
    setStartDate(date);
    setEndDate(date);
    setChanges(true)
  };

  const handleFechaAnticipo = date => {
    setStartDate(date);
    setEndDate(date);
    setChanges(true)
  };

  const onFileChange = ( e ) => {
    let tempFiles = files;
    let tempTargetFiles = [...e.target.files];
    tempTargetFiles.map(file => {
      tempFiles.push(file);
    });
    setFiles(tempFiles);
    setUpdateModal(true)
  };

  const handleLicencia = event => {
    setLicencia(event.target.value);
  };

  const handleAmonestacion = event => {
    setAmonestacion(event.target.value);
  };

  const handleDescuento = event => {
    setDescuento(event.target.value);
  };

  const handlePatient = patient => {
    setPatient(patient);
    const checkVetoe = vetoes.filter(
      filtered =>
        filtered.info_veto.id_ficha_paciente_id.id_paciente_id.id ===
        patient.value
    );

    if (checkVetoe.length > 0) {
      setAlertVetoe(true);
    } else {
      setAlertVetoe(false);
    }
  };

  const handleUltimoDiaTrabajado = date => {
    setUltimoDiaTrabajado(date);
  };

  const handlePrimerDiaTrabajado = date => {
    setPrimerDiaTrabajado(date);
  };

  const handleEstadoVacaciones = event => {
    setEstadoVacaciones(event.target.value);
  };

  const handleCheckColacion = event => {
    setCheckColacion(event.target.checked);
  };

  const handleCheckMovilizacion = event => {
    setCheckMovilizacion(event.target.checked);
  };

  const handleCheckViatico = event => {
    setCheckViatico(event.target.checked);
  };

  const handleSelectTypeRecord = event => {
    setSelectTypeRecord(event.target.value);
    if (event.target.value === "AtencionExtra") {
      setCallVetos(true);
    }
  };

  const handleStartDate = date => {
    setStartDate(date);
    setTotalDias(
      momentBusinessDays(moment(endDate).add(2, "seconds")).businessDiff(
        moment(date).add(1, "seconds")
      )
    );
  };

  const handleEndDate = date => {
    setEndDate(date);
    setTotalDias(
      momentBusinessDays(moment(date).add(2, "seconds")).businessDiff(
        moment(startDate).add(1, "seconds")
      )
    );
  };

  const addTurnoExtra = () => {
    setOpenAnimation(true);
    let tempVariableCounter = { ...variableCounter };
    let tempEvents = [...eventsVariables];
    const formData = new FormData();
    formData.append(
      "fecha_hora_inicio",
      moment(startDate).format("YYYY-MM-DDTHH:mm:01")
    );
    formData.append(
      "fecha_hora_fin",
      moment(endDate).format("YYYY-MM-DDTHH:mm:02")
    );
    if (isNaN(valorTurno)) {
      formData.append("valor_turno", 0);
    } else {
      formData.append("valor_turno", valorTurno);
    }
    if (isNaN(valorColacion)) {
      formData.append("valor_colacion", "0");
    } else {
      formData.append("valor_colacion", valorColacion.toString());
    }
    if (isNaN(valorMovilizacion)) {
      formData.append("valor_movilizacion", "0");
    } else {
      formData.append("valor_movilizacion", valorMovilizacion.toString());
    }
    if (isNaN(valorViatico)) {
      formData.append("viatico_especial", "0");
    } else {
      formData.append("viatico_especial", valorViatico.toString());
    }
    formData.append("id_ficha_personal", idFicha);

    apiform
      .post("api/rrhh/variablesdinamicas/registros/turnosextra/", formData)
      .then(response => {
        tempVariableCounter.cantidad_turnos_extra =
          variableCounter.cantidad_turnos_extra + 1;
        setVariableCounter(tempVariableCounter);
        if (extraShift) {
          tempEvents.push({
            title: "Turno extra",
            allDay: true,
            start: moment(startDate)
              .add(30, "seconds")
              .toDate(),
            end: moment(endDate)
              .add(30, "minutes")
              .toDate(),
            row: response.data
          });
          setEvents(tempEvents);
        }
        setTempMessage("Turno extra guardado con éxito");
        setPageRefresh(true);
        setOpenAnimation(false);
        setOpenDialogSucess(true);
      })

  };

  const addAtencionExtra = () => {
    setOpenAnimation(true);
    let tempVariableCounter = { ...variableCounter };
    let tempEvents = [...eventsVariables];
    const formData = new FormData();
    formData.append(
      "fecha_hora_inicio",
      moment(startDate).format("YYYY-MM-DDTHH:mm:01")
    );
    formData.append(
      "fecha_hora_fin",
      moment(endDate).format("YYYY-MM-DDTHH:mm:02")
    );
    if (isNaN(valorTurno)) {
      formData.append("valor_turno", 0);
    } else {
      formData.append("valor_turno", valorTurno);
    }
    if (isNaN(valorColacion)) {
      formData.append("valor_colacion", "0");
    } else {
      formData.append("valor_colacion", valorColacion.toString());
    }
    if (isNaN(valorMovilizacion)) {
      formData.append("valor_movilizacion", "0");
    } else {
      formData.append("valor_movilizacion", valorMovilizacion.toString());
    }
    if (isNaN(valorViatico)) {
      formData.append("viatico_especial", "0");
    } else {
      formData.append("viatico_especial", valorViatico.toString());
    }
    formData.append("id_ficha_personal", idFicha);
    formData.append("id_paciente_id", patient.value);

    apiform
      .post("api/rrhh/variablesdinamicas/registros/atencionextra/", formData)
      .then(response => {
        tempVariableCounter.cantidad_atencion_extra =
          variableCounter.cantidad_atencion_extra + 1;
        setVariableCounter(tempVariableCounter);
        if (extraAttention) {
          tempEvents.push({
            title: "Atención extra",
            allDay: true,
            start: moment(startDate)
              .add(30, "seconds")
              .toDate(),
            end: moment(endDate)
              .add(30, "minutes")
              .toDate(),
            row: response.data
          });
          setEvents(tempEvents);
        }
        setTempMessage("Atención extra guardado con éxito");
        setPageRefresh(true);
        setOpenAnimation(false);
        setOpenDialogSucess(true);
      })

  };

  const addVacaciones = () => {
    setOpenAnimation(true);
    let tempVariableCounter = { ...variableCounter };
    let tempEvents = [...eventsVariables];
    const formData = new FormData();
    formData.append(
      "fecha_hora_inicio",
      moment(startDate).format("YYYY-MM-DDTHH:mm:01")
    );
    formData.append(
      "fecha_hora_fin",
      moment(endDate).format("YYYY-MM-DDTHH:mm:02")
    );
    formData.append(
      "ultimo_dia_trabajado",
      moment(ultimoDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    formData.append(
      "primer_dia_trabajar",
      moment(primerDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    if (totalDias === 0) {
      formData.append("total_dias", 1);
    } else {
      formData.append("total_dias", totalDias);
    }
    formData.append("estado_solicitud", estadoVacaciones);
    formData.append("id_ficha_personal", idFicha);

    apiform
      .post("api/rrhh/variablesdinamicas/registros/vacaciones/", formData)
      .then(response => {
        tempVariableCounter.cantidad_vacaciones_tomadas =
          variableCounter.cantidad_vacaciones_tomadas + 1;
        setVariableCounter(tempVariableCounter);
        tempEvents.push({
          title: "Vacación",
          allDay: true,
          start: moment(startDate)
            .add(30, "seconds")
            .toDate(),
          end: moment(endDate)
            .add(30, "minutes")
            .toDate(),
          row: response.data
        });
        setEvents(tempEvents);
        setPageRefresh(true);
        setOpenAnimation(false);
        setTitulo("¡Exito!");
        setTipoAlerta("success");
        setMensaje("¡El registro se ha guardado con exito!");
        setAbrirAlerta(true);
      })

  };

  const addPermisoSinSueldo = () => {
    setOpenAnimation(true);
    let tempVariableCounter = { ...variableCounter };
    let tempEvents = [...eventsVariables];
    const formData = new FormData();
    formData.append(
      "fecha_hora_inicio",
      moment(startDate).format("YYYY-MM-DDTHH:mm:01")
    );
    formData.append(
      "fecha_hora_fin",
      moment(endDate).format("YYYY-MM-DDTHH:mm:02")
    );
    formData.append(
      "ultimo_dia_trabajado",
      moment(ultimoDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    formData.append(
      "primer_dia_trabajar",
      moment(primerDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    if (totalDias === 0) {
      formData.append("total_dias", 1);
    } else {
      formData.append("total_dias", totalDias);
    }
    formData.append("estado_solicitud", estadoPermisoSinSueldo);
    formData.append("id_ficha_personal", idFicha);

    apiform
      .post("api/rrhh/variablesdinamicas/registros/permisosinsueldo/", formData)
      .then(response => {
        tempVariableCounter.cantidad_permisos_sin_goce_sueldo =
          variableCounter.cantidad_permisos_sin_goce_sueldo + 1;
        setVariableCounter(tempVariableCounter);

        tempEvents.push({
          title: "Permiso sin sueldo",
          allDay: true,
          start: moment(startDate)
            .add(30, "seconds")
            .toDate(),
          end: moment(endDate)
            .add(30, "minutes")
            .toDate(),
          row: response.data
        });

        setEvents(tempEvents);
        setPageRefresh(true);
        setOpenAnimation(false);
        setTitulo("¡Exito!");
        setTipoAlerta("success");
        setMensaje("¡El registro se ha guardado con exito!");
        setAbrirAlerta(true);
      })

  };

  const addInasistencia = () => {
    setOpenAnimation(true);
    let tempVariableCounter = { ...variableCounter };
    let tempEvents = [...eventsVariables];
    const formData = new FormData();
    formData.append(
      "fecha_hora_inicio",
      moment(startDate).format("YYYY-MM-DDTHH:mm:01")
    );
    formData.append(
      "fecha_hora_fin",
      moment(endDate).format("YYYY-MM-DDTHH:mm:02")
    );
    formData.append(
      "ultimo_dia_trabajado",
      moment(ultimoDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    formData.append(
      "primer_dia_trabajar",
      moment(primerDiaTrabajado).format("YYYY-MM-DDTHH:mm:ss")
    );
    if (totalDias === 0) {
      formData.append("total_dias", 1);
    } else {
      formData.append("total_dias", totalDias);
    }
    formData.append("id_ficha_personal", idFicha);
    formData.append("licencia", licencia);
    formData.append("amonestacion", amonestacion);
    formData.append("descuento", descuento);

    apiform
      .post("api/rrhh/variablesdinamicas/registros/inasistencias/", formData)
      .then(response => {
        tempVariableCounter.cantidad_inasistencias = variableCounter.cantidad_inasistencias + 1;
        setVariableCounter(tempVariableCounter);
        tempEvents.push({
          title: "Inasistencia",
          allDay: true,
          start: moment(startDate)
            .add(30, "seconds")
            .toDate(),
          end: moment(endDate)
            .add(30, "minutes")
            .toDate(),
          row: response.data
        });
        setPageRefresh(true);
        setOpenAnimation(false);
        setTitulo("¡Exito!");
        setTipoAlerta("success");
        setMensaje("¡El registro se ha guardado con exito!");
        setAbrirAlerta(true);
      })

  };
  const addRenuncia = () => {
    setOpenAnimation(true);
    let tempVariableCounter = { ...variableCounter };
    let tempEvents = [...eventsVariables];

    var fecha_hora_fin = moment(startDate).add(30, 'minutes')
    const formData = new FormData();
    formData.append(
        "fecha_hora_inicio",
        moment(startDate).format('YYYY-MM-DDTh:mm:ss').toString()
    );
    formData.append(
        "fecha_hora_fin",
        moment(fecha_hora_fin,"YYYY-MM-DD h:mm:ss").format('YYYY-MM-DDTh:mm:ss').toString()
    );
    formData.append(
        "tipo_registro",
        "Renuncia"
    );
    formData.append(
        "id_ficha_personal",
        idFicha
    );
    formData.append(
        "fecha_inicio_renuncia",
        moment(startDate).format("YYYY-MM-DDTHH:mm:ss")
    );
    formData.append(
        "dias_trabajados",
        diasTrabajados
    );
    apiform.post("api/rrhh/variablesdinamicas/registros/renuncia/", formData)
    .then(response => {

      tempVariableCounter.cantidad_renuncia = variableCounter.cantidad_renuncia + 1;
      setVariableCounter(tempVariableCounter);

      tempEvents.push({
        title: "Renuncia",
        allDay: true,
        start: moment(startDate)
          .add(30, "seconds")
          .toDate(),
        end: moment(startDate)
          .add(30, "minutes")
          .toDate(),
        row: response.data
      });
      let idRegistro = response.data.id
      onupload(idRegistro)
      setEvents(tempEvents);
      setPageRefresh(true);
      setOpenAnimation(false);
      setTitulo("¡Exito!");
      setTipoAlerta("success");
      setMensaje("¡El registro se ha guardado con exito!");
      setAbrirAlerta(true);
    })
    .catch(error =>{ console.log("ERROR", error)});
};
const addDespido = () => {
  setOpenAnimation(true);
  let tempVariableCounter = { ...variableCounter };
  let tempEvents = [...eventsVariables];
  var fecha_hora_fin = moment(startDate).add(30, 'minutes')
  const formData = new FormData();
  formData.append(
      "fecha_hora_inicio",
      moment(startDate).format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "fecha_hora_fin",
      moment(fecha_hora_fin,"YYYY-MM-DD h:mm:ss").format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "tipo_registro",
      "Renuncia"
  );
  formData.append(
      "id_ficha_personal",
      idFicha
  );
  formData.append(
      "fecha_inicio_despido",
      moment(startDate).format("YYYY-MM-DDTHH:mm:ss")
  );
  formData.append(
      "dias_trabajados",
      diasTrabajados
  );
  apiform.post("api/rrhh/variablesdinamicas/registros/despido/", formData)
  .then(response => {
    tempVariableCounter.cantidad_despido =
    variableCounter.cantidad_despido + 1;
    setVariableCounter(tempVariableCounter);
    tempEvents.push({
      title: "Despido",
      allDay: true,
      start: moment(startDate)
        .add(30, "seconds")
        .toDate(),
      end: moment(startDate)
        .add(30, "minutes")
        .toDate(),
      row: response.data
    });
    let idRegistro = response.data.id
    onupload(idRegistro)
    setEvents(tempEvents);
    setPageRefresh(true);
    setOpenAnimation(false);
    setTitulo("¡Exito!");
    setTipoAlerta("success");
    setMensaje("¡El registro se ha guardado con exito!");
    setAbrirAlerta(true);
  })
  .catch(error => {{}});
};
const addImponible = () => {
  setOpenAnimation(true);
  let tempVariableCounter = { ...variableCounter };
  let tempEvents = [...eventsVariables];

  var fecha_hora_fin = moment(startDate).add(30, 'minutes')
  const formData = new FormData();
  formData.append(
      "fecha_hora_inicio",
      moment(startDate).format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "fecha_hora_fin",
      moment(fecha_hora_fin,"YYYY-MM-DD h:mm:ss").format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "tipo_registro",
      "Imponible"
  );
  formData.append(
      "id_ficha_personal",
      idFicha
  );
  formData.append(
      "fecha_registro_imponible",
      moment(startDate).format("YYYY-MM-DDTHH:mm:ss")
  );
  formData.append(
      "monto_imponible",
      montoImponible
  );
  formData.append(
      "periodo_correcion_imponible",
      periodoCorreccionImponible
  );
  apiform.post("api/rrhh/variablesdinamicas/registros/imponible/", formData)
  .then(response => {
    tempVariableCounter.cantidad_imponible = variableCounter.cantidad_imponible + 1;
    setVariableCounter(tempVariableCounter);
    tempEvents.push({
      title: "Imponible",
      allDay: true,
      start: moment(startDate)
        .add(30, "seconds")
        .toDate(),
      end: moment(startDate)
        .add(30, "minutes")
        .toDate(),
      row: response.data
    });
    let idRegistro = response.data.id
    onupload(idRegistro)
    setEvents(tempEvents);
    setPageRefresh(true);
    setOpenAnimation(false);
    setTitulo("¡Exito!");
    setTipoAlerta("success");
    setMensaje("¡El registro se ha guardado con exito!");
    setAbrirAlerta(true);
  })
  .catch(error => console.log("ERROR", error));
};
const addColacion = () => {
  setOpenAnimation(true);
  let tempVariableCounter = { ...variableCounter };
  let tempEvents = [...eventsVariables];

  var fecha_hora_fin = moment(startDate).add(30, 'minutes')
  const formData = new FormData();
  formData.append(
      "fecha_hora_inicio",
      moment(startDate).format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "fecha_hora_fin",
      moment(fecha_hora_fin,"YYYY-MM-DD h:mm:ss").format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "tipo_registro",
      "Colacion"
  );
  formData.append(
      "id_ficha_personal",
      idFicha
  );
  formData.append(
      "fecha_registro_colacion",
      moment(startDate).format("YYYY-MM-DDTHH:mm:ss")
  );
  formData.append(
      "monto_colacion",
      montoColacion
  );
  formData.append(
      "periodo_correcion_colacion",
      periodoCorreccionColacion
  );
  apiform.post("api/rrhh/variablesdinamicas/registros/colacion/", formData)
  .then(response => {
    tempVariableCounter.cantidad_colacion = variableCounter.cantidad_colacion + 1;
    setVariableCounter(tempVariableCounter);
    tempEvents.push({
      title: "Colacion",
      allDay: true,
      start: moment(startDate)
        .add(30, "seconds")
        .toDate(),
      end: moment(startDate)
        .add(30, "minutes")
        .toDate(),
      row: response.data
    });
    let idRegistro = response.data.id
    onupload(idRegistro)
    setEvents(tempEvents);
    setPageRefresh(true);
    setOpenAnimation(false);
    setTitulo("¡Exito!");
    setTipoAlerta("success");
    setMensaje("¡El registro se ha guardado con exito!");
    setAbrirAlerta(true);
  })
  .catch(error => console.log("ERROR", error));
};
const addMovilizacion = () => {
  setOpenAnimation(true);
  let tempVariableCounter = { ...variableCounter };
  let tempEvents = [...eventsVariables];

  var fecha_hora_fin = moment(startDate).add(30, 'minutes')
  const formData = new FormData();
  formData.append(
      "fecha_hora_inicio",
      moment(startDate).format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "fecha_hora_fin",
      moment(fecha_hora_fin,"YYYY-MM-DD h:mm:ss").format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "tipo_registro",
      "Movilizacion"
  );
  formData.append(
      "id_ficha_personal",
      idFicha
  );
  formData.append(
      "fecha_registro_movilizacion",
      moment(startDate).format("YYYY-MM-DDTHH:mm:ss")
  );
  formData.append(
      "monto_movilizacion",
      montoMovilizacion
  );
  formData.append(
      "periodo_correcion_movilizacion",
      periodoCorreccionMovilizacion
  );
  apiform.post("api/rrhh/variablesdinamicas/registros/movilizacion/", formData)
  .then(response => {
    tempVariableCounter.cantidad_movilizacion = variableCounter.cantidad_movilizacion + 1;
    setVariableCounter(tempVariableCounter);
    tempEvents.push({
      title: "Movilizacion",
      allDay: true,
      start: moment(startDate)
        .add(30, "seconds")
        .toDate(),
      end: moment(startDate)
        .add(30, "minutes")
        .toDate(),
      row: response.data
    });
    let idRegistro = response.data.id
    onupload(idRegistro)
    setEvents(tempEvents);
    setPageRefresh(true);
    setOpenAnimation(false);
    setTitulo("¡Exito!");
    setTipoAlerta("success");
    setMensaje("¡El registro se ha guardado con exito!");
    setAbrirAlerta(true);
  })
  .catch(error => console.log("ERROR", error));
};
const addGuarderia = () => {
  setOpenAnimation(true);
  let tempVariableCounter = { ...variableCounter };
  let tempEvents = [...eventsVariables];

  var fecha_hora_fin = moment(startDate).add(30, 'minutes')
  const formData = new FormData();
  formData.append(
      "fecha_hora_inicio",
      moment(startDate).format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "fecha_hora_fin",
      moment(fecha_hora_fin,"YYYY-MM-DD h:mm:ss").format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "tipo_registro",
      "Guarderia"
  );
  formData.append(
      "id_ficha_personal",
      idFicha
  );
  formData.append(
      "fecha_registro_guarderia",
      moment(startDate).format("YYYY-MM-DDTHH:mm:ss")
  );
  formData.append(
      "monto_guarderia",
      montoGuarderia
  );

  apiform.post("api/rrhh/variablesdinamicas/registros/guarderia/", formData)
  .then(response => {
    tempVariableCounter.cantidad_guarderia = variableCounter.cantidad_guarderia + 1;
    setVariableCounter(tempVariableCounter);
    tempEvents.push({
      title: "Guarderia",
      allDay: true,
      start: moment(startDate)
        .add(30, "seconds")
        .toDate(),
      end: moment(startDate)
        .add(30, "minutes")
        .toDate(),
      row: response.data
    });
    let idRegistro = response.data.id
    onupload(idRegistro)
    setEvents(tempEvents);
    setPageRefresh(true);
    setOpenAnimation(false);
    setTitulo("¡Exito!");
    setTipoAlerta("success");
    setMensaje("¡El registro se ha guardado con exito!");
    setAbrirAlerta(true);
  })
  .catch(error => console.log("ERROR", error));
};
const addBienestar = () => {
  setOpenAnimation(true);
  let tempVariableCounter = { ...variableCounter };
  let tempEvents = [...eventsVariables];

  var fecha_hora_fin = moment(startDate).add(30, 'minutes')
  const formData = new FormData();
  formData.append(
      "fecha_hora_inicio",
      moment(startDate).format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "fecha_hora_fin",
      moment(fecha_hora_fin,"YYYY-MM-DD h:mm:ss").format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "tipo_registro",
      "Bienestar"
  );
  formData.append(
      "id_ficha_personal",
      idFicha
  );
  formData.append(
      "fecha_registro_bienestar",
      moment(startDate).format("YYYY-MM-DDTHH:mm:ss")
  );
  formData.append(
      "monto_bienestar",
      montoBienestar
  );

  apiform.post("api/rrhh/variablesdinamicas/registros/bienestar/", formData)
  .then(response => {
    tempVariableCounter.cantidad_bienestar = variableCounter.cantidad_bienestar + 1;
    setVariableCounter(tempVariableCounter);
    tempEvents.push({
      title: "Bienestar",
      allDay: true,
      start: moment(startDate)
        .add(30, "seconds")
        .toDate(),
      end: moment(startDate)
        .add(30, "minutes")
        .toDate(),
      row: response.data
    });
    let idRegistro = response.data.id
    onupload(idRegistro)
    setEvents(tempEvents);
    setPageRefresh(true);
    setOpenAnimation(false);
    setTitulo("¡Exito!");
    setTipoAlerta("success");
    setMensaje("¡El registro se ha guardado con exito!");
    setAbrirAlerta(true);
  })
  .catch(error => console.log("ERROR", error));
};
const addAnticipo = () => {
  setOpenAnimation(true);
  let tempVariableCounter = { ...variableCounter };
  let tempEvents = [...eventsVariables];

  var fecha_hora_fin = moment(startDate).add(30, 'minutes')
  const formData = new FormData();
  formData.append(
      "fecha_hora_inicio",
      moment(startDate).format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "fecha_hora_fin",
      moment(fecha_hora_fin,"YYYY-MM-DD h:mm:ss").format('YYYY-MM-DDTh:mm:ss').toString()
  );
  formData.append(
      "tipo_registro",
      "Anticipo"
  );
  formData.append(
      "id_ficha_personal",
      idFicha
  );
  formData.append(
      "fecha_registro_anticipo",
      moment(startDate).format("YYYY-MM-DDTHH:mm:ss")
  );
  formData.append(
      "monto_anticipo",
      montoAnticipo
  );

  apiform.post("api/rrhh/variablesdinamicas/registros/anticipo/", formData)
  .then(response => {
    tempVariableCounter.cantidad_anticipo = variableCounter.cantidad_anticipo + 1;
    setVariableCounter(tempVariableCounter);
    tempEvents.push({
      title: "Anticipo",
      allDay: true,
      start: moment(startDate)
        .add(30, "seconds")
        .toDate(),
      end: moment(startDate)
        .add(30, "minutes")
        .toDate(),
      row: response.data
    });
    let idRegistro = response.data.id
    onupload(idRegistro)
    setEvents(tempEvents);
    setPageRefresh(true);
    setOpenAnimation(false);
    setTitulo("¡Exito!");
    setTipoAlerta("success");
    setMensaje("¡El registro se ha guardado con exito!");
    setAbrirAlerta(true);
  })
  .catch(error => console.log("ERROR", error));
};

  const _content_ = <Grid container justify="center">
  <Grid item container xs={12} justify="center">
    <Grid item container xs={7} justify="center">
      <Grid item xs={8}>
        {selectTypeRecord !== "" ? (
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo de registro
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectTypeRecord}
              onChange={handleSelectTypeRecord}
              label="Tipo de registro"
            >
              {listRecordType.map(list => (
                <MenuItem key={list.value} value={list.value}>
                  {list.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.formControl}
            error
          >
            <InputLabel id="demo-simple-select-error-label">
              Tipo de registro
            </InputLabel>
            <Select
              labelId="demo-simple-select-error-label"
              id="demo-simple-select-error"
              value={selectTypeRecord}
              onChange={handleSelectTypeRecord}
            >
              {listRecordType.map(list => (
                <MenuItem key={list.value} value={list.value}>
                  {list.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Debes seleccionar un tipo de registro
            </FormHelperText>
          </FormControl>
        )}
      </Grid>
    </Grid>
    {selectTypeRecord === "TurnoExtra" ? (
      <TurnoExtra
        startDate={startDate}
        handleStartDate={handleStartDate}
        endDate={endDate}
        handleEndDate={handleEndDate}
        valorTurno={valorTurno}
        setValorTurno={setValorTurno}
        valorColacion={valorColacion}
        setValorColacion={setValorColacion}
        valorMovilizacion={valorMovilizacion}
        setValorMovilizacion={setValorMovilizacion}
        valorViatico={valorViatico}
        setValorViatico={setValorViatico}
        NumberFormatCustom={NumberFormatCustom}
        checkColacion={checkColacion}
        handleCheckColacion={handleCheckColacion}
        checkMovilizacion={checkMovilizacion}
        handleCheckMovilizacion={handleCheckMovilizacion}
        checkViatico={checkViatico}
        handleCheckViatico={handleCheckViatico}
      />
    ) : selectTypeRecord === "AtencionExtra" ? (
      <AtencionExtra
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        valorTurno={valorTurno}
        setValorTurno={setValorTurno}
        valorColacion={valorColacion}
        setValorColacion={setValorColacion}
        valorMovilizacion={valorMovilizacion}
        setValorMovilizacion={setValorMovilizacion}
        valorViatico={valorViatico}
        setValorViatico={setValorViatico}
        NumberFormatCustom={NumberFormatCustom}
        checkColacion={checkColacion}
        handleCheckColacion={handleCheckColacion}
        checkMovilizacion={checkMovilizacion}
        handleCheckMovilizacion={handleCheckMovilizacion}
        checkViatico={checkViatico}
        handleCheckViatico={handleCheckViatico}
        patients={patients}
        patient={patient}
        handlePatient={handlePatient}
        alertVetoe={alertVetoe}
      />
    ) : selectTypeRecord === "Vacaciones" ? (
      <Vacaciones
        startDate={startDate}
        handleStartDate={handleStartDate}
        endDate={endDate}
        handleEndDate={handleEndDate}
        estadoVacaciones={estadoVacaciones}
        handleEstadoVacaciones={handleEstadoVacaciones}
        totalDias={totalDias}
        ultimoDiaTrabajado={ultimoDiaTrabajado}
        handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
        primerDiaTrabajado={primerDiaTrabajado}
        handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
      />
    ) : selectTypeRecord === "PermisoSinSueldo" ? (
      <PermisoSinSueldo
        startDate={startDate}
        handleStartDate={handleStartDate}
        endDate={endDate}
        handleEndDate={handleEndDate}
        estadoPermisoSinSueldo={estadoPermisoSinSueldo}
        totalDias={totalDias}
        ultimoDiaTrabajado={ultimoDiaTrabajado}
        handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
        primerDiaTrabajado={primerDiaTrabajado}
        handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
      />
    ) : selectTypeRecord === "Inasistencias" ? (
      <Inasistencia
        startDate={startDate}
        handleStartDate={handleStartDate}
        endDate={endDate}
        handleEndDate={handleEndDate}
        estadoPermisoSinSueldo={estadoPermisoSinSueldo}
        totalDias={totalDias}
        ultimoDiaTrabajado={ultimoDiaTrabajado}
        handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
        primerDiaTrabajado={primerDiaTrabajado}
        handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
        licencia={licencia}
        handleLicencia={handleLicencia}
        amonestacion={amonestacion}
        handleAmonestacion={handleAmonestacion}
        descuento={descuento}
        handleDescuento={handleDescuento}
      />
    ): selectTypeRecord === "Renuncia" ? (
      <Renuncia
        idFicha={idFicha}
        startDate={startDate}
        handleStartDate={handleStartDate}
        handleAgregar={addRenuncia}
        handleFechaInicioRenuncia={handleFechaInicioRenuncia}
        fechaTermino={startDate}
        diasTrabajados={diasTrabajados}
        setFechaTermino={setFechaTermino}
        setDiasTrabajados={setDiasTrabajados}
        onFileChange={onFileChange}
        files={files}
        onupload={onupload}
        setUpdateModal={setUpdateModal}
        setFiles={setFiles}
      />
    ) : selectTypeRecord === "Despido" ? (
      <Despido
        idFicha={idFicha}
        startDate={startDate}
        handleStartDate={handleStartDate}
        handleAgregar={addDespido}
        handleFechaInicioDespido={handleFechaInicioDespido}
        fechaTermino={startDate}
        diasTrabajados={diasTrabajados}
        setFechaTermino={setFechaTermino}
        setDiasTrabajados={setDiasTrabajados}
        onFileChange={onFileChange}
        files={files}
        onupload={onupload}
        setUpdateModal={setUpdateModal}
        setFiles={setFiles}
      />
    ) : selectTypeRecord === "Imponible" ? (
      <Imponible
        periodoCorreccionImponible={periodoCorreccionImponible}
        startDate={startDate}
        handleStartDate={handleStartDate}
        handleAgregar={addImponible}
        handleFechaImponible={handleFechaImponible}
        handlePeriodoCorreccionImponible={handlePeriodoCorreccionImponible}
        montoImponible={montoImponible}
        handleMontoImponible={handleMontoImponible}
        onFileChange ={onFileChange}
        files={files}
        onupload={onupload}
        setUpdateModal={setUpdateModal}
        setFiles={setFiles}
      />
    ) :selectTypeRecord === "Colacion" ? (
      <Colacion
        periodoCorreccionColacion={periodoCorreccionColacion}
        startDate={startDate}
        handleStartDate={handleStartDate}
        handleAgregar={addColacion}
        handleFechaColacion={handleFechaColacion}
        handlePeriodoCorreccionColacion={handlePeriodoCorreccionColacion}
        montoColacion={montoColacion}
        handleMontoColacion={handleMontoColacion}
        onFileChange={onFileChange}
        files={files}
        onupload={onupload}
        setUpdateModal={setUpdateModal}
        setFiles={setFiles}
      />
    ) : selectTypeRecord === "Movilizacion" ? (
      <Movilizacion
        periodoCorreccionMovilizacion={periodoCorreccionMovilizacion}
        startDate={startDate}
        handleStartDate={handleStartDate}
        handleAgregar={addMovilizacion}
        handleFechaMovilizacion={handleFechaMovilizacion}
        handlePeriodoCorreccionMovilizacion={handlePeriodoCorreccionMovilizacion}
        montoMovilizacion={montoMovilizacion}
        handleMontoMovilizacion={handleMontoMovilizacion}
        onFileChange={onFileChange}
        files={files}
        onupload={onupload}
        setUpdateModal={setUpdateModal}
        setFiles={setFiles}
      />
    )  : selectTypeRecord === "Guarderia" ? (
      <Guarderia
        startDate={startDate}
        handleStartDate={handleStartDate}
        handleAgregar={addGuarderia}
        handleFechaGuarderia={handleFechaGuarderia}
        montoGuarderia={montoGuarderia}
        handleMontoGuarderia={handleMontoGuarderia}
        onFileChange={onFileChange}
        files={files}
        onupload={onupload}
        setUpdateModal={setUpdateModal}
        setFiles={setFiles}
      />
    )  : selectTypeRecord === "Bienestar" ? (
      <Bienestar
        startDate={startDate}
        handleStartDate={handleStartDate}
        handleAgregar={addBienestar}
        handleFechaBienestar={handleFechaBienestar}
        montoBienestar={montoBienestar}
        handleMontoBienestar={handleMontoBienestar}
        onFileChange={onFileChange}
        files={files}
        onupload={onupload}
        setUpdateModal={setUpdateModal}
        setFiles={setFiles}
      />
    )  : selectTypeRecord === "Anticipo" ? (
      <Anticipo
        startDate={startDate}
        updateModal={updateModal}
        handleStartDate={handleStartDate}
        handleAgregar={addAnticipo}
        handleFechaAnticipo={handleFechaAnticipo}
        montoAnticipo={montoAnticipo}
        handleMontoAnticipo={handleMontoAnticipo}
        onFileChange={onFileChange}
        files={files}
        setFiles={setFiles}
        onupload={onupload}
        setUpdateModal={setUpdateModal}
      />
    )  :  null}

  </Grid>
  <AlertDialog openDialog={abrirAlerta} handleClose={handleCerrarAlerta} message={mensaje} title={titulo} severity={tipoAlerta} />
  </Grid>

  const _actions_ = <>
      {openAnimation ? (
      <CircularProgress />
    ) : (
      <div>
        {selectTypeRecord === "TurnoExtra" ? (
          valorTurno > 0 && moment(endDate) >= moment(startDate) ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addTurnoExtra}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "AtencionExtra" ? (
          valorTurno > 0 &&
          moment(endDate) >= moment(startDate) &&
          Object.keys(patient).length > 0 &&
          alertVetoe === false ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addAtencionExtra}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "Vacaciones" ? (
          estadoVacaciones != "" &&
          moment(endDate) >= moment(startDate) ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addVacaciones}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "PermisoSinSueldo" ? (
          moment(endDate) >= moment(startDate) ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addPermisoSinSueldo}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "Inasistencias" ? (
          moment(endDate) >= moment(startDate) &&
          licencia != "" &&
          amonestacion != "" &&
          descuento != "" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addInasistencia}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "Renuncia" ? (
          moment(endDate) >= moment(startDate) &&
          diasTrabajados!="" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addRenuncia}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "Despido" ? (
          moment(endDate) >= moment(startDate) &&
          diasTrabajados!="" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addDespido}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "Imponible" ? (
          moment(endDate) >= moment(startDate) &&
          montoImponible!=0 && periodoCorreccionImponible !='' ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addImponible}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "Colacion" ? (
          moment(endDate) >= moment(startDate) &&
          montoColacion!=0 && periodoCorreccionColacion !='' ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addColacion}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "Movilizacion" ? (
          moment(endDate) >= moment(startDate) &&
          montoMovilizacion!=0 && periodoCorreccionMovilizacion!='' ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addMovilizacion}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "Guarderia" ? (
          moment(endDate) >= moment(startDate) &&
          montoGuarderia!=0  ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addGuarderia}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "Bienestar" ? (
          moment(endDate) >= moment(startDate) &&
          montoBienestar!=0  ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addBienestar}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : selectTypeRecord === "Anticipo" ? (
          moment(endDate) >= moment(startDate) &&
          montoAnticipo!=0  ? (
            <Button
              variant="contained"
              color="primary"
              onClick={addAnticipo}
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled
              style={{ textTransform: "none", marginRight: "3px" }}
            >
              Guardar
            </Button>
          )
        ) : null}

        <Button
          variant="contained"
          color="primary"
          onClick={closeModal}
          style={{ textTransform: "none", marginLeft: "3px" }}
        >
          Cerrar
        </Button>
      </div>
    )}
  </>

  return (
    <ModalDialog
      open={openModal}
      onClose={closeModal}
      title={"Agregar registro"}
      _content_={_content_}
      _actions_ = {_actions_}
      maxWidth="xl"
    />
  );
};

export default ModalAddRecord;

ModalAddRecord.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.object,
  idFicha: PropTypes.string,
  setOpenDialogSucess: PropTypes.func,
  setOpenModalAddRecord: PropTypes.func,
  setTempMessage: PropTypes.func,
  variableCounter: PropTypes.object,
  setVariableCounter: PropTypes.func,
  setPageRefresh: PropTypes.func,
  eventsVariables: PropTypes.array,
  setEvents: PropTypes.func,
  extraShift: PropTypes.bool,
  extraAttention: PropTypes.bool,
  holiday: PropTypes.bool,
  unpaidLeave: PropTypes.bool,
  absence: PropTypes.bool
};
