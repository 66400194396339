import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import apiform from "utils/APIForm";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "80%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },

}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalAddAuthorization = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    tempRow,
    setauxData,
    dataArray,
    finishArray,
    setFinishArray
  } = props;

  const [approvalFile, setApprovalFile] = useState(null);
  const [transferPrice, setTransferPrice] = useState(0);
  const getIndex = dataArray.findIndex(row => row.id === tempRow.id);


  const uploadAuthorization = (check=true) => {

    const formData = new FormData();
    const requestList = []
    requestList.push(api.patch(`api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/traslados/${tempRow.id_solicitud_paciente.id}/`,{valor_traslado: transferPrice, autorizacion:check}))
    if (check){
      formData.append("archivo", approvalFile[0]);
      formData.append("tipo_documento", "AutorizacionExamenesIsapre");
      formData.append("nombre_archivo", approvalFile[0].name);
      formData.append("id_paciente", parseInt(tempRow.id_ficha.id_paciente.id));
      formData.append("id_solicitud_paciente", parseInt(tempRow.id_solicitud_paciente.id));
      requestList.push(apiform.post(`/api/paciente/${tempRow.id_ficha.id_paciente.id}/solicitudes/traslados/${tempRow.id_solicitud_paciente.id}/documentos/autorizacion_traslado/subir/`, formData))
    }
    
    Promise.all(requestList).then(() => {
      let tempArray = dataArray;
      let tempFinish = finishArray;
      tempArray[getIndex].estado = "Finalizado";
      tempFinish.push(tempArray[getIndex]);
      setFinishArray(tempFinish);
      tempArray.splice(getIndex, 1);
      setauxData(tempArray);
      closeModal();
    });
  };

  const rejectRequest= () => {

    api.delete(`api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/traslados/${tempRow.id_solicitud_paciente.id}/cancelar/`,{}).then(() => {
      let tempArray = dataArray;
      let tempFinish = finishArray;
      tempArray[getIndex].estado = "Finalizado";
      tempFinish.push(tempArray[getIndex]);
      setFinishArray(tempFinish);
      tempArray.splice(getIndex, 1);
      setauxData(tempArray);
      closeModal();
    })

  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        <ModalTitle id="customized-dialog-title" onClose={closeModal}>
          Adjuntar autorización de traslado
        </ModalTitle>
        <DialogContent>
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ marginTop: "1em" }}
          >
            <Grid item container xs={12} justify="center">
              {approvalFile != null ? (
                approvalFile[0].name
              ) : (
                <span></span>
              )}
            </Grid>
            <input
              id="contained-button-file-isapre"
              type="file"
              onChange={e => setApprovalFile(e.target.files)}
              hidden
            />
            <label htmlFor="contained-button-file-isapre">
              <Button
                variant="contained"
                color="primary"
                component="span"
                style={{ textTransform: "none" }}
              >
                Adjuntar autorización de isapre
              </Button>
            </label>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ marginTop: "1em" }}
          >
            <TextField
              label="Valor del traslado"
              value={transferPrice}
              onChange={e => setTransferPrice(parseInt(e.target.value))}
              name="numberformat"
              id="formatted-numberformat-input"
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
        </DialogContent>

        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          <Button
            disabled={approvalFile === null || transferPrice === 0 || isNaN(transferPrice)}
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={uploadAuthorization}
          >
            Autorizar con isapre
          </Button>
          <Button
            disabled={transferPrice === 0 || isNaN(transferPrice)}
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={() => uploadAuthorization(false)}
          >
            Autorizar sin isapre
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={rejectRequest}
          >
            Rechazar solicitud
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={closeModal}
            style={{ textTransform: "none" }}
          >
            Cerrar
          </Button>
        </DialogActions>

      </Paper>
    </Modal>
  );
};

export default ModalAddAuthorization;

ModalAddAuthorization.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRow: PropTypes.object,
  setauxData: PropTypes.func,
  dataArray: PropTypes.array,
  finishArray: PropTypes.array,
  setFinishArray: PropTypes.func
};
