import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import apiform from "utils/APIForm";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function ModalDeleteDevice(props) {
  const classes = useStyles();
  const {
    open,
    closeModal,
    device,
    setChanges,
  } = props;
  const [openBackdropEditDevice, setOpenBackdropEditDevice] = useState(false);

  const deleteDevice = () => {
    setOpenBackdropEditDevice(true);

    apiform
      .delete(`api/dispositivospaciente/${device.id}`)
      .then(response => {
        setChanges(true);
        closeModal();
      })
  };

  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
        disableBackdropClick
        scroll="paper"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          align="center"
        >
          Eliminar dispositivo
        </DialogTitle>
        <DialogContent scroll={"paper"}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} container>
              <Grid item xs={1} />
              <Grid item xs={10} container>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{ textTransform: "none" }}
                >
                  ¿Está seguro que desea eliminar este dispositivo?
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          {openBackdropEditDevice ? (
            <CircularProgress />
          ) : (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={closeModal}
                style={{ marginRight: "2px" }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={deleteDevice}
                style={{ marginLeft: "2px" }}
              >
                Eliminar dispositivo
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

ModalDeleteDevice.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  device: PropTypes.object,
  getPatientSupplies: PropTypes.array,
  setChanges: PropTypes.func,
};
