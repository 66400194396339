import React from 'react'
import moment from "moment-timezone";
import "moment/locale/es";
//moment.locale("es");

import { makeStyles } from '@material-ui/core/styles'
import { TypographyMui } from 'utils/componentsStyle';
import { Button, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import FilterListIcon from '@material-ui/icons/FilterList';
import ImageSearchOutlinedIcon from '@material-ui/icons/ImageSearchOutlined';
import date from '../../../../assets/img/icons/memo/date.svg'
import people from '../../../../assets/img/icons/memo/people.svg'
import folder from '../../../../assets/img/icons/shared/folder.svg'

const useStyles = makeStyles({
  listGrid: {
    display: 'grid',
    gridTemplateColumns: '30% 1fr 25%'
  },
  '@media (max-width: 800px)': {},
});

const MemorandumList = ({ memorandumsList, errorAlert, setMemoToEditData, pagination }) => {
  const classes = useStyles()

  return (
    <Box px={1.5}>
      {/* Header */}
      <Box className={classes.listGrid} mt={3}>
        <Box color='primary'>
          <Box display='flex' alignItems='center'>
            <Box mr={0.2}>
              <FilterListIcon fontSize='small' color='primary' />
              <img src={date} alt="date" width='20px' height='30px' className='mr-2' />
            </Box>
            <TypographyMui variant='subtitle2' color='primary'>FECHA</TypographyMui>
          </Box>
        </Box>

        <Box color='primary'>
          <Box display='flex' alignItems='center'>
            <Box mr={0.2}>
              <img src={people} alt="people" width='20px' height='30px' className='mr-2' />
            </Box>
            <TypographyMui variant='subtitle2' color='primary'>PARTICIPANTES</TypographyMui>
          </Box>
        </Box>

        <Box color='primary'>
          <Box display='flex' alignItems='center'>
            <Box mr={0.2}>
              <img src={folder} alt="folder" width='20px' height='30px' className='mr-2' />
            </Box>
            <TypographyMui variant='subtitle2' color='primary'>DETALLE</TypographyMui>
          </Box>
        </Box>
      </Box>
      <hr />
      {errorAlert && (
        <Box mt={1.5}>
          <Alert severity="error">No se encontraron resultados. Intentalo con otra palabra</Alert>
        </Box>
      )}
      {memorandumsList.list
        .slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage)
        .map(memo => {
        return (
          <Box key={memo.id_minuta}>
            <Box className={classes.listGrid}>
              <Box alignSelf='center'>
                <TypographyMui variant='body2'>
                  {moment(memo?.fecha_creacion).format('DD / MM / YYYY')}
                  </TypographyMui>
                <TypographyMui variant='body2'>
                  {moment(memo?.fecha_creacion).format('HH:mm')}
                  </TypographyMui>
              </Box>

              {memo.participantes ?
                <Box alignSelf='center' ml={-3}>
                  <ul>
                    {memo.participantes.map(member => {
                      return (
                        <li key={member.id_usuario}>
                          <TypographyMui variant='body2'>{member.nombre_completo}</TypographyMui>
                        </li>
                      )
                    })}
                  </ul>
                </Box>
                :
                <TypographyMui clasName='align-self-center' variant='body2'>Aun sin participantes</TypographyMui>
              }

              <Box alignSelf='center'>
                <Button onClick={() => setMemoToEditData(memo)} variant='outlined' size='small' color='primary' style={{ backgroundColor: 'rgba(63, 81, 181, 0.08)' }}>
                  <ImageSearchOutlinedIcon color='primary' className={classes.mr8} />
                  Ver detalle
                </Button>
              </Box>
            </Box>
            <hr />
          </Box>
        )
      })}
    </Box>
  )
}

export default MemorandumList
