import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Paper,
    Grid,
    Typography,
    Backdrop,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import {green, red} from "@material-ui/core/colors";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
}));

export default function ModalDeleteScheduleTime({
    open,
    handleClose,
    handleDelete,
}) {
    const classes = useStyles();

    const handleOption = (value) => {
        handleDelete(value);
        handleClose()
    }

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px'
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
            className={classes.paper}
            style={{
                maxHeight: "300px",
                overflow: "auto",
            }}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{padding: "5px"}}
                    >
                        <Typography  variant="h3" display="block" gutterBottom>
                            {`Eliminar tarea`}
                        </Typography>
                        <SnackbarContent
                            message={`Al continuar con está acción se eliminaran todas las tareas relacionadas a la solicitud`}
                            color="danger"
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 0 0"}}
                >
                     <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        style={{backgroundColor: green[800], marginRight: "10px"}}
                        onClick={() => handleOption(true)}
                    >
                        Sí
                    </Button>
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={() => handleOption(false)}
                    >
                        No
                    </Button>

                </Grid>
            </Grid>
        </Paper>
      </Modal>
  );
}