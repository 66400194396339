import React, { useEffect, useState } from "react";
import TableComponent from "views/Widgets/Common/TableComponent";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { colorGreen, colorRed, colorWhite } from "./Colors";
import { Link } from "react-router-dom";

const RequestTable = props => {
  const {
    getProducts,
    order,
    setModalCancel,
    setModalEdit,
    setModalInsumo,
    handlePicking,
    checkArray,
    getProductsComplete,
    getProductsOther,
    actualGroup,
  } = props;

  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (loading) {
      setloading(false)
    }
  });

  const handlePickingTemp = (value, index) => {
    handlePicking(value, index);
  };


  const headerTable = [
    {label: "SKU"},
    {label: "Grupo"},
    {label: "Descripción"},
    {label: "Observaciones"},
    {eval: false, label: "Reemplazo"},
    {label: "Cantidad solicitada"},
    {label: "Cantidad por enviar"},
    {label: "Stock disponible"},
    {label: "Picking"},
    {eval: false, label: "Detalle"},
    {label: "Estado"},
  ]

  console.log(getProducts)

  const dataItem = getProducts.map((row, index) => {
    const isReeplace = row.reemplazado
    const SKU = row.id_insumo_medicamento?.SKU || row.id_equipo_asignado?.sku_bodega || row.id_equipo?.SKU;
    const group = row.id_insumo_medicamento !== null ? row.id_insumo_medicamento?.grupo_producto : "Equipo";
    const description =  row.id_insumo_medicamento?.descripcion_producto || row.id_equipo_asignado?.tipo_equipo || row.id_equipo?.descripcion
    const comment = row.comentario || row.observaciones;
    const stock = row.id_insumo_medicamento?.stock_producto || (row.id_equipo_asignado?.en_bodega ? "Sí":"No")
    
    const _reeplace_ = isReeplace ? <Button
      variant="outlined"
      color="primary"
      size="small"
      disabled
    >
      Reemplazar
    </Button> : 
    (order[`estado_solicitud_${actualGroup}`] === "Esperar a egresos" || order[`estado_solicitud_${actualGroup}`] === "Finalizado" || 
    order[`estado_solicitud_${actualGroup}`] === "Documento pendiente en Egresos" || order[`estado_solicitud_${actualGroup}`] === "Adjuntar guía de bodega" ||
    order[`estado_solicitud_${actualGroup}`] === "Esperando documento de egresos" || order[`estado_solicitud`] === "Pendiente en Solicitud Paciente") ? (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        disabled
      >
        Reemplazar
      </Button>
    ) : row.estado_reemplazo === "Rechazado" ? (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        disabled={row.id_insumo_medicamento===null ? true:false}
        onClick={() => setModalEdit(row.id, true, row)}
      >
        Rechazado
      </Button>
    ) : row.estado_reemplazo === "Solicitado" ? (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        disabled={row.id_insumo_medicamento===null ? true:false}
        onClick={() => setModalCancel(row.id, true, row)}
      >
        Solicitado
      </Button>
    ) : (row.id_insumo_medicamento!==null && actualGroup === "insumo") ? (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => setModalInsumo(row.id, true)}
      >
        Reemplazar
      </Button>
    ) : (
      <Button
        disabled={row.id_insumo_medicamento===null ? true:false}
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => setModalEdit(row.id, true, row)}
      >
        Reemplazar
      </Button>
    )

    const _picking_ =  isReeplace ?  <input
        type="number"
        className="form-control bg-light font-weight-lighter"
        style={{ color: "#000000", width: "5em" }}
        value={row.picking}
        disabled
      /> : 
      (order[`estado_solicitud_${actualGroup}`] === "Esperar a egresos" || order[`estado_solicitud_${actualGroup}`] === "Finalizado" ||
      order[`estado_solicitud_${actualGroup}`] === "Documento pendiente en Egresos" || order[`estado_solicitud_${actualGroup}`] === "Esperando documento de egresos" ||
      order[`estado_solicitud`] === "Pendiente en Solicitud Paciente" || order[`estado_solicitud_${actualGroup}`] === "Adjuntar guía de bodega") ? (
      <input
        type="number"
        min="0"
        className="form-control bg-light font-weight-lighter"
        style={{ color: "#000000", width: "5em" }}
        value={row.picking}
        disabled
      />
    ) : (
      <input
        type="number"
        min="0"
        max={(row.id_insumo_medicamento?.stock_producto >= row.cantidad) ? row.cantidad : row.id_insumo_medicamento?.stock_producto}
        className="form-control bg-light font-weight-lighter"
        style={{ color: "#000000", width: "5em" }}
        value={row.picking}
        onChange={e => {
          if (/^[0-9\b]+$/.test(e.target.value)) {
            handlePickingTemp(parseInt(e.target.value), index);
          }
        }}
        disabled = {!row.id_insumo_medicamento}
      />
    )

    const _detail_ = row.id_insumo_medicamento===null ?
    <Link
      to={`/admin/warehouse/order/equipments/${row.id_solicitud_pedido.id_solicitud_paciente}`}
    >
      <Button
        variant="outlined contained"
        style={{backgroundColor:'green',color:'white'}}
        size="small"
      >
        Detalle
      </Button>
    </Link>
      : "-"

    const colorStatus = (row.estado === "Completa" || row.estado === "Completada" ) ? colorGreen : (row.estado === "Incompleta" || row.estado === "Incompleto") ? colorRed : colorWhite

    const _status_ =   <div className="text-center align-middle">
      <div className="row">
        <div className="align-self-center">
              {row.estado}
        </div>
        <Box display="flex" justifyContent="center">
          <Box borderRadius="50%" {...colorStatus} />
        </Box>
      </div>
    </div>
    

    let listRow = [
      {value: SKU},
      {value: group},
      {value: description},
      {value: comment},
      {value: !!isReeplace,_value_: _reeplace_},
      {value: row.cantidad},
      {value: row.cantidad_por_enviar},
      {value: stock},
      {value: row.picking, _value_: _picking_},
      {_value_: _detail_},
      {_value_: _status_},
    ]

    if (isReeplace){
      listRow = listRow.map((auxRow) => {
      const tempRow = {...auxRow}
        if (tempRow._value_ === undefined){
          tempRow._value_ = <s>{tempRow.value}</s>
        }
        return tempRow
      })
    }

    

    return listRow
  });

  const dataComplete = getProductsComplete.map((row, index) => {
    const SKU = row.id_insumo_medicamento?.SKU || row.id_equipo_asignado?.sku_bodega || row.id_equipo?.SKU;
    const group = row.id_insumo_medicamento !== null ? row.id_insumo_medicamento?.grupo_producto : "Equipo";
    const description =  row.id_insumo_medicamento?.descripcion_producto || row.id_equipo_asignado?.tipo_equipo || row.id_equipo?.descripcion
    const comment = row.comentario || row.observaciones;
    const stock = row.id_insumo_medicamento?.stock_producto || (row.id_equipo_asignado?.en_bodega ? "Sí":"No")
    
    const _reeplace_ =  <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() => setModalEdit(row.id, true, row)}
      disabled
    >
      Reemplazar
    </Button>

    const _picking_ =  <input
      type="number"
      className="form-control bg-light font-weight-lighter"
      style={{ color: "#000000", width: "5em" }}
      value={row.picking}
      disabled = {row.id_insumo_medicamento===null ? true:false}
    />

    const _detail_ = row.id_insumo_medicamento===null ?
    <Link
      to={`/admin/warehouse/order/equipments/${row.id_solicitud_pedido.id_solicitud_paciente}`}
    >
      <Button
        variant="outlined contained"
        style={{backgroundColor:'green',color:'white'}}
        size="small"
      >
        Detalle
      </Button>
    </Link> : "-"

    const _status_ =   <div className="text-center align-middle">
      <div className="row">
        <div className="align-self-center">
          <span>
            {row.estado}

          </span>
        </div>
        <Box display="flex" justifyContent="center">
          <Box borderRadius="50%" {...colorGreen} />
        </Box>
      </div>
    </div>
      

    let listRow = [
      {value: SKU},
      {value: group},
      {value: description},
      {value: comment},
      {_value_: _reeplace_},
      {value: row.cantidad},
      {value: row.cantidad_por_enviar},
      {value: stock},
      {value: row.picking, _value_: _picking_},
      {_value_: _detail_},
      {_value_: _status_},
    ]

    return listRow
  
  });

  const dataOther = getProductsOther.map((row, index) => {
    const isReeplace = row.reemplazado
    const SKU = row.id_insumo_medicamento?.SKU || row.id_equipo_asignado?.sku_bodega || row.id_equipo?.SKU;
    const group = row.id_insumo_medicamento !== null ? row.id_insumo_medicamento?.grupo_producto : "Equipo";
    const description =  row.id_insumo_medicamento?.descripcion_producto || row.id_equipo_asignado?.tipo_equipo || row.id_equipo?.descripcion
    const comment = row.comentario || row.observaciones;
    const stock = row.id_insumo_medicamento?.stock_producto || (row.id_equipo_asignado?.en_bodega ? "Sí":"No")
    
    const _reeplace_ = isReeplace ? <Button
      variant="outlined"
      color="primary"
      size="small"
      disabled
    >
      Reemplazar
    </Button> : 
    <Button
      variant="outlined"
      color="primary"
      size="small"
      disabled={row.id_insumo_medicamento===null ? true:false}
      onClick={() => setModalCancel(row.id, true, row)}
      >
      Solicitado
    </Button>
    

    const _picking_ =  isReeplace ? <input
      type="number"
      className="form-control bg-light font-weight-lighter"
      style={{ color: "#000000", width: "5em" }}
      value={row.picking}
      disabled = {row.id_insumo_medicamento===null ? true:false}
    />:
    <input
      className="form-control bg-light font-weight-lighter"
      style={{ color: "#000000", width: "5em" }}
      value={"-"}
      disabled
    />

    const _detail_ = row.id_insumo_medicamento===null ?
    <Link
      to={`/admin/warehouse/order/equipments/${row.id_solicitud_pedido.id_solicitud_paciente}`}
    >
      <Button
        variant="outlined contained"
        style={{backgroundColor:'green',color:'white'}}
        size="small"
      >
        Detalle
      </Button>
    </Link>
      : "-"

    const colorStatus = (row.estado === "Completa" || row.estado === "Completada" ) ? colorGreen : (row.estado === "Incompleto" || row.estado === "Incompleta") ? colorRed : colorWhite

    const _status_ =   <div className="text-center align-middle">
      <div className="row">
        <div className="align-self-center">
              {row.estado}
        </div>
        <Box display="flex" justifyContent="center">
          <Box borderRadius="50%" {...colorStatus} />
        </Box>
      </div>
    </div>
    

    let listRow = [
      {value: SKU},
      {value: group},
      {value: description},
      {value: comment},
      {_value_: _reeplace_},
      {value: row.cantidad},
      {value: row.cantidad_por_enviar},
      {value: stock},
      {value: row.picking, _value_: _picking_},
      {_value_: _detail_},
      {_value_: _status_},
    ]

    if (isReeplace){
      listRow = listRow.map((auxRow) => {
      const tempRow = {...auxRow}
        if (tempRow._value_ === undefined){
          tempRow._value_ = <s>{tempRow.value}</s>
        }
        return tempRow
      })
    }
    return listRow
  });

  return (
    <TableComponent 
      headers={headerTable}
      data={[...dataItem, ...dataComplete, ...dataOther]}
      filter={false}
  />
  );
};

export default RequestTable;

RequestTable.propTypes = {
  getProducts: PropTypes.array,
  order: PropTypes.array,
  setModalCancel: PropTypes.func,
  setModalEdit: PropTypes.func,
  setModalInsumo: PropTypes.func,
  handlePicking: PropTypes.func,
  checkArray: PropTypes.array,
  getProductsComplete: PropTypes.array,
  getProductsOther: PropTypes.array,
  actualGroup: PropTypes.string
};
