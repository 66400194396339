import React from "react";
import "../../../../styles/Styles.css";

const DetailFileContrato = props => {
  return(
    <div className="col-md-4 mb-3">
      <div className="card">
        <div className="card-body">
          <div>

          
          <span className="card-title text-uppercase">{props.type} / </span>
          
          <span className="card-title">{props.name}</span>
          </div>
          {props.link ?
            <button onClick={() => window.open(`${props.link}`, "_blank")}  className="btn btn-cmh-color">Ver Documento</button>
            :
            <button className="btn-cmh-color btn" disabled>Sin Documento</button>
          }
        </div>
      </div>
    </div>
  );
}

export default DetailFileContrato;