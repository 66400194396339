import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import { amber, red } from '@material-ui/core/colors';

import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransitionsModal({open, update, closeModal}) {
  const classes = useStyles();

  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [valid, setValid] = useState(false);

  useEffect(() => {
    generateValidation();
  });

  const handleCloseModal = () => {
    update(
      newPassword,
    )
  };

  const generateValidation = () => {
    const validations = [
      newPassword === repeatPassword,
      newPassword !== "",
      repeatPassword !== "",
    ];
    setValid(validations.every((item) => item === true));
  };

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handlerepeatPassword = (event) => {
    setRepeatPassword(event.target.value);
  }


  return (
    <Modal
      aria-labelledby="new-provider-modal"
      aria-describedby="new-provider-description"
      open={open}
      onClose={closeModal}
      disableBackdropClick={true}
      style={{
          height: "50%",
          width: "50%",
          left: "25%",
          top: "20%",
          borderRadius: '4px',
      }}
  >
      <Paper
          style={{
              padding: "5% 2%",
          }}
      >
          <Grid container item xs={12}>
              <Grid container direction="row" item xs={12} style={{margin: "1%"}}>
                  <Grid
                      container
                      justify="flex-end"
                      alignItems="center"
                      xs={6}>
                      <Typography style={{margin: "0 19px 0 0"}} variant="h5" id="modal-title">
                          Nueva contraseña:
                      </Typography>
                  </Grid>
                  <Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      item
                      xs={4}
                  >
                      <TextField
                          id="newPassword"
                          label="Nueva Contraseña"
                          variant="outlined"
                          type="password"
                          defaultValue={newPassword}
                          onChange={handleNewPassword}
                          error={newPassword == ""}
                          helperText={newPassword == "" ? "No puede ser vacio" : ""}
                          fullWidth my={2}
                      />
                  </Grid>
              </Grid>
              <Grid container direction="row" xs={12} style={{margin: "1%"}}>
                  <Grid
                      container
                      justify="flex-end"
                      alignItems="center"
                      item
                      xs={6}>
                      <Typography style={{margin: "0 19px 0 0"}} variant="h5" id="modal-title">
                          Repetir contraseña:
                      </Typography>
                  </Grid>
                  <Grid
                      container
                      justify="flex-start"
                      alignItems="center"
                      item
                      xs={4}
                  >
                      <TextField
                          id="repeatPassword"
                          label="Repetir contraseña"
                          variant="outlined"
                          type="password"
                          defaultValue={repeatPassword}
                          onChange={handlerepeatPassword}
                          error={repeatPassword === ""}
                          helperText={repeatPassword === "" ? "No puede ser vacio" : ""}
                          fullWidth my={2}
                      />
                  </Grid>
              </Grid>
              {!valid &&
                  <Grid
                      container
                      justify="center"
                      alignItems="center"
                      item
                      xs={12}>
                          <Typography style={{color: red[500], margin: "0 19px 0 0"}} variant="h6" id="modal-title">
                              Las claves no son iguales
                          </Typography>
                  </Grid>
              }
              <Grid
                  container
                  justify="center"
                  alignItems="center"
                  item
                  xs={12}
                  style={{margin: "1%"}}
              >
                <Button
                mr={2}
                variant="contained"
                style={{backgroundColor: "#1B9CFC", marginRight: "10px"}}
                className="text-white"
                onClick={closeModal}
              >
                Cancelar
              </Button>

                  {valid ?
                      <Button
                          mr={2}
                          variant="contained"
                          style={{backgroundColor: amber[500]}}
                          onClick={handleCloseModal}
                      >
                          Guardar
                      </Button>
                  :
                      <Button
                          mr={2}
                          variant="contained"
                          disabled
                      >
                          Guardar
                      </Button>
                  }
              </Grid>
          </Grid>
      </Paper>
  </Modal>
  );
}
