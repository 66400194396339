import React from "react";

export const useSortableDataCode = (itemsCode, config = null) => {
  const [sortConfigCode, setSortConfigCode] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...itemsCode];
    if (sortConfigCode !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfigCode.key] < b[sortConfigCode.key]) {
          return sortConfigCode.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigCode.key] > b[sortConfigCode.key]) {
          return sortConfigCode.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [itemsCode, sortConfigCode]);

  const requestSortCode = key => {
    let direction = "ascending";
    if (
      sortConfigCode &&
      sortConfigCode.key === key &&
      sortConfigCode.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfigCode({ key, direction });
  };

  return { itemsCode: sortedItems, requestSortCode, sortConfigCode };
};
