import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import {
    Brightness1,
  } from "@material-ui/icons/";
  import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStylesTemplate = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  }
}));

const stateColor = {
    Completo: green[500],
}

export default function ModalDetailRefuseDriver({
    open,
    handleClose,
    handleDetailRefuse,
    setchangeButtons,
    rowRefuse,
    rowsActive,
    setrowsActive,
    setmessage,
    infoRequest,
}) {
    const classes = useStyles();
    const [reason, setreason] = useState("");

    const handleRefuse = () => {
        const body = {};
        const body2 = {};
        //cambiar estado en caja
        body["estado_recepcion"] = "Fallido";
        body2["estado"] = "Fallido";
        body["motivo_rechazo"] = reason;
        const requestSend = api.patch(`/api/enviospedidos/${rowRefuse[0].id_envios_pedidos.id}`, body2);
        const requestTransport = api.patch(`/api/agendatransportes/${rowRefuse[0].id}`, body);
        Promise.all([
            requestSend,
            requestTransport,
        ]).then((result) => {
            const tempRows = rowsActive.map((row) => {
                const tempRow = row;
                if(rowRefuse[0].id === row.id){
                    tempRow.estado_recepcion = "Fallido";
                    tempRow.motivo_rechazo = reason
                }
                return tempRow;
            });
            setrowsActive(tempRows);
            setchangeButtons(true);
            setmessage("Envío rechazado");
            handleClose();
        });
    };

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleDetailRefuse}
        closeAfterTransition
        style={{
            borderRadius: '4px'
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
            className={classes.paper}
            style={{
                maxHeight: "900px",
                overflow: "auto",
            }}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <h3 id="spring-modal-title">{`Rechazar solicitud`}</h3>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                    <Typography  variant="caption" display="block" gutterBottom>
                        {`¿Por qué esta rechazando la solicitud?`}
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                  <DetailText
                      label={` `}
                      value={reason}
                      onchange={(e) => { setreason(e.target.value)}}
                      multiline={true}
                  />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                  {reason != "" ?
                  <Button
                      mr={2}
                      variant="contained"
                      className="text-white"
                      color="primary"
                      onClick={handleRefuse}
                  >
                      Rechazar
                  </Button>
                  :
                  <Button
                      mr={2}
                      disabled
                      variant="contained"
                      className="text-white"
                  >
                      Rechazar
                  </Button>
                  }
                  <Button
                      mr={2}
                      variant="contained"
                      style={{backgroundColor: red[500], marginRight: "10px"}}
                      className="text-white"
                      onClick={handleDetailRefuse}
                  >
                      Cancelar
                  </Button>
                </Grid>
            </Grid>
        </Paper>
      </Modal>
  );
}