import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    Typography,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import {
    Search,
    ArrowDropDown,
    ArrowDropUp,
  } from "@material-ui/icons/"
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {red, green, yellow, orange, indigo} from "@material-ui/core/colors";
import FilterBox from "views/Widgets/Transport/FilterBox";
import LittleChart from "components/Boss/LittleChart";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  }
}));



export default function WorkerTable({
    arrayWorker,
    period,
    handleFilter,
    filter,
    selectProfessional,
    showTableSwich,
    handleSelectProfessional,
    handleOrder,
    order=null,
    direction=false,
    tableMaxHeigth="650px",
    changeButtons=false,
}) {
    const classes = useStyles();
    const [initialCharge, setinitialCharge] = useState(false);

    useEffect(() => {
        if (initialCharge){
          initialData();
        }
    });

    const initialData = () => {
        setinitialCharge(false);
    };

    const getColorSelected = (id, index) => {
        if (selectProfessional){
            if (selectProfessional.id === id){
                if (index% 2 === 0){
                    return {background: yellow[100]};
                } else {
                    return {background: orange[100]};
                }
            } else {
                return {};
            }
        } else {
            return {};
        }
    };

    const headerColumn = (headerName, style={}) => {
        return (
          <Tooltip
              title={
                <React.Fragment>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Typography variant="body2" gutterBottom>{`Ordernar por ${headerName}`}</Typography>
                  </Grid>
                </React.Fragment>
              }
            >
              <TableCell
                className="text-center align-top"
                style={{minWidth: "85px", ...style}}
                onClick={() => handleOrder(headerName)}
              >
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  item xs={12}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    item xs={10}
                  >
                    {headerName}
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    item xs={2}
                    onClick={() => handleOrder(headerName)}
                  >
                    {order === headerName &&
                      <ArrowDropUp style={{
                        margin: "-15px 0 -15px 0",
                        fill: indigo[direction ? 900 : 200]}} />
                    }
                    <ArrowDropDown style={{
                      margin: order === headerName ? "0 0 -15px 0" : "0",
                      fill: indigo[(order === headerName && !direction ) ? 900 : 200]
                    }} />
                  </Grid>

                </Grid>
              </TableCell>
            </Tooltip>
        );
    };

    const getVisitProfessional = (row) => {
        return (
            <Grid item xs={12} style={{position: "relative"}}>
                <LittleChart
                    row={row.visitas_realizadas}
                    color={row.visitas_realizadas === 0 ? red[100] : green[100]}
                    max={row.visitas === undefined ? 0 : row.visitas}
                    stackOffset="none"
                    width={100}
                    height={40}
                />
                <div
                    style={{
                        color: row.visitas_realizadas === 0 ? red[900] : green[900],
                        position: "absolute",
                        margin: "-29px 0px 0px 27px",
                    }}>
                    {`${row.visitas === undefined ?
                        `${row.visitas_realizadas} - No registra horas` :
                        `${row.visitas_realizadas} de ${row.visitas}`}`}
                </div>
            </Grid>
        );
    };

    return (
      <Card>
        <CardBody stats className={classes.cardFooter} style={{padding: "2rem 1rem", margin: "0"}}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                    <h5>{`Lista de profesionales`}</h5>
                </Grid>
                <Grid item xs={12}>
                    <FilterBox
                        filterText={filter}
                        handleFilter={handleFilter}
                        IconSpan={Search}
                        stylePaper={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} style={{margin: "30px 0 0 0"}}>
                <TableContainer style={{maxHeight: tableMaxHeigth}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {headerColumn("Profesional", {})}
                                {headerColumn("Estado", {})}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {arrayWorker.map((row, index) => (
                            period === "Dias" ?
                            <TableRow key={`${index}-workRows`}>
                                <TableCell className="text-center align-middle">
                                    {`${row.first_name} ${row.last_name}`}
                                </TableCell>
                                <TableCell className="text-center align-middle">{getVisitProfessional(row)}</TableCell>
                            </TableRow> :
                            <TableRow
                                key={`${index}-workRows`}
                                onClick={() => handleSelectProfessional(row)}
                                style={{...getColorSelected(row.id, index), cursor: !showTableSwich ? "pointer" : "context-menu"}}
                            >
                                <TableCell className="text-center align-middle">
                                    {`${row.first_name} ${row.last_name}`}
                                </TableCell>
                            <TableCell className="text-center align-middle">{getVisitProfessional(row)}</TableCell>
                            </TableRow>
                        ))}
                        {changeButtons &&
                          <TableRow></TableRow>
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
            </Grid>
        </CardBody>
      </Card>
  );
}