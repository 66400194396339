import numeral from 'numeral';
"numeral/locales/es";

export const toMoneyFormat = (number) => {
    return numeral(parseInt(number)).format('$0,0');
}

export const firstChartUpperString = (string) => {

    return string.split(" ").map((tempString) => {
        return tempString.charAt(0).toUpperCase() + tempString.slice(1);
    }).join(" ")
}

export const onlyNumbers = (value) => {
    let newValue = value.replace(/[^0-9]/g, '');
    if (newValue.length > 1 && newValue.charAt(0) === '0') {
        newValue = newValue.slice(1);
    }
    return  newValue
};

export const eventColors = (event) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
};

export const getUrl = (config) => {
    const url = config?.url
    const method =  config?.method
    return `${method}.${url}`
}
