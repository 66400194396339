import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "../Widgets/FichaPersonal/viewTitle";
import CardTitle from "../Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "../Widgets/FichaPersonal/loadingSpinner";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function TableSolicitudesContrato() {
  const classes = useStyles();

  const [contratos, setContratos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.get("api/fichapersonal/").then(result => {
      setLoading(true);
      setContratos(result.data);
    });
    setLoading(false);
  }, []);

  return (
    <GridContainer>
      <ViewTitle title="SOLICITUDES" message="
      En esta sección podrás revisar todas las solicitudes realizadas por las diferentes áreas.  En ella podrás generar y descargar contratos, subir contratos firmados, crear anexos y adjuntar anexos firmados.
      " />


      <GridItem xs={12} sm={6} md={12}>
        {loading ?

        <Card>
          <CardTitle title="SOLICITUDES" class={classes.cardTitle} />
          <CardBody>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Fecha solicitud</th>
                  <th>Área solicitud</th>
                  <th>Nombre solicitante</th>
                  <th>Tipo solicitud</th>
                  <th>Estado</th>
                  <th style={{textAlign: "center"}}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {contratos.map(contrato => (
                  <tr key={contrato.id}>
                    <td>{contrato.fecha_creacion}</td>
                    <td>{contrato.nombres}</td>
                    <td>{contrato.cargo}</td>
                    <td>{contrato.fecha_contrato}</td>
                    <td></td>
                    <td style={{textAlign: "center"}}>


                        <Link to={`/admin/vistacontrato/${contrato.id}`}>
                        <Button style={{backgroundColor: "#1F8AFF"}} >
                          Gestionar
                          </Button>
                        </Link>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
        :
        <Card>
          <CardTitle title="Cargando información ..." class={classes.cardTitle}/>
          <CardBody className="d-flex justify-content-center">
            <LoadingSpinner />
          </CardBody>
        </Card>
        }
      </GridItem>
    </GridContainer>
  );
}
