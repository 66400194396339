import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import ViewStock from "views/DashboardBoss/ViewStock";
import moment from "moment-timezone";
import UpdateStockButton from "views/Widgets/UpdateStockButton";
import api from "utils/API";
import "moment/locale/es";
moment.locale("es");



const DashboardBoss = (props) => {
  const [dataArray, setdataArray] = useState([]);
  const [auxData, setauxData] = useState([]);
  const [barChartData, setbarChartData] = useState([]);
  const [demandStock, setdemandStock] = useState([]);
  const option = "stock";
  const [initialCharge, setinitialCharge] = useState(true);
  const [loading, setloading] = useState(false);
  const [filterData, setfilterData] = useState(false);
  const [startDate, setstartDate] = useState(moment().subtract(2, "months").startOf("month"));
  const [endDate, setendDate] = useState(moment().add(2, "months").endOf("month"));
  const minDate = moment(startDate);
  const [process, setprocess] = useState(false);
  const [pending, setpending] = useState(false);
  const [patient, setpatient] = useState({ value: null, label: "Todas" });
  const [listPatient, setlistPatient] = useState([
    { value: null, label: "Todas" },
  ]);
  const [dictPatient, setdictPatient] = useState({});
  const [typeRequest, settypeRequest] = useState({
    value: null,
    label: "Todas",
  });
  const [listTypeRequest, setlistTypeRequest] = useState([
    { value: null, label: "Todas" },
  ]);
  const [dictSend, setdictSendRequest] = useState({});
  const [dictProductRequest, setdictProductRequest] = useState({});
  const [dictMedicinesSupplies, setdictMedicinesSupplies] = useState({});
  const [comuna, setcomuna] = useState({ value: null, label: "Todas" });
  const [listComuna, setlistComuna] = useState([
    { value: null, label: "Todas" },
  ]);
  const [selectItemTable, setselectItemTable] = useState([]);
  const [selectItemTableDemandStock, setselectItemTableDemandStock] = useState(
    null
  );
  const [filterSelect, setfilterSelect] = useState([]);
  const [selectColumn, setSelectColumn] = useState(null);
  const [demand, setdemand] = useState(0);
  const [stock, setstock] = useState(0);
  const [diff, setdiff] = useState(0);
  const [diffMin, setdiffMin] = useState(0);

  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);
  const dictPicking = useSelector(state => state.currentList.DICCIONARIO_ESTATUS_PICKING);

  useEffect(() => {
    if (initialCharge && dictTypeRequest) {
      initialData();
    }
    if (filterData) {
      handleFilterData();
    }
  });

  const initialData = () => {
    setinitialCharge(false);
    setloading(false);
    const start = startDate.format("YYYY-MM-DD");
    const end = endDate.format("YYYY-MM-DD");

    const requestDataDashboard = api.get(
      `api/jefe/dashboard/solicitudpedidosdepth/${start}/${end}`
    );
    Promise.all([requestDataDashboard])
      .then((request) => {
        const data = request[0].data;
        const tempPatientDict = {};
        const tempSendRequestDict = {};
        const tempScheduleDict = {};
        const tempProductRequest = {};
        const tempMedicinesSuppliesDict = {};
        const templistComuna = [{ value: null, label: "Todas" }];
        const templistTypeRequest = [{ value: null, label: "Todas" }];
        const tempPatient = [{ value: null, label: "Todas" }];

        const auxTypeRequest = data["solicitudes"].map((row) => {
          return row.tipo_solicitud;
        });
        Array.from(new Set(auxTypeRequest)).map((row) => {
          if (dictTypeRequest[row]){
            templistTypeRequest.push({
              value: row,
              label: dictTypeRequest[row]?.label,
            });
          }
        });
        const auxComuna = data["pacientes"].map((row) => {
          tempPatientDict[row.id] = row;
          tempPatient.push({
            value: row.id,
            label: `(${row.id}) ${row.rut} ${row.nombre} ${row.apellido_paterno} ${row.appellido_materno}`,
          });
          return row.direccion_comuna;
        });
        Array.from(new Set(auxComuna)).map((row) => {
          templistComuna.push({ value: row, label: row });
        });
        data["envios"].map((row) => {
          if (tempSendRequestDict[row.id_solicitud_pedido_id]) {
            tempSendRequestDict[row.id_solicitud_pedido_id].push(row);
          } else {
            tempSendRequestDict[row.id_solicitud_pedido_id] = [row];
          }
        });
        data["agenda_transportes"].map((row) => {
          if (tempScheduleDict[row.id_envios_pedidos_id]) {
            tempScheduleDict[row.id_envios_pedidos_id].push(row);
          } else {
            tempScheduleDict[row.id_envios_pedidos_id] = [row];
          }
        });
        data["insumos_medicamentos"].map((row) => {
          tempMedicinesSuppliesDict[row.id] = row;
        });
        data["productos_pedidos"].map((row) => {
          if (tempProductRequest[row.id_solicitud_pedido_id]) {
            tempProductRequest[row.id_solicitud_pedido_id].push(row);
          } else {
            tempProductRequest[row.id_solicitud_pedido_id] = [row];
          }
        });
        setdataArray(data["solicitudes"]);
        setauxData(data["solicitudes"]);
        setlistPatient(tempPatient);
        setdictPatient(tempPatientDict);
        setlistComuna(templistComuna);
        setlistTypeRequest(templistTypeRequest);
        setdictSendRequest(tempSendRequestDict);
        setdictProductRequest(tempProductRequest);
        setdictMedicinesSupplies(tempMedicinesSuppliesDict);
        setselectItemTable([]);
        setselectItemTableDemandStock(null);
        setSelectColumn(null);
        setloading(true);
        setfilterData(true);
      })
      .catch((error) => {
        {};
        {}
      });
  };

  const handleFilterData = () => {
    setfilterData(false);
    let tempDataArray = dataArray;
    let auxDataArray = [];
    if (process || pending) {
      tempDataArray.map((row) => {
        const tempIteration = getIterationNumber(row.id);
        if (process && tempIteration >= 0) {
          auxDataArray.push(row);
        }
        if (pending && tempIteration > 1) {
          auxDataArray.push(row);
        }
      });
      tempDataArray = auxDataArray;
      auxDataArray = [];
    }
    if (comuna.value) {
      tempDataArray.map((row) => {
        if (dictPatient[row.id_paciente_id].direccion_comuna === comuna.value) {
          auxDataArray.push(row);
        }
      });
      tempDataArray = auxDataArray;
      auxDataArray = [];
    }
    if (typeRequest.value) {
      tempDataArray.map((row) => {
        if (dictTypeRequest[row.tipo_solicitud]?.value === typeRequest.value) {
          auxDataArray.push(row);
        }
      });
      tempDataArray = auxDataArray;
      auxDataArray = [];
    }
    if (patient.value) {
      tempDataArray.map((row) => {
        if (row.id_paciente_id === patient.value) {
          auxDataArray.push(row);
        }
      });
      tempDataArray = auxDataArray;
      auxDataArray = [];
    }

    const tempDictCellar = {
      "Hacer picking": {
        name: "Hacer picking",
        Completos: 0,
        Pendientes: 0,
        SelCompletos: 0,
        SelPendientes: 0,
      },
      "Notificar a egresos": {
        name: "Notificar a egresos",
        Completos: 0,
        Pendientes: 0,
        SelCompletos: 0,
        SelPendientes: 0,
      },
      "Reemplazo aprobado": {
        name: "Reemplazo aprobado",
        Completos: 0,
        Pendientes: 0,
        SelCompletos: 0,
        SelPendientes: 0,
      },
      "Ir a retirar pedido": {
        name: "Ir a retirar pedido",
        Completos: 0,
        Pendientes: 0,
        SelCompletos: 0,
        SelPendientes: 0,
      },
      "Adjuntar guía": {
        name: "Adjuntar guía",
        Completos: 0,
        Pendientes: 0,
        SelCompletos: 0,
        SelPendientes: 0,
      },
      "Esperar a egresos": {
        name: "Esperar a egresos",
        Completos: 0,
        Pendientes: 0,
        SelCompletos: 0,
        SelPendientes: 0,
      },
    };

    let tempDictDemandStock = {};
    let totalDictDemandStock = {};
    tempDataArray.map((row) => {
      const tempState = getPickingState(
        row.estado_picking_farmacia,
        row.estado_picking
      );
      if (tempDictCellar[row.estado_solicitud]) {
        if (selectColumn) {
          if (
            tempState.includes("Falta") ||
            tempState.includes("Incompleta") ||
            tempState.includes("Pendiente")
          ) {
            if (
              selectColumn.tipo === "Pendientes" &&
              row.estado_solicitud === selectColumn.name
            ) {
              tempDictCellar[row.estado_solicitud].SelPendientes += 1;
            } else {
              tempDictCellar[row.estado_solicitud].Pendientes += 1;
            }
          } else {
            if (
              selectColumn.tipo === "Completos" &&
              row.estado_solicitud === selectColumn.name
            ) {
              tempDictCellar[row.estado_solicitud].SelCompletos += 1;
            } else {
              tempDictCellar[row.estado_solicitud].Completos += 1;
            }
          }
          tempDictDemandStock = getMedicinesSupplies(row, tempDictDemandStock);
        } else if (selectItemTable.length > 0) {
          if (selectItemTable.includes(row.id)) {
            if (
              tempState.includes("Falta") ||
              tempState.includes("Incompleta") ||
              tempState.includes("Pendiente")
            ) {
              tempDictCellar[row.estado_solicitud].SelPendientes += 1;
            } else {
              tempDictCellar[row.estado_solicitud].SelCompleto += 1;
            }
            if (selectItemTableDemandStock === null) {
              tempDictDemandStock = getMedicinesSupplies(
                row,
                tempDictDemandStock
              );
            }
          } else {
            if (
              tempState.includes("Falta") ||
              tempState.includes("Incompleta") ||
              tempState.includes("Pendiente")
            ) {
              tempDictCellar[row.estado_solicitud].Pendientes += 1;
            } else {
              tempDictCellar[row.estado_solicitud].Completo += 1;
            }
          }
          if (selectItemTableDemandStock) {
            tempDictDemandStock = getMedicinesSupplies(
              row,
              tempDictDemandStock
            );
          }
        } else {
          if (
            tempState.includes("Falta") ||
            tempState.includes("Incompleta") ||
            tempState.includes("Pendiente")
          ) {
            tempDictCellar[row.estado_solicitud].Pendientes += 1;
          } else {
            tempDictCellar[row.estado_solicitud].Completo += 1;
          }
          tempDictDemandStock = getMedicinesSupplies(row, tempDictDemandStock);
        }
        auxDataArray.push(row);
      }
      totalDictDemandStock = getMedicinesSupplies(row, totalDictDemandStock);
    });

    tempDataArray = auxDataArray;
    auxDataArray = [];

    const tempBarChart = Object.values(tempDictCellar);
    const tempDemandStock = Object.values(tempDictDemandStock);

    let tempdemand = 0;
    let tempstock = 0;
    let tempdiff = 0;
    let tempdiffMin = 0;
    tempDemandStock.map((row) => {
      tempdemand = tempdemand < row.demanda ? row.demanda : tempdemand;
      tempstock = tempstock < row.stock ? row.stock : tempstock;
      tempdiff = tempdiff < row.difrencia ? row.difrencia : tempdiff;
      tempdiffMin = tempdiffMin > row.difrencia ? row.difrencia : tempdiffMin;
    });

    setdemand(tempdemand);
    setstock(tempstock);
    setdiff(tempdiff);
    setdiffMin(tempdiffMin);

    setbarChartData(tempBarChart);
    setdemandStock(tempDemandStock);
    setauxData(tempDataArray);
  };

  const getMedicinesSupplies = (row, dict, order = false) => {
    const tempDict = { ...dict };
    if (dictProductRequest[row.id]) {
      dictProductRequest[row.id].map((tempRow) => {
        if (tempRow.id_insumo_medicamento_id){
        const temp = dictMedicinesSupplies[tempRow.id_insumo_medicamento_id];
        if (tempDict[tempRow.id_insumo_medicamento_id]) {
          if (
            !tempDict[tempRow.id_insumo_medicamento_id].pedido.includes(
              tempRow.id_solicitud_pedido_id
            ) ||
            order
          ) {
            tempDict[tempRow.id_insumo_medicamento_id].demanda +=
              tempRow.cantidad;
            tempDict[tempRow.id_insumo_medicamento_id].pedido.push(
              tempRow.id_solicitud_pedido_id
            );
            tempDict[tempRow.id_insumo_medicamento_id].difrencia -=
              tempRow.cantidad;
          }
        } else {
          tempDict[tempRow.id_insumo_medicamento_id] = {
            id: temp.id,
            grupo: temp.grupo_producto,
            SKU: temp.SKU,
            demanda: tempRow.cantidad,
            stock: temp.stock_producto,
            difrencia: temp.stock_producto - tempRow.cantidad,
            pedido: [tempRow.id_solicitud_pedido_id],
          };
        }
        }
      });
    }
    return { ...tempDict };
  };

  const handlePatient = (value) => {
    let tempFilterSelect = filterSelect;
    setpatient(value);
    setfilterData(true);
    if (value.value) {
      tempFilterSelect.push("patient");
    } else {
      tempFilterSelect = filterSelect.filter((row) => row !== "patient");
    }
    setfilterSelect(Array.from(new Set(tempFilterSelect)));
  };

  const handleTypeRequest = (value) => {
    let tempFilterSelect = filterSelect;
    settypeRequest(value);
    setfilterData(true);
    if (value.value) {
      tempFilterSelect.push("type");
    } else {
      tempFilterSelect = filterSelect.filter((row) => row !== "type");
    }
    setfilterSelect(Array.from(new Set(tempFilterSelect)));
  };

  const handleComuna = (value) => {
    let tempFilterSelect = filterSelect;
    setcomuna(value);
    setfilterData(true);
    if (value.value) {
      tempFilterSelect.push("comuna");
    } else {
      tempFilterSelect = filterSelect.filter((row) => row !== "comuna");
    }
    setfilterSelect(Array.from(new Set(tempFilterSelect)));
  };

  const handleStartDate = (value) => {
    if (moment(value).diff(minDate, "days") > 0) {
      if (moment(endDate).diff(value, "days") > 0) {
        setstartDate(value);
      } else {
        setstartDate(moment(endDate).subtract(1, "days"));
      }
    } else {
      setstartDate(minDate);
    }
    setinitialCharge(true);
  };

  const handleEndDate = (value) => {
    if (moment(value).diff(startDate, "days") > 0) {
      setendDate(value);
    } else {
      setendDate(moment(startDate).add(1, "days"));
    }
    setinitialCharge(true);
  };

  const handlePending = (value) => {
    setpending(value);
    setfilterData(true);
  };

  const handleProcess = (value) => {
    setprocess(value);
    setfilterData(true);
  };

  const handleItemTable = (id) => {
    let tempSelectItemTable = selectItemTable;
    if (selectItemTable.includes(id)) {
      tempSelectItemTable = selectItemTable.filter((row) => row !== id);
    } else {
      tempSelectItemTable.push(id);
    }
    setselectItemTable(tempSelectItemTable);
    setselectItemTableDemandStock(null);
    setSelectColumn(null);
    setfilterData(true);
  };

  const handleItemTableDemandStock = (row) => {
    if (selectItemTableDemandStock) {
      if (selectItemTableDemandStock.id !== row.id) {
        setselectItemTableDemandStock(row);
        setselectItemTable(row.pedido);
      } else {
        setselectItemTableDemandStock(null);
        setselectItemTable([]);
      }
    } else {
      setselectItemTableDemandStock(row);
      setselectItemTable(row.pedido);
    }
    setSelectColumn(null);
    setfilterData(true);
  };

  const handleSelectColumn = (value, typeChart) => {
    let flag = false;
    const array = [];
    if (selectColumn) {
      if (selectColumn.name === value.name && selectColumn.tipo === typeChart) {
        setSelectColumn(null);
      } else {
        setSelectColumn({ ...value, tipo: typeChart });
        flag = true;
      }
    } else {
      setSelectColumn({ ...value, tipo: typeChart });
      flag = true;
    }
    if (flag) {
      auxData.map((row) => {
        if (row.estado_solicitud === value.name) {
          array.push(row.id);
        }
      });
      setselectItemTable(array);
    } else {
      setselectItemTable([]);
    }
    setselectItemTableDemandStock(null);
    setfilterData(true);
  };

  const getPickingState = (pharmacy, supplies) => {
    let statePharmacy = "";
    let stateSupplies = "";

    if (pharmacy === "FaltaFarmacia" || supplies === "FaltaFarmacia") {
      return dictPicking["FaltaFarmacia"]?.label;
    } else if (pharmacy === "FaltaInsumos" || supplies === "FaltaInsumos") {
      return dictPicking["FaltaInsumos"]?.label;
    } else {
      if (pharmacy === "CompletaFarmacia" || pharmacy === "CompletaInsumos") {
        statePharmacy = dictPicking[pharmacy]?.label;
      } else {
        statePharmacy = `Farmacia ${dictPicking[pharmacy]?.label}`;
      }
      if (supplies === "CompletaFarmacia" || supplies === "CompletaInsumos") {
        stateSupplies = dictPicking[supplies]?.label;
      } else {
        stateSupplies = `Insumos ${dictPicking[supplies]?.label}`;
      }
    }
    return `${statePharmacy}, ${stateSupplies}`;
  };

  const getIterationNumber = (id) => {
    if (dictSend[id]) {
      return dictSend[id].length;
    } else {
      return 0;
    }
  };

  const getArea = (id) => {
    let pharmacy = false;
    let supplies = false
    if (dictProductRequest[id]){
      dictProductRequest[id].map((row) => {
        if (row.id_equipo_id){
          supplies = true;
        }
        if (dictMedicinesSupplies[row.id_insumo_medicamento_id]) {
          const tempItem = dictMedicinesSupplies[row.id_insumo_medicamento_id];
          if (["Insumos", "Articulos", "Alimentos"].includes(tempItem.grupo_producto)){
            supplies = true;
          }
          if (tempItem.grupo_producto === "Medicamentos"){
            pharmacy = true;
          }
        }
      })
      let area = "Sin Area"
      if (supplies && pharmacy){
        area = "Insumos/Medicamentos";
      } else if (supplies){
        area = "Insumos";
      } else if (pharmacy) {
        area = "Medicamentos";
      }
      return area
    } else
    return "Sin area"
  }

  const updateStock = () => {
    api.get("/api/poblarinsumos/").then(() => {
      const start = startDate.format("YYYY-MM-DD");
      const end = endDate.format("YYYY-MM-DD");
      api.get(`api/jefe/dashboard/solicitudpedidosdepth/${start}/${end}`).then((request) => {
        const data = request[0].data;
        const tempPatientDict = {};
        const tempSendRequestDict = {};
        const tempScheduleDict = {};
        const tempProductRequest = {};
        const tempMedicinesSuppliesDict = {};
        const templistComuna = [{ value: null, label: "Todas" }];
        const tempPatient = [{ value: null, label: "Todas" }];

        const auxComuna = data["pacientes"].map((row) => {
          tempPatientDict[row.id] = row;
          tempPatient.push({
            value: row.id,
            label: `(${row.id}) ${row.rut} ${row.nombre} ${row.apellido_paterno} ${row.appellido_materno}`,
          });
          return row.direccion_comuna;
        });
        Array.from(new Set(auxComuna)).map((row) => {
          templistComuna.push({ value: row, label: row });
        });
        data["envios"].map((row) => {
          if (tempSendRequestDict[row.id_solicitud_pedido_id]) {
            tempSendRequestDict[row.id_solicitud_pedido_id].push(row);
          } else {
            tempSendRequestDict[row.id_solicitud_pedido_id] = [row];
          }
        });
        data["agenda_transportes"].map((row) => {
          if (tempScheduleDict[row.id_envios_pedidos_id]) {
            tempScheduleDict[row.id_envios_pedidos_id].push(row);
          } else {
            tempScheduleDict[row.id_envios_pedidos_id] = [row];
          }
        });
        data["insumos_medicamentos"].map((row) => {
          tempMedicinesSuppliesDict[row.id] = row;
        });
        data["productos_pedidos"].map((row) => {
          if (tempProductRequest[row.id_solicitud_pedido_id]) {
            tempProductRequest[row.id_solicitud_pedido_id].push(row);
          } else {
            tempProductRequest[row.id_solicitud_pedido_id] = [row];
          }
        });
        setdataArray(data["solicitudes"]);
        setauxData(data["solicitudes"]);
        setlistPatient(tempPatient);
        setdictPatient(tempPatientDict);
        setlistComuna(templistComuna);
        setdictSendRequest(tempSendRequestDict);
        setdictProductRequest(tempProductRequest);
        setdictMedicinesSupplies(tempMedicinesSuppliesDict);
      })
    })
  };

  return (
    <div>
    <GridContainer>
      {loading ? (
        <GridItem
          container
          direction="row"
          justify="center"
          alignItems="center"

          xs={12}
        >
          {option === "stock" ? (
            <ViewStock
              auxData={auxData}
              barChartData={barChartData}
              demandStock={demandStock}
              startDate={startDate}
              endDate={endDate}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              process={process}
              setprocess={handleProcess}
              pending={pending}
              setpending={handlePending}
              patient={patient}
              listPatient={listPatient}
              dictPatient={dictPatient}
              handlePatient={handlePatient}
              typeRequest={typeRequest}
              handleTypeRequest={handleTypeRequest}
              listTypeRequest={listTypeRequest}
              comuna={comuna}
              handleComuna={handleComuna}
              listComuna={listComuna}
              selectItemTable={selectItemTable}
              handleItemTable={handleItemTable}
              getPickingState={getPickingState}
              getIterationNumber={getIterationNumber}
              getArea={getArea}
              handleSelectColumn={handleSelectColumn}
              selectColumn={selectColumn}
              handleItemTableDemandStock={handleItemTableDemandStock}
              selectItemTableDemandStock={selectItemTableDemandStock}
              demand={demand}
              stock={stock}
              diff={diff}
              diffMin={diffMin}
            />
          ) : (
            <div></div>
          )}
        </GridItem>
      ) : (
        <LoadInfo title={"Cargando información ..."} />
      )}
      <UpdateStockButton
        updateStock={updateStock}
      />
    </GridContainer>
    </div>
  );
};

export default DashboardBoss;
