import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import CloseIcon from '@material-ui/icons/Close';
import ConfirmacionSolicitudEquipo from "./ConfirmacionSolicitudEquipo";
import RechazoSolicitudEquipo from "./RechazoSolicitudEquipo";
import ModalRequestEquipment from "./ModalRequestEquipment";
import api from "utils/API";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 660,
    minHeight: 360,
    padding: "10px"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }

}));

const equipSorting = (dataFrame,listRequest) => {
  let newDataFrame = [];
  listRequest.forEach(item => {
    let filteredListPrority1 = dataFrame.filter(
      equipObject => equipObject.equipo === item.equipo
    );
    newDataFrame = [...newDataFrame, ...filteredListPrority1];
    let newGroupDataFrame = [...filteredListPrority1];
  });
  dataFrame.forEach(dataFrameElement => {
    let indexFinded = newDataFrame.findIndex(
      newDataFrameElement => dataFrameElement.sku === newDataFrameElement.sku
    );
    if (indexFinded === -1) {
      newDataFrame.push(dataFrameElement)
    }
  });
  return newDataFrame;
};

const ModalPreviewRequestEquipment = props => {
  const classes = useStyles();
  const { openModal, closeModal, tempRow, tipoTarea } = props;
  const [demandaDataFrame, setDemandaDataFrame] = React.useState([]);
  const [dataFrame, setDataFrame] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openAlertReject, setOpenAlertReject] = React.useState(false);
  const [openModalRequest, setOpenModalRequest] = React.useState(false);


  const getRequestEquipments = () => {
    setLoading(true)
    api
      .get(`api/resumen/solicitud/equipos/${tempRow.id_solicitud_paciente.id}`)
      .then(response => {
        let previewArrayDemanda = response.data.data;
        setDemandaDataFrame(previewArrayDemanda);
        const tempdict = previewArrayDemanda.reduce((accum, obj) => ({
          ...accum,
          [obj.id_equipo]: obj,
        }),{})
        setLoading(false)
        api.get(`/api/equipos/tipos/`).then(response => {
          let previewArray = response.data;
          let newDataFrame = previewArray.map((row, index) => {
            let objectFinded = tempdict[row.id]
            let fechaInicio = objectFinded?.fecha_inicio
              ? objectFinded?.fecha_inicio
              : "";
            let fechaFin = objectFinded?.fecha_fin
              ? objectFinded?.fecha_fin
              : "";
            return {
              checked: objectFinded === undefined ? false : true,
              id_equipo: row.id,
              equipo: row.descripcion,
              sku: row.SKU,
              marca: null,
              modelo_equipo: null,
              numero_serie: null,
              fecha_fin:
                fechaFin !== ""
                  ? moment(fechaFin, "YYYY-MM-DD").format("DD-MM-YYYY")
                  : "",
              fecha_inicio: fechaInicio,
              sku_especifico: objectFinded?.sku_especifico || " ",
              detalle_especifico: objectFinded?.detalle_especifico || " ",
            };
          });
          let sortedDataFrame = equipSorting(newDataFrame, previewArrayDemanda);
          console.log(sortedDataFrame)
          setDataFrame(sortedDataFrame)
        });
      });
  };
  const closeAlert = () =>{
    setOpenAlert(false)
  }
  const closeRejectAlert = () =>{
    setOpenAlertReject(false)
  }
  const handleConfirmRequest = ()=> {
    setOpenAlert(false)

    if (tipoTarea === "SolicitarEquipo") {
      api
        .post(`api/resumen/confirmar/solicitud/equipos/`, {
          id_solicitud: tempRow.id_solicitud_paciente.id,
          id_tarea: tempRow.id
        })
        .then(response => {

          closeModal()
          window.location.reload(true);
        });
    } else if (tipoTarea === "ReemplazarEquipo") {
      api
        .post(`api/resumen/confirmar/reemplazo/equipos/`, {
          id_solicitud: tempRow.id_solicitud_paciente.id,
          id_tarea: tempRow.id
        })
        .then(response => {

          closeModal()
          window.location.reload(true);
        });
    } else {
      api
        .post(`api/resumen/confirmar/retiro/equipos/`, {
          id_solicitud: tempRow.id_solicitud_paciente.id,
          id_tarea: tempRow.id
        })
        .then(response => {

          closeModal()
          window.location.reload(true);
        });
    }
  }
  const handleRejectRequest = ()=> {
    setOpenAlertReject(false)

    api
      .post(`api/resumen/rechazar/solicitud/equipos/`, {
        id_solicitud: tempRow.id_solicitud_paciente.id,
        id_tarea: tempRow.id
      })
      .then(response => {

        closeModal()
        window.location.reload(true);
      });
  };
  const handleOpenModifyRequest = () => {
    setOpenModalRequest(true);
  };
  const handleCloseModifyRequest = () => {
    setOpenModalRequest(false);
  };
  const handleAcceptRequest = () => {
    setOpenAlert(true);
  };

  React.useEffect(() => {
    if (tipoTarea === "SolicitarEquipo" || tipoTarea === "ReemplazarEquipo") {
      getRequestEquipments()
    } else {
      setLoading(true)
      api
        .get(`api/resumen/retiro/equipos/${tempRow.id_solicitud_paciente.id}`)
        .then(response => {
          let previewArrayDemanda=response.data.data;
          setDemandaDataFrame(previewArrayDemanda)
          setLoading(false)
        })
    }
  },[]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openModal}>
          <Card className={classes.root} variant="outlined">
            <CardHeader
              action={
                <IconButton
                  aria-label="delete"
                  onClick={closeModal}
                  size="medium"
                  style={{ textTransform: "none", outline: "none" }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              title="Resumen demanda y stock"
            />
            <CardContent>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Equipo</TableCell>
                      <TableCell align="left">SKU</TableCell>
                      <TableCell align="left">Especifico</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demandaDataFrame.map((row,index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{row.equipo}</TableCell>
                        <TableCell align="left">{row.sku}</TableCell>
                        <TableCell align="left">{row.sku_especifico !== " " ? `(${row.sku_especifico}) - ${row.detalle_especifico}` : ""}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {loading &&
                <Box p={2} display="flex" justifyContent="space-around" >
                  <LoadingSpinner />
                </Box>
              }
            </CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid container item xs={4} >
                  <Button
                    disabled={demandaDataFrame.length > 0 ? false : true}
                    variant="contained"
                    className="text-white"
                    color="primary"
                    onClick={handleAcceptRequest}
                  >
                    Aceptar{" "}
                    {tipoTarea === "ReemplazarEquipo"
                      ? "reemplazo"
                      : "solicitud"}
                  </Button>
                </Grid>
                {(tipoTarea === "SolicitarEquipo" ||
                  tipoTarea === "ReemplazarEquipo") && (
                  <Grid container item xs={3}>
                    <Button
                      disabled={demandaDataFrame.length>0 ? false:true}
                      variant="contained"
                      className="text-white"
                      onClick={handleOpenModifyRequest}
                      style={{
                        background:
                          demandaDataFrame.length > 0 ? "green" : "default"
                      }}
                    >
                      Editar
                    </Button>
                  </Grid>
                )}
                <Grid container item xs={2} >
                  <Button
                    variant="contained"
                    className="text-white"
                    color="secondary"
                    onClick={handleRejectRequest}
                  >
                    Rechazar
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
            <ConfirmacionSolicitudEquipo openAlert={openAlert} closeAlert={closeAlert} handleConfirmRequest={handleConfirmRequest}/>
            <RechazoSolicitudEquipo openAlertReject={openAlertReject} closeRejectAlert={closeRejectAlert} handleRejectRequest={handleRejectRequest}/>
            <ModalRequestEquipment
              openModal={openModalRequest}
              tipoTarea={tipoTarea}
              closeModal={handleCloseModifyRequest}
              demandaDataFrame={demandaDataFrame}
              setDemandaDataFrame={setDemandaDataFrame}
              dataFrame={dataFrame}
              setDataFrame={setDataFrame}
              tempRow={tempRow}
            />
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}
export default ModalPreviewRequestEquipment;