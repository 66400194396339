export const checkRut = (argRut) => {
    let cleanProviderId = argRut.split(' ').join('');
    const reg = /((\d{3}\.\d{3}\.\d{3})\-([\dkK])$).?/;
    if (reg.test(cleanProviderId) === false) {
        return "No cumple con formato de softland '000.000.000-0'";
    }
    const value = cleanProviderId.split('.').join('').replace('-','');
    const body = value.slice(0,-1);
    const verificationDigit = value.slice(-1).toUpperCase();
    cleanProviderId = body + '-' + verificationDigit;
    let sum = 0;
    let multiple = 2;
    for(let i=1;i<=body.length;i++) {
        let index = multiple * value.charAt(body.length - i);
        sum = sum + index;
        if(multiple < 7){
            multiple = multiple + 1;
        } else {
            multiple = 2;
        }
    }
    const expectedVerificationDigit = 11 - (sum % 11);
    let calculatedVerificationDigit = 0;
    calculatedVerificationDigit = (verificationDigit == 'K') ? 10 : verificationDigit;
    calculatedVerificationDigit = (verificationDigit == 0) ? 11 : calculatedVerificationDigit;
    if(expectedVerificationDigit != calculatedVerificationDigit) {
        return "RUT Inválido";
    }
    return "";
};

export const validateEmail = (email) => {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (reg.test(email) === false) {
        return "Email invalido";
    }
    return "";
};

export const validateCellPhone = (number) => {
    const reg =  /^(\+|\d{1,2})?[ -]*\d{6,15}$/;
    if (reg.test(number) === false) {
        return "Numero invalido";
    }
    return "";
}

export const validatePhone = (number) => {
    const reg =  /^(\+?56)?(0?2|0?3[2-5]|0?4[1-5]|0?5[123578]|0?6[13457]|0?7[1235])?(\d{6})$/;
    if (reg.test(number) === false) {
        return "Numero invalido";
    }
    return "";
}

export const validateDate = (date) => {
    const reg =  /^(\d{2})\-(\d{2})\-(\d{4})/;
    return reg.test(date);
}

export const dictMonth = {
    1  :	"enero",
    2  :	"febrero",
    3  :	"marzo",
    4  :	"abril",
    5  :	"mayo",
    6  :	"junio",
    7  :	"julio",
    8  :	"agosto",
    9  :	"septiembre",
    10 :    "octubre",
    11 :	"noviembre",
    12 :	"diciembre",
};

export const daysWeek = {
    1: "Lunes",
    2: "Martes",
    3: "Miercoles",
    4: "Jueves",
    5: "Viernes",
    6: "Sabado",
    0: "Domingo",
};

export const daysWeekShort = {
    1: "Lun",
    2: "Mar",
    3: "Mie",
    4: "Jue",
    5: "Vie",
    6: "Sab",
    0: "Dom",
};

export const daysList = [
    {
        label: "L",
        value: 1,
    },
    {
        label: "M",
        value: 2,
    },
    {
        label: "M",
        value: 3,
    },
    {
        label: "J",
        value: 4,
    },
    {
        label: "V",
        value: 5,
    },
    {
        label: "S",
        value: 6,
    },
    {
        label: "D",
        value: 0,
    },
];

export const dictError = {
    [400]: "Lo siento, no se pudo procesar su solicitud. Por favor, inténtelo de nuevo.",
    [401]: "Lo siento, no tiene permiso para acceder a este recurso. Por favor, inicie sesión e inténtelo de nuevo.",
    [403]: "Lo siento, no tiene permiso para acceder a este recurso.",
    [404]: "Lo siento, no se pudo encontrar la página que está buscando.",
    [405]: "Lo siento, el método de solicitud que está utilizando no es compatible con este recurso.",
    [408]: "Lo siento, el servidor tardó demasiado en responder. Por favor, inténtelo de nuevo.",
    [429]: "Lo siento, ha enviado demasiadas solicitudes en un corto período de tiempo. Por favor, espere unos minutos antes de intentarlo de nuevo.",
    [500]: "Lo siento, algo salió mal en nuestro extremo. Por favor, inténtelo de nuevo más tarde.",
    [502]: "Lo siento, algo salió mal en nuestro extremo. Por favor, inténtelo de nuevo más tarde.",
    [503]: "Lo siento, el servidor no está disponible actualmente. Por favor, inténtelo de nuevo más tarde.",
  }


