import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableActivas = props => {
  const classes = useStyles();
  const { files } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nombre</TableCell>
              <TableCell align="center">Cargo</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Días trabajador</TableCell>
              <TableCell align="center">Horas extras</TableCell>
              <TableCell align="center">Turnos extras</TableCell>
              <TableCell align="center">Att extras</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow key={row.id} align="center">
                    <TableCell align="center">
                      {row.nombres}{row.apellido_paterno}
                      {row.apellido_matero}
                    </TableCell>
                    <TableCell align="center"> - </TableCell>
                    <TableCell align="center"> - </TableCell>
                    <TableCell align="center"> - </TableCell>
                    <TableCell align="center"> - </TableCell>
                    <TableCell align="center">
                      {row.variables_contadas.TurnoExtra}
                    </TableCell>
                    <TableCell align="center">
                      {row.variables_contadas.AtencionExtra}
                    </TableCell>
                    <TableCell align="center">
                      <Link
                        href={`/admin/clinicendowmentsdetail/${row.id}`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none" }}
                        >
                          Detalle
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={files.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableActivas;

TableActivas.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  files: PropTypes.array
};
