import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const PositionFilter = props => {
  const { position, listPositions, handlePosition } = props;
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth>
      <InputLabel id="demo-simple-select-outlined-label">Cargo</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={position || ""}
        onChange={handlePosition}
        label="Cargo"
      >
        {listPositions.map(row => (
          <MenuItem key={row.id} value={row.id}>
            {row.nombre_corto}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PositionFilter;

PositionFilter.propTypes = {
  position: PropTypes.number,
  listPositions: PropTypes.array,
  handlePosition: PropTypes.func
};
