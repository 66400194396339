import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {green, indigo} from "@material-ui/core/colors";
import moment from "moment-timezone";
import api from "utils/API";
import { CSVLink } from "react-csv";
import {toMoneyFormat} from "utils/formatFunctions";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import "moment/locale/es";
moment.locale("es");



export default function ModalRemunerationDownload({
    open,
    handleClose,
    arrayChecked,
    changeButtons,
    setchangeButtons,
    monthDate,
}) {
    const [arrayTable, setarrayTable] = useState([]);
    const [headerTable, setheaderTable] = useState([]);
    const [headeArrray, setheaderArray] = useState([]);
    const [checkDownload, setcheckDownload] = useState(false);

    const openBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);

    useEffect(() => {
        getHeader();
    }, []);

    useEffect(() => {
        if (checkDownload){
            setcheckDownload(false);
        }
    }, );

    const getHeader = () => {
        api.get(`api/itemremuneracion/`).then((response) => {
            const orderData = response.data.sort((a,b) => (a.orden > b.orden ? 1: -1))
            const tempData = orderData.reduce((accum, obj) => ([
                ...accum,
                {
                    label: obj.item,
                    key: obj.id.toString(),
                    type: obj.tipo_valor
                }
            ]), []);

            const auxData = [{label: "Nombre", key: "Nombre", type: "string"},{label: "RUT", key: "RUT", type: "string"},...tempData]
            setheaderTable(auxData);
            setheaderArray(orderData);
            handleProcessDownload();
        });
    }

    const handleProcessDownload = () => {

        const tempArrayTable = [...arrayTable];
        const arrayRequest = [];
        
        arrayChecked.map((row, index)=>{
            arrayRequest.push(api.get(`api/remuneracion/fichapersonal/${row}/fecha/${moment(monthDate).format("YYYY-MM-DD")}`))
        });
        Promise.all(arrayRequest).then((request) => {
            request.map((response) => {
                const data = response.data
                try {
                    const auxData = data["lista_item_remuneracion"].reduce((accum, obj) => ({
                        ...accum,
                        [obj.id.toString()]: obj.valores.eliminado ? 0 : parseInt(obj.valores.valor_modificado)
                    }), {});
                    const auxRow = {
                        Nombre: data["remuneracion"]["nombre_completo"],
                        RUT: data["remuneracion"]["rut"],
                        ...auxData
                    };
                    tempArrayTable.push(auxRow);

                } catch (error) {
                    const auxRow = {
                        Nombre: "Error",
                        RUT: "Error",
                    }
                    tempArrayTable.push(auxRow);

                }
            });
            setarrayTable(tempArrayTable);
            setchangeButtons(true);
            setcheckDownload(true);
        });
    };

    const _content_ =  <div >
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item xs={12}
        >
            <Grid item xs={12}>
                <TableContainer >
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        <TableCell className="text-center align-middle">
                            NOMBRE
                        </TableCell>
                        <TableCell className="text-center align-middle">
                            RUT
                        </TableCell>
                        {headeArrray.map((rowHeader) => (
                            <TableCell className="text-center align-middle" style={{ minWidth: "150px", background: rowHeader.color, color: (rowHeader.color === "#ffc107" || rowHeader.color === "#ffef62") ? "#000000": "#fff" }}>
                            {rowHeader.item}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {arrayTable.map((row, index) => (
                            <TableRow
                            key={`${index}-workRows`}
                            >
                            {headerTable.map((rowHeader) => (
                                <TableCell className="text-center align-middle">
                                {row[rowHeader.key] ? `${rowHeader.type === "$" ? toMoneyFormat(row[rowHeader.key]) : row[rowHeader.key]}` : 0 }
                                </TableCell>
                            ))}
                            </TableRow>
                        ))}
                        {changeButtons === false && <TableRow></TableRow>}
                    </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid >
    </div>

    const _actions_ = <>
        {openBackdrop ?
        <Button
            disabled
        >
            Descargar Archivos
        </Button> :
        <CSVLink
            data={arrayTable}
            headers={headerTable}
            className="btn btn-primary"
            filename={`remuneraciones_${moment(monthDate).format("YYYY-MM-DD")}.csv`}
            style={{background:indigo[500]}}
            separator={";"}
        >
            Descargar Archivos
        </CSVLink>
        }
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            color="primary"
            onClick={handleClose}
            style={{margin: "0 5px"}}
        >
            Cerrar
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Remuneraciones`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xl"
        />
    );
}