import React, {useState, useEffect} from 'react';
import {
    Grid,
    TextField,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";
import { checkRut, validateEmail } from "../../utils/validationFunction";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function FormAttorneyData({data, setdata, id_patient, setmessage}) {
    const [nombres, setnombres] = useState(data?.nombres || "");
    const [apellido_paterno, setapellido_paterno] = useState(data?.apellido_paterno || "");
    const [apellido_materno, setapellido_materno] = useState(data?.apellido_materno || "");
    const [rut, setrut] = useState(data?.rut || "");
    const [rutError, setrutError] = useState("");
    const [email, setemail] = useState(data?.email || "");
    const [emailError, setemailError] = useState("");
    const [celular, setcelular] = useState(data?.celular || "");
    const [otro_telefono, setotro_telefono] = useState(data?.otro_telefono || "");
    const [validation, setvalidation] = useState(false);

    const type_user = localStorage.getItem("tipo_perfil");

    useEffect(() => {
        validateForm();
    });

    const validateForm = () => {
        const arrayValidation = [
            nombres !== "",
            apellido_paterno !== "",
            apellido_materno !== "",
            rut !== "",
            rutError === "",
            email !== "",
            emailError === "",
            celular !== "",
            otro_telefono !== "",
        ];
        if (arrayValidation.every((item) => item === true)) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    };

    const updateValue = () => {

        const body = {...data}
        body.nombres = nombres;
        body.apellido_paterno = apellido_paterno;
        body.apellido_materno = apellido_materno;
        body.rut = rut;
        body.email = email;
        body.celular = celular;
        body.otro_telefono = otro_telefono;

        if (data.id){
            api.put(`/api/tutor/${data.id}`, body).then((response) => {
                setdata(response.data);
                setmessage("Datos actualizados")
            });
        } else {
            body["id_paciente"] = id_patient;
            api.post(`/api/tutor/`, body).then((response) => {
                setdata(response.data)
                setmessage("Datos actualizados")
            });
        }

    };

    const handleOnChangeRut = (value, set) => {
        setrutError(checkRut(value))
        set(value.replace(/[^\dkK.-]/gi, ''));
    };

    const handleOnChangeEmail = (value, set) => {
        setemailError(validateEmail(value))
        set(value);
    };

    const restart = () => {
        setnombres(data?.nombres || "");
        setapellido_paterno(data?.apellido_paterno || "");
        setapellido_materno(data?.apellido_materno || "");
        setrut(data?.rut || "");
        setemail(data?.email || "");
        setcelular(data?.celular || "");
        setotro_telefono(data?.otro_telefono || "");
    };

    const listForm = [
        {
            disabled: type_user == "CLITecEnfBas",
            label:`Nombres Apoderado`,
            value: nombres,
            onChange: (e) => setnombres(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label:`Apellido Paterno Apoderado`,
            value: apellido_paterno,
            onChange: (e) => setapellido_paterno(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label:`Apellido Materno Apoderado`,
            value: apellido_materno,
            onChange: (e) => setapellido_materno(e.target.value),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label:`RUT`,
            value: rut,
            onChange: (e) => handleOnChangeRut(e.target.value, setrut),
            error: rutError,
            helperText: rutError,
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label:`Email Apoderado`,
            value: email,
            onChange: (e) => handleOnChangeEmail(e.target.value, setemail),
            error: emailError,
            helperText: emailError,
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label:`Celular Apoderado`,
            value: celular,
            onChange: (e) => setcelular(e.target.value.replace(/[^\d'+']/gi, '')),
            gridStyle: {padding: "15px"}
        },
        {
            disabled: type_user == "CLITecEnfBas",
            label:`Telefono Apoderado`,
            value: otro_telefono,
            onChange: (e) => setotro_telefono(e.target.value.replace(/[^\d'+']/gi, '')),
            gridStyle: {padding: "15px"}
        },
    ]

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            item
            xs={12}
        >
            {listForm.map((row) => (
            <Grid
                item
                xs={12}
                sm={6}
                md={4}
                style={row.gridStyle || {}}
            >
                {row._content_ ||
                <TextField
                    variant="outlined"
                    type="text"
                    error={row.value == ""}
                    helperText={row.value == "" ? "No puede ser vacio" : ""}
                    style={{ width: '100%' }}
                    {...row}

                />}
            </Grid>
            ))}
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                item
                xs={12}
            >
                { (validation && type_user !== "CLITecEnfBas" )?
                <Button
                    mr={2}
                    variant="contained"
                    className="text-white"
                    style={{backgroundColor: green[500]}}
                    onClick={updateValue}
                >
                    Guardar
                </Button>
                :
                <Button
                    disabled
                    mr={2}
                    variant="contained"
                >
                    Guardar
                </Button>
                }
                {type_user === "CLITecEnfBas" ?
                <Button
                    disabled
                    mr={2}
                    variant="contained"
                >
                    Reiniciar
                </Button> :
                 <Button
                    mr={2}
                    variant="contained"
                    style={{backgroundColor: red[500], marginRight: "10px"}}
                    className="text-white"
                    onClick={restart}
                >
                    Reiniciar
                </Button>
                }
            </Grid>
        </Grid>
    );
}