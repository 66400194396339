import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import PropTypes from "prop-types";

const ShowData = props => {
  const { graphicData, activos } = props;

  const setName = name => {
    switch (name) {
      case "FrecuenciaCardiaca":
        return "Frecuencia Cardiaca";
      case "FrecuenciaRespiratoria":
        return "Frecuencia Respiratoria";
      case "SaturacionOxigeno":
        return "Saturación Oxígeno";
      case "PresionArterialDiastolica":
        return "Presión Arterial Diastolica";
      case "PresionArterialSistolica":
        return "Presión Arterial Sistolica";
      case "PresionArterial":
        return "Presión Arterial Media";
      default:
        return name;
    }
  };
  const setMaxValue = name => {
    switch (name) {
      case "FrecuenciaCardiaca":
        return Math.max.apply(Math, graphicData.map(function(item) { return item.FrecuenciaCardiaca; }));
      case "FrecuenciaRespiratoria":
        return Math.max.apply(Math, graphicData.map(function(item) { return item.FrecuenciaRespiratoria; }));
      case "SaturacionOxigeno":
        return Math.max.apply(Math, graphicData.map(function(item) { return item.SaturacionOxigeno; }));
      case "PresionArterialDiastolica":
        return Math.max.apply(Math, graphicData.map(function(item) { return item.PresionArterialDiastolica; }));
      case "PresionArterialSistolica":
        return Math.max.apply(Math, graphicData.map(function(item) { return item.PresionArterialSistolica; }));
      case "PresionArterial":
        return Math.max.apply(Math, graphicData.map(function(item) { return item.PresionArterial; }));
      case "Temperatura":
        return Math.max.apply(Math, graphicData.map(function(item) { return item.Temperatura; }));
      case "Talla":
        return Math.max.apply(Math, graphicData.map(function(item) { return item.Talla; }));
      case "Peso":
        return Math.max.apply(Math, graphicData.map(function(item) { return item.Peso; }));
      case "Dolor":
        return Math.max.apply(Math, graphicData.map(function(item) { return item.Dolor; }));
      default:
        return 10;
    }
  };
  return (
    <LineChart
      width={800}
      height={500}
      data={graphicData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      {activos.length === 0 ? null : (
        <YAxis
          yAxisId="left"
          // domain={[0, Math.max(setMaxValue(activos[0].name), setMaxValue(activos[1]?.name)) ]}
          domain={[0, setMaxValue(activos[0].name) ]}
          label={{
            value: `${setName(activos[0].name)}`,
            angle: -90,
            position: "center",
            stroke: "#8884d8"
          }}
          width={100}
        />
      )}
      {activos.length === 2 ? (
        <YAxis
          yAxisId="right"
          orientation="right"
          // domain={[0, Math.max(setMaxValue(activos[0].name), setMaxValue(activos[1].name))]}
          domain={[0, setMaxValue(activos[0].name) ]}
          label={{
            value: `${setName(activos[1].name)}`,
            angle: -90,
            position: "center",
            stroke: "#82ca9d"
          }}
          width={100}
        />
      ) : null}
      <Tooltip />
      <Legend />
      {activos.length === 0 ? null : (
        <Line
          yAxisId="left"
          type="monotone"
          dataKey={activos[0].name}
          name={setName(activos[0].name)}
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      )}
      {activos.length === 2 ? (
        <Line
          yAxisId="right"
          type="monotone"
          dataKey={activos[1].name}
          name={setName(activos[1].name)}
          stroke="#82ca9d"
        />
      ) : null}
    </LineChart>
  );
};

export default ShowData;

ShowData.propTypes = {
  graphicData: PropTypes.array,
  activos: PropTypes.array
};
