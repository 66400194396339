import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const DivisionsBlock = ({moduleType}) => {
  return (
    <div className="mb-3 m-1">
      <div className="card">
        <div className="card-header text-center">
          Fraccionamiento y código de productos
        </div>
        <div className="card-body">
          <div className="row">
            <div className="text-center">
              <h3>12</h3>
              <div className="col-sm text-center">Fraccionamientos</div>
            </div>
            <div className="text-center">
              <h3>7</h3>
              <div className="col-sm text-center">Códigos de productos</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm text-center mt-3 mb-1">
              <Link to={`/admin/income/divisions/${moduleType}`}>
                <Button variant="contained" color="primary">
                  Ver detalle
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DivisionsBlock;
