import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import WarningIcon from "@material-ui/icons/Warning";
import NumberFormat from "react-number-format";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const Adjuntar = props => {
  const {
    transferPrice,
    setTransferPrice,
    approvalFile,
    setApprovalFile,
    viewOnly
  } = props;

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container justify="center" style={{ marginTop: "3em" }}>
        <Grid item container xs={6} justify="center">
          <Grid item container xs={12} justify="center">
            <Typography variant="caption" display="block" gutterBottom>
              <WarningIcon />
              Adjuntar aprobación de Isapre
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ marginTop: "1em" }}
          >
            <Grid item container xs={12} justify="center">
              {approvalFile != null ? (
                approvalFile[0].name
              ) : (
                <span></span>
              )}
            </Grid>
            <input
              id="contained-button-file-isapre"
              //multiple
              type="file"
              onChange={e => setApprovalFile(e.target.files)}
              hidden
              disabled={viewOnly ? true : false}
            />
            <label htmlFor="contained-button-file-isapre">
              <Button
                disabled={viewOnly ? true : false}
                variant="contained"
                color="primary"
                component="span"
                style={{ textTransform: "none" }}
              >
                <CloudUploadIcon />
                Adjuntar autorización de isapre
              </Button>
              {/*}
              <FormHelperText>
                {approvalFile === null ? (
                  <span className="text-danger">
                    Debes subir una autorización
                  </span>
                ) : null}
              </FormHelperText>
                */}
            </label>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ marginTop: "1em" }}
          >
            <TextField
              disabled={viewOnly ? true : false}
              label="Valor del traslado"
              value={transferPrice}
              onChange={e => setTransferPrice(parseInt(e.target.value))}
              name="numberformat"
              //id="standard-basic"
              id="formatted-numberformat-input"
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default Adjuntar;

Adjuntar.propTypes = {
  transferPrice: PropTypes.string,
  setTransferPrice: PropTypes.func,
  approvalFile: PropTypes.object,
  setApprovalFile: PropTypes.func,
  viewOnly: PropTypes.bool
};
