import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {indigo } from "@material-ui/core/colors";
import {
  Typography,
  Grid,
  Tooltip,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
  ArrowDropDown,
  ArrowDropUp,
} from "@material-ui/icons";

import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import {dictMonth} from "utils/validationFunction";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import {toMoneyFormat} from "utils/formatFunctions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStylesTable = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});
const useStyles = makeStyles(styles);

// eslint-disable-next-line react/prop-types
export default function BillingsPatientTable({
  dataArray,
  setdata,
  dictChecked,
  handleDetail,
  handleChange,
}) {
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [order, setorder] = useState("Fecha");
  const [direction, setdirection] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOrder = (value, attribute) => {
    let tempDirection = direction;
    if (value === order) {
      tempDirection = !direction;
    } else {
      tempDirection = false;
    }
    if (tempDirection) {
      setdata(dataArray.sort((a, b) => (a[attribute] < b[attribute] ? 1 : -1)));
    } else {
      setdata(dataArray.sort((a, b) => (a[attribute] > b[attribute] ? 1 : -1)));
    }
    setorder(value);
    setdirection(tempDirection);
  }

  const headerColumn = (
    atributteName,
    headerName,
    style = {},
  ) => {
    return (
      <Tooltip
        title={
          <React.Fragment>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography variant="body2" gutterBottom>
                {`Ordenar por ${headerName}`}
              </Typography>
            </Grid>
          </React.Fragment>
        }
      >
        <TableCell
          className="text-center align-top"
          style={{ minWidth: "85px", ...style }}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              item
              xs={10}
              onClick={() => handleOrder(headerName, atributteName)}
            >
              {headerName}
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              item
              xs={2}
              onClick={() => handleOrder(headerName, atributteName)}
            >
              {order === headerName && (
                <ArrowDropUp
                  style={{
                    margin: "-15px 0 -15px 0",
                    fill: indigo[direction ? 900 : 200]
                  }}
                />
              )}
              <ArrowDropDown
                style={{
                  margin: order === headerName ? "0 0 -15px 0" : "0",
                  fill: indigo[order === headerName && !direction ? 900 : 200]
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
      </Tooltip>
    );
  };

  const dateFormat = (row) => {
    const endDate = moment(row.fin_periodo);
    const startDate = moment(row.inicio_periodo);
    const startFormat = `${startDate.format("DD")} de ${dictMonth[parseInt(startDate.format("MM"))]} ${startDate.format("YYYY")}`;
    const endFormat = `${endDate.format("DD")} de ${dictMonth[parseInt(endDate.format("MM"))]} ${endDate.format("YYYY")}`;
    return `${startFormat} al ${endFormat}`;
  };


  return (
    <Paper className={classesTable.root}>
      <TableContainer className={classesTable.container} style={{height: "440px"}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumn(
                "nombre_paciente",
                "Nombre paciente",
                { width: "200px" },
              )}
              {headerColumn(
                "tipo_paciente",
                "Tipo de paciente",
                { minWidth: "170px" },
              )}
              {headerColumn(
                "nombre_isapre",
                "Isapre",
                { minWidth: "100px" },
              )}
              {headerColumn(
                "monto_facturado_modificado",
                "Monto facturado total",
                { minWidth: "160px" },
              )}
              <TableCell className="text-center align-top" style={{minWidth: "150px"}}>
                Periodo facturado
              </TableCell>
              <TableCell className="text-center align-top">
                Accion
              </TableCell>
              <TableCell className="text-center align-top">
                Seleccionar
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataArray
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={`${index}-workRows`}
                >
                  <TableCell className="text-center align-middle">
                    {row.nombre_paciente}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.tipo_paciente}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.nombre_isapre}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {`${toMoneyFormat(row.monto_facturado_modificado+row.paquete_insumos+row.paquete_medicamentos) }`}
                  </TableCell>
                  <TableCell className="text-center align-top">
                    {dateFormat(row)}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    <Button
                      style={{ background: indigo[500] }}
                      className="text-white"
                      onClick={() => handleDetail(row)}
                    >
                      Detalle
                    </Button>
                  </TableCell>
                  <TableCell className="text-center align-middle">
                      <Checkbox
                        value="remember"
                        checked={dictChecked[row.id_ficha_paciente_id] ? true : false}
                        onChange={event =>
                          handleChange(event.target.checked, row)
                        }
                        color="primary"
                      />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dataArray.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />


    </Paper>
  );
}
