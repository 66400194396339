import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import api from "utils/API";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from "moment-timezone";
import "moment/locale/es";
import { checkRut, validateEmail, validateCellPhone, validatePhone } from "../../utils/validationFunction";
import { setmessageGeneral } from "actions/getGlobalAction";
moment.locale("es");

export default function ModalCreateTENS({
    open,
    handleClose,
    setarrayWorker,
    arrayWorker,
}) {
    const dispatch = useDispatch();
    const [nameTENS, setnameTENS] = useState("");
    const [secondNameTENS, setsecondNameTENS] = useState("");
    const [lastNameTENS, setlastNameTENS] = useState("");
    const [secondLastNameTENS, setsecondLastNameTENS] = useState("");
    const [rutTENS, setrutTENS] = useState("");
    const [errorRutTENS, seterrorRutTENS] = useState("");
    const [codeTENS, setcodeTENS] = useState("");
    const [errorCodeTENS, seterrorCodeTENS] = useState("");
    const [emailTENS, setemailTENS] = useState("");
    const [errorEmailTENS, seterrorEmailTENS] = useState("");
    const [phoneTENS, setphoneTENS] = useState("");
    const [errorPhoneTENS, seterrorPhoneTENS] = useState("");
    const [cellPhoneTENS, setcellPhoneTENS] = useState("");
    const [errorCellPhoneTENS, seterrorCellPhoneTENS] = useState("");
    const [validateInfo, setvalidateInfo] = useState(false);
    const [generalError, setgeneralError] = useState("");

    useEffect(() => {
        if (codeTENS === "" && rutTENS === ""){
            seterrorRutTENS("Debe ingresar rut o identificador");
            seterrorCodeTENS("Debe ingresar rut o identificador");
        } else if (errorRutTENS === errorCodeTENS || errorRutTENS === "") {
            seterrorRutTENS("");
            seterrorCodeTENS("");
        }
    });

    useEffect(() => {
        const tempValidate = [
            nameTENS !== "",
            lastNameTENS !== "",
            emailTENS !== "",
            cellPhoneTENS !== "",
            errorRutTENS === "",
            errorEmailTENS === "",
            errorCellPhoneTENS === "",
            errorPhoneTENS === "",
            codeTENS !== "" || rutTENS !== "",
        ].every((item) => (item === true));
        setvalidateInfo(tempValidate);
    }, [nameTENS, lastNameTENS, emailTENS, phoneTENS, cellPhoneTENS]);

    const handleValidateRut = (value) => {
        const valueCheck = checkRut(value);
        setrutTENS(value);
        if (value === "") {
            seterrorRutTENS("");
        } else if (valueCheck === "") {
            seterrorRutTENS("");
        }  else {
            seterrorRutTENS(valueCheck);
        }
    }

    const handleValidateEmail = (value) => {
        const valueCheck = validateEmail(value);
        setemailTENS(value);
        if (value === "") {
            seterrorEmailTENS("");
        } else if (valueCheck === "") {
            seterrorEmailTENS("");
        }  else {
            seterrorEmailTENS(valueCheck);
        }
    }

    const handleValidatePhone = (value) => {
        const valueCheck = validatePhone(value);
        setphoneTENS(value);
        if (value === "") {
            seterrorPhoneTENS("");
        } else if (valueCheck === "") {
            seterrorPhoneTENS("");
        }  else {
            seterrorPhoneTENS(valueCheck);
        }
    }

    const handleValidateCellPhone = (value) => {
        const valueCheck = validateCellPhone(value);
        setcellPhoneTENS(value);
        if (value === "") {
            seterrorCellPhoneTENS("");
        } else if (valueCheck === "") {
            seterrorCellPhoneTENS("");
        }  else {
            seterrorCellPhoneTENS(valueCheck);
        }
    }

    const handleAddTens = () =>  {
        const body = {
            nombre: nameTENS,
            segundo_nombre: secondNameTENS || " ",
            apellido_paterno: lastNameTENS,
            apellido_materno: secondLastNameTENS,
            rut: rutTENS || " ",
            codigo: codeTENS || " ",
            email: emailTENS,
            telefono: phoneTENS || " ",
            celular: cellPhoneTENS,
            cargo: "CLITecEnfBas",
        }
        api.post("appauth/crearprofesional/", body).then((response) => {
            const tempWorker = [...arrayWorker]
            tempWorker.push({...response.data});
            setarrayWorker(tempWorker);
            setmessageGeneral(dispatch, `Usuario creado los datos fueron enviados al siguiente correo electronico ${emailTENS}`, "success", "¡Exito!");
            handleClose()
        }).catch((error) => {
            setmessageGeneral(dispatch, "Error al crear usuario", "error", "¡Error!");
            if (error.response) {
                setgeneralError(error.response.data);
            }
        });
    };

    const formTens = [
        {_value_: <TextField
            error={nameTENS === ""}
            label="*Primer Nombre"
            helperText={"Campo obligatorio"}
            variant="outlined"
            onChange={(e) => setnameTENS(e.target.value)}
        />, size: 6},
        {_value_: <TextField
            label="Segundo Nombre"
            variant="outlined"
            onChange={(e) => setsecondNameTENS(e.target.value)}
        />, size: 6},
        {_value_: <TextField
            error={lastNameTENS === ""}
            label="*Primer Apellido"
            helperText="Campo obligatorio"
            variant="outlined"
            onChange={(e) => setlastNameTENS(e.target.value)}
        />, size: 6},
        {_value_: <TextField
            label="Segundo Apellido"
            variant="outlined"
            onChange={(e) => setsecondLastNameTENS(e.target.value)}
        />, size: 6},
        {_value_: <TextField
            error={errorRutTENS !== ""}
            label="Rut"
            helperText={errorRutTENS}
            variant="outlined"
            onChange={(e) => handleValidateRut(e.target.value)}
            placeholder={`Ej: 012.345.678-9`}
        />, size: 6},
        {_value_: <TextField
            error={errorCodeTENS !== ""}
            label="Identificador"
            helperText={errorCodeTENS}
            variant="outlined"
            onChange={(e) => setcodeTENS(e.target.value)}
        />, size: 6},
        {_value_: <TextField
            error={errorEmailTENS !== "" || emailTENS === ""}
            label="*Email"
            helperText={errorEmailTENS !== "" ? errorEmailTENS :  emailTENS === "" ? "Campo obligatorio" : ""}
            variant="outlined"
            onChange={(e) => handleValidateEmail(e.target.value)}
            style={{width: "100%"}}
        />, size: 12},
        {_value_: <TextField
            error={errorPhoneTENS !== ""}
            label="Teléfono"
            helperText={errorPhoneTENS}
            variant="outlined"
            onChange={(e) => handleValidatePhone(e.target.value)}
        />, size: 6},
        {_value_: <TextField
            error={errorCellPhoneTENS !== "" || cellPhoneTENS === ""}
            label="*Celular"
            helperText={errorCellPhoneTENS !== "" ? errorCellPhoneTENS : cellPhoneTENS === "" ? "Campo obligatorio" : "" }
            variant="outlined"
            onChange={(e) => handleValidateCellPhone(e.target.value)}
        />, size: 6},

    ]

    const _content_ = <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            item
            xs={12}
        >
            {formTens.map((row, index) => (
            <Grid
                key={`index-form-${index}`}
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={row.size || 12}
                style={{padding: "10px 5px"}}
            >
                {row._value_}
            </Grid>
            ))}

        { generalError &&
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{padding: "10px 5px"}}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{padding: "10px 5px"}}
            >
                <Typography variant="body2" color="error"> Errores </Typography>
            </Grid>
            {Object.keys(generalError).map((row, index) => (
                <Grid
                    key={`index-error-grid-${index}`}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{padding: "5px 5px"}}
                >
                    <Typography variant="body2" color="error">{`${row}: ${generalError[row]}`} </Typography>
                </Grid>
             ))}
        </Grid>
        }
        </Grid>
    </div>

    const _actions_ = <>
        {validateInfo ?
        <Button
            mr={2}
            variant="contained"
            style={{
                backgroundColor: green[500],
                color: green[50],

            }}
            onClick={handleAddTens}
        >
            CREAR TENS
        </Button> :
        <Button
            disabled
            mr={2}
            variant="contained"
        >
          CREAR TENS
        </Button> }
        <Button
            mr={2}
            variant="contained"
            style={{
                backgroundColor: red[500],
                color: red[50],
            }}
            onClick={handleClose}
        >
            CERRAR
        </Button>

    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={"Crear TENS"}
            maxWidth="xs"
            _content_={_content_}
            _actions_ = {_actions_}
        />

    );
}