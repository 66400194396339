import React, { useState, useEffect } from "react";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailText from "views/Widgets/FichaPersonal/EditFichaPersonal/detailText";
import ModalDialog from "views/Widgets/Common/ModalDialog";


export default function ModalCreateCovenant(props) {
  const { open, closeModal } = props;

  const [isapre, setIsapre] = useState("");
  const [nombre, setNombre] = useState("");

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  let list_isapres = [];
  const [get_isapres, setGet_isapres] = useState([]);

  useEffect(() => {
    api.get("api/convenioisapre/").then(response => {
      setGet_isapres(response.data);
    });
  }, []);

  get_isapres.map(isapre => {
    list_isapres.push({ value: isapre.id, label: isapre.nombre });
  });

  function createTherapy() {
    const formData = new FormData();

    if (isapre) {
      formData.append("id_convenio_isapre", isapre.value);
    }
    formData.append("nombre", nombre);
    formData.append("estado", true);

    apiform
      .post("api/paquetizaciones/", formData)
      .then(response => {
        window.location.href = "/admin/convenants/";
      })
  }

  const handleIsapre = isapre => {
    setIsapre(isapre);
  };

  const _content_ = <div className="form-row center" style={{ marginBottom: "8rem" }}>
    <DetailSelect
      label="Isapre:"
      value={isapre}
      onchange={handleIsapre}
      list={list_isapres}
    />
    <DetailText
      label="Nombre:"
      placeholder="Nombre"
      value={nombre}
      maxlength="45"
      onchange={(e) => {
        setNombre(e.target.value);
      }}
    />
  </div>

  const _actions_ = <>
   <Button
      onClick={closeModal}
      color="primary"
      variant="contained"
    >
      Cancelar
    </Button>
    <Button
      onClick={createTherapy}
      color="primary"
      variant="contained"
    >
      Crear
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={"Crear paquetización"}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}

ModalCreateCovenant.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_paquetizacion: PropTypes.number
};
