import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import { useParams } from "react-router-dom";
import CardTitle from "./FichaPersonal/cardTitle";
import ViewTitle from "./FichaPersonal/viewTitle";
import DetailSelect2 from "./FichaPaciente/detailSelect";
import DetailFileView from "./FichaPersonal/ViewFichaPersonal/detailFile";
import LoadingSpinner from "./FichaPersonal/loadingSpinner";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Typography,
  Tab,
  Tabs,
  AppBar,
  TextField,
  InputAdornment,
} from "@material-ui/core/";
import {AttachMoney} from '@material-ui/icons/';
import {CustomDatePicker, CustomSelectAutoComplete, CustomCheckBox  } from "utils/componentsStyle";
import {onlyNumbers} from "utils/formatFunctions"
import DetailFileMultiple from "./FichaPersonal/EditFichaPersonal/detailFileMultiple";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import { Link } from "react-router-dom";
import moment from "moment-timezone";
import "moment/locale/es";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Edit = (props) => {
  const classes = useStyles();

  const { id } = useParams();


  const [apellido_paterno, setApellido_paterno] = useState("");
  const [apellido_materno, setApellido_materno] = useState("");
  const [nombres, setNombres] = useState("");
  const [rut, setRut] = useState("");
  const [sexo, setSexo] = useState("");
  const [estado_civil, setEstado_civil] = useState("");
  const [direccion_calle, setDireccion_calle] = useState("");
  const [direccion_numero, setDireccion_numero] = useState("");
  const [direccion_block, setDireccion_block] = useState("");
  const [direccion_departamento, setDireccion_departamento] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [nacionalidad, setNacionalidad] = useState("");
  const [nacimiento, setNacimiento] = useState("");
  const [email, setEmail] = useState("");
  const [emailInstitutional, setEmailInstitutional] = useState("");
  const [banco, setBanco] = useState("");
  const [cuenta_banco, setCuenta_banco] = useState("");
  const [tipo_cuenta_banco, setTipo_cuenta_banco] = useState("");
  const [nivel_estudios, setNivel_estudios] = useState("");
  const [estado_estudios, setEstado_estudios] = useState("");
  const [titulo, setTitulo] = useState("");
  const [REG, setREG] = useState("");
  const [RCM, setRCM] = useState("");
  const [fecha_inicio_contrato, setFecha_inicio_contrato] = useState("");
  const [fecha_fin_contrato, setFecha_fin_contrato] = useState("");
  const [tipo_contrato, setTipo_contrato] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [id_cargo, setId_cargo] = useState("");

  const [lista_regiones, setlista_regiones] = useState([]);
  const [asignacion_telefono, setAsignacion_telefono] = useState(false);
  const [asignacion_uniforme, setAsignacion_uniforme] = useState(false);
  const [vigencia, setvigencia] = useState({});
  const [asignacion_equipo, setAsignacion_equipo] = useState(false);
  const [nombre_isapre, setNombre_isapre] = useState("");
  const [valor_pactado_isapre, setValor_pactado_isapre] = useState(0);
  const [funciones, setFunciones] = useState("");
  const [id_lista_afp, setId_lista_afp] = useState("");

  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState("");

  const [area, setArea] = useState();
  const [valid, setValid] = useState(true);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [region, setRegion] = useState("");
  const [idRegion, setIdRegion] = useState("");
  const [flagRegion, setFlagRegion] = useState(false);
  const [listComunas, setlistComunas] = useState([]);
  const [comuna, setComuna] = useState("");
  const [telefono1, setTelefono1] = useState("");
  const [telefono2, setTelefono2] = useState("");
  const [telefono3, setTelefono3] = useState("");
  const [tipoPago, setTipoPago] = useState("");
  const [listUser, setlistUser] = useState([]);
  const [listConveniosIsapre, setlistConveniosIsapre] = useState([]);
  const [convenioIsapre, setConvenioIsapre] = useState("");
  const [getDocumentos, setGetDocumentos] = useState([]);
  const [filesFicha, setFilesFicha] = useState([]);
  let sendFilesFicha = [...filesFicha];

  const [selectedBoss, setselectedBoss] = useState([])
  const [tipo_gratificacion, settipo_gratificacion] = useState({})
  const [dictVariablesPersonales, setdictVariablesPersonales] = useState({})



  useEffect(() => {
    if (flagRegion) {
      setFlagRegion(false);
      loadComunas();
    }

  });

  const list_vigencia = [
    {value: true, label: "Activo"},
    {value: false, label: "Inactivo"}
  ]

  const listTypeGratification = useSelector(state => state.currentList.LISTA_TIPO_GRATIFICACION);
  const dictTypeGratification = useSelector(state => state.currentList.DICCIONARIO_TIPO_GRATIFICACION);
  const listTipoPago = useSelector(state => state.currentList.LISTA_TIPO_PAGO);
  const list_cargo = useSelector(state => state.currentList.listCharges);
  const dict_cargo = useSelector(state => state.currentList.dictCharges);
  const list_afp = useSelector(state => state.currentList.listAFP)
  const list_sexo = useSelector(state => state.currentList.LISTA_SEXO)
  const list_estado_civil = useSelector(state => state.currentList.LISTA_ESTADO_CIVIL)
  const list_nacionalidad = useSelector(state => state.currentList.LISTA_NACIONALIDAD)
  const list_banco = useSelector(state => state.currentList.LISTA_BANCO)
  const list_tipo_cuenta_banco = useSelector(state => state.currentList.LISTA_CUENTA_BANCO)
  const list_nivel_estudios = useSelector(state => state.currentList.LISTA_TIPO_ESTUDIOS)
  const list_estado_estudios = useSelector(state => state.currentList.LISTA_ESTADO_ESTUDIO)
  const list_tipo_contrato = useSelector(state => state.currentList.LISTA_TIPO_CONTRATO)
  const list_empresa = useSelector(state => state.currentList.LISTA_EMPRESA)
  const list_isapres = useSelector(state => state.currentList.LISTA_ISAPRES)
  const list_variables_personal = useSelector(state => state.currentList.LISTA_VARIABLES_PERSONAL)
  const lista_key = useSelector(state => state.currentList.LISTA_KEY_AUXILIARES)

  const loadComunas = () => {
    const getComuna = api.get(`api/locaciones/regiones/${idRegion}/comunas/`);
    Promise.all([getComuna])
      .then((response) => {
        const templistComunas = []
        response[0].data.map((row) => {
          templistComunas.push({ value: row.id, label: row.nombre });
        });
        setlistComunas(templistComunas);
      })
  };

  const checkList = (lists) => {
    return lists.every((list) =>{
      return list && list.length >0
    } )
  }

  useEffect(() => {
    const requestJefatura = api.get(`api/personal/jefatura/ficha/${id}`)
    const requestConvenioIsapre = api.get("api/convenioisapre/");
    const requestUsuarios = api.get(`api/usuarios/plataforma/`);
    const requestRegiones = api.get("api/locaciones/regiones/");
    api.get(`api/personal/ficha/${id}/documentos/`).then((response) => {
      setGetDocumentos(response.data);
    })
    Promise.all([
      requestJefatura,
      requestConvenioIsapre,
      requestUsuarios,
      requestRegiones,
    ]).then((request) => {
      setselectedBoss(request[0].data.reduce((accum, obj) => ([
        ...accum,
        {value: obj.id, label: `${obj.first_name} ${obj.last_name}`}
      ]), []));
      setlistConveniosIsapre(request[1].data.reduce((accum, obj) => ([
        ...accum,
        { value: obj.id, label: obj.nombre },
      ]), []));
      const tempDictUser = {};
      const tempListUser = request[2].data.map((row) => {
        tempDictUser[row.id] = row
        return {value: row.id, label: `${row.first_name} ${row.last_name}`}
      });
      setlistUser(tempListUser);
      setlista_regiones(request[3].data.reduce((accum, obj) => ([
        ...accum,
        { value: obj.id, label: obj.nombre },
      ]), []));
    });
  }, []);

  useEffect(() => {
    if (checkList([list_variables_personal])){
      api.get(`api/personal/ficha/${id}/variables/`).then((response) => {
        const tempDictVar = list_variables_personal.reduce((accum, obj) => {
          const tempVar = response.data.filter((filtered) => filtered.codigo_variable === obj.value)[0]
          return {
            ...accum,
            [obj.value]: tempVar,
          }
        }, {})

        setdictVariablesPersonales(tempDictVar)
      });
    }
  }, [list_variables_personal]);

  useEffect(() => {
    setLoading(false);
    if (checkList([lista_key, list_cargo, list_afp])){
      api.get(`api/personal/jefatura/ficha/${id}`).then((response) => {
        setselectedBoss(response.data.reduce((accum, obj) => ([
          ...accum,
          {value: obj.id, label: `${obj.first_name} ${obj.last_name}`}
        ]), []));
      });
      api.get(`api/fichapersonal/${id}`).then((result) => {
        const fileInfo = result.data
        const tempCargo = dict_cargo[fileInfo.id_cargo];
        setApellido_paterno(fileInfo.apellido_paterno);
        setApellido_materno(fileInfo.apellido_materno);
        setNombres(fileInfo.nombres);
        setRut(fileInfo.rut);
        setSexo(list_sexo.filter((row)=> row.value === fileInfo.sexo)[0]);

        settipo_gratificacion(dictTypeGratification[fileInfo.tipo_gratificacion] ? dictTypeGratification[fileInfo.tipo_gratificacion] : {})
        setEstado_civil(list_estado_civil.filter((row)=> row.value === fileInfo.estado_civil)[0]);
        setDireccion_calle(fileInfo.direccion_calle);
        setDireccion_numero(fileInfo.direccion_numero);
        setDireccion_block(fileInfo.direccion_block);
        setDireccion_departamento(fileInfo.direccion_departamento);
        setCiudad(fileInfo.ciudad);
        setNacionalidad(list_nacionalidad.filter((row)=> row.value === fileInfo.nacionalidad)[0]);
        setNacimiento(fileInfo.nacimiento);
        setEmail(fileInfo.email);
        setEmailInstitutional(fileInfo.email_institucional);
        setBanco(list_banco.filter((row)=> row.value === fileInfo.banco)[0]);
        setCuenta_banco(fileInfo.cuenta_banco);
        setTipo_cuenta_banco(list_tipo_cuenta_banco.filter((row)=> row.value === fileInfo.tipo_cuenta_banco)[0]);
        setNivel_estudios(list_nivel_estudios.filter((row)=> row.value === fileInfo.nivel_estudios)[0]);
        setEstado_estudios(list_estado_estudios.filter((row)=> row.value === fileInfo.estado_estudios)[0]);
        setTitulo(fileInfo.titulo);
        setFecha_inicio_contrato(fileInfo.fecha_inicio_contrato);
        setFecha_fin_contrato(fileInfo.fecha_fin_contrato);
        setTipo_contrato(list_tipo_contrato.filter((row)=> row.value === fileInfo.tipo_contrato)[0]);
        setEmpresa(list_empresa.filter((row)=> row.value === fileInfo.empresa)[0]);
        setAsignacion_telefono(fileInfo.asignacion_telefono);
        setAsignacion_uniforme(fileInfo.asignacion_uniforme);
        if (fileInfo.vigencia){
          setvigencia({value: true, label: "Activo"});
        } else {
          setvigencia({value: false, label: "Inactivo"});
        }

        console.log("check")

        setAsignacion_equipo(fileInfo.asignacion_equipo);
        setNombre_isapre(list_isapres.filter((row)=> row.value === fileInfo.nombre_isapre)[0]);
        setValor_pactado_isapre(fileInfo.valor_pactado_isapre || 0);
        setFunciones(fileInfo.funciones);
        setId_lista_afp(list_afp.filter((row)=> row.value === fileInfo.id_lista_afp)[0]);
        setId_cargo(tempCargo ? {value: tempCargo.id, label: tempCargo.nombre_corto} : {} );
        setArea(tempCargo ? tempCargo.gerencia : "");
        setStatus(fileInfo.status);
        setRegion({
          value: fileInfo.id_region,
          label: fileInfo.direccion_region,
        });
        setIdRegion(fileInfo.id_region)
        setComuna({
          value: fileInfo.id_comuna,
          label: fileInfo.direccion_comuna,
        });
        setTelefono1(fileInfo.telefono_1);
        setTelefono2(fileInfo.telefono_2);
        setTelefono3(fileInfo.telefono_3);
        setTipoPago({
          value: fileInfo.tipo_pago,
          label: fileInfo.tipo_pago,
        });
        setConvenioIsapre(listConveniosIsapre.filter((row)=> row.value ===  fileInfo.id_convenio_isapre)[0]);
        if (fileInfo?.id_region){
          setFlagRegion(true);
        }
        setREG(fileInfo.REG)
        setRCM(fileInfo.RCM)
        setLoading(true);
      })

      api.get(`api/personal/ficha/${id}/documentos/`).then((response) => {
        setGetDocumentos(response.data);
      })
    }
  }, [id, lista_key, list_cargo, list_afp]);;


  function uploadWithFormData(request) {
    const formData = new FormData();

    if (apellido_paterno){
      formData.append("apellido_paterno", apellido_paterno);
    }
    if (apellido_materno){
      formData.append("apellido_materno", apellido_materno);
    }
    if (nombres){
      formData.append("nombres", nombres);
    }
    if (rut){
      formData.append("rut", rut);
    }
    if (sexo?.value) {
      formData.append("sexo", sexo.value);
    }
    if (estado_civil?.value) {
      formData.append("estado_civil", estado_civil.value);
    }
    if (direccion_calle){
      formData.append("direccion_calle", direccion_calle);
    }
    if (direccion_numero){
      formData.append("direccion_numero", direccion_numero);
    }
    if (direccion_block){
      formData.append("direccion_block", direccion_block);
    }
    if (direccion_departamento){
      formData.append("direccion_departamento", direccion_departamento);
    }
    if (ciudad){
      formData.append("ciudad", ciudad);
    }
    if (nacionalidad?.value) {
      formData.append("nacionalidad", nacionalidad.value);
    }
    if (nacimiento) {
      formData.append("nacimiento", nacimiento);
    }
    if (email){
      formData.append("email", email);
    }
    if (emailInstitutional){
      formData.append("email_institucional", emailInstitutional);
    }
    if (tipoPago?.value && tipoPago?.value !== "No Ingresado") {
      formData.append("tipo_pago", tipoPago.value);
    }
    if (banco?.value) {
      formData.append("banco", banco.value);
    }
    if (cuenta_banco){
      formData.append("cuenta_banco", cuenta_banco);
    }
    if (tipo_cuenta_banco?.value) {
      formData.append("tipo_cuenta_banco", tipo_cuenta_banco.value);
    }
    if (nivel_estudios?.value) {
      formData.append("nivel_estudios", nivel_estudios.value);
    }
    if (estado_estudios?.value) {
      formData.append("estado_estudios", estado_estudios.value);
    }
    if (titulo){
      formData.append("titulo", titulo);
    }
    if (fecha_inicio_contrato) {
      formData.append("fecha_inicio_contrato", fecha_inicio_contrato);
    }
    if (fecha_fin_contrato) {
      formData.append("fecha_fin_contrato", fecha_fin_contrato);
    }
    if (tipo_contrato?.value) {
      formData.append("tipo_contrato", tipo_contrato.value);
    }
    if (empresa?.value) {
      formData.append("empresa", empresa.value);
    }

    if (tipo_gratificacion?.value) {
      formData.append("tipo_gratificacion", tipo_gratificacion.value);
    }

    if (REG){
      formData.append("REG", REG);
    }
    if (RCM){
      formData.append("RCM", RCM);
    }

    formData.append("vigencia", vigencia.value);

    formData.append("asignacion_telefono", asignacion_telefono);


    formData.append("asignacion_uniforme", asignacion_uniforme);


    formData.append("asignacion_equipo", asignacion_equipo);

    if (nombre_isapre?.value) {
      formData.append("nombre_isapre", nombre_isapre.value);
    }
    if (funciones){
      formData.append("funciones", funciones);
    }
    if (telefono1){
      formData.append("telefono_1", telefono1);
    }
    if (telefono2){
      formData.append("telefono_2", telefono2);
    }
    if (telefono3){
      formData.append("telefono_3", telefono3);
    }
    if (region?.value) {
      formData.append("id_region", region.value);
    }
    if (comuna?.value) {
      formData.append("id_comuna", comuna.value);
    }
    if (id_lista_afp?.value) {
      formData.append("id_lista_afp", id_lista_afp.value);
    }
    if (convenioIsapre?.value) {
      formData.append("id_convenio_isapre", convenioIsapre.value);
    }
    if (id_cargo?.value) {
      formData.append("id_cargo", id_cargo.value);
    }
    if (valor_pactado_isapre){
      formData.append("valor_pactado_isapre", valor_pactado_isapre);
    }



    if (sendFilesFicha.length > 0) {
      sendFilesFicha.map((ficha) => {
        const formData1 = new FormData();

        formData1.append("nombre_archivo", ficha.name);
        formData1.append("tipo_archivo", ficha.type);
        formData1.append("archivo", ficha);
        formData1.append("id_ficha_personal", id);
        apiform
          .post("api/documentospersonal/", formData1)
          .then((response) => console.log(response))
          .catch((error) => console.log("documentospersonal", error.response));
      });
    }

    let arrayBulk = [];
    if (request) {
      apiform
        .patch(`api/fichapersonal/${id}`, formData)
        .then((result) => {
          api.put(`api/solicitudesrealizadas/${localStorage.getItem("request_id")}`, {
              id_cuenta_gestionando: localStorage.getItem("user_id"),
              id_tipo_solicitud: localStorage.getItem("request_type"),
            }
          ).then((resp) => {
            alert("Se modificó correctamente");
            window.location.href = `/admin/personalfile/manage/${id}`;
          })
          .catch((err) => {
            console.log(err.response);
          });
        })

    } else {
      apiform.patch(`api/fichapersonal/${id}`, formData).then((result) => {
        list_variables_personal.filter((row) => dictVariablesPersonales[row.value]?.valor).map((row) => {
          const tempVariable = dictVariablesPersonales[row.value]
          arrayBulk.push({
            codigo_variable: row.value,
            nombre: row.label,
            valor: tempVariable?.valor || 0,
            id_personal: id,
            id: tempVariable.id || null,
          })
        })

        if (sendFilesFicha.length > 0) {
          let count = 0;
          sendFilesFicha.map((ficha) => {
            const formData1 = new FormData();

            formData1.append("nombre_archivo", ficha.name);
            formData1.append("tipo_archivo", ficha.type);
            formData1.append("archivo", ficha);
            formData1.append("id_ficha_personal", id);
            apiform.post("api/documentospersonal/", formData1).then((response) => {
                console.log(response);
                count = count + 1;
                if (count === sendFilesFicha.length) {
                  api.put("api/personal/ficha/variables/bulk/", arrayBulk).then((response) => {
                      console.log(response);
                      alert("Se modificó correctamente");
                      window.location.href = `/admin/personalfile/manage/${id}`;
                    })
                    .catch((error) => console.log("bulk", error.response));
                  console.log(result);
                }
              })
              .catch((error) =>
                console.log("documentospersonal", error.response)
              );
          });
        } else {
          console.log("no hay archivos");
          api.put("api/personal/ficha/variables/bulk/", arrayBulk).then((response) => {
              console.log(response);
              alert("Se modificó correctamente");
              window.location.href = `/admin/personalfile/manage/${id}`;
            })

        }
      }).catch((error) => {
        {};

        alert(`ERROR: ${error.response}`);
      });
    }
    const tempSelectBoss = selectedBoss.reduce((accum, obj) => ([
      ...accum,
      obj.value,
    ]), [])
    const body =  {
      jefatura: tempSelectBoss,
    };
    api.post(`api/personal/jefatura/ficha/${id}`, body).then((request) => {
    });
  }


  const handleRegiones = (region) => {
    setRegion(region);
    setFlagRegion(true);
    setIdRegion(region.value);
    setComuna("");
  };


  const handleId_lista_afp = (id_lista_afp) => {
    setId_lista_afp(id_lista_afp);
  };

  const handleId_cargo = (value) => {
    if (value?.value){
      const tempCargo = dict_cargo[value.value];
      setId_cargo(value);
      setArea(tempCargo?.gerencia || "");
    } else {
      setId_cargo(null);
      setArea(null);
    }

  };


  useEffect(() => {
    var sRut1 = rut;
    sRut1 = sRut1.replace("-", ""); // se elimina el guion
    sRut1 = sRut1.replace(".", ""); // se elimina el primer punto
    sRut1 = sRut1.replace(".", ""); // se elimina el segundo punto
    sRut1 = sRut1.replace(/k$/, "K");

    //contador de para saber cuando insertar el . o la -
    var nPos = 0;
    //Guarda el rut invertido con los puntos y el gui&oacute;n agregado
    var sInvertido = "";
    //Guarda el resultado final del rut como debe ser
    var sRut = "";
    for (var i = sRut1.length - 1; i >= 0; i--) {
      sInvertido += sRut1.charAt(i);
      if (i == sRut1.length - 1) sInvertido += "-";
      else if (nPos == 3) {
        sInvertido += ".";
        nPos = 0;
      }
      nPos++;
    }
    for (var j = sInvertido.length - 1; j >= 0; j--) {
      if (sInvertido.charAt(sInvertido.length - 1) != ".")
        sRut += sInvertido.charAt(j);
      else if (j != sInvertido.length - 1) sRut += sInvertido.charAt(j);
    }
    //Pasamos al campo el valor formateado


  });


const inputProps = {
  startAdornment: (
    <InputAdornment position="start">
    <AttachMoney />
    </InputAdornment>
  ),
}

  const listForm = [
    {
      _content_: <CustomSelectAutoComplete
        label="Vigencia"
        value={vigencia}
        onChange={(e) => setvigencia(e)}
        listItem={list_vigencia}
      />,
      gridStyle: {padding: "15px"}
    },
    {
        label:"Apellido paterno",
        value: apellido_paterno,
        onChange: (e) => setApellido_paterno(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"Apellido materno",
        value: apellido_materno,
        onChange: (e) => setApellido_materno(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"Nombres",
        value: nombres,
        onChange:(e) => setNombres(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"RUT",
        value: rut,
        onChange:(e) => setRut(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        _content_: <CustomSelectAutoComplete
          label="Sexo"
          value={sexo}
          onChange={setSexo}
          listItem={list_sexo}
        />,
        gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomSelectAutoComplete
        label="Estado civil"
        value={estado_civil}
        onChange={setEstado_civil}
        listItem={list_estado_civil}
        />,
        gridStyle: {padding: "15px"}
    },
    {
        label:"Dirección",
        value: direccion_calle,
        onChange:(e) => setDireccion_calle(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"Número",
        value: direccion_numero,
        onChange: (e) => setDireccion_numero(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"Block",
        value: direccion_block,
        onhange:(e) => setDireccion_block(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"Departamento/casa",
        value: direccion_departamento,
        onChange: (e) => setDireccion_departamento(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"Ciudad",
        value: ciudad,
        onChange: (e) => setCiudad(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        _content_: <CustomSelectAutoComplete
          label="Región"
          value={region}
          onChange={handleRegiones}
          listItem={lista_regiones}
      />,
      gridStyle: {padding: "15px"}
    },
    {
        _content_: <CustomSelectAutoComplete
          label="Comuna"
          value={comuna}
          onChange={setComuna}
          listItem={listComunas}
        />,
        gridStyle: {padding: "15px"}
    },
    {
        _content_: <CustomSelectAutoComplete
          label="Nacionalidad"
          value={nacionalidad}
          onChange={setNacionalidad}
          listItem={list_nacionalidad}
        />,
        gridStyle: {padding: "15px"}
    },
    {
        _content_: <CustomDatePicker
            variant="inline"
            format="YYYY-MM-DD"
            margin="normal"
            label={"Fecha de nacimiento"}
            placeholder="Fecha de nacimiento"
            onChange={(e) => setNacimiento(e.format("YYYY-MM-DD"))}
            value={nacimiento ? moment(nacimiento): null}
            inputVariant={"outlined"}
        />,
        gridStyle: {padding: "15px"}
    },
    {
        label:"Teléfono 1",
        value: telefono1,
        onChange:(e) => setTelefono1(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"Teléfono 2",
        value: telefono2,
        onChange:(e) => setTelefono2(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"Teléfono 3",
        value: telefono3,
        onChange:(e) => setTelefono3(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"Email",
        value: email,
        onChange:(e) => setEmail(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        label:"Email Institucional",
        value: emailInstitutional,
        onChange: (e) => setEmailInstitutional(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomSelectAutoComplete
          label="Tipo pago"
          value={tipoPago}
          onChange={setTipoPago}
          listItem={listTipoPago}
        />,
        gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomSelectAutoComplete
        label="Banco"
        value={banco}
        onChange={setBanco}
        listItem={list_banco}
      />,
        gridStyle: {padding: "15px"}
    },
    {
        label: "Número de cuenta",
        value: cuenta_banco,
        onChange: (e) => setCuenta_banco(e.target.value),
        gridStyle: {padding: "15px"}
    },
    {
        _content_: <CustomSelectAutoComplete
          label="Tipo de cuenta"
          value={tipo_cuenta_banco}
          onChange={setTipo_cuenta_banco}
          listItem={list_tipo_cuenta_banco}
        />,
        gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomSelectAutoComplete
        label="Estudios"
        value={nivel_estudios}
        onChange={setNivel_estudios}
        listItem={list_nivel_estudios}
      />,
      gridStyle: {padding: "15px"}
    },
    {
        _content_: <CustomSelectAutoComplete
          label="Estado de estudios"
          value={estado_estudios}
          onChange={setEstado_estudios}
          listItem={list_estado_estudios}
        />,
        gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomSelectAutoComplete
        label="AFP"
        value={id_lista_afp}
        onChange={setId_lista_afp}
        listItem={list_afp}
      />,
        gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomSelectAutoComplete
        label="Convenio Isapre"
        value={convenioIsapre}
        onChange={setConvenioIsapre}
        listItem={listConveniosIsapre}
      />,
        gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomSelectAutoComplete
        label="Nombre Isapre"
        value={nombre_isapre}
        onChange={setNombre_isapre}
        listItem={list_isapres}
      />,
      gridStyle: {padding: "15px"}
    },
    {
        label: "Valor pactado Isapre",
        value: valor_pactado_isapre,
        onChange: (e) => {setValor_pactado_isapre(onlyNumbers(e.target.value))},
        InputProps: inputProps,
        gridStyle: {padding: "15px"}
    },
    {
        _content_: <CustomDatePicker
          label="Fecha de inicio contrato"
          value={fecha_inicio_contrato ? moment(fecha_inicio_contrato): null}
          onChange={(e) => setFecha_inicio_contrato(e.format("YYYY-MM-DD"))}
          variant="inline"
          format="YYYY-MM-DD"
          margin="normal"
          placeholder="Fecha de nacimiento"
          inputVariant={"outlined"}
        />,
        gridStyle: {padding: "15px"}
    },
    {
        _content_:   <CustomDatePicker
          label="Fecha de fin contrato"
          value={fecha_fin_contrato ? moment(fecha_fin_contrato): null}
          onChange={(e) => setFecha_fin_contrato(e.format("YYYY-MM-DD"))}
          variant="inline"
          format="YYYY-MM-DD"
          margin="normal"
          placeholder="Fecha de nacimiento"
          inputVariant={"outlined"}
        />,
        gridStyle: {padding: "15px"}
    },
    {
        _content_:   <CustomSelectAutoComplete
          label="Tipo de contrato"
          value={tipo_contrato}
          onChange={setTipo_contrato}
          listItem={list_tipo_contrato}
        />,
        gridStyle: {padding: "15px"}
    },
    {
        _content_:   <CustomSelectAutoComplete
          label="Empresa"
          value={empresa}
          onChange={setEmpresa}
          listItem={list_empresa}
        />,
        gridStyle: {padding: "15px"}
    },
    {
        _content_:   <CustomSelectAutoComplete
          label="Cargo"
          value={id_cargo}
          onChange={handleId_cargo}
          listItem={list_cargo}
        />,
        gridStyle: {padding: "15px"}
    },
    {
      _content_:  <DetailSelect2
        label="Jefatura"
        isMulti={true}
        value={selectedBoss}
        list={listUser}
        validation={false}
        onchange={(value) => setselectedBoss(value ? value : [])}
      />,
      gridStyle: {padding: "15px"},
      gridSize: 12
    },
    {
      label: "REG",
      value: REG,
      placeholder:"REG solo para medicos",
      onChange: (e) => setREG(e.target.value),
      gridStyle: {padding: "15px"}
    },
    {
      label: "RCM",
      value: RCM,
      placeholder:"RCM solo para medicos",
      onChange: (e) => setRCM(e.target.value),
      gridStyle: {padding: "15px"}
    },
    {
      label: "Título",
      value: titulo,
      onChange:(e) => setTitulo(e.target.value),
      gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomCheckBox
        label="Asignación teléfono"
        value={asignacion_telefono}
        onClick={() =>setAsignacion_telefono(!asignacion_telefono)}
      />,
      gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomCheckBox
        label="Asignación uniforme"
        value={asignacion_uniforme}
        onClick={() => setAsignacion_uniforme(!asignacion_uniforme)}
      />,
      gridStyle: {padding: "15px"}
    },
    {
      _content_: <CustomCheckBox
        label="Asignación equipo"
        value={asignacion_equipo}
        onClick={setAsignacion_equipo}
      />,
      gridStyle: {padding: "15px"}
    },
    {
      disabled: true,
      label: "Gerencia",
      value: area,
      gridStyle: {padding: "15px"}
    },
    {
      label: "Funciones",
      value: funciones,
      maxRows: 4,
      onChange: (e) => setFunciones(e.target.value),
      gridStyle: {padding: "15px"},
      gridSize: 12,
    },
    {
      _content_:  <CustomSelectAutoComplete
        label="Tipo Gratificación"
        value={ tipo_gratificacion }
        listItem={listTypeGratification}
        onChange={settipo_gratificacion}
      />,
      gridStyle: {padding: "15px"}
    },
  ]

  const handleUpdateVariables = (row, value) => {

    const tempDict = {...dictVariablesPersonales};
    let tempItem = tempDict[row.value];

    if (tempItem){
      tempItem.valor = value
    } else {
      tempItem = {valor: value}
    }

    tempDict[row.value] = tempItem

    setdictVariablesPersonales(tempDict)
  }

  const listFormPersonal = (list_variables_personal || []).map((row) => {
    const input = {
      label: row.label,
      value: parseInt(dictVariablesPersonales[row.value]?.valor, 10) || 0,
      onChange: (e) => handleUpdateVariables(row, e.target.value),
      gridStyle: {padding: "15px"},
    }

    if (row.value !== "P057"){
      input["InputProps"] = inputProps
    }

    return input
  })



  return (
    <GridContainer>
      <ViewTitle
        title="Edición de ficha personal"
        message="A continuación podrás editar esta ficha personal"
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Recursos humanos</Typography>
            <Typography color="textPrimary">
              <Link color="inherit" to="/admin/endowment/">
                Dotación
              </Link>
            </Typography>
            <Typography color="textPrimary">Detalle dotación</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      {loading ? (
        <form>
          <GridItem xs={12} sm={6} md={12}>
            <GridItem
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button
                onClick={() => props.history.goBack()}
                style={{ backgroundColor: "#1B9CFC", textTransform: "none" }}
              >
                <svg
                  className="bi bi-arrow-return-left"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.854 5.646a.5.5 0 010 .708L3.207 9l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M13.5 2.5a.5.5 0 01.5.5v4a2.5 2.5 0 01-2.5 2.5H3a.5.5 0 010-1h8.5A1.5 1.5 0 0013 7V3a.5.5 0 01.5-.5z"
                    clipRule="evenodd"
                  />
                </svg>
                 Volver
              </Button>
            </GridItem>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                centered
              >
                <Tab
                  label="Datos personales"
                  {...a11yProps(0)}
                  style={{ textTransform: "none" }}
                />
                <Tab
                  label="Variables personales"
                  {...a11yProps(1)}
                  style={{ textTransform: "none" }}
                />
                <Tab
                  label="Documentos personales"
                  {...a11yProps(2)}
                  style={{ textTransform: "none" }}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Card>
                <CardTitle title="Datos personales" class={classes.cardTitle} />
                <GridItem
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <span>Estado de ficha personal: </span>
                  <span className="text-uppercase">
                    <mark>
                      <strong>{status}</strong>
                    </mark>
                  </span>
                </GridItem>
                <CardBody>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    item
                    xs={12}
                  >
                    {listForm.map((row) => (
                    <Grid
                      item
                      xs={row.gridSize || 12}
                      sm={row.gridSize || 6}
                      md={row.gridSize || 4}
                      style={row.gridStyle || {}}
                    >
                      {row._content_ ||
                      <TextField
                          variant="outlined"
                          type="text"
                          // error={row.value == ""}
                          // helperText={row.value == "" ? "No puede ser vacio" : ""}
                          style={{ width: '100%' }}
                          {...row}

                      />}
                    </Grid>
                    ))}
                  </Grid>
                </CardBody>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card>
                <CardTitle
                  title="Variables personales"
                  class={classes.cardTitle}
                />
                <CardBody>
                  <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                      item
                      xs={12}
                    >
                      {listFormPersonal.map((row) => (
                      <Grid
                        item
                        xs={row.gridSize || 12}
                        sm={row.gridSize || 6}
                        md={row.gridSize || 4}
                        style={row.gridStyle || {}}
                      >
                        {row._content_ ||
                        <TextField
                            variant="outlined"
                            type="text"
                            // error={row.value == ""}
                            // helperText={row.value == "" ? "No puede ser vacio" : ""}
                            style={{ width: '100%' }}
                            {...row}

                        />}
                      </Grid>
                      ))}
                    </Grid>
                </CardBody>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <GridContainer>
                <GridItem xs={12}>
                  <Card>
                    <CardTitle title="Archivo" class={classes.cardTitle} />
                    <CardBody>
                      <GridContainer style={{ maxWidth: "100%" }}>
                        {getDocumentos.map((document) => (
                          <DetailFileView
                            key={document.id}
                            name={document.nombre_archivo}
                            link={document.archivo}
                          />
                        ))}
                        <div style={{ marginTop: "15em" }}></div>
                        <DetailFileMultiple
                          label="Archivos ficha personal:"
                          value={filesFicha}
                          onchange={(e) => setFilesFicha(e.target.files)}
                        />

                        <div style={{ width: "80vw" }}></div>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </TabPanel>
          </GridItem>

          <GridContainer justify="flex-end">
            <GridItem xs={12} sm={12} md={2}>
              {valid ? (
                <Button
                  onClick={() => uploadWithFormData(props.location.state)}
                  style={{ backgroundColor: "#1B9CFC", textTransform: "none" }}
                >
                  Actualizar
                </Button>
              ) : (
                <Button
                  style={{ backgroundColor: "#1B9CFC", textTransform: "none" }}
                  disabled
                >
                  Actualizar
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </form>
      ) : (
        <GridItem xs={12} sm={6} md={12}>
          <Card>
            <CardTitle
              title="Cargando información ..."
              class={classes.cardTitle}
            />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        </GridItem>
      )}
    </GridContainer>
  );
};

export default Edit;
