import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";
import api from "utils/API";
import apiform from "utils/APIForm";
import TurnoExtra from "./TurnoExtra";
import AtencionExtra from "./AtencionExtra";
import Vacaciones from "./Vacaciones";
import PermisoSinSueldo from "./PermisoSinSueldo";
import Inasistencia from "./Inasistencia";
import Renuncia from "./Renuncia";
import Despido from "./Despido";
import Imponible from "./Imponible";
import Colacion from "./Colacion";
import Movilizacion from "./Movilizacion";
import Guarderia from "./Guarderia";
import Bienestar from "./Bienestar";
import Anticipo from "./Anticipo";
import moment from "moment-timezone";
import "moment/locale/es";
import AlertDialog from "components/Attendance/AlertDialog";
moment.locale("es");

var momentBusinessDays = require("moment-business-days");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    maxHeight: "70%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator={true}
      decimalSeparator={false}
      allowNegative={false}
      isNumericString
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ModalViewRecord = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    tempEventInfo,
    setOpenDialogSucess,
    setTempMessage,
    setPageRefresh,
    variableCounter,
    setVariableCounter,
    eventsVariables,
    setEvents
  } = props;
  const [openAnimation, setOpenAnimation] = useState(false);
  const [changes, setChanges] = useState(true);
  const [startDate, setStarDate] = useState(moment(tempEventInfo.start));
  const [endDate, setEndDate] = useState(moment(tempEventInfo.end));
  const [editInfo, setEditInfo] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(false);
  const [abrirAlerta,setAbrirAlerta] = React.useState(false);
  const [titulo,setTitulo] = React.useState('dsd');
  const [mensaje,setMensaje] = React.useState('dsds');
  const [tipoAlerta,setTipoAlerta] = React.useState('success');
  // turno extra
  const [valorTurno, setValorTurno] = useState(0);
  const [valorColacion, setValorColacion] = useState(undefined);
  const [valorMovilizacion, setValorMovilizacion] = useState(undefined);
  const [valorViatico, setValorViatico] = useState(undefined);
  const [checkColacion, setCheckColacion] = useState(false);
  const [checkMovilizacion, setCheckMovilizacion] = useState(false);
  const [checkViatico, setCheckViatico] = useState(false);
  const getIndex = eventsVariables.indexOf(tempEventInfo);
  // end turno extra
  // atención extra
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  // end atención extra

  // vacaciones
  const [estadoVacaciones, setEstadoVacaciones] = useState("");
  const [ultimoDiaTrabajado, setUltimoDiaTrabajado] = useState("");
  const [primerDiaTrabajado, setPrimerDiaTrabajado] = useState("");
  const [totalDias, setTotalDias] = useState("");
  // end vacaciones
  // inasistencia
  const [licencia, setLicencia] = useState("");
  const [amonestacion, setAmonestacion] = useState("");
  const [descuento, setDescuento] = useState("");
  const [responseInasistencia, setResponseInasistencia] = useState({});
  // end inasistencia

  //Renuncia
  const [diasTrabajados, setDiasTrabajados] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [asociatedFiles, setAsociatedFiles] = React.useState([]);
  const [responseRenuncia, setResponseRenuncia] = useState({});
  const [fechaInicioRenuncia, setFechaInicioRenuncia] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  // end renuncia

  // Despido
  const [fechaInicioDespido, setFechaInicioDespido] = useState("");
  const [responseDespido, setResponseDespido] = useState({});
  // end Despido
  // Imponibles
  const [periodoCorreccionImponible, setPeriodoCorrecionImponible] = React.useState(moment(new Date()));
  const [fechaRegistroImponible, setFechaRegistroImponible] = React.useState(moment(new Date()));
  const [montoImponible, setMontoImponible] = React.useState(0);
  const [responseImponible, setResponseImponible] = useState({});
  // end imponible
  // Colaciones
  const [periodoCorreccionColacion, setPeriodoCorrecionColacion] = React.useState(moment(new Date()));
  const [fechaRegistroColacion, setFechaRegistroColacion] = React.useState(moment(new Date()));
  const [montoColacion, setMontoColacion] = React.useState(0);
  const [responseColacion, setResponseColacion] = useState({});
  // end Colaciones
  // Movilizaciones
  const [periodoCorreccionMovilizacion, setPeriodoCorrecionMovilizacion] = React.useState(moment(new Date()));
  const [fechaRegistroMovilizacion, setFechaRegistroMovilizacion] = React.useState(moment(new Date()));
  const [montoMovilizacion, setMontoMovilizacion] = React.useState(0);
  const [responseMovilizacion, setResponseMovilizacion] = useState({});
  // end Movilizaciones
  // Guarderia
  const [fechaRegistroGuarderia, setFechaRegistroGuarderia] = React.useState(moment(new Date()));
  const [montoGuarderia, setMontoGuarderia] = React.useState(0);
  const [responseGuarderia, setResponseGuarderia] = useState({});
  // end Movilizaciones
  // Bienestar
  const [fechaRegistroBienestar, setFechaRegistroBienestar] = React.useState(moment(new Date()));
  const [montoBienestar, setMontoBienestar] = React.useState(0);
  const [responseBienestar, setResponseBienestar] = useState({});
  // end Movilizaciones
  // Anticipo
  const [fechaRegistroAnticipo, setFechaRegistroAnticipo] = React.useState(moment(new Date()));
  const [montoAnticipo, setMontoAnticipo] = React.useState(0);
  const [responseAnticipo, setResponseAnticipo] = useState({});
  // end Anticipo

  const handleCerrarAlerta = (event) => {
    setAbrirAlerta(false);
    closeModal();
  }

  const onupload = ({ event }) => {
    setOpenAnimation(true);
    if (files.length > 0) {
      let countArray = files.length;
      let count = 0;
      files.map(file => {
        const formData = new FormData();
        formData.append("nombre_archivo", file.name);
        formData.append("descripcion_archivo", "Licencia");
        formData.append("archivo", file);
        formData.append("estado", true);
        formData.append("id_registro_variable", tempEventInfo.row.id);
        apiform
          .post("api/documentosregistrosvariables/", formData)
          .then(() => {
            count = count + 1;
            if (count === countArray) {
              setFiles([])
              setUpdateModal(true)
              setPageRefresh(true);
              setOpenAnimation(false);

              setTitulo("¡Exito!");
              setMensaje("¡El archivo se ha guardado con exito!");
              setTipoAlerta("success")
              setEditInfo(false)
              setAbrirAlerta(true);
            }

          })

      });
    } else {
      setFiles([])
      setUpdateModal(true)
    }

  }
  const getAsociatedFiles = () =>{
    api.get(`api/rrhh/variablesdinamicas/variable/${tempEventInfo.row.id}/documentos/`)
    .then(response => {
      let filesarray = []
      response.data.map(file =>{
        filesarray.push({
          "id":file['id'],
          "nombre":file['nombre_archivo'],
          "link":file['archivo']
        })
      })
      setAsociatedFiles(filesarray);

    })

  }

  const onFileChange = ( e ) => {
    let tempFiles = files;
    let tempTargetFiles = [...e.target.files];
    tempTargetFiles.map(file => {
      tempFiles.push(file);
    });
    setFiles(tempFiles);
    setUpdateModal(true)

  };


  const getDiasTrabajados = () => {
    api.post('/api/diasnohabiles/',{
        'fecha_inicio': moment().clone().startOf('month').format('YYYY-MM-DD').toString(),
        //{/*'fecha_termino':moment(startDate).format('YYYY-MM-DD').toString()*/}
        'fecha_termino':moment(fechaInicioRenuncia).format('YYYY-MM-DD').toString()
    }).then(response => {

        var fecha_inicio = moment().clone().startOf('month')
        {/*var fecha_termino = moment(startDate)*/}
        var fecha_termino = moment(fechaInicioRenuncia)

        var diferenciaDias =  fecha_termino.diff(fecha_inicio, 'days')
        var diasTrabajados = diferenciaDias - response.data.data
        setDiasTrabajados(diasTrabajados)

    }).catch(error =>
        console.log(error.response)
    );
  }
  const getDiasTrabajadosDespido = () => {
    api.post('/api/diasnohabiles/',{
        'fecha_inicio': moment().clone().startOf('month').format('YYYY-MM-DD').toString(),
        //{/*'fecha_termino':moment(startDate).format('YYYY-MM-DD').toString()*/}
        'fecha_termino':moment(fechaInicioDespido).format('YYYY-MM-DD').toString()
    }).then(response => {

        var fecha_inicio = moment().clone().startOf('month')
        {/*var fecha_termino = moment(startDate)*/}
        var fecha_termino = moment(fechaInicioDespido)

        var diferenciaDias =  fecha_termino.diff(fecha_inicio, 'days')
        var diasTrabajados = diferenciaDias - response.data.data
        setDiasTrabajados(diasTrabajados)

    }).catch(error =>
        console.log(error.response)
    );
  }

  const handleFechaInicioRenuncia = date => {
    setStarDate(date);
    setEndDate(date);
    setFechaInicioRenuncia(date)
    setDiasTrabajados('');
    setUpdateModal(true)

  };
  const handleFechaInicioDespido = date => {
    setStarDate(date);
    setEndDate(date);
    setFechaInicioDespido(date)
    setDiasTrabajados('');
    setUpdateModal(true)

  };
  const handleFechaImponible = date => {
    setStarDate(date);
    setEndDate(date);
    setChanges(true)
  };
  const handlePeriodoCorreccionImponible = date => {
    setPeriodoCorrecionImponible(date);
  };

  const handleFechaColacion = date => {
    setStarDate(date);
    setEndDate(date);
    setChanges(true)
  };
  const handlePeriodoCorreccionColacion= date => {
    setPeriodoCorrecionColacion(date);
  };

  const handleFechaMovilizacion= date => {
    setStarDate(date);
    setEndDate(date);
    setChanges(true)
  };
  const handlePeriodoCorreccionMovilizacion= date => {
    setPeriodoCorrecionMovilizacion(date);
  };

  const handleFechaGuarderia= date => {
    setStarDate(date);
    setEndDate(date);
    setChanges(true)
  };

  const handleFechaBienestar= date => {
    setStarDate(date);
    setEndDate(date);
    setChanges(true)
  };
  const handleMontoImponible = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoImponible(new_value);
  };
  const handleMontoMovilizacion = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoMovilizacion(new_value);
  };
  const handleMontoColacion = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoColacion(new_value);
  };
  const handleMontoGuarderia = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoGuarderia(new_value);
  };
  const handleMontoBienestar = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoBienestar(new_value);
  };
  const handleMontoAnticipo = (event) => {
    let new_value=parseInt(event.target.value.toString().split('$').join('').split(',').join('').split('.').join(''))
    setMontoAnticipo(new_value);
  };
  const handleFechaAnticipo= date => {
    setStarDate(date);
    setEndDate(date);
    setChanges(true)
  };
  const handleLicencia = event => {
    setLicencia(event.target.value);
  };

  const handleAmonestacion = event => {
    setAmonestacion(event.target.value);
  };

  const handleDescuento = event => {
    setDescuento(event.target.value);
  };

  const handleUltimoDiaTrabajado = date => {
    setUltimoDiaTrabajado(date);
  };

  const handlePrimerDiaTrabajado = date => {
    setPrimerDiaTrabajado(date);
  };

  const handleEstadoVacaciones = event => {
    setEstadoVacaciones(event.target.value);
  };

  const handlePatient = patient => {
    setPatient(patient);
  };

  useEffect(() => {
    if (changes) {
      if (tempEventInfo.title === "Turno extra") {
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (tempEventInfo.title === "Atención extra") {
        initialCharge();
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (
        tempEventInfo.title === "Vacación" ||
        tempEventInfo.title === "Permiso sin sueldo"
      ) {
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (tempEventInfo.title === "Inasistencia") {
        initialChargeInasistencia();
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (tempEventInfo.title === "Renuncia") {
        initialChargeRenuncia()
        getAsociatedFiles()
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (tempEventInfo.title === "Despido") {
        initialChargeDespido()
        getAsociatedFiles()
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (tempEventInfo.title === "Imponible") {
        initialChargeImponible()
        getAsociatedFiles()
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (tempEventInfo.title === "Colacion") {
        initialChargeColacion()
        getAsociatedFiles()
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (tempEventInfo.title === "Movilizacion") {
        initialChargeMovilizacion()
        getAsociatedFiles()
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (tempEventInfo.title === "Guarderia") {
        initialChargeGuarderia()
        getAsociatedFiles()
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (tempEventInfo.title === "Bienestar") {
        initialChargeBienestar()
        getAsociatedFiles()
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }
      if (tempEventInfo.title === "Anticipo") {
        initialChargeAnticipo()
        getAsociatedFiles()
        tempEventInfo.row.valores.map(value => {
          setVariableValue(value.codigo_tipo_valor, value.valor);
        });
      }

    }
    if(updateModal){
      if (tempEventInfo.title === "Despido") {
        getDiasTrabajadosDespido();
      }else if (tempEventInfo.title === "Renuncia") {
        getDiasTrabajados();
      }
        getDiasTrabajadosDespido();
      setUpdateModal(false)
    }
  });

  const initialChargeInasistencia = () => {
    const getInfoInasistencia = api.get(
      `api/rrhh/variablesdinamicas/registros/inasistencias/${tempEventInfo.row.id}/`
    );

    Promise.all([getInfoInasistencia])
      .then(response => {
        setResponseInasistencia(response[0].data);
      })

  };

  const initialChargeRenuncia = () => {
    const getInfoRenuncia = api.get(
      `api/rrhh/variablesdinamicas/registros/renuncia/${tempEventInfo.row.id}/`
    );

    Promise.all([getInfoRenuncia])
      .then(response => {
        setResponseRenuncia(response[0].data);
      })

  };
  const initialChargeDespido = () => {
    const getInfoDespido = api.get(
      `api/rrhh/variablesdinamicas/registros/despido/${tempEventInfo.row.id}/`
    );
    Promise.all([getInfoDespido])
      .then(response => {
        setResponseDespido(response[0].data);
      })

  };
  const initialChargeImponible = () => {
    const getInfoImponible = api.get(
      `api/rrhh/variablesdinamicas/registros/imponible/${tempEventInfo.row.id}/`
    );
    Promise.all([getInfoImponible])
      .then(response => {
        setResponseImponible(response[0].data);
      })

  };
  const initialChargeColacion = () => {
    const getInfoColacion = api.get(
      `api/rrhh/variablesdinamicas/registros/colacion/${tempEventInfo.row.id}/`
    );
    Promise.all([getInfoColacion])
      .then(response => {
        setResponseColacion(response[0].data);
      })

  };
  const initialChargeMovilizacion = () => {
    const getInfoMovilizacion = api.get(
      `api/rrhh/variablesdinamicas/registros/movilizacion/${tempEventInfo.row.id}/`
    );
    Promise.all([getInfoMovilizacion])
      .then(response => {
        setResponseMovilizacion(response[0].data);
      })

  };

  const initialChargeGuarderia = () => {
    const getInfoGuarderia = api.get(
      `api/rrhh/variablesdinamicas/registros/guarderia/${tempEventInfo.row.id}/`
    );
    Promise.all([getInfoGuarderia])
      .then(response => {
        setResponseGuarderia(response[0].data);
      })

  };
  const initialChargeBienestar = () => {
    const getInfoBienestar = api.get(
      `api/rrhh/variablesdinamicas/registros/bienestar/${tempEventInfo.row.id}/`
    );
    Promise.all([getInfoBienestar])
      .then(response => {
        setResponseBienestar(response[0].data);
      })

  };

  const initialChargeAnticipo = () => {
    const getInfoAnticipo = api.get(
      `api/rrhh/variablesdinamicas/registros/anticipo/${tempEventInfo.row.id}/`
    );
    Promise.all([getInfoAnticipo])
      .then(response => {
        setResponseAnticipo(response[0].data);
      })

  };

  const setVariableValue = (name, value) => {
    setChanges(false);
    switch (name) {
      case "TurnoExtra":
        setValorTurno(parseInt(value));
        break;
      case "MontoColacion":
        setMontoColacion(parseInt(value));
        break;
      case "MontoGuarderia":
        setMontoGuarderia(parseInt(value));
        break;
      case "MontoBienestar":
        setMontoBienestar(parseInt(value));
        break;
      case "MontoAnticipo":
        setMontoAnticipo(parseInt(value));
        break;
      case "MontoMovilizacion":
        setMontoMovilizacion(parseInt(value));
        break;
      case "MontoMovilizacion":
        setMontoMovilizacion(parseInt(value));
        break;
      case "ViaticoEspecial":
        setValorViatico(parseInt(value));
        break;
      case "MovilizacionEspecial":
        setValorMovilizacion(parseInt(value));
        break;
      case "UltimoDiaTrabajado":
        setUltimoDiaTrabajado(moment(value));
        break;
      case "PrimerDiaTrabajar":
        setPrimerDiaTrabajado(moment(value));
        break;
      case "DiasPedidos":
        setTotalDias(value);
        break;
      case "EstadoSolicitud":
        setEstadoVacaciones(value);
        break;
      case "DiasTrabajados":
        setDiasTrabajados(value);
        break;
      case "FechaInicioRenuncia":
        setFechaInicioRenuncia(moment(value))
      case "FechaInicioDespido":
        setFechaInicioDespido(moment(value))
      case "MontoImponible":
        setMontoImponible(value);
        break;
      case "FechaRegistroImponible":
        setFechaRegistroImponible(value);
        break;
      case "FechaRegistroGuarderia":
        setFechaRegistroGuarderia(value);
        break;
      case "FechaRegistroBienestar":
        setFechaRegistroBienestar(value);
        break;
      case "FechaRegistroAnticipo":
        setFechaRegistroAnticipo(value);
        break;
      case "PeriodoCorreccionImponible":
        setPeriodoCorrecionImponible(moment(value))
    }
  };

  const initialCharge = () => {
    const getPatients = api.get("api/paciente/activos/");

    Promise.all([getPatients])
      .then(response => {
        let tempPatients = [];
        let tempPatientId = tempEventInfo.row.valores
          .filter(filtered => filtered.codigo_tipo_valor === "PacienteAsociado")
          .map(info => parseInt(info.valor))[0];
        response[0].data
          .filter(filtered => filtered.id === tempPatientId)
          .map(patient => {
            setPatient({
              value: patient.id,
              label: `${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`
            });
          });
        response[0].data.map(patient => {
          tempPatients.push({
            value: patient.id,
            label: `${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`
          });
        });
        setPatients(tempPatients);
      })

  };


  const handleCheckColacion = event => {
    setCheckColacion(event.target.checked);
  };

  const handleCheckMovilizacion = event => {
    setCheckMovilizacion(event.target.checked);
  };

  const handleCheckViatico = event => {
    setCheckViatico(event.target.checked);
  };

  const handleStartDate = date => {
    setStarDate(date);
    setTotalDias(
      momentBusinessDays(moment(endDate).add(2, "seconds")).businessDiff(
        moment(date).add(1, "seconds")
      )
    );
  };

  const handleEndDate = date => {
    setEndDate(moment(date).add(2, "seconds"));
    setTotalDias(
      momentBusinessDays(moment(date).add(2, "seconds")).businessDiff(
        moment(startDate).add(1, "seconds")
      )
    );
  };

  const editRecord = (tipo, titulo) => {
    setOpenAnimation(true);
    let tempEvents = [...eventsVariables];
    tempEvents.splice(getIndex, 1);
    let tempInformation = {};
    if (tipo === "turnosextra") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        valor_turno: valorTurno.toString(),
        valor_colacion: valorColacion.toString(),
        valor_movilizacion: valorMovilizacion.toString(),
        viatico_especial: valorViatico.toString()
      };
      if (isNaN(valorColacion)) {
        tempInformation["valor_colacion"] = "0";
      }
      if (isNaN(valorMovilizacion)) {
        tempInformation["valor_movilizacion"] = "0";
      }
      if (isNaN(valorViatico)) {
        tempInformation["viatico_especial"] = "0";
      }
    } else if (tipo === "atencionextra") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        valor_turno: valorTurno.toString(),
        valor_colacion: valorColacion.toString(),
        valor_movilizacion: valorMovilizacion.toString(),
        viatico_especial: valorViatico.toString(),
        id_paciente_id: patient.value
      };
      if (isNaN(valorColacion)) {
        tempInformation["valor_colacion"] = "0";
      }
      if (isNaN(valorMovilizacion)) {
        tempInformation["valor_movilizacion"] = "0";
      }
      if (isNaN(valorViatico)) {
        tempInformation["viatico_especial"] = "0";
      }
    } else if (tipo === "vacaciones") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        ultimo_dia_trabajado: ultimoDiaTrabajado,
        primer_dia_trabajar: primerDiaTrabajado,
        total_dias: totalDias,
        estado_solicitud: estadoVacaciones
      };
    } else if (tipo === "permisosinsueldo") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        ultimo_dia_trabajado: ultimoDiaTrabajado,
        primer_dia_trabajar: primerDiaTrabajado,
        total_dias: totalDias
      };
    } else if (tipo === "inasistencias") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        ultimo_dia_trabajado: ultimoDiaTrabajado,
        primer_dia_trabajar: primerDiaTrabajado,
        licencia: licencia,
        amonestacion: amonestacion,
        descuento: descuento
      };
    } else if (tipo === "renuncia") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        dias_trabajados: diasTrabajados,
        fecha_inicio_renuncia: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
      };
    }
    else if (tipo === "despido") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        dias_trabajados: diasTrabajados,
        fecha_inicio_despido: moment(fechaInicioDespido).format("YYYY-MM-DDTHH:mm:ss"),
      };
    }
    else if (tipo === "imponible") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        monto_imponible: montoImponible,
        fecha_registro_imponible: moment(fechaRegistroImponible).format("YYYY-MM-DDTHH:mm:ss"),
        periodo_correcion_imponible: moment(periodoCorreccionImponible).format("YYYY-MM"),
      };
    }
    else if (tipo === "colacion") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        monto_colacion: montoColacion,
        fecha_registro_colacion: moment(fechaRegistroColacion).format("YYYY-MM-DDTHH:mm:ss"),
        periodo_correcion_colacion: moment(periodoCorreccionColacion).format("YYYY-MM"),
      };
    }
    else if (tipo === "movilizacion") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        monto_movilizacion: montoMovilizacion,
        fecha_registro_movilizacion: moment(fechaRegistroMovilizacion).format("YYYY-MM-DDTHH:mm:ss"),
        periodo_correcion_movilizacion: moment(periodoCorreccionMovilizacion).format("YYYY-MM"),
      };
    }
    else if (tipo === "guarderia") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        monto_guarderia: montoGuarderia,
        fecha_registro_guarderia: moment(fechaRegistroGuarderia).format("YYYY-MM-DDTHH:mm:ss"),
      };
    }
    else if (tipo === "bienestar") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        monto_bienestar: montoBienestar,
        fecha_registro_bienestar: moment(fechaRegistroBienestar).format("YYYY-MM-DDTHH:mm:ss"),
      };
    }
    else if (tipo === "anticipo") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        monto_anticipo: montoAnticipo,
        fecha_registro_anticipo: moment(fechaRegistroAnticipo).format("YYYY-MM-DDTHH:mm:ss"),
      };
    }
    api
      .patch(
        `api/rrhh/variablesdinamicas/registros/${tipo}/${tempEventInfo.row.id}/`,
        tempInformation
      )
      .then(response => {
        tempEvents.push({
          title: titulo,
          allDay: true,
          start: moment(startDate).add(30, "seconds"),
          end: moment(endDate).add(30, "minutes"),
          row: response.data
        });
        setEvents(tempEvents);
        setPageRefresh(true);
        setOpenAnimation(false);
        setTitulo("¡Exito!");
        setTipoAlerta("success")
        setMensaje("¡El registro se ha actualizado con exito!");
        setEditInfo(false)
        setAbrirAlerta(true);
      })
      .catch(error => {
        setEditInfo(false)
        {}
      });
  };

  const deleteRecord = (tipo, cantidad) => {
    setOpenAnimation(true);
    let tempVariableCounter = { ...variableCounter };
    let tempEvents = [...eventsVariables];
    tempEvents.splice(getIndex, 1);
    api
      .delete(
        `api/rrhh/variablesdinamicas/registros/${tipo}/${tempEventInfo.row.id}/`
      )
      .then(() => {
        tempVariableCounter[cantidad] = variableCounter[cantidad] - 1;
        setVariableCounter(tempVariableCounter);
        setEvents(tempEvents);
        setPageRefresh(true);
        setOpenAnimation(false);
        setTitulo("¡Exito!");
        setMensaje("¡El registro se ha eliminado con exito!");
        setTipoAlerta("success")
        setEditInfo(false)
        setAbrirAlerta(true);
      })
      .catch(error => {
        console.log(error.response)
        setEditInfo(false)
      });
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        {openAnimation ? (
          <ModalTitle id="customized-dialog-title">
            Tipo de registro: {tempEventInfo.title}
          </ModalTitle>
        ) : (
          <ModalTitle id="customized-dialog-title" onClose={closeModal}>
            Tipo de registro: {tempEventInfo.title}
          </ModalTitle>
        )}
        <DialogContent>
          <Grid container className={classes.root} justify="center">
            <Grid item container xs={12} justify="center">
              {tempEventInfo.title === "Turno extra" ? (
                <TurnoExtra
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  valorTurno={valorTurno}
                  setValorTurno={setValorTurno}
                  valorColacion={valorColacion}
                  setValorColacion={setValorColacion}
                  valorMovilizacion={valorMovilizacion}
                  setValorMovilizacion={setValorMovilizacion}
                  valorViatico={valorViatico}
                  setValorViatico={setValorViatico}
                  NumberFormatCustom={NumberFormatCustom}
                  checkColacion={checkColacion}
                  handleCheckColacion={handleCheckColacion}
                  checkMovilizacion={checkMovilizacion}
                  handleCheckMovilizacion={handleCheckMovilizacion}
                  checkViatico={checkViatico}
                  handleCheckViatico={handleCheckViatico}
                  editInfo={editInfo}
                  tempEventInfo={tempEventInfo}
                />
              ) : tempEventInfo.title === "Atención extra" ? (
                <AtencionExtra
                  startDate={startDate}
                  setStartDate={setStarDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  valorTurno={valorTurno}
                  setValorTurno={setValorTurno}
                  valorColacion={valorColacion}
                  setValorColacion={setValorColacion}
                  valorMovilizacion={valorMovilizacion}
                  setValorMovilizacion={setValorMovilizacion}
                  valorViatico={valorViatico}
                  setValorViatico={setValorViatico}
                  NumberFormatCustom={NumberFormatCustom}
                  checkColacion={checkColacion}
                  handleCheckColacion={handleCheckColacion}
                  checkMovilizacion={checkMovilizacion}
                  handleCheckMovilizacion={handleCheckMovilizacion}
                  checkViatico={checkViatico}
                  handleCheckViatico={handleCheckViatico}
                  editInfo={editInfo}
                  tempEventInfo={tempEventInfo}
                  patient={patient}
                  patients={patients}
                  handlePatient={handlePatient}
                />
              ) : tempEventInfo.title == "Vacación" ? (
                <Vacaciones
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  ultimoDiaTrabajado={ultimoDiaTrabajado}
                  handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
                  primerDiaTrabajado={primerDiaTrabajado}
                  handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
                  totalDias={totalDias}
                  estadoVacaciones={estadoVacaciones}
                  handleEstadoVacaciones={handleEstadoVacaciones}
                  editInfo={editInfo}
                />
              ) : tempEventInfo.title === "Permiso sin sueldo" ? (
                <PermisoSinSueldo
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  ultimoDiaTrabajado={ultimoDiaTrabajado}
                  handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
                  primerDiaTrabajado={primerDiaTrabajado}
                  handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
                  totalDias={totalDias}
                  estadoVacaciones={estadoVacaciones}
                  handleEstadoVacaciones={handleEstadoVacaciones}
                  editInfo={editInfo}
                />
              ) : tempEventInfo.title === "Inasistencia" ? (
                <Inasistencia
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  ultimoDiaTrabajado={ultimoDiaTrabajado}
                  handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
                  primerDiaTrabajado={primerDiaTrabajado}
                  handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
                  totalDias={totalDias}
                  estadoVacaciones={estadoVacaciones}
                  handleEstadoVacaciones={handleEstadoVacaciones}
                  editInfo={editInfo}
                  licencia={licencia}
                  handleLicencia={handleLicencia}
                  amonestacion={amonestacion}
                  handleAmonestacion={handleAmonestacion}
                  descuento={descuento}
                  handleDescuento={handleDescuento}
                  responseInasistencia={responseInasistencia}
                />
              ) : tempEventInfo.title === "Renuncia" ? (
                <Renuncia
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  diasTrabajados={diasTrabajados}
                  fechaInicioRenuncia={fechaInicioRenuncia}
                  tempEventInfo={tempEventInfo}
                  onupload={onupload}
                  onFileChange={onFileChange}
                  files={files}
                  setFiles={setFiles}
                  setTitulo={setTitulo}
                  setTipoAlerta={setTipoAlerta}
                  setMensaje={setMensaje}
                  setAbrirAlerta={setAbrirAlerta}
                  editInfo={editInfo}
                  asociatedFiles={asociatedFiles}
                  setUpdateModal={setUpdateModal}
                  handleFechaInicioRenuncia={handleFechaInicioRenuncia}
                  setTempMessage={setTempMessage}
                  setPageRefresh={setPageRefresh}
                  setOpenAnimation={setOpenAnimation}
                  setOpenDialogSucess={setOpenDialogSucess}
                  updateModal={updateModal}
                />
              ) : tempEventInfo.title === "Despido" ? (
                <Despido
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  diasTrabajados={diasTrabajados}
                  fechaInicioDespido={fechaInicioDespido}
                  tempEventInfo={tempEventInfo}
                  onupload={onupload}
                  onFileChange={onFileChange}
                  files={files}
                  setFiles={setFiles}
                  setTitulo={setTitulo}
                  setTipoAlerta={setTipoAlerta}
                  setMensaje={setMensaje}
                  setAbrirAlerta={setAbrirAlerta}
                  editInfo={editInfo}
                  asociatedFiles={asociatedFiles}
                  setUpdateModal={setUpdateModal}
                  handleFechaInicioDespido={handleFechaInicioDespido}
                  setTempMessage={setTempMessage}
                  setPageRefresh={setPageRefresh}
                  setOpenAnimation={setOpenAnimation}
                  setOpenDialogSucess={setOpenDialogSucess}
                  updateModal={updateModal}
                />
              ) : tempEventInfo.title === "Imponible" ? (
                <Imponible
                  startDate={startDate}
                  periodoCorreccionImponible={periodoCorreccionImponible}
                  montoImponible={montoImponible}
                  handleMontoImponible={handleMontoImponible}
                  handlePeriodoCorreccionImponible={handlePeriodoCorreccionImponible}
                  onupload={onupload}
                  onFileChange={onFileChange}
                  files={files}
                  editInfo={editInfo}
                  setFiles={setFiles}
                  setTitulo={setTitulo}
                  setTipoAlerta={setTipoAlerta}
                  setMensaje={setMensaje}
                  setAbrirAlerta={setAbrirAlerta}
                  asociatedFiles={asociatedFiles}
                  setUpdateModal={setUpdateModal}
                  handleFechaImponible={handleFechaImponible}
                  setTempMessage={setTempMessage}
                  setPageRefresh={setPageRefresh}
                  setOpenAnimation={setOpenAnimation}
                  setOpenDialogSucess={setOpenDialogSucess}
                  updateModal={updateModal}
                />
              ): tempEventInfo.title === "Colacion" ? (
                <Colacion
                  startDate={startDate}
                  periodoCorreccionColacion={periodoCorreccionColacion}
                  montoColacion={montoColacion}
                  handleMontoColacion={handleMontoColacion}
                  handlePeriodoCorreccionColacion={handlePeriodoCorreccionColacion}
                  onupload={onupload}
                  onFileChange={onFileChange}
                  files={files}
                  editInfo={editInfo}
                  setFiles={setFiles}
                  setTitulo={setTitulo}
                  setTipoAlerta={setTipoAlerta}
                  setMensaje={setMensaje}
                  setAbrirAlerta={setAbrirAlerta}
                  asociatedFiles={asociatedFiles}
                  setUpdateModal={setUpdateModal}
                  handleFechaColacion={handleFechaColacion}
                  setTempMessage={setTempMessage}
                  setPageRefresh={setPageRefresh}
                  setOpenAnimation={setOpenAnimation}
                  setOpenDialogSucess={setOpenDialogSucess}
                  updateModal={updateModal}
                />
              ): tempEventInfo.title === "Movilizacion" ? (
                <Movilizacion
                  startDate={startDate}
                  periodoCorreccionMovilizacion={periodoCorreccionMovilizacion}
                  montoMovilizacion={montoMovilizacion}
                  handleMontoMovilizacion={handleMontoMovilizacion}
                  handlePeriodoCorreccionMovilizacion={handlePeriodoCorreccionMovilizacion}
                  onupload={onupload}
                  onFileChange={onFileChange}
                  files={files}
                  editInfo={editInfo}
                  setFiles={setFiles}
                  setTitulo={setTitulo}
                  setTipoAlerta={setTipoAlerta}
                  setMensaje={setMensaje}
                  setAbrirAlerta={setAbrirAlerta}
                  asociatedFiles={asociatedFiles}
                  setUpdateModal={setUpdateModal}
                  handleFechaMovilizacion={handleFechaMovilizacion}
                  setTempMessage={setTempMessage}
                  setPageRefresh={setPageRefresh}
                  setOpenAnimation={setOpenAnimation}
                  setOpenDialogSucess={setOpenDialogSucess}
                  updateModal={updateModal}
                />
              ): tempEventInfo.title === "Guarderia" ? (
                <Guarderia
                  startDate={startDate}
                  montoGuarderia={montoGuarderia}
                  handleMontoGuarderia={handleMontoGuarderia}
                  onupload={onupload}
                  onFileChange={onFileChange}
                  files={files}
                  editInfo={editInfo}
                  setFiles={setFiles}
                  setTitulo={setTitulo}
                  setTipoAlerta={setTipoAlerta}
                  setMensaje={setMensaje}
                  setAbrirAlerta={setAbrirAlerta}
                  asociatedFiles={asociatedFiles}
                  setUpdateModal={setUpdateModal}
                  handleFechaGuarderia={handleFechaGuarderia}
                  setTempMessage={setTempMessage}
                  setPageRefresh={setPageRefresh}
                  setOpenAnimation={setOpenAnimation}
                  setOpenDialogSucess={setOpenDialogSucess}
                  updateModal={updateModal}
                />
              ): tempEventInfo.title === "Bienestar" ? (
                <Bienestar
                  startDate={startDate}
                  montoBienestar={montoBienestar}
                  handleMontoBienestar={handleMontoBienestar}
                  onupload={onupload}
                  onFileChange={onFileChange}
                  files={files}
                  editInfo={editInfo}
                  setFiles={setFiles}
                  setTitulo={setTitulo}
                  setTipoAlerta={setTipoAlerta}
                  setMensaje={setMensaje}
                  setAbrirAlerta={setAbrirAlerta}
                  asociatedFiles={asociatedFiles}
                  setUpdateModal={setUpdateModal}
                  handleFechaBienestar={handleFechaBienestar}
                  setTempMessage={setTempMessage}
                  setPageRefresh={setPageRefresh}
                  setOpenAnimation={setOpenAnimation}
                  setOpenDialogSucess={setOpenDialogSucess}
                  updateModal={updateModal}
                />
              ):tempEventInfo.title === "Anticipo" ? (
                <Anticipo
                  startDate={startDate}
                  montoAnticipo={montoAnticipo}
                  handleMontoAnticipo={handleMontoAnticipo}
                  onupload={onupload}
                  onFileChange={onFileChange}
                  files={files}
                  setFiles={setFiles}
                  setTitulo={setTitulo}
                  setTipoAlerta={setTipoAlerta}
                  setMensaje={setMensaje}
                  setAbrirAlerta={setAbrirAlerta}
                  editInfo={editInfo}
                  asociatedFiles={asociatedFiles}
                  setUpdateModal={setUpdateModal}
                  handleFechaAnticipo={handleFechaAnticipo}
                  setTempMessage={setTempMessage}
                  setPageRefresh={setPageRefresh}
                  setOpenAnimation={setOpenAnimation}
                  setOpenDialogSucess={setOpenDialogSucess}
                  updateModal={updateModal}
                />
              ): null}
              {!updateModal ? <div></div>:""}
            </Grid>
            <Grid container justify="center">
              {deleteInfo ? (
                <Typography
                  variant="h6"
                  style={{ textTransform: "none", marginTop: "2em" }}
                >
                  ¿Está seguro que desea eliminar este registro?
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {openAnimation ? (
            <CircularProgress />
          ) : (
            <div>
              {tempEventInfo.title === "Turno extra" ? (
                <div>
                  {deleteInfo ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setDeleteInfo(false)}
                      style={{ textTransform: "none", marginRight: "3px" }}
                    >
                      Cancelar
                    </Button>
                  ) : editInfo ? (
                    valorTurno > 0 && moment(endDate) >= moment(startDate) ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => editRecord("turnosextra", "Turno extra")}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Guardar
                      </Button>
                    ) : (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Guardar
                      </Button>
                    )
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setEditInfo(true)}
                      style={{ textTransform: "none", marginRight: "3px" }}
                    >
                      Editar
                    </Button>
                  )}
                  {deleteInfo ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        deleteRecord("turnosextra", "cantidad_turnos_extra")
                      }
                      style={{ textTransform: "none", marginLeft: "3px" }}
                    >
                      Si
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setDeleteInfo(true)}
                      style={{ textTransform: "none", marginLeft: "3px" }}
                    >
                      Eliminar
                    </Button>
                  )}
                </div>
              ) : tempEventInfo.title === "Atención extra" ? (
                <div>
                  {deleteInfo ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setDeleteInfo(false)}
                      style={{ textTransform: "none", marginRight: "3px" }}
                    >
                      Cancelar
                    </Button>
                  ) : editInfo ? (
                    valorTurno > 0 &&
                    moment(endDate) >= moment(startDate) &&
                    Object.keys(patient).length > 0 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          editRecord("atencionextra", "Atención extra")
                        }
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Guardar
                      </Button>
                    ) : (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Guardar
                      </Button>
                    )
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setEditInfo(true)}
                      style={{ textTransform: "none", marginRight: "3px" }}
                    >
                      Editar
                    </Button>
                  )}
                  {deleteInfo ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        deleteRecord("atencionextra", "cantidad_atencion_extra")
                      }
                      style={{ textTransform: "none", marginLeft: "3px" }}
                    >
                      Si
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setDeleteInfo(true)}
                      style={{ textTransform: "none", marginLeft: "3px" }}
                    >
                      Eliminar
                    </Button>
                  )}
                </div>
              ) : tempEventInfo.title === "Vacación" ? (
                estadoVacaciones === "Aprobado" ? null : (
                  <div>
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteInfo(false)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Cancelar
                      </Button>
                    ) : editInfo ? (
                      estadoVacaciones != "" > 0 &&
                      moment(endDate) >= moment(startDate) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => editRecord("vacaciones", "Vacación")}
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditInfo(true)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Editar
                      </Button>
                    )}
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          deleteRecord(
                            "vacaciones",
                            "cantidad_vacaciones_tomadas"
                          )
                        }
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Si
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteInfo(true)}
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Eliminar
                      </Button>
                    )}
                  </div>
                )
              ) : tempEventInfo.title === "Permiso sin sueldo" ? (
                <div>
                  {deleteInfo ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setDeleteInfo(false)}
                      style={{ textTransform: "none", marginRight: "3px" }}
                    >
                      Cancelar
                    </Button>
                  ) : editInfo ? (
                    moment(endDate) >= moment(startDate) ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          editRecord("permisosinsueldo", "Permiso sin sueldo")
                        }
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Guardar
                      </Button>
                    ) : (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Guardar
                      </Button>
                    )
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setEditInfo(true)}
                      style={{ textTransform: "none", marginRight: "3px" }}
                    >
                      Editar
                    </Button>
                  )}
                  {deleteInfo ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        deleteRecord(
                          "permisosinsueldo",
                          "cantidad_permisos_sin_goce_sueldo"
                        )
                      }
                      style={{ textTransform: "none", marginLeft: "3px" }}
                    >
                      Si
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setDeleteInfo(true)}
                      style={{ textTransform: "none", marginLeft: "3px" }}
                    >
                      Eliminar
                    </Button>
                  )}
                </div>
              ) : tempEventInfo.title === "Inasistencia" ? (
                responseInasistencia.solicitud?.estado ===
                "Finalizado" ? null : (
                  <div>
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteInfo(false)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Cancelar
                      </Button>
                    ) : editInfo ? (
                      moment(endDate) >= moment(startDate) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            editRecord("inasistencias", "Inasistencia")
                          }
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditInfo(true)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Editar
                      </Button>
                    )}
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          deleteRecord(
                            "inasistencias",
                            "cantidad_inasistencias"
                          )
                        }
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Si
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteInfo(true)}
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Eliminar
                      </Button>
                    )}
                  </div>
                )
              ) :  tempEventInfo.title === "Renuncia" ? (
                responseRenuncia.solicitud?.estado ===
                "Finalizado" ? null : (
                  <div>
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteInfo(false)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Cancelar
                      </Button>
                    ) : editInfo ? (
                      moment(endDate) >= moment(startDate) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            editRecord("renuncia", "Renuncia")
                          }
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditInfo(true)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Editar
                      </Button>
                    )}
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          deleteRecord(
                            "renuncia",
                            "cantidad_renuncia"
                          )
                        }
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Si
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteInfo(true)}
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Eliminar
                      </Button>
                    )}
                  </div>
                )
              ):  tempEventInfo.title === "Despido" ? (
                responseRenuncia.solicitud?.estado ===
                "Finalizado" ? null : (
                  <div>
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteInfo(false)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Cancelar
                      </Button>
                    ) : editInfo ? (
                      moment(endDate) >= moment(startDate) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            editRecord("despido", "Despido")
                          }
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditInfo(true)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Editar
                      </Button>
                    )}
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          deleteRecord(
                            "despido",
                            "cantidad_despido"
                          )
                        }
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Si
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteInfo(true)}
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Eliminar
                      </Button>
                    )}
                  </div>
                )
              ) :  tempEventInfo.title === "Imponible" ? (
                responseImponible.solicitud?.estado ===
                "Finalizado" ? null : (
                  <div>
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteInfo(false)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Cancelar
                      </Button>
                    ) : editInfo ? (
                      moment(endDate) >= moment(startDate) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            editRecord("imponible", "Imponible")
                          }
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditInfo(true)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Editar
                      </Button>
                    )}
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          deleteRecord(
                            "imponible",
                            "cantidad_imponible"
                          )
                        }
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Si
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteInfo(true)}
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Eliminar
                      </Button>
                    )}
                  </div>
                )
              ) : tempEventInfo.title === "Colacion" ? (
                responseColacion.solicitud?.estado ===
                "Finalizado" ? null : (
                  <div>
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteInfo(false)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Cancelar
                      </Button>
                    ) : editInfo ? (
                      moment(endDate) >= moment(startDate) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            editRecord("colacion", "Colacion")
                          }
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditInfo(true)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Editar
                      </Button>
                    )}
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          deleteRecord(
                            "colacion",
                            "cantidad_Colacion"
                          )
                        }
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Si
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteInfo(true)}
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Eliminar
                      </Button>
                    )}
                  </div>
                )
              ) : tempEventInfo.title === "Movilizacion" ? (
                responseMovilizacion.solicitud?.estado ===
                "Finalizado" ? null : (
                  <div>
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteInfo(false)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Cancelar
                      </Button>
                    ) : editInfo ? (
                      moment(endDate) >= moment(startDate) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            editRecord("movilizacion", "Movilizacion")
                          }
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditInfo(true)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Editar
                      </Button>
                    )}
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          deleteRecord(
                            "movilizacion",
                            "cantidad_movilizacion"
                          )
                        }
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Si
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteInfo(true)}
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Eliminar
                      </Button>
                    )}
                  </div>
                )
              ) : tempEventInfo.title === "Guarderia" ? (
                responseGuarderia.solicitud?.estado ===
                "Finalizado" ? null : (
                  <div>
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteInfo(false)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Cancelar
                      </Button>
                    ) : editInfo ? (
                      moment(endDate) >= moment(startDate) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            editRecord("guarderia", "Guarderia")
                          }
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditInfo(true)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Editar
                      </Button>
                    )}
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          deleteRecord(
                            "guarderia",
                            "cantidad_guarderia"
                          )
                        }
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Si
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteInfo(true)}
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Eliminar
                      </Button>
                    )}
                  </div>
                )
              ) : tempEventInfo.title === "Bienestar" ? (
                responseBienestar.solicitud?.estado ===
                "Finalizado" ? null : (
                  <div>
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteInfo(false)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Cancelar
                      </Button>
                    ) : editInfo ? (
                      moment(endDate) >= moment(startDate) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            editRecord("bienestar", "Bienestar")
                          }
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditInfo(true)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Editar
                      </Button>
                    )}
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          deleteRecord(
                            "bienestar",
                            "cantidad_bienestar"
                          )
                        }
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Si
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteInfo(true)}
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Eliminar
                      </Button>
                    )}
                  </div>
                )
              ) : tempEventInfo.title === "Anticipo" ? (
                responseAnticipo.solicitud?.estado ===
                "Finalizado" ? null : (
                  <div>
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteInfo(false)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Cancelar
                      </Button>
                    ) : editInfo ? (
                      moment(endDate) >= moment(startDate) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            editRecord("anticipo", "Anticipo")
                          }
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Guardar
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditInfo(true)}
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Editar
                      </Button>
                    )}
                    {deleteInfo ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          deleteRecord(
                            "anticipo",
                            "cantidad_anticipo"
                          )
                        }
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Si
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDeleteInfo(true)}
                        style={{ textTransform: "none", marginLeft: "3px" }}
                      >
                        Eliminar
                      </Button>
                    )}
                  </div>
                )
              ) : null}
              {!updateModal ? <div></div>:""}
            </div>
          )}
        </DialogActions>
        <AlertDialog openDialog={abrirAlerta} handleClose={handleCerrarAlerta} message={mensaje} title={titulo} severity={tipoAlerta} />
      </Paper>
    </Modal>
  );
};

export default ModalViewRecord;

ModalViewRecord.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.object,
  tempEventInfo: PropTypes.object,
  setOpenDialogSucess: PropTypes.func,
  setTempMessage: PropTypes.func,
  setPageRefresh: PropTypes.func,
  variableCounter: PropTypes.object,
  setVariableCounter: PropTypes.func,
  eventsVariables: PropTypes.array,
  setEvents: PropTypes.func
};
