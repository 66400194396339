import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Grid,
    Link,
} from "@material-ui/core";
import { 
    CustomTab, 
    CustomTabs, 
    CustomTabPanel, 
    TypographyMui  
} from "utils/componentsStyle";
import { green, indigo, orange  } from "@material-ui/core/colors";
import MedicinesTable from "components/PatientFile/MedicinesTable";
import moment from 'moment-timezone';
import 'moment/locale/es' ;
moment.locale("es");


export default function TabsMedicines({
    recharge,
    editable,
    handleDelete,
    medicineListSimple,
    medicineListCheck,
    medicineListHeld,
}) {

    const [prescriptionsTabs, setprescriptionsTabs] = useState(null);

    useEffect(() => {
        autoSelectTab();
    }, []);

    useEffect(() => {
        if(recharge && prescriptionsTabs === null){
            autoSelectTab();
        }
    });

    const autoSelectTab = () =>  {
        const arrayInfo = [
            !!medicineListSimple.length,
            !!medicineListCheck.length,
            !!medicineListHeld.length,
        ]
        console.log(arrayInfo)
        const tempIndex = arrayInfo.findIndex((item) => item === true);
        if (tempIndex > -1){
            setprescriptionsTabs(tempIndex)
        }
    }

    console.log(prescriptionsTabs)

    return (
        <Grid 
            container
            direction="row"
            justify="center"
            alignItems="center"
            item 
            xs={12} 
        >
            {prescriptionsTabs !== null &&
            <CustomTabs
                value={prescriptionsTabs}
                onChange={(event, newValue) => setprescriptionsTabs(newValue)}
                variant="fullWidth"
                aria-label="tab_"
            >
                {medicineListSimple.length > 0 &&
                <CustomTab label="RECETA SIMPLE" />
                }
                {medicineListCheck.length > 0 &&
                <CustomTab label="RECETA CHEQUE" />
                }
                {medicineListHeld.length > 0 &&
                <CustomTab label="RECETA RETENIDA" />
                }
            </CustomTabs>
            }
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                item 
                xs={12} 
            >
                {medicineListSimple.length > 0 &&
                <CustomTabPanel  value={prescriptionsTabs} index={0}>
                    <Grid 
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item 
                        xs={12} 
                    >
                        <MedicinesTable 
                            rows={medicineListSimple}
                            updateRow={editable}
                            recharge={recharge}
                            typeTable={"Simple"}
                            handleDelete={handleDelete}
                        />
                    </Grid>
                </CustomTabPanel>
                }
                {medicineListCheck.length > 0 &&
                <CustomTabPanel  value={prescriptionsTabs} index={
                    medicineListSimple.length === 0 ? 0 : 1
                }>
                    <Grid 
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item 
                        xs={12} 
                    >
                        <MedicinesTable 
                            rows={medicineListCheck}
                            updateRow={editable}
                            recharge={recharge}
                            typeTable={"Cheque/Controlada"}
                            handleDelete={handleDelete}
                        />
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            item xs={12}
                            style={{margin: "30px 0 0 0"}}
                        >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                item xs={6}
                                style={{margin: "0 0 30px 0"}}
                            >
                                <TypographyMui variant='body2'>
                                    {"Para esta receta debes acceder a Seremi en línea donde tendrás que subir una fotografía de la receta realizada a mano y desde ahí enviar el código correspondiente al correo electrónico del paciente. No olvides escribir “Digitalizada” en la receta escrita antes de fotografíarla."}
                                </TypographyMui>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                item xs={12}
                            >
                                <Button 
                                    style={{
                                        background: indigo[500], 
                                        color: indigo[50],
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                                        margin: "0 31px 0 0"
                                    }} 

                                > 
                                    <Link 
                                        href="https://seremienlinea.minsal.cl/recetas/" 
                                        color="inherit"
                                        target="_blank"
                                    >
                                        SEREMI EN LINEA
                                    </Link>
                                </Button>
                            </Grid>
                        </Grid>

                       
                    </Grid>
                </CustomTabPanel>
                }
                {medicineListHeld.length > 0 &&
                <CustomTabPanel  value={prescriptionsTabs} index={
                    (medicineListSimple.length === 0 && medicineListCheck.length === 0) ? 
                    0 :
                    (medicineListSimple.length === 0 || medicineListCheck.length === 0) ? 
                    1 :
                    2
                }>
                    <Grid 
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item 
                        xs={12}
                    >
                        <MedicinesTable 
                            rows={medicineListHeld}
                            updateRow={editable}
                            recharge={recharge}
                            typeTable={"Digital"}
                            handleDelete={handleDelete}
                        />
                    </Grid>                              
                </CustomTabPanel>
                }
            </Grid>          
        </Grid>
    );
};

