import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import apiform from "utils/APIForm";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export default function ModalChangeQuantity(props) {
  const classes = useStyles();

  const { open, closeModal, divisionRequest, moduleType } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  function updateDivision() {

    const formData = new FormData();

    formData.append("cantidad_fraccionar", divisionRequest.cantidad_fraccionar);
    formData.append("observaciones", divisionRequest.observaciones);
    formData.append("cantidad_resultante", divisionRequest.cantidad_resultante);
    formData.append("estado", "Esperar a egresos");
    formData.append("id_producto_fraccionar", divisionRequest.id_producto_fraccionar);
    formData.append("id_producto_resultante", divisionRequest.id_producto_resultante);
    formData.append("area", "Ingresos");

    apiform
      .put(`api/solicitudfraccionamiento/${divisionRequest.id}`, formData)
      .then(response => {
        console.log(response);
        window.location.href = `/admin/income/divisions/${moduleType}`;
      })

  }

  console.log("division", divisionRequest);

  console.log("cantidad fraccionar", props.cantidadFraccionar);

  function closeNoSave() {
    props.setCantidad_resultante(props.compareQuantity);
    props.closeModal();
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Cantidad resultante</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div style={{ marginBottom: "10rem" }}>
          <p className="text-center font-weight-bold mb-4">
            ¿Qué cantidad debiera quedar del nuevo SKU?
          </p>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justify="center"
            spacing={2}
          >
            <div className="col-md-4 mb-3">
              <label className="text-dark">
                CANTIDAD A FRACCIONAR:
                {parseInt(props.cantidadFraccionar, 10) >=
                parseInt(props.value, 10) ? (
                  <span className="text-danger">
                    debe ser mayor que {props.cantidadFraccionar}
                  </span>
                ) : null}
              </label>
              <input
                type="number"
                min="1"
                className="form-control bg-light font-weight-lighter"
                style={{ color: "#000000" }}
                value={props.value}
                onChange={e => {
                  if (/^[0-9.\b]+$/.test(e.target.value)) {
                    props.setCantidad_resultante(e.target.value);
                  }
                }}
              />
            </div>
            {/* <DetailSelect
              label="SKU:"
              value={props.value}
              onchange={props.onChange}
              list={props.listInsumos}
            /> */}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeNoSave} color="primary">
          Cancelar
        </Button>
        {parseInt(props.value, 10) > parseInt(props.cantidadFraccionar, 10)
        ? (
          <Button onClick={closeModal} color="primary">
            Aceptar
          </Button>
        ) : null}
      </DialogActions>

    </Dialog>
  );
}

ModalChangeQuantity.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func
};
