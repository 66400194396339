import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from "material-table";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import Fade from "@material-ui/core/Fade";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CardActions from "@material-ui/core/CardActions";
import Backdrop from "@material-ui/core/Backdrop";
import AlertDialog from "components/Attendance/AlertDialog";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import api from "utils/API";

import "moment/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
  root: {
    width: "95%",
    overflowY: "scroll",
    maxHeight: "600px"
  },
  container: {
    minHeight: 100
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const ModalRequestEquipment = props => {
  const {
    openModal,
    closeModal,
    demandaDataFrame,
    setDemandaDataFrame,
    dataFrame,
    setDataFrame,
    tempRow,
    tipoTarea
  } = props;

  const classes = useStyles();
  const [change, setChange] = React.useState(false);
  const [abrirAlerta, setAbrirAlerta] = React.useState(false);
  const [titulo, setTitulo] = React.useState("¡Atencion!");
  const [mensaje, setMensaje] = React.useState(
    "Antes debe seleccionar una fecha de inicio para el equipo"
  );
  const [tipoAlerta, setTipoAlerta] = React.useState("warning");


  const handleCerrarAlerta = () => {
    setAbrirAlerta(false);
  };
  const handleSetChange = (row, operation) => {
    let tempdemandaDataFrame = demandaDataFrame;
    let tempDemandaEquipoIndex = tempdemandaDataFrame.findIndex(
      item => item.sku === row.sku
    );
    if (operation === true && tempDemandaEquipoIndex === -1) {
      tempdemandaDataFrame.push({
        bodega: row.stock === "Sí" ? true : false,
        equipo: row.equipo,
        estado: row.estadoOperacional,
        id_bodega: row.id_bodega,
        id_equipo: row.id_equipo,
        fecha_inicio: moment(row.fecha_inicio).format("DD-MM-YYYY"),
        fecha_fin:
          moment(row.fecha_fin).format("DD-MM-YYYY") === "Invalid date"
            ? ""
            : moment(row.fecha_fin).format("DD-MM-YYYY"),
        marca: row.marca,
        modelo: row.modelo,
        sku: row.sku,
        sku_especifico: row?.sku_especifico || " ",
        detalle_especifico: row?.detalle_especifico || " ",
      });
    } else if (operation === false && tempDemandaEquipoIndex !== -1) {
      tempdemandaDataFrame.splice(tempDemandaEquipoIndex, 1);
    }
    setDemandaDataFrame(tempdemandaDataFrame);
    setChange(true);
  };

  const handleSetChangeFechaFin = (date, row) => {
    let tempdataFrame = dataFrame;
    console.log(dataFrame)
    let tempEquipoIndex = dataFrame.findIndex(item => item.sku === row.sku);
    if (tempEquipoIndex !== -1) {
      tempdataFrame[tempEquipoIndex].fecha_fin = date;
    }
    setDataFrame(tempdataFrame)
    setChange(true);
  };
  const handleSetChangeFechaInicio = (date, row) => {
    let tempdataFrame = dataFrame;
    let tempEquipoIndex = dataFrame.findIndex(item => item.sku === row.sku);
    if (tempEquipoIndex !== -1) {
      tempdataFrame[tempEquipoIndex].fecha_inicio = date;
    }
    setDataFrame(tempdataFrame);
    setChange(true);
  };

  const handleSaveRequest = () => {

    let bodyes_list = {};
    var body_solicitud_paciente = {
      id_solicitud_paciente: tempRow.id_solicitud_paciente.id,
      id_tarea_paciente: tempRow.id
    };
    bodyes_list["solicitud_paciente"] = body_solicitud_paciente;
    if (tipoTarea === "SolicitarEquipo") {
      var body_productos_pedidos = demandaDataFrame.map(equip => ({
        cantidad: 1,
        id_equipo_id: equip.id_equipo,
        fecha_inicio:
          equip?.fecha_inicio !== ""
            ? moment(equip?.fecha_inicio, "DD-MM-YYYY").format("YYYY-MM-DD")
            : null,
        fecha_fin: equip?.fecha_fin !== "" ? equip?.fecha_fin : null,
        tipo: "Solicitar"
      }));

      bodyes_list["productos_pedidos"] = body_productos_pedidos;
      api
        .post(`api/resumen/actualizar/solicitud/equipos/`, bodyes_list)
        .then(() => {

          setTipoAlerta("success");
          setTitulo("¡Exito!");
          setMensaje("Sus en la solicitud se han guardado con exito.");
          setAbrirAlerta(true);
          window.location.reload(true);
        })
        .catch((response) => {

          setTipoAlerta("warning");
          setTitulo("¡Atención!");
          setMensaje("Ha ocurrido un problema al guardar los cambios.");
          setAbrirAlerta(true);
          window.location.reload(true);
        });
    } else {
      var body_productos_pedidos = demandaDataFrame.map(equip => ({
        cantidad: 1,
        id_equipo_id: equip.id_equipo,
        // id_equipo_bodega_id: equip.id_bodega ,
        fecha_inicio:
          equip?.fecha_inicio !== ""
            ? moment(equip?.fecha_inicio, "DD-MM-YYYY").format("YYYY-MM-DD")
            : null,
        fecha_fin:
          equip?.fecha_fin !== ""
            ? moment(equip?.fecha_fin, "DD-MM-YYYY").format("YYYY-MM-DD")
            : null,
        tipo: "Reemplazar"
      }));
      bodyes_list["productos_pedidos"] = body_productos_pedidos;
      api
        .post(`api/resumen/guardar/cambios/reemplazar/equipos/`, bodyes_list)
        .then(response => {

          setTipoAlerta("success");
          setTitulo("¡Exito!");
          setMensaje("Sus en la solicitud se han guardado con exito.");
          setAbrirAlerta(true);
          window.location.reload(true);
        })
        .catch(error => {

          setTipoAlerta("warning");
          setTitulo("¡Atención!");
          setMensaje("Ha ocurrido un problema al guardar los cambios.");
          setAbrirAlerta(true);
          window.location.reload(true);
        });
    }
  };
  const handleConfirmSaveRequest = event => {

    let bodyes_list = {};
    var body_solicitud_tarea_paciente = {
      id_solicitud_paciente: tempRow.id_solicitud_paciente.id,
      id_tarea_paciente: tempRow.id
    };
    bodyes_list["solicitud_tarea_paciente"] = body_solicitud_tarea_paciente;
    if (tipoTarea === "SolicitarEquipo") {
      var body_productos_pedidos = demandaDataFrame.map(equip => ({
        cantidad: 1,
        id_equipo_id: equip.id_equipo,
        // id_equipo_bodega_id: equip.id_bodega,
        fecha_inicio:
          equip?.fecha_inicio !== ""
            ? moment(equip?.fecha_inicio, "DD-MM-YYYY").format("YYYY-MM-DD")
            : null,
        fecha_fin:
          equip?.fecha_fin !== ""
            ? moment(equip?.fecha_fin, "DD-MM-YYYY").format("YYYY-MM-DD")
            : null,
        tipo: "Solicitar"
      }));
      bodyes_list["productos_pedidos"] = body_productos_pedidos;
      api
        .post(`api/resumen/enviar/cambios/solicitud/equipos/`, bodyes_list)
        .then(response => {

          setTipoAlerta("success");
          setTitulo("¡Exito!");
          setMensaje("Sus solicitud ha sido enviada con exito a Bodega.");
          setAbrirAlerta(true);
          window.location.reload(true);
        })
        .catch(error => {

          setTipoAlerta("warning");
          setTitulo("¡Atención!");
          setMensaje("Ha ocurrido un problema al enviar si solicitud a Bodega.");
          setAbrirAlerta(true);
          window.location.reload(true);
        });
    } else {
      var body_productos_pedidos = demandaDataFrame.map(equip => ({
        cantidad: 1,
        id_equipo_id: equip.id_equipo,
        // id_equipo_bodega_id: equip.id_bodega,
        fecha_inicio:
          equip?.fecha_inicio !== ""
            ? moment(equip?.fecha_inicio, "DD-MM-YYYY").format("YYYY-MM-DD")
            : null,
        fecha_fin:
          equip?.fecha_fin !== ""
            ? moment(equip?.fecha_fin, "DD-MM-YYYY").format("YYYY-MM-DD")
            : null,
        tipo: "Reemplazar"
      }));
      bodyes_list["productos_pedidos"] = body_productos_pedidos;
      api
        .post(`api/resumen/enviar/cambios/reemplazo/equipos/`, bodyes_list)
        .then(response => {

          setTipoAlerta("success");
          setTitulo("¡Exito!");
          setMensaje("Sus solicitud ha sido enviada con exito a Bodega.");
          setAbrirAlerta(true);
          window.location.reload(true);
        })
        .catch(error => {

          setTipoAlerta("warning");
          setTitulo("¡Atención!");
          setMensaje("Ha ocurrido un problema al enviar si solicitud a Bodega.");
          setAbrirAlerta(true);
          window.location.reload(true);
        });
    }
  };

  React.useEffect(() => {
    if (change) {
      setChange(false);
    }
  });

  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}

    >
        <Fade in={openModal}>
            <Card className={classes.root} variant="outlined">
                <CardHeader

                   action={
                       <IconButton aria-label="delete"  onClick={closeModal} size="medium" style={{textTransform:'none', outline: 'none'}}>
                           <CloseIcon fontSize="inherit" />
                       </IconButton>
                   }
                   title="Resumen solicitud"
                />
                <CardContent>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid container item xs={12} >

                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Equipo</TableCell>
                                            <TableCell align="left">SKU</TableCell>
                                            <TableCell align="left">Especifico</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {!change && demandaDataFrame && demandaDataFrame.map((row,index) => (
                                        <TableRow key={index}>
                                            <TableCell align="left">{row.equipo}</TableCell>
                                            <TableCell align="left">{row.sku}</TableCell>
                                            <TableCell align="left">{row.sku_especifico !== " " ? `(${row.sku_especifico}) - ${row.detalle_especifico}` : ""}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                        <h3>Confirme y/o cambie los equipos equipos para solicitar o reemplazar</h3>
                        <Grid container  item  xs={12} style={{display:'block'}}>
                            {!change && dataFrame && <div></div>}
                            <MaterialTable
                                localization={{
                                    pagination: {
                                        labelRowsSelect: 'Filas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: 'Primera página',
                                        previousTooltip: 'Página anterior',
                                        nextTooltip: 'Próxima página',
                                        lastTooltip: 'Última página'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} Equípo(s) seleccionados',
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar',
                                        showColumnsTitle:'Mostrar Columnas',
                                    },

                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos para mostrar',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        }
                                    }
                                }}
                                columns={[

                                    {   title: "Seleccionar",
                                        field: 'checked',
                                        filtering: false ,
                                        render: (row) => {
                                            return (
                                                    <Checkbox
                                                        inputProps={{ 'aria-labelledby': "hola" }}
                                                        disabled={(row.estadoOperacional === 'No operativo' ) ? true:false}
                                                        checked={row.checked}
                                                        onClick={() => {
                                                            if(moment(row?.fecha_inicio, 'DD-MM-YYYY').isValid()){
                                                                row.checked=!row.checked
                                                                handleSetChange(row,row.checked)
                                                            }else{
                                                                setTipoAlerta("warning")
                                                                setTitulo("¡Atención!")
                                                                setMensaje("Antes debe seleccionar una fecha de inicio para el equipo.")
                                                                setAbrirAlerta(true)
                                                            }
                                                        }}
                                                    />
                                                );
                                        }
                                    },
                                    { title: 'Equípo', field: 'equipo' },
                                    { title: 'SKU', field: 'sku' },
                                    {   title: 'Fecha Inicio',
                                        field: 'fecha_inicio',
                                        render: (row) => {
                                            return (
                                                <Box style={{minWidth:"162px"}}>
                                                    <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            InputAdornmentProps={{ position: "end" }}
                                                            label="Fecha Inicio"
                                                            key={row.sku+"inicio"}
                                                            format="DD-MM-YYYY"
                                                            variant="inline"
                                                            inputVariant="outlined"
                                                            InputProps={{ readOnly: true }}
                                                            value={moment(row?.fecha_inicio, 'DD-MM-YYYY').isValid() ? moment(row?.fecha_inicio,'DD-MM-YYYY'):null}
                                                            onChange={date=>handleSetChangeFechaInicio(date,row,row.value)}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Box>
                                            );
                                        }
                                    },
                                    {   title: 'Fecha Fin',
                                        field: 'fecha_fin',
                                        render: (row) => {
                                            return (
                                                <Box style={{minWidth:"162px"}}>
                                                    <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            InputAdornmentProps={{ position: "end" }}
                                                            label="Indefinido"
                                                            key={row.sku+"fin"}
                                                            format="DD-MM-YYYY"
                                                            variant="inline"
                                                            inputVariant="outlined"
                                                            InputProps={{ readOnly: true }}
                                                            value={moment(row?.fecha_fin, 'DD-MM-YYYY').isValid() ? moment(row?.fecha_fin,'DD-MM-YYYY'):null}
                                                            onChange={date=>handleSetChangeFechaFin(date,row,row.value)}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Box>
                                            );
                                        }
                                    },

                                ]}
                                data={dataFrame}
                                options={{
                                    selection: false,
                                    showTitle: false,
                                    filtering: true,
                                    doubleHorizontalScroll: true,
                                    selectionProps: rowData => ({
                                    disabled: rowData.stock === 'No' ||  rowData.estadoOperacional === 'No operativo',
                                    color: 'primary'
                                    }),
                                    headerStyle: {
                                        position: "sticky",
                                        top: "0"
                                    },
                                    maxBodyHeight: "300px",
                                    actionsColumnIndex: -1
                                }}
                                detailPanel={[
                                    {
                                        icon: () => <AddIcon color="primary"></AddIcon>,
                                        tooltip:"Informacion extra",
                                        render: rowData => {
                                            return (
                                                <TableContainer component={Paper} style={{ padding: '10px 0px 10px 50px' }}>
                                                    <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">Estado cable poder</TableCell>
                                                            <TableCell align="left">Estado bolsa transporte</TableCell>
                                                            <TableCell align="left">Estado autonomia</TableCell>
                                                            <TableCell align="left">Estado mordaza fijacion</TableCell>
                                                            <TableCell align="left">Estado soporte atril</TableCell>
                                                            <TableCell align="left">Fecha Entrada</TableCell>
                                                            <TableCell align="left">Fecha Salida</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow >
                                                            <TableCell align="left">{rowData.extraDetail?.estadoCablePoder}</TableCell>
                                                            <TableCell align="left">{rowData.extraDetail?.estadoBolsaTransporte}</TableCell>
                                                            <TableCell align="left">{rowData.extraDetail?.estadoAutonomia}</TableCell>
                                                            <TableCell align="left">{rowData.extraDetail?.estadoMordazaFijacion}</TableCell>
                                                            <TableCell align="left">{rowData.extraDetail?.estadoSoporteAtril}</TableCell>
                                                            <TableCell align="left">{rowData.extraDetail?.fechaEntrada}</TableCell>
                                                            <TableCell align="left">{rowData.extraDetail?.fechaSalida}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )
                                        }
                                    },
                                ]}

                            />
                        </Grid>

                    </Grid>
                </CardContent>
                <CardActions style={{height:'50px'}}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-end"
                    >
                        <Grid container  item xs={2} spacing={1}>
                            <Button
                                variant="contained"
                                className="text-white"
                                color="primary"
                                onClick={handleConfirmSaveRequest}
                            >
                                Guardar y Enviar
                            </Button>
                        </Grid>
                        <Grid container  item xs={2} spacing={1}>
                            <Button
                                variant="contained"
                                className="text-white"
                                style={{background : 'green'}}
                                onClick={handleSaveRequest}
                            >
                                Guardar
                            </Button>
                        </Grid>
                        <Grid container item xs={2} spacing={1}>
                            <Button
                                variant="contained"
                                className="text-white"
                                color="secondary"
                                onClick={closeModal}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                    <AlertDialog openDialog={abrirAlerta} handleClose={handleCerrarAlerta} message={mensaje} title={titulo} severity={tipoAlerta} />
                </CardActions>
            </Card>
        </Fade>
    </Modal>
  );
}
export default ModalRequestEquipment;