export const colorGreen = {
  bgcolor: "#32CD32",
  borderColor: "text.primary",
  m: 1,
  border: 1,
  style: { width: "1.5rem", height: "1.5rem" }
};

export const colorRed = {
  bgcolor: "#FF0000",
  borderColor: "text.primary",
  m: 1,
  border: 1,
  style: { width: "1.5rem", height: "1.5rem" }
};
export const colorWhite = {
  bgcolor: "#FFFFFF",
  borderColor: "text.primary",
  m: 1,
  border: 1,
  style: { width: "1.5rem", height: "1.5rem" }
};
