import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


const ModalauthorizationQuotation = props => {
  const {
    openModal,
    closeModal,
    tempRow,
    setauxData,
    dataArray,
    finishArray,
    setFinishArray
  } = props;
  const [approvalFile, setApprovalFile] = useState(null);
  const getIndex = dataArray.findIndex(row => row.id === tempRow.id);

  const uploadAuthorization = () => {
    const formData = new FormData();
    formData.append("archivo", approvalFile[0]);
    formData.append("tipo_documento", "AutorizacionExamenesIsapre");
    formData.append("nombre_archivo", approvalFile[0].name);
    formData.append("id_paciente", parseInt(tempRow.id_ficha.id_paciente.id));
    formData.append("id_solicitud_paciente", parseInt(tempRow.id_solicitud_paciente.id));

    api.post(`api/clinica/${tempRow.id_ficha.id_paciente.id}/solicitudes/cotizacion/${tempRow.id_solicitud_paciente.id}/subiraprobacion/`, formData).then(() => {
        let tempArray = dataArray;
        let tempFinish = finishArray;
        tempArray[getIndex].estado = "Finalizado";
        tempFinish.push(tempArray[getIndex]);
        setFinishArray(tempFinish);
        tempArray.splice(getIndex, 1);
        setauxData(tempArray);

        closeModal();
        })
  };

  const _content_ =  <div>
    <Grid
      item
      container
      xs={12}
      justify="center"
      style={{ marginTop: "1em" }}
    >
      <Grid item container xs={12} justify="center">
        {approvalFile != null ? (
          approvalFile[0].name
        ) : (
          <span></span>
        )}
      </Grid>
      <input
        id="contained-button-file-isapre"
        type="file"
        onChange={e => setApprovalFile(e.target.files)}
        hidden
      />
      <label htmlFor="contained-button-file-isapre">
        <Button
          variant="contained"
          color="primary"
          component="span"
          style={{ textTransform: "none" }}
        >
          Adjuntar autorización de isapre
        </Button>
      </label>
    </Grid>
  </div>

  const _actions_ = <>
     <Button
      variant="contained"
      color="primary"
      onClick={closeModal}
      style={{ textTransform: "none" }}
    >
      Atrás
    </Button>
    <Button
      disabled={approvalFile === null ? true : false}
      variant="contained"
      color="primary"
      style={{ textTransform: "none" }}
      onClick={uploadAuthorization}
    >
      Guardar solicitud
    </Button>
  </>


  return (
    <ModalDialog
      open={openModal}
      onClose={closeModal}
      title={`Adjuntar autorización de cotización`}
      _content_={_content_}
      _actions_ = {_actions_}
      maxWidth="xs"
    />
  );
};

export default ModalauthorizationQuotation;

ModalauthorizationQuotation.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRow: PropTypes.object,
  setauxData: PropTypes.func,
  dataArray: PropTypes.array,
  finishArray: PropTypes.array,
  setFinishArray: PropTypes.func
};
