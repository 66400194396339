import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import {
  green,
  indigo,
} from "@material-ui/core/colors";
import {TypographyMui as Typography} from "utils/componentsStyle";
import RemunerationTable from "views/Widgets/Remunerations/RemunerationTable";
import ModalEditBenefit from "components/Remunerations/ModalEditBenefit";
import ModalDeleteBenefit from "components/Remunerations/ModalDeleteBenefit";
import ModalReverseBenefit from "components/Billing/ModalReverseBenefit";
import ModalAddBenefit from "components/Remunerations/ModalAddBenefit";
import RemunerationDoc from "components/Remunerations/RemunerationDoc";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import api from "utils/API";
import {toMoneyFormat} from "utils/formatFunctions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


const classStyle = makeStyles((theme) => ({
  text: {
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    "&:hover": {
      color: indigo[400],
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PersonalRemuneration = ({
  personalRow,
  valuesData,
  remuneration,
  setremuneration,
  changeButtons,
  setopenBackdrop,
  setchangeButtons,
  monthDate,
}) => {
  const classesStyle = classStyle();
  const [dataArray, setdataArray] = useState([]);
  const [expandedPanel, setexpandedPanel] = useState(null);
  const [openEditModal, setopenEditModal] = useState(null);
  const [openDeleteModal, setopenDeleteModal] = useState(null);
  const [openAddModal, setopenAddModal] = useState(null);
  const [openReverseModal, setopenReverseModal] = useState(null);
  const [doc, setdoc] = useState(null);
  const [chargeDoc, setchargeDoc] = useState(false);
  const UTM = useSelector(state => state.currentGlobalVar.UTM);
  const UF = useSelector(state => state.currentGlobalVar.UF);
  const UF2 = useSelector(state => state.currentGlobalVar.UF2);
  const TUF = useSelector(state => state.currentGlobalVar.TUF);
  const TUF2 = useSelector(state => state.currentGlobalVar.TUF2);
  const minSalary = useSelector(state => state.currentGlobalVar.minSalary);
  const listQuotationAFP = useSelector(state => state.currentGlobalVar.listQuotationAFP);
  const componentRef = useRef();

  useEffect(() => {
    initialData();
  }, [])

  useEffect(() => {
    if (chargeDoc) {
      setchargeDoc(false);

      handlePrint();
    }
  });

  const initialData = () => {
    setdataArray(valuesData)
  };

  const handleGenerateDoc = () => {

    setchangeButtons(true);
    setdoc("remuneracion")
    setchargeDoc(true);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const handleUpdateRow = (row, value) => {
    if (row.valores.id){
      const body = {
        valor:  value,
      };
      api.put(`api/remuneracion/item/actualizar/${row.valores.id}`, body).then((response) => {
        setdataArray(response.data.filter((row) => (!row.valores.eliminado)));

        setchangeButtons(true);
      });
    } else {
      const body = {
        valor_inicial: value,
        valor_modificado: value,
        editado: 1,
        eliminado: 0,
        id_item_remuneracion_id: row.valores.id_item_remuneracion_id,
        id_remuneracion_id: row.valores.id_remuneracion_id,
      }
      api.patch(`api/remuneracion/${remuneration.id}/item/crear/`, body).then((response) => {
        setdataArray(response.data.filter((row) => (!row.valores.eliminado)));

        setchangeButtons(true);
      });
    }
  };

  const handleAddRow = (auxRow) => {
    setchangeButtons(true);
    api.post(`api/remuneracion/${remuneration.id}/item/crear/`, auxRow).then((response) => {
      setdataArray(response.data.filter((row) => (!row.valores.eliminado)));
      setchangeButtons(true);

    });
  };

  const handleDeleteRow = (row) => {

    if (row.valores.id){
      api.delete(`api/remuneracion/item/borrar/${row.valores.id}`).then((response) => {
        setdataArray(response.data.filter((row) => (!row.valores.eliminado)));
        setchangeButtons(true);

      });
    } else {
      const body = {
        valor_inicial: 0,
        valor_modificado: 0,
        editado: 1,
        eliminado: 1,
        id_item_remuneracion_id: row.valores.id_item_remuneracion_id,
        id_remuneracion_id: row.valores.id_remuneracion_id,
      }
      api.patch(`api/remuneracion/${remuneration.id}/item/crear/`, body).then((response) => {
        setdataArray(response.data.filter((row) => (!row.valores.eliminado)));

        setchangeButtons(true);
      });
    }

  };

  const handleReverse = () => {

    api.post(`api/remuneracion/fichapersonal/${remuneration.id_ficha_personal}/fecha/${moment(monthDate).format("YYYY-MM-DD")}`).then((response) => {
      setremuneration(response.data["remuneracion"]);
      setdataArray(response.data["lista_item_remuneracion"].filter((row) => (!row.valores.eliminado)));
      setchangeButtons(true);

    })
  };

  return (

    <div>
      <div>
        {changeButtons === false && <div></div>}
        <GridItem xs={12}>
          <GridItem
            container
            direction="column"
            justify="center"
            alignItems="center"
            xs={12}
          >
            <GridItem
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              xs={12}
            >
              <GridItem
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                xs={2}
              >
                <Typography variant="body2" gutterBottom><b>RUT:</b></Typography>
              </GridItem>
              <GridItem
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                xs={2}
              >
                <Typography variant="body2" gutterBottom>{personalRow.rut}</Typography>
              </GridItem>
            </GridItem>
            <GridItem
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              xs={12}
            >
              <GridItem
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                xs={2}
              >
                <Typography variant="body2" gutterBottom><b>Dias Mes:</b></Typography>
              </GridItem>
              <GridItem
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                xs={2}
              >
                <Typography variant="body2" gutterBottom>{`${moment(monthDate).daysInMonth()}`}</Typography>
              </GridItem>
            </GridItem>
          </GridItem>
          <Grid item xs={12}>
            <ExpansionPanel
                square
                expanded={expandedPanel === "panel1"}
                onChange={() => setexpandedPanel(expandedPanel === "panel1" ? "" : "panel1")}
            >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={classesStyle.title}
                >
                    <Typography
                        className={classesStyle.text}
                        variant="body1"
                        gutterBottom
                    >
                        {`Variables Softland `}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Grid
                        item
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        xs={12}
                    >
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>UF:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(UF) }`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>UF Seguro:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(UF2) }`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>Tope UF:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(TUF)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>Tope UF seguro:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(TUF2)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>UTM:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(UTM)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            xs={6}
                        >
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    <b>Salario Mínimo:</b>
                                </Typography>
                            </Grid>
                            <Grid item
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                xs={6}
                            >
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    className='align-self-center'
                                >
                                    {`${toMoneyFormat(minSalary)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            xs={12}
                            style={{margin: "10px 0"}}
                        >
                            <TableContainer style={{height: "440px"}}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="text-center align-top">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    <b>Tramo</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-top">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    <b>Desde</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-top">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    <b>Hasta</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-top">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    <b>Tasa</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-top">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    <b>Rebaja Impuesto</b>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {listQuotationAFP.map((row, index) => (
                                        <TableRow
                                            key={`${index}-workRows`}
                                        >
                                            <TableCell className="text-center align-middle">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                {row.Tramo}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-middle">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    {row.TopeMin}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-middle">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    {row.TopeMax}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-middle">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    {row.Porcentaje}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="text-center align-middle">
                                                <Typography
                                                    variant='body2'
                                                    color='primary'
                                                    className='align-self-center'
                                                >
                                                    {row.CantRebajar}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>

          <RemunerationTable
            dataArray={dataArray}
            setdataArray={setdataArray}
            setopenEditModal={setopenEditModal}
            setopenDeleteModal={setopenDeleteModal}
            changeButtons={changeButtons}
          />
        </GridItem>
        <GridItem
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
          style={{margin: "10px 0"}}
        >
          <Button
            mr={2}
            variant="contained"
            className="text-white"
            onClick={() => setopenAddModal(true)}
            style={{margin: "0 5px", background: green[500]}}
          >
            Agregar item
          </Button>
          <Button
            mr={2}
            variant="contained"
            className="text-white"
            color="primary"
            onClick={() => handleGenerateDoc()}
            style={{margin: "0 5px"}}
          >
            Generar remuneracion
          </Button>
          <Button
            mr={2}
            variant="contained"
            className="text-white"
            color="primary"
            onClick={() => setopenReverseModal(true)}
            style={{margin: "0 5px"}}
          >
            Revertir todos los cambios
          </Button>

        </GridItem>
      </div>
      <div style={{display: "none"}}>
      {/* <div> */}
        <RemunerationDoc
          ref={componentRef}
          personalRow={personalRow}
          doc={doc}
          dataArray={dataArray}
          monthDate={monthDate}
        />
      </div>
      {openAddModal &&
      <ModalAddBenefit
        open={!!openAddModal}
        handleClose={() => setopenAddModal(null)}
        handleAddRow={handleAddRow}
      />
      }
      {openEditModal &&
      <ModalEditBenefit
        open={!!openEditModal}
        handleClose={() => setopenEditModal(null)}
        row={openEditModal}
        handleUpdateRow={handleUpdateRow}
      />
      }
      {openDeleteModal &&
      <ModalDeleteBenefit
        open={!!openDeleteModal}
        handleClose={() => setopenDeleteModal(null)}
        row={openDeleteModal}
        handleDeleteRow={handleDeleteRow}
      />
      }
      {openReverseModal &&
      <ModalReverseBenefit
        open={!!openReverseModal}
        handleClose={() => setopenReverseModal(null)}
        handleReverse={handleReverse}
      />
      }
    </div>

  );
};

export default PersonalRemuneration;
