import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";
import ReceptionInfoTransport from "./ReceptionInfoTransport";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Grid from "@material-ui/core/Grid";


export default function OutcomeReceptions() {

  const { id } = useParams();
  const id_order = parseInt(id, 10);
  const [moduleType, setmoduleType] = useState("");
  const [loading, setLoading] = useState(false);

  const [observation, setObservation] = useState("");
  const [order, setOrder] = useState([]);

  const [envioPedido, setEnvioPedido] = useState({});

  useEffect(() => {
    setLoading(false);
    const tempArrayURL = window.location.href.split("/");
    setmoduleType(tempArrayURL[tempArrayURL.length - 2]);
    api.get(`api/enviospedidos/${id_order}`).then((result) => {
      setEnvioPedido(result.data);
      api
        .get(
          `api/egresos/recepcion/solicitudpedidosdepth/${result.data.id_solicitud_pedido}`
        )
        .then((response) => {
          setOrder(response.data);
          setLoading(true);
        })

    });
  }, [id]);

  function update() {


    const formData = new FormData();

    formData.append("numero_pedido", envioPedido.numero_pedido);
    formData.append("estado", envioPedido.estado);
    if (observation !== "") {
      formData.append("observacion", observation);
    }

    apiform
      .put(`api/enviospedidos/${envioPedido.id}`, formData)
      .then((response) => {
        window.location.href = `/admin/outcome/receptions/${moduleType}`;
      })

  }

  function updateAndSend() {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    const formData = new FormData();

    formData.append("numero_pedido", envioPedido.numero_pedido);
    if (observation !== "") {
      formData.append("observacion", observation);
    }
    formData.append("estado", "Esperar a transporte");
    formData.append("estado_egreso", "Esperar a transporte");

    const formData3 = new FormData();

    formData3.append("id_paciente", order.id_paciente.id);
    formData3.append("estado_recepcion", "NoAsignado");
    formData3.append("fecha_solicitud", order.fecha_solicitud);
    formData3.append("tipo_solicitud", order.tipo_solicitud);
    formData3.append("solicitud_tiene_farmacia", order.tiene_medicamentos);
    formData3.append("comuna", order?.id_paciente?.id_comuna?.nombre || "-");
    formData3.append("zona", order?.id_paciente?.id_zona?.nombre || "Sin Zona");
    formData3.append("id_envios_pedidos", envioPedido.id);
    formData3.append("es_farmacia", group === "Insumos" ? false : true);
    formData3.append(
      "solicitud_tiene_farmacia",
      order.tiene_medicamentos ? true : false
    );

    apiform.patch(`api/enviospedidos/${envioPedido.id}`, formData).then((request) => {
      apiform.post("api/agendatransportes/", formData3).then((response) => {
        window.location.href = `/admin/outcome/receptions/${moduleType}`;
      })
    })

  }

  return (
    <GridContainer>
      <ViewTitle
        title="ENVÍO POR TRANSPORTE DE LA EMPRESA"
        message="En esta sección podrás ingresar los datos del envío por transporte de la empresa."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/outcome/tasks/${moduleType}`}>
              Tareas de egresos
            </Link>
            <Typography color="textPrimary">
              Envío por transporte de la empresa
            </Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="mt-3">
              <GridContainer justify="center">
                <ReceptionInfoTransport
                  idEnvioPedido={envioPedido.id}
                  order={order}
                  transport={"Company"}
                  moduleType={moduleType}
                />
              </GridContainer>
            </GridContainer>
            <CardBody className="mb-1">
              <GridContainer justify="center">
                <Grid container item xs={12} style={{ textAlign: "center" }}>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <DetailTextArea
                      label="OBSERVACIONES PARA TRANSPORTE:"
                      placeholder="Observaciones para transporte"
                      value={observation}
                      maxlength="500"
                      onchange={(e) => {
                        setObservation(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
                <GridContainer
                  justify="center"
                  style={{ textAlign: "center" }}
                  className="m-3 mt-3 mb-3"
                >
                  {envioPedido.observacion ? (
                    <div>
                      <p>
                        <span className="font-weight-bold">
                          Observación anterior:
                        </span>
                        {envioPedido.observacion
                          ? envioPedido.observacion
                          : null}
                      </p>
                    </div>
                  ) : observation === "" ? (
                    <span className="text-danger">
                      Debes ingresar una observación
                    </span>
                  ) : null}
                </GridContainer>
                {observation ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={update}
                    className="m-1"
                  >
                    Guardar
                  </Button>
                ) : null}
                {observation ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={updateAndSend}
                    className="m-1"
                  >
                    Guardar y enviar
                  </Button>
                ) : null}
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
