import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const ProductsIncome = ({moduleType}) => {
  return (
    <div className="mb-3 m-1">
      <div className="card">
        <div className="card-header text-center">Ingreso de productos</div>
        <div className="card-body">
          <div className="row">
            <div className="text-center">
              <h3>8</h3>
              <div className="col-sm text-center">Pendientes</div>
            </div>
            <div className="text-center">
              <h3>13</h3>
              <div className="col-sm text-center">A realizar hoy</div>
            </div>
            <div className="text-center">
              <h3>40</h3>
              <div className="col-sm text-center">A realizar esta semana</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm text-center mt-3 mb-1">
              <Link to={`/admin/income/productsincome/${moduleType}`}>
                <Button variant="contained" color="primary">
                  Ver detalle
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsIncome;
