import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from '@material-ui/core/TextField';
import api from "utils/API";
import apiform from "utils/APIForm";
import DialogCancelRequest from "./DialogCancelRequest";
import DialogSendRequest from "./DialogSendRequest";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalExamToPerform = props => {
  const classes = useStyles();
  const { openModal, closeModal, tempRow } = props;

  const [openAnimation, setOpenAnimation] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [fileResultsExam, setFileResultExams] = useState(null);
  const [fileRequestExam, setFilesRequestExam] = useState(null);
  const [fileIsapreAuthorization, setFileIsapreAuthorization] = useState(null);
  const [openDialogCancelRequest, setOpenDialogCancelRequest] = useState(false);
  const [openDialogSendRequest, setOpenDialogSendRequest] = useState(false);
  const [addExamData, setAddExamData] = useState(false);
  const [examDate, setExamDate] = useState(moment());
  const [comments, setComments] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [results, setResults] = useState("");
  const [pageRefresh, setPageRefresh] = useState(false);

  console.log("tempRow", tempRow);

  const handleDateChange = date => {
    setExamDate(date);
  };

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
    if (pageRefresh) {
      setPageRefresh(false);
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);

  };

  const handleDeleteFile = index => {
    setPageRefresh(true);
    let tempFiles = fileResultsExam;
    tempFiles.splice(index, 1);
    console.log("tempFiles", tempFiles);
    setFileResultExams(tempFiles);
  };

  const uploadResults = () => {
    setOpenAnimation(true);

    let sendPatch = {
      resultados: results
    };
    api
      .patch(
        `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/examenes/${tempRow.id_solicitud_paciente.id}/`,
        sendPatch
      )
      .then(response => {
        console.log("response", response);
        const formData = new FormData();
        formData.append("archivo", fileResultsExam[0]);
        formData.append("tipo_documento", "ResultadosExamenes");
        formData.append("nombre_archivo", fileResultsExam[0].name);
        formData.append(
          "id_paciente",
          tempRow.id_solicitud_paciente.id_paciente_id
        );
        //formData.append("resultados", results);

        apiform
          .post(
            `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/examenes/${tempRow.id_solicitud_paciente.id}/documentos/resultado_examen/subir/`,
            formData
          )
          .then(response => {
            console.log("response", response);
            window.location.href = "/admin/clinicRequest/";
            closeModal();
            //window.location.href = "/admin/clinicRequest/";
            // closeModal();
          })

      })


      {/*
    let sum = fileResultsExam.length;
    let count = 0;

    fileResultsExam.map(row => {
      api
        .patch(`/api/paciente/14/solicitudes/examenes/470/`, sendPatch)
        .then(response => {
          apiform
            .post(
              `/api/paciente/14/solicitudes/examenes/470/documentos/resultado_examen/subir/`,
              formData
            )
            .then(response => {
              console.log("response", response);
              //window.location.href = "/admin/clinicRequest/";
              // closeModal();
            })

          console.log("response", response);
        })
        .catch(error => console.log("error", error));
    });

      */}
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        {openAnimation ? (
          <ModalTitle id="customized-dialog-title">Datos del examen</ModalTitle>
        ) : (
          <ModalTitle id="customized-dialog-title" onClose={closeModal}>
            Datos del examen
          </ModalTitle>
        )}
        <DialogContent>
          {pageRefresh && <div></div>}
          <Grid container className={classes.root} justify="center">
            <Grid item container xs={12} justify="center" spacing={1}>
              <Grid item container xs={8} justify="center">
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Resultados"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={results}
                  onChange={e => setResults(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justify="center"
              spacing={1}
              style={{ marginTop: "2em" }}
            >
              <Grid
                item
                container
                xs={12}
                style={{ marginBottom: "4em" }}
                justify="center"
              >
                <Grid
                  item
                  container
                  xs={12}
                  justify="center"
                  style={{ marginBottom: "4px" }}
                >
                  {fileResultsExam != null ? (
                    <Typography variant="body2" gutterBottom>
                      {fileResultsExam[0].name}
                    </Typography>
                  ) : null}
                </Grid>
                <input
                  id="contained-button-file-isapre"
                  //multiple
                  type="file"
                  onChange={e => setFileResultExams(e.target.files)}
                  hidden
                />
                <label htmlFor="contained-button-file-isapre">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ textTransform: "none", marginRight: "3px" }}
                  >
                    Adjuntar resultados
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        {openAnimation ? (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            <CircularProgress />
          </DialogActions>
        ) : (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            {fileResultsExam != null ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#43a047",
                  textTransform: "none",
                  color: "#fff"
                }}
                onClick={() => {
                  uploadResults();
                  //editRequest(true);
                  //setAddExamData(true);
                }}
              >
                Enviar resultados
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none"
                }}
                onClick={() => {
                  uploadResults();
                  //editRequest(true);
                  //setAddExamData(true);
                }}
              >
                Enviar resultados
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: "none"
              }}
              onClick={() => {
                closeModal();
                //editRequest(false);
              }}
            >
              Atrás
            </Button>
          </DialogActions>
        )}
        {openDialogCancelRequest && (
          <DialogCancelRequest
            openDialog={openDialogCancelRequest}
            closeDialog={() => setOpenDialogCancelRequest(false)}
            closeMainModal={closeModal}
          />
        )}
        {openDialogSendRequest && (
          <DialogSendRequest
            openDialog={openDialogSendRequest}
            closeDialog={() => setOpenDialogSendRequest(false)}
            closeMainModal={closeModal}
          />
        )}
      </Paper>
    </Modal>
  );
};

export default ModalExamToPerform;

ModalExamToPerform.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRow: PropTypes.object
};
