import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Paper,
    Grid,
    Typography,
    Backdrop,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {orange, red} from "@material-ui/core/colors";
import ModalDeleteScheduleTime from "components/ClinicalRequests/ModalDeleteScheduleTime";
import api from "utils/API";

import {dictMonth} from "utils/validationFunction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
}));

export default function ModalEditScheduleTime({
    open,
    handleClose,
    editRowDetails,
    handleClearState,
    setselectRowDetails,
}) {
    const classes = useStyles();
    const [initialCharge, setinitialCharge] = useState(true);
    const [startDate, setstartDate] = useState(moment(editRowDetails[0].fecha_hora).format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setendDate] = useState(moment(editRowDetails[0].fecha_final).format("YYYY-MM-DD HH:mm:ss"));
    const [personal, setpersonal] = useState([]);
    const [openDeleteModal, setopenDeleteModal] = useState(false);

    const dictTypeWork = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA);

    useEffect(() => {
        if (initialCharge){
            initialData();
        }
    });

    const initialData = () => {
        setinitialCharge(false);
        api.get(`/api/solicitudvisitainicial/tarea/${editRowDetails[0].id}/usuarios/`).then((response) => {
            setpersonal(response.data);
        })
    };

    const handleEditReservation = () => {
        setselectRowDetails(editRowDetails);
        handleClose();
    };

    const handleDelete = (value) => {
        if (value){
            api.delete(`/api/solicitudvisitainicial/tarea/${editRowDetails[0].id}/borrar/`).then((response) => {
                handleClearState(editRowDetails[0]);
                handleClose();
            });
        } else {
            handleClose();
        }
    };

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px'
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
            className={classes.paper}
            style={{
                maxHeight: "900px",
                overflow: "auto",
            }}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <h3 id="spring-modal-title">{`Se agendará la siguiente visita`}</h3>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{padding: "5px"}}
                    >
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Tipo de visita: ${dictTypeWork[editRowDetails[0].tipo_tarea]?.label}`}
                        </Typography>
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Paciente:  ${editRowDetails[0].id_ficha ?
                                `${editRowDetails[0].id_ficha.id_paciente.nombre} ${editRowDetails[0].id_ficha.id_paciente.apellido_paterno} ${editRowDetails[0].id_ficha.id_paciente.apellido_materno}` :
                                `No disponible`
                            }`}
                        </Typography>
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Profesionales que asisten:`}
                        </Typography>
                        {personal.map((row, index) => (
                            <Typography  style={{margin: "0 0 0 10px"}} key={`${index}-profesional_select`} variant="caption" display="block" gutterBottom>
                              {`${row.id_cargo.nombre_corto} - ${row.first_name} ${row.last_name}`}
                            </Typography>
                        ))}
                        { startDate &&
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Fecha: ${moment(startDate).format("DD")} de ${dictMonth[parseInt(moment(startDate).format("MM"))]}`}
                        </Typography>
                        }
                        { startDate &&
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Hora inicio: ${moment(startDate).format("HH:mm:ss")}`}
                        </Typography>
                        }
                        { endDate &&
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Hora de fin: ${moment(endDate).format("HH:mm:ss")}`}
                        </Typography>
                        }
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 0 0"}}
                >
                     <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        style={{backgroundColor: orange[800], marginRight: "10px"}}
                        onClick={handleEditReservation}
                    >
                        Editar
                    </Button>
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={handleDelete}
                    >
                        Eliminar
                    </Button>
                    <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        color="primary"
                        onClick={handleClose}
                    >
                        Volver
                    </Button>
                </Grid>
            </Grid>
            {openDeleteModal &&
                <ModalDeleteScheduleTime
                    open={openDeleteModal}
                    handleClose={() => setopenDeleteModal(false)}
                    handleDelete={handleDelete}
                />
            }
        </Paper>
      </Modal>
  );
}