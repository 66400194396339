import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import ModalMessage from "./ModalMessage";
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import deLocale from "date-fns/locale/es";
import moment from "moment";



export default function ModalEdit(props) {
  const { open, closeModal, id_producto, id_detalle, productos_restantes, id_order } = props;

  const [group, setGroup] = useState("");
  const [description, setDescription] = useState("");
  const [sku, setSku] = useState("");
  const [modalMessage, setModalMessage] = useState(false);

  const [observation, setObservation] = useState(props.observation);

  const [selectedDate, setSelectedDate] = useState(props.date);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleGroup = group => {
    setGroup(group);
  };

  const handleDescription = description => {
    setDescription(description);
  };

  const handleSKU = sku => {
    setSku(sku);
  };

  const getTipoCantidad = (id_supply, supplies) => {
    const resp = supplies
      .filter(supply => supply.id === id_supply)
      .map(filtered => {
        return filtered.tipo_cantidad;
      });
    return resp;
  };

  const getDescripcionProducto = (id_supply, supplies) => {
    const resp = supplies
      .filter(supply => supply.id === id_supply)
      .map(filtered => {
        return filtered.descripcion_producto;
      });
    return resp;
  };

  function updatePurchase() {

    const formData = new FormData();

    formData.append("tipo_solicitud_cotizar", props.type);
    formData.append("cantidad_productos", props.quantity);
    formData.append("fecha_solicitud", moment(selectedDate).format("YYYY-MM-DD"));
    formData.append("estado", props.state);
    formData.append("observacion", observation);

    apiform
      .put(`api/solicitudcotizacioncompras/${props.idRequest}`, formData)
      .then(response => {
        window.location.href = `/admin/purchases/inprocess/detail/${props.idRequest}`;
      })

  }

  const handleModal = (close, open) => {
    setModalMessage(open);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">
        Editar datos de la compra
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          <DetailTextArea
            label="Observaciones:"
            placeholder="Observaciones"
            value={observation}
            maxlength="500"
            onchange={e => {
              setObservation(e.target.value);
            }}
          />
          <div>
            <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Fecha de compra"
                  format="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  okLabel="Aceptar"
                  cancelLabel="Cancelar"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          color="primary"
          style={{ textTransform: "none" }}
          variant="contained"
        >
          Cancelar
        </Button>
        <Button
          onClick={updatePurchase}
          color="primary"
          style={{ textTransform: "none" }}
          variant="contained"
        >
          Guardar cambios
        </Button>
      </DialogActions>
      {modalMessage && (
        <ModalMessage
          open={modalMessage}
          closeModal={() => setModalMessage(false)}
        />
      )}
    </Dialog>
  );
}

ModalEdit.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number,
  productos_restantes: PropTypes.array
};
