import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  table: {
    minWidth: 650
  },
  container: {
    maxHeight: 440
  }
});

const TableRecords = props => {
  const classes = useStyles();
  const { filtersRecords } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const dictSigns = useSelector(state => state.currentList.DICCIONARIO_SIGNOS_VITALES);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      style={{ marginBottom: "6em" }}
    >
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          style={{ textTransform: "none" }}
        >
          Registros
        </Typography>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Fecha registro</TableCell>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell align="center">Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
               
                {filtersRecords
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row,index )=> {
                    return (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {moment(row.fecha_hora).format("LLLL")}
                        </TableCell>
                        <TableCell align="center">
                          {dictSigns[row.tipo_signos]?.label}
                        </TableCell>
                        <TableCell align="center">{row.medicion}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "Todas", value: -1 }]}
            colSpan={12}
            component="div"
            count={filtersRecords.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "Filas por pagina" },
              native: false
            }}
            labelRowsPerPage={"Filas por pagina"}
            nextIconButtonText={"Siguiente"}
            backIconButtonText={"Atras"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} de ${
                count !== -1 ? count : `mas que ${to}`
              }`
            }
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default TableRecords;

TableRecords.propTypes = {
  filtersRecords: PropTypes.array,
};
