import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const Success = props => {
  const {
    openDialog,
    closeDialog,
    message,
    setOpenModalAddRecord,
    setPageRefresh,
    setOpenModalViewRecord
  } = props;

  return (
    <Dialog
      onClose={() => {
        setPageRefresh(true);
        setOpenModalAddRecord(false);
        setOpenModalViewRecord(false);
        closeDialog();
      }}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      disableBackdropClick
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => {
          setPageRefresh(true);
          setOpenModalAddRecord(false);
          setOpenModalViewRecord(false);
          closeDialog();
        }}
      >
        
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setPageRefresh(true);
            setOpenModalAddRecord(false);
            setOpenModalViewRecord(false);
            closeDialog();
          }}
          style={{ textTransform: "none" }}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Success;

Success.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  message: PropTypes.string,
  setOpenModalAddRecord: PropTypes.func,
  setPageRefresh: PropTypes.func,
  setOpenModalViewRecord: PropTypes.func
};
