import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "../Widgets/FichaPersonal/viewTitle";
import ModalCreateCovenant from "components/Covenants/ModalCreateCovenant";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import TableCovenants from "./TableCovenants";
import SearchCovenants from "./SearchCovenants";


export default function Covenants() {
  const [modal_create_covenant, setModal_create_covenant] = useState(false);
  const [changes, setChanges] = useState([]);
  const [packets, setPackets] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);

    const getPackets = api.get("api/clinica/paquetizaciones/");

    Promise.all([getPackets])
      .then(response => {
        let tempPackets = [];
        response[0].data.map(row => {
          tempPackets.push({
            ...row,
            status: row.estado ? "Activo" : "Inactivo"
          });
        });
        setPackets(tempPackets);
      })

  };

  const searchCovenants = packets.filter(request => {
    return (
      request.id_convenio_isapre.nombre
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.nombre
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.fecha_creacion
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.status
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    );
  });

  return (
    <GridContainer>
      <ViewTitle
        title="CONVENIOS"
        message="En esta sección podrás revisar los convenios"
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Convenios</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <GridContainer justify="center">
            <Button
              style={{
                backgroundColor: "#1F8AFF",
                marginTop: "5em",
                marginBottom: "2em",
                textTransform: "none"
              }}
              onClick={() => setModal_create_covenant(true)}
            >
              Agregar convenio
            </Button>
          </GridContainer>
          <CardBody>
            <GridContainer justify="center" style={{ marginBottom: "3em" }}>
              <SearchCovenants search={search} setSearch={setSearch} />
            </GridContainer>
            <TableCovenants packets={searchCovenants} />
          </CardBody>
        </Card>
      </GridItem>
      {modal_create_covenant && (
        <ModalCreateCovenant
          open={modal_create_covenant}
          closeModal={() => setModal_create_covenant(false)}
        />
      )}
    </GridContainer>
  );
}
