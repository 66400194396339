import React from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ModalSaved(props) {
  const { open, closeModal, moduleType } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const scroll = "paper";

  function accept() {
    window.location.href = `/admin/warehouse/requests/${moduleType}`;
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">
        Éxito a guardar el registro
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form-row" style={{ marginBottom: "1rem" }}>
          Se guardó exitosamente el registro
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={accept}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalSaved.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  moduleType: PropTypes.string
};
