import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "utils/API";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function DialogDelete(props) {
  const {
    openDialog,
    closeDialog,
    orders,
    setOrders,
    tempIdRequest,
    tempIdRecord,
    tempIndex
  } = props;



  const handleClose = () => {
    closeDialog();
  };

  const deleteRecord = () => {


    const deleteRecord = api.post(
      `api/compras/solicitudcotizacion/productosagrupados/${tempIdRequest}/eliminar/${tempIdRecord}`
    );

    Promise.all([deleteRecord])
      .then(() => {
        let tempDelete = orders;
        tempDelete.splice(tempIndex, 1);
        setOrders(tempDelete);
        handleClose();

      })

  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        {openBackdrop ? (
          <DialogTitle id="customized-dialog-title">
            Eliminar registro
          </DialogTitle>
        ) : (
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Eliminar registro
          </DialogTitle>
        )}
        <DialogContent dividers>
          <Typography gutterBottom>
            ¿Estás seguro que deseas eliminar este registro?
          </Typography>
        </DialogContent>
        {openBackdrop ? (
          <DialogActions
            style={{
              marginTop: "1em",
              marginBottom: "1em",
              justifyContent: "center"
            }}
          >
            <CircularProgress />
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ textTransform: "none" }}
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={deleteRecord}
              color="secondary"
              style={{ textTransform: "none" }}
              variant="contained"
            >
              Eliminar
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

DialogDelete.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  orders: PropTypes.array,
  setOrders: PropTypes.func,
  tempIdRequest: PropTypes.number,
  tempIdRecord: PropTypes.number,
  tempIndex: PropTypes.number
};
