import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 230
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Inasistencia = props => {
  const classes = useStyles();
  const {
    startDate,
    handleStartDate,
    endDate,
    handleEndDate,
    totalDias,
    primerDiaTrabajado,
    handlePrimerDiaTrabajado,
    ultimoDiaTrabajado,
    handleUltimoDiaTrabajado,
    licencia,
    handleLicencia,
    amonestacion,
    handleAmonestacion,
    descuento,
    handleDescuento,
  } = props;

  return (
    <Grid container justify="flex-start" style={{ marginTop: "2em" }}>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Días inasistencia:
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none", fontWeight: "bold" }}
          >
            {totalDias === 0 ? 1 : totalDias}
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <Grid container>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD-MM-YYYY"
                margin="normal"
                id="date-picker-inline1"
                label="Fecha de inicio"
                value={startDate}
                onChange={handleStartDate}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <Grid container>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD-MM-YYYY"
                margin="normal"
                id="date-picker-inline2"
                label="Fecha de término"
                value={endDate}
                onChange={handleEndDate}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                minDate={startDate}
                minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <Grid container>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD-MM-YYYY"
                margin="normal"
                id="date-picker-inline3"
                label="Fecha último día trabajado"
                value={ultimoDiaTrabajado}
                onChange={handleUltimoDiaTrabajado}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                // minDate={startDate}
                // minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <Grid container>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD-MM-YYYY"
                margin="normal"
                id="date-picker-inline4"
                label="Fecha primer día trabajado"
                value={primerDiaTrabajado}
                onChange={handlePrimerDiaTrabajado}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                // minDate={startDate}
                // minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
        style={{ marginTop: "1em" }}
      >
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            error={licencia === "" ? true : false}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Licencia
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={licencia}
              onChange={handleLicencia}
              label="Licencia"
            >
              <MenuItem value={"si"}>Si</MenuItem>
              <MenuItem value={"no"}>No</MenuItem>
            </Select>
            <FormHelperText>
              {licencia === "" ? (
                <span className="text-danger">Debe ingresar licencia</span>
              ) : null}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
        style={{ marginTop: "1em" }}
      >
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            error={amonestacion === "" ? true : false}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Amonestación
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={amonestacion}
              onChange={handleAmonestacion}
              label="Amonestación"
            >
              <MenuItem value={"si"}>Si</MenuItem>
              <MenuItem value={"no"}>No</MenuItem>
            </Select>
            <FormHelperText>
              {amonestacion === "" ? (
                <span className="text-danger">Debe ingresar amonestación</span>
              ) : null}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
        style={{ marginTop: "1em" }}
      >
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            error={descuento === "" ? true : false}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Descuento
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={descuento}
              onChange={handleDescuento}
              label="Descuento"
            >
              <MenuItem value={"si"}>Si</MenuItem>
              <MenuItem value={"no"}>No</MenuItem>
            </Select>
            <FormHelperText>
              {descuento === "" ? (
                <span className="text-danger">Debe ingresar descuento</span>
              ) : null}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  );
};

export default Inasistencia;

Inasistencia.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.object,
  startDate: PropTypes.object,
  handleStartDate: PropTypes.func,
  endDate: PropTypes.object,
  handleEndDate: PropTypes.func,
  estadoVacaciones: PropTypes.string,
  handleEstadoVacaciones: PropTypes.func,
  totalDias: PropTypes.number,
  primerDiaTrabajado: PropTypes.object,
  handlePrimerDiaTrabajado: PropTypes.func,
  ultimoDiaTrabajado: PropTypes.object,
  handleUltimoDiaTrabajado: PropTypes.func,
  licencia: PropTypes.string,
  handleLicencia: PropTypes.func,
  amonestacion: PropTypes.string,
  handleAmonestacion: PropTypes.func,
  descuento: PropTypes.string,
  handleDescuento: PropTypes.func
};
