import { useState } from "react"

function useToogle() {
  const [isOpen, setIsOpen] = useState(false)

  const setToogle = (state) => {
    setIsOpen(prev => !prev)
  }

  return ([isOpen, setToogle])
}

export default useToogle
