import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {classStyle} from "../../utils/styleFunction";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
    red,
  } from "@material-ui/core/colors";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function ModalReverseBenefit({
    open,
    handleClose,
    handleReverse,
}) {
    const classesStyle = classStyle();

    const handleUpdate = () => {
        handleReverse();
        handleClose();
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesStyle.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper  className={classesStyle.paper}  style={{maxHeight: "85%", overflow: "auto"}}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Typography variant="h5" gutterBottom>{`Revertir todos los cambios`}</Typography>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0"}}
                    >
                        <Typography variant="body2" gutterBottom>¿Estás seguro que deseas revertir todos los cambios de la factura?</Typography>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 0 0"}}
                    >

                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            style={{backgroundColor: red[500]}}
                            onClick={handleUpdate}
                        >
                            Sí, revertir cambios
                        </Button>
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            color="primary"
                            onClick={handleClose}
                        >
                            No, volver atrás
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
}