import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import {blue} from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import {classStyle} from "utils/styleFunction";
import ButtonTemplate from "components/CustomButtons/Button.js";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Typography,
    TableCell,
    Tabs,
    Tab,
    Box,
    Radio,
    TextField,
    Checkbox,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select as SelectMUI,
    MenuItem,
    Grid
} from "@material-ui/core"
import {Autocomplete} from '@material-ui/lab/';
import {
  CheckCircle,
  RadioButtonUnchecked,
} from "@material-ui/icons/";


import clsx from 'clsx';
import {red, green, orange, indigo} from "@material-ui/core/colors";


export const ButtonTextMUI = styled(Button)`
    text-transform: none !important;
    outline: none !important;
`;

export const ButtonTextTemplate = styled(ButtonTemplate)`
    text-transform: none !important;
`;

export const TypographyMui = styled(Typography)`
    text-transform: none !important;
`;

const useStyles = makeStyles((theme) => ({
  MuiTextField: {
    marginTop: 0,
    width: "100%",
  },
  outlined: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[500],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[700],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

export const CustomTableCell = withStyles(theme => ({
    head: {
      color: indigo[500],
      textTransform: "none",
      fontSize: "16px",
    },
    body: {
      fontSize: 14,
      textTransform: "none",
    }
}))(TableCell);

export const CustomTab = withStyles(theme => ({
    root: {
      borderLeft: "2px solid #2196f3",
      borderRight: "2px solid #2196f3",
      color: blue[500],
      margin: "0 -1px 0 0",
      transition: "1s",
      "&:hover": {
        background: blue[50],
      }
    },
    selected: {
      background: blue[100],
    }
}))(Tab);

export const CustomTabs = withStyles(theme => ({
    indicator: {
      background: blue[100],
    }
}))(Tabs);

export const CustomTabPanel = props => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
        style={{width: "100%"}}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
}

export const Root = styled('div')((props) => ({
    width: '100%',
    ...props => props.theme.typography.body2,
    '& > :not(style) + :not(style)': {
        marginTop: props => props.theme.spacing(2),
    },
}));

export const CustomRadio = props => {
  const classes = classStyle();

  return (
      <Radio
          className={classes.rootRadio}
          disableRipple
          color="default"
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          {...props}
      />
  );
};

export const CustomDatePicker = props => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardDatePicker
        inputProps={{ readOnly: true, width: "100%" }}
        {...props}
        TextFieldComponent={TextField}
        classes={{ root: classes.MuiTextField }}
      />
    </MuiPickersUtilsProvider>
  );
}

export const CustomCheckBox = props => {
  const classes = useStyles();
  return (
  <FormControlLabel
    control={
        <Checkbox
            checked={props.value}
            tabIndex={-1}
            onClick={props.onClick}
            disabled={props.disabled}
            checkedIcon={
                <CheckCircle style={{fill: indigo[500]}} />
            }
            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
        />
    }
    classes={{
        label: classes.label,
        root: classes.labelRoot
    }}
    label={props.label}
    />
  )
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? theme.palette.primary.main : theme.palette.grey[500],
    boxShadow: 'none',
    '&:hover': {
      borderColor: state.isFocused ? theme.palette.primary.main : theme.palette.grey[700],
    },
  }),
};

export const CustomSelect2 = props => {
  const classes = useStyles();



  return (
      <Select
        disabled={props.disbled}
        className={classes.outlined}
        styles={customStyles}
        options={props.listItem}

        label={props.label}
        onChange={props.onChange}
        value={props.value}
        isSearchable
        isClearable
        placeholder={"Buscar opción..."}
      />
    );
}

export const CustomSelect = props => {
  return (
    <FormControl variant="outlined" style={{width: "100%"}}>
    <InputLabel htmlFor="outlined-age-native-simple">{props.label}</InputLabel>
    <SelectMUI
        disabled={props.disbled}
        value={props.value}
        onChange={props.onChange}
    >
        {props.listItem.map((row) => (
            <MenuItem  key={row.value} value={row.value}>{row.label}</MenuItem>
        ))}
    </SelectMUI>
  </FormControl>
  )
}


export const CustomSelectAutoComplete = props => {

  const {listItem} = props

  const [inputLabel, setinputLabel] = useState({})
  const [countUpdate, setcountUpdate] = useState(0);


  useEffect(() => {
    if (countUpdate === 0 & listItem.lenght > 0 & props?.value?.value){
      updataInitialValue()
      setcountUpdate(countUpdate+1)
    }
  }, [listItem])

  const updataInitialValue = () => {
    let temp_value = {}
    if (props?.value?.label & props?.value?.value){
      temp_value = props.value
    } else if (props?.value){
      temp_value = listItem.filter((row) => row.value == props?.value)[0]
    }
    if (temp_value === undefined){
      temp_value = {}
    }
    handleOnChange(temp_value)
  }

  const handleOnChange = (value) => {
    props.onChange(value)
    handleInputOnChange(value)
  }

  const handleInputOnChange = (value) => {
    if (value?.value){
      setinputLabel(value.label)
    } else {
      setinputLabel(value || "")
    }
  }

  const label = (props.value || props.disabled) ? props.label : `Seleccione ${props.label.toLocaleLowerCase() || "Item"}...`

  return (
    <Autocomplete
      value={props.value}
      onChange={(event, newValue) => handleOnChange(newValue)}
      disabled={props.disabled}
      options={listItem}
      getOptionLabel={(option) => option.label}
      inputValue={inputLabel}
      onInputChange={(event, newValue) => handleInputOnChange(newValue)}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params}   disabled={props.disabled} label={label} variant="outlined" />}
    />
  );
}

export const CustomCardItem = ({title, content, textAction, action, component=null, subComponent=null, xs=4, sm=4, md=4}) => <Grid item container xs={xs} sm={sm} md={md} 
style={{padding: "1em" }}>
<Grid item container xs={12}  direction="row" alignContent="flex-start" style={{ backgroundColor: "#fff", borderRadius: "5px", padding: "1em"}}>
    <Grid item container xs={12} style={{margin: "0 0 20px 0"}}>
        <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}>
        {title}
        </Typography>
    </Grid>
    <Grid item container xs={12}  direction="column" alignContent="flex-start" justify='center'>
        <Typography gutterBottom style={{ textTransform: "none", margin: "0 0 12px 0" }}>
            {content}
        </Typography>
        {subComponent}
        {component ?
          component : <Button variant="contained" color="primary" style={{ width: "313px", height: "36px"}} onClick={action}>{textAction}</Button>
        }
    </Grid>
</Grid>
</Grid>



