import React, {useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    IconButton,
} from '@material-ui/core';
import {
    Comment
} from '@material-ui/icons/';
import moment from "moment-timezone";
import ModalCommentDecompensation from "./ModalCommentDecompensation"
import "moment/locale/es";
moment.locale("es");

export default function HistoryComent({
   dictComments,
   requestID,
   setdictComments,
   arrayComments,
   setarrayComments,
   title,
   flagProps,
   stylePaper={}
}) {

    const [openComment, setopenComment] = useState (false);

    return (
        <div>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <Typography variant="h5" gutterBottom>
                    {title}
                </Typography>
            </Grid>
            {flagProps === "historial" &&
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                item
                xs={12}
                style={{margin: "-50px 0 0 0px"}}
            >
                <IconButton onClick={() => setopenComment(true)} aria-label="delete">
                    <Comment fontSize="inherit" />
                </IconButton>
            </Grid>
            }
            <Grid style={{overflow: "auto", maxHeight: "350px"}} item xs={12} >
            {Object.keys(dictComments).map((row, index) => (
            <Grid
                key={`${index}-comment`}
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                {Object.keys(dictComments[row]).map((userRow, userindex) => (
                    <Grid
                        key={`${index}-${userindex}-comment`}
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{padding: "10px"}}
                    >
                    <Typography variant="body2" gutterBottom>
                        {`Usuario: ${userRow} | Fecha: ${row}`}
                    </Typography>
                    {dictComments[row][userRow].map((subrow, indexsubrow) => (
                        <Grid
                            key={`${index}-${userindex}-${indexsubrow}-comment`}
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={12}
                        >
                            <Typography variant="body2" gutterBottom>
                                {subrow.comentario}
                            </Typography>
                        </Grid>
                    ))}
                    </Grid>
                ))}
            </Grid>
            ))}
            </Grid>
            {openComment &&
            <ModalCommentDecompensation
                open={openComment}
                handleClose={() => setopenComment(false)}
                requestID={requestID}
                setdictComments={setdictComments}
                arrayComments={arrayComments}
                setarrayComments={setarrayComments}
            />
            }
        </div>

    );
}