import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Fab from '@material-ui/core/Fab';
import DateFnsUtils from "@date-io/date-fns";
import ReactSelect, { components } from 'react-select';
import api from "utils/API";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import apiform from "utils/APIForm";
import moment from "moment-timezone";
import "moment/locale/es";
import { TramOutlined } from "@material-ui/icons";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  switch_track: {
    backgroundColor: "#808080",
  },
  switch_base: {
      color: "#fff",
      "&.Mui-disabled": {
          color: "#808080"
      },
      "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#808080",
      }
  },
  switch_primary: {
      "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#808080",
      },
  },
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const groupBadgeStyles = {
  borderRadius: "2em",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);
const Placeholder = props => {
  return <components.Placeholder {...props} />;
};
const listPeriod = [
  { value: "Horario", label: "Horario" },
  { value: "Diario", label: "Diario" },
  { value: "Semanal", label: "Semanal" },
  { value: "Mensual", label: "Mensual" },
  { value: "Anual", label: "Anual" },
]
const days = [
  { label: "L", value: 1, },
  { label: "M", value: 2, },
  { label: "M", value: 3, },
  { label: "J", value: 4, },
  { label: "V", value: 5, },
  { label: "S", value: 6, },
  { label: "D", value: 7, },
];
const periodDict = {
  Horario: "hours",
  Diario: "days",
  Semanal: "weeks",
  Mensual: "months",
  Anual: "years",
};
export default function ModalAddDevice(props) {
  const classes = useStyles();
  const {
    open,
    closeModal,
    getPatientSupplies,
    setChanges,
    listassigned,
    dataFile
  } = props;
  const [devices, setDevices] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [observation, setObservation] = useState("");
  const [openBackdropAddDevice, setOpenBackdropAddDevice] = useState(false);
  const [assignedPosition, setassignedPosition] = useState({});
  const [assignedPeople, setassignedPeople] = useState({});
  const [listPeople, setlistPeople] = useState([]);
  const [repeatable, setRepeatable] = useState(false);
  const [changeButtons, setchangeButtons] = useState(false);
  const [period, setPeriod] = useState({});
  const [hours, setHours] = useState('');
  const [selectDays, setSelectDays] = useState([ ]);

  const handlePeriod = (value) => {
    const temPeriod = value.value;
    const tempDateStart = moment( moment(startDate).format("YYYY-MM-DD HH:mm"),"YYYY-MM-DD HH:mm");
    setStartDate(tempDateStart);
    if (temPeriod === "Horario") {

      if(moment(startDate).diff(endDate,'hours')<1){

          setEndDate(
            moment(
              moment(tempDateStart)
                .add(1, "hours")
                .format("YYYY-MM-DD HH:mm"),
                "YYYY-MM-DD HH:mm"
              )
            );
          }

    } else if (temPeriod === "Diario") {

      if(moment(startDate).diff(endDate,'days')<1){

          setEndDate(
            moment(
              moment(tempDateStart)
                .add(1, "days")
                .format("YYYY-MM-DD HH:mm"),
                "YYYY-MM-DD HH:mm"
              )
            );
          }

    } else if (temPeriod === "Semanal") {

        if(moment(startDate).diff(endDate,'weeks')<1){
          setEndDate(
            moment(
              moment(tempDateStart)
                .add(1, "weeks")
                .format("YYYY-MM-DD HH:mm"),
                "YYYY-MM-DD HH:mm"
            )
          );
        }

    } else if (temPeriod === "Mensual") {

        if(moment(startDate).diff(endDate,'months')<1){
          setEndDate(
            moment(
              moment(tempDateStart)
                .add(1, "months")
                .format("YYYY-MM-DD HH:mm"),
                "YYYY-MM-DD HH:mm"
            )
          );

      }

    } else if (temPeriod === "Anual") {

        if(moment(startDate).diff(endDate,'years')<1){
          setEndDate(
            moment(
              moment(tempDateStart)
                .add(1, "years")
                .format("YYYY-MM-DD HH"),
              "YYYY-MM-DD HH"
            )
          );
        }

    }
    setPeriod(value);
  };

  const handleStartDate = time => {
    setStartDate(time);
  };
  const handleSelectDay = (button) => {
    let tempArray = selectDays;

    if (selectDays.find(item => item ===button)) {
      tempArray = selectDays.filter((row) => row !== button);
    } else {
      tempArray.push(button);
    }

    setSelectDays(tempArray);
    setchangeButtons(true);
  };
  const handleRepeatable = (value) => {
    setRepeatable(value);
  };

  const handleEndDate = time => {
    setEndDate(time);
  };

  const handleDevices = event => {
    setDevices(event.target.value);
  };

  const addDevice = () => {
    setOpenBackdropAddDevice(true);
    let frecuenciaCambio = '';
    if(period.value==='Horario'){
      frecuenciaCambio=hours;
    }else if (period.value==='Semanal') {
        frecuenciaCambio=selectDays.length;
    }else if (period.value==='Diario' || period.value==='Mensual' || period.value==='Anual') {
      frecuenciaCambio = 1;
    }else{
      frecuenciaCambio = 0;
    }

    const formData = new FormData();
    formData.append("estado", "Habilitado");
    formData.append("fecha_inicio", moment(startDate).format("YYYY-MM-DD HH:mm:ss"));
    formData.append("fecha_fin", moment(endDate).format("YYYY-MM-DD HH:mm:ss"));
    formData.append("frecuencia_cambio", frecuenciaCambio ? frecuenciaCambio:'');
    formData.append("frecuencia_tipo", period.value ? period.value:'');
    formData.append("observacion", observation);
    formData.append("id_prestaciones_paciente", devices);
    formData.append("id_cargo_asignado", assignedPosition.value,);
    formData.append("id_personal_asignado", assignedPeople.value);
    formData.append("repetible",repeatable);

    const formDataPrint = {
      estado:'Habilitado',
      fecha_inicio:moment(startDate).format("YYYY-MM-DD"),
      fecha_fin:moment(endDate).format("YYYY-MM-DD"),
      frecuencia_cambio:frecuenciaCambio,
      frecuencia_tipo:period.value,
      observacion:observation,
      id_prestaciones_paciente:devices,
    };

    const body = {
      tipo_tarea: 'AsignarDispositivos',
      fecha_hora: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      id_cargo_asignado: assignedPosition.value,
      id_usuarios: assignedPeople.value,
      descripcion: observation,
      estado: "Incompleto",
      id_ficha: dataFile.id,
    };
    const auxArray = [];
    if (repeatable) {
      const tempPeriod = periodDict[period.value];
      if (period.value === "Horario") {
        let dateCondition = moment(startDate, "DD-MM-YYYY HH:mm:ss");
        const dateEndIteration = moment(endDate, "DD-MM-YYYY HH:mm:ss");
        while ( dateEndIteration.diff( moment(dateCondition, "DD-MM-YYYY HH:mm:ss"),"minute") >= 0 ) {
            auxArray.push({
              tipo_tarea:'AsignarDispositivos',
              fecha_hora: moment(dateCondition).format("YYYY-MM-DD HH:mm:ss"),
              id_cargo_asignado: assignedPosition.value,
              id_usuarios: assignedPeople.value,
              descripcion: observation,
              estado: "Incompleto",
              id_ficha: dataFile.id,
            });
          dateCondition = dateCondition.add(hours, tempPeriod);
        }
        auxArray[auxArray.length - 1]['descripcion'] = observation + " Recordatorio: debe configurar nuevamente los cambios del dispositvo";
      } else if (period.value === "Semanal") {
        let dateCondition = moment(startDate, "DD-MM-YYYY HH:mm:ss");
        const dateEndIteration = moment(endDate, "DD-MM-YYYY HH:mm:ss");
        while ( dateEndIteration.diff(moment(dateCondition, "DD-MM-YYYY HH:mm:ss"),"days" ) >= 0 ) {
          if (selectDays.includes(dateCondition.day())) {
            auxArray.push({
              tipo_tarea:'AsignarDispositivos',
              fecha_hora: moment(dateCondition).format("YYYY-MM-DD HH:mm:ss"),
              id_cargo_asignado: assignedPosition.value,
              id_usuarios: assignedPeople.value,
              descripcion:observation,
              estado: "Incompleto",
              id_ficha: dataFile.id,
            });
          }
          dateCondition = dateCondition.add(1, "days");
        }
        auxArray[auxArray.length - 1]['descripcion'] = observation + " Recordatorio: debe configurar nuevamente los cambios del dispositvo";
      }else{
        const diff = moment(endDate, "DD-MM-YYYY HH:mm:ss").diff( moment(startDate, "DD-MM-YYYY HH:mm:ss"),  tempPeriod ) + 1;
        Array.from(Array(diff), (e, i) => {
          auxArray.push({
            tipo_tarea: 'AsignarDispositivos',
            fecha_hora: moment(startDate, "DD-MM-YYYY HH:mm:ss").add(i, tempPeriod).format("YYYY-MM-DD HH:mm:ss"),
            id_cargo_asignado: assignedPosition.value,
            id_usuarios: assignedPeople.value,
            descripcion: observation,
            estado: "Incompleto",
            id_ficha: dataFile.id,
          });

        });
        auxArray[auxArray.length - 1]['descripcion'] = observation + " Recordatorio: debe configurar nuevamente los cambios del dispositvo";

      }
    }

    if(auxArray.length===1){
      body['descripcion'] = observation+ " Recordatorio: debe configurar nuevamente los cambios del dispositvo";
    }
    api.post(`api/tareaspaciente/`, body).then((result) => {

          const tempResult = result.data;
          const bodyBulk = auxArray.map((row) => {
            const tempRow = row;
            tempRow.id_tarea_padre = tempResult.id;
            return tempRow;
          });
          formData.append("id_tarea_paciente", tempResult.id);
          apiform.post("api/dispositivospaciente/", formData)
            .then(response => {
              console.log("OK post api/dispositivospaciente/", response);
              setChanges(true);
              closeModal();
            })
            .catch(error => {
              console.log("ERROR post api/dispositivospaciente/", error.response);
          });
        if (bodyBulk.length>1){
          bodyBulk.shift()
          api.post(`api/paciente/tareas/bulk/`, bodyBulk).then((result) => { });
        }
    });




  };
  const handleAssignedPosition = (value) => {
    setassignedPeople({});
    setassignedPosition(value);
    api.get(`/api/usuario/cargo/${value.value}`).then((response) => {
      setlistPeople(
        response.data.map((row) => {
          return {
            value: row.id,
            label: `(${row.rut}) ${row.first_name} ${row.last_name}`,
          };
        })
      );
      setchangeButtons(true);
    });

  };
  const handleOnChange = (value, set) => {
    set(value);
  };
  React.useEffect(() => {
    if (changeButtons) {
      setchangeButtons(false);
    }
  });
  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
        disableBackdropClick
        scroll="paper"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          align="center"
        >
          Cambio de dispositivos
        </DialogTitle>
        <DialogContent >

            <Container maxWidth="lg" height="100%" spacing={2}>
              <Grid container direction="column" spacing={4} justify="center" alignItems="center">
                <Grid container item xs={10} spacing={2} direction="row" justify="center" alignItems="center">
                  <Grid item xs={10}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Dispositivos
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={devices}
                        onChange={handleDevices}
                        label="Dispositivos"
                      >
                        {getPatientSupplies.map(supply => (
                          <MenuItem key={supply.id} value={supply.id}>
                            {supply.id_equipo_id
                              ? supply.id_equipo_id.descripcion
                              : supply.id_insumo_medicamento_id
                                  .descripcion_producto}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                </Grid>
                <Grid item xs={10} container spacing={2} direction="row" justify="center" alignItems="center">
                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      {!changeButtons && <div></div>}
                      <ReactSelect
                        onChange={ handleAssignedPosition}
                        noOptionsMessage={() => 'No hay cargos disponibles'}
                        options={listassigned}
                        components={{ Placeholder }}
                        placeholder={"Seleccione un cargo"}
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          menu: provided => ({ ...provided, zIndex: "9999 !important" ,fontFamily: "Roboto",fontSize: 16}),
                          control: base => ({ ...base, height: '54px',minHeight: '54px',fontFamily: "Roboto",fontSize: 16}),
                          indicatorsContainer: provided => ({ ...provided,  height: '54px' }),
                          valueContainer: provided => ({ ...provided, height: '54px',  padding: '10px' }),
                          placeholder: base => ({...base,fontSize: '16',fontFamily: "Roboto",   }),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <ReactSelect
                        onChange={(value) => {
                          handleOnChange(value, setassignedPeople);
                        }}
                        options={listPeople}
                        formatGroupLabel={formatGroupLabel}
                        components={{ Placeholder }}
                        placeholder={'Seleccione personal'}
                        noOptionsMessage={() => 'No hay personal disponible'}
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          menu: provided => ({ ...provided, zIndex: "9999 !important" ,fontFamily: "Roboto",fontSize: 16}),
                          control: base => ({ ...base, height: '54px',minHeight: '54px',fontFamily: "Roboto",fontSize: 16}),
                          indicatorsContainer: provided => ({ ...provided,  height: '54px' }),
                          valueContainer: provided => ({ ...provided, height: '54px',  padding: '10px' }),
                          placeholder: base => ({...base, fontSize: '16',fontFamily: "Roboto",    }),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={10} container spacing={2} direction="row" justify="center" alignItems="flex-end">
                  <Grid item xs={3}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                          <KeyboardDateTimePicker
                            inputVariant="outlined"
                            format="dd/MM/yyyy HH:mm"
                            margin="normal"
                            id="date-picker-inline"
                            label="Fecha de inicio"
                            value={startDate}
                            onChange={handleStartDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>

                    <Grid item xs={3}>
                      <FormControl
                        fullWidth
                        className={classes.margin}
                        variant="outlined"
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around">
                            <KeyboardDateTimePicker
                              inputVariant="outlined"
                              format="dd/MM/yyyy HH:mm"
                              margin="normal"
                              id="date-picker-inline"
                              label="Fecha de fin"
                              value={endDate}
                              maxDate={moment().add(2,'years')}
                              onChange={handleEndDate}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </Grid>

                </Grid>
                <Grid item xs={8} container direction="row" justify="center" alignItems="center" spacing={2}>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          classes={{
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                            colorPrimary: classes.switch_primary,
                          }}
                          checked={repeatable}
                          onChange={(event) => handleRepeatable(event.target.checked)}
                          name="Repetible"
                          inputProps={{ "aria-label": "periodSelect" }}
                        />
                      }
                      label="Repetible"
                    />
                  </FormControl>
                  {repeatable ? (
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={4}>
                        <ReactSelect
                          onChange={(value) => {
                            handlePeriod(value);
                          }}
                          options={listPeriod}
                          formatGroupLabel={formatGroupLabel}
                          components={{ Placeholder }}
                          placeholder={'Seleccione Frecuencia'}
                          noOptionsMessage={() => 'No hay personal frecuencias disponibles'}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            menu: provided => ({ ...provided, zIndex: "9999 !important" ,fontFamily: "Roboto",fontSize: 16}),
                            control: base => ({ ...base, height: '54px',minHeight: '54px',fontFamily: "Roboto",fontSize: 16}),
                            indicatorsContainer: provided => ({ ...provided,  height: '54px' }),
                            valueContainer: provided => ({ ...provided, height: '54px',  padding: '10px' }),
                            placeholder: base => ({...base, fontSize: '16',fontFamily: "Roboto",    }),
                          }}
                        />
                      </Grid>
                      {period.value === "Horario" && (
                        <Grid item xs={5}>
                          <TextField
                            id="outlined-number2"
                            label="Tiempo de repetición (Horas)"
                            placeholder={`Horas`}
                            inputProps={{ type: "number", step: "1" }}
                            style={{width: '250px',}}
                            variant="outlined"
                            value={hours}
                            onChange={e => {
                              if (/^[0-9.\b]+$/.test(e.target.value)) {
                                setHours(parseFloat(e.target.value));
                              }
                            }}

                          />
                        </Grid>
                      )}
                      {period.value === "Semanal" && (
                        <Grid
                          item
                          xs={8}
                          container
                          spacing={6}
                          direction="row"
                          alignItems="center"
                          justify="flex-start"
                        >

                          {!changeButtons && days.map((row, index) => (
                            <Grid item xs={1} key={`${index}-daybuttons`}>
                              <Fab
                                size="small"
                                style={{backgroundColor:selectDays.find(item => item ===row.value) ? "#52d869":"#d5d5d5"}}
                                onClick={() => handleSelectDay(row.value)}
                              >
                                {row.label}
                              </Fab >
                            </Grid>
                          ))}
                          {selectDays.length === 0 && (
                            <Grid item xs={4} >
                              <Typography
                                variant="caption"
                                style={{ color: '#631'}}
                                gutterBottom
                              >
                                A lo menos 1 día seleccionado
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      </Grid>
                      ) : (
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-end"
                          item
                          xs={12}
                        >

                        </Grid>
                    )}
                </Grid>
                <Grid item xs={8} container direction="row" justify="center" alignItems="center" spacing={4}>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <TextField
                      id="outlined-multiline-static"
                      label="Observación"
                      multiline
                      rows={3}
                      value={observation}
                      onChange={e => setObservation(e.target.value)}
                      variant="outlined"
                      error={observation == ""}
                      helperText={
                        observation == "" ? "El campo es obligatorio" : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          {openBackdropAddDevice ? (
            <CircularProgress />
          ) : observation != "" ? (
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              onClick={addDevice}
            >
              Guardar cambios
            </Button>
          ) : (
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              disabled
            >
              Guardar cambios
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

ModalAddDevice.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  getPatientSupplies: PropTypes.array,
  setChanges: PropTypes.func,
};
