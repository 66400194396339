import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import ModalCreateCovenant from "components/Covenants/ModalCreateCovenant";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import ReceptionsInformation from "../../Outcome/Receptions/ReceptionsInformation";
import ModalTransport from "../../Outcome/Receptions/ModalTransport";
import TableInProcessTaskIncome from "./Tables/TableInProcessTaskIncome";
import TableWaitToWarehouseTaskIncome from "./Tables/TableWaitToWarehouseTaskIncome";



export default function OutcomeReceptions() {
  const [moduleType, setmoduleType] = useState("");
  const [changes, setChanges] = useState(true);


  const [requestIncome, setRequestIncome] = useState([]);
  const [requestIncomeWaiting, setRequestIncomeWaiting] = useState([]);
  const [requestIncomeFinalized, setRequestIncomeFinalized] = useState([]);


  const [loading, setLoading] = useState(true);
  const [modal_create_covenant, setModal_create_covenant] = useState(false);
  const [value, setValue] = useState(0);

  const [checkTab, setCheckTab] = useState(0);

  const [modalTransport, setModalTransport] = useState(false);
  const idTransport = 0;

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    if (value === 0) {
      setCheckTab(0);
    }
    if (value === 1) {
      setCheckTab(1);
    }
    if (value === 2) {
      setCheckTab(2);
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);
    const tempArrayURL = window.location.href.split("/");
    const tempModuleType = tempArrayURL[tempArrayURL.length - 1];
    setmoduleType(tempModuleType);
    const group = tempModuleType === "storage" ? "Insumos" : "Medicamentos";
    const requestIncome = api.get(
      `api/ingresos/solicitud/estados/grupo/${group}/estado/Activas/`
    );
    const requestIncomeFinalized = api.get(
      `api/ingresos/solicitud/estados/grupo/${group}/estado/Finalizado/`
    );

    Promise.all([
      requestIncome,
      requestIncomeFinalized
    ])
      .then(response => {
        let temp_request_income = response[0].data.filter((item) => item.estado_solicitud!== "EsperandoBodega" && item.estado_solicitud!== "EsperandoGuiaBodega" )
        setRequestIncome(temp_request_income);
        let temp_request_warehouse_income = response[0].data.filter((item) => item.estado_solicitud=== "EsperandoBodega" || item.estado_solicitud=== "EsperandoGuiaBodega" )
        setRequestIncomeWaiting(temp_request_warehouse_income);
        setRequestIncomeFinalized(response[1].data);

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  return (
    <GridContainer>
      <ViewTitle
        title="RECEPCIÓN DE PEDIDOS"
        message="En esta sección podrás revisar la recepción de los pedidos de bodega."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/income/tasks/${moduleType}`}>
              {`Tareas de ${
                moduleType === "storage" ? "Ingreso" : "Ingreso Farmacia"
              }`}
            </Link>
            <Typography color="textPrimary">Recepción de pedidos</Typography>
            {checkTab === 0 ? (
              <Typography color="textPrimary">En proceso</Typography>
            ) : checkTab === 1 ? (
              <Typography color="textPrimary">En espera de bodega</Typography>
            ) : (
              <Typography color="textPrimary">Finalizado</Typography>
            )}
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {!loading ? (
          <Card>
            <GridContainer justify="center" className="mt-3">
              <GridContainer justify="center">
                <ReceptionsInformation />
              </GridContainer>
            </GridContainer>
            <CardBody className="mb-1">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  centered
                >
                  <Tab
                    style={{ textTransform: "none" }}
                    label="En proceso"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Esperando a bodega"
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Finalizado"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <TableInProcessTaskIncome
                  requestIncome={requestIncome}
                  moduleType={moduleType}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableWaitToWarehouseTaskIncome
                  requestIncomeWaiting={requestIncomeWaiting}
                  moduleType={moduleType}
                />
              </TabPanel>

              <TabPanel value={value} index={2}>
                <TableWaitToWarehouseTaskIncome
                  requestIncomeWaiting={requestIncomeFinalized}
                  moduleType={moduleType}
                />
              </TabPanel>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modal_create_covenant && (
        <ModalCreateCovenant
          open={modal_create_covenant}
          closeModal={() => setModal_create_covenant(false)}
        />
      )}
      {modalTransport && (
        <ModalTransport
          open={modalTransport}
          closeModal={() => setModalTransport(false)}
          idTransport={idTransport}
        />
      )}
    </GridContainer>
  );
}
