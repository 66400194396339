import React from "react";
import NumberFormat from 'react-number-format';

const DetailMoney = props => {

  const writtenNumber = require('written-number');
  writtenNumber.defaults.lang = 'es';

  return(
    <div className="col-md-4 mb-3">
      <label className="text-dark">{props.label}</label>
      <NumberFormat 
        thousandSeparator={true} 
        prefix={'$'} 
        className="form-control bg-light font-weight-lighter"
        style={{color: "#000000"}}
        isNumericString="false" 
        value={props.value} 
        onValueChange={(values) => {
          const { floatValue } = values;
          props.money(floatValue);
          }
        }  
      />
      
    </div>
  );
}

export default DetailMoney;