import React from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import PurchaseRequest from "./PurchaseRequests";
import Providers from "./Providers";

const PurchasesTasks = () => {
  return (
    <GridContainer>
      <ViewTitle
        title="TAREAS DE COMPRAS"
        message="En esta sección podrás revisar las tareas de compras"
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Typography color="textPrimary">Tareas de compras</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <GridContainer justify="center" className="m-3 mt-5 mb-5">
            <PurchaseRequest />
            <Providers />
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default PurchasesTasks;
