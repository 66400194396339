import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "../Widgets/FichaPersonal/viewTitle";
import CardTitle from "../Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "../Widgets/FichaPersonal/loadingSpinner";
import Button from "@material-ui/core/Button";
import ModalEditBenefits from "components/Benefits/ModalEditBenefits";
import ModalCreateBenefit from "components/Benefits/ModalCreateBenefit";
import TableBenefits from "./TableBenefits";
import SearchBenefits from "./SearchBenefits";

export default function Benefits() {
  const [benefits, setBenefits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal_edit_benefits, setModal_edit_benefits] = useState(false);
  const [id_benefit, setId_benefit] = useState("");
  const [modal_create_benefit, setModal_create_benefit] = useState(false);
  const [groupBenefit, setGroupBenefit] = useState("");
  const [nameBenefit, setNameBenefit] = useState("");
  const [unitTypeBenefit, setUnitTypeBenefit] = useState("");
  const [charge, setCharge] = useState("");
  const [code, setCode] = useState("");
  const [firstCharge, setFirstCharge] = useState(true);
  const [search, setSearch] = useState("");

  const setgrupo = (grupo, lists) => {
    const resp = lists
      .filter(list => list[0] === grupo)
      .map(filtered => filtered[1]);
    return resp;
  };

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);

    const getPrestaciones = api.get("api/prestacionesclinica/");

    Promise.all([getPrestaciones])
      .then(response => {
        let tempPrestaciones = [];
        response[0].data.map(row => {
          tempPrestaciones.push({
            id: row.id,
            idCargo: row.id_cargo,
            codigo: row.codigo_prestacion,
            grupo: setgrupo(row.grupo, response[0].data),
            grupoOriginal: row.grupo,
            nombre: row.nombre,
            unidad: row.tipo_cantidad
          });
        });
        setBenefits(tempPrestaciones);
        setLoading(true);
      })

  };

  const setmodal = (id_benefit, modal_edit, group, name, unit, id_cargo, modal_code) => {
    setId_benefit(id_benefit);
    setModal_edit_benefits(modal_edit);
    setGroupBenefit(group);
    setNameBenefit(name);
    setUnitTypeBenefit(unit);
    setCharge(id_cargo);
    setCode(modal_code)
  };

  const searchBenefits = benefits.filter(request => {
    return (
      request.grupo
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.nombre
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.unidad
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    );
  });

  return (
    <GridContainer>
      <ViewTitle
        title="Prestaciones"
        message="En esta sección podrás revisar las prestaciones."
      />
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center">
              <Button
                style={{
                  backgroundColor: "#1F8AFF",
                  color: "white",
                  marginTop: "5em",
                  marginBottom: "2em",
                  textTransform: "none"
                }}
                onClick={() => setModal_create_benefit(true)}
              >
                Agregar prestación
              </Button>
            </GridContainer>
            <GridContainer justify="center">
              <SearchBenefits search={search} setSearch={setSearch} />
            </GridContainer>
            <CardBody>
              <TableBenefits benefits={searchBenefits} setmodal={setmodal} />
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modal_edit_benefits && (
        <ModalEditBenefits
          open={modal_edit_benefits}
          closeModal={() => setModal_edit_benefits(false)}
          id_benefit={id_benefit}
          groupBenefit={groupBenefit}
          nameBenefit={nameBenefit}
          unitTypeBenefit={unitTypeBenefit}
          charge={charge}
          code={code}
        />
      )}
      {modal_create_benefit && (
        <ModalCreateBenefit
          open={modal_create_benefit}
          closeModal={() => setModal_create_benefit(false)}
        />
      )}
    </GridContainer>
  );
}
