import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailText from "views/Widgets/FichaPersonal/EditFichaPersonal/detailText";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";

export default function ModalEditBenefits(props) {
  const { open, closeModal, id_benefit } = props;

  const [grupo, setGrupo] = useState(props.groupBenefit);
  const [nombre, setNombre] = useState(props.nameBenefit);
  const [tipo_cantidad, setTipo_cantidad] = useState(props.unitTypeBenefit);
  const [cargo, setCargo] = useState(props.charge);
  const [code, setCode] = useState(props.code);

  const [list_cargos, setlistcargos] = useState([]);

  const list_cantidades = useSelector(state => state.currentList.LISTA_TIPO_CANTIDAD_PRESTACION);
  const dict_cantidades = useSelector(state => state.currentList.DICCIONARIO_TIPO_CANTIDAD_PRESTACION);
  const list_grupos = useSelector(state => state.currentList.LISTA_GRUPOS_PRESTACION);
  const dict_grupos = useSelector(state => state.currentList.DICCIONARIO_GRUPOS_PRESTACION);

  useEffect(() => {
    setGrupo(dict_grupos[props.groupBenefit] || {})
    setTipo_cantidad(dict_cantidades[props.unitTypeBenefit] || {})

    const requestListCharges = api.get("api/cargos/?profecionales_visita=True");

    Promise.all([
      requestListCharges,
    ]).then((request) => {
      const tempListCharge = [];
      request[0].data.map(charges => {
        if (cargo === charges.id){
          setCargo({value: charges.id, label: charges.nombre_corto})
        }
        tempListCharge.push({value: charges.id, label: charges.nombre_corto})
      });
      setlistcargos(tempListCharge);
    });
  }, []);

  const scroll = "paper";


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function updateBenefit() {
    const formData = new FormData();

    if (grupo) {
      formData.append("grupo", grupo.value);
    }

    if (tipo_cantidad) {
      formData.append("tipo_cantidad", tipo_cantidad.value);
    }

    formData.append("nombre", nombre);
    formData.append("codigo_prestacion", code);

    if (cargo){
      formData.append("id_cargo", cargo.value);
    }
    apiform.patch(`api/prestacionesclinica/${id_benefit}`, formData)
      .then(response => {
        window.location.href = "/admin/benefits/";
      })

  }

  const handleGrupo = grupo => {
    setGrupo(grupo);
  };

  const handleTipoCantidad = tipo_cantidad => {
    setTipo_cantidad(tipo_cantidad);
    if (["Turno12", "Turno24"].includes(tipo_cantidad.value) && grupo.value === "Profesionales"){
      setNombre(`${cargo.label} ${tipo_cantidad.label}`);
    }
  };

  const handleCargo = cargo => {
    setCargo(cargo);
    if (["Turno12", "Turno24"].includes(tipo_cantidad.value)){
      setNombre(`${cargo.label} ${tipo_cantidad.label}`);
    } else {
      setNombre(cargo.label);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullWidth={true}
      PaperProps={{
        style: {
          height: "100%",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">Editar prestación </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form-row" style={{ marginBottom: "10rem" }}>
          <DetailSelect
            label="Grupo:"
            value={grupo}
            onchange={handleGrupo}
            list={list_grupos}
          />
          {grupo.value === "Profesionales" && (
            <DetailSelect
              label="Cargo asociado:"
              value={cargo}
              onchange={handleCargo}
              list={list_cargos}
            />
          )}
          {grupo.value === "Profesionales" ? (
            <DetailTextDisabled
              label="Nombre descriptivo de prestación:"
              placeholder="Nombre descriptivo de prestación"
              value={nombre}
            />
          ) : (
            <DetailText
              label="Nombre descriptivo de prestación:"
              placeholder="Nombre descriptivo de prestación"
              value={nombre}
              onchange={e => {
                setNombre(e.target.value);
              }}
            />
          )}
          <DetailText
            label="Codigo de prestación:"
            placeholder="Codigo de prestación"
            value={code}
            onchange={e => {
              setCode(e.target.value);
            }}
          />
          <DetailSelect
            label="Tipo cantidad:"
            value={tipo_cantidad}
            onchange={handleTipoCantidad}
            list={list_cantidades}
          />
          {/*
          <DetailMoneyOnly
            label="KTR (INHÁBIL):"
            value={kine_resp_inhabil}
            money={setKine_resp_inhabil}
          />
          <DetailMoneyOnly
            label="KTM:"
            value={kine_motor}
            money={setKine_motor}
          />
          <DetailMoneyOnly
            label="FONO:"
            value={fonoaudiologo}
            money={setFonoaudiologo}
          />
          <DetailMoneyOnly
            label="TO:"
            value={terapia_ocupacional}
            money={setTerapia_ocupacional}
          />
          <DetailMoneyOnly
            label="EU (HÁBIL):"
            value={enf_urgencias_habil}
            money={setEnf_urgencias_habil}
          />
          <DetailMoneyOnly
            label="EU (INHÁBIL):"
            value={enf_urgencias_inhabil}
            money={setEnf_urgencias_inhabil}
          />
          <DetailMoneyOnly
            label="MÉDICO PEDIATRA (HÁBIL):"
            value={pediatra_habil}
            money={setPediatra_habil}
          />
          <DetailMoneyOnly
            label="MÉDICO PEDIATRA (INHÁBIL):"
            value={pediatra_inhabil}
            money={setPediatra_inhabil}
          />
          <DetailMoneyOnly
            label="MÉDICO INTERNISTA ADULTO (HÁBIL):"
            value={internista_adulto_habil}
            money={setinternista_adulto_habil}
          />
          <DetailMoneyOnly
            label="MÉDICO INTERNISTA ADULTO (INHÁBIL):"
            value={internista_adulto_inhabil}
            money={setInternista_adulto_inhabil}
          /> */}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Cancelar
        </Button>
        <Button
          onClick={updateBenefit}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Editar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalEditBenefits.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_therapy: PropTypes.number,
  id_paquetizacion: PropTypes.number
};
