import React, {useState } from 'react';
import {
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Grid,
} from '@material-ui/core';

import {
    ExpandMore,

} from "@material-ui/icons/";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function ExpansionPanelCommon(props) {

    const [expandedPanel, setexpandedPanel] = useState(false);

    const handleChange = (value) => {
        setexpandedPanel(value);
        if (props.handleChange) {
            props.handleChange(value);
        }
    }

    return (
    <ExpansionPanel
        square
        expanded={expandedPanel}
        onChange={() => handleChange(!expandedPanel)}
    >
        <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            className="titleExpandPanel"
        >
            <Typography  className="titleTextExpandPanel" variant="body1" gutterBottom>{props.title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                {props.content}
           </Grid>
        </ExpansionPanelDetails>
    </ExpansionPanel>
    );
}