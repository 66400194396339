import React from "react";

const DetailTextArea = props => {
  return(
    <div className="col-md-12 mb-3">
      <label className="text-dark">{props.name}</label>
      <textarea
        className="form-control bg-light font-weight-lighter"
        style={{color: "#000000"}}
        value={props.field}
        disabled
      />
    </div>
  );
}

export default DetailTextArea;