import React from "react";
import PropTypes from "prop-types";

const Orders = props => {
  const { id, tipo, cantidad, observacion, fechaLlegada, estado } = props;

  return (
    <div className="col-xl-8 mb-3">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col text-center">Datos del pedido</div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm text-left ml-5">
              <span className="font-weight-bold">Id: </span>
              {id}
              <br />
              <span className="font-weight-bold">Tipo: </span>
              {tipo}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Cantidad SKU: </span>
              {cantidad}
              <br />
              <span className="font-weight-bold">Observación: </span>
              {observacion}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Fecha de llegada: </span>
              {fechaLlegada}
              <br />
              <span className="font-weight-bold">Estado: </span>
              {estado}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;

Orders.propTypes = {
  id: PropTypes.number,
  tipo: PropTypes.string,
  cantidad: PropTypes.number,
  observacion: PropTypes.string,
  fechaLlegada: PropTypes.string,
  estado: PropTypes.string
};
