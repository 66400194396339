import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import ModalAdd from "./ModalAdd";

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  table: {
    minWidth: 650
  }
});

const MedicalTableModify = props => {
  const classes = useStyles();
  const {
    dataMedicalArray,
    setDataMedicalArray,
    setPageRefresh,
    addDataMedicalArray,
    setAddDataMedicalArray,
    deleteDataMedicalArray,
    setDeleteDataMedicalArray,
    editDataMedicalArray,
    setEditDataMedicalArray
  } = props;
  const [openModal, setOpenModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const listMedical = useSelector(state => state.currentList.listMedicines);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    if (loading2) {
      setLoading2(false);
    }
  });

  const handleDeleteRow = (row, index, table) => {
    setLoading2(table);
    let tempDeleteArray = dataMedicalArray;
    let tempEditedArray = editDataMedicalArray;
    let tempAuxDeleteArray = deleteDataMedicalArray;
    const check = tempEditedArray.filter(array => array.id == row.id);
    const addRow = tempDeleteArray.filter(array => array.id == row.id);
    tempAuxDeleteArray.push(addRow[0]);

    setDataMedicalArray(tempDeleteArray.filter(array => array.id != row.id));
    setDeleteDataMedicalArray(tempAuxDeleteArray);
    if (check.length > 0) {
      setEditDataMedicalArray(
        tempEditedArray.filter(array => array.id != row.id)
      );
    }
    setPageRefresh(true);
  };

  const handleDeleteRowNew = (row, index, table) => {
    setLoading2(table);
    let tempDeleteArray = addDataMedicalArray;
    setAddDataMedicalArray(tempDeleteArray.filter(array => array.id != row.id));
    setPageRefresh(true);
  };

  const handleChangeQuantity = (value, index, table) => {
    setLoading(table);
    const auxDataArray = dataMedicalArray;
    auxDataArray[index].cantidad = parseInt(value);
    const tempAuxDataArray = editDataMedicalArray;
    let check = tempAuxDataArray.filter(
      row => row.id == auxDataArray[index].id
    );
    if (check.length > 0) {
      tempAuxDataArray[index].cantidad = parseInt(value);
    } else {
      tempAuxDataArray.push(auxDataArray[index]);
    }
    setEditDataMedicalArray(tempAuxDataArray);
    setDataMedicalArray(auxDataArray);
    setPageRefresh(true);
  };

  const handleChangeQuantityNew = (value, index, table) => {
    setLoading(table);
    const auxDataArray = addDataMedicalArray;
    auxDataArray[index].cantidad = parseInt(value);
    setAddDataMedicalArray(auxDataArray);
    setPageRefresh(true);
  };

  const handleAddItem = value => {
    let auxAddArray = addDataMedicalArray;
    const tempData = medicalResourceData[value.value];
    const element = {
      item: tempData?.descripcion_producto,
      sku: tempData?.SKU,
      id: tempData?.id,
      cantidad: 1
    };
    auxAddArray.push(element);
    setAddDataMedicalArray(auxAddArray);
    setPageRefresh(true);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          item
          xs={12}
          style={{
            margin: "30px 0 0 0",
            maxHeight: "300px",
            overflow: "auto"
          }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: "200px" }}>
                    Item
                  </TableCell>
                  <TableCell align="center" style={{ width: "200px" }}>
                    SKU
                  </TableCell>
                  <TableCell align="center" style={{ width: "200px" }}>
                    Cantidad
                  </TableCell>
                  <TableCell align="center" style={{ width: "200px" }}>
                    Acción
                  </TableCell>
                </TableRow>
              </TableHead>
              {!loading2 && (
                <TableBody>
                  {dataMedicalArray.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.item}
                      </TableCell>
                      <TableCell align="center">{row.sku}</TableCell>
                      <TableCell align="right">
                        <DetailNumber
                          value={row.cantidad}
                          onchange={e => {
                            if (/^[0-9\b]+$/.test(e.target.value)) {
                              handleChangeQuantity(e.target.value, index, true);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            background: red[500],
                            textTransform: "none"
                          }}
                          onClick={() => handleDeleteRow(row, index, true)}
                        >
                          Eliminar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {addDataMedicalArray.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.item}
                      </TableCell>
                      <TableCell align="center">{row.sku}</TableCell>
                      <TableCell align="right">
                        <DetailNumber
                          value={row.cantidad}
                          onchange={e => {
                            if (/^[0-9\b]+$/.test(e.target.value)) {
                              handleChangeQuantityNew(
                                e.target.value,
                                index,
                                true
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            background: red[500],
                            textTransform: "none"
                          }}
                          onClick={() => handleDeleteRowNew(row, index, true)}
                        >
                          Eliminar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setOpenModal(false);
                        }}
                        style={{ textTransform: "none" }}
                      >
                        Agregar Medicamento
                      </Button>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {!loading && <TableRow></TableRow>}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
      {openModal !== null && (
        <ModalAdd
          open={openModal !== null}
          table={openModal}
          handleClose={() => setOpenModal(null)}
          listItem={listMedical}
          dataArray={dataMedicalArray}
          setdataArray={setDataMedicalArray}
          handleAddItem={handleAddItem}
        />
      )}
    </Card>
  );
};

export default MedicalTableModify;

MedicalTableModify.propTypes = {
  dataMedicalArray: PropTypes.array,
  setDataMedicalArray: PropTypes.func,
  setPageRefresh: PropTypes.func,
  checkDataMedicalArray: PropTypes.array,
  setCheckDataMedicalArray: PropTypes.func,
  addDataMedicalArray: PropTypes.array,
  setAddDataMedicalArray: PropTypes.func,
  deleteDataMedicalArray: PropTypes.array,
  setDeleteDataMedicalArray: PropTypes.func,
  editDataMedicalArray: PropTypes.array,
  setEditDataMedicalArray: PropTypes.func
};
