import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import api from "utils/API";
import { checkRut, validateEmail, validateCellPhone, validatePhone } from "../../utils/validationFunction";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import CardDetailInfo from "views/Widgets/Common/CardDetailInfo";
import TextField from "views/Widgets/Common/TextField";
import {setmessageGeneral } from "actions/getGlobalAction";
import allActions from "actions";

export default function ModalnewPatient({
    open,
    handleClose,
    setselectPatient,
}) {
    const dispatch = useDispatch();

    const [nombre, setnombre] = useState("");
    const [apellido_paterno, setapellido_paterno] = useState("");
    const [apellido_materno, setapellido_materno] = useState("");
    const [rut, setrut] = useState("");
    const [rutError, setrutError] = useState("Campo obligatorio");
    const [nombres_apoderado, setnombres_apoderado] = useState("");
    const [apellido_paterno_apoderado, setapellido_paterno_apoderado] = useState("");
    const [apellido_materno_apoderado, setapellido_materno_apoderado] = useState("");
    const [rut_apoderado, setrut_apoderado] = useState("");
    const [rutApoderadoError, setrutApoderadoError] = useState("");
    const [email, setemail] = useState("");
    const [emailError, setemailError] = useState("");
    const [celular, setcelular] = useState("");
    const [celularError, setcelularError] = useState("");
    const [otro_telefono, setotro_telefono] = useState("");
    const [validation, setvalidation] = useState(false);

    useEffect(() => {
        validateForm();
    });


    const validateForm = () => {
        const arrayValidation = [
            nombre !== "",
            apellido_paterno !== "",
            apellido_materno !== "",
            rut != "",
            rutError === "",
            rutApoderadoError === "",

        ];
        if (arrayValidation.every((item) => item === true)) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    };

    const handleOnChange = (value, set) => {
        set(value);
    };

    const handleOnChangeRut = (value, set, setError, isVoid=false) => {
        if (isVoid && value === "" ) {
            setError("");
        } else {
            setError(checkRut(value));
        }
        set(value);
    };

    const handleOnChangeEmail = (value, set, isVoid=false) => {
        if (isVoid && value === "") {
            setemailError("");
        } else {
            setemailError(validateEmail(value));
        }
        set(value);
    };

    const handleOnChangeCellPhone = (value, set, isVoid=false) => {
        if (isVoid && value === "") {
            setcelularError("");
        } else {
            setcelularError(validateCellPhone(value));
        }
        set(value);
    };

    const handleOnChangePhone = (value, set) => {
        set(value);
    };

    const addNewPatient = () => {
        const bodyPatient = {
            nombre: nombre,
            apellido_paterno: apellido_paterno,
            apellido_materno: apellido_materno,
            rut: rut,
        };
        let bodyTutor = {
            nombres: nombres_apoderado,
            apellido_paterno: apellido_paterno_apoderado,
            apellido_materno: apellido_materno_apoderado,
            rut: rut_apoderado,
            email: email,
            celular: celular ? "+"+celular : "",
            otro_telefono: otro_telefono ? "+"+otro_telefono : otro_telefono,
            id_paciente: null,
        };

        api.post(`/api/paciente/`, bodyPatient).then((response) => {
            const patientData = response.data
            bodyTutor.id_paciente = patientData.id;
            const bodyFilePatient = {
                centro_hospitalario_referencia: "Sin asignar",
                diagnostico_ingreso: "Sin asignar",
                estado_ficha: "Prospecto",
                id_paciente: patientData.id
            };
            const requestFilePatient = api.post(`api/fichapaciente/`, bodyFilePatient);
            const requestTutor =  api.post(`/api/tutor/`, bodyTutor)
            Promise.all([
                requestFilePatient,
                requestTutor
            ]).then((request) => {
                handleClose();
                setmessageGeneral(dispatch, "Paciente creado", "success", "Exito!");
            })
        }).catch((error) => {
            if(error?.response?.data?.error) {
                setmessageGeneral(dispatch, error?.response?.data?.error, "error", "¡Atencion!");
            }
        });
    };

    const dataPatient = [
        {label: "Nombre", value: nombre, helperText: nombre ? "" : "Campo obligatorio", error: nombre === "",   onChange:(value) => {handleOnChange(value, setnombre)} },
        {label: "Apellido Paterno", value: apellido_paterno, helperText: apellido_paterno ? "" : "Campo obligatorio", error: apellido_paterno === "",   onChange:(value) => {handleOnChange(value, setapellido_paterno)} },
        {label: "Apellido Materno", value: apellido_materno, helperText: apellido_materno ? "" : "Campo obligatorio", error: apellido_materno === "",   onChange:(value) => {handleOnChange(value, setapellido_materno)} },
        {label: "Rut", value: rut, helperText: rutError, error: rutError !== "", placeholder:`Ej: 012.345.678-9`, onChange:(value) => {handleOnChangeRut(value, setrut, setrutError)} },
    ]

    const dataTutor = [
        {label: "Nombres", value: nombres_apoderado, onChange:(value) => {handleOnChange(value, setnombres_apoderado)} },
        {label: "Apellido Paterno", value: apellido_paterno_apoderado, onChange:(value) => {handleOnChange(value, setapellido_paterno_apoderado)} },
        {label: "Apellido Materno", value: apellido_materno_apoderado, onChange:(value) => {handleOnChange(value, setapellido_materno_apoderado)} },
        {label: "Rut", value: rut_apoderado, helperText: rutApoderadoError, error: rutApoderadoError !== "", placeholder:`Ej: 012.345.678-9`, onChange:(value) => { handleOnChangeRut(value, setrut_apoderado, setrutApoderadoError, true)} },
        {label: "Email", value: email, helperText: emailError, error: emailError !== "", onChange:(value) => {handleOnChangeEmail(value, setemail, true)} },
        {label: "Celular", value: celular, helperText: celularError, error: celularError !== "", onlyNumber: true, placeholder:`Ej: 56912345678`, adornment: {label: "+"}, onChange: (value) => { handleOnChangeCellPhone(value, setcelular, true)} },
        {label: "Telefono", value: otro_telefono, onlyNumber: true, onChange: (value) => { handleOnChangePhone(value, setotro_telefono)} },
    ]

    const _contentPatient_ = <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        item
        xs={12}
    >
        {dataPatient.map((item, index) => (
        <Grid
            item
            xs={3}
            key={`patient-${index}`}
            style={{margin: "1rem 0 0 0" }}
        >
            <TextField
                {...item}
                variant="outlined"
            />
        </Grid>
        ))}
    </Grid>

    const _contentTutor_ = <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        item
        xs={12}
    >
        {dataTutor.map((item, index) => (
        <Grid
            item
            xs={3}
            key={`tutor-${index}`}
            style={{margin: "1rem 0 0 0" }}
        >
            <TextField
                {...item}
                variant="outlined"
            />
        </Grid>
        ))}
    </Grid>

    const _content_ = <div>
        <CardDetailInfo
            styleCard={{margin: "2rem 0 0 0" }}
            title="Datos Paciente"
            contentCard={_contentPatient_}
        />
        <CardDetailInfo
            styleCard={{margin: "2rem 0 0 0" }}
            title="Datos Tutor"
            contentCard={_contentTutor_}
        />
    </div>

    const _actions_ = <>
        <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
        >
            Cancelar
        </Button>
        { validation ?
            <Button
                color="primary"
                variant="contained"
                onClick={addNewPatient}
            >
                Guardar
            </Button>
            :
            <Button
                disabled
                color="primary"
                variant="contained"
            >
                Guardar
            </Button>
            }
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={"Nuevo Paciente"}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth={'lg'}
        />
    );
}