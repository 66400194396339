import React from "react";
import "../../../../styles/Styles.css";
import api from "../../../../utils/API";
const DetailFile = props => {

  const EliminarDocumento = () =>{
    console.log("id documento", props.id)
    props.setLoading(true)
    api.delete(
      `api/documentospersonal/${props.id}`,
    )
    .then((resp) => {
      props.setLoading(false)
      props.setUpdateView(true);
    })

  }

  return(
    <div className="col-md-4 mb-3">
      <div className="card">
        <div className="card-body">
          <span className="card-title">{props.name}</span>
          <br />
          {props.link ?
            <button onClick={() => window.open(`${props.link}`, "_blank")}  className="btn btn-cmh-color">Ver Documento</button>
            :
            <button className="btn-cmh-color btn" disabled>Sin Documento</button>
          }
          <button className="btn" style={{backgroundColor: "#f44336",color:"white"}} onClick={EliminarDocumento}>Eliminar Documento</button>
        </div>
      </div>
    </div>
  );
}

export default DetailFile;