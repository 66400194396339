import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableIncomes = props => {
  const { getFilterOne, totalFilterOne, balanceTypes } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} container>
      <Typography
        variant="h6"
        gutterBottom
        align="center"
        style={{ textTransform: "none" }}
      >
        {getFilterOne.length > 0 ? getFilterOne[0].tipo_io : <div></div>}
      </Typography>
      <Paper className={classes.root}>
        <TableContainer
          className={classes.container}
          style={{ minHeight: 270, maxHeight: 270 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Fecha registro</TableCell>
                <TableCell align="center">Tipo</TableCell>
                <TableCell align="center">ml</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getFilterOne.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell align="center">
                      {moment(row.fecha_registro).format("LLLL")}
                    </TableCell>
                    <TableCell align="center">
                    {
                        balanceTypes[row.id_tipo_movimiento]?.nombre
                      }
                      {balanceTypes[row.id_tipo_movimiento]?.id}
                      {row.id_tipo_movimiento}
                    </TableCell>
                    <TableCell align="center">{row.cantidad}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid item xs={8} />
      <Grid item xs={4} container justify="flex-end">
        <Grid
          item
          xs={12}
          container
          justify="center"
          style={{ marginTop: "2em" }}
        >
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    Total {getFilterOne[0]?.tipo_io}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={1}>
                  <TableCell align="center">{totalFilterOne.toFixed(1)} ml</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableIncomes;

TableIncomes.propTypes = {
  getFilterOne: PropTypes.array,
  totalFilterOne: PropTypes.number,
  balanceTypes: PropTypes.array
};
