import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import apiform from "utils/APIForm";
import DialogCancelRequest from "./DialogCancelRequest";
import DialogSuccess from "./DialogSuccess";
// import TableSummary from "./TableSummary";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalUploadExamRequest = props => {
  const classes = useStyles();
  const { openModal, closeModal, tempRow } = props;

  const [openAnimation, setOpenAnimation] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [fileRequestExam, setFileRequestExam] = useState(null);
  const [openDialogCancelRequest, setOpenDialogCancelRequest] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);

  };

  const saveIsapreAuthorization = () => {
    setOpenAnimation(true);
    const formData = new FormData();
    formData.append("archivo", fileRequestExam[0]);
    formData.append("tipo_documento", "OrdenExamenes");
    formData.append("nombre_archivo", fileRequestExam[0].name);
    formData.append(
      "id_paciente",
      tempRow.id_solicitud_paciente.id_paciente_id
    );
    apiform
      .post(
        `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/examenes/${tempRow.id_solicitud_paciente.id}/documentos/orden_examen/subir/`,
        formData
      )
      .then(responseFile => {
        console.log("response file", responseFile);
        setOpenAnimation(false);
        setOpenDialogSuccess(true);
      })

  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        {openAnimation ? (
          <ModalTitle id="customized-dialog-title">Orden de exámen</ModalTitle>
        ) : (
          <ModalTitle id="customized-dialog-title" onClose={closeModal}>
            Orden de exámen
          </ModalTitle>
        )}
        <DialogContent>
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ marginTop: "4em" }}
          >
            <Grid
              item
              container
              xs={6}
              style={{ marginBottom: "4em" }}
              justify="center"
            >
              <Grid
                item
                container
                xs={12}
                justify="center"
                style={{ marginBottom: "4px" }}
              >
                {fileRequestExam != null ? fileRequestExam[0].name : null}
              </Grid>
              <input
                id="contained-button-file"
                //multiple
                type="file"
                onChange={e => setFileRequestExam(e.target.files)}
                hidden
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ textTransform: "none", marginRight: "3px" }}
                >
                  Adjuntar orden de exámen
                </Button>
              </label>
            </Grid>
          </Grid>
        </DialogContent>
        {openAnimation ? (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            <CircularProgress />
          </DialogActions>
        ) : (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            {fileRequestExam != null ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#43a047",
                  textTransform: "none",
                  color: "#fff"
                }}
                onClick={() => {
                  saveIsapreAuthorization();
                  //editRequest(true);
                }}
              >
                Subir orden de exámen
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none"
                }}
                onClick={() => {
                  saveIsapreAuthorization();
                  //editRequest(true);
                }}
              >
                Subir orden de exámen
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: "none"
              }}
              onClick={() => {
                //editRequest(false);
              }}
            >
              Atrás
            </Button>
          </DialogActions>
        )}
        {openDialogCancelRequest && (
          <DialogCancelRequest
            openDialog={openDialogCancelRequest}
            closeDialog={() => setOpenDialogCancelRequest(false)}
            closeMainModal={closeModal}
          />
        )}
        {openDialogSuccess && (
          <DialogSuccess
            openDialog={openDialogSuccess}
            closeDialog={() => {
              window.location.href = "/admin/clinicRequest/";
            }}
          />
        )}
      </Paper>
    </Modal>
  );
};

export default ModalUploadExamRequest;

ModalUploadExamRequest.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRow: PropTypes.object
};
