import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid,
    Typography,
    Button,
} from "@material-ui/core";
import moment from "moment-timezone";
import "moment/locale/es";
import { grey, indigo, orange, green, red } from "@material-ui/core/colors";
import { ButtonTextMUI as ButtonCustom } from "utils/componentsStyle";
import StorageIcon from '@material-ui/icons/Storage';
import {toMoneyFormat} from "utils/formatFunctions";
import RepearOrders from "./RepearOrders"
import api from "utils/API";
import TableComponent from "views/Widgets/Common/TableComponent";
import allActions from "actions";
import {
    getListOrderPatient,
} from "actions/getListAction";

moment.locale("es");

const DispatchProgram = ({
    listaPrestaciones,
    dispatchDate,
    setOpenEditModal,
    ordersPatientId,
    getPatientInfo,
    setOpenPatientRegistration,
    setSelectOrderPatient,
}) => {
    const dispatch = useDispatch();

    const [repearOrdersDialog, setRepearOrdersDialog] = useState(false);
    const [backdropTogle, setBackdropTogle] = useState(false);
    const [value, setValue] = useState(0);
    const [initialCharge, setInitialCharge] = useState(true);

    const [totalValue, setTotalValue] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [patientID, setpatientID] = useState(null);

    const listOrdersPatient = useSelector(state => state.currentList.listOrdersPatient);
    const patient = useSelector(state => state.currentGlobalVar.patientClinicOrder);
    const total = useSelector(state => state.currentGlobalVar.TotalValueMonthConsume);
    const packaguePatient = useSelector(state => state.currentGlobalVar.patientPackage);
    const total_month = useSelector(state => state.currentGlobalVar.TotalValueMonthConsume);
    const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
    // # Productos_Pedidos ESTADO INCOMPLETO = PERMITE EDITAR (DE TODOS LOS PRODUCTOS DEL PEDIDO GRACIAS DANIEL)


    useEffect(() => {
        if (packaguePatient?.packagingInfo)loadData();
        if (totalValue === 0) setTotalValue(total);
        if((initialCharge  || (patient?.id && patientID !== patient?.id)) && listaPrestaciones.length > 0){
            initialData();
        }
    })

    const initialData = () => {
        setInitialCharge(false);
        setpatientID(patient?.id)
        let totalValue = 0
        listaPrestaciones.filter(item => item.fuera_paquete === false).map(item => {
          totalValue = totalValue + parseInt(item.cantidad_envio !== null ? item.cantidad_envio : item.cantidad) * parseInt(item.id_insumo_medicamento__valor_con_iva)
        })
        if (totalAmount == 0) {
          setTotalAmount(totalValue)
        }
    }

    const loadData = () => {
        const total = packaguePatient.packagingInfo ? packaguePatient.packagingInfo.insumos + packaguePatient.packagingInfo.medicamentos : 0;
        let value_total = total - (value + total_month)
        if (value_total > 0 && total != 0 && backdropTogle == true) {
            setBackdropTogle(false)
        }
        if (value_total < 0 && total != 0 && backdropTogle == true) {
            setBackdropTogle(false)
        }
    }

    let replacement = [];
    // estos son los elementos que van a remplazar a otros....

    let dataDispatch = []

    listaPrestaciones.forEach(item => {
        if (!replacement.includes(item.id)) {
            item.visibility = 'visible'
            item.togle = true
            dataDispatch.push({...item})
            if (replacement.includes(item.id_producto_reemplazo)) {
                listaPrestaciones.forEach(element => {
                    if (element.id == item.id_producto_reemplazo) {
                        element.togle = false
                        element.visibility = 'hidden'
                        dataDispatch.push({...element})
                    }
                })
            }
        }
    })

    const handleShowPackage = () =>{

        api.get(`api/pedidosprogramados/?paquete_paciente=${ordersPatientId}`).then((response) => {
            getListOrderPatient(dispatch, ordersPatientId, response.data);
            setRepearOrdersDialog(true);

        })
    }

    const handleSelectShowOrder = (row) => {
        setSelectOrderPatient(row);
        setOpenEditModal(true);
    }

    const headerDetail = [
        { eval: true, label: 'nº pedido'},
        { eval: true, label: 'Periodo de cobro' },
        { eval: true, label: 'Fecha envio' },
        { eval: true, label: 'Valor medicamento' },
        { eval: true, label: 'Valor insumo' },
        { eval: false, label: "Acción"},
    ]


    const dataDetail = listOrdersPatient.map((row, index) => {

        const _buttonNextOrder_ = <ButtonCustom variant={"outlined"} style={{ color: indigo[500] }} onClick={() => handleSelectShowOrder(row)}>
            <StorageIcon /> Ver
        </ButtonCustom>

        let totalResource = 0;
        let totalMedicine = 0;

        let tempItems = row?.items || {};
        tempItems = Object.values(tempItems).filter((item) => medicalResourceData[item.id_insumo_medicamento_id])

        tempItems.map((item) => {
            const tempItem = medicalResourceData[item.id_insumo_medicamento_id] || {};
            if (tempItem?.grupo_producto == "Medicamentos") {
                totalMedicine += item.cantidad_envio * tempItem.valor_con_iva
            } else {
                totalResource += item.cantidad_envio * tempItem.valor_con_iva
            }
        });

        return [
            { eval: true, value: row.numero_pedido },
            { eval: true, value: moment(row.fecha_cobro).format("MMMM YYYY") },
            { eval: true, value: moment(row.fecha_envio).format("YYYY-MM-DD") },
            { eval: true, value: toMoneyFormat(totalMedicine) },
            { eval: true, value: toMoneyFormat(totalResource) },
            { eval: false, value: _buttonNextOrder_ },
        ]
    })

    return (
        <Grid item container xs={12} style={{ marginTop: "2em", backgroundColor: "#fff", borderRadius: "5px" }}>
            <Grid item container xs={12} style={{ padding: "0 2% 0 2%" }}>
                <Grid item container xs={3}  direction="row" alignContent="flex-start">
                    <Grid item container xs={12} style={{margin: "0 0 20px 0"}}>
                        <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}>
                            Detalle de pedidos
                        </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Typography gutterBottom style={{ textTransform: "none", margin: "0 0 32px 0" }}>
                            De acuerdo a los registros del paciente puedes editar y revisar aquí el próximo despacho o el paquete general,
                            enviar la solicitud de despacho a bodega o generar un despacho rápido.
                        </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Button variant="contained" color="primary" style={{ width: "313px", height: "36px",  margin: "5px 0"}} onClick={() => setOpenEditModal(true)}>Crear Pedido rápido</Button>
                    </Grid>
                    <Grid item container xs={12}>
                        <Button variant="contained" color="primary" style={{ width: "201px", height: "36px", margin: "5px 0" }} onClick={() => handleShowPackage()}>Ver paquete</Button>
                    </Grid>
                </Grid>
                <Grid item container xs={1}>
                </Grid>
                <Grid item container xs={8}>
                    <Grid item container xs={12}>
                        {repearOrdersDialog &&
                        <RepearOrders
                            dateNextOrder={patient.fecha_pedido_programado}
                            ordersPatientId={ordersPatientId}
                            patient={patient}
                            listaPrestaciones={listaPrestaciones}
                            repearOrdersDialog={repearOrdersDialog}
                            setRepearOrdersDialog={setRepearOrdersDialog}
                            getPatientInfo={getPatientInfo}
                        />
                        }
                    </Grid>
                    <Grid item container xs={12}>
                        <TableComponent
                            headers={headerDetail}
                            data={dataDetail}
                            size={'small'}
                            pageSize={5}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DispatchProgram;
