import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import ModalCreateCovenant from "components/Covenants/ModalCreateCovenant";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import RequestsInformation from "./RequestsInformation";
import DivisionTable from "./Division/DivisionTable";
import PurchasesTable from "./Purchases/PurchasesTable";
import CommodityTable from "./Commodity/CommodityTable";
import ReportsTable from "./Reports/ReportsTable";
import SearchRequestWarehouse from "./SearchRequestWarehouse";
import { Buttons } from "./Buttons/Buttons";
import { Position } from "./Position/Position";


export default function WareHouseRequests() {
  const [moduleType, setmoduleType] = useState("");

  const [requests, setRequests] = useState([]);
  const [reports, setReports] = useState([]);

  const [loading, setLoading] = useState(false);
  const [modal_create_covenant, setModal_create_covenant] = useState(false);
  const [value, setValue] = useState(0);

  const [checkTab, setCheckTab] = useState(0);
  const [purchases, setPurchases] = useState([]);
  const [commodity, setCommodity] = useState([]);

  const [changes, setChanges] = useState(true);

  const [loadCompras, setLoadCompras] = useState(true);
  const [loadMercaderia, setLoadMercaderia] = useState(true);
  const [loadReportes, setLoadReportes] = useState(true);
  const [searchDivision, setSearchDivision] = useState("");
  const [searchPurchases, setSearchPurchases] = useState("");
  const [searchCommodity, setSearchCommodity] = useState("");
  const [searchReport, setSearchReport] = useState("");
  const [listPurchaseGroup, setListPurchaseGroup] = useState([]);

  const auxlistPurchaseGroup = useSelector(state => state.currentList.LISTA_TIPO_PRODUCTO);
  const listPurchasesStatus = useSelector(state => state.currentList.LISTA_ESTADO_COMPRAS);
  const reasonList = useSelector(state => state.currentList.LISTA_MOTIVO_RETIRO);
  const groupList = useSelector(state => state.currentList.LISTA_GRUPO_PRESTACIONES_SOLICITUDES);

  useEffect(() => {
    if (changes && auxlistPurchaseGroup) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);
    setLoading(true);
    const tempArrayURL = window.location.href.split("/");
    setmoduleType(tempArrayURL[tempArrayURL.length - 1]);
    const division = api.get("/api/bodega/fraccionamiento");
    Promise.all([
      division,
    ])
      .then(response => {
        setListPurchaseGroup(auxlistPurchaseGroup.filter(row => row[0] !== "No"));
        setRequests(response[0].data);

      })

  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    if (value === 0) {
      setCheckTab(0);
    }
    if (value === 1) {
      setCheckTab(1);
    }
    if (value === 2) {
      setCheckTab(2);
    }
    if (value === 3) {
      setCheckTab(3);
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loadFunctionCompras = () => {
    if (loadCompras) {
      setLoadCompras(false);
      callCompras();
    }
  };

  const getPurchaseStatus = status => {
    const response = listPurchasesStatus.filter(row => row[0] === status);

    return response[0][1];
  };

  const getPurchaseGroup = group => {
    const response = listPurchaseGroup.filter(row => row[0] === group);
    return response[0][1];
  };

  const callCompras = () => {

    const purchases = api.get("api/bodega/solicitudescompras/");

    Promise.all([purchases])
      .then(response => {
        let tempPurchase = [];
        response[0].data.map(row => {
          tempPurchase.push({
            ...row,
            sku: row.id_producto_comprado.SKU,
            description: row.id_producto_comprado.descripcion_producto,
            status: getPurchaseStatus(row.estado),
            group: getPurchaseGroup(row.id_producto_comprado.grupo_producto)
          });
        });
        setPurchases(tempPurchase);

      })

  };

  const loadFunctionMercaderia = () => {
    if (loadMercaderia) {
      setLoadMercaderia(false);
      callMercaderia();
    }
  };

  const callMercaderia = () => {

    const commodity = api.get("api/bodega/retiros/");

    Promise.all([commodity])
      .then(response => {
        setCommodity(response[0].data);

      })

  };

  const loadFunctionReportes = () => {
    if (loadReportes) {
      setLoadReportes(false);
      callReportes();
    }
  };

  const callReportes = () => {

    const reports = api.get("api/bodega/reportes/");

    Promise.all([reports])
      .then(response => {
        setReports(response[0].data);

      })

  };

  let searchDivisionFilter = requests.filter(request => {
    return (
      request.id
        .toString()
        .toLowerCase()
        .indexOf(searchDivision.toLowerCase()) !== -1 ||
      request.id_producto_fraccionar.grupo_producto
        .toString()
        .toLowerCase()
        .indexOf(searchDivision.toLowerCase()) !== -1 ||
      request.id_producto_fraccionar.SKU.toString()
        .toLowerCase()
        .indexOf(searchDivision.toLowerCase()) !== -1 ||
      request.cantidad_fraccionar
        .toString()
        .toLowerCase()
        .indexOf(searchDivision.toLowerCase()) !== -1 ||
      request.id_producto_resultante.SKU.toString()
        .toLowerCase()
        .indexOf(searchDivision.toLowerCase()) !== -1 ||
      request.cantidad_resultante
        .toString()
        .toLowerCase()
        .indexOf(searchDivision.toLowerCase()) !== -1 ||
      request.fecha_solicitud
        .toString()
        .toLowerCase()
        .indexOf(searchDivision.toLowerCase()) !== -1 ||
      request.estado
        .toString()
        .toLowerCase()
        .indexOf(searchDivision.toLowerCase()) !== -1
    );
  });

  let searchPurchasesFilter = purchases.filter(request => {
    return (
      request.id
        .toString()
        .toLowerCase()
        .indexOf(searchPurchases.toLowerCase()) !== -1 ||
      request.id_producto_comprado.SKU.toString()
        .toLowerCase()
        .indexOf(searchPurchases.toLowerCase()) !== -1 ||
      request.group
        .toString()
        .toLowerCase()
        .indexOf(searchPurchases.toLowerCase()) !== -1 ||
      request.id_producto_comprado.descripcion_producto
        .toString()
        .toLowerCase()
        .indexOf(searchPurchases.toLowerCase()) !== -1 ||
      request.cantidad
        .toString()
        .toLowerCase()
        .indexOf(searchPurchases.toLowerCase()) !== -1 ||
      request.observaciones
        .toString()
        .toLowerCase()
        .indexOf(searchPurchases.toLowerCase()) !== -1 ||
      request.fecha_solicitud
        .toString()
        .toLowerCase()
        .indexOf(searchPurchases.toLowerCase()) !== -1 ||
      request.status
        .toString()
        .toLowerCase()
        .indexOf(searchPurchases.toLowerCase()) !== -1
    );
  });

  let searchCommodityFilter = commodity.filter(request => {
    return (
      request.id
        .toString()
        .toLowerCase()
        .indexOf(searchCommodity.toLowerCase()) !== -1 ||
      request.id_producto_retirado.SKU.toString()
        .toLowerCase()
        .indexOf(searchCommodity.toLowerCase()) !== -1 ||
      request.id_producto_retirado.grupo_producto
        .toString()
        .toLowerCase()
        .indexOf(searchCommodity.toLowerCase()) !== -1 ||
      request.id_producto_retirado.descripcion_producto
        .toString()
        .toLowerCase()
        .indexOf(searchCommodity.toLowerCase()) !== -1 ||
      request.cantidad
        .toString()
        .toLowerCase()
        .indexOf(searchCommodity.toLowerCase()) !== -1 ||
      request.motivo
        .toString()
        .toLowerCase()
        .indexOf(searchCommodity.toLowerCase()) !== -1 ||
      request.fecha_solicitud
        .toString()
        .toLowerCase()
        .indexOf(searchCommodity.toLowerCase()) !== -1 ||
      request.estado
        .toString()
        .toLowerCase()
        .indexOf(searchCommodity.toLowerCase()) !== -1
    );
  });

  let searchReportFilter = reports.filter(request => {
    return (
      request.id
        .toString()
        .toLowerCase()
        .indexOf(searchReport.toLowerCase()) !== -1 ||
      request.id_insumo_medicamento.SKU.toString()
        .toLowerCase()
        .indexOf(searchReport.toLowerCase()) !== -1 ||
      request.id_insumo_medicamento.grupo_producto
        .toString()
        .toLowerCase()
        .indexOf(searchReport.toLowerCase()) !== -1 ||
      request.id_insumo_medicamento.descripcion_producto
        .toString()
        .toLowerCase()
        .indexOf(searchReport.toLowerCase()) !== -1 ||
      request.id_insumo_medicamento.stock_producto
        .toString()
        .toLowerCase()
        .indexOf(searchReport.toLowerCase()) !== -1 ||
      request.observaciones
        .toString()
        .toLowerCase()
        .indexOf(searchReport.toLowerCase()) !== -1 ||
      request.fecha_reporte
        .toString()
        .toLowerCase()
        .indexOf(searchReport.toLowerCase()) !== -1 ||
      request.cantidad_encontrada
        .toString()
        .toLowerCase()
        .indexOf(searchReport.toLowerCase()) !== -1 ||
      request.estado
        .toString()
        .toLowerCase()
        .indexOf(searchReport.toLowerCase()) !== -1
    );
  });

  return (
    <GridContainer>
      <ViewTitle
        title="COMPRAS, FRACCIONAMIENTO, RETIROS Y REPORTES"
        message="En esta sección podrás revisar las compras, fraccionamiento, retiros y reportes."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Typography color="textPrimary">
              Fraccionamiento - Compras
            </Typography>
            {Position(checkTab)}
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="mt-5">
              <GridContainer justify="center">
                <RequestsInformation />
              </GridContainer>
              <GridContainer justify="center">
                {Buttons(checkTab, moduleType)}
              </GridContainer>
              <GridContainer justify="center">
                <SearchRequestWarehouse
                  checkTab={checkTab}
                  searchDivision={searchDivision}
                  setSearchDivision={setSearchDivision}
                  searchPurchases={searchPurchases}
                  setSearchPurchases={setSearchPurchases}
                  searchCommodity={searchCommodity}
                  setSearchCommodity={setSearchCommodity}
                  searchReport={searchReport}
                  setSearchReport={setSearchReport}
                />
              </GridContainer>
            </GridContainer>
            {checkTab === 1 ? loadFunctionCompras() : null}
            {checkTab === 2 ? loadFunctionMercaderia() : null}
            {checkTab === 3 ? loadFunctionReportes() : null}
            <CardBody className="mb-3">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  centered
                >
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Fraccionamiento"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Compras"
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Retiro mercaderías"
                    {...a11yProps(2)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Reportes"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <DivisionTable
                  requests={searchDivisionFilter}
                  groupList={groupList}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <PurchasesTable
                  purchases={searchPurchasesFilter}
                  groupList={listPurchaseGroup}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CommodityTable
                  commodity={searchCommodityFilter}
                  groupList={groupList}
                  reasonList={reasonList}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ReportsTable
                  reports={searchReportFilter}
                  groupList={groupList}
                />
              </TabPanel>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modal_create_covenant && (
        <ModalCreateCovenant
          open={modal_create_covenant}
          closeModal={() => setModal_create_covenant(false)}
        />
      )}
    </GridContainer>
  );
}
