import React from 'react'

import { TypographyMui } from 'utils/componentsStyle';

import { makeStyles } from '@material-ui/core/styles'
import { Box, Dialog, DialogTitle, DialogContent, Slide, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  dialogHeader: {
    backgroundColor: '#303F9F',
    padding: '0px 24px'
  },
  dialogContent: {
    padding: 24
  },
  colorWhite: {
    color: 'white'
  },
});

function AppDialog({ open, title, children, fullScreen = false, maxWidth = 'md', onClose, style={} }) {
  const classes = useStyles()
  return (
    <>
      <Dialog 
        fullScreen={fullScreen} 
        open={open} 
        TransitionComponent={Transition} 
        keepMounted maxWidth={maxWidth}
        style={{...style}}
      >
          <DialogTitle className={classes.dialogHeader}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
            <TypographyMui className={classes.colorWhite} variant='body2'>{title}</TypographyMui>

              <IconButton type='reset' onClick={onClose}>
                <CloseIcon className={classes.colorWhite} />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            {children}
          </DialogContent>
      </Dialog>
    </>
  )
}

export default AppDialog
