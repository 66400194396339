import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from '@material-ui/core/styles';
import {
    Button as ButtonMUI,
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    IconButton,
    FormHelperText,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
} from '@material-ui/core';
import {
    CheckCircle,
    RadioButtonUnchecked,
    HighlightOff,
    Event,
} from "@material-ui/icons/";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import api from "utils/API";
import {dictMonth} from "utils/validationFunction";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function EditVisit({
    open,
    handleClose,
    editScheduleVisit,
    dictReservation,
    listType,
    listAbsence,
    handleEditReservation,
    handleEditAbsence,
    handleRemoveReservation,
    deleteVisita,
    handleDeleteAbsence,
    dictProfessionalStatistics,
    showTabs,
    dictAsigtTensWorker={},
}) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const data = editScheduleVisit[0];
    const dataWork = dictAsigtTensWorker[data.id]

    console.log(dataWork)

    const [initialCharge, setinitialCharge] = useState(true);
    const [start, setstart] = useState({});
    const [end, setend] = useState({});
    const [listHour, setlistHour] = useState([]);
    const [type, settype] = useState(listType[0])
    const [worker, setworker] = useState(editScheduleVisit[1]);
    const [changeHour, setchangeHour] = useState(false);
    const [checkPatient, setcheckPatient] = useState(false);
    const [extraTurnValue, setextraTurnValue] = useState("0");
    const [extraLunch, setextraLunch] = useState(false);
    const [shiftMobilization, setshiftMobilization] = useState(false);
    const [specialViatic, setspecialViatic] = useState(false);
    const [extraLunchAmount, setextraLunchAmount] = useState(0);
    const [shiftMobilizationAmount, setshiftMobilizationAmount] = useState(0);
    const [specialViaticAmount, setspecialViaticAmount] = useState(0);
    const [value, setvalue] = useState("0");
    const [changeButtons, setchangeButtons] = useState(true);
    const [messageWarning, setmessageWarning] = useState("");
    const [changeDate , setchangeDate] = useState(false);
    const [updateDate, setupdateDate] = useState(moment());
    const [numberVisit, setnumberVisit] = useState(0);
    const [updateDateStart, setupdateDateStart] = useState(moment(data.value));
    const [updateDateEnd, setupdateDateEnd] = useState(moment(data.value));
    const [lastDayWork, setlastDayWork] = useState(moment(data.value));
    const [dayReinstatement, setdayReinstatement] = useState(moment(data.value));
    const [comment, setcomment] = useState(dataWork?.comentarios || "");
    const [licence, setlicence] = useState(null);
    const [admonition, setadmonition] = useState(null);
    const [discount, setdiscount] = useState(null);

    const listValueAbsence = listAbsence.reduce((acc, item) => {return [...acc, item.value]}, []);

    useEffect(() => {
        if (initialCharge){
            initialData();
        }
        if (changeHour){
            getTimeList()
        }
        if (checkPatient){
            getWarningMessage();
        }
        if (changeButtons){
            setchangeButtons(false);
        }
    });


    const initialData = () => {
        setinitialCharge(false);

        handleType([...listType, ...listAbsence].filter((row) => row.value == data?.tipo_turno ||  row.label == data?.tipo_registro)[0]);
        if (data?.tipo_turno){
            const tempStart = moment(data.fecha_hora_inicio).format("HH:mm");
            const tempEnd = moment(data.fecha_hora_fin).format("HH:mm");
            setupdateDate(moment(data.fecha_hora_inicio).diff(moment(), "days") < 0 ? moment() : moment(data.fecha_hora_inicio));
            setstart({value: tempStart, label: tempStart});
            setend({value: tempEnd, label: tempEnd});
            setnumberVisit(dictProfessionalStatistics[worker.id].real);
            api.get(`/api/clinica/pacientes/asignar/turno/tens/${data.id}`).then((response) => {
                const extra_values = response.data.valores_extras.reduce((accum, obj) => ({
                    ...accum,
                    [obj.codigo_tipo_valor]: obj
                }), {});
                setextraLunch(extra_values["ColacionExtra"] ? true : false);
                setshiftMobilization(extra_values["MovilizacionExtra"] ? true : false);
                setspecialViatic(extra_values["ViaticoEspecial"] ? true : false);
                setextraLunchAmount(extra_values["ColacionExtra"] ? extra_values["ColacionExtra"].valor : 0);
                setshiftMobilizationAmount(extra_values["MovilizacionExtra"] ? extra_values["MovilizacionExtra"].valor : 0);
                setspecialViaticAmount(extra_values["ViaticoEspecial"] ? extra_values["ViaticoEspecial"].valor : 0);
                setvalue(extra_values["ValorHora"] ? extra_values["ValorHora"].valor : 0);
                setextraTurnValue(extra_values["TurnoExtra"] ? extra_values["TurnoExtra"].valor : 0);
                setchangeHour(true);
                setcheckPatient(true);
            }).catch((error) => {
                setchangeHour(true);
                setcheckPatient(true);
            });
        } else {
            setupdateDateStart(data.fecha_hora_inicio)
            setupdateDateEnd(data.fecha_hora_fin)
            const tempLastDayWork = data.valores_extras.filter((row) => row.codigo_tipo_valor === "UltimoDiaTrabajado")[0];
            setlastDayWork(moment(tempLastDayWork?.valor))
            const tempFirstDayWork = data.valores_extras.filter((row) => row.codigo_tipo_valor === "PrimerDiaTrabajar")[0];
            setdayReinstatement(moment(tempFirstDayWork?.valor))
            if(data.tipo_registro === "Inasistencias"){
                const tempLicence = data.valores_extras.filter((row) => row.codigo_tipo_valor === "Licencia")[0];
                const tempAdmonition = data.valores_extras.filter((row) => row.codigo_tipo_valor === "Amonestacion")[0];
                const tempDiscount = data.valores_extras.filter((row) => row.codigo_tipo_valor === "Descuento")[0];
                setlicence(tempLicence.valor)
                setadmonition(tempAdmonition.valor)
                setdiscount(tempDiscount.valor)
            }
        }

    };

    const getWarningMessage = () => {
        const dateEvent = moment(changeDate ? updateDate : data.fecha_hora_inicio).format("YYYY-MM-DD");
        if (dictReservation[dateEvent]){
            if (dictReservation[dateEvent][worker.id]){
                const tempArray = dictReservation[dateEvent][worker.id].filter((row) => row.id !== data.id);
                if (tempArray.length > 0){
                    setmessageWarning(`Ya tiene asignado un turno en este día`);
                } else {
                    setmessageWarning("");
                }
            } else {
                setmessageWarning("");
            }
        } else {
            setmessageWarning("");
        }
    };

    const getTimeList = () => {
        setchangeHour(false);
        const tempListHour = [];

        let tempDate =  moment(data.fecha_hora_inicio).format("YYYY-MM-DD");
        let countDate = moment(tempDate);
        let tempDateEnd = moment(tempDate).add(1, "days");

        while (moment(tempDateEnd).diff(countDate, "minutes") >= 0){
            const tempHour = moment(countDate).format("HH:mm");
            tempListHour.push({value: tempHour, label: tempHour});
            countDate = moment(countDate).add(30, "minutes");
        }
        setlistHour(tempListHour);
    };

    const handleDate = (value) =>{
        setupdateDate(value);
        setcheckPatient(true);
    }

    const handleSelectHour = (value, argDate, set) => {
        const tempDate =  moment(data.fecha_hora_inicio).format("YYYY-MM-DD");
        let auxDate = moment(`${tempDate} ${value.value}:0`);
        let compareDate = null;
        let auxValue = value
        if (argDate === "start"){
            if (["D", "N", "EXD", "EXN", "TRN", "TRD"].includes(type.value)){

                const tempEndDate = moment(auxDate).add(12, "hours").format("HH:mm");
                auxValue = auxDate;
                setend({value: tempEndDate, label: tempEndDate});
            } else if (["T", "EXT", "TR24"].includes(type.value)){
                const tempEndDate = moment(auxDate).add(24, "hours").format("HH:mm");
                auxValue = auxDate;
                setend({value: tempEndDate, label: tempEndDate});
            } else {
                compareDate = moment(`${tempDate} ${end.value}:0`);
                auxValue = moment(compareDate).diff(auxDate, "minutes") > 0 ?
                    auxDate : moment(compareDate).subtract(30, "minutes");
            }

        } else {
            compareDate = moment(`${tempDate} ${start.value}:0`);
            auxValue = moment(auxDate).diff(compareDate, "minutes") > 0 ?
                auxDate : moment(compareDate).add(30, "minutes");
        }
        const valueFormat = moment(auxValue).format("HH:mm");
        set({value:valueFormat, label: valueFormat});
    };

    const handleType = (value) => {
        settype(value);
        if (value.value === "D" ||  value.value === "N"){
            const tempEndDate = moment(`${moment(data.fecha_hora_inicio).format("YYYY-MM-DD")} ${start.value}:00`).add(12, "hours").format("HH:mm");
            setend({value: tempEndDate, label: tempEndDate});
        } else if (value.value === "T"){
            const tempEndDate = moment(`${moment(data.fecha_hora_inicio).format("YYYY-MM-DD")} ${start.value}:00`).add(24, "hours").format("HH:mm");
            setend({value: tempEndDate, label: tempEndDate});
        }
        if (!["EXD", "EXN", "EXT"].includes(type.value)){
            setextraTurnValue("0")
        }
    };

    const handleAddItems = (value, set, setvalue) => {
        set(!value);
        if (value) {
            setvalue(0);
        }
    };

    const handleOnChange = (value, set) => {
        let tempValue =  isNaN(value) ? 0 : value;
        set(tempValue);
    };

    const handleCaculateRows = () => {
        handleEditReservation(
            type,
            start,
            end,
            extraLunchAmount,
            shiftMobilizationAmount,
            specialViaticAmount,
            extraTurnValue,
            value,
            comment,
            moment(changeDate ? updateDate : data.fecha_hora_inicio).format("YYYY-MM-DD"),
        );
    };

    const handleSelectProcess = () => {
        if (listValueAbsence.includes(type.value)){
            handleEditAbsence(updateDateStart, updateDateEnd, lastDayWork, dayReinstatement, type, licence, admonition, discount );
        } else {
            handleCaculateRows();
        }
    };

    const handleSelectDelete = () => {
        if (listValueAbsence.includes(type.value)){
            handleDeleteAbsence(type);
        } else {
            deleteVisita();
        }
    }

    const _content_ =  <div>
    {changeButtons === false &&
        <div></div>
    }
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    item
                    xs={12}
                    style={{margin: "10px 0"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <Typography variant="body2" gutterBottom>
                            {`TENS: ${worker.first_name} ${worker.last_name}`}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <Typography variant="body2" gutterBottom>
                            {`Tipo contrato: ${worker.id_ficha.tipo_contrato ? worker.id_ficha.tipo_contrato : "Sin asignar" }`}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0"}}
                    >
                        {!changeDate ?
                        <Typography variant="body2" gutterBottom>
                            {`Fecha: ${moment(data.fecha_hora_inicio).format("DD")} ${dictMonth[parseInt(moment(data.fecha_hora_inicio).format("MM"))]} de ${parseInt(moment(data.fecha_hora_inicio).format("YYYY"))}`}
                        </Typography> :
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                format="DD/MM/YYYY"
                                margin="normal"
                                label={"Fecha de inicio"}
                                placeholder="Fecha de inicio"
                                onChange={e => handleDate(e)}
                                value={updateDate}
                                inputVariant={"outlined"}
                            />
                        </MuiPickersUtilsProvider>
                        }
                        {changeDate ?
                         <IconButton style={{margin: "-6px 0 0 6px", color: indigo[500], background: indigo[50]}} onClick={() => setchangeDate(!changeDate)} size="small">
                            <HighlightOff fontSize="inherit" />
                        </IconButton> :
                        <IconButton style={{margin: "-6px 0 0 6px", color: indigo[500], background: indigo[50]}} onClick={() => setchangeDate(!changeDate)} size="small">
                            <Event fontSize="inherit" />
                        </IconButton>
                        }
                    </Grid>
                </Grid>
                {!listValueAbsence.includes(type.value) &&
                <Typography variant="body2" gutterBottom>
                    {`Hasta ahora ha trabajado ${numberVisit} horas en el mes`}
                </Typography>
                }
                {messageWarning != "" &&
                <SnackbarContent
                    message={messageWarning}
                    color="danger"
                />
                }
            </Grid>

            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                {listValueAbsence.includes(type.value) ?
                <>
                    <Grid item container xs={12} justify="center">
                        <Typography
                            variant="body2"
                            gutterBottom
                            style={{ textTransform: "none" }}
                        >
                            {`Tipo de asignación: ${type.label}`}
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} justify="center">
                    <Typography
                        variant="body2"
                        gutterBottom
                        style={{ textTransform: "none" }}
                    >
                        {`Días: ${moment(updateDateEnd).diff(moment(updateDateStart), "days") + 1}`}
                    </Typography>
                    </Grid>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha de inicio"}
                            placeholder="Fecha de inicio"
                            onChange={e => setupdateDateStart(e)}
                            maxDate={moment(updateDateEnd)}
                            value={updateDateStart}
                            inputVariant={"outlined"}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha de término"}
                            placeholder="Fecha de término"
                            onChange={e => setupdateDateEnd(e)}
                            minDate={moment(updateDateStart)}
                            value={updateDateEnd}
                            inputVariant={"outlined"}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha último día de trabajo"}
                            placeholder="Fecha último día de trabajo"
                            onChange={e => setlastDayWork(e)}
                            maxDate={moment(updateDateStart)}
                            value={lastDayWork}
                            inputVariant={"outlined"}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha de reincorporación"}
                            placeholder="Fecha de reincorporación"
                            onChange={e => setdayReinstatement(e)}
                            minDate={moment(updateDateEnd)}
                            value={dayReinstatement}
                            inputVariant={"outlined"}
                        />
                    </MuiPickersUtilsProvider>
                    { type.value === "inasistencias" &&
                    <>
                    <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <FormControl
                            variant="outlined"
                            style={{minWidth: "230px"}}
                            error={licence === "" ? true : false}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                            Licencia
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={licence}
                            onChange={(event) =>  setlicence(event.target.value)}
                            label="Licencia"
                            >
                            <MenuItem value={"si"}>Si</MenuItem>
                            <MenuItem value={"no"}>No</MenuItem>
                            </Select>
                            <FormHelperText>
                            {licence === "" ? (
                                <span className="text-danger">Debe ingresar licencia</span>
                            ) : null}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <FormControl
                            variant="outlined"
                            style={{minWidth: "230px"}}
                            error={admonition === "" ? true : false}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                            Amonestación
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={admonition}
                            onChange={(event) => setadmonition(event.target.value)}
                            label="Amonestación"
                            >
                            <MenuItem value={"si"}>Si</MenuItem>
                            <MenuItem value={"no"}>No</MenuItem>
                            </Select>
                            <FormHelperText>
                            {admonition === "" ? (
                                <span className="text-danger">Debe ingresar amonestación</span>
                            ) : null}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <FormControl
                            variant="outlined"
                            style={{minWidth: "230px"}}
                            error={discount === "" ? true : false}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                            Descuento
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={discount}
                            onChange={(event) => setdiscount(event.target.value)}
                            label="Descuento"
                            >
                            <MenuItem value={"si"}>Si</MenuItem>
                            <MenuItem value={"no"}>No</MenuItem>
                            </Select>
                            <FormHelperText>
                            {discount === "" ? (
                                <span className="text-danger">Debe ingresar descuento</span>
                            ) : null}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    </>
                    }
                    </>:
                    <>
                        <DetailSelect
                            label="Tipo de asignación:"
                            value={type}
                            onchange={(value) => handleType(value)}
                            list={[...listType, ...listAbsence]}
                            validation={false}
                        />
                        <DetailSelect
                            label="Horario inicio:"
                            value={start}
                            onchange={(value) => handleSelectHour(value, "start", setstart)}
                            list={listHour.slice(0, listHour.length - 1)}
                        />
                        <DetailSelect
                            isDisabled={["D", "N", "EXD", "EXN", "TRN", "TRD", "T", "EXT", "TR24"].includes(type.value)}
                            label="Horario fin:"
                            value={end}
                            onchange={(value) => handleSelectHour(value, "end", setend)}
                            list={listHour.slice(1, listHour.length)}
                        />
                        {showTabs === "Traslado" &&
                        <DetailNumber
                            label={`Valor`}
                            value={value}
                            onchange={e => handleOnChange(
                                parseInt(e.target.value.split('$').join('').split(',').join('')),
                                setvalue)
                            }
                            prefix={"$"}
                            thousandSeparator={true}
                        />
                        }
                    </>
                    }
            </Grid>

            <DetailNumber
                label={["EXD", "EXN", "EXT"].includes(type.value) ? `Valor liquido turno extra`: `Se realizo alguna hora extra? (ingrese monto liquido)` }
                value={extraTurnValue}
                onchange={e => handleOnChange(
                    parseInt(e.target.value.split('$').join('').split(',').join('')),
                    setextraTurnValue)
                }
                prefix={"$"}
                thousandSeparator={true}
            />
            
            {!listValueAbsence.includes(type.value) &&
            <>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Typography variant="body2" gutterBottom>
                   {`¿Cuál de los siguientes items desea agregar?`}
                </Typography>
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleAddItems(extraLunch, setextraLunch, setextraLunchAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Colación extra"
                />
                { extraLunch &&
                    <DetailNumber
                        label={`Valor colación extra`}
                        value={extraLunchAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setextraLunchAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleAddItems(shiftMobilization, setshiftMobilization, setshiftMobilizationAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Movilizacíon extra"
                />
                { shiftMobilization &&
                    <DetailNumber
                        label={`Valor movilizacíon extra`}
                        value={shiftMobilizationAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setshiftMobilizationAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleAddItems(specialViatic, setspecialViatic, setspecialViaticAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Viatico especial"
                />
                { specialViatic &&
                    <DetailNumber
                        label={`Valor viatico especial`}
                        value={specialViaticAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setspecialViaticAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}

                    />
                }
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    item
                    xs={10}
                    style={{margin: "10px 0 10x 0"}}
                >
                    <DetailText
                        label={`Comentario`}
                        placeholder={``}
                        value={comment}
                        validation={false}
                        onchange={(e) => { setcomment(e.target.value)}}
                    />
                </Grid>
            </Grid>
            </>}
        </Grid>
    </div>

    const _actions_ = <>
        <ButtonMUI
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: green[500], marginRight: "3px"}}
            onClick={handleSelectProcess}
        >
            Guardar
        </ButtonMUI>
        <ButtonMUI
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: red[500], marginRight: "3px"}}
            onClick={handleSelectDelete}
        >
            Eliminar
        </ButtonMUI>
        <ButtonMUI
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500], marginRight: "10px", marginLeft: "3px"}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </ButtonMUI>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Editar turno TENS`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xs"
        />

    );
}