import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";
import api from "utils/API";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


export default function ModalUploadFileIsapre({
    open,
    handleClose,
    dataPatient,
    openModalNoFile,
    reloadRequest,
    handleUploadFile,
}) {
    const dispatch = useDispatch();
    const [selectfile, setselectfile] = useState(null);

    const handleupload = () => {
        if (openModalNoFile){
            handleUploadFile();
            handleClose();

        } else {
            const fileInfo = selectfile[0];
            const body = new FormData();
            body.append('archivo', fileInfo);
            body.append('tipo_documento', "AprobacionCotizacion");
            body.append('nombre_archivo', fileInfo.name);
            body.append('id_paciente',  dataPatient.id);

            api.post(`api/clinica/${reloadRequest.id_paciente}/solicitudes/cotizacion/${reloadRequest.id_paciente}/subiraprobacion/`, body).then((response) => {
                handleUploadFile();
                handleClose();
            });
        }
    };

    const _content_ = <div>
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            { openModalNoFile ?
            <Typography variant="body2" gutterBottom>
            {`¿Desea posponer la aprobación de la ISAPRE?`}
            </Typography>
            :
            <Typography variant="body2" gutterBottom>
                {`¿Desea adjuntar la aprobación de la ISAPRE?`}
            </Typography>
            }
            { openModalNoFile === false &&
            <Typography variant="caption" gutterBottom>
                {`La autorización debe coincidir con la cotización`}
            </Typography>
            }
            { openModalNoFile === false &&
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
                item
                xs={6}
            >
                <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{margin: "0 0 18px 0"}}
                >
                    Seleccionar archivo
                    <TextField
                        type="file"
                        id="exam"
                        onChange={(event) =>  setselectfile(event.target.files)}
                        fullWidth my={2}
                        style={{display: 'none'}}
                        accept={["pdf", "jpg", "jpeg", "png"]}
                    />
                </Button>
            </Grid>
            }
            {selectfile? selectfile[0].name: ""}
        </Grid>
    </div>

    const _actions_ = <>
        {(selectfile || openModalNoFile) ?
        <Button
            variant="contained"
            component="label"
            className="text-white"
            style={{backgroundColor: green[500]}}
            onClick={handleupload}
        >
            Sí
        </Button>
        :
        <Button
            disabled
            variant="contained"
            component="label"
        >
            Sí
        </Button>

        }
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500]}}
            className="text-white"
            onClick={handleClose}
        >
            No
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={ openModalNoFile ? `Posponer autorización de cotización` : `Adjuntar autorización de cotización`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xs"
        />
    );
}