import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReceptionInfoModal from "./ReceptionInfoModal";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Grid from "@material-ui/core/Grid";



export default function ModalRejectPending(props) {
  const { open, closeModal, productos_restantes, id_order } = props;
  const [insumos, setInsumos] = useState([]);
  const [order, setOrder] = useState([]);
  const [reasonRejection, setReasonRejection] = useState("");

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    api.get(`api/solicitudpedidos/${id_order}`).then(response => {
      setOrder(response.data);
    });
    api.get("api/insumosmedicamento").then(response => {
      setInsumos(response.data);
    });
  }, []);

  const [scroll, setScroll] = useState("paper");

  const getTipoCantidad = (id_supply, supplies) => {
    const resp = supplies
      .filter(supply => supply.id === id_supply)
      .map(filtered => {
        return filtered.tipo_cantidad;
      });
    return resp;
  };
  const getDescripcionProducto = (id_supply, supplies) => {
    const resp = supplies
      .filter(supply => supply.id === id_supply)
      .map(filtered => {
        return filtered.descripcion_producto;
      });
    return resp;
  };

  function updateBenefit() {

    const formData = new FormData();

    formData.append("tipo_solicitud", order.tipo_solicitud);
    formData.append("fecha_solicitud", order.fecha_solicitud);
    formData.append("estado_picking", order.estado_picking);
    formData.append("fecha_despacho", order.fecha_despacho);
    formData.append("nombre_paciente", order.nombre_paciente);
    formData.append("es_region", order.es_region);
    formData.append("tiene_medicamentos", order.tiene_medicamentos);
    formData.append("estado_guia_despacho", order.estado_guia_despacho);
    formData.append("estado_solicitud", "Hacer picking");
    formData.append("traslado_clinica", order.traslado_clinica);
    formData.append("motivo_rechazo", reasonRejection);
    formData.append("id_paciente", order.id_paciente);

    apiform
      .put(`api/solicitudpedidos/${id_order}`, formData)
      .then(response => {
        console.log(response);
        window.location.href = "/admin/outcome/receptions/";
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">No aceptar recepción</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <Grid
          container
          justify="center"
          xs={12}
          style={{ textAlign: "center" }}
        >
          <ReceptionInfoModal id={id_order} />
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            El pedido quedo con los siguiente pendientes: <br />
            <div className="mt-2 mb-3">
              <ul className="list-group">
                {productos_restantes.map(producto => (
                  <li key={producto.id} className="list-group-item">
                    {producto.cantidad_por_enviar}
                    {getTipoCantidad(producto.id_insumo_medicamento, insumos)}
                     de
                    {getDescripcionProducto(
                      producto.id_insumo_medicamento,
                      insumos
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid container xs={12} style={{ textAlign: "center" }}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <DetailTextArea
              label="MOTIVO RECHAZO:"
              placeholder="Motivo rechazo"
              value={reasonRejection}
              maxlength="500"
              onchange={e => {
                setReasonRejection(e.target.value);
              }}
            />
            {reasonRejection === "" ? (
              <span className="text-danger">
                Debes ingresar un motivo de rechazo.
              </span>
            ) : null}
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Cancelar
        </Button>
        {reasonRejection != "" ? (
          <Button onClick={updateBenefit} color="primary">
            Enviar
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

ModalRejectPending.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  productos_restantes: PropTypes.array,
  id_order: PropTypes.number
};
