import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
    Typography,
    TextField,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import TableComponent from "views/Widgets/Common/TableComponent";
import TabCommon from "views/Widgets/Common/TabCommon";
import {setmessageGeneral } from "actions/getGlobalAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function ModalTabletLose({
    open,
    closeModal,
    rowInfo,
    setinitialCharge,
}) {
    const dispatch = useDispatch();
    const [openModalAccept, setopenModalAccept] = useState(null);
    const [comment, setcomment] = useState("");
    const [initialData, setinitialData] = useState(false)
    const [listAssistance, setlistAssistance] = useState([])
    const [listComment, setlistComment] = useState([])
    const limitObservation  = 400;
    
    const headers = [
        {label: "Fecha asistencia"},
        {label: "Usuario"},
        {label: "Cargo"},
        {label: "Recepción tablet"},
        {label: "dispositivo"},
    ];

    const headersComment = [
        {label: "Fecha"},
        {label: "Usuario"},
        {label: "Comentario"},
    ];

    useEffect(() => {
        setinitialData(true);
    }, []);
    
    useEffect(() => {
        if (initialData){
            api.get(`api/paciente/dispositivo/extraviado/${rowInfo.id}`).then((response) => {
                setlistAssistance(response.data["asistencia"])
                setlistComment(response.data["observaciones"])
                setinitialData(false)
            })
        }
      
    }, [initialData]);

    const handleNextStep = (state) => {
        const body = {
            observacion: comment,
            tarea_finalizada: openModalAccept === "Finalizar",
        }
        api.post(`api/paciente/dispositivo/extraviado/${rowInfo.id}`, body ).then((response) => {
            setmessageGeneral(dispatch, response.data.mensaje, "success", "¡Exito!");
            if (openModalAccept === "Finalizar"){
                setinitialCharge(true)
                closeModal()
            } else {
                setinitialData(true)
            }
          
        })
    }

    const dataAssistance = listAssistance.map((row) => {
        return [
            {value: moment(row.fecha).format("YYYY-MM-DD HH:mm")}, 
            {value: row.nombre},
            {value: row.cargo},
            {value: row.recepcion_tablet ? "Sí" : "No"},
            {value: row.tam_dispositivo},
        ]
    })

    const dataComment= listComment.map((row) => {
        return [
            {value: moment(row.fecha).format("YYYY-MM-DD HH:mm")}, 
            {value: row.nombre},
            {value: row.observacion },
        ]
    })

    const patientData = rowInfo.id_ficha.id_paciente
    const patientName = `${patientData.nombre} ${patientData.apellido_paterno} ${patientData.apellido_materno}`

    const _requestInfoBox_ = <div className="col-xl-8 mb-3">
        <div className="card">
        <div className="card-body">
            <div className="row">
            <div className="col-sm text-left ml-5">
                <span className="font-weight-bold">iD tarea: </span>
                {rowInfo.id}
                <br />
                <span className="font-weight-bold">Paciente: </span>
                {patientName}
            </div>
            </div>
        </div>
        </div>
    </div>

const listTab = [
    {label:"Registros asistencia",
    _value_: <>
       <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <TableComponent 
                headers={headers}
                data={dataAssistance}
            />
        </Grid>
    </>
    },
    {label:"Observaciones",
    _value_: <>
       <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <TableComponent 
                headers={headersComment}
                data={dataComment}
            />
        </Grid>
    </>
    },
  ]

    const _content_ = <div>
        <Grid 
            direction="row" 
            justify="center"
            alignItems="center"
            item xs={12} 
            style={{padding: "5px", width: "100%"}
        }>
            <Typography component="body2" variant="body2"  style={{fontWeight: 'bold'}} > 
                {`Se ha generado una alerta de un dispositivo posiblemente extraviado en el domicilio del paciente:`}
            </Typography>
        </Grid>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0"}}
        >
            {_requestInfoBox_}
        </Grid>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <TabCommon
                data={listTab}
            />
        </Grid>
    </div>

    const _actions_ = <>
        {rowInfo.estado == "PorRealizar" &&
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: green[500]}}
            className="text-white"
            onClick={() =>setopenModalAccept("Finalizar")}
        >
            Finalizar
        </Button>
        }
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: green[500]}}
            className="text-white"
            onClick={() =>setopenModalAccept("Comentar")}
        >
            Agregar comentario
        </Button>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: indigo[500]}}
            className="text-white"
            onClick={closeModal}
        >
            Cerrar
        </Button>
    </>


    const _contentAccept_ = <div >
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-end"
            item
            xs={12}
        >
            {openModalAccept == "Finalizar" && 
            <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
              ¿desea dar por finalizado el inconveniente?
            </Grid>
            }
            <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
            <TextField
                label={"Observación"}
                multiline
                rows={8}
                value={comment}
                variant="outlined"
                onChange={(event) => setcomment(event.target.value)}
                style={{width: "100%"}}
            />
            <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
                {`${comment?.length || 0 }/${limitObservation}`}
            </Typography>
            </Grid>
        </Grid>
    </div>

    const _actionsAccept_ = <>
          <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: green[500]}}
            className="text-white"
            onClick={() =>  handleNextStep()}
        >
            Aceptar
        </Button>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: indigo[500]}}
            className="text-white"
            onClick={() => setopenModalAccept(null)}
        >
            Cerrar
        </Button>
    </>


    return (
        <>
        <ModalDialog
            open={open}
            onClose={closeModal}
            title={`Dispositivo extraviado`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xl"
        />
        {openModalAccept &&
            <ModalDialog
                open={openModalAccept}
                onClose={() => setopenModalAccept(false)}
                title={`Dispositivo extraviado`}
                _content_={_contentAccept_}
                _actions_ = {_actionsAccept_}
                maxWidth="sm"
            />
        }
        </>

    );
}