import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import ModalAddEgresos from "./ModalAddEgresos";
import ModalAddIngresos from "./ModalAddIngresos";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  formControl: {
    margin: theme.spacing(3)
  },
  formControlSelect: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const BalanceHidrico = props => {
  const classes = useStyles();
  const {
    getSpecialties,
    setPageRefresh,
    balanceIngresos,
    setBalanceIngresos,
    balanceEgresos,
    setBalanceEgresos,
    startDateBalance,
    setStartDateBalance,
    endDateBalance,
    setEndDateBalance,
    specialtyBalance,
    setSpecialtyBalance,
    dataFile,
    setChanges,
  } = props;

  const [openModalAddIngresos, setOpenModalAddIngresos] = useState(false);
  const [openModalAddEgresos, setOpenModalAddEgresos] = useState(false);

  const handleChangeBalanceIngresos = (event, index) => {
    const tempRow = balanceIngresos;
    tempRow[index].select = event;
    setBalanceIngresos(tempRow);
    setPageRefresh(true);
  };

  const handleChangeBalanceEgresos = (event, index) => {
    const tempRow = balanceEgresos;
    tempRow[index].select = event;
    setBalanceEgresos(tempRow);
    setPageRefresh(true);
  };

  const handleSpeciality = event => {
    setSpecialtyBalance(event.target.value);
    const tempRowIngresos = balanceIngresos;
    tempRowIngresos.map(row => {
      row.speciality = event.target.value;
    });
    setBalanceIngresos(tempRowIngresos);
    const tempRowEgresos = balanceEgresos;
    tempRowEgresos.map(row => {
      row.speciality = event.target.value;
    });
    setBalanceEgresos(tempRowEgresos);
    setPageRefresh(true);
  };

  const handleStartDateChange = date => {
    setStartDateBalance(date);
    const tempRowIngresos = balanceIngresos;
    tempRowIngresos.map(row => {
      row.startDate = moment(date).format("YYYY-MM-DD[T]HH:mm:ss");
    });
    setBalanceIngresos(tempRowIngresos);
    const tempRowEgresos = balanceEgresos;
    tempRowEgresos.map(row => {
      row.startDate = moment(date).format("YYYY-MM-DD[T]HH:mm:ss");
    });
    setBalanceEgresos(tempRowEgresos);
    setPageRefresh(true);
  };

  const handleEndDateChange = date => {
    setEndDateBalance(date);
    const tempRowIngresos = balanceIngresos;
    tempRowIngresos.map(row => {
      row.endDate = moment(date).format("YYYY-MM-DD[T]HH:mm:ss");
    });
    setBalanceIngresos(tempRowIngresos);
    const tempRowEgresos = balanceEgresos;
    tempRowEgresos.map(row => {
      row.endDate = moment(date).format("YYYY-MM-DD[T]HH:mm:ss");
    });
    setBalanceEgresos(tempRowEgresos);
    setPageRefresh(true);
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} container justify="center">
        <Grid item xs={3}>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Rango de tiempo
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Fecha de inicio"
                value={startDateBalance}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline2"
                label="Fecha fin"
                value={endDateBalance}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3} container alignItems="flex-start">
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ textTransform: "none" }}
            >
              Ingresos
            </Typography>

            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => setOpenModalAddIngresos(true)}
                color="primary"
              >
                Agregar ingresos
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="flex-start"
            //style={{ marginTop: "-140px" }}
          >
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {balanceIngresos.map((row, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={row.select}
                        onChange={e =>
                          handleChangeBalanceIngresos(e.target.checked, index)
                        }
                        color="primary"
                      />
                    }
                    label={row.item}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={3} container alignItems="flex-start">
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ textTransform: "none" }}
            >
              Egresos
            </Typography>
          </Grid>
          <Grid item xs={12} container alignItems="flex-start">
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => setOpenModalAddEgresos(true)}
                color="primary"
              >
                Agregar egresos
              </Button>
            </Grid>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {balanceEgresos.map((row, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={row.select}
                        onChange={e =>
                          handleChangeBalanceEgresos(e.target.checked, index)
                        }
                        color="primary"
                      />
                    }
                    label={row.item}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={3} container alignItems="flex-start">
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ textTransform: "none" }}
            >
              Especialidad
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControlSelect}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Especialidad
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={specialtyBalance || ""}
                onChange={handleSpeciality}
                label="Especialidad"
              >
                {getSpecialties.map(specialty => (
                  <MenuItem key={specialty.id} value={specialty.id}>
                    {specialty.nombre_corto}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {openModalAddEgresos && (
        <ModalAddEgresos
          openModal={openModalAddEgresos}
          closeModal={() => setOpenModalAddEgresos(false)}
          dataFile={dataFile}
          setChanges={setChanges}
          balanceEgresos={balanceEgresos}
          setBalanceEgresos={setBalanceEgresos}
          startDateBalance={startDateBalance}
          endDateBalance={endDateBalance}
          specialtyBalance={specialtyBalance}
        />
      )}
      {openModalAddIngresos && (
        <ModalAddIngresos
          openModal={openModalAddIngresos}
          closeModal={() => setOpenModalAddIngresos(false)}
          dataFile={dataFile}
          setChanges={setChanges}
          balanceIngresos={balanceIngresos}
          setBalanceIngresos={setBalanceIngresos}
          startDateBalance={startDateBalance}
          endDateBalance={endDateBalance}
          specialtyBalance={specialtyBalance}
        />
      )}
    </Grid>
  );
};

export default BalanceHidrico;

BalanceHidrico.propTypes = {
  getSpecialties: PropTypes.array,
  setPageRefresh: PropTypes.func,
  balanceIngresos: PropTypes.array,
  setBalanceIngresos: PropTypes.func,
  balanceEgresos: PropTypes.array,
  setBalanceEgresos: PropTypes.func,
  startDateBalance: PropTypes.string,
  setStartDateBalance: PropTypes.func,
  endDateBalance: PropTypes.string,
  setEndDateBalance: PropTypes.func,
  specialtyBalance: PropTypes.number,
  setSpecialtyBalance: PropTypes.func,
  dataFile: PropTypes.object,
  setChanges: PropTypes.func,
};
