import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import moment from "moment-timezone";
import "moment/locale/es";
import ModalDialog from "views/Widgets/Common/ModalDialog";
moment.locale("es");

export default function ModalAdd({
  open,
  handleClose,
  table,
  listItem,
  dataArray,
  handleAddItem,
  //setPageRefresh
}) {
  const dispatch = useDispatch()
  const [tempList, settempList] = useState([]);
  const [value, setvalue] = useState({});
  const [charge, setcharge] = useState(true);

  const openBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);

  useEffect(() => {
    if (charge) {
      getList();
    }
    if (openBackdrop){
      getList();
      dispatch({
        type: "BLOQUEAR_PANTALLA",
        payload: false,
    });
    }
  });

  const getList = () => {
    setcharge(false);
    const auxDataId = [];
    dataArray.map(row => {
      if (row.fuera_paquete === table) {
        auxDataId.push(row.id);
      }
    });
    settempList(listItem.filter(row => !auxDataId.includes(row.value)));
  };

  const handleOnChange = (value, set) => {
    if(value.value !== null){
      set(value);
    }
  };

  const updateValue = () => {
    handleAddItem(value);
    handleClose();
  };

  const chargeList = [{value: null, label: "Cargando..."}]

  const _content_ = <div>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      item
      xs={12}
    >
      <Grid item xs={12}>
        {openBackdrop &&
          <div></div>
        }
          <DetailSelect
              label="Buscar"
              value={value}
              onchange={(value) => { handleOnChange(value, setvalue)}}
              list={tempList.length > 0 ? tempList : chargeList}
          />
      </Grid>
    </Grid>
  </div>

  const _actions_ = <>
    {value ? (
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{ backgroundColor: green[500], textTransform: "none" }}
        onClick={updateValue}
      >
        Agregar
      </Button>
    ) : (
      <Button disabled mr={2} variant="contained">
        Agregar
      </Button>
    )}
    <Button
      mr={2}
      variant="contained"
      style={{
        backgroundColor: red[500],
        marginRight: "10px",
        textTransform: "none"
      }}
      className="text-white"
      onClick={handleClose}
    >
      Cerrar
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={"Agregar Item"}
      _content_={_content_}
      _actions_ = {_actions_}
      maxWidth={"xs"}
    />
  );
}

ModalAdd.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  table: PropTypes.bool,
  listItem: PropTypes.array,
  dataInfo: PropTypes.object,
  dataArray: PropTypes.array,
  setdataArray: PropTypes.func,
  handleAddItem: PropTypes.func,
  setPageRefresh: PropTypes.func
};
