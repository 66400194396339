import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
const styles = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

export default function TableListIsapres() {
  const classes = useStyles();
  const [checkedDepartamento, setCheckedDepartamento] = React.useState(true);
  const [checkedJefatura, setCheckedJefatura] = React.useState(true);
  const [checkedCargo, setCheckedCargo] = React.useState(true);
  const [checkedLugarTrabajo, setCheckedLugarTrabajo] = React.useState(true);
  const [
    checkedFechaContratacion,
    setCheckedFechaContratacion,
  ] = React.useState(true);
  const [checkedTipoPuesto, setCheckedTipoPuesto] = React.useState(true);
  const [checkedNivelEducacional, setCheckedNivelEducacional] = React.useState(
    true
  );
  const [checkedTituloObtenido, setCheckedTituloObtenido] = React.useState(
    true
  );
  const [checkedExperiencia, setCheckedExperiencia] = React.useState(true);
  const [checkedConocimiento, setCheckedConocimiento] = React.useState(true);
  const [checkedHabilidadesMin, setCheckedHabilidadesMin] = React.useState(
    true
  );
  const [checkedPrincipalResp, setCheckedPrincipalResp] = React.useState(true);
  const [checkedFunciones, setCheckedFunciones] = React.useState(true);
  const [checkedTiposContrato, setCheckedTiposContrato] = React.useState(true);
  const [checkedSueldo, setCheckedSueldo] = React.useState(true);
  const [checkedNumeroVacante, setCheckedNumeroVacante] = React.useState(true);
  const [checkedJornada, setCheckedJornada] = React.useState(true);
  const [checkedModalidadTrabajo, setCheckedModalidadTrabajo] = React.useState(
    true
  );
  const [checkedRangoEdad, setCheckedRangoEdad] = React.useState(true);
  const [checkedGenero, setCheckedGenero] = React.useState(true);
  const [checkedAptoPara, setCheckedAptoPara] = React.useState(true);
  const [checkedExtranjeros, setCheckedExtranjeros] = React.useState(true);
  const [checkedLicConducir, setCheckedLicConducir] = React.useState(true);
  const [checkedMovPropia, setCheckedMovPropia] = React.useState(true);
  const [checkedOtro, setCheckedOtro] = React.useState(true);

  return (
    <GridContainer>
      <GridItem xs={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Dependencias</h4>
          </CardHeader>
          <CardBody>
            <table>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedDepartamento}
                            onChange={(event) =>
                              setCheckedDepartamento(event.target.checked)
                            }
                            value="checkedDepartamento"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Departamento</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>
                    {" "}
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedJefatura}
                            onChange={(event) =>
                              setCheckedJefatura(event.target.checked)
                            }
                            value="checkedJefatura"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Jefatura</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedCargo}
                            onChange={(event) =>
                              setCheckedCargo(event.target.checked)
                            }
                            value="checkedCargo"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Cargo</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedLugarTrabajo}
                            onChange={(event) =>
                              setCheckedLugarTrabajo(event.target.checked)
                            }
                            value="checkedLugarTrabajo"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Lugar de Trabajo</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedFechaContratacion}
                            onChange={(event) =>
                              setCheckedFechaContratacion(event.target.checked)
                            }
                            value="checkedFechaContratacion"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Fecha estimada contratación</td>
                </tr>

                <tr>
                  <th scope="row">6</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedTipoPuesto}
                            onChange={(event) =>
                              setCheckedTipoPuesto(event.target.checked)
                            }
                            value="checkedTipoPuesto"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Tipo de Puesto</td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Requisitos</h4>
          </CardHeader>
          <CardBody>
            <table>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedNivelEducacional}
                            onChange={(event) =>
                              setCheckedNivelEducacional(event.target.checked)
                            }
                            value="checkedNivelEducacional"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Nivel Educacional</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>
                    {" "}
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedTituloObtenido}
                            onChange={(event) =>
                              setCheckedTituloObtenido(event.target.checked)
                            }
                            value="checkedTituloObtenido"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Título Obtenido</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedExperiencia}
                            onChange={(event) =>
                              setCheckedExperiencia(event.target.checked)
                            }
                            value="checkedExperiencia"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Experiencia</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedConocimiento}
                            onChange={(event) =>
                              setCheckedConocimiento(event.target.checked)
                            }
                            value="checkedConocimiento"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Conocimientos</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedHabilidadesMin}
                            onChange={(event) =>
                              setCheckedHabilidadesMin(event.target.checked)
                            }
                            value="checkedHabilidadesMin"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Habilidades mínimas</td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Características del cargo</h4>
          </CardHeader>
          <CardBody>
            <table>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedPrincipalResp}
                            onChange={(event) =>
                              setCheckedPrincipalResp(event.target.checked)
                            }
                            value="checkedPrincipalResp"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Principal Responsabiliad</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>
                    {" "}
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedFunciones}
                            onChange={(event) =>
                              setCheckedFunciones(event.target.checked)
                            }
                            value="checkedFunciones"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Funciones</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedTiposContrato}
                            onChange={(event) =>
                              setCheckedTiposContrato(event.target.checked)
                            }
                            value="checkedTiposContrato"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Tipo de Contrato</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedSueldo}
                            onChange={(event) =>
                              setCheckedSueldo(event.target.checked)
                            }
                            value="checkedSueldo"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Sueldo</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedNumeroVacante}
                            onChange={(event) =>
                              setCheckedNumeroVacante(event.target.checked)
                            }
                            value="checkedNumeroVacante"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Número de Vacantes</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedJornada}
                            onChange={(event) =>
                              setCheckedJornada(event.target.checked)
                            }
                            value="checkedJornada"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Jornada</td>
                </tr>
                <tr>
                  <th scope="row">7</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedModalidadTrabajo}
                            onChange={(event) =>
                              setCheckedModalidadTrabajo(event.target.checked)
                            }
                            value="checkedModalidadTrabajo"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Modalidad de trabajo</td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Otros</h4>
          </CardHeader>
          <CardBody>
            <table>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedRangoEdad}
                            onChange={(event) =>
                              setCheckedRangoEdad(event.target.checked)
                            }
                            value="checkedRangoEdad"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Rango de Edad</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>
                    {" "}
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedGenero}
                            onChange={(event) =>
                              setCheckedGenero(event.target.checked)
                            }
                            value="checkedGenero"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Género</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedAptoPara}
                            onChange={(event) =>
                              setCheckedAptoPara(event.target.checked)
                            }
                            value="checkedAptoPara"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Apto Para Personas con Discapacidad</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedExtranjeros}
                            onChange={(event) =>
                              setCheckedExtranjeros(event.target.checked)
                            }
                            value="checkedExtranjeros"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Extranjeros </td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedLicConducir}
                            onChange={(event) =>
                              setCheckedLicConducir(event.target.checked)
                            }
                            value="checkedLicConducir"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Licencias de Conducir</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedMovPropia}
                            onChange={(event) =>
                              setCheckedMovPropia(event.target.checked)
                            }
                            value="checkedMovPropia"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Movilización propia</td>
                </tr>
                <tr>
                  <th scope="row">7</th>
                  <td>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedOtro}
                            onChange={(event) =>
                              setCheckedOtro(event.target.checked)
                            }
                            value="checkedOtro"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>Otro(s) especifique</td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </GridItem>
      <Button color="rose" onClick={console.log("guardando cambios")}>
        Guardar
      </Button>
    </GridContainer>
  );
}
