import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal, 
    Backdrop, 
    Paper, 
    Grid,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import {red, green} from "@material-ui/core/colors";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: "all 500ms linear",
    },
    paper: {
        transition: "all 500ms linear",
        padding: "2% 4% 3%",
    }
}));
  
export default function ModalComment({open, handleClose, index, array, setArray}) {
    const classes = useStyles();
    const [comment, setcomment] = useState (array[index].comentario != "" ? array[index].comentario : "");

    const handleOnChange = (value, set) => {
        set(value);
    };

    const updateValue = () => {
        const auxArray = array;
        auxArray[index].comentario = comment;
        auxArray[index].estado = true;
        setArray(auxArray);
        handleClose();
    };

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px',
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.paper}>
            <h3 id="spring-modal-title">{`Agregar comentario`}</h3>
            <Grid 
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                item
                xs={12}
            >
                <Grid 
                    item
                    xs={12}
                >
                    <DetailText 
                        label={`Comentario`} 
                        placeholder={`Comentario`} 
                        value={comment} 
                        onchange={(e) => { handleOnChange(e.target.value, setcomment)}}
                    />
                </Grid>
                <Grid 
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                >
                    {array[index].estado === false &&
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            style={{backgroundColor: green[500]}}
                            onClick={updateValue}
                        >
                            Finalizar
                        </Button>
                    }
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    </Modal>
    );
}