import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {
    TextField,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    Grid,
    CircularProgress,
    FormControlLabel ,
    Checkbox,
} from "@material-ui/core";
import { CustomRadio, TypographyMui } from "utils/componentsStyle";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
  } from "@material-ui/pickers";
import {
    Close,
    Replay,
} from '@material-ui/icons/';
import { green, indigo } from "@material-ui/core/colors";
import Select from "react-select";
import {
    getListSuppliesMedicines,
} from "actions/getListAction";
import {classStyle} from "utils/styleFunction";
import MomentUtils from "@date-io/moment";
import moment from 'moment-timezone';
import 'moment/locale/es' ;
moment.locale("es");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function ModalAddMedicine({
    open,
    closeModal,
    handleSaveMedicine,
    style={}
}) {
    const classes = classStyle();
    const dispatch = useDispatch();

    const [selectMedicine, setselectMedicine] = useState({});
    const [selectTypeQuantity, setselectTypeQuantity] = useState({});
    const [selectTotalTypeQuantity, setselectTotalTypeQuantity] = useState({});
    const [selectFrequency, setselectFrequency] = useState({});
    const [medicineText, setmedicineText] = useState(" ");
    const [valueQuantity, setvalueQuantity] = useState("");
    const [valueTotalQuantity, setvalueTotalQuantity] = useState("");
    const [valueFrequency, setvalueFrequency] = useState("");
    const [startDate, setstartDate] = useState(moment());
    const [endDate, setendDate] = useState(null);
    const [typeRecipe, settypeRecipe] = useState("Simple");
    const [disabledType, setdisabledType] = useState(false);
    const [magistral, setmagistral] = useState(false);

    const listMedicines = useSelector(state => state.currentList.listMedicines);
    const dictSuppliesMedicines = useSelector(state => state.currentList.dictSuppliesMedicines);
    const dictMedicinesPrescription = useSelector(state => state.currentList.dictMedicinesPrescription);
    const listTypeQuantity = useSelector(state => state.currentList.LISTA_TIPO_CANTIDAD);
    const listTypeQuantityTotal = useSelector(state => state.currentList.LISTA_TIPO_CANTIDAD_TOTAL);
    const listFrequency = useSelector(state => state.currentList.LISTA_TIPO_FRECUENCIA_PRESTACION);
    const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

    const listKeyMedicinePrescription = Object.keys(dictMedicinesPrescription)

    const handleReloadMedicines = () => {
        setselectMedicine({});
        getListSuppliesMedicines(dispatch, chargeMedicine);
    }

    const handleSelectMedicine = (value) => {
        setselectMedicine(value);
        setmedicineText("");
        const tempItem = dictMedicinesPrescription[dictSuppliesMedicines[value.value]?.SKU];

        if(tempItem){
            settypeRecipe(tempItem.tipo_receta);
            setdisabledType(true);
            setmagistral(tempItem.magistral)
        } else {
            settypeRecipe("Simple");
            setdisabledType(false);
            setmagistral(false);
        }
    }

    const handleMedicineText = (value) => {
        setmedicineText(value);
        setselectMedicine({});
        settypeRecipe("Simple");
        setdisabledType(false);
        setmagistral(false);
    }

    const handleTypeRecipe = (value) => {
        if(!disabledType){
            settypeRecipe(value);
        }
    };

    const handleMagistral = (value) => {
        if(!disabledType){
            setmagistral(value);
        }
    };

    return (
        <div>
            <Dialog
                fullScreen={false}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={"md"}
                style={{...style}}
            >
                <DialogTitle className={classes.dialogHeader}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        <TypographyMui className={classes.colorWhite} variant='body2'>{"AGREGAR MEDICAMENTO"}</TypographyMui>

                        <IconButton type='reset' onClick={closeModal}>
                            <Close className={classes.colorWhite} />
                        </IconButton>
                    </Grid>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    item
                    xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        <TypographyMui
                            style={{margin: "0 0 15px 0", color: indigo[500]}}
                            variant='h6'
                        >
                            {"Medicamento"}
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        <TypographyMui
                            style={{color: indigo[500]}}
                            variant='body2'
                        >
                            {"Medicamento existente"}
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "0 0 10px 0"}}
                    >
                        {(listMedicines.length > 0 && listKeyMedicinePrescription.length > 0) &&
                        <div style={{minWidth: "300px"}}>
                        <Select
                            options={ listMedicines}
                            value={selectMedicine}
                            onChange={(value) => handleSelectMedicine(value)}
                        />
                        </div>
                        }
                        {(listMedicines.length > 0 && listKeyMedicinePrescription.length > 0) ?
                        <IconButton style={{margin: "0 0 0 15px"}} onClick={handleReloadMedicines}>
                            <Replay style={{color: indigo[500]}} />
                        </IconButton> :
                        <CircularProgress
                            style={{color: indigo[500]}}
                            thickness={7}
                        />
                        }

                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        <TypographyMui
                            style={{color: indigo[500]}}
                            variant='body2'
                        >
                            {"Medicamento no disponible en la lista"}
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "0 0 10px 0"}}
                    >
                        <TextField
                            size="small"
                            id="medicineExtra"
                            variant="outlined"
                            value={medicineText}
                            onChange={(event) => handleMedicineText(event.target.value)}
                            style={{width: "100%"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        <TypographyMui
                            style={{color: indigo[500]}}
                            variant='body2'
                        >
                            {"Tipo de receta"}
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={12}
                    >
                        <FormControlLabel
                            control={
                                <CustomRadio
                                    value="Simple"
                                    checked={typeRecipe === "Simple"}
                                    onChange={(event) => handleTypeRecipe(event.target.value)}

                                />
                            }
                            label="Simple" style={{textTransform: "none"}}
                        />
                        <FormControlLabel
                            control={
                                <CustomRadio
                                    value="Cheque/Controlada"
                                    checked={typeRecipe === "Cheque/Controlada"}
                                    onChange={(event) => handleTypeRecipe(event.target.value)}

                                />
                            }
                            label="Cheque" style={{textTransform: "none"}}
                        />
                         <FormControlLabel
                            control={
                                <CustomRadio
                                    disabled
                                    value="Digital"
                                    checked={typeRecipe === "Digital"}
                                    onChange={(event) => handleTypeRecipe(event.target.value)}

                                />
                            }
                            label="Retenida" style={{textTransform: "none"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={12}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value="Simple"
                                    checked={magistral}
                                    onChange={(event) => handleMagistral(event.target.checked)}

                                />
                            }
                            label="Receta magistral"
                            style={{textTransform: "none"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={10}
                        style={{margin: "45px 0 0 0"}}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={5}
                        >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={12}
                                style={{margin: "0 0 15px 0 "}}
                            >
                                <TypographyMui
                                    style={{color: indigo[500]}}
                                    variant='h6'
                                >
                                    {"Dosis"}
                                </TypographyMui>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={5}
                            >
                                <TypographyMui
                                    variant='body2'
                                >
                                    {"Cantidad"}
                                </TypographyMui>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={7}
                            >
                                <TypographyMui
                                    variant='body2'
                                >
                                    {"Medida"}
                                </TypographyMui>

                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={5}
                                style={{padding: "0 5px 0 0px"}}
                            >
                                <TextField
                                    size="small"
                                    type="number"
                                    variant="outlined"
                                    value={valueQuantity}
                                    onChange={(event) => setvalueQuantity(event.target.value)}
                                    style={{width: "100%"}}
                                />
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={7}
                            >
                                <div style={{width: "100%"}}>
                                    <Select
                                        label="Cantidad"
                                        options={listTypeQuantity}
                                        value={selectTypeQuantity}
                                        onChange={(value) => setselectTypeQuantity(value)}
                                    />
                                </div>

                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={12}
                            >
                                <TypographyMui
                                    variant='caption'
                                >
                                    {"Ejs. 100 mg / 20 ml / 30 gotas"}
                                </TypographyMui>

                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            item
                            xs={2}
                        >
                            <TypographyMui variant='body2'>{"cada"}</TypographyMui>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={5}
                        >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={12}
                                style={{margin: "0 0 15px 0 "}}
                            >
                                <TypographyMui
                                    style={{color: indigo[500]}}
                                    variant='h6'
                                >
                                    {"Frecuencia"}
                                </TypographyMui>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={5}
                            >
                                <TypographyMui
                                    variant='body2'
                                >
                                    {"Cantidad"}
                                </TypographyMui>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={7}
                            >
                                <TypographyMui
                                    variant='body2'
                                >
                                    {"Tiempo"}
                                </TypographyMui>

                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={5}
                                style={{padding: "0 5px 0"}}
                            >
                                <TextField
                                    size="small"
                                    type="number"
                                    variant="outlined"
                                    value={valueFrequency}
                                    onChange={(event) => setvalueFrequency(event.target.value)}
                                    style={{width: "100%"}}
                                />
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={7}
                            >
                                <div style={{width: "100%"}}>
                                    <Select
                                        options={listFrequency}
                                        value={selectFrequency}
                                        onChange={(value) => setselectFrequency(value)}
                                    />
                                </div>

                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={12}
                            >
                                <TypographyMui
                                    variant='caption'
                                >
                                    {"Ejs. 8 horas / 3 días / 2 semanas"}
                                </TypographyMui>

                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={8}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={12}
                            style={{margin: "45px 0 5px 0 "}}
                        >
                            <TypographyMui
                                style={{color: indigo[500]}}
                                variant='h6'
                            >
                                {"Periodo tratamiento"}
                            </TypographyMui>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={6}
                            style={{padding: "0 5px 0"}}
                        >
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                {endDate ?
                                <KeyboardDateTimePicker
                                    label={"Fecha y hora de inicio"}
                                    variant="inline"
                                    format="DD/MM/YYYY HH:mm"
                                    margin="normal"
                                    onChange={setstartDate}
                                    maxDate={moment(endDate)}
                                    
                                    value={startDate}
                                    inputVariant={"outlined"}
                                />:
                                <KeyboardDateTimePicker
                                    label={"Fecha y hora de inicio"}
                                    variant="inline"
                                    format="DD/MM/YYYY HH:mm"
                                    margin="normal"
                                    onChange={setstartDate}
                                    value={startDate}
                                    inputVariant={"outlined"}
                                />
                                }
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={6}
                        >
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDateTimePicker
                                    label={"Fecha y hora de término"}
                                    variant="inline"
                                    format="DD/MM/YYYY HH:mm"
                                    margin="normal"
                                    onChange={setendDate}
                                    minDate={startDate ? moment(startDate): null}
                                    value={endDate}
                                    inputVariant={"outlined"}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={6}
                        style={{margin: "45px 0 5px 0 "}}

                    >
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={12}
                            style={{margin: "0 0 15px 0 "}}
                        >
                            <TypographyMui
                                style={{color: indigo[500]}}
                                variant='h6'
                            >
                                {"Cantidad necesaria para el tratamiento"}
                            </TypographyMui>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={5}
                        >
                            <TypographyMui
                                variant='body2'
                            >
                                {"Cantidad"}
                            </TypographyMui>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={7}
                        >
                            <TypographyMui
                                variant='body2'
                            >
                                {"Medida"}
                            </TypographyMui>

                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={5}
                            style={{padding: "0 5px 0 0px"}}
                        >
                            <TextField
                                size="small"
                                type="number"
                                variant="outlined"
                                value={valueTotalQuantity}
                                onChange={(event) => setvalueTotalQuantity(event.target.value)}
                                style={{width: "100%"}}
                            />
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            item
                            xs={7}
                        >
                            <div style={{width: "100%"}}>
                                <Select
                                    options={listTypeQuantityTotal}
                                    value={selectTotalTypeQuantity}
                                    onChange={(value) => setselectTotalTypeQuantity(value)}
                                    menuPlacement="top"
                                />
                            </div>

                        </Grid>
                        <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                item
                                xs={12}
                            >
                                <TypographyMui
                                    variant='caption'
                                >
                                    {"Ejs. 3 cajas / 2 botellas / 4 tiras"}
                                </TypographyMui>

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        {(
                            (selectMedicine.value || medicineText) &&
                            selectTypeQuantity.value &&
                            selectTotalTypeQuantity.value &&
                            selectFrequency.value &&
                            valueQuantity > 0 &&
                            valueTotalQuantity > 0 &&
                            valueFrequency > 0 &&
                            startDate &&
                            endDate &&
                            typeRecipe
                        ) ?

                        <Button
                            style={{
                                background: indigo[500],
                                color: indigo[50],
                            }}
                            onClick={() => handleSaveMedicine(
                                selectMedicine,
                                selectTypeQuantity,
                                selectTotalTypeQuantity,
                                selectFrequency,
                                medicineText,
                                valueQuantity,
                                valueTotalQuantity,
                                valueFrequency,
                                startDate,
                                endDate,
                                typeRecipe,
                                magistral,
                            )}
                        >
                            AGREGAR MEDICAMENTO
                        </Button>:
                        <Button
                            disabled
                            style={{
                                background: indigo[50],
                                color: indigo[500],
                            }}
                        >
                            AGREGAR MEDICAMENTO
                        </Button>
                        }
                    </Grid>


                </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

