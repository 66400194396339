import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
    TextField,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";
import api from "utils/API";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import {setmessageGeneral } from "actions/getGlobalAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function ModalCommentDecompensation({
    open,
    handleClose,
    requestID,
    setdictComments,
    arrayComments,
    setarrayComments,
}) {
    const dispatch = useDispatch();
    const [comment, setcomment] = useState ("");

    const updateValue = () => {
        const body = {
            comentario: comment,
            manual: true,
            tipo_comentario: "ComentarioManual",
            id_descompensacion: null,
            id_usuario: localStorage.getItem("user_id")
        }
        api.post(`api/paciente/comentariosdescompensacion/solicitud/${requestID}/`, body).then((response) => {
            const tempArrayComment = arrayComments;
            const tempdictComment = {}
            const tempComment = {
                ...response.data,
                id_usuario_id: localStorage.getItem("user_id"),
                id_usuario: {
                    first_name: localStorage.getItem("first_name"),
                    last_name: localStorage.getItem("last_name"),
                },
                estado: true
            };
            tempArrayComment.push(tempComment)
            tempArrayComment.map((row) => {
                const tempDate = row.estado ? moment(row.fecha_traslado).format("YYYY-MM-DD HH:mm") : moment(row.fecha_traslado).format("YYYY-MM-DD HH:mm")
                const userId = `${row.id_usuario_id} ${row.id_usuario.first_name} ${row.id_usuario.last_name}`;
                if (tempdictComment[tempDate] === undefined || tempdictComment[tempDate] === null ){
                    tempdictComment[tempDate] = {};
                }
                if (tempdictComment[tempDate][userId]){
                    tempdictComment[tempDate][userId].push(row);
                } else {
                    tempdictComment[tempDate][userId] = [row];
                }
            });
            setdictComments(tempdictComment);
            setarrayComments(tempArrayComment);
            handleClose();
            setmessageGeneral(dispatch, "Mensaje enviado", "succes", "¡Exito!" );
        }).catch((error) => {
            setmessageGeneral(dispatch,"Error al cargar mensaje", "error", "¡Error!" );
        })
    };

    const _content_ = <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-end"
        item
        xs={12}
    >
        <Grid
            item
            xs={12}
        >
            <TextField
                id="comentario_id"
                label={`Ingresar comentario de la descompensación`}
                multiline
                rows={8}
                value={comment}
                variant="outlined"
                onChange={(event) => setcomment(event.target.value)}
                style={{width: "100%"}}
            />
        </Grid>
    </Grid>

    const _actions_ = <>
        {comment !== "" ?
            <Button
                mr={2}
                variant="contained"
                className="text-white"
                style={{backgroundColor: green[500]}}
                onClick={updateValue}
            >
                Enviar
            </Button> :
            <Button
                mr={2}
                variant="contained"
                disabled

            >
                Enviar
            </Button>
        }
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500], marginRight: "10px"}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={"Agregar comentario"}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth={"xs"}
        />
    );
}