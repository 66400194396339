import React, { useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ModalEditRequestInProcess from "../../PatientFile/InsumosYMedicamentos/ModalEditRequestInProces";
import ModalCheckRequest from "../../PatientFile/InsumosYMedicamentos/RequestInProcess/ModalCheckRequest";
import ModalExamDataView from "./Examenes/ModalExamDataView";
import ModalTransferClinic from "views/PatientFile/Traslados/Modals/ModalTransferClinic";
import ModalRequestTelemedicina from "components/PatientFile/ModalRequestTelemedicina";
import { Grid } from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

export default function RequestProgressTable({
  dataArray,
  dictTypeStateRequest,
  handleSelectRow,
  handleSelectDetail,
  handleQualitication,
  handleHistory,
  listMedical,
  listResource,
  listEquip,
  equipData,
  medicalResourceData,
  setauxData,
  dataPatient
}) {
  const classes = useStyles();
  const [rowInfo, setRowInfo] = useState({});
  const [openModalInProcess, setOpenModalInProcess] = useState(false);
  const [openModalEditInProcess, setOpenModalEditInProcess] = useState(false);
  const [openModalCheckInProcess, setOpenModalCheckInProcess] = useState(false);
  const [openModalTelemedicina, setopenModalTelemedicina] = useState(false);
  const [openModalExamView, setOpenModalExamView] = useState(false);
  const [openModalTransfer, setOpenModalTransfer] = useState(false);
  const array = dataArray.filter(
    row =>
      row.estado === "EsperaIsapre" ||
      row.estado === "EsperandoHabilitacion" ||
      row.estado === "HabilitadaPendientes" ||
      row.estado === "EnEspera" ||
      row.estado === "EnProgreso" ||
      row.estado === "Revisar" ||
      row.estado === "EnviadaCoordinadora" ||
      row.estado === "EnviadaBodega" ||
      row.estado === "EsperandoInstrucciones" ||
      row.estado === "InstruccionesEntregadas" ||
      row.estado === "EsperandoLaboratorio" ||
      row.estado === "EnRotativaRuta" ||
      row.estado === "Agendada" ||
      row.estado === "EsperandoCoordinador"
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const dictPosition = useSelector(state => state.currentList.dictCharges);
  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PACIENTE);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Prioridad</TableCell>
              <TableCell align="center">Hecha por</TableCell>
              <TableCell align="center">Días abierta</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {array
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">
                    {dictTypeRequest[row.Tipo_Solicitud_Paciente]?.label}
                  </TableCell>
                  <TableCell align="center">{row.prioridad}</TableCell>
                  <TableCell align="center">
                    {`${row.id_cargo_solicitante
                      ? dictPosition[row.id_cargo_solicitante]?.nombre
                      : null}/${row.nombre_completo}`}
                  </TableCell>
                  <TableCell align="center">
                    {moment().diff(moment(row.fecha_creacion), "days")}
                  </TableCell>
                  <TableCell align="center">
                    {dictTypeStateRequest[row.estado]?.label}
                  </TableCell>
                  <TableCell align="center">
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      itemxs={12}
                    >
                      {row.estado === "EsperaIsapre" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ margin: "1% 0", width: "100%" }}
                          onClick={() => handleSelectRow(row)}
                        >
                          Aprobar
                        </Button>
                      ) : row.Tipo_Solicitud_Paciente ===
                          "HabilitacionDomicilio" &&
                        (row.estado === "EnEspera" || row.estado === "HabilitadaPendientes") ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ margin: "1% 0", width: "100%" }}
                          onClick={() => handleSelectDetail(row)}
                        >
                          Detalle
                        </Button>
                      ) : (
                        (row.estado === "EsperandoInstrucciones" || row.estado === "InstruccionesEntregadas") && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ margin: "1% 0", width: "100%" }}
                            onClick={() => handleHistory(row)}
                          >
                            Detalle
                          </Button>
                        )
                      )}
                      {((row.estado === "EnviadaCoordinadora" || row.estado === "EsperandoCoordinador" )&&
                        row.Tipo_Solicitud_Paciente === "InsumosExtra") ||
                      (row.estado === "Revisar" &&
                        row.Tipo_Solicitud_Paciente) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            margin: "1% 0",
                            width: "100%",
                            textTransform: "none"
                          }}
                          onClick={() => {
                            setRowInfo(row);
                            setOpenModalInProcess(true);
                          }}
                        >
                          Editar
                        </Button>
                      ) : null}
                      {row.Tipo_Solicitud_Paciente === "Examenes" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            margin: "1% 0",
                            width: "100%",
                            textTransform: "none"
                          }}
                          onClick={() => {
                            setRowInfo(row);
                            setOpenModalExamView(true);
                          }}
                        >
                          Revisar
                        </Button>
                      ) : null}
                      {row.estado === "EnEspera" &&
                      row.Tipo_Solicitud_Paciente === "Traslado" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            margin: "1% 0",
                            width: "100%",
                            textTransform: "none"
                          }}
                          onClick={() => {
                            setRowInfo(row);
                            setOpenModalTransfer(true);
                          }}
                        >
                          Revisar
                        </Button>
                      ) : null}
                      {row.estado === "EnviadaBodega" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            margin: "1% 0",
                            width: "100%",
                            textTransform: "none"
                          }}
                          onClick={() => {
                            setRowInfo(row);
                            setOpenModalCheckInProcess(true);
                          }}
                        >
                          Editar
                        </Button>
                      ) :
                      row.estado === "Agendada" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            margin: "1% 0",
                            width: "100%",
                            textTransform: "none"
                          }}
                          onClick={() => {
                            setRowInfo(row);
                            setopenModalTelemedicina(true);
                          }}
                        >
                          Detalle
                        </Button>
                      ) :
                      row.estado === "EsperandoHabilitacion" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            margin: "1% 0",
                            width: "100%",
                            textTransform: "none"
                          }}
                          onClick={() => {
                            handleQualitication(row)
                          }}
                        >
                          Habilitar Paciete
                        </Button>
                      ) :
                      null}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={array.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {openModalInProcess && (
        <ModalEditRequestInProcess
          openModal={openModalInProcess}
          closeModal={() => setOpenModalInProcess(false)}
          rowInfo={rowInfo}
          listMedical={listMedical}
          listResource={listResource}
          medicalResourceData={medicalResourceData}
          dataArray={dataArray}
          setOpenModalEditInProcess={setOpenModalEditInProcess}
          type={"2"}
          isSpecific={false}
          inRequest={true}
        />
      )}
      {openModalCheckInProcess && (
        <ModalCheckRequest
          openModal={openModalCheckInProcess}
          closeModal={() => setOpenModalCheckInProcess(false)}
          rowInfo={rowInfo}
          setauxData={setauxData}
          dataArray={dataArray}
          setOpenModalEditInProcess={setOpenModalEditInProcess}
          type={"2"}
        />
      )}
      {openModalExamView && (
        <ModalExamDataView
          openModal={openModalExamView}
          closeModal={() => setOpenModalExamView(false)}
          tempRow={rowInfo}
          listMedical={listMedical}
          listResourse={listResource}
          medicalResourceData={medicalResourceData}
        />
      )}
      {openModalTransfer && (
        <ModalTransferClinic
          openModal={openModalTransfer}
          closeModal={() => setOpenModalTransfer(false)}
          dataPatient={dataPatient}
          tempRow={rowInfo}
          dataArray={dataArray}
          setauxData={setauxData}
          viewOnly={true}
          edit={false}
        />
      )}
      {openModalTelemedicina && (
        <ModalRequestTelemedicina
          open={openModalTelemedicina}
          closeModal={() => setopenModalTelemedicina(false)}
          dataPatient={dataPatient}
          requestInfo={rowInfo}
        />
      )}
    </Paper>
  );
}

RequestProgressTable.propTypes = {
  dataArray: PropTypes.array
};
