import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Select from "react-select";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator={true}
      decimalSeparator={false}
      allowNegative={false}
      isNumericString
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ModalEditPatient = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    patients,
    idFicha,
    tempMotivo,
    setTempMotivo,
    tempPatient,
    setTempPatient,
    tempPatientCompare,
    variableCounter,
    setVariableCounter,
    vetoesInfo,
    setVetoesInfo,
    tempVetoe,
    setMessage,
    setOpenDialogEdit,
    setPageRefresh,
    setPatients
  } = props;
  const [openAnimation, setOpenAnimation] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(false);
  const getIndex = vetoesInfo.indexOf(tempVetoe);

  const handlePatient = patient => {
    setTempPatient(patient);
  };

  const editVeto = () => {
    setOpenAnimation(true);
    let tempVetoes = [...vetoesInfo];
    tempVetoes.splice(getIndex, 1);
    let tempInformation = {
      fecha_hora_inicio: moment().format("YYYY-MM-DDTHH:mm:ss"),
      id_ficha_paciente: tempPatient.value,
      id_ficha_personal: parseInt(idFicha),
      motivo: tempMotivo
    };
    if (JSON.stringify(tempPatient) != JSON.stringify(tempPatientCompare)) {
      let patientsTemp = patients.filter(
        filtered => JSON.stringify(filtered) != JSON.stringify(tempPatient)
      );
      patientsTemp.push(tempPatientCompare);
      setPatients(patientsTemp);
    }

    api
      .patch(
        `api/rrhh/variablesdinamicas/registros/vetos/${tempVetoe.id}/`,
        tempInformation
      )
      .then(response => {
        tempVetoes.push(response.data);
        setVetoesInfo(tempVetoes);
        setMessage("Registro guardado con éxito");
        setPageRefresh(true);
        setOpenAnimation(false);
        setOpenDialogEdit(true);
      })

  };

  const deleteVeto = () => {
    setOpenAnimation(true);
    let tempVariableCounter = { ...variableCounter };
    let tempVetoes = [...vetoesInfo];
    tempVetoes.splice(getIndex, 1);
    let tempPatients = patients;
    tempPatients.push(tempPatient);

    api
      .delete(`api/rrhh/variablesdinamicas/registros/vetos/${tempVetoe.id}/`)
      .then(() => {
        tempVariableCounter.cantidad_vetos = variableCounter.cantidad_vetos - 1;
        setVariableCounter(tempVariableCounter);
        setVetoesInfo(tempVetoes);
        setPatients(tempPatients);
        setMessage("Registro eliminado con éxito");
        setPageRefresh(true);
        setOpenAnimation(false);
        setOpenDialogEdit(true);
      });
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        {openAnimation ? (
          <ModalTitle id="customized-dialog-title">Editar registro</ModalTitle>
        ) : (
          <ModalTitle id="customized-dialog-title" onClose={closeModal}>
            Editar registro
          </ModalTitle>
        )}
        <DialogContent>
          <Grid container className={classes.root} justify="center">
            <Grid item container xs={12} justify="center">
              <Grid
                item
                container
                xs={7}
                justify="center"
                style={{ marginBottom: "1em" }}
              >
                <Grid item xs={7}>
                  <label className="text-dark">Paciente</label>
                  <Select
                    value={
                      Object.keys(tempPatient).length > 0 ? tempPatient : " "
                    }
                    onChange={handlePatient}
                    options={patients}
                    placeholder={
                      Object.keys(tempPatient).length > 0 ? (
                        "Seleccione"
                      ) : (
                        <span className="text-danger">Requerido</span>
                      )
                    }
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    noOptionsMessage={() => "Sin resultados"}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={7}
                justify="center"
                style={{ marginTop: "3em", marginBottom: "3em" }}
              >
                <Grid item xs={7}>
                  <TextField
                    error={tempMotivo === "" ? true : false}
                    fullWidth
                    id="outlined-multiline-static"
                    label="Motivo"
                    multiline
                    rows={5}
                    variant="outlined"
                    value={tempMotivo}
                    onChange={e => setTempMotivo(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item container justify="center">
                {deleteInfo ? (
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ textTransform: "none" }}
                  >
                    ¿Está seguro de eliminar este registro?
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          {openAnimation ? (
            <CircularProgress />
          ) : (
            <div>
              {deleteInfo ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={deleteVeto}
                  style={{ textTransform: "none", marginLeft: "3px" }}
                >
                  Sí, eliminar veto
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setDeleteInfo(true)}
                  style={{ textTransform: "none", marginLeft: "3px" }}
                >
                  Eliminar veto
                </Button>
              )}
              {Object.keys(tempPatient).length > 0 && tempMotivo != "" ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={editVeto}
                  style={{ textTransform: "none", marginLeft: "3px" }}
                >
                  Guardar cambios
                </Button>
              ) : (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  onClick={editVeto}
                  style={{ textTransform: "none", marginLeft: "3px" }}
                >
                  Guardar cambios
                </Button>
              )}
            </div>
          )}
        </DialogActions>
      </Paper>
    </Modal>
  );
};

export default ModalEditPatient;

ModalEditPatient.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  patients: PropTypes.array,
  idFicha: PropTypes.string,
  tempMotivo: PropTypes.string,
  setTempMotivo: PropTypes.func,
  tempIdFichaPaciente: PropTypes.number,
  setTempIdFichaPaciente: PropTypes.func,
  tempIdFichaPersonal: PropTypes.number,
  tempPatient: PropTypes.object,
  setTempPatient: PropTypes.func,
  tempPatientCompare: PropTypes.object,
  variableCounter: PropTypes.object,
  setVariableCounter: PropTypes.func,
  vetoesInfo: PropTypes.array,
  setVetoesInfo: PropTypes.func,
  tempVetoe: PropTypes.object,
  setMessage: PropTypes.func,
  setOpenDialogEdit: PropTypes.func,
  setPageRefresh: PropTypes.func,
  setPatients: PropTypes.func
};
