import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    ButtonTextTemplate as Button,
    ButtonTextMUI as ButtonMUI
} from "utils/componentsStyle";
import {
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    MenuItem,
    InputLabel,
    FormControl,
    Select,

} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import {
    CheckCircle,
    RadioButtonUnchecked,

    Close,
} from "@material-ui/icons/";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import DetailNumber2 from "views/Widgets/FichaPaciente/detailNumber2";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {dictMonth} from "utils/validationFunction";
import ExpansionPanelCommon from "views/Widgets/Common/ExpansionPanelCommon";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function DefineVisit({
    open,
    handleClose,
    scheduleVisit,
    dictReservation,
    listType,
    listAbsence,
    handleSaveReservation,
    handleSaveAbsence,
    dictProfessionalStatistics,
    showTabs,
}) {

    const listValueAbsence = listAbsence.reduce((acc, item) => {return [...acc, item.value]}, []);

    const classes = useStyles();
    const [type, settype] = useState(listType[0])
    const [data, setdata] = useState(scheduleVisit[0]);
    const [start, setstart] = useState({});
    const [startDate, setstartDate] = useState(moment());
    const [endDate, setendDate] = useState(moment().add(1, "days"));
    const [end, setend] = useState({});
    const [listHour, setlistHour] = useState([]);
    const [worker, setworker] = useState(scheduleVisit[1]);
    const [changeHour, setchangeHour] = useState(false);
    const [checkPatient, setcheckPatient] = useState(false);
    const [extraLunch, setextraLunch] = useState(false);
    const [shiftMobilization, setshiftMobilization] = useState(false);
    const [specialViatic, setspecialViatic] = useState(false);
    const [extraTurnValue, setextraTurnValue] = useState("0");
    const [extraLunchAmount, setextraLunchAmount] = useState("0");
    const [shiftMobilizationAmount, setshiftMobilizationAmount] = useState("0");
    const [specialViaticAmount, setspecialViaticAmount] = useState("0");
    const [value, setvalue] = useState("0");
    const [comment, setcomment] = useState("");
    const [messageWarning, setmessageWarning] = useState("");
    const [changeButtons, setchangeButtons] = useState(true);
    const [expandedPanel, setexpandedPanel] = useState("");
    const [selectFrequency, setselectFrequency] = useState({});
    const [selectDays, setselectDays] = useState([0, 1, 2, 3, 4, 5, 6]);
    const [numberDays, setnumberDays] = useState(1);
    const [numberDayMonth, setnumberDayMonth] = useState(1);
    const [numberVisit, setnumberVisit] = useState(0);
    const [consecutiveDays, setconsecutiveDays] = useState(1);
    const [pauseDays, setpauseDays] = useState(0);
    const [updateDateStart, setupdateDateStart] = useState(moment(data.value));
    const [updateDateEnd, setupdateDateEnd] = useState(moment(data.value));
    const [lastDayWork, setlastDayWork] = useState(moment(data.value));
    const [dayReinstatement, setdayReinstatement] = useState(moment(data.value));
    const [licence, setlicence] = useState(null);
    const [admonition, setadmonition] = useState(null);
    const [discount, setdiscount] = useState(null);
    const listFrequency = [
        {value: "diario", label: "Diario"},
        {value: "semanal", label: "Semanal"},
        {value: "mensual", label: "Mensual"},
        {value: "otra", label: "Otra"}
    ];
    const days = [
        {
            label: "L",
            value: 1,
        },
        {
            label: "M",
            value: 2,
        },
        {
            label: "M",
            value: 3,
        },
        {
            label: "J",
            value: 4,
        },
        {
            label: "V",
            value: 5,
        },
        {
            label: "S",
            value: 6,
        },
        {
            label: "D",
            value: 0,
        },
    ];



    useEffect(() => {
        if (changeHour){
            getTimeList();
        }
        if (checkPatient){
            getWarningMessage();
        }
        if (changeButtons){
            setchangeButtons(false);
        }
    });

    useEffect(() => {
        setstartDate(data.value)
        setendDate(moment(data.value).add(1, "days"));
        setstart({value: "00:00", label: "00:00"});
        setend({value: "12:00", label: "12:00"});
        setnumberVisit(dictProfessionalStatistics[worker.id]?.real || 0);
        setchangeHour(true);
        setcheckPatient(true);
    }, []);

    useEffect(() => {
        setdiscount(false);
        setadmonition(false);
        setlicence(false);
        setupdateDateStart(moment(data.value));
        setupdateDateEnd(moment(data.value));
        setlastDayWork(moment(data.value));
        setdayReinstatement(moment(data.value));
    }, [type]);

    const getWarningMessage = () => {
        const dateEvent = moment(data.value).format("YYYY-MM-DD");
        if (dictReservation[dateEvent]){
            if (dictReservation[dateEvent][worker.id]){
                const tempArray = dictReservation[dateEvent][worker.id].filter((row) => row.id !== data.id);
                if (tempArray.length > 0){
                    setmessageWarning(`Ya tiene asignado un turno en este día`);
                } else {
                    setmessageWarning("");
                }
            } else {
                setmessageWarning("");
            }
        } else {
            setmessageWarning("");
        }
    };

    const getTimeList = () => {
        setchangeHour(false);
        const tempListHour = [];

        let tempDate =  moment(data.value).format("YYYY-MM-DD");
        let countDate = moment(tempDate);
        let tempDateEnd = moment(tempDate).add(1, "days");

        while (moment(tempDateEnd).diff(countDate, "minutes") >= 0){
            const tempHour = moment(countDate).format("HH:mm");
            tempListHour.push({value: tempHour, label: tempHour});
            countDate = moment(countDate).add(30, "minutes");
        }
        setlistHour(tempListHour);
    };

    const handleSelectHour= (value, argDate, set) => {
        const tempDate =  moment(data.value).format("YYYY-MM-DD");
        let auxDate = moment(`${tempDate} ${value.value}:0`);
        let compareDate = null;
        let auxValue = value
        if (argDate === "start"){
            if (type.value === "D" ||  type.value === "N"){
                const tempEndDate = moment(auxDate).add(12, "hours").format("HH:mm");
                auxValue = auxDate;
                setend({value: tempEndDate, label: tempEndDate});
            } else if (type.value === "T"){
                const tempEndDate = moment(auxDate).add(24, "hours").format("HH:mm");
                auxValue = auxDate;
                setend({value: tempEndDate, label: tempEndDate});
            } else {
                compareDate = moment(`${tempDate} ${end.value}:0`);
                auxValue = moment(compareDate).diff(auxDate, "minutes") > 0 ?
                    auxDate : moment(compareDate).subtract(30, "minutes");
            }

        } else {
            compareDate = moment(`${tempDate} ${start.value}:0`);
            auxValue = moment(auxDate).diff(compareDate, "minutes") > 0 ?
                auxDate : moment(compareDate).add(30, "minutes");
        }
        const valueFormat = moment(auxValue).format("HH:mm");
        set({value:valueFormat, label: valueFormat});
    };

    const handleType = (value) => {
        settype(value);
        if (value.value === "D" ||  value.value === "N"){
            const tempEndDate = moment(`${moment(data.value).format("YYYY-MM-DD")} ${start.value}:00`).add(12, "hours").format("HH:mm");
            setend({value: tempEndDate, label: tempEndDate});
        } else if (value.value === "T"){
            const tempEndDate = moment(`${moment(data.value).format("YYYY-MM-DD")} ${start.value}:00`).add(24, "hours").format("HH:mm");
            setend({value: tempEndDate, label: tempEndDate});
        }
        if (!["EXD", "EXN", "EXT"].includes(type.value)){
        setextraTurnValue("0")
        }
    };

    const handleAddItems = (value, set, setvalue) => {
        set(!value);
    };

    const handleOnChange = (value, set) => {
        let tempValue =  isNaN(value) ? 0 : value;
        set(tempValue);
    };

    const handleSelectDay = (button) => {
        let tempArray = selectDays;
        if (selectDays.includes(button)) {
          tempArray = selectDays.filter((row) => row != button);
        } else {
          tempArray.push(button);
        }
        setselectDays(tempArray)
        setchangeButtons(true);
    };

    const handleFrequency = (value) => {
        setselectFrequency(value)
        if (value.value === "diario"){
            setendDate(moment(startDate).add(1, "days"));
        } else if (value.value === "semanal"){
            setendDate(moment().add(1, "weeks"));
        } else if (value.value === "mensual"){
            setendDate(moment(startDate).add(1, "months"));
        }
    };

    const handleSelectProcess = () => {
        if (listValueAbsence.includes(type.value)){
            handleSaveAbsence(updateDateStart, updateDateEnd, lastDayWork, dayReinstatement, type, licence, admonition, discount);
        } else {
            handleCaculateRows();
        }
    };

    const handleCaculateRows = () => {
        let tempDateArray = [];
        if (expandedPanel != ""){
            const tempEndDate = moment(endDate);
            let dateCondition = moment(startDate)
            if (selectFrequency.value === "diario"){
                let countDay = 0;
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    if (countDay% parseInt(numberDays) === 0){
                        tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    }
                    countDay += 1;
                    dateCondition = dateCondition.add(1, "days");
                }
            } else if (selectFrequency.value === "semanal"){
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    if (selectDays.includes(dateCondition.day())){
                        tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    }
                    dateCondition = dateCondition.add(1, "days");
                }
            } else if (selectFrequency.value === "mensual"){
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    if (parseInt(moment(dateCondition).format("DD")) === parseInt(numberDayMonth)){
                        tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    }
                    dateCondition = dateCondition.add(1, "days");
                }
            } else if (selectFrequency.value === "otra"){
                let checkConsecutiveDay = 0;
                while (tempEndDate.diff(moment(dateCondition), "hours") >= 0){
                    tempDateArray.push(moment(dateCondition).format("YYYY-MM-DD"));
                    checkConsecutiveDay += 1;
                    dateCondition = dateCondition.add(1, "days");
                    if (checkConsecutiveDay >= parseInt(consecutiveDays)){
                        checkConsecutiveDay = 0
                        dateCondition = dateCondition.add(pauseDays, "days");
                    }
                }
            } else {
                tempDateArray.push(moment(data.value).format("YYYY-MM-DD"));
            }
        } else {
            tempDateArray.push(moment(data.value).format("YYYY-MM-DD"));
        }
        handleSaveReservation(
            type,
            start,
            end,
            extraLunchAmount,
            shiftMobilizationAmount,
            specialViaticAmount,
            extraTurnValue,
            comment,
            value,
            tempDateArray,
        )
    };

    const _expandContent_ = <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        item
    >
        <DetailSelect
            label="Frecuencia:"
            value={selectFrequency}
            onchange={(value) => handleFrequency(value)}
            list={listFrequency}
        />
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha Inicio"}
                placeholder="Fecha Inicio"
                onChange={setstartDate}
                maxDate={moment(endDate)}
                value={startDate}
                inputVariant={"outlined"}
            />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label={"Fecha Término"}
                placeholder="Fecha Término"
                minDate={moment(startDate)}
                onChange={setendDate}
                value={endDate}
                inputVariant={"outlined"}
            />
        </MuiPickersUtilsProvider>
        {selectFrequency.value === "diario" &&
        <DetailNumber2
            label={"Cada cuantos días"}
            value={numberDays}
            onchange={e => {
            if (/^[0-9\b]+$/.test(e.target.value)) {
                setnumberDays(e.target.value);
            }
            }}
        />
        }
        {selectFrequency.value === "semanal" &&
        <Grid item xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            {days.map((row, index) => (
                <Button
                    key={`${index}-daybuttons`}
                    justIcon
                    round
                    size="sm"
                    style={{
                        background: selectDays.includes(row.value) ?  indigo[500] :  indigo[50],
                        color: selectDays.includes(row.value) ?  indigo[50] :  indigo[500],
                    }}
                    onClick={() => handleSelectDay(row.value)}
                >
                    {row.label}
                </Button>
            ))}
            {selectDays.length === 0 &&
                <Typography variant="caption" style={{color: red[500]}} gutterBottom>
                    A lo menos 1 día seleccionado
                </Typography>
            }
        </Grid>
        }
        {selectFrequency.value === "mensual" &&
        <DetailNumber2
            label={"Dia de cada mes"}
            value={numberDayMonth}
            max={"31"}
            onchange={e => {
            if (/^[0-9\b]+$/.test(e.target.value)) {
                setnumberDayMonth(e.target.value);
            }
            }}
        />
        }
        {selectFrequency.value === "otra" &&
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0"}}
        >
            <Grid item xs={5} style={{margin: "10px 0"}} >
                <DetailNumber2
                    label={"Días seguidos"}
                    min={"1"}
                    value={consecutiveDays}
                    onchange={e => {
                        if (/^[0-9\b]+$/.test(e.target.value)) {
                            setconsecutiveDays(e.target.value);
                        }
                    }}
                />
            </Grid>
            <Grid  item xs={2} style={{margin: "10px 0"}} >
                <ButtonMUI justIcon round color="primary">
                    <Close className={classes.icons} />
                </ButtonMUI>
            </Grid>
            <Grid item xs={5} style={{margin: "10px 0"}}
            >
                <DetailNumber2
                    label={"Días de descanso"}
                    min={"1"}
                    value={pauseDays}
                    onchange={e => {
                        if (/^[0-9\b]+$/.test(e.target.value)) {
                            setpauseDays(e.target.value);
                        }
                    }}
                />
            </Grid>
        </Grid>
        }
    </Grid>

    const _content_ = <div>
        {changeButtons === false &&
            <div></div>
        }
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    item
                    xs={12}
                    style={{margin: "10px 0"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <Typography variant="body2" gutterBottom>
                            {`TENS: ${worker.first_name} ${worker.last_name}`}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <Typography variant="body2" gutterBottom>
                            {`Tipo contrato: ${worker.id_ficha.tipo_contrato ? worker.id_ficha.tipo_contrato : "Sin asignar" }`}
                        </Typography>
                    </Grid>
                    {!listValueAbsence.includes(type.value) &&
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <Typography variant="body2" gutterBottom>
                            {`Fecha: ${moment(data.value).format("DD")} ${dictMonth[parseInt(moment(data.value).format("MM"))]} de ${parseInt(moment(data.value).format("YYYY"))}`}
                        </Typography>
                    </Grid>
                    }
                </Grid>
                {!listValueAbsence.includes(type.value) &&
                <Typography variant="body2" gutterBottom>
                    {`Hasta ahora ha trabajado ${numberVisit} horas en el mes`}
                </Typography>
                }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <DetailSelect
                    label="Tipo de asignación:"
                    value={type}
                    onchange={(value) => handleType(value)}
                    list={[...listType, ...listAbsence]}
                />
                {listValueAbsence.includes(type.value) ?
                <>
                    <Grid item container xs={12} justify="center">
                        <Typography
                            variant="body2"
                            gutterBottom
                            style={{ textTransform: "none" }}
                        >
                            {`Días: ${moment(updateDateEnd).diff(moment(updateDateStart), "days") + 1}`}
                        </Typography>
                    </Grid>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha de inicio"}
                            placeholder="Fecha de inicio"
                            onChange={e => setupdateDateStart(e)}
                            minDate={moment(data.value)}
                            value={updateDateStart}
                            inputVariant={"outlined"}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha de término"}
                            placeholder="Fecha de término"
                            onChange={e => setupdateDateEnd(e)}
                            minDate={moment(updateDateStart)}
                            value={updateDateEnd}
                            inputVariant={"outlined"}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha último día de trabajo"}
                            placeholder="Fecha último día de trabajo"
                            onChange={e => setlastDayWork(e)}
                            maxDate={moment(updateDateStart)}
                            value={lastDayWork}
                            inputVariant={"outlined"}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha de reincorporación"}
                            placeholder="Fecha de reincorporación"
                            onChange={e => setdayReinstatement(e)}
                            minDate={moment(updateDateEnd)}
                            value={dayReinstatement}
                            inputVariant={"outlined"}
                        />
                    </MuiPickersUtilsProvider>
                    { type.value === "inasistencias" &&
                    <>
                    <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <FormControl
                            variant="outlined"
                            style={{minWidth: "230px"}}
                            error={licence === "" ? true : false}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                            Licencia
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={licence}
                            onChange={(event) =>  setlicence(event.target.value)}
                            label="Licencia"
                            >
                            <MenuItem value={"si"}>Si</MenuItem>
                            <MenuItem value={"no"}>No</MenuItem>
                            </Select>
                            <FormHelperText>
                            {licence === "" ? (
                                <span className="text-danger">Debe ingresar licencia</span>
                            ) : null}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <FormControl
                            variant="outlined"
                            style={{minWidth: "230px"}}
                            error={admonition === "" ? true : false}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                            Amonestación
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={admonition}
                            onChange={(event) => setadmonition(event.target.value)}
                            label="Amonestación"
                            >
                            <MenuItem value={"si"}>Si</MenuItem>
                            <MenuItem value={"no"}>No</MenuItem>
                            </Select>
                            <FormHelperText>
                            {admonition === "" ? (
                                <span className="text-danger">Debe ingresar amonestación</span>
                            ) : null}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <FormControl
                            variant="outlined"
                            style={{minWidth: "230px"}}
                            error={discount === "" ? true : false}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                            Descuento
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={discount}
                            onChange={(event) => setdiscount(event.target.value)}
                            label="Descuento"
                            >
                            <MenuItem value={"si"}>Si</MenuItem>
                            <MenuItem value={"no"}>No</MenuItem>
                            </Select>
                            <FormHelperText>
                            {discount === "" ? (
                                <span className="text-danger">Debe ingresar descuento</span>
                            ) : null}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    </>
                }
                </>:
                <>
                <DetailSelect
                    label="Horario inicio:"
                    value={start}
                    onchange={(value) => handleSelectHour(value, "start", setstart)}
                    list={listHour.slice(0, listHour.length - 1)}
                />
                <DetailSelect
                    isDisabled={["D", "N", "EXD", "EXN", "TRN", "TRD", "T", "EXT", "TR24"].includes(type.value)}
                    label="Horario fin:"
                    value={end}
                    onchange={(value) => handleSelectHour(value, "end", setend)}
                    list={listHour.slice(1, listHour.length)}
                />
                {showTabs === "Traslado" &&
                <DetailNumber
                    label={`Valor`}
                    value={value}
                    onchange={e => handleOnChange(
                        parseInt(e.target.value.split('$').join('').split(',').join('')),
                        setvalue)
                    }
                    prefix={"$"}
                    thousandSeparator={true}
                />
                }
                </>
                }

                {messageWarning != "" &&
                <SnackbarContent
                    message={messageWarning}
                    color="danger"
                />
                }
            </Grid>

            <DetailNumber
                label={["EXD", "EXN", "EXT"].includes(type.value) ? `Valor liquido turno extra`: `Se realizo alguna hora extra? (ingrese monto liquido)` }
                value={extraTurnValue}
                onchange={e => handleOnChange(
                    parseInt(e.target.value.split('$').join('').split(',').join('')),
                    setextraTurnValue)
                }
                prefix={"$"}
                thousandSeparator={true}
            /> 
            {!listValueAbsence.includes(type.value) &&
            <>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Typography variant="body2" gutterBottom>
                {`¿Cuál de los siguientes items desea agregar?`}
                </Typography>

            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleAddItems(extraLunch, setextraLunch, setextraLunchAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Colación extra"
                />
                { extraLunch &&
                    <DetailNumber
                        label={`Valor colación extra`}
                        value={extraLunchAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setextraLunchAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleAddItems(shiftMobilization, setshiftMobilization, setshiftMobilizationAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Movilizacíon extra"
                />
                { shiftMobilization &&
                    <DetailNumber
                        label={`Valor movilizacíon extra`}
                        value={shiftMobilizationAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setshiftMobilizationAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleAddItems(specialViatic, setspecialViatic, setspecialViaticAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Viatico especial"
                />
                { specialViatic &&
                    <DetailNumber
                        label={`Valor viatico especial`}
                        value={specialViaticAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setspecialViaticAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}

                    />
                }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <DetailText
                    label={`Comentario`}
                    placeholder={``}
                    value={comment}
                    validation={false}
                    onchange={(e) => { setcomment(e.target.value)}}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 0 0"}}
            >
                <ExpansionPanelCommon
                    title= "Definir recurrencia"
                    content={_expandContent_}
                    handleChange={(expand) => setexpandedPanel(expand)}
                />
            </Grid>
            </>}
        </Grid>
    </div>

    const _actions_ = <>
        <ButtonMUI
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: green[500], marginRight: "3px"}}
            onClick={handleSelectProcess}
        >
            Guardar
        </ButtonMUI>
        <ButtonMUI
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500], marginRight: "10px", marginLeft: "3px"}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </ButtonMUI>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Asignar TENS`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xs"
        />
    );
}