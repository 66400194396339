import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import {Add, Edit, KeyboardArrowUp} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";
import api from "utils/API";
import clsx from "clsx";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(2.5),
    textTransform: "none",
    minHeight: "3rem",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    color: "#fff",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);


const UpdateStockButton = ({updateStock, title="Actualizar stock"}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [lastUpdate, setLastUpdate] = useState(moment());

  // useEffect(() => {
  //     initialChargeReception();
  // }, []);

  // const initialChargeReception = () => {
  //   api.get("/api/plataforma/stockmasivo/").then((response) => {
  //     setLastUpdate(response.data.valor);
  //   });
  // };

  const value = 0;
  const fabs = [
    {
      color: "primary",
      className: classes.fab,
      icon: <Add />,
      label: "Add",
    },
    {
      color: "secondary",
      className: classes.fab,
      icon: <Edit />,
      label: "Edit",
    },
    {
      color: "inherit",
      className: clsx(classes.fab, classes.fabGreen),
      icon: <KeyboardArrowUp />,
      label: "Expand",
    },
  ];

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return(
    fabs.map((fab, index) => (
      <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              value === index ? transitionDuration.exit : 0
            }ms`,
            backgroundColor: "#ff6f00",
          }}
          unmountOnExit
        >
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">
                  Última actualización:
                  {moment(lastUpdate).format("YYYY-MM-DD HH:mm")}
                </Typography>
              </React.Fragment>
            }
          >
            <Fab
              variant="extended"
              aria-label={fab.label}
              className={fab.className}
              color={fab.color}
              onClick={updateStock}
            >
              {title}
            </Fab>
          </HtmlTooltip>
        </Zoom>
    ))
  )
}

export default UpdateStockButton;