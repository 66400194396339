import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Container from "@material-ui/core/Container";
import TableHead from "@material-ui/core/TableHead";
import Link from "@material-ui/core/Link";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from "material-table";
import CardBody from "components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Box from "@material-ui/core/Box";
import AlertDialog from "components/Attendance/AlertDialog";
import MomentUtils from "@date-io/moment";
import api from "utils/API";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import moment from "moment-timezone";
import "moment/locale/es";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  container: {
    minHeight: 100,
    paddingTop: "50px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const equipSorting = (dataFrame, listRequest) => {
  let newDataFrame = [];
  listRequest.forEach(item => {
    let filteredListPrority1 = dataFrame.filter(equipObject =>equipObject.equipo === item.equipo && equipObject.stock==='Sí' && equipObject.estado==='Operativo')
    let filteredListPrority2 = dataFrame.filter(equipObject =>equipObject.equipo === item.equipo && equipObject.stock==='Sí' &&( equipObject.estado==='No operativo' || equipObject.estado==='No Declarado'))
    let filteredListPrority3 = dataFrame.filter(equipObject =>equipObject.equipo === item.equipo && equipObject.stock==='No' && equipObject.estado==='Operativo')
    let filteredListPrority4 = dataFrame.filter(equipObject =>equipObject.equipo === item.equipo && equipObject.stock==='No' && ( equipObject.estado==='No operativo' || equipObject.estado==='No Declarado'))

    newDataFrame = [
      ...newDataFrame,
      ...filteredListPrority1,
      ...filteredListPrority2,
      ...filteredListPrority3,
      ...filteredListPrority4
    ];
  });
  dataFrame.forEach(dataFrameElement => {
    let indexFinded = newDataFrame.findIndex(
      newDataFrameElement => dataFrameElement.sku === newDataFrameElement.sku
    );
    if (indexFinded === -1) {
      newDataFrame.push(dataFrameElement);
    }
  });
  return newDataFrame;
};

const OrderEquipDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [change, setChange] = React.useState(false);
  const [abrirAlerta, setAbrirAlerta] = React.useState(false);
  const [titulo, setTitulo] = React.useState("¡Atencion!");
  const [mensaje, setMensaje] = React.useState(
    "Antes debe seleccionar una fecha de inicio para el equipo"
  );
  const [tipoAlerta, setTipoAlerta] = React.useState("warning");
  const [demandaDataFrame, setDemandaDataFrame] = React.useState([]);
  const [dataFrame, setDataFrame] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (change) {
      setChange(false);
    }
  });

  React.useEffect(() => {
    getRequestEquipments();
  }, []);


  const goBack = () => {
    window.history.back();
  };

  const getRequestEquipments = () => {
    setLoading(true)
    api.get(`api/resumen/solicitud/equipos/${id}`).then(response => {
      let previewArrayDemanda = response.data.data.map(item =>
        Object.assign(item, { disponibilidad: 1 })
      );
      setDemandaDataFrame(previewArrayDemanda);
      const tempdict = previewArrayDemanda.reduce((accum, obj) => ({
        ...accum,
        [obj.id_equipo]: obj,
      }),{})
      api.get(`/api/bodega/equipos/${id}/`).then(response => {
        let previewArray = response.data;
        let newDataFrame = previewArray.map(row => {
          let objectFinded = tempdict[row.id_equipo];
          let fechaInicio = objectFinded?.fecha_inicio
            ? objectFinded?.fecha_inicio
            : "";
          let fechaFin = objectFinded?.fecha_fin ? objectFinded?.fecha_fin : "";
          return {
            checked: false,
            stock: row.bodega === true ? "Sí" : "No",
            id_equipo: row.id_equipo,
            id_bodega: row.id_bodega,
            estado: row.estado,
            equipo: row.equipo,
            sku: row.sku || objectFinded.sku,
            marca: row.marca,
            modelo: row.modelo,
            numero_serie: row.numero,
            disponibilidad: 1,
            fecha_fin:
              fechaFin !== ""
                ? moment(fechaFin, "YYYY-MM-DD").format("DD-MM-YYYY")
                : "",
            fecha_inicio: fechaInicio,
            sku_especifico: objectFinded?.sku_especifico || " ",
            detalle_especifico: objectFinded?.detalle_especifico || " ",
          };
        });

        let sortedDataFrame = equipSorting(newDataFrame, previewArrayDemanda);
        setDataFrame(sortedDataFrame);
        setLoading(false);
      });
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCerrarAlerta = () => {
    setAbrirAlerta(false);
  };


  const handleAsignRequest = () => {

    var body_productos_pedidos = demandaDataFrame.map(equip => ({
      cantidad: 1,
      id_equipo_id: equip.id_equipo,
      id_equipo_bodega_id: equip.id_bodega,
      fecha_inicio: equip?.fecha_inicio !== "" ? moment(equip?.fecha_inicio, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
      fecha_fin: equip?.fecha_fin !== "" ? moment(equip?.fecha_fin, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
      tipo: "Solicitar",
      detalle_especifico: equip?.detalle_especifico || " ",
      sku_especifico: equip?.sku_especifico || " ",
    }));

    var body_solicitud_tarea_paciente = { id_solicitud_paciente: id };
    let bodyes_list = {};
    bodyes_list["solicitud_tarea_paciente"] = body_solicitud_tarea_paciente;
    bodyes_list["productos_pedidos"] = body_productos_pedidos;


    api.post(`api/asignar/solicitud/equipos/`, bodyes_list).then(response => {
       
        setTitulo("!Exito¡");
        setTipoAlerta("success");
        setMensaje("Se ha guardado con exito los equipos para asignar");
        setAbrirAlerta(true);
        window.history.go(-2);
      }).catch(() => {
        setTitulo("!Atencion");
        setTipoAlerta("warning");
        setMensaje("ha ocurrido un error al asignar los equipos");
        setAbrirAlerta(true);
        // window.history.go(-2);
      });
  };

  const handleSetChange = (row, operation) => {
    let tempdemandaDataFrame = demandaDataFrame;
    let tempDataFrame = dataFrame;

    if (operation === false) {
      let tempDemandaEquipoIndex = tempdemandaDataFrame.findIndex(item => item.equipo === row.equipo);
      tempDataFrame.filter(item => 
        item.equipo == tempdemandaDataFrame[tempDemandaEquipoIndex].equipo
      ).forEach(someobject =>
        (someobject.disponibilidad = parseInt(someobject.disponibilidad) + 1)
      );

      tempdemandaDataFrame[tempDemandaEquipoIndex] = {
        bodega: "",
        disponibilidad: "",
        equipo: tempdemandaDataFrame[tempDemandaEquipoIndex].equipo,
        estado: "",
        fecha_fin: "",
        fecha_inicio: "",
        id_bodega: tempdemandaDataFrame[tempDemandaEquipoIndex].id_bodega,
        id_equipo: tempdemandaDataFrame[tempDemandaEquipoIndex].id_equipo,
        marca: "",
        modelo: "",
        sku: "",
        sku_especifico: tempdemandaDataFrame[tempDemandaEquipoIndex].sku_especifico || " ",
        detalle_especifico: tempdemandaDataFrame[tempDemandaEquipoIndex].detalle_especifico || " ",
      };
    } else {
      let tempDemandaEquipoIndex = tempdemandaDataFrame.findIndex(
        item =>
          item.equipo === row.equipo && item.modelo === "" && item.marca === ""
      );
      tempDataFrame
        .filter(
          item =>
            item.equipo == tempdemandaDataFrame[tempDemandaEquipoIndex].equipo
        )
        .forEach(
          someobject =>
            (someobject.disponibilidad =
              parseInt(someobject.disponibilidad) - 1)
        );
      tempdemandaDataFrame[tempDemandaEquipoIndex] = {
        bodega: row.stock === "Sí" ? true : false,
        disponibilidad: row.disponibilidad,
        equipo: tempdemandaDataFrame[tempDemandaEquipoIndex].equipo,
        estado: row.estado,
        fecha_fin: row.fecha_fin,
        fecha_inicio: row.fecha_inicio,
        id_bodega: row.id_bodega,
        id_equipo: row.id_equipo,
        marca: row.marca,
        modelo: row.modelo,
        sku: row.sku,
        sku_especifico: row.sku_especifico || " ",
        detalle_especifico: row.detalle_especifico || " ",
      };
    }
    setDemandaDataFrame(tempdemandaDataFrame);
    setDataFrame(tempDataFrame);
    setChange(true);
  };

  const handleSetChangeFechaFin = (date, row) => {
    let tempdataFrame = dataFrame;
    let tempEquipoIndex = dataFrame.findIndex(item => item.sku === row.sku);

    if (tempEquipoIndex !== -1) {
      tempdataFrame[tempEquipoIndex].fecha_fin = date;
    }
    setDataFrame(tempdataFrame)
    setChange(true)
  };

  const handleSetChangeFechaInicio = (date, row) => {
    let tempdataFrame = dataFrame;
    let tempEquipoIndex = dataFrame.findIndex(item => item.sku === row.sku);

    if (tempEquipoIndex !== -1) {
      tempdataFrame[tempEquipoIndex].fecha_inicio = date;
    }
    setDataFrame(tempdataFrame)
    setChange(true)
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <CardBody className="rounded shadow border border-primary">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Operaciones</Typography>
          <Link color="inherit" href={`/admin/warehouse/task/storage`}>
            Bodega
          </Link>
          <Link color="inherit" href={`/admin/warehouse/orders/storage`}>
            Pedidos
          </Link>
          <Link color="inherit" href="#" onClick={goBack}>
            Detalle del pedido
          </Link>
          <Typography color="textPrimary">Equipos solicitados</Typography>
        </Breadcrumbs>
      </CardBody>
      <Paper elevation={0} style={{ padding: "20px" }}>
        <h3>Resumen demanda y stock</h3>
        <Paper className={classes.root}>
          {!change && <div></div>}
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Equipo</TableCell>
                  <TableCell align="left">Modelo</TableCell>
                  <TableCell align="left">Marca</TableCell>
                  <TableCell align="left">SKU</TableCell>
                  <TableCell align="left">Especifico</TableCell>
                  <TableCell align="center">Stock</TableCell>
                  <TableCell align="center">Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!change &&
                  demandaDataFrame.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{row.equipo}</TableCell>
                      <TableCell align="left">{row.modelo}</TableCell>
                      <TableCell align="left">{row.marca}</TableCell>
                      <TableCell align="left">{row.sku}</TableCell>
                      <TableCell align="left">{row.sku_especifico !== " " ? `(${row.sku_especifico}) - ${row.detalle_especifico}` : ""}</TableCell>
                      <TableCell align="center">
                        {row.bodega !== ""
                          ? row.bodega === true
                            ? "Sí"
                            : "No"
                          : "-"}
                      </TableCell>
                      <TableCell align="center">{ row.estado !== "" ? row.estado : row.requiere_inventario ? "" : "No requiere inventario"}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {loading && (
            <Box p={2} display="flex" justifyContent="space-around">
              <LoadingSpinner />
            </Box>
          )}
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={demandaDataFrame.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          /> */}
        </Paper>
        <h3>Detalle: Seleccione los equipos a asignar</h3>
        <Paper className={classes.root}>
          {!change && <div></div>}
          <MaterialTable
            localization={{
              pagination: {
                labelRowsSelect: "Filas",
                labelDisplayedRows: "{count} de {from}-{to}",
                firstTooltip: "Primera página",
                previousTooltip: "Página anterior",
                nextTooltip: "Próxima página",
                lastTooltip: "Última página"
              },
              toolbar: {
                nRowsSelected: "{0} Equípo(s) seleccionados",
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
                showColumnsTitle: "Mostrar Columnas"
              },

              header: {
                actions: "Acciones"
              },
              body: {
                emptyDataSourceMessage: loading ? (
                  <Box p={2} display="flex" justifyContent="space-around">
                    <LoadingSpinner />
                  </Box>
                ) : (
                  "No hay datos para mostrar"
                ),
                filterRow: {
                  filterTooltip: "Filtrar"
                }
              }
            }}
            columns={[
              {
                title: "Seleccionar",
                field: "checked",
                filtering: false,
                // eslint-disable-next-line react/display-name
                render: row => {
                  return (
                    <Checkbox
                      inputProps={{ "aria-labelledby": "hola" }}
                      disabled={
                        row.stock === "No" ||
                        row.estado === "No operativo" ||
                        (row.disponibilidad === 0 && row.checked === false)
                          ? true
                          : false
                      }
                      checked={row.checked}
                      onClick={() => {
                        if (moment(row?.fecha_inicio, "DD-MM-YYYY").isValid()) {
                          row.checked = !row.checked;
                          handleSetChange(row, row.checked);
                        } else {
                          setMensaje(
                            "Antes debe seleccionar una fecha de inicio para el equipo"
                          );
                          setTitulo("!Atencion¡");
                          setTipoAlerta("warning");
                          setAbrirAlerta(true);
                        }
                      }}
                    />
                  );
                }
              },
              { title: "Stock Bodega", field: "stock" },
              { title: "Equípo", field: "equipo" },
              { title: "Modelo", field: "modelo" },
              { title: "SKU", field: "sku" },
              { title: "Marca", field: "marca" },
              { title: "N° Inventario", field: "nInventario" },
              {
                title: "Fecha Inicio",
                field: "fecha_inicio",
                // eslint-disable-next-line react/display-name
                render: row => {
                  return (
                    <Box style={{ minWidth: "162px" }}>
                      <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <KeyboardDatePicker
                          label="Fecha Inicio"
                          key={row.sku + "inicio"}
                          format="DD-MM-YYYY"
                          variant="inline"
                          inputVariant="outlined"
                          InputAdornmentProps={{ position: "end" }}
                          InputProps={{ readOnly: true }}
                          value={
                            moment(row?.fecha_inicio, "DD-MM-YYYY").isValid()
                              ? moment(row?.fecha_inicio, "DD-MM-YYYY")
                              : null
                          }
                          onChange={date =>
                            handleSetChangeFechaInicio(date, row, row.value)
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                  );
                }
              },
              {
                title: "Fecha Fin",
                field: "fecha_fin",
                // eslint-disable-next-line react/display-name
                render: row => {
                  return (
                    <Box style={{ minWidth: "162px" }}>
                      <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <KeyboardDatePicker
                          label="Indefinido"
                          InputAdornmentProps={{ position: "end" }}
                          InputProps={{ readOnly: true }}
                          key={row.sku + "fin"}
                          format="DD-MM-YYYY"
                          variant="inline"
                          inputVariant="outlined"
                          value={
                            moment(row?.fecha_fin, "DD-MM-YYYY").isValid()
                              ? moment(row?.fecha_fin, "DD-MM-YYYY")
                              : null
                          }
                          onChange={date =>
                            handleSetChangeFechaFin(date, row, row.value)
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                  );
                }
              },
              {
                title: "Estado Operacional",
                field: "estado",
                cellStyle: (e, rowData) => {
                  if (rowData.estado === "No operativo") {
                    return { backgroundColor: "red", color: "white" };
                  } else {
                    return { backgroundColor: "green", color: "white" };
                  }
                }
              }
            ]}
            data={dataFrame}
            options={{
              selection: false,
              showTitle: false,
              filtering: true,
              doubleHorizontalScroll: true,
              selectionProps: rowData => ({
                disabled:
                  rowData.stock === "No" || rowData.estado === "No operativo",
                color: "primary"
              }),
              headerStyle: {
                position: "sticky",
                top: "0"
              },
              maxBodyHeight: "550px",
              actionsColumnIndex: -1
            }}
            detailPanel={[
              {
                // eslint-disable-next-line react/display-name
                icon: () => <AddIcon color="primary"></AddIcon>,
                tooltip:"Informacion extra",
                // eslint-disable-next-line react/display-name
                render: rowData => {
                  return (
                    <TableContainer component={Paper} style={{ padding: '10px 0px 10px 50px' }}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Estado cable poder</TableCell>
                            <TableCell align="left">Estado bolsa transporte</TableCell>
                            <TableCell align="left">Estado autonomia</TableCell>
                            <TableCell align="left">Estado mordaza fijacion</TableCell>
                            <TableCell align="left">Estado soporte atril</TableCell>
                            <TableCell align="left">Fecha Entrada</TableCell>
                            <TableCell align="left">Fecha Salida</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">{rowData?.extraDetail?.estadoCablePoder}</TableCell>
                            <TableCell align="left">{rowData?.extraDetail?.estadoBolsaTransporte}</TableCell>
                            <TableCell align="left">{rowData?.extraDetail?.estadoAutonomia}</TableCell>
                            <TableCell align="left">{rowData?.extraDetail?.estadoMordazaFijacion}</TableCell>
                            <TableCell align="left">{rowData?.extraDetail?.estadoSoporteAtril}</TableCell>
                            <TableCell align="left">{rowData?.extraDetail?.fechaEntrada}</TableCell>
                            <TableCell align="left">{rowData?.extraDetail?.fechaSalida}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  );
                }
              }
            ]}
          />
          <AlertDialog openDialog={abrirAlerta} handleClose={handleCerrarAlerta} message={mensaje} title={titulo} severity={tipoAlerta} />
        </Paper>
        <Container className={classes.container}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
          >
            <Grid container item xs={3} spacing={3}>
              <Button
                variant="contained"
                className="text-white"
                color="secondary"
                onClick={() => window.history.go(-2)}
              >
                Volver
              </Button>
            </Grid>
            <Grid container item xs={3} spacing={3}>
              <Button
                disabled={demandaDataFrame.find(item => item.bodega === false)}
                variant="contained"
                className="text-white"
                color="primary"
                onClick={handleAsignRequest}
              >
                Enviar asignación egresos
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </div>
  );
}
export default OrderEquipDetail;