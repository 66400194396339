const setammountValuePackage = (listObj) => {
    return {
        type: "VALOR_INSUMOS_MEDICAMENTOS_PAQUETE",
        payload: listObj
    }
};

const setammountResourcePackage = (listObj) => {
    return {
        type: "VALOR_INSUMOS_PAQUETE",
        payload: listObj
    }
};
const setammountMedicineValuePackage = (listObj) => {
    return {
        type: "VALOR_MEDICAMENTOS_PAQUETE",
        payload: listObj
    }
};

const setammountProfesionalValuePackage = (listObj) => {
    return {
        type: "VALOR_PROFESIONALES_PAQUETE",
        payload: listObj
    }
};

const setsumAmmountValuePackage = (listObj) => {
    return {
        type: "SUMAR_INSUMOS_MEDICAMENTOS_PAQUETE",
        payload: listObj
    }
};

const setlessAmmountValuePackage = (listObj) => {
    return {
        type: "RESTAR_INSUMOS_MEDICAMENTOS_PAQUETE",
        payload: listObj
    }
};

const setopenBackdrop = (listObj) => {
    return {
        type: "BLOQUEAR_PANTALLA",
        payload: listObj
    }
};

const setDialogTitleDashboard = (listObj) => {
    return {
        type: "TITULO_DIALOGO_DASHBOARD",
        payload: listObj
    }
};
const setDialogTitleNavbar = (listObj) => {
    return {
        type: "TITULO_DIALOGO_NAVBAR",
        payload: listObj
    }
};

const setDialogMessageDashboard = (listObj) => {
    return {
        type: "MENSAJE_DIALOGO_DASHBOARD",
        payload: listObj
    }
};
const setDialogMessageNavbar= (listObj) => {
    return {
        type: "MENSAJE_DIALOGO_NAVBAR",
        payload: listObj
    }
};

const setOpenDialogDashboard = (listObj) => {
    return {
        type: "ABRIR_DIALOGO_DASHBOARD",
        payload: listObj
    }
};

const setOpenDialogNavbar = (listObj) => {
    return {
        type: "ABRIR_DIALOGO_NAVBAR",
        payload: listObj
    }
};

const setOpenWelcomeDialogDashboard = (listObj) => {
    return {
        type: "ABRIR_DIALOGO_BIENVENIDA_DASHBOARD",
        payload: listObj
    }
};

const setDisabledEntryAttendance = (listObj) => {
    return {
        type: "DESHABILITAR_ASISTENCIA_ENTRADA",
        payload: listObj
    }
};

const setDisabledExitAttendance = (listObj) => {
    return {
        type: "DESHABILITAR_ASISTENCIA_SALIDA",
        payload: listObj
    }
};
const setAttendanceType = (listObj) => {
    return {
        type: "ASISTENCIA_TIPO",
        payload: listObj
    }
};
const setAttendanceUrl = (listObj) => {
    return {
        type: "ASISTENCIA_URL",
        payload: listObj
    }
};
const setOpenReminder = (listObj) => {
    return {
        type: "ABRIR_DIALOGO_RECORDATORIO",
        payload: listObj
    }
};
const setOpenDateReminder = (listObj) => {
    return {
        type: "FECHA_DIALOGO_RECORDATORIO",
        payload: listObj
    }
};

export default {
    setammountValuePackage,
    setammountResourcePackage,
    setammountMedicineValuePackage,
    setammountProfesionalValuePackage,
    setsumAmmountValuePackage,
    setlessAmmountValuePackage,
    setopenBackdrop,
    setDialogTitleDashboard,
    setDialogMessageDashboard,
    setOpenDialogDashboard,
    setOpenDialogNavbar,
    setOpenWelcomeDialogDashboard,
    setDisabledEntryAttendance,
    setDisabledExitAttendance,
    setDialogMessageNavbar,
    setDialogTitleNavbar,
    setAttendanceType,
    setAttendanceUrl,
    setOpenReminder,
    setOpenDateReminder,
}
