import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Search, ArrowDropDown, ArrowDropUp } from "@material-ui/icons/";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
  red,
  green,
  cyan,
  blue,
  yellow,
  orange,
  indigo,
  deepOrange
} from "@material-ui/core/colors";
import FilterBox from "views/Widgets/Transport/FilterBox";
import LittleChart from "components/Boss/LittleChart";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);
const classStyle = makeStyles((theme) => ({
  text: {
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    "&:hover": {
      color: indigo[400],
    },
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
}));

export default function PatientTable({
  arrayPatient,
  period,
  calendarView,
  selectPatient,
  filter,
  showTableSwich,
  handleFilter,
  handleSelectPatient,
  handlePeriod,
  handleOrder,
  handleShowTabs,
  dictPatientTransient={},
  order = null,
  direction = false,
  changePeriod = true,
  showPeriod = false,
  changeButtons = false,
  showTabs = false,
  decompensation = false,
}) {
  const classes = useStyles();
  const [initialCharge, setinitialCharge] = useState(false);
  const dictType = {
    Turno12: "Turno (12h)",
    Turno24: "Turno (24h)",
  };

  useEffect(() => {
    if (initialCharge) {
      initialData();
    }
  });

  const initialData = () => {
    setinitialCharge(false);
  };

  const getColorSelected = (row, index) => {
    if (selectPatient) {
      if (selectPatient.id === row.id) {
        if (selectPatient.proceso === "Habilitacion") {
          if (index % 2 === 0) {
            return { background: blue[200] };
          } else {
            return { background: blue[200] };
          }
        } else {
          const pair = row?.id_paquete?.descompensacion ? red[100] : yellow[100];
          const notPair = row?.id_paquete?.descompensacion ? deepOrange[100] : orange[100];
          if (index % 2 === 0) {
            return { background: pair };
          } else {
            return { background: notPair };
          }
        }
      } else {
        if (row.proceso === "Habilitacion") {
          if (index % 2 === 0) {
            return { background: cyan[50] };
          } else {
            return { background: cyan[50] };
          }
        }
      }
    }
    return {};
  };

  const headerColumn = (headerName, style = {}) => {
    return (
      <Tooltip
        title={
          <React.Fragment>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography
                variant="body2"
                gutterBottom
              >{`Ordernar por ${headerName}`}</Typography>
            </Grid>
          </React.Fragment>
        }
      >
        <TableCell
          className="text-center align-top"
          style={{ minWidth: "85px", ...style }}
          onClick={() => handleOrder(headerName)}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              item
              xs={10}
            >
              {headerName}
            </Grid>

            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              item
              xs={2}
              onClick={() => handleOrder(headerName)}
            >
              {order === headerName && (
                <ArrowDropUp
                  style={{
                    margin: "-15px 0 -15px 0",
                    fill: indigo[direction ? 900 : 200],
                  }}
                />
              )}
              <ArrowDropDown
                style={{
                  margin: order === headerName ? "0 0 -15px 0" : "0",
                  fill: indigo[order === headerName && !direction ? 900 : 200],
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
      </Tooltip>
    );
  };

  const getWorkerPatient = (row, argdecompensation = false) => {
    // agregar datos por descompensacion
    return (
      <Grid item xs={12} style={{ position: "relative" }}>
        <LittleChart
          row={
            argdecompensation
              ? row.descompensacion_actual
              : row.beneficio_actual
          }
          color={
            (argdecompensation
              ? row.descompensacion_actual
              : row.beneficio_actual) === 0
              ? red[100]
              : green[100]
          }
          max={
            argdecompensation ? row.descompensacion_total : row.beneficio_total
          }
          stackOffset="none"
          width={100}
          height={40}
        />
        <div
          style={{
            color:
              (argdecompensation
                ? row.descompensacion_actual
                : row.beneficio_actual) === 0
                ? red[900]
                : green[900],
            position: "absolute",
            margin: "-29px 0px 0px 27px",
          }}
        >
          {`${
            argdecompensation
              ? row.descompensacion_actual
              : row.beneficio_actual
          } de ${
            argdecompensation ? row.descompensacion_total : row.beneficio_total
          }`}
        </div>
      </Grid>
    );
  };

  const getTurno = (turno) => {
    if (turno === "Turno12") {
      return "Turno 12";
    } else if (turno === "Turno24") {
      return "Turno 24";
    }
  };

  return (
    <Card>
    <CardBody stats className={classes.cardFooter} style={{padding: "2rem 1rem", margin: "0"}}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
          style={{ margin: "10px 0 10x 0" }}
        >
          <h5>{`Pacientes`}</h5>
        </Grid>
        {changePeriod && (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{ margin: "10px 0" }}
          >
            <div className={classes.cardContentRight}>
              <div className={classes.buttonGroup}>
                <Button
                  mr={2}
                  variant="contained"
                  style={{
                    backgroundColor:
                      period === "Negocio" ? indigo[500] : indigo[50],
                    color: period === "Negocio" ? indigo[50] : indigo[500],
                  }}
                  onClick={() => handlePeriod("Negocio", "month")}
                >
                  Extras
                </Button>
                <Button
                  mr={2}
                  variant="contained"
                  style={{
                    backgroundColor:
                      period === "Meses" ? indigo[500] : indigo[50],
                    color: period === "Meses" ? indigo[50] : indigo[500],
                  }}
                  onClick={() => handlePeriod("Meses", "month")}
                >
                  Mensual
                </Button>
                <Button
                  mr={2}
                  variant="contained"
                  style={{
                    backgroundColor:
                      period === "Semanas" ? indigo[500] : indigo[50],
                    color: period === "Semanas" ? indigo[50] : indigo[500],
                  }}
                  onClick={() => handlePeriod("Semanas", "week")}
                >
                  Semanal
                </Button>
                <Button
                  mr={2}
                  variant="contained"
                  style={{
                    backgroundColor:
                      period === "Dias" ? indigo[500] : indigo[50],
                    color: period === "Dias" ? indigo[50] : indigo[500],
                  }}
                  onClick={() => handlePeriod("Dias", "days")}
                >
                  Diario
                </Button>
              </div>
            </div>
          </Grid>
        )}
        {showTabs && (
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            item
            xs={12}
            style={{ margin: "10px 0" }}
          >
            <div className={classes.cardContentRight}>
              <div className={classes.buttonGroup}>
                <Button
                  mr={2}
                  variant="contained"
                  style={{
                    backgroundColor:
                      showTabs === "Visitas" ? indigo[500] : indigo[50],
                    color: showTabs === "Visitas" ? indigo[50] : indigo[500],
                  }}
                  size="small"
                  onClick={() => handleShowTabs("Visitas")}
                >
                  Visitas
                </Button>
                <Button
                  mr={2}
                  variant="contained"
                  style={{
                    backgroundColor:
                      showTabs === "Traslado" ? indigo[500] : indigo[50],
                    color: showTabs === "Traslado" ? indigo[50] : indigo[500],
                  }}
                  size="small"
                  onClick={() => handleShowTabs("Traslado")}
                >
                  Traslado
                </Button>
                <Button
                  mr={2}
                  variant="contained"
                  style={{
                    backgroundColor:
                      showTabs === "Habilitacion" ? indigo[500] : indigo[50],
                    color:
                      showTabs === "Habilitacion" ? indigo[50] : indigo[500],
                  }}
                  size="small"
                  onClick={() => handleShowTabs("Habilitacion")}
                >
                  Habilitación
                </Button>
              </div>
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <FilterBox
            filterText={filter}
            handleFilter={handleFilter}
            IconSpan={Search}
            stylePaper={{ width: "100%" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ margin: "30px 0 0 0", maxHeight: "650px", overflow: "auto" }}
        >
          <TableContainer style={{ maxHeight: "650px", overflow: "auto" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headerColumn("Paciente", {})}

                  {period !== "Negocio" && headerColumn("Estado", {})}
                  {showTabs === "Visitas" ? headerColumn("Cantidad", {}) : null}

                  {showPeriod && (
                    <TableCell
                      className="text-center align-top"
                      style={{ minWidth: "85px" }}
                    >
                      Turno
                    </TableCell>
                  )}
                  {decompensation && (
                  <TableCell
                    className="text-center align-top"
                    style={{ minWidth: "85px" }}
                  >
                    {"Extra"}
                  </TableCell>
                  )}
                  <TableCell
                    className="text-center align-top"
                    style={{ minWidth: "85px" }}
                  >
                    {"Es región"}
                  </TableCell>
                  <TableCell
                    className="text-center align-top"
                    style={{ minWidth: "85px" }}
                  >
                    {"Descompensado"}
                  </TableCell>
                  <TableCell
                    className="text-center align-top"
                    style={{ minWidth: "85px" }}
                  >
                    {"Transitorio"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arrayPatient.map((row, index) =>
                  <TableRow
                    key={`${index}-workRows`}
                    onClick={() => handleSelectPatient(row)}
                    style={{
                      ...getColorSelected(row, index),
                      cursor: showTableSwich ? "pointer" : "context-menu",
                    }}
                  >
                    <TableCell className="text-center align-middle">
                      {`${row.nombre} ${row.apellido_paterno}`}
                    </TableCell>
                    {period !== "Negocio" &&
                    <TableCell className="text-center align-middle">
                      {getWorkerPatient(row)}
                    </TableCell>
                    }

                    {showTabs === "Visitas" ? (
                      <TableCell className="text-center align-middle">
                        {getTurno(
                          row?.id_prestacion?.id_prestaciones?.tipo_cantidad
                        )}
                      </TableCell>
                    ) : null}
                    {showPeriod && (
                      <TableCell className="text-center align-middle">
                        {
                          dictType[
                            row.id_prestacion.id_prestaciones.tipo_cantidad
                          ]
                        }
                      </TableCell>
                    )}
                    {decompensation && (
                      <TableCell className="text-center align-middle">
                        {getWorkerPatient(row, true)}
                      </TableCell>
                    )}
                    <TableCell className="text-center align-middle">
                      {row.direccion_region === "Región Metropolitana (de Santiago)" ? "No" : "Si"}
                    </TableCell>
                    <TableCell className="text-center align-middle">
                      {row?.id_paquete?.descompensacion ? "Si" :  "No"}
                    </TableCell>
                    <TableCell className="text-center align-middle">
                      {dictPatientTransient[row.id] ? 
                        `${dictPatientTransient[row.id]?.repeticiones}/${dictPatientTransient[row.id]?.dias_periodo}`  :  
                        row?.id_paquete?.tipo_permanencia == "Transitorio" ? row?.id_paquete?.dias_periodo :  "No"
                      }
                    </TableCell>

                  </TableRow>

                )}
                {changeButtons && <TableRow></TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </CardBody>
    </Card>
  );
}
