import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { indigo } from "@material-ui/core/colors";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableOrders = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    ordersPatients,
    getPatientInfo,
    setPatientId,
    setTempPatientIdInfo,
    setOpenPatientRegistration
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectPatient = (row) => {
    setPatientId(row.id_paciente_id);
    setTempPatientIdInfo(row.id_paciente_id);
    getPatientInfo(row.id_paciente_id);
    dispatch({ payload: row, type: 'PACIENTE_ORDEN_CLINICA' })
    setOpenPatientRegistration(true);
  }



  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container} style={{height:"400px"}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow >

              <TableCell align="center"><Grid style={{display: "flex"}}><Typography variant="subtitle2" gutterBottom style={{ textTransform: "none", color: indigo[700] }}>Nombre paciente</Typography></Grid></TableCell>
              <TableCell align="center"><Grid style={{display: "flex"}}><Typography variant="subtitle2" gutterBottom style={{ textTransform: "none", color: indigo[700] }}>Fecha Proximo Despacho</Typography></Grid></TableCell>
              <TableCell align="center"><Grid style={{display: "flex"}}><Typography variant="subtitle2" gutterBottom style={{ textTransform: "none", color: indigo[700] }}>Detalle</Typography></Grid></TableCell>

            </TableRow>
          </TableHead>
          <TableBody >
            {ordersPatients
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow
                    key={row.id}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell >
                      {`${row.id_paciente.nombre} ${row.id_paciente.apellido_paterno} ${row.id_paciente.apellido_materno}`}
                    </TableCell>
                    <TableCell >
                      {row.fecha_calculada || "Sin día agendado"}
                    </TableCell>
                    <TableCell >
                      <Button
                        aria-label="close"
                        variant={"outlined"}
                        style={{ color: indigo[500] }}
                        onClick={() => {handleSelectPatient(row)}} >
                        Ver Detalle
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={ordersPatients.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        style={{fontSize:"12px"}}
        labelRowsPerPage={<div style={{fontSize: 12}}>Filas por página</div>}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          <div style={{fontSize: 12}}> {from}-{to === -1 ? count : to} de {count !== -1 ? count : `más que ${to}`}</div>
        }
      />
    </Paper>
  );
};

export default TableOrders;

TableOrders.propTypes = {
  ordersPatients: PropTypes.array,
  getPatientInfo: PropTypes.func,
  setPatientName: PropTypes.func,
  setPatientId: PropTypes.func,
  setTempPatientIdInfo: PropTypes.func
};
