import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import Summary from "components/Transport/Summary";
import TransportDetail from "./TransportDetail";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import moment from "moment-timezone";
import api from "utils/API";
import apiHide from "utils/API_HIDE";
import { setmessageAlert } from "actions/getGlobalAction";
import "moment/locale/es";
moment.locale("es");

const Transport = (props) => {
  const dispatch = useDispatch();
  const [openDetail, setopenDetail] = useState(null);
  const [initialCharge, setinitialCharge] = useState(true);
  const [activeRow, setactiveRow] = useState([]);
  const [finishedRow, setfinishedRow] = useState([]);
  const [driverRow, setdriverRow] = useState([]);
  const [driverDict, setdriverDict] = useState({});
  const [vehicleRow, setvehicleRow] = useState([]);
  const [vehicleDict, setvehicleDict] = useState({});
  const [patientDict, setpatientDict] = useState({});
  const [packagePending, setpackagePending] = useState(0);
  const [packageToDayPending, setpackageToDayPending] = useState(0);
  const [packageWeekPending, setpackageWeekPending] = useState(0);
  const [changes, setChanges] = useState(true);


  const startDate = moment(moment().startOf('week').format("YYYY-MM-DD"));
  const endDate = moment(moment().startOf('week').add(7, 'days').format("YYYY-MM-DD"));

  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (initialCharge){
      initialData();
    }
  });

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  }, [changes]);

  useEffect(() => {
    setInterval(() => setChanges(true), 300000);
  }, []);

  const initialChargeReception = () => {
    setChanges(false);
    const dateToDate = moment().format("YYYY-MM-DD");
    api.get(`api/transporte/solicitudes/Activas`).then(response => {
        let countPending = 0;
        let countPendingToDay = 0;
        let countPendingWeek = 0;
        let newData = response.data
        const oldInprogress = activeRow.map((row) => {return row.id})
        const newInprogress = newData.filter(newItem => !oldInprogress.includes(newItem.id));
        let  message = ""
        if (oldInprogress.length == 0 && newInprogress.length > 0){
          message = `Tiene ${newInprogress.length} solicitudes que requieren su atención`;
        } else if (newInprogress.length > 0){
          const ids = newInprogress.reduce((accum, obj) => {
            const id = obj?.id_parada_traslado?.id_traslado_paciente?.id_solicitud_paciente || obj?.id_envios_pedidos?.id_solicitud_pedido || obj.id
            return `${accum}, ${id}`
          }, "")
          message = `Tiene ${newInprogress.length} solicitudes nuevas que requieren su atención (${ids})`;
        }
        newData.map((row) => {
          if (row.estado_recepcion === "NoAsignado"){
            countPending += 1;
            if (moment(row.fecha_solicitud).format("YYYY-MM-DD") === dateToDate){
              countPendingToDay += 1;
            }
            if (moment(row.fecha_solicitud) >= startDate && moment(row.fecha_solicitud) <= endDate ) {
              countPendingWeek += 1;
            }
          }
        });
        setpackagePending(countPending);
        setpackageToDayPending(countPendingToDay);
        setpackageWeekPending(countPendingWeek);
        setmessageAlert(dispatch, message, "success" )
        setactiveRow(newData);
        setloading(true)
      })
  };

  
  const initialData = () => {
    setinitialCharge(false);

    const requestFinished = api.get("api/transporte/solicitudes/Finalizadas");
    const requestDrivers = api.get("api/transporte/solicitudes/despacho/conductores/");
    const requestVehicle = api.get("api/vehiculos/");
    const requestPatient = api.get("api/paciente");
    Promise.all([
      requestFinished,
      requestDrivers,
      requestVehicle,
      requestPatient,
    ]).then((request) => {
      const tempfinishedRow = request[0].data;
      const tempDriverRow = request[1].data;
      const tempVehicleRow = request[2].data;
      setfinishedRow(tempfinishedRow);
      setdriverRow(tempDriverRow);
      setdriverDict(request[1].data.reduce((accum, obj) => ({
        ...accum,
        [obj.id]: obj
      }), {}));
      setvehicleRow(tempVehicleRow);
      setvehicleDict(request[2].data.reduce((accum, obj) => ({
        ...accum,
        [obj.id]: obj
      }), {}));
      setpatientDict(request[3].data.reduce((accum, obj) => ({
        ...accum,
        [obj.id]: obj
      }), {}));
      setloading(true);
    });
  };

  return (
    <div>
      {openDetail ?
        <TransportDetail
          setopenDetail={setopenDetail}
          activeRow={activeRow}
          finishedRow={finishedRow}
          driverRow={driverRow}
          driverDict={driverDict}
          vehicleRow={vehicleRow}
          vehicleDict={vehicleDict}
          patientDict={patientDict}
          setChanges={setChanges}
        />
      :
      <GridContainer item
        direction="row"
        justify="space-evenly"
        alignItems="center"
        style={{height: "330px"}}
        xs={12}
      >
        {loading ?
        <Summary
          setopenDetail={setopenDetail}
          packagePending={packagePending}
          packageToDayPending={packageToDayPending}
          packageWeekPending={packageWeekPending}
        />
        :
        <LoadInfo
          title={"Cargando información ..."}
        />
      }
      </GridContainer>
      }
    </div>
  );
};

export default Transport;
