import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { green } from "@material-ui/core/colors";
import MedicalTableComponent from "../../InsumosYMedicamentos/MedicalTableComponent";
import ResourceTableComponent from "../../InsumosYMedicamentos/ResourceTableComponent";
import DialogAceptRequest from "../DialogAceptRequest";
import UpdateStockButton from "views/Widgets/UpdateStockButton";
import api from "utils/API";
import NumberFormat from "react-number-format";
import {
  getListSuppliesMedicines,
} from "actions/getListAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "80%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 99,
    color: "#fff"
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalCreateExam = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    examsList,
    setExamsList,
    dataMedicalArray,
    setDataMedicalArray,
    dataResourceArray,
    setDataResourceArray,
    indications,
    setIndications,
    examName,
    setExamName,
    examCode,
    setExamCode,
    responsable,
    setResponsable,
  } = props;
  const dispatch = useDispatch()
  //
  const [openAnimation, setOpenAnimation] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [openDialogAceptRequest, setOpenDialogAceptRequest] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [exams, setExams] = useState([]);
  const [nameExists, setNameExists] = useState(false);
  const [transferPrice, setTransferPrice] = useState(0);

  const listMedical = useSelector(state => state.currentList.listMedicines);
  const listResource = useSelector(state => state.currentList.listSupplies);
  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

  useEffect(() => {
    if(listMedical.length === 0) {
        getListSuppliesMedicines(dispatch, chargeMedicine)
    }
  }, [])

  const checkName = name => {
    const check = exams.filter(
      row => row.nombre.toLowerCase() === name.toLowerCase()
    );
    if (check.length) {
      setNameExists(true);
    } else {
      setNameExists(false);
    }
  };

  const handleResponsable = event => {
    setResponsable(event.target.value);
  };

  useEffect(() => {
    if (pageRefresh) {
      setPageRefresh(false);
    }
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {

    setFirstCharge(false);

    const requestExams = api.get("api/clinica/examenes/");

    Promise.all([requestExams])
      .then(response => {
        setExams(response[0].data);

      })

  };

  const createExam = () => {
    setOpenAnimation(true);
    let tempListInsumos = [];
    dataMedicalArray.map(row => {
      tempListInsumos.push({
        cantidad: row.cantidad,
        id_insumo_medicamento: row.id
      });
    });
    dataResourceArray.map(row => {
      tempListInsumos.push({
        cantidad: row.cantidad,
        id_insumo_medicamento: row.id
      });
    });
    let tempListExams = examsList;

    let sendPost = {
      nombre: examName,
      indicaciones: indications,
      realiza_cmh: responsable,
      tiene_insumos: tempListInsumos.length > 0 ? true : false,
      necesita_transporte: false,
      lista_insumos: tempListInsumos,
      codigo_fonasa: examCode,
    };

    sendPost["valor"] = transferPrice;

    api
      .post("/api/clinica/examenes/", sendPost)
      .then(response => {
        tempListExams.push({
          value: response.data.id,
          label: `${examCode} ${examName}`
        });
        setExamsList(tempListExams);
        setOpenAnimation(false);
        setOpenDialogAceptRequest(true);
      })

  };

  const updateStock = () => {

    api.get("/api/poblarinsumos/").then(() => {
      getListSuppliesMedicines(dispatch, chargeMedicine, true);

    }).catch((error) => {
      console.log(error.response);

    });
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <div>
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        {openAnimation ? (
          <ModalTitle id="customized-dialog-title">
            Ingresar datos del examen a crear
          </ModalTitle>
        ) : (
          <ModalTitle id="customized-dialog-title" onClose={closeModal}>
            Ingresar datos del examen a crear
          </ModalTitle>
        )}
        <DialogContent>
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ marginTop: "3em" }}
          >
            {pageRefresh && <div></div>}
            <Grid item container xs={12} justify="center">
              <Grid
                item
                container
                xs={12}
                style={{ marginTop: "1em" }}
                justify="center"
              >
                <Grid item xs={1} />
                <Grid item container xs={10}>
                  <Grid
                    item
                    container
                    xs={12}
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid
                      item
                      container
                      xs={4}
                      justify="center"
                      style={{ marginTop: "8px" }}
                    >
                      <TextField
                        fullWidth
                        id="outlined-basic3"
                        label="Código fonasa del examen"
                        variant="outlined"
                        value={examCode}
                        onChange={e => {
                          setExamCode(e.target.value);
                        }}
                        error={examCode === "" ? true : false}
                        helperText={examCode === "" ? "Debes código fonasa" : null }
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      xs={4}
                      justify="center"
                      style={{ marginTop: "8px" }}
                    >
                      <TextField
                        fullWidth
                        id="outlined-basic1"
                        label="Nombre del examen"
                        variant="outlined"
                        value={examName}
                        onChange={e => {
                          setExamName(e.target.value);
                          checkName(e.target.value);
                        }}
                        helperText={
                          examName === ""
                            ? "Debes ingresar un nombre"
                            : nameExists
                            ? "Este nombre ya existe"
                            : null
                        }
                        error={
                          examName === "" ? true : nameExists ? true : false
                        }
                      />
                    </Grid>

                    <Grid item container xs={4} justify="center">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          ¿Quién realiza el análisis?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={responsable}
                          onChange={handleResponsable}
                          label="¿Quién realiza el análisis?"
                        >
                          <MenuItem value={true}>CMH</MenuItem>
                          <MenuItem value={false}>Laboratorio Externo</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ marginTop: "1em" }}
                justify="center"
              >
                <Grid item xs={1} />
                <Grid item container xs={10} justify="center">
                  <Grid item container xs={12} justify="center">
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="outlined-basic2"
                      label="Indicaciones"
                      variant="outlined"
                      value={indications}
                      onChange={e => setIndications(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ marginBottom: "4em", marginTop: "1em" }}
                justify="center"
              >
                <Grid item xs={4} />
                <Grid item container xs={4} justify="center">
                  <Grid item container xs={12} justify="center">
                  <TextField
                    label="Valor del examen"
                    value={transferPrice}
                    onChange={e => setTransferPrice(parseInt(e.target.value))}
                    name="numberformat"
                    //id="standard-basic"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumberFormatCustom
                    }}
                  />
                  </Grid>
                </Grid>
                <Grid item xs={4} />
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={1} />
                <Grid item container xs={10} justify="center">
                  <MedicalTableComponent
                    dataMedicalArray={dataMedicalArray}
                    setDataMedicalArray={setDataMedicalArray}
                    medicalResourceData={medicalResourceData}
                    //listMedical={listMedical}
                    listMedical={listMedical.filter(list =>
                      dataMedicalArray.every(data => data.id !== list.value)
                    )}
                    setPageRefresh={setPageRefresh}
                  />
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={1} />
                <Grid
                  item
                  container
                  xs={10}
                  justify="center"
                  style={{ marginTop: "2em" }}
                >
                  <ResourceTableComponent
                    dataResourceArray={dataResourceArray}
                    setDataResourceArray={setDataResourceArray}
                    medicalResourceData={medicalResourceData}
                    listResource={listResource.filter(list =>
                      dataResourceArray.every(data => data.id !== list.value)
                    )}
                    setPageRefresh={setPageRefresh}
                  />
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </Grid>
            {openDialogAceptRequest && (
              <DialogAceptRequest
                openDialog={openDialogAceptRequest}
                closeDialog={() => setOpenDialogAceptRequest(false)}
                closeMainModal={() => closeModal()}
                name={examName}
              />
            )}
          </Grid>
        </DialogContent>
        {openAnimation ? (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            <CircularProgress />
          </DialogActions>
        ) : (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            {transferPrice > 0 &&examName != "" &&
            nameExists === false ? (
              <Button
                variant="contained"
                className="text-white"
                style={{
                  marginLeft: "1em",
                  textTransform: "none",
                  backgroundColor: green[500],
                  marginTop: "3em"
                }}
                onClick={() => {
                  createExam();
                }}
              >
                Crear un nuevo examen
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "1em",
                  textTransform: "none",
                  marginTop: "3em"
                }}
                onClick={() => {
                  createExam();
                }}
              >
                Crear un nuevo examen
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                marginTop: "3em"
              }}
              onClick={closeModal}
            >
              Salir
            </Button>
          </DialogActions>
        )}

      </Paper>
      <UpdateStockButton
        updateStock={updateStock}
      />
      </div>
    </Modal>
  );
};

export default ModalCreateExam;

ModalCreateExam.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  examsList: PropTypes.array,
  setExamsList: PropTypes.func,
  dataMedicalArray: PropTypes.array,
  setDataMedicalArray: PropTypes.func,
  dataResourceArray: PropTypes.array,
  setDataResourceArray: PropTypes.func,
  indications: PropTypes.string,
  setIndications: PropTypes.func,
  examName: PropTypes.string,
  setExamName: PropTypes.func,
  examCode: PropTypes.string,
  setExamCode: PropTypes.func,
  responsable: PropTypes.string,
  setResponsable: PropTypes.func,
  openBackdrop: PropTypes.bool,
  setOpenBackdrop: PropTypes.func,
};
