import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    FormControlLabel,
    Radio,
    Checkbox,
} from '@material-ui/core';
import {
    RadioButtonChecked,
    CheckCircle,
    RadioButtonUnchecked,
} from "@material-ui/icons/"
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";

import {dictMonth} from "utils/validationFunction";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import allActions from "actions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function EditVisitCalendar({
    open,
    handleClose,
    selectEditWeek,
    dictChargeId,
    dictReservation,
    dictUser,
    selectPatient,
    dailyHeaderArray,
    handleEditReservation,
    handleRemoveReservation,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [initialCharge, setinitialCharge] = useState(true);
    const [start, setstart] = useState({});
    const [end, setend] = useState({});
    const [listHour, setlistHour] = useState([]);
    const [worker, setworker] = useState({});
    const [selectWorker, setselectWorker] = useState({});
    const [hourType, sethourType] = useState("normal");
    const [changeHour, setchangeHour] = useState(false);
    const [checkPatient, setcheckPatient] = useState(false);
    const [extraLunch, setextraLunch] = useState(false);
    const [shiftMobilization, setshiftMobilization] = useState(false);
    const [specialMobilization, setspecialMobilization] = useState(false);
    const [specialViatic, setspecialViatic] = useState(false);
    const [extraLunchAmount, setextraLunchAmount] = useState("0");
    const [shiftMobilizationAmount, setshiftMobilizationAmount] = useState("0");
    const [specialViaticAmount, setspecialViaticAmount] = useState("0");
    const [extraTurnValue, setextraTurnValue] = useState("0");
    const [comment, setcomment] = useState("");
    const [messageWorkerWarning, setmessageWorkerWarning] = useState("");
    const [alertVetoe, setAlertVetoe] = useState(false);

    useEffect(() => {
        if (initialCharge){
            initialData();
        }
        if (changeHour){
            getTimeList();
        }
        if (checkPatient){
            getWarningMessage();
            if (worker?.id){
                initialVetoes();
            }
        }
    });

    const initialVetoes = () => {
        const getVetoes = api.get(
        `api/rrhh/variablesdinamicas/personal/${worker.id_ficha.id}/tipo/vetos/`
        );

        Promise.all([getVetoes])
        .then(response => {
            const checkVetoe = response[0].data.valores.filter(
            filtered =>
                filtered.info_veto.id_ficha_paciente_id.id_paciente_id.id ===
                selectPatient.value
            );
            setAlertVetoe(checkVetoe.length > 0);
        })
    };

    const initialData = () => {
        setinitialCharge(false);
        const tempStart = moment(selectEditWeek.fecha_hora).format("HH:mm");
        const tempEnd = moment(selectEditWeek.fecha_final).format("HH:mm");
        const tempWorker = dictUser[selectEditWeek.id_usuarios]
        setstart({value: tempStart, label: tempStart});
        setend({value: tempEnd, label: tempEnd});
        setchangeHour(true);
        setcheckPatient(true);
        if (tempWorker){
            setselectWorker({value: tempWorker?.id_ficha.id, label: `(${tempWorker.id_ficha.rut}) ${tempWorker.id_ficha?.nombres} ${tempWorker.id_ficha?.apellido_paterno} - ${dictChargeId[tempWorker.id_ficha?.id_cargo_id]?.nombre_corto}]}`});
            setworker(dictUser[selectEditWeek.id_usuarios])
        }

        api.get(`/api/clinica/asignar/tarea/${selectEditWeek.id}`).then((response) => {
            const extra_values = (response?.data?.valores_extras || []).reduce((accum, obj) => ({
                ...accum,
                [obj.codigo_tipo_valor]: obj
            }), {});
            sethourType(extra_values["ValorAtencionExtra"] ? "extra" : "normal")
            setextraLunch(extra_values["ColacionExtra"] ? true : false)
            setshiftMobilization(extra_values["MovilizacionExtra"] ? true : false)
            setspecialMobilization(extra_values["MovilizacionEspecial"] ? true : false)
            setspecialViatic(extra_values["ViaticoEspecial"] ? true : false)
            setextraLunchAmount(extra_values["ColacionExtra"] ? extra_values["ColacionExtra"].valor : 0)
            setshiftMobilizationAmount(extra_values["MovilizacionExtra"] ? extra_values["MovilizacionExtra"].valor : 0)
            setspecialViaticAmount(extra_values["ViaticoEspecial"] ? extra_values["ViaticoEspecial"].valor : 0)
            setextraTurnValue(extra_values["ValorAtencionExtra"] ? extra_values["ValorAtencionExtra"].valor : 0);
        })
    };

    const getWarningMessage = () => {
        setcheckPatient(false);
        const dateEvent = moment(selectEditWeek.fecha_hora).format("YYYY-MM-DD");
        const tempStartDate = moment(`${dateEvent} ${start.value}:00`)
        const tempEndDate = moment(`${dateEvent} ${end.value}:00`)
        const schdeuleWorkerArray = []

        const arrayDateKey = Object.keys(dictReservation).filter((row) =>   {
            const dateKey = moment(row)
            if (dateKey.format("YYYY-MM-DD") === dateEvent){
                return moment(tempEndDate).diff(dateKey, "minutes") >= 0 && moment(dateKey).diff(tempStartDate, "minutes") <= 0
            }
            return false
        });
        arrayDateKey.map((row) => {
            const tempRow = dictReservation[row][selectWorker.value]
            if (tempRow){
                schdeuleWorkerArray.push(`${moment(tempRow.fecha_hora).format("HH:mm")} - ${moment(tempRow.fecha_final).format("HH:mm")}`)
            }
        })
        if (schdeuleWorkerArray.length > 0 ){
            const tempScheduleString = schdeuleWorkerArray.join(", ");
            setmessageWorkerWarning(`El Profesional tiene una consulta agendada en el horario: ${tempScheduleString}`)
        } else {
            setmessageWorkerWarning("");
        }
    };

    const getTimeList = () => {
        setchangeHour(false);
        const tempListHours = [];
        const auxSelectDate = moment(selectEditWeek.fecha_hora).format("YYYY-MM-DD");
        let countDate = moment(auxSelectDate);
        const endDate = moment(auxSelectDate).add(1, "days");
        while (moment(endDate).diff(countDate, "minutes") >= 0){
            const tempHour = moment(countDate).format("HH:mm");
            tempListHours.push({value: tempHour, label: tempHour});
            countDate = moment(countDate).add(30, "minutes");
        }
        setlistHour(tempListHours);
        setcheckPatient(true);
    };

    const handleSelectHour = (value, argDate, set) => {
        const tempDate = moment(selectEditWeek.fecha_hora).format("YYYY-MM-DD")
        let auxDate = moment(`${tempDate} ${value.value}:0`);
        let compareDate = null;
        if (argDate === "start"){
            compareDate = moment(`${tempDate} ${end.value}:0`);
            if (moment(compareDate).diff(auxDate, "minutes") <= 0 ){
                let auxEndTime = moment(auxDate).add(30, "minutes");
                auxEndTime = moment(auxEndTime).format("HH:mm");
                setend({value:auxEndTime, label: auxEndTime});
            }
        } else {
            compareDate = moment(`${tempDate} ${start.value}:0`);
            if (moment(auxDate).diff(compareDate, "minutes") <= 0){
                let auxStartTime = moment(auxDate).subtract(30, "minutes");
                auxStartTime = moment(auxStartTime).format("HH:mm");
                setstart({value:auxStartTime, label: auxStartTime});
            }
        }
        const valueFormat = moment(auxDate).format("HH:mm")
        set({value: valueFormat, label: valueFormat});
        setchangeHour(true);
        setcheckPatient(true);
    };

    const handleWorker = (value) => {
        const tempWorwer = value.item
        setworker(tempWorwer);
        setselectWorker(value);
        setcheckPatient(true);
    }


    const handleHourType = event => {
        sethourType(event.target.value);
        setextraTurnValue(0);
    };

    const handleAddItems = (value, set, setvalue) => {
        set(!value);
        setvalue(0);
    };

    const handleOnChange = (value, set) => {
        let tempValue =  isNaN(value) ? 0 : value;
        set(tempValue);
    };

    const tempWorkerArray = dailyHeaderArray.reduce((accum, obj) => [
        ...accum,
        {
            value: obj.id,
            label: `(${obj.id_ficha.rut}) ${obj.id_ficha?.nombres} ${obj.id_ficha?.apellido_paterno} - ${dictChargeId[obj.id_ficha?.id_cargo_id]?.nombre_corto}]}`,
            item: {...obj}
        }
    ], []);

    const _content_ =  <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Typography variant="body2" gutterBottom>
                    {`Paciente: ${selectPatient.nombre} ${selectPatient.apellido_paterno} ${selectPatient.apellido_materno}`}
                </Typography>
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <DetailSelect
                    label="Profesional:"
                    value={selectWorker}
                    onchange={(value) => handleWorker(value)}
                    list={tempWorkerArray}
                />
                <DetailSelect
                    isDisabled={selectEditWeek.tipo_tarea === "HabilitacionDomicilio"}
                    label="Horario inicio:"
                    value={start}
                    onchange={(value) => handleSelectHour(value, "start", setstart)}
                    list={listHour.slice(0, listHour.length - 1)}
                />
                <DetailSelect
                    isDisabled={selectEditWeek.tipo_tarea === "HabilitacionDomicilio"}
                    label="Horario fin:"
                    value={end}
                    onchange={(value) => handleSelectHour(value, "end", setend)}
                    list={listHour.slice(1, listHour.length)}
                />
                {messageWorkerWarning != "" &&
                    <SnackbarContent
                        message={messageWorkerWarning}
                        color="danger"
                    />
                    }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <FormControlLabel
                    control={
                        <Radio
                            checked={hourType === "normal"}
                            onChange={handleHourType}
                            value="normal"
                            name="radio button enabled"
                            aria-label="normal"
                            icon={
                                <RadioButtonUnchecked style={{fill: indigo[500]}} />
                            }
                            checkedIcon={
                                <RadioButtonChecked style={{fill: indigo[500]}}/>
                            }
                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label="Considerar como atención normal"
                />
                <FormControlLabel
                    control={
                        <Radio
                            checked={hourType === "extra"}
                            onChange={handleHourType}
                            value="extra"
                            name="radio button enabled"
                            aria-label="extra"
                            icon={
                                <RadioButtonUnchecked style={{fill: indigo[500]}} />
                            }
                            checkedIcon={
                                <RadioButtonChecked style={{fill: indigo[500]}} />
                            }

                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label="Considerar como atención extra"
                />
                { hourType === "extra" &&
                    <DetailNumber
                        label={`Valor atención extra`}
                        value={extraTurnValue}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setextraTurnValue)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Typography variant="body2" gutterBottom>
                {`¿Cuál de los siguientes items desea agregar?`}
                </Typography>

            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checked={extraLunch}
                            onClick={() => handleAddItems(extraLunch, setextraLunch, setextraLunchAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Colación atención extra"
                />
                { extraLunch &&
                    <DetailNumber
                        label={`Valor colación atención extra`}
                        value={extraLunchAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setextraLunchAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checked={shiftMobilization}
                            onClick={() => handleAddItems(shiftMobilization, setshiftMobilization, setshiftMobilizationAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Movilizacíon atención extra"
                />
                { shiftMobilization &&
                    <DetailNumber
                        label={`Valor movilizacíon atención extra`}
                        value={shiftMobilizationAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setshiftMobilizationAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checked={specialViatic}
                            onClick={() => handleAddItems(specialViatic, setspecialViatic, setspecialViaticAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Viatico especial"
                />
                { specialViatic &&
                    <DetailNumber
                        label={`Valor viatico especial`}
                        value={specialViaticAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setspecialViaticAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <DetailText
                    label={`Comentario`}
                    placeholder={``}
                    value={comment}
                    validation={false}
                    onchange={(e) => { handleOnChange(e.target.value, setcomment)}}
                />
            </Grid>
        </Grid>
    </div>

    const _actions_ = <>
        {(alertVetoe || !selectWorker.value) ?
        <Button
            disabled={alertVetoe}
            mr={2}
            variant="contained"
            className="text-white"
        >
            Guardar
        </Button>:
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: green[500]}}
            onClick={() => handleEditReservation(
                selectPatient,
                start,
                end,
                extraLunchAmount,
                shiftMobilizationAmount,
                specialViaticAmount,
                extraTurnValue,
                comment,
                hourType,
                worker,
            )}
        >
            Guardar
        </Button>
        }
        {selectEditWeek.tipo_tarea !== "HabilitacionDomicilio" &&
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: red[500]}}
            onClick={handleRemoveReservation}
        >
            Eliminar
        </Button>
        }
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500], marginRight: "10px", marginLeft: "3px"}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </Button>
    </>


    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Editar visita - ${moment(selectEditWeek.fecha_hora).format("DD")} de ${dictMonth[parseInt(moment(selectEditWeek.fecha_hora).format("MM"))]}`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xs"
        />
    );
}