import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

export default function AlertDelete(props) {
  const { openDialog, closeDialog, deleteQuote } = props;

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Eliminar registro"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          ¿Deseas eliminar este registro?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={deleteQuote}
          color="secondary"
        >
          Eliminar
        </Button>
        <Button
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={closeDialog}
          color="primary"
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDelete.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.bool,
  deleteQuote: PropTypes.func
};
