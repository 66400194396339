import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";
import ReceptionInfoTransport from "./ReceptionInfoTransport";
import Grid from "@material-ui/core/Grid";


export default function SendExternalTransport() {

  const { id } = useParams();
  const id_order = parseInt(id, 10);
  const [moduleType, setmoduleType] = useState("");
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState([]);
  const [getFiles, setGetFiles] = useState([]);
  const [company, setCompany] = useState("");
  const [ticket, setTicket] = useState("");
  const [trackNumber, setTrackNumber] = useState("");
  const [file, setFile] = useState(null);

  const [externalDispatch, setExternalDispatch] = useState([]);
  const [envioPedido, setEnvioPedido] = useState([]);

  useEffect(() => {
    setLoading(true);
    const tempArrayURL = window.location.href.split('/');
    setmoduleType(tempArrayURL[tempArrayURL.length-2]);
    api.get(`api/enviospedidos/${id_order}`).then(result => {
        setLoading(true);
        setEnvioPedido(result.data);
        api.get(`api/solicitudpedidos/${result.data.id_solicitud_pedido}`).then(response => {
            setOrder(response.data);
          })
        console.log("Ok enviospedidos", result);
        api.get("api/documentospedidos").then(result2 => {
            setLoading(true);
            setGetFiles(
              result2.data.filter(
                filtered =>
                  filtered.id_pedidos === result.data.id_solicitud_pedido &&
                  filtered.tipo_archivo === "ComprobanteDespachoExterno" &&
                  filtered.area === "Egresos"
              )
            );
          })
        
        api.get("api/registrodespachoexterno/").then(result3 => {
            setLoading(true);
            setExternalDispatch(
              result3.data.filter(
                dispatch => dispatch.id_envio_pedido === result.data.id
              )
            );
          })
      })

    setLoading(false);
  }, [id]);

  function save() {

    const formData = new FormData();

    formData.append("empresa", company);
    formData.append("boleta", ticket);
    formData.append("tracking", trackNumber);
    formData.append("id_envio_pedido", envioPedido.id);

    const formData2 = new FormData();

    formData2.append("nombre", file.name);
    formData2.append("archivo", file);
    formData2.append("tipo_archivo", "ComprobanteDespachoExterno");
    formData2.append("observacion", "Transporte externo");
    formData2.append("area", "Egresos");
    formData2.append("id_pedidos", envioPedido.id_solicitud_pedido);

    apiform.post("api/registrodespachoexterno/", formData).then(response => {
        apiform.post("api/documentospedidos/", formData2).then(result => {
          window.location.href = `/admin/outcome/receptions/${moduleType}`;
        })
      })

  }

  function saveAndSend() {

    const formData = new FormData();

    formData.append("empresa", company);
    formData.append("boleta", ticket);
    formData.append("tracking", trackNumber);
    formData.append("id_envio_pedido", envioPedido.id);

    const formData2 = new FormData();

    formData2.append("nombre", file.name);
    formData2.append("archivo", file);
    formData2.append("tipo_archivo", "ComprobanteDespachoExterno");
    formData2.append("observacion", "Transporte externo");
    formData2.append("area", "Egresos");

    const formData3 = new FormData();

    formData3.append("numero_pedido", envioPedido.numero_pedido);
    formData3.append("estado", "Esperar a transporte");
    formData3.append("estado_egreso", "Esperar a transporte");

    apiform.post("api/registrodespachoexterno/", formData).then(response => {
        apiform.post("api/documentospedidos/", formData2).then(response2 => {
          apiform.put(`api/enviospedidos/${envioPedido.id}`, formData3).then(response3 => {
                window.location.href = `/admin/outcome/receptions/${moduleType}`;
              })
          })
      })

  }

  function updateAndSend() {

    const formData = new FormData();

    if (company !== "") {
      formData.append("empresa", company);
    } else {
      formData.append("empresa", externalDispatch[0].empresa);
    }
    if (ticket !== "") {
      formData.append("boleta", ticket);
    } else {
      formData.append("boleta", externalDispatch[0].boleta);
    }
    if (trackNumber !== "") {
      formData.append("tracking", trackNumber);
    } else {
      formData.append("tracking", externalDispatch[0].tracking);
    }

    const formData2 = new FormData();

    if (file != null) {
      formData2.append("archivo", file);
      formData2.append("nombre", file.name);
    } else {
      formData2.append("nombre", getFiles[0].nombre);
    }
    formData2.append("tipo_archivo", "ComprobanteDespachoExterno");
    formData2.append("observacion", "Transporte externo");
    formData2.append("area", "Egresos");

    const formData3 = new FormData();

    formData3.append("numero_pedido", envioPedido.numero_pedido);
    formData3.append("estado", "Esperar a transporte");
    formData3.append("estado_egreso", "Esperar a transporte");

    apiform
      .put(`api/registrodespachoexterno/${externalDispatch[0].id}`, formData)
      .then(response => {
        apiform
          .put(`api/documentospedidos/${getFiles[0].id}`, formData2)
          .then(response2 => {
            apiform
              .put(`api/enviospedidos/${envioPedido.id}`, formData3)
              .then(response3 => {
                window.location.href = `/admin/outcome/receptions/${moduleType}`;
              })

          })

      })

  }

  function update() {

    const formData = new FormData();

    if (company !== "") {
      formData.append("empresa", company);
    } else {
      formData.append("empresa", externalDispatch[0].empresa);
    }
    if (ticket !== "") {
      formData.append("boleta", ticket);
    } else {
      formData.append("boleta", externalDispatch[0].boleta);
    }
    if (trackNumber !== "") {
      formData.append("tracking", trackNumber);
    } else {
      formData.append("tracking", externalDispatch[0].tracking);
    }

    const formData2 = new FormData();

    if (file != null) {
      formData2.append("archivo", file);
      formData2.append("nombre", file.name);
    } else {
      formData2.append("nombre", getFiles[0].nombre);
    }
    formData2.append("tipo_archivo", "ComprobanteDespachoExterno");
    formData2.append("observacion", "Transporte externo");
    formData2.append("area", "Egresos");

    apiform
      .put(`api/registrodespachoexterno/${externalDispatch[0].id}`, formData)
      .then(response => {
        apiform
          .put(`api/documentospedidos/${getFiles[0].id}`, formData2)
          .then(response2 => {
            window.location.href = `/admin/outcome/receptions/${moduleType}`;
          })
          .catch(error => console.log("Error update documentos", error.response));
      })

  }

  function NewTab(url) {
    window.open(url);
    return false;
  }

  return (
    <GridContainer>
      <ViewTitle
        title="ENVÍO POR TRANSPORTE EXTERNO"
        message="En esta sección podrás ingresar los datos del envío por transporte externo."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/outcome/tasks/${moduleType}`}>
              Tareas de egresos
            </Link>
            <Typography color="textPrimary">
              Envío por transporte externo
            </Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="mt-3">
              <GridContainer justify="center">
                <ReceptionInfoTransport
                  idEnvioPedido={envioPedido.id}
                  idSolicitud={order.id}
                  tipoSolicitud={order.tipo_solicitud}
                  estadoSolicitud={order.estado_solicitud}
                  paciente={order.nombre_paciente}
                  fechaSolicitud={order.fecha_solicitud}
                  fechaDespacho={order.fecha_tentativa_llegada}
                  esRegion={order.es_region}
                  estadoPicking={order.estado_picking}
                  tieneMedicamentos={order.tiene_medicamentos}
                  transport={"External"}
                  moduleType={moduleType}
                />
              </GridContainer>
            </GridContainer>
            <CardBody className="mb-1">
              <GridContainer justify="center">
                {/* {getFiles.length > 0 && externalDispatch.length > 0 ? (
                  <div className="col-md-4 mb-3">
                    <div className="card">
                      <div className="card-body">
                        <p className="text-center font-weight-bold">
                          Información último registro:
                        </p>
                        <p>
                          Empresa:
                          {externalDispatch.length > 0
                            ? externalDispatch[0].empresa
                            : null}
                        </p>
                        <p>
                          Nº boleta:
                          {externalDispatch.length > 0
                            ? externalDispatch[0].boleta
                            : null}
                        </p>
                        <p>
                          Nº seguimiento:
                          {externalDispatch.length > 0
                            ? externalDispatch[0].tracking
                            : null}
                        </p>
                        <p>
                          Comprobante:
                          {getFiles.map(file => (
                            <a
                              key={file.id}
                              onClick={() => NewTab(file.archivo)}
                              className="btn btn-cmh-color text-white"
                            >
                              {file.nombre}
                            </a>
                          ))}
                        </p>
                      </div>
                    </div>
                    <p className="font-weight-bold text-warning text-center">
                      Ya tienes un registro, puedes enviar la solicitud o
                      guardar un nuevo registro
                    </p>
                  </div>
                ) : null} */}
                <Grid container xs={12} style={{ textAlign: "center" }}>
                  <Grid container direction="row" justify="center">
                    <Grid container item direction="row" xs={8}>
                      <Grid item xs={4} align="center" />
                      <Grid item xs={4} align="center">
                        <h4 className="font-weight-bold mb-3">
                          Datos del envío
                        </h4>
                      </Grid>
                      <Grid item xs={4} />
                      <div className="col-md-4 mb-3">
                        <label className="text-dark">EMPRESA:</label>
                        <input
                          type="text"
                          maxLength={90}
                          className="form-control bg-light font-weight-lighter"
                          style={{ color: "#000000" }}
                          value={company}
                          placeholder="Empresa"
                          onChange={e => setCompany(e.target.value)}
                        />
                        {getFiles.length > 0 &&
                        externalDispatch.length > 0 ? null : company === "" ? (
                          <span className="text-danger">
                            Debes ingresar una empresa
                          </span>
                        ) : null}
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="text-dark">Nº BOLETA:</label>
                        <input
                          type="text"
                          maxLength={90}
                          className="form-control bg-light font-weight-lighter"
                          style={{ color: "#000000" }}
                          value={ticket}
                          placeholder="Nº boleta"
                          onChange={e => setTicket(e.target.value)}
                        />
                        {getFiles.length > 0 &&
                        externalDispatch.length > 0 ? null : ticket === "" ? (
                          <span className="text-danger">
                            Debes ingresar un nº de boleta
                          </span>
                        ) : null}
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="text-dark">Nº SEGUIMIENTO:</label>
                        <input
                          type="text"
                          maxLength={90}
                          className="form-control bg-light font-weight-lighter"
                          style={{ color: "#000000" }}
                          value={trackNumber}
                          placeholder="Nº seguimiento"
                          onChange={e => setTrackNumber(e.target.value)}
                        />
                        {getFiles.length > 0 &&
                        externalDispatch.length > 0 ? null : trackNumber ===
                          "" ? (
                          <span className="text-danger">
                            Debes ingresar un nº de seguimiento
                          </span>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <GridContainer
                  justify="center"
                  style={{ textAlign: "center" }}
                  className="m-3 mt-3 mb-3"
                >
                  <div className="col-md-4 mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="card-title">
                          ADJUNTAR COMPROBANTE:
                        </span>
                        <p className="card-text">
                          Comprobante: {file ? file.name : null}
                        </p>
                        <span className="btn btn-cmh-color btn-file">
                          Seleccionar
                          <input
                            type="file"
                            name={file}
                            onChange={e => {
                              setFile(e.target.files[0]);
                            }}
                          />
                        </span>
                        {getFiles.length > 0 && externalDispatch.length > 0 ? (
                          <div>
                            <p />
                            <br />
                            <p className="card-text">
                              Comprobante anterior:
                            </p>
                            {getFiles.map(file => (
                              <a
                                key={file.id}
                                onClick={() => NewTab(file.archivo)}
                                className="btn btn-cmh-color text-white"
                              >
                                {file.nombre}
                              </a>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* {getFiles.length > 0 &&
                    externalDispatch.length > 0 ? null : file === null ? (
                      <span className="text-danger">
                        Debes ingresar un comprobante
                      </span>
                    ) : null} */}
                  </div>
                </GridContainer>
                <GridContainer
                  justify="center"
                  style={{ textAlign: "center" }}
                  className="m-3 mt-3 mb-3"
                >
                  {getFiles.length > 0 && externalDispatch.length > 0 ? (
                    <div>
                      {/* <span className="font-weight-bold text-warning">
                        Ya tienes un registro, puedes enviar la solicitud o
                        guardar un nuevo registro.
                      </span> */}
                      <p>
                        <span className="font-weight-bold">
                          Empresa anterior:
                        </span>
                        {externalDispatch ? externalDispatch[0].empresa : null}
                      </p>
                      <p>
                        <span className="font-weight-bold">
                          Nº boleta anterior:
                        </span>
                        {externalDispatch ? externalDispatch[0].boleta : null}
                      </p>
                      <p>
                        <span className="font-weight-bold">
                          Nº seguimiento anterior:
                        </span>
                        {externalDispatch ? externalDispatch[0].tracking : null}
                      </p>
                    </div>
                  ) : file === null ? (
                    <span className="text-danger">
                      Debes ingresar un comprobante
                    </span>
                  ) : null}
                </GridContainer>
                {getFiles.length > 0 && externalDispatch.length > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={update}
                    className="m-1"
                  >
                    Guardar
                  </Button>
                ) : company !== "" &&
                  ticket !== "" &&
                  trackNumber !== "" &&
                  file != null ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={save}
                    className="m-1"
                  >
                    Guardar
                  </Button>
                ) : null}
                {getFiles.length > 0 && externalDispatch.length > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={updateAndSend}
                    className="m-1"
                  >
                    Guardar y enviar
                  </Button>
                ) : company !== "" &&
                  ticket !== "" &&
                  trackNumber !== "" &&
                  file != null ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveAndSend}
                    className="m-1"
                  >
                    Guardar y enviar
                  </Button>
                ) : null}
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
