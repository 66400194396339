import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import apiform from "utils/APIForm";
import api from "utils/API";
import TableExamData from "views/Widgets/FichaPaciente/Examenes/TableExamData";
import TableExamProf from "views/Widgets/FichaPaciente/Examenes/TableExamProf";
// import TableSummary from "./TableSummary";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalUploadOrderAntibiotic = props => {
  const classes = useStyles();
  const { openModal, closeModal, tempRow } = props;
  const [firstCharge, setFirstCharge] = useState(true);
  const [fileIsapreAuthorization, setFileIsapreAuthorization] = useState(null);

  const [indications, setIndications] = useState("");
  const [antibioticDate, setantibioticDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [priority, setPriority] = useState("");
  const [supplies, setSupplies] = useState([]);
  const [listProfesional, setlistProfesional] = useState([]);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);
    const getRequestAntibiotic = api.get(
      `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/tratamiento/antibiotico/${tempRow.id_solicitud_paciente.id}/`
    );
    Promise.all([getRequestAntibiotic]).then(response => {
        setantibioticDate(
          moment(response[0].data.tratamiento_antibiotico.fecha_hora_examen).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        );
        setPriority(tempRow.id_solicitud_paciente.prioridad);
        setIndications(response[0].data.tratamiento_antibiotico.indicaciones);

        let tempSupplies = [];
        response[0].data.lista_insumos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto === "Medicamentos") {
            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad_necesaria,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });
        response[0].data.lista_insumos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto === "Insumos") {
            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad_necesaria,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });
        setlistProfesional(response[0].data.lista_cargos.reduce(
          (accum, obj) => ([
            ...accum,
            {
              cantidad: obj.cantidad_necesaria,
              nombre: obj.id_cargo__nombre_corto,
            },
          ]),
          []
        ))

        setSupplies(tempSupplies);
      })
  };

  const saveIsapreAuthorization = () => {
    const formData = new FormData();
    formData.append("archivo", fileIsapreAuthorization[0]);
    formData.append("tipo_documento", "OrdenTratamientoAntibiotico");
    formData.append("nombre_archivo", fileIsapreAuthorization[0].name);
    formData.append("id_paciente",tempRow.id_solicitud_paciente.id_paciente_id);
    apiform
      .post(
        `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/tratamiento/antibiotico/${tempRow.id_solicitud_paciente.id}/documentos/orden_tratamiento_antibiotico/subir/`,
        formData
      )
      .then(responseFile => {
        window.location.reload();
      })
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ height: "90%", overflow: "auto" }}
      >
        <ModalTitle id="customized-dialog-title">
          Orden para "Tratamiento Antibiótico"
        </ModalTitle>

        <DialogContent>
          <Grid
            item
            container
            xs={12}
            justify="center"
          >
            <Grid container className={classes.root} justify="center">
              <Grid item container xs={12} direction="column" justify="center" alignContent="center" spacing={1}>
                  <Typography variant="body2" gutterBottom>
                    Fecha Tratamiento antibiótico:
                    <span style={{ fontWeight: "bold" }}>
                      {moment(antibioticDate).format("YYYY-MM-DD")}
                    </span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Hora:
                    <span style={{ fontWeight: "bold" }}>
                      {moment(antibioticDate).format("HH:mm")}
                    </span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Prioridad:
                    <span style={{ fontWeight: "bold" }}>{priority}</span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Indicaciones:
                    <span style={{ fontWeight: "bold" }}>{indications}</span>
                  </Typography>

              </Grid>
            </Grid>
            <Grid item container xs={12} justify="center">
              <TableExamData supplies={supplies} />
            </Grid>
            <Grid item container xs={12} justify="center">
              <TableExamProf supplies={listProfesional} />
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ margin: "10px 0" }}
              justify="center"
            >
              <Grid
                item
                container
                xs={12}
                justify="center"
                style={{ marginBottom: "4px" }}
              >
                {fileIsapreAuthorization != null
                  ? fileIsapreAuthorization[0].name
                  : null}
              </Grid>
              <input
                id="contained-button-file"
                //multiple
                type="file"
                onChange={e => setFileIsapreAuthorization(e.target.files)}
                hidden
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ textTransform: "none", marginRight: "3px" }}
                >
                  Adjuntar orden
                </Button>
              </label>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          {fileIsapreAuthorization != null ? (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#43a047",
                textTransform: "none",
                color: "#fff"
              }}
              onClick={() => {
                saveIsapreAuthorization();
                //editRequest(true);
              }}
            >
              Subir orden
            </Button>
          ) : (
            <Button
              disabled
              variant="contained"
              color="primary"
              style={{
                textTransform: "none"
              }}
              onClick={() => {
                saveIsapreAuthorization();
                //editRequest(true);
              }}
            >
              Subir orden
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: "none"
            }}
            onClick={closeModal}
          >
            Atrás
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
};

export default ModalUploadOrderAntibiotic;

ModalUploadOrderAntibiotic.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRow: PropTypes.object
};
