import React from "react";

const DetailNumber = props => {
  return(
    <div className="col-md-12 mb-3" style={props.styleDiv ? props.styleDiv : {}}>
      <label className="text-dark">
        {props?.label}
        {(props?.value === "" && props.validation !== false) ? (
          <span className="text-danger"> no puede ser vacío</span>
        ) : (props?.error && props.validation !== false) ? 
          <span className="text-danger"> {props?.error} </span> : null}
      </label>
      <input
        disabled={props.isdisabled}
        type="number"
        min={props.min ? props.min : "1"}
        max={props?.max}
        // step={props.step ? props.step : "1"}
        className="form-control bg-light font-weight-lighter"
        style={{color: "#000000"}}
        value={props.value}
        onChange={props.onchange}
        allowclear 
      />
    </div>
  );
}

export default DetailNumber;