import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Paper,
    Grid,
    Typography,
    Backdrop,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
}));

export default function ModalEnableAddress({
    open,
    handleClose,
    selectEnableAddress,
}) {
    const classes = useStyles();
    const [patientInfo, setpatientInfo] = useState(selectEnableAddress.row.id_ficha.id_paciente)

    const dictTypeWork = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA);

    const handleAssing = () => {
        window.open(`/admin/Assignments/`);
    };

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px'
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
            className={classes.paper}
            style={{
                maxHeight: "900px",
                overflow: "auto",
                width: "650px",
            }}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={12}
                        style={{padding: "5px"}}
                    >
                        <Grid item xs={6}>
                            <Typography  variant="caption" display="block" gutterBottom>
                                {`Tipo de solicitud: ${dictTypeWork[selectEnableAddress.row.tipo_tarea]?.label}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography  variant="caption" display="block" gutterBottom>
                                {`Dirección: ${patientInfo.direccion_calle} ${patientInfo.direccion_numero} ${patientInfo.direccion_casa_depto} ${patientInfo.direccion_block} ${patientInfo.direccion_comuna} ${patientInfo.direccion_region}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography  variant="caption" display="block" gutterBottom>
                                {`Fecha de Habilitación: ${moment(selectEnableAddress.row.fecha_hora).format("DD-MM-YYYY HH:mm:ss")}`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs = {12} >
                        <TableContainer style={{maxHeight: "650px"}}>
                        <Table className="table table-striped" >
                            <TableHead>
                            <TableRow>
                                <TableCell  className="text-center align-top">Especialista </TableCell>
                                <TableCell  className="text-center align-top">Cantidad </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {selectEnableAddress.benefits.map((benefistRow, index) => (
                                <TableRow
                                key={`${index}-workRows`}
                                >
                                <TableCell className="text-center align-middle">{benefistRow.nombre_corto} </TableCell>
                                <TableCell className="text-center align-middle">{benefistRow.cantidad}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 0 0"}}
                >
                    <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        color="primary"
                        onClick={handleAssing}
                    >
                        Asignar
                    </Button>
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500]}}
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
      </Modal>
  );
}