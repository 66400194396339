import React, { useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import api from "utils/API";
import {
  getListRequestPatient,
} from 'actions/getListAction';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const DialogDeleteRequest = props => {
  const {
    openDialog,
    closeDialog,
    closeMainModal,
    tempRow,
    dataPatient,
  } = props;
  const dispatch = useDispatch()
  const [openAnimation, setOpenAnimation] = useState(false);

  const cancelRequest = () => {
    setOpenAnimation(true);
    api
      .delete(
        `/api/paciente/${tempRow.id_paciente}/solicitudes/traslados/${tempRow.id}/`
      )
      .then(() => {
        getListRequestPatient(dataPatient, dispatch);
        setOpenAnimation(false);
        closeDialog();
        closeMainModal();
      })

  };

  return (
    <Dialog
      onClose={closeDialog}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      disableBackdropClick
    >
      {openAnimation ? (
        <DialogTitle id="customized-dialog-title" />
      ) : (
        <DialogTitle id="customized-dialog-title" onClose={closeDialog} />
      )}
      <DialogContent style={{ marginTop: "1em" }}>
        <Typography gutterBottom>
          ¿Estás seguro que quieres eliminar el envío?
        </Typography>
      </DialogContent>
      {openAnimation ? (
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          <CircularProgress />
        </DialogActions>
      ) : (
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={cancelRequest}
          >
            Si, eliminar solicitud traslado
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={() => {
              closeMainModal();
              closeDialog();
            }}
          >
            No, atrás
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogDeleteRequest;

DialogDeleteRequest.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  closeMainModal: PropTypes.func,
  name: PropTypes.string,
  tempRow: PropTypes.object,
  setauxData: PropTypes.func,
};
