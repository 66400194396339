import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import apiform from "utils/APIForm";



export default function ModalCancelRequest(props) {
  const classes = useStyles();

  const { open, closeModal, divisionRequest, moduleType } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  function updateDivision() {

    const formData = new FormData();

    formData.append("cantidad_fraccionar", divisionRequest.cantidad_fraccionar);
    formData.append("observaciones", divisionRequest.observaciones);
    formData.append("cantidad_resultante", divisionRequest.cantidad_resultante);
    formData.append("estado", "Esperar a egresos");
    formData.append(
      "id_producto_fraccionar",
      divisionRequest.id_producto_fraccionar.id
    );
    formData.append(
      "id_producto_resultante",
      divisionRequest.id_producto_resultante.id
    );
    formData.append("area", "Egreso");

    apiform
      .put(`api/solicitudfraccionamiento/${divisionRequest.id}`, formData)
      .then(response => {
        window.location.href = `/admin/income/divisions/${moduleType}`;
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Cancelar envío</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div style={{ marginBottom: "10rem" }}>
          <p className="text-center font-weight-bold">
            ¿Está seguro que desea cancelar en envío?
          </p>
          <p className="text-center">
            Tendrá que notificar nuevamente a egresos.
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateDivision} color="primary">
          Si
        </Button>
        <Button onClick={closeModal} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCancelRequest.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  divisionRequest: PropTypes.array
};
