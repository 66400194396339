import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment-timezone";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import { checkRut } from "utils/validationFunction";
import api from "utils/API";
import "moment/locale/es";
moment.locale("es");

export default function FormEditPatient({
  data,
  setdata,
  setmessage,
  openAnimation,
  setOpenAnimation,
  closeModal,
  setpatient,
}) {
  const [nombre, setnombre] = useState(data.nombre);
  const [apellido_paterno, setapellido_paterno] = useState(
    data.apellido_paterno
  );
  const [apellido_materno, setapellido_materno] = useState(
    data.apellido_materno
  );
  const [rut, setrut] = useState(data.rut);
  const [rutError, setrutError] = useState("");
  const [nacimiento, setnacimiento] = useState(data.nacimiento);
  const [sexo, setsexo] = useState({ value: data.sexo, label: data.sexo });
  const [direccion_calle, setdireccion_calle] = useState(data.direccion_calle);
  const [direccion_numero, setdireccion_numero] = useState(
    data.direccion_numero
  );
  const [direccion_block, setdireccion_block] = useState(data.direccion_block);
  const [direccion_casa_depto, setdireccion_casa_depto] = useState(
    data.direccion_casa_depto
  );
  const [direccion_ciudad, setdireccion_ciudad] = useState(
    data.direccion_ciudad
  );
  const [direccion_comuna, setdireccion_comuna] = useState({});
  const [direccion_region, setdireccion_region] = useState({});
  const [id_zona, setzona] = useState(data.id_zona);
  const [listComuna, setlistComuna] = useState([]);
  const [listRegion, setlistRegion] = useState([]);
  const [dictComuna, setdictComuna] = useState([]);
  const [initialCharge, setinitialCharge] = useState(true);
  const [validation, setvalidation] = useState(false);
  const foto_paciente = null;
  const [loading, setloading] = useState(false);

  const listSex = useSelector(state => state.currentList.LISTA_SEXO);

  useEffect(() => {
    validateForm();
    if (initialCharge) {
      initialData();
    }
  });

  const initialData = () => {
    setinitialCharge(false);
    setloading(false);
    const requestRegion = api.get(`api/locaciones/regiones/`);
    Promise.all([requestRegion])
      .then((request) => {
        setlistRegion(
          request[0].data.map((row) => {
            if (data.id_region === row.id) {
              setdireccion_region({ value: row.id, label: row.nombre });
            }
            return { value: row.id, label: row.nombre };
          })
        );

        if (data.id_region) {
          api
            .get(`api/locaciones/regiones/${data.id_region}/comunas/`)
            .then((response) => {
              const tempDictComuna = {};
              setlistComuna(
                response.data.map((row) => {
                  tempDictComuna[row.id] = row.id;
                  if (data.id_comuna === row.id) {
                    setdireccion_comuna({ value: row.id, label: row.nombre });
                  }
                  return { value: row.id, label: row.nombre };
                })
              );
              setdictComuna(tempDictComuna);
              setloading(true);
            });
        } else {
          setloading(true);
        }
      })

  };

  const validateForm = () => {
    const arrayValidation = [
      nombre !== "",
      apellido_paterno !== "",
      apellido_materno !== "",
      rut !== "",
      rutError === "",
      nacimiento !== "",
      sexo !== "",
      direccion_calle !== "",
      direccion_numero !== "",
      direccion_block !== "",
      direccion_casa_depto !== "",
      direccion_ciudad !== "",
      direccion_comuna.value !== undefined,
      direccion_region.value !== undefined,
    ];
    if (arrayValidation.every((item) => item === true)) {
      setvalidation(true);
    } else {
      setvalidation(false);
    }
  };

  const updateValue = () => {
    const body = { ...data };
    body.nombre = nombre;
    body.apellido_paterno = apellido_paterno;
    body.apellido_materno = apellido_materno;
    body.rut = rut;
    body.nacimiento = nacimiento;
    body.sexo = sexo.value;
    body.direccion_calle = direccion_calle;
    body.direccion_numero = direccion_numero;
    body.direccion_block = direccion_block;
    body.direccion_casa_depto = direccion_casa_depto;
    body.direccion_ciudad = direccion_ciudad;
    body.direccion_comuna = direccion_comuna.label;
    body.direccion_region = direccion_region.label;
    body.id_comuna = direccion_comuna.value;
    body.id_region = direccion_region.value;
    body.id_comuna_id = direccion_comuna.value;
    body.id_region_id = direccion_region.value;
    body.id_zona = id_zona;
    delete body["foto_paciente"];
    let formData = new FormData();
    formData.append("foto_paciente", foto_paciente);
    setdata(body);
    setpatient(body);
    setOpenAnimation(true);
    setmessage("Actualizando datos");
    const requestArray = [];
    requestArray.push(api.patch(`api/paciente/${data.id}`, body));
    if (foto_paciente) {
      requestArray.push(api.patch(`api/paciente/${data.id}`, formData));
    }
    Promise.all(requestArray).then((request) => {
      if (foto_paciente) {
        body.foto_paciente = request[1].data.foto_paciente;
      }
      setdata(body);
      setpatient(body);
      setmessage("Datos actualizados");
      setOpenAnimation(false);
    });
  };

  const handleOnChange = (value, set) => {
    set(value);
  };

  const handleRegion = (value) => {
    setdireccion_region(value);
    setdireccion_comuna({});
    setOpenAnimation(true);
    api
      .get(`api/locaciones/regiones/${value.value}/comunas/`)
      .then((response) => {
        const tempDictComuna = {};
        setlistComuna(
          response.data.map((row) => {
            tempDictComuna[row.id] = row;
            return { value: row.id, label: row.nombre };
          })
        );
        setdictComuna(tempDictComuna);
        setOpenAnimation(false);
      });
  };

  const handleComuna = (value) => {
    setdireccion_comuna(value);
    const comuna = dictComuna[value.value];
    if (comuna.id_zona) {
      setzona(comuna.id_zona.id);
    } else {
      setzona(null);
    }
  };

  const handleOnChangeRut = (value, set) => {
    setrutError(checkRut(value));
    set(value);
  };

  return (
    <Grid item xs={12}>
      {loading ? (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
          item
          xs={12}
        >
          <Grid item xs={4}>
            <DetailText
              label={`Nombres`}
              placeholder={`Nombres`}
              value={nombre}
              onchange={(e) => {
                handleOnChange(e.target.value, setnombre);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DetailText
              label={`Apellido Paterno `}
              placeholder={`Apellido Paterno `}
              value={apellido_paterno}
              onchange={(e) => {
                handleOnChange(e.target.value, setapellido_paterno);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DetailText
              label={`Apellido Materno `}
              placeholder={`Apellido Materno `}
              value={apellido_materno}
              onchange={(e) => {
                handleOnChange(e.target.value, setapellido_materno);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DetailText
              label={`Rut `}
              placeholder={`Ej: 12345678-9`}
              value={rut}
              onchange={(e) => {
                handleOnChangeRut(e.target.value, setrut);
              }}
              error={rutError}
            />
          </Grid>
          <Grid item xs={4}>
            <DetailText
              label={`Fecha de nacimiento	(AAAA-MM-DD)`}
              placeholder={`AAAA-MM-DD`}
              value={nacimiento}
              onchange={(e) => {
                handleOnChange(e.target.value, setnacimiento);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DetailSelect
              label="Sexo"
              value={sexo}
              onchange={(value) => {
                handleOnChange(value, setsexo);
              }}
              list={listSex}
            />
          </Grid>
          <Grid item xs={4}>
            <DetailText
              label={`Dirección calle`}
              placeholder={`Dirección calle`}
              value={direccion_calle}
              onchange={(e) => {
                handleOnChange(e.target.value, setdireccion_calle);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DetailText
              label={`Departamento/Casa`}
              placeholder={`Departamento/Casa`}
              value={direccion_casa_depto}
              onchange={(e) => {
                handleOnChange(e.target.value, setdireccion_casa_depto);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DetailText
              label={`Block/Villa/Poblacion/Sector`}
              placeholder={`Block/Villa/Poblacion/Secto`}w
              value={direccion_block}
              onchange={(e) => {
                handleOnChange(e.target.value, setdireccion_block);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DetailText
              label={`Dirección número`}
              placeholder={`Dirección número`}
              value={direccion_numero}
              onchange={(e) => {
                handleOnChange(e.target.value, setdireccion_numero);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DetailText
              label={`Ciudad`}
              placeholder={`Ciudad`}
              value={direccion_ciudad}
              onchange={(e) => {
                handleOnChange(e.target.value, setdireccion_ciudad);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {openAnimation ? (
              <Grid item container justify="center">
                <CircularProgress />
              </Grid>
            ) : (
              <DetailSelect
                label="Comuna"
                value={direccion_comuna}
                onchange={(value) => {
                  handleComuna(value);
                }}
                list={listComuna}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <DetailSelect
              label="Región"
              value={direccion_region}
              onchange={(value) => {
                handleRegion(value);
              }}
              list={listRegion}
            />
          </Grid>
          {openAnimation ? (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              item
              xs={12}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
              item
              xs={12}
            >
              {validation ? (
                <Button
                  mr={2}
                  variant="contained"
                  className="text-white"
                  style={{ backgroundColor: green[500] }}
                  onClick={updateValue}
                >
                  Guardar
                </Button>
              ) : (
                <Button
                  disabled
                  mr={2}
                  variant="contained"
                  className="text-white"
                >
                  Guardar
                </Button>
              )}
              <Button
                mr={2}
                variant="contained"
                color="primary"
                style={{ marginRight: "10px" }}
                className="text-white"
                onClick={closeModal}
              >
                Cerrar
              </Button>
            </Grid>
          )}
        </Grid>
      ) : (
        <LoadInfo />
      )}
    </Grid>
  );
}

FormEditPatient.propTypes = {
  data: PropTypes.object,
  setdata: PropTypes.func,
  setmessage: PropTypes.func,
  openAnimation: PropTypes.bool,
  setOpenAnimation: PropTypes.func,
  closeModal: PropTypes.func,
  setpatient: PropTypes.func,
};
