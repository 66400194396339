import React from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ModalMessageBodega(props) {
  const { open, closeModal } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const scroll = "paper";

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">
        Pendiente guía de bodega
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <p className="text-center font-weight-bold">
          El encargado de bodega debe adjuntar su guía de despacho.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalMessageBodega.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_order: PropTypes.number,
};
