import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "../Widgets/FichaPersonal/viewTitle";
import CardTitle from "../Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "../Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ModalEdidIsapreContact from "components/Covenants/ModalEditIsapreContact";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ModalPackage from "components/Covenants/ModalPackage";
import ModalTherapy from "components/Covenants/ModalTherapy";
import ModalEditPackage from "components/Covenants/Packages/ModalEditPackage";
import ModalEditTherapy from "components/Covenants/Therapies/ModalEditTherapy";
import SearchCovenants from "components/Covenants/SearchCovenants";

import TabCommon from "views/Widgets/Common/TabCommon";
import TableComponent from "views/Widgets/Common/TableComponent";
import CardDetailInfo from "views/Widgets/Common/CardDetailInfo";
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import {toMoneyFormat} from "utils/formatFunctions";
import allActions from "actions";

export default function CovenantsDetail() {
  const dispatch = useDispatch();
  let history = useHistory();
  const { id } = useParams();
  const id_paquetizacion = parseInt(id, 10);

  const [packages, setPackages] = useState([]);
  const [isapre, setIsapre] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [id_isapre, setId_Isapre] = useState();
  const [therapies, setTherapies] = useState([]);
  const [rehabilitations, setRehabilitations] = useState([]);
  const [nombre_paquetizacion, setNombre_paquetizacion] = useState("");
  const [modalPackage, setModalPackage] = useState(false);
  const [modalTherapy, setModalTherapy] = useState(false);
  const [modalRehabilitation, setModalRehabilitation] = useState(false);
  const [searchPackage, setSearchPackage] = useState("");
  const [searchTherapy, setSearchTherapy] = useState("");
  const [searchRehabilitation, setSearchRehabilitation] = useState("");
  const [id_paquete, setId_paquete] = useState("");
  const [modal_edit_package, setModal_edit_package] = useState(false);
  const [id_therapy, setId_therapy] = useState("");
  const [modal_edit_therapy, setModal_edit_therapy] = useState(false);
  const [id_rehabilitation, setId_rehabilitation] = useState("");
  const [modal_edit_rehabilitation, setModal_edit_rehabilitation] = useState(false);

  const dictComplexities = useSelector(state => state.currentList.DICCIONARIO_COMPLEJIDADES);

  useEffect(() => {
    setLoading(false);
    const requestPackage = api.get(`api/clinica/paquetizaciones/${id}/paquetes/`)
    const requestRehabilitation = api.get(`api/clinica/paquetizaciones/${id}/rehabilitaciones/`)
    const requestTherapy = api.get(`api/clinica/paquetizaciones/${id}/terapias/`)
    const requestPackaging = api.get(`api/paquetizaciones/${id}`)
    Promise.all([requestPackage, requestRehabilitation, requestTherapy, requestPackaging]).then((request) => {
      setPackages(request[0].data);
      setRehabilitations(request[1].data);
      setTherapies(request[2].data);
      setId_Isapre(request[3].data.id_convenio_isapre.id);
      setNombre_paquetizacion(request[3].data.nombre);
      api.get(`api/convenioisapre/${request[3].data.id_convenio_isapre.id}`).then(result => {
        setLoading(true);
        setIsapre(result.data);
      })
    }).catch((error) => {})

  }, [id]);

  const reloadPackage = () => {

    api.get(`api/clinica/paquetizaciones/${id}/paquetes/`).then((response) => {
      setPackages(response.data);

    });
  }

  const reloadTherapy = () => {

    api.get(`api/clinica/paquetizaciones/${id}/terapias/`).then((response) => {
      setTherapies(response.data);

    });
  }

  const reloadRehabilitation = () => {

    api.get(`api/clinica/paquetizaciones/${id}/rehabilitaciones/`).then((response) => {
      setRehabilitations(response.data);

    });
  }

  const setmodalPackage = (id_paquete, modal_edit) => {
    setId_paquete(id_paquete);
    setModal_edit_package(modal_edit);

  };

  const setmodalTherapy = (id_therapy, modal_edit) => {
    setId_therapy(id_therapy);
    setModal_edit_therapy(modal_edit);

  };

  const setmodalRehabilitation = (id_therapy, modal_edit) => {
    setId_rehabilitation(id_therapy);
    setModal_edit_rehabilitation(modal_edit);

  };

  const goPackage = (link, complexities) => {
    history.push({
      pathname: link,
      state: {
        fromConvenant: id_paquetizacion,
        nombre_paquetizacion: nombre_paquetizacion,
        nombre_isapre: isapre.nombre,
        complexity: complexities,
      }
    });
  }

  let isapreData = [];
  let contactData = [];
  let listTab = [];
  if (loading){
    isapreData = [
      {label: "Nombre", value: isapre.nombre},
      {label: "Fecha registro", value: isapre.fecha_registro},
      {label: "RUT", value: isapre.rut},
      {label: "Dirección", value: isapre.direccion},
      {label: "Representante", value: isapre.representante},
      {label: "RUT representante", value: isapre.rut_representante},
    ]

    contactData = [
      {label: "Nombre contacto 1", value: isapre.nombre_contacto1},
      {label: "Mail contacto 1", value: isapre.contacto1},
      {label: "Nombre contacto 2", value: isapre.nombre_contacto2},
      {label: "Mail contacto 2", value: isapre.contacto2},
      {label: "Nombre contacto 3", value: isapre.contacto3},
      {label: "RUT representante", value: isapre.rut_representante},
      {_value_: <Button
          variant="contained"
          color={"primary"}
          onClick={() => setModalEdit(true)}
        >
          Editar
      </Button>
      }
    ]

    const headerPackages = [
      {label: "Estado"},
      {label: "Codigo"},
      {label: "Descripción"},
      {label: "Descripción Facturación"},
      {label: "Zona"},
      {label: "Complejidad"},
      {label: "Dia cama"},
      {label: "Total excento"},
      {label: "Insumos"},
      {label: "Medicamentos"},
      {label: "Total afecto"},
      {label: "Total por mes"},
      {label: "Total por día"},
      {eval: false, label: "Editar"},
      {eval: false, label: " Agregar prestación", style:{ minWidth: "200px" }},
    ]

    const searchPackagesFilter = packages.map((row) => {
      const _tempButton_ = <Button
        variant={"outlined"}
        color={"primary"}
        onClick={() => setmodalPackage(row.id, true)}
      >
        Editar
      </Button>

      const _tempLink_ = <Button
        variant={"outlined"}
        color={"primary"}
        onClick={() => goPackage(`/admin/packages/${row.id}`, dictComplexities[row.complejidad]?.label)}
      >
        Agregar prestación
      </Button>

      const _status_ = <Typography style={{color: row.estado == "Activo" ? "green" : "red"}}>{row.estado}</Typography>

      //eval is not use in table only condition to filter
      return [
        {eval:true, value: row.estado, _value_: _status_},
        {eval:true, value: row.codigo_paquete},
        {eval:true, value: row.descripcion !== "null" ? row.descripcion : ""},
        {eval:true, value: row.descripcion_facturacion !== "null" ? row.descripcion_facturacion : ""},
        {eval:true, value: row.zona},
        {eval:true, value: row.complejidad},
        {eval:true, value: row.dia_cama},
        {eval:true, value: row.total_excento},
        {eval:true, value: row.insumos},
        {eval:true, value: row.medicamentos},
        {eval:true, value: row.total_afecto},
        {eval:true, value: row.total_mes},
        {eval:true, value: row.total_dia},
        {eval:false, _value_: _tempButton_},
        {eval:false, _value_: _tempLink_},
      ]
    }).filter(row => row.some(item => (item.eval && item.value?.toString().toLowerCase().indexOf(searchPackage.toLowerCase()) !== -1 )));

    const headerTherapy = [
      {label: "Zona"},
      {label: "KTR (Hábil)"},
      {label: "KTR (Inhábil)"},
      {label: "KTM"},
      {label: "Fono"},
      {label: "TO"},
      {label: "EU (Hábil)"},
      {label: "EU (Inhábil)"},
      {label: "Médio pediatra (Hábil)"},
      {label: "Médico pediatra (Inhábil)"},
      {label: "Médico internista adulto (Hábil)"},
      {label: "Médico internista adulto (Inhábil)"},
      {eval: false, label: "Editar"},
    ]

    const searchTherapyFilter = therapies.map((row) => {
      const _tempbutton_ = <Button
        variant={"outlined"}
        color={"primary"}
        onClick={() => setmodalTherapy(row.id, true)}
      >
        Editar
      </Button>

      return [
        {eval:true, value: row.zona},
        {eval: false, value: toMoneyFormat(row.kine_resp_habil)},
        {eval: false, value: toMoneyFormat(row.kine_resp_inhabil)},
        {eval: false, value: toMoneyFormat(row.kine_motor)},
        {eval: false, value: toMoneyFormat(row.fonoaudiologo)},
        {eval: false, value: toMoneyFormat(row.terapia_ocupacional)},
        {eval: false, value: toMoneyFormat(row.enf_urgencias_habil)},
        {eval: false, value: toMoneyFormat(row.enf_urgencias_inhabil)},
        {eval: false, value: toMoneyFormat(row.pediatra_habil)},
        {eval: false, value: toMoneyFormat(row.pediatra_inhabil)},
        {eval: false, value: toMoneyFormat(row.internista_adulto_habil)},
        {eval: false, value: toMoneyFormat(row.internista_adulto_inhabil)},
        {eval: false, _value_: _tempbutton_},
      ]
    }).filter(row => row.some(item => (item.eval && item.value?.toString().toLowerCase().indexOf(searchTherapy.toLowerCase()) !== -1 )));

    const headerRehabilitation = [
      {label: "Zona"},
      {label: "KTR (Hábil)"},
      {label: "KTR (Inhábil)"},
      {label: "KTM"},
      {label: "Fono"},
      {label: "TO"},
      {label: "EU (Hábil)"},
      {label: "EU (Inhábil)"},
      {label: "Médio pediatra (Hábil)"},
      {label: "Médico pediatra (Inhábil)"},
      {label: "Médico internista adulto (Hábil)"},
      {label: "Médico internista adulto (Inhábil)"},
      {eval: false,  label: "Editar"},
    ]

    const searchRehabilitationFilter = rehabilitations.map((row) => {
      const _tempbutton_ = <Button
        variant={"outlined"}
        color={"primary"}
        onClick={() => setmodalRehabilitation(row.id, true)}
      >
        Editar
      </Button>

      return [
        {eval:true, value: row.zona},
        {eval: false, value: toMoneyFormat(row.kine_resp_habil)},
        {eval: false, value: toMoneyFormat(row.kine_resp_inhabil)},
        {eval: false, value: toMoneyFormat(row.kine_motor)},
        {eval: false, value: toMoneyFormat(row.fonoaudiologo)},
        {eval: false, value: toMoneyFormat(row.terapia_ocupacional)},
        {eval: false, value: toMoneyFormat(row.enf_urgencias_habil)},
        {eval: false, value: toMoneyFormat(row.enf_urgencias_inhabil)},
        {eval: false, value: toMoneyFormat(row.pediatra_habil)},
        {eval: false, value: toMoneyFormat(row.pediatra_inhabil)},
        {eval: false, value: toMoneyFormat(row.internista_adulto_habil)},
        {eval: false, value: toMoneyFormat(row.internista_adulto_inhabil)},
        {eval: false, _value_: _tempbutton_},
      ]
    }).filter(row => row.some(item => (item.eval && item.value?.toString().toLowerCase().indexOf(searchRehabilitation.toLowerCase()) !== -1 )));


    listTab = [
      {label:"Paquetes",
      _value_: <>
        <GridContainer justify="center">
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => setModalPackage(true)}
          >
            Agregar paquete
          </Button>
        </GridContainer>
        <GridContainer justify="center" style={{ marginBottom: "2em" }}>
          <SearchCovenants
            search={searchPackage}
            setSearch={setSearchPackage}
            name={"paquetes"}
          />
        </GridContainer>
        <TableComponent
          headers={headerPackages}
          data={searchPackagesFilter}
        />
      </>
      },
      {label:"Terapia permanente",
      _value_: <>
        <GridContainer justify="center">
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => setModalTherapy(true)}
          >
            Agregar terapia permanente
          </Button>
        </GridContainer>
        <GridContainer justify="center" style={{ marginBottom: "2em" }}>
          <SearchCovenants
            search={searchTherapy}
            setSearch={setSearchTherapy}
            name={"terapias"}
          />
        </GridContainer>
        <TableComponent
          headers={headerTherapy}
          data={searchTherapyFilter}
        />
      </>
      },
      {label:"Rehabilitación extra",
      _value_: <>
        <GridContainer justify="center">
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => setModalRehabilitation(true)}
          >
            Agregar rehabilitación extra
          </Button>
        </GridContainer>
        <GridContainer justify="center" style={{ marginBottom: "2em" }}>
          <SearchCovenants
            search={searchRehabilitation}
            setSearch={setSearchRehabilitation}
            name={"rehabilitaciones"}
          />
        </GridContainer>
          <TableComponent
            headers={headerRehabilitation}
            data={searchRehabilitationFilter}
          />
      </>
      },
    ]

}

  return (
    <GridContainer>
      <ViewTitle
        title="Paquetizaciones"
        message="En esta sección podrás revisar las paquetizaciones"
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/admin/convenants/">
              Convenios
            </Link>
            <Typography color="textPrimary">
              Paquetizaciones {isapre.nombre} {nombre_paquetizacion}
            </Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center">
              <CardDetailInfo
                dataList={isapreData}
                styleCard={{ width: "25rem", margin: "2rem" }}
                title="Datos de la isapre"
              />
              <CardDetailInfo
                dataList={contactData}
                styleCard={{ width: "25rem", margin: "2rem" }}
                title="Contactos Isapre"
              />
            </GridContainer>
            <CardBody>
              <TabCommon
                data={listTab}
              />
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
        {modalEdit && (
          <ModalEdidIsapreContact
            open={modalEdit}
            closeModal={() => setModalEdit(false)}
            id_isapre={id_isapre}
            id_paquetizacion={id_paquetizacion}
            setIsapre={setIsapre}
          />
        )}
        {modalPackage && (
        <ModalPackage
          open={modalPackage}
          closeModal={() => setModalPackage(false)}
          id_paquetizacion={id_paquetizacion}
          packages={packages}
          reloadPackage={reloadPackage}
        />
      )}
      {modalTherapy && (
        <ModalTherapy
          open={modalTherapy}
          closeModal={() => setModalTherapy(false)}
          id_paquetizacion={id_paquetizacion}
          therapies={therapies}
          type="TerapiaPermanente"
          title="Crear terapia permanente"
          reloadTherapy={reloadTherapy}
        />
      )}
      {modalRehabilitation && (
        <ModalTherapy
          open={modalRehabilitation}
          closeModal={() => setModalRehabilitation(false)}
          id_paquetizacion={id_paquetizacion}
          therapies={rehabilitations}
          type="RehabilitacionExtra"
          title="Crear rehabilitación extra"
          reloadTherapy={reloadRehabilitation}
        />
      )}
      {modal_edit_package && (
        <ModalEditPackage
          open={modal_edit_package}
          closeModal={() => setModal_edit_package(false)}
          id_paquete={id_paquete}
          id_paquetizacion={id_paquetizacion}
          reloadPackage={reloadPackage}
        />
      )}
      {modal_edit_therapy && (
        <ModalEditTherapy
          open={modal_edit_therapy}
          closeModal={() => setModal_edit_therapy(false)}
          id_therapy={id_therapy}
          id_paquetizacion={id_paquetizacion}
          title={"Editar terapia permanente"}
          reloadTherapy={reloadTherapy}
        />
      )}
      {modal_edit_rehabilitation && (
        <ModalEditTherapy
          open={modal_edit_rehabilitation}
          closeModal={() => setModal_edit_rehabilitation(false)}
          id_therapy={id_rehabilitation}
          id_paquetizacion={id_paquetizacion}
          title={"Editar rehabilitación extra"}
          reloadTherapy={reloadRehabilitation}
        />
      )}
      </GridItem>
    </GridContainer>
  );
}
