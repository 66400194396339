import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import PropTypes from "prop-types";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function TableStateTENS({
  arrayWorkers,
  columnHeader,
  dictColumnHeader,
  changeButtons=false,
}) {
  const classes = useStyles();
  const [initialCharge, setinitialCharge] = useState(true);
  const [headerHeight, setheaderHeight] = useState(0)
  const ref = useRef(null);

  useEffect(() => {
    if (initialCharge) {
      initialData();
    }
    setheaderHeight(ref.current.clientHeight)
  });

  const initialData = () => {
    setinitialCharge(false);
  };

  return (
    <Card>
      <CardBody stats className={classes.cardFooter} style={{padding: "1rem 1rem"}}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
          style={{ margin: "10px 0 10x 0" }}
        >
          <h5 style={{ margin: "0 0 0px 95px" }}>Estado TENS</h5>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          item
          xs={12}
        >
          <Grid item xs={2}>
            <TableContainer>
              <Table size={"small"} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ width: "95px", height: `${headerHeight}px` }}
                      className="text-center align-top"
                    >
                      TENS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {arrayWorkers.map((RowWorker, index) => (
                    <TableRow key={`${index}-workRows`}>
                      <TableCell
                        className="text-center align-middle"
                        style={{ width: "95px", height: "73px" }}
                      >
                        {`${RowWorker.first_name} ${RowWorker.last_name}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={10}>
            <TableContainer style={{}}>
              <Table size={"small"} stickyHeader aria-label="sticky table">
                <TableHead ref={ref}>
                  <TableRow>
                    {columnHeader.map((row, index) => (
                      <TableCell
                        key={`${index}-header`}
                        className="text-center align-top"
                        style={{ width: "95px", height: "85px" }}
                      >
                        {`${row.label}`}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {arrayWorkers.map((RowWorker, index) => (
                    <TableRow key={`${index}-workRows`}>
                      {columnHeader.map((rowColumn, Workerindex) =>
                        dictColumnHeader[RowWorker.id] ? (
                          dictColumnHeader[RowWorker.id][rowColumn.value] ? (
                            <TableCell
                              key={`${index}-${Workerindex}-work`}
                              id={`${index}-${Workerindex}-work`}
                              className="text-center align-middle"
                              style={{
                                position: "relative",
                                width: "95px",
                                height: "73px"
                              }}
                            >
                              {dictColumnHeader[RowWorker.id][rowColumn.value]}
                            </TableCell>
                          ) : (
                            <TableCell
                              key={`${index}-${Workerindex}-work`}
                              id={`${index}-${Workerindex}-work`}
                              className="text-center align-middle"
                              style={{ width: "95px", height: "73px" }}
                            >
                              {" "}
                            </TableCell>
                          )
                        ) : (
                          <TableCell
                            key={`${index}-${Workerindex}-work`}
                            id={`${index}-${Workerindex}-work`}
                            className="text-center align-middle"
                            style={{ width: "95px", height: "73px" }}
                          >
                            {" "}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))}
                  {changeButtons && <TableRow></TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
    </CardBody>
    </Card>
  );
}

TableStateTENS.propTypes = {
  arrayWorkers: PropTypes.array,
  columnHeader: PropTypes.array
};
