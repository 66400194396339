import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import ShowData from "./ShowData";
import DateFilterStart from "./DateFilterStart";
import DateFilterEnd from "./DateFilterEnd";
import ButtonsFilter from "./ButtonsFilter";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  buttonMargin: {
    marginTop: "3px",
    marginBottom: "3px"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const GraphicComponent = props => {
  const classes = useStyles();
  const {
    activos,
    graphicData,
    startDate,
    endDate,
    handleDateStartChange,
    handleDateEndChange,
    size,
    weight,
    temperature,
    heartRate,
    breathingFrecuency,
    oxygenSaturation,
    bloodPressure,
    bloodPressureSystolic,
    bloodPressureDiastolic,
    pain,
    handleChangeFilters,
    checkFilter,
    alertSigns
  } = props;

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={1} />
      <Grid item xs={10} container>
        <Grid item xs={12} container justify="center">
          <Grid item xs={1} />
          <Grid item xs={4}>
            <DateFilterStart
              startDate={startDate}
              handleDateStartChange={handleDateStartChange}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <DateFilterEnd
              endDate={endDate}
              handleDateEndChange={handleDateEndChange}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container justify="center">
          <ButtonsFilter
            size={size}
            weight={weight}
            temperature={temperature}
            heartRate={heartRate}
            breathingFrecuency={breathingFrecuency}
            oxygenSaturation={oxygenSaturation}
            bloodPressure={bloodPressure}
            bloodPressureSystolic={bloodPressureSystolic}
            bloodPressureDiastolic={bloodPressureDiastolic}
            pain={pain}
            handleChangeFilters={handleChangeFilters}
            checkFilter={checkFilter}
            alertSigns={alertSigns}
          />
        </Grid>
        <Grid item xs={12} container justify="center">
          <Grid
            item
            xs={12}
            align="center"
            style={{ marginTop: "3em", marginBottom: "3em" }}
          >
            <ShowData graphicData={graphicData} activos={activos} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default GraphicComponent;

GraphicComponent.propTypes = {
  activos: PropTypes.array,
  graphicData: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  handleDateStartChange: PropTypes.func,
  handleDateEndChange: PropTypes.func,
  getFilterOne: PropTypes.array,
  getFilterTwo: PropTypes.array,
  size: PropTypes.bool,
  weight: PropTypes.bool,
  temperature: PropTypes.bool,
  heartRate: PropTypes.bool,
  breathingFrecuency: PropTypes.bool,
  oxygenSaturation: PropTypes.bool,
  bloodPressure: PropTypes.bool,
  bloodPressureDiastolic: PropTypes.bool,
  bloodPressureSystolic: PropTypes.bool,
  pain: PropTypes.bool,
  handleChangeFilters: PropTypes.func,
  checkFilter: PropTypes.bool,
  alertSigns: PropTypes.array
};
