import api from "utils/API";

export const getAllAuxList = (dispatch) => {
    const list_key = JSON.parse(localStorage.getItem("LISTA_KEY_AUXILIARES"))
    if (list_key){
        list_key.map((row) => {
            if (localStorage.getItem(row)){
                const valueStorage = JSON.parse(localStorage.getItem(row));
                dispatch({
                    type: row,
                    payload: valueStorage
                });
            }
        })
        dispatch({
            type: "LISTA_KEY_AUXILIARES",
            payload: list_key
        });

    } else {
        api.get("api/auxlistasDict/").then((response) => {
            const tempListKey = []
            Object.keys(response.data).map((key) => {
                const auxType = [];
                const auxDictType = {};
                const name_list = `LISTA_${key.toUpperCase().replace('LISTA_','')}`
                const name_dict = `DICCIONARIO_${name_list.replace('LISTA_','')}`
                response.data[key].map((row) => {
                    auxType.push({ value: row[0], label: row[1] });
                    auxDictType[row[0]] = { value: row[0], label: row[1] };
                });
                dispatch({
                    type: name_list,
                    payload: auxType
                });
                dispatch({
                    type: name_dict,
                    payload: auxDictType
                });
                localStorage.setItem(name_dict, JSON.stringify(auxDictType));
                localStorage.setItem(name_list, JSON.stringify(auxType));
                tempListKey.push(name_dict);
                tempListKey.push(name_list);
            });
            dispatch({
                type: "LISTA_KEY_AUXILIARES",
                payload: tempListKey
            });
            localStorage.setItem("LISTA_KEY_AUXILIARES", JSON.stringify(tempListKey));
        });
    };
}

export const getlistCharges = (dispatch) => {
    const auxType = JSON.parse(localStorage.getItem("LISTA_CARGOS"))
    const auxDictType = JSON.parse(localStorage.getItem("DICCIONARIO_CARGOS"))
    if (auxType){
        dispatch({
            type: "DICCIONARIO_CARGOS",
            payload: auxDictType
        });
        dispatch({
            type: "LISTA_CARGOS",
            payload: auxType
        });
    } else {
        api.get("api/cargos/").then((response) => {
            const auxType = [];
            const auxDictType = {};
            response.data.map((row) => {
                auxType.push({ value: row.id, label: row.nombre_corto });
                auxDictType[row.id] = row;
            });
            dispatch({
                type: "DICCIONARIO_CARGOS",
                payload: auxDictType
            });
            dispatch({
                type: "LISTA_CARGOS",
                payload: auxType
            });
            localStorage.setItem("LISTA_CARGOS", JSON.stringify(auxType));
            localStorage.setItem("DICCIONARIO_CARGOS", JSON.stringify(auxDictType));
        });
    }
}

export const getlistAFP = (dispatch) => {
    const auxType = JSON.parse(localStorage.getItem("LISTA_AFP"))
    const auxDictType = JSON.parse(localStorage.getItem("DICCIONARIO_AFP"))
    if (auxType){
        dispatch({
            type: "DICCIONARIO_AFP",
            payload: auxDictType
        });
        dispatch({
            type: "LISTA_AFP",
            payload: auxType
        });
    } else {
        api.get("api/listaafp/").then((result) => {
            const auxType = [];
            const auxDictType = {};
            result.data.map((row) => {
                auxType.push({ value: row.id, label: row.nombre });
                auxDictType[row.id] = row;
            });
            dispatch({
                type: "DICCIONARIO_AFP",
                payload: auxDictType
            });
            dispatch({
                type: "LISTA_AFP",
                payload: auxType
            });
            localStorage.setItem("LISTA_AFP", JSON.stringify(auxType));
            localStorage.setItem("DICCIONARIO_AFP", JSON.stringify(auxDictType));
        });
    }
}

export const getlistForecast = (dispatch) => {
    api.get("api/convenioisapre/").then((response) => {
        const auxType = [];
        const auxDictType = {};
        response.data.map((row) => {
            auxType.push({ value: row.id, label: row.nombre });
            auxDictType[row.id] = row;
        });
        dispatch({
            type: "LISTA_CONVENIO_ISAPRE",
            payload: auxType
        });
        dispatch({
            type: "DICCIONARIO_CONVENIO_ISAPRE",
            payload: auxDictType
        });
    });
}

export const getlistPackage = (dispatch) => {
    api.get("api/paquetes/").then((response) => {
        const auxType = [];
        const auxDictType = {};
        response.data.map((row) => {
            auxType.push({
                value: row.id,
                label: `${row.zona} ${row.complejidad} ${row.descripcion ? `- ${row.descripcion}` : ""}`,
                id_paquetizacion: row.id_paquetizacion,
                estado: row.estado
            });
            auxDictType[row.id] = row;
        });
        dispatch({
            type: "LISTA_PAQUETES",
            payload: auxType
        });
        dispatch({
            type: "DICCIONARIO_PAQUETES",
            payload: auxDictType
        });
    });
}

export const getlistPackaging = (dispatch) => {
    api.get("api/paquetizaciones/").then((response) => {
        const auxType = [];
        const auxDictType = {};
        response.data.map((row) => {
            auxType.push({
                value: row.id,
                label: row.nombre,
                id_convenio_isapre: row.id_convenio_isapre,
            });
            auxDictType[row.id] = row;
        });
        dispatch({
            type: "LISTA_PAQUETETIZACIONES",
            payload: auxType
        });
        dispatch({
            type: "DICCIONARIO_PAQUETETIZACIONES",
            payload: auxDictType
        });
    });
}

export const getListRequestPatient = (dataPatient, dispatch) => {
    api.get(`api/paciente/${dataPatient.id}/solicitudes/`).then((response) => {
        const temprArrayQualification = response.data.filter((row) => row.estado === "EsperandoHabilitacion")
        dispatch({
            type: "HABILITACION_PACIENTE_DISPONIBLE",
            payload: temprArrayQualification.length > 0
        });
        dispatch({
            type: "LISTA_SOLICITUDES_PACIENTE",
            payload: response.data,
        });
      });
}

export const getListOrderPatient = (dispatch, packageId, orders=null) => {
    if (orders){
        dispatch({
            type: "LISTA_PEDIDOS_PACIENTE",
            payload: orders
        });
    } else {
        dispatch({
            type: "LISTA_PEDIDOS_PACIENTE",
            payload: null,
        });
        api.get(`api/pedidosprogramados/?paquete_paciente=${packageId}`).then((response) => {
            dispatch({
                type: "LISTA_PEDIDOS_PACIENTE",
                payload: response.data,
            });
        })
    }
};

export const getlistWarehouseManagers = (dispatch) => {
    api.get("api/bodega/equipos/encargados/").then((response) => {
        const auxType = [];
        response.data.map((row) => {
            auxType.push({ value: row.id, label: row.nombre_completo });
        });
        dispatch({
            type: "LISTA_ENCARGADOS_BODEGA",
            payload: auxType
        });

    });
}

export const getListEquipment = (dispatch, charge=false) => {
    if (!charge){
        dispatch({
            type: "CARGANDO_EQUIPOS",
            payload: true
        });
        dispatch({
            type: "LISTA_EQUIPOS",
            payload: []
        });
        dispatch({
            type: "DICCIONARIO_EQUIPOS",
            payload: {}
        });
        api.get("api/equipos/tipos/").then((response) => {
            const auxType = [];
            const auxDictType = {};
            response.data.map((row) => {
                const aux_row = { value: row.id, label: `(${row.SKU}) - ${row.descripcion} -  ${row.tipo_producto}/${row.subtipo_producto}` }
                auxType.push(aux_row);
                auxDictType[row.id] = row;
            });
            dispatch({
                type: "DICCIONARIO_EQUIPOS",
                payload: auxDictType
            });
            dispatch({
                type: "LISTA_EQUIPOS",
                payload: auxType
            });
            dispatch({
                type: "CARGANDO_EQUIPOS",
                payload: false
            });
        });
    }
}

const procesSuppliesMedicines = (dispatch, data) => {
    const auxTypeMedicine = [];
    const auxTypeSupplies = [];
    const auxTypeFood = [];
    const auxDictType = {};
    data.map((row) => {
        if (row.valor_con_iva > 0) {
            const aux_row = { value: row.id, label: `(${row.SKU}) - (stock: ${row.stock_producto}) ${row.descripcion_producto} - valor (IVA): $${row.valor_con_iva}` }
            if (row.descripcion_producto.toLowerCase().includes("magistral") && ["Medicamentos", "Insumos", "Articulos", "Alimentos"].includes(row.grupo_producto)) {
                auxTypeMedicine.push(aux_row);
            } else if(row.descripcion_producto.toLowerCase().includes("cloruro") && ["Medicamentos", "Insumos", "Articulos", "Alimentos"].includes(row.grupo_producto)){
                auxTypeSupplies.push(aux_row);
            } else if(row.grupo_producto === "Medicamentos"){
                auxTypeMedicine.push(aux_row);
            } else if (["Insumos", "Articulos", "Alimentos"].includes(row.grupo_producto)){
                auxTypeSupplies.push(aux_row);
            }
            auxDictType[row.id] = row;
            if(row.grupo_producto === "Alimentos"){
                auxTypeFood.push(aux_row);
            }
        }
    });
    dispatch({
        type: "LISTA_MEDICAMENTOS",
        payload: auxTypeMedicine
    });
    dispatch({
        type: "LISTA_INSUMOS",
        payload: auxTypeSupplies
    });
    dispatch({
        type: "LISTA_ALIMENTOS",
        payload: auxTypeFood
    });
    dispatch({
        type: "DICCIONARIO_INSUMOS_MEDICAMENTOS",
        payload: auxDictType
    });
    dispatch({
        type: "CARGANDO_MEDICAMENTOS",
        payload: false
    });
    localStorage.setItem("DICCIONARIO_INSUMOS_MEDICAMENTOS", JSON.stringify(auxDictType));
}

export const getListSuppliesMedicines = (dispatch, charge=false, reStock=false) => {
    if (!charge){
        const dictType = JSON.parse(localStorage.getItem("DICCIONARIO_INSUMOS_MEDICAMENTOS"))
        let list_dict = []
        if (dictType){
            list_dict = Object.values(dictType)
        }
       
        if (list_dict.length === 0 || reStock){
            dispatch({
                type: "CARGANDO_MEDICAMENTOS",
                payload: true
            });
            dispatch({
                type: "LISTA_MEDICAMENTOS",
                payload: []
            });
            dispatch({
                type: "LISTA_INSUMOS",
                payload: []
            });
            dispatch({
                type: "LISTA_ALIMENTOS",
                payload: []
            });
            dispatch({
                type: "DICCIONARIO_INSUMOS_MEDICAMENTOS",
                payload: {}
            });
            api.get("api/insumosmedicamento/Insumos-Medicamentos").then((response) => {
                procesSuppliesMedicines(dispatch, response.data)
            });
        } else {
            procesSuppliesMedicines(dispatch, list_dict)
        }
       
    }
}

export const getDictMedicinesPrescription = (dispatch) => {
    const auxDictType = JSON.parse(localStorage.getItem("DICCIONARIO_MEDICAMENTOS_PRESCRIPCION"))
    if (auxDictType){
        dispatch({
            type: "DICCIONARIO_MEDICAMENTOS_PRESCRIPCION",
            payload: auxDictType
        });
    } else {
        api.get("api/medicamentosreceta/").then((response) => {
            const auxDictType = {};
            response.data.map((row) => {
                auxDictType[row.SKU] = row;
            });
            dispatch({
                type: "DICCIONARIO_MEDICAMENTOS_PRESCRIPCION",
                payload: auxDictType
            });
            localStorage.setItem("DICCIONARIO_MEDICAMENTOS_PRESCRIPCION", JSON.stringify(auxDictType));
        });
    }
}
