import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal, 
    Backdrop, 
    Paper, 
    Grid,
    Typography
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: "all 500ms linear",
    },
    paper: {
        transition: "all 500ms linear",
        padding: "2% 4% 3%",
    }
}));
  
export default function ModalSaveQuotation({
    open,
    handleClose,
    handleUpload,
}) {
    const classes = useStyles();

    const updateValue = () => {
        handleUpload();
        handleClose();
    };

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px',
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.paper}>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <Grid 
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                     <Typography >
                      ¿Está seguro que desea guardar esta cotización en los datos del paciente?
                    </Typography>
                </Grid>
                <Grid 
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "20px 0 0 0"}}
                >
                    <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        style={{backgroundColor: green[500]}}
                        onClick={updateValue}
                    >
                        Aceptar
                    </Button>

                   
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    </Modal>
    );
}