import React from "react";

import { Alert, AlertTitle } from "@material-ui/lab";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import {
  Dialog,
  Paper as MuiPaper,
  Paper
} from "@material-ui/core";

export default class AlertDialog extends React.Component {
 

  render() {
    const buttonProps = this.props.url ? { href: this.props.url } : {};

    return (
      <Paper mt={4}>
        <Dialog
          open={this.props.openDialog}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Alert severity={this.props.severity ? this.props.severity : 'error'}>
            <AlertTitle>
              {this.props.title
                ? this.props.title
                : "Error al subir el archivo!"}
            </AlertTitle>
              {this.props.message} — <strong>
              {(this.props.actionGlobalMessage &&  this.props.nameActionGlobalMessage) &&
               <Button
               style={{ border: "none", outline: "none" }}
               onClick={() => this.props.actionGlobalMessage()}
               color="primary"
               autoFocus
             >
              {this.props.nameActionGlobalMessage}
             </Button>
             }
              <Button
                style={{ border: "none", outline: "none" }}
                onClick={this.props.handleClose}
                color="primary"
                autoFocus
                {...buttonProps}
              >
                Ok
              </Button>
            </strong>
          </Alert>
        </Dialog>
      </Paper>
    );
  }
}
