import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import NumberFormat from "react-number-format";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const FirstPart = props => {
  const { dataPatient, transferQuantity, originGoing, originReturn} = props;

  const subtitle = (originGoing && originReturn) ? "ida y vuelta" : originGoing ? "ida" : originReturn ? "vuelta" : ""

  return (
    <Grid item container justify="center" spacing={2}>
      <Grid item container xs={5} justify="center">
        <Grid item container xs={12} justify="center">
          <Typography
            variant="body2"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Solicitud de Traslado
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Typography variant="caption" display="block" gutterBottom>
            Paciente:
            {`${dataPatient.nombre} ${dataPatient.apellido_paterno} ${dataPatient.apellido_materno}`}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={5} justify="center">
        <Grid item container xs={12} justify="center">
          <Typography variant="caption" display="block" gutterBottom>
            {`Traslados realizados vs autorizados ${subtitle}`}
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Avatar
            variant="square"
            style={{
              width: "100px",
              backgroundColor: "#fff",
              border: "2px solid #3f51b5",
              borderRadius: "4px",
              margin: "20px",
              color: "#000",
              fontSize: "15px"
            }}
          >
            {`${transferQuantity.cantidad_pedida} de ${transferQuantity.cantidad_asignada}`}
          </Avatar>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FirstPart;

FirstPart.propTypes = {
  dataPatient: PropTypes.object,
  transferPrice: PropTypes.number,
  setTransferPrice: PropTypes.func,
  transferQuantity: PropTypes.object
};
