import React, {useState, useEffect } from 'react';
import {
    Modal,
    Backdrop,
    Paper,
    Button as ButtonMUI,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {red, green, orange, indigo} from "@material-ui/core/colors";
import {classStyle} from "utils/styleFunction";
import {dictMonth} from "utils/validationFunction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function DefineVisitWarning({
    open,
    handleClose,
    scheduleVisitOther,
    handleSelectDateWorker,
    dictPatientFile,
}) {
    const classesStyle = classStyle();
    const [initialCharge, setinitialCharge] = useState(false);
    const [data, setDate] = useState(scheduleVisitOther[0]);
    const [worker, setworker] = useState(scheduleVisitOther[1]);

    const [changeButtons, setchangeButtons] = useState(true);


    useEffect(() => {
        if (initialCharge){
            initialData();
        }
        if (changeButtons){
            setchangeButtons(false);
        }
    });

    const initialData = () => {
        setinitialCharge(false);
    };

    const handleContinue = () => {
        const tempStart = data.tipo_tarea ? data.fecha_hora : data.fecha_hora_inicio;
        const tempEnd = data.tipo_tarea ? data.fecha_final : data.fecha_hora_fin;
        if (scheduleVisitOther[2]){
            handleSelectDateWorker(
                {value: tempStart, label: tempEnd},
                {...worker},
                scheduleVisitOther[2],
            );
        } else {
        handleSelectDateWorker(
            {value: tempStart, label: tempEnd},
            {...worker},
            null
        );
        }
        handleClose();
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesStyle.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper  className={classesStyle.paper}  style={{width: "80%", maxHeight: "85%", overflow: "auto"}}>
            {changeButtons === false &&
                <div></div>
            }
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        <Typography variant="h5" gutterBottom>{`Horario TENS`}</Typography>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="flex-start"
                            item
                            xs={12}
                            style={{margin: "10px 0"}}
                        >
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                item
                                xs={12}
                                style={{margin: "10px 0 10x 0"}}
                            >
                                <Typography variant="body2" gutterBottom>
                                    {`TENS: ${worker.first_name} ${worker.last_name}`}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                item
                                xs={12}
                                style={{margin: "10px 0 10x 0"}}
                            >
                                <Typography variant="body2" gutterBottom>
                                    {`Fecha: ${moment(data[0].fecha_hora ? data[0].fecha_hora : data[0].fecha_final).format("DD")} ${dictMonth[parseInt(moment(data[0].fecha_hora ? data[0].fecha_hora : data[0].fecha_final).format("MM"))]} de ${parseInt(moment(data[0].fecha_hora ? data[0].fecha_hora : data[0].fecha_final).format("YYYY"))}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={12}
                        style={{margin: "10px 0"}}
                    >
                        <TableContainer style={{}}>
                        <Table size={'small'} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="text-center align-top">
                                        Paciente
                                    </TableCell>
                                    <TableCell className="text-center align-top">
                                        Tipo de turno/Tarea
                                    </TableCell>
                                    <TableCell className="text-center align-top">
                                        Fecha inicio
                                    </TableCell>
                                    <TableCell className="text-center align-top">
                                        Fecha fín
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={`${index}-workRows`}>
                                    <TableCell
                                        className="text-center align-middle"
                                    >
                                       {`${dictPatientFile[row["id_ficha"]]?.nombre} ${dictPatientFile[row["id_ficha"]]?.apellido_paterno} ${dictPatientFile[row["id_ficha"]]?.apellido_materno}`}
                                    </TableCell>
                                    <TableCell
                                        className="text-center align-middle"
                                    >
                                       {row["tipo_tarea"]}
                                    </TableCell>
                                    <TableCell
                                        className="text-center align-middle"
                                    >
                                       {moment(row["fecha_hora"]).format("DD-MM-YYYY HH:mm:ss")}
                                    </TableCell>
                                    <TableCell
                                        className="text-center align-middle"
                                    >
                                       {moment(row["fecha_final"]).format("DD-MM-YYYY HH:mm:ss")}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 0 0"}}
                    >
                        <ButtonMUI
                            mr={2}
                            variant="contained"
                            className="text-white"
                            style={{backgroundColor: indigo[500], marginRight: "3px"}}
                            onClick={handleClose}
                        >
                            Atrás
                        </ButtonMUI>
                        <ButtonMUI
                            mr={2}
                            variant="contained"
                            style={{backgroundColor: indigo[500], marginRight: "10px", marginLeft: "3px"}}
                            className="text-white"
                            onClick={handleContinue}
                        >
                            Asignar de todas formas
                        </ButtonMUI>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
}