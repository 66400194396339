import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import ViewTitle from "../Widgets/FichaPersonal/viewTitle";
import CardTitle from "../Widgets/FichaPersonal/cardTitle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import ModalMessage from "../../components/ChangePassword/ModalMessage";
import ModalChangePassword from "../../components/ChangePassword/ModalChangePassword";
import api from "utils/API";


const ChangePassword = () => {

  const [blocking, setblocking] = useState(false);
  const [password, setPassword] = useState("");
  const [message, setmessage] = useState("");
  const [modalMessage, setmodalMessage] = useState(false);
  const [modalChangePassword, setmodalChangePassword] = useState(false);
  const [changes, setchanges] = useState(null);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (changes === null){
        validatePassword();
    }
  });

  const validatePassword = () => {
    if (password === ""){
        setValid(false);
    }
    else {
        setValid(true);
    }
  };

  const updatePassword = (newPassword) => {
    setmodalChangePassword(false)
    setblocking(true);
    const requestChangePassword = api.post(`/appauth/user/ChangePassword/`,{
        username: localStorage.getItem('username'),
        password: newPassword,
    });
    Promise.all([
        requestChangePassword,
    ]).then(response => {
        const data = response[0].data;
        setblocking(false);
        setmessage("Clave actualizada");
        setmodalMessage(true);
        setchanges(null);
    }).catch(error => {
        {}
        setmessage("Error")
        setmodalMessage(true);
        setblocking(false);
        setchanges(null);
    })
};

  const checkPassword = () => {
    setblocking(true);
    const requestGetInfo = api.post(`/appauth/user/CheckPassword/`,{
        username: localStorage.getItem('username'),
        password: password,
    });
    Promise.all([
        requestGetInfo,
    ]).then(response => {
        setchanges("check");
        setblocking(false);
        setmodalChangePassword(true);
    }).catch(error => {
        {}
        setmessage("Error al introducir contraseña")
        setmodalMessage(true);
        setblocking(false);
    })

  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  }

  return (
    <GridContainer>
      <ViewTitle title="CAMBIAR CONTRASEÑA" message="
        En esta sección podrás cambiar tu contraseña
      " />
      <Card >
        <CardTitle title="CAMBIAR CONTRASEÑA" />
        <Grid container justify="center" item xs={12} >
          <Grid container justify="center" alignItems="center" item xs={3} className="mb-5">

            <TextField
              id="password"
              label="Contraseña actual"
              variant="outlined"
              type="password"
              defaultValue={password}
              onChange={handlePassword}
              error={password == ""}
              helperText={password == "" ? "No puede ser vacio" : ""}
              margin="normal"
              fullWidth my={2}
            />
            {valid ?
              <Button
                mr={2}
                variant="contained"
                style={{backgroundColor: "#1B9CFC"}}
                className="text-white"
                onClick={checkPassword}
              >
                Cambiar constraseña
              </Button>
            :
              <Button
                mr={2}
                variant="contained"
                disabled
              >
                Cambiar contraseña
              </Button>
            }


          </Grid>
        </Grid>
      </Card>
      { modalMessage &&
      <ModalMessage
          open={modalMessage}
          message={message}
          closeModal={() => setmodalMessage(false)}
      />
      }
      {modalChangePassword &&
      <ModalChangePassword
          open={modalChangePassword}
          closeModal={() => setmodalChangePassword(false)}
          update={updatePassword}
      />
      }
    </GridContainer>
  );
}

export default ChangePassword;