import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import api from "utils/API";
import { days_open } from "components/Request/ManageRequest/DaysOpen";
import moment from "moment";

const CommodityInfo = props => {
  const [order, setOrder] = useState([]);
  const [supplies, setSupplies] = useState([]);

  useEffect(() => {
    api.get(`api/solicitudretiro/${props.id}`).then(result => {
      setOrder(result.data);
    });
    api.get("api/insumosmedicamento/").then(result => {
      setSupplies(result.data);
    });
  }, []);

  const getPatient = (id, patients) => {
    const response = patients
      .filter(patient => patient.id === id)
      .map(filtered => {
        return filtered.nombre;
      });
    return response;
  };

  const getGroup = (id, supplies) => {
    const response = supplies
      .filter(supply => supply.id === id)
      .map(filtered => {
        return filtered.grupo_producto;
      });
    return response;
  };

  const getDescription = (id, supplies) => {
    const response = supplies
      .filter(supply => supply.id === id)
      .map(filtered => {
        return filtered.descripcion_producto;
      });
    return response;
  };

  const months_open = date => {
    const today = moment(new Date()).format("DD/MM/YYYY");
    const request_date = moment(date).format("DD/MM/YYYY");
    const diff = moment(today, "DD/MM/YYYY").diff(
      moment(request_date, "DD/MM/YYYY")
    );
    const duration = moment.duration(diff);
    return duration._data.months;
  };

  return (
    <div className="col-xl-8 mb-3">
      <div className="card">
        <div className="card-header">
          <p className="text-center">Datos del pedido</p>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm text-left ml-5">
              <span className="font-weight-bold">Id: </span>
              {props.id}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Grupo: </span>
              {getGroup(order.id, supplies)}
              <br />
              <span className="font-weight-bold">Descripción: </span>
              {getDescription(order.id, supplies)}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Fecha de ingreso: </span>
              {order.fecha_solicitud}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommodityInfo;

CommodityInfo.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.bool,
  id_therapy: PropTypes.number,
  id_paquetizacion: PropTypes.number,
  id: PropTypes.number
};
