import React from "react";
import Select from "react-select";

const DetailSelect = props => {
  return(
    <div className="col-md-4 mb-3">
      <label className="text-dark" style={{ fontSize: "16px" }}>{props.label}</label>
      <Select 
        value={props.value}
        onChange={ props.onchange }
        options={props.list}
        placeholder="Seleccione"
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          ...props.colourStyles,
        }}
        menuPortalTarget={document.body}
      />
    </div>
  );
}

export default DetailSelect;