import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailMoneyOnly from "views/Widgets/FichaPersonal/EditFichaPersonal/detailMoneyOnly";
import DetailText from "views/Widgets/FichaPersonal/EditFichaPersonal/detailText";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import allActions from "actions";

export default function ModalEditPackage(props) {
  const dispatch = useDispatch();
  const { open, closeModal, id_paquete, id_paquetizacion, reloadPackage} = props;

  const [zona, setZona] = useState("");
  const [estado, setestado] = useState("");
  const [complejidad, setComplejidad] = useState("");
  const [descripcion, setdescripcion] = useState("");
  const [descripcionFacturacion, setdescripcionFacturacion] = useState("");
  const [dia_cama, setDia_cama] = useState("");
  const [insumos, setInsumos] = useState("");
  const [medicamentos, setMedicamentos] = useState("");
  const [packageCode, setPackageCode] = useState("");
  const total_afecto = insumos + medicamentos;
  const total_excento = dia_cama * 30;
  const total_mes = total_afecto + total_excento;
  const total_dia = total_mes / 30;
  const [initialData, setinitialData] = useState(true);


  const list_zona = useSelector(state => state.currentList.LISTA_ZONA);
  const list_estado = useSelector(state => state.currentList.LISTA_ESTADOS_PAQUETE);
  const list_complejidades = useSelector(state => state.currentList.LISTA_COMPLEJIDADES);
  const dictZone = useSelector(state => state.currentList.DICCIONARIO_ZONA);
  const dictComplexities = useSelector(state => state.currentList.DICCIONARIO_COMPLEJIDADES);


  useEffect(() => {
    if (initialData && list_complejidades.length && list_zona.length){
      setinitialData(false);
      api.get(`api/paquetes/${id_paquete}`).then((request) => {
        setComplejidad(dictComplexities[request.data.complejidad]);
        setZona(dictZone[request.data.zona]);
        setDia_cama(request.data.dia_cama);
        setInsumos(request.data.insumos);
        setMedicamentos(request.data.medicamentos);
        setdescripcion(request.data.descripcion);
        setdescripcionFacturacion(request.data.descripcion_facturacion);
        setPackageCode(request.data.codigo_paquete);
        setestado({value: request.data.estado, label: request.data.estado});
      });
    }
  });

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  function updatePackage() {

    const formData = new FormData();
    formData.append("zona", zona.value);
    formData.append("complejidad", complejidad.value);
    formData.append("descripcion", descripcion || " ");
    formData.append("descripcion_facturacion", descripcionFacturacion || " ");
    formData.append("dia_cama", dia_cama);
    formData.append("insumos", insumos);
    formData.append("medicamentos", medicamentos);
    formData.append("total_afecto", total_afecto);
    formData.append("total_excento", total_excento);
    formData.append("total_mes", total_mes);
    formData.append("total_dia", parseInt(total_dia, 10));
    formData.append("id_paquetizacion", id_paquetizacion);
    formData.append("codigo_paquete", packageCode);
    formData.append("estado", estado.value);

    apiform.put(`api/paquetes/${id_paquete}`, formData).then(response => {
      reloadPackage();
      closeModal();
    })
  }

  const handleZona = zona => {
    setZona(zona);
  };

  const handleComplejidad = complejidad => {
    setComplejidad(complejidad);
  };

  const handlePackageCode = packageCode => {
    setPackageCode(packageCode.target.value);
  };

  const _content_ = <div className="form-row" style={{ marginBottom: "10rem" }}>
    <DetailText
      label="Código"
      value={packageCode}
      onchange={handlePackageCode}
    />
    <DetailSelect
      label="Zona:"
      value={zona}
      onchange={handleZona}
      list={list_zona}
    />
    <DetailSelect
      label="Complejidad:"
      value={complejidad}
      onchange={handleComplejidad}
      list={list_complejidades}
    />
    <DetailText
      label="Descripción"
      value={descripcion}
      onchange={(event) => setdescripcion(event.target.value) }
    />
    <DetailText
      label="Descripción Facturación"
      value={descripcionFacturacion}
      onchange={(event) => setdescripcionFacturacion(event.target.value) }
    />
    <DetailMoneyOnly
      label="Día cama:"
      value={dia_cama}
      money={setDia_cama}
    />
    <DetailMoneyOnly
      label="Insumos:"
      value={insumos}
      money={setInsumos}
    />
    <DetailMoneyOnly
      label="Medicamentos:"
      value={medicamentos}
      money={setMedicamentos}
    />
    <DetailSelect
      label="Estado:"
      value={estado}
      onchange={setestado}
      list={list_estado}
    />
  </div>

  const _actions_ = <><Button
      onClick={closeModal}
      color="primary"
      variant="contained"
    >
      Cancelar
    </Button>
    <Button
      onClick={updatePackage}
      color="primary"
      variant="contained"
    >
      Guardar
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={"Editar paquete"}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}

ModalEditPackage.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_paquete: PropTypes.number,
  id_paquetizacion: PropTypes.number
};
