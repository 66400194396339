import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import { useParams } from "react-router-dom";
import CalendarEndowment from "./CalendarEndowment";
import Vetoes from "./Vetoes/Vetoes";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },

}));

const DetailPage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [variableCounter, setVariableCounter] = useState({});
  const [events, setEvents] = useState([]);
  const [eventsWorks, seteventsWorks] = useState([]);
  const [changes, setChanges] = useState(true);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [extraShift, setExtraShift] = useState(false);
  const [extraAttention, setExtraAttention] = useState(false);
  const [absence, setAbsence] = useState(false);
  const [holiday, setHoliday] = useState(false);
  const [resignation, setResignation] = useState(false);
  const [dismissal, setDismissal] = useState(false);
  const [taxable, setTaxable] = useState(false);
  const [collation, setCollation] = useState(false);
  const [mobilization, setMobilization] = useState(false);
  const [kindergarten, setKindergarten] = useState(false);
  const [wellness, setWellness] = useState(false);
  const [advance, setAdvance] = useState(false);
  const [unpaidLeave, setUnpaidLeave] = useState(false);
  const [workedDays, setWorkedDays] = useState(false);
  const [numberWorkedDays, setnumberWorkedDays] = useState(0);
  const [hours, setHours] = useState(false);
  const [numberHours, setnumberHours] = useState(0);
  const [vetoes, setVetoes] = useState(false);
  const [vetoesInfo, setVetoesInfo] = useState([]);
  const [patients, setPatients] = useState([]);
  const [origen, setOrigen] = useState('');
  const [areaPerfil, setAreaPerfil] = useState('');
  const [startDate, setStartDate] = useState(
    moment()
      .startOf("month")
      .format("YYYY-MM-DDT00:00:01")
  );
  const [endDate, setEndDate] = useState(
    moment()
      .endOf("month")
      .format("YYYY-MM-DDT23:59:59")
  );
  const [openModalAddRecord, setOpenModalAddRecord] = useState(false);
  const [tempDateInfo, setTempDateInfo] = useState(moment(new Date()));
  const [infoFicha, setInfoFicha] = useState({});
  const [position, setPosition] = useState("");

  const setEventTitle = name => {
    switch (name) {
      case "TurnoExtra":
        return "Turno extra";
      case "AtencionExtra":
        return "Atención extra";
      case "Inasistencias":
        return "Inasistencia";
      case "Vacaciones":
        return "Vacación";
      case "PermisoSinSueldo":
        return "Permiso sin sueldo";
      case "Vetos":
        return "Veto";
      case "Renuncia":
        return "Renuncia";
      case "Despido":
        return "Despido";
      case "Imponible":
        return "Imponible";
      case "Colacion":
        return "Colacion";
      case "Movilizacion":
        return "Movilizacion";
      case "Guarderia":
        return "Guarderia";
      case "Bienestar":
        return "Bienestar";
      case "Anticipo":
        return "Anticipo";
      default:
        break;
    }
  };

  const getVetoes = () => {
    const requestVetoes = api.get(
      `api/rrhh/variablesdinamicas/personal/${id}/tipo/vetos/`
    );
    const requestPatient = api.get("api/clinica/pacientes/ficha");

    Promise.all([requestVetoes, requestPatient])
      .then(response => {
        setVetoes(true);
        let newPatients = [];
        response[1].data.map(patient => {
          newPatients.push({
            value: patient.id,
            label: `${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`
          });
        });
        let vetadosPatient = [];
        response[0].data.valores.map(veto => {
          vetadosPatient.push({
            value: veto.info_veto.id_ficha_paciente_id.id,
            label: `${veto.info_veto.id_ficha_paciente_id.id_paciente_id.nombre} ${veto.info_veto.id_ficha_paciente_id.id_paciente_id.apellido_paterno} ${veto.info_veto.id_ficha_paciente_id.id_paciente_id.apellido_materno}`
          });
        });
        let tempPatients = newPatients.filter(
          patient =>
            !vetadosPatient.some(
              vetado => JSON.stringify(vetado) === JSON.stringify(patient)
            )
        );
        setVetoesInfo(response[0].data.valores);
        setPatients(tempPatients);
      })
      //
  };

  const getWorksDay = (change=false) => {
    if (infoFicha.id_usuario){
      let tempWorkedDay = !workedDays
      let tempHours = !hours
      if (change){
        tempWorkedDay = change
        tempHours = change
       
      } 
      setWorkedDays(tempWorkedDay);
      setHours(tempHours)
    
      if(tempWorkedDay){
        const dateSend = moment(startDate).add(1, "month")
        api.get(`api/personal/ficha/asistencia/?mes=${dateSend.month()}&anio=${dateSend.year()}&persona=${infoFicha.id_usuario}`).then((response) => {
          seteventsWorks(response.data.lista_eventos.map((row) => {
            const auxStartDate = `${row["fecha"]} ${row["Hora Login"]}`
            const auxEndDate = `${row["fecha"]} ${row["Hora Logout"]}`
            return {
              title: `Horas: ${row["Tiempo Conectado(Horas)"]}`,
              allDay: true,
              start: moment(auxStartDate).toDate(),
              end: moment(auxEndDate).toDate(),
            }
          }))
          setnumberWorkedDays(response.data.dias_trabajados)
          setnumberHours(parseInt(response.data.horas_trabajadas))
        })
      } else {
        seteventsWorks([])
      }
    }

  }

  const getVariable = (
    variable,
    setVariable,
    tipo,
    nombre,
    titulo,
    dateStart,
    dateEnd
  ) => {
    setVetoes(false);
    setVariable(!variable);
    const tempVariable = !variable;
    let tempEvents = events;
    const arrayURL = window.location.href.split('/');
    setOrigen(arrayURL[4])
    if (tempVariable) {
      let tempVariableCounter = variableCounter;

      const callVariableApi = api.get(
        `api/rrhh/variablesdinamicas/personal/${id}/tipo/${tipo}/?fecha_inicio=${dateStart}&fecha_fin=${dateEnd}`
      );

      Promise.all([callVariableApi])
        .then(response => {
          if (response[0].data.valores.length > 0) {
            response[0].data.valores.map((row, index) => {
              if(origen!='personalfile'){
                if(row.tipo_registro ==='TurnoExtra' || row.tipo_registro ==='AtencionExtra' || row.tipo_registro ==='Inasistencias' || row.tipo_registro ==='Vacaciones' || row.tipo_registro ==='PermisoSinSueldo' || row.tipo_registro ==='Vetos'){

                  tempEvents.push({
                    title: setEventTitle(row.tipo_registro),
                    allDay: true,
                    start: moment(row.fecha_hora_inicio).toDate(),
                    end: moment(row.fecha_hora_fin)
                      .add(30, "minutes")
                      .toDate(),
                    row: row,
                    rowInfo: response[0].data[index]
                  });
                }
              }else{
                tempEvents.push({
                  title: setEventTitle(row.tipo_registro),
                  allDay: true,
                  start: moment(row.fecha_hora_inicio).toDate(),
                  end: moment(row.fecha_hora_fin)
                    .add(30, "minutes")
                    .toDate(),
                  row: row,
                  rowInfo: response[0].data[index]
                });
              }

            });
          }
          tempVariableCounter[nombre] = response[0].data.contados[nombre];
          setVariableCounter(tempVariableCounter);
          setEvents(tempEvents);
        })

    } else {
      let filteredEvents = tempEvents.filter(event => event.title != titulo);
      setEvents(filteredEvents);
    }
  };

  const getAllVariables = (dateStart, dateEnd) => {
    setVetoes(false);
    setEvents([]);
    setExtraShift(true);
    setExtraAttention(true);
    setAbsence(true);
    setHoliday(true);
    setUnpaidLeave(true);
    setResignation(true);
    setDismissal(true);
    setTaxable(true);
    setCollation(true);
    setKindergarten(true);
    setMobilization(true);
    setWellness(true);
    setAdvance(true);
    let tempEvents = [];
    const arrayURL = window.location.href.split('/');
    setOrigen(arrayURL[4])
    const getAllVariablesApi = api.get(
      `api/rrhh/variablesdinamicas/personal/${id}/?fecha_inicio=${dateStart}&fecha_fin=${dateEnd}`
    );
    Promise.all([getAllVariablesApi])
      .then(response => {
        setVariableCounter(response[0].data.contados);
        if (response[0].data.valores.length > 0) {
          response[0].data.valores.map((row,index) => {
            if(origen==='personalfile'){
              tempEvents.push({
                title: setEventTitle(row.tipo_registro),
                allDay: true,
                start: moment(row.fecha_hora_inicio).toDate(),
                end: moment(row.fecha_hora_fin)
                  .add(30, "minutes")
                  .toDate(),
                row: row,
                rowInfo: response[0].data[index]
              });
            }else{
              if(row.tipo_registro ==='TurnoExtra' || row.tipo_registro ==='DiasTrabajados' || row.tipo_registro ==='HorasMasMenos' || row.tipo_registro ==='AtencionExtra' || row.tipo_registro ==='Inasistencias' || row.tipo_registro ==='Vacaciones' || row.tipo_registro ==='PermisoSinSueldo' || row.tipo_registro ==='Vetos'){
                tempEvents.push({
                  title: setEventTitle(row.tipo_registro),
                  allDay: true,
                  start: moment(row.fecha_hora_inicio).toDate(),
                  end: moment(row.fecha_hora_fin)
                    .add(30, "minutes")
                    .toDate(),
                  row: row,
                  rowInfo: response[0].data[index]
                });
              }

            }
          });
        }
        setEvents(tempEvents);
      })

  };

  const cleanVariables = (dateStart, dateEnd) => {
    setVariableCounter({});
    setVetoes(false);
    setExtraShift(false);
    setExtraAttention(false);
    setAbsence(false);
    setHoliday(false);
    setUnpaidLeave(false);
    setResignation(false);
    setDismissal(false);
    setTaxable(false);
    setCollation(false);
    setMobilization(false);
    setKindergarten(false);
    setWellness(false);
    setAdvance(false);
    setHours(false);
    setWorkedDays(false);
    setEvents([]);
    seteventsWorks([]);
    const getVariableCounter = api.get(
      `api/rrhh/variablesdinamicas/personal/${id}/?fecha_inicio=${dateStart}&fecha_fin=${dateEnd}`
    );

    Promise.all([getVariableCounter])
      .then(response => {
        setVariableCounter(response[0].data.contados);
      })

  };

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  useEffect(() => {
    const arrayURL = window.location.href.split('/');
    setOrigen(arrayURL[4])
    const areaperfil = localStorage.getItem("area_perfil")
    setAreaPerfil(areaperfil)
  },[]);

  useEffect(() => {
    getWorksDay()
  },[infoFicha]);

  useEffect(() => {
    getWorksDay(true)
  },[startDate, endDate]);



  const initialCharge = () => {
    setChanges(false);
    const getVariableCounter = api.get(
      `api/rrhh/variablesdinamicas/personal/${id}/contar/`
    );
    const getCargos = api.get("api/cargos/");

    Promise.all([getVariableCounter, getCargos])
      .then(response => {
        setVariableCounter(response[0].data.contados);

        setInfoFicha(response[0].data.ficha_personal);
        setPosition(
          response[1].data
            .filter(
              filtered =>
                filtered.id === response[0].data.ficha_personal.id_cargo
            )
            .map(position => {
              return position.nombre_corto;
            })
        );
      })
    };

  return (
    <Grid container className={classes.root}>
      {origen==='personalfile' ? '':(<div style={{width: "100%" }}><Card style={{ backgroundColor: "#1B9CFC", width: "100%" }}>
        <CardContent>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{
              color: "#FFFFFF",
              textTransform: "none",
              fontWeight: "bold"
            }}
          >
            Dotación
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{
              color: "#FFFFFF",
              textTransform: "none"
            }}
          >
            En esta sección podrás revisar, registrar y modificar la información
            de tu dotación.
          </Typography>
        </CardContent>
        </Card>
        <Grid item xs={12} sm={6} md={12} style={{ marginTop: "2em" }}>
          <CardContent
            className="rounded shadow border border-primary"
            style={{ justifyContent: "center" }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">
                <Link
                  href={`/admin/clinicendowments/`}
                  style={{ color: "inherit" }}
                  underline="hover"
                >
                  Mi dotación
                </Link>
              </Typography>
              <Typography color="textPrimary">Información dotación</Typography>
            </Breadcrumbs>
          </CardContent>
        </Grid></div>)}
      <Grid item xs={12} sm={6} md={12} style={{ marginTop: "2em" }}>
        <Card>.
          <Grid container justify="center" className="m-3 mt-5 mb-5">
            <Grid
              item
              container
              xs={12}
              justify="center"
              style={{ marginLeft: "1em", marginRight: "1em" }}
            >
              <Grid item container xs={7}>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "4px", textTransform: "none" }}
                    onClick={() => getAllVariables(startDate, endDate)}
                  >
                    Seleccionar todo
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "4px", textTransform: "none" }}
                    onClick={() => cleanVariables(startDate, endDate)}
                  >
                    Limpiar filtros
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  {moment().format("ll")}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justify="center"
              style={{
                marginTop: "5em",
                marginLeft: "1em",
                marginRight: "15px"
              }}
              spacing={2}
            >
              <Grid item container xs={6} justify="center">
                {workedDays ? (
                  <Button
                    onClick={() => getWorksDay()}
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#8bc34a"
                    }}
                  >
                    {`Días trabajados: ${numberWorkedDays}`}
                  </Button>
                ) : (
                  <Button
                    onClick={() => getWorksDay()}
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#8bc34a",
                      borderColor: "#8bc34a"
                    }}
                  >
                    {`Días trabajados: ${numberWorkedDays}`}
                  </Button>
                )}
                {hours ? (
                  <Button
                    onClick={() => getWorksDay()}
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#4caf50"
                    }}
                  >
                    {`Horas: ${numberHours}`}
                  </Button>
                ) : (
                  <Button
                    onClick={() => getWorksDay()}
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#4caf50",
                      borderColor: "#4caf50"
                    }}
                  >
                    {`Horas: ${numberHours}`}
                  </Button>
                )}
                {extraShift ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#64b5f6"
                    }}
                    onClick={() =>
                      getVariable(
                        extraShift,
                        setExtraShift,
                        "TurnoExtra",
                        "cantidad_turnos_extra",
                        "Turno extra",
                        startDate,
                        endDate
                      )
                    }
                  >
                    Turnos extra: {variableCounter?.cantidad_turnos_extra}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#64b5f6",
                      borderColor: "#64b5f6"
                    }}
                    onClick={() =>
                      getVariable(
                        extraShift,
                        setExtraShift,
                        "TurnoExtra",
                        "cantidad_turnos_extra",
                        "Turno extra",
                        startDate,
                        endDate
                      )
                    }
                  >
                    Turnos extra: {variableCounter?.cantidad_turnos_extra}
                  </Button>
                )}
                {extraAttention ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#1565c0"
                    }}
                    onClick={() =>
                      getVariable(
                        extraAttention,
                        setExtraAttention,
                        "AtencionExtra",
                        "cantidad_atencion_extra",
                        "Atención extra",
                        startDate,
                        endDate
                      )
                    }
                  >
                    Atenciones extra: {variableCounter?.cantidad_atencion_extra}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#1565c0",
                      borderColor: "#1565c0"
                    }}
                    onClick={() =>
                      getVariable(
                        extraAttention,
                        setExtraAttention,
                        "AtencionExtra",
                        "cantidad_atencion_extra",
                        "Atención extra",
                        startDate,
                        endDate
                      )
                    }
                  >
                    Atenciones extra: {variableCounter?.cantidad_atencion_extra}
                  </Button>
                )}
              </Grid>
              <Grid item container xs={6} justify="center">
                {absence ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#e53935"
                    }}
                    onClick={() =>
                      getVariable(
                        absence,
                        setAbsence,
                        "Inasistencias",
                        "cantidad_inasistencias",
                        "Inasistencia",
                        startDate,
                        endDate
                      )
                    }
                  >
                    Inasistencia: {variableCounter?.cantidad_inasistencias}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#e53935",
                      borderColor: "#e53935"
                    }}
                    onClick={() =>
                      getVariable(
                        absence,
                        setAbsence,
                        "Inasistencias",
                        "cantidad_inasistencias",
                        "Inasistencia",
                        startDate,
                        endDate
                      )
                    }
                  >
                    Inasistencia: {variableCounter?.cantidad_inasistencias}
                  </Button>
                )}
                {holiday ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#ffa726"
                    }}
                    onClick={() =>
                      getVariable(
                        holiday,
                        setHoliday,
                        "Vacaciones",
                        "cantidad_vacaciones_tomadas",
                        "Vacación",
                        startDate,
                        endDate
                      )
                    }
                  >
                    Vacaciones: {variableCounter?.cantidad_vacaciones_tomadas}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#ffa726",
                      borderColor: "#ffa726"
                    }}
                    onClick={() =>
                      getVariable(
                        holiday,
                        setHoliday,
                        "Vacaciones",
                        "cantidad_vacaciones_tomadas",
                        "Vacación",
                        startDate,
                        endDate
                      )
                    }
                  >
                    Vacaciones: {variableCounter?.cantidad_vacaciones_tomadas}
                  </Button>
                )}
                {unpaidLeave ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#fff9c4",
                      color: "#000"
                    }}
                    onClick={() =>
                      getVariable(
                        unpaidLeave,
                        setUnpaidLeave,
                        "PermisoSinSueldo",
                        "cantidad_permisos_sin_goce_sueldo",
                        "Permiso sin sueldo",
                        startDate,
                        endDate
                      )
                    }
                  >
                    Permiso sin sueldo:
                    {variableCounter?.cantidad_permisos_sin_goce_sueldo}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#000",
                      borderColor: "#fff9c4"
                    }}
                    onClick={() =>
                      getVariable(
                        unpaidLeave,
                        setUnpaidLeave,
                        "PermisoSinSueldo",
                        "cantidad_permisos_sin_goce_sueldo",
                        "Permiso sin sueldo",
                        startDate,
                        endDate
                      )
                    }
                  >
                    Permiso sin sueldo:
                    {variableCounter?.cantidad_permisos_sin_goce_sueldo}
                  </Button>
                )}

                {origen==='personalfile' ?
                (resignation ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#fff9c4",
                      color: "#000"
                    }}
                    onClick={() =>
                      getVariable(
                        resignation,
                        setResignation,
                        "Renuncia",
                        "cantidad_renuncia",
                        "Renuncia",
                        startDate,
                        endDate
                      )
                    }
                  >
                   Renuncia: {variableCounter?.cantidad_renuncia}
                  </Button>
                  ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#000",
                      borderColor: "#fff9c4"
                    }}
                    onClick={() =>
                      getVariable(
                        resignation,
                        setResignation,
                        "Renuncia",
                        "cantidad_renuncia",
                        "Renuncia",
                        startDate,
                        endDate
                      )
                    }
                  >
                   Renuncia: {variableCounter?.cantidad_renuncia}
                  </Button>
                )):''}

                {vetoes ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#616161"
                    }}
                    onClick={() => setVetoes(false)}
                  >
                    Vetos: {variableCounter?.cantidad_vetos}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#616161",
                      borderColor: "#616161"
                    }}
                    onClick={() => {
                      getVetoes();
                    }}
                  >
                    Vetos: {variableCounter?.cantidad_vetos}
                  </Button>
                )}
                {origen==='personalfile'?
                (dismissal ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#fff9c4",
                      color: "#000"
                    }}
                    onClick={() =>
                      getVariable(
                        dismissal,
                        setDismissal,
                        "Despido",
                        "cantidad_despido",
                        "Despido",
                        startDate,
                        endDate
                      )
                    }
                  >
                   Despidos: {variableCounter?.cantidad_despido}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#000",
                      borderColor: "#fff9c4"
                    }}
                    onClick={() =>
                      getVariable(
                        dismissal,
                        setDismissal,
                        "Despido",
                        "cantidad_despido",
                        "Despido",
                        startDate,
                        endDate
                      )
                    }
                  >
                   Despidos: {variableCounter?.cantidad_despido}
                  </Button>
                )):''}
                {origen==='personalfile'?
                (taxable ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#fff9c4",
                      color: "#000"
                    }}
                    onClick={() =>
                      getVariable(
                        taxable,
                        setTaxable,
                        "Imponible",
                        "cantidad_imponible",
                        "Imponible",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Imponible: {variableCounter?.cantidad_imponible}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#000",
                      borderColor: "#fff9c4"
                    }}
                    onClick={() =>
                      getVariable(
                        taxable,
                        setTaxable,
                        "Imponible",
                        "cantidad_imponible",
                        "Imponible",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Imponible: {variableCounter?.cantidad_imponible}
                  </Button>
                )):''}
                {origen==='personalfile'?
                (collation ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#fff9c4",
                      color: "#000"
                    }}
                    onClick={() =>
                      getVariable(
                        collation,
                        setCollation,
                        "Colacion",
                        "cantidad_colacion",
                        "Colacion",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Colacion: {variableCounter?.cantidad_colacion}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#000",
                      borderColor: "#fff9c4"
                    }}
                    onClick={() =>
                      getVariable(
                        collation,
                        setCollation,
                        "Colacion",
                        "cantidad_colacion",
                        "Colacion",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Colacion: {variableCounter?.cantidad_colacion}
                  </Button>
                )):''}
                {origen==='personalfile'?
                (mobilization ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#fff9c4",
                      color: "#000"
                    }}
                    onClick={() =>
                      getVariable(
                        mobilization,
                        setMobilization,
                        "Movilizacion",
                        "cantidad_movilizacion",
                        "Movilizacion",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Movilizacion: {variableCounter?.cantidad_movilizacion}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#000",
                      borderColor: "#fff9c4"
                    }}
                    onClick={() =>
                      getVariable(
                        mobilization,
                        setMobilization,
                        "Movilizacion",
                        "cantidad_movilizacion",
                        "Movilizacion",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Movilizacion: {variableCounter?.cantidad_movilizacion}
                  </Button>
                )):''}
                {origen==='personalfile'?
                (kindergarten ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#fff9c4",
                      color: "#000"
                    }}
                    onClick={() =>
                      getVariable(
                        kindergarten,
                        setKindergarten,
                        "Guarderia",
                        "cantidad_guarderia",
                        "Guarderia",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Guarderia: {variableCounter?.cantidad_guarderia}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#000",
                      borderColor: "#fff9c4"
                    }}
                    onClick={() =>
                      getVariable(
                        kindergarten,
                        setKindergarten,
                        "Guarderia",
                        "cantidad_guarderia",
                        "Guarderia",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Guarderia: {variableCounter?.cantidad_guarderia}
                  </Button>
                )):''}
                {origen==='personalfile'?
                (wellness ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#fff9c4",
                      color: "#000"
                    }}
                    onClick={() =>
                      getVariable(
                        wellness,
                        setWellness,
                        "Bienestar",
                        "cantidad_bienestar",
                        "Bienestar",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Bienestar: {variableCounter?.cantidad_bienestar}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#000",
                      borderColor: "#fff9c4"
                    }}
                    onClick={() =>
                      getVariable(
                        wellness,
                        setWellness,
                        "Bienestar",
                        "cantidad_bienestar",
                        "Bienestar",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Bienestar: {variableCounter?.cantidad_bienestar}
                  </Button>
                )):''}
                {origen==='personalfile'?
                (advance ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      backgroundColor: "#fff9c4",
                      color: "#000"
                    }}
                    onClick={() =>
                      getVariable(
                        advance,
                        setAdvance,
                        "Anticipo",
                        "cantidad_anticipo",
                        "Anticipo",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Anticipo: {variableCounter?.cantidad_anticipo}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "4px",
                      marginTop: "4px",
                      textTransform: "none",
                      color: "#000",
                      borderColor: "#fff9c4"
                    }}
                    onClick={() =>
                      getVariable(
                        advance,
                        setAdvance,
                        "Anticipo",
                        "cantidad_anticipo",
                        "Anticipo",
                        startDate,
                        endDate
                      )
                    }
                  >
                  Anticipo: {variableCounter?.cantidad_anticipo}
                  </Button>
                )):''}
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ marginTop: "2em" }}>
              <Grid item container xs={2} alignItems="center">
                <Grid item container xs={12}>
                  <Grid item xs={2} />
                  <Grid item container xs={8} justify="center">
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="200"
                      width="80"
                      image="https://cdn0.iconfinder.com/data/icons/social-media-network-4/48/male_avatar-512.png"
                      title="Contemplative Reptile"
                    />
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ marginTop: "20px", fontWeight: "bold" }}
                    >
                      {`${infoFicha.nombres} ${infoFicha.apellido_paterno}`}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Cargo: {position}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Tipo de contrato: {infoFicha.tipo_contrato}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Status: {infoFicha.status}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Vetos: {variableCounter?.cantidad_vetos > 0 ? "Si" : "No"}
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} justify="center">
                    {vetoes ?
                    <Button
                      disabled
                      variant="contained"
                      style={{ textTransform: "none" }}
                    >
                      Nuevo registro
                    </Button> :
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ textTransform: "none" }}
                      onClick={() => {
                        setOpenModalAddRecord(true);
                      }}
                    >
                      Nuevo registro
                    </Button>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={10} justify="center">
                <Grid item xs={1} />
                <Grid item xs={10}>
                  {vetoes ? (
                    <Vetoes
                      patients={patients}
                      setPatients={setPatients}
                      idFicha={id}
                      vetoesInfo={vetoesInfo}
                      setVetoesInfo={setVetoesInfo}
                      variableCounter={variableCounter}
                      setVariableCounter={setVariableCounter}
                    />
                  ) : (
                    <CalendarEndowment
                      eventsVariables={[...events, ...eventsWorks]}
                      setEvents={setEvents}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      getAllVariables={getAllVariables}
                      idFicha={id}
                      variableCounter={variableCounter}
                      setVariableCounter={setVariableCounter}
                      pageRefresh={pageRefresh}
                      setPageRefresh={setPageRefresh}
                      extraShift={extraShift}
                      extraAttention={extraAttention}
                      holiday={holiday}
                      openModalAddRecord={openModalAddRecord}
                      setOpenModalAddRecord={setOpenModalAddRecord}
                      tempDateInfo={tempDateInfo}
                      setTempDateInfo={setTempDateInfo}
                      unpaidLeave={unpaidLeave}
                      absence={absence}
                    />
                  )}
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {pageRefresh && <div></div>}
    </Grid>
  );
};

export default DetailPage;
