import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {green, red} from "@material-ui/core/colors";
import ModalHistorialSV from "./HistorialSV/ModalHistorialSV";
import ModalBalanceHidrico from "./BalanceHídrico/ModalBalanceHidrico";
import ModalDispositivos from "./Dispositivos/ModalDispositivos";
import ModalHojaRegistro from "./HojaRegistro/ModalHojaRegistro";
import ModalDisabledPatient from "./HojaRegistro/ModalDisabledPatient";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const ButtonsRegistros = props => {
  const classes = useStyles();
  const {
    dataPatient,
    dataFile,
    alertSigns,
    activateAlert,
    sizePatientProp,
    weightProp,
    temperatureProp,
    heartRateProp,
    breathingFrequencyProp,
    oxygenSaturationProp,
    bloodPressureSystolicProp,
    bloodPressureDiastolicProp,
    bloodPressureMeanProp,
    painProp,
    setActivateAlert,
    setAlertSigns,
    setdataPatient,
    tensAssigned,
    listassigned,
    clinic,
    width
  } = props;
  const [openModalHistorialSV, setOpenModalHistorialSV] = useState(false);
  const [openModalBalanceHidrico, setOpenModalBalanceHidrico] = useState(false);
  const [openModalDispositivos, setOpenModalDispositivos] = useState(false);
  const [openModalHojaRegistro, setOpenModalHojaRegistro] = useState(false);
  const [openModaDisabled, setopenModaDisabled] = useState(false);

  const permission = JSON.parse(localStorage.getItem("permisos"))

  const disablePatient = permission.includes("inhabilitarPaciente")
  const colorButton = dataPatient.activo ? red : green

  return (
    <Grid container className={classes.root} justify="center">
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
        alignItems="center"
        style={(width === "xs" || width === "sm") ? {padding: "10px 0px", margin: "0px"} : {}}
      >
        <Grid container justify="center" alingItems="center" style={(width === "xs" || width === "sm") ? {padding: "5px 0px"} : {}} item xs={12} sm={6} md={3} lg={3}>
          <Button
            style={{ textTransform: "none", ...(width === "xs") ? {width: "80%"} : {} }}
            variant="contained"
            color={activateAlert ? "secondary" : "primary"}
            onClick={() => setOpenModalHistorialSV(true)}
          >
            Signos vitales
          </Button>
        </Grid>
        <Grid container justify="center" alingItems="center" style={(width === "xs" || width === "sm") ? {margin: "5px 0px"} : {}} item xs={12} sm={6} md={3} lg={3}>
          <Button
            style={{ textTransform: "none", ...(width === "xs") ? {width: "80%"} : {}  }}
            variant="contained"
            color="primary"
            onClick={() => setOpenModalBalanceHidrico(true)}
          >
            Tipo de Ingreso
          </Button>
        </Grid>
        <Grid container justify="center" alingItems="center" style={(width === "xs" || width === "sm") ? {margin: "5px 0px"} : {}} item xs={12} sm={6} md={3} lg={3}>
          <Button
            style={{ textTransform: "none", ...(width === "xs") ? {width: "80%"} : {}  }}
            variant="contained"
            color="primary"
            onClick={() => setOpenModalDispositivos(true)}
          >
            Dispositivos
          </Button>
        </Grid>
        <Grid container justify="center" alingItems="center" style={(width === "xs" || width === "sm") ? {margin: "5px 0px"} : {}} item xs={12} sm={6} md={3} lg={3}>
          <Button
            style={{ textTransform: "none", ...(width === "xs") ? {width: "80%"} : {}  }}
            variant="contained"
            color="primary"
            onClick={() => setOpenModalHojaRegistro(true)}
          >
            Hoja de registro
          </Button>
        </Grid>
        {disablePatient &&
        <Grid container justify="center" alingItems="center" style={(width === "xs" || width === "sm") ? {margin: "5px 0px"} : {margin: "15px 0px"}} item xs={12} sm={6} md={3} lg={3}>
          <Button
            style={{ textTransform: "none", background: colorButton[500]  }}
            variant="contained"
            color="primary"
            onClick={() => setopenModaDisabled(true)}
          >
            {dataPatient.activo ? "Inhabilitar" : "Habilitar"}
          </Button>
        </Grid>
        }
      </Grid>
      {openModalHistorialSV && (
        <ModalHistorialSV
          open={openModalHistorialSV}
          closeModal={() => setOpenModalHistorialSV(false)}
          dataFile={dataFile}
          alertSigns={alertSigns}
          sizePatientProp={sizePatientProp}
          weightProp={weightProp}
          temperatureProp={temperatureProp}
          heartRateProp={heartRateProp}
          breathingFrequencyProp={breathingFrequencyProp}
          oxygenSaturationProp={oxygenSaturationProp}
          bloodPressureSystolicProp={bloodPressureSystolicProp}
          bloodPressureDiastolicProp={bloodPressureDiastolicProp}
          bloodPressureMeanProp={bloodPressureMeanProp}
          painProp={painProp}
          setActivateAlert={setActivateAlert}
          setAlertSigns={setAlertSigns}
        />
      )}
      {openModalBalanceHidrico && (
        <ModalBalanceHidrico
          open={openModalBalanceHidrico}
          closeModal={() => setOpenModalBalanceHidrico(false)}
          dataFile={dataFile}
        />
      )}
      {openModalDispositivos && (
        <ModalDispositivos
          open={openModalDispositivos}
          closeModal={() => setOpenModalDispositivos(false)}
          dataPatient={dataPatient}
          listassigned={listassigned}
          dataFile={dataFile}
        />
      )}
      {openModalHojaRegistro && (
        <ModalHojaRegistro
          open={openModalHojaRegistro}
          closeModal={() => setOpenModalHojaRegistro(false)}
          dataPatient={dataPatient}
          dataFile={dataFile}
          tensAssigned={tensAssigned}
          clinic={clinic}
        />
      )}
      {openModaDisabled && (
        <ModalDisabledPatient
          open={openModaDisabled}
          closeModal={() => setopenModaDisabled(false)}
          dataPatient={dataPatient}
          setdataPatient={setdataPatient}
        />
      )}
    </Grid>
  );
};

export default ButtonsRegistros;
