import React, { useState, useEffect } from "react";
import GridItem from "components/Grid/GridItem.js";
import { DoneAll, Warning  } from "@material-ui/icons/";
import MedicalTable from "components/PatientFile/MedicalTable";
import ResourseTable from "components/PatientFile/ResourseTable";
import BenefitTable from "components/PatientFile/BenefitTable";
import Snackbar from "components/Snackbar/Snackbar.js";
import NavPills from "components/NavPills/NavPills.js";
import DialogSuccess from "./DialogSuccess";

export default function PedidosProgramadosQuotationTabs({
  viewQuotation,
  medicalResourseTotal,
  medicalResourseBase,
  setmedicalResourseBase,
  listEmergency,
  listEquip,
  equipData,
  emergencyData,
  benefitsPackage,
  reloadRequest,
  tempPatientIdInfo,
  getPatientInfo,
  closeModal
}) {
    const [medicinesCheck, setmedicinesCheck] = useState(false);
    const [suppliesCheck, setsuppliesCheck] = useState(false);
    const [dataMedicalArray, setdataMedicalArray] = useState([]);
    const [dataResourseArray, setdataResourseArray] = useState([]);
    const [dataProfessionalArray, setdataProfessionalArray] = useState([]);
    const [dataEquipArray, setdataEquipArray] = useState([]);
    const [dataEmergencyArray, setdataEmergencyArray] = useState([]);
    const [deleteArray, setdeleteArray] = useState([]);
    const [updateArray, setupdateArray] = useState([]);
    const [initialCharge, setinitialCharge] = useState(true);
    const [message, setmessage] = useState(false);
    const [openDialogSucess, setOpenDialogSucess] = useState(false);

    useEffect(() => {
      if (initialCharge){
        processData();
      }
    });

    const processData = () =>{
      setinitialCharge(false);
      const auxDataMedicalArray = [];
      const auxDataResourseArray = [];
      const auxDataProfessionalArray = [];
      const auxDataEquipArray = [];
      const auxDataEmergency = [];
      const auxInitialArray = [];
      benefitsPackage.map((row) => {
        const tempRowPrest = row.id_prestaciones;
        const tempRowSource = row.id_insumo_medicamento;
        const tempRowEquip = row.id_equipo;
        row.cantidad_envio =  row.cantidad_envio || 0;
        row.envio_mensual =  (row.envio_mensual === false) ? false : true;
        if (tempRowPrest){
          if ( tempRowPrest.grupo === "Profesionales") {
            if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
              auxDataProfessionalArray.push(row);
              auxInitialArray.push(row.id)
            } else {
              auxDataProfessionalArray.push({
                frecuencia: row.frecuencia,
                tipo_frecuencia: row.tipo_frecuencia,
                cantidad: row.cantidad,
                cantidad_envio: row.cantidad_envio,
                envio_mensual: row.envio_mensual,
                fuera_paquete: false,
                valor: row.valor,
                otros_datos: row.otros_datos ? row.otros_datos : "-",
                id_prestaciones: row.id_prestaciones,
                id_insumo_medicamento: row.id_insumo_medicamento,
                //id_paquete_cotizacion: viewQuotation.packagingInfo,
              });
            }
          }
          if ( tempRowPrest.grupo === "Equipamiento") {
            if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
              auxDataEquipArray.push(row);
              auxInitialArray.push(row.id)
            } else {
              auxDataEquipArray.push({
                frecuencia: row.frecuencia,
                tipo_frecuencia: row.tipo_frecuencia,
                cantidad: row.cantidad,
                cantidad_envio: row.cantidad_envio,
                envio_mensual: row.envio_mensual,
                fuera_paquete: false,
                valor: row.valor,
                otros_datos: row.otros_datos ? row.otros_datos : "-",
                id_prestaciones: row.id_prestaciones,
                id_insumo_medicamento: row.id_insumo_medicamento,
                //id_paquete_cotizacion: viewQuotation.packagingInfo,
              });
            }
          }
          if ( tempRowPrest.grupo === "Emergencias") {
            if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
              auxDataEmergency.push(row);
              auxInitialArray.push(row.id)
            } else {
              auxDataEmergency.push({
                frecuencia: row.frecuencia,
                tipo_frecuencia: row.tipo_frecuencia,
                cantidad: row.cantidad,
                cantidad_envio: row.cantidad_envio,
                envio_mensual: row.envio_mensual,
                fuera_paquete: false,
                valor: row.valor,
                otros_datos: row.otros_datos ? row.otros_datos : "-",
                id_prestaciones: row.id_prestaciones,
                id_insumo_medicamento: row.id_insumo_medicamento,
                //id_paquete_cotizacion: viewQuotation.packagingInfo,
              });
            }
          }
        }
        if (tempRowSource){
          if ( ["Insumos", "Articulos", "Alimentos"].includes(tempRowSource.grupo_producto)) {
            if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
              auxDataResourseArray.push(row);
              auxInitialArray.push(row.id);
            } else {
              auxDataResourseArray.push({
                frecuencia: row.frecuencia,
                tipo_frecuencia: row.tipo_frecuencia,
                cantidad: row.cantidad,
                cantidad_envio: row.cantidad_envio,
                envio_mensual: row.envio_mensual,
                fuera_paquete: false,
                valor: row.valor,
                otros_datos: row.otros_datos ? row.otros_datos : "-",
                id_prestaciones: row.id_prestaciones,
                id_insumo_medicamento: row.id_insumo_medicamento,
                id_equipo: row.id_equipo,
              });
            }
          }
          if ( tempRowSource.grupo_producto === "Medicamentos" ) {
            if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
              auxDataMedicalArray.push(row);
              auxInitialArray.push(row.id)
            } else {
              auxDataMedicalArray.push({
                frecuencia: row.frecuencia,
                tipo_frecuencia: row.tipo_frecuencia,
                cantidad: row.cantidad,
                cantidad_envio: row.cantidad_envio,
                envio_mensual: row.envio_mensual,
                fuera_paquete: false,
                valor: row.valor,
                otros_datos: row.otros_datos ? row.otros_datos : "-",
                id_prestaciones: row.id_prestaciones,
                id_insumo_medicamento: row.id_insumo_medicamento,
                id_equipo: row.id_equipo,
              });
            }
          }
        }
        if (tempRowEquip){
          if ( tempRowEquip.tipo_producto === "EquiposMedicos") {
            if (row.fuera_paquete !== null && row.fuera_paquete !== undefined){
              auxDataEquipArray.push(row);
              auxInitialArray.push(row.id)
            } else {
              auxDataEquipArray.push({
                frecuencia: row.frecuencia,
                tipo_frecuencia: row.tipo_frecuencia,
                cantidad: row.cantidad,
                cantidad_envio: row.cantidad_envio,
                envio_mensual: row.envio_mensual,
                fuera_paquete: false,
                valor: row.valor,
                otros_datos: row.otros_datos ? row.otros_datos : "-",
                id_prestaciones: row.id_prestaciones,
                id_insumo_medicamento: row.id_insumo_medicamento,
                id_equipo: row.id_equipo,
              });
            }
          }
        }
      });
      setdataMedicalArray(auxDataMedicalArray);
      setdataResourseArray(auxDataResourseArray);
      setdataProfessionalArray(auxDataProfessionalArray);
      setdataEquipArray(auxDataEquipArray);
      setdataEmergencyArray(auxDataEmergency)
    };

    return (
      <GridItem xs={12}>
        {initialCharge === false &&
        <NavPills
            color="primary"
            alignCenter
            tabs={[
              {
                tabButton: "Equipos",
                tabIcon: DoneAll,
                tabContent: (
                  <BenefitTable
                    dataArray={dataEquipArray}
                    setdataArray={setdataEquipArray}
                    listBenefit={listEquip}
                    benefitData={equipData}
                    viewQuotation={viewQuotation}
                    deleteArray={deleteArray}
                    setdeleteArray={setdeleteArray}
                    updateArray={updateArray}
                    setupdateArray={setupdateArray}
                    typeRequest={reloadRequest.Tipo_Solicitud_Paciente}
                    stateRequest={reloadRequest.estado}
                    title={"Equipo"}
                    type={"equipo"}
                    updateRows={false}
                    editable={false}
                  />
                )
              },
              {
                tabButton: "Emergencia",
                tabIcon: DoneAll,
                tabContent: (
                  <BenefitTable
                    dataArray={dataEmergencyArray}
                    setdataArray={setdataEmergencyArray}
                    listEmergency={listEmergency}
                    emergencyData={emergencyData}
                    viewQuotation={viewQuotation}
                    deleteArray={deleteArray}
                    setdeleteArray={setdeleteArray}
                    updateArray={updateArray}
                    setupdateArray={setupdateArray}
                    typeRequest={reloadRequest.Tipo_Solicitud_Paciente}
                    stateRequest={reloadRequest.estado}
                    title={"Prestación"}
                    type={"prestacion"}
                    updateRows={false}
                    editable={false}
                  />
                )
              },
              {
                tabButton: "Medicamentos",
                tabIcon: medicinesCheck ? DoneAll : Warning,
                tabContent: (
                  <MedicalTable
                    medicalResourseTotal={medicalResourseTotal}
                    dataArray={dataMedicalArray}
                    setdataArray={setdataMedicalArray}
                    medicalResourseBase={medicalResourseBase}
                    setmedicalResourseBase={setmedicalResourseBase}
                    setmedicinesCheck={setmedicinesCheck}
                    setsuppliesCheck={setsuppliesCheck}
                    viewQuotation={viewQuotation}
                    deleteArray={deleteArray}
                    setdeleteArray={setdeleteArray}
                    updateArray={updateArray}
                    setupdateArray={setupdateArray}
                    updateRows={false}
                    editable={false}
                  />
                )
              },
              {
                tabButton: "Insumos",
                tabIcon: suppliesCheck ? DoneAll : Warning,
                tabContent: (
                  <ResourseTable
                    medicalResourseTotal={medicalResourseTotal}
                    dataArray={dataResourseArray}
                    setdataArray={setdataResourseArray}
                    medicalResourseBase={medicalResourseBase}
                    setmedicalResourseBase={setmedicalResourseBase}
                    setmedicinesCheck={setmedicinesCheck}
                    setsuppliesCheck={setsuppliesCheck}
                    viewQuotation={viewQuotation}
                    deleteArray={deleteArray}
                    setdeleteArray={setdeleteArray}
                    updateArray={updateArray}
                    setupdateArray={setupdateArray}
                    updateRows={false}
                    editable={false}
                  />
                )
              }
            ]}
        />
        }

        <Snackbar
          place="tc"
          close
          color="success"
          message="Datos Guardados"
          open={message}
          closeNotification={() => setmessage(null)}
        />
      {openDialogSucess && (
        <DialogSuccess
          openDialog={openDialogSucess}
          closeDialog={() => setOpenDialogSucess(false)}
          getPatientInfo={getPatientInfo}
          tempPatientIdInfo={tempPatientIdInfo}
          closeModal={closeModal}
        />
      )}
      </GridItem>
    );
}