import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { DoneAll, Info } from "@material-ui/icons/";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardStatistics from "views/Widgets/FichaPaciente/CardStatistics";
import PedidosProgramadosQuotationTabs from "./PedidosProgramadosQuotationTabs";

export default function PedidosProgramadosViewQuotation({
  viewQuotation,
  reloadRequest,
  handleReloadFile,
  medicalResourseTotal,
  medicalResourseBase,
  setmedicalResourseBase,
  listEquip,
  listEmergency,
  equipData,
  emergencyData,
  benefitsPackage,
  tempPatientIdInfo,
  getPatientInfo,
  closeModal
}) {
  const monthTotal = viewQuotation.packagingInfo
    ? viewQuotation.packagingInfo.total_mes
    : 0;
  const exemptTotal = viewQuotation.packagingInfo
    ? viewQuotation.packagingInfo.total_excento
    : 0;
  const dayBed = viewQuotation.packagingInfo
    ? viewQuotation.packagingInfo.dia_cama
    : 0;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
              >
                <Grid item xs={12} sm={6} md={3} style={{ padding: "1%" }}>
                  <CardStatistics
                    amountTotal={dayBed}
                    title={"Día cama"}
                    subTitle={"Recursos asignados"}
                    IconTitle={Info}
                    IconSub={Info}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} style={{ padding: "1%" }}>
                  <CardStatistics
                    amountTotal={exemptTotal}
                    title={"Total exento"}
                    subTitle={"Recursos asignados"}
                    IconTitle={Info}
                    IconSub={Info}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} style={{ padding: "1%" }}>
                  <CardStatistics
                    amountTotal={monthTotal}
                    title={"Total mes"}
                    subTitle={"Recursos asignados"}
                    IconTitle={Info}
                    IconSub={Info}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} style={{ padding: "1%" }}>
                  <CardStatistics
                    amountTotal={medicalResourseTotal}
                    amountBase={medicalResourseBase}
                    title={"Insumos / Medicamentos"}
                    subTitle={"Recursos por asignar"}
                    subTitleError={"Recursos excedidos"}
                    IconTitle={DoneAll}
                    IconSub={Info}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{ margin: "0 0 20px 0" }}
              >
                {benefitsPackage.length > 0 && (
                  <PedidosProgramadosQuotationTabs
                    viewQuotation={viewQuotation}
                    medicalResourseTotal={medicalResourseTotal}
                    medicalResourseBase={medicalResourseBase}
                    setmedicalResourseBase={setmedicalResourseBase}
                    listEmergency={listEmergency}
                    listEquip={listEquip}
                    emergencyData={emergencyData}
                    equipData={equipData}
                    benefitsPackage={benefitsPackage}
                    reloadRequest={reloadRequest}
                    handleReloadFile={handleReloadFile}
                    tempPatientIdInfo={tempPatientIdInfo}
                    getPatientInfo={getPatientInfo}
                    closeModal={closeModal}
                  />
                )}
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
      </GridItem>
    </GridContainer>
  );
}

PedidosProgramadosViewQuotation.propTypes = {
  viewQuotation: PropTypes.object,
  reloadRequest: PropTypes.array,
  handleReloadFile: PropTypes.func,
  medicalResourseTotal: PropTypes.number,
  medicalResourseBase: PropTypes.number,
  setmedicalResourseBase: PropTypes.func,
  listEquip: PropTypes.array,
  listEmergency: PropTypes.array,
  equipData: PropTypes.object,
  emergencyData: PropTypes.object,
  benefitsPackage: PropTypes.array,
  tempPatientIdInfo: PropTypes.number,
  getPatientInfo: PropTypes.func,
  closeModal: PropTypes.func
};
