import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {Grid} from "@material-ui/core/";
import { DoneAll, Warning  } from "@material-ui/icons/";
import BenefitTable from "./BenefitTable";
import ModalUploadFileIsapre from "./ModalUploadFileIsapre";
import NavPills from "components/NavPills/NavPills.js";
import ModalQuotationSkip from "./ModalQuotationSkip";
import ModalSaveQuotation  from "./ModalSaveQuotation";
import {
  setmessageGeneral,
} from 'actions/getGlobalAction';
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");




export default function QuotationTabs({
  dataPatient,
  selectedPackage,
  packageBalanced,
  packageUnbalanced,
  viewQuotation,
  setviewQuotation,
  resourceTotal,
  medicineTotal,
  listProfessional,
  listEquip,
  listEmergency,
  listOther,
  workersData,
  equipData,
  emergencyData,
  otherData,
  setresumeQuotation,
  updatePackage,
  updatePackageSelected,
  handleReloadFile,
  handleBack,
  dataFile,
  setdataFile,
}) {
    const dispatch = useDispatch();

    const [dataMedicalArray, setdataMedicalArray] = useState([]);
    const [dataResourseArray, setdataResourseArray] = useState([]);
    const [dataProfessionalArray, setdataProfessionalArray] = useState([]);
    const [dataEquipArray, setdataEquipArray] = useState([]);
    const [dataEmergencyArray, setdataEmergencyArray] = useState([]);
    const [dataOtherArray, setdataOtherArray] = useState([]);
    const [deleteArray, setdeleteArray] = useState([]);
    const [updateArray, setupdateArray] = useState([]);
    const [validate, setvalidate] = useState(false);
    const [initialCharge, setinitialCharge] = useState(true);
    const [openModalFile, setopenModalFile] = useState(false);
    const [openModalNoFile, setopenModalNoFile] = useState(false);
    const [idSelectPackage, setidSelectPackage] = useState(selectedPackage.id);
    const [updateSelectPackage, setupdateSelectPackage] = useState(false);
    const [openModalSkip, setopenModalSkip] = useState(false);
    const [openModalSave, setopenModalSave] = useState(false);

    const ammountResourcePackage = useSelector(state => state.currentGlobalVar.ammountResourcePackage);
    const ammountMedicinePackage = useSelector(state => state.currentGlobalVar.ammountMedicinePackage);

    const medicalResourseBase =  ammountResourcePackage + ammountMedicinePackage

    const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
    const dictDiscountQuotation = useSelector(state => state.currentList.dictDiscountQuotation);
    const listMedical = useSelector(state => state.currentList.listMedicines);
    const listResource = useSelector(state => state.currentList.listSupplies);

    const validateAmountMedicalResourse = (
      ["ParticularServicios", "ParticularHD"].includes(selectedPackage?.tipo_paciente) ||
      // selectedPackage?.id_convenio_isapre?.nombre == "Fondo Nacional de Salud" ||
      (selectedPackage?.tipo_permanencia == "Transitorio" && resourceTotal + medicineTotal == 0) 
    )  || (resourceTotal + medicineTotal - medicalResourseBase >= 0)


    useEffect(() => {
      checkArray();
      if (initialCharge){
        processData();
      }
    });

    useEffect(() => {
      if (selectedPackage && idSelectPackage !== selectedPackage?.id){
        setidSelectPackage(selectedPackage.id);
        setinitialCharge(true);
      }
    }, [selectedPackage, idSelectPackage])

    useEffect(() => {
      if (selectedPackage && updateSelectPackage){
        updateProcessUpdateSelectPackage(selectedPackage);
      }
    }, [selectedPackage, updateSelectPackage])


    const updateProcessUpdateSelectPackage = () => {
      setupdateSelectPackage(false);
      const tempSelectedPackage = {...selectedPackage}

      if (selectedPackage.descompensacion){
        tempSelectedPackage.prestaciones = [
          ...dataProfessionalArray,
          ...dataEquipArray,
          ...dataEmergencyArray,
          ...dataOtherArray,
        ]
      } else{
        tempSelectedPackage.prestaciones = [
          ...dataProfessionalArray,
          ...dataEquipArray,
          ...dataEmergencyArray,
          ...dataOtherArray,
          ...dataMedicalArray,
          ...dataResourseArray,
        ]

      }
      updatePackageSelected(tempSelectedPackage)
    }

    const processData = () => {
      setinitialCharge(false);
      const auxDataMedicalArray = [];
      const auxDataResourseArray = [];
      const auxDataProfessionalArray = [];
      const auxDataEquipArray = [];
      const auxDataEmergency = [];
      const auxDataOther = [];

      selectedPackage.prestaciones.filter((row) => !row.id_insumo_medicamento?.grupo_producto ).map((row) => {
        if (row.id_prestaciones?.grupo === "Profesionales") {
          auxDataProfessionalArray.push({...row});
        } else if (["Emergencias", "Traslados", "Otros"].includes(row.id_prestaciones?.grupo)) {
          auxDataOther.push({...row});
        } else if ( row.id_equipo?.tipo_producto === "EquiposMedicos") {
          auxDataEquipArray.push({...row});
        }
      });

      selectedPackage.prestaciones.filter((row) => row.id_insumo_medicamento?.grupo_producto ).map((row) => {
        row.valor = row.id_insumo_medicamento.valor_con_iva;
        if (["Insumos", "Articulos", "Alimentos"].includes(row.id_insumo_medicamento?.grupo_producto)) {
          auxDataResourseArray.push({...row});
        } else if (row.id_insumo_medicamento?.grupo_producto === "Medicamentos" ) {
          auxDataMedicalArray.push({...row});
        }
      });

      setdataMedicalArray(auxDataMedicalArray);
      setdataResourseArray(auxDataResourseArray);
      setdataProfessionalArray(auxDataProfessionalArray);
      setdataEquipArray(auxDataEquipArray);
      setdataEmergencyArray(auxDataEmergency);
      setdataOtherArray(auxDataOther);
    };

    const checkArray = () => {
      if (validateAmountMedicalResourse){
        setvalidate(true);
      } else {
        setvalidate(false);
      }
    };

    const generateRequestUpdatePackage = (packageData) => {
      if(packageData?.id){
        const dayBed = packageData.dias_periodo || 30
        const body = {
          tipo_porcentaje: packageData.tipo_porcentaje,
          porcentaje_item: packageData.porcentaje_item,
          dia_cama: packageData.dia_cama,
          total_excento: packageData.dia_cama*(dayBed),
          total_afecto: resourceTotal+medicineTotal,
          total_mes: packageData.dia_cama*dayBed+resourceTotal+medicineTotal,
          insumos: packageData.insumos,
          medicamentos: packageData.medicamentos,
        }
        if (packageData?.tipo_permanencia == "Transitorio"){
          body.dias_periodo = packageData.dias_periodo;
        }
        return api.patch(`api/paquetecotizacion/${packageData.id}`, body);
      }
      return null
    }

    const handleGoResume = (value, date=null) => {
      let tempEquipArray = []
      const temppackageBalanced = packageBalanced?.prestaciones ? packageBalanced.prestaciones.filter((row) => row.id === undefined) : [];
      const temppackageUnbalanced = packageUnbalanced?.prestaciones ? packageUnbalanced.prestaciones.filter((row) => row.id === undefined) : [];
      const filterBodyPackageQuotation = [...temppackageBalanced, ...temppackageUnbalanced];
      const tempupdateArray = updateArray.filter((row) => !deleteArray.includes(row.id));

      const bodyUpdateArray = [...tempupdateArray, ...filterBodyPackageQuotation].map((row) => {
        const temp = {...row};
        temp.id_insumo_medicamento = row.id_insumo_medicamento?.id || row.id_insumo_medicamento || null;
        temp.id_prestaciones = row.id_prestaciones?.id || row.id_prestaciones || null;
        temp.id_paquete_cotizacion = row.id_paquete_cotizacion?.id || row.id_paquete_cotizacion || row.id_paquete_cotizacion_id || null;
        temp.id_equipo = row.id_equipo?.id || row.id_equipo || null;
        if (temp.items) {
          temp.items = temp.items.map((subRow) => {
            const subTemp = {...subRow}
            subTemp.id_paquete_cotizacion = subRow.id_paquete_cotizacion?.id ||  subRow.id_paquete_cotizacion || subRow.id_paquete_cotizacion_id || null;
            subTemp.id_equipo =  subRow.id_equipo?.id || subRow.id_equipo || null;
            return{...subTemp}
          });
        }
        return {...temp}
      });

      const requestList = []

      const arrayDiscount = Object.values(dictDiscountQuotation);
      const requestUpdateQuotation = [generateRequestUpdatePackage(packageBalanced), generateRequestUpdatePackage(packageUnbalanced)].filter((row) => row !== null);
      if (bodyUpdateArray.length > 0){
        requestList.push(api.put(`api/paciente/prestacionescotizacion/bulk/`, bodyUpdateArray))
      }
      if (deleteArray.length > 0){
        requestList.push( api.delete(`api/paciente/prestacionescotizacion/bulk/`, { data: deleteArray }))
      }
      if (arrayDiscount.length > 0){
        requestList.push( api.post(`api/paciente/descuento/prestacionescotizacion/bulk/${packageBalanced.id}`, arrayDiscount))
      }
      if (value == "uploadFile"){
        const bodyRequest = {
          estado: viewQuotation.requestPatientInfo.estado === "EsperaIsapre" ? "EsperandoHabilitacion" : "Progreso",
          Tipo_Solicitud_Paciente: viewQuotation.requestPatientInfo.estado === "EsperaIsapre" ? "Cotizacion" : "HabilitacionDomicilio",
        };
        if (viewQuotation.requestPatientInfo.estado === "EsperaIsapre"){
           requestList.push(api.post(`/api/clinica/solicitudes/${viewQuotation.requestPatientInfo.id}/cotizacion/finalizar/`, {}))
        } else {
          requestPatient = api.patch(`/api/solicitudpaciente/${viewQuotation.requestPatientInfo.id}`, bodyRequest);
        }
      }
      setdeleteArray([]);
      setupdateArray([]);
      
      Promise.all([
        ...requestUpdateQuotation,
        ...requestList
      ]).then((request) => {
        dataEquipArray.map((row) => {
          if(row.items){
            tempEquipArray = [...tempEquipArray,...row.items]
          }
        });

        if (bodyUpdateArray.length > 0 || deleteArray > 0){
          updatePackage()
        }
        setmessageGeneral(dispatch, "Datos guardados", "succes", "¡Exito!");
        if (value === "skip"){
          handleUploadSkip(date)
        } else if (value === "file"){
          handleUpload()
        } else if (value == "uploadFile"){
          const tempView = {...viewQuotation}
          tempView.requestPatientInfo.estado = "EsperandoHabilitacion" 
          setviewQuotation({...tempView})
        } else if (value == "habilitacion"){
          setresumeQuotation({
            packageBalanced: packageBalanced,
            packageUnbalanced: packageUnbalanced,
            View: value,
          });
        }

      })
    };

    const handleUpload = () => {
      const requestPatient = api.put(`/api/clinica/solicitudes/${viewQuotation.requestPatientInfo.id}/cotizacion/finalizar/`, {});
      Promise.all([
        requestPatient,
      ]).then((request) => {
        setmessageGeneral(dispatch, "Cotización finalizada", "succes", "¡Exito!");
        const tempView = {...viewQuotation}
        tempView.requestPatientInfo.estado = "EsperaIsapre"
        setviewQuotation({...tempView})
      })
    };
  
    const handleUploadSkip = (date) => {
      const body = {
        fecha_pedidos: moment(date).format("YYYY-MM-DD"),
        actualizar_paquete: packageBalanced.actualizar_paquete,
      }
      api.post(`/api/clinica/solicitudes/${viewQuotation.requestPatientInfo.id}/cotizacion/saltar/habilitacion/`, body).then((response) =>{
        setmessageGeneral(dispatch, "Habilitación finalizada", "succes", "¡Exito!");
        const tempFile = {...dataFile}
        tempFile.estado_ficha = "Habilitado"
        setdataFile({...tempFile})
        handleBack();
      })
    };


    const handleUpdatePackageBenefit = (value, set) => {
      set(value);
      setupdateSelectPackage(true);
    }

    const TabList = []
    TabList.push({
      tabButton: "Profesionales",
      tabIcon:  DoneAll,
      tabContent: (<BenefitTable
        dataArray={dataProfessionalArray}
        setdataArray={(value) => handleUpdatePackageBenefit(value, setdataProfessionalArray)}
        listBenefit={listProfessional}
        benefitData={workersData}
        selectedPackage={selectedPackage}
        deleteArray={deleteArray}
        setdeleteArray={setdeleteArray}
        updateArray={updateArray}
        setupdateArray={setupdateArray}
        typeRequest={viewQuotation.requestPatientInfo.Tipo_Solicitud_Paciente}
        stateRequest={viewQuotation.requestPatientInfo.estado}
        title={"Profesional"}
        type={"prestacion"}
      />)
    })
    TabList.push({
      tabButton: "Equipos",
      tabIcon: DoneAll,
      tabContent: (<BenefitTable
          dataArray={dataEquipArray}
          setdataArray={(value) => handleUpdatePackageBenefit(value, setdataEquipArray)}
          listBenefit={listEquip}
          benefitData={equipData}
          selectedPackage={selectedPackage}
          deleteArray={deleteArray}
          setdeleteArray={setdeleteArray}
          updateArray={updateArray}
          setupdateArray={setupdateArray}
          typeRequest={viewQuotation.requestPatientInfo.Tipo_Solicitud_Paciente}
          stateRequest={viewQuotation.requestPatientInfo.estado}
          title={"Equipo"}
          type={"equipo"}
        />)
    })
   
    TabList.push({
      tabButton: "Emergencia",
      tabIcon: DoneAll,
      tabContent: (<BenefitTable
          dataArray={dataEmergencyArray}
          setdataArray={(value) => handleUpdatePackageBenefit(value, setdataEmergencyArray)}
          listBenefit={listEmergency}
          benefitData={emergencyData}
          selectedPackage={selectedPackage}
          deleteArray={deleteArray}
          setdeleteArray={setdeleteArray}
          updateArray={updateArray}
          setupdateArray={setupdateArray}
          typeRequest={viewQuotation.requestPatientInfo.Tipo_Solicitud_Paciente}
          stateRequest={viewQuotation.requestPatientInfo.estado}
          title={"Prestación"}
          type={"prestacion"}
        />)
    })
  
    if (!selectedPackage.descompensacion){
      TabList.push({
        tabButton: "Medicamentos",
        tabIcon: validateAmountMedicalResourse > 0 ? DoneAll : Warning,
        tabContent: (<BenefitTable
            dataArray={dataMedicalArray}
            setdataArray={(value) => handleUpdatePackageBenefit(value, setdataMedicalArray)}
            listBenefit={listMedical}
            benefitData={medicalResourceData}
            selectedPackage={selectedPackage}
            deleteArray={deleteArray}
            setdeleteArray={setdeleteArray}
            updateArray={updateArray}
            setupdateArray={setupdateArray}
            typeRequest={viewQuotation.requestPatientInfo.Tipo_Solicitud_Paciente}
            stateRequest={viewQuotation.requestPatientInfo.estado}
            title={"Medicamentos"}
            type={"insumos_medicamentos"}
          />)
      })
      TabList.push({
        tabButton: "Insumos",
        tabIcon: validateAmountMedicalResourse > 0 ? DoneAll : Warning,
        tabContent: (<BenefitTable
              dataArray={dataResourseArray}
              setdataArray={(value) => handleUpdatePackageBenefit(value, setdataResourseArray)}
              listBenefit={listResource}
              benefitData={medicalResourceData}
              selectedPackage={selectedPackage}
              deleteArray={deleteArray}
              setdeleteArray={setdeleteArray}
              updateArray={updateArray}
              setupdateArray={setupdateArray}
              typeRequest={viewQuotation.requestPatientInfo.Tipo_Solicitud_Paciente}
              stateRequest={viewQuotation.requestPatientInfo.estado}
              title={"Insumos"}
              type={"insumos_medicamentos"}
            />)
      })
    }
   
    TabList.push({
      tabButton: "Otros",
      tabIcon: DoneAll,
      tabContent: (<BenefitTable
          dataArray={dataOtherArray}
          setdataArray={(value) => handleUpdatePackageBenefit(value, setdataOtherArray)}
          listBenefit={listOther}
          benefitData={otherData}
          selectedPackage={selectedPackage}
          deleteArray={deleteArray}
          setdeleteArray={setdeleteArray}
          updateArray={updateArray}
          setupdateArray={setupdateArray}
          typeRequest={viewQuotation.requestPatientInfo.Tipo_Solicitud_Paciente}
          stateRequest={viewQuotation.requestPatientInfo.estado}
          title={"Prestación"}
          type={"prestacion"}
        />)
    })

    return (
      <Grid item xs={12}>

        {initialCharge === false &&
        <NavPills
          color="primary"
          alignCenter
          tabs={TabList}
        />
        }
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ margin: "0 0 20px 0" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleGoResume("salir")}
            style={{ marginRight: "5px" }}
          >
            Guardar
          </Button>

          { !["EsperaIsapre", "EsperandoHabilitacion"].includes(viewQuotation.requestPatientInfo.estado) &&
          <>

          {validate ? 
            <Button
              variant="contained"
              color="primary"
              onClick={() => setopenModalSave(true)}
              style={{margin: "0 5px"}}
            >
              {`Finalizar cotización`}
            </Button>:
            <Button
              disabled
              variant="contained"
              color="primary"
              style={{margin: "0 5px"}}
            >
                {`Finalizar cotización`}
            </Button>
          }

          {validate ? 
            <Button
              variant="contained"
              color="primary"
              onClick={() => setopenModalSkip(true)}
              style={{margin: "0 5px"}}
            >
              {!packageBalanced.actualizar_paquete ? `Habilitar directamente` :  `Actualizar paquete paciente`}
            </Button>:
            <Button
              disabled
              variant="contained"
              color="primary"
              style={{margin: "0 5px"}}
            >
              {!packageBalanced.actualizar_paquete ? `Habilitar directamente` :  `Actualizar paquete paciente`}
            </Button>
          }

          </>
          }
             
          {viewQuotation.requestPatientInfo.estado === "EsperaIsapre" &&
          <>
            <Button
                variant="contained"
                onClick={() => setopenModalFile(true)}
                color="primary"
                style={{ marginLeft: "5px" }}
              >
                Adjuntar autorización Isapre
            </Button>
           <Button
              variant="contained"
              onClick={() => {
                setopenModalNoFile(true)
                setopenModalFile(true)
              }}
              color="primary"
              style={{ marginLeft: "5px" }}
            >
              Adjuntar autorización Isapre después
            </Button>
          </>
              
          }

          {viewQuotation.requestPatientInfo.estado === "EsperandoHabilitacion" && (
            <Button
              variant="contained"
              onClick={() => handleGoResume("habilitacion")}
              color="primary"
              style={{ marginLeft: "5px" }}
            >
              Siguiente
            </Button>
          )} 
         
        </Grid>
        { openModalFile &&
          <ModalUploadFileIsapre
            open={openModalFile}
            handleClose={() => {
              setopenModalFile(false)
              setopenModalNoFile(false)
            }}
            openModalNoFile={openModalNoFile}
            dataPatient={dataPatient}
            reloadRequest={viewQuotation.requestPatientInfo}
            handleUploadFile={() => handleGoResume("uploadFile")}
          />
        }
        {openModalSkip &&
          <ModalQuotationSkip
            open={openModalSkip}
            handleClose={() => setopenModalSkip(false)}
            handleUpload={(date) => handleGoResume("skip", date)}
            updatePackaging={packageBalanced.actualizar_paquete}
          />
        }
        {openModalSave &&
          <ModalSaveQuotation
            open={openModalSave}
            handleClose={() => setopenModalSave(false)}
            handleUpload={() => handleGoResume("file")}
          />
        }
      </Grid>
    );
}