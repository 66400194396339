import React from 'react'
import api from 'utils/API';
import moment from "moment-timezone";

import { TypographyMui } from 'utils/componentsStyle';

import { makeStyles } from '@material-ui/core/styles'
import { Box, Button } from '@material-ui/core'
import calendar from '../../../../assets/img/icons/exams/fecha-de-toma.svg'
import reloj from '../../../../assets/img/icons/exams/hora-examen.svg'
import ambulance from '../../../../assets/img/icons/exams/traslado.svg'
import lab from '../../../../assets/img/icons/exams/laboratorio.svg'
import priority from '../../../../assets/img/icons/exams/prioridad.svg'
import examContent from '../../../../assets/img/icons/exams/indicaciones.svg'
import avatar from '../../../../assets/img/icons/exams/profesional-a-cargo.svg'

const useStyles = makeStyles({
  lateralPadding: {
    paddingLeft: 40
  },
  centralGrid: {
    paddingLeft: 40,
    paddingRight: 40,
    borderRight: '1px solid #E0E0E0',
    borderLeft: '1px solid #E0E0E0'
  },
  noLineHeight: {
    lineHeight: '12px !important',
    color: '#3F51B5'
  },
  noAvailableText: {
    color: 'rgba(0, 0, 0, 0.38)',
    textAlign: 'center',
    display: 'block'
  },

  '@media (max-width: 800px)': {
    lateralPadding: {
      paddingLeft: 32
    },
    centralGrid: {
      paddingLeft: 32,
      paddingRight: 32
    },
  },
});

function ExamDetail({ exam }) {
  const classes = useStyles()

  const getExamOrderDocument = async (currentExam) => {
    api.get(`api/documentospaciente/${currentExam.id_orden_examen_id}`).then(response => {
      window.open(response.data.archivo)
    })
  }

  const getIsapreAuthDocument = async (currentExam) => {
    api.get(`api/documentospaciente/${currentExam.id_autorizacion_isapre_id}`).then(response => {
      window.open(response.data.archivo)
    })
  }

  return (
    <>
      <hr />
      {!exam.fecha_hora_examen && !exam.fecha_hora_entrega ?
        <Box mt={2} ml={2.5}>
          <Box display='flex' alignItems='center' mt={2} mb={1}>
            <Box mr={0.5}>
              <img src={lab} alt="lab" width='20px' height='30px' className='mr-2' />
            </Box>
            <TypographyMui variant='body2' className={classes.noLineHeight}>
              Laboratorio
            </TypographyMui>
          </Box>
          <TypographyMui variant='subtitle2'>{exam.laboratorio ? exam.laboratorio : 'No asignado'}</TypographyMui>
        </Box>
        :
        <Box display='grid' gridTemplateColumns='1fr 40% 1fr' mb='0.5rem'>
          <Box className={classes.lateralPadding}>
            <Box>
              <Box display='flex' alignItems='center'>
                <Box mr={0.5}>
                  <img src={calendar} alt="calendar" width='20px' height='30px' className='mr-2' />
                </Box>
                <TypographyMui variant='body2' className={classes.noLineHeight}>
                  Fecha
                  examen
                </TypographyMui>
              </Box>

              <TypographyMui variant='subtitle2'>{moment(exam.fecha_hora_examen).format('DD-MM-YYYY')}</TypographyMui>
            </Box>

            <Box mt={2}>
              <Box display='flex' alignItems='center'>
                <Box mr={0.5}>
                  <img src={reloj} alt="reloj" width='20px' height='30px' className='mr-2' />
                </Box>
                <TypographyMui variant='body2' className={classes.noLineHeight}>
                  Hora
                  examen
                </TypographyMui>
              </Box>

              <TypographyMui variant='subtitle2'>{moment(exam.fecha_hora_examen).format('HH:mm')}</TypographyMui>
            </Box>

            <Box mt={2}>
              <Box display='flex' alignItems='center'>
                <Box mr={0.5}>
                  <img src={ambulance} alt="ambulance" width='20px' height='30px' className='mr-2' />
                </Box>
                <TypographyMui variant='body2' className={classes.noLineHeight}>
                  Traslado
                </TypographyMui>
              </Box>

              <TypographyMui variant='subtitle2'>{exam.traslado ? 'Si' : 'No'}</TypographyMui>
            </Box>

            <Box mt={2}>
              <Box display='flex' alignItems='center'>
                <Box mr={0.5}>
                  <img src={ambulance} alt="ambulance" width='20px' height='30px' className='mr-2' />
                </Box>
                <TypographyMui variant='body2' className={classes.noLineHeight}>
                  Tipo de
                  traslado
                </TypographyMui>
              </Box>

              <TypographyMui variant='subtitle2'>{exam.tipo_traslado}</TypographyMui>
            </Box>
          </Box>

          <Box className={classes.centralGrid}>
            <Box>
              {exam.id_orden_examen_id ?
                <Button variant='outlined' color='primary' size='smal' fullWidth onClick={() => getExamOrderDocument(exam)}>
                  Ver orden de examen
                </Button>
                :
                <TypographyMui className={classes.noAvailableText} variant='body2'>Orden no disponible</TypographyMui>
              }
            </Box>

            <Box mt={2}>
              {exam.id_autorizacion_isapre_id ?
                <Button variant='outlined' color='primary' size='smal' fullWidth onClick={() => getIsapreAuthDocument(exam)}>
                  Ver Autorización isapre
                </Button>
                :
                <TypographyMui className={classes.noAvailableText} variant='body2'>Autorizacón no disponible</TypographyMui>
              }
            </Box>

            <Box mt={2}>
              <Box display='flex' alignItems='center' mt={2}>
                <Box mr={0.5}>
                  <img src={lab} alt="lab" width='20px' height='30px' className='mr-2' />
                </Box>
                <TypographyMui variant='body2' className={classes.noLineHeight}>
                  Laboratorio
                </TypographyMui>
              </Box>

              <TypographyMui variant='subtitle2'>{exam.laboratorio ? exam.laboratorio : 'No asignado'}</TypographyMui>
            </Box>

            <Box mt={2}>
              <Box display='flex' alignItems='center' mt={2}>
                <Box mr={0.5}>
                  <img src={priority} alt="priority" width='20px' height='30px' className='mr-2' />
                </Box>
                <TypographyMui variant='body2' className={classes.noLineHeight}>
                  Prioridad
                </TypographyMui>
              </Box>

              <TypographyMui variant='subtitle2'>{exam.id_solicitud_paciente.prioridad}</TypographyMui>
            </Box>
          </Box>

          <Box className={classes.lateralPadding}>
            <Box>
              <Box display='flex' alignItems='center' mt={2}>
                <Box mr={0.5}>
                  <img src={avatar} alt="avatar" width='20px' height='30px' className='mr-2' />
                </Box>
                <TypographyMui variant='body2' className={classes.noLineHeight}>
                  Profesional a cargo
                </TypographyMui>
              </Box>

              <TypographyMui variant='subtitle2'>{exam?.nombre_nombre_encargado}</TypographyMui>
            </Box>

            <Box mt={2}>
              <Box display='flex' alignItems='center' mt={2}>
                <Box mr={0.5}>
                  <img src={examContent} alt="examContent" width='20px' height='30px' className='mr-2' />
                </Box>
                <TypographyMui variant='body2' className={classes.noLineHeight}>
                  Indicaciones
                </TypographyMui>
              </Box>

              <TypographyMui variant='subtitle2'>{exam.id_examen_clinica.indicaciones}</TypographyMui>
            </Box>
          </Box>
        </Box>
      }
    </>
  )
}

export default ExamDetail
