import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux';

import { TypographyMui } from 'utils/componentsStyle';

import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, TextField, MenuItem } from '@material-ui/core'
import Select from "react-select";
import Alert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import folder from '../../../../assets/img/icons/shared/folder.svg'
import amount from '../../../../assets/img/icons/stock/amount.svg'
import copy from '../../../../assets/img/icons/stock/copy.svg'
import api from 'utils/API';

const useStyles = makeStyles({
  disabledColor: {
    color: '#BDBDBD'
  },

  '@media (max-width: 800px)': {},
});

function CreateStockForm({stockList, dataFile, setStockList, setSpiner}) {
  const classes = useStyles()
  const [groupSelect, setgroupSelect] = useState({});
  const [itemSelect, setitemSelect] = useState({});
  const [stockItem, setstockItem] = useState(0);
  const [message, setmessage] = useState("");

  const listMedical = useSelector(state => state.currentList.listMedicines);
  const listResource = useSelector(state => state.currentList.listSupplies);
  const listEquip = useSelector(state => state.currentList.listEquip);

  const handleGroup = (value) => {
    setgroupSelect(value)
    setitemSelect({});
    setstockItem(0);
  }

  const onSubmit = (data) => {
    setSpiner()
    const body = {
      "grupo_item": groupSelect.value,
      "unidades_actualizar": parseInt(stockItem),
      "id_item": itemSelect.value
    }
    api.post(`api/stock/paciente/${dataFile.id}/domicilio/`, body).then((response) => {
      const tempList = {...stockList};
      tempList.list.push({...response.data[0],  isUpdate: false, showMessage: false})
      setStockList(tempList);
      setmessage("Item guardado exitosamente");
      setSpiner();
    })
  }

  return (
    <>
      <Box display='grid' gridTemplateColumns='10% 30% 1fr' gridGap='0.5rem'>
        <img src={copy} alt="copy" width='35px' height='35px' className='mr-3' />
        <TypographyMui variant='body2' color='primary' className='align-self-center'>Grupo</TypographyMui>
        <Box>
          <Box>
              <Select
                options={[
                  {value: "medicamentos", label: "Medicamento"},
                  {value: "insumos", label: "Insumo"},
                  {value: "equipos", label: "Equipo"}
                ]}
                placeholder="Seleccione"
                value={groupSelect}
                onChange={(value) => handleGroup(value)}
              >
              </Select>
          </Box>
          <span style={{color: "#E31B0C"}}>{groupSelect?.value ? "" : "Campo obligatorio"}</span>
        </Box>
      </Box>

      <Box display='grid' gridTemplateColumns='10% 30% 1fr' gridGap='0.5rem' mt={3.25}>
        <img src={folder} alt="folder" width='35px' height='35px' className='mr-3' />
        <TypographyMui variant='body2' color='primary' className='align-self-center'>Nombre producto</TypographyMui>
        <Box>
          <Box>
          <Select
            value={itemSelect}
            onChange={(value) => setitemSelect(value)}
            options={
              groupSelect?.value === "equipos"     ? listEquip :
              groupSelect?.value === "insumos"    ? listResource :
              groupSelect?.value === "medicamentos"? listMedical : []
            }
            placeholder="Seleccione"
            styles={{
              // Fixes the overlapping problem of the component
              menu: provided => ({ ...provided, zIndex: 9999 })
            }}
          />
          </Box>
          <span style={{color: "#E31B0C"}}>{itemSelect?.value ? "" : "Campo obligatorio"}</span>
        </Box>
      </Box>

      <Box display='grid' gridTemplateColumns='10% 30% 1fr' gridGap='0.5rem' mt={3.25}>
        <img src={amount} alt="amount" width='35px' height='35px' className='mr-3' />
        <TypographyMui variant='body2' color='primary' className='align-self-center'>Stock actual en domicilio</TypographyMui>
        <Box>

                <TextField size="small" variant='outlined' type='number' name='currentStock'
                  onChange={(event) => setstockItem(event.target.value)}
                  value={stockItem}
                  style={{width: "100%"}}
                />
           <span style={{color: "#E31B0C"}}>
            {stockItem > 0 ? "" : "Campo obligatorio"}
          </span>
        </Box>
      </Box>

      <Box display={message ? 'block' : 'none'} mt={1.5}>
        <Alert severity="success">{message}</Alert>
      </Box>

      <Box display='grid' gridTemplateColumns='10% 30% 1fr' gridGap='0.5rem' mt={5}>
        <Box />
        <Box />
        {(stockItem > 0 && groupSelect?.value && itemSelect?.value) ?
        <Button
          variant='outlined'
          fullWidth
          size='small'
          style={{ backgroundColor: 'rgba(76, 175, 80, 0.08)', color: '#4CAF50' }}
          onClick={onSubmit}
        >
          <AddIcon style={{ color: '#4CAF50' }} className='mr-3' />
          Agregar
        </Button> :
        <Button disabled variant='outlined' fullWidth size='small'>
          <AddIcon style={{ color: '#4CAF50' }} className='mr-3' />
          Agregar
        </Button>
        }
      </Box>
    </>
  )
}

export default CreateStockForm
