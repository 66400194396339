import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import PropTypes from "prop-types";
import apiform from "utils/APIForm";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailMoneyOnly from "views/Widgets/FichaPersonal/EditFichaPersonal/detailMoneyOnly";
import DetailText from "views/Widgets/FichaPersonal/EditFichaPersonal/detailText";
import ModalDialog from "views/Widgets/Common/ModalDialog";


export default function ModalPackage(props) {
  const {
    open,
    closeModal,
    id_paquetizacion,
    reloadPackage,
  } = props;

  const [zona, setZona] = useState("");
  const [complejidad, setComplejidad] = useState("");
  const [descripcion, setdescripcion] = useState("");
  const [descripcionFacturacion, setdescripcionFacturacion] = useState("");
  const [dia_cama, setDia_cama] = useState("");
  const [insumos, setInsumos] = useState("");
  const [medicamentos, setMedicamentos] = useState("");
  const [packageCode, setPackageCode] = useState("");
  const total_afecto = insumos + medicamentos;
  const total_excento = dia_cama * 30;
  const total_mes = total_afecto + total_excento;
  const total_dia = total_mes / 30;

  const list_zona = useSelector(state => state.currentList.LISTA_ZONA);
  const list_complejidades = useSelector(state => state.currentList.LISTA_COMPLEJIDADES);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

  }, [open]);


  function createPackage() {
    const formData = new FormData();

    formData.append("zona", zona.value);
    formData.append("complejidad", complejidad.value);
    formData.append("descripcion", descripcion);
    formData.append("descripcion_facturacion", descripcionFacturacion);
    formData.append("dia_cama", dia_cama);
    formData.append("insumos", insumos);
    formData.append("medicamentos", medicamentos);
    formData.append("total_afecto", total_afecto);
    formData.append("total_excento", total_excento);
    formData.append("total_mes", total_mes);
    formData.append("total_dia", parseInt(total_dia, 10));
    formData.append("id_paquetizacion", id_paquetizacion);
    formData.append("codigo_paquete", packageCode);
    apiform.post("api/paquetes/", formData).then((response) => {
      reloadPackage();
      closeModal();
    })
  }

  const handleZona = (zona) => {
    setZona(zona);
  };

  const handleComplejidad = (complejidad) => {
    setComplejidad(complejidad);
  };

  const handlePackageCode = packageCode => {
    setPackageCode(packageCode.target.value);
  };

  const _content_ = <div className="form-row" style={{ marginBottom: "10rem" }}>
    <DetailText
      label="Código"
      value={packageCode}
      onchange={handlePackageCode}
    />
    <DetailSelect
      label="Zona:"
      value={zona}
      onchange={handleZona}
      list={list_zona}
    />
    <DetailSelect
      label="Complejidad:"
      value={complejidad}
      onchange={handleComplejidad}
      list={list_complejidades}
    />
    <DetailText
      label="Descripción"
      value={descripcion}
      onchange={(event) => setdescripcion(event.target.value) }
    />
      <DetailText
      label="Descripción Facturación"
      value={descripcionFacturacion}
      onchange={(event) => setdescripcionFacturacion(event.target.value) }
    />
    <DetailMoneyOnly
      label="Valor ($) día cama:"
      value={dia_cama}
      money={setDia_cama}
    />
    <DetailMoneyOnly
      label="Valor ($) insumos:"
      value={insumos}
      money={setInsumos}
    />
    <DetailMoneyOnly
      label="Valor ($) medicamentos:"
      value={medicamentos}
      money={setMedicamentos}
    />
  </div>

  const _actions_ = <>
    <Button
      onClick={closeModal}
      color="primary"
      variant="contained"
    >
      Cancelar
    </Button>
    <Button
      onClick={createPackage}
      color="primary"
      variant="contained"
    >
      Crear
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={"Crear paquete"}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}

ModalPackage.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  update: PropTypes.func,
  id_isapre: PropTypes.number,
  id_paquetizaciones: PropTypes.string,
};
