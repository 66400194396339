import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Grid from "@material-ui/core/Grid";



export default function ModalRejectRequest(props) {

  const { open, closeModal, id_order, moduleType } = props;
  const [order, setOrder] = useState([]);
  const [reasonRejection, setReasonRejection] = useState("");


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    api.get(`api/solicitudpedidos/${id_order}`).then(response => {
      setOrder(response.data);
    });
  }, []);

  const [scroll, setScroll] = useState("paper");

  function updateBenefit() {

    const formData = new FormData();

    formData.append("tipo_solicitud", order.tipo_solicitud);
    formData.append("fecha_solicitud", order.fecha_solicitud);
    formData.append("estado_picking", order.estado_picking);
    formData.append("fecha_despacho", order.fecha_despacho);
    formData.append("nombre_paciente", order.nombre_paciente);
    formData.append("es_region", order.es_region);
    formData.append("tiene_medicamentos", order.tiene_medicamentos);
    formData.append("estado_guia_despacho", order.estado_guia_despacho);
    formData.append("estado_solicitud", "Hacer Picking");
    formData.append("traslado_clinica", order.traslado_clinica);
    formData.append("motivo_rechazo", reasonRejection);
    formData.append("id_paciente", order.id_paciente);

    api.get("api/solicitudsku/").then(response => {
      window.location.href = `/admin/income/divisions/${moduleType}`;
    })
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">
        Rechazar creación de código
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <Grid container xs={12} style={{ textAlign: "center" }}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <DetailTextArea
              label="¿POR QUÉ DESEA RECHAZAR LA SOLICITUD?:"
              placeholder="Motivo rechazo"
              value={reasonRejection}
              maxlength="500"
              onchange={e => {
                setReasonRejection(e.target.value);
              }}
            />
            {reasonRejection === "" ? (
              <span className="text-danger">
                Debes ingresar un motivo de rechazo.
              </span>
            ) : null}
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <div className="form" style={{ marginBottom: "3rem" }}>
              <div>
                <p className="text-center font-weight-bold">
                  ¿Está seguro que sea rechazar el fraccionamiento?
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </DialogContent>
      <DialogActions>
        {reasonRejection != "" ? (
          <Button onClick={updateBenefit} color="primary">
            Si
          </Button>
        ) : null}
        <Button onClick={closeModal} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalRejectRequest.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_order: PropTypes.number
};
