import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import api from "utils/API";
import {
  getListSuppliesMedicines,
} from "actions/getListAction";
import ModalDialog from "views/Widgets/Common/ModalDialog";


export default function ModalReplaceSKU(props) {
  const dispatch = useDispatch();
  const { open, closeModal, id_producto, id_detalle, handleRequestReplacement } = props;
  const { order, moduleType, infoProduct } = props;

  const [medicalItemReplace, setmedicalItemReplace] = useState({})

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const openBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);
  const listMedical = useSelector(state => state.currentList.listMedicines);
  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

    useEffect(() => {
        if(medicalResourceData[infoProduct?.id_insumo_medicamento?.id] === undefined && !chargeMedicine) {
          getListSuppliesMedicines(dispatch, chargeMedicine)
        }
    }, [])

  function updateProduct() {

    api.post(`api/bodega/reemplazo/producto/${id_producto}/generar/`, {item_remplazo: medicalItemReplace?.value} ).then(response => {
      handleRequestReplacement()

    })
  }
  const _content_ = <div>
    <div className="form" style={{ marginBottom: "10rem" }}>
      Se solicita el reemplazo del siguiente producto:  <br />
      <div className="mt-3 mb-3">
        {openBackdrop ? null : (
          <ul className="list-group">
            <li className="list-group-item">
              <span className="font-weight-bold">SKU: </span>
              {infoProduct?.id_insumo_medicamento?.SKU}
            </li>
            <li className="list-group-item">
              <span className="font-weight-bold">Grupo: </span>
              {infoProduct?.id_insumo_medicamento?.grupo_producto}
            </li>
            <li className="list-group-item">
              <span className="font-weight-bold">Descripción: </span>
              {infoProduct?.id_insumo_medicamento?.descripcion_producto}
            </li>
            <li className="list-group-item">
              <span className="font-weight-bold">Sugerir medicamento: </span>
              <DetailSelect
                validation={false}
                value={medicalItemReplace}
                onchange={(value) => {setmedicalItemReplace(value)}}
                list={listMedical.length > 0 ? listMedical : [{value: null, label: "Cargando..."}]}
              />
            </li>
          </ul>
        )}
      </div>
      <div className="text-center">
        <span className="font-weight-bold">¿Estás seguro que deseas enviar la solicitud de reemplazo?</span>
      </div>
      <div className="text-center">
        Se enviará una solicitud al área clínica para que indiquen el reemplazo del producto.
      </div>
      <div className="text-center">
        El estado del pedido será "Esperando reemplazo" y cambiará a "Reemplazo aprobado" cuando el área clínica haya contestado.
      </div>
    </div>
  </div>

  const _actions_ = <>
    <Button onClick={closeModal} color="primary">
      Cancelar
    </Button>
    <Button onClick={updateProduct} color="primary">
      Si
    </Button>
  </>

  return (
    <ModalDialog
        open={open}
        onClose={closeModal}
        title={`Solicitud de Reemplazo`}
        _content_={_content_}
        _actions_ = {_actions_}
        maxWidth="sm"
    />
  );
}

ModalReplaceSKU.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number,
  order: PropTypes.array
};
