import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import NavPills from "components/NavPills/NavPills.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
    Grid,
    Typography,
    TextField,
    FormControlLabel,
    Radio,
    Checkbox,
    FormControl,
    FormGroup,
    FormLabel,
    RadioGroup,
} from '@material-ui/core';
import {
    RadioButtonChecked,
    CheckCircle,
    RadioButtonUnchecked,
    CloudUpload,
    Publish
} from "@material-ui/icons/";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import MedicalTableComponent from "views/PatientFile/InsumosYMedicamentos/MedicalTableComponent";
import ResourceTableComponent from "views/PatientFile/InsumosYMedicamentos/ResourceTableComponent";
import HistoryComment from "components/PatientFile/HistoryComment";
import FormNewWork from "components/PatientFile/FormNewWork";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import {classStyle} from "../../utils/styleFunction";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from "moment-timezone";
import {setmessageGeneral } from "actions/getGlobalAction";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);
export default function ModalDecompensation({
    open,
    handleClose,
    patient,
    dataFile,
    listPeriod,
    listassigned,
    listProfDecompensation,
    handleUpdateStateDecompetation,
    switchProps=false,
    flagProps="",
    requestData=null,
    requestDataWork=null,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const classesStyle = classStyle();
    const [changeButtons, setchangeButtons] = useState(true);
    const [changeComment, setchangeComment] = useState(flagProps==="historial");
    const [switchView, setswitchView] = useState(switchProps);
    const [userInfo, setuserInfo] = useState({
        id_cargo: localStorage.getItem("id_cargo"),
        first_name: localStorage.getItem("first_name"),
        last_name: localStorage.getItem("last_name"),
        user_id: localStorage.getItem("user_id"),
    });
    const [checkValidation, setcheckValidation] = useState(false);
    const [validation, setvalidation] = useState(false);
    const [changes, setChanges] = useState(true);
    const [dateEvent, setdateEvent] = useState(moment());
    const [symptoms, setsymptoms] = useState("");
    const [indication, setindication] = useState("");
    const [lengthSymptoms, setlengthSymptoms] = useState(0);
    const [lengthIndication, setlengthIndication] = useState(0);
    const [checkedKinesiologist, setcheckedKinesiologist] = useState(false);
    const [checkedNurse, setcheckedNurse] = useState(false);
    const [checkNewProffesional, setcheckNewProffesional] = useState("No");
    const [checkdoctor, setcheckdoctor] = useState(false);
    const [checkKTM, setcheckKTM] = useState(false);
    const [checkKTR, setcheckKTR] = useState(false);
    const [checkFono, setcheckFono] = useState(false);
    const [checkNurse, setcheckNurse] = useState(false);
    const [checkTO, setcheckTO] = useState(false);
    const [dataMedicalArray, setDataMedicalArray] = useState([]);
    const [dataResourceArray, setDataResourceArray] = useState([]);
    const [arrayAllItem, setarrayAllItem] = useState([]);
    const [dataWork, setdataWork] = useState([]);
    const [indicationWork, setindicationWork] = useState([]);
    const [infoRequest, setinfoRequest] = useState({});
    const [dictComments, setdictComments] = useState({});
    const [arrayComments, setarrayComments] = useState([]);
    const [selectfile, setselectfile] = useState(null);
    const [dictIdProfesionalSelect, setdictIdProfesionalSelect] = useState({});
    const [flag, setflag] = useState(flagProps);
    const limitChartSymptoms = 4000;
    const [balanceIngresos, setbalanceIngresos] = useState([]);
    const [balanceEgresos, setbalanceEgresos] = useState([]);
    const [startDateBalance, setStartDateBalance] = useState(
        moment().format("YYYY-MM-DD[T]HH:mm:ss")
    );
    const [endDateBalance, setEndDateBalance] = useState(
        moment().format("YYYY-MM-DD[T]HH:mm:ss")
    );
    const [specialtyBalance, setSpecialtyBalance] = useState();

    useEffect(() => {
        if(checkValidation){
            validateForm();
        }
        if (changes) {
            initialCharge();
        }
        if (changeButtons) {
            setchangeButtons(false);
        }
        if (changeComment){
            updateComment();
        }
    });

    const initialCharge = () => {
        setChanges(false);
        if (requestDataWork){
            const tempDescompetation = requestDataWork[0].descompensacion;
            setdateEvent(moment(requestDataWork[1].fecha_hora).format("YYYY-MM-DD HH:mm"))
            setsymptoms(tempDescompetation.sintomas)
            setcheckedKinesiologist(tempDescompetation.necesita_kinesiologo);
            setcheckedNurse(tempDescompetation.necesita_enfermero);
            const tempList = requestDataWork[0].solicitud_pedido.lista_insumos.reduce((accum, obj) => ([
                ...accum,
                {
                    ...obj,
                    id: obj.id_insumo_medicamento,
                    cantidad: obj.cantidad,
                    sku: obj.id_insumo_medicamento__SKU,
                    item: obj.id_insumo_medicamento__descripcion_producto,
                    id_insumo: obj.id,
                    cantidad_antigua: obj.cantidad,
                }
            ]), []);
            setarrayAllItem([...tempList]);
            setDataMedicalArray([...tempList.filter((row) => row.id_insumo_medicamento__grupo_producto === "Medicamentos")]);
            setDataResourceArray([...tempList.filter((row) => ["Insumos", "Articulos", "Alimentos"].includes(row.id_insumo_medicamento__grupo_producto))]);
            setinfoRequest(requestDataWork[0]);
            setdictIdProfesionalSelect(requestDataWork[0].profesionales.reduce((accum, obj) => ({
                ...accum,
                [obj]: true,
            }), []));
            setdataWork(requestDataWork[0].tareas_paciente);
            setselectfile(requestDataWork[0].descompensacion.id_aprobacion_examen);

        }
        const arrayRequest = [];
        let getBalanceTypes = null;
        if (dataFile?.id){
            getBalanceTypes = api.get(
                `api/paciente/${dataFile.id}/balancehidrico/tiposmovimientos/`
            );
            arrayRequest.push(getBalanceTypes)
        }
        if (arrayRequest.length > 0) {
            Promise.all(arrayRequest)
            .then(response => {
                const egresos = [];
                const ingresos = [];
                if (dataFile?.id){
                    response[0].data
                    .filter(filtered => filtered.tipo_io === "Ingresos")
                    .map(balance => {
                    const tempRow = {
                        select: false,
                        item: balance.nombre,
                        value: balance.tipo_io,
                        startDate: moment(startDateBalance).format(
                            "YYYY-MM-DD[T]HH:mm:ss"
                        ),
                        endDate: moment(endDateBalance).format("YYYY-MM-DD[T]HH:mm:ss"),
                        speciality: specialtyBalance
                    };
                    ingresos.push(tempRow);
                    });
                    response[0].data
                        .filter(filtered => filtered.tipo_io === "Egresos")
                        .map(balance => {
                        const tempRow = {
                            select: false,
                            item: balance.nombre,
                            value: balance.tipo_io,
                            startDate: moment(startDateBalance).format(
                                "YYYY-MM-DD[T]HH:mm:ss"
                            ),
                            endDate: moment(endDateBalance).format("YYYY-MM-DD[T]HH:mm:ss"),
                            speciality: specialtyBalance
                        };
                        egresos.push(tempRow);
                        });
                    setbalanceIngresos(ingresos);
                    setbalanceEgresos(egresos);
                }
            })
        }

    };

    const validateForm = () => {
        const arrayValidation = [
            (
                (
                    switchView &&
                    indication !== ""
                ) ||
                (
                    !switchView &&
                    symptoms !== "" &&
                    Object.keys(dictIdProfesionalSelect).length > 0
                )
            ),
        ];
        if (arrayValidation.every((item) => item === true)) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    };

    const updateComment = () => {
        setchangeComment(false);
        const tempdictComment = {}
        api.get(`api/paciente/${patient.id}/solicitudes/descompensacion/${requestData ? requestData.id : infoRequest.id}/comentarios/usuarios/`).then((response) => {
            response.data.map((row) => {
                const tempDate = moment(row.fecha_traslado).format("YYYY-MM-DD HH:mm")
                const userId = `${row.id_usuario_id} ${row.id_usuario.first_name} ${row.id_usuario.last_name}`;
                if (tempdictComment[tempDate] === undefined || tempdictComment[tempDate] === null ){
                    tempdictComment[tempDate] = {};
                }
                if (tempdictComment[tempDate][userId]){
                    tempdictComment[tempDate][userId].push(row);
                } else {
                    tempdictComment[tempDate][userId] = [row];
                }

            });
            setdictComments(tempdictComment)
            setarrayComments(response.data)
        }).catch((error) => {
            setmessageGeneral(dispatch,"Error al cargar historial", "error", "¡Error!");
        })
    };

    const handleSymptoms = (value) => {
        if (value.length <= 4000){
            setsymptoms(value);
            setlengthSymptoms(value.length);
        } else {
            setsymptoms(value.substring(0, 4000));
        }
        setcheckValidation(true)
    };

    const handleIndications = (value) => {
        if (value.length <= 4000){
            setindication(value);
            setlengthIndication(value.length);
        } else {
            setindication(value.substring(0, 4000));
        }
        setcheckValidation(true)
    };

    const handleSend = () => {
        const tempArray = [];
        dataMedicalArray.map((row) => {
            tempArray.push({
                cantidad: row.cantidad,
                id_insumo_medicamento: row.id,
            });
        });
        dataResourceArray.map((row) => {
            tempArray.push({
                cantidad: row.cantidad,
                id_insumo_medicamento: row.id,
            });
        });
        const body = {
            "sintomas": symptoms,
            "profesionales": dictIdProfesionalSelect,
            "necesita_insumos": tempArray.length > 0,
            "lista_insumos": tempArray,
        };
        api.post(`api/paciente/${patient.id}/solicitudes/descompensacion/`, body).then((response) => {
            setinfoRequest(response.data);
            setmessageGeneral(dispatch, "Descompensación Generada", "success", "¡Exito!");
            setflag("enviar");
        }).catch((error) => {
            setmessageGeneral(dispatch, "Error al generar descompensación", "error", "¡Error!");
        })
    }

    const handlecheckNewProffesional = (value) => {
        setcheckNewProffesional(value);
        if (value === "No"){
            setcheckdoctor(false);
            setcheckKTM(false);
            setcheckKTR(false);
            setcheckFono(false);
            setcheckNurse(false);
            setcheckTO(false);
        }
    };

    const handleSave = () => {
        const tempItem = [
            ...dataMedicalArray,
            ...dataResourceArray,
        ];
        const tempActualId = [];
        const arrayDelete = [];
        const arrayDeleteComplete = [];
        const arrayUpdate = [];
        const arrayUpdateComplete = [];
        const arrayNew = [];
        const arrayNewComplete = [];
        const dictActualId = {};
        const arrayWorkDelete = [];
        const arrayWorkNew = [...dataWork, ...indicationWork].filter((row) => (
            (row.id === undefined || row.id === null) && (row.id_tarea_padre === undefined || row.id_tarea_padre === null)
        )).reduce((accum, obj) => ([
            ...accum,
            {
                ...obj,
                id_tarea_padre: requestDataWork[1].id,
            }
        ]), []);
        tempItem.map((row) => {
            if (row.id_insumo !== undefined){
                tempActualId.push(row.id_insumo);
                dictActualId[row.id_insumo] = {...row};
            }
            if (row.id_insumo === undefined){
                arrayNew.push({
                    cantidad : row.cantidad,
                    id_insumo_medicamento : row.id,
                });
                arrayNewComplete.push({...row});
            }
        });
        arrayAllItem.map((row) => {
            if (!tempActualId.includes(row.id_insumo) && row.id_insumo !== undefined) {
                arrayDelete.push({id: row.id_insumo});
                arrayDeleteComplete.push({...row})
            }
            if (dictActualId[row.id_insumo]){
                if (row.cantidad !== row.cantidad_antigua && row.cantidad_antigua){
                    arrayUpdate.push({
                        id : row.id_insumo,
                        cantidad : row.cantidad,
                    });
                    arrayUpdateComplete.push({...row});
                }
            }
        });
        const bodyUpdate = {};
        if (arrayDelete.length > 0){
            bodyUpdate["datos_eliminar"] = arrayDelete;
        }
        if (arrayNew.length > 0){
            bodyUpdate["datos_agregar"] = arrayNew;
        }
        if (arrayUpdate.length > 0){
            bodyUpdate["datos_modificar"] = arrayUpdate;
        }

        const body = {
            tareas: {
                datos_eliminar: arrayWorkDelete,
                datos_agregar: arrayWorkNew,
            },
            lista_insumos: {
                datos_eliminar: arrayDeleteComplete,
                datos_agregar: arrayNewComplete,
                datos_modificar: arrayUpdateComplete,
            },
            profecionales: {
                medico: checkdoctor,
                ktm: checkKTM,
                ktr: checkKTR,
                fono: checkFono,
                enfermero: checkNurse,
                to: checkTO,
            },
            indicacion: indication,
        };
        const requestUpdateItems = api.put(`api/paciente/${patient.id}/solicitudes/${infoRequest.id}/insumos/modificar/`, bodyUpdate);
        const requestUpdate = api.post(`api/paciente/${patient.id}/solicitudes/descompensacion/${infoRequest.id}/responder/${requestDataWork[1].id}/`, body);
        Promise.all([
            requestUpdateItems,
            requestUpdate,
        ]).then((request) => {
            if (checkdoctor || checkKTM || checkKTR || checkFono || checkNurse || checkTO){
                window.open(`/admin/Assignments/`);
            }
            setmessageGeneral(dispatch, "Descompensación Respondida", "success", "¡Exito!");
            handleClose();
        }).catch((error) => {
            setmessageGeneral(dispatch, "Error al responder descompensación", "error", "¡Error!");
        })
    };

    const handleEndDecompensation = () => {
        api.get(`api/paciente/${patient.id}/solicitudes/descompensacion/${infoRequest.id}/finalizar/${requestDataWork[1].id}/`).then((response) => {
            handleUpdateStateDecompetation();
            handleClose();
            setmessageGeneral(dispatch, "Descompensación finalizada", "success", "¡Exito!");
        }).catch((error) => {
            setmessageGeneral(dispatch, "Ocurrio un problema al finalizar la descompensación", "error", "¡Error!");
        })
    };

    const handleUploadFile = () =>  {
        const body = new FormData();
        const fileInfo = selectfile[0];
        body.append('archivo', fileInfo);
        body.append('nombre_archivo', fileInfo.name);
        body.append('tipo_documento', "isapre");
        body.append('id_paciente', patient.id);
        api.post(`api/paciente/${patient.id}/solicitudes/descompensacion/${infoRequest.id}/subiraprobacion/`, body).then((response) => {
            setflag("");
            setmessageGeneral(dispatch, "Archivo cargado", "success", "¡Exito!");
        }).catch((error) => {
            setmessageGeneral(dispatch, "Ocurrio un problema al subir archivo", "error", "¡Error!");
        })
    };

    const handleSelectProfesional = (id) => {
        let auxList = dictIdProfesionalSelect
        if (auxList[id]){
            delete auxList[id];
        } else {
            auxList[id] = true;
        }
        setdictIdProfesionalSelect(auxList);
        setchangeButtons(true);
        setcheckValidation(true);
    }

    const title = (flag === "" || flag === "indicacion" ) ? `Descompensación ${switchView ? `id: ${infoRequest.id}` : ""}` :
        flag === "historial" ? "Historial de descompesación" : "Descompensación";


    const _content_ = <div>
        {changeButtons && <div></div>}
        {(flag === "")?
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={4}
                >
                    <Typography variant="body2" gutterBottom>
                        {`Paciente: ${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`}
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={4}
                >
                    <Typography variant="body2" gutterBottom>
                        {`Profesional: ${userInfo.first_name} ${userInfo.last_name}`}
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={4}
                >
                    <Typography variant="body2" gutterBottom>
                        {`Fecha: ${moment(dateEvent).format("YYYY-MM-DD HH:mm")}`}
                    </Typography>
                </Grid>
            </Grid>
            <GridItem xs={12}>
                <NavPills
                    color="primary"
                    alignCenter
                    tabs={[
                        {
                        tabButton: "1. Sintomatología",
                        tabContent: (
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="flex-end"
                                item
                                xs={12}
                            >
                                <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
                                    <TextField
                                        id="sintomatologia_id"
                                        disabled={switchView}
                                        label={switchView ? `Sintomatología que presenta el paciente` : `Indique detalladamente la sintomatología que presenta el paciente`}
                                        multiline
                                        rows={8}
                                        value={symptoms}
                                        variant="outlined"
                                        onChange={(event) => handleSymptoms(event.target.value)}
                                        style={{width: "100%"}}
                                    />
                                </Grid>
                                <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
                                    {`${lengthSymptoms}/${limitChartSymptoms}`}
                                </Typography>
                            </Grid>
                        )},
                        {
                        tabButton: "2. Profesionales",
                        tabContent: (
                            <Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        className={classesStyle.labelBox}
                                        variant="body2" gutterBottom>
                                        {switchView ? "Profesionales solicitados" : `Indique los profesionales de los que requiere asistencia`}
                                    </Typography>
                                </Grid>
                                <Card style={{margin: "0", padding: "1px"}}>
                                    <CardBody  className={classesStyle.cardBox} style={{height: "500px"}} >
                                        <GridItem
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center"
                                            xs={12} sm={12}
                                        >
                                            {listProfDecompensation.map((row, index) => (
                                                <GridItem
                                                    container
                                                    direction="row"
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    xs={12}
                                                    sm={6}
                                                >
                                                <GridItem
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                    xs={1}
                                                >
                                                    <Checkbox
                                                        disabled={switchView}
                                                        value="remember"
                                                        checked={dictIdProfesionalSelect[row.id_usuario_id] ? true : false}
                                                        onChange={(event) => handleSelectProfesional(row.id_usuario_id)}
                                                        color="primary"
                                                    />
                                                </GridItem>
                                                <GridItem
                                                    container
                                                    direction="row"
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    xs={11}
                                                >
                                                    <Typography
                                                        style={{textTransform: "none"}}
                                                        color="textPrimary"
                                                        variant="body2"
                                                    >
                                                        {`${row.nombres} ${row.apellido_paterno} ${row.apellido_materno} - ${row.id_cargo__nombre_corto}`}
                                                    </Typography>
                                                </GridItem>
                                            </GridItem>
                                            ))
                                            }

                                        </GridItem>
                                    </CardBody>
                                </Card>
                            </Grid>
                        )
                        },
                        {
                        tabButton: "3. Medicamentos",
                        tabContent: (
                            <MedicalTableComponent
                                dataMedicalArray={dataMedicalArray}
                                setDataMedicalArray={setDataMedicalArray}
                                setPageRefresh={() => {}}
                                margin={"0"}
                                disabled={switchView}
                            />
                        )},
                        {
                        tabButton: "4. Insumos",
                        tabContent: (
                            <ResourceTableComponent
                                dataResourceArray={dataResourceArray}
                                setDataResourceArray={setDataResourceArray}
                                setPageRefresh={() => {}}
                                margin={"0"}
                                disabled={switchView}
                            />
                        )},
                    ]}
                />
            </GridItem>
        </Grid> :
        flag === "enviar" ?
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0"}}
        >
            <Typography variant="body2" gutterBottom>
                {`Se envió con éxito el registro ID ${infoRequest.id} de Descompensación a los profesionales:`}
            </Typography>
            {  checkedKinesiologist &&
            <Typography variant="body2" gutterBottom>
                {`Kinesiólogo(a) de urgencia`}
            </Typography>
            }
            { checkedNurse &&
            <Typography variant="body2" gutterBottom>
                {`Enfermero(a) de urgencia`}
            </Typography>
            }
            <Typography variant="body2" gutterBottom>
                {`Puede revisar el historial del registro en el menú “solicitudes del paciente”`}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {`Puede revisar las tareas que se le asignenen para esta descompensación en la sección “tareas del paciente”`}
            </Typography>
        </Grid> :
        flag === "indicacion" ?
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={4}
                >
                    <Typography variant="body2" gutterBottom>
                        {`Paciente: ${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`}
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={4}
                >
                    <Typography variant="body2" gutterBottom>
                        {`Profesional: ${userInfo.first_name} ${userInfo.last_name}`}
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={4}
                >
                    <Typography variant="body2" gutterBottom>
                        {`Fecha: ${moment(dateEvent).format("YYYY-MM-DD HH:mm")}`}
                    </Typography>
                </Grid>
            </Grid>
            <GridItem xs={12}>
                <NavPills
                    color="primary"
                    alignCenter
                    tabs={[
                        {
                        tabButton: "1. Indicaciones",
                        tabContent: (
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="flex-end"
                                item
                                xs={12}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-end"
                                    item
                                    xs={6}
                                >
                                    <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
                                        <TextField
                                            id="sintomatologia_id"
                                            disabled={true}
                                            label="Sintomatología que presenta el paciente"
                                            multiline
                                            rows={8}
                                            value={symptoms}
                                            variant="outlined"
                                            onChange={(event) => handleIndications(event.target.value)}
                                            style={{width: "100%"}}
                                        />
                                    </Grid>
                                    <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
                                        {`${lengthSymptoms}/${limitChartSymptoms}`}
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-end"
                                    item
                                    xs={6}
                                >
                                    <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
                                        <TextField
                                            id="sintomatologia_id"
                                            label="Indicaciones generales para el personal en domicilio"
                                            multiline
                                            rows={8}
                                            value={indication}
                                            variant="outlined"
                                            onChange={(event) => handleIndications(event.target.value)}
                                            style={{width: "100%"}}
                                        />
                                    </Grid>
                                    <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
                                        {`${lengthIndication}/${limitChartSymptoms}`}
                                    </Typography>
                                </Grid>
                            </Grid>

                        )},
                        {
                        tabButton: "2. Medicamentos",
                        tabContent: (
                            <MedicalTableComponent
                                dataMedicalArray={dataMedicalArray}
                                setDataMedicalArray={setDataMedicalArray}
                                setPageRefresh={() => {}}
                                margin={"0"}
                            />
                        )},
                        {
                        tabButton: "3. Insumos",
                        tabContent: (
                            <ResourceTableComponent
                                dataResourceArray={dataResourceArray}
                                setDataResourceArray={setDataResourceArray}
                                setPageRefresh={() => {}}
                                margin={"0"}
                            />
                        )},
                        {
                        tabButton: "4. Tareas asociadas",
                        tabContent: (
                            <FormNewWork
                                dataFile={dataFile}
                                listPeriod={listPeriod}
                                listassigned={listassigned}
                                balanceIngresos={balanceIngresos}
                                balanceEgresos={balanceEgresos}
                                setbalanceIngresos={setbalanceIngresos}
                                setbalanceEgresos={setbalanceEgresos}
                                startDateBalance={startDateBalance}
                                setStartDateBalance={setStartDateBalance}
                                endDateBalance={endDateBalance}
                                setEndDateBalance={setEndDateBalance}
                                specialtyBalance={specialtyBalance}
                                setSpecialtyBalance={setSpecialtyBalance}
                                dataWork={dataWork}
                                setdataWork={setdataWork}
                                indicationWork={indicationWork}
                                setindicationWork={setindicationWork}
                            />
                        )},
                        {
                            tabButton: "5. Profesionales Extra",
                            tabContent: (
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    item
                                    xs={12}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        item
                                        xs={12}
                                    >
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Indique si la descompensación requiere de profesionales que asistan al domicilio:</FormLabel>
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        item
                                        xs={12}
                                    >
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                                <FormControlLabel
                                                    value="Sí"
                                                    control={
                                                        <Radio
                                                            checked={checkNewProffesional === "Sí"}
                                                            onChange={(event) => handlecheckNewProffesional(event.target.value)}
                                                            color="primary"
                                                            value="Sí"
                                                            icon={
                                                                <RadioButtonUnchecked style={{fill: indigo[500]}} />
                                                            }
                                                            checkedIcon={
                                                                <RadioButtonChecked style={{fill: indigo[500]}}/>
                                                            }
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot
                                                            }}
                                                        />
                                                    }
                                                    label="Sí"
                                                    labelPlacement="bottom"
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                />
                                                <FormControlLabel
                                                    value="Sí"
                                                    control={
                                                        <Radio
                                                            checked={checkNewProffesional === "No"}
                                                            onChange={(event) => handlecheckNewProffesional(event.target.value)}
                                                            color="primary"
                                                            value="No"
                                                            icon={
                                                                <RadioButtonUnchecked style={{fill: indigo[500]}} />
                                                            }
                                                            checkedIcon={
                                                                <RadioButtonChecked style={{fill: indigo[500]}}/>
                                                            }
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot
                                                            }}
                                                        />
                                                    }
                                                    label="No"
                                                    labelPlacement="bottom"
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {checkNewProffesional === "Sí" &&
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        item
                                        xs={12}
                                    >
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkdoctor}
                                                        onChange={(event) => setcheckdoctor(event.target.checked)} name="Medico(a)"
                                                        checkedIcon={
                                                            <CheckCircle style={{fill: indigo[500]}} />
                                                        }
                                                        icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                                    />
                                                }
                                                label="Medico"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkFono}
                                                        onChange={(event) => setcheckFono(event.target.checked)} name="Fono"
                                                        checkedIcon={
                                                            <CheckCircle style={{fill: indigo[500]}} />
                                                        }
                                                        icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                                    />}
                                                label="Fonoaudiólogo "
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkNurse}
                                                        onChange={(event) => setcheckNurse(event.target.checked)} name="Enfermero"
                                                        checkedIcon={
                                                            <CheckCircle style={{fill: indigo[500]}} />
                                                        }
                                                        icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                                    />}
                                                label="Enfermero(a) "
                                            />
                                            </FormGroup>
                                        </FormControl>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkTO}
                                                        onChange={(event) => setcheckTO(event.target.checked)} name="TO"
                                                        checkedIcon={
                                                            <CheckCircle style={{fill: indigo[500]}} />
                                                        }
                                                        icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                                    />}
                                                label="TO"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkKTR}
                                                        onChange={(event) => setcheckKTR(event.target.checked)} name="KTR"
                                                        checkedIcon={
                                                            <CheckCircle style={{fill: indigo[500]}} />
                                                        }
                                                        icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                                    />}
                                                label="KTR "
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkKTM}
                                                        onChange={(event) => setcheckKTM(event.target.checked)} name="KTM"
                                                        checkedIcon={
                                                            <CheckCircle style={{fill: indigo[500]}} />
                                                        }
                                                        icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                                                    />}
                                                label="KTM"
                                            />
                                            </FormGroup>
                                        </FormControl>

                                    </Grid>
                                    }
                                </Grid>
                            )}

                    ]}
                />
            </GridItem>
        </Grid> :
        flag === "historial" ?
        <HistoryComment
            flagProps={flagProps}
            requestID={requestData ? requestData.id : infoRequest.id}
            dictComments={dictComments}
            setdictComments={setdictComments}
            arrayComments={arrayComments}
            setarrayComments={setarrayComments}
            title={"Historial de descompesación"}
            stylePaper={{width: "100%", maxHeight: "500px", overflow: "auto"}}
        /> :
        flag === "finalizar" ?
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0"}}
        >
            <Typography variant="body2" gutterBottom>
                {`Al continuar se dará por finalizada la solicitud de descompensación`}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {`* Los elementos solicitados durante la descompensación continuarán con su actual procedimiento`}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {`* Para interrumpir los elementos solicitados durante la descompensación, se deben interrumpir desde sus respectivos modulo (RUTA, BODEGA) `}
            </Typography>
        </Grid> :
        flag === "isapre" &&
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{margin: "10px 0"}}
        >
            <Button
                variant="contained"
                component="label"
                color="primary"
                style={{margin: "10px 0"}}
                startIcon={<Publish />}
            >
                Cargar archivo de isapre
                <TextField
                    type="file"
                    id="exam"
                    onChange={(event) =>  setselectfile(event.target.files)}
                    fullWidth my={2}
                    style={{display: 'none'}}
                    accept={["pdf", "jpg", "jpeg", "png"]}
                />
            </Button>
            {selectfile &&
                `${selectfile[0].name}`
            }
        </Grid>
        }
    </div>

    const _actions_ = <>
    { (selectfile && flag === "indicacion" && switchView) ?
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: indigo[500], marginRight: "10px"}}
        onClick={() => setflag("finalizar")}
    >
        Finalizar
    </Button> :
    (
        selectfile === null &&
        switchView && flag !== "isapre"
    ) &&
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: indigo[500], marginRight: "10px"}}
        onClick={() => setflag("isapre")}
    >
        Subir Autorización ISAPRE
    </Button>
    }
    {(flag === "finalizar" && switchView) &&
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: indigo[500], marginRight: "10px"}}
        onClick={handleEndDecompensation}
    >
        Finalizar Descompensación
    </Button>
    }
    { (flag === "isapre" && switchView  && selectfile) ?
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: indigo[500], marginRight: "10px"}}
        onClick={handleUploadFile}
        startIcon={<CloudUpload />}
    >
        Subir archivo
    </Button>
    : (flag === "isapre" && switchView) &&
    <Button
        disabled
        variant="contained"
        component="label"
        startIcon={<CloudUpload />}
        style={{marginRight: "10px"}}
    >
        Subir archivo
    </Button>
    }
    {(flag === "" && switchView ) &&
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: indigo[500], marginRight: "10px"}}
        onClick={() => setflag("indicacion")}
    >
        Dar Indicaciones
    </Button>
    }
    {(flag === "indicacion" && switchView && flag !== "isapre"  ) && (
    validation ?
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: indigo[500], marginRight: "10px"}}
        onClick={handleSave}
    >
        Guardar
    </Button> :
    flag !== "isapre"  &&
    <Button
        disabled
        mr={2}
        variant="contained"
        style={{marginRight: "10px"}}
    >
        Guardar
    </Button>
    )}
    {switchView && (
    flag !== "historial" ?
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: indigo[500], marginRight: "10px"}}
        onClick={() => {
            setflag("historial")
            setchangeComment(true)
        }}
    >
        Ver historial
    </Button> :
    <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: indigo[500], marginRight: "10px"}}
        onClick={() => setflag("")}
    >
        Atras
    </Button>
    )
    }
    {(validation &&  flag === "" && !switchView)?
        <Button
        mr={2}
        variant="contained"
        className="text-white"
        style={{backgroundColor: indigo[500], marginRight: "10px"}}
        onClick={handleSend}
    >
        Enviar
    </Button> :  (flag === "" && !switchView) &&
    <Button
        disabled
        mr={2}
        variant="contained"
        style={{marginRight: "10px"}}
    >
        Enviar
    </Button>
    }

    <Button
        mr={2}
        variant="contained"
        style={{backgroundColor: red[500]}}
        className="text-white"
        onClick={handleClose}
    >
        Cerrar
    </Button>

    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={title}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth = "lg"
        />
    );
}