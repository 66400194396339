import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import apiform from "utils/APIForm";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export default function ModalChangeSku(props) {
  const classes = useStyles();

  const { open, closeModal, divisionRequest, moduleType} = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  function updateDivision() {

    const formData = new FormData();

    formData.append("cantidad_fraccionar", divisionRequest.cantidad_fraccionar);
    formData.append("observaciones", divisionRequest.observaciones);
    formData.append("cantidad_resultante", divisionRequest.cantidad_resultante);
    formData.append("estado", "Esperar a egresos");
    formData.append("id_producto_fraccionar", divisionRequest.id_producto_fraccionar);
    formData.append("id_producto_resultante", divisionRequest.id_producto_resultante);
    formData.append("area", "Ingresos");

    apiform
      .put(`api/solicitudfraccionamiento/${divisionRequest.id}`, formData)
      .then(response => {
        console.log(response);
        window.location.href = `/admin/income/divisions/${moduleType}`;
      })

  }

  function closeNoSave() {
    props.setSKU_final(props.checkSkuFinal);
    props.closeModal();
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Sugerir SKU</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div style={{ marginBottom: "10rem" }}>
          <p className="text-center font-weight-bold mb-4">
            ¿Por cuál SKU se debiera fraccionar?
          </p>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justify="center"
            spacing={2}
          >
            <DetailSelect
              label="SKU:"
              value={props.value}
              onchange={props.onChange}
              list={props.list}
            />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeNoSave} color="primary">
          Cancelar
        </Button>
        <Button onClick={closeModal} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalChangeSku.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func
};
