import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailNumber from "views/Widgets/FichaPersonal/EditFichaPersonal/detailNumber";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";



export default function ModalReplaceInsumo(props) {


  const {
    open,
    closeModal,
    id_producto,
    id_detalle,
    id_insumo_reemplazo
  } = props;
  const [pedido, setPedido] = useState("");
  const [pedido_to_replace, setPedido_to_replace] = useState("");
  const [description, setDescription] = useState("");
  const [sku, setSku] = useState("");
  let list_sku = [];
  const [quantity, setQuantity] = useState("");
  const [supplies, setSupplies] = useState([]);
  const [id_supply, setId_supply] = useState("");

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClose = () => {

  };

  const handleToggle = () => {
    setOpen_backgrop(!open_backgrop);
  };

  useEffect(() => {
    api.get(`api/productospedidos/${id_producto}`).then(response => {
      setPedido_to_replace(response.data);
    });
    api.get("api/insumosmedicamento/").then(result => {
      setSupplies(result.data);
    });
  }, []);

  console.log("PEDIDO A REEMPLAZAR", pedido_to_replace);

  console.log("id supply", id_supply);

  console.log("id_producto AAAAAAA", id_producto);

  supplies
    .filter(supply => ["Insumos", "Articulos", "Alimentos"].includes(supply.grupo_producto))
    .map(filtered => {
      list_sku.push({
        value: filtered.SKU,
        label: filtered.SKU
      });
    });

  useEffect(() => {
    supplies
      .filter(supply => supply.SKU === sku.value)
      .map(filtered => {
        setId_supply(filtered.id);
        setDescription(filtered.descripcion_producto);
      });
    console.log("id_supply", id_supply);
  });

  const handleSKU = sku => {
    setSku(sku);
  };

  console.log("producto", pedido);

  const [scroll, setScroll] = useState("paper");
  console.log("id_producto", id_producto);

  function updateProduct() {

    const formData_reemplazar = new FormData();

    formData_reemplazar.append(
      "observaciones",
      pedido_to_replace.observaciones
    );
    formData_reemplazar.append("estado", "Reemplazado");
    formData_reemplazar.append("cantidad", pedido_to_replace.cantidad);
    formData_reemplazar.append("picking", pedido_to_replace.picking);
    formData_reemplazar.append("reemplazado", true);
    formData_reemplazar.append(
      "cantidad_por_enviar",
      pedido_to_replace.cantidad_por_enviar
    );
    formData_reemplazar.append("estado_reemplazo", "Aprobado");
    formData_reemplazar.append(
      "id_insumo_medicamento",
      pedido_to_replace.id_insumo_medicamento
    );
    formData_reemplazar.append(
      "id_solicitud_pedido",
      pedido_to_replace.id_solicitud_pedido
    );

    const formData_nuevo = new FormData();

    formData_nuevo.append("observaciones", pedido_to_replace.observaciones);
    formData_nuevo.append("estado", "Incompleta");
    formData_nuevo.append("cantidad", quantity);
    formData_nuevo.append("picking", 0);
    formData_nuevo.append("reemplazado", false);
    formData_nuevo.append("cantidad_por_enviar", quantity);
    formData_nuevo.append("estado_reemplazo", "No solicitado");
    formData_nuevo.append("id_insumo_medicamento", id_supply);
    formData_nuevo.append(
      "id_solicitud_pedido",
      pedido_to_replace.id_solicitud_pedido
    );
    formData_nuevo.append(
      "id_producto_reemplazo",
      pedido_to_replace.id_insumo_medicamento
    );

    apiform
      .put(`api/productospedidos/${id_producto}`, formData_reemplazar)
      .then(response => {
        console.log(response);
        apiform
          .post("api/productospedidos/", formData_nuevo)
          .then(response => {
            console.log(response);
            window.location.href = `/admin/warehouse/order/${id_detalle}`;
          })
          .catch(error =>
            {}
          );
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Solicitud de Reemplazo</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "10rem" }}>
          <DetailTextDisabled label="GRUPO:" value={"Insumos"} />
          <DetailSelect
            label="SKU:"
            value={sku}
            onchange={handleSKU}
            list={list_sku}
          />
          <DetailTextDisabled label="DESCRIPCIÓN:" value={description} />
          <DetailNumber
            label="CANTIDAD:"
            value={quantity}
            onchange={e => {
              if (/^[0-9\b]+$/.test(e.target.value)) {
                setQuantity(e.target.value);
              }
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Cancelar
        </Button>
        <Button onClick={updateProduct} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalReplaceInsumo.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number,
  id_insumo_reemplazo: PropTypes.number
};
