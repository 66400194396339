import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";
import { grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";

moment.locale("es");

const DateFilter = props => {
  const {
    title,
    date,
    setDate,
  } = props;

  return (
    <Grid item container xs={12} style={{ padding: "1rem" }}>
      <Grid item container xs={6}  alignItems="center">
        <Typography variant="body2" gutterBottom style={{ textTransform: "none", color: grey[900] }}>
          {title}
        </Typography>
      </Grid>
      <Grid item container alignItems="center" xs={6} style={{margin: "-17px 0 0 0"}}>
        <MuiPickersUtilsProvider locale="es" utils={MomentUtils} >
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD-MM-YYYY"
            margin="normal"
            id="date-picker-inline1-1"
            value={date}
            onChange={(e)=> setDate(e)}
            KeyboardButtonProps={{"aria-label": "change date"}}
            autoOk={true}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

export default DateFilter;