import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import "./Styles.css";
import CardTitle from "./FichaPersonal/cardTitle";
import ViewTitle from "./FichaPersonal/viewTitle";
import DetailText from "./FichaPersonal/EditFichaPersonal/detailText";
import DetailSelect from "./FichaPersonal/EditFichaPersonal/detailSelect";
import DetailNumber from "./FichaPersonal/EditFichaPersonal/detailNumber";
import DetailMoney from "./FichaPersonal/EditFichaPersonal/detailMoney";
import DetailTextDisabled from "./FichaPersonal/EditFichaPersonal/detailTextDisabled";
import DetailBoolean from "./FichaPersonal/EditFichaPersonal/detailBoolean";
import DetailMoneyDisabled from "./FichaPersonal/EditFichaPersonal/detailMoneyDisabled";
import DetailTextArea from "./FichaPersonal/EditFichaPersonal/detailTextArea";
import DetailTextCheck from "./FichaPersonal/EditFichaPersonal/detailTextCheck";
import DetailSelectCheck from "./FichaPersonal/EditFichaPersonal/detailSelectCheck";
import DetailFileMultiple from "./FichaPersonal/EditFichaPersonal/detailFileMultiple";


const useStyles = makeStyles((theme) => ({

}));

const Create = (props) => {
  const writtenNumber = require("written-number");
  writtenNumber.defaults.lang = "es";

  const classes = useStyles();
  const [apellido_paterno, setApellido_paterno] = useState("");
  const [apellido_materno, setApellido_materno] = useState("");
  const [nombres, setNombres] = useState("");
  const [rut, setRut] = useState("");
  const [sexo, setSexo] = useState("");
  const [get_sexo, setGet_sexo] = useState("");
  let list_sexo = [];
  const [estado_civil, setEstado_civil] = useState("");
  const [get_estado_civil, setGet_estado_civil] = useState("");
  let list_estado_civil = [];
  const [direccion_calle, setDireccion_calle] = useState("");
  const [direccion_numero, setDireccion_numero] = useState("");
  const [direccion_block, setDireccion_block] = useState("");
  const [direccion_departamento, setDireccion_departamento] = useState("");
  const [get_region, setGet_region] = useState("");
  let list_region = [];
  const [ciudad, setCiudad] = useState("");
  const [nacionalidad, setNacionalidad] = useState("");
  const [get_nacionalidad, setGet_nacionalidad] = useState("");
  let list_nacionalidad = [];
  const [nacimiento, setNacimiento] = useState("");
  const [email, setEmail] = useState("");
  const [banco, setBanco] = useState({value:"No", label:"No Ingresado"});
  const [get_banco, setGet_banco] = useState("");
  let list_banco = [];
  const [cuenta_banco, setCuenta_banco] = useState("");
  const [tipo_cuenta_banco, setTipo_cuenta_banco] = useState("");
  const [get_tipo_cuenta_banco, setGet_tipo_cuenta_banco] = useState("");
  let list_cuenta_banco = [];
  const [nivel_estudios, setNivel_estudios] = useState({value:"No", label:"No Ingresado"});
  const [get_nivel_estudios, setGet_nivel_estudios] = useState("");
  let list_nivel_estudios = [];
  const [estado_estudios, setEstado_estudios] = useState("");
  const [get_estado_estudios, setGet_estado_estudios] = useState("");
  let list_estado_estudios = [];
  const [titulo, setTitulo] = useState("");
  const [id_cargo, setId_cargo] = useState("");
  const [get_cargo, setGet_cargo] = useState("");
  let list_cargo = [];
  const [fecha_inicio_contrato, setFecha_inicio_contrato] = useState("");
  const [fecha_fin_contrato, setFecha_fin_contrato] = useState("");
  const [tipo_contrato, setTipo_contrato] = useState("");
  const [get_tipo_contrato, setGet_tipo_contrato] = useState("");
  let list_tipo_contrato = [];
  const [empresa, setEmpresa] = useState("");
  const [get_empresa, setGet_empresa] = useState("");
  let list_empresa = [];
  const [jornada_laboral, setJornada_laboral] = useState(0);
  const [sueldo_base, setSueldo_base] = useState(0);
  const [texto_sueldo_base, setTexto_sueldo_base] = useState(
    writtenNumber(sueldo_base)
  );
  const [bono_responsabilidad, setBono_responsabilidad] = useState(0);
  const [texto_responsabilidad, setTexto_responsabilidad] = useState(
    writtenNumber(bono_responsabilidad)
  );
  const bono_colacion = 0;
  const bono_movilizacion = 0;
  const [viaticos, setViaticos] = useState(0);
  const [texto_viaticos, setTexto_viaticos] = useState(writtenNumber(viaticos));
  const [seguro_complementario, setSeguro_complementario] = useState(0);
  const [seguro_cesantia, setSeguro_cesantia] = useState(0);
  const [asignacion_telefono, setAsignacion_telefono] = useState(false);
  const [asignacion_uniforme, setAsignacion_uniforme] = useState(false);
  const [asignacion_equipo, setAsignacion_equipo] = useState(false);
  const [nombre_isapre, setNombre_isapre] = useState("");
  const [get_nombre_isapre, setGet_nombre_isapre] = useState("");
  let list_isapres = [];
  const [valor_pactado_isapre, setValor_pactado_isapre] = useState(0);

  const [id_lista_afp, setId_lista_afp] = useState("");
  const [get_afp, setGet_afp] = useState("");
  let list_afp = [];
  const [porcentaje_afp, setPorcentaje_afp] = useState(0);
  const porcentaje_isapre = 0.07;
  const porcentaje_cesantia = 0.006;

  const total_imponible = Math.trunc(
    sueldo_base + bono_responsabilidad
  );
  const [texto_imponible, setTexto_imponible] = useState(
    writtenNumber(total_imponible)
  );
  const total_haberes = Math.trunc(
    total_imponible + viaticos + bono_colacion + bono_movilizacion
  );
  const [texto_haberes, setTexto_haberes] = useState(
    writtenNumber(total_haberes)
  );
  const total_liquido = Math.trunc(
    total_haberes -
      total_imponible * porcentaje_afp -
      total_imponible * porcentaje_isapre -
      total_imponible * porcentaje_cesantia
  );
  const [texto_liquido, setTexto_liquido] = useState(
    writtenNumber(total_liquido)
  );
  const [funciones, setFunciones] = useState("");

  const [archivo_carga_masiva, setArchivo_carga_masiva] = useState(null);

  const [asignacion_caja, setAsignacion_caja] = useState(0);
  const [texto_caja, setTexto_caja] = useState(writtenNumber(asignacion_caja));
  const [
    bono_responsabilidad_inventario,
    setBono_responsabilidad_inventario,
  ] = useState(0);
  const [
    texto_responsabilidad_inventario,
    setTexto_responsabilidad_inventario,
  ] = useState(writtenNumber(bono_responsabilidad_inventario));

  const [area, setArea] = useState();
  const [id_jefatura, setId_jefatura] = useState();
  const [jefatura, setJefatura] = useState();
  const [valid, setValid] = useState(false);
  const [rut_formato, setRut_formato] = useState("");
  const [getTipoPago, setGetTipoPago] = useState([]);
  let listTipoPago = [];
  const [tipoPago, setTipoPago] = useState({value:"No", label:"No Ingresado"});
  const [telefono1, setTelefono1] = useState("");
  const [telefono2, setTelefono2] = useState("");
  const [telefono3, setTelefono3] = useState("");
  const [getCentroCosto, setGetCentroCosto] = useState([]);
  let listCentroCosto = [];
  const [centroCosto, setCentroCosto] = useState("");
  const [getRegiones, setGetRegiones] = useState([]);
  let listRegiones = [];
  const [region, setRegion] = useState("");
  const [idRegion, setIdRegion] = useState("");
  const [getConveniosIsapres, setGetConveniosIsapres] = useState([]);
  let listConveniosIsapre = [];
  const [convenioIsapre, setConvenioIsapre] = useState("");
  const [flagRegion, setFlagRegion] = useState(false);
  const [getComuna, setGetComuna] = useState([]);
  let listComunas = [];
  const [comuna, setComuna] = useState("");
  const [asignacionLaboral, setAsignacionLaboral] = useState(0);
  const [filesFicha, setFilesFicha] = useState([]);
  let sendFilesFicha = [...filesFicha];
  const [bonoIncentivo, setBonoIncentivo] = useState(0);
  const [bonoCompensatorio, setBonoCompensatorio] = useState(0);
  const [bonoColacion, setBonoColacion] = useState(0);
  const [bonoMovilizacion, setBonoMovilizacion] = useState(0);
  const [desgasteHerramientas, setDesgasteHerramientas] = useState(0);
  const [cuotaSindical, setCuotaSindical] = useState(0);
  const [idCargo, setIdCargo] = useState("");
  const [tipoSolicitud, setTipoSolicitud] = useState("");

  useEffect(() => {
    if (flagRegion) {

      setFlagRegion(false);
      listComunas = [];
      loadComunas();
    }
  });

  const loadComunas = () => {
    const getComuna = api.get(`api/locaciones/regiones/${idRegion}/comunas/`);
    Promise.all([getComuna])
      .then((response) => {
        setGetComuna(response[0].data);

      })

  };

  getComuna.map((comuna) => {
    listComunas.push({ value: comuna.id, label: comuna.nombre });
  });

  useEffect(() => {
    api
      .get("api/cargos/?codigo=Gerencia/")
      .then((response) => setIdCargo(response.data.id))


    api
      .get("api/tiposolicitud/?codigo=Contratacion/")
      .then((response) => setTipoSolicitud(response.data.id))


    api.get("api/convenioisapre/").then((result) => {
      setGetConveniosIsapres(result.data);
    });

    api.get("api/locaciones/regiones/").then((result) => {
      setGetRegiones(result.data);
    });

    api.get("api/centrocosto/").then((result) => {
      setGetCentroCosto(result.data);
    });

    api.get("api/auxlistas/tipo_pago").then((result) => {
      setGetTipoPago(result.data);
    });

    api.get("api/auxlistas/lista_sexo").then((result) => {
      setGet_sexo(result.data);
    });

    api.get("api/auxlistas/lista_estado_civil").then((result) => {
      setGet_estado_civil(result.data);
    });

    api.get("api/auxlistas/lista_region").then((result) => {
      setGet_region(result.data);
    });

    api.get("api/auxlistas/lista_nacionalidad").then((result) => {
      setGet_nacionalidad(result.data);
    });

    api.get("api/auxlistas/lista_banco").then((result) => {
      setGet_banco(result.data);
    });

    api.get("api/auxlistas/lista_cuenta_banco").then((result) => {
      setGet_tipo_cuenta_banco(result.data);
    });

    api.get("api/auxlistas/lista_tipo_estudios").then((result) => {
      setGet_nivel_estudios(result.data);
    });

    api.get("api/auxlistas/lista_estado_estudio").then((result) => {
      setGet_estado_estudios(result.data);
    });

    api.get("api/auxlistas/lista_tipo_contrato").then((result) => {
      setGet_tipo_contrato(result.data);
    });

    api.get("api/auxlistas/lista_empresa").then((result) => {
      setGet_empresa(result.data);
    });

    api.get("api/cargos/").then((result) => {
      setGet_cargo(result.data);
    });

    api.get("api/auxlistas/lista_isapres").then((result) => {
      setGet_nombre_isapre(result.data);
    });

    api.get("api/listaafp/").then((result) => {
      setGet_afp(result.data);
    });
  }, []);

  useEffect(() => {
    if (
      apellido_paterno !== "" &&
      apellido_materno !== "" &&
      nombres !== "" &&
      rut !== "" &&
      estado_civil !== "" &&
      direccion_calle !== "" &&
      direccion_numero !== "" &&
      ciudad !== "" &&
      comuna !== "" &&
      region !== "" &&
      nacionalidad !== "" &&
      nacimiento !== "" &&
      fecha_inicio_contrato !== "" &&
      fecha_fin_contrato !== "" &&
      tipo_contrato !== "" &&
      empresa !== "" &&
      id_cargo !== "" &&
      nombre_isapre !== "" &&
      id_lista_afp !== ""
    ) {
      setValid(true);
    }
  });

  getConveniosIsapres.map((convenio) => {
    listConveniosIsapre.push({ value: convenio.id, label: convenio.nombre });
  });

  getRegiones.map((region) => {
    listRegiones.push({ value: region.id, label: region.nombre });
  });

  getCentroCosto.map((centro) => {
    listCentroCosto.push({ value: centro.id, label: centro.nombre });
  });

  getTipoPago.map((pago) => {
    listTipoPago.push({ value: pago[0], label: pago[1] });
  });

  for (let i = 0; i < get_sexo.length; i++) {
    list_sexo.push({ value: get_sexo[i][0], label: get_sexo[i][1] });
  }

  for (let i = 0; i < get_estado_civil.length; i++) {
    list_estado_civil.push({
      value: get_estado_civil[i][0],
      label: get_estado_civil[i][1],
    });
  }

  for (let i = 0; i < get_region.length; i++) {
    list_region.push({ value: get_region[i][0], label: get_region[i][1] });
  }

  for (let i = 0; i < get_nacionalidad.length; i++) {
    list_nacionalidad.push({
      value: get_nacionalidad[i][0],
      label: get_nacionalidad[i][1],
    });
  }

  for (let i = 0; i < get_banco.length; i++) {
    list_banco.push({ value: get_banco[i][0], label: get_banco[i][1] });
  }

  for (let i = 0; i < get_tipo_cuenta_banco.length; i++) {
    list_cuenta_banco.push({
      value: get_tipo_cuenta_banco[i][0],
      label: get_tipo_cuenta_banco[i][1],
    });
  }

  for (let i = 0; i < get_nivel_estudios.length; i++) {
    list_nivel_estudios.push({
      value: get_nivel_estudios[i][0],
      label: get_nivel_estudios[i][1],
    });
  }

  for (let i = 0; i < get_estado_estudios.length; i++) {
    list_estado_estudios.push({
      value: get_estado_estudios[i][0],
      label: get_estado_estudios[i][1],
    });
  }

  for (let i = 0; i < get_tipo_contrato.length; i++) {
    list_tipo_contrato.push({
      value: get_tipo_contrato[i][0],
      label: get_tipo_contrato[i][1],
    });
  }

  for (let i = 0; i < get_empresa.length; i++) {
    list_empresa.push({ value: get_empresa[i][0], label: get_empresa[i][1] });
  }

  for (let i = 0; i < get_cargo.length; i++) {
    list_cargo.push({ value: get_cargo[i].id, label: get_cargo[i].nombre });
  }

  for (let i = 0; i < get_nombre_isapre.length; i++) {
    list_isapres.push({
      value: get_nombre_isapre[i][0],
      label: get_nombre_isapre[i][1],
    });
  }

  for (let i = 0; i < get_afp.length; i++) {
    list_afp.push({ value: get_afp[i].id, label: get_afp[i].nombre });
  }

  const handleComuna = (comuna) => {
    setComuna(comuna);
  };

  const handleConvenio = (convenio) => {
    setConvenioIsapre(convenio);
  };

  const handleRegiones = (region) => {
    setRegion(region);
    setFlagRegion(true);
    setIdRegion(region.value);
    setComuna("");
  };

  const handleCentroCosto = (centro) => {
    setCentroCosto(centro);
  };

  const handleTipoPago = (pago) => {
    setTipoPago(pago);
  };

  const handleSexo = (sexo) => {
    setSexo(sexo);
  };

  const handleEstado_civil = (estado_civil) => {
    setEstado_civil(estado_civil);
  };

  const handleNacionalidad = (nacionalidad) => {
    setNacionalidad(nacionalidad);
  };

  const handleBanco = (banco) => {
    setBanco(banco);
  };

  const handleTipo_cuenta_banco = (tipo_cuenta_banco) => {
    setTipo_cuenta_banco(tipo_cuenta_banco);
  };

  const handleNivel_estudios = (nivel_estudios) => {
    setNivel_estudios(nivel_estudios);
  };

  const handleEstado_estudios = (estado_estudios) => {
    setEstado_estudios(estado_estudios);
  };

  const handleTipo_contrato = (tipo_contrato) => {
    setTipo_contrato(tipo_contrato);
  };

  const handleEmpresa = (empresa) => {
    setEmpresa(empresa);
  };

  const handleNombre_isapre = (nombre_isapre) => {
    setNombre_isapre(nombre_isapre);
  };

  const handleId_lista_afp = (id_lista_afp) => {
    setId_lista_afp(id_lista_afp);
  };

  const handleId_cargo = (id_cargo) => {
    setId_cargo(id_cargo);
  };

  useEffect(() => {
    for (let i = 0; i < get_afp.length; i++) {
      if (id_lista_afp?.value === get_afp[i].id) {
        setPorcentaje_afp(get_afp[i].porcentaje);
      }
    }
  });

  function uploadCargaMasiva() {

    const formData = new FormData();

    formData.append("archivo", archivo_carga_masiva);

    api
      .post("api/personal/ficha/cargamasiva/", formData)
      .then((response) => {
        alert("Registro exitoso");

        window.location.href = `/admin/dashboard`;
      })
      .catch((error) => {
        console.log("error response", error.response);
        alert(`ERROR: ${error.response.data.Error}`);
      });
  }
  function uploadWithFormData() {

    const formData = new FormData();

    formData.append("apellido_paterno", apellido_paterno);
    formData.append("apellido_materno", apellido_materno);
    formData.append("nombres", nombres);
    formData.append("rut", rut_formato);
    if (sexo) {
      formData.append("sexo", sexo.value);
    }
    if (estado_civil) {
      formData.append("estado_civil", estado_civil.value);
    }
    formData.append("direccion_calle", direccion_calle);
    formData.append("direccion_numero", direccion_numero);
    formData.append("direccion_block", direccion_block);
    formData.append("direccion_departamento", direccion_departamento);
    formData.append("ciudad", ciudad);
    if (nacionalidad) {
      formData.append("nacionalidad", nacionalidad.value);
    }
    if (nacimiento) {
      formData.append("nacimiento", nacimiento);
    }
    formData.append("email", email);
    if (tipoPago) {
      formData.append("tipo_pago", tipoPago.value);
    }
    if (banco) {
      formData.append("banco", banco.value);
    }
    formData.append("cuenta_banco", cuenta_banco);
    if (tipo_cuenta_banco) {
      formData.append("tipo_cuenta_banco", tipo_cuenta_banco.value);
    }
    if (nivel_estudios) {
      formData.append("nivel_estudios", nivel_estudios.value);
    }
    if (estado_estudios) {
      formData.append("estado_estudios", estado_estudios.value);
    }
    formData.append("titulo", titulo);
    if (fecha_inicio_contrato) {
      formData.append("fecha_inicio_contrato", fecha_inicio_contrato);
    }
    if (fecha_fin_contrato) {
      formData.append("fecha_fin_contrato", fecha_fin_contrato);
    }
    if (tipo_contrato) {
      formData.append("tipo_contrato", tipo_contrato.value);
    }
    if (empresa) {
      formData.append("empresa", empresa.value);
    }
    formData.append("asignacion_telefono", asignacion_telefono);
    formData.append("asignacion_uniforme", asignacion_uniforme);
    formData.append("asignacion_equipo", asignacion_equipo);
    if (nombre_isapre) {
      formData.append("nombre_isapre", nombre_isapre.value);
    }
    formData.append("funciones", funciones);
    formData.append("telefono_1", telefono1);
    formData.append("telefono_2", telefono2);
    formData.append("telefono_3", telefono3);
    if (centroCosto) {
      formData.append("id_centro_costo", centroCosto.value);
    }
    if (region) {
      formData.append("id_region", region.value);
    }
    if (comuna) {
      formData.append("id_comuna", comuna.value);
    }
    if (id_lista_afp) {
      formData.append("id_lista_afp", id_lista_afp.value);
    }
    if (convenioIsapre) {
      formData.append("id_convenio_isapre", convenioIsapre.value);
    }
    if (id_cargo) {
      formData.append("id_cargo", id_cargo.value);
    }

    let arrayBulk = [];

    console.log("formData", formData);

    apiform
      .patch("api/personal/ficha/", formData)
      .then((response) => {
        if (sendFilesFicha.length > 0) {
          sendFilesFicha.map((ficha) => {
            const formData1 = new FormData();

            formData1.append("nombre_archivo", ficha.name);
            formData1.append("tipo_archivo", ficha.type);
            formData1.append("archivo", ficha);
            formData1.append("id_ficha_personal", response.data.id);
            apiform
              .post("api/documentospersonal/", formData1)
              .then((response) => console.log(response))
              .catch((error) =>
                console.log("documentospersonal", error.response)
              );
          });
        }

        if (asignacionLaboral > 0) {
          arrayBulk.push({
            codigo_variable: "P01",
            nombre: "ASIGNACION LABORAL",
            valor: asignacionLaboral.toString(),
            id_personal: response.data.id,
          });
        }
        if (jornada_laboral > 0) {
          arrayBulk.push({
            codigo_variable: "P057",
            nombre: "JORNADA LABORAL SEGUN CONTRATO",
            valor: jornada_laboral.toString(),
            id_personal: response.data.id,
          });
        }
        if (sueldo_base > 0) {
          arrayBulk.push({
            codigo_variable: "H001",
            nombre: "SUELDO BASE",
            valor: sueldo_base.toString(),
            id_personal: response.data.id,
          });
        }
        if (bono_responsabilidad > 0) {
          arrayBulk.push({
            codigo_variable: "H038",
            nombre: "BONO DE RESPONSABILIDAD",
            valor: bono_responsabilidad.toString(),
            id_personal: response.data.id,
          });
        }
        if (asignacion_caja > 0) {
          arrayBulk.push({
            codigo_variable: "H031",
            nombre: "ASIGNACION DE CAJA",
            valor: asignacion_caja.toString(),
            id_personal: response.data.id,
          });
        }
        if (bono_responsabilidad_inventario > 0) {
          arrayBulk.push({
            codigo_variable: "H013",
            nombre: "BONO RESPONSABILIDAD INVENTARIO",
            valor: bono_responsabilidad_inventario.toString(),
            id_personal: response.data.id,
          });
        }
        if (viaticos > 0) {
          arrayBulk.push({
            codigo_variable: "H027",
            nombre: "VIATICO",
            valor: viaticos.toString(),
            id_personal: response.data.id,
          });
        }
        if (seguro_complementario > 0) {
          arrayBulk.push({
            codigo_variable: "P04",
            nombre: "SEGURO COMPLEMENTARIO",
            valor: seguro_complementario.toString(),
            id_personal: response.data.id,
          });
        }
        if (seguro_cesantia > 0) {
          arrayBulk.push({
            codigo_variable: "D012",
            nombre: "SEGURO DE CESANTIA",
            valor: seguro_cesantia.toString(),
            id_personal: response.data.id,
          });
        }
        if (total_imponible > 0) {
          arrayBulk.push({
            codigo_variable: "P05",
            nombre: "TOTAL IMPONIBLE",
            valor: total_imponible.toString(),
            id_personal: response.data.id,
          });
        }
        if (total_haberes > 0) {
          arrayBulk.push({
            codigo_variable: "H034",
            nombre: "TOTAL HABERES",
            valor: total_haberes.toString(),
            id_personal: response.data.id,
          });
        }
        if (total_liquido > 0) {
          arrayBulk.push({
            codigo_variable: "H035",
            nombre: "LIQUIDO A PAGO",
            valor: total_liquido.toString(),
            id_personal: response.data.id,
          });
        }
        if (bonoIncentivo > 0) {
          arrayBulk.push({
            codigo_variable: "H008",
            nombre: "BONO DE ANTIGUEDAD",
            valor: bonoIncentivo.toString(),
            id_personal: response.data.id,
          });
        }
        if (bonoCompensatorio > 0) {
          arrayBulk.push({
            codigo_variable: "H005",
            nombre: "COMISIONES",
            valor: bonoCompensatorio.toString(),
            id_personal: response.data.id,
          });
        }
        if (bonoColacion > 0) {
          arrayBulk.push({
            codigo_variable: "P008",
            nombre: "COLACION PACTADA",
            valor: bonoColacion.toString(),
            id_personal: response.data.id,
          });
        }
        if (bonoMovilizacion > 0) {
          arrayBulk.push({
            codigo_variable: "P009",
            nombre: "MOVILIZACION PACTADA",
            valor: bonoMovilizacion.toString(),
            id_personal: response.data.id,
          });
        }
        if (desgasteHerramientas > 0) {
          arrayBulk.push({
            codigo_variable: "H032",
            nombre: "DESGASTE DE HERRAMIENTAS",
            valor: desgasteHerramientas.toString(),
            id_personal: response.data.id,
          });
        }
        if (cuotaSindical > 0) {
          arrayBulk.push({
            codigo_variable: "D033",
            nombre: "OTROS DCTOS NO PREV. 5",
            valor: cuotaSindical.toString(),
            id_personal: response.data.id,
          });
        }

        api
          .post("api/solicitudesrealizadas/", {
            estado: "Esperando Contrato",
            etapa: "Recién Iniciada",
            nombre_solicitante: `${localStorage.getItem(
              "first_name"
            )} ${localStorage.getItem("last_name")}`,
            prioridad: "Baja",
            id_cargo_solicitante: localStorage.getItem("id_cargo"),
            // id_cargo_receptor: 1,
            id_cargo_receptor: idCargo,
            id_tipo_solicitud: tipoSolicitud,
            id_cuenta_solicitante: localStorage.getItem("user_id"),
            id_registro_modificar: response.data.id,
          })
          .then((resp) => {
            api
              .put("api/personal/ficha/variables/bulk/", arrayBulk)
              .then((response) => {
                console.log(response);

                alert("Registro exitoso");
                window.location.href = `/admin/dashboard`;
              })
              .catch((error) => console.log("bulk", error.response));
            console.log(resp);
          })
          .catch((err) => {
            console.log("error solicitudes", err.response);
          });
      })
      .catch((error) => {
        console.log("error ficha", error.response);
        {};
        {};
      });
  }

  useEffect(() => {
    for (let i = 0; i < get_cargo.length; i++) {
      if (id_cargo?.value === get_cargo[i].id) {
        setArea(get_cargo[i].gerencia);
        setId_jefatura(get_cargo[i].jefatura);
      }
      if (id_jefatura === get_cargo[i].id) {
        setJefatura(get_cargo[i].nombre);
      }
      if (id_jefatura === null) {
        setJefatura("");
      }
    }
  });

  useEffect(() => {
    var sRut1 = rut;
    sRut1 = sRut1.replace("-", ""); // se elimina el guion
    sRut1 = sRut1.replace(".", ""); // se elimina el primer punto
    sRut1 = sRut1.replace(".", ""); // se elimina el segundo punto
    sRut1 = sRut1.replace(/k$/, "K");
    //contador de para saber cuando insertar el . o la -
    var nPos = 0;
    //Guarda el rut invertido con los puntos y el gui&oacute;n agregado
    var sInvertido = "";
    //Guarda el resultado final del rut como debe ser
    var sRut = "";
    for (var i = sRut1.length - 1; i >= 0; i--) {
      sInvertido += sRut1.charAt(i);
      if (i === sRut1.length - 1) sInvertido += "-";
      else if (nPos === 3) {
        sInvertido += ".";
        nPos = 0;
      }
      nPos++;
    }
    for (var j = sInvertido.length - 1; j >= 0; j--) {
      if (sInvertido.charAt(sInvertido.length - 1) !== ".")
        sRut += sInvertido.charAt(j);
      else if (j !== sInvertido.length - 1) sRut += sInvertido.charAt(j);
    }
    //Pasamos al campo el valor formateado

    if (sRut.length === 12) {
      sRut = "0" + sRut;
      setRut_formato(sRut);
    }
    if (sRut.length === 11) {
      sRut = "00" + sRut;
      setRut_formato(sRut);
    }
  });

  return (
    <div>
      <GridContainer>
        <ViewTitle
          title="Ficha personal"
          message="En esta sección podrás ingresar todos los datos de un nuevo trabajador, así como adjuntar los archivos necesarios para el proceso de contratación, como certificado de AFP, Isapre, entre otros."
        />
        <GridItem xs={12} sm={6} md={12}>
          <Card>
            <CardTitle
              title="Carga masiva ficha personal"
              class={classes.cardTitle}
            />
            <CardBody>
              <GridContainer>
                {/*Excel carga masiva*/}
                <GridItem xs={12} sm={4} md={4} className="mb-3">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Excel carga masiva</h5>
                      <p className="card-text">
                        Archivo: {archivo_carga_masiva?.name}
                      </p>
                      <span className="btn btn-cmh-color btn-file">
                        Seleccionar
                        <input
                          type="file"
                          name="archivo_carga_masiva"
                          accept=".xls,.xlsx"
                          onChange={(e) =>
                            setArchivo_carga_masiva(e.target.files[0])
                          }
                        />
                      </span>

                      {archivo_carga_masiva ? (
                        <button
                          onClick={uploadCargaMasiva}
                          className="btn btn-primary"
                        >
                          Guardar
                        </button>
                      ) : (
                        <button
                          onClick={uploadCargaMasiva}
                          className="btn btn-primary"
                          disabled
                        >
                          Guardar
                        </button>
                      )}
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <form>
          <GridItem xs={12} sm={6} md={12}>
            <Card>
              <CardTitle title="Datos personales" class={classes.cardTitle} />
              <CardBody>
                <div className="form-row">
                  <DetailTextCheck
                    label="Apellido paterno:"
                    placeholder="Apellido paterno"
                    value={apellido_paterno}
                    maxlength="45"
                    onchange={(e) => setApellido_paterno(e.target.value)}
                  />
                  <DetailTextCheck
                    label="Apellido materno:"
                    placeholder="Apellido materno"
                    value={apellido_materno}
                    maxlength="45"
                    onchange={(e) => setApellido_materno(e.target.value)}
                  />
                  <DetailTextCheck
                    label="Nombres:"
                    placeholder="Nombres"
                    value={nombres}
                    maxlength="180"
                    onchange={(e) => setNombres(e.target.value)}
                  />
                  <DetailTextCheck
                    label="RUT:"
                    placeholder="RUT"
                    value={rut}
                    maxlength="15"
                    onchange={(e) => setRut(e.target.value)}
                  />
                  <DetailSelect
                    label="Sexo:"
                    value={sexo}
                    onchange={handleSexo}
                    list={list_sexo}
                  />
                  <DetailSelectCheck
                    label="Estado civil:"
                    value={estado_civil}
                    onchange={handleEstado_civil}
                    list={list_estado_civil}
                  />
                  <DetailTextCheck
                    label="Dirección:"
                    placeholder="Dirección"
                    value={direccion_calle}
                    maxlength="500"
                    onchange={(e) => setDireccion_calle(e.target.value)}
                  />
                  <DetailTextCheck
                    label="Número:"
                    placeholder="Número"
                    value={direccion_numero}
                    maxlength="45"
                    onchange={(e) => setDireccion_numero(e.target.value)}
                  />
                  <DetailText
                    label="Block:"
                    placeholder="Block"
                    value={direccion_block}
                    maxlength="20"
                    onchange={(e) => setDireccion_block(e.target.value)}
                  />
                  <DetailText
                    label="Departamento/casa:"
                    placeholder="Departamento/Casa"
                    value={direccion_departamento}
                    maxlength="20"
                    onchange={(e) => setDireccion_departamento(e.target.value)}
                  />
                  <DetailTextCheck
                    label="Ciudad:"
                    placeholder="Ciudad"
                    value={ciudad}
                    maxlength="45"
                    onchange={(e) => setCiudad(e.target.value)}
                  />
                  <DetailSelectCheck
                    label="Región:"
                    value={region}
                    onchange={handleRegiones}
                    list={listRegiones}
                  />
                  <DetailSelectCheck
                    label="Comuna:"
                    value={comuna}
                    onchange={handleComuna}
                    list={listComunas}
                  />
                  <DetailSelectCheck
                    label="Nacionalidad:"
                    value={nacionalidad}
                    onchange={handleNacionalidad}
                    list={list_nacionalidad}
                  />
                  <DetailTextCheck
                    label="Fecha de nacimiento (AAAA-MM-DD):"
                    placeholder="AAAA-MM-DD"
                    value={nacimiento}
                    onchange={(e) => setNacimiento(e.target.value)}
                  />
                  <DetailText
                    label="Teléfono 1:"
                    placeholder="Teléfono 1"
                    value={telefono1}
                    maxlength="15"
                    onchange={(e) => setTelefono1(e.target.value)}
                  />
                  <DetailText
                    label="Teléfono 2:"
                    placeholder="Teléfono 2"
                    value={telefono2}
                    maxlength="15"
                    onchange={(e) => setTelefono2(e.target.value)}
                  />
                  <DetailText
                    label="Teléfono 3:"
                    placeholder="Teléfono 3"
                    value={telefono3}
                    maxlength="15"
                    onchange={(e) => setTelefono3(e.target.value)}
                  />
                  <DetailText
                    label="Email:"
                    placeholder="Email"
                    value={email}
                    maxlength="45"
                    onchange={(e) => setEmail(e.target.value)}
                  />
                  <DetailSelect
                    label="Tipo pago:"
                    value={tipoPago}
                    onchange={handleTipoPago}
                    list={listTipoPago}
                  />
                  <DetailSelect
                    label="Banco:"
                    value={banco}
                    onchange={handleBanco}
                    list={list_banco}
                  />
                  <DetailText
                    label="Número de cuenta:"
                    placeholder="Número de cuenta"
                    value={cuenta_banco}
                    maxlength="45"
                    onchange={(e) => setCuenta_banco(e.target.value)}
                  />
                  <DetailSelect
                    label="Tipo de cuenta:"
                    value={tipo_cuenta_banco}
                    onchange={handleTipo_cuenta_banco}
                    list={list_cuenta_banco}
                  />
                  <DetailSelect
                    label="Estudio:"
                    value={nivel_estudios}
                    onchange={handleNivel_estudios}
                    list={list_nivel_estudios}
                  />
                  <DetailSelect
                    label="Estado de estudios:"
                    value={estado_estudios}
                    onchange={handleEstado_estudios}
                    list={list_estado_estudios}
                  />
                  <DetailSelectCheck
                    label="AFP:"
                    value={id_lista_afp}
                    onchange={handleId_lista_afp}
                    list={list_afp}
                  />
                  <DetailSelect
                    label="Convenio isapre:"
                    value={convenioIsapre}
                    onchange={handleConvenio}
                    list={listConveniosIsapre}
                  />
                  <DetailSelectCheck
                    label="Nombre isapre:"
                    value={nombre_isapre}
                    onchange={handleNombre_isapre}
                    list={list_isapres}
                  />
                  <DetailNumber
                    label="Valor pactado isapre:"
                    value={valor_pactado_isapre}
                    onchange={(e) => {
                      if (/^[0-9.\b]+$/.test(e.target.value)) {
                        setValor_pactado_isapre(e.target.value);
                      }
                    }}
                  />
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardTitle
                title="Condiciones de ingreso"
                class={classes.cardTitle}
              />
              <CardBody>
                <div className="form-row">
                  <DetailTextCheck
                    label="Fecha de inicio contrato (AAAA-MM-DD):"
                    placeholder="AAAA-MM-DD"
                    value={fecha_inicio_contrato}
                    onchange={(e) => setFecha_inicio_contrato(e.target.value)}
                  />
                  <DetailTextCheck
                    label="Fecha de fin contrato (AAAA-MM-DD):"
                    placeholder="AAAA-MM-DD"
                    value={fecha_fin_contrato}
                    onchange={(e) => setFecha_fin_contrato(e.target.value)}
                  />
                  <DetailSelectCheck
                    label="Tipo de contrato:"
                    value={tipo_contrato}
                    onchange={handleTipo_contrato}
                    list={list_tipo_contrato}
                  />
                  <DetailSelectCheck
                    label="Empresa:"
                    value={empresa}
                    onchange={handleEmpresa}
                    list={list_empresa}
                  />
                  <DetailSelectCheck
                    label="Cargo:"
                    value={id_cargo}
                    onchange={handleId_cargo}
                    list={list_cargo}
                  />
                  <DetailTextDisabled label="Gerencia:" value={area} />
                  <DetailTextDisabled label="Jefatura:" value={jefatura} />
                  <DetailText
                    label="Título"
                    placeholder="Título"
                    value={titulo}
                    maxlength="90"
                    onchange={(e) => setTitulo(e.target.value)}
                  />
                  <DetailBoolean
                    label="Asignación teléfono:"
                    value={asignacion_telefono}
                    onchange={() =>
                      setAsignacion_telefono(!asignacion_telefono)
                    }
                  />
                  <DetailBoolean
                    label="Asignación uniforme:"
                    value={asignacion_uniforme}
                    onchange={() =>
                      setAsignacion_uniforme(!asignacion_uniforme)
                    }
                  />
                  <DetailBoolean
                    label="Asignación equipo:"
                    value={asignacion_equipo}
                    onchange={() => setAsignacion_equipo(!asignacion_equipo)}
                  />
                  <DetailTextArea
                    label="Funciones:"
                    placeholder="Funciones"
                    value={funciones}
                    maxlength="500"
                    onchange={(e) => setFunciones(e.target.value)}
                  />
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardTitle title="Remuneraciones" class={classes.cardTitle} />
              <CardBody>
                <div className="form-row">
                  <DetailSelect
                    label="Centro costo:"
                    value={centroCosto}
                    onchange={handleCentroCosto}
                    list={listCentroCosto}
                  />
                  <DetailMoney
                    label="Asignación laboral:"
                    value={asignacionLaboral}
                    money={setAsignacionLaboral}
                    words={setTexto_sueldo_base}
                  />
                  <DetailMoney
                    label="Jornada laboral:"
                    value={jornada_laboral}
                    money={setJornada_laboral}
                    words={setTexto_sueldo_base}
                  />
                  <DetailMoney
                    label="Sueldo base:"
                    value={sueldo_base}
                    money={setSueldo_base}
                    words={setTexto_sueldo_base}
                  />z
                  <DetailMoney
                    label="Bono de responsabilidad:"
                    value={bono_responsabilidad}
                    money={setBono_responsabilidad}
                    words={setTexto_responsabilidad}
                  />
                  <DetailMoney
                    label="Asignación caja:"
                    value={asignacion_caja}
                    money={setAsignacion_caja}
                    words={setTexto_caja}
                  />
                  <DetailMoney
                    label="Bono responsabilidad inventario:"
                    value={bono_responsabilidad_inventario}
                    money={setBono_responsabilidad_inventario}
                    words={setTexto_responsabilidad_inventario}
                  />
                  <DetailMoney
                    label="Viáticos:"
                    value={viaticos}
                    money={setViaticos}
                    words={setTexto_viaticos}
                  />
                  <DetailMoney
                    label="Seguro complementario:"
                    value={seguro_complementario}
                    money={setSeguro_complementario}
                    words={setTexto_viaticos}
                  />
                  <DetailMoney
                    label="Seguro cesantía:"
                    value={seguro_cesantia}
                    money={setSeguro_cesantia}
                    words={setTexto_viaticos}
                  />
                  <DetailMoneyDisabled
                    label="Total imponible:"
                    value={total_imponible}
                    words={setTexto_imponible}
                  />
                  <DetailMoneyDisabled
                    label="Total haberes:"
                    value={total_haberes}
                    words={setTexto_haberes}
                  />
                  <DetailMoneyDisabled
                    label="Total líquido:"
                    value={total_liquido}
                    words={setTexto_liquido}
                  />
                  <DetailMoney
                    label="Bono incentivo / Antigüedad:"
                    value={bonoIncentivo}
                    money={setBonoIncentivo}
                    words={setTexto_viaticos}
                  />
                  <DetailMoney
                    label="Bono compensatorio sala cuna:"
                    value={bonoCompensatorio}
                    money={setBonoCompensatorio}
                    words={setTexto_viaticos}
                  />
                  <DetailMoney
                    label="Bono colación:"
                    value={bonoColacion}
                    money={setBonoColacion}
                    words={setTexto_viaticos}
                  />
                  <DetailMoney
                    label="Bono movilización:"
                    value={bonoMovilizacion}
                    money={setBonoMovilizacion}
                    words={setTexto_viaticos}
                  />
                  <DetailMoney
                    label="Desgaste de herramientas:"
                    value={desgasteHerramientas}
                    money={setDesgasteHerramientas}
                    words={setTexto_viaticos}
                  />
                  <DetailMoney
                    label="Cuota sindical:"
                    value={cuotaSindical}
                    money={setCuotaSindical}
                    words={setTexto_viaticos}
                  />
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardTitle title="Archivos" class={classes.cardTitle} />
              <CardBody>
                <GridContainer>
                  <DetailFileMultiple
                    label="Archivos ficha personal:"
                    value={filesFicha}
                    onchange={(e) => setFilesFicha(e.target.files)}
                  />
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridContainer justify="flex-end">
            <GridItem xs={12} sm={12} md={2}>
              {valid ? (
                <Button
                  onClick={uploadWithFormData}
                  style={{ backgroundColor: "#1F8AFF", textTransform: "none" }}
                >
                  Guardar
                </Button>
              ) : (
                <Button
                  onClick={uploadWithFormData}
                  style={{ backgroundColor: "#1F8AFF", textTransform: "none" }}
                  disabled
                >
                  Guardar
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </form>
      </GridContainer>
    </div>
  );
};

export default Create;
