import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

const DateFilterStart = props => {
  const { startDate, handleDateStartChange } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline1"
          label="Fecha de inicio"
          value={startDate}
          onChange={handleDateStartChange}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker1"
          label="Hora de inicio"
          value={startDate}
          onChange={handleDateStartChange}
          KeyboardButtonProps={{
            "aria-label": "change time"
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DateFilterStart;

DateFilterStart.propTypes = {
  startDate: PropTypes.string,
  handleDateStartChange: PropTypes.func
};
