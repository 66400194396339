import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { manage } from "./ManageRequest/ManageRequest";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableRequest = props => {
  const classes = useStyles();
  const { manageRequest } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Fecha solicitud</TableCell>
              <TableCell align="center">Área solicitante</TableCell>
              <TableCell align="center">Nombre solicitante</TableCell>
              <TableCell align="center">Tipo solicitud</TableCell>
              <TableCell align="center">Prioridad</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Días abierta</TableCell>
              <TableCell align="center">En proceso de gestión</TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {manageRequest
              .filter(manage => manage.estado != "Listo")
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.fechaSolicitud}</TableCell>
                  <TableCell align="center">{row.area}</TableCell>
                  <TableCell align="center">{row.solicitante}</TableCell>
                  <TableCell align="center">{row.tipoSolicitud}</TableCell>
                  <TableCell align="center">{row.prioridad}</TableCell>
                  <TableCell align="center">{row.estado}</TableCell>
                  <TableCell align="center">{row.diasAbierta}</TableCell>
                  <TableCell align="center">
                    {row.proceso === "En proceso" ? (
                      <span className="text-primary">En proceso</span>
                    ) : (
                      <span className="text-warning">No gestionada</span>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {manage(
                      row.row.id_tipo_solicitud_id,
                      row.row.id_registro_modificar,
                      row.id,
                      row.id_tipo_solicitud_id
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={manageRequest.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableRequest;

TableRequest.propTypes = {
  manageRequest: PropTypes.array
};
