import React from "react";
import styled from "styled-components";
import { Typography, Grid, Divider } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";

export default function TitleDivider({ title, variant }) {

  const Root = styled('div')(() => ({
    width: '100%',
    '& > :not(style) + :not(style)': {
      marginTop: "10px",
    },
  }));

  return (
    <Root>
      <Grid item container xs={12} justify="center">
        <Grid item container xs={12} justify="center">
          <Typography variant={variant}  gutterBottom style={{ textTransform: "none", color: indigo[700] }}>
            {title}
          </Typography>
        </Grid>
        <Divider style={{ border: "2px solid #303F9F", margin: "10px 0", width: "95%" }}/>
      </Grid>
    </Root>
  );
}

