import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import MedicalTableModify from "views/PatientFile/InsumosYMedicamentos/ModifyRequest/MedicalTableModify";
import ResourceTableModify from "views/PatientFile/InsumosYMedicamentos/ModifyRequest/ResourceTableModify";
import EquipTableComponent from "views/PatientFile/InsumosYMedicamentos/EquipTableComponent";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const EditPageTwo = props => {
  const {
    listMedical,
    listResource,
    medicalResourceData,
    dataResourceArray,
    setDataResourceArray,
    dataMedicalArray,
    setDataMedicalArray,
    checkDataMedicalArray,
    setCheckDataMedicalArray,
    addDataMedicalArray,
    setAddDataMedicalArray,
    deleteDataMedicalArray,
    setDeleteDataMedicalArray,
    editDataMedicalArray,
    setEditDataMedicalArray,
    addDataResourceArray,
    setAddDataResourceArray,
    deleteDataResourceArray,
    setDeleteDataResourceArray,
    editDataResourceArray,
    setEditDataResourceArray
  } = props;
  const [pageRefresh, setPageRefresh] = useState(false);
  const [dataEquipArray, setDataEquipArray] = useState([]);
  const [listEquip, setListEquip] = useState([]);

  return (
    <Grid item container xs={12} justify="center">
      <Grid item container xs={12} justify="center">

        <MedicalTableModify
          dataMedicalArray={dataMedicalArray}
          setDataMedicalArray={setDataMedicalArray}
          medicalResourceData={medicalResourceData}
          //listMedical={listMedical}
          listMedical={listMedical.filter(list =>
            dataMedicalArray.every(data => data.id !== list.value)
          )}
          setPageRefresh={setPageRefresh}
          checkDataMedicalArray={checkDataMedicalArray}
          setCheckDataMedicalArray={setCheckDataMedicalArray}
          addDataMedicalArray={addDataMedicalArray}
          setAddDataMedicalArray={setAddDataMedicalArray}
          deleteDataMedicalArray={deleteDataMedicalArray}
          setDeleteDataMedicalArray={setDeleteDataMedicalArray}
          editDataMedicalArray={editDataMedicalArray}
          setEditDataMedicalArray={setEditDataMedicalArray}
        />
      </Grid>
      <Grid item container xs={12} justify="center">
      
        <ResourceTableModify
          dataResourceArray={dataResourceArray}
          setDataResourceArray={setDataResourceArray}
          medicalResourceData={medicalResourceData}
          //listResource={listResource}
          listResource={listResource.filter(list =>
            dataResourceArray.every(data => data.id !== list.value)
          )}
          setPageRefresh={setPageRefresh}
          addDataResourceArray={addDataResourceArray}
          setAddDataResourceArray={setAddDataResourceArray}
          deleteDataResourceArray={deleteDataResourceArray}
          setDeleteDataResourceArray={setDeleteDataResourceArray}
          editDataResourceArray={editDataResourceArray}
          setEditDataResourceArray={setEditDataResourceArray}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="center"
        style={{ marginTop: "2em" }}
      >
        <EquipTableComponent
          dataEquipArray={dataEquipArray}
          setDataEquipArray={setDataEquipArray}
          medicalResourceData={medicalResourceData}
          listEquip={listEquip}
          setPageRefresh={setPageRefresh}
        />
      </Grid>
    </Grid>
  );
};

export default EditPageTwo;

EditPageTwo.propTypes = {
  listMedical: PropTypes.array,
  listResource: PropTypes.array,
  medicalResourceData: PropTypes.object,
  dataResourceArray: PropTypes.array,
  setDataResourceArray: PropTypes.func,
  dataMedicalArray: PropTypes.array,
  setDataMedicalArray: PropTypes.func
};
