import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import api from "utils/API";
import ModalEdit from "./ModalEdit";

const Orders = props => {
  const [order, setOrder] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);

  useEffect(() => {
    api.get(`api/solicitudcotizacioncompras/${props.id}`).then(result => {
      setOrder(result.data);
    });
  }, []);

  return (
    <div className="col-xl-8 mb-3">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col text-center">Datos de la compra</div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm text-left ml-5">
              <span className="font-weight-bold">Id: </span>
              {props.id}
              <br />
              <span className="font-weight-bold">Tipo: </span>
              {order.tipo_solicitud_cotizar === "NormalActivos"
                ? "Normal con activos"
                : order.tipo_solicitud_cotizar}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Cantidad SKU: </span>
              {order.cantidad_productos}
              <br />
              <span className="font-weight-bold">Observación: </span>
              {order.observacion}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Fecha de solicitud: </span>
              {order.fecha_solicitud}
              <br />
              <span className="font-weight-bold">Estado: </span>
              {order.estado === "RevisarCompra"
                ? "Revisar compra"
                : order.estado === "JefeSeleccionaCotizacion"
                ? "Esperando aprobación del jefe"
                : order.estado === "AdjuntarOrdenCompra"
                ? "Adjuntar orden de compra"
                : order.estado === "RevisarOC"
                ? "Esperar revisión orden de compra"
                : order.estado === "IngresarDespacho"
                ? "Ingresar despacho"
                : order.estado}
            </div>
          </div>
          <div className="row">
            <div className="col-sm text-center">
              {order.estado === "JefeSeleccionaCotizacion" ||
              order.estado === "AdjuntarOrdenCompra" ||
              order.estado === "RevisarOC" ? (
                <Button
                  disabled
                  className="mt-4"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: "none" }}
                >
                  Editar
                </Button>
              ) : (
                <Button
                  className="mt-4"
                  variant="contained"
                  color="primary"
                  onClick={() => setModalEdit(true)}
                  style={{ textTransform: "none" }}
                >
                  Editar
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {modalEdit && (
        <ModalEdit
          idRequest={props.id}
          state={order.estado}
          quantity={order.cantidad_productos}
          type={order.tipo_solicitud_cotizar}
          observation={order.observacion}
          date={order.fecha_solicitud}
          open={modalEdit}
          closeModal={() => setModalEdit(false)}
        />
      )}
    </div>
  );
};

export default Orders;

Orders.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.bool,
  id_therapy: PropTypes.number,
  id_paquetizacion: PropTypes.number,
  id: PropTypes.number
};
