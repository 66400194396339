import React, { useState, useEffect } from "react";
import {
    IconButton,
    Button,
    Dialog, 
    DialogTitle, 
    DialogContent, 
    Slide, 
    Grid,
} from "@material-ui/core";
import {
    Close, Cancel, Check
} from '@material-ui/icons/';
import {classStyle} from "utils/styleFunction";
import { 
    TypographyMui,
} from "utils/componentsStyle"
import { green, indigo, orange, red } from "@material-ui/core/colors";

import moment from 'moment-timezone';
import 'moment/locale/es' ;
moment.locale("es");


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalMessageGenPrescription({
    open,
    closeModal,
    handleGenerate,
}) {
    const classesStyle = classStyle();

    return (
        <div>
            <Dialog 
                fullScreen={false} 
                open={open} 
                TransitionComponent={Transition} 
                keepMounted 
                maxWidth={false}
            >
                <DialogTitle className={classesStyle.dialogHeader}>
                    <Grid 
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        item 
                        xs={12} 
                    >
                        <TypographyMui className={classesStyle.colorWhite} variant='body2'>{"GENERAR RECETA"}</TypographyMui>
                        <IconButton type='reset' onClick={closeModal}>
                            <Close className={classesStyle.colorWhite} />
                        </IconButton>
                    </Grid> 
                </DialogTitle>
                <DialogContent className={classesStyle.dialogContent}>
                   
                    <Grid 
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item 
                        xs={12} 
                    >
                        <Grid 
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            item 
                            xs={12} 
                        >
                           
                            <TypographyMui variant='body2'>
                                {"¿Desea generar la receta del paciente?"}
                            </TypographyMui> 
                            <TypographyMui variant='caption'>
                                {"Una vez generada l receta no se podrá modificar"}
                            </TypographyMui> 

                             <Grid 
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                item 
                                xs={12}
                                style={{margin: "28px 0 0 0"}}
                            >
                                <Button 
                                    variant={"outlined"}
                                    size={"small"}
                                    style={{
                                        background: "#fff", 
                                        color: red[500],
                                        margin: "0 18px 0 0",
                                    }} 
                                    onClick={closeModal}
                                    startIcon={<Cancel />}
                                > 
                                    CANCELAR
                                </Button>
                                <Button 
                                    variant={"outlined"}
                                    style={{
                                        background: "#fff", 
                                        color: green[500],
                                    }} 
                                    onClick={handleGenerate}
                                    startIcon={<Check />}
                                > 
                                    GENERAR RECETA
                                </Button> 
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

