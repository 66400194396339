import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import { green, indigo, orange, red  } from "@material-ui/core/colors";
import { Delete } from "@material-ui/icons/";
import NavPills from "components/NavPills/NavPills.js";
import GridItem from "components/Grid/GridItem.js";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import MomentUtils from "@date-io/moment";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import MedicalTableComponent from "views/PatientFile/InsumosYMedicamentos/MedicalTableComponent";
import ResourceTableComponent from "views/PatientFile/InsumosYMedicamentos/ResourceTableComponent";
import TableComponent from "views/Widgets/Common/TableComponent";
import ModalDeleteItemTable from "./ModalDeleteItemTable";
import api from "utils/API";
import moment from "moment-timezone";
import {
  getListRequestPatient,
} from 'actions/getListAction';
import ModalDialog from "views/Widgets/Common/ModalDialog";
import "moment/locale/es";
moment.locale("es");


const ModalRequestAntibiotic = props => {
  const dispatch = useDispatch()
  const {
    openModal,
    closeModal,
    dataPatient,
  } = props;
  const [indication, setindication] = useState("");
  const [startDate, setstartDate] = useState(moment());
  const [endDate, setendDate] = useState(moment());
  const [lengthIndication, setlengthIndication] = useState(0);
  const [dataMedicalArray, setDataMedicalArray] = useState([]);
  const [dataResourceArray, setDataResourceArray] = useState([]);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [listProffesional, setlistProffesional] = useState([]);
  const [openDeleteModal, setopenDeleteModal] = useState(null);
  const [selectTab, setselectTab] = useState(0);
  const limitChartSymptoms = 4000;


  const dictCharges = useSelector(state => state.currentList.dictCharges);
  const listTypeTime = useSelector(state => state.currentList.LISTA_TIPO_TIEMPO_PERSONAL_TRATAMIENTO);

  const listChargesRoute = [
    {label: "Kinesiologo(a) Motor", value: "CLIKineMot"},
    {label: "Kinesiologo(a) Respiratorio", value: "CLIKineResp"},
    {label: "Kinesiologo(a) integral", value: "CLIKineIntegral"},
    {label: "Fonoaudiologo(a)", value: "CLIFono"},
    {label: "Enfermero(a)", value: "CLIEnfRuta"},
    {label: "Terapeuta Ocupacional", value: "CLITerOcu"},
    {label: "Tecnico Enfermería Basico", value: "CLITecEnfBas"},
    {label: "Médico", value: "CLIMed"},
]

const dictTab = {
  "0": "Indicaciones",
  "1": "Medicamentos",
  "2": "Insumos",
  "3": "Profesionales",
}

const { enqueueSnackbar, closeSnackbar } = useSnackbar();

const action = key => (
  <Button style={{background: "#af4c4c00",  color: "#fff"}} onClick={() => {closeSnackbar(key)}}>
      CERRAR
  </Button>
);

  const handleSnakeMessage = (message, variant, vertical= "top", hotizontal="center", persist=false) => {
    enqueueSnackbar(
        message,
        {
            variant,
            persist: persist,
            action,
            preventDuplicate: true,
            anchorOrigin: {
                vertical: vertical,
                horizontal: hotizontal,
            },
        }
    );
};

  useEffect(() => {
    if (pageRefresh) {
      setPageRefresh(false);
    }
  });

  const handleAddProffesional = () => {
      const tempList = [...listProffesional]
      let tempDate = moment()
      let remainder = tempDate.minute() % 30; // 13
      if (remainder < 30) {
          tempDate.subtract(remainder, 'minutes'); // 10:00
      } else {
          tempDate.add(30 - remainder, 'minutes'); // 10:30
      }
      tempList.push({charge: "", date: tempDate.format("YYYY-MM-DD HH:mm"), type: listTypeTime[0]})
      setlistProffesional(tempList)
  }

  const handleDeleteRow = () => {
      const index = openDeleteModal.index;
      let tempData = listProffesional
      tempData = tempData.filter((temprow, tempIndex) => index !== tempIndex )
      setlistProffesional(tempData);
      setopenDeleteModal(null);
  };

  const handleUpdateDate = (value, index) => {
    const tempList = [...listProffesional];
    let tempDate = moment(value)
    let remainder = tempDate.minute() % 30;
    if (remainder < 15) {
        tempDate.subtract(remainder, 'minutes'); //ex 10:00
    } else {
        tempDate.add(30 - remainder, 'minutes'); //ex 10:30
    }
    tempDate.seconds(0);
    tempList[index].date = tempDate.format("YYYY-MM-DD HH:mm:ss");
    setlistProffesional(tempList);
}

  const handleUpdateCharge = (value, index) => {
      const tempList = [...listProffesional];
      tempList[index].charge = value;
      setlistProffesional(tempList);
  }
  const handleUpdateType = (value, index) => {
      const tempList = [...listProffesional];
      tempList[index].type = value;
      setlistProffesional(tempList);
  }

  const handleIndications = (value) => {
    if (value.length <= 4000){
        setindication(value);
        setlengthIndication(value.length);
    } else {
        setindication(value.substring(0, 4000));
    }
  };

  const sendFiles = () => {

    const tempDictCharges = Object.values(dictCharges).reduce((accum, obj) => ({
        ...accum,
        [obj.tipo_perfil]: obj
    }), {})

    const tempList = listProffesional.map((row) => {
        const tempCharge = tempDictCharges[row.charge.value]
        return {id_cargo_id: tempCharge.id, fecha_hora: row.date, tipo: row.type.value}
    })

    let tempListaInsumos = [];
    dataMedicalArray.map((row) => {
      tempListaInsumos.push({
        cantidad_necesaria: row.cantidad,
        id_insumo_medicamento_id: row.id,
      });
    });
    dataResourceArray.map((row) => {
      tempListaInsumos.push({
        cantidad_necesaria: row.cantidad,
        id_insumo_medicamento_id: row.id,
      });
    });

    const body = {
      indicaciones: indication,
      fecha_agendada: moment(startDate).format("YYYY-MM-DD"),
      fecha_termino: moment(endDate).format("YYYY-MM-DD"),
      tiene_insumos: dataResourceArray.length > 0,
      tiene_medicamentos: dataMedicalArray.length > 0,
      lista_insumos: tempListaInsumos,
      lista_profesionales: tempList,
      // id_orden_tratamiendo: idOrderFile,
      // id_autorizacion_isapre: idAuthFile,
    }
    api.post(`/api/paciente/${dataPatient.id}/solicitudes/tratamiento/antibiotico/`, body).then((response) => {
      const message = `Solicitud "Tratamiento de antibiótico" generada`
      getListRequestPatient(dataPatient, dispatch)
      handleSnakeMessage(message, "success");
      closeModal();
    });

  }

  const handleOnClickTab = (tab) => {
    setselectTab(tab)
  }

  const headers = [
    {label: "Cargo"},
    {label: "Tipo"},
    {label: "Fecha/hora"},
    {eval: false, label: "Acción"},
  ];

  const data = listProffesional.map((row, index) => {
    const _selectCharge_ =  <DetailSelect
        value={row?.charge || {}}
        onchange={(value) => handleUpdateCharge(value, index)}
        list={listChargesRoute}
    />

    const _selectType_ = <DetailSelect
        value={row?.type}
        onchange={(value) => handleUpdateType(value, index)}
        list={listTypeTime}
    />
    const _date_ = <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        {row.type.value == "Repetido" ?  <KeyboardTimePicker
            label={"Hora a repetir"}
            variant="inline"
            format="HH:mm"
            margin="normal"
            onChange={(value) => handleUpdateDate(value, index)}
            value={moment(row.date)}
            inputVariant={"outlined"}
            minutesStep={30}
        /> :
        <KeyboardDateTimePicker
            label={"Fecha y hora"}
            variant="inline"
            format="DD/MM/YYYY HH:mm"
            margin="normal"
            onChange={(value) => handleUpdateDate(value, index)}
            minDate={moment(startDate)}
            maxDate={moment(endDate)}
            value={moment(row.date)}
            inputVariant={"outlined"}
            minutesStep={30}
        />
      }
    </MuiPickersUtilsProvider>
    const _buttonDelete_ = <Button
        variant="outlined"
        style={{
            color: red[500],
            borderColor: red[500],
        }}
        onClick={() => setopenDeleteModal({row, index: index})}
        startIcon={<Delete style={{color: red[500]}}/>}
    >
        Eliminar
    </Button>

    return [
      {_value_: _selectCharge_ },
      {_value_: _selectType_ },
      {_value_: _date_},
      {_value_: _buttonDelete_},
    ]
  });

  const _contenProf_ = <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      item
      xs={12}
      style={{margin: "0 0 32px 0"}}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleAddProffesional()}
      >
        {`Agregar Profesional Extra`}
      </Button>
    </Grid>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      item
      xs={12}
      style={{margin: "0 0 32px 0"}}
    >
      <TableComponent
          headers={headers}
          data={data}
      />
    </Grid>
  </Grid>


  const _content_ = <div>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
      <GridItem xs={12}>
        <NavPills
          color="primary"
          alignCenter
          tabs={[
              {
              tabButton: "1. Indicaciones",
              tabContent: (
                  <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-end"
                      item
                      xs={12}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      item
                      xs={12}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={4}
                      >
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                          <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha Inicio"}
                            placeholder="Fecha Inicio"
                            onChange={(e) => setstartDate(e)}
                            value={moment(startDate)}
                            inputVariant={"outlined"}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={4}
                      >
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                          <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            label={"Fecha Fin"}
                            placeholder="Fecha Fin"
                            onChange={(e) => setendDate(e)}
                            minDate={moment(startDate)}
                            value={endDate ? moment(endDate) : null}
                            inputVariant={"outlined"}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
                      <TextField
                          id="indication_id"
                          label="Indicaciones generales"
                          multiline
                          rows={8}
                          value={indication}
                          variant="outlined"
                          onChange={(event) => handleIndications(event.target.value)}
                          style={{width: "100%"}}
                      />
                  </Grid>
                  <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
                      {`${lengthIndication}/${limitChartSymptoms}`}
                  </Typography>
                  </Grid>

              )},
              {
              tabButton: "2. Medicamentos",
              tabContent: (
                  <Grid container style={{height: "315px", overflow: "auto"}}>
                      <Grid item xs={12}>
                          <MedicalTableComponent
                              dataMedicalArray={dataMedicalArray}
                              setDataMedicalArray={setDataMedicalArray}
                              setPageRefresh={() => {}}
                              margin={"0"}
                          />
                      </Grid>
                  </Grid>
              )},
              {
              tabButton: "3. Insumos",
              tabContent: (
                  <Grid container style={{height: "315px", overflow: "auto"}}>
                      <Grid item xs={12}>
                          <ResourceTableComponent
                              dataResourceArray={dataResourceArray}
                              setDataResourceArray={setDataResourceArray}
                              setPageRefresh={() => {}}
                              margin={"0"}
                          />
                      </Grid>
                  </Grid>
              )},
              {
                  tabButton: "4. Profesionales",
                  tabContent: _contenProf_

              }
          ]}
          onClick={handleOnClickTab}
          updateTab={selectTab}
        />
      </GridItem>
    </Grid>
  </div>

  const backTab = selectTab-1;
  const nextTab = selectTab+1;

  const _actions_ = <>
    {dictTab[backTab.toString()] &&
    <Button
      variant="contained"
      color="primary"
      style={{background: red[500]}}
      onClick={() => setselectTab(backTab)}
    >
      Atras
    </Button>
    }
  {dictTab[nextTab.toString()] &&
    <Button
      variant="contained"
      color="primary"
      style={{background: green[500]}}
      onClick={() => setselectTab(nextTab)}
    >
      Siguiente
    </Button>
    }
    {dictTab[nextTab.toString()] === undefined &&
    <Button
      disabled={(!startDate || !endDate || !indication)}
      variant="contained"
      color="primary"
      style={(!startDate || !endDate || !indication) ? {} : {background: green[500]}}
      onClick={sendFiles}
    >
      Enviar y guardar
    </Button>
    }
    <Button
      variant="contained"
      color="primary"
      onClick={closeModal}
    >
      Salir
    </Button>
  </>

  return (
  <>
    <ModalDialog
        open={openModal}
        onClose={closeModal}
        title={`Ingresar datos del "Tratamiento Antibiótico"`}
        _content_={_content_}
        _actions_ = {_actions_}
        maxWidth="lg"
    />
  {openDeleteModal && (
    <ModalDeleteItemTable
      open={!!openDeleteModal}
      handleClose={() => setopenDeleteModal(null)}
      handleDelete={handleDeleteRow}
      title={`Eliminar reserva ${openDeleteModal.charge}`}
    />
  )}
  </>
  );
};

export default ModalRequestAntibiotic;

