import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const TurnoExtra = props => {
  const {
    startDate,
    handleStartDate,
    endDate,
    handleEndDate,
    valorTurno,
    setValorTurno,
    valorColacion,
    setValorColacion,
    valorMovilizacion,
    setValorMovilizacion,
    valorViatico,
    setValorViatico,
    NumberFormatCustom,
    checkColacion,
    handleCheckColacion,
    checkMovilizacion,
    handleCheckMovilizacion,
    checkViatico,
    handleCheckViatico,
    editInfo
  } = props;

  return (
    <Grid container justify="center">
      <Grid item container xs={7} justify="center">
        <Grid item xs={10}>
          {editInfo ? (
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline1"
                  label="Fecha de inicio"
                  value={startDate}
                  onChange={handleStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          ) : (
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline1"
                  label="Fecha de inicio"
                  value={startDate}
                  onChange={handleStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  readOnly
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={7} justify="center">
        <Grid item xs={10}>
          {editInfo ? (
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline2"
                  label="Fecha de término"
                  value={endDate}
                  onChange={handleEndDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  minDate={startDate}
                  minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          ) : (
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  id="date-picker-inline2"
                  label="Fecha de término"
                  value={endDate}
                  onChange={handleEndDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  readOnly
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={7} justify="center" style={{ marginTop: "3em" }}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Indique que incluye el turno extra
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <CheckIcon />
                  </TableCell>
                  <TableCell align="center">Item</TableCell>
                  <TableCell align="center">Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell align="center">Turno</TableCell>
                  <TableCell align="right">
                    {editInfo ? (
                      <TextField
                        variant="outlined"
                        label="Valor turno"
                        value={valorTurno}
                        onChange={e => setValorTurno(parseInt(e.target.value))}
                        name="numberformat"
                        id="formatted-numberformat-input2"
                        InputProps={{
                          inputComponent: NumberFormatCustom
                        }}
                        error={valorTurno > 0 ? false : true}
                        helperText={
                          valorTurno > 0 ? null : "Debe ser mayor que 0"
                        }
                      />
                    ) : (
                      <TextField
                        value={valorTurno}
                        name="numberformat"
                        id="formatted-numberformat-input21"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          disableUnderline: true,
                          readOnly: true
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                {valorColacion != undefined ? (
                  <TableRow>
                    {editInfo ? (
                      <TableCell component="th" scope="row">
                        <Checkbox
                          checked={checkColacion}
                          onChange={handleCheckColacion}
                          color="primary"
                        />
                      </TableCell>
                    ) : (
                      <TableCell component="th" scope="row">
                        <Checkbox checked={checkColacion} color="primary" />
                      </TableCell>
                    )}
                    <TableCell align="center">Colación</TableCell>
                    <TableCell align="right">
                      {checkColacion ? (
                        <TextField
                          variant="outlined"
                          label="Valor colación"
                          value={valorColacion}
                          onChange={e =>
                            setValorColacion(parseInt(e.target.value))
                          }
                          name="numberformat"
                          id="formatted-numberformat-input3"
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                        />
                      ) : (
                        <TextField
                          value={valorColacion}
                          name="numberformat"
                          id="formatted-numberformat-input4"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            disableUnderline: true,
                            readOnly: true
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ) : null}
                {valorMovilizacion != undefined ? (
                  <TableRow>
                    {editInfo ? (
                      <TableCell component="th" scope="row">
                        <Checkbox
                          checked={checkMovilizacion}
                          onChange={handleCheckMovilizacion}
                          color="primary"
                        />
                      </TableCell>
                    ) : (
                      <TableCell component="th" scope="row">
                        <Checkbox checked={checkMovilizacion} color="primary" />
                      </TableCell>
                    )}
                    <TableCell align="center">Movilización</TableCell>
                    <TableCell align="right">
                      {checkMovilizacion ? (
                        <TextField
                          variant="outlined"
                          label="Valor movilización"
                          value={valorMovilizacion}
                          onChange={e =>
                            setValorMovilizacion(parseInt(e.target.value))
                          }
                          name="numberformat"
                          id="formatted-numberformat-input5"
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                        />
                      ) : (
                        <TextField
                          value={valorMovilizacion}
                          name="numberformat"
                          id="formatted-numberformat-input6"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            disableUnderline: true,
                            readOnly: true
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ) : null}
                {valorViatico != undefined ? (
                  <TableRow>
                    {editInfo ? (
                      <TableCell component="th" scope="row">
                        <Checkbox
                          checked={checkViatico}
                          onChange={handleCheckViatico}
                          color="primary"
                        />
                      </TableCell>
                    ) : (
                      <TableCell component="th" scope="row">
                        <Checkbox checked={checkViatico} color="primary" />
                      </TableCell>
                    )}
                    <TableCell align="center">Viático especial</TableCell>
                    <TableCell align="right">
                      {checkViatico ? (
                        <TextField
                          variant="outlined"
                          label="Valor viático especial"
                          value={valorViatico}
                          onChange={e =>
                            setValorViatico(parseInt(e.target.value))
                          }
                          name="numberformat"
                          id="formatted-numberformat-input7"
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                        />
                      ) : (
                        <TextField
                          value={valorViatico}
                          name="numberformat"
                          id="formatted-numberformat-input8"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            disableUnderline: true,
                            readOnly: true
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TurnoExtra;

TurnoExtra.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.object,
  startDate: PropTypes.object,
  handleStartDate: PropTypes.func,
  endDate: PropTypes.object,
  handleEndDate: PropTypes.func,
  valorTurno: PropTypes.number,
  setValorTurno: PropTypes.func,
  valorColacion: PropTypes.number,
  setValorColacion: PropTypes.func,
  valorMovilizacion: PropTypes.number,
  setValorMovilizacion: PropTypes.func,
  valorViatico: PropTypes.number,
  setValorViatico: PropTypes.func,
  NumberFormatCustom: PropTypes.func,
  checkColacion: PropTypes.bool,
  handleCheckColacion: PropTypes.func,
  checkMovilizacion: PropTypes.bool,
  handleCheckMovilizacion: PropTypes.func,
  checkViatico: PropTypes.bool,
  handleCheckViatico: PropTypes.func,
  editInfo: PropTypes.bool
};
