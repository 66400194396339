import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const DialogSendRequest = props => {
  const { openDialog, closeDialog, closeMainModal, name } = props;

  return (
    <Dialog
      onClose={closeDialog}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      disableBackdropClick
    >
      <DialogTitle id="customized-dialog-title" onClose={closeDialog} />
      <DialogContent style={{ marginTop: "1em" }}>
        <Typography gutterBottom>
          ¿Estás seguro que quieres realizar el envío?
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "1em" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
        >
          Si, enviar
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          onClick={() => {
            closeMainModal();
            closeDialog();
          }}
        >
          No, atrás
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSendRequest;

DialogSendRequest.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  closeMainModal: PropTypes.func,
  name: PropTypes.string
};
