import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {  Grid, Typography } from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import { Brightness1 } from "@material-ui/icons/";
import { red, green } from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import allActions from "actions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const stateColor = {
  Completo: green[500],
  Completa: green[500],
  Completada: green[500],
  Incompleta: red[500],
};

export default function ModalDetailResourcesDriver({
  open,
  handleClose,
  handleDetailRefuse,
  withdrawOrder,
  rowsActive,
  setrowsActive,
  setmessage,
  infoRequest,
  patientDict,
}) {
  const dispatch = useDispatch();
  const [tempRow, settempRow] = useState(withdrawOrder[0]);
  const [patient, setpatient] = useState(patientDict[tempRow.id_paciente]);

  const dictSTValueLabel = useSelector(state => state.currentList.DICCIONARIO_ESTADOS_TRANSPORTES);
  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);
  

  const handleAccept = () => {
    const body = {};
    const body2 = {};

    body.estado_recepcion = "IniciarViaje";
    body2.estado = "IniciarViaje";
    const requestArray = [];
    requestArray.push(api.patch(`/api/agendatransportes/${tempRow.id}`, body));
    if (tempRow.id_envios_pedidos) {
      requestArray.push(
        api.patch(`/api/enviospedidos/${tempRow.id_envios_pedidos.id}`, body2)
      );
    }
    setmessage("Actualizando estado");
    Promise.all(requestArray).then((request) => {
      const tempRows = rowsActive.map((row) => {
        const auxRow = row;
        if (withdrawOrder[0].id === row.id) {
          auxRow.estado_recepcion = body.estado_recepcion;
        }
        return auxRow;
      });
      setrowsActive(tempRows);
      setmessage();
      handleClose();
    });
  };


  const _content_ = <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    item
    xs={12}
  >
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      item
      xs={12}
      style={{ margin: "10px 0 10x 0" }}
    >
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        item
        md={3}
        sm={6}
        xs={12}
        style={{ padding: "5px" }}
      >
        <Typography variant="caption" display="block" gutterBottom>
        {tempRow.id_parada_traslado_ingreso && ( tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudRetiroEquipo" || tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudEntregaRetiroEquipo" )  ? `Empresa: ${tempRow.id_parada_traslado_ingreso.nombre_empresa}`
            : tempRow.id_parada_traslado_ingreso && tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso === "SolicitudRetiroCompra" ?
              `Paciente: ${tempRow.id_parada_traslado_ingreso.nombre_empresa}` :`Paciente: ${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {tempRow.id_parada_traslado_ingreso
            ? tempRow.id_parada_traslado_ingreso.tipo_parada === "Inicio"
              ? `Dirección: ${tempRow.id_parada_traslado_ingreso.direccion_calle} ${tempRow.id_parada_traslado_ingreso.direccion_numero} ${tempRow.id_parada_traslado_ingreso.direccion_oficina}`
              : tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso
                  .tipo_producto === "Insumo" ||
                tempRow.id_parada_traslado_ingreso.id_solicitud_ingreso
                  .tipo_producto === "Equipo"
              ? `Dirección: Bodega Ingreso`
              : `Dirección: Bodega Farmacia`
            : `Dirección: ${patient.direccion_calle} ${patient.direccion_numero} casa/dpto ${patient.direccion_casa_depto}`}
        </Typography>
      </Grid>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        item
        md={3}
        sm={6}
        xs={12}
        style={{ padding: "5px" }}
      >
        <Typography variant="caption" display="block" gutterBottom>
          {tempRow.id_parada_traslado_ingreso
            ? `Comuna: ${tempRow.id_parada_traslado_ingreso.direccion_comuna}`
            : `Comuna: ${patient.direccion_comuna}`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {tempRow.id_parada_traslado_ingreso
            ? `Región: ${tempRow.id_parada_traslado_ingreso.direccion_region}`
            : `Región: ${patient.direccion_region}`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {tempRow.id_parada_traslado_ingreso
            ? `Zona: -`
            : `Zona: ${tempRow.zona}`}
        </Typography>
      </Grid>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        item
        md={3}
        sm={6}
        xs={12}
        style={{ padding: "5px" }}
      >
        <Typography variant="caption" display="block" gutterBottom>
          {`Fecha solicitud:`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`${
            tempRow.fecha_solicitud
              ? moment(tempRow.fecha_solicitud).format("YYYY-MM-DD")
              : moment(tempRow.id_parada_traslado.fecha_hora).format(
                  "YYYY-MM-DD HH:mm"
                )
          }`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {tempRow.hora_estimada ?
          `Fecha a traslado:`:
          `Fecha a despachar:`
          }
          {}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`${ tempRow.id_envios_pedidos ?
            moment(tempRow.id_envios_pedidos.fecha_tentativa_llegada).format(
              "YYYY-MM-DD HH:mm"
            ) : tempRow.fecha_tentativa_llegada?
              moment(tempRow.fecha_tentativa_llegada).format(
              "YYYY-MM-DD HH:mm"
              ) :  moment(tempRow.hora_estimada).format(
              "YYYY-MM-DD HH:mm"
              )

          }`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`Fecha estimada:`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`${
            tempRow.hora_estimada
              ? moment(tempRow.hora_estimada).format("DD-MM-YYYY HH:mm")
              : "-"
          }`}
        </Typography>
        {(tempRow.estado_recepcion === "Entregado" ||
          tempRow.estado_recepcion === "Retrasado") && (
          <Typography variant="caption" display="block" gutterBottom>
            {`Fecha entrega:`}
          </Typography>
        )}
        {(tempRow.estado_recepcion === "Entregado" ||
          tempRow.estado_recepcion === "Retrasado") && (
          <Typography variant="caption" display="block" gutterBottom>
            {`${moment(tempRow.hora_llegada).format("YYYY-MM-DD HH:mm")}`}
          </Typography>
        )}
      </Grid>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        item
        md={3}
        sm={6}
        xs={12}
        style={{ padding: "5px" }}
      >
        <Typography variant="caption" display="block" gutterBottom>
          {tempRow.id_envios_pedidos
            ? `Solicitud pedido: ${tempRow.id_envios_pedidos.id_solicitud_pedido}`
            : tempRow.id_parada_traslado
            ? `Solicitud paciente: ${tempRow.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}`
            : tempRow.id_parada_traslado_ingreso
            ? `Solicitud ingreso: ${tempRow?.id_parada_traslado_ingreso?.id_solicitud_ingreso.id}`
            : " "}
        </Typography>
        {tempRow.id_envios_pedidos && (
          <Typography variant="caption" display="block" gutterBottom>
            {tempRow.id_parada_traslado_ingreso
              ? `Caja: 1`
              : `Caja: ${
                  tempRow.id_envios_pedidos
                    ? tempRow.id_envios_pedidos.numero_pedido
                    : "-"}`}
          </Typography>
        )}
        <Typography variant="caption" display="block" gutterBottom>
          {`Tipo de solicitud: ${dictTypeRequest[tempRow.tipo_solicitud]?.label}`}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {`Estado pedido: ${dictSTValueLabel[tempRow.estado_recepcion]?.label || ""}`}
        </Typography>
        {tempRow.id_envios_pedidos && (
          <Typography variant="caption" display="block" gutterBottom>
            {`Medicamento: ${
              tempRow?.id_parada_traslado_ingreso?.id_solicitud_ingreso
                .tipo_producto === "Medicamento" ||
              tempRow?.id_parada_traslado_ingreso?.id_solicitud_ingreso
                .tipo_producto === "InsumoMedicamento" ||
              tempRow?.id_envios_pedidos?.es_farmacia === true
                ? "Sí"
                : "No"
            }`}
          </Typography>
        )}
        {infoRequest.length > 0 && (
          <Typography variant="caption" display="block" gutterBottom>
            {`Estado picking: ${
              infoRequest[0].id_envio_pedido
                ? infoRequest[0].id_envio_pedido.id_solicitud_pedido
                    .estado_picking
                : "-"
            }`}
          </Typography>
        )}
      </Grid>
    </Grid>
    {infoRequest.length > 0 && (
      <h3 id="spring-modal-title">{`Contenido del pedido`}</h3>
    )}
    {infoRequest.length > 0 && (
      <Grid
        item
        xs={12}
        style={{
          margin: "10px 0 10x 0",
          height: "300px",
          overflow: "auto",
        }}
      >
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-center align-top">SKU </th>
              <th className="text-center align-top">Grupo </th>
              <th className="text-center align-top">
                Descripción
              </th>
              <th className="text-center align-top">Cantidad </th>
              <th className="text-center align-top">
                Observaciones
              </th>
              <th className="text-center align-top">Picking </th>
              <th className="text-center align-top">Estado </th>
              <th className="text-center align-top"></th>
            </tr>
          </thead>
          <tbody>
            {infoRequest.map((row, index) => (
              <tr key={`${index}-workRows`}>
                <td className="text-center align-middle">
                  {row.sku ? row?.sku
                    : (row?.id_producto_pedido?.id_insumo_medicamento ? row?.id_producto_pedido?.id_insumo_medicamento.SKU
                    : row?.id_producto_pedido?.id_equipo_asignado?.sku_bodega)}
                </td>
                <td className="text-center align-middle">
                  {row.sku ? "Insumo / Medicamento"
                    : (row?.id_producto_pedido?.id_insumo_medicamento ? row?.id_producto_pedido?.id_insumo_medicamento?.grupo_producto
                    : "Equipo")}
                </td>
                <td className="text-center align-middle">
                  {row.descripcion ? row?.descripcion
                    : (row?.id_producto_pedido?.id_insumo_medicamento ? row?.id_producto_pedido?.id_insumo_medicamento?.descripcion_producto
                    : row?.id_producto_pedido?.id_equipo_asignado?.tipo_equipo)}
                </td>
                <td className="text-center align-middle">
                  {row.cantidad ? row?.cantidad
                    : row.id_producto_pedido.cantidad}
                </td>
                <td className="text-center align-middle">
                  {row.observaciones ? row?.observaciones
                    : row.id_producto_pedido.observaciones}
                </td>
                <td className="text-center align-middle">
                  {row.id_producto_pedido ? row.id_producto_pedido.estado
                    : "-"}
                </td>
                <td className="text-center align-middle">
                  <Brightness1
                    style={{
                      fill:
                        stateColor[
                          row.id_producto_pedido
                            ? row.id_producto_pedido.estado
                            : "Completo"
                        ]
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    )}
  </Grid>

  const _actions_ = <>
    <Button
      mr={2}
      variant="contained"
      className="text-white"
      color="primary"
      onClick={handleAccept}
    >
      Aceptar
    </Button>
    <Button
      mr={2}
      variant="contained"
      className="text-white"
      color="primary"
      onClick={handleDetailRefuse}
    >
      Rechazar
    </Button>
    <Button
      mr={2}
      variant="contained"
      style={{ backgroundColor: red[500], marginRight: "10px" }}
      className="text-white"
      onClick={handleClose}
    >
      Cerrar
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={`Datos del pedido`}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}
