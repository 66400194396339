import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SortIcon from "@material-ui/icons/Sort";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";

import { useSortableData } from "../SortableData/SortableData";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const PurchasesTable = props => {
  const classes = useStyles();
  const { groupSelected, setGroupSelected, listProductType } = props;
  const [group, setGroup] = useState("");
  const { items, requestSort, sortConfig } = useSortableData(props.purchases);
  const [checked, setChecked] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  let filteredDivision = items.filter(order => {
    return order.id_producto_comprado.grupo_producto.toLowerCase().indexOf(group.toLowerCase()) !== -1;
  });


  const handleSelect = (event, index) => {
    let sumSelect = 0;
    let tempRequestType = "";
    let tempRequestGroup = "";
    const tempPurchase = props.purchases;
    tempPurchase[index].select = event;
    if (tempPurchase[index].tipo_solicitud === "Normal") {
      tempRequestType = "Normal";
      tempRequestGroup =
        tempPurchase[index].id_producto_comprado.grupo_producto;
    } else if (tempPurchase[index].tipo_solicitud === "Aseo") {
      tempRequestType = "Aseo";
      tempRequestGroup =
        tempPurchase[index].id_producto_comprado.grupo_producto;
    } else if (tempPurchase[index].tipo_solicitud === "Activos") {
      tempRequestType = "Activos";
      tempRequestGroup =
        tempPurchase[index].id_producto_comprado.grupo_producto;
    }
    props.setPurchases(tempPurchase);

    props.setPageRefresh(true);
    sumSelect = tempPurchase.filter(purchase => purchase.select).length;
    if (sumSelect === 0) {
      props.setRequestType("");
      props.setRequestGroup("");
    } else {
      props.setRequestType(tempRequestType);
      props.setRequestGroup(tempRequestGroup);
    }
    props.setShowHandlePurchase(sumSelect);
    
    // console.log("sumSelect", sumSelect);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "10px" }}>
                Id 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("id")}
                  className={getClassNamesFor("id")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                SKU 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("sku")}
                  className={getClassNamesFor("sku")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Descripción 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("description")}
                  className={getClassNamesFor("description")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Grupo 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={groupSelected}
                  onChange={e => setGroupSelected(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {listProductType.map((group, index) => {
                    return (
                      <MenuItem key={index} value={group[1]}>
                        {group[1]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Tipo de solicitud 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("tipo_solicitud")}
                  className={getClassNamesFor("tipo_solicitud")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Cantidad 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("cantidad")}
                  className={getClassNamesFor("cantidad")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Observaciones 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("observaciones")}
                  className={getClassNamesFor("observacione")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Area que solicita 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("area_solicitante")}
                  className={getClassNamesFor("area_solicitante")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Fecha solicitud 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("fecha_solicitud")}
                  className={getClassNamesFor("fecha_solicitud")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">Seleccionar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDivision
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.sku}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.group}</TableCell>
                  <TableCell align="center">{row.tipo_solicitud}</TableCell>
                  <TableCell align="center">{row.cantidad}</TableCell>
                  <TableCell align="center">{row.observaciones}</TableCell>
                  <TableCell align="center">{row.area_solicitante}</TableCell>
                  <TableCell align="center">{row.fecha_solicitud}</TableCell>
                  <TableCell align="center">
                    {props.requestType === "" ||
                    row.tipo_solicitud === props.requestType && row.id_producto_comprado.grupo_producto === props.requestGroup ? (
                      <Checkbox
                        checked={row.select}
                        onChange={e => {
                          handleSelect(e.target.checked, index);
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    ) : (
                      <Checkbox
                        checked={row.select}
                        onChange={e => {
                          handleSelect(e.target.checked, index);
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        disabled
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredDivision.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {props.pageRefresh && <div></div>}
    </Paper>
  );
};

export default PurchasesTable;

PurchasesTable.propTypes = {
  purchases: PropTypes.array,
  pageRefresh: PropTypes.bool,
  groupSelected: PropTypes.string,
  setGroupSelected: PropTypes.func,
  listProductType: PropTypes.array
};
