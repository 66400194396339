import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    TextField as TextFieldMui,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import {
    blue,
  } from "@material-ui/core/colors";
import MedicinesTable from "./MedicinesTable";
import Logo from "../../layouts/Logo.jpeg";
import {toMoneyFormat} from "utils/formatFunctions";
import moment from "moment-timezone";

const TextField = withStyles({
    root: {
        marginRight: 8,
        display: "flex",
        "& .MuiInputBase-root.Mui-disabled": {
            color: "#000000" // (default alpha is 0.38)
        }
    }
})(TextFieldMui);

export default class GenerationPrescriptionGeneric extends React.Component {
    render() {
        const {
            dataPatient,
            infoArray,
            specialistInfo,
            medicineList,
        } = this.props;

        return (
        <div>
            {infoArray.filter((info) => !!info).map((info, index) => (
            <div key={`prescription_${index}`} style={{padding: "20px"}}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item xs={12}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={6}
                    >
                        <img
                            alt="Qries"
                            src={Logo}
                            width="150"
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item xs={6}
                    >
                        <Typography variant="h6" style={{color: blue[500]}} gutterBottom><b>{`Dr. ${specialistInfo.nombres}  ${specialistInfo.apellido_paterno}  ${specialistInfo.apellido_materno}`}</b></Typography>
                        <Typography variant="h6" style={{color: blue[500]}} gutterBottom><b>{`${specialistInfo.id_cargo ? specialistInfo.id_cargo.nombre_corto : ""}`}</b></Typography>
                        <Typography variant="h6" style={{color: blue[500]}} gutterBottom><b>{`R.U.T: ${specialistInfo.rut}`}</b></Typography>
                        <Typography variant="h6" style={{color: blue[500]}} gutterBottom><b>{`Reg: ${specialistInfo.REG}`}</b></Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                    item xs={12}
                    style={{padding: "20px"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        item xs={3}
                    >
                        <Typography variant="h6" gutterBottom><b>Nombre:</b></Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={9}
                        style={{padding: "5px 10px"}}
                    >
                        <TextField
                            disabled
                            id="nombre"
                            label=""
                            multiline
                            value={`${dataPatient.nombre} ${dataPatient.apellido_paterno} ${dataPatient.apellido_materno}`}
                            variant="standard"
                            onChange={(event) => {}}
                            style={{width: "100%"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        item xs={3}
                    >
                        <Typography variant="h6" gutterBottom><b>Edad:</b></Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={3}
                        style={{padding: "5px 10px"}}
                    >
                        <TextField
                            disabled
                            id="nombre"
                            label=""
                            multiline
                            value={`${moment().diff(moment(dataPatient.nacimiento),"years")} años`}
                            variant="standard"
                            onChange={(event) => {}}
                            style={{width: "100%"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        item xs={3}
                    >
                        <Typography variant="h6" gutterBottom><b>C. identidad:</b></Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={3}
                        style={{padding: "5px 10px"}}
                    >
                        <TextField
                            disabled
                            id="nombre"
                            label=""
                            multiline
                            value={dataPatient.rut}
                            variant="standard"
                            onChange={(event) => {}}
                            style={{width: "100%"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        item xs={3}
                    >
                        <Typography variant="h6" gutterBottom><b>Domicilio:</b></Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={9}
                        style={{padding: "5px 10px"}}
                    >
                        <TextField
                            disabled
                            id="nombre"
                            label=""
                            multiline
                            value={`${dataPatient.direccion_calle} ${dataPatient.direccion_block}, ${dataPatient.direccion_comuna}, ${dataPatient.direccion_region}`}
                            variant="standard"
                            onChange={(event) => {}}
                            style={{width: "100%"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        item xs={12}
                    >
                        <Typography variant="h6" gutterBottom>{moment().format("YYYY-MM-DD")}</Typography>
                    </Grid>
                </Grid>
                <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{padding: "5px 10px"}}
                    >
                        <TextField
                            disabled
                            id="indication_id"
                            label="Indicaciones generales"
                            multiline
                            rows={41}
                            value={info}
                            variant="outlined"
                            onChange={(event) => {}}
                            style={{width: "100%"}}
                        />

                    </Grid>
            </Grid>
            {specialistInfo.firma ?
             <Grid
                container
                direction="column"
                justify="flex-end"
                alignItems="flex-end"
                item xs={12}
                style={{padding: "10px"}}
            >
                <img
                    style={{width: "150px", height: "150px"}}
                    src={specialistInfo.firma.archivo}
                />
                <Grid  style={{width: "150px"}}>
                    <TextField
                        disabled
                        id="nombre"
                        label=""
                        multiline
                        value={``}
                        variant="standard"
                        style={{width: "100%"}}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item xs={12}
                    style={{width: "150px"}}
                >
                    <Typography variant="h5" style={{color: blue[900]}} gutterBottom>{'Firma'}</Typography>
                </Grid>
            </Grid> :
            <Grid>
                <Card variant="outlined" style={{width: "150px", height: "150px", background: blue[500]}}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            item xs={12}
                        >
                            <Typography variant="h5" style={{color: blue[50]}} gutterBottom>{<u>Sin Firma</u>}</Typography>
                        </Grid>

                    </CardContent>
                </Card>
            </Grid>
            }
            <Grid
                container
                direction="column"
                justify="flex-end"
                alignItems="center"
                item xs={12}
                // style={{height: "100%", }}
            >
                <Card variant="outlined" style={{width: "100%", background: blue[500]}}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            item xs={12}

                        >
                            <Typography variant="h5" style={{color: blue[50]}} gutterBottom>{<u>SAN GERARDO 741 - RECOLETA - SANTIAGO, CHILE</u>}</Typography>
                            <Typography variant="h6" style={{color: blue[50]}}  gutterBottom>{`56 22 622 5560 WWW.CMCHILE.CL 9 66297228`}</Typography>
                        </Grid>

                    </CardContent>
                </Card>

            </Grid>
            </div>
            ))}

            {medicineList.map((auxMedicineList) => (
             auxMedicineList && auxMedicineList.map((rows, index) => (
            <div key={`medicines_${index}`} style={{padding: "20px"}}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item xs={12}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={6}
                    >
                        <img
                            alt="Qries"
                            src={Logo}
                            width="150"
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item xs={6}
                    >
                        <Typography variant="h6" style={{color: blue[500]}} gutterBottom><b>{`Dr. ${specialistInfo.nombres}  ${specialistInfo.apellido_paterno}  ${specialistInfo.apellido_materno}`}</b></Typography>
                        <Typography variant="h6" style={{color: blue[500]}} gutterBottom><b>{`${specialistInfo.id_cargo ? specialistInfo.id_cargo.nombre_corto : ""}`}</b></Typography>
                        <Typography variant="h6" style={{color: blue[500]}} gutterBottom><b>{`R.U.T: ${specialistInfo.rut}`}</b></Typography>
                        <Typography variant="h6" style={{color: blue[500]}} gutterBottom><b>{`Reg: ${specialistInfo.REG}`}</b></Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                    item xs={12}
                    style={{padding: "0 20px"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        item xs={3}
                    >
                        <Typography variant="h6" gutterBottom><b>Nombre:</b></Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={9}
                        style={{padding: "5px 10px"}}
                    >
                        <TextField
                            disabled
                            id="nombre"
                            label=""
                            multiline
                            value={`${dataPatient.nombre} ${dataPatient.apellido_paterno} ${dataPatient.apellido_materno}`}
                            variant="standard"
                            onChange={(event) => {}}
                            style={{width: "100%"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        item xs={3}
                    >
                        <Typography variant="h6" gutterBottom><b>Edad:</b></Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={3}
                        style={{padding: "5px 10px"}}
                    >
                        <TextField
                            disabled
                            id="nombre"
                            label=""
                            multiline
                            value={`${moment().diff(moment(dataPatient.nacimiento),"years")} años`}
                            variant="standard"
                            onChange={(event) => {}}
                            style={{width: "100%"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        item xs={3}
                    >
                        <Typography variant="h6" gutterBottom><b>C. identidad:</b></Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={3}
                        style={{padding: "5px 10px"}}
                    >
                        <TextField
                            disabled
                            id="nombre"
                            label=""
                            multiline
                            value={dataPatient.rut}
                            variant="standard"
                            onChange={(event) => {}}
                            style={{width: "100%"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        item xs={3}
                    >
                        <Typography variant="h6" gutterBottom><b>Domicilio:</b></Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        item xs={9}
                        style={{padding: "5px 10px"}}
                    >
                        <TextField
                            disabled
                            id="nombre"
                            label=""
                            multiline
                            value={`${dataPatient.direccion_calle} ${dataPatient.direccion_block}, ${dataPatient.direccion_comuna}, ${dataPatient.direccion_region}`}
                            variant="standard"
                            onChange={(event) => {}}
                            style={{width: "100%"}}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-end"
                        item xs={12}
                    >
                        <Typography variant="h6" gutterBottom>{moment().format("YYYY-MM-DD")}</Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    item
                    xs={12}
                    style={{padding: "0 10px", height: "850px"}}
                >
                    <MedicinesTable
                        rows={rows}
                        updateRow={false}
                        pagination={false}
                    />
                </Grid>
            </Grid>
            {specialistInfo.firma ?
            <Grid
                container
                direction="column"
                justify="flex-end"
                alignItems="flex-end"
                item xs={12}
                style={{padding: "10px"}}
            >
                <img
                    style={{width: "150px", height: "150px"}}
                    src={specialistInfo.firma.archivo}
                />
                <Grid  style={{width: "150px"}}>
                    <TextField
                        disabled
                        id="nombre"
                        label=""
                        multiline
                        value={``}
                        variant="standard"
                        style={{width: "100%"}}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item xs={12}
                    style={{width: "150px"}}
                >
                    <Typography variant="h5" style={{color: blue[900]}} gutterBottom>{'Firma'}</Typography>
                </Grid>
            </Grid> :
            <Grid>
                <Card variant="outlined" style={{width: "150px", height: "150px", background: blue[500]}}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            item xs={12}
                        >
                            <Typography variant="h5" style={{color: blue[50]}} gutterBottom>{<u>Sin Firma</u>}</Typography>
                        </Grid>

                    </CardContent>
                </Card>
            </Grid>
            }
            <Grid
                container
                direction="column"
                justify="flex-end"
                alignItems="center"
                item xs={12}
                // style={{height: "100%", }}
            >
                <Card variant="outlined" style={{width: "100%", background: blue[500]}}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            item xs={12}

                        >
                            <Typography variant="h5" style={{color: blue[50]}} gutterBottom>{<u>SAN GERARDO 741 - RECOLETA - SANTIAGO, CHILE</u>}</Typography>
                            <Typography variant="h6" style={{color: blue[50]}}  gutterBottom>{`56 22 622 5560 WWW.CMCHILE.CL 9 66297228`}</Typography>
                        </Grid>

                    </CardContent>
                </Card>

            </Grid>
            </div>
            ))))}
        </div>
      );
    }
};
