import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import OrderDetailInfo from "./OrderDetail";
import ProductsTable from "./Products/Products";
import ModalMessageSuccess from "./ModalMessageSuccess";
import ModalMessageIncomplete from "./ModalMessageIncomplete";
import ModalMessageFiles from "./ModalMessageFiles";
import ModalError from "./ModalError";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function AddFileIncomeFile() {
  const classes = useStyles();
  const { id } = useParams();
  const id_order = parseInt(id, 10);
  const [moduleType, setmoduleType] = useState("");


  const [changes, setChanges] = useState(true);
  const [detail, setDetail] = useState([]);
  const [products, setProducts] = useState([]);
  const [openModalMessageSuccess, setOpenModalMessageSuccess] = useState(false);
  const [messageModalMessageSuccess, setMessageModalMessageSuccess] = useState("");
  const [openModalMessageIncomplete, setOpenModalMessageIncomplete] = useState(false);
  const [messageModalMessageIncomplete, setMessageModalMessageIncomplete] = useState("");
  const [openModalMessageFiles, setOpenModalMessageFiles] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const [loading, setLoading] = useState(false);

  const [observation, setObservation] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);
    const arrayURL = window.location.href.split('/');
    setmoduleType(arrayURL[arrayURL.length-2])
    const detail = api.get(
      `api/ingresos/compras/solicitud/${id_order}/bodega/`
    );
    const products = api.get(
      `api/ingresos/compras/solicitud/${id_order}/detalle/`
    );
    Promise.all([detail, products])
      .then(response => {
        if (response[0].data.length > 1) {

          setOpenModalError(true);
        }
        setDetail(response[0].data);
        setProducts(response[1].data);

        setLoading(true);
      })

  };

  const modalMessageSuccess = (message, open) => {
    setMessageModalMessageSuccess(message);
    setOpenModalMessageSuccess(open);
  };

  const modalMessageIncomplete = (message, open) => {
    setMessageModalMessageIncomplete(message);
    setOpenModalMessageIncomplete(open);
  };

  function createReception() {

    const formData = new FormData();

    formData.append("nombre", file.name);
    formData.append("tipo_documento", "Guía de entrada");
    formData.append("archivo", file);
    formData.append("area", "Ingresos");
    formData.append("observacion", observation);
    formData.append("id_solicitud", detail.id);

    apiform
      .post("api/documentosingresos/", formData)
      .then(response => {
        console.log("ok api/documentosingresos/", response);
        api
          .patch(`api/bodega/ingresos/${id}/detalle/`, { guia_ingresos: true })
          .then(response => {
            console.log("ok bodega/ingresos/id/detalle/", response);
            api
              .post(`api/ingresos/compras/solicitud/${id}/finalizar/`)
              .then(response => {

                console.log("OK api/bodega/ingresos/id/finalizar", response);
                modalMessageSuccess(
                  "Se guardaron exitosamente los registros",
                  true
                );
              })
              .catch(error => {

                console.log(
                  "Error api/bodega/ingresos/id/finalizar",
                  error.response
                );
                modalMessageIncomplete(error.response.data.mensaje, true);
              });
          })
          .catch(error => {
            console.log("error api/bodea/ingresos/id/detalle", error.response);
          });
      })

  }

  const checkFiles = () => {

    api
      .post(`api/bodega/ingresos/${id}/finalizar/`)
      .then(response => {

        modalMessageSuccess("Se guardaron exitosamente los registros", true);
      })
      .catch(error => {

        console.log("Error api/bodega/ingresos/id/finalizar", error.response);
        setOpenModalMessageFiles(true);
      });
  };

  return (
    <GridContainer>
      <ViewTitle
        title="ADJUNTAR ARCHIVO"
        message="En esta sección podrás el archivo de recepción."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link color="inherit" to={`/admin/warehouse/receptions/${moduleType}`}>
              Recepción de ingresos y devoluciones
            </Link>
            <Typography color="textPrimary">Adjuntar archivo</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <OrderDetailInfo
                id={detail.id}
                tipo={detail.tipo_solicitud_ingreso}
                cantidad={detail.cantidad_productos}
                observacion={detail.observacion}
                fechaLlegada={detail.fecha_llegada}
                estado={detail.estado_solicitud}
              />
            </GridContainer>
            <CardBody>
              <ProductsTable products={products} />
              {detail.estado_solicitud === "RevisarCompra" ? null : (
                <GridContainer justify="center" className="m-3 mt-3 mb-3">
                  <Grid container xs={12} style={{ textAlign: "center" }}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                      <DetailTextArea
                        label="OBSERVACIONES:"
                        placeholder="Observaciones"
                        value={observation}
                        maxlength="500"
                        onchange={e => {
                          setObservation(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </GridContainer>
              )}
              {detail.estado_solicitud === "RevisarCompra" ? null : (
                <GridContainer
                  justify="center"
                  style={{ textAlign: "center" }}
                  className="m-3 mt-3 mb-3"
                >
                  <div className="col-md-4 mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="card-title">ARCHIVO:</span>
                        <p className="card-text">
                          Archivo: {file ? file.name : null}
                        </p>
                        <span className="btn btn-cmh-color btn-file">
                          Seleccionar
                          <input
                            type="file"
                            name={file}
                            onChange={e => {
                              setFile(e.target.files[0]);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </GridContainer>
              )}
              <GridContainer justify="center" className="mt-3 mb-3">
                {detail.estado_solicitud === "RevisarCompra" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={checkFiles}
                    className="m-1"
                  >
                    Revisar guías
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createReception}
                    className="m-1"
                  >
                    Guardar
                  </Button>
                )}
                <Link to={`/admin/warehouse/receptions/${moduleType}`}>
                  <Button variant="contained" color="primary" className="m-1">
                    Atrás
                  </Button>
                </Link>
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {openModalMessageSuccess && (
        <ModalMessageSuccess
          open={openModalMessageSuccess}
          message={messageModalMessageSuccess}
          moduleType={moduleType}
        />
      )}
      {openModalMessageIncomplete && (
        <ModalMessageIncomplete
          open={openModalMessageIncomplete}
          message={messageModalMessageIncomplete}
          moduleType={moduleType}
          id={id}
        />
      )}
      {openModalMessageFiles && (
        <ModalMessageFiles
          open={openModalMessageFiles}
          closeModal={() => setOpenModalMessageFiles(false)}
          moduleType={moduleType}
        />
      )}
      {openModalError && <ModalError open={openModalError} moduleType={moduleType} />}
    </GridContainer>
  );
}
