import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const Calendar = () => {
  const [loading, setLoading] = useState(true);
  const [moduleType, setmoduleType] = useState("");
  const [orders, setOrders] = useState([]);
  let arrayDavid = [];
  let dates = [];
  const [show, setShow] = useState(false);
  const [area, setArea] = useState("");

  useEffect(() => {
    const tempArrayURL = window.location.href.split("/");
    setmoduleType(tempArrayURL[tempArrayURL.length - 1]);
    tempArrayURL[tempArrayURL.length - 1] === "storage"
      ? setArea("insumo")
      : setArea("farmacia");
    api.get("api/solicitudpedidos/").then(result => {
      setLoading(true);
      setOrders(result.data);
    });
    setLoading(false);
  }, []);


  orders.map(solicitud => {
    const found = arrayDavid.find(
      element => element.fechaSolicitud === solicitud.fecha_solicitud
    );

    if (found) {
      switch (solicitud[`estado_solicitud_${area}`]) {
        case "Finalizado":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.finalizado = element.finalizado + 1;
            });
          break;
        case "Finalizado en bodega":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.finalizadoEnBodega = element.finalizadoEnBodega + 1;
            });
          break;
        case "Reemplazo aprobado":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.reemplazoAprobado = element.reemplazoAprobado + 1;
            });
          break;
        case "Continuar picking":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.continuarPicking = element.continuarPicking + 1;
            });
          break;
        case "Adjuntar guía":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.adjuntarGuia = element.adjuntarGuia + 1;
            });
          break;
        case "Esperando documento de egresos":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.esperandoDocumentoEgreso =
                element.esperandoDocumentoEgreso + 1;
            });
          break;
        case "Pendiente en Solicitud Paciente":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.pendienteSolicitudPaciente =
                element.pendienteSolicitudPaciente + 1;
            });
          break;
        case "Adjuntar guía de bodega":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.adjuntarGuiaBodega = element.adjuntarGuiaBodega + 1;
            });
          break;
        case "Esperar a egresos":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.esperarEgresos = element.esperarEgresos + 1;
            });
          break;
        case "Esperar aprobación reemplazo de reemplazo área clínica":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.aprobacionReemplazo = element.aprobacionReemplazo + 1;
            });
          break;
        case "Hacer picking":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.hacerPicking = element.hacerPicking + 1;
            });
          break;
        case "Notificar a egresos":
          arrayDavid
            .filter(array => array.fechaSolicitud === solicitud.fecha_solicitud)
            .map(element => {
              element.notificarEgresos = element.notificarEgresos + 1;
            });
          break;
        default:
          return null;
      }
    } else {
      switch (solicitud[`estado_solicitud_${area}`]) {
        case "Finalizado":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 1,
            finalizadoEnBodega: 0,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Adjuntar guía de bodega":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 0,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 1,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Pendiente en Solicitud Paciente":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 0,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 1,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Esperando documento de gresos":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 0,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 1,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Finalizado en bodega":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 1,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Continuar picking":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 0,
            continuarPicking: 1,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Esperar a egresos":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 0,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 1,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Esperar aprobación reemplazo área clínica":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 0,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 1,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Reemplazo aprobado":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 0,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 1,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Adjuntar guía":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 0,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 1,
            hacerPicking: 0,
            notificarEgresos: 0
          });
          break;
        case "Hacer picking":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 0,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 1,
            notificarEgresos: 0
          });
          break;
        case "Notificar a egresos":
          arrayDavid.push({
            fechaSolicitud: solicitud.fecha_solicitud,
            finalizado: 0,
            finalizadoEnBodega: 0,
            continuarPicking: 0,
            esperandoDocumentoEgreso: 0,
            pendienteSolicitudPaciente: 0,
            adjuntarGuiaBodega: 0,
            esperarEgresos: 0,
            aprobacionReemplazo: 0,
            reemplazoAprobado: 0,
            adjuntarGuia: 0,
            hacerPicking: 0,
            notificarEgresos: 1
          });
          break;
        default:
          return null;
      }
    }
  });

  arrayDavid.map(array => {
    if (array.adjuntarGuia > 0) {
      dates.push({
        title: `${array.adjuntarGuia} Adjuntar guía`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.finalizadoEnBodega > 0) {
      dates.push({
        title: `${array.finalizadoEnBodega} Finalizado en bodega`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.continuarPicking > 0) {
      dates.push({
        title: `${array.continuarPicking} Continuar picking`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }

    if (array.esperandoDocumentoEgreso > 0) {
      dates.push({
        title: `${array.esperandoDocumentoEgreso} Esperando documento de egresos`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.adjuntarGuiaBodega > 0) {
      dates.push({
        title: `${array.adjuntarGuiaBodega} Adjuntar guía de bodega`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.pendienteSolicitudPaciente > 0) {
      dates.push({
        title: `${array.pendienteSolicitudPaciente} Pendiente en Solicitud Paciente`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.aprobacionReemplazo > 0) {
      dates.push({
        title: `${array.aprobacionReemplazo} Esperar aprobación reemplazo área clínica`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.adjuntarGuia > 0) {
      dates.push({
        title: `${array.adjuntarGuia} Adjuntar guía`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.esperarEgresos > 0) {
      dates.push({
        title: `${array.esperarEgresos} Esperar a egresos`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    /*
    if (array.finalizado > 0) {
      dates.push({
        title: `${array.finalizado} Finalizado`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    */
    if (array.hacerPicking > 0) {
      dates.push({
        title: `${array.hacerPicking} Hacer picking`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.notificarEgresos > 0) {
      dates.push({
        title: `${array.notificarEgresos} Notificar a egresos`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
    if (array.reemplazoAprobado > 0) {
      dates.push({
        title: `${array.reemplazoAprobado} Reemplazo aprobado`,
        date: array.fechaSolicitud,
        url: `/admin/warehouse/orders/${moduleType}`
      });
    }
  });

  useEffect(() => {
    if (dates.some(date => date.title)) {
      setShow(true);
    }
  });

  return (
    <GridContainer>
      <ViewTitle
        title="CALENDARIO"
        message={`En esta sección podrás revisar los pedidos de ${
          moduleType === "storage" ? "bodega" : "bodega farmacia"
        }.`}
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" href={`/admin/warehouse/task/${moduleType}`}>
              {`Tareas ${
                moduleType === "storage" ? "Bodega" : "Bodega Farmacia"
              }`}
            </Link>
            <Typography color="textPrimary">Calendario</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading && show ? (
          <Card>
            <CardBody>
              <FullCalendar
                defaultView="dayGridMonth"
                plugins={[dayGridPlugin]}
                weekends={true}
                events={dates}
              />
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default Calendar;
