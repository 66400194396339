import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  field: {
    "&::placeholder": {
      color: "#e0e0e0"
    }
  }
});

const DetailText = props => {
  const classes = useStyles();

  return (
    <div className="col-md-4 mb-3">
      <label className="text-dark" style={{ fontSize: "16px" }}>
        {props?.label}
        {props?.value === "" ? (
          <span className="text-danger"> no puede ser vacío</span>
        ) : null}
      </label>
      <input
        type="text"
        maxLength={props?.maxlength}
        className={
          props?.value === ""
            ? `form-control bg-danger font-weight-lighter ${classes.field}`
            : "form-control bg-light font-weight-lighter text-dark"
        }
        value={props?.value}
        placeholder={props.placeholder}
        onChange={props?.onchange}
      />
    </div>
  );
};

export default DetailText;
