import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import 'date-fns';




export default function ModalRejectPurchase(props) {
  const {
    open,
    closeModal,

  } = props;


  const [observation, setObservation] = useState("");

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  function rejectPurchases() {

    let tempPurchases = [];
    props.purchasesToSend.map(row => {
      tempPurchases.push(row.id);
    });

    api
      .put("api/compras/rechazarcompra/", {
        lista_compras: tempPurchases,
        motivo_rechazo: observation
      })
      .then(() => {
        window.location.href = "/admin/purchases/requests/";
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Rechazar solicitud</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          Los productos seleccionados son los siguientes: <br />
          <div className="mt-3 mb-3">
            <ul className="list-group">
              {props.purchasesToSend.map(purchase => (
                <li key={purchase.id} className="list-group-item">
                  {purchase.id_producto_comprado.SKU} /
                  {purchase.id_producto_comprado.grupo_producto} /
                  {purchase.id_producto_comprado.descripcion_producto} (
                  {purchase.cantidad}{" "}
                  {purchase.cantidad === 1 ? "Unidad" : "Unidades"})
                </li>
              ))}
            </ul>
          </div>
          <div>
            <DetailTextArea
              label="¿Cuál es el motivo del rechazo?"
              placeholder="Motivo rechazo"
              value={observation}
              maxlength="500"
              onchange={e => {
                setObservation(e.target.value);
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          color="primary"
          style={{ textTransform: "none" }}
          variant="contained"
        >
          Cancelar
        </Button>
        <Button
          onClick={rejectPurchases}
          color="primary"
          style={{ textTransform: "none" }}
          variant="contained"
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalRejectPurchase.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number,
  productos_restantes: PropTypes.array,
  purchasesToSend: PropTypes.array
};
