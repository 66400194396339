import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModalMessage from "./ModalMessage";

import Checkbox from "@material-ui/core/Checkbox";


export default function ModalAddRecord(props) {
  const {
    open,
    closeModal,
    id_order,
  } = props;
  const [modalMessage, setModalMessage] = useState(false);
  const [insumos, setInsumos] = useState([]);
  const [order, setOrder] = useState([]);


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    api.get(`api/solicitudpedidos/${id_order}`).then((response) => {
      setOrder(response.data);
    });
    api.get("api/insumosmedicamento/").then((response) => {
      setInsumos(response.data);

    });
  }, []);

  const scroll = "paper";

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Agregar registro</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          <div className="mt-3 mb-3">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="text-center align-middle">Id</th>
                  <th className="text-center align-middle">SKU</th>
                  <th className="text-center align-middle">Descripción</th>
                  <th className="text-center align-middle">Grupo</th>
                  <th className="text-center align-middle">Cantidad</th>
                  <th className="text-center align-middle">Observaciones</th>
                  <th className="text-center align-middle">Seleccionar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center align-middle">1</td>
                  <td className="text-center align-middle">pro104</td>
                  <td className="text-center align-middle">Propoleo</td>
                  <td className="text-center align-middle">Insumo</td>
                  <td className="text-center align-middle">4</td>
                  <td className="text-center align-middle">Sin observación</td>
                  <td className="text-center align-middle">
                    <Checkbox
                      defaultChecked
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Cancelar
        </Button>
        <Button onClick={closeModal} color="primary">
          Agregar registro
        </Button>
      </DialogActions>
      {modalMessage && (
        <ModalMessage
          open={modalMessage}
          closeModal={() => setModalMessage(false)}
        />
      )}
    </Dialog>
  );
}

ModalAddRecord.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number,
  productos_restantes: PropTypes.array,
};
