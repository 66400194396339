import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Paper,
    Grid,
    Typography,
    Backdrop,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
}));

export default function ModalContractUpload({
    open,
    handleClose,
}) {
    const classes = useStyles();


    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px'
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
            className={classes.paper}
            style={{
                maxHeight: "900px",
                overflow: "auto",
                width: "650px",
            }}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{padding: "5px"}}
                >
                    <Typography  variant="caption" gutterBottom>
                        {`Archivo adjuntado con éxito. Éste será almacenado en la Ficha del paciente, sección Datos Previsionales.`}
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 0 0"}}
                >
                    <Button
                        mr={2}
                        variant="contained"
                        color="primary"
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
      </Modal>
  );
}