import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "../Widgets/FichaPersonal/viewTitle";
import CardTitle from "../Widgets/FichaPersonal/cardTitle";
import TabRequest from "components/Request/TabRequest";
import SearchRequest from "./SearchRequest";
import moment from "moment";


const Request = () => {
  const [manageRequest, setManageRequest] = useState([]);
  const [search, setSearch] = useState("");

  const [firstCharge, setFirstCharge] = useState(true);

  const listAreaWork = useSelector(state => state.currentList.LISTA_AREA_TRABAJO);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);
    const getRequest = api.get(
      `api/solicitudesresponder/${localStorage.getItem("id_cargo")}`
    );
    const getRequestType = api.get("api/tiposolicitud/");
    const getPositions = api.get("api/cargos/");

    Promise.all([getRequest, getRequestType, getPositions])
      .then(response => {
        let tempManageRequest = [];
        response[0].data.map(row => {
          const tempArea = response[2].data
            .filter(position => position.id === row.id_cargo_solicitante_id)
            .map(filtered => filtered.area)[0];
          const today = moment(new Date()).format("DD/MM/YYYY");
          const request_date = moment(row.fecha_solicitud).format("DD/MM/YYYY");
          const diff = moment(today, "DD/MM/YYYY").diff(
            moment(request_date, "DD/MM/YYYY")
          );
          const duration = moment.duration(diff);
          tempManageRequest.push({
            row: row,
            id: row.id,
            prioridad: row.Prioridad,
            estado: row.estado,
            etapa: row.etapa,
            fechaSolicitud: row.fecha_solicitud,
            area: listAreaWork.filter(area => area[0] === tempArea)?.map(filtered => filtered[1])[0] || "-",
            solicitante: row.nombre_solicitante,
            tipoSolicitud: response[1].data
              .filter(request => request.id === row.id_tipo_solicitud_id)
              .map(filtered => filtered.nombre_solicitud)[0],
            diasAbierta: duration._data.days,
            proceso: row.id_cuenta_gestionando_id
              ? "En proceso"
              : "No gestionada"
          });
        });
        setManageRequest(tempManageRequest);

      })

  };

  const searchRequest = manageRequest.filter(request => {
    return (
      request.fechaSolicitud
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.area
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.solicitante
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.tipoSolicitud
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.prioridad
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.estado
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.diasAbierta
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
      request.proceso
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    );
  });

  return (
    <GridContainer>
      <ViewTitle
        title="Solicitudes"
        message="En esta sección podrás revisar todas las solicitudes realizadas por las diferentes áreas.  En ella podrás generar y descargar contratos, subir contratos firmados, crear anexos y adjuntar anexos firmados."
      />
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardTitle title="Solicitudes" />
          <CardBody>
            <Grid
              item
              container
              xs={12}
              justify="center"
              style={{ marginBottom: "3em" }}
            >
              <SearchRequest search={search} setSearch={setSearch} />
            </Grid>
            <TabRequest manageRequest={searchRequest} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Request;
