import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ModalAddDevice from "./ModalAddDevice";
import ModalEditDevice from "./ModalEditDevice";
import ModalDeleteDevice from "./ModalDeleteDevice";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  table: {
    minWidth: 700
  }
});

const TableDevices = props => {
  const classes = useStyles();
  const {
    getPatientDevices,
    getPatientSupplies,
    setChanges,
    listassigned,
    dataFile,
  } = props;
  const [openModalAddDevice, setOpenModalAddDevice] = useState(false);
  const [openModalEditDevice, setOpenModalEditDevice] = useState(false);
  const [openModalDeleteDevice, setOpenModalDeleteDevice] = useState(false);
  const [device, setDevice] = useState({});
  const today = moment().format("YYYY-MM-DD");

  const type_user = localStorage.getItem("tipo_perfil");

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      style={{ marginBottom: "6em" }}
    >
      <Grid item xs={1} />
      <Grid item xs={12}>
        <Grid item xs={12} container>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            item
            xs={12}
            style={{ marginBottom: "2em" }}
          >
            {type_user !== "CLITecEnfBas" &&
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              onClick={() => setOpenModalAddDevice(true)}
            >
              Agregar dispositivo
            </Button>
            }
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Dispositivos</TableCell>
                    <TableCell align="center">Estado</TableCell>
                    <TableCell align="center">Fecha inicio</TableCell>
                    <TableCell align="center">Fecha fin</TableCell>
                    <TableCell align="center">Frecuencia de cambio</TableCell>
                    <TableCell align="center">Frecuencia tipo de cambio</TableCell>
                    <TableCell align="center">Observación</TableCell>
                    {type_user !== "CLITecEnfBas" &&
                    <TableCell align="center">Eliminar</TableCell>
                    }
                    {type_user !== "CLITecEnfBas" &&
                    <TableCell align="center">Editar</TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getPatientDevices.map(row =>
                    moment(today).diff(moment(row.fecha_fin), "days") < 0 ? (
                      <TableRow
                        key={row.id}
                        style={{ backgroundColor: "#FFA4A4" }}
                      >
                        <TableCell align="center">
                          {row.id_prestaciones_paciente_id?.id_equipo_id
                            ? row.id_prestaciones_paciente_id.id_equipo_id
                                .descripcion
                            : row.id_prestaciones_paciente_id
                                .id_insumo_medicamento_id.descripcion_producto}
                        </TableCell>
                        <TableCell align="center">{row.estado}</TableCell>
                        <TableCell align="center">{moment(row.fecha_inicio).format("DD-MM-YYYY HH:mm")}</TableCell>
                        <TableCell align="center">{moment(row.fecha_fin).format("DD-MM-YYYY HH:mm")}</TableCell>
                        <TableCell align="center">
                          {row.frecuencia_cambio===0 ? '-':row.frecuencia_cambio}
                        </TableCell>
                        <TableCell align="center">
                          {row.frecuencia_tipo ? row.frecuencia_tipo:'Sin frecuencia'}
                        </TableCell>
                        <TableCell align="center">{row.observacion}</TableCell>
                        {type_user !== "CLITecEnfBas" &&
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setDevice(row);
                              setOpenModalDeleteDevice(true);
                            }}
                          >
                            Eliminar
                          </Button>
                        </TableCell>
                        }
                        {type_user !== "CLITecEnfBas" &&
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setDevice(row);
                              setOpenModalEditDevice(true);
                            }}
                          >
                            Editar
                          </Button>
                        </TableCell>
                        }
                      </TableRow>
                    ) : (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          {
                            row?.id_prestaciones_paciente_id?.id_equipo_id?.descripcion
                          }
                        </TableCell>
                        <TableCell align="center">{row.estado}</TableCell>
                        <TableCell align="center">{moment(row.fecha_inicio).format("DD-MM-YYYY HH:mm")}</TableCell>
                        <TableCell align="center">{moment(row.fecha_fin).format("DD-MM-YYYY HH:mm")}</TableCell>
                        <TableCell align="center">
                          {row.frecuencia_cambio===0 ? '-':row.frecuencia_cambio}
                        </TableCell>
                        <TableCell align="center">
                          {row.frecuencia_tipo ? row.frecuencia_tipo:'Sin frecuencia'}
                        </TableCell>
                        <TableCell align="center">{row.observacion}</TableCell>
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setDevice(row);
                              setOpenModalDeleteDevice(true);
                            }}
                          >
                            Eliminar
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setDevice(row);
                              setOpenModalEditDevice(true);
                            }}
                          >
                            Editar
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} />
      {openModalAddDevice && (
        <ModalAddDevice
          open={openModalAddDevice}
          closeModal={() => setOpenModalAddDevice(false)}
          getPatientSupplies={getPatientSupplies}
          setChanges={setChanges}
          listassigned={listassigned}
          dataFile={dataFile}
        />
      )}
      {openModalEditDevice && (
        <ModalEditDevice
          open={openModalEditDevice}
          closeModal={() => setOpenModalEditDevice(false)}
          device={device}
          getPatientSupplies={getPatientSupplies}
          setChanges={setChanges}
          listassigned={listassigned}
          dataFile={dataFile}
        />
      )}
      {openModalDeleteDevice && (
        <ModalDeleteDevice
          open={openModalDeleteDevice}
          closeModal={() => setOpenModalDeleteDevice(false)}
          device={device}
          setChanges={setChanges}
        />
      )}
    </Grid>
  );
};

export default TableDevices;

TableDevices.propTypes = {
  getPatientDevices: PropTypes.array,
  getPatientSupplies: PropTypes.array,
  setChanges: PropTypes.func,
};
