import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import SortIcon from "@material-ui/icons/Sort";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableRecallOutcome = props => {
  const classes = useStyles();
  const {
    searchRetirement,
    requestSortRetirement,
    getClassNamesForRetirement,
    moduleType,
    request_type,
    setRequest_type
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Id 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortRetirement("id")}
                  className={getClassNamesForRetirement("id")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                SKU 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() =>
                    requestSortRetirement("id_producto_retirado.SKU")
                  }
                  className={getClassNamesForRetirement(
                    "id_producto_retiro.SKU"
                  )}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Grupo 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={request_type}
                  onChange={e => setRequest_type(e.target.value.substr(0, 20))}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value={"Medicamentos"}>Medicamentos</MenuItem>
                  <MenuItem value={"Insumos"}>Insumos</MenuItem>
                  <MenuItem value={"Equipamiento"}>Equipamiento</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "250px" }}>
                Descripción 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() =>
                    requestSortRetirement(
                      "id_producto_retirado.descripcion_producto"
                    )
                  }
                  className={getClassNamesForRetirement(
                    "id_producto_retirado.descripcion_producto"
                  )}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Cantidad a retirar 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortRetirement("cantidad")}
                  className={getClassNamesForRetirement("cantidad")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Motivo 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortRetirement("motivo")}
                  className={getClassNamesForRetirement("motivo")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Fecha solicitud 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortRetirement("fecha_solicitud")}
                  className={getClassNamesForRetirement("fecha_solicitud")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Estado 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSortRetirement("estado")}
                  className={getClassNamesForRetirement("estado")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchRetirement
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">
                    {row.id_producto_retirado.SKU}
                  </TableCell>
                  <TableCell align="center">
                    {row.id_producto_retirado.grupo_producto}
                  </TableCell>
                  <TableCell align="center">
                    {row.id_producto_retirado.descripcion_producto}
                  </TableCell>
                  <TableCell align="center">{row.cantidad} </TableCell>
                  <TableCell align="center">{row.motivo}</TableCell>
                  <TableCell align="center">{row.fecha_solicitud}</TableCell>
                  <TableCell align="center">{row.estado}</TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/admin/outcome/commodity/${moduleType}/${row.id}`}
                    >
                      <Button
                        style={{ textTransform: "none" }}
                        variant="contained"
                        color="primary"
                      >
                        Detalle
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={searchRetirement.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableRecallOutcome;

TableRecallOutcome.propTypes = {
  searchRetirement: PropTypes.array,
  requestSortRetirement: PropTypes.func,
  getClassNamesForRetirement: PropTypes.func,
  moduleType: PropTypes.string,
  request_type: PropTypes.string,
  setRequest_type: PropTypes.func
};
