import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import RequestsInformation from "./RequestsInformation";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSortableData } from "./SortableData/SortableData";
import { useSortableDataCode } from "./SortableDataCode/SortableData";
import TableDivisionDivision from "./Tables/TableDivisionDivision";
import TableProductCodeDivision from "./Tables/TableProductCodeDivision";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export default function IncomeDivisions() {
  const classes = useStyles();
  const [moduleType, setmoduleType] = useState("");
  const [requests, setRequests] = useState([]);

  const [skuRequest, setSkuRequest] = useState([]);

  const { items, requestSort, sortConfig } = useSortableData(requests);

  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const { itemsCode, requestSortCode, sortConfigCode } = useSortableDataCode(
    skuRequest
  );
  const getClassNamesForCode = name => {
    if (!sortConfigCode) {
      return;
    }
    return sortConfigCode.key === name ? sortConfigCode.direction : undefined;
  };

  const [search, setSearch] = useState("");
  const [request_type, setRequest_type] = useState("");
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [checkTab, setCheckTab] = useState(0);
  const [loadCode, setLoadCode] = useState(true);
  const [changes, setChanges] = useState(true);
  const [searchCode, setSearchCode] = useState("");
  const [requestTypeCode, setRequestTypeCode] = useState("");

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    if (value === 0) {
      setCheckTab(0);
    }
    if (value === 1) {
      setCheckTab(1);
    }
    if (value === 2) {
      setCheckTab(2);
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });



  const initialChargeReception = () => {
    setChanges(false);
    const tempArrayURL = window.location.href.split('/');
    const tempModuleType = tempArrayURL[tempArrayURL.length-1];
    setmoduleType(tempModuleType);
    const group = tempModuleType === "storage" ? "Insumos" : "Medicamentos";
    const fraccionamiento = api.get("api/ingresos/fraccionamiento");
    Promise.all([fraccionamiento])
      .then(response => {
        setRequests(response[0].data);

      })

  };

  let searchOrders = items.filter(order => {
    return (
      (order.id
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1 ||
        order.id_producto_fraccionar.grupo_producto
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        order.id_producto_fraccionar.descripcion_producto
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        order.id_producto_fraccionar.SKU.toLowerCase().indexOf(
          search.toLowerCase()
        ) !== -1 ||
        order.cantidad_fraccionar
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        order.id_producto_resultante.SKU.toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        order.cantidad_resultante
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        order.observaciones.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        order.fecha_solicitud.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        order.estado.toLowerCase().indexOf(search.toLowerCase()) !== -1) &&
      order.id_producto_fraccionar.grupo_producto
        .toLowerCase()
        .indexOf(request_type.toLowerCase()) !== -1
    );
  });

  let codeSearch = itemsCode.filter(order => {
    return (
      (order.id
        .toString()
        .toLowerCase()
        .indexOf(searchCode.toLowerCase()) !== -1 ||
        order.grupo.toLowerCase().indexOf(searchCode.toLowerCase()) !== -1 ||
        order.descripcion.toLowerCase().indexOf(searchCode.toLowerCase()) !==
          -1 ||
        order.sku_sugerido.toLowerCase().indexOf(searchCode.toLowerCase()) !==
          -1 ||
        order.fecha_solicitud
          .toLowerCase()
          .indexOf(searchCode.toLowerCase()) !== -1) &&
      order.grupo.toLowerCase().indexOf(requestTypeCode.toLowerCase()) !== -1
    );
  });

  const loadFunctionCode = () => {
    if (loadCode) {
      setLoadCode(false);
      callCode();
    }
  };

  const callCode = () => {

    const code = api.get("api/solicitudsku/");
    Promise.all([code])
      .then(response => {
        setSkuRequest(response[0].data);

      })

  };

  return (
    <GridContainer>
      <ViewTitle
        title="FRACCIONAMIENTO Y CÓDIGOS DE PRODUCTOS"
        message="En esta sección podrás revisar los fraccionamientos y códigos de productos."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/income/tasks/${moduleType}`}>
              {`Tareas de ${moduleType === "storage" ? "Ingreso" : "Ingreso Farmacia"}`}
            </Link>
            <Typography color="textPrimary">
              Fraccionamiento y código de productos
            </Typography>
            {checkTab === 0 ? (
              <Typography color="textPrimary">Fraccionamiento</Typography>
            ) : checkTab === 1 ? (
              <Typography color="textPrimary">Códigos de productos</Typography>
            ) : null}
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="mt-3">
              <GridContainer justify="center">
                <RequestsInformation />
              </GridContainer>
              {checkTab === 1 ? loadFunctionCode() : null}
              {checkTab === 0 ? (
                <TextField
                  id="outlined-basic"
                  label="Buscar"
                  variant="outlined"
                  value={search}
                  className="mt-1"
                  onChange={e => setSearch(e.target.value.substr(0, 20))}
                />
              ) : (
                <TextField
                  id="outlined-basic"
                  label="Buscar código"
                  variant="outlined"
                  value={searchCode}
                  className="mt-1"
                  onChange={e => setSearchCode(e.target.value.substr(0, 20))}
                />
              )}
            </GridContainer>
            <CardBody className="mb-1">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  centered
                >
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Fraccionamiento"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Códigos de productos"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <TableDivisionDivision
                  searchOrders={searchOrders}
                  requestSort={requestSort}
                  getClassNamesFor={getClassNamesFor}
                  request_type={request_type}
                  setRequest_type={setRequest_type}
                  moduleType={moduleType}
                />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <TableProductCodeDivision
                  codeSearch={codeSearch}
                  requestSortCode={requestSortCode}
                  getClassNamesForCode={getClassNamesForCode}
                  requestTypeCode={requestTypeCode}
                  setRequestTypeCode={setRequestTypeCode}
                  moduleType={moduleType}
                />
              </TabPanel>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
