import React from "react";

const DetailBoolean = props => {
  return(
    <div className="col-md-4 mb-3">
      <label className="text-dark">{props.name}</label>
      <div className="card bg-light font-weight-lighter" style={{height: "38px"}}>
        {props.field ?
          <input 
            type="checkbox" 
            className="form-control ml-1 mr-1" 
            value={props.field}
            style={{color: "#000000"}}
            checked
            disabled
          />
        :
        <input 
          type="checkbox" 
          className="form-control ml-1 mr-1" 
          value={props.field}
          disabled
        />
        }
      </div>
    </div>
  );
}

export default DetailBoolean;