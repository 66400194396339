import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {
    ArrowForward,
  } from "@material-ui/icons/";
import {classStyle} from "../../utils/styleFunction";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
    green,
  } from "@material-ui/core/colors";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function ModalEditBenefit({
    open,
    handleClose,
    row,
    handleUpdateRow,
}) {
    const classes = useStyles();
    const classesStyle = classStyle();
    const [value, setvalue] = useState(row.valores.valor_modificado);

    const handleOnChange = (value, set) => {
        let tempValue =  isNaN(value) ? 0 : value;
        set(tempValue);
    };

    const handleUpdate = () => {
        handleUpdateRow(row, value);
        handleClose();
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesStyle.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Paper  className={classesStyle.paper}  style={{maxHeight: "85%", overflow: "auto"}}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Typography variant="h5" gutterBottom>{`Editar registro`}</Typography>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={12}
                        style={{margin: "10px 0"}}
                    >
                        <Typography variant="body2" gutterBottom>{`Item: ${row.item}`}</Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0"}}
                    >
                        <Grid
                            item
                            xs={5}
                            style={{margin: "10px 0"}}
                        >
                            {row.tipo_valor === "$" ?
                            <DetailNumber
                                isdisabled={true}
                                label={`Valor original`}
                                value={row.valores.valor_inicial}
                                prefix={"$"}
                            />:
                            <DetailNumber
                                isdisabled={true}
                                label={`Valor original`}
                                value={row.valores.valor_inicial}
                            />
                            }

                        </Grid>
                        <Grid
                            item
                            xs={2}
                            style={{margin: "10px 0"}}
                        >
                            <Button
                                justIcon
                                round
                                color="primary"
                            >
                                <ArrowForward className={classes.icons} />
                            </Button>

                        </Grid>

                        <Grid
                            item
                            xs={5}
                            style={{margin: "10px 0"}}
                        >
                            {row.tipo_valor === "$" ?
                            <DetailNumber
                                label={`Valor modificado`}
                                value={value}
                                onchange={e => handleOnChange(
                                    parseInt(e.target.value.split('$').join('').split(',').join('')),
                                    setvalue)
                                }
                                prefix={"$"}
                                thousandSeparator={true}
                            />:
                            <DetailNumber
                                label={`Valor modificado`}
                                value={value}
                                onchange={e => handleOnChange(
                                    parseInt(e.target.value.split('$').join('').split(',').join('')),
                                    setvalue)
                                }
                                thousandSeparator={true}
                            />
                            }
                        </Grid>

                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        item
                        xs={12}
                        style={{margin: "10px 0 0 0"}}
                    >
                        {(value) ?
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            style={{backgroundColor: green[500]}}
                            onClick={handleUpdate}
                        >
                            Guardar
                        </Button> :
                        <Button
                            disabled
                            mr={2}
                            variant="contained"
                            className="text-white"
                        >
                            Guardar
                        </Button>
                        }
                        <Button
                            mr={2}
                            variant="contained"
                            className="text-white"
                            color="primary"
                            onClick={handleClose}
                        >
                            Cerrar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
}