import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import OrderDetailInfo from "components/WareHouse/Orders/OrderDetail";
import Button from "@material-ui/core/Button";
import ModalAddPicking from "./ModalAddPicking";
import ModalReplaceSKU from "./ModalReplaceSKU";
import ModalSummary from "./ModalSendPending";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Grid from "@material-ui/core/Grid";
import TableWaitToOutcome from "./TableWaitToOutcome";



export default function AddFile() {
  const { id } = useParams();
  const [moduleType, setmoduleType] = useState("");
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [modalAddPicking, setModalAddPicking] = useState(false);

  const id_producto = "";

  const [modalEditSupply, setModalEditSupply] = useState(false);
  const [modalSend, setModalSend] = useState(false);

  const [observation, setObservation] = useState(null);
  const [file, setFile] = useState(null);


  useEffect(() => {
    const tempArrayURL = window.location.href.split("/");
    const tempModuleType = tempArrayURL[tempArrayURL.length - 2];
    setmoduleType(tempModuleType);
    const group = tempModuleType === "storage" ? "Insumos" : "Medicamentos";
    api
      .get(`/api/v2/bodega/pedidos/egresos/grupo/${group}/envio/${id}/detalle/`)
      .then((result) => {
        setLoading(true);
        setProducts(result.data);
      });
    setLoading(false);
  }, [id]);

  function saveFile() {

    const formData = new FormData();
    formData.append(
      "nombre",
      `Guía de despacho caja ${products[0].numero_pedido}`
    );
    formData.append("tipo_archivo", "GuiaDespacho");
    formData.append("archivo", file);
    formData.append("observacion", observation);
    formData.append("area", "Bodega");
    formData.append(
      "id_pedidos",
      products[0].id_producto_pedido.id_solicitud_pedido.id
    );

    const formData2 = new FormData();

    formData2.append(
      "numero_pedido",
      products[0].id_envio_pedido.numero_pedido
    );
    formData2.append("estado_bodega", "Finalizado");
    formData2.append("estado", products[0].id_envio_pedido.estado);

    apiform
      .post("api/documentospedidos/", formData)
      .then((response) => {
        apiform
          .put(`api/enviospedidos/${products[0].id_envio_pedido.id}`, formData2)
          .then((response) => {
            window.location.href = `/admin/warehouse/orders/${moduleType}`;
          })
          .catch((error) => console.log(error.reponse));
      })

  }

  return (
    <GridContainer>
      <ViewTitle
        title="ADJUNTAR GUÍA DE DESPACHO"
        message="En esta sección podrás la guía de despacho para tu pedido."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" href={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link
              color="inherit"
              href={`/admin/warehouse/orders/${moduleType}`}
            >
              Pedidos
            </Link>
            <Typography color="textPrimary">Adjuntar archivo</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <OrderDetailInfo
                getApi={false}
                idSolicitud={
                  products[0]?.id_producto_pedido.id_solicitud_pedido.id
                }
                tipoSolicitud={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .tipo_solicitud
                }
                paciente={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .nombre_paciente
                }
                fechaSolicitud={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .fecha_solicitud
                }
                fechaDespacho={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .fecha_despacho
                }
                esRegion={
                  products[0]?.id_producto_pedido.id_solicitud_pedido.es_region
                }
                estadoPicking={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .estado_picking
                }
                tieneMedicamentos={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .tiene_medicamentos
                }
                estadoSolicitud={
                  products[0]?.id_producto_pedido.id_solicitud_pedido
                    .estado_solicitud
                }
              />
            </GridContainer>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <TableWaitToOutcome products={products} />
            </GridContainer>
            <CardBody>
              <GridContainer justify="center" className="m-3 mt-3 mb-3">
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <h5>Adjuntar guía de despacho</h5>
                </Grid>
                <Grid container xs={12} style={{ textAlign: "center" }}>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <DetailTextArea
                      label="OBSERVACIONES:"
                      placeholder="Observaciones"
                      value={observation}
                      maxlength="500"
                      onchange={(e) => {
                        setObservation(e.target.value);
                      }}
                    />
                    {observation === "" ? (
                      <span className="text-danger">
                        Debes ingresar una observación
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </GridContainer>
              <GridContainer
                justify="center"
                style={{ textAlign: "center" }}
                className="m-3 mt-3 mb-3"
              >
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <span className="card-title">ARCHIVO:</span>
                      <p className="card-text">
                        Archivo: {file ? file.name : null}
                      </p>
                      <span className="btn btn-cmh-color btn-file">
                        Seleccionar
                        <input
                          type="file"
                          name={file}
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  {file === null ? (
                    <span className="text-danger">
                      Debes ingresar un archivo
                    </span>
                  ) : null}
                </div>
              </GridContainer>
              <GridContainer justify="center" className="mt-3 mb-3">
                {observation !== "" && file != null ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveFile}
                    className="m-1"
                  >
                    Guardar
                  </Button>
                ) : null}
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modalAddPicking && (
        <ModalAddPicking
          open={modalAddPicking}
          closeModal={() => setModalAddPicking(false)}
          id_producto={id_producto}
          id_detalle={id_producto}
          moduleType={moduleType}
        />
      )}
      {modalEditSupply && (
        <ModalReplaceSKU
          open={modalEditSupply}
          closeModal={() => setModalEditSupply(false)}
          id_producto={id_producto}
          id_detalle={id_producto}
          moduleType={moduleType}
        />
      )}
      {modalSend && (
        <ModalSummary
          open={modalSend}
          closeModal={() => setModalSend(false)}
          moduleType={moduleType}
        />
      )}
    </GridContainer>
  );
}
