import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ModalMessageSuccess(props) {
  const { open, message, moduleType } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const exit = () => {
    window.location.href = `/admin/warehouse/receptions/${moduleType}`;
  };

  return (
    <Dialog
      open={open}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">
        Éxito a guardar el registro
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form-row" style={{ marginBottom: "5rem" }}>
          {message}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={exit} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalMessageSuccess.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string
};
