import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Grid,
} from '@material-ui/core';
import {ButtonTextMUI as Button, CustomSelectAutoComplete} from "utils/componentsStyle";
import api from "utils/API";
import moment from "moment-timezone";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import MedicalTableComponent from "views/ClinicOrders/InsumosYMedicamentos/MedicalTableComponent";
import ResourceTableComponent from "views/ClinicOrders/InsumosYMedicamentos/ResourceTableComponent";
import ModalSummary from "views/PatientFile/InsumosYMedicamentos/ModalSummary";
import DialogDeleteRequest from "views/ClinicOrders/InsumosYMedicamentos/DialogDeleteRequest";
import UpdateStockButton from "views/Widgets/UpdateStockButton";

import {
  getListRequestPatient,
  getListSuppliesMedicines,
} from 'actions/getListAction';

import "moment/locale/es";
moment.locale("es");


const ModalRequestSuppliesMedicine = props => {
  const dispatch = useDispatch()
  const {
    openModal,
    closeModal,
    dataPatient,
    recharche,
  } = props;

  const [openModalSummary, setOpenModalSummary] = useState(false);
  const [company, setcompany] = useState({value: "CMH", label: "CMH"});
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [dataMedicalArray, setDataMedicalArray] = useState([]);
  const [dataResourceArray, setDataResourceArray] = useState([]);
  const [existValueNull, setExistValueNull] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [auxListMedical, setauxListMedical] = useState(null);
  const [auxListResource, setauxListResource] = useState(null);
  const [listStorage, setlistStorage] = useState([]);
  const [storage, setstorage] = useState([]);


  const [changes, setChanges] = useState(true);

  const listMedical = useSelector(state => state.currentList.listMedicines);
  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);
  const listCompany = useSelector(state => state.currentList.LISTA_EMPRESA);

  const type_area = localStorage.getItem("area_perfil");

  useEffect(() => {
    if(listMedical.length === 0) {
        getListSuppliesMedicines(dispatch, chargeMedicine)
    }
  }, [])

  useEffect(() => {
   
  }, [chargeMedicine])

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
    if (pageRefresh) {
      setPageRefresh(false);
    }
    if(dataMedicalArray.find(item => item.cantidad===undefined)===undefined &&  dataResourceArray.find(item => item.cantidad===undefined)===undefined){
      setExistValueNull(false)
    }else{
      setExistValueNull(true)
    }
  });

  const initialCharge = () => {
    setChanges(false);
  };


  const updateStock = () => {
    api.get("/api/poblarinsumos/").then(() => {
      getListSuppliesMedicines(dispatch, chargeMedicine, true);
    }).catch((error) => {
      console.log(error.response);
    });
  };

  const handlesumary = (state) => {
    closeModal()
    recharche()
  }

  const handleSelect = (value) => {
    setcompany(value)
    setstorage([])
    setDataMedicalArray([])
    setDataResourceArray([])
    if (value.value === "CMH"){
      setauxListMedical(null)
      setauxListResource(null)
      setPageRefresh(true)
    } else {
      api.get(`api/paciente/ficha/bodega/${value.value}`).then((response) => {
        setlistStorage(response.data)
        setauxListMedical([])
        setauxListResource([])
        setPageRefresh(true)
      })
    }
  }
  const handleStorage = (value) => {
    setstorage(value)
    const tempListMedical = []
    const tempLIstResource = []
    api.get(`api/stock/paciente/id/${value.value}/domicilio/`).then((response) => {
      response.data.map((row) => {
        const item = medicalResourceData[row.id_insumo_medicamento_id];
        if (item){
          const aux_row = { value: item.id, label: `(${item.SKU}) - (stock: ${row.total_unidades}) ${item.descripcion_producto} - valor (IVA): $${item.valor_con_iva}` }
          if (row.grupo_item === "medicamentos"){
            tempListMedical.push(aux_row)
          } else {
            tempLIstResource.push(aux_row)
          }
        }
      })
      setauxListMedical(tempListMedical)
      setauxListResource(tempLIstResource)
      setPageRefresh(true)
    })
  }


  const validatePermission = ["Gerencia", "Gestión Clinica"].includes(type_area)

  const _content_ = <div>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >

      {pageRefresh && <div></div>}
      {validatePermission &&
      <Grid item xs={6} >
        <Grid item xs={12} style={{padding: "10px 0px"}}>
        <CustomSelectAutoComplete
          value={company}
          onChange={(e) => handleSelect(e)}
          label="Empresa"
          listItem={listCompany}
        />
        </Grid>
        {company.value !== "CMH" &&
        <Grid item xs={12} style={{padding: "10px 0px"}}>
          <CustomSelectAutoComplete
            value={storage}
            onChange={(e) => handleStorage(e)}
            label="Bodega"
            listItem={listStorage}
          />
        </Grid>
        }
      </Grid>
      }
      <Grid item xs={12}>
      {!pageRefresh &&
        <MedicalTableComponent
          dataMedicalArray={dataMedicalArray}
          setDataMedicalArray={setDataMedicalArray}
          medicalResourceData={medicalResourceData}
          auxlistMedical={auxListMedical}
          setPageRefresh={setPageRefresh}
          initialCharge={false}
        />
      }
      </Grid>
      <Grid item xs={12}>
        <ResourceTableComponent
          dataResourceArray={dataResourceArray}
          setDataResourceArray={setDataResourceArray}
          medicalResourceData={medicalResourceData}
          auxlistResource={auxListResource}
          setPageRefresh={setPageRefresh}
          initialCharge={false}
        />
      </Grid>
     
      {openModalSummary && (
        <ModalSummary
          openModal={openModalSummary}
          closeModal={() => setOpenModalSummary(false)}
          settypeRequest={handlesumary}
          setOpenDialogDelete={setOpenDialogDelete}
          dataMedicalArray={dataMedicalArray}
          dataResourceArray={dataResourceArray}
          state="summary"
          storage={storage}
          dataPatient={dataPatient}
        />
      )}
      {openDialogDelete && (
        <DialogDeleteRequest
          openDialog={openDialogDelete}
          closeDialog={() => setOpenDialogDelete(false)}
        />
      )}
      <UpdateStockButton
        updateStock={updateStock}
      />

    </Grid>
  </div>

  const _actions_ = <>
    {dataMedicalArray.length > 0 ||
    dataResourceArray.length > 0 ? (
      <Button
        disabled={existValueNull}
        variant="contained"
        style={{
          backgroundColor: existValueNull===false ? "#43a047":'#dddddd',
          textTransform: "none",
          marginRight: "1em",
          color: "#fff",
        }}
        onClick={() => {
          setOpenModalSummary(true);
        }}
      >

        Solicitar
      </Button>
    ) : (
      <Button
        disabled
        variant="contained"
        color="primary"
        style={{
          textTransform: "none",
          marginRight: "1em",
        }}
      >
        Solicitar
      </Button>
    )}
  </>

  return (
  <>
    <ModalDialog
        open={openModal}
        onClose={closeModal}
        title={`Solicitar insumos y medicamentos`}
        _content_={_content_}
        _actions_ = {_actions_}
        maxWidth="lg"
    />
  </>
  );
};

export default ModalRequestSuppliesMedicine;

