import React from "react";
import PropTypes from "prop-types";
import "../../../../styles/Styles.css";

const DetailFileMultiple = props => {
  const { value } = props;
  let array = [...value];

  return (
    <div className="col-md-4 mb-3">
      <div className="card">
        <div className="card-body">
          <span className="card-title">{props.label}</span>
          <p className="card-text">
            Archivos:
            {array.map(file => (
              <p key={file}>{file.name}</p>
            ))}
          </p>
          <span className="btn btn-cmh-color btn-file">
            Seleccionar
            <input
              type="file"
              name={props.value}
              onChange={props.onchange}
              multiple
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default DetailFileMultiple;

DetailFileMultiple.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array,
  onchange: PropTypes.func
};
