import React from "react";
import Typography from "@material-ui/core/Typography";

export const Position = checkTab => {
  switch (checkTab) {
    case 0:
      return <Typography color="textPrimary">Fraccionamiento</Typography>;
    case 1:
      return <Typography color="textPrimary">Compras</Typography>;
    case 2:
      return <Typography color="textPrimary">Retiro mercaderías</Typography>;
    case 3:
      return <Typography color="textPrimary">Reportes</Typography>;
    default:
      return null;
  }
};
