import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
    Typography,
    TextField,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import TableComponent from "views/Widgets/Common/TableComponent";
import TabCommon from "views/Widgets/Common/TabCommon";
import {setmessageGeneral } from "actions/getGlobalAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default function ModalTabletLose({
    open,
    closeModal,
    rowInfo,
    setinitialCharge,
}) {
    console.log(rowInfo)
    const dispatch = useDispatch();
    const [initialData, setinitialData] = useState(false)
    const [listAsssigned, setlistAsssigned ] = useState([])


    useEffect(() => {
        setinitialData(true);
    }, []);
    
    useEffect(() => {
        if (initialData){
            api.get(`/api/paciente/${rowInfo.id_ficha.id_paciente.id}/solicitudes/traslados/${rowInfo.id_solicitud_paciente.id}/`).then((request) => {
                setlistAsssigned(request.data.asignaciones.map((row) => {
                    return [
                    {value: row.id},
                    {value: row.id_cargo_asignado__nombre_corto},
                    {value: row.id_usuarios__rut},
                    {value: moment(row.fecha_hora).format("YYYY-MM-DD hh:mm")},
                    {value: moment(row.fecha_final).format("YYYY-MM-DD hh:mm")},
                    ]
                }))
            }
            //     setlistAssistance(response.data["asistencia"])
            //     setlistComment(response.data["observaciones"])
            //     setinitialData(false)
            )
        }
      
    }, [initialData]);

    const header = [
        {label: "ID tarea"},
        {label: "Cargo asignado"},
        {label: "rut"},
        {label: "Fecha inicio"},
        {label: "Fecha fin"},
    ]

    const patientData = rowInfo.id_ficha.id_paciente
    const patientName = `${patientData.nombre} ${patientData.apellido_paterno} ${patientData.apellido_materno}`

    const _content_ = <div>
        <Grid 
            direction="row" 
            justify="center"
            alignItems="center"
            item xs={12} 
            style={{padding: "5px", width: "100%"}
        }>
            <Typography component="body2" variant="body2"  style={{fontWeight: 'bold'}} > 
                {`Se ha generado una alerta de Traslado para el paciente ${patientName}. Esta tarea debe ser atentida en los modulos de ruta o rotativa. Ademas se autocompleta al terminar finalizar la solicitud de traslado`}
            </Typography>
        </Grid>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <TableComponent 
                    headers={header}
                    data={listAsssigned}
                />
            </Grid>
        </Grid>
    </div>

    const _actions_ = <>
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: indigo[500]}}
            className="text-white"
            onClick={closeModal}
        >
            Cerrar
        </Button>
    </>




    return (
        <>
        <ModalDialog
            open={open}
            onClose={closeModal}
            title={`Asignación En curso`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="md"
        />
        </>

    );
}