import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import OrderDetailInfo from "components/WareHouse/Receptions/OrderDetail";
import Button from "@material-ui/core/Button";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function AddFile() {
  const classes = useStyles();
  const { id } = useParams();
  const id_order = parseInt(id, 10);
  const [moduleType, setmoduleType] = useState("");
  const [products, setProducts] = useState([]);
  const [supplies, setSupplies] = useState([]);
  const [loading, setLoading] = useState(false);


  const [observation, setObservation] = useState("");
  const [file, setFile] = useState(null);


  const [getFiles, setGetFiles] = useState([]);


  useEffect(() => {
    const tempArrayURL = window.location.href.split('/');
    setmoduleType(tempArrayURL[tempArrayURL.length-2]);
    api.get("api/productospedidos/").then(result => {
      setLoading(true);
      setProducts(result.data);
    });

    api.get("api/insumosmedicamento/").then(result => {
      setLoading(true);
      setSupplies(result.data);
    });
    api.get("api/documentosdevoluciones/").then(result => {
      setLoading(true);
      setGetFiles(
        result.data.filter(filtered => filtered.id_devoluciones === id_order)
      );
    });
    setLoading(false);
  }, [id]);


  function createReception() {

    const formData = new FormData();

    formData.append("nombre", file.name);
    formData.append("tipo_archivo", "GuiaDespacho");
    formData.append("archivo", file);
    formData.append("observacion", observation);
    formData.append("id_devoluciones", id);

    apiform
      .post("api/documentosdevoluciones/", formData)
      .then(response => {
        console.log(response.data);
        window.location.href = `/admin/warehouse/receptions/${moduleType}`;
      })

  }

  const colorGreen = {
    bgcolor: "#32CD32",
    borderColor: "text.primary",
    m: 1,
    border: 1,
    style: { width: "1.5rem", height: "1.5rem" }
  };
  const colorRed = {
    bgcolor: "#FF0000",
    borderColor: "text.primary",
    m: 1,
    border: 1,
    style: { width: "1.5rem", height: "1.5rem" }
  };
  const colorWhite = {
    bgcolor: "#FFFFFF",
    borderColor: "text.primary",
    m: 1,
    border: 1,
    style: { width: "1.5rem", height: "1.5rem" }
  };


  function NewTab(url) {
    window.open(url);
    return false;
  }

  return (
    <GridContainer>
      <ViewTitle
        title="ADJUNTAR ARCHIVO"
        message="En esta sección podrás el archivo de recepción."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link color="inherit" to={`/admin/warehouse/receptions/${moduleType}`}>
              Recepción de ingresos y devoluciones
            </Link>
            <Typography color="textPrimary">Adjuntar archivo</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <OrderDetailInfo id={id_order} />
            </GridContainer>
            <CardBody>
              <GridContainer justify="center" className="m-3 mt-3 mb-3">
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      {getFiles.map(file => (
                        <a
                          key={file.id}
                          onClick={() => NewTab(file.archivo)}
                          className="btn btn-cmh-color text-white"
                        >
                          {file.nombre}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <Grid container xs={12} style={{ textAlign: "center" }}>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <DetailTextArea
                      label="OBSERVACIONES:"
                      placeholder="Observaciones"
                      value={observation}
                      maxlength="500"
                      onchange={e => {
                        setObservation(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </GridContainer>
              <GridContainer
                justify="center"
                style={{ textAlign: "center" }}
                className="m-3 mt-3 mb-3"
              >
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <span className="card-title">ARCHIVO:</span>
                      <p className="card-text">Archivo: {file ? file.name : null}</p>
                      <span className="btn btn-cmh-color btn-file">
                        Seleccionar
                        <input
                          type="file"
                          name={file}
                          onChange={e => {
                            setFile(e.target.files[0]);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </GridContainer>
              <GridContainer justify="center" className="mt-3 mb-3">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={createReception}
                  className="m-1"
                >
                  Guardar
                </Button>
                <Link to={`/admin/warehouse/receptions/${moduleType}`}>
                  <Button variant="contained" color="primary" className="m-1">
                    Cancelar solicitud
                  </Button>
                </Link>
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
