import React, { useState } from 'react'
import { TypographyMui } from 'utils/componentsStyle';

import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import moment from "moment-timezone";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "moment/locale/es";
moment.locale("es");

const localizer = momentLocalizer(moment);

const useStyles = makeStyles({
  upperCase: {
    textTransform: 'uppercase',
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  }
})

  function WorkTeamCalendar({ open, workTeamData, chargeColor }) {
  const classes = useStyles()
  const [selectDate, setselectDate] = useState(moment())
  const [calendarView, setCalendarView] = useState("month")

  const handleNavigation = (date) => {
    setselectDate(moment(date))
  }

  const eventColors = (event) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };

  const handleOnMore = (event) => {
    setselectDate(moment(event[0]?.start));
    setCalendarView("week");
  };

  const CustomToolbar = () => {
    return (
      <Box mt={3}>
        <Box display='flex' justifyContent='center'>
          <Button variant='contained' color={calendarView === 'month' ? 'primary' : 'default'} onClick={() => setCalendarView("month")}>
            Mensual
          </Button>
          <Button variant='contained' color={calendarView === 'month' ? 'default' : 'primary'} onClick={() => setCalendarView("week")}>
            Semanal
          </Button>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          {calendarView === 'month' ?
            <TypographyMui className='mr-3' variant='h4'>
              <strong className={classes.upperCase}>{moment(selectDate).format('MMMM')}</strong> {moment(selectDate).format('YYYY')}
            </TypographyMui>
            :
            <TypographyMui className='mr-3' variant='h4'>
              {`${moment(selectDate).format('MMMM')} ${moment(selectDate).startOf("week").format("DD")} - ${moment(selectDate).endOf("week").format("DD")}`}
            </TypographyMui>
          }

          <Box display='flex' justifyContent='flex-end' mt={1.5}>
            <IconButton className='mr-2' onClick={() => handleNavigation(moment(selectDate).subtract(1, calendarView))}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton className='mr-2' onClick={() => handleNavigation(moment())}>
              <TypographyMui className='mr-3' variant='buttton'>
                hoy
              </TypographyMui>
            </IconButton>
            <IconButton className='mr-2' onClick={() => handleNavigation(moment(selectDate).add(1, calendarView))}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box display={open === 1 ? 'block' : 'none'}>
      <Calendar
        selectable
        localizer={localizer}
        events={workTeamData.tareas.filter((row) => (row.end != null ))}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventColors}
        view={calendarView}
        // defaultDate={moment().toDate()}
        date={selectDate.toDate()}
        step={30}
        timeslots={1}
        components={{
          toolbar: CustomToolbar,
        }}
      //style={{ height: 500 }}
        onShowMore={(event) => handleOnMore(event)}
      //onSelectEvent: fire when an event in clicked
      //onSelectEvent={event => { console.log('event') }}
      //onSelectSlot: fire when a day chart in clicked
      //onSelectSlot={slotInfo => { console.log('slot') }}
      //onView={(view) => setcalendarView(view)}
      // messages={{
      //   next: "Siguiente",
      //   previous: "Anterior",
      //   today: "Hoy",
      //   month: "Mes",
      //   week: "Semana",
      //   day: "Día",
      //   agenda: false,
      // }}
      //onNavigate={(date) => handleNavigation(date)}
      // min={moment(moment().format("YYYY-MM-DD")).add(8,"hours").toDate()}
      // max={moment(moment().format("YYYY-MM-DD")).add(23,"hours").add(30, "minutes").toDate()}
      />
    </Box>
  )
}

export default WorkTeamCalendar
