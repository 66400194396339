import React, { useState, useEffect, useRef } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useReactToPrint } from "react-to-print";
import {
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    Grid,
    Divider,
    TextField,
} from "@material-ui/core";
import {
    CustomTab,
    CustomTabs,
    CustomTabPanel,
    Root,
    TypographyMui,
} from "utils/componentsStyle"
import {classStyle} from "utils/styleFunction";
import GenerationPrescriptionGeneric from "components/PatientFile/GenerationPrescriptionGeneric";
import {
    Close,
} from '@material-ui/icons/';
import {
    getListSuppliesMedicines,
    getDictMedicinesPrescription,
    getListRequestPatient,
} from "actions/getListAction";
import {setmessageGeneral} from "actions/getGlobalAction";
import { green, indigo, orange  } from "@material-ui/core/colors";
import api from "utils/API";
import TabsMedicines from "components/PatientFile/TabsMedicines";
import ModalAddMedicine from "components/PatientFile/ModalAddMedicine";
import ModalDeleteMedicine from "components/PatientFile/ModalDeleteMedicine";
import ModalMessageGenPrescription from "components/PatientFile/ModalMessageGenPrescription";
import moment from 'moment-timezone';

import 'moment/locale/es' ;
moment.locale("es");


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalGenPrescription({
    open,
    closeModal,
    dataPatient,
    requestInfo,
    editable=true,
    style={}
}) {

    const classesStyle = classStyle();
    const dispatch = useDispatch();
    const [generalInstruction, setgeneralInstruction] = useState("");
    const [exam, setexam] = useState("");
    const [derivation, setderivation] = useState("");
    const [diagnosis, setdiagnosis] = useState("");
    const [prescriptionsTabs, setprescriptionsTabs] = useState(0);
    const [medicineListSimple, setmedicineListSimple] = useState([]);
    const [medicineListCheck, setmedicineListCheck] = useState([]);
    const [medicineListHeld, setmedicineListHeld] = useState([]);
    const [userData, setuserData] = useState(null);
    const limitChar = 4000;
    const [openModalDeleteMedicine, setopenModalDeleteMedicine] = useState(null);
    const [openModalMessageGenPrescription, setopenModalMessageGenPrescription] = useState(null);
    const [openAddMedicine, setopenAddMedicine] = useState(false);
    const [recharge, setrecharge] = useState(false)

    const listMedicines = useSelector(state => state.currentList.listMedicines);
    const dictMedicinesPrescription = useSelector(state => state.currentList.dictMedicinesPrescription);
    const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

    useEffect(() => {
        if (listMedicines.length === 0){
            getListSuppliesMedicines(dispatch, chargeMedicine)
        }
        if (Object.keys(dictMedicinesPrescription).length === 0){
            getDictMedicinesPrescription(dispatch)
        }
        const usuario_id = editable ? localStorage.getItem("user_id") : requestInfo.id_persona_solicitante;
         api.get(`api/clinica/solicitudes/obtener/datos/especialista/${usuario_id}`).then((response) => {
            setuserData(response.data);
        }).catch((error) => {
            setmessageGeneral(dispatch, error?.response?.data?.error, "error", "¡Atencion!");
            closeModal();

        });
        if(!editable){
            api.get(`api/clinica/solicitudes/obtener/recetamedica/${requestInfo.id}`).then((response) => {
                setmedicineListSimple(response.data["lista_medicamentos"].filter((row) => (row.tipo_receta_medicamento === "Simple")));
                setmedicineListCheck(response.data["lista_medicamentos"].filter((row) => (row.tipo_receta_medicamento === "Cheque/Controlada")));
                setmedicineListHeld(response.data["lista_medicamentos"].filter((row) => (row.tipo_receta_medicamento === "Digital")));
                const prescriptionData = response.data["receta"];
                setderivation(prescriptionData["derivacion"]);
                setexam(prescriptionData["examenes"]);
                setdiagnosis(prescriptionData["diagnostico"]);
                setgeneralInstruction(prescriptionData["instrucciones_generales"]);
                setrecharge(true);
            }).catch((error) => {
                setmessageGeneral(dispatch, error?.response?.data?.error, "error", "¡Atencion!")
            });
        }
    }, []);

    useEffect(() => {
        if(recharge){
            setrecharge(false)
        }
    })

    var auxMedicineListSimple = medicineListSimple.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/14)
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray
    }, []);

    var auxMedicineListCheck = medicineListCheck.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/14)
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray
    }, []);

    var auxMedicineListHeld = medicineListHeld.filter((row) => (row.tipo_receta_medicamento === "Digital")).reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/14)
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, []);

    const handlePrescriptionDownload = () => {
        setopenModalMessageGenPrescription(false);
        const tempMedicineListSimple = medicineListSimple.filter((row) => !row.delete )
        const tempMedicineListCheck = medicineListCheck.filter((row) => !row.delete )
        const tempMedicineListHeld = medicineListHeld.filter((row) => !row.delete )
        const tempList = [
            ...tempMedicineListSimple,
            ...tempMedicineListCheck,
            ...tempMedicineListHeld,
        ];
        setmedicineListSimple(tempMedicineListSimple);
        setmedicineListCheck(tempMedicineListCheck);
        setmedicineListHeld(tempMedicineListHeld);
        setTimeout(() => {
            handlePrint1();
        },100);

        if (editable){
            const body={
                medicamentos: tempList,
                id_paciente: dataPatient.id,
                instrucciones_generales: generalInstruction,
                examenes: exam,
                derivacion: derivation,
                diagnostico: diagnosis,
            }
            api.post(`api/clinica/solicitudes/generar/RecetaMedica/`, body).then((response) => {
                const message = `Para volver a ver la receta medica debe revisar la solicitud ${response.data.id} del paciente en finalizados`
                setmessageGeneral(dispatch, message, "success", "!Receta medica generada!" )
                getListRequestPatient(dataPatient, dispatch);
                closeModal();
            }).catch((error) => {
                setmessageGeneral(dispatch, error?.response?.data?.error, "error", "¡Atencion!", )
            });
        }
    };

    const handleSaveMedicine = (
        selectMedicine,
        selectTypeQuantity,
        selectTotalTypeQuantity,
        selectFrequency,
        medicineText,
        valueQuantity,
        valueTotalQuantity,
        valueFrequency,
        startDate,
        endDate,
        typeRecipe,
        magistral,
    ) => {
        const body = {
            id_insumo_medicamento_id: selectMedicine.value,
            medicamento_descripcion: selectMedicine.label,
            medicamento_texto: medicineText,
            tipo_cantidad: selectTypeQuantity.value,
            cantidad: valueQuantity,
            tipo_cantidad_total: selectTotalTypeQuantity.value,
            cantidad_total: valueTotalQuantity,
            frecuencia: selectFrequency.value,
            valor_frecuencia: valueFrequency,
            fecha_inicio: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
            fecha_fin: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
            tipo_receta_medicamento: typeRecipe,
            magistral: magistral,
        }
        const tempList = typeRecipe === "Simple" ?
            medicineListSimple :
            typeRecipe === "Cheque/Controlada" ?
            medicineListCheck :
            medicineListHeld
        tempList.push(body);
        console.log(tempList);
        console.log(typeRecipe);
        if (typeRecipe === "Simple"){
            setmedicineListSimple(tempList)
        } else if (typeRecipe === "Cheque/Controlada"){
            setmedicineListCheck(tempList)
        } else {
            setmedicineListHeld(tempList);
        }

        setopenAddMedicine(false);
        setrecharge(true);
    };

    const handleDeleteMedicine = () => {
        const tempMedicineList = [...openModalDeleteMedicine.typeTable === "Simple" ?
            medicineListSimple :
            openModalDeleteMedicine.typeTable === "Cheque/Controlada" ?
            medicineListCheck :
            medicineListHeld
        ]

        const tempItem = tempMedicineList[openModalDeleteMedicine.index];
        if (tempItem["delete"]){
            tempItem["delete"] = false;
        } else {
            tempItem["delete"] = true;
        }
        tempMedicineList[openModalDeleteMedicine.index] = tempItem;
        if (openModalDeleteMedicine.typeTable === "Simple"){
            setmedicineListSimple(tempMedicineList)
        } else if (openModalDeleteMedicine.typeTable === "Cheque/Controlada"){
            setmedicineListCheck(tempMedicineList)
        } else {
            setmedicineListHeld(tempMedicineList);
        }
        setopenModalDeleteMedicine(null);
        setrecharge(true);
    };

    const handleValidateText = (value, set) => {
        if (value.length < 4000){
            set(value)
        }
    };

    const componentRef = useRef();

    const handlePrint1 = useReactToPrint({
        content: () => componentRef.current
    });

    console.log("simple",medicineListSimple);
    console.log("cheque",medicineListCheck);
    console.log("retenido",medicineListHeld);

    return (
        <div>
            <Dialog
                fullScreen={false}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={"lg"}
                style={{...style}}
            >
                <DialogTitle className={classesStyle.dialogHeader}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        <TypographyMui className={classesStyle.colorWhite} variant='body2'>{"GENERAR RECETAS"}</TypographyMui>
                        <IconButton type='reset' onClick={closeModal}>
                            <Close className={classesStyle.colorWhite} />
                        </IconButton>
                    </Grid>

                </DialogTitle>
                <DialogContent className={classesStyle.dialogContent}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    {recharge && <div></div>}
                    <CustomTabs
                        value={prescriptionsTabs}
                        onChange={(event, newValue) => setprescriptionsTabs(newValue)}
                        variant="fullWidth"
                        aria-label="tab_"
                    >
                        <CustomTab label="INDICACIONES GENERALES" />
                        <CustomTab label="MEDICAMENTOS" />
                        <CustomTab label="EXÁMENES" />
                        <CustomTab label="DERIVACIÓN" />
                    </CustomTabs>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                    >
                        <Root>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                item
                                xs={12}
                                style={{margin: "60px 0 20px 0"}}
                            >
                                <TypographyMui style={{color: indigo[500]}}
                                    variant='subtitle1'
                                >
                                    {"Diagnóstico"}
                                </TypographyMui>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-start"
                                item
                                xs={12}
                            >
                                <TextField
                                    id="diagnostico"
                                    multiline
                                    rows={8}
                                    value={diagnosis}
                                    variant="outlined"
                                    onChange={(event) => handleValidateText(event.target.value, setdiagnosis)}
                                    style={{width: "100%"}}
                                    disabled={!editable}
                                />
                                <TypographyMui style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
                                    {`${diagnosis.length}/${limitChar}`}
                                </TypographyMui>
                            </Grid>
                            <Divider
                                style={{
                                border: "1px solid #303F9F",
                                margin: "40px 0"
                            }}/>
                        <CustomTabPanel  value={prescriptionsTabs} index={0}>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                item
                                xs={12}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    item
                                    xs={12}
                                    style={{margin: "0 0 20px 0"}}
                                >
                                    <TypographyMui style={{color: indigo[500]}}
                                        variant='subtitle1'
                                    >
                                        {"Indicaciones generales"}
                                    </TypographyMui>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="flex-start"
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        id="genera_instruction"
                                        multiline
                                        rows={8}
                                        value={generalInstruction}
                                        variant="outlined"
                                        onChange={(event) => handleValidateText(event.target.value, setgeneralInstruction)}
                                        style={{width: "100%"}}
                                        disabled={!editable}
                                    />
                                    <TypographyMui style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
                                        {`${generalInstruction.length}/${limitChar}`}
                                    </TypographyMui>
                                </Grid>
                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel  value={prescriptionsTabs} index={1}>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                item
                                xs={12}
                            >
                                {editable &&
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    item
                                    xs={12}
                                    style={{margin: "0 0 20px 0"}}
                                >
                                    <Button
                                        style={{
                                            background: green[500],
                                            color: green[50],
                                            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
                                        }}
                                        onClick={() => setopenAddMedicine(true)}
                                    >
                                        AGREGAR MEDICAMENTOS
                                    </Button>
                                </Grid>
                                }
                               <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    item
                                    xs={12}
                                >
                                    {((!editable && (
                                        medicineListSimple.length > 0 || medicineListCheck.length > 0 || medicineListHeld.length > 0)
                                    ) || editable) &&
                                    <TabsMedicines
                                        medicineListSimple={medicineListSimple}
                                        medicineListCheck={medicineListCheck}
                                        medicineListHeld={medicineListHeld}
                                        recharge={recharge}
                                        editable={editable}
                                        handleDelete={setopenModalDeleteMedicine}
                                    />
                                    }
                                </Grid>

                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel  value={prescriptionsTabs} index={2}>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                item
                                xs={12}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    item
                                    xs={12}
                                    style={{margin: "0 0 20px 0"}}
                                >
                                    <TypographyMui style={{color: indigo[500]}}
                                        variant='subtitle1'
                                    >
                                        {"Exámenes"}
                                    </TypographyMui>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="flex-start"
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        id="exam"
                                        multiline
                                        rows={8}
                                        value={exam}
                                        variant="outlined"
                                        onChange={(event) => handleValidateText(event.target.value, setexam)}
                                        disabled={!editable}
                                        style={{width: "100%"}}
                                    />
                                    <TypographyMui style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
                                        {`${exam.length}/${limitChar}`}
                                    </TypographyMui>
                                </Grid>
                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel  value={prescriptionsTabs} index={3}>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                item
                                xs={12}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    item
                                    xs={12}
                                    style={{margin: "0 0 20px 0"}}
                                >
                                    <TypographyMui style={{color: indigo[500]}}
                                        variant='subtitle1'
                                    >
                                        {"Derivación"}
                                    </TypographyMui>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="flex-start"
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        id="derivacion"
                                        multiline
                                        rows={8}
                                        value={derivation}
                                        variant="outlined"
                                        onChange={(event) => handleValidateText(event.target.value, setderivation)}
                                        style={{width: "100%"}}
                                        disabled={!editable}
                                    />
                                    <TypographyMui style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
                                        {`${derivation.length}/${limitChar}`}
                                    </TypographyMui>
                                </Grid>
                            </Grid>
                        </CustomTabPanel>
                        </Root>
                    </Grid>
                    {(editable )?
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{padding: "5px"}}
                    >
                        {   (
                                diagnosis &&
                                (
                                    exam ||
                                    derivation ||
                                    generalInstruction ||
                                    medicineListSimple.length > 0 ||
                                    medicineListCheck.length > 0 ||
                                    medicineListHeld.length > 0
                                )
                            ) ?
                        <Button
                            style={{
                                background: green[500],
                                color: green[50],
                                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
                            }}
                            onClick={() => setopenModalMessageGenPrescription(true)}
                        >
                            GENERAR RECETAS
                        </Button> :
                        <Button
                            disabled
                        >
                            GENERAR RECETAS
                        </Button>
                        }
                        <Grid item xs={12}>
                        {userData && !userData?.firma &&
                            <span style={{color: "#E31B0C"}}>{"Falta cargar firma del usuario en ficha de personal para poder generar receta. Favor comuníquese con Recursos Humanos"}</span>
                        }
                       </Grid>
                    </Grid>:
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{padding: "5px"}}
                    >
                        <Button
                            style={{
                                background: green[500],
                                color: green[50],
                                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
                            }}
                            onClick={handlePrescriptionDownload}
                        >
                            Descargar recetas
                        </Button>
                    </Grid>
                    }
                    {userData &&
                    <div style={{visibility: "hidden", height: "0px", margin: "-100000px 0 0 0"}}>
                        <GenerationPrescriptionGeneric
                            ref={componentRef}
                            infoArray={[generalInstruction, exam, derivation]}
                            dataPatient={dataPatient}
                            specialistInfo={userData}
                            medicineList={[auxMedicineListSimple, []]}
                            // auxMedicineListHeld
                        />
                    </div>
                    }
                 { openAddMedicine &&
                    <ModalAddMedicine
                        open={openAddMedicine}
                        handleSaveMedicine={handleSaveMedicine}
                        closeModal={() => setopenAddMedicine(false)}
                    />
                }
                { openModalDeleteMedicine !== null &&
                    <ModalDeleteMedicine
                        open={openModalDeleteMedicine !== null}
                        data={openModalDeleteMedicine}
                        closeModal={() => setopenModalDeleteMedicine(null)}
                        medicineList={
                            openModalDeleteMedicine.typeTable === "Simple" ?
                            medicineListSimple :
                            openModalDeleteMedicine.typeTable === "Cheque/Controlada" ?
                            medicineListCheck :
                            medicineListHeld
                        }
                        handleDelete={handleDeleteMedicine}
                    />
                }
                { openModalMessageGenPrescription &&
                <ModalMessageGenPrescription
                    open={openModalMessageGenPrescription}
                    closeModal={() => setopenModalMessageGenPrescription(null)}
                    handleGenerate={handlePrescriptionDownload}
                />

                }
                </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

