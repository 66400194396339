import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import SortIcon from "@material-ui/icons/Sort";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableDivisionDivision = props => {
  const classes = useStyles();
  const {
    searchOrders,
    requestSort,
    getClassNamesFor,
    request_type,
    setRequest_type,
    moduleType,
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const getGroupList = useSelector(state => state.currentList.LISTA_TIPO_PRODUCTO);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Id 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("id")}
                  className={getClassNamesFor("id")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Grupo 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={request_type}
                  onChange={e => setRequest_type(e.target.value.substr(0, 20))}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {getGroupList.map(list => (
                    <MenuItem key={list.value} value={list.value}>
                      {list.label}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                SKU 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("sku")}
                  className={getClassNamesFor("sku")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "250px" }}>
                Cantidad a fraccionar 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("cantidad_fraccionar")}
                  className={getClassNamesFor("cantidad_fraccionar")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                SKU final 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("sku_final")}
                  className={getClassNamesFor("sku_final")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "250px" }}>
                Cantidad resultante 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("cantidad_resultante")}
                  className={getClassNamesFor("cantidad_resultante")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Fecha solicitud 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("fecha_solicitud")}
                  className={getClassNamesFor("fecha_solicitud")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Estado 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("estado")}
                  className={getClassNamesFor("estado")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchOrders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">
                    {row.id_producto_fraccionar.grupo_producto}
                  </TableCell>
                  <TableCell align="center">
                    {row.id_producto_fraccionar.SKU}
                  </TableCell>
                  <TableCell align="center">
                    {row.cantidad_fraccionar}
                  </TableCell>
                  <TableCell align="center">
                    {row.id_producto_resultante.SKU}
                  </TableCell>
                  <TableCell align="center">
                    {row.cantidad_resultante}
                  </TableCell>
                  <TableCell align="center">{row.fecha_solicitud}</TableCell>
                  <TableCell align="center">
                    {row.estado === "Esperar a Ingresos"
                      ? "Responder solicitud"
                      : row.estado}
                  </TableCell>
                  <TableCell align="center">
                    <Link to={`/admin/income/division/${moduleType}/${row.id}`}>
                      <Button
                        style={{ textTransform: "none" }}
                        variant="contained"
                        color="primary"
                      >
                        Detalle
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={searchOrders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableDivisionDivision;

TableDivisionDivision.propTypes = {
  searchOrders: PropTypes.array,
  requestSort: PropTypes.func,
  getClassNamesFor: PropTypes.func,
  request_type: PropTypes.string,
  setRequest_type: PropTypes.func,
  moduleType: PropTypes.string,
};
