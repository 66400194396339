import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Select from "react-select";
import DetailText from "views/Widgets/FichaPersonal/ViewFichaPersonal/detailText";
import DetailMoney from "views/Widgets/FichaPersonal/ViewFichaPersonal/detailMoney";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import DetailBoolean from "views/Widgets/FichaPersonal/ViewFichaPersonal/detailBoolean";
import DetailTextArea from "views/Widgets/FichaPersonal/ViewFichaPersonal/detailTextArea";
import DetailFile from "views/Widgets/FichaPersonal/ViewFichaPersonal/detailFile";
import DetailFileContrato from "views/Widgets/FichaPersonal/ViewFichaPersonal/detailFileContrato";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withStyles } from "@material-ui/core/styles";
import api from "utils/API";
import apiform from "utils/APIForm";
import apilink from "utils/APIlink";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../styles/Styles.css"
import "styles/Styles.css";

const useStyles = makeStyles(styles);

const styles1 = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textTransform: "none"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const list_gerente = [
  { value: "wildo", label: "Wildo" },
  { value: "maria", label: "María" },
  { value: "chung", label: "Chung" },
];

const DialogTitle = withStyles(styles1)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const ViewPersonalFile = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles();

  const FileDownload = require("js-file-download");

  const { id } = useParams();
  const [contrato, setContrato] = useState();
  const [gerente, setGerente] = useState("");
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [sexo, setSexo] = useState("");

  const [estado_civil, setEstado_civil] = useState("");

  const [direccion_region, setDireccion_region] = useState("");
  const [get_region, setGet_region] = useState("");
  const [nacionalidad, setNacionalidad] = useState("");
  const [banco, setBanco] = useState("");
  const [tipo_cuenta_banco, setTipo_cuenta_banco] = useState("");
  const [nivel_estudios, setNivel_estudios] = useState("");
  const [estado_estudios, setEstado_estudios] = useState("");
  const [tipo_contrato, setTipo_contrato] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [id_cargo, setId_cargo] = useState();
  const [id_lista_afp, setId_lista_afp] = useState(1);
  const [get_afp, setGet_afp] = useState("");

  const [hoja_anexo, setHoja_anexo] = useState();

  const [get_contratos_anexos, setGet_contratos_anexos] = useState();

  const [area, setArea] = useState();
  const [jefatura, setJefatura] = useState();

  const [texto_anexo, setTexto_anexo] = useState();

  const id_request = props.location.id_request;
  const id_solicitud = props.location.id_solicitud;

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const get_cargo = useSelector(state => state.currentList.listCharges);
  const dictSexo = useSelector(state => state.currentList.DICCIONARIO_SEXO);
  const dictEstado_civil = useSelector(state => state.currentList.DICCIONARIO_ESTADO_CIVIL);
  const dictNacionalidad = useSelector(state => state.currentList.DICCIONARIO_NACIONALIDAD);
  const dictBanco = useSelector(state => state.currentList.DICCIONARIO_BANCO);
  const dictCuentaBanco = useSelector(state => state.currentList.DICCIONARIO_CUENTA_BANCO);
  const dictTipoEstudio = useSelector(state => state.currentList.DICCIONARIO_TIPO_ESTUDIOS);
  const dictEstadoEstudio = useSelector(state => state.currentList.DICCIONARIO_ESTADO_ESTUDIO);
  const dictTipoContrato = useSelector(state => state.currentList.DICCIONARIO_TIPO_CONTRATO);
  const dictEmpresa = useSelector(state => state.currentList.DICCIONARIO_EMPRESA);


  useEffect(() => {

    api.get("api/auxlistas/lista_region").then((result) => {
      setGet_region(result.data);
    });

    api.get("api/listaafp/").then((result) => {
      setGet_afp(result.data);
    });

    api.get("api/contratosanexosficha/").then((result) => {
      setGet_contratos_anexos(result.data);
    });
  }, []);

  useEffect(() => {
    api
      .get(`api/fichapersonal/${id}`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((result) => {
        setLoading(true);
        setSexo(dictSexo[result.data.sexo]?.label || "");
        setEstado_civil(dictEstado_civil[result.data.estado_civil]?.label || "");
        setDireccion_region(result.data.direccion_region);
        setNacionalidad(dictNacionalidad[result.data.nacionalidad]?.label || "");
        setBanco(dictBanco[result.data.banco]?.label || "");
        setTipo_cuenta_banco(dictCuentaBanco[result.data.tipo_cuenta_banco]?.label || "");
        setNivel_estudios(dictTipoEstudio[result.data.nivel_estudios]?.label || "");
        setEstado_estudios(dictEstadoEstudio[result.data.estado_estudios]?.label || "");
        setTipo_contrato(dictTipoContrato[result.data.tipo_contrato]?.label || "");
        setEmpresa(dictEmpresa[result.data.empresa]?.label || "");
        setId_cargo(result.data.id_cargo);
        setId_lista_afp(result.data.id_lista_afp);

        setContrato(result.data);
      });
    setLoading(false);
  }, [id]);

  useEffect(() => {

    for (let i = 0; i < get_region.length; i++) {
      if (direccion_region === get_region[i][0]) {
        setDireccion_region(get_region[i][1]);
      }
    }

    for (let i = 0; i < get_cargo.length; i++) {
      if (id_cargo === get_cargo[i].id) {
        setId_cargo(get_cargo[i].nombre);
        setArea(get_cargo[i].gerencia);
      }
    }

    for (let i = 0; i < get_afp.length; i++) {
      if (id_lista_afp === get_afp[i].id) {
        setId_lista_afp(get_afp[i].nombre);
      }
    }
  });

  const handleGerente = (gerente) => {
    setGerente(gerente);
  };

  const handleGenerarContrato = () => {
    window.open(`${apilink}api/contrato/${id}/${gerente.value}`, "_blank");
    api
      .put(`api/fichapersonal/${id}`, { status: "Esperando firma" })
      .then((response) => {})

  };


  const generateAnexo = () => {
    const formData = new FormData();
    formData.append("texto", texto_anexo);

    apiform
      .post(`api/personal/ficha/${id}/anexo/${gerente.value}/`, formData)
      .then((response) => {
        FileDownload(response.data, "anexo.pdf");
      })
      .catch((error) => {
        console.log("error response", error.response);
        alert(`ERROR: ${error.response.data}`);
      });
  };

  const uploadAnexo = (e) => {
    const formData = new FormData();

    formData.append("archivo", hoja_anexo);
    formData.append("id_ficha_personal", id);
    formData.append("nombre_archivo", hoja_anexo.name);
    formData.append("tipo", "Anexo");

    apiform
      .post("api/contratosanexosficha/", formData)
      .then((response) => {
        alert("Anexo subido exitosamente");
        window.location.href = `/admin/vistacontrato/${id}`;
      })
      .catch((error) => {
        console.log("error response", error.response);
        alert(`ERROR: ${error.response.data.Error}`);
      });
  };

  const id_ficha = parseInt(id, 10);


  return (
    <GridContainer>
      <ViewTitle
        title="Ver ficha personal"
        message="A continuación podrás ver en detalle esta ficha personal"
      />

      <GridItem
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Link
          to={{
            pathname: `/admin/personalfile/manage/${id_ficha}`,
            id_request: id_request,
            id_solicitud: id_solicitud,
          }}
        >
          <button className="btn btn-cmh-color">Gestionar</button>
        </Link>
      </GridItem>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Generar contrato
        </DialogTitle>
        <DialogContent dividers className="pb-4">
          <DialogContentText>
            Selecciona gerente para firmar contrato.
          </DialogContentText>
          <DialogContentText>
            <div className="col-md-10 mb-3">
              <Select
                value={gerente}
                onChange={handleGerente}
                options={list_gerente}
                placeholder="Gerente"
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
              />
            </div>
          </DialogContentText>

          <Typography gutterBottom>
            {gerente ? (
              <button
                onClick={() => handleGenerarContrato()}
                className="btn btn-cmh-color mt-5 mb-5"
              >
                Generar contrato
              </button>
            ) : (
              <button className="btn btn-cmh-color mt-5 mb-5" disabled>
                Generar contrato
              </button>
            )}
          </Typography>
        </DialogContent>
      </Dialog>

      {/*-------------------------------------- */}

      <Dialog
        onClose={handleClose2}
        aria-labelledby="customized-dialog-title"
        open={open2}
        className="p-5"
        fullWidth="true"
        maxWidth="md"
      >
        <DialogTitle style={{ textTransform: "none" }} id="customized-dialog-title" onClose={handleClose2}>
          Generar anexo
        </DialogTitle>
        <DialogContent dividers className="pb-4">
          <DialogContentText>
            Escriba el anexo y seleccione un gerente para firmar contrato.
          </DialogContentText>
          <DialogContentText>
            <div className="col-md-12 mb-3">
              <label className="text-dark">Anexo</label>
              <textarea
                className="form-control bg-light font-weight-lighter"
                maxLength="500"
                style={{ color: "#000000" }}
                value={texto_anexo}
                placeholder="Anexo"
                onChange={(e) => {
                  setTexto_anexo(e.target.value);
                }}
              />
            </div>
            <div className="col-md-4 mb-3">
              <Select
                value={gerente}
                onChange={handleGerente}
                options={list_gerente}
                placeholder="Gerente"
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
          </DialogContentText>

          <Typography gutterBottom>
            {texto_anexo && gerente ? (
              <button
                onClick={generateAnexo}
                className="btn btn-cmh-color mt-5 mb-5"
              >
                Generar anexo
              </button>
            ) : (
              <button className="btn btn-cmh-color mt-5 mb-5" disabled>
                Generar anexo
              </button>
            )}
          </Typography>
        </DialogContent>
      </Dialog>

      {loading ? (
        <div>
          <GridItem xs={12} sm={6} md={12}>
            <Card>
              <CardTitle title="DATOS PERSONALES" class={classes.cardTitle} />
              <GridItem
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <span>Estado de ficha personal: </span>{" "}
                <span className="text-uppercase">
                  <mark>
                    <strong>{contrato?.status}</strong>
                  </mark>
                </span>
              </GridItem>
              <CardBody>
                <div className="form-row">
                  <DetailText
                    name="APELLIDO PATERNO:"
                    field={contrato?.apellido_paterno}
                  />
                  <DetailText
                    name="APELLIDO MATERNO:"
                    field={contrato?.apellido_materno}
                  />
                  <DetailText name="NOMBRES:" field={contrato?.nombres} />
                  <DetailText name="RUT:" field={contrato?.rut} />
                  <DetailText name="SEXO:" field={sexo} />
                  <DetailText name="ESTADO CIVIL:" field={estado_civil} />
                  <DetailText
                    name="DIRECCIÓN:"
                    field={contrato?.direccion_calle}
                  />
                  <DetailText
                    name="NÚMERO:"
                    field={contrato?.direccion_numero}
                  />
                  <DetailText name="BLOCK:" field={contrato?.direccion_block} />
                  <DetailText
                    name="DEPARTAMENTO/CASA:"
                    field={contrato?.direccion_departamento}
                  />
                  <DetailText name="CIUDAD:" field={contrato?.ciudad} />
                  <DetailText
                    name="COMUNA:"
                    field={contrato?.direccion_comuna}
                  />
                  <DetailText name="REGIÓN:" field={direccion_region} />
                  <DetailText
                    name="CÓDIGO POSTAL:"
                    field={contrato?.codigo_postal}
                  />
                  <DetailText name="NACIONALIDAD:" field={nacionalidad} />
                  <DetailText
                    name="FECHA DE NACIMIENTO:"
                    field={contrato?.nacimiento}
                  />
                  <DetailText
                    name="TELÉFONO FIJO:"
                    field={contrato?.telefono_fijo}
                  />
                  <DetailText
                    name="TELÉFONO CELULAR:"
                    field={contrato?.telefono_celular}
                  />
                  <DetailText name="EMAIL:" field={contrato?.email} />
                  <DetailText name="EMAIL INSTITUCIONAL:" field={contrato?.email_institucional} />
                  <DetailText name="BANCO:" field={banco} />
                  <DetailText
                    name="NÚMERO DE CUENTA:"
                    field={contrato?.cuenta_banco}
                  />
                  <DetailText
                    name="TIPO DE CUENTA:"
                    field={tipo_cuenta_banco}
                  />
                  <DetailText
                    name="CCAF ANTERIOR:"
                    field={contrato?.ccaf_anterior}
                  />
                  <DetailText
                    name="NÚMERO DE ASIGNACIÓN FAMILIAR:"
                    field={contrato?.n_asignacion_familiar}
                  />
                  <DetailText name="ESTUDIOS:" field={nivel_estudios} />
                  <DetailText
                    name="ESTADO DE ESTUDIOS:"
                    field={estado_estudios}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={12}>
            <Card>
              <CardTitle
                title="CONDICIONES DE INGRESO"
                class={classes.cardTitle}
              />
              <CardBody>
                <div className="form-row">
                  <DetailText
                    name="FECHA DE INICIO CONTRATO:"
                    field={contrato?.fecha_inicio_contrato}
                  />
                  <DetailText
                    name="FECHA DE FIN CONTRATO:"
                    field={contrato?.fecha_fin_contrato}
                  />
                  <DetailText name="TIPO DE CONTRATO:" field={tipo_contrato} />
                  <DetailText name="EMPRESA:" field={empresa} />
                  <DetailText name="CARGO:" field={id_cargo} />
                  <DetailText name="Gerencia:" field={area} />
                  <DetailText name="JEFATURA:" field={jefatura} />
                  <DetailText
                    name="JORNADA LABORAL:"
                    field={contrato?.jornada_laboral}
                  />
                  <DetailMoney
                    name="SUELDO BASE:"
                    field={contrato?.sueldo_base}
                  />
                  <DetailText
                    name="TEXTO SUELDO BASE:"
                    field={contrato?.texto_sueldo_base}
                  />
                  <DetailMoney
                    name="BONO DE RESPONSABILIDAD:"
                    field={contrato?.bono_responsabilidad}
                  />
                  <DetailText
                    name="TEXTO BONO RESPONSABILIDAD:"
                    field={contrato?.texto_responsabilidad}
                  />
                  <DetailMoney
                    name="BONO COLACIÓN:"
                    field={contrato?.bono_colacion}
                  />
                  <DetailText
                    name="TEXTO BONO COLACIÓN:"
                    field={contrato?.texto_colacion}
                  />
                  <DetailMoney
                    name="BONO MOVILIZACIÓN:"
                    field={contrato?.bono_movilizacion}
                  />
                  <DetailText
                    name="TEXTO BONO MOVILIZACIÓN:"
                    field={contrato?.texto_movilizacion}
                  />
                  <DetailMoney
                    name="ASIGNACIÓN CAJA"
                    field={contrato?.asignacion_caja}
                  />
                  <DetailText
                    name="TEXTO ASIGNACIÓN CAJA:"
                    field={contrato?.texto_caja}
                  />
                  <DetailMoney
                    name="BONO RESPONSABILIDAD INVENTARIO:"
                    field={contrato?.bono_responsabilidad_inventario}
                  />
                  <DetailText
                    name="TEXTO BONO RESPONSABILIDAD INVENTARIO:"
                    field={contrato?.texto_responsabilidad_inventario}
                  />
                  <DetailMoney name="VIÁTICOS:" field={contrato?.viaticos} />
                  <DetailText
                    name="TEXTO VIÁTICOS:"
                    field={contrato?.texto_viaticos}
                  />
                  <DetailText
                    name="SEGURO COMPLEMENTARIO:"
                    field={contrato?.seguro_complementario}
                  />
                  <DetailText
                    name="SEGURO DE CESANTÍA:"
                    field={contrato?.seguro_cesantia}
                  />
                  <DetailMoney
                    name="BONO SALA CUNA:"
                    field={contrato?.bono_sala_cuna}
                  />
                  <DetailText
                    name="TEXTO BONO SALA CUNA:"
                    field={contrato?.texto_sala_cuna}
                  />
                  <DetailMoney
                    name="DESGASTE DE HERRAMIENTAS:"
                    field={contrato?.desgaste_herramientas}
                  />
                  <DetailText
                    name="TEXTO DESGASTE DE HERRAMIENTAS:"
                    field={contrato?.texto_herramientas}
                  />
                  <DetailBoolean
                    name="ASIGNACIÓN TELÉFONO:"
                    field={contrato?.asignacion_telefono}
                  />
                  <DetailBoolean
                    name="ASIGNACIÓN UNIFORME:"
                    field={contrato?.asignacion_uniforme}
                  />
                  <DetailBoolean
                    name="ASIGNACIÓN EQUIPO:"
                    field={contrato?.asignacion_equipo}
                  />
                  <DetailText
                    name="NOMBRE ISAPRE:"
                    field={contrato?.nombre_isapre}
                  />
                  <DetailText
                    name="VALOR PACTADO ISAPRE:"
                    field={contrato?.valor_pactado_isapre}
                  />
                  <DetailMoney
                    name="VALOR TURNO TENS:"
                    field={contrato?.valor_turno_tens}
                  />
                  <DetailText
                    name="TEXTO VALOR TURNO TENS:"
                    field={contrato?.texto_turno_tens}
                  />
                  <DetailMoney
                    name="VALOR ORIENTACIÓN:"
                    field={contrato?.valor_orientacion}
                  />
                  <DetailText
                    name="TEXTO VALOR ORIENTACIÓN:"
                    field={contrato?.texto_orientacion}
                  />
                  <DetailMoney
                    name="TOTAL IMPONIBLE:"
                    field={contrato?.total_imponible}
                  />
                  <DetailText
                    name="TEXTO TOTAL IMPONIBLE:"
                    field={contrato?.texto_imponible}
                  />
                  <DetailMoney
                    name="TOTAL HABERES:"
                    field={contrato?.total_haberes}
                  />
                  <DetailText
                    name="TEXTO TOTAL HABERES:"
                    field={contrato?.texto_haberes}
                  />
                  <DetailMoney
                    name="TOTAL LÍQUIDO:"
                    field={contrato?.total_liquido}
                  />
                  <DetailText
                    name="TEXTO TOTAL LÍQUIDO:"
                    field={contrato?.texto_liquido}
                  />
                  <DetailTextArea
                    name="FUNCIONES:"
                    field={contrato?.funciones}
                  />
                  <DetailText name="AFP:" field={id_lista_afp} />
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={12}>
            <Card>
              <CardTitle title="ARCHIVOS" class={classes.cardTitle} />
              <CardBody>
                <GridContainer>
                  <DetailFile
                    name="CERTIFICADO ANTECEDENTES:"
                    link={contrato?.certificado_antecedentes}
                  />
                  <DetailFile
                    name="FOTOCOPIA CARNET IDENTIDAD:"
                    link={contrato?.fotocopia_ci}
                  />
                  <DetailFile
                    name="HOJA DE VIDA CONDUCTOR"
                    link={contrato?.hoja_vida_conductor}
                  />
                  <DetailFile
                    name="CERTIFICADO FONASA/ISAPRE:"
                    link={contrato?.certificado_isapre_fonasa}
                  />
                  <DetailFile
                    name="CERTIFICADO AFP:"
                    link={contrato?.certificado_afp}
                  />
                  <DetailFile
                    name="CURRÍCULUM VITAE:"
                    link={contrato?.curriculum}
                  />
                  <DetailFile
                    name="INSCRIP. SUPERINTENDENCIA SALUD:"
                    link={contrato?.inscripcion_super_salud}
                  />
                  <DetailFile
                    name="VACUNA HEPATITIS:"
                    link={contrato?.vacuna_hepatitis}
                  />
                  {get_contratos_anexos
                    ? get_contratos_anexos
                        .filter(
                          (contrato) => contrato.id_ficha_personal === id_ficha
                        )
                        .map((contrato) => (
                          <DetailFileContrato
                            key={contrato.id}
                            name={contrato.nombre_archivo}
                            type={contrato.tipo}
                            link={contrato.archivo}
                          />
                        ))
                    : null}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </div>
      ) : (
        <GridItem xs={12} sm={6} md={12}>
          <Card>
            <CardTitle
              title="Cargando información ..."
              class={classes.cardTitle}
            />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        </GridItem>
      )}
    </GridContainer>
  );
};

export default ViewPersonalFile;
