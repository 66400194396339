import React from "react";

const DetailText = props => {
  return(
    <div className="col-md-4 mb-3">
      <label className="text-dark">{props.name}</label>
      <input
        type="text"
        className="form-control bg-light font-weight-lighter"
        style={{color: "#000000"}}
        value={props?.field}
        disabled
      />
    </div>
  );
}

export default DetailText;