import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { TextField, Typography, Button, Grid } from "@material-ui/core";
import {
  ArrowForward,
} from "@material-ui/icons/";
import api from "utils/API";
import TableComponent from "views/Widgets/Common/TableComponent";
import DialogDeleteRequest from "./RequestInProcess/DialogDeleteRequest";
// import TableSummary from "./TableSummary";
import moment from "moment-timezone";
import "moment/locale/es";
import DialogAceptRequest from "./RequestInProcess/DialogAceptRequest";
import RequestInfo from "views/Widgets/Common/RequestInfo";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import allActions from "actions";
import {toMoneyFormat} from "utils/formatFunctions";
import MedicalTableComponent from "views/PatientFile/InsumosYMedicamentos/MedicalTableComponent";
import ResourceTableComponent from "views/PatientFile/InsumosYMedicamentos/ResourceTableComponent";
import {
  getListRequestPatient,
} from 'actions/getListAction';
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
moment.locale("es");

const ModalEditRequestInProcess = props => {
  const {
    openModal,
    closeModal,
    rowInfo,
    dataArray,
    setauxData,
    type,
    isSpecific,
    inRequest=false,
    finishArray,
    setFinishArray
  } = props;
  const dispatch = useDispatch();
  const [charge, setCharge] = useState(true);
  const [pageRefresh, setPageRefresh] = useState(false);
  const getIndex = dataArray.findIndex(row => row.id === (isSpecific ? rowInfo.id_row : rowInfo.id));
  const [arrayInfo, setArrayInfo] = useState([]);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogAceptRequest, setOpenDialogAceptRequest] = useState(false);
  const [idNotaVenta, setIdNotaVenta] = useState(null);
  const [orderInfo, setorderInfo] = useState({});
  const [showNV, setShowNV] = useState(false);
  const [requestState, setrequestState] = useState(null);
  const [openModalAmount, setOpenModalAmount] = useState(false);
  const [openModalEditRequest, setopenModalEditRequest] = useState(false);
  const [openModalAccept, setopenModalAccept] = useState(false);
  const [openModalReject, setopenModalReject] = useState(false);
  const [observation, setobservation] = useState("");
  const [oldObservation, setoldObservation] = useState("");
  const [reasonRejected, setreasonRejected] = useState("");
  const [valuesAmountPay, setvaluesAmountPay] = useState([]);
  const [isEmergency, setisEmergency] = useState({});
  const [requestDate, setrequestDate] = useState("");

  const [tempItemMedicine, settempItemMedicine] = useState([])
  const [tempItemResource, settempItemResource] = useState([])

  const elementPay = ["Isapre", "CMH", "Paciente"]

  const limitObservation  = 400;

  const patientFile = JSON.parse(localStorage.getItem("patientFile"))

  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);

  useEffect(() => {
    if (charge) {
      initialCharge();
    }
    if (pageRefresh) {
      setPageRefresh(false);
    }
  });

  const initialCharge = () => {
    setCharge(false);

    const getInformation = api.get(`/api/paciente/${rowInfo.id_paciente}/solicitudes/insumosextra/${rowInfo.id}/`);
    Promise.all([getInformation]).then(response => {
        setrequestState(response[0].data.estado);
        let list_nv = [...response[0].data.productos_pedidos?.equipos, ...response[0].data.productos_pedidos.insumos, ...response[0].data.productos_pedidos.medicamentos].map((row) => {return row.numero_nota_venta})
        list_nv = list_nv.filter((row) => row)
        const tempOrderInfo = response[0]?.data?.productos_pedidos?.solicitud_pedido
        setIdNotaVenta(list_nv.length > 0 ? response[0].data.productos_pedidos.id_solicitud_pedido : null);
        setorderInfo(tempOrderInfo)
        setoldObservation(tempOrderInfo.observacion)
        setrequestDate(tempOrderInfo.fecha_tentativa_llegada)
        if (tempOrderInfo.tipo_solicitud == "InsumosMedicamentos") {
          setisEmergency({value: false, label: "No"})
        } else if (tempOrderInfo.tipo_solicitud == "Urgente"){
          setisEmergency({value: true, label: "Sí"})
        }
    
        setShowNV(response[0]?.data?.productos_pedidos?.insumos.length > 0 || response[0].data?.productos_pedidos?.medicamentos?.lenght > 0);

        let tempArray = [];
        response[0].data.productos_pedidos.medicamentos.map(medicamento =>
          tempArray.push({
            tipo: "Medicamento",
            item: medicamento.id_insumo_medicamento.descripcion_producto,
            sku: medicamento.id_insumo_medicamento.sku,
            cantidad: medicamento.cantidad,
            valor: medicamento.id_insumo_medicamento.valor_con_iva,
            id: medicamento.id_insumo_medicamento.id,
          })
        );
        response[0].data.productos_pedidos.insumos.map(insumo =>
          tempArray.push({
            tipo: "Insumos",
            cantidad: insumo.cantidad,
            item: insumo.id_insumo_medicamento.descripcion_producto,
            sku: insumo.id_insumo_medicamento.sku,
            valor: insumo.id_insumo_medicamento.valor_con_iva,
            id: insumo.id_insumo_medicamento.id,
          })
        );
        response[0].data.productos_pedidos.equipos.map(equipo =>
          tempArray.push({
            tipo: "Equipos",
            cantidad: equipo.cantidad,
            item: equipo.id_equipo.descripcion_producto,
            sku: equipo.id_equipo.sku,
            valor: equipo?.id_equipo?.valor_con_iva,
            id: equipo.id_insumo_medicamento.id,
          })
        );
        tempOrderInfo
        setArrayInfo(tempArray);
        settempItemMedicine(tempArray.filter((row) => row.tipo === "Medicamento"))
        settempItemResource(tempArray.filter((row) => row.tipo !== "Medicamento"))

      })

   
  };

  const acceptRequest = () => {

    const listPay = valuesAmountPay.map((row) => {
      return{
        valor: row.value,
        entidad_pago: row.label,
      }
    })
    const body = {
      lista_copago: listPay,
      actualizar_observacion: `${observation}`,
    }
    if (isEmergency.value !== undefined){
      body["es_urgente"] = isEmergency.value
      body["fecha_entrega"] = moment(requestDate).format("YYYY-MM-DDTHH:mm:ss")

  }
    api.post(`api/paciente/${rowInfo.id_paciente}/solicitudes/insumosextra/${rowInfo.id}/aceptar/`, body).then(response => {
      if (inRequest){
        getListRequestPatient(patientFile, dispatch)
      } else {
        let tempArray = dataArray;
        if (isSpecific) {
          let tempFinish = finishArray;
          tempArray[getIndex].estado = "Finalizado";
          tempFinish.push(tempArray[getIndex]);
          setFinishArray(tempFinish);
          tempArray.splice(getIndex, 1);
        } else {
          tempArray[getIndex].estado = "EnviadaBodega";
        }
        setauxData(tempArray);
      }
      setOpenModalAmount(false);
      handleClose();

    }).catch(error => {

      {}
    });
  };

  const rejectRequest = () => {

    const body = {
      observacion_rechazo: reasonRejected,
    }
    api.post(`api/paciente/${rowInfo.id_paciente}/solicitudes/insumosextra/${rowInfo.id}/rechazar/`, body).then(response => {
      if (inRequest){
        getListRequestPatient(patientFile, dispatch)
      } else {
        let tempArray = dataArray;
        if (isSpecific) {
          let tempFinish = finishArray;
          tempArray[getIndex].estado = "Finalizado";
          tempFinish.push(tempArray[getIndex]);
          setFinishArray(tempFinish);
          tempArray.splice(getIndex, 1);
        } else {
          tempArray[getIndex].estado = "EnviadaBodega";
        }
        setauxData(tempArray);
      }
      closeModal();

    }).catch(error => {

      {}
    });
  };

  const handleClose = () => {
    setOpenDialogAceptRequest(false);
    closeModal();
  }

  const handleEditProcessAmount = () => {
    const tempElementPay = elementPay.map(row => {
      return {value: 0, percentage: 0, label: row}
    })
    setvaluesAmountPay(tempElementPay)
    setOpenModalAmount(true);
  }

    const headersSupplies = [
      {eval: true, label: "Tipo"},
      {eval: true, label: "Item"},
      {eval: true, label: "SKU"},
      {eval: true, label: "Cantidad"},
      {eval: true, label: "Valor"},
      {eval: true, label: "Total"},
    ]

    const dataSupplies = arrayInfo.map((row) => {
      return [
        {value: row.tipo},
        {value: row.item },
        {value: row.sku },
        {value: row.cantidad},
        {value: toMoneyFormat(row.valor)},
        {value: toMoneyFormat(row.valor*row.cantidad)},
      ]
    })

  const _content_ =  <div>
    {pageRefresh && <div></div>}
    <Grid container justify="center">
      <RequestInfo
        orderInfo={orderInfo}
        idNotaVenta={showNV ? idNotaVenta : null}
      />
      <Grid item container xs={12}>
        <TableComponent 
          headers={headersSupplies}
          data={dataSupplies}
        />
      </Grid>
    </Grid>
    {openDialogDelete && (
      <DialogDeleteRequest
        openDialog={openDialogDelete}
        closeDialog={() => setOpenDialogDelete(false)}
        rowInfo={rowInfo}
        closeModal={closeModal}
        dataArray={dataArray}
        getIndex={getIndex}
      />
    )}
    {openDialogAceptRequest && (
      <DialogAceptRequest
        openDialog={openDialogAceptRequest}
        closeDialog={handleClose}
        closeMainModal={closeModal}
      />
    )}
  </div>

  const _actions_ = <>
    {(requestState === "EnviadaCoordinadora" && type !== "1" && !inRequest) &&
    <Button
      variant="contained"
      style={{
        backgroundColor: green[500],
        textTransform: "none",
        color: "#fff",
      }}
      onClick={() => setopenModalAccept(true)}
    >
      Aceptar solicitud
    </Button>
    }
    {(requestState === "EnviadaCoordinadora" && type !== "1" && !inRequest) &&
    <Button
      variant="contained"
      style={{
        backgroundColor: orange[500],
        textTransform: "none",
        color: "#fff",
      }}
      onClick={() => {setopenModalEditRequest(!openModalEditRequest)}}
    >
      Editar solicitud
    </Button>
    }
    {(requestState === "EnviadaCoordinadora" && type !== "1") &&
    <Button
      variant="contained"
      color="secondary"
      style={{
        backgroundColor: red[500],
        textTransform: "none",
        color: "#fff",
      }}
      onClick={() => {setopenModalReject(true);}}
    >
      Rechazar solicitud
    </Button>
    }
    <Button
      variant="contained"
      color="secondary"
      style={{
        backgroundColor: red[500],
        textTransform: "none",
        color: "#fff",
      }}
      onClick={closeModal}
    >
      Cerrar
    </Button>
  </>

  const totalAmount = parseInt(arrayInfo.reduce((acc, item) => acc + ((item.valor || 0) * item.cantidad), 0));

  const totalPay = parseInt(valuesAmountPay.reduce((acc, item) => acc + item.value, 0));

  const accumPercentage = valuesAmountPay.reduce((acc, item) => acc + item.percentage, 0)
  const valueAccum = parseInt(valuesAmountPay.reduce((acc, item) => acc + item.value, 0));

  let percentageError = ""
  if (accumPercentage > 100) percentageError = "El % total debe ser sumar 100%";

  let valueError = ""

  if (valueAccum !== totalAmount) valueError = `El valor repartido debe ser igual al total del pedido`;

  const handleEditAmount = (value, index, key) => {
    const tempValues = [...valuesAmountPay];
    let tempValue = value.split("$").join("").split(",").join("");
    tempValue = tempValue === "" ? 0 : key === "percentage" ? parseFloat(tempValue) : parseInt(tempValue);
    if (!isNaN(toMoneyFormat(tempValue)) || !isNaN(tempValue) && tempValue >= 0) {
      tempValues[index][key] = tempValue;
      if (key === "percentage") {
        tempValues[index]["showPercentage"] = tempValue;
        tempValues[index].value = ((totalAmount * tempValue) / 100);
        tempValues[index].showValue = parseInt((totalAmount * tempValue) / 100);
      } else {
        tempValues[index]["showValue"] = tempValue;
        tempValues[index].percentage = (tempValue * 100) / totalAmount;
        tempValues[index].showPercentage = parseFloat((tempValue * 100) / totalAmount).toFixed(2);
      }
      setvaluesAmountPay(tempValues);
    }
  }

  const _contentAmount_ = <div>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
      {valuesAmountPay.map((row, index) => (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          item
          xs={12}
          style={{margin: "10px 0"}}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={5}
            style={{margin: "10px 0"}}
          >
            <TextField
              variant="outlined"
              label={`${row.label} % copago`}
              value={row.showPercentage}
              onChange={(e) => handleEditAmount(e.target.value, index, "percentage")}
              error={percentageError !== ""}
              helperText={percentageError !== "" ? percentageError : ""}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={2}
            style={{margin: "10px 0"}}
          >
            <Button
              justIcon
              round
              color="primary"
            >
              <ArrowForward />
            </Button>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={5}
            style={{margin: "10px 0"}}
          >
            <TextField
              variant="outlined"
              label={`${row.label} valor copago`}
              value={toMoneyFormat(row.showValue)}
              onChange={(e) => handleEditAmount(e.target.value, index, "value")}
              error={valueError !== ""}
              helperText={valueError !== "" ? valueError : ""}
            />
          </Grid>
        </Grid>
      ))}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item xs={12}
      >
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {`Total pedido: ${toMoneyFormat(totalAmount)}`}
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item xs={12}
      >
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {`Total copago: ${toMoneyFormat(totalPay)}`}
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item xs={12}
      >
        <Typography variant="h6" style={{ textAlign: "center" }}>
          {`Restante: ${toMoneyFormat(totalAmount-totalPay)}`}
        </Typography>
      </Grid>
    </Grid>
  </div>

  const _actionAmount_ = <>
    {(valueError === "" && percentageError === "") ?
    <Button
      variant="contained"
      color="primary"
      onClick={() => acceptRequest()}
      style={{
        backgroundColor: green[500],
        textTransform: "none",
        color: "#fff",
      }}
    >
      Aceptar solicitud
    </Button> : <Button
      disabled
      variant="contained"
    >
      Aceptar solicitud
    </Button>}
    <Button
      variant="contained"
      color="secondary"
      style={{
        backgroundColor: red[500],
        textTransform: "none",
        color: "#fff",
      }}
      onClick={() => {setOpenModalAmount(false)}}
    >
      Cerrar
    </Button>
  </>

  const handleUpdateTempItem = (listItem, type, set) => {
    console.log(listItem)
    set(listItem.map((row) => {
      if (!row.tipo){
        return {...row, tipo: type}
      } else { return {...row}}
    }
    ))
  }

  const handleCancelEditRequest = () => {
    settempItemMedicine(arrayInfo.filter((row) => row.tipo === "Medicamento"))
    settempItemResource(arrayInfo.filter((row) => row.tipo !== "Medicamento"))
    setopenModalEditRequest(false)
  }

  const handleSaveEditRequest = () => {
    const tempArrayInfo = [...tempItemMedicine, ...tempItemResource]


    api.put(`api/paciente/${rowInfo.id_paciente}/solicitudes/insumosextra/${rowInfo.id}/actualizar/`, {items: tempArrayInfo}).then(response => {

      setArrayInfo( tempArrayInfo.map((row) => {
        const item = medicalResourceData[row.id]
        return {...row, valor: item.valor_con_iva}
      }))
      setopenModalEditRequest(false)

    })
  }

  const _contentEdit_ = <div>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
    >
      <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
      style={{margin: "10px 0"}}
    >
      <MedicalTableComponent
        dataMedicalArray={tempItemMedicine}
        setDataMedicalArray={(listItem) => handleUpdateTempItem(listItem, "Medicamento", settempItemMedicine)}
        setPageRefresh={() => {}}
      />
    </Grid>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
      style={{margin: "10px 0"}}
    >
      <ResourceTableComponent
        dataResourceArray={tempItemResource}
        setDataResourceArray={(listItem) => handleUpdateTempItem(listItem, "Insumo", settempItemResource)}
        setPageRefresh={() => {}}
      />
    </Grid>
    </Grid>
  </div>

  const _actionEdit_ = <>
  {tempItemMedicine.length > 0 || tempItemResource.length > 0 ?
      <Button
      variant="contained"
      color="secondary"
      style={{
        backgroundColor: green[500],
        textTransform: "none",
        color: "#fff",
      }}
      onClick={handleSaveEditRequest}
    >
      Guardar
    </Button> :
    <Button
      disabled
      variant="contained"
    >
      Guardar
    </Button>
  }

    <Button
      variant="contained"
      color="secondary"
      style={{
        backgroundColor: red[500],
        textTransform: "none",
        color: "#fff",
      }}
      onClick={handleCancelEditRequest}
    >
      Cancelar
    </Button>
  </>

  const _contentReject_ = <div >
  <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-end"
      item
      xs={12}
  >
  <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
      <TextField
          label={"Motivo rechazo"}
          multiline
          rows={8}
          value={reasonRejected}
          variant="outlined"
          onChange={(event) => setreasonRejected(event.target.value)}
          style={{width: "100%"}}
      />
  </Grid>
  <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
      {`${reasonRejected?.length || 0 }/${limitObservation}`}
  </Typography>
  </Grid>
  </div>

  const _actionsReject_ = <>
  <Button
      mr={2}
      variant="contained"
      style={{backgroundColor: red[500]}}
      className="text-white"
      onClick={() => rejectRequest()}
  >
      Rechazar
  </Button>
  <Button
      mr={2}
      variant="contained"
      style={{backgroundColor: indigo[500]}}
      className="text-white"
      onClick={() => setopenModalReject(false)}
  >
      Cerrar
  </Button>
  </>

  const _contentAccept_ = <div >
  <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-end"
      item
      xs={12}
  >
    <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
      <Typography component="body2" variant="body2"  style={{fontWeight: 'bold'}} >  ¿Está seguro de que desea aprobar la solicitud del paciente?</Typography>
    </Grid>
    <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
        <TextField
            label={"Agregar Observación"}
            multiline
            rows={8}
            value={observation}
            variant="outlined"
            onChange={(event) => setobservation(event.target.value)}
            style={{width: "100%"}}
        />
    </Grid>
    <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
        {`${observation?.length || 0 }/${limitObservation}`}
    </Typography>
    <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
      <Typography component="body2" variant="body2"  style={{fontWeight: 'bold'}} > ¿Es urgente?</Typography>
    </Grid>
    <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{padding: "5px 0"}}
      >
          <DetailSelect
              value={isEmergency}
              onchange={value => {setisEmergency(value)}}

              list={[{value: false, label: "No"}, {value: true, label: "Sí"}]}
          />
    </Grid>
    <Grid
        item
        xs={12}
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{padding: "5px 0"}}
    >
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DateTimePicker
                variant="inline"
                format="DD/MM/YYYY HH:mm"
                margin="normal"
                label={"Fecha entrega en domiciolio"}
                placeholder=""
                minDate={moment()}
                minDateMessage={"La fecha de envío no puede ser anterior al día de hoy"}
                cancelLabel={"Cancelar"}
                okLabel={"Aceptar"}
                onChange={(value) => setrequestDate(value)}
                value={requestDate}
                inputVariant={"outlined"}
                invalidDateMessage={"Debe ingresar fecha de entrega en domicilio"}
            />
        </MuiPickersUtilsProvider>
    </Grid>
        
  </Grid>
  </div>

  const _actionsAccept_ = <>

  <Button
      mr={2}
      variant="contained"
      style={{backgroundColor: green[500]}}
      className="text-white"
      onClick={() =>  handleEditProcessAmount()}
  >
      Aceptar
  </Button>
  <Button
      mr={2}
      variant="contained"
      style={{backgroundColor: indigo[500]}}
      className="text-white"
      onClick={() => setopenModalAccept(false)}
  >
      Cerrar
  </Button>
  </>

  return (<>
    <ModalDialog
      open={openModal}
      onClose={closeModal}
      title={`Resumen solicitud`}
      _content_={_content_}
      _actions_ = {_actions_}
    />
    <ModalDialog
      open={openModalAmount}
      onClose={() => setOpenModalAmount(false)}
      title={`Repartir Montos`}
      _content_={_contentAmount_}
      _actions_ = {_actionAmount_}
      maxWidth="xs"
    />
    <ModalDialog
      open={openModalEditRequest}
      onClose={() => setopenModalEditRequest(false)}
      title={`Editar Pedido`}
      _content_={_contentEdit_}
      _actions_ = {_actionEdit_}
      maxWidth="xl"
    />

    <ModalDialog
        open={openModalReject}
        onClose={() => setopenModalReject(false)}
        title={`Rechazo`}
        _content_={_contentReject_}
        _actions_ = {_actionsReject_}
        maxWidth="sm"
    />
    {openModalAccept &&
      <ModalDialog
        open={openModalAccept}
        onClose={() => setopenModalAccept(false)}
        title={`Aceptar`}
        _content_={_contentAccept_}
        _actions_ = {_actionsAccept_}
        maxWidth="sm"
      />
    }

    </>
  );
};

export default ModalEditRequestInProcess;
