import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModalMessage from "./ModalMessage";



export default function ModalSendComplete(props) {

  const { open, closeModal, id_detalle, id_order, enviosPedidos, productos_restantes, moduleType, fileInfo } = props;
  const [order, setOrder] = useState([]);
  const [modalMessage, setModalMessage] = useState(false);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    api.get(`api/solicitudpedidos/${id_order}`).then(response => {
      setOrder(response.data);

    });
  }, []);

  const [scroll, setScroll] = useState("paper");

  function updateBenefit() {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    const formData = new FormData();
    formData.append("tipo_solicitud", order.tipo_solicitud);
    formData.append("fecha_solicitud", order.fecha_solicitud);
    formData.append("estado_picking", productos_restantes.length === 0 ? "Completa" : "Pendiente");
    formData.append("fecha_despacho", order.fecha_despacho);
    formData.append("nombre_paciente", order.nombre_paciente);
    formData.append("es_region", order.es_region ? 1 : 0 );
    formData.append("tiene_medicamentos", order.tiene_medicamentos ? 1 : 0);
    formData.append("estado_guia_despacho", order.estado_guia_despacho);
    formData.append("estado_solicitud", order.estado_solicitud);
    formData.append("traslado_clinica", order.traslado_clinica ? 1 : 0);
    formData.append("motivo_rechazo", order.motivo_rechazo);
    formData.append("id_paciente", order.id_paciente);
    formData.append("numero_de_pedidos", order.numero_de_pedidos + 1);


    apiform.put(`api/bodega/finalizar/picking/grupo/${group}/solicitud/${id_order}`, formData).then(response => {
      const data = response.data;
      const arrayAux = props?.uploadArrayEnviados?.map(array => {
        return {
          numero_pedido: data.numero_pedido,
          cantidad_enviada: array.picking,
          id_producto_pedido: array.id,
          id_envio_pedido: data.id
        };
      });
      
      const sendProducts = api.put("api/ProductosEnviados/bulk", arrayAux);
      const sendFileCheck = api.patch(`api/envios/bodega/caja/${data.id}/adjuntado/`);
      const listPromise = [sendProducts, sendFileCheck]
      if (fileInfo != null){
        const requestDoc = apiform.post("api/documentospedidos/", fileInfo);
        listPromise.push(requestDoc)
      }
      Promise.all(listPromise).then((request) => { 
        window.location.href = `/admin/warehouse/orders/${props.moduleType}`;
      })
       // 

      // api.patch(`api/envios/bodega/caja/${idEnvio}/adjuntado/`).then((response) => {
      //   api.post("api/documentosenvios/", {
      //       tipo_documento: "NotaVenta",
      //       id_envio_pedido: idEnvio,
      //       id_documento: responseDocumento.data.id,
      //     })
      //         window.location.href = `/admin/warehouse/orders/${moduleType}`;
      //   })

    })
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Resumen del pedido</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          <div className="mt-3 mb-3">
            <ul className="list-group">
              {productos_restantes.map((producto) => (
                <li key={producto.id} className="list-group-item">
                  {producto.cantidad_por_enviar}
                  {producto.id_insumo_medicamento.tipo_cantidad}
                  de
                  {producto.id_insumo_medicamento.descripcion_producto}
                </li>
              ))}
            </ul>
          </div>
          <div>
            El estado del pedido será:
            <span className="font-weight-bold">{productos_restantes.length === 0 ? "Completo" : "Pendiente" }</span>
          </div>
        </div>
        <div>
          <span className="font-weight-bold">
            ¿Estás seguro que deseas enviar el pedido a egresos?
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          No
        </Button>
        <Button onClick={updateBenefit} color="primary">
          Si
        </Button>
      </DialogActions>
      {modalMessage && (
        <ModalMessage
          open={modalMessage}
          closeModal={() => setModalMessage(false)}
        />
      )}
    </Dialog>
  );
}

ModalSendComplete.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number,
  id_order: PropTypes.number,
  enviosPedidos: PropTypes.array,
  uploadArrayEnviados: PropTypes.array
};
