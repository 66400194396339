//TODO Aplicar programacion funcional
import React, { useState, useEffect, useRef } from 'react'
import Trix from "trix";
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import moment from "moment-timezone";
import "moment/locale/es";
import api from 'utils/API';

import AppDialog from 'views/Widgets/AppDialog';
import ActionButtons from 'views/Widgets/ActionButtons';

import { makeStyles } from '@material-ui/core/styles'
import { TypographyMui } from 'utils/componentsStyle';
import { Button, Box, TextField, Chip, Grid } from '@material-ui/core'
import { Autocomplete, Alert } from '@material-ui/lab'
import EditIcon from '@material-ui/icons/Edit';
import people from '../../../../assets/img/icons/memo/people.svg'
import folder from '../../../../assets/img/icons/shared/folder.svg'
import avatar from '../../../../assets/img/icons/memo/avatar.svg'
import date from '../../../../assets/img/icons/memo/date.svg'

const useStyles = makeStyles((theme) => ({
  textAreaContainer: {
    border: '1px solid #3F51B5',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: 4,
    height: styleProps => styleProps.edit ? 'calc(100% - 230px - 1rem)' : 'calc(100% - 190px - 1rem)'
  },
  orangeBordersColor: {
    color: '#FF9800',
    border: '1px solid #FF9800',
  },
  orangeButton: {
    backgroundColor: 'rgba(255, 152, 0, 0.08)',
    paddingLeft: '60px',
    paddingRight: '60px'
  },
  '@media (max-width: 800px)': {},
}));

function MemorandumForm({ memoData, memorandumsData, possibleMember, membersSelected, setmembersSelected, toogleDialog, setMemoData, resetMemoData, updateMemorandums, setSpiner }) {
  const classes = useStyles({ edit: memoData.data })
  const [areYouSureDialog, setAreYouSureDialog] = useState(false)
  const [alert, setAlert] = useState({ success: false, error: false, emptyField: false })

  const handleMenbers = (type, member) => {
    setAlert({ ...alert, error: false, emptyField: false })
    if (!member) { return }

    if (type === 'save') {
      const alreadyExist = membersSelected.some(item => item.id_ficha_personal === member.id_ficha_personal)
      if (alreadyExist) { return }
      setmembersSelected([...membersSelected, member])
    } else {
      const deletedMember = membersSelected.filter(item => item.id_ficha_personal !== member.id_ficha_personal)
      setmembersSelected(deletedMember)
    }
  };

  const saveOrEditMemo = () => {
    setAreYouSureDialog(false)
    if (!membersSelected.length && memoData.data.memoDescription) {
      setAlert({ ...alert, emptyField: true })
      return
    }
    setSpiner()
    if (memoData.data.memoId) {
      editMemorandum()
    } else {
      saveMemorandum()
    }
  }

  const saveMemorandum = () => {
    const newMemo = {
      minuta: memoData.data.memoDescription,
      id_paciente: memorandumsData.file.id_paciente
    }
    api.post(`api/minutas/`, newMemo).then(response => {
      if (membersSelected.length) {
        postMembers(response)
        return
      }
      successResponse(response, undefined)
    }, error => {
      setAlert({ ...alert, error: true })
      setSpiner()
    })
  }

  const editMemorandum = () => {
    const editedMemoData = new FormData()
    editedMemoData.append('minuta', memoData.data.memoDescription)
    editedMemoData.append('id_paciente', memorandumsData.file.id_paciente)
    api.put(`api/minutas/${memoData.data.memoId}`, editedMemoData).then(response => {
      postMembers(response)
    }, error => {
      setAlert({ ...alert, error: true })
      setSpiner()
    })
  }

  const postMembers = (response) => {
    const membersSelectedFormated = membersSelected.map(member => member.id_ficha_personal)
    const memberData = {
      id_participantes: membersSelectedFormated,
      id_minuta: response.data.id
    }
    api.post(`api/minutas/participantes/`, memberData).then(subResponse => {
      successResponse(response, subResponse)
    }, error => {
      setAlert({ ...alert, error: true })
    })
  }

  const successResponse = (memo, members) => {
    setSpiner()
    updateMemorandums({ ...memo.data, id_minuta: memo.data.id, participantes: members.data }, memoData.data.memoId)
    setAlert({ ...alert, success: true, error: false, emptyField: false })
    setTimeout(() => {
      setAreYouSureDialog(false)
      setAlert({ ...alert, success: false })
      toogleDialog()
      resetMemoData()
    }, 2500);
  }

  const enableFormFields = () => {
    setMemoData({ ...memoData, showFormFields: true, isEdit: false })
  }

  //Lógica que determina que se renderiza desde la linea 289
  const setActionButtons = () => {
    const shouldMemoEdit = new Date(memoData.data?.createdAt).getTime() + 1800000 < new Date().getTime()
    if (shouldMemoEdit) {
      return (
        <Box height='64px' width='100%' className={classes.orangeBordersColor} py={1} display='flex' justifyContent='center' alignItems='center' mt={1.5}>
          <TypographyMui variant='button'>
            Esta minuta no puede ser editada ya que fue creada hace más de 30 minutos.
          </TypographyMui>
        </Box>
      )
    } else if (memoData.isEdit) {
      return (
        <Box height='64px' display='flex' justifyContent='center' alignItems='center'>
          <Button className={`${classes.orangeButton} ${classes.orangeBordersColor}`} onClick={enableFormFields} variant='outlined'>
            <EditIcon style={{ color: '#FF9800' }} className='mr-3' /> EDITAR
          </Button>
        </Box>
      )
    } else {
      return (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box width='60%'>
            <Box display={alert.success ? 'block' : 'none'}>
              <Alert severity="success">Minuta {memoData.data.memoId ? 'Editada' : 'Creada'} exitosamente</Alert>
            </Box>
            <Box display={alert.error ? 'block' : 'none'}>
              <Alert severity="error">Hubo un error. Intentalo nuevamente</Alert>
            </Box>
            <Box display={alert.emptyField ? 'block' : 'none'}>
              <Alert severity="error">Debes agregar al menos 1 participante y el campo "DETALLE" es requerido</Alert>
            </Box>
          </Box>

          <Box width='40%' height='100%' display='flex' alignItems='center'>
            <Box height='100%' display='flex' justifyContent='flex-end' alignItems='center'>
              <ActionButtons
                actionButtonText= 'Guardar'
                justifyActionButtons='flex-end'
                disabledAction={!membersSelected.length && !memoData.data.memoDescription}
                cancelAction={toogleDialog}
                actionButtonMethod={() => setAreYouSureDialog(true)}
              />
            </Box>
          </Box>
        </Box>
      )
    }
  }

  return (
    <>
      <Box height='100%' overflow='auto' px={4} py={1}>
        {memoData.showFormFields ?
          //Create menu..................
          <Box display='grid' gridTemplateColumns='30% 20% 1fr'>
            <Box display='flex' alignItems='center'>
              <img src={people} alt="people" width='20px' height='30px' className='mr-2' />
              <TypographyMui variant='button' color='primary'>AGREGAR PARTICIPANTES</TypographyMui>
            </Box>
            <Box />
            <Autocomplete
              options={possibleMember}
              getOptionLabel={(option) => option.nombres}
              clearOnBlur={false}
              noOptionsText={'No se encontraron resultados'}
              renderInput={(params) => <TextField {...params} label="Buscar" />}
              onChange={(event, member) => handleMenbers('save', member)}
            />
          </Box>
          :
          //Edit menu..................
          <Grid container>
            <Grid item xs={6}>
              <Box>
                <Box display='flex' alignItems='center' mb={0.5}>
                  <img src={avatar} alt="avatar" width='20px' height='30px' className='mr-2' />
                  <TypographyMui variant='button' color='primary'>PACIENTE</TypographyMui>
                </Box>
                <TypographyMui variant='button'>{`${memorandumsData.dataPatient.nombre} ${memorandumsData.dataPatient.apellido_paterno}`}</TypographyMui>
              </Box>
              <Box mt={1.5}>
                <Box display='flex' alignItems='center' mb={0.5}>
                  <img src={date} alt="date" width='20px' height='30px' className='mr-2' />
                  <TypographyMui variant='button' color='primary'>FECHA Y HORA</TypographyMui>
                </Box>
                <TypographyMui variant='button'>
                  {moment(memoData.data?.fecha_creacion).format('DD/MM/YYYY')} | {moment(memoData.data?.fecha_creacion).format('HH:mm')}
                </TypographyMui>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mt={0.5}>
                <Box display='flex' alignItems='center' mb={0.5}>
                  <img src={people} alt="people" width='20px' height='30px' className='mr-2' />
                  <TypographyMui variant='button' color='primary'>PARTICIPANTES</TypographyMui>
                </Box>
                <Box display='flex' flexDirection='column'>
                  {memoData.data?.memoMembers && memoData.data?.memoMembers.map(member => {
                    return (
                      <TypographyMui key={member.id_ficha_personal} variant='button'>• {member.nombres}</TypographyMui>
                    )
                  })
                  }
                </Box>
              </Box>
            </Grid>
          </Grid>
        }
        {/*Members selected.................. */}
        <Box display={memoData.showFormFields ? 'block' : 'none'} my={1}>
          {membersSelected.map(member => {
            return (
              <Chip
                key={member.id_ficha_personal}
                className='mr-2'
                label={member.nombres}
                onDelete={() => handleMenbers('delete', member)}
                variant="outlined"
                color='primary'
              />
            )
          })}
        </Box>
        {/* Memoraundum description.................. */}
        <Box display='grid' gridTemplateColumns='repeat(2, 1fr)'>
          <Box display='flex' alignItems='center'>
            <img src={folder} alt="folder" width='20px' height='30px' className='mr-2' />
            <TypographyMui variant='button' color='primary'>DETALLE</TypographyMui>
          </Box>

          <Box alignSelf='center' pt={2}>
            {memoData.showFormFields &&
              <ReactTrixRTEToolbar
                toolbarId="react-trix-rte-editor"
                disableGroupingAction={true}
                toolbarActions={["bold", "italic", "heading1", "bullet", "number"]}
              />
            }
          </Box>
        </Box>
        <Box className={classes.textAreaContainer}>
          {memoData.showFormFields ?
            <ReactTrixRTEInput
              toolbarId='react-trix-rte-editor'
              placeholder='Escribe aquí la minuta'
              defaultValue={memoData.data.memoDescription}
              onChange={(e) => setMemoData({ ...memoData, data: { ...memoData.data, memoDescription: e.target.value } })}
            />
            :
            <Box p={2} dangerouslySetInnerHTML={{ __html: memoData.data.memoDescription }} />
          }
        </Box>

        {/* Create / edit Memoraundum actions.................. */}
        {setActionButtons()}
      </Box>

      {/* are you sure dialog.................. */}
      <AppDialog title='GUARDAR MINUTA' open={areYouSureDialog} onClose={() => setAreYouSureDialog(false)} maxWidth='xs'>
        <Box py={1} px={2}>
          <TypographyMui variant='body2' className='text-center'>
            ¿Estás seguro que deseas guardar los cambios en la minuta? <br />
            Recuerda que solo tendrás 30 minutos luego de su creación para editarla.
          </TypographyMui>

          <Box height='64px' display='flex' justifyContent='space-between' alignItems='center'>
            <ActionButtons
              actionButtonText= 'Guardar'
              cancelAction={() => setAreYouSureDialog(false)}
              actionButtonMethod={saveOrEditMemo}
            />
          </Box>
        </Box>
      </AppDialog>
    </>
  )
}

export default MemorandumForm

