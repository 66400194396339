import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
    Typography,
    Button,
} from "@material-ui/core";
import moment from "moment-timezone";
import "moment/locale/es";
import api from "utils/API";
import { grey, indigo, orange, green, red } from "@material-ui/core/colors";
import TableComponent from "views/Widgets/Common/TableComponent";
import ModalEditDispatch from "views/ClinicOrders/OrdersDashboard/ModalEditDispatch";
import ModalDeleteItemTable from "components/PatientFile/ModalDeleteItemTable";
import {setmessageGeneral } from "actions/getGlobalAction";

moment.locale("es");

const DispatchPatientDetail = ({
    listaPrestaciones,
    getPatientInfoFilter,
    dispatchDate,
}) => {
    const dispatch = useDispatch();
    const [initialCharge, setInitialCharge] = useState(true);
    const [openDeleteModal, setopenDeleteModal] = useState(null);
    const [totalValue, setTotalValue] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [indexSelectOrder, setIndexSelectOrder] = useState(0);
    const [patientID, setpatientID] = useState(null);
    const [lenListOrderPatient, setLenListOrderPatient] = useState(0);
    const [openEditDispatch, setopenEditDispatch] = useState(false);

    const patient = useSelector(state => state.currentGlobalVar.patientClinicOrder);

    const total = useSelector(state => state.currentGlobalVar.TotalValueMonthConsume);

    const listPatientScheduledOrders = useSelector(state => state.currentList.listPatientScheduledOrders);
    // # Productos_Pedidos ESTADO INCOMPLETO = PERMITE EDITAR (DE TODOS LOS PRODUCTOS DEL PEDIDO GRACIAS DANIEL)
    const permission = JSON.parse(localStorage.getItem("permisos"))

    let listOrderPatient = [];
    if (listPatientScheduledOrders) listOrderPatient = [...listPatientScheduledOrders];

    useEffect(() => {
        if (totalValue === 0) setTotalValue(total);
        if((initialCharge  || (patient?.id && patientID !== patient?.id || lenListOrderPatient !== listOrderPatient.length)) && listaPrestaciones.length > 0  && listPatientScheduledOrders){
            initialData();
        }
    })

    const initialData = () => {
        setInitialCharge(false);
        setpatientID(patient?.id)
        let totalValue = 0
        listaPrestaciones.filter(item => item.fuera_paquete === false).map(item => {
          totalValue = totalValue + parseInt(item.cantidad_envio !== null ? item.cantidad_envio : item.cantidad) * parseInt(item.id_insumo_medicamento__valor_con_iva)
        })
        if (totalAmount == 0) {
          setTotalAmount(totalValue)
        }
        setIndexSelectOrder(listOrderPatient.length -1 > 0 ? listOrderPatient.length -1 : 0)
        setLenListOrderPatient(listOrderPatient.length)
    }

    let replacement = [];
    // estos son los elementos que van a remplazar a otros....
    let editEnable = 0;
    listaPrestaciones.forEach(element => {
        if ((element?.estado === "Incompleta" | element?.estado === undefined) & editEnable !== false) {
            editEnable = true
        } else {
            editEnable = false
        }
        if (element.id_producto_reemplazo) {
            replacement.push(element.id_producto_reemplazo)
        }
    })

    let dataDispatch = []

    listaPrestaciones.forEach(item => {
        if (!replacement.includes(item.id)) {
            item.visibility = 'visible'
            item.togle = true
            dataDispatch.push({...item})
            if (replacement.includes(item.id_producto_reemplazo)) {
                listaPrestaciones.forEach(element => {
                    if (element.id == item.id_producto_reemplazo) {
                        element.togle = false
                        element.visibility = 'hidden'
                        dataDispatch.push({...element})
                    }
                })
            }
        }
    })

    const handleChangeOrder = (index) => {
        setIndexSelectOrder(index);
        getPatientInfoFilter(listOrderPatient[index]);
    }

    const handleDeleteDispatch = () => {
        const item = listOrderPatient[indexSelectOrder]
        api.delete(`api/clinica/pedidosprogramados/anular/solicitud/${item.id}/`).then((response) => {
            setmessageGeneral(dispatch, `Pedido ${item.id} eliminado`, "success", "¡Exito!")
            delete listOrderPatient[indexSelectOrder]
            if(listOrderPatient.length>0){
                handleChangeOrder(listOrderPatient.length-1)
            }
            setopenDeleteModal(false)
        })
    }

    const handleNoteSold = () => {
        const item = listOrderPatient[indexSelectOrder]
        api.get(`api/documentosbodega/notaventa/${item.id}/tipo/Insumos-Medicamentos/`,{ responseType: "blob" }).then((response) => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement("a");
    
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "nota_venta.xlsx");
            document.body.appendChild(fileLink);
    
            fileLink.click();
          })
    
      };

    const headerDetail = [
        { eval: true, label: 'Tipo'},
        { eval: true, label: 'Item' },
        { eval: true, label: 'SKU' },
        { eval: !dispatchDate, label: !dispatchDate ? 'Cantidad pedida' : 'Cantidad enviada/pedida' },
        { eval: false, label: "Reemplazado", hidding: !dispatchDate  },
    ]

    const dataFormat = (row, displayRow=null) => {
        const _typeItem_ =  <Typography>{row.estado == "Reemplazado" ? <s>{row.id_insumo_medicamento__grupo_producto}</s> : row.id_insumo_medicamento__grupo_producto}</Typography>
        const _descroptionItem_ =  <Typography>{row.estado == "Reemplazado"  ? <s>{row.id_insumo_medicamento__descripcion_producto}</s> : row.id_insumo_medicamento__descripcion_producto}</Typography>
        const _SKUItem_ =  <Typography>{row.estado == "Reemplazado"  ? <s>{row.id_insumo_medicamento__SKU}</s> : row.id_insumo_medicamento__SKU}</Typography>
        let _amountItem_ = <Typography>{row.estado == "Reemplazado" ? <s>{row.cantidad}</s> : row.cantidad}</Typography>;

        let calculate = parseInt((row.cantidad-row.cantidad_por_enviar) * 100 / row.cantidad)
        let color = red[500]
        if (calculate > 49 & calculate < 100) {
            color = orange[500]
        }
        else if (calculate > 99) {
            color = green[500]
        }
        _amountItem_ = <Typography>
            <p style={{ color: color, textAlignLast: "center" }}>{row.cantidad - row.cantidad_por_enviar} / {row.cantidad} -  {calculate}%</p>
        </Typography>
        return [
            {eval: true, value: row.id_insumo_medicamento__grupo_producto, _value_: _typeItem_},
            {eval: true, value: row.id_insumo_medicamento__descripcion_producto, _value_: _descroptionItem_},
            {eval: true, value: row.id_insumo_medicamento__SKU, _value_: _SKUItem_},
            {eval: false, value: row.cantidad, _value_: _amountItem_},
            {eval: true, value: displayRow ? true : false, displayRow: displayRow}
        ]
    }

    const replaceItem = dataDispatch.filter((row) => row.id_producto_reemplazo).reduce((acc, row) => ([
        ...acc,
        row.id_producto_reemplazo,
    ]), [])

    const dataDetail = dataDispatch.filter((row) => !replaceItem.includes(row.id)).map((row, index) => {
        const listInfo = dataFormat(row, row.id_producto_reemplazo)
        let subData = [];
        if (row.remplazo){
            subData = row.remplazo.map((subRow, index) => {
                const tempArrow = dataFormat(subRow)
                return {data: tempArrow, displayRow: subRow.id}
            })
        }
        return {
            data: listInfo,
            subData: subData
        }
    })

    return (
        <Grid item container xs={12} style={{ marginTop: "2em", backgroundColor: "#fff", borderRadius: "5px" }}>
            <Grid item container xs={12} style={{ padding: "0 2% 0 2%" }}>
                <Grid item container xs={3}  direction="row" alignContent="flex-start">
                    <Grid item container xs={12} style={{margin: "0 0 20px 0"}}>
                        <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}>
                            Detalle de pedidos en bodega
                        </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Typography gutterBottom style={{ textTransform: "none", margin: "0 0 32px 0" }}>
                            De acuerdo a los registros del paciente puedes revisar aquí despachos los anteriores.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={1}>
                </Grid>
                <Grid item container xs={8}>
                    <Grid item container xs={12}>
                        <Grid item container xs={1} >
                            {indexSelectOrder > 0 ?
                            <Button variant="contained" onClick={() => {
                                    handleChangeOrder(indexSelectOrder-1);
                                }}
                                style={{ width: "105px", height: "46px", color: indigo[500] }}>{"<"}
                            </Button> :
                            <Button variant="contained" style={{ width: "105px", height: "46px", color: indigo[100] }}>{"X"}</Button>
                            }
                        </Grid>
                        <Grid item container xs={10} justify="center">
                            <Button variant="contained" style={{ width: "685px", height: "46px", color: indigo[500] }}>
                            {`${
                                listOrderPatient[indexSelectOrder] ? `PEDIDO ${
                                    listaPrestaciones[0]?.id_solicitud_pedido__tipo_solicitud === "ProgramadoRapido" ?
                                        "RAPIDO " :
                                        listaPrestaciones[0]?.id_solicitud_pedido__tipo_solicitud === "ProgramadoRepetido" ?
                                        "REPETIDO HISTORICO" : ""
                                    }` : ""
                            }  ${listOrderPatient[indexSelectOrder]?.fecha_tentativa_llegada ? moment(listOrderPatient[indexSelectOrder]?.fecha_tentativa_llegada).format("DD/MM/YYYY"): ""} ${`${listOrderPatient[indexSelectOrder]?.id ? `id: ${listOrderPatient[indexSelectOrder]?.id}` : ""}`} ${`${listOrderPatient[indexSelectOrder]?.id_bodega_paciente__nombre ? `- ${listOrderPatient[indexSelectOrder]?.id_bodega_paciente__nombre}` : ""}`}
                            `}
                            </Button>
                        </Grid>
                        <Grid item container xs={1} justify="flex-end">
                            {indexSelectOrder+1 < listOrderPatient.length ?
                            <Button variant="contained"
                                onClick={() => {
                                    handleChangeOrder(indexSelectOrder+1);
                                }}
                                style={{ width: "105px", height: "46px", color: indigo[500] }}>{">"}
                            </Button> :
                            <Button variant="contained" style={{ width: "105px", height: "46px", color: indigo[100] }}>{"X"}</Button>
                            }
                        </Grid>
                    </Grid>

                  
                    <Grid item container xs={12} style={{margin: "10px 0"}}>
                        {permission && permission.includes("editarNV") &&
                        <Button 
                            variant="contained" 
                            color="primary" 
                            style={{ backgroundColor: red[500]}} 
                            onClick={() => setopenDeleteModal(true)}
                        >
                            ELIMINAR PEDIDO
                        </Button>
                        }
                        {permission && permission.includes("editarNV") &&
                        <Button 
                            variant="contained" 
                            color="primary" 
                            style={{ backgroundColor: indigo[500], margin: "0px 10px"}} 
                            onClick={() => setopenEditDispatch(true)}
                        >
                            EDITAR PEDIDO
                        </Button>
                        }
                        <Button 
                            variant="contained" 
                            color="primary" 
                            style={{ backgroundColor: indigo[500], margin: "0px 10px"}} 
                            onClick={() => handleNoteSold()}
                        >
                            DESCARGAR NOTA DE VENTA
                        </Button>
                    </Grid>

                    <Grid item container xs={12}>
                        <TableComponent
                            headers={headerDetail}
                            data={dataDetail}
                            size={'small'}
                            pageSize={5}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {openEditDispatch && 
            <ModalEditDispatch 
                open={!!openEditDispatch}
                closeModal={() => setopenEditDispatch(false)}
                dataDispatch={dataDispatch}
                orderData={listOrderPatient[indexSelectOrder]}
                indexSelectOrder={indexSelectOrder}
                handleChangeOrder={handleChangeOrder}
                openEditDispatch={openEditDispatch}
            />
            }
            {openDeleteModal && (
            <ModalDeleteItemTable
            open={!!openDeleteModal}
            handleClose={() => setopenDeleteModal(null)}
            handleDelete={handleDeleteDispatch}
            title={`Eliminar ${openDeleteModal?.row?.id_insumo_medicamento__SKU || "pedido"}`}
            maxWidth="xs"
            />
            )}
        </Grid>
    )
}

export default DispatchPatientDetail;
