import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import GridItem from "components/Grid/GridItem.js";
import {red, green} from "@material-ui/core/colors";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


export default function UpdateStatistics({open, handleClose, iconSelect, dataFile}) {
    const classes = useStyles();
    let [value, setvalue] = useState(iconSelect[2].value? iconSelect[2].value : "" );

    const updateValue = () => {
        const data = {
            value: value,
            date: moment().format("DD-MM-YYYY"),
        }
        const body = {
            diagnostico: value,
            id_ficha: dataFile.id,
            tipo_diagnostico: "FichaPaciente"
        }
        api.post(`api/diagnostico/`, body).then((result) => {
            iconSelect[5](data);
            handleClose()
        });
    }

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px',
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >

        <Paper style={{padding: "2% 4% 3%"}}>
            <h3 id="spring-modal-title">{`Editar ${iconSelect[0]}`}</h3>
            <GridItem
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                {iconSelect[6] == "str" ?
                    <DetailText
                        label={`${iconSelect[1]} ${iconSelect[3] !== "" ? `(${iconSelect[3]})` : ""}`}
                        placeholder={`${iconSelect[1]} ${iconSelect[3] !== "" ? `(${iconSelect[3]})` : ""}`}
                        value={value}
                        onchange={(e) => { setvalue(e.target.value)}}
                    />
                : iconSelect[6] == "int" &&
                    <DetailNumber
                        label={`${iconSelect[1]} ${iconSelect[3] !== "" ? `(${iconSelect[3]})` : ""}`}
                        value={value}
                        onchange={(e) => { setvalue(e.target.value)}}
                    />
                }

            </GridItem>
            <GridItem
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                {value !== "" ?
                <Button
                    mr={2}
                    variant="contained"
                    className="text-white"
                    style={{backgroundColor: green[500]}}
                    onClick={updateValue}
                >
                    Guardar
                </Button>
                :
                <Button
                    mr={2}
                    variant="contained"
                    disabled
                >
                    Guardar
                </Button>
                }
                <Button
                    mr={2}
                    variant="contained"
                    style={{backgroundColor: red[500], marginRight: "10px"}}
                    className="text-white"
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
            </GridItem>
        </Paper>
      </Modal>
  );
}