import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Calendar, momentLocalizer } from "react-big-calendar";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
  Typography,
  Checkbox,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import api from "utils/API";
import moment from "moment";
import ModalScheduleTime from "components/ClinicalRequests/ModalScheduleTime";
moment.locale("es-es", {
  week: {
    dow: 0, //Monday is the first day of the week.
  },
});
const localizer = momentLocalizer(moment);

const classStyle = makeStyles((theme) => ({
  text: {
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    "&:hover": {
      color: indigo[400],
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const eventColors = (event) => {
  var backgroundColor = "event-";
  event.color
    ? (backgroundColor = backgroundColor + event.color)
    : (backgroundColor = backgroundColor + "default");
  return {
    className: backgroundColor,
  };
};

const SelectedRequest = ({
  selectRowDetails,
  handleUpdateState,
}) => {
  const classesStyle = classStyle();
  const [initialCharge, setinitialCharge] = useState(true);
  const [selectDate, setselectDate] = useState(moment());
  const [professional, setprofessional] = useState([]);
  const [checkedProfessional, setcheckedProfessional] = useState([]);
  const [dictProfessionalWorker, setdictProfessionalWorker] = useState([]);
  const [reservationRow, setreservationRow] = useState([]);
  const [events, setevents] = useState([]);
  const [eventsWeek, seteventsWeek] = useState([]);
  const [eventsMonth, seteventsMonth] = useState([]);
  const [calendarView, setcalendarView] = useState("month");
  const [updateView, setUpdateView] = useState(false);
  const [updateChecked, setupdateChecked] = useState(false);
  const [selectedModule, setselectedModule] = useState(null);
  const [modeView, setmodeView] = useState("agendará");
  const [checkOtherReservation, setcheckOtherReservation] = useState(false);
  const [isapre, setisapre] = useState(null);

  useEffect(() => {
    if (initialCharge) {
      initialData();
    }
    if (updateChecked) {
      setupdateChecked(false);
      getArrayDatePeople();
    }

    if (updateView) {
      setUpdateView(false);
    }
  });

  const initialData = () => {
    setinitialCharge(false);
    const id_patient = selectRowDetails[0].id_ficha.id_paciente.id;
    const typeProffesional =
      window.localStorage.getItem("tipo_perfil") === "RehabJefeDepto"
        ? "ENFJefeDepto"
        : "RehabJefeDepto";
    const requestProfessional = api.get(`/api/usuario/dependientes/`);
    const requestProfessionalWork = api.get(
      `/api/clinica/tareaspaciente/logeado/dependientes/`
    );
    const requestOtherBoss = api.get(
      `/api/usuario/tipoperfil/${typeProffesional}`
    );
    const requestWorkOtherBoss = api.get(
      `/api/clinica/tareaspaciente/tipoperfil/${typeProffesional}`
    );
    const requestpackage = api.get(
      `/api/clinica/pedidosprogramados/paquetepaciente/paciente/${id_patient}`
    );
    Promise.all([
      requestProfessional,
      requestProfessionalWork,
      requestOtherBoss,
      requestWorkOtherBoss,
      requestpackage,
    ]).then((request) => {
      const id_boss = selectRowDetails[0].id_usuarios.id;
      let flagBoss = false;
      let tempProfessional = [];
      const tempProfessionalWorker = {};
      const tempCheckProfessionalId = [];
      let tempCheckOtherReservation = false;
      const requestWorkerArray = [];
      let tempReservation = {};

      if (
        selectRowDetails[0].fecha_final &&
        selectRowDetails[0].estado === "EsperandoConfirmacion"
      ) {
        setselectDate(moment(selectRowDetails[0].fecha_hora));
        setcalendarView("week");
        setmodeView("editará");
      }

      request[1].data.map((row) => {
        if (tempProfessionalWorker[row.id_usuarios_id]) {
          tempProfessionalWorker[row.id_usuarios_id].push(row);
        } else {
          tempProfessionalWorker[row.id_usuarios_id] = [row];
        }

        if (selectRowDetails[0].id === row.id_tarea_padre_id) {
          tempCheckProfessionalId.push(row.id_usuarios_id);
        }

        if (
          selectRowDetails[0].id !== row.id &&
          row.id_solicitud_paciente_id ===
            selectRowDetails[0].id_solicitud_paciente.id &&
          row.fecha_final
        ) {
          tempCheckOtherReservation = true;
          setselectDate(moment(row.fecha_hora));
          setcalendarView("week");
          tempCheckProfessionalId.push(row.id_usuarios_id);
        }

        if (
          !requestWorkerArray.includes(row.id_solicitud_paciente_id) &&
          row.fecha_final
        ) {
          requestWorkerArray.push(row.id_solicitud_paciente_id);
        }
      });
      request[3].data.map((row) => {
        if (
          !requestWorkerArray.includes(row.id_solicitud_paciente_id) &&
          row.fecha_final
        ) {
          if (tempProfessionalWorker[row.id_usuarios_id]) {
            tempProfessionalWorker[row.id_usuarios_id].push(row);
          } else {
            tempProfessionalWorker[row.id_usuarios_id] = [row];
          }
          requestWorkerArray.push(row.id_solicitud_paciente_id);
        }

        if (selectRowDetails[0].id === row.id_tarea_padre_id) {
          tempCheckProfessionalId.push(row.id_usuarios_id);
        }
        if (
          selectRowDetails[0].id !== row.id &&
          row.id_solicitud_paciente_id ===
            selectRowDetails[0].id_solicitud_paciente.id &&
          row.fecha_final
        ) {
          tempCheckOtherReservation = true;
          setselectDate(moment(row.fecha_hora));
          setcalendarView("week");
          tempReservation = { ...row };
          tempCheckProfessionalId.push(row.id_usuarios_id);
        }
      });

      tempProfessional = [
        ...request[2].data.map((row) => {
          if (tempCheckProfessionalId.includes(row.id)) {
            return { ...row, visible: true, check: true };
          } else {
            return { ...row, visible: true, check: tempCheckOtherReservation };
          }
        }),
      ];
      request[0].data.map((row) => {
        const tempRow = { ...row };
        tempRow["visible"] = true;
        tempRow["check"] = false;
        if (id_boss === row.id) {
          tempRow["visible"] = false;
          tempRow["check"] = true;
          flagBoss = true;
        }

        if (tempCheckProfessionalId.includes(row.id)) {
          tempRow["check"] = true;
        }

        tempProfessional.push(tempRow);
      });
      if (!flagBoss) {
        tempProfessional.push({
          ...selectRowDetails[0].id_usuarios,
          visible: false,
          check: true,
          cargo_id: { ...selectRowDetails[0].id_cargo_asignado },
        });
      }
      const tempCheckedProfessional = tempProfessional.filter(
        (row) => row.check
      );
      setcheckOtherReservation(tempCheckOtherReservation);
      setcheckedProfessional(tempCheckedProfessional);
      setprofessional(tempProfessional);
      setdictProfessionalWorker(tempProfessionalWorker);
      setreservationRow(tempReservation);
      setisapre(request[4].data.id_convenio_isapre[0]);
      setupdateChecked(true);

    });
  };

  const getArrayDatePeople = () => {
    const tempArrayDate = [];
    const tempArrayMonth = [];
    const tempDictMonth = {};
    checkedProfessional.map((row) => {
      const tempWork = dictProfessionalWorker[row.id];
      if (tempWork) {
        tempWork.map((subRow) => {
          if (
            subRow.id_tarea_padre_id === null &&
            subRow.fecha_final !== null &&
            reservationRow.id !== subRow.id
          ) {
            tempArrayDate.push({
              title:
                subRow.id === selectRowDetails[0].id &&
                subRow.estado === "EsperandoConfirmacion"
                  ? "Reserva actual"
                  : (subRow.id === selectRowDetails[0].id &&
                      subRow.estado === "EsperandoConfirmacion") ||
                    subRow.id_solicitud_paciente_id ===
                      selectRowDetails[0].id_solicitud_paciente.id
                  ? "Programar evaluación inicial"
                  : "Horario ocupado",
              start: moment(subRow.fecha_hora).toDate(),
              end: moment(subRow.fecha_final).toDate(),
              color:
                (subRow.id === selectRowDetails[0].id &&
                  subRow.estado === "EsperandoConfirmacion") ||
                subRow.id_solicitud_paciente_id ===
                  selectRowDetails[0].id_solicitud_paciente.id
                  ? "orange"
                  : "red",
            });
            if (
              tempDictMonth[moment(subRow.fecha_hora).format("YYYY-MM-DD")] ===
              undefined
            ) {
              tempDictMonth[moment(subRow.fecha_hora).format("YYYY-MM-DD")] = 1;
            } else {
              tempDictMonth[
                moment(subRow.fecha_hora).format("YYYY-MM-DD")
              ] += 1;
            }
          }
        });
      }
    });

    if (reservationRow.fecha_final !== null) {
      tempArrayDate.push({
        title: "Programar evaluación inicial",
        start: moment(reservationRow.fecha_hora).toDate(),
        end: moment(reservationRow.fecha_final).toDate(),
        color: "orange",
      });
    }

    Object.keys(tempDictMonth).map((row) => {
      tempArrayMonth.push({
        allDay: true,
        title: `Nº reservas: ${tempDictMonth[row]}`,
        start: moment(row).toDate(),
        end: moment(row).toDate(),
        color: "red",
      });
    });
    if (calendarView === "month") {
      setevents(tempArrayMonth);
    } else {
      setevents([...tempArrayMonth, ...tempArrayDate]);
    }
    seteventsWeek(tempArrayDate);
    seteventsMonth(tempArrayMonth);
    setUpdateView(true);
  };

  const handleViewCalendar = (view) => {
    setcalendarView(view);
    if (view === "month") {
      setevents(eventsMonth);
    } else {
      setevents([...eventsMonth, ...eventsWeek]);
    }
  };

  const handleSelectEvent = (event) => {
    if (calendarView === "month") {
      setselectDate(moment(event.start));
      setcalendarView("week");
      setevents([...eventsMonth, ...eventsWeek]);
      setUpdateView(true);
    } else {
      if (checkedProfessional.length > 0 && event.color == "orange") {
        setselectedModule(event);
      }
    }
  };

  const handleSelectSlot = (event) => {
    if (calendarView === "month") {
      setselectDate(moment(event.start));
      setcalendarView("week");
      setevents([...eventsMonth, ...eventsWeek]);
      setUpdateView(true);
    } else {
      if (checkedProfessional.length > 0 && !checkOtherReservation) {
        setselectedModule(event);
      }
    }
  };

  const handleNavigation = (date) => {
    setselectDate(moment(date));
  };

  const handleChange = (checked, index) => {
    const tempProfessional = professional;
    tempProfessional[index].check = checked;
    const tempCheckedProfessional = tempProfessional.filter((row) => row.check);
    setcheckedProfessional(tempCheckedProfessional);
    setprofessional(tempProfessional);
    setupdateChecked(true);
  };

  return (
    <Card>
      <CardBody>
        {updateView && <div></div>}
        <GridItem
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          xs={12}
        >
          <GridItem
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={6}
          >
            <Typography
              color="textPrimary"
              variant="body2"
            >{`Paciente: ${selectRowDetails[0].id_ficha.id_paciente.nombre} ${selectRowDetails[0].id_ficha.id_paciente.apellido_paterno} ${selectRowDetails[0].id_ficha.id_paciente.apellido_materno}`}</Typography>
          </GridItem>
          <GridItem
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={6}
          >
            <Typography color="textPrimary" variant="body2">{`Isapre: ${
              isapre ? isapre.nombre : "Sin isapre"
            }`}</Typography>
          </GridItem>
        </GridItem>
        <GridItem
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          xs={12}
        >
          <Typography
            style={{ textTransform: "none" }}
            color="textPrimary"
            variant="h6"
          >
            1. Seleccione los profesionales
          </Typography>
        </GridItem>
        <GridItem
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
        >
          <Card>
            <CardBody style={{ height: "120px", overflow: "auto" }}>
              <GridItem
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                xs={12}
                sm={12}
              >
                {professional.map(
                  (row, index) =>
                    row.visible && (
                      <GridItem
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        xs={12}
                        sm={6}
                        key={`${index}-profesional`}
                      >
                        <GridItem
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          xs={1}
                        >
                          <Checkbox
                            value="remember"
                            checked={row.check}
                            onChange={(event) =>
                              handleChange(event.target.checked, index)
                            }
                            color="primary"
                          />
                        </GridItem>
                        <GridItem
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                          xs={11}
                        >
                          <Typography
                            style={{ textTransform: "none" }}
                            color="textPrimary"
                            variant="body2"
                          >
                            {`${row.cargo_id.nombre_corto} - ${row.first_name} ${row.last_name}`}
                          </Typography>
                        </GridItem>
                      </GridItem>
                    )
                )}
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          xs={12}
        >
          <Typography
            style={{ textTransform: "none" }}
            color="textPrimary"
            variant="h6"
          >
            2. Seleccione una fecha y hora
          </Typography>
        </GridItem>
        <GridItem
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
        >
          <div style={{ width: `100%` }}>
            <Calendar
              selectable
              localizer={localizer}
              events={events}
              defaultView={"month"}
              min={moment(moment().format("YYYY-MM-DD"))
                .add(8, "hours")
                .toDate()}
              max={moment(moment().format("YYYY-MM-DD"))
                .add(20, "hours")
                .toDate()}
              date={selectDate.toDate()}
              step={30}
              view={calendarView}
              onSelectEvent={(event) => handleSelectEvent(event)}
              onSelectSlot={(slotInfo) => handleSelectSlot(slotInfo)}
              onView={(view) => handleViewCalendar(view)}
              onNavigate={(date) => handleNavigation(date)}
              eventPropGetter={eventColors}
              messages={{
                next: "Siguiente",
                previous: "Anterior",
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día",
              }}
              timeslots={1}
            />
          </div>
        </GridItem>
      </CardBody>
      {selectedModule && checkedProfessional.length > 0 && (
        <ModalScheduleTime
          open={!!selectedModule}
          handleClose={() => setselectedModule(null)}
          selectedModule={selectedModule}
          professional={checkedProfessional}
          selectRowDetails={selectRowDetails}
          setupdateChecked={setupdateChecked}
          setselectedModule={setselectedModule}
          handleUpdateState={handleUpdateState}
          modeView={modeView}
        />
      )}
    </Card>
  );
};

export default SelectedRequest;
