import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { orange, blue, green, indigo } from "@material-ui/core/colors";
import {
  Typography,
  Grid,
  Tooltip,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import {
  Delete,
  Edit,
  ArrowDropDown,
  ArrowDropUp,
  AssignmentTurnedIn
} from "@material-ui/icons";

import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import ModalSVPeso from "./MedicalIndications/ModalSVPeso";
import ModalBalance from "./MedicalIndications/ModalBalance";
import ModalGeneral from "./MedicalIndications/ModalGeneral";
import ModalEstimulaciones from "./MedicalIndications/ModalEstimulaciones";
import { checkIndications } from "./MedicalIndications/IndicationsCheck";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


const useStylesTable = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});
const useStyles = makeStyles(styles);

// eslint-disable-next-line react/prop-types
export default function WorkTable({
  dataArray,
  setdata,
  setselectRemoveWorkRow,
  seteditWork,
  setendVisit,
  setselectUpdateState,
  complete,
  setsizePatient,
  settemperature,
  temperature,
  setweight,
  setheartRate,
  setbreathingFrequency,
  setoxygenSaturation,
  setbloodPressureSystolic,
  setbloodPressureDiastolic,
  setbloodPressureMean,
  setpain,
  setallergy,
  handleDiagnosis,
  setUpdatePage
}) {
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [order, setorder] = useState("Fecha");
  const [idWork, setidWork] = useState("");
  const [idRequest, setidRequest] = useState("");
  const [type, settype] = useState({});
  const [date, setdate] = useState(null);
  const [assing, setassing] = useState({});
  const [user, setuser] = useState("");
  const [descript, setdescript] = useState("");
  const [direction, setdirection] = useState(false);
  const [listAssing, setlistAssing] = useState([]);
  const [listType, setlistType] = useState([]);
  const [initialFilter, setinitialFilter] = useState(true);
  const [openModalSVPeso, setOpenModalSVPeso] = useState(false);
  const [openModalBalanceHidrico, setOpenModalBalanceHidrico] = useState(false);
  const [openModalGeneral, setOpenModalGeneral] = useState(false);
  const [openModalEstimulaciones, setOpenModalEstimulaciones] = useState(false);
  const [tempRowModal, setTempRowModal] = useState({});
  const [tempIndex, setTempIndex] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const dictCharges = useSelector(state => state.currentList.dictCharges);
  const dictTypeWorkFile = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA);

  const type_user = localStorage.getItem("tipo_perfil");

  const completeStateArray = [
    "Completado",
    "Realizada",
    "RealizadaAtraso",
    "NoRealizada",
    "CanceladaPaciente"
  ];

  const incompleteStateArray = ["Incompleto", "PorRealizar"];

  const arrayTypeNoDeleteEdit = [
    "IndicacionesMedicas",
    "DescompIndicacionesMedicas",
    "DescompSOS", 
    "DescompAlimentacion", 
    "DescompMedHab",
    "DescompRespiratorio",
    "Asignacion",
    "VisitaInicial",
    "HabilitacionDomicilio",
    "DescompensacionNoTens",
    "ExamenAsignarTens",
    "ExamenAsignarNoTens",
    "AsignarEspecialistasTENS",
    "AsignarEspecialistasNoTENS",
    "Telemedicina",
    "D",
    "N",
    "T",
    "OD",
    "ON",
    "ID",
    "IN",
    "RD",
    "RN"
  ];

  const arrayUTC = [
    "DescompIndicacionesMedicas",
    "DescompSOS", 
    "DescompAlimentacion", 
    "DescompMedHab",
    "DescompRespiratorio"
  ]

  const arrayTypeNoCheck = [
    "DescompIndicacionesMedicas",
    "IndicacionesMedicas",
    "Asignacion",
    "HabilitacionDomicilio",
    "ExamenAsignarTens",
    "ExamenAsignarNoTens",
    "AsignarEspecialistasTENS",
    "AsignarEspecialistasNoTENS",
    "Telemedicina",
    "D",
    "N",
    "T",
    "OD",
    "ON",
    "ID",
    "IN",
    "RD",
    "RN"
  ];
  
  useEffect(() => {
    if (initialFilter) {
      updateList();
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateList = () => {
    setinitialFilter(false);
    const tempType = [];
    const tempAssing = [];
    dataArray.map(row => {
      tempType.push(row.tipo_tarea);
      tempAssing.push(row.id_cargo_asignado);
    });
    const auxType = tempType.map(row => {
      return { value: row, label: dictTypeWorkFile[row]?.label };
    });
    const auxAssing = Array.from(new Set(tempAssing)).map(row => {
      return {
        value: row,
        label: dictCharges[row]?.nombre_corto
      };
    });
    auxType.push({ value: null, label: "Sin Filtro" });
    auxAssing.push({ value: null, label: "Sin Filtro" });
    handleOrder("Fecha", "fecha_hora");
    setlistType(auxType);
    setlistAssing(auxAssing.filter(row => row.label !== undefined));
  };

  const handleOnChange = (value, set) => {
    set(value);
    setPage(0)
  };

  const handleOrder = (value, attribute) => {
    let tempDirection = direction;
    if (value === order) {
      tempDirection = !direction;
    } else {
      tempDirection = false;
    }

    if (attribute === "usuario"){
      if (tempDirection) {
        setdata(dataArray.sort((a, b) => (`${a.usuario?.first_name} ${a.usuario?.last_name} ${a.usuario?.second_last_name}` < `${b.usuario?.first_name} ${b.usuario?.last_name} ${b.usuario?.second_last_name}` ? 1 : -1)));
      } else {
        setdata(dataArray.sort((a, b) => (`${a.usuario?.first_name} ${a.usuario?.last_name} ${a.usuario?.second_last_name}` > `${b.usuario?.first_name} ${b.usuario?.last_name} ${b.usuario?.second_last_name}` ? 1 : -1)));
      }
    }
    else {
      if (tempDirection) {
        setdata(dataArray.sort((a, b) => (a[attribute] < b[attribute] ? 1 : -1)));
      } else {
        setdata(dataArray.sort((a, b) => (a[attribute] > b[attribute] ? 1 : -1)));
      }
    }
    setorder(value);
    setdirection(tempDirection);
  }

  const headerColumn = (
    atributteName,
    headerName,
    value,
    set,
    style = {},
    list = null,
    typeData
  ) => {
    return (
      <Tooltip
        title={
          <React.Fragment>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography variant="body2" gutterBottom>
                {`Ordenar por ${headerName}`}
              </Typography>
            </Grid>
          </React.Fragment>
        }
      >
        <TableCell
          className="text-center align-top"
          style={{ minWidth: "85px", ...style }}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              item
              xs={10}
              onClick={() => handleOrder(headerName, atributteName)}
            >
              {headerName} 
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              item
              xs={2}
              onClick={() => handleOrder(headerName, atributteName)}
            >
              {order === headerName && (
                <ArrowDropUp
                  style={{
                    margin: "-15px 0 -15px 0",
                    fill: indigo[direction ? 900 : 200]
                  }}
                />
              )}
              <ArrowDropDown
                style={{
                  margin: order === headerName ? "0 0 -15px 0" : "0",
                  fill: indigo[order === headerName && !direction ? 900 : 200]
                }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              item
              xs={12}
              style={{ padding: order === headerName ? "3px 0 0 0" : "0" }}
            >
              {list ? (
                <DetailSelect
                  style={{ padding: "0" }}
                  value={value}
                  onchange={value => handleOnChange(value, set)}
                  list={list}
                  validation={false}
                />
              ) : typeData == "String" ? (
                <DetailText
                  value={value}
                  onchange={e => handleOnChange(e.target.value, set)}
                  validation={false}
                  style={{ padding: "0" }}
                />
              ) : (
                <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                  <div style={{padding: "5px 0 0 0"}}>
                  <KeyboardDatePicker
                    // variant="outlined"
                    clearable
                    format="DD-MM-YYYY"
                    margin="normal"
                    id="date-picker-inline1"
                    inputVariant="outlined"
                    value={value}
                    onChange={(e) => handleOnChange(e, set)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    InputProps={{
                      style: {
                          height: "38px"
                      }
                    }}
                    style={{height: "38px"}}
                  />
                  </div>
                </MuiPickersUtilsProvider>
              )}
            </Grid>
          </Grid>
        </TableCell>
      </Tooltip>
    );
  };

  const handleChange = (checked, row) => {
    setselectUpdateState(row);
  };

  const dataArrayFiltered = dataArray.filter(
    row =>
      (complete
        ? completeStateArray.includes(row.estado)
        : incompleteStateArray.includes(row.estado)) &&
      ((idWork !== "" && row.id.toString().includes(idWork)) || idWork == "") &&
      ((idRequest !== "" && row.id_solicitud_paciente
        ? row.id_solicitud_paciente.toString().includes(idRequest)
        : false) ||
        idRequest == "") &&
      ((type.value && type.value === row.tipo_tarea) ||
        (type.value === undefined || type.value === null)) &&
      (((date != "" || date != null)  &&
        moment(row.fecha_hora).format("DD-MM-YYYY") ===
          moment(date).format("DD-MM-YYYY")) ||
        (date == "" || date == null)) &&
      ((assing.value && assing.value === row.id_cargo_asignado) ||
        (assing.value === undefined || assing.value === null)) &&
      ((descript && row.descripcion.toLowerCase().includes(descript)) || descript == "") &&
      ((user && `${row.usuario?.first_name} ${row.usuario?.last_name} ${row.usuario?.second_last_name}`.toLowerCase().includes(user)) || user == "")
  );

  return (
    <Paper className={classesTable.root}>
      <TableContainer className={classesTable.container} style={{height: "440px"}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumn(
                "id",
                "id",
                idWork,
                setidWork,
                { width: "90px" },
                null,
                "String"
              )}
              {headerColumn(
                "id_solicitud_paciente",
                "Id Solicitud",
                idRequest,
                setidRequest,
                { width: "125px", minWidth: "125px"},
                null,
                "String"
              )}
              {headerColumn(
                "tipo_tarea",
                "Tipo",
                type,
                settype,
                { minWidth: "130px" },
                listType
              )}
              {headerColumn(
                "fecha_hora",
                "Fecha",
                date,
                setdate,
                { minWidth: "150px", zIndex: 9999 },
                null,
                "Time"
              )}
              {headerColumn(
                "id_cargo_asignado",
                "Cargo",
                assing,
                setassing,
                { minWidth: "150px" },
                listAssing
              )}
              {headerColumn(
                "usuario",
                "Asignada a",
                user,
                setuser,
                { minWidth: "150px" },
                null,
                "String"
              )}
              {headerColumn(
                "descripcion",
                "Descripción",
                descript,
                setdescript,
                { minWidth: "150px" },
                null,
                "String"
              )}
              <TableCell style={{minWidth: "200px"}} className="text-center align-top">
                Acciones 
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataArrayFiltered
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={`${index}-workRows`}
                >
                  <TableCell className="text-center align-middle">
                    {row.id}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row?.id_solicitud_paciente}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {dictTypeWorkFile[row.tipo_tarea]?.label}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {arrayUTC.includes(row.tipo_tarea) ? 
                    moment(row.fecha_hora).utc().format("DD-MM-YYYY HH:mm") :
                    moment(row.fecha_hora).format("DD-MM-YYYY HH:mm")
                  }
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {dictCharges[row.id_cargo_asignado]
                      ? dictCharges[row.id_cargo_asignado].nombre_corto
                      : "-"}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.usuario ? 
                      `${row.usuario?.first_name} ${row.usuario?.last_name} ${row.usuario?.second_last_name}` : "-"}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {(row.tipo_tarea === "IndicacionesMedicas" || row.tipo_tarea === "DescompIndicacionesMedicas") 
                      ? row.motivo_rechazo === "SignosVitales"
                        ? checkIndications(
                            row,
                            setOpenModalSVPeso,
                            setTempRowModal,
                            index,
                            setTempIndex
                          )
                        : row.motivo_rechazo === "BalanceHidrico"
                        ? checkIndications(
                            row,
                            setOpenModalBalanceHidrico,
                            setTempRowModal,
                            index,
                            setTempIndex
                          )
                        : row.motivo_rechazo === "General"
                        ? checkIndications(
                            row,
                            setOpenModalGeneral,
                            setTempRowModal,
                            index,
                            setTempIndex
                          )
                        : row.motivo_rechazo === "Estimulaciones"
                        ? checkIndications(
                            row,
                            setOpenModalEstimulaciones,
                            setTempRowModal,
                            index,
                            setTempIndex
                          )
                        : null
                      : row.descripcion}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    <Grid 
                      container
                      xs={12}
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      {!arrayTypeNoCheck.includes(row.tipo_tarea) && 
                        <Checkbox
                          value="remember"
                          checked={row.estado === "Completado" ? true : false}
                          onChange={event =>
                            handleChange(event.target.checked, row)
                          }
                          color="primary"
                          style={{margin: "0 5px"}}
                        />
                      }
                      {(arrayTypeNoCheck.includes(row.tipo_tarea) && row.tipo_tarea !== "IndicacionesMedicas" && row.tipo_tarea !== "DescompIndicacionesMedicas") ? (
                      <Button
                        justIcon
                        round
                        size="sm"
                        style={{ background: indigo[500], margin: "0 5px" }}
                        onClick={() => setendVisit(row)}
                      >
                        <AssignmentTurnedIn className={classes.icons} />
                      </Button>
                    ) : (!arrayTypeNoDeleteEdit.includes(row.tipo_tarea) && type_user !== "CLITecEnfBas") ? (
                      <Button
                        justIcon
                        round
                        size="sm"
                        style={{ background: indigo[500], margin: "0 5px" }}
                        onClick={() => seteditWork([index])}
                      >
                        <Edit className={classes.icons} />
                      </Button>
                    ) : null}
                    {(!arrayTypeNoDeleteEdit.includes(row.tipo_tarea) && type_user !== "CLITecEnfBas" ) && (
                      <Button
                        justIcon
                        round
                        size="sm"
                        style={{ background: indigo[500], margin: "0 5px" }}
                        onClick={() => setselectRemoveWorkRow(row)}
                      >
                        <Delete className={classes.icons} />
                      </Button>
                    )}

                    </Grid>
                    
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dataArrayFiltered.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {openModalSVPeso && (
        <ModalSVPeso
          openModal={openModalSVPeso}
          closeModal={() => setOpenModalSVPeso(false)}
          tempRowModal={tempRowModal}
          handleChange={handleChange}
          tempIndex={tempIndex}
          setsizePatient={setsizePatient}
          settemperature={settemperature}
          temperature={temperature}
          setweight={setweight}
          setheartRate={setheartRate}
          setbreathingFrequency={setbreathingFrequency}
          setoxygenSaturation={setoxygenSaturation}
          setbloodPressureSystolic={setbloodPressureSystolic}
          setbloodPressureDiastolic={setbloodPressureDiastolic}
          setbloodPressureMean={setbloodPressureMean}
          setpain={setpain}
          setallergy={setallergy}
          handleDiagnosis={handleDiagnosis}
          setUpdatePage={setUpdatePage}
        />
      )}
      {openModalBalanceHidrico && (
        <ModalBalance
          openModal={openModalBalanceHidrico}
          closeModal={() => setOpenModalBalanceHidrico(false)}
          tempRowModal={tempRowModal}
          handleChange={handleChange}
          tempIndex={tempIndex}
        />
      )}
      {openModalGeneral && (
        <ModalGeneral
          openModal={openModalGeneral}
          closeModal={() => setOpenModalGeneral(false)}
          tempRowModal={tempRowModal}
          handleChange={handleChange}
          tempIndex={tempIndex}
        />
      )}
      {openModalEstimulaciones && (
        <ModalEstimulaciones
          openModal={openModalEstimulaciones}
          closeModal={() => setOpenModalEstimulaciones(false)}
          tempRowModal={tempRowModal}
          handleChange={handleChange}
          tempIndex={tempIndex}
        />
      )}
    </Paper>
  );
}
