import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";
import { useParams } from "react-router-dom";
import ModalEditedRequest from "views/Warehouse/Requests/ModalEditedRequest";
import ModalAceptRequest from "./ModalAcepRequest";
import ModalRejectRequest from "./ModalRejectRequest";
import ModalCancelRequest from "./ModalCancelRequest";



export default function DivisionRequest() {
  const { id } = useParams();
  const [moduleType, setmoduleType] = useState("");
  const [supplies, setSupplies] = useState([]);
  const [group, setGroup] = useState("");
  const [group_final, setGroup_final] = useState("");
  const [sku, setSKU] = useState("");
  const [id_sku, setId_sku] = useState("");
  const [sku_final, setSKU_final] = useState("");
  const [id_sku_final, setId_sku_final] = useState("");
  const [description, setDescription] = useState("");
  const [description_final, setDescription_final] = useState("");
  const [cantidadFraccionar, setCantidadFraccionar] = useState(0);
  const [cantidad_resultante, setCantidad_resultante] = useState(0);
  const [observaciones, setObservaciones] = useState("");

  const [modalSaved, setModalSaved] = useState(false);
  const [select ,setSelect] = useState("");
  const [modalAceptRequest, setModalAceptReqest] = useState(false);
  const [modalRejectRequest, setModalRejectRequest] = useState(false);
  const [divisionRequest, setDivisionRequest] = useState([]);
  const [modalCancelRequest, setModalCancelRequest] = useState(false);

  let listMedicamentos = [];
  let listEquipamiento = [];
  let listInsumos = [];

  useEffect(() => {

    const tempArrayURL = window.location.href.split('/');
    setmoduleType(tempArrayURL[tempArrayURL.length-2]);
    api.get(`api/solicitudfraccionamiento/${id}`).then(response => {
      setDivisionRequest(response.data);
      console.log("response", response.data);
      setObservaciones(response.data.observaciones);
      setCantidad_resultante(response.data.cantidad_resultante);
      setCantidadFraccionar(response.data.cantidad_fraccionar);
      setId_sku(response.data.id_producto_fraccionar.id);
      setId_sku_final(response.data.id_producto_resultante.id);
      setSKU({
        value: response.data.id_producto_fraccionar.SKU,
        label: response.data.id_producto_fraccionar.SKU
      });
      setSKU_final({
        value: response.data.id_producto_resultante.SKU,
        label: response.data.id_producto_resultante.SKU
      });
      setGroup(response.data.id_producto_fraccionar.grupo_producto);
      setDescription(response.data.id_producto_fraccionar.descripcion_producto);
      setId_sku(response.data.id_producto_fraccionar.id);

      setGroup_final(response.data.id_producto_resultante.grupo_producto);
      setDescription_final(
        response.data.id_producto_resultante.descripcion_producto
      );
      setId_sku_final(response.data.id_producto_resultante.id);

    });
  }, []);

  useEffect(() => {
    supplies
      .filter(supply => supply.SKU === sku.value)
      .map(filtered => {
        if (filtered.grupo_producto === "Medicamento") {
          supplies
            .filter(
              supply =>
                supply.grupo_producto === "Medicamento" &&
                supply.SKU !== sku.value
            )
            .map(filtered => {
              listMedicamentos.push({
                value: filtered.SKU, label: filtered.SKU
              });
            });
          setSelect(filtered.grupo_producto);
        }
        if (["Insumos", "Articulos", "Alimentos"].includes(filtered.grupo_producto)) {
          supplies
            .filter(
              supply =>
              ["Insumos", "Articulos", "Alimentos"].includes(supply.grupo_producto) && supply.SKU !== sku.value
            )
            .map(filtered => {
              listInsumos.push({ value: filtered.SKU, label: filtered.SKU });
            });
          setSelect(filtered.grupo_producto);
        }
        if (filtered.grupo_producto === "Equipamiento") {
          supplies
            .filter(
              supply =>
                supply.grupo_producto === "Equipamiento" &&
                supply.SKU !== sku.value
            )
            .map(filtered => {
              listEquipamiento.push({
                value: filtered.SKU,
                label: filtered.SKU
              });
            });
          setSelect(filtered.grupo_producto);
        }
      });
  });

  return (
    <GridContainer>
      <ViewTitle
        title="DETALLE FRACCIONAMIENTO"
        message="En esta sección podrás administrar la solicitud del fraccionamiento seleccionado."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/outcome/tasks/${moduleType}`}>
              Tareas de egresos
            </Link>
            <Link color="inherit" to={`/admin/outcome/divisions/${moduleType}`}>
              Fraccionamiento - Retiros
            </Link>
            <Typography color="textPrimary">Detalle fraccionamiento</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardBody className="mb-3">
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <Grid item xs={4} align="center" />
                <Grid item xs={4} align="center">
                  <h4 className="font-weight-bold mb-3">SKU a fraccionar</h4>
                </Grid>
                <Grid item xs={4} />
                <DetailTextDisabled label="GRUPO:" value={group} />
                <DetailTextDisabled label="SKU:" value={sku.value} />
                <DetailTextDisabled label="DESCRIPCIÓN:" value={description} />
                <DetailTextDisabled
                  label="CANTIDAD A FRACCIONAR:"
                  value={cantidadFraccionar}
                />
              </Grid>
            </Grid>
            <Divider className="mb-4 mt-3" />
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <Grid item xs={4} align="center" />
                <Grid item xs={4} align="center">
                  <h4 className="font-weight-bold mb-3">SKU resultante</h4>
                </Grid>
                <Grid item xs={4} />
                <DetailTextDisabled label="SKU:" value={sku_final.value} />
                <DetailTextDisabled label="GRUPO:" value={group_final} />
                <DetailTextDisabled
                  label="DESCRIPCIÓN:"
                  value={description_final}
                />
                <DetailTextDisabled
                  label="CANTIDAD RESULTANTE:"
                  value={cantidad_resultante}
                />
              </Grid>
            </Grid>
            <Divider className="mb-4 mt-3" />
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item xs={8}>
                <div className="col-md-12 mb-3">
                  <label className="text-dark">OBSERVACIONES</label>
                  <textarea
                    className="form-control bg-light font-weight-lighter"
                    style={{ color: "#000000" }}
                    value={observaciones}
                    disabled
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                {divisionRequest.estado === "Finalizado" ? (
                  <Link to={`/admin/income/divisions/${moduleType}`}>
                    <Button variant="contained" color="primary">
                      Atrás
                    </Button>
                  </Link>
                ) : divisionRequest.estado === "Esperar a Ingresos" ? (
                  <Link to={`/admin/income/divisions/${moduleType}`}>
                    <Button variant="contained" color="primary">
                      Atrás
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalAceptReqest(true)}
                  >
                    Aceptar fraccionamiento
                  </Button>
                )}
              </Grid>
              <Grid item>
                {divisionRequest.estado ===
                "Finalizado" ? null : divisionRequest.estado ===
                  "Esperar a Ingresos" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalCancelRequest(true)}
                  >
                    Cancelar envío
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalRejectRequest(true)}
                  >
                    Rechazar fraccionamiento
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      {modalAceptRequest && (
        <ModalAceptRequest
          open={modalAceptRequest}
          divisionRequest={divisionRequest}
          closeModal={() => setModalAceptReqest(false)}
          moduleType={moduleType}
        />
      )}
      {modalRejectRequest && (
        <ModalRejectRequest
          open={modalRejectRequest}
          divisionRequest={divisionRequest}
          closeModal={() => setModalRejectRequest(false)}
          id_order={id}
          moduleType={moduleType}
        />
      )}
      {modalCancelRequest && (
        <ModalCancelRequest
          open={modalCancelRequest}
          divisionRequest={divisionRequest}
          closeModal={() => setModalCancelRequest(false)}
          moduleType={moduleType}
        />
      )}
      {modalSaved && (
        <ModalEditedRequest open={modalSaved} closeModal={() => setModalSaved(false)}  moduleType={moduleType} />
      )}
    </GridContainer>
  );
}
