import React from 'react';
import {
    Grid,
    Link,
    Breadcrumbs as BreadcrumbsMUI,
    Typography
} from "@material-ui/core";
import CardBody from "components/Card/CardBody.js";


export default function BreadScrumbs(props) {

    return (
        <Grid item xs={12}>
            <CardBody className="rounded shadow border border-primary">
                <BreadcrumbsMUI aria-label="breadcrumb">
                    {props.listLink.filter((link) => link).map((link, index) => (
                        index === props.listLink.length - 1
                            ? <Typography color="textPrimary" key={index}>{link.label}</Typography>
                            : <Link color="inherit" style={{ cursor: "pointer" }} {...link.link} key={index}>{link.label}</Link>
                    ))}

                </BreadcrumbsMUI>
            </CardBody>
        </Grid>

    );
}