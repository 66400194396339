import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import apiform from "utils/APIForm";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalEstimulaciones = (props) => {
  const { openModal, closeModal, tempRowModal, handleChange } = props;
  const [description, setDescription] = useState("");
  const [openProcess, setOpenProcess] = useState(false);

  const addEstimulacion = () => {
    setOpenProcess(true);
    const formData = new FormData();
    formData.append("catgoria", "Estimulaciones");
    formData.append("descripcion", description);
    formData.append("id_ficha", tempRowModal.id_ficha);
    console.log(tempRowModal);
    if (
      tempRowModal.id_solicitud_paciente &&
      tempRowModal.tipo_tarea.includes("Descomp")
    ) {
      const body = {
        comentario: `Estimulaciones ${tempRowModal.descripcion}: ${description}`,
        manual: true,
        tipo_comentario: "RespuestaCompesacion",
        id_descompensacion: null,
        id_usuario: localStorage.getItem("user_id"),
      };
      api
        .post(
          `api/paciente/comentariosdescompensacion/solicitud/${tempRowModal.id_solicitud_paciente}/`,
          body
        )
        .then(() => {})

    }

    apiform
      .post("api/tipostareasgenerales/", formData)
      .then(() => {
        handleChange(true, tempRowModal);
        closeModal();
      })

  };

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      titleStyle={{ textAlign: "center" }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeModal}>
        Estimulaciones
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} container justify="center">
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Descripción"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "1em" }}>
        {openProcess ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={addEstimulacion}
            style={{ textTransform: "none" }}
          >
            Agregar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalEstimulaciones;

ModalEstimulaciones.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRowModal: PropTypes.object,
  handleChange: PropTypes.func,
  tempIndex: PropTypes.number,
};
