import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";

const BoxTable = props => {
  const { tableBox } = props;
  return (
    <TableContainer component={Paper}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="text-center align-middle">SKU</th>
            <th className="text-center align-middle">Grupo</th>
            <th className="text-center align-middle">Descripción</th>
            <th className="text-center align-middle">Cantidad solicitada</th>
            <th className="text-center align-middle">Cantidad enviada</th>
            <th className="text-center align-middle">Observaciones</th>
          </tr>
        </thead>

        <tbody>
          {tableBox.map(product =>
            product?.id_producto_pedido?.reemplazado ? (
              <tr key={product.id}>
                <td className="text-center align-middle">
                  <s>
                    {
                        product?.id_producto_pedido?.id_insumo_medicamento!==null ? product.id_producto_pedido.id_insumo_medicamento.SKU:product?.id_producto_pedido?.id_equipo_asignado?.sku_bodega
                    }
                  </s>
                </td>
                <td className="text-center align-middle">
                  <s>
                    {
                      product?.id_producto_pedido?.id_insumo_medicamento!==null ? product.id_producto_pedido.id_insumo_medicamento.grupo_producto : "Equipo"
                    }
                  </s>
                </td>
                <td className="text-center align-middle">
                  <s>
                    {
                      product?.id_producto_pedido?.id_insumo_medicamento!==null ?product.id_producto_pedido.id_insumo_medicamento.descripcion_producto:product?.id_producto_pedido?.id_equipo_asignado?.tipo_equipo
                    }
                  </s>
                </td>
                <td className="text-center align-middle">
                  <s>{product.id_producto_pedido.cantidad}</s>
                </td>
                <td className="text-center align-middle">
                  <s>{product?.cantidad_enviada}
                  </s>
                </td>
                <td className="text-center align-middle">
                  <s>{product.id_producto_pedido.observaciones}</s>
                </td>
              </tr>
            ) : (
              <tr key={product.id}>
                <td className="text-center align-middle">
                  {product.sku ? product?.sku
                    : (product?.id_producto_pedido?.id_insumo_medicamento ? product.id_producto_pedido.id_insumo_medicamento.SKU
                    : product?.id_producto_pedido?.id_equipo_asignado?.sku_bodega)}
                </td>
                <td className="text-center align-middle">
                  {product.sku ? "Insumo / Medicamento"
                    : (product?.id_producto_pedido?.id_insumo_medicamento ? product.id_producto_pedido.id_insumo_medicamento.grupo_producto
                    : "Equipo")}
                </td>
                <td className="text-center align-middle">
                  {product.descripcion ? product?.descripcion
                    : (product?.id_producto_pedido?.id_insumo_medicamento !== null
                    ? product.id_producto_pedido.id_insumo_medicamento.descripcion_producto
                    : product?.id_producto_pedido?.id_equipo_asignado?.tipo_equipo )}
                </td>
                <td className="text-center align-middle">
                  {product.cantidad ? product?.cantidad
                    : product.id_producto_pedido.cantidad}
                </td>
                <td className="text-center align-middle">
                  {product.cantidad ? product?.cantidad
                    : product.cantidad_enviada}
                </td>
                <td className="text-center align-middle">
                  {product.observaciones ? product?.observaciones
                    : product.id_producto_pedido.observaciones}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default BoxTable;

BoxTable.propTypes = {
  tableBox: PropTypes.array
};
