import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Orders from "components/WareHouse/Orders/Orders";
import Requests from "components/WareHouse/Requests/Request";
import Receptions from "components/WareHouse/Receptions/Receptions";

const WareHouseTask = () => {
  const [moduleType, setmoduleType] = useState("");

  useEffect(() => {
    const arrayURL = window.location.href.split('/');
    setmoduleType(arrayURL[arrayURL.length-1]);
  }, []);

  return (
    <GridContainer>
      <ViewTitle
        title="SOLICITUDES RECIBIDAS"
        message={`En esta sección podrás revisar las tareas de ${moduleType === "storage" ? "bodega" : "bodega farmacia"}`}
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Typography color="textPrimary">{`Tareas ${moduleType === "storage" ? "Bodega" : "Bodega Farmacia"}`}</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <GridContainer justify="center" className="m-3 mt-5 mb-5">
            <Orders moduleType={moduleType}/>
            <Receptions moduleType={moduleType}/>
            <Requests moduleType={moduleType}/>
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default WareHouseTask;
