import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


export default function ModalCancelSendSupervisor(props) {
  const { open, closeModal, id_order } = props;
  const [order, setOrder] = useState([]);
  const [scroll, setScroll] = useState("paper");
  const descriptionElementRef = React.useRef(null);


  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    api.get(`api/solicitudpedidos/${id_order}`).then(response => {
      setOrder(response.data);
    });
  }, []);

  function updateBenefit() {

    const formData = new FormData();

    formData.append("tipo_solicitud", order.tipo_solicitud);
    formData.append("fecha_solicitud", order.fecha_solicitud);
    formData.append("estado_picking", order.estado_picking);
    formData.append("fecha_despacho", order.fecha_despacho);
    formData.append("nombre_paciente", order.nombre_paciente);
    formData.append("es_region", order.es_region);
    formData.append("tiene_medicamentos", order.tiene_medicamentos);
    formData.append("estado_guia_despacho", order.estado_guia_despacho);
    formData.append("estado_solicitud", "Esperar a egresos");
    formData.append("traslado_clinica", order.traslado_clinica);
    formData.append("motivo_rechazo", order.motivo_rechazo);
    formData.append("id_paciente", order.id_paciente);

    apiform
      .put(`api/solicitudpedidos/${id_order}`, formData)
      .then(response => {
        console.log(response);
        window.location.href = "/admin/outcome/receptions/";
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Cancelar solicitud</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <p className="text-center font-weight-bold">
          ¿Estás seguro que deseas cancelar la solicitud?
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          No
        </Button>
        <Button onClick={updateBenefit} color="primary">
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCancelSendSupervisor.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_order: PropTypes.number
};
