import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import ModalCreateWorkTelemedicina from "components/PatientFile/ModalCreateWorkTelemedicina";
import { Warning } from "@material-ui/icons";
import {
  ButtonTextMUI as ButtonMUI,
} from "utils/componentsStyle";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import APITelemedicina from "../../utils/APITelemedicina";

const useStyles = makeStyles(styles);

export default function RequestVisit({
  dataPatient,
  dataTutor,
  handleReloadRequest,
}) {
  const classes = useStyles();
  const [typeVisit, settypeVisit] = useState({});
  const priority = { value: "Media", label: "Media" };
  const [boss, setboss] = useState({});
  const [arrayMedic, setarrayMedic] = useState([]);
  const [arrayCard, setarrayCard] = useState([]);
  const [arrayUser, setarrayUser] = useState([]);

  useEffect(() => {
    api
      .get(`/api/solicitudvisitainicial/jefes`)
      .then((response) => {
        setboss(response.data);
      })
  }, []);

  const handleChargeQuotation = () => {
    const body = {
      solicitud_paciente: {
        Tipo_Solicitud_Paciente: typeVisit,
        prioridad: priority.value,
        id_cargo_solicitante: localStorage.getItem("id_cargo"),
        id_persona_solicitante: localStorage.getItem("user_id"),
        id_paciente: dataPatient.id,
        id_cargo_responder: null,
        id_persona_responder: null,
        estado: "EnEspera",
      },
    };

    api.post(`/api/solicitudvisitainicial/`, body).then((response) => {
      handleReloadRequest();
    })
  };

  const handleTelemedicina = () => {
    const requestCard = APITelemedicina.get(`api/especialistacarta/`);
    const requestSpecialist = api.get(`/api/integracion/obtener/especialistas/telemedicina/`);
    const requestProfesional = api.get(`/api/integracion/obtener/profesionales/siclin/${dataPatient.id}`);
    Promise.all([requestCard, requestSpecialist, requestProfesional]) .then((request) => {
      setarrayUser(request[2].data)
      setarrayMedic(request[1].data);
      setarrayCard(request[0].data);
      settypeVisit("ConsultaTelemedicina");
    })
  }

  return (
    <Card>
      <CardTitle title={`Visitas/Horas medicas`} class={classes.cardTitle} />
      <CardBody>
        {typeVisit === "VisitaInicial" ? (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            style={{ margin: "0 0 20px 0" }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              item
              xs={12}
            >
              <Typography variant="caption" display="block" gutterBottom>
                Se enviará una solicitud a los(a) siguientes cargos para coordinar la visita:
              </Typography>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={10}
              >
                <Typography variant="caption" display="block" gutterBottom>
                  {`Jefe(a) de Enfermería: ${
                    boss.usuario_jefe_enfermeria ? "" : "Falta nombre"
                  }`}
                </Typography>
                {boss.usuario_jefe_enfermeria &&
                  boss.usuario_jefe_enfermeria.map((row) => (
                    <Typography variant="caption" display="block" gutterBottom>
                      {`${row.first_name} ${row.last_name}`}
                    </Typography>
                  ))}
                <Typography variant="caption" display="block" gutterBottom>
                  {`Jefe(a) de Enfermería: ${
                    boss.usuario_jefe_rehabilitacion ? "" : "Falta nombre"
                  }`}
                </Typography>
                {boss.usuario_jefe_rehabilitacion &&
                  boss.usuario_jefe_rehabilitacion.map((row) => (
                    <Typography variant="caption" display="block" gutterBottom>
                      {`${row.first_name} ${row.last_name}`}
                    </Typography>
                  ))}
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ margin: "0 0 20px 0" }}
            >

                <ButtonMUI
                  style={{margin: "0 5px"}}
                  variant="contained"
                  color="primary"
                  onClick={handleChargeQuotation}
                >
                  Enviar
                </ButtonMUI>

                <ButtonMUI
                  variant="contained"
                  className="text-white"
                  style={{ backgroundColor: red[500], margin: "0 5px" }}
                  onClick={() => settypeVisit(null)}
                >
                  Volver
                </ButtonMUI>
            </Grid>
          </Grid>
        ) : (
          <div>
            <SnackbarContent
              message={"Seleccionar el tipo de visita"}
              color="warning"
              icon={Warning}
            />

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              item
              xs={12}
              style={{ margin: "0 0 20px 0" }}
            >
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{ margin: "0 0 10px 0" }}
              >
                <ButtonMUI
                  variant="contained"
                  disabled
                  //color="primary"
                  size="large"
                  style={{
                    width: "60%",
                    textTransform: "none",
                  }}
                  //onClick={() => settypeVisit("ConsultaPresencial")}
                >
                  Presencial
                </ButtonMUI>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{ margin: "0 0 10px 0" }}
              >
                {(dataTutor?.email && dataTutor?.rut) ?
                <ButtonMUI
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    width: "60%",
                    textTransform: "none",
                  }}
                  onClick={() => handleTelemedicina()}
                >
                  Telemedicina
                </ButtonMUI> :
                  <ButtonMUI
                  disabled
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    width: "60%",
                    textTransform: "none",
                  }}
                >
                  {"Telemedicina (Faltan datos del tutor )"}
                </ButtonMUI>
                }
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{ margin: "0 0 10px 0" }}
              >
                <ButtonMUI
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    width: "60%",
                    textTransform: "none",
                  }}
                  onClick={() => settypeVisit("VisitaInicial")}
                >
                  Evaluación inicial
                </ButtonMUI>
              </Grid>
            </Grid>
          </div>
        )}
      </CardBody>
      {typeVisit === "ConsultaTelemedicina" &&
        <ModalCreateWorkTelemedicina
          open={typeVisit === "ConsultaTelemedicina"}
          handleClose={() => {
            settypeVisit("");
            setarrayMedic([]);
            setarrayCard([]);
            setarrayUser([]);
          }}
          arrayMedic={arrayMedic}
          arrayCard={arrayCard}
          arrayUser={arrayUser}
          dataPatient={dataPatient}
        />
      }
    </Card>
  );
}
