import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { orange, blue, green, indigo } from "@material-ui/core/colors";
import {
  Typography,
  Grid,
  Tooltip,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStylesTable = makeStyles({
  root: {
    width: "100%"
  },
});
const useStyles = makeStyles(styles);

// eslint-disable-next-line react/prop-types
export default function ChargeWorker({
  array,
  handleChange,
}) {
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  useEffect(() => {

  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classesTable.root}>
      <TableContainer className={classesTable.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className="text-center align-top"> Cargo </TableCell>
              <TableCell className="text-center align-top"> Usuario </TableCell>
              <TableCell className="text-center align-top"> </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {array.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={`${index}-workRows`}
                >
                  <TableCell className="text-center align-middle">
                    {row.nombre_cargo}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {`${row.first_name} ${row.last_name}`}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    <Checkbox
                      value="remember"
                      checked={row.estado}
                      onChange={event =>
                        handleChange(event.target.checked, row)
                      }
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={array.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
