import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {useSelector, useDispatch} from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const TabCommon = props => {
  const classes = useStyles();
  const {data,} = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const globalOpenBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          centered
        >
          {data.map((item, index) => (
             <Tab
              style={{ textTransform: "none" }}
              label={item.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {!globalOpenBackdrop &&<>
      {data.map((item, index) => (
      <TabPanel value={value} index={index}>
        {item._value_}
      </TabPanel>
       ))}
      </>
      }
    </div>
  );
};

export default TabCommon;


