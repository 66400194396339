import React from "react";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import "../../../styles/Styles.css";

const ViewTitle = props => {
  return(
    <GridItem xs={12} sm={6} md={12}>
      <CardBody className="card-body-chm-color rounded text-white mt-4 mb-3 shadow">
        <h4 className="alert-heading font-weight-bold">{props.title}</h4>
        {props.message}
      </CardBody>
    </GridItem>
  );
}

export default ViewTitle;