import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";
import {
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import {toMoneyFormat} from "utils/formatFunctions";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStylesTable = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});
const useStyles = makeStyles(styles);

// eslint-disable-next-line react/prop-types
export default function RemunerationTable({
  dataArray,
  setdataArray,
  changeButtons,
  setopenEditModal,
  setopenDeleteModal,
}) {
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classesTable.root}>
      <TableContainer className={classesTable.container} style={{height: "440px"}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className="text-center align-top">
                Item
              </TableCell>
              <TableCell className="text-center align-top">
                Valor Modificado
              </TableCell>
              <TableCell className="text-center align-top">
                Accion
              </TableCell>
              <TableCell className="text-center align-top">

              </TableCell>
              <TableCell className="text-center align-top">

              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={`${index}-workRows`}>
                  <Tooltip title={row.nombre}>
                  <TableCell className="text-center align-middle" style={{background: row.color, color: (row.color === "#ffc107" || row.color === "#ffef62") ? "#000000": "#fff" }}>
                    {row.item}
                  </TableCell>
                  </Tooltip>
                  <TableCell className="text-center align-middle">
                    {`${row.tipo_valor === "$" ? toMoneyFormat(row.valores.valor_modificado) : row.valores.valor_modificado }`}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.editable ?
                    <Button
                      style={{ background: green[500] }}
                      className="text-white"
                      onClick={() => setopenEditModal(row)}
                    >
                      Editar
                    </Button> :
                    <Button
                      disabled
                    >
                      Editar
                    </Button>
                    }

                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.eliminable ?
                      <Button
                        style={{ background: red[500] }}
                        className="text-white"
                        onClick={() => setopenDeleteModal(row)}
                      >
                        Eliminar
                      </Button> :
                      <Button
                        disabled
                      >
                        Eliminar
                      </Button>
                    }
                  </TableCell>
                </TableRow>
              ))}
              {changeButtons &&
                <TableRow />
              }

          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dataArray.filter((row) => (!row.valores.eliminado)).length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />


    </Paper>
  );
}
