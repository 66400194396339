import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import { Paper } from '@material-ui/core';
import {
  Button
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal

        aria-labelledby="new-provider-modal"
        aria-describedby="new-provider-description"
        open={props.open}
        onClose={handleClose}
        disableBackdropClick={true}
        style={{
            height: "30%",
            width: "30%",
            left: "35%",
            top: "30%",
            borderRadius: '4px',
        }}
      >
        <Paper in={props.open} style={{
              padding: "5% 2%",
          }} className="text-center">

            <h3 id="transition-modal-title">{props.message}</h3>
            <br />
            <br />
            <Button
                mr={2}
                variant="contained"
                style={{backgroundColor: "#1B9CFC"}}
                className="text-white"
                onClick={props.closeModal}
              >
                Aceptar
              </Button>

        </Paper>
      </Modal>
    </div>
  );
}
