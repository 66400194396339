import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SortIcon from "@material-ui/icons/Sort";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import { Link } from "react-router-dom";
import { useSortableData } from "../SortableData/SortableData";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const CommodityTable = props => {
  const classes = useStyles();
  const [group, setGroup] = useState("");
  const [reason, setReason] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const { items, requestSort, sortConfig } = useSortableData(props.commodity);
  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  let filteredCommodity = items.filter(order => {
    return (
      order.id_producto_retirado.grupo_producto
        .toLowerCase()
        .indexOf(group.toLowerCase()) !== -1 &&
      order.motivo.toLowerCase().indexOf(reason.toLowerCase()) !== -1
    );
  });

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Id 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("id")}
                  className={getClassNamesFor("id")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                SKU 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("sku")}
                  className={getClassNamesFor("sku")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Grupo 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={group}
                  onChange={e => setGroup(e.target.value.substr(0, 20))}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {props.groupList.map((group, index) => {
                    return (
                      <MenuItem key={index} value={group[1]}>
                        {group[1]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Descripción 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("descripcion")}
                  className={getClassNamesFor("descripcion")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Cantidad a retirar 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("cantidad")}
                  className={getClassNamesFor("cantidad")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Motivo 
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={reason}
                  onChange={e => setReason(e.target.value.substr(0, 20))}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {props.reasonList.map((group, index) => {
                    return (
                      <MenuItem key={index} value={group[0]}>
                        {group[0]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Fecha solicitud 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("fecha_solicitud")}
                  className={getClassNamesFor("fecha_solicitud")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Estado 
                <IconButton
                  color="primary"
                  aria-label="sort"
                  onClick={() => requestSort("estado")}
                  className={getClassNamesFor("estado")}
                >
                  <SortIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredCommodity
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id} align="center">
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">
                    {row.id_producto_retirado.SKU}
                  </TableCell>
                  <TableCell align="center">
                    {row.id_producto_retirado.grupo_producto}
                  </TableCell>
                  <TableCell align="center">
                    {row.id_producto_retirado.descripcion_producto}
                  </TableCell>
                  <TableCell align="center">{row.cantidad}</TableCell>
                  <TableCell align="center">{row.motivo}</TableCell>
                  <TableCell align="center">{row.fecha_solicitud}</TableCell>
                  <TableCell align="center">{row.estado}</TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/admin/warehouse/commodity/edit/storage/${row.id}`}
                    >
                      <Button
                        style={{ textTransform: "none" }}
                        variant="contained"
                        color="primary"
                      >
                        Editar
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredCommodity.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CommodityTable;

CommodityTable.propTypes = {
  commodity: PropTypes.array,
  supplies: PropTypes.array,
  groupList: PropTypes.array,
  reasonList: PropTypes.array
};
