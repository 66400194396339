import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import OrderDetailInfo from "./OrderDetail";
import Button from "@material-ui/core/Button";
import ModalReplaceSKU from "./ModalReplaceSKU";
import ModalSendPending from "./ModalSendPending";
import ModalSaved from "./ModalSaved";
import ModalCancelReplace from "./ModalCancelReplace";
import ModalReplaceInsumos from "./ModalReplaceInsumos";
import ModalSendComplete from "./ModalSendComplete";
import ModalCancelRequest from "./ModalCancelRequest";
import ModalAddRecord from "./ModalAddRecord";



export default function ToComeDetail() {

  const { id } = useParams();
  const id_order = parseInt(id, 10);

  const [supplies, setSupplies] = useState([]);

  const [loading, setLoading] = useState(false);

  const [id_producto, setId_producto] = useState("");

  const [modalEditSupply, setModalEditSupply] = useState(false);
  const [modalSendPending, setModalSendPending] = useState(false);
  const [modalSendComplete, setModalSendComplete] = useState(false);
  const [modalSaved, setModalSaved] = useState(false);
  const [modalCancelReplace, setModalCancelReplace] = useState(false);
  const [modalReplaceInsumos, setModalReplaceInsumos] = useState(false);
  const [productosRestantes, setProductosRestantes] = useState([]);
  const [orders, setOrders] = useState([]);

  const [modalCancelRequest, setModalCancelRequest] = useState(false);

  const [modalAddRecord, setModalAddRecord] = useState(false);

  const [changes, setChanges] = useState(true);

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  });

  const initialChargeReception = () => {
    setChanges(false);
    const requestGetSupplies = api.get("api/insumosmedicamento/");
    const requestGetRequest = api.get(
      `api/compras/solicitudcotizacion/detalleproductos/${id_order}`
    );
    Promise.all([requestGetRequest, requestGetSupplies])
      .then(response => {
        setOrders(response[0].data);
        setSupplies(response[1].data);

      })

    setLoading(true);
  };

  const setSKU = (id_supply, supplies) => {
    const response = supplies
      .filter(supply => supply.id === id_supply)
      .map(filtered => {
        return filtered.SKU;
      });
    return response;
  };

  const setDescripcion = (id_supply, supplies) => {
    const response = supplies
      .filter(supply => supply.id === id_supply)
      .map(filtered => {
        return filtered.descripcion_producto;
      });
    return response;
  };

  return (
    <GridContainer>
      <ViewTitle
        title="DETALLE COMPRA"
        message="En esta sección podrás revisar el detalle de la compra seleccionada."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" href="/admin/purchases/tasks/">
              Tareas de compras
            </Link>
            <Link color="inherit" href="/admin/purchases/requests/">
              Solicitudes de compras
            </Link>
            <Typography color="textPrimary">Detalle solicitud</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <OrderDetailInfo id={id_order} />
            </GridContainer>
            <CardBody>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center align-middle">SKU</th>
                    <th className="text-center align-middle">Descripción</th>
                    <th className="text-center align-middle">Cantidad</th>
                    <th className="text-center align-middle">Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map(order => (
                    <tr key={order.id}>
                      <td className="text-center align-middle">
                        {setSKU(order.id_insumo_medicamento, supplies)}
                      </td>
                      <td className="text-center align-middle">
                        {setDescripcion(order.id_insumo_medicamento, supplies)}
                      </td>
                      <td className="text-center align-middle">
                        {order.cantidad}
                      </td>
                      <td className="text-center align-middle">
                        {order.tiene_observaciones ? "Si" : "No"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <GridContainer justify="center" className="mt-3 mb-6">
                <Link href="/admin/purchases/requests/">
                  <Button variant="contained" color="primary">
                    Atrás
                  </Button>
                </Link>
              </GridContainer>

              <div className="mt-3 mb-5"></div>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modalSaved && (
        <ModalSaved open={modalSaved} closeModal={() => setModalSaved(false)} />
      )}
      {modalEditSupply && (
        <ModalReplaceSKU
          open={modalEditSupply}
          closeModal={() => setModalEditSupply(false)}
          id_producto={id_producto}
          id_detalle={id_order}
        />
      )}
      {modalSendPending && (
        <ModalSendPending
          open={modalSendPending}
          closeModal={() => setModalSendPending(false)}
          productos_restantes={productosRestantes}
          id_producto={id_producto}
          id_order={id_order}
        />
      )}
      {modalCancelReplace && (
        <ModalCancelReplace
          open={modalCancelReplace}
          closeModal={() => setModalCancelReplace(false)}
          id_producto={id_producto}
          id_detalle={id_order}
        />
      )}
      {modalReplaceInsumos && (
        <ModalReplaceInsumos
          open={modalReplaceInsumos}
          closeModal={() => setModalReplaceInsumos(false)}
          id_producto={id_producto}
          id_detalle={id_order}
        />
      )}
      {modalSendComplete && (
        <ModalSendComplete
          open={modalSendComplete}
          closeModal={() => setModalSendComplete(false)}
          id_order={id_order}
        />
      )}
      {modalCancelRequest && (
        <ModalCancelRequest
          open={modalCancelRequest}
          closeModal={() => setModalCancelRequest(false)}
          id_order={id_order}
        />
      )}
      {modalAddRecord && (
        <ModalAddRecord
          open={modalAddRecord}
          closeModal={() => setModalAddRecord(false)}
        />
      )}
    </GridContainer>
  );
}
