import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import TableComponent from "views/Widgets/Common/TableComponent";
import moment from "moment-timezone";
import "moment/locale/es";
import allActions from "actions";
import { setmessageGeneral } from "actions/getGlobalAction";
import {dictMonth, daysWeek} from "utils/validationFunction";
moment.locale("es");

export default function ModalShowViewTensPatient({
    open,
    handleClose,
    arrayWorker,
    selectDate,
    selecterdMonthTens,
}) {
    const nameTENS = `${selecterdMonthTens.first_name} ${selecterdMonthTens.last_name}`

    const header = [
        {label: "Tipo Asignación"},
        {label: "Fecha"},
        {label: "Paciente asignado"},
        {label: "RUT Paciente"},
    ]

    const dataArray = arrayWorker.map((row) => {
        const tempPatient = row.paciente;
        const patientName = `${tempPatient.nombre} ${tempPatient.apellido_paterno} `
        return [
            {value:row.tipo_tarea},
            {value:row.fecha_hora},
            {value:patientName},
            {value:tempPatient.rut},
        ]
    })

    const _content_ = <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            item
            xs={12}
        >
            <TableComponent
                headers={header}
                data={dataArray}
                styleTableContainer={{maxHeight: "600px"}}
            />

        </Grid>
    </div>

    const _actions_ = <>
        <Button
            mr={2}
            variant="contained"
            style={{
                backgroundColor: red[500],
                color: red[50],
            }}
            onClick={handleClose}
        >
            CERRAR
        </Button>

    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Ver asignaciones TENS ${nameTENS}- ${dictMonth[parseInt(moment(selectDate).format("MM"))]} del ${moment(selectDate).format("YYYY")}`}
            maxWidth="md"
            _content_={_content_}
            _actions_ = {_actions_}
        />

    );
}