import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const { purchases } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Cantidad SKU</TableCell>
              <TableCell align="center">Observación</TableCell>
              <TableCell align="center">Fecha de compra</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchases
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">
                      {row.tipo_solicitud_cotizar}
                    </TableCell>
                    <TableCell align="center">
                      {row.cantidad_productos}
                    </TableCell>
                    <TableCell align="center">{row.observacion}</TableCell>
                    <TableCell align="center">{row.fecha_compra}</TableCell>
                    <TableCell align="center">
                      {row.estado === "JefeSeleccionaCotizacion"
                        ? "Elegir cotizacion"
                        : row.estado === "RevisarOC"
                        ? "Revisar orden de compra"
                        : row.estado}
                    </TableCell>
                    <TableCell align="center">
                      <Link
                        href={`/admin/boss/detail/shopping/${row.id}/`}
                        underline="none"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none" }}
                        >
                          Detalle
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={purchases.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
