import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Backdrop,
  Paper,
  Button as ButtonMUI,
  Grid,
} from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import moment from "moment-timezone";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
}));


export default function ModalHomeAuthorization({
  open,
  handleClose,
  dateRequest,
  setdateRequest,
  handleUpload,
}) {
  const classes = useStyles();
  const [validation, setvalidation] = useState(false);
  const [changeButtons, setchangeButtons] = useState(true);

  useEffect(() => {
    validateForm();
    if (changeButtons) {
      setchangeButtons(false);
    }
  });

  const validateForm = () => {
    const arrayValidation = [dateRequest !== undefined && dateRequest !== null];
    if (arrayValidation.every((item) => item === true)) {
      setvalidation(true);
    } else {
      setvalidation(false);
    }
  };

  const handleDate = (value) => {
    setdateRequest(moment(value).format("YYYY-MM-DD"));
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      style={{
        borderRadius: "4px",
        bottom: "200px",
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.paper}>
        <h3 id="spring-modal-title">{`Seleccione una fecha y hora para la habilitación`}</h3>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
          item
          xs={12}
        >
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              label={"Fecha estimada"}
              placeholder="Fecha estimada"
              onChange={handleDate}
              value={moment(dateRequest)}
              inputVariant={"outlined"}
            />
          </MuiPickersUtilsProvider>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
            item
            xs={12}
            style={{ margin: "10px 0 0 0" }}
          >
            {validation ? (
              <ButtonMUI
                mr={2}
                variant="contained"
                className="text-white"
                style={{ backgroundColor: green[500], textTransform: "none" }}
                onClick={handleUpload}
              >
                Solicitar
              </ButtonMUI>
            ) : (
              <ButtonMUI
                style={{ textTransform: "none" }}
                disabled
                mr={2}
                variant="contained"
                className="text-white"
              >
                Solicitar
              </ButtonMUI>
            )}
            <ButtonMUI
              mr={2}
              variant="contained"
              style={{
                backgroundColor: red[500],
                marginRight: "10px",
                textTransform: "none",
              }}
              className="text-white"
              onClick={handleClose}
            >
              Cerrar
            </ButtonMUI>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
