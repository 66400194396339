import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Sucess from "../Dialogs/Sucess";
import TableVetoes from "./TableVetoes";
import ModalAddPatient from "./ModalAddPatient";
import ModalEditPatient from "./ModalEditPatient";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const Vetoes = props => {
  const classes = useStyles();
  const {
    patients,
    idFicha,
    vetoesInfo,
    setVetoesInfo,
    setPatients,
    variableCounter,
    setVariableCounter
  } = props;
  const [pageRefresh, setPageRefresh] = useState(false);
  const [openModalAddPatient, setOpenModalAddPatient] = useState(false);
  const [tempVetoe, setTempVetoe] = useState({});
  const [tempPatient, setTempPatient] = useState({});
  const [tempPatientCompare, setTempPatientCompare] = useState({});
  const [tempMotivo, setTempMotivo] = useState("");
  const [tempIdFichaPaciente, setTempIdFichaPaciente] = useState("");
  const [tempIdFichaPersonal, setTempIdFichaPersonal] = useState("");
  const [openModalEditPatient, setOpenModalEditPatient] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [message, setMessage] = useState("");

  return (
    <Grid container className={classes.root} justify="center">
      <Grid item container xs={12} justify="center">
        <Typography variant="h6" gutterBottom style={{ textTransform: "none" }}>
          Lista de pacientes vetados
        </Typography>
      </Grid>
      <Grid item container xs={12} justify="center">
        <TableVetoes
          vetoesInfo={vetoesInfo}
          setTempMotivo={setTempMotivo}
          setTempIdFichaPaciente={setTempIdFichaPaciente}
          setTempIdFichaPersonal={setTempIdFichaPersonal}
          setOpenModalEditPatient={setOpenModalEditPatient}
          pageRefresh={pageRefresh}
          setTempPatient={setTempPatient}
          setTempPatientCompare={setTempPatientCompare}
          setTempVetoe={setTempVetoe}
        />
      </Grid>
      <Grid item container xs={12} justify="center">
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "1em", textTransform: "none" }}
          onClick={() => setOpenModalAddPatient(true)}
        >
          Agregar paciente
        </Button>
      </Grid>
      {openModalAddPatient && (
        <ModalAddPatient
          openModal={openModalAddPatient}
          closeModal={() => setOpenModalAddPatient(false)}
          patients={patients}
          idFicha={idFicha}
          setMessage={setMessage}
          vetoesInfo={vetoesInfo}
          setVetoesInfo={setVetoesInfo}
          setOpenDialog={setOpenDialog}
          setPageRefresh={setPageRefresh}
          setPatients={setPatients}
          variableCounter={variableCounter}
          setVariableCounter={setVariableCounter}
        />
      )}
      {openModalEditPatient && (
        <ModalEditPatient
          openModal={openModalEditPatient}
          closeModal={() => setOpenModalEditPatient(false)}
          patients={patients}
          idFicha={idFicha}
          tempMotivo={tempMotivo}
          setTempMotivo={setTempMotivo}
          tempIdFichaPaciente={tempIdFichaPaciente}
          setTempIdFichaPaciente={setTempIdFichaPaciente}
          tempIdFichaPersonal={tempIdFichaPersonal}
          tempPatient={tempPatient}
          setTempPatient={setTempPatient}
          tempPatientCompare={tempPatientCompare}
          variableCounter={variableCounter}
          setVariableCounter={setVariableCounter}
          vetoesInfo={vetoesInfo}
          setVetoesInfo={setVetoesInfo}
          tempVetoe={tempVetoe}
          setMessage={setMessage}
          setOpenDialogEdit={setOpenDialogEdit}
          setPageRefresh={setPageRefresh}
          setPatients={setPatients}
        />
      )}
      {openDialog && (
        <Sucess
          openDialog={openDialog}
          closeDialog={() => setOpenDialog(false)}
          message={message}
          setOpenModalAddRecord={setOpenModalAddPatient}
          setPageRefresh={setPageRefresh}
          setOpenModalViewRecord={setOpenModalAddPatient}
        />
      )}
      {openDialogEdit && (
        <Sucess
          openDialog={openDialogEdit}
          closeDialog={() => setOpenDialogEdit(false)}
          message={message}
          setOpenModalAddRecord={setOpenModalEditPatient}
          setPageRefresh={setPageRefresh}
          setOpenModalViewRecord={setOpenModalEditPatient}
        />
      )}
    </Grid>
  );
};

export default Vetoes;

Vetoes.propTypes = {
  patients: PropTypes.array,
  idFicha: PropTypes.string,
  vetoesInfo: PropTypes.array,
  setVetoesInfo: PropTypes.func,
  setPatients: PropTypes.func,
  variableCounter: PropTypes.object,
  setVariableCounter: PropTypes.func
};
