import React, { useState, useEffect } from "react";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import { Button, Grid, TextField } from "@material-ui/core";
import api from "utils/API";
import Select from "react-select";

export default function ModalReplaceMedicine({
  open,
  closeModal,
  info,
  listMedical,
}) {
  const medicine = info["medicamento"]
  const medicineSugges = info["medicamento_sugerido"]
  const product = info["producto_pedido"]
  const patientInfo = info["tarea"]["id_ficha"]["id_paciente"]
  const fileInfo = info["tarea"]["id_ficha"]
  const [sku, setSku] = useState(null);
  const [quantity, setQuantity] = useState(info["producto_pedido"]["cantidad"]);
  const [comment, setComment] = useState("");
  const [openModalRejected, setOpenModalRejected] = useState(false);

  const handleSKU = sku => {
    setSku(sku);
  };

  function updateProduct(replace=true) {
    const body = {
      reemplazo: sku?.value,
      cantidad: quantity,
      comentario: comment,
    }
    api.put(`api/bodega/reemplazo/producto/tarea/${info["tarea"]["id"]}/finalizar/`, body)
      .then(response => {
        window.location.reload();
      })

  }

  console.log(medicineSugges?.SKU )

  const _content_ = <div>
    Datos reemplazo: <br />
    <div className="mt-3 mb-3">
      <ul className="list-group">
        <li className="list-group-item">
          <span className="font-weight-bold">Paciente: </span>
          {`${patientInfo.nombre} ${patientInfo.apellido_paterno}`}
        </li>
        <li className="list-group-item">
          <span className="font-weight-bold">Diagnostico: </span>
          {`${fileInfo.diagnostico_ingreso}`}
        </li>
        <li className="list-group-item">
          <span className="font-weight-bold">SKU: </span>
          {medicine.SKU}
        </li>
        <li className="list-group-item">
          <span className="font-weight-bold">Grupo: </span>
          {medicine.grupo_producto}
        </li>
        <li className="list-group-item">
          <span className="font-weight-bold">Descripción: </span>
          {medicine.descripcion_producto }
        </li>
        <li className="list-group-item">
          <span className="font-weight-bold">Valor (IVA): </span>
          {`$${medicine.valor_con_iva}`}
        </li>
        <li className="list-group-item">
          <span className="font-weight-bold">Stock requerido: </span>
          {product.cantidad}
        </li>
      </ul>
    </div>
    {medicineSugges?.SKU  &&
    <div className="mt-3 mb-3">
      Datos recomendados: <br />
      <ul className="list-group">
        <li className="list-group-item">
          <span className="font-weight-bold">SKU: </span>
          {medicineSugges?.SKU}
        </li>
        <li className="list-group-item">
          <span className="font-weight-bold">Grupo: </span>
          {medicine.grupo_producto}
        </li>
        <li className="list-group-item">
          <span className="font-weight-bold">Descripción: </span>
          {medicineSugges?.descripcion_producto}
        </li>
        <li className="list-group-item">
          <span className="font-weight-bold">Valor (IVA): </span>

          {medicineSugges?.valor_con_iva}
        </li>
      </ul>
    </div>
    }
    
    <div className="col-md-11 mb-3">
      <label className="text-dark">Medicamento a remplazar:</label>
      <Select
        value={sku}
        onChange={handleSKU}
        options={listMedical}
        placeholder="Seleccione"
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
    </div>
    <div className="col-md-11 mb-3">
      <label className="text-dark">CANTIDAD A REMPLAZAR:</label>
      <input
        type="number"
        min="0"
        className="form-control bg-light font-weight-lighter"
        style={{ color: "#000000" }}
        value={quantity}
        onChange={e => {
          if (/^[0-9\b]+$/.test(e.target.value)) {
            setQuantity(e.target.value);
          }
        }}
      />
    </div>
  </div>

  const _actions_ = <>
    {sku && quantity > 0 ?
    <Button onClick={updateProduct} color="primary">
      REMPLAZAR
    </Button>:
    <Button disabled>
      REMPLAZAR
    </Button>
    }
    <Button onClick={() => setOpenModalRejected(true)} color="primary">
      RECHAZAR
    </Button>
    <Button onClick={closeModal} color="primary">
      Cancelar
    </Button>
  </>

  const _contentRejected_ = <div>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      item
      xs={12}
      style={{ padding: "5px" }}
    >
      <TextField
          label={"Ingresar Observaciones"}
          multiline
          rows={5}
          value={comment}
          variant="outlined"
          onChange={(e) => setComment(e.target.value)}
          style={{width: "100%"}}
      />
      </Grid>
  </div>

  const _actionsRejected_ = <>
    {comment ?
    <Button onClick={() => updateProduct(false)} color="primary">
      RECHAZAR
    </Button>:
    <Button disabled color="primary">
      RECHAZAR
    </Button>
    }
    <Button onClick={() => setOpenModalRejected(false)} color="primary">
      Cancelar
    </Button>
  </>

  return (<>
      <ModalDialog
        open={open}
        onClose={closeModal}
        title={`Reemplazo de Medicamento`}
        _content_={_content_}
        _actions_ = {_actions_}
        maxWidth="sm"
      />
      <ModalDialog
        open={openModalRejected}
        onClose={() => setOpenModalRejected(false)}
        title={`Reemplazo de Medicamento`}
        _content_={_contentRejected_}
        _actions_ = {_actionsRejected_}
        maxWidth="xs"
      />
    </>
  );
}
