import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiHide from "utils/API_HIDE";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import ModalCreateCovenant from "components/Covenants/ModalCreateCovenant";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { days_open } from "components/Request/ManageRequest/DaysOpen";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import ReceptionsInformation from "./ReceptionsInformation";
import { useSortableDataInProcess } from "./SortableDataInProcess/SortableDataInProcess";
import { useSortableDataToTransport } from "./SortableDataToTransport/SortableDataToTransport";
import { useSortableDataFinalized } from "./SortableDataFinalized/SortableDataFinalized";
import ModalTransport from "./ModalTransport";
import Fade from "@material-ui/core/Fade";
import ModalMessageBodega from "./ModaMessageBodega";
import TableInProcessOutcome from "./Tables/TableInProcessOutcome";
import TableWaitToTransportOutcome from "./Tables/TableWaitToTransportOutcome";
import TableFinalizedOutcome from "./Tables/TableFinalizedOutcome";
import {  setmessageAlert } from "actions/getGlobalAction";

export default function OutcomeReceptions() {
  const dispatch = useDispatch();
  const [moduleType, setmoduleType] = useState("");

  const [changes, setChanges] = useState(true);
  const [outcomeInProcess, setOutcomeInProcess] = useState([]);
  const [waitToTransport, setWaitToTransport] = useState([]);
  const [outcomeFinalized, setOutcomeFinalized] = useState([]);

  const [inputInProcess, setInputInProcess] = useState("");
  const [inputToTransport, setInputToTransport] = useState("");
  const [inputFinalized, setInputFinalized] = useState("");

  const [requestTypeInProcess, setRequestTypeInProcess] = useState("");
  const [requestTypeToTransport, setRequestTypeToTransport] = useState("");
  const [requestTypeFinalized, setRequestTypeFinalized] = useState("");

  const [pickingStatusInProcess, setPickingStatusInProcess] = useState("");
  const [pickingStatusToTransport, setPikcingStatusToTransport] = useState("");
  const [pickingStatusFinalized, setPickingStatusFinalized] = useState("");

  const [regionInProcess, setRegionInProcess] = useState("");
  const [regionToTransport, setRegionToTransport] = useState("");
  const [regionFinalized, setRegionFinalized] = useState("");

  const [supplyInProcess, setSupplyInProcess] = useState("");
  const [supplyToTransport, setSupplyToTransport] = useState("");
  const [supplyFinalized, setSupplyFinalized] = useState("");

  const {
    itemsInProcess,
    requestSortInProcess,
    sortConfigInProcess
  } = useSortableDataInProcess(outcomeInProcess);
  const getClassNamesForInProcess = name => {
    if (!sortConfigInProcess) {
      return;
    }
    return sortConfigInProcess.key === name
      ? sortConfigInProcess.direction
      : undefined;
  };

  const {
    itemsToTransport,
    requestSortToTransport,
    sortConfigToTransport
  } = useSortableDataToTransport(waitToTransport);
  const getClassNamesForToTransport = name => {
    if (!sortConfigToTransport) {
      return;
    }
    return sortConfigToTransport.key === name
      ? sortConfigToTransport.direction
      : undefined;
  };

  const {
    itemsFinalized,
    requestSortFinalized,
    sortConfigFinalized
  } = useSortableDataFinalized(outcomeFinalized);
  const getClassNamesForFinalized = name => {
    if (!sortConfigFinalized) {
      return;
    }
    return sortConfigFinalized.key === name
      ? sortConfigFinalized.direction
      : undefined;
  };

  const [loading, setLoading] = useState(false);
  const [modal_create_covenant, setModal_create_covenant] = useState(false);
  const [value, setValue] = useState(0);

  const [checkTab, setCheckTab] = useState(0);

  const [modalTransport, setModalTransport] = useState(false);
  const [idTransport, setIdTransport] = useState(0);
  const [openModalMessage, setOpenModalMessage] = useState(false);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    if (value === 0) {
      setCheckTab(0);
    }
    if (value === 1) {
      setCheckTab(1);
    }
    if (value === 2) {
      setCheckTab(2);
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setInterval(() => setChanges(true), 300000);
    const tempArrayURL = window.location.href.split("/");
    const tempModuleType = tempArrayURL[tempArrayURL.length - 1];
    const group = tempModuleType === "storage" ? "Insumos" : "Medicamentos";
    const waitToTransport = api.get(`api/egresos/recepcion/grupo/${group}/estado/Transporte/`);
    const finalized = api.get(`api/egresos/recepcion/grupo/${group}/estado/Finalizadas/`);
    Promise.all([waitToTransport, finalized]).then(response => {
      setWaitToTransport(response[0].data);
      setOutcomeFinalized(response[1].data);
    })
  }, [])

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
  }, [changes]);

  const initialChargeReception = () => {
    const tempArrayURL = window.location.href.split("/");
    const tempModuleType = tempArrayURL[tempArrayURL.length - 1];
    setmoduleType(tempModuleType);
    const group = tempModuleType === "storage" ? "Insumos" : "Medicamentos";
    setChanges(false);

    api.get(`api/egresos/recepcion/grupo/${group}/estado/Egresos/`).then(response => {
        let newData = [];
        response.data.map(row => {
          newData.push({
            ...row,
            fecha_despacho: row.id_solicitud_pedido.fecha_despacho,
            nombre_paciente: row.id_solicitud_pedido.nombre_paciente
          });
        });
        const oldInprogress = outcomeInProcess.map((row) => {return row.id_solicitud_pedido.id})
        const newInprogress = newData.filter(newItem => !oldInprogress.includes(newItem.id_solicitud_pedido.id));
        let  message = ""
        if (oldInprogress.length == 0 && newInprogress.length > 0){
          message = `Tiene ${newInprogress.length} solicitudes que requieren su atención`;
        } else if (newInprogress.length > 0){
          const ids = newInprogress.reduce((accum, obj) => {return `${accum}, ${obj.id_solicitud_pedido.id}`}, "")
          message = `Tiene ${newInprogress.length} solicitudes nuevas que requieren su atención (${ids})`;
        }
        setmessageAlert(dispatch, message, "success" )


        setOutcomeInProcess(newData);
      })
    setLoading(true);
  };



  const months_open = date => {
    const today = moment(new Date()).format("DD/MM/YYYY");
    const request_date = moment(date).format("DD/MM/YYYY");
    const diff = moment(today, "DD/MM/YYYY").diff(
      moment(request_date, "DD/MM/YYYY")
    );
    const duration = moment.duration(diff);
    return duration._data.months;
  };

  const getInfo = information => {
    const response = information ? "si" : "no";
    return response;
  };

  let searchInProcess = itemsInProcess.filter(order => {
    return (
      (order.id_solicitud_pedido.id
        .toString()
        .toLowerCase()
        .indexOf(inputInProcess.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.tipo_solicitud
          .toLowerCase()
          .indexOf(inputInProcess.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.fecha_solicitud
          .toLowerCase()
          .indexOf(inputInProcess.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.estado_picking
          .toLowerCase()
          .indexOf(inputInProcess.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.fecha_despacho
          .toLowerCase()
          .indexOf(inputInProcess.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.nombre_paciente
          .toLowerCase()
          .indexOf(inputInProcess.toLowerCase()) !== -1 ||
        getInfo(order.id_solicitud_pedido.es_region)
          .toString()
          .toLowerCase()
          .indexOf(inputInProcess.toLowerCase()) !== -1 ||
        getInfo(order.id_solicitud_pedido.tiene_medicamentos)
          .toString()
          .toLowerCase()
          .indexOf(inputInProcess.toLowerCase()) !== -1 ||
        months_open(order.id_solicitud_pedido.fecha_solicitud)
          .toString()
          .toLowerCase()
          .indexOf(inputInProcess.toLowerCase()) !== -1 ||
        days_open(order.id_solicitud_pedido.fecha_solicitud)
          .toString()
          .toLowerCase()
          .indexOf(inputInProcess.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.estado_solicitud
          .toLowerCase()
          .indexOf(inputInProcess.toLocaleLowerCase()) !== -1) &&
      (order.id_solicitud_pedido.tipo_solicitud
        .toLowerCase()
        .indexOf(requestTypeInProcess.toLowerCase()) !== -1 &&
        order.id_solicitud_pedido.estado_picking
          .toLowerCase()
          .indexOf(pickingStatusInProcess.toLowerCase()) !== -1 &&
        getInfo(order.id_solicitud_pedido.es_region)
          .toString()
          .toLowerCase()
          .indexOf(regionInProcess.toLowerCase()) !== -1 &&
        getInfo(order.id_solicitud_pedido.tiene_medicamentos)
          .toString()
          .toLowerCase()
          .indexOf(supplyInProcess.toLowerCase()) !== -1)
    );
  });

  let searchToTransport = itemsToTransport.filter(order => {
    return (
      (order.id_solicitud_pedido.id
        .toString()
        .toLowerCase()
        .indexOf(inputToTransport.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.tipo_solicitud
          .toLowerCase()
          .indexOf(inputToTransport.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.fecha_solicitud
          .toLowerCase()
          .indexOf(inputToTransport.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.estado_picking
          .toLowerCase()
          .indexOf(inputToTransport.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.fecha_despacho
          .toLowerCase()
          .indexOf(inputToTransport.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.nombre_paciente
          .toLowerCase()
          .indexOf(inputToTransport.toLowerCase()) !== -1 ||
        getInfo(order.id_solicitud_pedido.es_region)
          .toString()
          .toLowerCase()
          .indexOf(inputToTransport.toLowerCase()) !== -1 ||
        getInfo(order.id_solicitud_pedido.tiene_medicamentos)
          .toString()
          .toLowerCase()
          .indexOf(inputToTransport.toLowerCase()) !== -1 ||
        months_open(order.id_solicitud_pedido.fecha_solicitud)
          .toString()
          .toLowerCase()
          .indexOf(inputToTransport.toLowerCase()) !== -1 ||
        days_open(order.id_solicitud_pedido.fecha_solicitud)
          .toString()
          .toLowerCase()
          .indexOf(inputToTransport.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.estado_solicitud
          .toLowerCase()
          .indexOf(inputToTransport.toLocaleLowerCase()) !== -1) &&
      (order.id_solicitud_pedido.tipo_solicitud
        .toLowerCase()
        .indexOf(requestTypeToTransport.toLowerCase()) !== -1 &&
        order.id_solicitud_pedido.estado_picking
          .toLowerCase()
          .indexOf(pickingStatusInProcess.toLowerCase()) !== -1 &&
        getInfo(order.id_solicitud_pedido.es_region)
          .toString()
          .toLowerCase()
          .indexOf(regionInProcess.toLowerCase()) !== -1 &&
        getInfo(order.id_solicitud_pedido.tiene_medicamentos)
          .toString()
          .toLowerCase()
          .indexOf(supplyInProcess.toLowerCase()) !== -1)
    );
  });

  let searchFinalized = itemsFinalized.filter(order => {
    return (
      (order.id_solicitud_pedido.id
        .toString()
        .toLowerCase()
        .indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.tipo_solicitud
          .toLowerCase()
          .indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.fecha_solicitud
          .toLowerCase()
          .indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.estado_picking
          .toLowerCase()
          .indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.fecha_despacho
          .toLowerCase()
          .indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.nombre_paciente
          .toLowerCase()
          .indexOf(inputFinalized.toLowerCase()) !== -1 ||
        getInfo(order.id_solicitud_pedido.es_region)
          .toString()
          .toLowerCase()
          .indexOf(inputFinalized.toLowerCase()) !== -1 ||
        getInfo(order.id_solicitud_pedido.tiene_medicamentos)
          .toString()
          .toLowerCase()
          .indexOf(inputFinalized.toLowerCase()) !== -1 ||
        months_open(order.id_solicitud_pedido.fecha_solicitud)
          .toString()
          .toLowerCase()
          .indexOf(inputFinalized.toLowerCase()) !== -1 ||
        days_open(order.id_solicitud_pedido.fecha_solicitud)
          .toString()
          .toLowerCase()
          .indexOf(inputFinalized.toLowerCase()) !== -1 ||
        order.id_solicitud_pedido.estado_solicitud
          .toLowerCase()
          .indexOf(inputFinalized.toLocaleLowerCase()) !== -1) &&
      (order.id_solicitud_pedido.tipo_solicitud
        .toLowerCase()
        .indexOf(requestTypeFinalized.toLowerCase()) !== -1 &&
        order.id_solicitud_pedido.estado_picking
          .toLowerCase()
          .indexOf(pickingStatusFinalized.toLowerCase()) !== -1 &&
        getInfo(order.id_solicitud_pedido.es_region)
          .toString()
          .toLowerCase()
          .indexOf(regionFinalized.toLowerCase()) !== -1 &&
        getInfo(order.id_solicitud_pedido.tiene_medicamentos)
          .toString()
          .toLowerCase()
          .indexOf(supplyFinalized.toLowerCase()) !== -1)
    );
  });

  const handleOpenModalTransport = (open, id) => {
    setModalTransport(open);
    setIdTransport(id);
  };

  return (
    <GridContainer>
      <ViewTitle
        title="RECEPCIÓN DE PEDIDOS"
        message="En esta sección podrás revisar la recepción de los pedidos de bodega."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/outcome/tasks/${moduleType}`}>
              Tareas de egresos
            </Link>
            <Typography color="textPrimary">Recepción de pedidos</Typography>
            {checkTab === 0 ? (
              <Typography color="textPrimary">En proceso</Typography>
            ) : checkTab === 1 ? (
              <Typography color="textPrimary">
                En espera a transporte
              </Typography>
            ) : (
              <Typography color="textPrimary">Finalizado</Typography>
            )}
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="mt-3">
              <GridContainer justify="center">
                <ReceptionsInformation />
              </GridContainer>
              {checkTab === 0 ? (
                <Fade in>
                  <TextField
                    id="outlined-basic"
                    label="Buscar en proceso"
                    variant="outlined"
                    value={inputInProcess}
                    className="mt-1"
                    onChange={e =>
                      setInputInProcess(e.target.value.substr(0, 20))
                    }
                  />
                </Fade>
              ) : checkTab === 1 ? (
                <Fade in>
                  <TextField
                    id="outlined-basic"
                    label="Buscar transporte"
                    variant="outlined"
                    value={inputToTransport}
                    className="mt-1"
                    onChange={e =>
                      setInputToTransport(e.target.value.substr(0, 20))
                    }
                  />
                </Fade>
              ) : (
                <Fade in>
                  <TextField
                    id="outlined-basic"
                    label="Buscar finalizado"
                    variant="outlined"
                    value={inputFinalized}
                    className="mt-1"
                    onChange={e =>
                      setInputFinalized(e.target.value.substr(0, 20))
                    }
                  />
                </Fade>
              )}
            </GridContainer>
            <CardBody className="mb-1">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  centered
                >
                  <Tab
                    style={{ textTransform: "none" }}
                    label="En proceso"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="En espera a transporte"
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Finalizado"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <TableInProcessOutcome
                  searchInProcess={searchInProcess}
                  requestSortInProcess={requestSortInProcess}
                  getClassNamesForInProcess={getClassNamesForInProcess}
                  requestTypeInProcess={requestTypeInProcess}
                  setRequestTypeInProcess={setRequestTypeInProcess}
                  pickingStatusInProcess={pickingStatusInProcess}
                  setPickingStatusInProcess={setPickingStatusInProcess}
                  regionInProcess={regionInProcess}
                  setRegionInProcess={setRegionInProcess}
                  supplyInProcess={supplyInProcess}
                  setSupplyInProcess={setSupplyInProcess}
                  moduleType={moduleType}
                  months_open={months_open}
                  days_open={days_open}
                  setOpenModalMessage={setOpenModalMessage}
                  handleOpenModalTransport={handleOpenModalTransport}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableWaitToTransportOutcome
                  searchToTransport={searchToTransport}
                  requestSortToTransport={requestSortToTransport}
                  getClassNamesForToTransport={getClassNamesForToTransport}
                  requestTypeToTransport={requestTypeToTransport}
                  setRequestTypeToTransport={setRequestTypeToTransport}
                  pickingStatusToTransport={pickingStatusToTransport}
                  setPikcingStatusToTransport={setPikcingStatusToTransport}
                  regionToTransport={regionToTransport}
                  setRegionToTransport={setRegionToTransport}
                  supplyToTransport={supplyToTransport}
                  setSupplyToTransport={setSupplyToTransport}
                  moduleType={moduleType}
                  months_open={months_open}
                  days_open={days_open}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TableFinalizedOutcome
                  searchFinalized={searchFinalized}
                  requestSortFinalized={requestSortFinalized}
                  getClassNamesForFinalized={getClassNamesForFinalized}
                  requestTypeFinalized={requestTypeFinalized}
                  setRequestTypeFinalized={setRequestTypeFinalized}
                  pickingStatusFinalized={pickingStatusFinalized}
                  setPickingStatusFinalized={setPickingStatusFinalized}
                  regionFinalized={regionFinalized}
                  setRegionFinalized={setRegionFinalized}
                  supplyFinalized={supplyFinalized}
                  setSupplyFinalized={setSupplyFinalized}
                  months_open={months_open}
                  days_open={days_open}
                  moduleType={moduleType}
                />
              </TabPanel>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modal_create_covenant && (
        <ModalCreateCovenant
          open={modal_create_covenant}
          closeModal={() => setModal_create_covenant(false)}
          moduleType={moduleType}
        />
      )}
      {modalTransport && (
        <ModalTransport
          open={modalTransport}
          closeModal={() => setModalTransport(false)}
          idTransport={idTransport}
          moduleType={moduleType}
        />
      )}
      {openModalMessage && (
        <ModalMessageBodega
          open={openModalMessage}
          closeModal={() => setOpenModalMessage(false)}
          moduleType={moduleType}
        />
      )}
    </GridContainer>
  );
}
