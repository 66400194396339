import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import ModalCreateCovenant from "components/Covenants/ModalCreateCovenant";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import RequestsInformation from "./RequestsInformation";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import PurchasesTable from "./PurchasesTable/PurchasesTable";
import InProcessTable from "./InProcess/InProcessTable";
import ToComeTable from "./ToCome/ToComeTable";
import FinalizedTable from "./Finalized/FinalizedTable";
import ModalSetPurchase from "./ModalSetPurchase";
import ModalRejectPurchase from "./ModalRejectPurchase";
import DialogDespacho from "./InProcess/Despacho/DialogDespacho";
import ModalDespacho from "./InProcess/Despacho/ModalDespacho";

import { Position } from "./Position/Position";



export default function PurchaseRequest() {
  const [loading, setLoading] = useState(true);

  const [inProcess, setInProcess] = useState([]);
  const [toCome, setToCome] = useState([]);
  const [finalized, setFinalized] = useState([]);

  const [modal_create_covenant, setModal_create_covenant] = useState(false);
  const [value, setValue] = useState(0);
  const [modalSetPurchase, setModalSetPurchase] = useState(false);
  const [modalRejectPurchase, setModalRejectPurchase] = useState(false);

  const [checkTab, setCheckTab] = useState(0);
  const [purchases, setPurchases] = useState([]);

  const [purchasesToSend, setPurchasesToSend] = useState([]);
  const [copyPurchases, setCopyPurchases] = useState([]);

  const [solicitudesCompras, setSolicitudesCompras] = useState([]);

  const [pageRefresh, setPageRefresh] = useState(false);

  const [showHandlePurchase, setShowHandlePurchase] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [requestGroup, setRequestGroup] = useState("");
  const [searchPurchases, setSearchPurchases] = useState("");
  const [listProductType, setListProductType] = useState([]);
  const [groupSelected, setGroupSelected] = useState("");
  const [searchInProcess, setSearchInProcess] = useState("");
  const [searchToArrive, setSearchToArrive] = useState("");
  const [searchFinalized, setSearchFinalized] = useState("");
  const [openDialogDespacho, setOpenDialogDespacho] = useState(false);
  const [openModalDespacho, setOpenModalDespacho] = useState(false);
  const [internalTransport, setInternalTransport] = useState(null);
  const [quotationId, setQuotationId] = useState(null);

  const history = useHistory();

  const routeChange = () => {
    let path = `/admin/purchases/calendar/`;
    history.push(path);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    if (value === 0) {
      setCheckTab(0);
    }
    if (value === 1) {
      setCheckTab(1);
    }
    if (value === 2) {
      setCheckTab(2);
    }
    if (value === 3) {
      setCheckTab(3);
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [changes, setChanges] = useState(true);

  useEffect(() => {
    if (changes) {
      initialChargeReception();
    }
    if (pageRefresh) {
      setPageRefresh(false);
    }
  });

  const listTypeProd = useSelector(state => state.currentList.LISTA_TIPO_PRODUCTO);
  const dictTypeProd = useSelector(state => state.currentList.DICCIONARIO_TIPO_PRODUCTO);
  const listRequestType = useSelector(state => state.currentList.LISTA_TIPO_COMPRA_EN_PROCESO);
  const dictTypeBuyProcess = useSelector(state => state.currentList.DICCIONARIO_TIPO_COMPRA_EN_PROCESO);

  const initialChargeReception = () => {
    setChanges(false);
    const purchases = api.get("api/compras/solicitudescompras/");
    const inProcess = api.get("api/compras/solicitudcotizacion/activas/");
    const toCome = api.get("api/compras/solicitudcotizacion/activas/");
    const finalized = api.get("api/compras/solicitudcotizacion/finalizadas/");

    Promise.all([
      purchases,
      inProcess,
      toCome,
      finalized,
    ])
      .then(response => {
        let tempPurchase = [];
        response[0].data.map(row => {
          tempPurchase.push({
            ...row,
            group: dictTypeProd[row.id_producto_comprado.grupo_producto]?.value || "",
            sku: row.id_producto_comprado.SKU,
            description: row.id_producto_comprado.descripcion_producto
          });
        });
        setPurchases(tempPurchase);
        let tempInProcess = [];

        response[1].data.map(row => {
          tempInProcess.push({
            ...row,
            type: dictTypeBuyProcess[row.estado].value || "",
            status:
              row.estado === "RevisarCompra"
                ? "Revisar compra"
                : row.estado === "JefeSeleccionaCotizacion"
                ? "Esperando aprobación del jefe"
                : row.estado === "AdjuntarOrdenCompra"
                ? "Adjuntar orden de compra"
                : row.estado === "RevisarOC"
                ? "Esperar revisión orden de compra"
                : row.estado === "IngresarDespacho"
                ? "Ingresar despacho"
                : row.estado
          });
        });

        setInProcess(
          tempInProcess.filter(
            filtered =>
              filtered.estado != "Por llegar" &&
              filtered.estado != "Solicitar compra" &&
              filtered.estado != "Finalizada" &&
              filtered.estado != "Estado" &&
              filtered.estado != "Activas" &&
              filtered.estado != "AdjuntarGuiaIngreso" &&
              filtered.estado != "EsperandoTransporte" &&
              filtered.estado != "IngresarArticuloSoftland"
          )
        );
        setToCome(
          tempInProcess.filter(
            filtered =>
              filtered.estado === "Por llegar" ||
              filtered.estado === "PorLlegar"
          )
        );
        let tempFinalized = [];
        response[3].data.map(row => {
          tempFinalized.push({
            ...row,
            type: dictTypeBuyProcess[row.tipo_solicitud_cotizar]?.value || ""
          });
        });
        setFinalized(tempFinalized);
        setListProductType(listTypeProd.filter(row => row[0] != "No"));

        setLoading(false);
      })
      .catch(error => {

        setLoading(false);
      });

      setLoading(false);
  };

  const styles = {
    icon: {
      fontSize: 50
    }
  };

  const sendPurchases = () => {
    const tempPurchase = [];

    const tempPurchasesArray = purchases.filter(filtered => filtered.select);

    tempPurchasesArray.map(row => {
      const tempRow = row;
      solicitudesCompras.push(tempRow.id);
      const tempSkuCheck = tempPurchase.filter(
        filtered =>
          filtered.id_producto_comprado.SKU === tempRow.id_producto_comprado.SKU
      );
      if (tempSkuCheck.length > 0) {
        let tempCantidad = tempPurchase.filter(
          filtered =>
            filtered.id_producto_comprado.SKU ===
            tempRow.id_producto_comprado.SKU
        )[0].cantidad;

        const tempIndex = tempPurchase.indexOf(
          tempPurchase.filter(
            filtered =>
              filtered.id_producto_comprado.SKU ===
              tempRow.id_producto_comprado.SKU
          )[0]
        );
        tempPurchase[tempIndex].cantidad = tempCantidad + tempRow.cantidad;
      } else {
        tempPurchase.push({ ...tempRow });
      }
    });
    setPurchasesToSend(tempPurchase);
    setPageRefresh(true);
  };

  let searchPurchasesFilter = purchases.filter(order => {
    return (
      (order.id
        .toString()
        .toLowerCase()
        .indexOf(searchPurchases.toLowerCase()) !== -1 ||
        order.sku.toLowerCase().indexOf(searchPurchases.toLowerCase()) !== -1 ||
        order.description
          .toLowerCase()
          .indexOf(searchPurchases.toLowerCase()) !== -1 ||
        order.group.toLowerCase().indexOf(searchPurchases.toLowerCase()) !==
          -1 ||
        order.tipo_solicitud
          .toLowerCase()
          .indexOf(searchPurchases.toLowerCase()) !== -1 ||
        order.cantidad
          .toString()
          .toLowerCase()
          .indexOf(searchPurchases.toLowerCase()) !== -1 ||
        order.observaciones
          .toLowerCase()
          .indexOf(searchPurchases.toLowerCase()) !== -1 ||
        order.area_solicitante
          .toLowerCase()
          .indexOf(searchPurchases.toLowerCase()) !== -1 ||
        order.fecha_solicitud
          .toLowerCase()
          .indexOf(searchPurchases.toLowerCase()) !== -1) &&
      order.group.toLowerCase().indexOf(groupSelected.toLowerCase()) !== -1
    );
  });

  let searchPurchasesInProcessFilter = inProcess.filter(order => {
    return (
      order.id
        .toString()
        .toLowerCase()
        .indexOf(searchInProcess.toLowerCase()) !== -1 ||
      order.type.toLowerCase().indexOf(searchInProcess.toLowerCase()) !== -1 ||
      order.cantidad_productos
        .toString()
        .toLowerCase()
        .indexOf(searchInProcess.toLowerCase()) !== -1 ||
      order.observacion.toLowerCase().indexOf(searchInProcess.toLowerCase()) !==
        -1 ||
      (order.fecha_compra === null
        ? null
        : order.fecha_compra
            .toString()
            .toLowerCase()
            .indexOf(searchInProcess.toLowerCase()) !== -1) ||
      order.status
        .toString()
        .toLowerCase()
        .indexOf(searchInProcess.toLowerCase()) !== -1
    );
  });
  let searchPurchaseToComeFilter = toCome.filter(order => {
    return (
      order.id
        .toString()
        .toLowerCase()
        .indexOf(searchToArrive.toLowerCase()) !== -1 ||
      order.type.toLowerCase().indexOf(searchToArrive.toLowerCase()) !== -1 ||
      order.cantidad_productos
        .toString()
        .toLowerCase()
        .indexOf(searchToArrive.toLowerCase()) !== -1 ||
      order.observacion.toLowerCase().indexOf(searchToArrive.toLowerCase()) !==
        -1 ||
      (order.fecha_compra === null
        ? null
        : order.fecha_compra
            .toString()
            .toLowerCase()
            .indexOf(searchToArrive.toLowerCase()) !== -1) ||
      order.estado
        .toString()
        .toLowerCase()
        .indexOf(searchToArrive.toLowerCase()) !== -1
    );
  });

  let searchPurchasesFinalizedFilter = finalized.filter(order => {
    return (
      order.id
        .toString()
        .toLowerCase()
        .indexOf(searchFinalized.toLowerCase()) !== -1 ||
      order.type.toLowerCase().indexOf(searchFinalized.toLowerCase()) !== -1 ||
      order.cantidad_productos
        .toString()
        .toLowerCase()
        .indexOf(searchFinalized.toLowerCase()) !== -1 ||
      order.observacion.toLowerCase().indexOf(searchFinalized.toLowerCase()) !==
        -1 ||
      (order.fecha_compra === null
        ? null
        : order.fecha_compra
            .toString()
            .toLowerCase()
            .indexOf(searchFinalized.toLowerCase()) !== -1) ||
      order.estado
        .toString()
        .toLowerCase()
        .indexOf(searchFinalized.toLowerCase()) !== -1
    );
  });

  return (
    <GridContainer>
      <ViewTitle
        title="Solicitudes de compras"
        message="En esta sección podrás revisar las solicitudes de compras."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to="/admin/purchases/task/">
              Tareas de compras
            </Link>
            <Typography color="textPrimary">Solicitudes de compras</Typography>
            {Position(checkTab)}
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {!loading ? (
          <Card>
            <GridContainer justify="center" className="mt-5">
              <GridContainer justify="center">
                <RequestsInformation />
              </GridContainer>
              {value === 0 ? (
                <TextField
                  id="outlined-basic"
                  label="Buscar en armar compra"
                  variant="outlined"
                  value={searchPurchases}
                  className="mt-1 mb-4 col-md-3"
                  onChange={e => setSearchPurchases(e.target.value)}
                />
              ) : value === 1 ? (
                <TextField
                  id="outlined-basic"
                  label="Buscar compras en proceso"
                  variant="outlined"
                  value={searchInProcess}
                  className="mt-1 mb-4 col-md-3"
                  onChange={e => setSearchInProcess(e.target.value)}
                />
              ) : value === 2 ? (
                <TextField
                  id="outlined-basic"
                  label="Buscar compras por llegar"
                  variant="outlined"
                  value={searchToArrive}
                  className="mt-1 mb-4 col-md-3"
                  onChange={e => setSearchToArrive(e.target.value)}
                />
              ) : (
                <TextField
                  id="outlined-basic"
                  label="Buscar compras finalizadas"
                  variant="outlined"
                  value={searchFinalized}
                  className="mt-1 mb-4 col-md-3"
                  onChange={e => setSearchFinalized(e.target.value)}
                />
              )}
              <GridContainer justify="center">
                {checkTab === 0 ? (
                  <div>
                    {showHandlePurchase > 0 ? (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            sendPurchases();
                            setModalSetPurchase(true);
                          }}
                          style={{ textTransform: "none" }}
                        >
                          Armar compra
                        </Button>
                        <span className="ml-1 mr-1" />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            sendPurchases();
                            setModalRejectPurchase(true);
                          }}
                          style={{ textTransform: "none" }}
                        >
                          Rechazar solicitud de compra
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            sendPurchases();
                            setModalSetPurchase(true);
                          }}
                          disabled
                          style={{ textTransform: "none" }}
                        >
                          Armar compra
                        </Button>
                        <span className="ml-1 mr-1" />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            sendPurchases();
                            setModalRejectPurchase(true);
                          }}
                          disabled
                          style={{ textTransform: "none" }}
                        >
                          Rechazar solicitud de compra
                        </Button>
                      </div>
                    )}
                  </div>
                ) : null}
              </GridContainer>
              <GridItem
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                {value === 1 ? (
                  <IconButton
                    onClick={routeChange}
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <InsertInvitationIcon style={styles.icon} />
                  </IconButton>
                ) : null}
              </GridItem>
            </GridContainer>
            <CardBody className="mb-3">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  centered
                >
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Armar compras"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Compras en proceso"
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Compras por llegar"
                    {...a11yProps(2)}
                  />
                  <Tab
                    style={{ textTransform: "none" }}
                    label="Compras finalizadas"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <PurchasesTable
                  purchases={searchPurchasesFilter}
                  setPurchases={setPurchases}
                  setCopyPurchases={setCopyPurchases}
                  setPageRefresh={setPageRefresh}
                  pageRefresh={pageRefresh}
                  requestType={requestType}
                  setRequestType={setRequestType}
                  requestGroup={requestGroup}
                  setRequestGroup={setRequestGroup}
                  setShowHandlePurchase={setShowHandlePurchase}
                  groupSelected={groupSelected}
                  setGroupSelected={setGroupSelected}
                  listProductType={listProductType}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <InProcessTable
                  inProcess={searchPurchasesInProcessFilter}
                  setOpenDialogDespacho={setOpenDialogDespacho}
                  setQuotationId={setQuotationId}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ToComeTable
                  toCome={searchPurchaseToComeFilter}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <FinalizedTable
                  finalized={searchPurchasesFinalizedFilter}
                />
              </TabPanel>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modal_create_covenant && (
        <ModalCreateCovenant
          open={modal_create_covenant}
          closeModal={() => setModal_create_covenant(false)}
        />
      )}
      {modalSetPurchase && (
        <ModalSetPurchase
          purchasesToSend={purchasesToSend}
          solicitudesCompras={solicitudesCompras}
          open={modalSetPurchase}
          closeModal={() => setModalSetPurchase(false)}
        />
      )}
      {modalRejectPurchase && (
        <ModalRejectPurchase
          purchasesToSend={purchasesToSend}
          solicitudes_compras={solicitudesCompras}
          open={modalRejectPurchase}
          closeModal={() => setModalRejectPurchase(false)}
        />
      )}
      {openDialogDespacho && (
        <DialogDespacho
          openDialog={openDialogDespacho}
          closeDialog={() => setOpenDialogDespacho(false)}
          setInternalTransport={setInternalTransport}
          setOpenModalDespacho={setOpenModalDespacho}
        />
      )}
      {openModalDespacho && (
        <ModalDespacho
          openModal={openModalDespacho}
          closeModal={() => setOpenModalDespacho(false)}
          internalTransport={internalTransport}
          quotationId={quotationId}
        />
      )}
      {pageRefresh && (<div></div>)}
    </GridContainer>
  );
}
