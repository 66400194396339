import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "utils/API";
import apiformxlsx from "utils/APIForm"
import LoadingSpinnerInfo from "views/Widgets/FichaPersonal/loadingSpinnerInfo";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ViewTitle from "../Widgets/FichaPersonal/viewTitle";
import CardBody from "components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import TabEndowments from "./TabEndowments";
import SearchEndowments from "./SearchEndowments";
import ModalCreateUser from "components/Endowments/ModalCreateUser";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");



export default function Endowments() {
  const [firstCharge, setFirstCharge] = useState(true);

  const [tabValue, setTabValue] = useState(0);
  const [search, setSearch] = useState("");
  const [searchInactivas, setSearchInactivas] = useState("");
  const [activas, setActivas] = useState([]);
  const [inactivas, setInactivas] = useState([]);
  const [openModalNewUser, setopenModalNewUser] = useState(false);

  const listApiCharge = useSelector(state => state.currentList.listApiCharge);

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);
    const getActivas = api.get("api/personal/ficha/activas/");
    const getInactivas = api.get("api/personal/ficha/inactivas/");

    Promise.all([getActivas, getInactivas])
      .then(response => {
        setActivas(response[0].data);
        setInactivas(response[1].data);
      })

  };

  const generateEndowment = () => {
    api
      .get("api/personal/descargardotacion/", { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "dotacion.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const tempSearch = search.toLowerCase();
  const tempSearchInactivas = searchInactivas.toLowerCase();

  const searchActivas = activas.filter(row => (
    `${row.nombres} ${row.apellido_paterno} ${row.apellido_materno}`.toString().toLowerCase().includes(tempSearch) ||
    (row.status ? row.status.toString().toLowerCase().includes(tempSearch) : false) ||
    (row.estado_vigencia ? row.estado_vigencia.toString().toLowerCase().includes(tempSearch) : false ) ||
    (row.id_cargo ? row.id_cargo.nombre_corto.toString().toLowerCase().includes(tempSearch) : false )
  ));

  const searchInactivasFilter = inactivas.filter(row => (
    `${row.nombres} ${row.apellido_paterno} ${row.apellido_materno}`.toString().toLowerCase().includes(tempSearchInactivas) ||
    (row.status ? row.status.toString().toLowerCase().includes(tempSearchInactivas) : false) ||
    (row.estado_vigencia ? row.estado_vigencia.toString().toLowerCase().includes(tempSearchInactivas) : false) ||
    (row.id_cargo ? row.id_cargo.nombre_corto.toString().toLowerCase().includes(tempSearchInactivas) : false )
  ));

  const handleMultipleUser = (fileMultiUser, setfileMultiUser) => {
    const formData = new FormData();
    formData.append("archivo", fileMultiUser);
    apiformxlsx
      .post("api/personal/ficha/cargamasiva/", formData)
      .then(response => {
        console.log(response.data);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download = `resultado_carga_masiva_${moment().format("YYYY-MM-DD")}.csv`;
        document.body.appendChild(link);
        link.click();
        setfileMultiUser(null)
      })
      .catch(error => {
        setfileMultiUser(null)
        {}
      });
  }

  return (
    <GridContainer>
      <ViewTitle
        title="DOTACIÓN"
        message="En esta sección podrás revisar la dotación de la clínica."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Recursos humanos</Typography>
            <Typography color="textPrimary">Dotación</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12} justify="center">
        <Card>
          <GridContainer justify="center">
            {listApiCharge.length > 0 ? (
              <button className="btn btn-cmh-color mb-4 mt-5">
                <LoadingSpinnerInfo />
              </button>
            ) : (
              <button
                onClick={generateEndowment}
                className="btn btn-cmh-color mb-4 mt-5"
              >
                Descargar dotación
              </button>
            )}
          </GridContainer>
          <GridContainer justify="center" style={{ marginBottom: "3em" }}>
            <SearchEndowments
              search={search}
              setSearch={setSearch}
              searchInactivas={searchInactivas}
              setSearchInactivas={setSearchInactivas}
              tabValue={tabValue}
              setopenModalNewUser={setopenModalNewUser}
              handleMultipleUser={handleMultipleUser}
            />
          </GridContainer>
          <Grid container justify="center">
            <Grid item xs={1} />
            <Grid item xs={10}>
              <TabEndowments
                setTabValue={setTabValue}
                activas={searchActivas}
                inactivas={searchInactivasFilter}
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Card>
      </GridItem>
      {openModalNewUser &&
      <ModalCreateUser
          open={openModalNewUser}
          handleClose={() => setopenModalNewUser(false)}
      />
      }
    </GridContainer>
  );
}
