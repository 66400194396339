import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import TableComponent from "views/Widgets/Common/TableComponent";
import ModalAdd from "./ModalAdd";
import {
  getListSuppliesMedicines,
} from "actions/getListAction";

const ResourceTableComponent = props => {
  const dispatch = useDispatch()
  const {
    dataResourceArray,
    setDataResourceArray,
    setPageRefresh,
    margin="30px 0 0 0",
    disabled=false,
    initialCharge=true
  } = props;
  const [openModal, setOpenModal] = useState(null);
  const [loading, setLoading] = useState(false);

  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const listResource = useSelector(state => state.currentList.listSupplies);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    if(listResource.length === 0 && !chargeMedicine && initialCharge) {
      getListSuppliesMedicines(dispatch, chargeMedicine)
    }
  });

  const handleDeleteRow = (row, index, table) => {
    setLoading(table);
    let tempDeleteArray = dataResourceArray;
    setDataResourceArray(tempDeleteArray.filter(array => array.id != row.id));
    setPageRefresh(true);
  };

  const handleChangeQuantity = (value, index, table) => {
    setLoading(table);
    const auxDataArray = dataResourceArray;
    if(value ===""){
      auxDataArray[index].cantidad = undefined
    }else{
      auxDataArray[index].cantidad = parseInt(value);
    }
    setDataResourceArray(auxDataArray);
    setPageRefresh(true);
  };

  const handleAddItem = value => {
    let auxArray = dataResourceArray;
    const tempData = medicalResourceData[value.value];
    const element = {
      item: tempData.descripcion_producto,
      sku: tempData.SKU,
      id: tempData.id,
      cantidad: 1
    };
    auxArray.push(element);
    setDataResourceArray(auxArray);
    setPageRefresh(true);
  };

  const headerTable = [
    {label: "Item"},
    {label: "SKU"},
    {label: "Cantidad"},
    {label: "Acción", hidding: disabled},
  ];

  const tempDataResourceArray = dataResourceArray.map((row, index) => {
    const _amount_ =  <DetailNumber
      isdisabled={disabled}
      value={row.cantidad}
      onchange={e => {
        //if (/^[0-9\b]+$/.test(e.target.value)) {
          handleChangeQuantity(e.target.value, index, true);
        //}
      }}
      />

    const _action_ =   <Button
      variant="contained"
      color="primary"
      style={{
        background: red[500],
        textTransform: "none"
      }}
      onClick={() => handleDeleteRow(row, index, true)}
    >
      Eliminar
    </Button>

    //eval is not use in table only condition to filter
    return [
      {eval:true, value: row.item},
      {eval:true, value: row.sku},
      {eval:false, _value_: _amount_},
      {eval:false, _value_: _action_, hidding: disabled},
    ]
  });

  return (
    <Grid container spacing={3}>
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
      >
        {!disabled &&
        <Button
          disabled={listResource.length === 0}
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenModal(false);
          }}
          style={{ textTransform: "none" }}
        >
          {listResource.length === 0 ? "Cargando Insumos...": "Agregar Insumos"}
        </Button>
        }
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          margin: margin,
          maxHeight: "300px",
          overflow: "auto"
        }}
        justify="center"
      >
          <TableComponent
            headers={headerTable}
            data={tempDataResourceArray}
            size={'small'}
            pageSize={100}
            styleContainer={{overflowX: 'auto'}}
          />
      </Grid>
      {openModal !== null && (
      <ModalAdd
        open={openModal !== null}
        table={openModal}
        handleClose={() => setOpenModal(null)}
        listItem={listResource}
        dataArray={dataResourceArray}
        setdataArray={setDataResourceArray}
        handleAddItem={handleAddItem}
      />
    )}
  </Grid>
  );
};

export default ResourceTableComponent;

ResourceTableComponent.propTypes = {
  dataResourceArray: PropTypes.array,
  setDataResourceArray: PropTypes.func,
  setPageRefresh: PropTypes.func
};
