import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import {TextField, Button, Grid, Typography} from "@material-ui/core/";
import api from "utils/API";
import TableSummary from "./TableSummary";
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import {
  getListRequestPatient,
} from 'actions/getListAction';
import "moment/locale/es";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import ModalDialog from "views/Widgets/Common/ModalDialog";

moment.locale("es");

const ModalSummary = props => {
  const dispatch = useDispatch()
  const {
    openModal,
    closeModal,
    settypeRequest,
    setOpenDialogDelete,
    dataMedicalArray,
    dataResourceArray,
    state,
    dataPatient,
    storage=null,
  } = props;
  const [charge, setCharge] = useState(true);
  const [tempArray, setTempArray] = useState([]);
  const [requestDate, setrequestDate] = useState("");
  const [observation, setobservation] = useState("");
  const [documentGuide, setdocumentGuide] = useState(null);
  const [isEmergency, setisEmergency] = useState({value: false, label: "No"});
  const limitObservation = 400
  // const [loading, setloading] = useState(state === "summary");

  useEffect(() => {
    if (charge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setCharge(false);

    let tempArrayRequest = [];
    let tempArrayMedicamentos = [];
    dataMedicalArray.map(data => {
      tempArrayRequest.push({
        tipo: "Medicamento",
        info: data
      });
      tempArrayMedicamentos.push({
        id_insumo_medicamento: data.id,
        cantidad: data.cantidad
      });
    });
    dataResourceArray.map(data => {
      tempArrayRequest.push({
        tipo: "Insumos",
        info: data
      });
    });

    setTempArray(tempArrayRequest);
  };

  const handleObservation = (value) => {
    setobservation(value);

  }

  const saveRequest = status => {

    let productos_pedidos = {};
    let tempMedicamentos = [];
    dataMedicalArray.map(data => {
      tempMedicamentos.push({
        id_insumo_medicamento: data.id,
        cantidad: data.cantidad
      });
    });

    let tempInsumos = [];
    dataResourceArray.map(data => {
      tempInsumos.push({
        id_insumo_medicamento: data.id,
        cantidad: data.cantidad
      });
    });

    let tempEquipos = [];

    if (tempMedicamentos.length > 0) {
      productos_pedidos["medicamentos"] = tempMedicamentos;
    }
    if (tempInsumos.length > 0) {
      productos_pedidos["insumos"] = tempInsumos;
    }
    if (tempEquipos.length > 0) {
      productos_pedidos["equipos"] = [];
    }
    const body = {
      productos_pedidos: productos_pedidos,
      fecha_entrega: moment(requestDate).format("YYYY-MM-DDTHH:mm:ss"),
      observacion: observation,
      bodega: false,
      es_urgente: isEmergency.value,
    }
    if (storage?.value){
      body["bodega_empresa"] = storage?.value
    }


    api.post(`api/paciente/${dataPatient.id}/solicitudes/insumosextra/`, body).then(responsePost => {
        if (status) {
          if (documentGuide){
            const formData = new FormData();
            formData.append("nombre_archivo", documentGuide[0].name);
            formData.append("archivo", documentGuide[0]);
            formData.append("tipo_documento", "Receta");
            formData.append("observacion", "");
            formData.append("area", "Clinica");
            formData.append("id_paciente", dataPatient.id);
            formData.append("id_solicitud_paciente", responsePost.data.solicitud.id);
            api.post("/api/documentospaciente/", formData)
          }
              getListRequestPatient(dataPatient, dispatch)
              settypeRequest(null);
        } else {
          getListRequestPatient(dataPatient, dispatch)
          settypeRequest(null);

        }
      })

  };

  const _content_ = <div>
    <Grid container justify="center">
      <Grid item container xs={12} justify="center">
        <TableSummary tempArray={tempArray} />
      </Grid>
      {state === "summary" &&
      <Grid
        item
        xs={12}
        container
        direction="row"
        justify="center"
        alignItems="center"

      >
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{padding: "5px 0"}}
        >
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DateTimePicker
                variant="inline"
                format="DD/MM/YYYY HH:mm"
                margin="normal"
                label={"Fecha entrega en domiciolio"}
                placeholder=""
                minDate={moment()}
                minDateMessage={"La fecha de envío no puede ser anterior al día de hoy"}
                cancelLabel={"Cancelar"}
                okLabel={"Aceptar"}
                onChange={(value) => setrequestDate(value)}
                value={requestDate}
                inputVariant={"outlined"}
                invalidDateMessage={"Debe ingresar fecha de entrega en domicilio"}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
              item
              xs={4}
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{padding: "5px 0"}}
            >
            <DetailSelect
              value={isEmergency}
              onchange={value => {setisEmergency(value)}}
              label={"Es urgente?"}
              list={[{value: false, label: "No"}, {value: true, label: "Sí"}]}
            />
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-end"
          item
          xs={12}
        >
          <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
            <TextField
              label={"Observaciones"}
              multiline
              rows={8}
              value={observation}
              variant="outlined"
              onChange={(event) => handleObservation(event.target.value)}
              style={{width: "100%"}}
            />
          </Grid>
          <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
            {`${observation?.length || 0 }/${limitObservation}`}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-contain"
          item
          xs={8}
          style={{ margin: "10px 0" }}
        >
          <Button
            variant="contained"
            component="label"
            color="primary"
            className="text-white"
            style={{ margin: "0 10px" }}
          >
            Subir Archivo
            <TextField
              type="file"
              id="documentGuide"
              onChange={(event) => setdocumentGuide(event.target.files)}
              fullWidth
              my={2}
              style={{ display: "none" }}
            />
          </Button>
          {(documentGuide && documentGuide.length > 0) && `${documentGuide[0].name}`}
        </Grid>
      </Grid>
      }
    </Grid>
</div>

  const _actions_ = <>
    {state === "summary" ? <>
     <Button
        variant="contained"
        color="primary"
        onClick={closeModal}
        style={{ textTransform: "none" }}
      >
        Atrás
      </Button>
      {(((observation?.length || 0) <= limitObservation) && requestDate !== "" ) ?
        <Button
        variant="contained"
        style={{
          backgroundColor: "#43a047",
          textTransform: "none",
          color: "#fff"
        }}
        onClick={() => {
          saveRequest(true);
        }}
      >
        Enviar
      </Button>:
      <Button
        disabled
        variant="contained"
        style={{
          textTransform: "none",
        }}
      >
        Enviar
      </Button>
      }

      </> : <>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#43a047",
            textTransform: "none",
            color: "#fff"
          }}
          onClick={() => {
            settypeRequest(null);
            closeModal();
          }}
        >
        Agregar Items
      </Button>
      <Button
        variant="contained"
        color="secondary"
        style={{
          textTransform: "none"
        }}
        onClick={() => {
          setOpenDialogDelete(true);
        }}
      >
        Eliminar solicitud
      </Button>
      </>}
  </>

  return (
    <ModalDialog
      open={openModal}
      onClose={closeModal}
      title={`Resumen solicitud`}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
};

export default ModalSummary;

ModalSummary.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.object,
  idFicha: PropTypes.string,
  setOpenDialogSucess: PropTypes.func,
  setOpenModalAddRecord: PropTypes.func,
  setTempMessage: PropTypes.func,
  variableCounter: PropTypes.object,
  setVariableCounter: PropTypes.func,
  setPageRefresh: PropTypes.func,
  eventsVariables: PropTypes.array,
  setEvents: PropTypes.func,
  extraShift: PropTypes.bool,
  extraAttention: PropTypes.bool,
  holiday: PropTypes.bool,
  unpaidLeave: PropTypes.bool,
  absence: PropTypes.bool,
  settypeRequest: PropTypes.func,
  setOpenDialogDelete: PropTypes.func,
  dataMedicalArray: PropTypes.array,
  dataResourceArray: PropTypes.array,
  state: PropTypes.string,
  dataPatient: PropTypes.object,
};
