import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "5em"
  }
});

const SearchRequestWarehouse = props => {
  const classes = useStyles();
  const {
    checkTab,
    searchDivision,
    setSearchDivision,
    searchPurchases,
    setSearchPurchases,
    searchCommodity,
    setSearchCommodity,
    searchReport,
    setSearchReport
  } = props;

  return (
    <Grid container className={classes.root}>
      <Grid item container xs={12} justify="center">
        <Grid item container xs={4} justify="center">
          <FormControl fullWidth variant="outlined">
            {checkTab === 0 ? (
              <TextField
                id="outlined-disabled"
                label="Buscar en fraccionamiento"
                variant="outlined"
                value={searchDivision}
                onChange={e => setSearchDivision(e.target.value)}
              />
            ) : checkTab === 1 ? (
              <TextField
                id="outlined-disabled"
                label="Buscar en compras"
                variant="outlined"
                value={searchPurchases}
                onChange={e => setSearchPurchases(e.target.value)}
              />
            ) : checkTab === 2 ? (
              <TextField
                id="outlined-disabled"
                label="Buscar en mercadería"
                variant="outlined"
                value={searchCommodity}
                onChange={e => setSearchCommodity(e.target.value)}
              />
            ) : checkTab === 3 ? (
              <TextField
                id="outlined-disabled"
                label="Buscar en reportes"
                variant="outlined"
                value={searchReport}
                onChange={e => setSearchReport(e.target.value)}
              />
            ) : null}
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchRequestWarehouse;

SearchRequestWarehouse.propTypes = {
  checkTab: PropTypes.number,
  searchDivision: PropTypes.string,
  setSearchDivision: PropTypes.func,
  searchPurchases: PropTypes.string,
  setSearchPurchases: PropTypes.func,
  searchCommodity: PropTypes.string,
  setSearchCommodity: PropTypes.func,
  searchReport: PropTypes.string,
  setSearchReport: PropTypes.func
};
