import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import {
    Grid,
} from '@material-ui/core';
import {
    Search,
  } from "@material-ui/icons/"
import RequestSaveTable from "views/Widgets/FichaPaciente/RequestSaveTable";
import RequestProgressTable from "views/Widgets/FichaPaciente/RequestProgressTable";
import RequestFinishTable from "views/Widgets/FichaPaciente/RequestFinishTable";
import FilterBox from "views/Widgets/Transport/FilterBox";


export default function RequestPatientTabs({
  dataPatient,
  handleSelectRow,
  handleDeleteRow,
  handleSelectDetail,
  handleHistory,
  handleShowPrescription,
  handleQualitication,
  listEquip,
  equipData,
  reload
}) {
    const dispatch = useDispatch()
    const [filter, setfilter] = useState("");
    const [updateArray, setupdateArray] = useState(false);

    const listCharges = useSelector(state => state.currentList.listCharges);
    const listRequestPatient = useSelector(state => state.currentList.listRequestPatient);
    const dictPosition = useSelector(state => state.currentList.dictCharges);
    const dictTypeStateRequest = useSelector(state => state.currentList.DICCIONARIO_ESTADO_SOLICITUD_PACIENTE);
    const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PACIENTE);

    const auxData = listRequestPatient ? listRequestPatient.filter((row) => (
      filter === "" ||
      (
        row.id_cargo_solicitante ? dictPosition[row.id_cargo_solicitante]?.nombre.toLocaleLowerCase().includes(filter) : false ||
        dictTypeRequest[row.Tipo_Solicitud_Paciente] ? dictTypeRequest[row.Tipo_Solicitud_Paciente]?.label.toLocaleLowerCase().includes(filter) : false ||
        dictTypeStateRequest[row.estado] ? dictTypeStateRequest[row.estado]?.label.toLocaleLowerCase().includes(filter) : false ||
        row.id.toString().includes(filter)
      )
    )) : []

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (updateArray){

        }
    });


    const handleDeleteUpdateRow = (row) => {
        handleDeleteRow(row);
        setupdateArray(true);
    };

    const handleFilter = (event) => {
        setfilter(event.target.value.toLocaleLowerCase());
    };



    return (
      <GridItem xs={12}>
          <Grid item xs={12}>
            <FilterBox
                filterText={filter}
                handleFilter={handleFilter}
                IconSpan={Search}
                stylePaper={{ width: "100%" }}
            />
          </Grid>
          <NavPills
              color="primary"
              alignCenter
              tabs={[
                {
                  tabButton: "Guardadas",
                  tabContent: (
                    <RequestSaveTable
                        dataArray={auxData}
                        updateArray={reload}
                        dataPatient={dataPatient}
                        handleSelectRow={handleSelectRow}
                        handleDeleteRow={handleDeleteUpdateRow}
                    />
                  )
                },
                {
                  tabButton: "En curso",
                  tabContent: (
                    <RequestProgressTable
                        dataArray={auxData}
                        handleSelectRow={handleSelectRow}
                        handleSelectDetail={handleSelectDetail}
                        handleQualitication={handleQualitication}
                        dictTypeStateRequest={dictTypeStateRequest}
                        listEquip={listEquip}
                        equipData={equipData}
                        handleHistory={handleHistory}
                        dataPatient={dataPatient}
                    />
                  )
                },
                {
                  tabButton: "Finalizadas",
                  tabContent: (
                    <RequestFinishTable
                      dataArray={auxData}
                      handleShowPrescription={handleShowPrescription}
                    />
                  )
                }
              ]}
            />
        </GridItem>
    );
}