/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from 'notistack';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import store from './reducers/store';
import {Provider} from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import Login from "layouts/Login";
import Attendance from "views/Attendance/Attendance";
import Recovery from "views/Password/Recovery";
import BlockScreen from "views/Widgets/Common/BlockScreen";

import 'assets/App.scss';
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";


const hist = createBrowserHistory();



ReactDOM.render(
  <CookiesProvider>
  <Provider store={store}>
    <SnackbarProvider dense={false}>
      <Router history={hist}>
        <Switch>
          <Route path="/Login" component={Login} />
          <Route path="/rtl" component={RtlLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Route path="/attendance" component={Attendance} />
          <Route path="/password/recovery" component={Recovery} />

          <Redirect from="/" to="/Login" />
        </Switch>
      </Router>
      <BlockScreen />
    </SnackbarProvider>
  </Provider>
  </CookiesProvider>,

  document.getElementById("root")
);
