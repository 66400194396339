import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Checkbox, 
} from "@material-ui/core/"
import PropTypes from "prop-types";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import { indigo } from "@material-ui/core/colors";
import { Close, LocalShipping, Storage } from "@material-ui/icons/";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
const useStyles = makeStyles(styles);

const useStylesTable = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});
// eslint-disable-next-line react/prop-types
export default function TransportTable({
  dataArray,
  checkEnd,
  handleChange,
  handleDetail,
  setrowDetail,
  driverDict,
  vehicleDict,
  patientDict,
}) {
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const dictSTValueLabel = useSelector(state => state.currentList.DICCIONARIO_ESTADOS_TRANSPORTES); 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <Card style={{ margin: "0 0 0 0" }}>
      <CardBody>
        <GridContainer>
          <Paper className={classesTable.root}>
            <TableContainer className={classesTable.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ minWidth: "50px" }}>
                      Solicitud 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "60px" }}>
                      Caja 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "50px" }}>
                      Zona 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "50px" }}>
                      Comuna 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "40px" }}>
                      Tipo de solicitud 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "110px" }}>
                      Fecha solicitud 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "50px" }}>
                      Paciente 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "50px" }}>
                      Conductor 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "50px" }}>
                      Vehiculo 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "110px" }}>
                      {checkEnd ? "Fecha llegada" : "Fecha a despachar"}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "110px" }}>
                      Fecha llegada
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "50px" }}>
                      Es farmacia 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "50px" }}>
                      Solicitud tiene farmacia 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "50px" }}>
                      Estado recepción 
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "120px" }}>Acción </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataArray
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={`${index}-workRows`} align="center">
                        <TableCell align="center">
                        {
                          row.id_envios_pedidos ? `solicitud pedido: ${row.id_envios_pedidos.id_solicitud_pedido}`: 
                          row.id_parada_traslado ? `solicitud paciente: ${row.id_parada_traslado.id_traslado_paciente.id_solicitud_paciente}` :
                          row.id_parada_traslado_ingreso ? `solicitud ingreso: ${row?.id_parada_traslado_ingreso?.id_solicitud_ingreso.id}` : 
                          `Tramite - ${row.titulo} ${row.id}`
                        }
                        </TableCell>
                        <TableCell align="center">
                          {row.id_envios_pedidos
                            ? row.id_envios_pedidos.numero_pedido
                            : "-"}
                        </TableCell>

                        <TableCell align="center">{row.zona}</TableCell>
                        <TableCell align="center">{row.comuna}</TableCell>
                        <TableCell align="center">
                          {row.tipo_solicitud}
                        </TableCell>
                        <TableCell align="center">
                          {row.fecha_solicitud
                            ? moment(row.fecha_solicitud).format(
                                "YYYY-MM-DD HH:mm"
                              )
                            : row.id_parada_traslado
                            ? moment(row.id_parada_traslado.fecha_hora).format(
                                "YYYY-MM-DD HH:mm"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row.id_paciente
                            ? `${patientDict[row.id_paciente].nombre} ${patientDict[row.id_paciente].apellido_paterno} ${patientDict[row.id_paciente].apellido_materno}`
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {row.id_conductor
                            ? `${driverDict[row.id_conductor]?.first_name || "No se encuentra"} ${driverDict[row.id_conductor]?.last_name}`
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row.id_vehiculo
                            ? `${vehicleDict[row.id_vehiculo].patente}`
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row.fecha_tentativa_llegada ? 
                            moment(row.fecha_tentativa_llegada).format("YYYY-MM-DD HH:mm") : 
                            row.id_envios_pedidos ?
                              moment(row.id_envios_pedidos.fecha_tentativa_llegada).format("YYYY-MM-DD HH:mm") :
                              "-"
                          }
                        </TableCell>
                        <TableCell align="center">
                          {row.hora_llegada ? moment(row.hora_llegada).format("YYYY-MM-DD HH:mm") : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row.id_envios_pedidos
                            ? row.id_envios_pedidos.es_farmacia
                              ? "Sí"
                              : "No"
                            : row.es_farmacia != null
                            ? row.es_farmacia === true
                              ? "Sí"
                              : "No"
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row.solicitud_tiene_farmacia ? "Sí" : "No"}
                        </TableCell>
                        <TableCell align="center">
                          {dictSTValueLabel[row.estado_recepcion]?.label}
                        </TableCell>
                        <TableCell align="center">
                          <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            <Button
                              justIcon
                              round
                              size="sm"
                              style={{ background: indigo[500] }}
                              onClick={() =>
                                handleDetail([row, index], setrowDetail)
                              }
                            >
                              <Storage className={classes.icons} />
                            </Button>
                            {!checkEnd && (
                              <Checkbox
                                value="remember"
                                checked={row.check}
                                onChange={(event) =>
                                  handleChange(event.target.checked, row)
                                }
                                color="primary"
                              />
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={dataArray.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "Filas por página" },
                native: false,
              }}
              labelRowsPerPage={"Filas por página"}
              nextIconButtonText={"Siguiente"}
              backIconButtonText={"Atrás"}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} de ${
                  count !== -1 ? count : `más que ${to}`
                }`
              }
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

TransportTable.propTypes = {
  dataArray: PropTypes.array,
};
