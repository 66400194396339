import React, { useState, useEffect }  from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TextField,
  Link,
} from "@material-ui/core";
import {
  Add as AddIcon,
  CloudUpload,
} from '@material-ui/icons'
import api from "utils/API";
import allActions from "actions";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "5em"
  }
});

const SearchEndowments = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    search,
    setSearch,
    searchInactivas,
    setSearchInactivas,
    tabValue,
    setopenModalNewUser,
    handleMultipleUser,
  } = props;

  const [fileMultiUser, setfileMultiuser] = useState(null)

  const handleDownloadBaseFile = () => {

    api.get("api/personal/ficha/descargarbasemasiva/", { responseType: "blob" }).then(response => {
        var blob = new Blob([response.data], { type: response.headers["content-type"] });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "carga_masiva_fichapersonal.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();

      })
      .catch(error => {
        {}

      });
  }


  return (
    <Grid container className={classes.root}>
      <Grid item container xs={12} justify="center">
        <Grid item container xs={4} justify="center">
            {tabValue === 0 ? (
              <TextField
                id="outlined-disabled"
                label="Buscar en activas"
                variant="outlined"
                value={search}
                onChange={e => setSearch(e.target.value)}
                style={{width: "100%"}}
              />
            ) : (
              <TextField
                id="outlined-disabled"
                label="Buscar en inactivas"
                variant="outlined"
                value={searchInactivas}
                onChange={e => setSearchInactivas(e.target.value)}
                style={{width: "100%"}}
              />
            )}
        </Grid>
        <Grid container item xs={3} justify="center" alignContent="center">
          <Grid container item xs={12} justify="center" alignContent="center">
          {fileMultiUser ?
            <Button
              variant='contained'
              color='primary'
              className='px-4'
              onClick={() => handleMultipleUser(fileMultiUser, setfileMultiuser)}
            >
            <CloudUpload className='mr-3' />
              Subir archivo de carga masiva
            </Button> :
            <Button
              variant='contained'
              color='primary'
            >
            <span className="btn-file">
              Adjuntar archivo carga masiva
              <input
                type="file"
                accept="image/xlsx"
                name="archivo"
                onChange={e => setfileMultiuser(e.target.files[0])}
              />
            </span>
            </Button>
          }
          </Grid>
          <Grid container item xs={12} justify="center" alignContent="center">
          <Link href="#" onClick={() => handleDownloadBaseFile()}>
            Descargar archivo base
          </Link>
          </Grid>
        </Grid>
        <Grid container item xs={3} justify="center" alignContent="center">
          <Button variant='contained' color='primary' className='px-4' onClick={setopenModalNewUser} >
            <AddIcon className='mr-3' />
              Crear usuario
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchEndowments;
