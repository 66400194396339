import React, { useState, useEffect, useRef } from "react";
import {useSelector, useDispatch} from 'react-redux';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import ModalEditBenefit from "components/Billing/ModalEditBenefit";
import ModalMoveBenefit from "components/Billing/ModalMoveBenefit";
import ModalAddBenefit from "components/Billing/ModalAddBenefit";
import ModalDeleteBenefit from "components/Billing/ModalDeleteBenefit";
import ModalReverseBenefit from "components/Billing/ModalReverseBenefit";
import TableComponent from "views/Widgets/Common/TableComponent";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import api from "utils/API";
import { red, green, indigo, orange } from "@material-ui/core/colors";
import {toMoneyFormat} from "utils/formatFunctions";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../styles/Styles.css"



const PreBilling = ({
  viewDetail,
  isapre,
  benefitsData,
  patientRow,
  setbenefitsData,
  changeButtons,
  handleUpdateRow,
  handleAddRow,
  handleDeleteRow,
  handleReverse,
  handleMoveRow,
}) => {
  const dispatch = useDispatch();

  const [openEditModal, setopenEditModal] = useState(null);
  const [openMoveModal, setopenMoveModal] = useState(null);
  const [openDeleteModal, setopenDeleteModal] = useState(null);
  const [openReverseModal, setopenReverseModal] = useState(null);
  const [openAddModal, setopenAddModal] = useState(null);
  const [patientBillings, setpatientBillings] = useState(null);

  const handleGenerateDoc = () => {
    api.get(`api/facturacion/facturar/pacientes/${viewDetail.id}/descargar`, { responseType: "blob" }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // Create a link element and click it to trigger download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'pre-factura.xlsx';
      link.click();
    })
  };

  console.log(patientRow)
  console.log(viewDetail)

  useEffect(() => {
    let paramString = `?ficha_paciente=${patientRow.id_ficha_paciente_id}`;
    api.get(`api/facturaciones/${paramString}`).then((response) => {
      setpatientBillings(response.data);
    })
  }, []);


  const headerTable = [
    {label: "Codigo"},
    {label: "Prestación efectuada", style: {minWidth: "200px"}},
    {label: "Nº"},
    {label: "Valor unitario/Día"},
    {label: "Total"},
    {label: "Ocultar prestación"},
    {eval:false, label: "Acción"},
    {eval:false, label: ""},
    {eval:false, label: ""}
  ]

  const data = benefitsData.map((row) => {

    const _editButton_ =  <Button
      style={{ background: orange[500] }}
      className="text-white"
      onClick={() => setopenEditModal(row)}
    >
      Editar
    </Button>

    const _moveButton_ =  <Button
      style={{ background: indigo[500] }}
      className="text-white"
      onClick={() => setopenMoveModal(row)}
      >
        Mover
      </Button>

    const _deleteButton_ =  <Button
      style={{ background: red[500] }}
      className="text-white"
      onClick={() => setopenDeleteModal(row)}
    >
      Eliminar
    </Button>



    return [
      {value: row.codigo_prestacion},
      {value: `${row.prestacion_efectuada} ${row.id_prestacion_facturacion_padre ? `(${row.id_prestacion_facturacion_padre.prestacion_efectuada})`: "" }` },
      {value: row.cantidad_efectuada_modificado},
      {value: `${toMoneyFormat(row.valor_unitario_modificado)}`},
      {value: `${toMoneyFormat(row.total_prestacion_modificado) }`},
      {value: row.ocultar ? "Sí" : "No"},
      {_value_: _editButton_},
      {_value_: _moveButton_},
      {_value_: _deleteButton_},
    ]
  })


  return (

    <Card>
      <CardBody>
        {changeButtons === false && <div></div>}
        <GridItem xs={12}>
          <TableComponent
              headers={headerTable}
              data={data}
          />

        </GridItem>
        <GridItem
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
          style={{margin: "10px 0"}}
        >
          <Button
            mr={2}
            variant="contained"
            className="text-white"
            onClick={() => setopenAddModal(true)}
            style={{margin: "0 5px", background: green[500]}}
          >
            Agregar prestación a la prefactura
          </Button>
          <Button
            mr={2}
            variant="contained"
            className="text-white"
            color="primary"
            onClick={() => handleGenerateDoc()}
            style={{margin: "0 5px"}}
          >
            Generar prefactura
          </Button>
          <Button
            mr={2}
            variant="contained"
            className="text-white"
            color="primary"
            onClick={() => setopenReverseModal(true)}
            style={{margin: "0 5px"}}
          >
            Revertir todos los cambios
          </Button>
        </GridItem>
      </CardBody>
      {openAddModal &&
      <ModalAddBenefit
        open={!!openAddModal}
        viewDetail={viewDetail}
        handleClose={() => setopenAddModal(null)}
        handleAddRow={handleAddRow}
      />
      }
      {openEditModal &&
      <ModalEditBenefit
        open={!!openEditModal}
        handleClose={() => setopenEditModal(null)}
        row={openEditModal}
        handleUpdateRow={handleUpdateRow}
      />
      }
      {openMoveModal &&
      <ModalMoveBenefit
        open={!!openMoveModal}
        handleClose={() => setopenMoveModal(null)}
        row={openMoveModal}
        handleMoveRow={handleMoveRow}
        patientBillings={patientBillings}
      />
      }
      {openDeleteModal &&
      <ModalDeleteBenefit
        open={!!openDeleteModal}
        handleClose={() => setopenDeleteModal(null)}
        row={openDeleteModal}
        handleDeleteRow={handleDeleteRow}
      />
      }
      {openReverseModal &&
      <ModalReverseBenefit
        open={!!openReverseModal}
        handleClose={() => setopenReverseModal(null)}
        handleReverse={handleReverse}
      />
      }
    </Card>

  );
};

export default PreBilling;
