import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    FormControlLabel,
    Radio,
    Checkbox,
} from '@material-ui/core';
import {
    RadioButtonChecked,
    CheckCircle,
    RadioButtonUnchecked,
} from "@material-ui/icons/";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import allActions from "actions";
import {dictMonth} from "utils/validationFunction";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from "moment-timezone";
import "moment/locale/es";

moment.locale("es");

const useStyles = makeStyles(styles);

export default function EditVisit({
    open,
    handleClose,
    editScheduleVisit,
    dictChargeId,
    dictReservation,
    dictPatientFile,
    dictPatient,
    dictPatientFileWorker,
    listPatient,
    dailyHeaderArray,
    handleEditReservation,
    handleRemoveReservation,
}) {
    const dispatch = useDispatch();
    const data = editScheduleVisit[0];
    const classes = useStyles();
    const [initialCharge, setinitialCharge] = useState(true);
    const [patient, setpatient] = useState(listPatient[0])
    const [start, setstart] = useState({});
    const [end, setend] = useState({});
    const [listHour, setlistHour] = useState([]);
    const [worker, setworker] = useState(editScheduleVisit[1]);
    const [selectWorker, setselectWorker] = useState({});
    const [hourType, sethourType] = useState("normal");
    const [changeHour, setchangeHour] = useState(false);
    const [checkPatient, setcheckPatient] = useState(false);
    const [extraLunch, setextraLunch] = useState(false);
    const [shiftMobilization, setshiftMobilization] = useState(false);
    const [specialViatic, setspecialViatic] = useState(false);
    const [extraLunchAmount, setextraLunchAmount] = useState(0);
    const [shiftMobilizationAmount, setshiftMobilizationAmount] = useState(0);
    const [specialViaticAmount, setspecialViaticAmount] = useState(0);
    const [extraTurnValue, setextraTurnValue] = useState(0);
    const [comment, setcomment] = useState(data.comentarios ? data.comentarios : "");
    const [messageWarning, setmessageWarning] = useState("");
    const [alertVetoe, setAlertVetoe] = useState(false);

    useEffect(() => {
        if (initialCharge){
            initialData();
        }
        if (changeHour){
            getTimeList()
        }
        if (checkPatient){
            getWarningMessage();
            initialVetoes();
        }
    });

    const initialVetoes = () => {
        const getVetoes = api.get(`api/rrhh/variablesdinamicas/personal/${worker.id_ficha.id}/tipo/vetos/`
        );

        Promise.all([getVetoes]).then(response => {
            const checkVetoe = response[0].data.valores.filter(
            filtered =>
                filtered.info_veto.id_ficha_paciente_id.id_paciente_id.id ===
                patient.value
            );
            setAlertVetoe(checkVetoe.length > 0);
        })
    };

    const initialData = () => {
        setinitialCharge(false);
        setselectWorker({value: worker.id_ficha.id, label: `(${worker.id_ficha.rut}) ${worker.id_ficha?.nombres} ${worker.id_ficha?.apellido_paterno} - ${dictChargeId[worker.id_ficha?.id_cargo_id]?.nombre_corto}]}`});
        const tempStart = moment(data.fecha_hora).format("HH:mm");
        const tempEnd = moment(data.fecha_final).format("HH:mm");
        const tempUser = dictPatientFile[data.id_ficha] ;
        const tempPatient = listPatient.filter((row) => row.value === tempUser.id );
        setpatient(tempPatient[0]);
        setstart({value: tempStart, label: tempStart});
        setend({value: tempEnd, label: tempEnd});
        api.get(`/api/clinica/asignar/tarea/${data.id}`).then((response) => {
            const extra_values = response.data.valores_extras.reduce((accum, obj) => ({
                ...accum,
                [obj.codigo_tipo_valor]: obj
              }), {});
            sethourType(extra_values["ValorAtencionExtra"] ? "extra" : "normal")
            setextraLunch(extra_values["ColacionExtra"] ? true : false)
            setshiftMobilization(extra_values["MovilizacionExtra"] ? true : false)
            setspecialViatic(extra_values["ViaticoEspecial"] ? true : false)
            setextraLunchAmount(extra_values["ColacionExtra"] ? extra_values["ColacionExtra"].valor : 0)
            setshiftMobilizationAmount(extra_values["MovilizacionExtra"] ? extra_values["MovilizacionExtra"].valor : 0)
            setspecialViaticAmount(extra_values["ViaticoEspecial"] ? extra_values["ViaticoEspecial"].valor : 0)
            setextraTurnValue(extra_values["ValorAtencionExtra"] ? extra_values["ValorAtencionExtra"].valor : 0);
            setchangeHour(true);
            setcheckPatient(true);
        }).catch((error) => {
            setchangeHour(true);
            setcheckPatient(true);
        });

    };

    const getWarningMessage = () => {
        const tempPatientFileId = dictPatient[patient?.value];
        const tempPatientWork = dictPatientFileWorker[tempPatientFileId];
        const dateEvent = moment(data.fecha_hora).format("YYYY-MM-DD");
        const tempStartDate = moment(`${dateEvent} ${start.value}:00`);
        const tempEndDate = moment(`${dateEvent} ${end.value}:00`);
        const scheduleArray = [];
        if(tempPatientWork){
            tempPatientWork.map((row) => {
                if (moment(row.fecha_hora).format("YYYY-MM-DD") === dateEvent && data.id !== row.id){
                    if (
                        (moment(tempStartDate).diff(row.fecha_hora, "minutes") >= 0 &&
                        moment(row.fecha_final).diff(tempStartDate, "minutes") >= 0 ) ||
                        (moment(tempEndDate).diff(row.fecha_hora, "minutes") >= 0 &&
                        moment(row.fecha_final).diff(tempEndDate, "minutes") >= 0 )
                    ){
                        scheduleArray.push(`${moment(row.fecha_hora).format("HH:mm")} - ${moment(row.fecha_final).format("HH:mm")}`);
                    }
                }
            });
        }
        if (scheduleArray.length > 0 ){
            const tempScheduleString = scheduleArray.join(", ");
            setmessageWarning(`El paciente tiene una consulta agendada en el horario: ${tempScheduleString}`)
        } else {
            setmessageWarning("");
        }
    };

    const getTimeList = () => {
        setchangeHour(false);
        const tempListHours = [];
        let tempDate =  moment(data.fecha_hora).format("YYYY-MM-DD");
        const tempStartVisit =  moment(data.fecha_hora).format("YYYY-MM-DD HH:mm:ss");
        let countDate = moment(tempDate);
        let tempDateEnd = moment(tempDate).add(24, "hours")

        let startDateLimit = moment(countDate);
        let endDateLimit = moment(tempDateEnd);

        Object.keys(dictReservation).map((key) => {
            if (dictReservation[key][worker.id] && key !== tempStartVisit){
                const tempValue = dictReservation[key][worker.id];
                let tempStart = null;
                let tempEnd = null;

                if (moment(data.fecha_hora).diff(tempValue.fecha_final, "minutes") >= 0 ){
                    tempStart = moment(tempValue.fecha_final);
                } else if (moment(data.fecha_hora).diff(tempValue.fecha_hora, "minutes") >= 0 ){
                    tempStart = moment(tempValue.fecha_hora);
                }
                if (tempStart){
                    if (moment(tempStart).diff(startDateLimit, "minutes") >= 0 ){
                        startDateLimit = moment(tempStart);
                    }
                }

                if (moment(data.fecha_final).diff(tempValue.fecha_hora, "minutes") <= 0 ){
                    tempEnd = moment(tempValue.fecha_hora);
                } else if (moment(data.fecha_final).diff(tempValue.fecha_final, "minutes") <= 0 ){
                    tempEnd = moment(tempValue.fecha_final);
                }
                if (tempEnd){
                    if (moment(tempEnd).diff(endDateLimit, "minutes") <= 0 ){
                        endDateLimit = moment(tempEnd);
                    }
                }
            }
        });
        while (moment(tempDateEnd).diff(countDate, "minutes") >= 0){
            const tempHour = moment(countDate).format("HH:mm");
            tempListHours.push({value: tempHour, label: tempHour});
            countDate = moment(countDate).add(30, "minutes");
        }
        setlistHour(tempListHours);
    };

    const handleSelectHour = (value, argDate, set) => {
        const tempDate =  moment(data.fecha_hora).format("YYYY-MM-DD");
        let auxDate = moment(`${tempDate} ${value.value}:0`);
        let compareDate = null;
        if (argDate === "start"){
            compareDate = moment(`${tempDate} ${end.value}:0`);
            if (moment(compareDate).diff(auxDate, "minutes") < 0 ){
                let auxEndTime = moment(auxDate).add(30, "minutes");
                auxEndTime = moment(auxEndTime).format("HH:mm");
                setend({value:auxEndTime, label: auxEndTime});
            }
        } else {
            compareDate = moment(`${tempDate} ${start.value}:0`);
            if (moment(auxDate).diff(compareDate, "minutes") < 0){
                let auxStartTime = moment(auxDate).subtract(30, "minutes");
                auxStartTime = moment(auxStartTime).format("HH:mm");
                setstart({value:auxStartTime, label: auxStartTime});
            }
        }
        const valueFormat = moment(auxDate).format("HH:mm")
        set({value:valueFormat, label: valueFormat});
        setcheckPatient(true);
    };

    const handlePatient = (value) => {
        setpatient(value);
        setcheckPatient(true);
    };

    const handleWorker = (value) => {
        const tempWorwer = value.item
        setworker(tempWorwer);
        setselectWorker(value);
        setcheckPatient(true);
    }

    const handleHourType = event => {
        sethourType(event.target.value);
        setextraTurnValue(0);
    };

    const handleAddItems = (value, set, setvalue) => {
        set(!value);
        setvalue(0);
    };

    const handleOnChange = (value, set) => {
        let tempValue =  isNaN(value) ? 0 : value;
        set(tempValue);
    };

    const tempWorkerArray = dailyHeaderArray.reduce((accum, obj) => [
        ...accum,
        {
            value: obj.id,
            label: `(${obj.id_ficha.rut}) ${obj.id_ficha?.nombres} ${obj.id_ficha?.apellido_paterno} - ${dictChargeId[obj.id_ficha?.id_cargo_id]?.nombre_corto}]}`,
            item: {...obj}
        }
    ], [])

    const _content_ = <div >
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <DetailSelect
                    label="Profesional:"
                    value={selectWorker}
                    onchange={(value) => handleWorker(value)}
                    list={tempWorkerArray}
                />
                <DetailSelect
                    label="Paciente:"
                    value={patient}
                    onchange={(value) => handlePatient(value)}
                    list={listPatient}
                />
                <DetailSelect
                    label="Horario inicio:"
                    value={start}
                    onchange={(value) => handleSelectHour(value, "start", setstart)}
                    list={listHour.slice(0, listHour.length - 1)}
                />
                <DetailSelect
                    label="Horario fin:"
                    value={end}
                    onchange={(value) => handleSelectHour(value, "end", setend)}
                    list={listHour.slice(1, listHour.length)}
                />
                {alertVetoe && (
                <SnackbarContent
                    message={"No se puede guardar este registro, existe un veto para este profesional por parte de este paciente."}
                    color="danger"
                />
                )}
                {messageWarning != "" &&
                <SnackbarContent
                    message={messageWarning}
                    color="danger"
                />
                }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <FormControlLabel
                    control={
                        <Radio
                            checked={hourType === "normal"}
                            onChange={handleHourType}
                            value="normal"
                            name="radio button enabled"
                            aria-label="normal"
                            icon={
                                <RadioButtonUnchecked style={{fill: indigo[500]}} />
                            }
                            checkedIcon={
                                <RadioButtonChecked style={{fill: indigo[500]}}/>
                            }
                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label="Considerar como atención normal"
                />
                <FormControlLabel
                    control={
                        <Radio
                            checked={hourType === "extra"}
                            onChange={handleHourType}
                            value="extra"
                            name="radio button enabled"
                            aria-label="extra"
                            icon={
                                <RadioButtonUnchecked style={{fill: indigo[500]}} />
                            }
                            checkedIcon={
                                <RadioButtonChecked style={{fill: indigo[500]}} />
                            }

                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label="Considerar como atención extra"
                />
                { hourType === "extra" &&
                    <DetailNumber
                        label={`Valor atención extra`}
                        value={extraTurnValue}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setextraTurnValue)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Typography variant="body2" gutterBottom>
                {`¿Cuál de los siguientes items desea agregar?`}
                </Typography>

            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checked={extraLunch}
                            onClick={() => handleAddItems(extraLunch, setextraLunch, setextraLunchAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Colación atención extra"
                />
                { extraLunch &&
                    <DetailNumber
                        label={`Valor colación atención extra`}
                        value={extraLunchAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setextraLunchAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checked={shiftMobilization}
                            onClick={() => handleAddItems(shiftMobilization, setshiftMobilization, setshiftMobilizationAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Movilizacíon atención extra"
                />
                { shiftMobilization &&
                    <DetailNumber
                        label={`Valor movilizacíon atención extra`}
                        value={shiftMobilizationAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setshiftMobilizationAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checked={specialViatic}
                            onClick={() => handleAddItems(specialViatic, setspecialViatic, setspecialViaticAmount)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label="Viatico especial"
                />
                { specialViatic &&
                    <DetailNumber
                        label={`Valor viatico especial`}
                        value={specialViaticAmount}
                        onchange={e => handleOnChange(
                            parseInt(e.target.value.split('$').join('').split(',').join('')),
                            setspecialViaticAmount)
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                    />
                }
            </Grid>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                xs={10}
                style={{margin: "10px 0 10x 0"}}
            >
                <DetailText
                    label={`Comentario`}
                    placeholder={``}
                    value={comment}
                    validation={false}
                    onchange={(e) => { handleOnChange(e.target.value, setcomment)}}
                />
            </Grid>
        </Grid>
    </div>

    const _actions_ = <>
        {(alertVetoe || !selectWorker.value) ?
        <Button
            disabled={alertVetoe}
            mr={2}
            variant="contained"
            className="text-white"
        >
            Guardar
        </Button>:
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: green[500]}}
            onClick={() => handleEditReservation(
                patient,
                start,
                end,
                extraLunchAmount,
                shiftMobilizationAmount,
                specialViaticAmount,
                extraTurnValue,
                comment,
                hourType,
                worker,
            )}
        >
            Guardar
        </Button>
        }
         {alertVetoe ?
        <Button
            disabled={alertVetoe}
            mr={2}
            variant="contained"
            className="text-white"
        >
            Eliminar
        </Button>:
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: red[500]}}
            onClick={handleRemoveReservation}
        >
            Eliminar
        </Button>
        }

        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: indigo[500], marginRight: "10px"}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Editar visita - ${moment(data.fecha_hora).format("D")} de ${dictMonth[parseInt(moment(data.fecha_hora).format("MM"))]}`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xs"
        />
    );
}