import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const DialogSuccess = props => {
  const {
    openDialog,
    closeDialog,
    getPatientInfo,
    tempPatientIdInfo,
    closeModal
  } = props;

  const closeAll = () => {
    getPatientInfo(tempPatientIdInfo);
    closeModal();
    closeDialog();
  };

  return (
    <Dialog
      onClose={closeDialog}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
    >
      <DialogContent>
        <Typography gutterBottom>
          Pedido actualizado
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button variant="contained" color="primary" onClick={closeAll}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSuccess;

DialogSuccess.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  getPatientInfo: PropTypes.func,
  tempPatientIdInfo: PropTypes.number,
  closeModal: PropTypes.func
};
