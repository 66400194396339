import React from "react";
import Select from "react-select";

const DetailSelect = props => {

  const filterOptions = (candidate,input) => {
    if (input) {
      const tempInput = input.toUpperCase()
      const tempLabel = candidate.label.toUpperCase()
      return  tempInput.split(' ').every(char => tempLabel.includes(char));
    }
    return true;
  };


  return(
    <div className={`col-md-12 mb-3`}  style={{...props.style ? props.style : {}}}>
      <label className="text-dark">
        {props?.label}
        {(props?.value?.value === undefined && props.validation !== false) ? (
          <span className="text-danger"> no puede ser vacío</span>
        ) : (props?.error && props.validation !== false) ?
          <span className="text-danger"> {props?.error} </span> : null}
      </label>
      {(props.formatGroupLabel && props.colourStyles)?
      <Select
        isMulti={props.isMulti || false}
        isDisabled={props.isDisabled}
        value={props.value}
        onChange={ props.onchange }
        options={props.list}
        placeholder="Seleccione"
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          ...props.colourStyles,
        }}
        formatGroupLabel={props.formatGroupLabel}
        menuPlacement={props.menuPlacement || "auto"}
        menuPortalTarget={document.body}
        filterOption={filterOptions}
      />
      :
      <Select
        isMulti={props.isMulti || false}
        isDisabled={props.isDisabled}
        value={props.value}
        onChange={ props.onchange }
        options={props.list}
        placeholder="Seleccione"
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
        menuPlacement={props.menuPlacement || "auto"}
        menuPortalTarget={document.body}
        filterOption={filterOptions}
      />
      }
    </div>
  );
}

export default DetailSelect;