import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import apiform from "utils/APIForm";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailNumber from "views/Widgets/FichaPersonal/EditFichaPersonal/detailNumber";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import ModalDialog from "views/Widgets/Common/ModalDialog";


export default function ModalEditBenefits(props) {
  const { open, closeModal, id_benefit, id_paquete,
    setFirstCharge,
  } = props;
  const [grupo, setGrupo] = useState({});
  const nombre = props.nombreBenefit;
  const [frecuencia, setFrecuencia] = useState(props.frecuenciaBenefit);
  const [tipo_frecuencia, setTipo_frecuencia] = useState({});
  const [cantidad, setCantidad] = useState(props.cantidadBenefit);
  const [tipo_cantidad, setTipo_cantidad] = useState({});
  const valor = "";
  const [initialCharge, setinitialCharge] = useState(true);

  let list_valida = [
    { value: false, label: "Inactiva" },
    { value: true, label: "Activa" },
  ];

  const [estado, setEstado] = useState(
    props.estadoBenefit ?
    { value: true, label: "Activa" } :
    { value: false, label: "Inactiva" }
  );

  const [textoLibre, setTextoLibre] = useState([]);

  const list_frecuencias = useSelector(state => state.currentList.LISTA_TIPO_FRECUENCIA_PRESTACION);
  const list_grupos = useSelector(state => state.currentList.LISTA_GRUPOS_PRESTACION);
  const list_cantidades = useSelector(state => state.currentList.LISTA_TIPO_CANTIDAD_PRESTACION);
  const dictTypefrequencyPrestation = useSelector(state => state.currentList.DICCIONARIO_TIPO_FRECUENCIA_PRESTACION);
  const dictGroupPrestation = useSelector(state => state.currentList.DICCIONARIO_GRUPOS_PRESTACION);
  const dictTypeAmountBenefit = useSelector(state => state.currentList.DICCIONARIO_TIPO_CANTIDAD_PRESTACION);

  useEffect(() => {
    if(initialCharge && list_frecuencias.length > 0 && list_grupos.length > 0 && list_cantidades.length > 0){
      setinitialCharge(false)
      setTipo_frecuencia(dictTypefrequencyPrestation[props.tipoFrecuenciaBenefit] || {})
      setGrupo(dictGroupPrestation[props.grupoBenefit] || {})
      setTipo_cantidad(dictTypeAmountBenefit[props.tipoCantidadBenefit] || {})
    }
  });

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function updateBenefit() {
    const formData = new FormData();

    if (grupo) {
      formData.append("grupo", grupo.value);
    }
    if (tipo_frecuencia) {
      formData.append("tipo_frecuencia", tipo_frecuencia.value);
    }

    if (tipo_frecuencia.value === "Permanente") {
      formData.append("frecuencia", 0);
    } else {
      formData.append("frecuencia", frecuencia);
    }
    if (tipo_cantidad) {
      formData.append("tipo_cantidad", tipo_cantidad.value);
    }
    if (estado) {
      formData.append("estado", estado.value);
    }
    formData.append("nombre", nombre);

    formData.append("cantidad", cantidad);
    formData.append("valor", valor);
    formData.append("id_paquete", id_paquete);
    apiform
      .put(`api/prestacionespaquete/${id_benefit}`, formData)
      .then((response) => {
        setFirstCharge(true);
        closeModal();
      })
  }

  const _content_ = <div className="form-row">
      <DetailTextDisabled label="Grupo:" value={grupo?.label} />

      <DetailTextDisabled label="Prestación:" value={nombre} />
      <DetailTextDisabled label="Tipo cantidad:" value={tipo_cantidad?.label}/>

      <DetailNumber
        label="Cantidad:"
        value={cantidad}
        onchange={(e) => {
          if (/^[0-9\b]+$/.test(e.target.value)) {
            setCantidad(e.target.value);
          }
        }}
      />
      {tipo_frecuencia.value !== "Permanente" ? (
        <DetailNumber
          label="Cada:"
          value={frecuencia}
          onchange={(e) => {
            if (/^[0-9\b]+$/.test(e.target.value)) {
              setFrecuencia(e.target.value);
            }
          }}
        />
      ) : null}
      <DetailSelect
        label="Tiempo:"
        value={tipo_frecuencia}
        onchange={(event) =>  setTipo_frecuencia(event)}
        list={list_frecuencias}
      />
      <DetailSelect
        label="Estado:"
        value={estado}
        onchange={(event) => setEstado(event)}
        list={list_valida}
      />

      {grupo.value === "Otros" ? (
        <DetailTextArea
          label="Texto libre:"
          value={textoLibre}
          onchange={(e) => setTextoLibre(e.target.value)}
        />
      ) : null}
    </div>

  const _actions_ = <>
    <Button
      onClick={closeModal}
      color="primary"
      style={{ textTransform: "none" }}
    >
      Cancelar
    </Button>
    <Button
      onClick={updateBenefit}
      color="primary"
      style={{ textTransform: "none" }}
    >
      Guardar
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={" Editar prestación de paquete"}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}

ModalEditBenefits.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_therapy: PropTypes.number,
  id_paquetizacion: PropTypes.number,
};
