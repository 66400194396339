import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import apiform from "utils/APIForm";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export default function ModalAceptRequest(props) {
  const classes = useStyles();

  const { open, closeModal, moduleType } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  function updateSkuRequest() {

    const formData = new FormData();

    formData.append("grupo", props.group);
    formData.append("descripcion", props.description);
    formData.append("fecha_solicitud", props.dateRequest);
    formData.append("sku_sugerido", props.suggestedSku);
    formData.append("sku_final", props.skuToCreate);

    apiform
      .put(`api/solicitudsku/${props.id}`, formData)
      .then(response => {
        window.location.href = `/admin/income/divisions/${moduleType}`;
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Crear código</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div style={{ marginBottom: "10rem" }}>
          <p className="text-center font-weight-bold">
            ¿Está seguro que desea crear el código?
          </p>
          <p className="text-center">
            Los productos se crearán y se le notificará al jefe y subjefe de
            operaciones de la creación.
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateSkuRequest} color="primary">
          Si
        </Button>
        <Button onClick={closeModal} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalAceptRequest.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func
};
