import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import NavPills from "components/NavPills/NavPills.js";
// react plugin for creating vector maps
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  pieChart
} from "variables/charts.js";

import Timeline from "@material-ui/icons/Timeline";
import SvgIcon from "@material-ui/core/SvgIcon";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import api from "utils/API";
import LoadingSpinnerInfo from "../Widgets/FichaPersonal/loadingSpinnerInfo";
// @material-ui/icons
import {} from "variables/charts";
import DialogAttendance from "components/Dashboard/DialogAttendance";
import WelcomeDialogAttendance from "components/Dashboard/WelcomeDialogAttendance";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { useCookies } from 'react-cookie';
import { getAsistencia } from "actions/getGlobalAction";
import allActions from "actions";
const useStyles = makeStyles(styles);

const url_attendance = "/attendance/login";
function OutIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="25" viewBox="2 2 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5874 8.77701L22.5904 12.7796L18.5874 16.7826" stroke="white" strokeWidth="2"/>
        <path d="M8.97253 12.7798L22.5904 12.7798" stroke="white" strokeWidth="2"/>
        <path d="M15.945 15.7684L15.945 17.7643C15.945 18.8689 15.0496 19.7643 13.945 19.7643L4 19.7643C2.89543 19.7643 2 18.8689 2 17.7643L2 7.76494C2 6.66037 2.89543 5.76494 4 5.76494L13.945 5.76494C15.0496 5.76494 15.945 6.66037 15.945 7.76494L15.945 9.78805" stroke="white" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
}
function InIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="25" viewBox="1 3 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6149 8.2478L14.6179 12.2504L10.6149 16.2534" stroke="white" strokeWidth="2"/>
        <path d="M5.64856 9.23164V7.23572C5.64856 6.13115 6.54399 5.23572 7.64856 5.23572H17.5936C18.6981 5.23572 19.5936 6.13115 19.5936 7.23572V17.2351C19.5936 18.3396 18.6981 19.2351 17.5936 19.2351H7.64856C6.54399 19.2351 5.64856 18.3396 5.64856 17.2351V15.2119" stroke="white" strokeWidth="2"/>
        <path d="M1 12.2506L14.6179 12.2506" stroke="white" strokeWidth="2"/>
      </svg>
    </SvgIcon>
  );
}
export default function Dashboard() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [initialCharge, setinitialCharge] = useState(true);
  const listCharges = useSelector(state => state.currentList.listCharges);
  const dialogTitleAttendance = useSelector(state => state.currentGlobalVar.TITULO_DIALOGO_DASHBOARD);
  const dialogMessageAttendance = useSelector(state => state.currentGlobalVar.MENSAJE_DIALOGO_DASHBOARD);
  const openDialogAttendance = useSelector(state => state.currentGlobalVar.ABRIR_DIALOGO_DASHBOARD);
  const openWelcomeDialogAttendance = useSelector(state => state.currentGlobalVar.ABRIR_DIALOGO_BIENVENIDA_DASHBOARD);
  const disableAttendance = useSelector(state => state.currentGlobalVar.DESHABILITAR_ASISTENCIA_ENTRADA);
  const disableAttendanceExit = useSelector(state => state.currentGlobalVar.DESHABILITAR_ASISTENCIA_SALIDA);
  const attendanceType = useSelector(state => state.currentGlobalVar.ASISTENCIA_TIPO);
  const attendanceUrl = useSelector(state => state.currentGlobalVar.ASISTENCIA_URL);

  const urlRedirect = "https://www.siclin.cl/admin/dashboardRedirect/"
  const clientId = "4142a5dd-f07e-48a7-bfb6-d52361f3fae1";
  const urlTemp = `https://login.microsoftonline.com/common/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${urlRedirect}&response_mode=query&scope=openid&state=12345`


  useEffect(() => {
    getAsistencia(dispatch);
  }, []);

  const handlePowerBi = () => {
    window.open(urlTemp)
  }

  useEffect(() => {
    if (initialCharge) {
      initialData();
    }
  });

  const handleClickExitAttendance = () => {
    dispatch(allActions.setGlobalVar.setOpenDialogDashboard(true));
  };

  const initialData = () => {
    setinitialCharge(false);
  };

  const generateAsistencia = () => {
    setLoading(false);
    api
      .get("api/asistencia/?diario=True", { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "asistencia.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
        setLoading(true);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const handleCloseDialog = () => {
    dispatch(allActions.setGlobalVar.setOpenDialogDashboard(false));
  };

  const handleCloseWelcomeDialog = () => {
    dispatch(allActions.setGlobalVar.setOpenWelcomeDialogDashboard(false));
  };

  return (
    <div>
      {/* Este componente renderiza el primer pop up de alerta, el cual indica al usario que debe registrar su entrada, se despliega solo en el dashboard
        y solo cuando no ha registrado su entrada*/}
      <WelcomeDialogAttendance
        message={dialogMessageAttendance}
        openDialog={openWelcomeDialogAttendance}
        title={dialogTitleAttendance}
        handleCloseDialog={handleCloseWelcomeDialog}
        url={url_attendance}
        attendance={"login"}
      />
      {/* Este componente renderiza las opciones y los mensajes dependiendo si tiene registrada una entrada, dentro de el se encuentran los flujos de registro de salida.
        Tener considerado que este componente se gatilla al hacer click al boton de registrar salida, ubicado en el dashboard, tendra un comportamiento similar, al hacer click en el boton de
        salida del navbar, desplegando otros mensajes pero mismo flujo*/}
      <DialogAttendance
        message={dialogMessageAttendance}
        openDialog={openDialogAttendance}
        title={dialogTitleAttendance}
        handleCloseDialog={handleCloseDialog}
        url={attendanceUrl}
        attendance={attendanceType}
      />
      <GridContainer justifyContent="center" alignItems="center">
        {localStorage.getItem("area_perfil") === "Recursos Humanos" && (
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>content_copy</Icon>
                </CardIcon>
                {/* <p className={classes.cardCategory}>Asistencia</p> */}
                <br />
                {
                  loading ?
                  <button onClick={generateAsistencia} className="btn btn-cmh-color">Descargar asistencia</button>
                  :
                  <button className="btn btn-cmh-color">
                    <LoadingSpinnerInfo />
                  </button>
                }
              </CardHeader>
              <CardFooter stats>
                <div>Descarga aquí la asistencia</div>
              </CardFooter>
            </Card>
          </GridItem>
        )}
        {/* Dependiendo de la primera llamada al back obteniendo el ultimo registro de asistencia del usuario, se renderizara de forma condicional el boton de registrar entrada o salida
          las variables encargadas de estas condiciones (disableAttendance, disableAttendanceExit) de renderizacion se asignan al llamar la funcion getAsistencia en el archivo getGlobalAction de redux */}
        {!disableAttendance && (
          <GridItem xs={12} sm={6} md={3}>
            <Button
              color="secondary"
              size="small"
              aria-label="Notifications"
              aria-haspopup="true"
              href={"/attendance/login"}
              startIcon={<InIcon />}
              style={{
                backgroundColor: "#4CAF50",
                textTransform: "None",
                fontFamily: "Roboto",
                fontStyle: "Normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "12px",
                textAlign: "left",
                letterSpacing: "0.15000000596046448px",
                width: "230px"
              }}
            >
              Registrar entrada
            </Button>
          </GridItem>
        )}
        {!disableAttendanceExit && (
          <GridItem xs={12} sm={6} md={3}>
            <Button
              color="secondary"
              size="small"
              aria-label="Notifications"
              aria-haspopup="true"
              startIcon={<OutIcon />}
              style={{
                backgroundColor: "#F44336",
                textTransform: "None",
                fontFamily: "Roboto",
                fontStyle: "Normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "12px",
                textAlign: "left",
                letterSpacing: "0.15000000596046448px",
                width: "230px"
              }}
              onClick={handleClickExitAttendance}
            >
              Registrar salida
            </Button>
          </GridItem>
        )}
        {(localStorage.getItem("area_perfil") === "Gerencia" ||
          [ "ADMFINJefFactCobrs", "OPJefOpe","OPJefOpe","OPQFJefLoc", "OPJefBod", "CLIJefCol",
            "CliJefEnfCliCentClin", "CLIJefGesCli", "CLIJefRehab","OPGerOpe", "GERJefDptoSSOMA",
            "OPJefInfo", "ADMFINJefRRHH", "ADMFINJefRecSel", "ADMFINJefCon"
          ].includes(localStorage.getItem("tipo_perfil"))) &&
          <GridItem container direction="row" justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
            <Button
              size="small"
              aria-label="Notifications"
              aria-haspopup="true"
              onClick={handlePowerBi}
              style={{
                backgroundColor: "#4CAF50",
                textTransform: "None",
                fontStyle: "Normal",
                fontWeight: "400",
                letterSpacing: "0.15000000596046448px",
                fontSize: "inherit",
                lineHeight: "inherit",
                fontFamily: "inherit",
                backgroundColor:" #f2c811",
                borderColor: "#f2c811",
                color: "#000",
              }}
            >
              Login reporte powerBI
            </Button>
          </GridItem>
        }
      </GridContainer>

      {(localStorage.getItem("area_perfil") === "Gerencia" ||
          [ "ADMFINJefFactCobrs", "OPJefOpe","OPJefOpe","OPQFJefLoc", "OPJefBod", "CLIJefCol",
            "CliJefEnfCliCentClin", "CLIJefGesCli", "CLIJefRehab","OPGerOpe", "GERJefDptoSSOMA",
            "OPJefInfo", "ADMFINJefRRHH", "ADMFINJefRecSel", "ADMFINJefCon"
          ].includes(localStorage.getItem("tipo_perfil"))) &&
        <iframe style={{width:"100%", height:"500px"}} src="https://app.powerbi.com/reportEmbed?reportId=61788384-3e51-4865-91f8-7517c47ab350&autoAuth=true&ctid=73fb4ded-5fa9-4a4a-ba87-539f4d92ae94&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" frameborder="0" allowFullScreen="true" />
      }
    </div>
  );
}
