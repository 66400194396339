import React, {useEffect, useState} from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff"
  }
}))(LinearProgress);

const OrdersInformation = ({itemWait, itemToDay, itemWeek, total}) => {
  const percentItemWait = parseInt(itemWait/total)*100
  const percentItemToDay =parseInt (itemToDay/total)*100
  const percentItemWeek = parseInt(itemWeek/total)*100
  return (
    <div className="col-md-5 mb-3">
      <div className="card">
        <div className="card-header text-center">Estados pedidos</div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm text-center">
              <h1>{itemWait}</h1>
              <div className="col-sm text-center">Pendientes</div>
              <div>
                <BorderLinearProgress variant="determinate" value={percentItemWait} />
              </div>
            </div>
            <div className="col-sm text-center">
              <h1>{itemToDay}</h1>
              <div className="col-sm text-center">A realizar hoy</div>
              <div>
                <BorderLinearProgress variant="determinate" value={percentItemToDay} />
              </div>
            </div>
            <div className="col-sm text-center">
              <h1>{itemWeek}</h1>
              <div className="col-sm text-center">A realizar esta semana</div>
              <div>
                <BorderLinearProgress variant="determinate" value={percentItemWeek} />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default OrdersInformation;