import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import RequestTable from "./Request/RequestTable";
import BoxTable from "./BoxTable/BoxTable";
import Button from "@material-ui/core/Button";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  };
}

const TimeLine = props => {
  const {
    getProducts,
    order,
    setModalCancel,
    setModalEdit,
    setModalInsumo,
    handlePicking,
    timeLine,
    checkArray,
    getProductsComplete,
    getProductsOther,
    actualGroup,
    setTabValue,
    setModalCancelRequest,
    show_Save,
    observation,
    setObservation,
    file,
    setFile,
    saveFile,
    boxNumber
  } = props;

  //const [value, setValue] = React.useState(0);
  const [value, setValue] = React.useState(boxNumber);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab
              style={{ textTransform: "none" }}
              label="Solicitud"
              {...a11yProps(0)}
            />
            {timeLine.map((tab, index) => (
              <Tab
                style={{ textTransform: "none" }}
                key={index}
                label={`Envío ${tab.numero_pedido}`}
              />
            ))}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <RequestTable
            getProducts={getProducts}
            order={order}
            setModalCancel={setModalCancel}
            setModalEdit={setModalEdit}
            setModalInsumo={setModalInsumo}
            handlePicking={handlePicking}
            checkArray={checkArray}
            getProductsComplete={getProductsComplete}
            getProductsOther={getProductsOther}
            actualGroup={actualGroup}
          />
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ marginTop: "1em" }}
          >
            {order[`estado_solicitud_${actualGroup}`] === "Hacer picking" ||
            order[`estado_solicitud_${actualGroup}`] === "Continuar picking" ? (

              <Grid
                item
                container
                xs={12}
                justify="center"
                style={{ marginTop: "1em" }}
              >
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <h6>Adjuntar guía de despacho</h6>
                </Grid>
                <Grid container xs={12} style={{ textAlign: "center" }}>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <DetailTextArea
                      label="Observaciones:"
                      placeholder="Observaciones"
                      value={observation}
                      maxlength="500"
                      onchange={e => {
                        setObservation(e.target.value);
                      }}
                    />
                    {observation === "" ? (
                      <span className="text-danger">
                        Debes ingresar una observación 
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
                <Grid item container xs={12} justify="center">
                  <div className="col-md-4 mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="card-title">ARCHIVO:</span>
                        <p className="card-text">
                          Archivo: {file ? file.name : null}
                        </p>
                        <span className="btn btn-cmh-color btn-file">
                          Seleccionar
                          <input
                            type="file"
                            name={file}
                            onChange={e => {
                              setFile(e.target.files[0]);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    {file === null ? (
                      <span className="text-danger">
                        Debes ingresar un archivo
                      </span>
                    ) : null}
                  </div>
                </Grid>
              </Grid>  
            ) : null}
            
            {(order[`estado_solicitud_${actualGroup}`] ? (order[`estado_solicitud_${actualGroup}`] === "Finalizado") : (order.estado_solicitud === "Finalizado")
            ) ? null : 
            (order[`estado_solicitud_${actualGroup}`] ? (order[`estado_solicitud_${actualGroup}`] === "Esperar a egresos") : (order.estado_solicitud === "Esperar a egresos")
            ) ? null : (show_Save && order.estado_solicitud !== "Esperar aprobación de reemplazo área clínica") ? (
                  <Grid item container xs={12} justify="center">
                  {(observation !== "" && file != null) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        saveFile(
                          // tab.numero_pedido,
                          // tab.id_solicitud_pedido,
                          // tab.id
                        )
                      }}
                      className="m-1"
                    >
                      Guardar y enviar
                    </Button>
                  ) : (
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      className="m-1"
                    >
                      Guardar
                    </Button>
                  )}
                </Grid>
            ) : null}
          </Grid>
        </TabPanel>
        {timeLine.map((tab, index) => (
          <TabPanel key={index} value={value} index={index + 1}>
            <BoxTable tableBox={tab.productos} envioInfo={tab} order={order} />
            {tab.estado_bodega === "Recien Ingresado" ? (
              <Grid
                item
                container
                xs={12}
                justify="center"
                style={{ marginTop: "1em" }}
              >
                <Button
                  style={{ textTransform: "none" }}
                  variant="contained"
                  color="primary"
                  onClick={() => setModalCancelRequest(true)}
                  className="m-1"
                >
                  Cancelar envío
                </Button>
              </Grid>
            ) : tab.estado_bodega === "Adjuntar guía" ? (
              <Grid
                item
                container
                xs={12}
                justify="center"
                style={{ marginTop: "1em" }}
              >
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <h6>Adjuntar guía de despacho</h6>
                </Grid>
                <Grid container xs={12} style={{ textAlign: "center" }}>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <DetailTextArea
                      label="Observaciones:"
                      placeholder="Observaciones"
                      value={observation}
                      maxlength="500"
                      onchange={e => {
                        setObservation(e.target.value);
                      }}
                    />
                    {observation === "" ? (
                      <span className="text-danger">
                        Debes ingresar una observación 
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
                <Grid item container xs={12} justify="center">
                  <div className="col-md-4 mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="card-title">ARCHIVO:</span>
                        <p className="card-text">
                          Archivo: {file ? file.name : null}
                        </p>
                        <span className="btn btn-cmh-color btn-file">
                          Seleccionar
                          <input
                            type="file"
                            name={file}
                            onChange={e => {
                              setFile(e.target.files[0]);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    {file === null ? (
                      <span className="text-danger">
                        Debes ingresar un archivo
                      </span>
                    ) : null}
                  </div>
                </Grid>
                <Grid item container xs={12} justify="center">
                  {observation !== "" && file != null ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        saveFile(
                          tab.numero_pedido,
                          tab.id_solicitud_pedido,
                          tab.id
                        )
                      }}
                      className="m-1"
                    >
                      Guardar
                    </Button>
                  ) : (
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      className="m-1"
                    >
                      Guardar
                    </Button>
                  )}
                </Grid>
              </Grid>
            ) : null}
          </TabPanel>
        ))}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default TimeLine;

TimeLine.propTypes = {
  getProducts: PropTypes.array,
  order: PropTypes.array,
  setModalCancel: PropTypes.func,
  setModalEdit: PropTypes.func,
  setModalInsumo: PropTypes.func,
  handlePicking: PropTypes.func,
  timeLine: PropTypes.array,
  checkArray: PropTypes.array,
  getProductsComplete: PropTypes.array,
  getProductsOther: PropTypes.array,
  actualGroup: PropTypes.string,
  setTabValue: PropTypes.func,
  setModalCancelRequest: PropTypes.func,
  show_Save: PropTypes.bool,
  saveAndSend: PropTypes.func,
  observation: PropTypes.string,
  setObservation: PropTypes.func,
  file: PropTypes.object,
  setFile: PropTypes.func,
  saveFile: PropTypes.func,
  boxNumber: PropTypes.number
};
