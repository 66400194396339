import React from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {TypographyMui as Typography} from "utils/componentsStyle";
import {dictMonth} from "utils/validationFunction";
import {toMoneyFormat} from "utils/formatFunctions";
import moment from "moment-timezone";

export class ProfessionalRemuneration extends React.Component {
    render() {
        const {personalRow, dataArray, monthDate} = this.props;
        return (
            <div style={{padding: "20px"}}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    item xs={12}
                    style={{padding: "20px 0 0 20px", margin: "30px 0 0 0"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item xs={12}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            item xs={4}
                        >
                            <Typography variant="h6" gutterBottom>
                            <b>Nombre:</b>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            item xs={8}
                        >
                            <Typography variant="h6" gutterBottom>
                            {`${personalRow.nombres} ${personalRow.apellido_paterno} ${personalRow.apellido_materno}`}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            item xs={4}
                        >
                            <Typography variant="h6" gutterBottom>
                            <b>RUT:</b>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            item xs={8}
                        >
                            <Typography variant="h6" gutterBottom>
                            {`${personalRow.rut}`}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            item xs={4}
                        >
                            <Typography variant="h6" gutterBottom>
                            <b>Mes:</b>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            item xs={8}
                        >
                            <Typography variant="h6" gutterBottom>
                            {`${dictMonth[parseInt(moment(monthDate).format("MM"))]}-${moment(monthDate).format("YYYY")}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    item xs={12}
                    style={{margin: "10px 0", padding: "10px"}}
                >
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            <TableCell className="text-center align-top">
                                Item
                            </TableCell>
                            <TableCell className="text-center align-top">
                                Codigo
                            </TableCell>
                            <TableCell className="text-center align-top">
                                Valor Modificado
                            </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataArray.map((row, index) => (
                                <TableRow
                                key={`${index}-workRows`}>
                                <TableCell className="text-center align-middle" style={{background: row.color, color: (row.color === "#ffc107" || row.color === "#ffef62") ? "#000000": "#fff" }}>
                                    {row.item}
                                </TableCell>
                                <TableCell className="text-center align-middle">
                                    {row.codigo}
                                </TableCell>
                                <TableCell className="text-center align-middle">
                                    {`${row.tipo_valor === "$" ? toMoneyFormat(row.valores.valor_modificado) : row.valores.valor_modificado }`}
                                </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </div>
    );
    }
};
