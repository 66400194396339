import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    Typography,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";
import moment from "moment-timezone";
import clsx from 'clsx';
import api from "utils/API";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: "all 500ms linear",
    },
    paper: {
        transition: "all 500ms linear",
        padding: "2% 4% 3%",
    },
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
        },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
}));

function StyledRadio (props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
};

export default function ModalRemoveWorkRow({
    open,
    handleClose,
    row,
    array,
    setArray,
}) {
    const classes = useStyles();
    const [optionRemove, setoptionRemove] = useState ("No");

    const handleChange = (event, set) => {
        set(event.target.value);
    };

    const updateValue = () => {
      let tempArray = [];
      if (optionRemove === "Sí"){
        api.delete(`api/tareaspaciente/${row.id_tarea_padre ? row.id_tarea_padre : row.id}`).then((result) => {
            tempArray = array.filter((tempRow) => row.id_tarea_padre  ?
                (row.id_tarea_padre !== tempRow.id_tarea_padre ) :
                (row.id !== tempRow.id_tarea_padre )
            ).filter((tempRow) => row.id !== tempRow.id)
            setArray(tempArray);
            handleClose();
        });
      } else {
        api.delete(`api/tareaspaciente/${row.id}`).then((result) => {
            tempArray = array.filter((tempRow) => row.id_tarea_padre  ?
                row.id !== tempRow.id :
                (row.id_tarea_padre !== tempRow.id && row.id !== tempRow.id)
            );
            setArray(tempArray);
            handleClose();
        });
      };
    };

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px',
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.paper}>
            <h3 id="spring-modal-title">{`Eliminar Tarea`}</h3>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                item
                xs={12}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item xs={12}
                >
                   <Typography variant="caption" gutterBottom>
                        Desea eliminar todas las tareas relacionadas a esta?
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item xs={12}
                    style={{padding: "1%"}}
                >
                    <FormControl component="fieldset">
                        <RadioGroup
                            row aria-label="again"
                            name="again"
                            value={optionRemove}
                            onChange={(event) => handleChange(event, setoptionRemove)}>
                            <FormControlLabel value={"Sí"} control={<StyledRadio />} label="Sí" />
                            <FormControlLabel value={"No"} control={<StyledRadio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {row.id_tarea_padre === null &&
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item xs={12}
                 >
                    <Typography variant="caption" gutterBottom style={{color: red[500]}}>
                        Al elminar esta tarea se borráran todas las tareas relacionadas
                    </Typography>
                </Grid>
                }
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        style={{backgroundColor: green[500]}}
                        onClick={updateValue}
                    >
                        Eliminar
                    </Button>

                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    </Modal>
    );
}