import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const Vacaciones = props => {
  const {
    startDate,
    endDate,
    totalDias,
    primerDiaTrabajado,
    ultimoDiaTrabajado,
    estadoVacaciones
  } = props;

  return (
    <Grid container justify="flex-start" style={{ marginTop: "2em" }}>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Días disponible:
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none", fontWeight: "bold" }}
          >
            
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <Grid container>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD-MM-YYYY"
                margin="normal"
                id="date-picker-inline1"
                label="Fecha de inicio"
                value={startDate}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                onKeyDown={e => {
                  e.preventDefault();
                }}
                readOnly
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <Grid container>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD-MM-YYYY"
                margin="normal"
                id="date-picker-inline2"
                label="Fecha de término"
                value={endDate}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                onKeyDown={e => {
                  e.preventDefault();
                }}
                minDate={startDate}
                minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
                readOnly
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <Grid container>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD-MM-YYYY"
                margin="normal"
                id="date-picker-inline3"
                label="Fecha último día trabajado"
                value={ultimoDiaTrabajado}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                onKeyDown={e => {
                  e.preventDefault();
                }}
                readOnly
                // minDate={startDate}
                // minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <Grid container>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD-MM-YYYY"
                margin="normal"
                id="date-picker-inline4"
                label="Fecha primer día trabajado"
                value={primerDiaTrabajado}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                onKeyDown={e => {
                  e.preventDefault();
                }}
                readOnly
                // minDate={startDate}
                // minDateMessage="La fecha de término no debe ser menor a la fecha de inicio"
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
        style={{ marginTop: "1em" }}
      >
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Días hábiles totales solicitados:
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none", fontWeight: "bold" }}
          >
            {totalDias === 0 ? 1 : totalDias}
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
        style={{ marginTop: "1em" }}
      >
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Estado vacaciones:
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none", fontWeight: "bold" }}
          >
            {estadoVacaciones}
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  );
};

export default Vacaciones;

Vacaciones.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.object,
  startDate: PropTypes.object,
  handleStartDate: PropTypes.func,
  endDate: PropTypes.object,
  handleEndDate: PropTypes.func,
  totalDias: PropTypes.string,
  primerDiaTrabajado: PropTypes.object,
  handlePrimerDiaTrabajado: PropTypes.func,
  ultimoDiaTrabajado: PropTypes.object,
  handleUltimoDiaTrabajado: PropTypes.func,
  editInfo: PropTypes.bool,
  estadoVacaciones: PropTypes.string,
  handleEstadoVacaciones: PropTypes.func
};
