import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "5em"
  }
});

const SearchBenefits = props => {
  const classes = useStyles();
  const { search, setSearch } = props;

  return (
    <Grid container className={classes.root}>
      <Grid item container xs={12} justify="center">
        <Grid item container xs={4} justify="center">
          <FormControl fullWidth variant="outlined">
            <TextField
              id="outlined-disabled"
              label="Buscar prestaciones"
              variant="outlined"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchBenefits;

SearchBenefits.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func
};
