import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";
import FormEditPatient from "./FormEditPatient";
import Snackbar from "components/Snackbar/Snackbar.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },

}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalEditPatient = props => {
  const classes = useStyles();
  const { openModal, closeModal, setpatient } = props;
  const [dataPatient, setdataPatient] = useState({
    ...props.patient,
    id_region: props.patient.id_region_id,
    id_comuna: props.patient.id_comuna_id
  });
  const [openAnimation, setOpenAnimation] = useState(false);
  const firstCharge = true;
  const [message, setmessage] = useState(null);

  useEffect(() => {
    if (firstCharge) {
      //initialCharge();
    }
  });

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto", width: "60%" }}
      >
        {openAnimation ? (
          <ModalTitle id="customized-dialog-title">
            Datos del paciente a editar
          </ModalTitle>
        ) : (
          <ModalTitle id="customized-dialog-title" onClose={closeModal}>
            Datos del paciente a editar
          </ModalTitle>
        )}
        <DialogContent>
          <Grid container className={classes.root} justify="center">
            <FormEditPatient
              data={dataPatient}
              setdata={setdataPatient}
              setmessage={setmessage}
              openAnimation={openAnimation}
              setOpenAnimation={setOpenAnimation}
              closeModal={closeModal}
              setpatient={setpatient}
            />
          </Grid>
        </DialogContent>
        {openAnimation ? (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            <CircularProgress />
          </DialogActions>
        ) : (
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "1em" }}
          >
            {/*}
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none" }}
            >
              Guardar
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={closeModal}
            >
              Cerrar
            </Button>

        */}
          </DialogActions>
        )}

        {message &&
          <Snackbar
            place="tc"
            close
            color="success"
            message={message}
            open={!!message}
            closeNotification={() => setmessage(null)}
          />
        }
      </Paper>
    </Modal>
  );
};

export default ModalEditPatient;

ModalEditPatient.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  patient: PropTypes.object,
  setpatient: PropTypes.func
};
