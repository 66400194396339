import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ModalSaved(props) {
  const { open, closeModal } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Detalle del pedido</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form-row" style={{ marginBottom: "10rem" }}>
          Se guardó exitosamente el pedido
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalSaved.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func
};
