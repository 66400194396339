import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailText from "views/Widgets/FichaPersonal/EditFichaPersonal/detailText";
import DetailFile from "views/Widgets/FichaPersonal/EditFichaPersonal/detailFileOnly";

export default function ModalCreateDocument(props) {
  const { open, closeModal, id_paquetizacion } = props;
  const [nombre_archivo, setNombre_archivo] = useState("");
  const [archivo, setArchivo] = useState(null);
  const [tipo_archivo, setTipo_archivo] = useState("");

  const list_tipo_archivo = useSelector(state => state.currentList.LISTA_TIPO_ARCHIVO);

  const scroll = "paper";

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function createTherapy() {
    const formData = new FormData();

    if (archivo) {
      formData.append("archivo", archivo);
    }
    formData.append("nombre_archivo", nombre_archivo);
    formData.append("tipo_archivo", tipo_archivo.value);
    formData.append("id_paquetizacion", id_paquetizacion);
    formData.append("estado", true);

    apiform
      .post("api/documentospaquetizacion/", formData)
      .then(response => {
        console.log(response);
        window.location.href = `/admin/documents/${id_paquetizacion}`;
      })
      .catch(error => {
        console.log(error.response);
        console.log("erro", error.response.data);
        {};
      });
  }

  const handleTipoArchivo = tipo_archivo => {
    setTipo_archivo(tipo_archivo);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Agregar archivo</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form-row center" style={{ marginBottom: "8rem" }}>
          <DetailSelect
            label="Tipo archivo:"
            value={tipo_archivo}
            onchange={handleTipoArchivo}
            list={list_tipo_archivo}
          />
          <DetailText
            label="Nombre:"
            placeholder="Nombre"
            value={nombre_archivo}
            maxlength="45"
            onchange={e => {
              setNombre_archivo(e.target.value);
            }}
          />
          <DetailFile
            label="Archivo:"
            value={archivo}
            onchange={e => setArchivo(e.target.files[0])}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Cancelar
        </Button>
        <Button
          onClick={createTherapy}
          color="primary"
          style={{ textTransform: "none" }}
        >
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCreateDocument.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_paquetizacion: PropTypes.number
};