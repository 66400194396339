import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core/';
import { Link } from "react-router-dom";
import OrderDetailInfo from "./OrderDetail";
import ProductsTable from "./Products/Products";
import ModalMessageSuccess from "./ModalMessageSuccess";
import ModalMessageIncomplete from "./ModalMessageIncomplete";
import ModalMessageFiles from "./ModalMessageFiles";


export default function AddFile() {
  const { id } = useParams();
  const [moduleType, setmoduleType] = useState("");

  const [changes, setChanges] = useState(true);
  const [detail, setDetail] = useState([]);
  const [products, setProducts] = useState([]);
  const [openModalMessageSuccess, setOpenModalMessageSuccess] = useState(false);
  const [messageModalMessageSuccess, setMessageModalMessageSuccess] = useState("");
  const [openModalMessageIncomplete, setOpenModalMessageIncomplete] = useState(false);
  const [messageModalMessageIncomplete, setMessageModalMessageIncomplete] = useState("");
  const [openModalMessageFiles, setOpenModalMessageFiles] = useState(false);

  const [loading, setLoading] = useState(false);

  const [observation, setObservation] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    initialChargeReception();
  }, []);

  const initialChargeReception = () => {
    const tempArrayURL = window.location.href.split("/");
    setmoduleType(tempArrayURL[tempArrayURL.length - 2]);
    const productosDetail = api.get(`api/ingresos/solicitud/${id}/productos/`);
    const solicitudDetail = api.get(`api/solicitudingresos/${id}`);
    Promise.all([productosDetail, solicitudDetail])
      .then(response => {
        setProducts(response[0].data);
        setDetail(response[1].data);

        setChanges(false)
        setLoading(true)
      })
      .catch(error => {

        setChanges(false)
        setLoading(true)
      });
  };
  const goBack = () => {
    window.location.href = `/admin/warehouse/task/${moduleType}`;
  };
  const modalMessageSuccess = (message, open) => {
    setMessageModalMessageSuccess(message);
    setOpenModalMessageSuccess(open);
  };

  const modalMessageIncomplete = (message, open) => {
    setMessageModalMessageIncomplete(message);
    setOpenModalMessageIncomplete(open);
  };

  function createReception() {

    const formData = new FormData();

    formData.append("nombre", file.name);
    formData.append("tipo_documento", "GuiaRecepcion");
    formData.append("archivo", file);
    formData.append("area", "Bodega");
    formData.append("observacion", observation);
    formData.append("id_solicitud", detail.id);

    let nuevo_estado_solicitud = "";
    let nuevo_estado_ingreso = "";
    let nuevo_estado_bodega = "";
    if (detail.estado_solicitud === "EsperandoGuiaBodega") {
      nuevo_estado_solicitud = "IngresarBodega";
      nuevo_estado_ingreso = "Finalizado";
      nuevo_estado_bodega = "IngresarBodega";
    } else {
      nuevo_estado_solicitud = "EsperandoGuiaEntrega";
      nuevo_estado_ingreso = "EsperandoGuiaEntrega";
      nuevo_estado_bodega = "EsperandoIngresos";
    }
    apiform
      .post("api/documentosingresos/", formData)
      .then(response => {
        api
          .patch(`api/solicitudingresos/${detail.id}`, {
            estado_solicitud: nuevo_estado_solicitud,
            estado_ingreso: nuevo_estado_ingreso,
            estado_bodega: nuevo_estado_bodega,
            guia_bodega: true
          })
          .then(response => {

            goBack();
          })
          .catch(error => {

          });
      })

  }

  const checkFiles = () => {

    api
      .post(`api/bodega/ingresos/${id}/finalizar/`)
      .then(response => {

        modalMessageSuccess("Se guardaron exitosamente los registros", true);
      })
      .catch(error => {

        setOpenModalMessageFiles(true);
      });
  };
  const finishWarehouse = () => {

    api
      .post(`api/bodega/finalizar/ingreso/${detail.id}`)
      .then(response => {

        goBack();
      })
      .catch(error => {

        goBack();
      });
  };
  const handleChangeObservation = (event) => {
    setObservation(event.target.value);
  };


  return (
    <GridContainer>
      <ViewTitle
        title="ADJUNTAR ARCHIVO"
        message="En esta sección podrás el archivo de recepción."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/warehouse/task/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link
              color="inherit"
              to={`/admin/warehouse/receptions/${moduleType}`}
            >
              Recepción de ingresos y devoluciones
            </Link>
            <Typography color="textPrimary">Adjuntar archivo</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <OrderDetailInfo
                id={detail.id}
                tipo={detail.tipo_origen}
                cantidad={detail.cantidad_productos}
                observacion={detail.observacion}
                fechaLlegada={detail.fecha_tentativa_llegada}
                estado={detail.estado_solicitud}
              />
            </GridContainer>
            <CardBody>
              <ProductsTable products={products} />
              {detail.estado_solicitud === "EsperandoGuiaBodega" ||
              detail.estado_solicitud === "EsperandoGuiaEntrega" ? (
                <GridContainer justify="center" className="m-3 mt-3 mb-3">
                  <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                      <TextField
                        required
                        id="outlined-name"
                        multiline
                        label="OBSERVACIONES:"
                        placeholder="Observaciones"
                        value={observation}
                        variant="outlined"
                        onChange={handleChangeObservation}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </GridContainer>
              ) : null}
              {detail.estado_solicitud === "EsperandoGuiaBodega" ||
              detail.estado_solicitud === "EsperandoGuiaEntrega" ? (
                <GridContainer
                  justify="center"
                  style={{ textAlign: "center" }}
                  className="m-3 mt-3 mb-3"
                >
                  <div className="col-md-4 mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="card-title">ARCHIVO:</span>
                        <p className="card-text">
                          Archivo: {file ? file.name : null}
                        </p>
                        <span className="btn btn-cmh-color btn-file">
                          Seleccionar
                          <input
                            type="file"
                            name={file}
                            onChange={e => {
                              setFile(e.target.files[0]);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </GridContainer>
              ) : null}
              <GridContainer justify="center" className="mt-3 mb-3">
                {detail.estado_bodega === "IngresarBodega" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={finishWarehouse}
                    className="m-1"
                  >
                   Finalizar ingreso a bodega
                  </Button>
                ) : detail.estado_solicitud === "EsperandoGuiaBodega" ||
                  detail.estado_solicitud === "EsperandoGuiaEntrega" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createReception}
                    className="m-1"
                  >
                    Adjuntar guía recepción
                  </Button>
                ) : null}
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {openModalMessageSuccess && (
        <ModalMessageSuccess
          open={openModalMessageSuccess}
          message={messageModalMessageSuccess}
          moduleType={moduleType}
        />
      )}
      {openModalMessageIncomplete && (
        <ModalMessageIncomplete
          open={openModalMessageIncomplete}
          message={messageModalMessageIncomplete}
          id={id}
          moduleType={moduleType}
        />
      )}
      {openModalMessageFiles && (
        <ModalMessageFiles
          open={openModalMessageFiles}
          closeModal={() => setOpenModalMessageFiles(false)}
          moduleType={moduleType}
        />
      )}
    </GridContainer>
  );
}
