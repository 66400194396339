import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import { Link } from "react-router-dom";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import DetailText from "views/Widgets/FichaPersonal/EditFichaPersonal/detailText";
import DetailTextDisabled from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextDisabled";
import { useParams } from "react-router-dom";
import ModalEditedRequest from "views/Warehouse/Requests/ModalEditedRequest";
import ModalAceptRequest from "./ModalAcepRequest";
import ModalRejectRequest from "./ModalRejectRequest";
import ProductRequestInfo from "./ProductRequestInfo";


export default function ProductCodeRequest() {
  const { id } = useParams();
  const [moduleType, setmoduleType] = useState("");
  const supplies = [];
  const [group, setGroup] = useState("");
  const [group_final, setGroup_final] = useState("");
  const sku = "";
  const [id_sku, setId_sku] = useState("");
  const [sku_final, setSKU_final] = useState("");
  const [id_sku_final, setId_sku_final] = useState("");
  let list_sku = [];
  let list_sku_final = [];
  const [description, setDescription] = useState("");
  const [description_final, setDescription_final] = useState("");

  const [modalSaved, setModalSaved] = useState(false);
  const [value, setValue] = useState(sku.value);
  const [select ,setSelect] = useState("");
  const [modalAceptRequest, setModalAceptReqest] = useState(false);
  const [modalRejectRequest, setModalRejectRequest] = useState(false);
  const [skuToCreate, setSkuToCreate] = useState("");
  const [suggestedSku, setSuggestedSku] = useState("");
  const [groupSku, setGroupSku] = useState("");
  const [descriptionSku, setDescriptionSku] = useState("");
  const [dateSku, setDateSku] = useState("");
  const [createdProduct, setCreatedProduct] = useState("");

  let listMedicamentos = [];
  let listEquipamiento = [];
  let listInsumos = [];

  useEffect(() => {

    const tempArrayURL = window.location.href.split('/');
    const tempModuleType = tempArrayURL[tempArrayURL.length-2];
    setmoduleType(tempModuleType);
    api.get(`api/solicitudsku/${id}`).then(response => {
      setSuggestedSku(response.data.sku_sugerido);
      setGroupSku(response.data.grupo);
      setDescriptionSku(response.data.descripcion);
      setDateSku(response.data.fecha_solicitud);
      setCreatedProduct(response.data.id_producto_creado);


    });
  }, []);

  useEffect(() => {
    supplies.map(supply => {
      list_sku.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies.map(supply => {
      list_sku_final.push({ value: supply.SKU, label: supply.SKU });
    });
    supplies
      .filter(supply => supply.SKU === sku.value)
      .map(filtered => {
        setGroup(filtered.grupo_producto);
        setDescription(filtered.descripcion_producto);
        setId_sku(filtered.id);
      });
    supplies
      .filter(supply => supply.SKU === sku_final.value)
      .map(filtered => {
        setGroup_final(filtered.grupo_producto);
        setDescription_final(filtered.descripcion_producto);
        setId_sku_final(filtered.id);
      });
  });

  useEffect(() => {
    supplies
      .filter(supply => supply.SKU === sku.value)
      .map(filtered => {
        if (filtered.grupo_producto === "Medicamento") {
          supplies
            .filter(
              supply =>
                supply.grupo_producto === "Medicamento" &&
                supply.SKU !== sku.value
            )
            .map(filtered => {
              listMedicamentos.push({
                value: filtered.SKU, label: filtered.SKU
              });
            });
          setSelect(filtered.grupo_producto);
        }
        if (["Insumos", "Articulos", "Alimentos"].includes(filtered.grupo_producto)) {
          supplies
            .filter(
              supply =>
              ["Insumos", "Articulos", "Alimentos"].includes(supply.grupo_producto) && supply.SKU !== sku.value
            )
            .map(filtered => {
              listInsumos.push({ value: filtered.SKU, label: filtered.SKU });
            });
          setSelect(filtered.grupo_producto);
        }
        if (filtered.grupo_producto === "Equipamiento") {
          supplies
            .filter(
              supply =>
                supply.grupo_producto === "Equipamiento" &&
                supply.SKU !== sku.value
            )
            .map(filtered => {
              listEquipamiento.push({
                value: filtered.SKU,
                label: filtered.SKU
              });
            });
          setSelect(filtered.grupo_producto);
        }
      });
  });

  useEffect(() => {
    if (value !== sku.value) {
      setValue(sku.value);
      setSKU_final("");
      setGroup_final("");
      setDescription_final("");
    }
  });

  return (
    <GridContainer>
      <ViewTitle
        title="DETALLE FRACCIONAMIENTO"
        message="En esta sección podrás administrar la solicitud del fraccionamiento seleccionado."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" to={`/admin/income/tasks/${moduleType}`}>
              {`Tareas de ${moduleType === "storage" ? "Ingreso" : "Ingreso Farmacia"}`}
            </Link>
            <Link color="inherit" to={`/admin/income/divisions/${moduleType}`}>
              Fraccionamiento y códigos
            </Link>
            <Typography color="textPrimary">Detalle fraccionamiento</Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        <Card>
          <CardBody className="mb-3">
            <GridContainer justify="center">
              <ProductRequestInfo
                id={id}
                group={groupSku}
                description={descriptionSku}
                date={dateSku}
              />
            </GridContainer>
            <Grid container direction="row" justify="center">
              <Grid container item direction="row" xs={8}>
                <Grid item xs={4} align="center" />
                <Grid item xs={4} align="center">
                  <h4 className="font-weight-bold mb-3"></h4>
                </Grid>
                <Grid item xs={4} />
                <DetailTextDisabled
                  label="SKU sugerido:"
                  value={suggestedSku}
                />
                <DetailText
                  label="SKU a crear:"
                  placeholder="SKU a crear"
                  value={skuToCreate}
                  maxlength="20"
                  onchange={e => {
                    setSkuToCreate(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                {skuToCreate === "" ? (
                  <span className="text-danger">
                    Debes ingresar un SKU a crear
                  </span>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setModalRejectRequest(true)}
                >
                  Rechazar solicitud
                </Button>
              </Grid>
              <Grid item>
                {skuToCreate !== "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalAceptReqest(true)}
                  >
                    Crear código
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      {modalAceptRequest && (
        <ModalAceptRequest
          open={modalAceptRequest}
          id={id}
          group={groupSku}
          description={descriptionSku}
          dateRequest={dateSku}
          suggestedSku={suggestedSku}
          createdProduct={createdProduct}
          skuToCreate={skuToCreate}
          closeModal={() => setModalAceptReqest(false)}
          moduleType={moduleType}
        />
      )}
      {modalRejectRequest && (
        <ModalRejectRequest
          open={modalRejectRequest}
          closeModal={() => setModalRejectRequest(false)}
          id_order={id}
          moduleType={moduleType}
        />
      )}
      {modalSaved && (
        <ModalEditedRequest open={modalSaved} closeModal={() => setModalSaved(false)} moduleType={moduleType} />
      )}
    </GridContainer>
  );
}
