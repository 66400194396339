import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop, Paper, Grid } from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField";
import moment from "moment-timezone";
import "moment/locale/es";
import api from "utils/API";
import AlertDialog from "components/Attendance/AlertDialog";


import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
moment.locale("es");


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 450,
    paddingBottom: '45px',
    paddingLeft: '45px',
    paddingRight: '45px',
    paddingTop: '20px',
  },
  modal: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 6, 5),

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function ModalReemplazo({
  open,
  handleClose,
  tipoAccion,
  equipo,
  dataPatient,
}) {
  const classes = useStyles();
  const [motivo, setMotivo] = useState('');
  const [fechaInicio, handleOnChangeFechaInicio] = useState(moment());
  const [fechaFin, handleOnChangeFechaFin] = useState(moment());
  const [abrirAlerta,setAbrirAlerta] = React.useState(false);

  const [checkedIndefinido,setCheckedIndefinido] = React.useState(true);
  const [titulo,setTitulo] = React.useState('');
  const [mensaje,setMensaje] = React.useState('');
  const [tipoAlerta,setTipoAlerta] = React.useState('');

  const handleOnChangeMotivo = (value) => {
    setMotivo(value);
  };
  const handleChangeCheckbox = (value) => {
    setCheckedIndefinido(!value);
  };
  const handleCerrarAlerta = (event) => {
    setAbrirAlerta(false);
  }
  const sendRequest = (status) => {

    let bodyes_list = {};
    let body_solicitud_paciente = {
      'Tipo_Solicitud_Paciente':tipoAccion==='reemplazo'? 'SolicitarReemplazoEquipo':'SolicitarRetiroEquipo',
      'prioridad':'Media',
      'id_paciente_id':dataPatient.id,
    }
    let body_solicitud_equipo = {
      'fecha_inicio':moment(fechaInicio).format("YYYY-MM-DD"),
      'fecha_fin':moment(fechaFin, 'DD-MM-YYYY').isValid() ? moment(fechaFin).format("YYYY-MM-DD"):null,
      'motivo':motivo,
      'tipo':tipoAccion==='reemplazo'? 'Reemplazar':'Retirar',
      'id_equipo_paciente_id':equipo.id,
    }

    bodyes_list["solicitud_paciente"]=body_solicitud_paciente;
    bodyes_list["solicitud_equipo"]=body_solicitud_equipo;

    if(tipoAccion==='reemplazo'){

      api.post(`api/paciente/solicitudes/reemplazo/equipos`,bodyes_list)
      .then((response) => {
          setTitulo("Exito")
          setMensaje(response.data.Exito)
          setTipoAlerta("success")

          setAbrirAlerta(true)
      })
      .catch((error) => {
        setTitulo("¡Atención!")
        setMensaje(error.data.error)
        setTipoAlerta("warning")

        setAbrirAlerta(true)
      });
    }else{

      api.post(`api/paciente/solicitudes/retiro/equipos`,bodyes_list)
      .then((response) => {
          setTitulo("Exito")
          setMensaje(response.data.Exito)
          setTipoAlerta("success")

          setAbrirAlerta(true)
      })
      .catch((error) => {
        setTitulo("¡Atención!")
        setMensaje(error.data.error)
        setTipoAlerta("warning")

        setAbrirAlerta(true)
      });
    }
  };


  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{outline: 'none !important',textTransform: 'none !important'}}
      >
        <Fade in={open}>
          <Card className={classes.root}>
            <CardHeader

              action={
                <IconButton onClick={handleClose} aria-label="settings">
                  <CloseIcon />
                </IconButton>
              }
              title={`Solicitar ${tipoAccion} de ${equipo.equipo}:`}
            />
            <CardContent>
             <Grid container direction="column" alignItems="center" justify="center" spacing={5}>
              <Grid item xs={12}>
                <TextField
                  id="filled-number"
                  label="Indique el motivo"
                  type="text"
                  value={motivo}
                  onChange={(event) =>handleOnChangeMotivo(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  style={{width:"570px"}}
                >
                </TextField>
              </Grid>
             </Grid>
             <Grid container direction="row" alignItems="center" justify="center" spacing={3}>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                  <KeyboardDatePicker
                    label={tipoAccion==='reemplazo'? 'Fecha Inicio':'Fecha Retiro'}
                    clearable
                    key={"fecha-inicio"}
                    format="DD-MM-YYYY"
                    variant="inline"
                    inputVariant="outlined"
                    style={{outline: 'none !important',textTransform: 'none !important'}}
                    value={fechaInicio ? fechaInicio:moment()}
                    onChange={date=>handleOnChangeFechaInicio(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              {tipoAccion==='reemplazo' &&
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                      <KeyboardDatePicker
                        label="Fecha Final"
                        clearable
                        key={"fecha-fin"}
                        format="DD-MM-YYYY"
                        variant="inline"
                        style={{outline: 'none !important',textTransform: 'none !important'}}
                        inputVariant="outlined"
                        value={moment(fechaFin, 'DD-MM-YYYY').isValid() ? fechaFin:null}
                        onChange={date=>handleOnChangeFechaFin(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
              }
             </Grid>

            </CardContent>
            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none"
                }}
                onClick={sendRequest}>
                  Solicitar
              </Button>
              <AlertDialog openDialog={abrirAlerta} handleClose={handleCerrarAlerta} message={mensaje} title={titulo} severity={tipoAlerta} />
            </CardActions>
          </Card>
        </Fade>
      </Modal>
  );
}

ModalReemplazo.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  table: PropTypes.bool,
  listItem: PropTypes.array,
  dataInfo: PropTypes.object,
  dataArray: PropTypes.array,
  setdataArray: PropTypes.func,
  handleAddItem: PropTypes.func,
  setPageRefresh: PropTypes.func
};
