import React, { useState, useEffect } from "react";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import api from "utils/API";
import apiform from "utils/APIForm";
import DetailText from "views/Widgets/FichaPersonal/EditFichaPersonal/detailText";
import ModalDialog from "views/Widgets/Common/ModalDialog";

export default function ModalEditIsapreContact(props) {
  const { open, closeModal, id_isapre, id_paquetizacion, setIsapre } = props;

  const [nombre_contacto1, setNombre_contacto1] = useState("");
  const [contacto1, setContacto1] = useState("");
  const [nombre_contacto2, setNombre_contacto2] = useState("");
  const [contacto2, setContacto2] = useState("");
  const [nombre_contacto3, setNombre_contacto3] = useState("");
  const [contacto3, setContacto3] = useState("");
  const [direccion, setDireccion] = useState("");
  const [nombre, setNombre] = useState("");
  const [representante, setRepresentante] = useState("");
  const [rut, setRut] = useState("");
  const [rut_representante, setRut_representante] = useState("");

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {

    api.get(`api/convenioisapre/${id_isapre}`).then(result => {
      setNombre_contacto1(result.data.nombre_contacto1);
      setContacto1(result.data.contacto1);
      setNombre_contacto2(result.data.nombre_contacto2);
      setContacto2(result.data.nombre_contacto2);
      setNombre_contacto3(result.data.nombre_contacto3);
      setContacto3(result.data.contacto3);
      setDireccion(result.data.direccion);
      setNombre(result.data.nombre);
      setRepresentante(result.data.representante);
      setRut(result.data.rut);
      setRut_representante(result.data.rut_representante);
    });
  }, []);

  function editIsapre() {
    const formData = new FormData();
    formData.append("nombre_contacto1", nombre_contacto1);
    formData.append("contacto1", contacto1);
    formData.append("nombre_contacto2", nombre_contacto2);
    formData.append("contacto2", contacto2);
    formData.append("nombre_contacto3", nombre_contacto3);
    formData.append("contacto3", contacto3);
    formData.append("direccion", direccion);
    formData.append("nombre", nombre);
    formData.append("representante", representante);
    formData.append("rut", rut);
    formData.append("rut_representante", rut_representante);

    apiform
      .put(`api/convenioisapre/${id_isapre}`, formData)
      .then(result => {
        setIsapre(result.data)
        closeModal();
      })
  }

  const _content_ = <div className="form-row" style={{ marginBottom: "10rem" }}>
    <DetailText
      label="Nombre contacto 1:"
      value={nombre_contacto1}
      onchange={e => setNombre_contacto1(e.target.value)}
    />
    <DetailText
      label="Contacto 1:"
      value={contacto1}
      onchange={e => setContacto1(e.target.value)}
    />
    <DetailText
      label="Nombre contacto 2:"
      value={nombre_contacto2}
      onchange={e => setNombre_contacto2(e.target.value)}
    />
    <DetailText
      label="Contacto 2:"
      value={contacto2}
      onchange={e => setContacto2(e.target.value)}
    />
    <DetailText
      label="Nombre contacto 3:"
      value={nombre_contacto3}
      onchange={e => setNombre_contacto3(e.target.value)}
    />
    <DetailText
      label="Contacto 3:"
      value={contacto3}
      onchange={e => setContacto3(e.target.value)}
    />
  </div>

  const _actions_ = <>
    <Button
      onClick={closeModal}
      color="primary"
      variant="contained"
    >
      Cancelar
    </Button>
    <Button
      onClick={editIsapre}
      color="primary"
      variant="contained"
    >
      Guardar
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={"Editar contactos isapre"}
      _content_={_content_}
      _actions_ = {_actions_}
    />
  );
}
