import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from '@material-ui/core/Checkbox';
import ReactSelect from 'react-select';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import api from "utils/API";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import {useSelector, useDispatch} from 'react-redux';
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  margin: {
    margin: theme.spacing(1)
  },
  formRoot: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  rooCard: {
    minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, tensAssigned, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function ModalEditRecordNurse(props) {
  const {
    open,
    closeModal,
    dataPatient,
    dataFile,
    setChanges,
    tensAssigned,
    nurseInfo,
  } = props;
  const classes = useStyles();
  const [timeStart, setTimeStart] = useState(moment(nurseInfo?.fecha_inicio));
  const [timeEnd, setTimeEnd] = useState(moment(nurseInfo?.fecha_fin));
  const [familyPresence, setFamilyPresence] = useState(nurseInfo?.presencia_familiar);
  const [relevantBackground, setRelevantBackground] = useState(nurseInfo?.antecedentes_relevantes);
  const [saturation, setSaturation] = useState(nurseInfo?.saturacion);
  const [fc, setFc] = useState(nurseInfo?.frecuencia_cardiaca);
  const [fr, setFr] = useState(nurseInfo?.frecuencia_respiratoria);
  const [pSistolica, setPSistolica] = useState(nurseInfo?.presion_sistolica);
  const [pDistolica, setPDistolica] = useState(nurseInfo?.presion_diastolica);
  const [pam, setpam] = useState(nurseInfo?.presion_arterial_media)
  const [t, setT] = useState(nurseInfo?.temperatura);
  const [incomes, setIncomes] = useState(nurseInfo?.hidrico_ingreso);
  const [outcomes, setOutcomes] = useState(nurseInfo?.hidrico_egreso);
  let total = outcomes - incomes;
  const [weight, setWeight] = useState(nurseInfo?.peso);
  const [size, setSize] = useState(nurseInfo?.talla);
  const [lastMedicalVisit, setLastMedicalVisit] = useState(moment());
  const [nextMedicalVisit, setNextMedicalVisit] = useState(moment());
  const [exams, setExams] = useState(nurseInfo?.examenes);
  const [gtt, setGtt] = useState(nurseInfo?.gtt);
  const [tqt, setTqt] = useState(nurseInfo?.tqt);
  const [vvp, setVvp] = useState(nurseInfo?.vpp);
  const [o2, setO2] = useState(nurseInfo?.o2);
  const [physicalExam, setPhysicalExam] = useState(nurseInfo?.examen_fisico);
  const [checked, setChecked] = useState(true);
  const [tens, setTens] = useState({value:nurseInfo?.id_profesional_tens,label:nurseInfo['profesional_tens']?.first_name+" "+nurseInfo['profesional_tens']?.last_name+" "+nurseInfo['profesional_tens']?.second_last_name});
  const [o2Comment, setO2Comment] = useState(nurseInfo?.comentario_o2);
  const [medicamento, setMedicamento] = useState(nurseInfo?.medicamentos);
  const [tensList, setTensList] = useState([]);
  const [inputFields, setInputFields] = useState(nurseInfo?.medicamentos);
  const [patientUnit, setPatientUnit] = useState(nurseInfo?.unidad_paciente);
  const [nursingIndications, setNursinIndications] = useState(nurseInfo?.indicaciones_enfermeria);


  const listMedical = useSelector(state => state.currentList.listMedicines);


  const handleSelectTens= (value) => {
    setTens(value);
  };
  const handleRelevantBackground= (event) => {
    setRelevantBackground(event.target.value)
  };
  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const handleLastMedicalVisit = time => {
    setLastMedicalVisit(time);
  };

  const handleNextMedicalVisit = time => {
    setNextMedicalVisit(time);
  };


  const handleTimeStartChange = time => {


    var tensassigned = []
    tensAssigned.filter(item => {
      const horaVisitaIni = moment(time).format("YYYY-MM-DD HH:mm:ss")
      const horaVisitaFin = moment(timeEnd).format("YYYY-MM-DD HH:mm:ss")
      const horaHoraIni = moment(item.fecha_hora).subtract(4, 'hours').format("YYYY-MM-DD HH:mm:ss")
      const horaHoraFin = moment(item.fecha_final,"YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD HH:mm:ss")

      if(( moment(horaVisitaFin).diff(horaHoraIni, "minutes") >=0 &&  moment(horaHoraFin).diff(horaVisitaFin, "minutes") >= 0) &&
      ( moment(horaVisitaIni).diff(horaHoraIni, "minutes") >=0 &&  moment(horaHoraFin).diff(horaVisitaIni, "minutes") >= 0)  ){

        tensassigned.push( {value:item.id_usuarios,label:item['usuario'].first_name+" "+item['usuario'].last_name+item['usuario'].second_last_name});
      }
    });

    setTimeStart(time);
    setTensList(tensassigned)
  };

  const handleTimeEndChange = time => {
    var tensassigned = []
    tensAssigned.filter(item =>  {

      const horaVisitaIni = moment(timeStart).format("YYYY-MM-DD HH:mm:ss")
      const horaVisitaFin = moment(time).format("YYYY-MM-DD HH:mm:ss")
      const horaHoraIni = moment(item.fecha_hora).subtract(4, 'hours').format("YYYY-MM-DD HH:mm:ss")
      const horaHoraFin = moment(item.fecha_final,"YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD HH:mm:ss")
      if(( moment(horaVisitaFin).diff(horaHoraIni, "minutes") >=0 &&  moment(horaHoraFin).diff(horaVisitaFin, "minutes") >= 0) &&
      ( moment(horaVisitaIni).diff(horaHoraIni, "minutes") >=0 &&  moment(horaHoraFin).diff(horaVisitaIni, "minutes") >= 0)  ){

        tensassigned.push( {value:item.id_usuarios,label:item['usuario'].first_name+" "+item['usuario'].last_name+item['usuario'].second_last_name});
      }
    });

    setTimeEnd(time);
    setTensList(tensassigned)
  };

  const handleRemoveSelect = index => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleAddSelect = () => {
    setInputFields([
      ...inputFields,
      { medicamento: "", via: "", administracion: "", horario: "" }
    ]);
  };

  const handleInputMedicamento = (value,index) => {
    setMedicamento(value);
    const values = [...inputFields];
    values[index].medicamento = value.label;
  };

  const handleInputVia = (event, index) => {
    const values = [...inputFields];
    values[index].via = event.target.value;
    setInputFields(values);
  };

  const handleInputAdministracion = (event, index) => {
    const values = [...inputFields];
    values[index].administracion = event.target.value;
    setInputFields(values);
  };

  const handleInputHorario = (event, index) => {
    const values = [...inputFields];
    values[index].horario = event.target.value;
    setInputFields(values);
  };

  const addRecord = () => {
    const hojaEnfermera = {
      fecha_inicio: moment(timeStart).format("YYYY-MM-DD[T]HH:mm:ss"),
      fecha_fin: moment(timeEnd).format("YYYY-MM-DD[T]HH:mm:ss"),
      presencia_familiar: familyPresence,
      antecedentes_relevantes: relevantBackground,
      saturacion: saturation,
      frecuencia_cardiaca: fc,
      frecuencia_respiratoria: fr,
      presion_sistolica: pSistolica,
      presion_diastolica: pDistolica,
      presion_arterial_media: pam,
      temperatura: t,
      hidrico_ingreso: incomes,
      hidrico_egreso: outcomes,
      peso: weight,
      talla: size,
      comentario_o2:o2Comment,
      ultima_visita_medica: moment(lastMedicalVisit).format(
        "YYYY-MM-DD[T]HH:mm:ss"
        ),
      proxima_visita_medica: moment(nextMedicalVisit).format(
        "YYYY-MM-DD[T]HH:mm:ss"
        ),
      examenes: exams,
      examen_fisico: physicalExam,
      unidad_paciente: patientUnit,
      indicaciones_enfermeria: nursingIndications,
      gtt: gtt,
      tqt: tqt,
      vpp: vvp,
      o2: o2,
      medicamentos: inputFields,
      id_hoja_enfermera:nurseInfo?.id,
      id_profesional_tens:tens.value,
      id_hoja_registros:nurseInfo?.id_hoja_registros,
      id_profesional: parseInt(localStorage.getItem("user_id")),
      id_ficha: dataFile.id,
      id_cargo: parseInt(localStorage.getItem("id_cargo"))
    };

    api.post("api/paciente/editarhojaenfermera/", hojaEnfermera).then(() => {
      setChanges(true);
      closeModal();
    })
  };
  React.useEffect(() => {
    handleTimeStartChange(moment(nurseInfo?.fecha_inicio))
    if(inputFields===null || inputFields.length===0 ){
      setInputFields([
        { medicamento: "", via: "", administracion: "", horario: "" }
      ])
    }
  }, []);
  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
        disableBackdropClick
        scroll="paper"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          align="center"
          style={{ textTransform: "none" }}
        >
          Agregar registro
        </DialogTitle>
        <DialogContent scroll={"paper"}>
          <Grid container className={classes.root}>
            <Grid item xs={12} container>
              <Grid item xs={1} />
              <Grid item xs={10} container justify="center">
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  style={{ marginTop: "4px" }}
                >
                  <Grid item xs={8}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <TextField
                        disabled
                        id="outlined-disabled"
                        label="Nombre Paciente"
                        value={`${dataPatient?.nombre} ${dataPatient?.apellido_paterno} ${dataPatient?.apellido_materno}`}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <TextField
                        disabled
                        id="outlined-disabled"
                        label="Fecha"
                        variant="outlined"
                        value={moment().format("LL")}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  style={{ marginTop: "4px" }}
                >
                  <Grid item xs={3}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Inicio Visita"
                            value={timeStart===null ? moment(nurseInfo?.fecha_inicio):timeStart}
                            onChange={handleTimeStartChange}
                            KeyboardButtonProps={{
                              "aria-label": "change time"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Término Visita"
                            value={timeEnd===null ? moment(nurseInfo?.fecha_fin).add(30, "minutes") :timeEnd}
                            onChange={handleTimeEndChange}
                            KeyboardButtonProps={{
                              "aria-label": "change time"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Presencia Familiar"
                        inputProps={{
                          maxLength: 100
                        }}
                        variant="outlined"
                        value={familyPresence}
                        onChange={e => setFamilyPresence(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  style={{ marginTop: "4px" }}
                >
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <TextField
                      disabled
                      id="outlined-disabled"
                      label="Personal en Turno"
                      value={`${localStorage.getItem(
                        "first_name"
                      )} ${localStorage.getItem("last_name")}`}
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <ReactSelect
                      onChange={handleSelectTens}
                      options={tensList}
                      value={{value: nurseInfo?.id_profesional_tens, label: nurseInfo?.id_profesional_tens ? `${nurseInfo['profesional_tens']?.first_name} ${nurseInfo['profesional_tens']?.last_name} ${nurseInfo['profesional_tens']?.second_last_name}` : "Sin Tens"}}
                      placeholder={'Seleccione Tens de turno'}
                      noOptionsMessage={() => 'No hay Tens disponible'}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        menu: provided => ({ ...provided, zIndex: "9999 !important" ,fontFamily: "Roboto",fontSize: 16}),
                        control: base => ({ ...base, height: '56px',minHeight: '56px',fontFamily: "Roboto",fontSize: 16}),
                        indicatorsContainer: provided => ({ ...provided,  height: '56px' }),
                        valueContainer: provided => ({ ...provided, height: '56px',  padding: '0 6px' }),

                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  style={{ marginTop: "4px" }}
                >
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <TextField
                      id="outlined-multiline-static"
                      label="Antecedentes Relevantes"
                      inputProps={{
                        maxLength: 500
                      }}
                      multiline
                      rows={4}
                      value={relevantBackground}
                      onChange={(event) => handleRelevantBackground(event)}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  style={{ marginTop: "4px" }}
                >
                  <Grid item xs={3} container>
                    <Card className={classes.root} variant="outlined">
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                          align="center"
                        >
                          Signos Vitales
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Grid item xs={12} container>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-number"
                                label="Saturación"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={saturation}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setSaturation(parseInt(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              value="bottom"
                              control={<Checkbox
                                color="primary"
                                checked={checked}
                                onChange={handleCheckbox}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />}
                              label="¿Registrar O2?"
                              labelPlacement="start"
                            />
                          </Grid>
                          {checked ?
                            <div><Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="O2"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={o2}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setO2(parseFloat(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="Comentario O2"
                                variant="outlined"
                                value={o2Comment}
                                onChange={e => {
                                  setO2Comment(e.target.value);
                                }}
                              />
                            </FormControl>
                          </Grid></div>
                          :''}
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="FC"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={fc}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setFc(parseInt(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="FR"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={fr}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setFr(parseInt(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="Presión sistólica"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={pSistolica}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setPSistolica(parseInt(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="Presión diastólica"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={pDistolica}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setPDistolica(parseInt(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="Presión arterial Media"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={pam}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setpam(parseInt(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="T°"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={t}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setT(parseFloat(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={3} container>
                    <Card className={classes.root} variant="outlined">
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                          align="center"
                        >
                          Último Tipo de Ingreso
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Grid item xs={12} container>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="Ingresos"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={incomes}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setIncomes(parseInt(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="Egresos"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={outcomes}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setOutcomes(parseInt(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                disabled
                                style={{ paddingRight: "1em" }}
                                id="outlined-disabled"
                                label="Total"
                                variant="outlined"
                                value={total}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={3} container>
                    <Card className={classes.root} variant="outlined">
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                          align="center"
                        >
                          Observaciones
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Grid item xs={12} container>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="Peso"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={weight}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setWeight(parseInt(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="Talla"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={size}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setSize(parseInt(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Fecha Últ. V. Médica."
                                    value={lastMedicalVisit}
                                    onChange={handleLastMedicalVisit}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Hora Últ. V. Médica."
                                    value={lastMedicalVisit}
                                    onChange={handleLastMedicalVisit}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time"
                                    }}
                                  />
                                </Grid>
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Fecha Prox. V. Méd."
                                    value={nextMedicalVisit}
                                    onChange={handleNextMedicalVisit}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Hora Prox. V. Méd."
                                    value={nextMedicalVisit}
                                    onChange={handleNextMedicalVisit}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time"
                                    }}
                                  />
                                </Grid>
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="Exámenes"
                                inputProps={{
                                  maxLength: 500
                                }}
                                variant="outlined"
                                value={exams}
                                onChange={e => setExams(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={3} container>
                    <Card className={classes.root} variant="outlined">
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                          align="center"
                        >
                          Nº Días
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Grid item xs={12} container>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="GTT"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={gtt}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setGtt(parseFloat(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="TQT"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={tqt}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setTqt(parseFloat(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                style={{ paddingRight: "1em" }}
                                id="outlined-basic"
                                label="VVP"
                                inputProps={{ type: "number", step: "1" }}
                                variant="outlined"
                                value={vvp}
                                onChange={e => {
                                  if (/^[0-9\b]+$/.test(e.target.value)) {
                                    setVvp(parseFloat(e.target.value));
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  style={{ marginTop: "4px" }}
                >
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <TextField
                      id="outlined-multiline-static"
                      label="Exámen Físico"
                      inputProps={{
                        maxLength: 4000
                      }}
                      multiline
                      rows={7}
                      value={physicalExam}
                      onChange={e => setPhysicalExam(e.target.value)}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  style={{ marginTop: "4px" }}
                >
                  <Grid item xs={12}>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                      align="center"
                    >
                      Indicaciones Médicas Actuales
                    </Typography>
                  </Grid>

                  {inputFields.map((inputField, index) => (

                    <Grid key={index} item xs={12} container spacing={1}>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                        >
                          <ReactSelect

                            onChange={value => handleInputMedicamento(value, index)}
                            options={listMedical.length > 0 ? listMedical : [{value: null, label: "Cargando..."}]}
                            defaultValue={listMedical.find(item => item.label === inputField.medicamento)}
                            noOptionsMessage={() => 'No hay medicamentos disponible'}
                            styles={{
                              menuPortal: base => ({ ...base, zIndex: 9999 }),
                              menu: provided => ({ ...provided, zIndex: "9999 !important" ,fontFamily: "Roboto",fontSize: 16}),
                              control: base => ({ ...base, height: '56px',minHeight: '56px',fontFamily: "Roboto",fontSize: 16}),
                              indicatorsContainer: provided => ({ ...provided,  height: '56px' }),
                              valueContainer: provided => ({ ...provided, height: '56px',  padding: '0 6px' }),

                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={5} container>
                        <Grid item xs={12} container spacing={1}>
                          <Grid item xs={4}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                id="outlined-basic"
                                label="VIA"
                                inputProps={{
                                  maxLength: 45
                                }}
                                variant="outlined"
                                value={inputField.via}
                                onChange={event => handleInputVia(event, index)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                id="outlined-basic"
                                label="Administración"
                                inputProps={{
                                  maxLength: 45
                                }}
                                variant="outlined"
                                value={inputField.administracion}
                                onChange={event =>
                                  handleInputAdministracion(event, index)
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <TextField
                                id="outlined-basic"
                                label="Horario"
                                inputProps={{
                                  maxLength: 45
                                }}
                                variant="outlined"
                                value={inputField.horario}
                                onChange={event =>
                                  handleInputHorario(event, index)
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1} container spacing={1}>
                        <Grid item xs={6}>
                          <IconButton onClick={() => handleRemoveSelect(index)}>
                            <RemoveIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={6}>
                          <IconButton onClick={() => handleAddSelect()}>
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={1}
                    style={{ marginTop: "4px" }}
                  >
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <TextField
                        id="outlined-multiline-static"
                        label="Unidad del Paciente"
                        multiline
                        inputProps={{
                          maxLength: 500
                        }}
                        rows={3}
                        value={patientUnit}
                        onChange={e => setPatientUnit(e.target.value)}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={1}
                    style={{ marginTop: "4px" }}
                  >
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <TextField
                        id="outlined-multiline-static"
                        label="Indicaciones de Enfermería"
                        inputProps={{
                          maxLength: 4000
                        }}
                        multiline
                        rows={7}
                        value={nursingIndications}
                        onChange={e => setNursinIndications(e.target.value)}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "1em" }}
        >
        <Button
          style={{ textTransform: "none" }}
          variant="contained"
          color="primary"
          onClick={addRecord}
        >
          Guardar
        </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ModalEditRecordNurse.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  dataPatient: PropTypes.object,
  dataFile: PropTypes.object,
  setChanges: PropTypes.func,
  setOpenBackdrop: PropTypes.func,
  nurseInfo: PropTypes.object,
};
