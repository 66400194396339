import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import CancelIcon from "@material-ui/icons/Cancel";
import Link from "@material-ui/core/Link";
import NumberFormat from "react-number-format";
import api from "utils/API";
import apiform from "utils/APIForm";
import TurnoExtra from "./TurnoExtra";
import AtencionExtra from "./AtencionExtra";
import Vacaciones from "./Vacaciones";
import PermisoSinSueldo from "./PermisoSinSueldo";
import Inasistencia from "./Inasistencia";
import VacacionesSummary from "./VacacionesSummary";
import Sucess from "../../../Clinic/Endowments/Detail/Dialogs/Sucess";
import moment from "moment-timezone";
import "moment/locale/es";
import Success from "components/Typography/Success";
moment.locale("es");

var momentBusinessDays = require("moment-business-days");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  deleteFile: {
    "&:hover": {
      backgroundColor: theme.palette.grey[500]
    }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    maxHeight: "70%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    position: "absolute",
    minHeight: "150%",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ textAlign: "center" }}
    >
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator={true}
      decimalSeparator={false}
      allowNegative={false}
      isNumericString
      prefix="$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ModalViewRecord = props => {
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    tempEventInfo,
    tempRowInfo,
    setOpenDialogSucess,
    setMessage,
    setPageRefresh,
    variableCounter,
    setVariableCounter,
    eventsVariables,
    setEvents
  } = props;

  const [openAnimation, setOpenAnimation] = useState(false);
  const [changes, setChanges] = useState(true);
  const [startDate, setStarDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [idFichaPersonal, setIdFichaPersonal] = useState("");
  const [idRegistro, setIdRegistro] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [information, setInformation] = useState({});
  const [editInfo, setEditInfo] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(false);
  // turno extra
  const [valorTurno, setValorTurno] = useState(0);
  const [valorColacion, setValorColacion] = useState(undefined);
  const [valorMovilizacion, setValorMovilizacion] = useState(undefined);
  const [valorViatico, setValorViatico] = useState(undefined);
  const [checkColacion, setCheckColacion] = useState(false);
  const [checkMovilizacion, setCheckMovilizacion] = useState(false);
  const [checkViatico, setCheckViatico] = useState(false);
  // end turno extra
  // atención extra
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  // end atención extra
  // vacaciones
  const [estadoVacaciones, setEstadoVacaciones] = useState("");
  const [ultimoDiaTrabajado, setUltimoDiaTrabajado] = useState(null);
  const [primerDiaTrabajado, setPrimerDiaTrabajado] = useState(null);
  const [totalDias, setTotalDias] = useState("");
  const [gestionar, setGestionar] = useState(false);
  const [filesArray, setFilesArray] = useState([]);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [checkFiles, setCheckFiles] = useState(false);
  const [checkRequest, setCheckRequest] = useState(false);
  const [checkVacations, setCheckVacations] = useState(false);
  const [freeText, setFreeText] = useState("");
  // end vacaciones
  // inasistencia
  const [licencia, setLicencia] = useState("");
  const [amonestacion, setAmonestacion] = useState("");
  const [descuento, setDescuento] = useState("");
  const [adjuntarArchivo, setAdjuntarArchivo] = useState(false);
  const [filesLicencia, setFilesLicencia] = useState([]);
  const [filesAmonestacion, setFilesAmonestacion] = useState([]);
  const [filesDescuento, setFilesDescuento] = useState([]);
  const [checkLicencia, setCheckLicencia] = useState(false);
  const [checkLicenciaFiles, setCheckLicenciaFiles] = useState(false);
  const [checkAmonestacion, setCheckAmonestacion] = useState(false);
  const [checkAmonestacionFiles, setCheckAmonestacionFiles] = useState(false);
  const [checkDescuento, setCheckDescuento] = useState(false);
  const [checkDescuentoFiles, setCheckDescuentoFiles] = useState(false);
  const [checkInasistenciaFunc, setCheckInasistenciaFunc] = useState(false);
  // end inasistencia
  console.log("tempEventInfo", tempEventInfo);

  useEffect(() => {
    if (checkInasistenciaFunc) {
      checkInasistencia();
    }
  });

  const checkInasistencia = () => {
    setCheckInasistenciaFunc(false);
    if (licencia === "si") {
      if (filesLicencia.length > 0) {
        setCheckLicencia(true);
      } else {
        setCheckLicencia(false);
      }
    } else {
      setCheckLicencia(true);
    }
    if (amonestacion === "si") {
      if (filesAmonestacion.length > 0) {
        setCheckAmonestacion(true);
      } else {
        setCheckAmonestacion(false);
      }
    } else {
      setCheckAmonestacion(true);
    }
    if (descuento === "si") {
      if (filesDescuento.length > 0) {
        setCheckDescuento(true);
      } else {
        setCheckDescuento(false);
      }
    } else {
      setCheckDescuento(true);
    }
  };

  const handleLicencia = event => {
    setLicencia(event.target.value);
  };

  const handleAmonestacion = event => {
    setAmonestacion(event.target.value);
  };

  const handleDescuento = event => {
    setDescuento(event.target.value);
  };

  const handleUltimoDiaTrabajado = date => {
    setUltimoDiaTrabajado(date);
  };

  const handlePrimerDiaTrabajado = date => {
    setPrimerDiaTrabajado(date);
  };

  const handleEstadoVacaciones = event => {
    setEstadoVacaciones(event.target.value);
  };

  const handlePatient = patient => {
    setPatient(patient);
  };

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);
    if (tempEventInfo.title === "Vacación") {
      const requestVacations = api.get(
        `api/rrhh/variablesdinamicas/registros/vacaciones/${tempEventInfo.id}/`
      );

      Promise.all([requestVacations])
        .then(response => {
          setStarDate(moment(response[0].data.fecha_hora_inicio));
          setEndDate(moment(response[0].data.fecha_hora_fin).add(1, "hours"));
          setIdFichaPersonal(response[0].data.id_ficha_personal);
          setIdRegistro(response[0].data.id);
          response[0].data.valores.map(value => {
            setVariableValueVacaciones(value.codigo_tipo_valor, value.valor);
          });

        })

    }
    if (tempEventInfo.title === "Permiso sin sueldo") {
      const requestPermission = api.get(
        `api/rrhh/variablesdinamicas/registros/permisosinsueldo/${tempEventInfo.id}/`
      );

      Promise.all([requestPermission])
        .then(response => {
          setStarDate(moment(response[0].data.fecha_hora_inicio));
          setEndDate(moment(response[0].data.fecha_hora_fin).add(1, "hours"));
          setIdFichaPersonal(response[0].data.id_ficha_personal);
          setIdRegistro(response[0].data.id);
          response[0].data.valores.map(value => {
            setVariableValueVacaciones(value.codigo_tipo_valor, value.valor);
          });

        })

    }
    if (tempEventInfo.title === "Inasistencia") {
      const requestAbsence = api.get(
        `api/rrhh/variablesdinamicas/registros/inasistencias/${tempEventInfo.id}`
      );

      Promise.all([requestAbsence])
        .then(response => {
          setStarDate(moment(response[0].data.fecha_hora_inicio));
          setEndDate(moment(response[0].data.fecha_hora_fin).add(1, "hours"));
          setIdFichaPersonal(response[0].data.id_ficha_personal);
          setIdRegistro(response[0].data.id);
          response[0].data.valores.map(value => {
            setVariableValueInasistencia(value.codigo_tipo_valor, value.valor);
          });

        })

    }
  };

  const setVariableValueVacaciones = (name, value) => {
    setChanges(false);
    switch (name) {
      case "UltimoDiaTrabajado":
        setUltimoDiaTrabajado(moment(value));
        break;
      case "PrimerDiaTrabajar":
        setPrimerDiaTrabajado(moment(value));
        break;
      case "DiasPedidos":
        setTotalDias(value);
        break;
      case "EstadoSolicitud":
        setEstadoVacaciones(value);
        break;
      default:
        break;
    }
  };

  const setVariableValueInasistencia = (name, value) => {
    setChanges(false);
    switch (name) {
      case "UltimoDiaTrabajado":
        setUltimoDiaTrabajado(moment(value));
        break;
      case "PrimerDiaTrabajar":
        setPrimerDiaTrabajado(moment(value));
        break;
      case "Licencia":
        setLicencia(value);
        break;
      case "Amonestacion":
        setAmonestacion(value);
        break;
      case "Descuento":
        setDescuento(value);
    }
  };

  const setVariableValueTE = (name, value) => {
    setChanges(false);
    switch (name) {
      case "TurnoExtra":
        setValorTurno(parseInt(value));
        break;
      case "ColacionExtra":
        setValorColacion(parseInt(value));
        break;
      case "ViaticoEspecial":
        setValorViatico(parseInt(value));
        break;
      case "MovilizacionEspecial":
        setValorMovilizacion(parseInt(value));
        break;
      default:
        break;
    }
  };

  const handleCheckColacion = event => {
    setCheckColacion(event.target.checked);
  };

  const handleCheckMovilizacion = event => {
    setCheckMovilizacion(event.target.checked);
  };

  const handleCheckViatico = event => {
    setCheckViatico(event.target.checked);
  };

  const handleStartDate = date => {
    setStarDate(date);
    setTotalDias(
      momentBusinessDays(moment(endDate).add(2, "seconds")).businessDiff(
        moment(date).add(1, "seconds")
      )
    );
  };

  const handleEndDate = date => {
    setEndDate(moment(date).add(2, "seconds"));
    setTotalDias(
      momentBusinessDays(moment(date).add(2, "seconds")).businessDiff(
        moment(startDate).add(1, "seconds")
      )
    );
  };

  const removeFile = index => {
    let tempFilesArray = [...filesArray];
    tempFilesArray.splice(index, 1);
    setFilesArray(tempFilesArray);
    setCheckInasistenciaFunc(true);
  };

  const removeFileLicencia = index => {
    let tempFilesLicencia = [...filesLicencia];
    tempFilesLicencia.splice(index, 1);
    setFilesLicencia(tempFilesLicencia);
    setCheckInasistenciaFunc(true);
  };

  const removeFileAmonestacion = index => {
    let tempFilesAmonestacion = [...filesAmonestacion];
    tempFilesAmonestacion.splice(index, 1);
    setFilesAmonestacion(tempFilesAmonestacion);
    setCheckInasistenciaFunc(true);
  };

  const removeFileDescuento = index => {
    let tempFilesDescuento = [...filesDescuento];
    tempFilesDescuento.splice(index, 1);
    setFilesDescuento(tempFilesDescuento);
    setCheckInasistenciaFunc(true);
  };

  const editRecord = (tipo, titulo) => {
    setOpenAnimation(true);
    let tempInformation = {};
    if (tipo === "turnosextra") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        valor_turno: valorTurno.toString(),
        valor_colacion: valorColacion.toString(),
        valor_movilizacion: valorMovilizacion.toString(),
        viatico_especial: valorViatico.toString()
      };
      if (isNaN(valorColacion)) {
        tempInformation["valor_colacion"] = "0";
      }
      if (isNaN(valorMovilizacion)) {
        tempInformation["valor_movilizacion"] = "0";
      }
      if (isNaN(valorViatico)) {
        tempInformation["viatico_especial"] = "0";
      }
    } else if (tipo === "atencionextra") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        valor_turno: valorTurno.toString(),
        valor_colacion: valorColacion.toString(),
        valor_movilizacion: valorMovilizacion.toString(),
        viatico_especial: valorViatico.toString(),
        id_paciente_id: patient.value
      };
      if (isNaN(valorColacion)) {
        tempInformation["valor_colacion"] = "0";
      }
      if (isNaN(valorMovilizacion)) {
        tempInformation["valor_movilizacion"] = "0";
      }
      if (isNaN(valorViatico)) {
        tempInformation["viatico_especial"] = "0";
      }
    } else if (tipo === "vacaciones") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        ultimo_dia_trabajado: ultimoDiaTrabajado,
        primer_dia_trabajar: primerDiaTrabajado,
        total_dias: totalDias
      };
    } else if (tipo === "permisosinsueldo") {
      tempInformation = {
        fecha_hora_inicio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        ultimo_dia_trabajado: ultimoDiaTrabajado,
        primer_dia_trabajar: primerDiaTrabajado,
        total_dias: totalDias
      };
    } else if (tipo === "inasistencias") {
      tempInformation = {
        fecha_hora_incio: moment(startDate).format("YYYY-MM-DDTHH:mm:01"),
        fecha_hora_fin: moment(endDate).format("YYYY-MM-DDTHH:mm:02"),
        ultimo_dia_trabajado: ultimoDiaTrabajado,
        primer_dia_trabajar: primerDiaTrabajado,
        licencia: licencia,
        amonestacion: amonestacion,
        descuento: descuento
      };
    }
    api
      .patch(
        `api/rrhh/variablesdinamicas/registros/${tipo}/${tempEventInfo.id}/`,
        tempInformation
      )
      .then(() => {
        setMessage("Registro gestionado con éxito");
        setOpenAnimation(false);
        setOpenDialogSucess(true);
      })

  };

  const deleteRecord = tipo => {
    setOpenAnimation(true);
    api
      .delete(
        `api/rrhh/variablesdinamicas/registros/${tipo}/${tempEventInfo.id}/`
      )
      .then(() => {
        api
          .delete(`api/solicitudesrealizadas/${tempRowInfo.id}`)
          .then(response => {
            console.log("response delete", response);
            setMessage("Registro eliminado con éxito");
            // setPageRefresh(true);
            setOpenAnimation(false);
            setOpenDialogSucess(true);
          })

      })

  };

  const saveRecord = () => {
    setOpenAnimation(true);
    if (filesArray.length > 0) {
      let countArray = filesArray.length;
      let count = 0;
      filesArray.map(file => {
        const formData = new FormData();
        formData.append("nombre_archivo", file.name);
        formData.append("descripcion_archivo", "File");
        formData.append("archivo", file);
        formData.append("estado", true);
        formData.append("id_registro_variable", tempEventInfo.id);
        apiform
          .post("api/documentosregistrosvariables/", formData)
          .then(() => {
            count = count + 1;
            if (count === countArray) {
              setCheckFiles(true);
            }
          })

      });
    } else {
      setCheckFiles(true);
    }

    if (approved) {
      const tempInfoVacaciones = {
        estado_solicitud: "Aprobado",
        observaciones: freeText
      };
      api
        .patch(
          `api/rrhh/variablesdinamicas/registros/vacaciones/${tempEventInfo.id}/`,
          tempInfoVacaciones
        )
        .then(() => {
          api
            .put(`api/solicitudesrealizadas/${tempRowInfo.id}`, {
              estado: "Finalizado"
            })
            .then(() => {
              setCheckRequest(true);
            })

          setCheckVacations(true);
        })

    } else if (rejected) {
      api
        .delete(
          `api/rrhh/variablesdinamicas/registros/vacaciones/${tempEventInfo.id}/`
        )
        .then(() => {
          api
            .put(`api/solicitudesrealizadas/${tempRowInfo.id}`, {
              estado: "Rechazado"
            })
            .then(() => {
              setCheckRequest(true);
            })

          setCheckVacations(true);
        })

    }
  };

  const saveRecordInasistencia = () => {
    setOpenAnimation(true);
    if (filesLicencia.length > 0) {
      let countArray = filesLicencia.length;
      let count = 0;
      filesLicencia.map(file => {
        const formData = new FormData();
        formData.append("nombre_archivo", file.name);
        formData.append("descripcion_archivo", "Licencia");
        formData.append("archivo", file);
        formData.append("estado", true);
        formData.append("id_registro_variable", tempEventInfo.id);
        apiform
          .post("api/documentosregistrosvariables/", formData)
          .then(() => {
            count = count + 1;
            if (count === countArray) {
              setCheckLicenciaFiles(true);
            }
          })

      });
    } else {
      setCheckLicenciaFiles(true);
    }
    if (filesAmonestacion.length > 0) {
      let countArray = filesAmonestacion.length;
      let count = 0;
      filesAmonestacion.map(file => {
        const formData = new FormData();
        formData.append("nombre_archivo", file.name);
        formData.append("descripcion_archivo", "Amonestación");
        formData.append("archivo", file);
        formData.append("estado", true);
        formData.append("id_registro_variable", tempEventInfo.id);
        apiform
          .post("api/documentosregistrosvariables/", formData)
          .then(() => {
            count = count + 1;
            if (count === countArray) {
              setCheckAmonestacionFiles(true);
            }
          })

      });
    } else {
      setCheckAmonestacionFiles(true);
    }
    if (filesDescuento.length > 0) {
      let countArray = filesDescuento.length;
      let count = 0;
      filesDescuento.map(file => {
        const formData = new FormData();
        formData.append("nombre_archivo", file.name);
        formData.append("descripcion_archivo", "Descuento");
        formData.append("archivo", file);
        formData.append("estado", true);
        formData.append("id_registro_variable", tempEventInfo.id);
        apiform
          .post("api/documentosregistrosvariables/", formData)
          .then(() => {
            count = count + 1;
            if (count === countArray) {
              setCheckDescuentoFiles(true);
            }
          })

      });
    } else {
      setCheckDescuentoFiles(true);
    }

    const tempInfoSolicitud = {
      estado: "Finalizado"
    };

    api
      .put(`api/solicitudesrealizadas/${tempRowInfo.id}`, tempInfoSolicitud)
      .then(() => {
        setCheckRequest(true);
      })


    const tempInfoInasistencia = {
      estado_solicitud: "Aprobado"
    };
    api
      .patch(
        `api/rrhh/variablesdinamicas/registros/inasistencias/${tempEventInfo.id}/`,
        tempInfoInasistencia
      )
      .then(() => {
        setCheckVacations(true);
      })

  };

  useEffect(() => {
    if (checkFiles && checkRequest && checkVacations) {
      closeInformation();
    }
    if (checkDescuentoFiles && checkAmonestacionFiles && checkLicenciaFiles) {
      closeInformation();
    }
  });

  const closeInformation = () => {
    setCheckFiles(false);
    setCheckRequest(false);
    setCheckVacations(false);
    setCheckDescuentoFiles(false);
    setCheckAmonestacionFiles(false);
    setCheckLicenciaFiles(false);
    setMessage("Registro gestionado con éxito");
    setOpenAnimation(false);
    setOpenDialogSucess(true);
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Paper
        className={classes.paper}
        style={{ maxHeight: "580px", overflow: "auto" }}
      >
        {openAnimation ? (
          <ModalTitle id="customized-dialog-title">
            Tipo de registro: {tempEventInfo.title}
          </ModalTitle>
        ) : (
          <ModalTitle id="customized-dialog-title" onClose={closeModal}>
            Tipo de registro: {tempEventInfo.title}
          </ModalTitle>
        )}
        <DialogContent>
          <Grid container className={classes.root} justify="center">
            <Grid item container xs={12} justify="center">
              {tempEventInfo.title === "Turno extra" ? (
                <TurnoExtra
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  valorTurno={valorTurno}
                  setValorTurno={setValorTurno}
                  valorColacion={valorColacion}
                  setValorColacion={setValorColacion}
                  valorMovilizacion={valorMovilizacion}
                  setValorMovilizacion={setValorMovilizacion}
                  valorViatico={valorViatico}
                  setValorViatico={setValorViatico}
                  NumberFormatCustom={NumberFormatCustom}
                  checkColacion={checkColacion}
                  handleCheckColacion={handleCheckColacion}
                  checkMovilizacion={checkMovilizacion}
                  handleCheckMovilizacion={handleCheckMovilizacion}
                  checkViatico={checkViatico}
                  handleCheckViatico={handleCheckViatico}
                  editInfo={editInfo}
                  tempEventInfo={tempEventInfo}
                />
              ) : tempEventInfo.title === "Atención extra" ? (
                <AtencionExtra
                  startDate={startDate}
                  setStartDate={setStarDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  valorTurno={valorTurno}
                  setValorTurno={setValorTurno}
                  valorColacion={valorColacion}
                  setValorColacion={setValorColacion}
                  valorMovilizacion={valorMovilizacion}
                  setValorMovilizacion={setValorMovilizacion}
                  valorViatico={valorViatico}
                  setValorViatico={setValorViatico}
                  NumberFormatCustom={NumberFormatCustom}
                  checkColacion={checkColacion}
                  handleCheckColacion={handleCheckColacion}
                  checkMovilizacion={checkMovilizacion}
                  handleCheckMovilizacion={handleCheckMovilizacion}
                  checkViatico={checkViatico}
                  handleCheckViatico={handleCheckViatico}
                  editInfo={editInfo}
                  tempEventInfo={tempEventInfo}
                  patient={patient}
                  patients={patients}
                  handlePatient={handlePatient}
                />
              ) : tempEventInfo.title == "Vacación" ? (
                approved || rejected ? (
                  <VacacionesSummary
                    approved={approved}
                    rejected={rejected}
                    totalDias={totalDias}
                    tempRowInfo={tempRowInfo}
                    freeText={freeText}
                    setFreeText={setFreeText}
                  />
                ) : (
                  <Vacaciones
                    startDate={startDate}
                    handleStartDate={handleStartDate}
                    endDate={endDate}
                    handleEndDate={handleEndDate}
                    ultimoDiaTrabajado={ultimoDiaTrabajado}
                    handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
                    primerDiaTrabajado={primerDiaTrabajado}
                    handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
                    totalDias={totalDias}
                    estadoVacaciones={estadoVacaciones}
                    handleEstadoVacaciones={handleEstadoVacaciones}
                    editInfo={editInfo}
                  />
                )
              ) : tempEventInfo.title === "Permiso sin sueldo" ? (
                <PermisoSinSueldo
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  ultimoDiaTrabajado={ultimoDiaTrabajado}
                  handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
                  primerDiaTrabajado={primerDiaTrabajado}
                  handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
                  totalDias={totalDias}
                  estadoVacaciones={estadoVacaciones}
                  handleEstadoVacaciones={handleEstadoVacaciones}
                  editInfo={editInfo}
                />
              ) : tempEventInfo.title === "Inasistencia" ? (
                <Inasistencia
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  ultimoDiaTrabajado={ultimoDiaTrabajado}
                  handleUltimoDiaTrabajado={handleUltimoDiaTrabajado}
                  primerDiaTrabajado={primerDiaTrabajado}
                  handlePrimerDiaTrabajado={handlePrimerDiaTrabajado}
                  totalDias={totalDias}
                  estadoVacaciones={estadoVacaciones}
                  handleEstadoVacaciones={handleEstadoVacaciones}
                  editInfo={editInfo}
                  licencia={licencia}
                  handleLicencia={handleLicencia}
                  amonestacion={amonestacion}
                  handleAmonestacion={handleAmonestacion}
                  descuento={descuento}
                  handleDescuento={handleDescuento}
                  filesLicencia={filesLicencia}
                  filesAmonestacion={filesAmonestacion}
                  filesDescuento={filesDescuento}
                />
              ) : null}
            </Grid>
            <Grid container justify="center">
              {deleteInfo ? (
                <Typography
                  variant="h6"
                  style={{ textTransform: "none", marginTop: "2em" }}
                >
                  ¿Está seguro que desea eliminar este registro?
                </Typography>
              ) : null}
            </Grid>
            <Grid container justify="center">
              {adjuntarArchivo ? (
                <Typography
                  variant="h6"
                  style={{ textTransform: "none", marginTop: "2em" }}
                >
                  ¿Qué tipo de archivo quieres adjuntar?
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {openAnimation ? (
            <CircularProgress />
          ) : (
            <div>
              {tempEventInfo.title === "Vacación" ? (
                tempRowInfo.estado === "Finalizado" ? null : gestionar ? (
                  approved || rejected ? (
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", marginRight: "3px" }}
                        onClick={saveRecord}
                      >
                        Guardar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", marginLeft: "3px" }}
                        onClick={() => {
                          setApproved(false);
                          setRejected(false);
                        }}
                      >
                        Cancelar
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <input
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={e => setFilesArray([...e.target.files])}
                        hidden
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{ textTransform: "none", marginRight: "3px" }}
                        >
                          Adjuntar documento
                        </Button>
                      </label>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", marginRight: "3px" }}
                        onClick={() => setApproved(true)}
                      >
                        Aprobar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", marginRight: "3px" }}
                        onClick={() => setRejected(true)}
                      >
                        Rechazar
                      </Button>
                    </div>
                  )
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none", marginRight: "3px" }}
                    onClick={() => setGestionar(true)}
                  >
                    Gestionar
                  </Button>
                )
              ) : tempEventInfo.title === "Permiso sin sueldo" ? (
                <div>
                  {deleteInfo ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setDeleteInfo(false)}
                      style={{ textTransform: "none", marginRight: "3px" }}
                    >
                      Cancelar
                    </Button>
                  ) : editInfo ? (
                    moment(endDate) >= moment(startDate) ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          editRecord("permisosinsueldo", "Permiso sin sueldo")
                        }
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Guardar
                      </Button>
                    ) : (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", marginRight: "3px" }}
                      >
                        Guardar
                      </Button>
                    )
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setEditInfo(true)}
                      style={{ textTransform: "none", marginRight: "3px" }}
                    >
                      Editar
                    </Button>
                  )}
                  {deleteInfo ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        deleteRecord(
                          "permisosinsueldo",
                          "cantidad_permisos_sin_goce_sueldo"
                        )
                      }
                      style={{ textTransform: "none", marginLeft: "3px" }}
                    >
                      Si
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setDeleteInfo(true)}
                      style={{ textTransform: "none", marginLeft: "3px" }}
                    >
                      Eliminar
                    </Button>
                  )}
                </div>
              ) : tempEventInfo.title === "Inasistencia" ? (
                <div>
                  {adjuntarArchivo ? null : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={closeModal}
                      style={{ textTransform: "none", marginRight: "3px" }}
                    >
                      Atrás
                    </Button>
                  )}
                  {adjuntarArchivo ? (
                    <div>
                      {licencia === "si" ? (
                        <span>
                          <input
                            id="contained-button-file-licencia"
                            multiple
                            type="file"
                            onChange={e => {
                              let tempFiles = filesLicencia;
                              let tempTargetFiles = [...e.target.files];
                              tempTargetFiles.map(file => {
                                tempFiles.push(file);
                              });
                              setFilesLicencia(tempFiles);
                              setCheckInasistenciaFunc(true);
                            }}
                            hidden
                          />
                          <label htmlFor="contained-button-file-licencia">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              style={{
                                textTransform: "none",
                                marginRight: "3px"
                              }}
                            >
                              Licencia
                            </Button>
                          </label>
                        </span>
                      ) : null}
                      {amonestacion === "si" ? (
                        <span>
                          <input
                            id="contained-button-file-amonestacion"
                            multiple
                            type="file"
                            onChange={e => {
                              let tempFiles = filesAmonestacion;
                              let tempTargetFiles = [...e.target.files];
                              tempTargetFiles.map(file => {
                                tempFiles.push(file);
                              });
                              setFilesAmonestacion(tempFiles);
                              setCheckInasistenciaFunc(true);
                            }}
                            hidden
                          />
                          <label htmlFor="contained-button-file-amonestacion">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              style={{
                                textTransform: "none",
                                marginRight: "3px"
                              }}
                            >
                              Carta de amonestación
                            </Button>
                          </label>
                        </span>
                      ) : null}
                      {descuento === "si" ? (
                        <span>
                          <input
                            id="contained-button-file-descuento"
                            multiple
                            type="file"
                            onChange={e => {
                              let tempFiles = filesDescuento;
                              let tempTargetFiles = [...e.target.files];
                              tempTargetFiles.map(file => {
                                tempFiles.push(file);
                              });
                              setFilesDescuento(tempFiles);
                              setCheckInasistenciaFunc(true);
                            }}
                            hidden
                          />
                          <label htmlFor="contained-button-file-descuento">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              style={{
                                textTransform: "none",
                                marginRight: "3px"
                              }}
                            >
                              Descuento
                            </Button>
                          </label>
                        </span>
                      ) : null}
                      {checkLicencia && checkAmonestacion && checkDescuento ? (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                          onClick={saveRecordInasistencia}
                        >
                          Finalizar
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none", marginRight: "3px" }}
                          onClick={saveRecordInasistencia}
                        >
                          Finalizar
                        </Button>
                      )}
                    </div>
                  ) : licencia === "no" &&
                    amonestacion === "no" &&
                    descuento === "no" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ textTransform: "none", marginRight: "3px" }}
                      onClick={saveRecordInasistencia}
                    >
                      Finalizar
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setAdjuntarArchivo(true)}
                      style={{ textTransform: "none", marginRight: "3px" }}
                    >
                      Adjuntar archivos
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
          )}
        </DialogActions>
        <Grid
          item
          container
          xs={12}
          justify="flex-start"
          style={{ marginTop: "1em" }}
        >
          <Grid item xs={2} />
          <Grid item container xs={9}>
            {approved || rejected
              ? null
              : filesArray.map((file, index) => (
                  <ListItem key={index}>
                    {file?.name}
                    <ListItemIcon>
                      <CancelIcon
                        color="primary"
                        className={classes.deleteFile}
                        onClick={() => removeFile(index)}
                      />
                    </ListItemIcon>
                  </ListItem>
                ))}
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justify="flex-start"
          style={{ marginTop: "1em" }}
        >
          <Grid item xs={2} />
          <Grid item container xs={9}>
            {openAnimation ? null : adjuntarArchivo ? (
              <div>
                {filesLicencia.map((file, index) => (
                  <ListItem key={index}>
                    {file?.name}-Licencia
                    <ListItemIcon>
                      <CancelIcon
                        color="primary"
                        className={classes.deleteFile}
                        onClick={() => removeFileLicencia(index)}
                      />
                    </ListItemIcon>
                  </ListItem>
                ))}
                {filesAmonestacion.map((file, index) => (
                  <ListItem key={index}>
                    {file?.name}-Amonestación
                    <ListItemIcon>
                      <CancelIcon
                        color="primary"
                        className={classes.deleteFile}
                        onClick={() => removeFileAmonestacion(index)}
                      />
                    </ListItemIcon>
                  </ListItem>
                ))}
                {filesDescuento.map((file, index) => (
                  <ListItem key={index}>
                    {file?.name}-Descuento
                    <ListItemIcon>
                      <CancelIcon
                        color="primary"
                        className={classes.deleteFile}
                        onClick={() => removeFileDescuento(index)}
                      />
                    </ListItemIcon>
                  </ListItem>
                ))}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Paper>
    </Modal>
  );
};

export default ModalViewRecord;

ModalViewRecord.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempDateInfo: PropTypes.string,
  tempEventInfo: PropTypes.object,
  tempRowInfo: PropTypes.object,
  setOpenDialogSucess: PropTypes.func,
  setMessage: PropTypes.func,
  setPageRefresh: PropTypes.func,
  variableCounter: PropTypes.object,
  setVariableCounter: PropTypes.func,
  eventsVariables: PropTypes.array,
  setEvents: PropTypes.func
};
