import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";

import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DetailText from "views/Widgets/FichaPersonal/EditFichaPersonal/detailText";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import 'date-fns';

import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';



export default function ModalSetPurchase(props) {
  const { open, closeModal, id_producto, id_detalle, productos_restantes, id_order } = props;


  const [modalMessage, setModalMessage] = useState(false);
  const [provider, setProvider] = useState(props.providerName);
  const [adress, setAdress] = useState(props.providerAdress);
  const [contact, setContact] = useState(props.providerContact);
  const [phone, setPhone] = useState(props.providerPhone);
  const [products, setProducts] = useState(props.providerProducts);
  const [comments, setComments] = useState(props.providerComments);
  const [evaluation, setEvaluation] = useState(props.providerEvaluation);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  function updateProvider() {

    const formData = new FormData();

    formData.append("nombre", provider);
    formData.append("direccion", adress);
    formData.append("contacto", contact);
    formData.append("telefono", phone);
    formData.append("producto_especialidad", products);
    formData.append("comentarios", comments);
    formData.append("evaluacion", evaluation);

    apiform
      .put(`api/proveedores/${props.providerId}`, formData)
      .then(response => {
        console.log(response);
        window.location.href = "/admin/purchases/providers/";
      })

  }

  function deleteProvider() {


    apiform
      .delete(`api/proveedores/${props.providerId}`)
      .then(response => {
        console.log(response);
        window.location.href = "/admin/purchases/providers/";
      })

  }

  const handleModal = (close, open) => {
    setModalMessage(open);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Editar proveedor</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div className="form" style={{ marginBottom: "3rem" }}>
          <DetailText
            label="Nombre proveedor:"
            placeholder="Proveedor"
            value={provider}
            onchange={e => {
              setProvider(e.target.value);
            }}
          />
          <DetailText
            label="Dirección:"
            placeholder="Dirección"
            value={adress}
            onchange={e => {
              setAdress(e.target.value);
            }}
          />
          <DetailText
            label="Contacto:"
            placeholder="Contacto"
            value={contact}
            onchange={e => {
              setContact(e.target.value);
            }}
          />
          <DetailText
            label="Teléfono:"
            placeholder="Teléfono"
            value={phone}
            onchange={e => {
              setPhone(e.target.value);
            }}
          />
          <DetailTextArea
            label="Productos que venden:"
            placeholder="Productos que venden"
            value={products}
            maxlength="500"
            onchange={e => {
              setProducts(e.target.value);
            }}
          />
          <DetailTextArea
            label="Comentarios:"
            placeholder="Comentarios"
            value={comments}
            maxlength="500"
            onchange={e => {
              setComments(e.target.value);
            }}
          />
          <div className="ml-4">
            <Box component="fieldset" mb={3} borderColor="transparent">
              <Typography component="legend">Evaluación</Typography>
              <Rating
                name="simple-controlled"
                value={evaluation}
                onChange={(event, newValue) => {
                  setEvaluation(newValue);
                }}
              />
            </Box>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Cancelar
        </Button>
        {/* <Button onClick={() => handleModal(closeModal, true)} color="primary"> */}
        <Button onClick={updateProvider} color="primary">
          Guardar cambios
        </Button>
        <Button onClick={deleteProvider} color="primary">
          Eliminar proveedor
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalSetPurchase.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_producto: PropTypes.number,
  id_detalle: PropTypes.number,
  productos_restantes: PropTypes.array
};
