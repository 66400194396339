import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const VacacionesSummay = props => {
  const { approved, rejected, totalDias, freeText, setFreeText } = props;

  return (
    <Grid container justify="flex-start" style={{ marginTop: "2em" }}>
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
        style={{ marginBottom: "1em" }}
      >
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <TextField
            id="outlined-basic"
            label="Comentario"
            variant="outlined"
            value={freeText}
            onChange={e => setFreeText(e.target.value)}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Status:
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none", fontWeight: "bold" }}
          >
            {approved ? "Aprobado" : rejected ? "Rechazado" : null}
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Se notificará al encargado(a) y a la persona.
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none", fontWeight: "bold" }}
          >
            
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="flex-start">
        <Grid item xs={2} />
        <Grid item container xs={9}>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Días {approved ? "Aprobados: " : rejected ? "Rechazados: " : null}
            {totalDias}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textTransform: "none", fontWeight: "bold" }}
          >
            
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  );
};

export default VacacionesSummay;

VacacionesSummay.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  approved: PropTypes.bool,
  rejected: PropTypes.bool,
  totalDias: PropTypes.string,
  freeText: PropTypes.string,
  setFreeText: PropTypes.func
};
