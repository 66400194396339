import React from "react";

export const useSortableDataFinalized = (itemsFinalized, config = null) => {
  const [sortConfigFinalized, setSortConfigFinalized] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...itemsFinalized];
    if (sortConfigFinalized !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfigFinalized.key] < b[sortConfigFinalized.key]) {
          return sortConfigFinalized.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigFinalized.key] > b[sortConfigFinalized.key]) {
          return sortConfigFinalized.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [itemsFinalized, sortConfigFinalized]);

  const requestSortFinalized = key => {
    let direction = "ascending";
    if (
      sortConfigFinalized &&
      sortConfigFinalized.key === key &&
      sortConfigFinalized.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfigFinalized({ key, direction });
  };

  return {
    itemsFinalized: sortedItems,
    requestSortFinalized,
    sortConfigFinalized
  };
};
