import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {
    ArrowForward,
} from "@material-ui/icons/";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
    green, indigo,
} from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import {RadioButtonUnchecked, CheckCircle } from "@material-ui/icons/";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

export default function ModalEditBenefit({
    open,
    handleClose,
    row,
    handleUpdateRow,
}) {
    const classes = useStyles();

    const [amount, setamount] = useState(row.cantidad_efectuada_modificado);
    const [value, setvalue] = useState(row.valor_unitario_modificado);
    const [tempValue, setTempValue] = useState(row.valor_unitario_modificado);
    const [valueTotal, setvalueTotal] = useState(row.cantidad_efectuada_modificado*row.valor_unitario_modificado);
    const [hideValue, sethideValue] = useState(row.ocultar);

    const handleOnChange = (value, set) => {
        let tempValue =  isNaN(value) ? 0 : value;
        set(tempValue);
    };

    const handleUpdate = () => {
        handleUpdateRow(row, amount, tempValue, hideValue);
        handleClose();
    };

    const handleOnChangeValue = (valueArg) => {
        setvalue(valueArg)
        setTempValue(valueArg)
        setvalueTotal(valueArg*amount)
    }

    const handleOnChangeTotalValue = (valueArg) => {
        setvalue(parseInt(valueArg/amount))
        setTempValue(valueArg/amount)
        setvalueTotal(valueArg)
    }

    const listItem = [
        {
            labelInitial: "Cantidad original",
            initialValue: row.cantidad_efectuada_inicial,
            labelModify: "Cantidad Modificada",
            modifyValue: amount,
            onChangeModify: e => handleOnChange(parseInt(e.target.value.split('$').join('').split(',').join('')),setamount)

        },
        {
            labelInitial: "Valor original",
            initialValue: row.valor_unitario_inicial,
            labelModify: "valor Modificado",
            modifyValue: value,
            onChangeModify: e => handleOnChangeValue(parseInt(e.target.value.split('$').join('').split(',').join('')),setvalue),
            prefix:"$"
        },
        {
            labelInitial: "Valor Total original",
            initialValue: row.cantidad_efectuada_inicial*row.valor_unitario_inicial,
            labelModify: "Valor Total Modificado",
            modifyValue: valueTotal,
            onChangeModify: e => handleOnChangeTotalValue(parseInt(e.target.value.split('$').join('').split(',').join('')),setvalueTotal),
            prefix:"$"

        },
    ]

    const _content_ =  <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <Grid
                    item
                    xs={6}
                >
                    <Typography variant="body2" gutterBottom>{`Código: ${row.codigo_prestacion}`}</Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <Typography variant="body2" gutterBottom>{`Prestación efectuada: ${row.prestacion_efectuada}`}</Typography>
                </Grid>
            </Grid>
            {listItem.map((item) => (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <Grid
                    item
                    xs={5}
                    style={{margin: "10px 0"}}
                >
                    <DetailNumber
                        isdisabled={true}
                        label={item.labelInitial}
                        value={item.initialValue}
                        thousandSeparator={true}
                        prefix={item.prefix || ""}
                    />

                </Grid>
                <Grid
                    item
                    xs={2}
                    style={{margin: "10px 0"}}
                >
                    <Button
                        justIcon
                        round
                        color="primary"
                    >
                        <ArrowForward className={classes.icons} />
                    </Button>

                </Grid>
                <Grid
                    item
                    xs={5}
                    style={{margin: "10px 0"}}
                >
                    <DetailNumber
                        label={item.labelModify}
                        value={item.modifyValue}
                        onchange={e => item.onChangeModify(e)}
                        thousandSeparator={true}
                        prefix={item.prefix || ""}
                    />
                </Grid>
            </Grid>
            ))}
            <Grid item container xs={12} justify="center" alignContent="flex-start">
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            checked={hideValue}
                            onClick={() => sethideValue(!hideValue)}
                            checkedIcon={
                                <CheckCircle style={{fill: indigo[500]}} />
                            }
                            icon={<RadioButtonUnchecked style={{fill: indigo[500]}} />}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                label="Ocultar"
                />
            </Grid>
        </Grid>
    </div>

    const _actions_ = <>
        {(amount && value >= 0) ?
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: green[500]}}
            onClick={handleUpdate}
        >
            Guardar
        </Button> :
        <Button
            disabled
            mr={2}
            variant="contained"
            className="text-white"
        >
            Guardar
        </Button>
        }
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            color="primary"
            onClick={handleClose}
        >
            Cerrar
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Editar registro`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="sm"
        />
    );
}