import React, { useState, useEffect } from "react";
import { Info } from "@material-ui/icons/";
import CardStatistics from "views/Widgets/FichaPaciente/CardStatistics";
import { useSelector, useDispatch } from 'react-redux';
import "moment/locale/es";
import Grid from "@material-ui/core/Grid";


export default function Indicator({totalValueNew=0, valuePackage=0, setFlagAlert=() => {}, setFlagAlertSending=() => {}}) {

  const dispatch = useDispatch()

  const total = useSelector(state => state.currentGlobalVar.TotalValueMonthConsume);
  const totalPackage = useSelector(state => state.currentGlobalVar.TotalValuePackageConsume);
  const valueUpdateTemp = useSelector(state => state.currentGlobalVar.ValueUpdate);
  const viewQuotation = useSelector(state => state.currentGlobalVar.patientPackage);
  const quotationDiscount = useSelector(state => state.currentGlobalVar.quotationDiscount);
  const typeQuotationDiscount = useSelector(state => state.currentGlobalVar.typeQuotationDiscount);
  const validateAmountMedicalResourse = (
    ["ParticularServicios", "ParticularHD"].includes(viewQuotation?.tipo_paciente) ||
    ["ParticularServicios", "ParticularHD"].includes(viewQuotation?.typePatient) ||
    viewQuotation?.tipo_permanencia == "Transitorio" ||
    viewQuotation?.typeStay == "Transitorio" ||
    // viewQuotation?.id_convenio_isapre?.nombre == "Fondo Nacional de Salud" ||
    // viewQuotation?.forecast[0]?.nombre == "Fondo Nacional de Salud" ||
    (viewQuotation?.packagingInfo?.insumos == 0 && viewQuotation?.packagingInfo?.medicamentos == 0)
  )

  console.log(viewQuotation)

  useEffect(() => {
    if (totalValueNew > 0 || valuePackage > 0) {
    }
  }, [])

  useEffect(() => {
    if (medicalResourseTotal > 0) {
      dispatch({ payload: medicalResourseTotal, type: 'TOTAL_VALOR_PAQUETE' })
    }
  })

  const medicalResourseTotal = viewQuotation
    ? viewQuotation.packagingInfo.insumos + viewQuotation.packagingInfo.medicamentos
    : 0;

  const monthTotal = viewQuotation
    ? viewQuotation.packagingInfo.total_mes
    : 0;
  const exemptTotal = viewQuotation
    ? viewQuotation.packagingInfo.total_excento
    : 0;
  const dayBed = viewQuotation
    ? viewQuotation.packagingInfo.dia_cama
    : 0;

  let mRTotal = 0
  if (viewQuotation?.packagingInfo?.insumos == 0 && viewQuotation?.packagingInfo?.medicamentos == 0 ){
    mRTotal = parseInt(total) + parseInt(valueUpdateTemp) + parseInt(totalValueNew)
  } else {
    mRTotal = parseInt(total) + parseInt(valueUpdateTemp) + parseInt(totalValueNew) + parseInt(valuePackage)
  }


  const tempValuePercentage = (100 + (typeQuotationDiscount ==="Descuento" ? -1 : 1)*quotationDiscount)/100
  setFlagAlert(validateAmountMedicalResourse ? valuePackage*tempValuePercentage : (medicalResourseTotal - valuePackage*tempValuePercentage))
  setFlagAlertSending(validateAmountMedicalResourse ? totalValueNew : medicalResourseTotal - (total-totalValueNew)*tempValuePercentage)

  const CardTotal = <CardStatistics
    amountTotal={validateAmountMedicalResourse ? mRTotal : medicalResourseTotal}
    amountBase={validateAmountMedicalResourse ? 0 : (total+totalValueNew)*tempValuePercentage}
    title={"Insumos / Medicamentos"}
    subTitle={"Recursos por asignar próximo envío"}
    subTitleError={"Recursos excedidos"}
    IconSub={Info}
  />


  return (
    <Grid direction="row" justify="center" alignItems="center" item container xs={12} >
      <Grid direction="row" justify="center" alignItems="center" item container xs={(!totalValueNew && !valuePackage) ? 9 : 7} >
        <Grid item xs={4}  style={{ padding: "1%" }}>
          <CardStatistics amountTotal={dayBed} title={"Día cama"} subTitle={"Recursos asignados"} IconSub={Info} />
        </Grid>
        <Grid item xs={4} style={{ padding: "1%" }}>
          <CardStatistics amountTotal={exemptTotal} title={"Total exento"} subTitle={"Recursos asignados"} IconSub={Info} />
        </Grid>
        <Grid item xs={4} style={{ padding: "1%" }}>
          <CardStatistics amountTotal={monthTotal} title={"Total mes"} subTitle={"Recursos asignados"}  IconSub={Info} />
        </Grid>
      </Grid>
      {(!validateAmountMedicalResourse && !totalValueNew && !valuePackage) &&
        <Grid item xs={3} style={{ padding: "1%" }}>
          <CardStatistics
            amountTotal={validateAmountMedicalResourse ? mRTotal : medicalResourseTotal}
            amountBase={validateAmountMedicalResourse ? 0 : mRTotal }
            title={"Insumos / Medicamentos"}
            subTitle={"Recursos por asignar"}
            subTitleError={"Recursos excedidos"}
            IconSub={Info}
          />
        </Grid>
      }
      {(validateAmountMedicalResourse) &&
        <Grid item xs={3} style={{ padding: "1%" }}>
          {CardTotal}
        </Grid>
      }
      {(!validateAmountMedicalResourse && (valuePackage || totalValueNew)) &&
      <Grid direction="row" justify="center" alignItems="stretch" item container xs={5} >
        <Grid item container xs={6} style={{ padding: "1%" }}>
          <CardStatistics
            amountTotal={validateAmountMedicalResourse ? valuePackage*tempValuePercentage : medicalResourseTotal}
            amountBase={validateAmountMedicalResourse ? 0 : valuePackage*tempValuePercentage}
            title={"Insumos / Medicamentos"}
            subTitle={"Recursos por asignar en paquete"}
            subTitleError={"Recursos excedidos"}
            IconSub={Info}
          />
        </Grid>
        <Grid item  xs={6} style={{ padding: "1%" }}>
          {CardTotal}
        </Grid>

      </Grid>
      }
    </Grid>
  );
}
