import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { orange, blue, green, indigo } from "@material-ui/core/colors";
import {
  Typography,
  Grid,
  Tooltip,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {
  ArrowDropDown,
  ArrowDropUp,
} from "@material-ui/icons";

import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStylesTable = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});
const useStyles = makeStyles(styles);

// eslint-disable-next-line react/prop-types
export default function PersonalRemunerationsTable({
  dataArray,
  setdata,
  dictChecked,
  handleDetail,
  handleChange,
  validationAFPTax,
}) {
  const classes = useStyles();
  const classesTable = useStylesTable();
  const [order, setorder] = useState("Fecha");
  const [direction, setdirection] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleOrder = (value, attribute) => {
    let tempDirection = direction;
    if (value === order) {
      tempDirection = !direction;
    } else {
      tempDirection = false;
    }
    if (tempDirection) {
      setdata(dataArray.sort((a, b) => (a[attribute] < b[attribute] ? 1 : -1)));
    } else {
      setdata(dataArray.sort((a, b) => (a[attribute] > b[attribute] ? 1 : -1)));
    }
    setorder(value);
    setdirection(tempDirection);
  }

  const headerColumn = (
    atributteName,
    headerName,
    style = {},
  ) => {
    return (
      <Tooltip
        title={
          <React.Fragment>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography variant="body2" gutterBottom>
                {`Ordenar por ${headerName}`}
              </Typography>
            </Grid>
          </React.Fragment>
        }
      >
        <TableCell
          className="text-center align-top"
          style={{ minWidth: "85px", ...style }}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              item
              xs={10}
              onClick={() => handleOrder(headerName, atributteName)}
            >
              {headerName} 
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              item
              xs={2}
              onClick={() => handleOrder(headerName, atributteName)}
            >
              {order === headerName && (
                <ArrowDropUp
                  style={{
                    margin: "-15px 0 -15px 0",
                    fill: indigo[direction ? 900 : 200]
                  }}
                />
              )}
              <ArrowDropDown
                style={{
                  margin: order === headerName ? "0 0 -15px 0" : "0",
                  fill: indigo[order === headerName && !direction ? 900 : 200]
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
      </Tooltip>
    );
  };

  return (
    <Paper className={classesTable.root}>
      <TableContainer className={classesTable.container} style={{height: "440px"}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumn(
                "nombre_paciente",
                "Nombre personal",
                { width: "200px" },
              )}
              {headerColumn(
                "empresa",
                "Empresa",
                { minWidth: "170px" },
              )}
              {headerColumn(
                "estado_vigencia",
                "Estado",
                { minWidth: "170px" },
              )}
              <TableCell className="text-center align-top">
                Accion 
              </TableCell>
              <TableCell className="text-center align-top">
                Seleccionar 
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataArray
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={`${index}-workRows`}
                >
                  <TableCell className="text-center align-middle">
                    {`${row.nombres} ${row.apellido_paterno} ${row.apellido_materno}`}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.empresa}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {row.estado_vigencia}
                  </TableCell>
                  <TableCell className="text-center align-middle">
                    {(validationAFPTax && row.id_lista_afp) ?
                    <Button
                      style={{ background: indigo[500] }}
                      className="text-white"
                      onClick={() => handleDetail(row)}
                    >
                      Detalle
                    </Button> :
                    <Button
                      disabled
                    >
                      {row.id_lista_afp ?  "Detalle" : "Sin AFP asociada"}
                    </Button>
                    }
                  </TableCell>
                  <TableCell className="text-center align-middle">
                      <Checkbox
                        disabled={!(validationAFPTax && row.id_lista_afp)}
                        value="remember"
                        checked={dictChecked[row.id] ? true : false}
                        onChange={event =>
                          handleChange(event.target.checked, row)
                        }
                        color="primary"
                      />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dataArray.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "Filas por página" },
          native: false
        }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `más que ${to}`
          }`
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      
    </Paper>
  );
}
