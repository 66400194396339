import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import decode from 'jwt-decode'
import api from "../utils/API";
import Typography from '@material-ui/core/Typography';
import Logo from "./Logo.jpeg";
import Fondo from "./fondo.jpg";
import { green } from '@material-ui/core/colors';
import ModalRecoverPassword from "components/Login/ModalRecoverPassword";
import ModalDialog from "views/Widgets/Common/ModalDialog";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  image: {
    //backgroundImage: "url(https://venezolanoenchile.com/wp-content/uploads/2016/09/medicos-extranjeros-en-chile.jpg)",
    backgroundImage: `url(${Fondo})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  attendance: {
    color: "#fff",
    contrastText: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: green[500]
      }
    }
  },
  attendanceBox: {
    paddingTop: "10px"
  },
  forgotPasswordButton: {
    color: "#3F51B5",
    border: "1px solid #3F51B5",
    backgroundColor: "rgba(63, 81, 181, 0.08)"
  }
}));

export default function Login() {
  const classes = useStyles();

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [requestMessage, setrequestMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [openRecoverySuccess, setOpenRecoverySucees] = useState(false);
  const [RUT, setRUT] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseRecovery = () => {
    setOpenRecoverySucees(false);
  };

  useEffect(() => {
    localStorage.clear();
    clearCacheData();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    api.post("appauth/token/obtain/", {
        username: username,
        password: password,
      })
      .then(response => {
        if (response.data.access) {
          localStorage.setItem('access', response.data.access);
          localStorage.setItem('refresh', response.data.refresh);
          localStorage.setItem('username', username);
          const user = decode(response.data.access);
          const ficha = user.id_ficha
          if (ficha["Error"]) {
            localStorage.setItem('error_ficha', ficha["Mensaje"]);
          } else {
            localStorage.setItem('id_ficha', user.id_ficha);
          }
          localStorage.setItem('area_perfil', user.area_perfil);
          localStorage.setItem("correo", user.correo);
          localStorage.setItem('first_name', user.first_name);
          localStorage.setItem('last_name', user.last_name);
          localStorage.setItem('tipo_perfil', user.tipo_perfil);
          localStorage.setItem('id_cargo', user.id_cargo);
          localStorage.setItem('user_id', user.user_id);
          localStorage.setItem('permisos',  JSON.stringify(user.permisos_cuenta));
          if (user.tipo_perfil === "Asistencia") {
            window.location.href = `/admin/attendance/`;
          } else {
            window.location.href = `/admin/dashboard/`;
          }
        }

      })
      .catch(() => {
        alert("Usuario o contraseña incorrectos");
      });
  };

  const callRecoveryPassword = () => {
    setOpen(false);

    api.post("appauth/recuperar/pass/", {RUT: RUT})
      .then((response) => {
        const emails = response.data.emails
        const tempMessage = <>
          <h3>Enlace enviado</h3>
          <p>Te enviamos un correo con un enlace para reestablecer tu contraseña a <strong>{emails}</strong>. Si no lo ves en tu bandeja de entrada revisa la carpeta de correo no deseado.</p>
        </>
        setrequestMessage(tempMessage)

        setOpenRecoverySucees(true)
      }).catch((error) => {
        setrequestMessage(<p>{error.response?.data?.error}</p>)
        setOpenRecoverySucees(true)
      });
  };



  const _contentEndRecover_ = <div className="form-row center">
    {requestMessage}
  </div>

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />{" "}
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img
            alt="Qries"
            className={classes.avatar}
            src={Logo}
            width="150"
            height="180"
          />
          <Typography component="h1" variant="h5">
            Inicio de sesión
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Usuario"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Iniciar Sesión
            </Button>
            <div className="d-flex justify-content-center">
              <Button onClick={handleClickOpen}>Olvidé mi contraseña</Button>
            </div>
          </form>
        </div>
      </Grid>
      <ModalRecoverPassword
        open={open}
        handleClose={handleClose}
        handleRut={setRUT}
        callRecoveryPassword={callRecoveryPassword}
        RUT={RUT}
      />
      <ModalDialog
        open={openRecoverySuccess}
        onClose={handleCloseRecovery}
        title={"Olvidé mi contraseña"}
        _content_={_contentEndRecover_}
      />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
    </Grid>
  );
}