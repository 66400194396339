import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "5em"
  }
});

const SearchRequest = props => {
  const classes = useStyles();
  const { search, setSearch } = props;

  return (
    <Grid container className={classes.root}>
      <Grid item container xs={12} justify="center">
        <Grid item xs={4} />
        <Grid item container xs={4} justify="center">
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <TextField
              id="outlined-disabled"
              label="Buscar"
              variant="outlined"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item container xs={4} justify="center">
          {/*
          <Link
            href="/admin/"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
          */}
            <Button
              disabled
              variant="contained"
              color="primary"
              style={{ textTransform: "none" }}
            >
              Solicitar contratación
            </Button>
          {/*
          </Link>
          */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchRequest;

SearchRequest.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func
};
