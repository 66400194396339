import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import api from "utils/API";
import TableDevices from "./TableDevices";



const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default function ModalDispositivos(props) {
  const { open, closeModal, dataPatient,listassigned,dataFile} = props;
  const [changes, setChanges] = useState(true);
  const [getPatientDevices, setGetPatientDevices] = useState([]);
  const [getPatientSupplies, setGetPatientSupplies] = useState([]);


  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);
    const getPatientDevices = api.get(
      `api/paciente/${dataPatient.id}/dispositivos/`
    );
    const getPatientSupplies = api.get(
      `api/paciente/${dataPatient.id}/insumosequipos/`
    );

    Promise.all([getPatientDevices, getPatientSupplies])
      .then(response => {
        setGetPatientDevices(response[0].data);
        setGetPatientSupplies(response[1].data);
      })
      .catch(() => {
        setGetPatientSupplies([]);
        setGetPatientDevices([]);
      });
  };

  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
        disableBackdropClick
        scroll="paper"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          align="center"
        >
          Dispositivos
        </DialogTitle>
        <DialogContent scroll={"paper"}>
          <TableDevices
            getPatientDevices={getPatientDevices}
            getPatientSupplies={getPatientSupplies}
            setChanges={setChanges}
            listassigned={listassigned}
            dataFile={dataFile}
          />
        </DialogContent>

      </Dialog>
    </div>
  );
}

ModalDispositivos.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  dataPatient: PropTypes.object
};
