import React, { useState } from 'react'
import moment from "moment-timezone";
import "moment/locale/es";
import api from 'utils/API';

import { Calendar, momentLocalizer } from "react-big-calendar";
import AppDialog from 'views/Widgets/AppDialog';
import HomeStockDetail from './HomeStockDetail';
import { TypographyMui } from 'utils/componentsStyle';

import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

moment.locale("es");
const localizer = momentLocalizer(moment);

const eventColors = (event) => {
  return {
    className: event.estado_solicitud_farmacia && event.estado_solicitud_insumo ? `event-lightGreen` : `event-yellow`,
  }
};

const useStyles = makeStyles({
  upperCase: {
    textTransform: 'uppercase',
  },
})

function HomeStockCalendar({ open, stockCalendarList, setSpiner }) {
  const classes = useStyles()
  const [selectDate, setselectDate] = useState(moment())
  const [calendarView, setCalendarView] = useState("month");
  const [dialogData, setDialogData] = useState({ isOpen: false, data: { stockDetailList: [] } })

  const openDialog = (stock) => {
    setSpiner()
    api.get(`api/solicitudespedidos/${stock.id}/productospedido/`).then(response => {
      setSpiner()
      const data = {
        stockDetailList: response.data,
        ...stock
      }
      setDialogData({ isOpen: true, data })
    })
  }

  const handleNavigation = (date) => {
    setselectDate(moment(date));
  }

  const CustomToolbar = () => {
    return (
      <Box mt={3}>
        <Box display='flex' justifyContent='center'>
          <Button variant='contained' color={calendarView === 'month' ? 'primary' : 'default'} onClick={() => setCalendarView("month")}>
            Mensual
          </Button>
          <Button variant='contained' color={calendarView === 'month' ? 'default' : 'primary'} onClick={() => setCalendarView("week")}>
            Semanal
          </Button>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          {calendarView === 'month' ?
            <TypographyMui className='mr-3' variant='h4'>
              <strong className={classes.upperCase}>{moment(selectDate).format('MMMM')}</strong> {moment(selectDate).format('YYYY')}
            </TypographyMui>
            :
            <TypographyMui className='mr-3' variant='h4'>
              {`${moment(selectDate).format('MMMM')} ${moment(selectDate).startOf("week").format("DD")} - ${moment(selectDate).endOf("week").format("DD")}`}
            </TypographyMui>
          }

          <Box display='flex' justifyContent='flex-end' mt={1.5}>
            <IconButton className='mr-2' onClick={() => handleNavigation(moment(selectDate).subtract(1, calendarView))}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton className='mr-2' onClick={() => handleNavigation(moment())}>
              <TypographyMui className='mr-3' variant='buttton'>
                hoy
              </TypographyMui>
            </IconButton>
            <IconButton className='mr-2' onClick={() => handleNavigation(moment(selectDate).add(1, calendarView))}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box display={open === 1 ? 'block' : 'none'}>
      <Box width='100%' my={2} display='flex' alignItems='center'>
        <Box display='flex' alignItems='center' mt={3} mr={2.5}>
          <Box width={15} height={15} borderRadius="50%" mr={1} style={{ backgroundColor: '#FFE082' }} />
          <span>En proceso</span>
        </Box>
        <Box display='flex' alignItems='center' mt={3}>
          <Box width={15} height={15} borderRadius="50%" mr={1} style={{ backgroundColor: '#A5D6A7' }} />
          <span>Finalizado</span>
        </Box>
      </Box>

      <Calendar
        selectable
        localizer={localizer}
        events={stockCalendarList}
        startAccessor="start"
        endAccessor="end"
        defaultView="month"
        eventPropGetter={eventColors}
        view={calendarView}
        defaultDate={moment().toDate()}
        date={selectDate.toDate()}
        onSelectEvent={event => openDialog(event)}
        step={30}
        timeslots={1}
        components={{
          toolbar: CustomToolbar,
        }}
      //onNavigate={(date) => handleNavigation(date)}
      // messages={{
      //   next: "Siguiente",
      //   previous: "Anterior",
      //   today: "Hoy",
      //   month: "Mes",
      //   week: "Semana",
      //   day: "Día",
      //   agenda: false,
      // }}
      //onView={(view) => setcalendarView(view)}
      //onSelectSlot={slotInfo => { console.log('slot') }}
      //style={{ height: 500 }}
      //onSelectEvent: fire when an event in clicked
      // min={moment(moment().format("YYYY-MM-DD")).add(8,"hours").toDate()}
      // max={moment(moment().format("YYYY-MM-DD")).add(23,"hours").add(30, "minutes").toDate()}
      />

      <AppDialog fullScreen title={`Solicitud Nº${dialogData.data.id}`} open={dialogData.isOpen} onClose={() => setDialogData({ isOpen: false, data: { stockDetailList: [] } })}>
        <HomeStockDetail dialogData={dialogData} />
      </AppDialog>
    </Box>
  )
}

export default HomeStockCalendar
