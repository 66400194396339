import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
} from '@material-ui/core';
import { green, purple } from '@material-ui/core/colors';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import moment from "moment-timezone";
import "moment/locale/es";
import SendIcon from '@material-ui/icons/Send';
import ModalDialog from "views/Widgets/Common/ModalDialog";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  attendance: {
    color: '#fff',
    contrastText: '#fff',
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: green[500]
      }
    },
  },
  attendanceBox: {
    paddingTop: '10px',
  },
  forgotPasswordButton: {
    color: '#3F51B5',
    border: '1px solid #3F51B5',
    backgroundColor: 'rgba(63, 81, 181, 0.08)'
  },
}));

export default function ModalRecoverPassword({ open, handleClose,handleRut, callRecoveryPassword, RUT}) {
  const classes = useStyles();

  const _content_ = <>
     <p>
        Ingresa tu RUT (ej: 12345678-9) y te enviaremos un enlace para que puedas restablecer tu contraseña.
      </p>
      <TextField
        className="mt-3 w-100"
        variant="outlined"
        required
        id="rut"
        label="rut"
        autoComplete="rut"
        autoFocus
        onChange={e => handleRut(e.target.value)}
      />
      <Button className={`mt-4 mb-4 d-block w-100 ${classes.forgotPasswordButton}`} variant='contained' onClick={callRecoveryPassword} >
        <SendIcon color="primary" /> Enviar enlace
      </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      title={"Olvidé mi contraseña"}
      _content_={_content_}
    />
  );
}
