import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import api from "utils/API";
import { days_open } from "components/Request/ManageRequest/DaysOpen";
import moment from "moment";

const Orders = props => {
  const [order, setOrder] = useState([]);
  const [patients, setPatients] = useState([]);
  const [supplies, setSupplies] = useState([]);

  useEffect(() => {
    api.get(`api/solicituddevolucionesingresos/${props.id}`).then(result => {
      setOrder(result.data);
    });
    api.get("api/paciente/").then(result => {
      setPatients(result.data);
    });
    api.get("api/insumosmedicamento").then(result => {
      setSupplies(result.data);
    });
  }, []);

  const getPatient = (id, patients) => {
    const response = patients
      .filter(patient => patient.id === id)
      .map(filtered => {
        return filtered.nombre;
      });
    return response;
  };

  const getSku = (id, supplies) => {
    const response = supplies
      .filter(supply => supply.id === id)
      .map(filtered => {
        return filtered.SKU;
      });
    return response;
  };

  const getSupply = (id, supplies) => {
    const response = supplies
      .filter(supply => supply.id === id)
      .map(filtered => {
        return filtered.descripcion_producto;
      });
    return response;
  };

  const months_open = date => {
    const today = moment(new Date()).format("DD/MM/YYYY");
    const request_date = moment(date).format("DD/MM/YYYY");
    const diff = moment(today, "DD/MM/YYYY").diff(
      moment(request_date, "DD/MM/YYYY")
    );
    const duration = moment.duration(diff);
    return duration._data.months;
  };

  return (
    <div className="col-xl-8 mb-3">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col text-center">Datos del pedido</div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm text-left ml-5">
              <span className="font-weight-bold">Id: </span>
              {props.id}
              <br />
              <span className="font-weight-bold">SKU: </span>
              {getSku(order.id_insumo_medicamento, supplies)}
              <br />
              <span className="font-weight-bold">Insumo devuelto: </span>
              {getSupply(order.id_insumo_medicamento, supplies)}
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Paciente: </span>
              {getPatient(order.id_paciente, patients)}
              <br />
              <span className="font-weight-bold">Fecha: </span>
              {order.fecha_solicitud}
              <br />
            </div>
            <div className="col-sm text-left">
              <span className="font-weight-bold">Cantidad: </span>
              {order.cantidad}
              <br />
              <span className="font-weight-bold">Motivo: </span>
              {order.motivo}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-sm text-center">
              <Link>
                <Button className="mt-4" variant="contained" color="primary">
                  Ver nota de venta
                </Button>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Orders;

Orders.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.bool,
  id_therapy: PropTypes.number,
  id_paquetizacion: PropTypes.number,
  id: PropTypes.number
};
