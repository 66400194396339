import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from "utils/API";
import { makeStyles } from "@material-ui/core/styles";


export default function ModalAceptRequest(props) {
  const { open, closeModal, moduleType } = props;



  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const scroll = "paper";

  function aceptRequest() {


    api.get("api/solicitudcompra/").then(response => {
      console.log(response);
      window.location.href = `/admin/outcome/divisions/${moduleType}`;
    });
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Aceptar retiro</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <div style={{ marginBottom: "10rem" }}>
          <p className="text-center font-weight-bold">
            ¿Está seguro que desea aceptar el retiro?
          </p>
          <p className="text-center">
            Se descontará la cantidad a fraccionar del SKU inicial
            automáticamente al momento de aceptar el fraccionamiento.
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={aceptRequest} color="primary">
          Si
        </Button>
        <Button onClick={closeModal} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalAceptRequest.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func
};
