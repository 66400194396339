import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

const DateFilterEnd = props => {
  const { endDate, handleDateEndChange } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline1"
          label="Fecha de fin"
          value={endDate}
          onChange={handleDateEndChange}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker1"
          label="Hora de fin"
          value={endDate}
          onChange={handleDateEndChange}
          KeyboardButtonProps={{
            "aria-label": "change time"
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DateFilterEnd;

DateFilterEnd.propTypes = {
  endDate: PropTypes.object,
  handleDateEndChange: PropTypes.func
};
