import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Button } from "@material-ui/core";
import api from "utils/API";
import apiform from "utils/APIForm";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import Select from "react-select";
import allActions from "actions";
import {
  getListSuppliesMedicines,
} from 'actions/getListAction';

export default function ModalReplaceInsumo(props) {
  const dispatch = useDispatch();
  const {
    open,
    closeModal,
    id_producto,
    id_detalle,
    handleAcceptReplacement,
  } = props;
  const [pedido_to_replace, setPedido_to_replace] = useState("");
  const [description, setDescription] = useState("");
  const [sku, setSku] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [id_supply, setId_supply] = useState("");

  const listResource = useSelector(state => state.currentList.listSupplies);
  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {

    api.get(`api/productospedidos/${id_producto}`).then(response => {
      setPedido_to_replace(response.data);

    });
    if(listResource.length === 0) {
      getListSuppliesMedicines(dispatch, chargeMedicine)
    }
  }, []);


  const handleSKU = value => {
    setSku(value);
    const tempResource = medicalResourceData[value.value]
    setDescription(tempResource?.descripcion_producto)
    setId_supply(tempResource?.id)
  };

  function updateProduct() {


    const formDataReplace = new FormData();

    formDataReplace.append("reemplazar", pedido_to_replace.id);
    formDataReplace.append("reemplazo", id_supply);
    formDataReplace.append("cantidad", quantity);

    apiform.post("api/bodega/pedidos/detalle/reemplazar/", formDataReplace).then(response => {
      const item = response.data.estado
      item.id_insumo_medicamento = medicalResourceData[item.id_insumo_medicamento]
      handleAcceptReplacement(item)
    })

  }

  const _content_ = <div>
    <div className="col-md-11 mb-3">
      <label className="text-dark">GRUPO:</label>
      <input
        type="text"
        className="form-control bg-light font-weight-lighter"
        style={{ color: "#000000" }}
        value={"Insumos"}
        disabled
      />
    </div>
    <div className="col-md-11 mb-3">
      <label className="text-dark">SKU:</label>
      <Select
        value={sku}
        onChange={handleSKU}
        options={listResource}
        placeholder="Seleccione"
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
    </div>
    <div className="col-md-11 mb-3">
      <label className="text-dark">DESCRIPCIÓN:</label>
      <input
        type="text"
        className="form-control bg-light font-weight-lighter"
        style={{ color: "#000000" }}
        value={description}
        disabled
      />
    </div>
    <div className="col-md-11 mb-3">
      <label className="text-dark">CANTIDAD:</label>
      <input
        type="number"
        min="0"
        className="form-control bg-light font-weight-lighter"
        style={{ color: "#000000" }}
        value={quantity}
        onChange={e => {
          if (/^[0-9\b]+$/.test(e.target.value)) {
            setQuantity(e.target.value);
          }
        }}
      />
    </div>
  </div>

  const _actions_ = <>
    <Button onClick={closeModal} color="primary">
      Cancelar
    </Button>
    <Button onClick={updateProduct} color="primary">
      Guardar
    </Button>
  </>

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={`Reemplazo de insumos`}
      _content_={_content_}
      _actions_ = {_actions_}
      maxWidth="sm"
    />
  );
}
