import React, {useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import {
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
    ArrowBackIos,
    ArrowForwardIos,
} from '@material-ui/icons';
import {red, green, orange, indigo} from "@material-ui/core/colors";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import moment from "moment-timezone";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);
const classStyle = makeStyles((theme) => ({
    text: {
      fontWeight: "bold",
    },
    title: {
      fontWeight: "bold",
      '&:hover': {
          color: indigo[400],
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff"
    },
    paper: {
        transition: "all 500ms linear",
        padding: "2% 4% 3%",
        margin: "30px 0 0 0",
        minWidth: "655px",
        width: "100%"
    }
  }));

export default function DailyTable({
    dailyArray,
    dailyHeaderArray,
    dailyHeaderPages,
    selectDate,
    handleNavigation,
    handleSelectDateWorker,
    handleEditDateWorker,
    dictPatientFile,
    dictReservation,
    changeDate=true,
    page,
    setpage,
}) {
    const classes = useStyles();
    const classesStyle = classStyle();
    const [initialCharge, setinitialCharge] = useState(true);
    const [columsHeader, setcolumnHeader] = useState(4);

    useEffect(() => {
        if (initialCharge){
          initialData();
        }
    });

    const initialData = () => {
        setinitialCharge(false);
    };

    const handleChangePage = (auxPage) => {
        if (auxPage <= dailyHeaderPages &&  auxPage >= 0){
            setpage(auxPage)
        }
    };

    const AddingButtonWork = (row, RowWorker) => {
        const tempPatient = dictPatientFile[row.id_ficha];
        const name = `${tempPatient?.nombre} ${tempPatient?.apellido_paterno} ${tempPatient?.apellido_materno}`;
        const styleState = (row.estado === "CanceladaPaciente" || row.estado === "NoRealizada" ) ?
            {background: red[500], color: red[50]} :
            row.estado === "Incompleto" ?
                {background: orange[500], color: orange[50]} :
                {background: green[500], color: green[50]}

        return (
            <Card
                style={{
                    width: `150px`,
                    height: `${row.numero_modulos > 1 ? 45*row.numero_modulos + 7.7*row.numero_modulos: 45*row.numero_modulos }px`,
                    position: "absolute",
                    margin: "-22px 0px 0px 0px",
                    overflow: "hidden",
                    cursor: "pointer",

                    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)",
                    ...styleState
                }}
                onClick={() => handleEditDateWorker(row, RowWorker)}
            >
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                >
                    <Typography variant="caption" display="block" gutterBottom>
                        {`Paciente: ${name}`}
                    </Typography>
                </Grid>
            </Card>
        )
    };

    const handleSelectCell = (row, RowWorker, index, cellId) => {
        handleSelectDateWorker(row, RowWorker, index);
    };

    return (
        <Card style={{margin: "0 0 0 0"}}>
        <CardBody stats className={classes.cardFooter} style={{padding: "2rem 1rem", margin: "0"}}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <MuiPickersUtilsProvider locale="es" utils={MomentUtils} >
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        margin="normal"
                        id="date-picker-inline1-1"
                        value={selectDate}
                        onChange={(e) => handleNavigation(moment(e))}
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                        autoOk={true}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{margin: "-38px 0 0 0"}}
            >
                <Grid item xs={6}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    {changeDate &&
                    <Button
                        round
                        color={"primary"}
                        size="sm"
                        style={{zIndex: 10}}
                        className={classes.marginRight}
                        onClick={() => handleNavigation(moment())}
                    >

                        Hoy
                    </Button>
                    }
                    {changeDate &&
                    <Button
                        round
                        color={"primary"}
                        size="sm"
                        style={{zIndex: 10}}
                        className={classes.marginRight}
                        onClick={() => handleNavigation(moment(selectDate).subtract(1, "days"))}
                    >
                        Anterior
                    </Button>
                    }
                    {changeDate &&
                    <Button
                        round
                        color={"primary"}
                        size="sm"
                        className={classes.marginRight}
                        style={{zIndex: 10}}
                        onClick={() => handleNavigation(moment(selectDate).add(1, "days"))}
                    >
                       Siguiente
                    </Button>
                    }
                </Grid>
                <Grid item xs={6}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <Button
                        justIcon
                        round
                        color={page === 0 ? "": "primary"}
                        style={{zIndex: 10}}
                        className={classes.marginRight}
                        onClick={() => handleChangePage(page-1)}
                    >
                        <ArrowBackIos style={{ marginRight: "-5px"}} />
                    </Button>
                    <Typography
                        variant="caption"
                        style={{margin: "0 10px"}}
                    >
                        {`${page} de ${dailyHeaderPages}`}
                    </Typography>
                    <Button
                        justIcon
                        round
                        color={page === dailyHeaderPages ? "": "primary"}
                        className={classes.marginRight}
                        style={{zIndex: 10}}
                        onClick={() => handleChangePage(page+1)}
                    >
                        <ArrowForwardIos style={{ marginRight: "-5px"}}/>
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} >
            <TableContainer style={{ ...changeDate ? {} : {maxHeight: "350px"} }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        <TableCell className="text-center align-top" style={{minWidth: "145px"}}>Horario </TableCell>
                        {dailyHeaderArray.slice(page *columsHeader, page * columsHeader + columsHeader).map((row, index) =>(
                            <TableCell
                                key={`${index}-header`}
                                className="text-center align-top"
                                style={{width: "200px", minWidth: "200px"}}
                            >
                                {`${row.first_name} ${row.last_name}`}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {dailyArray.map((row, index) => (
                        <TableRow key={`${index}-workRows`}>
                            <TableCell className="text-center align-middle">
                                {`${row.start} - ${row.end}`}
                            </TableCell>
                            {dailyHeaderArray.slice(page * columsHeader, page * columsHeader + columsHeader).map((RowWorker, Workerindex) =>(
                                dictReservation[`${moment(selectDate).format("YYYY-MM-DD")} ${row.start}:00`] ?
                                    dictReservation[`${moment(selectDate).format("YYYY-MM-DD")} ${row.start}:00`][RowWorker.id] ?
                                        <TableCell
                                            key={`${index}-${Workerindex}-work`}
                                            id={`${index}-${Workerindex}-work`}
                                            className="text-center align-middle"
                                            style={{position: "relative"}}
                                        >
                                            {AddingButtonWork(
                                                dictReservation[`${moment(selectDate).format("YYYY-MM-DD")} ${row.start}:00`][RowWorker.id],
                                                RowWorker
                                            )}
                                        </TableCell> :
                                        <TableCell
                                            key={`${index}-${Workerindex}-work`}
                                            id={`${index}-${Workerindex}-work`}
                                            onClick={() => handleSelectCell(row, RowWorker, Workerindex, `${index}-${Workerindex}-work`)}
                                            className="text-center align-middle"
                                        >
                                            -
                                        </TableCell> :
                                        <TableCell
                                            key={`${index}-${Workerindex}-work`}
                                            id={`${index}-${Workerindex}-work`}
                                            onClick={() => handleSelectCell(row, RowWorker, Workerindex, `${index}-${Workerindex}-work`)}
                                            className="text-center align-middle"
                                        >
                                            -
                                        </TableCell>
                            ))}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </Grid>
        </CardBody>
        </Card>
  );
}