import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop, Paper, Button, Grid } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 500ms linear"
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%"
  }
});

export default function ModalAdd({
  open,
  handleClose,
  table,
  listItem,
  dataArray,
  handleAddItem,
  //setPageRefresh
}) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [tempList, settempList] = useState([]);
  const [value, setvalue] = useState({});
  const [charge, setcharge] = useState(true);

  const openBackdrop = useSelector(state => state.currentGlobalVar.openBackdrop);
  

  useEffect(() => {
    if (charge) {
      getList();
    }
    if (openBackdrop){
      getList();
      dispatch({
        type: "BLOQUEAR_PANTALLA",
        payload: false,
    });
    }
  });

  const getList = () => {
    setcharge(false);
    const auxDataId = [];
    dataArray.map(row => {
      if (row.fuera_paquete === table) {
        auxDataId.push(row.id);
      }
    });
    settempList(listItem.filter(row => !auxDataId.includes(row.value)));
  };

  const handleOnChange = (value, set) => {
    if(value.value !== null){
      set(value);
    }
  };

  const updateValue = () => {
    handleAddItem(value);
    handleClose();
  };

  const chargeList = [{value: null, label: "Cargando..."}]

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      style={{
        borderRadius: "4px"
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Paper className={classes.paper}>
        <h3 id="spring-modal-title">{`Agregar Item`}</h3>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-end"
          item
          xs={12}
        >
          <Grid item xs={12}>
            {openBackdrop &&
              <div></div>
            }
              
              <DetailSelect 
                  label="Buscar" 
                  value={value} 
                  onchange={(value) => { handleOnChange(value, setvalue)}}
                  list={tempList.length > 0 ? tempList : chargeList} 
              /> 
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
            item
            xs={12}
          >
            {value ? (
              <Button
                mr={2}
                variant="contained"
                className="text-white"
                style={{ backgroundColor: green[500], textTransform: "none" }}
                onClick={updateValue}
              >
                Agregar
              </Button>
            ) : (
              <Button disabled mr={2} variant="contained">
                Agregar
              </Button>
            )}
            <Button
              mr={2}
              variant="contained"
              style={{
                backgroundColor: red[500],
                marginRight: "10px",
                textTransform: "none"
              }}
              className="text-white"
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}

ModalAdd.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  table: PropTypes.bool,
  listItem: PropTypes.array,
  dataInfo: PropTypes.object,
  dataArray: PropTypes.array,
  setdataArray: PropTypes.func,
  handleAddItem: PropTypes.func,
  setPageRefresh: PropTypes.func
};
