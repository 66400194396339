import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ModalReemplazo from "./ModalReemplazo";

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  table: {
    minWidth: 650
  }
});

const EquipTableComponent = props => {
  const classes = useStyles();
  const {
    dataEquipArray,
    setDataEquipArray,
    margin="30px 0 0 0",
    rentEquipList,
    dataPatient,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [tipoAccion, setTipoAccion] = useState('');
  const [equipo, setEquipo] = useState('');


  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    if (loading2) {
      setLoading2(false);
    }
  });

  const handleOpenModal = (estado, accion, dispositivo) => {
    setTipoAccion(accion)
    setEquipo(dispositivo)
    setOpenModal(estado)
  };




  return (
    <Card className={classes.root}>
      
      <CardContent>
        <h3 style={{paddingLeft:'20px'}}>Equipos del Paciente</h3>
        <Grid
          item
          xs={12}
          style={{
            margin: margin,
            maxHeight: "300px",
            overflow: "auto"
          }}
        > 
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: "150px" }}>
                    Equipo
                  </TableCell>
                  <TableCell align="center" style={{ width: "150px" }}>
                    Marca
                  </TableCell>
                  <TableCell align="center" style={{ width: "150px" }}>
                    Modelo
                  </TableCell>
                  <TableCell align="center" style={{ width: "150px" }}>
                    N° Inventario
                  </TableCell>
                  <TableCell align="center" style={{ width: "150px" }}>
                    Estado
                  </TableCell>
                 
                  <TableCell align="center" style={{ width: "150px" }}>
                    Acción
                  </TableCell>
              
                 
                </TableRow>
              </TableHead>
              <TableBody>
                {rentEquipList.length>0 && rentEquipList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{row.equipo}</TableCell>
                    <TableCell align="center">{row.marca}</TableCell>
                    <TableCell align="center">{row.modelo}</TableCell>
                    <TableCell align="center">{row.ninventario}</TableCell>
                    <TableCell align="center">{row.estado}</TableCell>
                   
                      <TableCell align="center">
                        <Grid container alignItems="center" direction="row" justify="center" spacing={3}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                
                                textTransform: "none"
                              }}
                              onClick={() => {
                                handleOpenModal(true,"reemplazo",row);
                              }}
                            >
                              Reemplazo
                            </Button>
                          </Grid>
                          
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{
                                
                                textTransform: "none"
                              }}
                              onClick={() => {
                                handleOpenModal(true,"retiro",row);
                              }}
                            >
                              Retiro
                            </Button>
                          </Grid>

                        </Grid>
                      </TableCell>
              
                  </TableRow>
                ))}
                {!loading && <TableRow></TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
      {openModal === true && (
        <ModalReemplazo
          open={openModal !== null}
          handleClose={() => setOpenModal(null)}
          equipo={equipo}
          dataPatient={dataPatient}
          tipoAccion={tipoAccion}
        />
      )}
    </Card>
  );
};

export default EquipTableComponent;

EquipTableComponent.propTypes = {
  dataEquipArray: PropTypes.array,
  setDataEquipArray: PropTypes.func,
  medicalResourceData: PropTypes.object,
  listEquip: PropTypes.array
};
