import React from "react";

export const useSortableDataToTransport = (itemsToTransport, config = null) => {
  const [sortConfigToTransport, setSortConfigToTransport] = React.useState(
    config
  );

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...itemsToTransport];
    if (sortConfigToTransport !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfigToTransport.key] < b[sortConfigToTransport.key]) {
          return sortConfigToTransport.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfigToTransport.key] > b[sortConfigToTransport.key]) {
          return sortConfigToTransport.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [itemsToTransport, sortConfigToTransport]);

  const requestSortToTransport = key => {
    let direction = "ascending";
    if (
      sortConfigToTransport &&
      sortConfigToTransport.key === key &&
      sortConfigToTransport.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfigToTransport({ key, direction });
  };

  return {
    itemsToTransport: sortedItems,
    requestSortToTransport,
    sortConfigToTransport
  };
};
