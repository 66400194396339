import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

const TableWaitToOutcome = props => {
  const { products } = props;

  return (
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <TableContainer component={Paper}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="text-center align-middle">SKU</th>
                <th className="text-center align-middle">Grupo</th>
                <th className="text-center align-middle">Descripción</th>
                <th className="text-center align-middle">
                  Cantidad solicitada
                </th>
                <th className="text-center align-middle">Cantidad faltante</th>
                <th className="text-center align-middle">Cantidad enviada</th>
                <th className="text-center align-middle">Observaciones</th>
              </tr>
            </thead>

            <tbody>
              {products.map(product =>
                product.id_producto_pedido.reemplazado ? (
                  <tr key={product.id}>
                    <td className="text-center align-middle">
                      <s>
                        {
                          product?.id_producto_pedido?.id_insumo_medicamento!==null ? product.id_producto_pedido.id_insumo_medicamento.SKU:product?.id_producto_pedido?.id_equipo_asignado?.sku_bodega
                        }
                      </s>
                    </td>
                    <td className="text-center align-middle">
                      <s>
                        {
                         product?.id_producto_pedido?.id_insumo_medicamento!==null ? product.id_producto_pedido.id_insumo_medicamento.grupo_producto : "Equipo"
                        }
                      </s>
                    </td>
                    <td className="text-center align-middle">
                      <s>
                        {
                          product?.id_producto_pedido?.id_insumo_medicamento!==null ?product.id_producto_pedido.id_insumo_medicamento.descripcion_producto:product?.id_producto_pedido?.id_equipo_asignado?.tipo_equipo
                        }
                      </s>
                    </td>
                    <td className="text-center align-middle">
                      <s>{product.id_producto_pedido.cantidad}</s>
                    </td>
                    <td className="text-center align-middle">
                      <s>{product.id_producto_pedido.cantidad_por_enviar}</s>
                    </td>
                    <td className="text-center align-middle">
                      <s>{product.cantidad_enviada}</s>
                    </td>
                    <td className="text-center align-middle">
                      <s>{product.id_producto_pedido.observaciones}</s>
                    </td>
                  </tr>
                ) : (
                  <tr key={product.id}>
                    <td className="text-center align-middle">
                      {
                         product?.id_producto_pedido?.id_insumo_medicamento!==null ? product.id_producto_pedido.id_insumo_medicamento.SKU:product?.id_producto_pedido?.id_equipo_asignado?.sku_bodega
                      }
                    </td>
                    <td className="text-center align-middle">
                      {
                        product?.id_producto_pedido?.id_insumo_medicamento!==null ? product.id_producto_pedido.id_insumo_medicamento.grupo_producto : "Equipo"
                      }
                    </td>
                    <td className="text-center align-middle">
                      {
                        product?.id_producto_pedido?.id_insumo_medicamento!==null ?product.id_producto_pedido.id_insumo_medicamento.descripcion_producto:product?.id_producto_pedido?.id_equipo_asignado?.tipo_equipo
                      }
                    </td>
                    <td className="text-center align-middle">
                      {product.id_producto_pedido.cantidad}
                    </td>
                    <td className="text-center align-middle">
                      {product.id_producto_pedido.cantidad_por_enviar}
                    </td>
                    <td className="text-center align-middle">
                      {product.cantidad_enviada}
                    </td>
                    <td className="text-center align-middle">
                      {product.id_producto_pedido.observaciones}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </TableContainer>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default TableWaitToOutcome;

TableWaitToOutcome.propTypes = {
  products: PropTypes.array
};
