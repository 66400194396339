import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import PropTypes from "prop-types";
import ModalAddRecord from "./AddRecord/ModalAddRecord";
import ModalViewRecord from "./ViewDetail/ModalViewDetail";
import Success from "./Dialogs/Sucess";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const localizer = momentLocalizer(moment);

const CalendarEndowment = props => {
  const {
    eventsVariables,
    setEvents,
    setStartDate,
    setEndDate,
    getAllVariables,
    idFicha,
    variableCounter,
    setVariableCounter,
    pageRefresh,
    setPageRefresh,
    extraShift,
    extraAttention,
    holiday,
    openModalAddRecord,
    setOpenModalAddRecord,
    tempDateInfo,
    setTempDateInfo,
    unpaidLeave,
    absence
  } = props;
  const [tempEventInfo, setTempEventInfo] = useState("");
  const [openModalViewRecord, setOpenModalViewRecord] = useState(false);
  const [openDialogSucess, setOpenDialogSucess] = useState(false);
  const [tempMessage, setTempMessage] = useState("");

  return (
    <div>
      <Calendar
        selectable
        localizer={localizer}
        events={eventsVariables}
        onNavigate={date => {
          setStartDate(
            moment(date)
              .startOf("month")
              .format("YYYY-MM-DDT00:00:01")
          );
          setEndDate(
            moment(date)
              .endOf("month")
              .format("YYYY-MM-DDT23:59:59")
          );
          const dateStart = moment(date)
            .startOf("month")
            .format("YYYY-MM-DDT00:00:01");
          const dateEnd = moment(date)
            .endOf("month")
            .format("YYYY-MM-DDT23:59:59");
          getAllVariables(dateStart, dateEnd);
        }}
        views={["month"]}
        defaultView={"month"}
        min={moment(moment().format("YYYY-MM-DD"))
          .add(30, "minutes")
          .toDate()}
        max={moment(moment().format("YYYY-MM-DD"))
          .add(23, "hours")
          .add(30, "minutes")
          .toDate()}
        // date={selectDate.toDate()}
        // step={30}
        // view={calendarView}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={event => {
          setOpenModalViewRecord(true);
          setTempEventInfo(event);
        }}
        // onSelectSlot={slotInfo => console.log(slotInfo)}
        // onView={(view) => handleViewCalendar(view)}
        // onNavigate={date => handleNavigation(date)}
        // onSelectEvent={handleSelectEvent}
        onSelectSlot={slotInfo => {
          setTempDateInfo(moment(slotInfo.start));
          setOpenModalAddRecord(true);
        }}
        // eventPropGetter={eventColors}
        messages={{
          next: "Siguiente",
          previous: "Anterior",
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día",
          agenda: "Agenda",
          showMore: function(e) {
            return "+" + e + " más";
          }
        }}
        // components={{
        //   toolbar: CustomToolbar
        // }}
        timeslots={1}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: "lightgrey",
            color: "#000",
            borderRadius: "0px",
            border: "none"
          };

          if (event.title === "Días trabajados") {
            newStyle.backgroundColor = "8bc34a";
          }

          if (event.title === "Horas (+) (-)") {
            newStyle.backgroundColor = "#4caf50";
          }

          if (event.title === "Turno extra") {
            newStyle.backgroundColor = "#64b5f6";
          }

          if (event.title === "Atención extra") {
            newStyle.backgroundColor = "#1565c0";
          }

          if (event.title === "Inasistencia") {
            newStyle.backgroundColor = "#e53935";
          }

          if (event.title === "Vacación") {
            newStyle.backgroundColor = "#ffa726";
          }

          if (event.title === "Licencia") {
            newStyle.backgroundColor = "#ffeb3b";
          }

          if (event.title === "Permiso sin sueldo") {
            newStyle.backgroundColor = "#fff9c4";
            newStyle.color = "black";
          }

          if (event.title === "Veto") {
            newStyle.backgroundColor = "#616161";
          }

          if (event.title === "Renuncia") {
            newStyle.backgroundColor = "#212131";
          }

          if (event.title === "Despido") {
            newStyle.backgroundColor = "#eea312";
          }

          return {
            className: "",
            style: newStyle
          };
        }}
      />
      {openModalAddRecord && (
        <ModalAddRecord
          openModal={openModalAddRecord}
          closeModal={() => setOpenModalAddRecord(false)}
          tempDateInfo={tempDateInfo}
          idFicha={idFicha}
          setOpenDialogSucess={setOpenDialogSucess}
          setOpenModalAddRecord={setOpenModalAddRecord}
          setTempMessage={setTempMessage}
          variableCounter={variableCounter}
          setVariableCounter={setVariableCounter}
          setPageRefresh={setPageRefresh}
          eventsVariables={eventsVariables}
          setEvents={setEvents}
          extraShift={extraShift}
          extraAttention={extraAttention}
          holiday={holiday}
          unpaidLeave={unpaidLeave}
          absence={absence}
        />
      )}
      {openModalViewRecord && (
        <ModalViewRecord
          openModal={openModalViewRecord}
          closeModal={() => setOpenModalViewRecord(false)}
          tempDateInfo={tempDateInfo}
          tempEventInfo={tempEventInfo}
          setOpenDialogSucess={setOpenDialogSucess}
          setTempMessage={setTempMessage}
          setPageRefresh={setPageRefresh}
          variableCounter={variableCounter}
          setVariableCounter={setVariableCounter}
          eventsVariables={eventsVariables}
          setEvents={setEvents}
        />
      )}
      {openDialogSucess && (
        <Success
          openDialog={openDialogSucess}
          closeDialog={() => setOpenDialogSucess(false)}
          message={tempMessage}
          setOpenModalAddRecord={setOpenModalAddRecord}
          setOpenModalViewRecord={setOpenModalViewRecord}
          setPageRefresh={setPageRefresh}
        />
      )}
      {pageRefresh && <div></div>}
    </div>
  );
};

export default CalendarEndowment;

CalendarEndowment.propTypes = {
  eventsVariables: PropTypes.array,
  setEvents: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  getAllVariables: PropTypes.func,
  idFicha: PropTypes.string,
  variableCounter: PropTypes.object,
  setVariableCounter: PropTypes.func,
  pageRefresh: PropTypes.bool,
  setPageRefresh: PropTypes.func,
  extraShift: PropTypes.bool,
  extraAttention: PropTypes.bool,
  holiday: PropTypes.bool,
  openModalAddRecord: PropTypes.bool,
  setOpenModalAddRecord: PropTypes.func,
  tempDateInfo: PropTypes.object,
  setTempDateInfo: PropTypes.func,
  unpaidLeave: PropTypes.bool,
  absence: PropTypes.bool
};
