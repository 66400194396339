import React from "react";
import Typography from "@material-ui/core/Typography";

export const Position = checkTab => {
  switch (checkTab) {
    case 0:
      return <Typography color="textPrimary">Armar compras</Typography>;
    case 1:
      return <Typography color="textPrimary">En proceso</Typography>;
    case 2:
      return <Typography color="textPrimary">Compras por llegar</Typography>;
    case 3:
      return <Typography color="textPrimary">Finalizado</Typography>;
    default:
      return null;
  }
};
