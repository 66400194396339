import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import api from "utils/API";
import TabsTasks from "./TabsTasks";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalMedicalIndications = props => {
  const { openModal, closeModal, dataFile, array, setArray, uploadWorks=true } = props;
  const [pageRefresh, setPageRefresh] = useState(false);
  const [getSpecialties, setGetSpecialties] = useState([]);
  const [changes, setChanges] = useState(true);
  const [startDateBalance, setStartDateBalance] = useState(
    moment().format("YYYY-MM-DD[T]HH:mm:ss")
  );
  const [endDateBalance, setEndDateBalance] = useState(
    moment().format("YYYY-MM-DD[T]HH:mm:ss")
  );
  const [specialtyBalance, setSpecialtyBalance] = useState();
  const [balanceIngresos, setBalanceIngresos] = useState([]);
  const [balanceEgresos, setBalanceEgresos] = useState([]);

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);

    const getSpecialties = api.get("api/paciente/cargos/tareas/");
    const getBalanceTypes = api.get(
      `api/paciente/${dataFile.id}/balancehidrico/tiposmovimientos/`
    );

    Promise.all([getSpecialties, getBalanceTypes])
      .then(response => {
        setGetSpecialties(response[0].data["nursingList"].reduce((accum, obj) => ([
          ...accum,
          {
            id: obj.value,
            nombre_corto: obj.label
          }
        ]), []));
        let ingresos = [];
        let egresos = [];
        response[1].data
          .filter(filtered => filtered.tipo_io === "Ingresos")
          .map(balance => {
            const tempRow = {
              select: false,
              item: balance.nombre,
              value: balance.tipo_io,
              startDate: moment(startDateBalance).format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
              endDate: moment(endDateBalance).format("YYYY-MM-DD[T]HH:mm:ss"),
              speciality: specialtyBalance
            };
            ingresos.push(tempRow);
          });
        response[1].data
          .filter(filtered => filtered.tipo_io === "Egresos")
          .map(balance => {
            const tempRow = {
              select: false,
              item: balance.nombre,
              value: balance.tipo_io,
              startDate: moment(startDateBalance).format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
              endDate: moment(endDateBalance).format("YYYY-MM-DD[T]HH:mm:ss"),
              speciality: specialtyBalance
            };
            egresos.push(tempRow);
          });
        setBalanceIngresos(ingresos);
        setBalanceEgresos(egresos);
      })

  };

  const [vitalSigns, setVitalSigns] = useState([
    {
      select: false,
      item: "Talla",
      value: "sizePatient",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""],
      hora1: "",
      hora2: "",
      hora3: "",
      hora4: "",
      hora5: "",
      hora6: "",
      hora7: "",
      hora8: ""
    },
    {
      select: false,
      item: "Peso",
      value: "weight",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""],
      hora1: "",
      hora2: "",
      hora3: "",
      hora4: "",
      hora5: "",
      hora6: "",
      hora7: "",
      hora8: ""
    },
    {
      select: false,
      item: "Temperatura",
      value: "temperature",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""],
      hora1: "",
      hora2: "",
      hora3: "",
      hora4: "",
      hora5: "",
      hora6: "",
      hora7: "",
      hora8: ""
    },
    {
      select: false,
      item: "Frecuencia cardiaca",
      value: "heartRate",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""],
      hora1: "",
      hora2: "",
      hora3: "",
      hora4: "",
      hora5: "",
      hora6: "",
      hora7: "",
      hora8: ""
    },
    {
      select: false,
      item: "Frecuencia respiratoria",
      value: "breathingFrequency",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""],
      hora1: "",
      hora2: "",
      hora3: "",
      hora4: "",
      hora5: "",
      hora6: "",
      hora7: "",
      hora8: ""
    },
    {
      select: false,
      item: "Saturacion de oxígeno",
      value: "oxygenSaturation",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""],
      hora1: "",
      hora2: "",
      hora3: "",
      hora4: "",
      hora5: "",
      hora6: "",
      hora7: "",
      hora8: ""
    },
    {
      select: false,
      item: "Presión arterial",
      value: "bloodPressure",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""],
      hora1: "",
      hora2: "",
      hora3: "",
      hora4: "",
      hora5: "",
      hora6: "",
      hora7: "",
      hora8: ""
    },
    {
      select: false,
      item: "Presión arterial media",
      value: "bloodPressureMean",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""],
      hora1: "",
      hora2: "",
      hora3: "",
      hora4: "",
      hora5: "",
      hora6: "",
      hora7: "",
      hora8: ""
    },
    {
      select: false,
      item: "Dolor",
      value: "pain",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""],
      hora1: "",
      hora2: "",
      hora3: "",
      hora4: "",
      hora5: "",
      hora6: "",
      hora7: "",
      hora8: ""
    }
  ]);

  const [general, setGeneral] = useState([
    // {
    //   select: false,
    //   item: "C.S.V",
    //   value: "General",
    //   startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
    //   endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
    //   speciality: "",
    //   horas: ["", "", "", "", "", "", "", ""]
    // },
    {
      select: false,
      item: "Aseo Cama",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Aseo Genital",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Lubricación piel",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Cuidados de Sonda",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Cambios de posición",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Cuidados de VVP",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Curación de TQT",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Curación de TQT",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Curación de GTT",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Sondeo Vesical",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Control Peso",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Medición CC",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Recolección de Orina",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    },
    {
      select: false,
      item: "Cambio bajada alimentación",
      value: "General",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      horas: ["", "", "", "", "", "", "", ""]
    }
  ]);

  const [others, setOthers] = useState([
    {
      select: false,
      item: "Ingesta de liquidos",
      value: "Estimulaciones",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      observation: ""
    },
    {
      select: false,
      item: "Deambulación",
      value: "Estimulaciones",
      startDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      speciality: "",
      observation: ""
    }
  ]);

  useEffect(() => {
    if (pageRefresh) {
      setPageRefresh(false);
    }
  });

  const addAll = () => {
    const tempArray = array;
    const sendArray = [];
    // others
    others.filter(filtered => filtered.select).map(sign => {
        sendArray.push({
          tipo_tarea: "IndicacionesMedicas",
          fecha_hora: sign.startDate,
          fecha_final: sign.endDate,
          descripcion: sign.item,
          estado: "Incompleto",
          comentarios: sign.value,
          motivo_rechazo: "Estimulaciones",
          id_ficha: dataFile.id
        });
      });
    // end others
    // general
    let countGeneral = 0;
    const totalGeneral = general.filter(filtered => filtered.select).length;
    general.filter(filtered => filtered.select).map(sign => {
        countGeneral = countGeneral + 1;
        let fechaComparar = moment(sign.startDate);
        let fechaFin = moment(sign.endDate).add(1, "days");
        while (fechaFin.diff(moment(fechaComparar)) > 0) {
          sign.horas
            .filter(hora => hora !== "")
            .map(hora => {
              const tempFecha = `${moment(fechaComparar).format(
                "YYYY-MM-DD"
              )}T${moment(hora).format("HH:mm:ss")}`;
              sendArray.push({
                tipo_tarea: "IndicacionesMedicas",
                fecha_hora: tempFecha,
                descripcion: sign.item,
                id_cargo_asignado: sign.speciality,
                estado: "Incompleto",
                comentarios: sign.value,
                motivo_rechazo: "General",
                id_ficha: dataFile.id
              });
            });
          fechaComparar = moment(fechaComparar).add(1, "days");
        }
      });
    // end general
    // vital signs
    let countVitalSigns = 0;
    const totalVitalSigns = vitalSigns.filter(filtered => filtered.select).length;
    vitalSigns.filter(filtered => filtered.select).map(sign => {
        countVitalSigns = countVitalSigns + 1;
        let fechaComparar = moment(sign.startDate);
        let fechaFin = moment(sign.endDate).add(1, "days");
        while (fechaFin.diff(moment(fechaComparar)) > 0) {
          sign.horas
            .filter(hora => hora !== "")
            .map(hora => {
              const tempFecha = `${moment(fechaComparar).format(
                "YYYY-MM-DD"
              )}T${moment(hora).format("HH:mm:ss")}`;
              sendArray.push({
                tipo_tarea: "IndicacionesMedicas",
                fecha_hora: tempFecha,
                descripcion: sign.item,
                id_cargo_asignado: sign.speciality,
                estado: "Incompleto",
                comentarios: sign.value,
                motivo_rechazo: "SignosVitales",
                id_ficha: dataFile.id
              });
            });
          fechaComparar = moment(fechaComparar).add(1, "days");
        }
      });
    // end vital signs
    // balance
    let totalBalance = 0;
    const countBalanceIngresos = balanceIngresos.filter(
      filtered => filtered.select
    ).length;
    const countBalanceEgresos = balanceEgresos.filter(
      filtered => filtered.select
    ).length;
    balanceIngresos.filter(filtered => filtered.select).map(sign => {
        totalBalance = totalBalance + 1;
        let fechaComparar = moment(sign.startDate);
        let fechaFin = moment(sign.endDate).add(1, "days");
        while (fechaFin.diff(moment(fechaComparar)) > 0) {
          const tempFecha = `${moment(fechaComparar).format(
            "YYYY-MM-DDTHH:mm:ss"
          )}`;
          sendArray.push({
            tipo_tarea: "IndicacionesMedicas",
            fecha_hora: tempFecha,
            fecha_final: tempFecha,
            descripcion: sign.item,
            id_cargo_asignado: sign.speciality,
            estado: "Incompleto",
            comentarios: sign.value,
            motivo_rechazo: "BalanceHidrico",
            id_ficha: dataFile.id
          });
          fechaComparar = moment(fechaComparar).add(1, "days");
        }
      });

    balanceEgresos.filter(filtered => filtered.select).map(sign => {
        totalBalance = totalBalance + 1;
        let fechaComparar = moment(sign.startDate);
        let fechaFin = moment(sign.endDate).add(1, "days");
        while (fechaFin.diff(moment(fechaComparar)) > 0) {
          const tempFecha = `${moment(fechaComparar).format(
            "YYYY-MM-DDTHH:mm:ss"
          )}`;
          sendArray.push({
            tipo_tarea: "IndicacionesMedicas",
            fecha_hora: tempFecha,
            fecha_final: tempFecha,
            descripcion: sign.item,
            id_cargo_asignado: sign.speciality,
            estado: "Incompleto",
            comentarios: sign.value,
            motivo_rechazo: "BalanceHidrico",
            id_ficha: dataFile.id
          });
          fechaComparar = moment(fechaComparar).add(1, "days");
        }
      });
    // end balance
    if (uploadWorks) {
      api.post("api/paciente/tareas/bulk/", sendArray).then(response => {
          const tempResult = response.data;
          tempResult.map(temp => {
            tempArray.push(temp);
          });
            setArray(tempArray);
            closeModal();
        }).catch(error =>{});
    } else {
      setArray([array, ...sendArray]);
    }
  };

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      fullWidth={true}
      maxWidth={"lg"}
      disableBackdropClick
    >
      <DialogTitle id="customized-dialog-title" onClose={closeModal}>
        Nueva tarea
      </DialogTitle>
      <DialogContent>
        <TabsTasks
          vitalSigns={vitalSigns}
          setVitalSigns={setVitalSigns}
          general={general}
          setGeneral={setGeneral}
          setPageRefresh={setPageRefresh}
          getSpecialties={getSpecialties}
          others={others}
          setOthers={setOthers}
          balanceIngresos={balanceIngresos}
          setBalanceIngresos={setBalanceIngresos}
          balanceEgresos={balanceEgresos}
          setBalanceEgresos={setBalanceEgresos}
          startDateBalance={startDateBalance}
          setStartDateBalance={setStartDateBalance}
          endDateBalance={endDateBalance}
          setEndDateBalance={setEndDateBalance}
          specialtyBalance={specialtyBalance}
          setSpecialtyBalance={setSpecialtyBalance}
          dataFile={dataFile}
          setChanges={setChanges}
          pageRefresh={pageRefresh}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "1em" }}>
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            onClick={addAll}
            color="primary"
          >
            Guardar cambios
          </Button>
      </DialogActions>
      {pageRefresh && <div></div>}
    </Dialog>
  );
};

export default ModalMedicalIndications;

ModalMedicalIndications.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  dataFile: PropTypes.object,
  array: PropTypes.array,
  setArray: PropTypes.func
};
