import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {

    Grid,

    TextField,
} from '@material-ui/core';
import Select from "react-select";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import { TypographyMui } from 'utils/componentsStyle';

import { checkRut, validateEmail} from "utils/validationFunction";
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import api from "utils/API";
import moment from "moment-timezone";
import AlertDialog from "components/Attendance/AlertDialog";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import "moment/locale/es";

moment.locale("es");

export default function DefineVisit({
    open,
    handleClose,
}) {

    const [firstName, setfirstName] = useState("");
    const [secondName, setsecondName] = useState("");
    const [lastName, setlastName] = useState("");
    const [secondLastName, setsecondLastName] = useState("");
    const [email, setemail] = useState("");
    const [errorEmail, seterrorEmail] = useState("");
    const [rut, setrut] = useState("");
    const [errorRut, seterrorRut] = useState("");
    const [selectCharge, setselectCharge] = useState(null);
    const [fileBoss, setfileBoss] = useState(null)

    const [abrirAlerta, setAbrirAlerta] = useState(false);
    const [titulo,setTitulo] = useState('');
    const [mensaje,setMensaje] = useState('');
    const [tipoAlerta,setTipoAlerta] = useState('');

    const listCharges = useSelector(state => state.currentList.listCharges);
    const dictCharges = useSelector(state => state.currentList.dictCharges);

    useEffect(() => {

        api.get("api/rrhh/obtener/ficha/jefe/").then((response) => {
            setfileBoss(response.data[0]);
        }).catch((error) => {
            setTitulo("¡Atencion!");
            setTipoAlerta("error");
            setMensaje("Ocurrio un problema al obtener correo de Jefe RRHH");
            setAbrirAlerta(true);
        })

    }, [])



    const handleCreateUser = () => {
        const body = {
            nombre: firstName,
            segundo_nombre: secondName,
            apellido_paterno: lastName,
            apellido_materno: secondLastName,
            rut: rut,
            email: email,
            id_cargo: selectCharge.value,
        }
        api.post(`api/crearusuarionuevo/`, body).then((response) => {
            setTitulo("¡Exito!");
            setTipoAlerta("success");
            setMensaje(`Usuario creado exitosamente, Se enviaron las crendenciales de acceso a ${firstName} ${lastName}: ${email} y a ${fileBoss.nombres} ${fileBoss.apellido_paterno}: ${fileBoss.email}`);
            setAbrirAlerta(true);
        }).catch((error) => {
            setTitulo("¡Atencion!");
            setTipoAlerta("error");
            if (error?.response?.data?.error){
                setMensaje(error?.response?.data?.error);
            } else {
                setMensaje("Ocurrio un problema al crear el usuario.");
            }
            setAbrirAlerta(true);
        })

    };

    const handleValidationRut = (value) => {
        seterrorRut(checkRut(value))
        setrut(value);
    };

    const handleValidationEmail = (value) => {
        seterrorEmail(validateEmail(value))
        setemail(value);
    };

    const handleSelectCharge = (value) => {
        if (listCharges.length > 0){
            setselectCharge(value)
        }
    }

    const handleCloseModal = () => {
        window.location.reload();
        setAbrirAlerta(false);
        handleClose();
    }

    const _content_ =  <div>
    <AlertDialog openDialog={abrirAlerta} handleClose={handleCloseModal} message={mensaje} title={titulo} severity={tipoAlerta}/>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "5px 0px"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                        {/*<img src={copy} alt="copy" width='35px' height='35px' className='mr-3' /> */}
                        <TypographyMui
                            variant='body2'
                            color='primary'
                            className='align-self-center'
                        >
                            Cargo:
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                        <div style={{width: '100%'}}>
                        <Select
                            style={{width: '100%'}}
                            value={selectCharge}
                            onChange={(value) => handleSelectCharge(value)}
                            options={listCharges.length > 0 ? listCharges: [{value: null, label: "cargando datos"}]}
                            placeholder="Seleccione"
                            styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                        />
                        </div>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                        <span style={{color: "#E31B0C"}}>
                            {selectCharge ? "" : "Campo obligatorio"}
                        </span>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "5px 0px"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={6}
                        style={{margin: "10px 0 10x 0"}}
                    >
                        {/*<img src={copy} alt="copy" width='35px' height='35px' className='mr-3' /> */}
                        <TypographyMui
                            variant='body2'
                            color='primary'
                            className='align-self-center'
                        >
                            Primer Nombre:
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={6}
                        style={{margin: "10px 0 10x 0"}}
                    >
                       <TextField size="small" variant='outlined' name='currentStock'
                            onChange={(event) => setfirstName(event.target.value)}
                            value={firstName}
                            style={{width: "100%"}}
                        />
                        <span style={{color: "#E31B0C"}}>
                            {firstName !== "" ? "" : "Campo obligatorio"}
                        </span>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "5px 0px"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                        {/*<img src={copy} alt="copy" width='35px' height='35px' className='mr-3' /> */}
                        <TypographyMui
                            variant='body2'
                            color='primary'
                            className='align-self-center'
                        >
                            Segundo nombre:
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                       <TextField size="small" variant='outlined' name='currentStock'
                            onChange={(event) => setsecondName(event.target.value)}
                            value={secondName}
                            style={{width: "100%"}}
                        />
                    </Grid></Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "5px 0px"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                        {/*<img src={copy} alt="copy" width='35px' height='35px' className='mr-3' /> */}
                        <TypographyMui
                            variant='body2'
                            color='primary'
                            className='align-self-center'
                        >
                            Apellido Paterno:
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                       <TextField size="small" variant='outlined' name='currentStock'
                            onChange={(event) => setlastName(event.target.value)}
                            value={lastName}
                            style={{width: "100%"}}
                        />
                        <span style={{color: "#E31B0C"}}>
                            {lastName !== "" ? "" : "Campo obligatorio"}
                        </span>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "5px 0px"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                        {/*<img src={copy} alt="copy" width='35px' height='35px' className='mr-3' /> */}
                        <TypographyMui
                            variant='body2'
                            color='primary'
                            className='align-self-center'
                        >
                            Apellido Materno:
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                       <TextField size="small" variant='outlined' name='currentStock'
                            onChange={(event) => setsecondLastName(event.target.value)}
                            value={secondLastName}
                            style={{width: "100%"}}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "5px 0px"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                        {/*<img src={copy} alt="copy" width='35px' height='35px' className='mr-3' /> */}
                        <TypographyMui
                            variant='body2'
                            color='primary'
                            className='align-self-center'
                        >
                            RUT:
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                       <TextField size="small" variant='outlined' name='currentStock'
                            onChange={(event) => handleValidationRut(event.target.value)}
                            value={rut}
                            style={{width: "100%"}}
                        />
                        <span style={{color: "#E31B0C"}}>
                            {rut !== "" ? "" : "Campo obligatorio"}
                        </span>
                        <span style={{color: "#E31B0C"}}>
                            {errorRut}
                        </span>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "5px 0px"}}
                >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                        {/*<img src={copy} alt="copy" width='35px' height='35px' className='mr-3' /> */}
                        <TypographyMui
                            variant='body2'
                            color='primary'
                            className='align-self-center'
                        >
                            Email:
                        </TypographyMui>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        item
                        xs={6}
                    >
                        <TextField size="small" variant='outlined' name='currentStock'
                            onChange={(event) => handleValidationEmail(event.target.value)}
                            value={email}
                            style={{width: "100%"}}
                        />
                        <span style={{color: "#E31B0C"}}>
                            {email !== "" ? "" : "Campo obligatorio"}
                        </span>
                        <span style={{color: "#E31B0C"}}>
                            {errorEmail}
                        </span>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    </div>

    const _actions_ = <>
        {(errorEmail === "" && errorRut === "" && selectCharge?.value && firstName !== "" && lastName !== "" && email !== "" && rut !== "" )? (
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: green[500], marginRight: "3px"}}
            onClick={handleCreateUser}
        >
            Guardar
        </Button>
        ) : (
            <Button
            disabled
            mr={2}
            variant="contained"
            className="text-white"
        >
            Guardar
        </Button>
        )}
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500], marginRight: "10px", marginLeft: "3px"}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </Button>
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={`Crear usuario`}
            _content_={_content_}
            _actions_ = {_actions_}
            maxWidth="xs"
        />
    );
}