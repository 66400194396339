import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableSignosVitales from "./TableSignosVitales/TableSignosVitales";
import TableGenerales from "./TableGeneral/TableGeneral";
import BalanceHidrico from "./BalanceHidrico/BalanceHidrico";
import TableOthers from "./TableOthers/TableOthers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const TabsTasks = props => {
  const classes = useStyles();
  const {
    vitalSigns,
    setVitalSigns,
    setPageRefresh,
    getSpecialties,
    general,
    setGeneral,
    others,
    setOthers,
    balanceIngresos,
    setBalanceIngresos,
    balanceEgresos,
    setBalanceEgresos,
    startDateBalance,
    setStartDateBalance,
    endDateBalance,
    setEndDateBalance,
    specialtyBalance,
    setSpecialtyBalance,
    dataFile,
    setChanges,
    pageRefresh
  } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          centered
        >
          <Tab label="Signos Vitales" {...a11yProps(0)} />
          <Tab label="Tipo de Ingreso" {...a11yProps(1)} />
          <Tab label="General" {...a11yProps(2)} />
          <Tab label="Estimulación" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TableSignosVitales
          vitalSigns={vitalSigns}
          setVitalSigns={setVitalSigns}
          setPageRefresh={setPageRefresh}
          getSpecialties={getSpecialties}
          pageRefresh={pageRefresh}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BalanceHidrico
          getSpecialties={getSpecialties}
          setPageRefresh={setPageRefresh}
          balanceIngresos={balanceIngresos}
          setBalanceIngresos={setBalanceIngresos}
          balanceEgresos={balanceEgresos}
          setBalanceEgresos={setBalanceEgresos}
          startDateBalance={startDateBalance}
          setStartDateBalance={setStartDateBalance}
          endDateBalance={endDateBalance}
          setEndDateBalance={setEndDateBalance}
          specialtyBalance={specialtyBalance}
          setSpecialtyBalance={setSpecialtyBalance}
          dataFile={dataFile}
          setChanges={setChanges}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableGenerales
          general={general}
          setGeneral={setGeneral}
          setPageRefresh={setPageRefresh}
          getSpecialties={getSpecialties}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableOthers
          others={others}
          setOthers={setOthers}
          setPageRefresh={setPageRefresh}
          getSpecialties={getSpecialties}
        />
      </TabPanel>
    </div>
  );
};

export default TabsTasks;

TabsTasks.propTypes = {
  vitalSigns: PropTypes.array,
  setVitalSigns: PropTypes.func,
  setPageRefresh: PropTypes.func,
  getSpecialties: PropTypes.array,
  general: PropTypes.array,
  setGeneral: PropTypes.func,
  others: PropTypes.array,
  setOthers: PropTypes.func,
  balanceIngresos: PropTypes.array,
  setBalanceIngresos: PropTypes.func,
  balanceEgresos: PropTypes.array,
  setBalanceEgresos: PropTypes.func,
  startDateBalance: PropTypes.string,
  setStartDateBalance: PropTypes.func,
  endDateBalance: PropTypes.string,
  setEndDateBalance: PropTypes.func,
  specialtyBalance: PropTypes.number,
  setSpecialtyBalance: PropTypes.func,
  dataFile: PropTypes.object,
  setChanges: PropTypes.func,
  pageRefresh: PropTypes.bool
};
