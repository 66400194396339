const initialState = {
    listApiCharge: [],
    listCharges: [],
    dictCharges: {},
    listRequestPatient: [],
    listOrdersPatient: [],
    LISTA_KEY_AUXILIARES: [],
    listMedicines: [],
    listSupplies: [],
    listFood: [],
    listEquip: [],
    listForecast: [],
    listPackage: [],
    listPackaging: [],
    listPatientScheduledOrders: [],
    listAFP: [],
    dictSuppliesMedicines: {},
    dictMedicinesPrescription: {},
    dictKitSpecific: [],
    dictKitEquipGen: {},
    dictEquipGenericSpecific: {},
    dictEquipGeneric: {},
    dictDiscountQuotation: {},
    dictForecast: {},
    dictPackage: {},
    dictPackaging: {},
    dictEquip: {},
    dictAFP: {},
};

const currentList = (state = initialState, action) => {

    switch(action.type){
        case "LISTA_CARGOS":
            return {
                ...state,
                listCharges: action.payload,
            }
        case "DICCIONARIO_CARGOS":
            return {
                ...state,
                dictCharges: action.payload,
            }
        case "LISTA_AFP":
            return {
                ...state,
                listAFP: action.payload,
            }
        case "DICCIONARIO_AFP":
            return {
                ...state,
                dictAFP: action.payload,
            }
        case "LISTA_SOLICITUDES_PACIENTE":
            return {
                ...state,
                listRequestPatient: action.payload,
            }
        case "LISTA_PEDIDOS_PACIENTE":
            return {
                ...state,
                listOrdersPatient: action.payload,
            }
        case "LISTA_KEY_AUXILIARES":
            return {
                ...state,
                LISTA_KEY_AUXILIARES: action.payload,
            }
        case 'LISTA_MEDICAMENTOS':
            return {
                ...state,
                listMedicines: action.payload
            };
        case 'LISTA_INSUMOS':
            return {
                ...state,
                listSupplies: action.payload
            };
        case 'LISTA_ALIMENTOS':
            return {
                ...state,
                listFood: action.payload
            };
        case 'LISTA_EQUIPOS':
            return {
                ...state,
                listEquip: action.payload
            };
        case 'LISTA_PEDIDOS_PROGRAMADOS_PACIENTE':
            return {
                ...state,
                listPatientScheduledOrders: action.payload
            };
        case 'DICCIONARIO_INSUMOS_MEDICAMENTOS':
            return {
                ...state,
                dictSuppliesMedicines: action.payload
            };
        case 'DICCIONARIO_MEDICAMENTOS_PRESCRIPCION':
            return {
                ...state,
                dictMedicinesPrescription: action.payload
            };
        case 'DICCIONARIO_KIT_ESPECIFICO':
            return {
                ...state,
                dictKitSpecific: action.payload
            };
        case 'DICCIONARIO_KIT_GENERICO':
            return {
                ...state,
                dictKitEquipGen: action.payload
            };
        case 'DICCIONARIO_EQUIPOS_GENERICOS_ESPECIFICOS':
            return {
                ...state,
                dictEquipGenericSpecific: action.payload
            };
        case 'DICCIONARIO_EQUIPOS_GENERICOS':
            return {
                ...state,
                dictEquipGeneric: action.payload
            };
        case 'DICCIONARIO_DESCUENTOS_COTIZACION':
            return {
                ...state,
                dictDiscountQuotation: action.payload
            };
        case 'DICCIONARIO_EQUIPOS':
            return {
                ...state,
                dictEquip: action.payload
            };
        case 'LISTA_CONVENIO_ISAPRE':
            return {
                ...state,
                listForecast: action.payload
            };
        case 'DICCIONARIO_CONVENIO_ISAPRE':
            return {
                ...state,
                dictForecast: action.payload
            };
        case 'LISTA_PAQUETES':
            return {
                ...state,
                listPackage: action.payload
            };
        case 'DICCIONARIO_PAQUETES':
            return {
                ...state,
                dictPackage: action.payload
            };
        case 'LISTA_PAQUETETIZACIONES':
            return {
                ...state,
                listPackaging: action.payload
            };
        case 'DICCIONARIO_PAQUETETIZACIONES':
            return {
                ...state,
                dictPackaging: action.payload
            };
        case 'LISTA_API_CARGA':
            return {
                ...state,
                listApiCharge: action.payload
            };
        default:
            return {
                ...state,
                [action.type]: action.payload
            }
    }
};

export default currentList;