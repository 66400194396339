import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Icon, Tooltip, Grid as GridMUI } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    border: "1px solid #dadde9",
  },
  iconRoot: {
    width: "50px",
    height: "50px",
    textAlign: "center",
  },
});

// const Tooltip = styled(TooltipMui)`
//     text-transform: none;
//     background-color: #f5f5f9,
//     color: rgba(0, 0, 0, 0.87),
//     max-width: 220,
//     border: 1px solid #dadde9,
//     justify-content: center;
// `;

// eslint-disable-next-line react/prop-types
export default function IconStatistics({
  icon,
  data,
  measurement,
  title,
  onclick = () => {},
}) {
  const classes = useStyles();
  return (
    <GridItem
      container
      direction="column"
      justify="center"
      alignItems="center"
      onClick={onclick}
    >
      <Tooltip
        // classes={{root: classes.tooltip}}
        title={
          <React.Fragment>
            <GridMUI
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography variant="body2" gutterBottom>
                {title}
              </Typography>
              {data.value ? (
                <Typography
                  variant="caption"
                  gutterBottom
                >{`${data.value} ${measurement}`}</Typography>
              ) : (
                <Typography
                  variant="caption"
                  gutterBottom
                >{`No ingresada`}</Typography>
              )}
              {data.value && (
                <Typography
                  variant="caption"
                  gutterBottom
                >{`${data.date}`}</Typography>
              )}
            </GridMUI>
          </React.Fragment>
        }
      >
        <GridMUI
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Icon classes={{ root: classes.iconRoot }}>
            <img
              alt="i"
              style={{ width: "100%" }}
              src={`${window.location.origin}${icon}`}
            />
          </Icon>
          {data.value ? (
            <Typography
              variant="caption"
              gutterBottom
            >{`${data.value} ${measurement}`}</Typography>
          ) : (
            <Typography
              variant="caption"
              gutterBottom
            >{`No ingresada`}</Typography>
          )}
        </GridMUI>
      </Tooltip>
    </GridItem>
  );
}
