import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment-timezone";
import { checkRut } from "utils/validationFunction";
import api from "utils/API";
import "moment/locale/es";
moment.locale("es");

export default function FormEditPatient({
  data,
  setmessage,
  openAnimation,
  setOpenAnimation,
  closeModal,
  settutor
}) {
  const [nombre, setnombre] = useState(data.nombres);
  const [apellido_paterno, setapellido_paterno] = useState(
    data.apellido_paterno
  );
  const [apellido_materno, setapellido_materno] = useState(
    data.apellido_materno
  );
  const [rut, setrut] = useState(data.rut);
  const [rutError, setrutError] = useState("");
  const [celular, setCelular] = useState(data.celular);
  const [otroCelular, setOtroCelular] = useState(data.otro_telefono);
  const [correo, setCorreo] = useState(data.email);
  const [validation, setvalidation] = useState(false);

  useEffect(() => {
    validateForm();
  });

  const validateForm = () => {
    const arrayValidation = [
      nombre !== "",
      apellido_paterno !== "",
      apellido_materno !== "",
      rut != "",
      rutError === "",
      celular != "",
      correo != "",
      otroCelular != ""
    ];
    if (arrayValidation.every(item => item === true)) {
      setvalidation(true);
    } else {
      setvalidation(false);
    }
  };

  const updateValue = () => {
    const body = { ...data };
    body.nombres = nombre;
    body.apellido_paterno = apellido_paterno;
    body.apellido_materno = apellido_materno;
    body.rut = rut;
    body.celular = celular;
    body.otro_telefono = otroCelular;
    body.email = correo;
    settutor(body);
    setOpenAnimation(true);
    setmessage("Actualizando datos");
    const requestArray = [];
    requestArray.push(api.put(`/api/tutor/${data.id}`, body));
    Promise.all(requestArray)
      .then(() => {
        settutor(body);
        setmessage("Datos actualizados");
        setOpenAnimation(false);
      })

  };

  const handleOnChange = (value, set) => {
    set(value);
  };

  const handleOnChangeRut = (value, set) => {
    setrutError(checkRut(value));
    set(value);
  };

  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-end"
        item
        xs={12}
      >
        <Grid item xs={4}>
          <DetailText
            label={`Nombres`}
            placeholder={`Nombres`}
            value={nombre}
            onchange={e => handleOnChange(e.target.value, setnombre)}
          />
        </Grid>
        <Grid item xs={4}>
          <DetailText
            label={`Apellido Paterno `}
            placeholder={`Apellido Paterno `}
            value={apellido_paterno}
            onchange={e => handleOnChange(e.target.value, setapellido_paterno)}
          />
        </Grid>
        <Grid item xs={4}>
          <DetailText
            label={`Apellido Materno `}
            placeholder={`Apellido Materno `}
            value={apellido_materno}
            onchange={e => handleOnChange(e.target.value, setapellido_materno)}
          />
        </Grid>
        <Grid item xs={4}>
          <DetailText
            label={`Rut `}
            placeholder={`Ej: 12345678-9`}
            value={rut}
            onchange={e => handleOnChangeRut(e.target.value, setrut)}
            error={rutError}
          />
        </Grid>
        <Grid item xs={4}>
          <DetailText
            label={`Celular `}
            placeholder={`Celular `}
            value={celular}
            onchange={e => handleOnChange(e.target.value, setCelular)}
          />
        </Grid>
        <Grid item xs={4}>
          <DetailText
            label={`Otro celular`}
            placeholder={`Otro celular `}
            value={otroCelular}
            onchange={e => handleOnChange(e.target.value, setOtroCelular)}
          />
        </Grid>
        <Grid item xs={4}>
          <DetailText
            label={`Correo `}
            placeholder={`Correo`}
            value={correo}
            onchange={e => handleOnChange(e.target.value, setCorreo)}
          />
        </Grid>
        {openAnimation ? (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
            item
            xs={12}
          >
            {validation ? (
              <Button
                mr={2}
                variant="contained"
                className="text-white"
                style={{ backgroundColor: green[500] }}
                onClick={updateValue}
              >
                Guardar
              </Button>
            ) : (
              <Button
                disabled
                mr={2}
                variant="contained"
                className="text-white"
              >
                Guardar
              </Button>
            )}
            <Button
              mr={2}
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }}
              className="text-white"
              onClick={closeModal}
            >
              Cerrar
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

FormEditPatient.propTypes = {
  data: PropTypes.object,
  setmessage: PropTypes.func,
  openAnimation: PropTypes.bool,
  setOpenAnimation: PropTypes.func,
  closeModal: PropTypes.func,
  settutor: PropTypes.func
};
