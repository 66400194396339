import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button as ButtonMUI,
  Grid,
  FormControl,
  Typography,
} from "@material-ui/core";
import {
  indigo
} from "@material-ui/core/colors";
import moment from "moment-timezone";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import DetailDate from "views/Widgets/FichaPaciente/detailDate";
import TitleDivider from "views/Widgets/Boss/TitleDivider";
import BarChart from "components/Boss/BarChart";
import TableDemandStock from "components/Boss/TableDemandStock";
import SearchBossTable from "./SearchBossTable";
import RequestTable from "components/Boss/RequestTable";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

const ViewStock = ({ 
  auxData,
  barChartData,
  demandStock,
  handleStartDate,
  handleEndDate,
  startDate,
  endDate,
  process,
  setprocess,
  pending,
  setpending,
  patient,
  listPatient,
  dictPatient,
  handlePatient,
  typeRequest,
  handleTypeRequest,
  listTypeRequest,
  comuna,
  handleComuna,
  listComuna,
  selectItemTable,
  handleItemTable,
  getPickingState,
  getIterationNumber,
  getArea,
  handleSelectColumn,
  selectColumn,
  handleItemTableDemandStock,
  selectItemTableDemandStock,
  demand,
  stock,
  diff,
  diffMin,
}) => {
  const classes = useStyles();
  const [searchPedidosBodega, setSearchPedidosBodega] = useState("");
  const [searchStock, setSearchStock] = useState("");

  const dictTypeRequest = useSelector(state => state.currentList.DICCIONARIO_TIPO_SOLICITUD_PEDIDO);


  let searchPedidosBodegaFilter = auxData.filter(row => {
    return (
      row.id
        .toString()
        .toLowerCase()
        .indexOf(searchPedidosBodega.toLowerCase()) !== -1 ||
      dictTypeRequest[row.tipo_solicitud]?.label
        .toString()
        .toLowerCase()
        .indexOf(searchPedidosBodega.toLowerCase()) !== -1 ||
      row.estado_solicitud
        .toString()
        .toLowerCase()
        .indexOf(searchPedidosBodega.toLowerCase()) !== -1 ||
      `
        ${dictPatient[row.id_paciente_id].nombre} 
        ${dictPatient[row.id_paciente_id].apellido_paterno} 
        ${dictPatient[row.id_paciente_id].apellido_materno}
      `
        .toString()
        .toLowerCase()
        .indexOf(searchPedidosBodega.toLowerCase()) !== -1 ||
      moment(row.fecha_tentativa_llegada)
        .diff(moment(), "days")
        .toString()
        .toLowerCase()
        .indexOf(searchPedidosBodega.toLowerCase()) !== -1 ||
      getPickingState(row.estado_picking_farmacia, row.estado_picking)
        .toString()
        .toLowerCase()
        .indexOf(searchPedidosBodega.toLowerCase()) !== -1 ||
      getIterationNumber(row.id)
        .toString()
        .toLowerCase()
        .indexOf(searchPedidosBodega.toLowerCase()) !== -1 ||
      `${dictPatient[row.id_paciente_id].direccion_comuna}`
        .toString()
        .toLowerCase()
        .indexOf(searchPedidosBodega.toLowerCase()) !== -1
    );
  });

  let searchStockFilter = demandStock.filter(row => {
    return (
      row.grupo
        .toString()
        .toLowerCase()
        .indexOf(searchStock.toLowerCase()) !== -1 ||
      row.SKU.toString()
        .toLowerCase()
        .indexOf(searchStock.toLowerCase()) !== -1 ||
      row.demanda
        .toString()
        .toLowerCase()
        .indexOf(searchStock.toLowerCase()) !== -1 ||
      row.stock
        .toString()
        .toLowerCase()
        .indexOf(searchStock.toLowerCase()) !== -1 ||
      row.difrencia
        .toString()
        .toLowerCase()
        .indexOf(searchStock.toLowerCase()) !== -1
    );
  });

  return (
    
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      item
      xs={12}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        item
        xs={12}
        sm={12}
        md={2}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          item
          xs={12}
        >
          <Typography variant="h5" gutterBottom>
            Quiebre de Stock
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={12}>
          <Card style={{ margin: "2px 0" }}>
            <CardBody>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography variant="h4" gutterBottom>
                  {auxData.length}
                </Typography>
                <Typography variant="caption" gutterBottom>
                  Q pedidos totales
                </Typography>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={12}>
          <Card style={{ margin: "2px 0" }}>
            <CardBody>
              <FormControl fullWidth>
                <DetailDate
                  handleOnChange={value => handleStartDate(value)}
                  value={startDate}
                  validation={false}
                  timeFormat={false}
                  style={{ padding: "0" }}
                  header={true}
                />
                <DetailDate
                  handleOnChange={value => handleEndDate(value)}
                  value={endDate}
                  validation={false}
                  timeFormat={false}
                  style={{ padding: "0" }}
                  header={true}
                />
              </FormControl>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={12}>
          <Card style={{ margin: "2px 0" }}>
            <CardBody>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                {process ? (
                  <ButtonMUI
                    style={{ textTransform: "none" }}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => setprocess(false)}
                  >
                    En proceso
                  </ButtonMUI>
                ) : (
                  <ButtonMUI
                    style={{ textTransform: "none" }}
                    variant="contained"
                    size="small"
                    onClick={() => setprocess(true)}
                  >
                    En proceso
                  </ButtonMUI>
                )}
                <div style={{ margin: "2px 0" }}></div>
                {pending ? (
                  <ButtonMUI
                    style={{ textTransform: "none" }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setpending(false)}
                  >
                    Pendientes
                  </ButtonMUI>
                ) : (
                  <ButtonMUI
                    style={{ textTransform: "none" }}
                    variant="contained"
                    size="small"
                    onClick={() => setpending(true)}
                  >
                    Pendientes
                  </ButtonMUI>
                )}
              </Grid>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={12}>
          <Card style={{ margin: "2px 0" }}>
            <CardBody>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <DetailSelect
                  label="Paciente"
                  value={patient}
                  onchange={value => handlePatient(value)}
                  list={listPatient}
                />
              </Grid>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={12}>
          <Card style={{ margin: "2px 0" }}>
            <CardBody>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <DetailSelect
                  label="Tipo pedido"
                  value={typeRequest}
                  onchange={value => handleTypeRequest(value)}
                  list={listTypeRequest}
                />
              </Grid>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={12}>
          <Card style={{ margin: "2px 0" }}>
            <CardBody>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <DetailSelect
                  label="Comuna"
                  value={comuna}
                  onchange={value => handleComuna(value)}
                  list={listComuna}
                />
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        item
        xs={12}
        sm={12}
        md={10}
        style={{ padding: "2px" }}
      >
        <TitleDivider title={"Demanda"} />
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          item
          xs={12}
          sm={12}
          md={6}
          style={{ padding: "2px" }}
        >
          <Typography variant="body1" gutterBottom>
            Q pedidos en bodega por estado de picking
          </Typography>
          <BarChart
            dataArray={barChartData}
            handleSelectColumn={handleSelectColumn}
            selectColumn={selectColumn}
            selectItemTable={selectItemTable}
          />
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          item
          xs={12}
          sm={12}
          md={6}
          style={{ padding: "2px" }}
        >
          <Typography variant="body1" gutterBottom>
            Demanda y stock por sku
          </Typography>
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ marginBottom: "1em" }}
          >
            <SearchBossTable
              search={searchStock}
              setSearch={setSearchStock}
              name="stock"
            />
          </Grid>
          <TableDemandStock
            //demandStock={demandStock}
            demandStock={searchStockFilter}
            total={auxData.length}
            handleItemTableDemandStock={handleItemTableDemandStock}
            selectItemTableDemandStock={selectItemTableDemandStock}
            demand={demand}
            stock={stock}
            diff={diff}
            diffMin={diffMin}
          />
        </Grid>
        <TitleDivider title={"Compras"} />
        <Grid item xs={12} sm={12} md={6}></Grid>
        <Grid item xs={12} sm={12} md={6}>
          compras en proceso
        </Grid>
        <TitleDivider title={"Pedidos"} />
        <Grid item xs={12} style={{ marginBottom: "2em" }}>
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ marginBottom: "2em" }}
          >
            <SearchBossTable
              search={searchPedidosBodega}
              setSearch={setSearchPedidosBodega}
              name="pedidos en bodega"
            />
          </Grid>
          <RequestTable
            dataArray={searchPedidosBodegaFilter}
            dictPatient={dictPatient}
            selectItemTable={selectItemTable}
            handleItemTable={handleItemTable}
            getPickingState={getPickingState}
            getIterationNumber={getIterationNumber}
            getArea={getArea}
          />
        </Grid>
      </Grid>
    </Grid>

  );
};

export default ViewStock;
