import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from 'notistack';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import FilterBox from "views/Widgets/Transport/FilterBox";
import RequestTable from "views/Widgets/ClinicalRequests/RequestTable";
import ModalEditScheduleTime from "components/ClinicalRequests/ModalEditScheduleTime";
import ModalEnableAddress from "components/ClinicalRequests/ModalEnableAddress";
import ModalEnableAddressWarehouse from "components/ClinicalRequests/ModalEnableAddressWarehouse";
import ModalTranscriptionTelemedicina from "components/ClinicalRequests/ModalTranscriptionTelemedicina";
import ModalDecompensation from "components/PatientFile/ModalDecompensation";
import ModalRoute from "components/ClinicalRequests/ModalRoute";
import ModalReplaceMedicine from "components/ClinicalRequests/ModalReplaceMedicine";
import ModalCheckEquipment from "components/ClinicalRequests/ModalCheckEquipment";
import ModalauthorizationQuotation from "components/ClinicalRequests/ModalauthorizationQuotation";
import ModalUploadAuthorizationAntibiotic from "components/ClinicalRequests/ModalUploadAuthorizationAntibiotic";
import ModalUploadOrderAntibiotic from "components/ClinicalRequests/ModalUploadOrderAntibiotic";
import ModalManagerRequestAntibiotic from "components/ClinicalRequests/ModalManagerRequestAntibiotic";
import ModalRequestTelemedicina from "components/PatientFile/ModalRequestTelemedicina";
import ModalDetailResourcesDriver from "components/ClinicalRequests/ModalDetailResourcesDriver";
import ModalProgressExtra from "components/ClinicalRequests/ModalProgressExtra"
import ModalTabletLose from "components/ClinicalRequests/ModalTabletLose";
import ModalAssingAdvice from "components/ClinicalRequests/ModalAssingAdvice";
import SelectedRequest from "./SelectedRequest";
import SelectedContract from "./SelectedContract";
import ModalExamData from "views/PatientFile/Examenes/Details/ModalExamData";
import ModalUploadAuthorization from "views/PatientFile/Examenes/Details/ModalUploadAuthorization";
import ModalUploadExamRequest from "views/PatientFile/Examenes/Details/ModalUploadExamRequest";
import ModalPreviewRequestEquipment from "views/PatientFile/Equipos/ModalPreviewRequestEquipment";
import ModalExamWaiting from "views/PatientFile/Examenes/Details/ModalExamWaiting";
import ModalExamToPerform from "views/PatientFile/Examenes/Details/ModalExamToPerform";
import ModalEditRequestInProcess from "views/PatientFile/InsumosYMedicamentos/ModalEditRequestInProces";
import ModalAddAuthorization from "./AddAuthorization/ModalAddAuthorization";
import ModalAddAuthorizationPersonal from "./AddAuthorization/ModalAddAuthorizationPersonal";
import ModalEditPersonal from "./AddAuthorization/ModalEditPersonal";

import {
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import {
    Search,
    Event,
} from '@material-ui/icons';
import moment from "moment-timezone";
import api from "utils/API";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {
    getListSuppliesMedicines,
} from 'actions/getListAction';
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(styles);

const ClinicalRequest = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [filter, setfilter] = useState("");
    const [userData, setuserData] = useState("");
    const [requestArray, setrequestArray] = useState([]);
    const [auxRequestArray, setauxRequestArray] = useState([]);
    const [requestEndArray, setrequestEndArray] = useState([]);
    const [auxRequestEndArray, setauxRequestEndArray] = useState([]);
    const [initialCharge, setinitialCharge] = useState(true);
    const [selectRowDetails, setselectRowDetails] = useState(null);
    const [editRowDetails, seteditRowDetails] = useState(null);
    const [updateView, setUpdateView] = useState(false);
    const [updateFilter, setUpdateFilter] = useState(false);
    const [openModalRoute, setopenModalRoute] = useState(false);
    const [selectEnableAddress, setselectEnableAddress] = useState(null);
    const [selectEnableAddressContracts, setselectEnableAddressContracts] = useState(null);
    const [selectEnableAddressWarehouse, setselectEnableAddressWarehouse] = useState(null);
    const [selectDecompetation, setselectDecompetation] = useState(null);
    const [selectRewriteTelemedicina, setselectRewriteTelemedicina] = useState(null);
    const [selectTelemedicinaModal, setselectTelemedicinaModal] = useState(null);
    const [selectTransportItem, setselectTransportItem] = useState(null);
    const [selectProgressExtra, setselectProgressExtra] = useState(null);
    const [selectPatient, setselectPatient] = useState(null);
    const [selectFile, setselectFile] = useState(null);
    const [listProfDecompensation, setlistProfDecompensation] = useState([]);
    const [listassigned, setlistassigned] = useState([]);
    const [tensAssigned, settensAssigned] = useState([]);
    const listPeriod =[
        {value: "Horario", label: "Horario"},
        {value: "Diario", label: "Diario"},
        {value: "Semanal", label: "Semanal"},
        {value: "Mensual", label: "Mensual"},
        {value: "Anual", label: "Anual"},
    ];

    const [openModalExam, setOpenModalExam] = useState(false);
    const [openModalUploadAuthorization, setOpenModalUploadAuthorization] = useState(false);
    const [openModalWaitingContract, setOpenModalWaitingContract] = useState(false);
    const [openModalToPerform, setOpenModalToPerform] = useState(false);
    const [tempRow, setTempRow] = useState(null);
    const [openModalRequestExam, setOpenModalRequestExam] = useState(false);
    const [openModalInsumos, setOpenModalInsumos] = useState(false);
    const [openModalAddAuthorization, setOpenModalAddAuthorization] = useState(false);
    const [openModalAddAuthorizationQuotation, setopenModalAddAuthorizationQuotation] = useState(false);
    const [openModalUploadAuthorizationAntibiotic, setOpenModalUploadAuthorizationAntibiotic] = useState(false);
    const [openModalUploadOrderAntibiotic, setOpenModalUploadOrderAntibiotic] = useState(false);
    const [openModalRequestAntibiotic, setOpenModalRequestAntibiotic] = useState(false);
    const [openModalPreviewRequestEquipment, setOpenModalPreviewRequestEquipment] = useState(false);
    const [openModalReplaceMedicine, setopenModalReplaceMedicine] = useState(false);
    const [openModalCheckEquipment, setOpenModalCheckEquipment] = useState(false);
    const [openModalAddAuthorizationProfExtra, setopenModalAddAuthorizationProfExtra] = useState(false);
    const [openModalEditProfExtra, setopenModalEditProfExtra] = useState(false);
    const [openModalTabletLose, setopenModalTabletLose] = useState(null);
    const [openModalAsssingWorks, setopenModalAsssingWorks] = useState(null);
    const [infoReplaceMedicine, setinfoReplaceMedicine] = useState(null)
    const [infoCheckEquipment, setInfoCheckEquipment] = useState(null)
    const [infoRow,  setInfoRow] = useState(null)
    const [tipoTarea, setTipoTarea] = React.useState("");
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const listMedical = useSelector(state => state.currentList.listMedicines);
    const listResource = useSelector(state => state.currentList.listSupplies);
    const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
    const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

    const dictTypeWork = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA);
    const dictStateWork = useSelector(state => state.currentList.DICCIONARIO_ESTADO_TAREAS_FICHA);

    useEffect(() => {
        if(listMedical.length === 0) {
            getListSuppliesMedicines(dispatch, chargeMedicine)
        }
    }, []);

    const action = key => (
        <Button style={{background: "#af4c4c00",  color: "#fff"}} onClick={() => {closeSnackbar(key)}}>
            CERRAR
        </Button>
    );

    const handleSnakeMessage = (message, variant, vertical= "top", hotizontal="center", persist=false) => {
        enqueueSnackbar(
            message,
            {
                variant,
                persist: persist,
                action,
                preventDuplicate: true,
                anchorOrigin: {
                    vertical: vertical,
                    horizontal: hotizontal,
                },
            }
        );
    };

    useEffect(() => {
        if (initialCharge){
            initialData();
        }
        if (updateView){
            setUpdateView(false);
        }
        if(updateFilter){
            updateData();
        }
    });

    const initialData = () => {
        setinitialCharge(false);
        const requestWorks = api.get(`/api/clinica/tareaspaciente/logeado/?`);
        const requestUserData = api.get(`/api/usuario/${window.localStorage.getItem("user_id")}`);
        Promise.all([
            requestWorks,
            requestUserData,
        ]).then((request) => {
            setrequestArray(request[0].data);
            setauxRequestArray(request[0].data);
            setuserData(request[1].data);
        });
    };

    const updateEndWork = () => {
        const requestWorksEnd = api.get(`/api/clinica/tareaspaciente/logeado/finalizado/`);
        Promise.all([
            requestWorksEnd
        ]).then((request) => {
            setrequestEndArray(request[0].data);
            setauxRequestEndArray(request[0].data);
        });
    }


    const updateData = () => {
        setUpdateFilter(false);
        const tempArray = [];
        const tempArrayEnd = [];
        requestArray.map((row) => {
            const patient = row.id_ficha?.id_paciente
            if (row.id.toString() === filter){
                tempArray.push(row);
            } else if (dictTypeWork[row.tipo_tarea]?.label.toLowerCase().includes(filter.toLowerCase())){
                tempArray.push(row);
            } else if (`${patient ? `${patient?.nombre} ${patient?.apellido_paterno} ${patient?.apellido_materno}` : `No disponible`}`.toLowerCase().includes(
                filter.toLowerCase()
            )){
                tempArray.push(row);
            } else if (dictStateWork[row.estado]?.label.toLowerCase().includes(filter.toLowerCase())){
                tempArray.push(row);
            }
        });

        requestEndArray.map((row) => {
            const patient = row.id_ficha.id_paciente;
            if (row.id.toString() === filter){
                tempArrayEnd.push(row);
            } else if (dictTypeWork[row.tipo_tarea]?.label.toLowerCase().includes(filter.toLowerCase())){
                tempArrayEnd.push(row);
            } else if (`${
                patient ?
                    `${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}` :
                    `No disponible`
                }`.toLowerCase().includes(filter.toLowerCase())){
                    tempArrayEnd.push(row);
            } else if (dictStateWork[row.estado]?.label.toLowerCase().includes(filter.toLowerCase())){
                tempArrayEnd.push(row);
            }
        });
        setauxRequestArray(tempArray);
        setauxRequestEndArray(tempArrayEnd);
        setUpdateView(true);
    };

    const handleFilter = (event) => {
        setfilter(event.target.value);
        setUpdateFilter(true);
    };

    const handleClickDetails = (row, index) => {
        if (row.estado === "EsperandoConfirmacion"){
            seteditRowDetails([row, index]);
        } else if (row.estado === "Incompleto") {
            setselectRowDetails([row, index]);
        }
    };

    const handleEnableAddress = (row) => {
        api.get(
            `/api/clinica/solicitudes/pacientes/${row.id_ficha.id_paciente.id}/coordinador/prestaciones/`
        ).then((response) => {
            setselectEnableAddress({
                row: row,
                benefits: response.data,
            })
        });
    };

    const handleUpdateRowState = (row, state) => {
        const tempArray = requestArray;
        const stateArray = ["Incompleto", "PorRealizar", "GestionarContratos", "SubirContratos"]
        let message = `Estado de la tarea actualizado a: "${dictStateWork[state]?.label}"`
        if (stateArray.includes(state)){
            const index = requestArray.findIndex((subrow) => subrow.id === row.id);
            tempArray[index].estado = state;
            setrequestArray(tempArray);
        } else {
            message = "Tarea finalizada";
            const tempRow = {...row, estado: state};
            setrequestArray(requestArray.filter((subrow) => subrow.id !== row.id));
            const tempEndArray = requestEndArray;
            tempEndArray.push(tempRow);
            setrequestEndArray(tempEndArray);
        }
        handleSnakeMessage(message, "success");
        setUpdateFilter(true);
    };

    const handleUpdateState = (argRequest) => {
        const tempArray = requestArray;
        const tempRow = tempArray[selectRowDetails[1]];
        tempRow.fecha_final = argRequest.fecha_final;
        tempRow.fecha_hora = argRequest.fecha_hora;
        tempRow.estado = argRequest.estado;
        tempArray[selectRowDetails[1]] = tempRow;
        let message = `Estado de la tarea actualizado a: "${dictStateWork[argRequest.estado]?.label}"`
        if (argRequest.estado === "Agendada"){
            const tempAuxRequestEndArray = requestEndArray;
            tempAuxRequestEndArray.push(tempRow);
            setrequestEndArray(tempAuxRequestEndArray);
            message = "Tarea Agendada";
        }
        handleSnakeMessage(message, "success");
        setrequestArray(tempArray);
        setselectRowDetails(null);
        setUpdateFilter(true);
    };

    const handleClearState = (selectRequest) => {
        const tempArray = requestArray.map((row) => {
            const tempRow = {...row};
            if (tempRow.id === selectRequest.id && tempRow.id_tarea_padre_id == selectRequest.id){
                tempRow.estado = "Recién Ingresada";
            }
            return {...tempRow}
        });
        setrequestArray(tempArray);
        setUpdateFilter(true);
    }

    const handleDecompetation = (selectRow) => {
        setselectPatient(selectRow.id_ficha.id_paciente);
        setselectFile(selectRow.id_ficha);
        const requestChargesWorks = api.get("api/paciente/cargos/tareas/");
        const requestProfesionalDecompensation = api.get("api/paciente/descompensacion/obtener/profesionales/");
        Promise.all([
            requestChargesWorks,
            requestProfesionalDecompensation
        ]).then((request) => {
            const auxChargesTemp =  request[0].data;
            setlistassigned([
                {
                    label: 'Enfermeria',
                    options: auxChargesTemp["nursingList"],
                },
            ]);
            setlistProfDecompensation(request[1].data);
        });


        api.get(`api/paciente/${selectRow.id_ficha.id_paciente.id}/solicitudes/descompensacion/${selectRow.id_solicitud_paciente.id}/`).then((response) => {
            setselectDecompetation([response.data, selectRow]);

        });
    };

    const handleRewriteTelemedicina = (selectRow) => {
        setselectPatient(selectRow.id_ficha.id_paciente);
        setselectFile(selectRow.id_ficha);
        const requestChargesWorks = api.get("api/paciente/cargos/tareas/");
        const requestTenstPatient = api.get(`api/clinica/pacientes/${selectRow.id_ficha.id_paciente.id}/tens/`);
        Promise.all([
            requestChargesWorks,
            requestTenstPatient
        ]).then((request) => {
            const auxChargesTemp =  request[1].data;
            const tempTensassigned =  request[0].data.map((task) => {
                const tempTask = {...task};
                tempTask.fecha_hora = moment(task.fecha_hora)
                    .format("YYYY-MM-DD hh:mm:ss")
                    .toString();
                if (task.fecha_final == task.fecha_hora){
                    tempTask.fecha_final = moment(task.fecha_final).add(1, "day");
                }
                tempTask.fecha_final = moment(tempTask.fecha_final)
                    .format("YYYY-MM-DD hh:mm:ss")
                    .toString();
                return tempTask
            });
            settensAssigned(tempTensassigned);
            setlistassigned([
                {
                    label: 'Enfermeria',
                    options: auxChargesTemp["nursingList"],
                },
            ]);
            setselectRewriteTelemedicina(selectRow);
        });
    };

    const handleUpdateStateDecompetation = () => {
        const tempSelect = selectDecompetation[1]
        const tempArray = requestArray.map((row) => {
            const tempRow = {...row};
            if (tempRow.id_solicitud_paciente){
            if (tempRow.id_solicitud_paciente.id === tempSelect.id_solicitud_paciente.id){
                tempRow.estado = "Finalizado";
            }
            }
            return {...tempRow}
        });
        setrequestArray(tempArray);
        setUpdateFilter(true);
    };


    const handleInsumos = row => {
        let tempRow = {
            ...row,
            id_paciente: row.id_ficha.id_paciente.id,
            id_row: row.id
        }
        tempRow["id"] = row.id_solicitud_paciente.id;
        setTempRow(tempRow);
        setOpenModalInsumos(true);
    };

    const handleReplacementMedicine  = (row) => {

        const requestWork = api.get(`api/bodega/reemplazo/producto/tarea/${row.id}/info/`);
        Promise.all([requestWork]).then((request) => {
            const auxRecplacementData = request[0].data;
            if (auxRecplacementData["message"]){
                handleSnakeMessage("Tarea finalizada", "success");
                window.location.reload();
            }
            auxRecplacementData["tarea"] = row;
            setinfoReplaceMedicine(auxRecplacementData);
            setopenModalReplaceMedicine(true);
        });
    };
    const handleCheckEquipment  = (row) => {
        api
            .get(`api/ingresos/solicitud/${row.id_solicitud_ingreso_id}/productos/`)
            .then(request => {
                setInfoCheckEquipment(request.data[0]);
                setInfoRow(row)
                setOpenModalCheckEquipment(true);
            });
    };

    const handleOnClickNav = (status)  => {
        if (status === 2){
            updateEndWork()
        } else if (status === 0){
            setinitialCharge(true);
        }
    }

    return (
        <GridContainer>
            <GridItem
                container
                direction="row"
                justify="center"
                alignItems="center"
                xs={12}
            >
                <GridItem xs={12}>
                    <CardBody className="rounded shadow border border-primary">
                        {selectRowDetails ?
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                color="inherit"
                                style={{cursor: "pointer"}}
                                onClick={() => setselectRowDetails(null)}
                            >
                                Mis tareas
                            </Link>
                            <Typography color="textPrimary">{`${selectRowDetails[0].id}`}</Typography>
                        </Breadcrumbs> :
                        selectEnableAddressContracts ?
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                color="inherit"
                                style={{cursor: "pointer"}}
                                onClick={() => setselectEnableAddressContracts(null)}
                            >
                                Mis tareas
                            </Link>
                            <Typography color="textPrimary">{`${selectEnableAddressContracts.id}`}</Typography>
                        </Breadcrumbs> :
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                color="inherit"
                                style={{cursor: "pointer"}}
                            >
                                Mis tareas
                            </Link>
                        </Breadcrumbs>
                        }

                    </CardBody>
                </GridItem>
                {selectRowDetails ?
                <SelectedRequest
                    selectRowDetails={selectRowDetails}
                    handleUpdateState={handleUpdateState}
                /> :
                selectEnableAddressContracts ?
                <SelectedContract
                    selectContracts={selectEnableAddressContracts}
                    setselectContracts={setselectEnableAddressContracts}
                    handleUpdateRowState={handleUpdateRowState}
                    setselectRowDetails={setselectRowDetails}
                /> :
                <Card>
                    {updateView &&
                        <div></div>
                    }
                    <CardBody>
                        <GridItem
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            xs={12}
                        >
                            <GridItem xs={7} md={9} style={{margin: "-20px 0 0 0"}}>
                                <FilterBox
                                    filterText={filter}
                                    handleFilter={handleFilter}
                                    IconSpan={Search}
                                />
                            </GridItem>
                            <GridItem
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                xs={4} md={2}
                            >
                                <Button
                                    style={{ textTransform: "none" }}
                                    color={"info"}
                                    onClick={() => setopenModalRoute(true)}
                                >
                                    Generar ruta
                                </Button>
                            </GridItem>
                            <GridItem
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                xs={1}
                            >
                                <Button
                                    justIcon
                                    round
                                    color={"info"}
                                    className={classes.marginRight}
                                >
                                    <Event className={classes.icons} />
                                </Button>
                            </GridItem>
                        </GridItem>
                        <NavPills
                            color="primary"
                            alignCenter
                            onClick={handleOnClickNav}
                            tabs={[
                                {
                                    tabButton: "En proceso",
                                    tabContent: (
                                        <RequestTable
                                            dataArray={auxRequestArray}
                                            handleClickDetails={handleClickDetails}
                                            handleEnableAddress={handleEnableAddress}
                                            handleEnableAddressContracts={setselectEnableAddressContracts}
                                            handleEnableAddressWarehouse={setselectEnableAddressWarehouse}
                                            handleDecompetation={handleDecompetation}
                                            handleRewriteTelemedicina={handleRewriteTelemedicina}
                                            handleRequestTelemedicina={setselectTelemedicinaModal}
                                            handlesTransport={setselectTransportItem}
                                            handleProgressExtra={setselectProgressExtra}
                                            title={"proceso"}
                                            setOpenModalExam={setOpenModalExam}
                                            setOpenModalRequestExam={setOpenModalRequestExam}
                                            setOpenModalUploadAuthorization={setOpenModalUploadAuthorization}
                                            setOpenModalWaitingContract={setOpenModalWaitingContract}
                                            setOpenModalToPerform={setOpenModalToPerform}
                                            setopenModalAddAuthorizationQuotation={setopenModalAddAuthorizationQuotation}
                                            setOpenModalUploadAuthorizationAntibiotic={setOpenModalUploadAuthorizationAntibiotic}
                                            setOpenModalPreviewRequestEquipment={setOpenModalPreviewRequestEquipment}
                                            setTipoTarea={setTipoTarea}
                                            setOpenModalUploadOrderAntibiotic={setOpenModalUploadOrderAntibiotic}
                                            setOpenModalRequestAntibiotic={setOpenModalRequestAntibiotic}
                                            handleCheckEquipment={handleCheckEquipment}
                                            setTempRow={setTempRow}
                                            handleInsumos={handleInsumos}
                                            handleReplacementMedicine={handleReplacementMedicine}
                                            setOpenModalAddAuthorization={setOpenModalAddAuthorization}
                                            setopenModalAddAuthorizationProfExtra={setopenModalAddAuthorizationProfExtra}
                                            setopenModalEditProfExtra={setopenModalEditProfExtra}
                                            setopenModalTabletLose={setopenModalTabletLose}
                                            setopenModalAsssingWorks={setopenModalAsssingWorks}
                                            
                                        />
                                    )
                                },
                                {
                                    tabButton: "Esperando a otra área",
                                    tabContent: (
                                        <RequestTable
                                            dataArray={auxRequestArray}
                                            handleClickDetails={handleClickDetails}
                                            handleEnableAddress={handleEnableAddress}
                                            handleEnableAddressContracts={setselectEnableAddressContracts}
                                            handleEnableAddressWarehouse={setselectEnableAddressWarehouse}
                                            handleDecompetation={handleDecompetation}
                                            title={"otro"}
                                        />
                                    )
                                },
                                {
                                    tabButton: "Finalizadas",
                                    tabContent: (
                                        <RequestTable
                                            dataArray={auxRequestEndArray}
                                            handleProgressExtra={setselectProgressExtra}
                                            setopenModalTabletLose={setopenModalTabletLose}
                                            title={"finalizado"}
                                        />

                                    )
                                }
                            ]}
                            />
                    </CardBody>
                    {editRowDetails &&
                    <ModalEditScheduleTime
                        open={!!editRowDetails}
                        handleClose={() => seteditRowDetails(null)}
                        editRowDetails={editRowDetails}
                        handleClearState={handleClearState}
                        setselectRowDetails={setselectRowDetails}
                    />
                    }
                    {openModalRoute &&
                    <ModalRoute
                        open={!!openModalRoute}
                        handleClose={() => setopenModalRoute(null)}
                        requestArray={requestArray}
                        userData={userData}
                    />
                    }
                    {selectEnableAddress &&
                        <ModalEnableAddress
                            open={!!selectEnableAddress}
                            handleClose={() => setselectEnableAddress(null)}
                            selectEnableAddress={selectEnableAddress}
                        />
                    }
                    {selectEnableAddressWarehouse &&
                        <ModalEnableAddressWarehouse
                            open={!!selectEnableAddressWarehouse}
                            handleClose={() => setselectEnableAddressWarehouse(null)}
                            selectEnableAddress={selectEnableAddressWarehouse}
                            handleUpdateRowState={handleUpdateRowState}
                        />
                    }
                    {selectDecompetation &&
                    <ModalDecompensation
                        open={!!selectDecompetation}
                        handleClose={() => setselectDecompetation(false)}
                        listPeriod={listPeriod}
                        listassigned={listassigned}
                        listProfDecompensation={listProfDecompensation}
                        medicalResourceData={medicalResourceData}
                        switchProps={true}
                        requestDataWork={selectDecompetation}
                        patient={selectPatient}
                        dataFile={selectFile}
                        handleUpdateStateDecompetation={handleUpdateStateDecompetation}
                    />
                    }
                    {selectRewriteTelemedicina &&
                    <ModalTranscriptionTelemedicina
                        open={!!selectRewriteTelemedicina}
                        handleClose={() => setselectRewriteTelemedicina(null)}
                        listMedical={listMedical}
                        listPeriod={listPeriod}
                        listassigned={listassigned}
                        tensAssigned={tensAssigned}
                        requestDataWork={selectRewriteTelemedicina}
                        dataFile={selectFile}
                        dataPatient={selectPatient}
                    />
                    }
                    {openModalUploadAuthorizationAntibiotic &&
                        <ModalUploadAuthorizationAntibiotic
                            tempRow={tempRow}
                            openModal={openModalUploadAuthorizationAntibiotic}
                            closeModal={() => setOpenModalUploadAuthorizationAntibiotic(false)}
                        />
                    }
                    {openModalPreviewRequestEquipment &&
                        <ModalPreviewRequestEquipment
                            tempRow={tempRow}
                            openModal={openModalPreviewRequestEquipment}
                            closeModal={() => setOpenModalPreviewRequestEquipment(false)}
                            tipoTarea={tipoTarea}
                        />
                    }
                    {openModalUploadOrderAntibiotic &&
                        <ModalUploadOrderAntibiotic
                            tempRow={tempRow}
                            openModal={openModalUploadOrderAntibiotic}
                            closeModal={() => setOpenModalUploadOrderAntibiotic(false)}
                        />
                    }
                    {openModalRequestAntibiotic &&
                        <ModalManagerRequestAntibiotic
                            tempRow={tempRow}
                            openModal={openModalRequestAntibiotic}
                            closeModal={() => setOpenModalRequestAntibiotic(false)}
                        />
                    }

                    {openModalExam && (
                        <ModalExamData
                        tempRow={tempRow}
                        openModal={openModalExam}
                        closeModal={() => setOpenModalExam(false)}
                        loadExams={false}
                        />
                    )}
                    {openModalUploadAuthorization && (
                        <ModalUploadAuthorization
                            tempRow={tempRow}
                            openModal={openModalUploadAuthorization}
                            closeModal={() => setOpenModalUploadAuthorization(false)}
                        />
                    )}
                    {openModalRequestExam && (
                        <ModalUploadExamRequest
                        tempRow={tempRow}
                        openModal={openModalRequestExam}
                        closeModal={() => setOpenModalRequestExam(false)}
                        />
                    )}
                    {openModalWaitingContract && (
                        <ModalExamWaiting
                        tempRow={tempRow}
                        openModal={openModalWaitingContract}
                        closeModal={() => setOpenModalWaitingContract(false)}
                        />
                    )}
                    {openModalToPerform && (
                        <ModalExamToPerform
                        tempRow={tempRow}
                        openModal={openModalToPerform}
                        closeModal={() => setOpenModalToPerform(false)}
                        />
                    )}
                    {openModalInsumos && (
                        <ModalEditRequestInProcess
                        openModal={openModalInsumos}
                        closeModal={() => setOpenModalInsumos(false)}
                        rowInfo={tempRow}
                        listMedical={listMedical}
                        listResource={listResource}
                        medicalResourceData={medicalResourceData}
                        setauxData={setauxRequestArray}
                        dataArray={auxRequestArray}
                        finishArray={auxRequestEndArray}
                        setFinishArray={setauxRequestEndArray}
                        type={"2"}
                        isSpecific={true}
                        />
                    )}
                    {openModalAddAuthorization && (
                        <ModalAddAuthorization
                            openModal={openModalAddAuthorization}
                            closeModal={() => setOpenModalAddAuthorization(false)}
                            tempRow={tempRow}
                            setauxData={setauxRequestArray}
                            dataArray={auxRequestArray}
                            finishArray={auxRequestEndArray}
                            setFinishArray={setauxRequestEndArray}
                        />
                    )}
                    {openModalAddAuthorizationProfExtra && (
                        <ModalAddAuthorizationPersonal
                            openModal={!!openModalAddAuthorizationProfExtra}
                            closeModal={() => setopenModalAddAuthorizationProfExtra(false)}
                            tempRow={openModalAddAuthorizationProfExtra}
                            setauxData={setauxRequestArray}
                            dataArray={auxRequestArray}
                            finishArray={auxRequestEndArray}
                            setFinishArray={setauxRequestEndArray}
                        />
                    )}
                    {openModalEditProfExtra && (
                        <ModalEditPersonal
                            openModal={!!openModalEditProfExtra}
                            closeModal={() => setopenModalEditProfExtra(false)}
                            tempRow={openModalEditProfExtra}
                            setauxData={setauxRequestArray}
                            dataArray={auxRequestArray}
                            finishArray={auxRequestEndArray}
                            setFinishArray={setauxRequestEndArray}
                        />
                    )}
                    {openModalAddAuthorizationQuotation && (
                        <ModalauthorizationQuotation
                            openModal={openModalAddAuthorizationQuotation}
                            closeModal={() => setopenModalAddAuthorizationQuotation(false)}
                            tempRow={tempRow}
                            setauxData={setauxRequestArray}
                            dataArray={auxRequestArray}
                            finishArray={auxRequestEndArray}
                            setFinishArray={setauxRequestEndArray}
                        />
                    )}
                    {openModalReplaceMedicine && (
                        <ModalReplaceMedicine
                            open={openModalReplaceMedicine}
                            closeModal={() => setopenModalReplaceMedicine(false)}
                            info={infoReplaceMedicine}
                            listMedical={listMedical}
                        />
                    )}
                    {openModalCheckEquipment && (
                        <ModalCheckEquipment
                            open={openModalCheckEquipment}
                            closeModal={() => setOpenModalCheckEquipment(false)}
                            info={infoCheckEquipment}
                            handleCheckEquipment={handleCheckEquipment}
                            infoRow={infoRow}
                        />
                    )}
                    {selectTelemedicinaModal && (
                        <ModalRequestTelemedicina
                            open={!!selectTelemedicinaModal}
                            closeModal={() => setselectTelemedicinaModal(null)}
                            dataPatient={selectTelemedicinaModal?.id_ficha?.id_paciente || {}}
                            requestInfo={selectTelemedicinaModal?.id_solicitud_paciente || {}}
                        />
                    )}
                    {selectTransportItem && (
                        <ModalDetailResourcesDriver
                            open={!!selectTransportItem}
                            closeModal={() => setselectTransportItem(null)}
                            infoRow={selectTransportItem}
                            setinitialCharge={setinitialCharge}
                        />
                    )}
                    {selectProgressExtra && (
                        <ModalProgressExtra
                            open={selectProgressExtra}
                            closeModal={() => setselectProgressExtra(false)}
                            rowInfo={selectProgressExtra}
                            setinitialCharge={setinitialCharge}
                        />
                    )}
                    {openModalTabletLose && (
                        <ModalTabletLose
                            open={!!openModalTabletLose}
                            closeModal={() => setopenModalTabletLose(null)}
                            rowInfo={openModalTabletLose}
                            setinitialCharge={setinitialCharge}
                        />
                    )}
                    {openModalAsssingWorks && (
                        <ModalAssingAdvice
                            open={!!openModalAsssingWorks}
                            closeModal={() => setopenModalAsssingWorks(null)}
                            rowInfo={openModalAsssingWorks}
                            setinitialCharge={setinitialCharge}
                        />
                    )}
                </Card>
                }
            </GridItem>
        </GridContainer>
    );
};

export default ClinicalRequest;
