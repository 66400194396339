import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import apiform from "utils/APIForm";
import DetailSelect from "views/Widgets/FichaPersonal/EditFichaPersonal/detailSelect";
import DetailMoneyOnly from "views/Widgets/FichaPersonal/EditFichaPersonal/detailMoneyOnly";
import ModalDialog from "views/Widgets/Common/ModalDialog";

export default function ModalTherapy(props) {
  const { open, closeModal, id_paquetizacion, type="TerapiaPermanente", title="Crear terapia permanente", reloadTherapy } = props;

  const [zona, setZona] = useState("");
  const [kine_resp_habil, setKine_resp_habil] = useState("");
  const [kine_resp_inhabil, setKine_resp_inhabil] = useState("");
  const [kine_motor, setKine_motor] = useState("");
  const [fonoaudiologo, setFonoaudiologo] = useState("");
  const [terapia_ocupacional, setTerapia_ocupacional] = useState("");
  const [enf_urgencias_habil, setEnf_urgencias_habil] = useState("");
  const [enf_urgencias_inhabil, setEnf_urgencias_inhabil] = useState("");
  const [pediatra_habil, setPediatra_habil] = useState("");
  const [pediatra_inhabil, setPediatra_inhabil] = useState("");
  const [internista_adulto_habil, setinternista_adulto_habil] = useState("");
  const [internista_adulto_inhabil, setInternista_adulto_inhabil] = useState("");

  const list_zona = useSelector(state => state.currentList.LISTA_ZONAS_TERAPIA);

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  function createTherapy() {
    const formData = new FormData();

    if (zona) {
      formData.append("zona", zona.value);
    }
    formData.append("kine_resp_habil", kine_resp_habil);
    formData.append("kine_resp_inhabil", kine_resp_inhabil);
    formData.append("kine_motor", kine_motor);
    formData.append("fonoaudiologo", fonoaudiologo);
    formData.append("terapia_ocupacional", terapia_ocupacional);
    formData.append("enf_urgencias_habil", enf_urgencias_habil);
    formData.append("enf_urgencias_inhabil", enf_urgencias_inhabil);
    formData.append("pediatra_habil", pediatra_habil);
    formData.append("pediatra_inhabil", pediatra_inhabil);
    formData.append("internista_adulto_habil", internista_adulto_habil);
    formData.append("internista_adulto_inhabil", internista_adulto_inhabil);
    formData.append("tipo_terapia_rehabilitacion", type);
    formData.append("id_paquetizacion", id_paquetizacion);

    apiform
      .post("api/terapiasrehabilitaciones/", formData).then(response => {
        reloadTherapy();
        closeModal();
      })
  }

  const handleZona = zona => {
    setZona(zona);
  };

  const _content_ = <div className="form-row" style={{ marginBottom: "10rem" }}>
    <DetailSelect
      label="Zona:"
      value={zona}
      onchange={handleZona}
      list={list_zona}
    />
    <DetailMoneyOnly
      label="KTR (Hábil):"
      value={kine_resp_habil}
      money={setKine_resp_habil}
    />
    <DetailMoneyOnly
      label="KTR (Inhábil):"
      value={kine_resp_inhabil}
      money={setKine_resp_inhabil}
    />
    <DetailMoneyOnly
      label="KTM:"
      value={kine_motor}
      money={setKine_motor}
    />
    <DetailMoneyOnly
      label="Fono:"
      value={fonoaudiologo}
      money={setFonoaudiologo}
    />
    <DetailMoneyOnly
      label="TO:"
      value={terapia_ocupacional}
      money={setTerapia_ocupacional}
    />
    <DetailMoneyOnly
      label="EU (Hábil):"
      value={enf_urgencias_habil}
      money={setEnf_urgencias_habil}
    />
    <DetailMoneyOnly
      label="EU (Inhábil):"
      value={enf_urgencias_inhabil}
      money={setEnf_urgencias_inhabil}
    />
    <DetailMoneyOnly
      label="Médico pediatra (Hábil):"
      value={pediatra_habil}
      money={setPediatra_habil}
    />
    <DetailMoneyOnly
      label="Médico pediatra (Inhábil):"
      value={pediatra_inhabil}
      money={setPediatra_inhabil}
    />
    <DetailMoneyOnly
      label="Médico internista adulto (Hábil):"
      value={internista_adulto_habil}
      money={setinternista_adulto_habil}
    />
    <DetailMoneyOnly
      label="Médico internista adulto (Inhábil):"
      value={internista_adulto_inhabil}
      money={setInternista_adulto_inhabil}
    />
  </div>

  const _actions_ = <>
    <Button
      onClick={closeModal}
      color="primary"
      style={{ textTransform: "none" }}
    >
      Cancelar
    </Button>
    <Button
      onClick={createTherapy}
      color="primary"
      style={{ textTransform: "none" }}
    >
      Crear
    </Button>
  </>

  return (
      <ModalDialog
        open={open}
        onClose={closeModal}
        title={title}
        _content_={_content_}
        _actions_ = {_actions_}
      />
  );
}

ModalTherapy.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_paquetizacion: PropTypes.number
};
