import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const Tab = props => {
  const { setTabSection, sendApproval } = props;
  return (
    <Grid
      container
      justify="center"
      style={{ marginTop: "2em" }}
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={2}>
        <Button
          size="large"
          color="primary"
          variant="contained"
          style={{
            textTransform: "none",
            //marginRight: "5px",
            height: "70px",
            width: "100%"
          }}
          onClick={() => setTabSection("motivo")}
        >
          1.- Motivo traslado
        </Button>
      </Grid>

      <Grid item xs={2}>
        <Button
          size="large"
          color="primary"
          variant="contained"
          style={{
            textTransform: "none",
            //marginLeft: "10px",
            //marginRight: "5px",
            height: "70px",
            width: "100%"
          }}
          onClick={() => setTabSection("tipo")}
        >
          2.- Tipo de traslado
        </Button>
      </Grid>

      <Grid item xs={2}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            //marginLeft: "10px",
            //marginRight: "5px",
            height: "70px",
            width: "100%"
          }}
          onClick={() => setTabSection("origen")}
        >
          3.- Origen y destino
        </Button>
      </Grid>

      <Grid item xs={2}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            textTransform: "none",
            //marginLeft: "10px",
            //marginRight: "5px",
            height: "70px",
            width: "100%"
          }}
          onClick={() => setTabSection("profesionales")}
        >
          4.- Profesionales clínicos
        </Button>
      </Grid>

      {sendApproval ? (
        <Grid item xs={2}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              //marginLeft: "10px",
              height: "70px",
              width: "100%"
            }}
            onClick={() => setTabSection("adjuntar")}
          >
            5.- Adjuntar aprobación
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Tab;

Tab.propTypes = {
  setTabSection: PropTypes.func,
  sendApproval: PropTypes.bool
};
