import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import api from "utils/API";
import apiform from "utils/APIForm";
import ViewTitle from "views/Widgets/FichaPersonal/viewTitle";
import CardTitle from "views/Widgets/FichaPersonal/cardTitle";
import LoadingSpinner from "views/Widgets/FichaPersonal/loadingSpinner";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Grid from "@material-ui/core/Grid";
import ReceptionInfo from "views/Outcome/Receptions/ReceptionInfo";
import ModalCancelRequest from "./ModalCancelRequest";
import TableWaitToOutcome from "../../../Warehouse/Orders/TableWaitToOutcome";



export default function AddReceptionFile() {
  const { id } = useParams();
  const id_order = parseInt(id, 10);
  const [moduleType, setmoduleType] = useState("");
  const [loading, setLoading] = useState(false);
  const [observation, setObservation] = useState(null);
  const [file, setFile] = useState(null);

  const [getFiles, setGetFiles] = useState([]);
  const [order, setOrder] = useState([]);
  const [modalCancelRequest, setModalCancelRequest] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(false);
    const tempArrayURL = window.location.href.split("/");
    const tempModuleType = tempArrayURL[tempArrayURL.length - 2];
    setmoduleType(tempModuleType);
    const group = tempModuleType === "storage" ? "Insumos" : "Medicamentos";
    const groupFile = `Egresos ${group}`;
    api.get(`/api/v2/bodega/pedidos/egresos/grupo/${group}/envio/${id}/detalle/`).then(result => {
      setProducts(result.data);
      api.get(`api/egresos/documentospedidos/solicitud/${result.data[0].id_envio_pedido.id_solicitud_pedido_id}/?area=${groupFile}&tipo_archivo=GuiaDespacho&nombre=${`Guía de despacho caja ${result.data[0].id_envio_pedido.numero_pedido}`}`).then(result2 => {
          setLoading(true);
          setGetFiles(result2.data);
      })
      api.get(`api/solicitudpedidos/${result.data[0].id_envio_pedido.id_solicitud_pedido_id}`).then(result3 => {
          setLoading(true);
          setOrder(result3.data);
        })
      })
   
  }, [id]);
  
  function saveFile() {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    const groupFile = `Egresos ${group}`
    const formData = new FormData();

    formData.append(
      "nombre",
      `Guía de despacho caja ${products[0].numero_pedido}`
    );
    formData.append("tipo_archivo", "GuiaDespacho");
    formData.append("archivo", file);
    formData.append("observacion", observation);
    formData.append("area", groupFile);
    formData.append(
      "id_pedidos",
      products[0].id_producto_pedido.id_solicitud_pedido.id
    );

    apiform
      .post("api/documentospedidos/", formData)
      .then(response => {
        window.location.href = `/admin/outcome/receptions/${moduleType}`;
      })

  }

  function updateFile() {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    const groupFile = `Egresos ${group}`
    const formData = new FormData();

    if (file != null) {
      formData.append("archivo", file);
    }

    formData.append(
      "nombre",
      `Guía de despacho caja ${products[0].numero_pedido}`
    );
    if (observation != "") {
      formData.append("observacion", observation);
    } else {
      formData.append("observacion", getFiles[0].observacion);
    }
    formData.append("tipo_archivo", "GuiaDespacho");
    formData.append("area", groupFile);
    formData.append(
      "id_pedidos",
      products[0].id_producto_pedido.id_solicitud_pedido.id
    );

    apiform
      .put(`api/documentospedidos/${getFiles[0].id}`, formData)
      .then(response => {
        window.location.href = `/admin/outcome/receptions/${moduleType}`;
      })

  }

  function saveAndSend() {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    const groupFile = `Egresos ${group}`;
    const formData = new FormData();

    formData.append(
      "nombre",
      `Guía de despacho caja ${products[0].numero_pedido}`
    );
    formData.append("tipo_archivo", "GuiaDespacho");
    formData.append("archivo", file);
    formData.append("observacion", observation);
    formData.append("area", groupFile);
    formData.append(
      "id_pedidos",
      products[0].id_producto_pedido.id_solicitud_pedido.id
    );

    const formData2 = new FormData();

    formData2.append(
      "numero_pedido",
      products[0].id_envio_pedido.numero_pedido
    );
    formData2.append("estado_egreso", "Notificar a transporte");
    formData2.append("estado", "Notificar a transporte");
    const requestDoc = apiform.post("api/documentospedidos/", formData);
    //const requestUpdateReception = apiform.patch(`api/enviospedidos/${products[0].id_envio_pedido.id}`, formData2)

    const requestUpdateReception = api.patch(`api/envios/egresos/caja/${products[0].id_envio_pedido.id}/adjuntado/`)
    Promise.all([requestDoc, requestUpdateReception])
      .then(response => {
        window.location.href = `/admin/outcome/receptions/${moduleType}`;
      })
      .catch(error => alert(`Error: ${error}`));
  }

  function updateAndSend() {

    const group = moduleType === "storage" ? "Insumos" : "Medicamentos";
    const groupFile = `Egresos ${group}`;
    const formData = new FormData();

    if (file != null) {
      formData.append("archivo", file);
    }
    formData.append(
      "nombre",
      `Guía de despacho caja ${products[0].numero_pedido}`
    );
    if (observation != "") {
      formData.append("observacion", observation);
    } else {
      formData.append("observacion", getFiles[0].observacion);
    }
    formData.append("tipo_archivo", "GuiaDespacho");
    formData.append("area", groupFile);
    formData.append(
      "id_pedidos",
      products[0].id_producto_pedido.id_solicitud_pedido.id
    );

    const formData2 = new FormData();

    formData2.append(
      "numero_pedido",
      products[0].id_envio_pedido.numero_pedido
    );
    formData2.append("estado", "Notificar a transporte");
    formData2.append("estado_egreso", "Notificar a transporte");

    const body = {
      observacion: observation
    };

    const requestDoc = apiform.put(`api/documentospedidos/${getFiles[0].id}`, formData);
    //const requestUpdateReception = apiform.patch(`api/enviospedidos/${products[0].id_envio_pedido.id}`, formData2);
    const requestUpdateReception = api.patch(`api/envios/egresos/caja/${products[0].id_envio_pedido.id}/adjuntado/`, body);
    Promise.all([requestDoc, requestUpdateReception]).then(response => {
      window.location.href = `/admin/outcome/receptions/${moduleType}`;
    })
     
  }

  function NewTab(url) {
    window.open(url);
    return false;
  }

  return (
    <GridContainer>
      <ViewTitle
        title="ADJUNTAR GUÍA DE DESPACHO"
        message="En esta sección podrás la guía de despacho para tu pedido."
      />
      <GridItem xs={12} sm={6} md={12}>
        <CardBody className="rounded shadow border border-primary">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Operaciones</Typography>
            <Link color="inherit" href={`/admin/outcome/tasks/${moduleType}`}>
              Tareas bodega
            </Link>
            <Link
              color="inherit"
              href={`/admin/outcome/receptions/${moduleType}`}
            >
              Pedidos
            </Link>
            <Typography color="textPrimary">
              Adjuntar guía de despacho
            </Typography>
          </Breadcrumbs>
        </CardBody>
      </GridItem>
      <GridItem xs={12} sm={6} md={12}>
        {loading ? (
          <Card>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <ReceptionInfo
                order={order}
                moduleType={moduleType}
              />
            </GridContainer>
            <GridContainer justify="center" className="m-3 mt-3 mb-3">
              <TableWaitToOutcome products={products} />
            </GridContainer>
            <CardBody>
              <GridContainer justify="center" className="m-3 mt-3 mb-3">
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  {products[0]?.id_envio_pedido.estado !=
                  "Esperar a transporte" ? (
                    <h5 className="mb-2">Adjuntar guía de despacho</h5>
                  ) : (
                    <div>
                      <h5 className="mb-2">Guía de despacho</h5>
                      <p />
                      <br />
                      <p className="card-text">Guía de despacho anterior:</p>
                      {getFiles.map(file => (
                        <a
                          key={file.id}
                          onClick={() => NewTab(file.archivo)}
                          className="btn btn-cmh-color text-white"
                        >
                          {file.nombre}
                        </a>
                      ))}
                      <p>
                        <span className="font-weight-bold">
                          Observación anterior:
                        </span>
                        {getFiles.length > 0 ? getFiles[0].observacion : null}
                      </p>
                    </div>
                  )}
                </Grid>
                <Grid container xs={12} style={{ textAlign: "center" }}>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    {products[0]?.id_envio_pedido.estado ===
                    "Esperar a transporte" ? null : (
                      <DetailTextArea
                        label="OBSERVACIONES:"
                        placeholder="Observaciones"
                        value={observation}
                        maxlength="500"
                        onchange={e => {
                          setObservation(e.target.value);
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </GridContainer>
              <GridContainer
                justify="center"
                style={{ textAlign: "center" }}
                className="m-3 mt-3 mb-3"
              >
                {products[0]?.id_envio_pedido.estado ===
                "Esperar a transporte" ? null : (
                  <div className="col-md-4 mb-3">
                    <div className="card">
                      <div className="card-body">
                        <span className="card-title">
                          ADJUNTAR GUÍA DE DESPACHO:
                        </span>
                        <p className="card-text">
                          Archivo seleccionado: {file ? file.name : null}
                        </p>
                        <span className="btn btn-cmh-color btn-file">
                          Seleccionar
                          <input
                            type="file"
                            name={file}
                            onChange={e => {
                              setFile(e.target.files[0]);
                            }}
                          />
                        </span>
                        {getFiles.length > 0 ? (
                          <div>
                            <p />
                            <br />
                            <p className="card-text">
                              Guía de despacho anterior:
                            </p>
                            {getFiles.map(file => (
                              <a
                                key={file.id}
                                onClick={() => NewTab(file.archivo)}
                                className="btn btn-cmh-color text-white"
                              >
                                {file.nombre}
                              </a>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </GridContainer>
              <GridContainer
                justify="center"
                style={{ textAlign: "center" }}
                className="m-3 mt-3 mb-3"
              >
                {products[0]?.id_envio_pedido.estado ===
                "Esperar a transporte" ? null : getFiles.length > 0 ? (
                  <div>
                    <p>
                      <span className="font-weight-bold">
                        Observación anterior:
                      </span>
                      {getFiles ? getFiles[0].observacion : null}
                    </p>
                  </div>
                ) : file === null ? (
                  <span className="font-weight-bold text-danger">
                    Debes ingresar un archivo
                  </span>
                ) : null}
              </GridContainer>
              <GridContainer justify="center" className="mt-3 mb-3">
                {products[0]?.id_envio_pedido.estado ===
                "Esperar a transporte" ? (
                  <Link href={`/admin/outcome/receptions/${moduleType}`}>
                    <Button variant="contained" color="primary" className="m-1">
                      Atrás
                    </Button>
                  </Link>
                ) : getFiles.length > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={updateFile}
                    className="m-1"
                  >
                    Guardar
                  </Button>
                ) : file != null ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveFile}
                    className="m-1"
                  >
                    Guardar
                  </Button>
                ) : null}
                {products[0]?.id_envio_pedido.estado ===
                "Esperar a transporte" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalCancelRequest(true)}
                    className="m-1"
                  >
                    Cancelar solicitud
                  </Button>
                ) : getFiles.length > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={updateAndSend}
                    className="m-1"
                  >
                    Guardar y enviar
                  </Button>
                ) : file != null ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveAndSend}
                    className="m-1"
                  >
                    Guardar y enviar
                  </Button>
                ) : null}
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardTitle title="Cargando información ..." />
            <CardBody className="d-flex justify-content-center">
              <LoadingSpinner />
            </CardBody>
          </Card>
        )}
      </GridItem>
      {modalCancelRequest && (
        <ModalCancelRequest
          open={modalCancelRequest}
          closeModal={() => setModalCancelRequest(false)}
          id_order={id_order}
          idEnvioPedido={products[0].id_envio_pedido.id}
          envioPedido={products[0].id_envio_pedido}
          moduleType={moduleType}
        />
      )}
    </GridContainer>
  );
}
