import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import api from "utils/API";
import apiform from "utils/APIForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import ReceptionInfoModal from "./ReceptionInfoModal";
import DetailTextArea from "views/Widgets/FichaPersonal/EditFichaPersonal/detailTextArea";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalRejectRequest(props) {
  const classes = useStyles();
  const { open, closeModal, id_order, divisionRequest, moduleType } = props;
  const [reasonRejection, setReasonRejection] = useState("");


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const scroll = "paper";

  function updateDivision() {

    const formData = new FormData();

    formData.append("cantidad_fraccionar", divisionRequest.cantidad_fraccionar);
    formData.append("observaciones", divisionRequest.observaciones);
    formData.append("cantidad_resultante", divisionRequest.cantidad_resultante);
    formData.append("estado", "Rechazada por ingresos");
    formData.append(
      "id_producto_fraccionar",
      divisionRequest.id_producto_fraccionar
    );
    formData.append(
      "id_producto_resultante",
      divisionRequest.id_producto_resultante
    );
    formData.append("area", "Bodega");
    formData.append("motivo_rechazo", reasonRejection);

    apiform
      .put(`api/solicitudfraccionamiento/${divisionRequest.id}`, formData)
      .then((response) => {
        console.log(response);
        window.location.href = `/admin/income/divisions/${moduleType}`;
      })

  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">
        Rechazar fraccionamiento
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        {/* <GridContainer justify="center" className="m-3 mt-3 mb-3">
          <ReceptionInfoModal id={id_order} />
        </GridContainer> */}
        {/* <Grid container xs={12}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <div className="form" style={{ marginBottom: "3rem" }}>
              <div>
                El pedido está:
                <span className="font-weight-bold">Completo</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={2} />
        </Grid> */}
        <Grid container xs={12} style={{ textAlign: "center" }}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <DetailTextArea
              label="¿POR QUÉ DESEA RECHAZAR EL FRACCIONAMIENTO?:"
              placeholder="Motivo rechazo"
              value={reasonRejection}
              maxlength="500"
              onchange={(e) => {
                setReasonRejection(e.target.value);
              }}
            />
            {reasonRejection === "" ? (
              <span className="text-danger">
                Debes ingresar un motivo de rechazo.
              </span>
            ) : null}
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <div className="form" style={{ marginBottom: "3rem" }}>
              <div>
                <p className="text-center font-weight-bold">
                  ¿Está seguro que sea rechazar el fraccionamiento?
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </DialogContent>
      <DialogActions>
        {reasonRejection !== "" ? (
          <Button onClick={updateDivision} color="primary">
            Si
          </Button>
        ) : null}
        <Button onClick={closeModal} color="primary">
          No
        </Button>
      </DialogActions>

    </Dialog>
  );
}

ModalRejectRequest.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.bool,
  id_order: PropTypes.number,
};
