import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import MuiAlert from "@material-ui/lab/Alert";
import {
    Grid,
    TextField,
    Paper,
    Typography
} from '@material-ui/core';
import {red, green} from "@material-ui/core/colors";
import {
    CloudDownload,
    CloudUpload,
    Edit,
    Publish,
} from '@material-ui/icons/';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import moment from "moment-timezone";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import api from "utils/API";
import apilink from "utils/APIlink";
import "moment/locale/es";
import allActions from "actions";
moment.locale("es");


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function FormContrat({
  contractsValidation,
  setContractsValidation,
  setBody,
  management,
  type,
  handleUpdateContract,
  typeForm,
  quotation=null,
  data,
  stateWork,
  selectContracts,
  stateFile,
  setopenModal,
  isapre,
  message
}) {
  const dispatch = useDispatch();
  const [validation, setvalidation] = useState(false);
  const [change, setChange] = useState(true);
  const [startDate, setstartDate] = useState(data?.fecha_fin);
  const [endDate, setendDate] = useState(moment(data?.fecha_fin).add(1,"year"));
  const [selectfile, setselectfile] = useState(null);
  const [blockUpload, setblockUpload] = useState(stateFile === "ContratoSubido" ? false : true);
  const [back, setback] = useState(false);

  useEffect(() => {
    if (change) {
      validateForm();
    }
  });

  const validateForm = () => {
    const arrayValidation = [startDate != null, endDate != null];
    if (arrayValidation.every(item => item === true)) {
      setvalidation(true);
    } else {
      setvalidation(false);
    }
  };

  const handleDate = (value) => {
    setstartDate(value)
    setendDate(moment(value).add(1,"year"));
    setChange(value);
  };

  const handleEditFile = () => {
    handleUpdateContract(
      {
        fecha_inicio: moment(startDate).format("YYYY-MM-DD"),
        fecha_fin: moment(endDate).format("YYYY-MM-DD"),
        dias_aviso: 30,
        fecha_aviso: moment(endDate).subtract(30, "days").format("YYYY-MM-DD"),
      },
      setBody,
      typeForm,
      data.id
    );
    const index = contractsValidation.findIndex(tempRow => tempRow === typeForm);
    if (index >= 0) {
      let tempContracts = contractsValidation;
      tempContracts[index] = true;
      setContractsValidation(tempContracts);
    }
    setback(false);
  };

  const handleDownloadFile = () => {
    window.open(
      `${apilink}api/facturacion/paciente/${selectContracts.id_ficha.id_paciente.id}/habilitacion/tarea/${selectContracts.id}/contratos/${data.id}/descargar/`,
      "_blank"
    );
  };

  const handleUploadFile = () => {
    const fileInfo = selectfile[0];
    const body = new FormData();
    body.append("archivo", fileInfo);
    body.append("nombre_archivo", selectfile[0].name);

    api.put(
      `api/facturacion/paciente/${selectContracts.id_ficha.id_paciente.id}/habilitacion/tarea/${selectContracts.id}/contratos/${data.id}/subir/`,
      body
    ).then(request => {
        const tempData = request.data;
        api.patch(`api/documentospaciente/${tempData.documento.id_documento}`,body).then(request => {
          if (tempData.tarea.estado === "Finalizado"){
            setopenModal(true);
          }
          setblockUpload(false);
        });
      });
  };

  return (
    <Paper
      style={{
        height: "325px",
        overflow: "hidden",
        margin: "1px 1px 15px 1px"
      }}
    >
      {stateWork !== "SubirContratos" || back ? (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
        >
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-end"
            item
            xs={6}
            style={{ padding: "10px 15px" }}
          >
            <Typography
              style={{ textTransform: "none" }}
              variant="body2"
              gutterBottom
            >{`Fecha de inicio del contrato`}</Typography>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              {endDate ? (
                <KeyboardDatePicker
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  label={"Fecha de inicio"}
                  placeholder="Fecha de inicio"
                  onChange={e => handleDate(e)}
                  maxDate={moment(endDate)}
                  value={startDate}
                  inputVariant={"outlined"}
                />
              ) : (
                <KeyboardDatePicker
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  label={"Fecha de inicio"}
                  placeholder="Fecha de inicio"
                  onChange={e => handleDate(e)}
                  value={startDate}
                  inputVariant={"outlined"}
                />
              )}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={6}
            style={{ padding: "10px 15px" }}
          >
            <Typography
              style={{ textTransform: "none" }}
              variant="body2"
              gutterBottom
            >{`Fecha de termino del contrato`}</Typography>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              {startDate ? (
                <KeyboardDatePicker
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  label={"Fecha de termino"}
                  placeholder="Fecha de termino"
                  onChange={e => setendDate(e)}
                  minDate={moment(startDate)}
                  value={endDate}
                  inputVariant={"outlined"}
                />
              ) : (
                <KeyboardDatePicker
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  label={"Fecha de termino"}
                  placeholder="Fecha de termino"
                  onChange={e => setendDate(e)}
                  value={endDate}
                  inputVariant={"outlined"}
                />
              )}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
            item
            xs={6}
            style={{ padding: "10px 15px" }}
          >
            <Typography
              style={{ textTransform: "none" }}
              variant="body2"
              gutterBottom
            >{`Isapre: ${isapre}`}</Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={6}
            style={{ padding: "10px 15px" }}
          >
            <Typography
              style={{ textTransform: "none" }}
              variant="body2"
              gutterBottom
            >{`Nº Cotización: ${quotation}`}</Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
            item
            xs={6}
            style={{ padding: "10px 15px" }}
          >
            <Typography
              style={{ textTransform: "none" }}
              variant="body2"
              gutterBottom
            >{`Dias de aviso de termino de contrato: 30 días`}</Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={6}
          ></Grid>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
            item
            xs={12}
          >
            {validation ? (
              <Button
                mr={2}
                variant="contained"
                className="text-white"
                style={{ backgroundColor: green[500] }}
                onClick={handleEditFile}
              >
                Guardar
              </Button>
            ) : (
              <Button
                disabled
                mr={2}
                variant="contained"
                className="text-white"
              >
                Guardar
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          item
          xs={12}
          style={{ height: "100%" }}
        >
          {blockUpload ? (
            <Button
              mr={2}
              variant="contained"
              className="text-white"
              style={{ backgroundColor: green[500], margin: "10px 0" }}
              onClick={() => setback(true)}
              startIcon={<Edit />}
            >
              Editar
            </Button>
          ) : (
            <Button
              mr={2}
              variant="contained"
              style={{ margin: "10px 0" }}
              disabled
              startIcon={<Edit />}
            >
              Editar
            </Button>
          )}
          {blockUpload ? (
            <Button
              onClick={handleDownloadFile}
              mr={2}
              variant="contained"
              color="primary"
              className="text-white"
              startIcon={<CloudDownload />}
            >
              Descargar
            </Button>
          ) : (
            <Button
              mr={2}
              variant="contained"
              disabled
              startIcon={<CloudDownload />}
            >
              Descargar
            </Button>
          )}
          {blockUpload ? (
            <Button
              variant="contained"
              component="label"
              color="primary"
              style={{ margin: "10px 0" }}
              startIcon={<Publish />}
            >
              Subir contrato firmado
              <TextField
                type="file"
                id="exam"
                onChange={event => setselectfile(event.target.files)}
                fullWidth
                my={2}
                style={{ display: "none" }}
                accept={("pdf", "jpg", "jpeg", "png")}
              />
            </Button>
          ) : (
            <Button
              disabled
              variant="contained"
              component="label"
              style={{ margin: "10px 0" }}
              startIcon={<Publish />}
            >
              Subir contrato firmado
            </Button>
          )}
          {selectfile ? selectfile[0].name : ""}
          {selectfile && blockUpload ? (
            <Button
              variant="contained"
              component="label"
              className="text-white"
              style={{ backgroundColor: green[500], margin: "10px 0" }}
              onClick={handleUploadFile}
              startIcon={<CloudUpload />}
            >
              Guardar contrato firmado
            </Button>
          ) : (
            <Button
              disabled
              variant="contained"
              component="label"
              startIcon={<CloudUpload />}
            >
              Guardar contrato firmado
            </Button>
          )}
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        justify="center"
        style={{ marginTop: "1em", marginBottom: "1em" }}
      >
        <Alert severity="info"> {message} </Alert>
      </Grid>
      {/*}
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        item
        xs={12}
        style={{ padding: "8px 0" }}
      >
        <SnackbarContent message={message} color="info" />
      </Grid>
          */}
    </Paper>
  );
}
